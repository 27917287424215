import React, { Component } from "react";
import { connect } from "react-redux";
import { getMcqList } from "../../../ducks/MCQDucks";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  checkWidgetAdminOrNot,
  draggable,
  URL,
} from "../../../utils/Constants";
import ModalWindow from "../../UI/ModalWindow";
import McqTestTemplate from "./McqTestTemplate";
import McqResultTemplate from "./McqResultTemplate";
import CreateMCQ from "../CreateMCQ";
import swal from "sweetalert";
import CustomCollapse from "../../UI/CustomCollapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import McqStudentList from "./McqStudentList";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
import GranularPermissionModal from "../../Permissions/GranularPermissionModal";

class ListMCQ extends Component {
  state = {
    practiseTest: [],
    examTest: [],
    mcqTest: [],
    mcqStudentList: [],
    mcqResult: "",
    mcqModal: false,
    mcqResultModal: false,
    minute: 15,
    seconds: 0,
    isSubmitted: false,
    mcq: [
      { name: "MCQ Without Timer", open: true },
      { name: "MCQ With Timer", open: false },
    ],
    mcqStudentListModal: false,
    correctAnswer: 0,
    incorrect: 0,
    unanswered: 0,
    totalAnswered: 0,
    mcqType: "my",
    updateData: "",
    resultHeader: "",
    permissionModal: false,
    fullMarks: 0,
    obtainedMarks: 0,
  };

  componentDidMount() {
    this.props.getMcqList(this.state.mcqType);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      practiseTest: nextProps.practiseTest,
      examTest: nextProps.examTest,
    };
  }

  startTimer = () => {
    this.setState({ seconds: this.state.seconds - 1 }, function () {
      if (this.state.seconds === -1) {
        this.setState({ minute: this.state.minute - 1 });
        this.setState({ seconds: 59 });
      }
      if (this.state.minute === 0 && this.state.seconds === 0) {
        clearInterval(this.startInterval);
        swal({
          title: "Time over",
          text: "Your time is up. Your given answers has been submitted.",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              dangerMode: true,
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
          alloswOutsideClick: false,
        }).then((ok) => {
          if (ok) {
            this.handleSubmit();
          } else {
            this.props.getMcqList(this.state.mcqType);
            this.setState({
              isSubmitted: false,
              mcqModal: !this.state.mcqModal,
            });
          }
        });
        // this.handleSubmit();
        // this.setState({ minute: 15, seconds: 59 });
      }
    });
  };

  startTimerTest = () => {
    if (this.state.mcqTest.testTypeId === 1) {
      this.startInterval = setInterval(this.startTimer, 1000);
    }
  };

  startTest = (e, id) => {
    let data = {
      id: id,
    };
    axiosPost(URL.getMcqById, data, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        if (datum.mcqQuestions.length > 0) {
          datum.mcqQuestions.forEach((ques) => {
            if (ques.mcqQuestionOptions.length > 0) {
              ques.mcqQuestionOptions.forEach((opt) => {
                opt.isSelected = false;
              });
            }
          });
        }

        this.setState(
          { mcqTest: datum, minute: datum.timer, seconds: 0 },
          function () {
            let studentMcq = datum.peopleMcq;
            if (studentMcq && studentMcq !== null) {
              this.setState({ isSubmitted: studentMcq.isSubmitted });
              if (datum.mcqQuestions.length > 0) {
                datum.mcqQuestions.forEach((ques) => {
                  if (ques.mcqQuestionOptions.length > 0) {
                    ques.mcqQuestionOptions.forEach((opt) => {
                      studentMcq.peopleMcqRecords.forEach((el) => {
                        if (el.mcqQuestionId === ques.id) {
                          if (el.mcqQuestionOptionId === opt.id) {
                            opt.isSelected = true;
                          }
                        }
                      });
                    });
                  }
                });
              }
            }
            this.setState({ mcqTest: datum }, function () {
              this.setState({ mcqModal: !this.state.mcqModal });
            });
          }
        );
      }
    });
  };

  openResult = (e, mcq) => {
    let url = "";
    let data = {};
    let creator = false;
    if (mcq.creatorPeopleId === parseInt(localStorage.getItem("peopleId"))) {
      url = URL.getAllStudentMCQResultList;
      data.mcqTestId = mcq.id;
      creator = true;
    } else {
      url = URL.getStudentMCQResultList;
      data.mcqTestId = mcq.id;
      data.peopleId = parseInt(localStorage.getItem("peopleId"));
    }

    axiosPost(url, data, (response) => {
      if (response.status === 200) {
        if (creator) {
          let mcqStudentList = response.data.data;
          let newMcqStudentList = [];
          if (mcqStudentList.length > 0) {
            mcqStudentList.forEach((el) => {
              if (el.isSubmitted === true) {
                newMcqStudentList.push(el);
              }
            });
            mcqStudentList.forEach((el) => {
              if (el.isSubmitted === false) {
                newMcqStudentList.push(el);
              }
            });
          }
          this.setState({
            mcqStudentListModal: !this.state.mcqStudentListModal,
            mcqStudentList: newMcqStudentList,
            resultHeader: mcq.topic + " - Results",
          });
        } else {
          let correctAnswer = 0;
          let unanswered = 0;
          let incorrect = 0;
          let fullMarks = 0;
          let obtainedMarks = 0;
          let datum = response.data.data;
          if (datum.length > 0) {
            datum.forEach((el) => {
              fullMarks = parseInt(el.fullMarks);
              if (
                el.selectedOptionId === undefined ||
                el.selectedOptionId === null
              ) {
                unanswered++;
              } else {
                if (el.mcqQuestionOptions.length > 0) {
                  el.mcqQuestionOptions.forEach((opt) => {
                    if (el.selectedOptionId === opt.id && opt.isCorrect) {
                      correctAnswer++;
                      obtainedMarks = obtainedMarks + parseInt(el.marks);
                    }
                    if (
                      el.selectedOptionId === opt.id &&
                      opt.isCorrect === false
                    ) {
                      incorrect++;
                    }
                  });
                }
              }
            });
          }

          let totalAnswered = correctAnswer + incorrect;
          this.setState(
            {
              mcqResult: datum,
              correctAnswer: correctAnswer,
              incorrect: incorrect,
              unanswered: unanswered,
              totalAnswered: totalAnswered,
              fullMarks,
              obtainedMarks,
            },
            function () {
              this.setState({
                mcqResultModal: !this.state.mcqResultModal,
                resultHeader: mcq.topic + " - Result",
              });
            }
          );
        }
      }
    });
  };

  viewStudentResult = (e, student) => {
    let data = {
      mcqTestId: student.mcqTestId,
      peopleId: student.peopleId,
    };
    axiosPost(URL.getStudentMCQResultList, data, (response) => {
      if (response.status === 200) {
        let correctAnswer = 0;
        let unanswered = 0;
        let incorrect = 0;
        let fullMarks = 0;
        let obtainedMarks = 0;
        let datum = response.data.data;
        if (datum.length > 0) {
          datum.forEach((el) => {
            fullMarks = parseInt(el.fullMarks);
            if (
              el.selectedOptionId === undefined ||
              el.selectedOptionId === null
            ) {
              unanswered++;
            } else {
              if (el.mcqQuestionOptions.length > 0) {
                el.mcqQuestionOptions.forEach((opt) => {
                  if (el.selectedOptionId === opt.id && opt.isCorrect) {
                    obtainedMarks = !!el.marks
                      ? obtainedMarks + parseInt(el.marks)
                      : obtainedMarks;

                    correctAnswer++;
                  }
                  if (
                    el.selectedOptionId === opt.id &&
                    opt.isCorrect === false
                  ) {
                    incorrect++;
                  }
                });
              }
            }
          });
        }
        let totalAnswered = correctAnswer + incorrect;
        this.setState(
          {
            mcqResult: datum,
            correctAnswer: correctAnswer,
            incorrect: incorrect,
            unanswered: unanswered,
            totalAnswered: totalAnswered,
            obtainedMarks,
            fullMarks,
          },
          function () {
            this.setState({
              mcqResultModal: !this.state.mcqResultModal,
            });
          }
        );
      }
    });
  };

  toggleMcqResultModal = () => {
    this.setState({ mcqResultModal: !this.state.mcqResultModal });
  };

  toggleMcqStudentListModal = () => {
    this.setState({ mcqStudentListModal: !this.state.mcqStudentListModal });
  };

  toggleMcqModal = () => {
    clearInterval(this.startInterval);
    this.setState({ mcqModal: !this.state.mcqModal, isSubmitted: false });
  };

  //OptionClick
  handleOptionClick = (e, quesId, oIdx) => {
    let mcqTest = this.state.mcqTest;
    if (mcqTest.mcqQuestions.length > 0) {
      mcqTest.mcqQuestions.forEach((ques) => {
        if (ques.id === quesId) {
          ques.mcqQuestionOptions.forEach((opt, idx) => {
            if (idx === oIdx) {
              opt.isSelected = true;
            } else {
              opt.isSelected = false;
            }
          });
        }
      });
    }
    this.setState({ mcqTest }, function () {
      let data = {
        mcqTestId: this.state.mcqTest.id,
        isSubmitted: this.state.isSubmitted,
      };
      let peopleMcqRecords = [];
      if (mcqTest.mcqQuestions.length > 0) {
        mcqTest.mcqQuestions.forEach((ques) => {
          ques.mcqQuestionOptions.forEach((opt, idx) => {
            if (opt.isSelected) {
              peopleMcqRecords.push({
                mcqQuestionId: ques.id,
                mcqQuestionOptionId: opt.id,
              });
            }
          });
        });
      }
      data.peopleMcqRecords = peopleMcqRecords;
      axiosPost(URL.insertMcqOption, data, (response) => {});
    });
  };

  submitMcqTest = (e) => {
    let mcqTest = this.state.mcqTest;
    let unanswered = 0;
    if (mcqTest.mcqQuestions.length > 0) {
      mcqTest.mcqQuestions.forEach((ques) => {
        let count = 0;
        ques.mcqQuestionOptions.forEach((opt, idx) => {
          if (opt.isSelected === false) {
            count++;
          }
        });
        if (count === ques.mcqQuestionOptions.length) {
          unanswered++;
        }
      });
    }
    if (unanswered === 0) {
      this.handleSubmit();
    } else {
      swal({
        title: "Warning",
        text: `${unanswered} questions ${
          unanswered === 1 ? "hasn't" : "haven't"
        } been answered yet. Do you want to submit answers anyway?`,
        buttons: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.handleSubmit();
        }
      });
    }
  };

  handleSubmit = () => {
    clearInterval(this.startInterval);
    this.setState({ isSubmitted: true }, function () {
      let data = {
        isSubmitted: this.state.isSubmitted,
        mcqTestId: this.state.mcqTest.id,
        peopleMcqRecords: [],
      };
      axiosPost(URL.insertMcqOption, data, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: "MCQ successfully submitted",
            alloswOutsideClick: false,
            closeOnClickOutside: false,
          }).then(() => {
            let mcqdata = {
              mcqTestId: this.state.mcqTest.id,
              peopleId: localStorage.getItem("profilePeopleId"),
            };
            this.viewStudentResult(null, mcqdata);
          });
          this.props.getMcqList(this.state.mcqType);
          this.setState({ isSubmitted: false, mcqModal: !this.state.mcqModal });
        }
      });
    });
  };

  listTest = (testType) => {
    let testList = [];
    if (testType === "MCQ Without Timer") {
      testList = this.state.practiseTest;
    } else {
      testList = this.state.examTest;
    }
    return (
      <table className="table table-bordered table-striped mb-3">
        <thead className="tt-group-header mcqTableSubHeader">
          <tr>
            <th>MCQ Topic</th>
            <th>Subject</th>
            <th
              width={this.state.mcqType === "my" ? "423px" : "115px"}
              className="text-center"
            >
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          {testList.length > 0 ? (
            testList.map((mcq, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    {mcq.topic}
                    <strong>{` [${mcq.totalMcqQuestions} questions]`}</strong>
                  </td>
                  <td>{mcq.subjectName}</td>
                  <td className="text-center">
                    {mcq.isSubmitted === true ? (
                      mcq.creatorPeopleId ===
                      parseInt(localStorage.getItem("peopleId")) ? null : (
                        <button
                          className="tt-button tt-button-primary"
                          onClick={(e) => this.openResult(e, mcq)}
                        >
                          My Result
                        </button>
                      )
                    ) : (
                      <button
                        className="tt-button tt-button-primary"
                        onClick={(e) => this.startTest(e, mcq.id)}
                      >
                        Start
                      </button>
                    )}
                    {mcq.creatorPeopleId ===
                    parseInt(localStorage.getItem("peopleId")) ? (
                      <button
                        className="tt-button tt-button-primary ml-2 mr-2"
                        onClick={(e) => this.openResult(e, mcq)}
                      >
                        Results
                      </button>
                    ) : null}
                    {this.state.mcqType === "my" ? (
                      <>
                        <button
                          className="tt-button tt-button-primary mr-2"
                          onClick={() => this.handleUpdateModal(mcq.id)}
                        >
                          Update
                        </button>
                        <button
                          className="tt-button tt-button-primary"
                          onClick={() => this.handleDelete(mcq.id)}
                        >
                          Delete
                        </button>
                      </>
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3} className="text-center">
                No data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  //Delete MCQ

  handleDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deleteMCQ);
  };

  deleteMCQ = (datum) => {
    let data = {
      id: datum.id,
    };
    axiosPost(URL.deleteMcq, data, (res) => {
      if (res.status === 200) {
        swal({
          title: "Success",
          text: res.data.message,
          closeOnClickOutside: false,
          alloswOutsideClick: false,
        });
        draggable();
        this.props.getMcqList(this.state.mcqType);
      }
    });
  };

  //updateMCQ

  handleUpdateModal = (id) => {
    let data = {
      id: id,
    };
    axiosPost(URL.getMCQDataForUpdate, data, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState({
            updateData: response.data.data,
            updateModal: !this.state.updateModal,
          });
        } else {
          swal({
            title: "Error",
            text: "Mcq Test has been started. So, unable to update.",
            alloswOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
        }
      }
    });
  };

  toggleUpdateModal = () => {
    this.setState({ updateModal: !this.state.updateModal, updateData: "" });
  };

  toggleCollapsible = (e, idx) => {
    let mcq = this.state.mcq;
    if (mcq[idx].open === true) {
      mcq.forEach((el) => {
        el.open = false;
      });
    } else {
      mcq.forEach((el) => {
        el.open = false;
      });
      mcq[idx].open = true;
    }
    this.setState({ mcq });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, function () {
      this.props.getMcqList(this.state.mcqType);
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          List of Multiple Choice Questions
          {checkWidgetAdminOrNot("Teaching Tools") ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="row mb-3">
          <div className="col-md-4"></div>
          <div className="col-md-2 mt-1">
            <strong>Select MCQ type: </strong>
          </div>
          <div className="col-md-2">
            <select
              className="form-control"
              name="mcqType"
              value={this.state.mcqType}
              onChange={this.handleChange}
            >
              <option value="my">My MCQ List</option>
              <option value="public">Public MCQ List</option>
            </select>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="" style={{ maxHeight: "69.6vh", overflow: "auto" }}>
          {this.state.mcq.map((mcqTestType, idx) => {
            return (
              <div className="card-body" key={idx}>
                <div className="container-fluid">
                  <div
                    className="row tt-group-header"
                    onClick={(e) => this.toggleCollapsible(e, idx)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="col-md-11">{mcqTestType.name}</div>
                    <div className="col-md-1 text-center">
                      <FontAwesomeIcon
                        icon={mcqTestType.open ? faAngleUp : faAngleDown}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                  <CustomCollapse
                    isOpen={mcqTestType.open}
                    collapsibleBody={this.listTest(mcqTestType.name)}
                  ></CustomCollapse>
                </div>
              </div>
            );
          })}
        </div>

        <ModalWindow
          modal={this.state.mcqModal}
          toggleModal={this.toggleMcqModal}
          size="xl"
          modalHeader="Teaching Tool"
          modalBody={
            <McqTestTemplate
              mcqTest={this.state.mcqTest}
              startTimerTest={this.startTimerTest}
              minute={this.state.minute}
              seconds={this.state.seconds}
              handleOptionClick={this.handleOptionClick}
              isSubmitted={this.state.isSubmitted}
              submitMcqTest={this.submitMcqTest}
              toggleMcqModal={this.toggleMcqModal}
            ></McqTestTemplate>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.mcqResultModal}
          toggleModal={this.toggleMcqResultModal}
          size="xl"
          modalHeader={this.state.resultHeader}
          modalBody={
            <McqResultTemplate
              mcqResult={this.state.mcqResult}
              correctAnswer={this.state.correctAnswer}
              unanswered={this.state.unanswered}
              incorrect={this.state.incorrect}
              totalAnswered={this.state.totalAnswered}
              fullMarks={this.state.fullMarks}
              obtainedMarks={this.state.obtainedMarks}
            ></McqResultTemplate>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.mcqStudentListModal}
          toggleModal={this.toggleMcqStudentListModal}
          size="lg"
          modalHeader={this.state.resultHeader}
          modalBody={
            <McqStudentList
              mcqStudentList={this.state.mcqStudentList}
              viewStudentResult={this.viewStudentResult}
            ></McqStudentList>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.updateModal}
          toggleModal={this.toggleUpdateModal}
          fullWidth={true}
          modalHeader="Update MCQ"
          modalBody={
            <CreateMCQ
              updateData={this.state.updateData}
              toggleUpdateModal={this.toggleUpdateModal}
              update={true}
            ></CreateMCQ>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Teaching Tools"
              moduleName="MCQ"
              header="List MCQ configuration"
              activityName="list-mcq"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  practiseTest: state.mcq.practiseTest,
  examTest: state.mcq.examTest,
});

const mapActionsToProps = {
  getMcqList,
};

export default connect(mapStateToProps, mapActionsToProps)(ListMCQ);
