import React, { Component } from "react";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import { TextField } from "../../UI/TextField";
import { SubjectValidationSchema } from "./subjectValidationSchema";
import { displayErrorAlert } from "../../utils/Utils";
import { validateFile } from "../../components/Validation/Validation";

export class SubjectForm extends Component {
  state = {
    // classSubjectForm: {
    subjectForms: [
      {
        name: "",
        description: "",
        image: "",
      },
    ],
    // },
    // classes: [],
    files: [],
    error: false,
    showSpinner: false,
  };

  addsubjectForm = () => {
    let subjectForm = [...this.state.subjectForms];
    subjectForm.push({
      name: "",
      description: "",
      image: "",
    });
    this.setState({
      subjectForms: subjectForm,
      error: false,
    });
  };

  removesubjectForm = (idx) => {
    let subjectForms = [...this.state.subjectForms];
    let files = [...this.state.files];

    files.splice(idx, 1);
    subjectForms.splice(idx, 1);
    this.setState({
      subjectForms,
      error: false,
      files,
    });
  };

  handleChange = (e, idx) => {
    let subjectForms = this.state.subjectForms;
    let name = e.target.name;
    let value = e.target.value;
    subjectForms[idx][name] = value;
    this.setState({ subjectForms });
  };

  handleChangeImage = (e, idx) => {
    let extensions = ["png", "jpeg", "jpg"];
    let validationResponse = validateFile(e.target.value, extensions);
    if (!validationResponse[0]) {
      swal("Error", validationResponse[1]);
      return;
    }
    let subjectForms = this.state.subjectForms;
    let files = this.state.files;
    if (e.target.files.length > 0) {
      // subjectForms[idx]["photo"] = e.target.value;
      subjectForms[idx]["image"] = e.target.files[0];
      // subjectForms[idx]["photoPreview"] = window.URL.createObjectURL(
      //   e.target.files[0]
      // );
      this.setState({ subjectForms });
      files[idx] = e.target.files[0];
      this.setState({ files });
    }
  };

  handleSave = () => {
    let error = false;
    let subjectForms = [...this.state.subjectForms];
    let param = [];
    subjectForms.forEach((subjectForm, index) => {
      if (
        subjectForm.image == "" ||
        subjectForm.name == "" ||
        subjectForm.description == ""
      ) {
        error = true;
        this.setState({ error: true });
      }
      param[index] = {
        name: subjectForm.name,
        description: subjectForm.description,
      };
    });

    let formData = new FormData();

    formData.append("jsonData", JSON.stringify(param));
    this.state.files.forEach((file) => {
      formData.append("file", file);
    });

    if (error === false) {
      this.setState(
        {
          showSpinner: true,
        },
        () => {
          axiosPost(
            URL.insertSubject,
            formData,
            (response) => {
              if (response.status === 200) {
                this.props.getAllSubjects();
                this.setState({
                  subjectForms: [{ name: "", description: "", image: "" }],
                  showSpinner: false,
                  error: false,
                });
                swal("Success", response.data.message);
                this.props.toggleModal();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                showSpinner: false,
              });
            }
          );
        }
      );
    }
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}
        <div className="row">
          {/* <Formik
            initialValues={this.state.classSubjectForm}
            validationSchema={SubjectValidationSchema}
            onSubmit={(values) => this.handleSave(values)}
          >
            {({ values }) => {
              return (
                <div className="ml-2">
                  <Form>
                    <FieldArray
                      name="subjectForms"
                      render={({ remove, push }) => {
                        return (
                          <>
                            {values.subjectForms.length > 0 &&
                              values.subjectForms.map((value, index) => (
                                <div className="row" key={index}>
                                  <div className="col-md-3">
                                    <TextField
                                      name={`subjectForms.${index}.name`}
                                      type="text"
                                      placeholder="Enter name here"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <TextField
                                      name={`subjectForms.${index}.description`}
                                      type="text"
                                      placeholder="Enter description here"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <TextField
                                      name={`subjectForms.${index}.image`}
                                      type="file"
                                      // placeholder="Enter description here"
                                    />
                                   
                                  </div>
                                  <div className="col-lg-2 mt-1">
                                    {index > 0 ? (
                                      <span
                                        className="material-icons-round text-danger pointer"
                                        style={{ cursor: "pointer" }}
                                        aria-hidden="true"
                                        onClick={e => remove(index)}
                                      >
                                        remove_circle
                                      </span>
                                    ) : null}
                                    <span
                                      className="material-icons-round text-success pointer"
                                      style={{ cursor: "pointer" }}
                                      aria-hidden="true"
                                      onClick={() =>
                                        push({
                                          name: "",
                                          description: "",
                                          image: "",
                                        })
                                      }
                                    >
                                      add_circle
                                    </span>
                                  </div>
                                </div>
                              ))}
                          </>
                        );
                      }}
                    />

                  
                  </Form>
                </div>
              );
            }}
          </Formik> */}

          {this.state.subjectForms?.length > 0
            ? this.state.subjectForms?.map((subjectForm, index) => {
                return (
                  <>
                    <div className="col-md-3 mt-3">
                      <input
                        className={`${
                          this.state.error && subjectForm.name == ""
                            ? "form-control cusInput border border-danger"
                            : "form-control cusInput"
                        }`}
                        name="name"
                        type="text"
                        onChange={(e) => this.handleChange(e, index)}
                        value={subjectForm.name}
                        placeholder="Enter subject name here"
                      />
                      {this.state.error && subjectForm.name == "" ? (
                        <small className="errorMsg-textField">
                          Name is required
                        </small>
                      ) : null}
                    </div>
                    <div className="col-md-3 mt-3">
                      <input
                        className={`${
                          this.state.error && subjectForm.name == ""
                            ? "form-control cusInput border border-danger"
                            : "form-control cusInput"
                        }`}
                        name="description"
                        type="text"
                        onChange={(e) => this.handleChange(e, index)}
                        value={subjectForm.description}
                        placeholder="Enter subject description here"
                      />
                      {this.state.error && subjectForm.description == "" ? (
                        <small className="errorMsg-textField">
                          Description is required
                        </small>
                      ) : null}
                    </div>
                    <div className="col-md-4 mt-3">
                      <input
                        className={`${
                          this.state.error && subjectForm.image == ""
                            ? "form-control cusInput border border-danger"
                            : "form-control cusInput"
                        }`}
                        name="image"
                        type="file"
                        onChange={(e) => this.handleChangeImage(e, index)}
                      />
                      {this.state.error && subjectForm.image == "" ? (
                        <small className="errorMsg-textField">
                          Image is required
                        </small>
                      ) : null}
                    </div>
                    <div className="col-lg-2 mt-3">
                      {index > 0 ? (
                        <span
                          className="material-icons-round text-danger"
                          aria-hidden="true"
                          onClick={(e) => this.removesubjectForm(index)}
                        >
                          remove_circle
                        </span>
                      ) : null}
                      {index === this.state.subjectForms.length - 1 ? (
                        <span
                          className="material-icons-round text-success"
                          aria-hidden="true"
                          onClick={this.addsubjectForm}
                        >
                          add_circle
                        </span>
                      ) : null}
                    </div>
                  </>
                );
              })
            : null}

          <div className="col text-right mt-2">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSave}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default SubjectForm;
