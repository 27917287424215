import React, { Component } from "react";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";

class ChangeAcademicYear extends Component {
  state = {
    systemDefaultAcYr: null,
    currentAcYrId: "",
    acYrList: null,
    currentAcYrName: "",
  };
  componentDidMount() {
    this.getUserCurrentAcademicYear();
    this.getEnabledAcademicYear();
  }
  getUserCurrentAcademicYear = () => {
    axiosPost(URL.selectUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState(
            {
              currentAcYrId: response.data.data.academicYearId,
              currentAcYrName: response.data.data.academicYearName,
            },
            function () {
              this.formatAcademicYearName();
            }
          );
        } else {
          this.getCurrentAcademicYear();
        }
      }
    });
  };
  handleChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;

    this.setState({ [name]: value });
    // this.selectByKey(value);
  };
  selectByKey = (id) => {
    let data = {
      id: id,
    };
    axiosPost(URL.getAcademicYearByKey, data, (response) => {
      if (response.status === 200) {
        this.setState(
          { currentAcYrName: response.data.data.name },
          function () {
            this.formatAcademicYearName();
          }
        );
        // displayMessage("Success", "Your Academic Year has been switched to " + this.state.currentAcYrName)
        this.props.toggleChangeAcYrModal();
      }
    });
  };

  handleApplyBtn = (e) => {
    let data = {
      academicYearId: this.state.currentAcYrId,
    };
    axiosPost(URL.switchUserAcademicYear, data, (response) => {
      if (response.status === 200) {
        this.selectByKey(this.state.currentAcYrId);
        // refresh the current page you are in
        window.location.reload(false);
      }
    });
  };

  getCurrentAcademicYear = () => {
    let data = {
      isCurrent: "true",
    };

    // axiosPost(URL.getAcademicYears, data, (response) => {
    axiosPost(URL.selectCurrentAcademicYear, data, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            // systemDefaultAcYr: response.data.data[0],
            // currentAcYrId: response.data.data[0].id,
            // currentAcYrName: response.data.data[0].name,
            systemDefaultAcYr: response.data.data,
            currentAcYrId: response.data.data.id,
            currentAcYrName: response.data.data.name,
          },
          function () {
            this.formatAcademicYearName();
          }
        );
      }
    });
  };

  formatAcademicYearName = () => {
    let name = this.state.currentAcYrName;
    name = name.replace(/_/g, "-");
    this.setState({ currentAcYrName: name });
  };

  getEnabledAcademicYear = () => {
    let data = {
      isEnabled: "true",
    };
    // axiosPost(URL.getAcademicYears, data, (response) => {
    axiosPost(URL.getAllAcademicYears, data, (response) => {
      if (response.status === 200) {
        let acYrList = response.data.data;
        if (acYrList.length > 0) {
          acYrList.forEach((el) => {
            el.name = el.name.replace(/_/g, "-");
          });
        }
        this.setState({ acYrList: response.data.data });
      }
    });
  };

  render() {
    return (
      <>
        <div className="container mt-4 mb-4">
          <strong>CURRENT ACADEMIC YEAR : {this.state.currentAcYrName}</strong>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="currentAcYrId">
                <strong>Work on another academic year : </strong>
              </label>
            </div>
            <div className="col-md-8">
              <select
                onChange={this.handleChange}
                name="currentAcYrId"
                id="currentAcYrId"
                value={this.state.currentAcYrId}
                className="form-control ttedu-level-dropdown"
              >
                <option value="">Select Academic Year</option>
                {this.state.acYrList
                  ? this.state.acYrList.map((acYrList, idx) => (
                      <option key={idx} value={acYrList.id}>
                        {/* {this.state.systemDefaultAcYr
                          ? this.state.systemDefaultAcYr.id === acYrList.id */}
                        {acYrList.isCurrent
                          ? acYrList.name + " [ System Default Current ]"
                          : acYrList.name}
                        {/* : acYrList.name} */}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row tt-group-header mb-0"
            style={{ marginRight: "-22px", marginLeft: "-22px" }}
          >
            <div className="col">
              <div className="text-right">
                <button
                  className="tt-button tt-button-primary "
                  onClick={this.handleApplyBtn}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ChangeAcademicYear;
