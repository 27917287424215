import React, { Component } from "react";
import "../../../assets/scss/template.scss";
import "../../../assets/scss/noticeDisplay.scss";
import ReadMoreTemplate from "../../Profile/Templates/ReadMoreTemplate";
import avatar from "../../../assets/images/user-image.png";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  postType,
  reactServer,
  userRoleCode,
  checkRolePermissions,
  draggable,
  userRole,
} from "../../../utils/Constants";
import ModalWindow from "../../UI/ModalWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import MediaAcademicRepoEditModal from "./MediaAcademicRepoEditModal";
import { Link } from "react-router-dom";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  displayNepaliDate,
  handleError,
} from "../../../utils/Utils";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import swal from "sweetalert";
import { Spinner } from "reactstrap";

import HistoryTemplates from "./HistoryTemplates/HistoryTemplates";
import {
  getGroupPosts,
  getPersonProfilePosts,
  getFamilyPosts,
  getPersonPostSectionAcademicRepoPosts,
  getGroupNotices,
  getFamilyNotices,
  getNotices,
  getPosts,
} from "../../../ducks/PostsDucks";
import { connect } from "react-redux";
import {
  youtubeLink,
  dailyMotionLink,
  vimeoLink,
  slideshareLink,
  defaultLinkURL,
  getYoutubeEmbedLink,
  getDailyMotionEmbedLink,
  getSoundcloudEmbedLink,
  getVimeoEmbedLink,
  getSlideShareEmbedLink,
} from "../../../utils/VideoURL";
class MediaTemplate extends Component {
  state = {
    modal: false,
    commentValue: "",
    comments: [],
    newCommentValue: "",
    slideShareURl: "",
    keywordModal: false,
    linkCopied: false,
    referenceId: null,
    post: null,
    dropdownOpen: false,
    editArticleAcademicModal: false,
    selectedPost: "",
    subjectList: [],
    showSpinner: false,
    mediaHistoryModal: false,
    history: false,
    postHistory: [],
  };

  toggleMenu = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleMediaEditModal = (e, data, action) => {
    if (action === "open") {
      this.getSubjectList();
      this.setState({ selectedPost: data });
    } else {
      this.setState({
        editArticleAcademicModal: !this.state.editArticleAcademicModal,
      });
    }
  };

  toggleHistoryEditModal = (e, data, action) => {
    if (action === "open") {
      this.getHistoryList(data);
      this.setState({ selectedPost: data });
    } else {
      this.setState({
        mediaHistoryModal: !this.state.mediaHistoryModal,
        history: false,
      });
    }
  };

  getHistoryList = (param) => {
    let data = {
      updatedId: param.id,
    };
    this.setState({ history: true, showSpinner: true }, function () {
      axiosPost(
        URL.getPostHistory,
        data,
        (response) => {
          this.setState({ showSpinner: false });
          if (response.status === 200) {
            this.setState({ postHistory: response.data.data }, function () {
              this.setState({
                mediaHistoryModal: !this.state.mediaHistoryModal,
              });
            });
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
        }
      );
    });
  };

  getSubjectList = () => {
    axiosPost(URL.subjectList, {}, (response) => {
      if (response.status === 200 || response.status === 204) {
        this.setState({ subjectList: response.data.data }, function () {
          this.setState({
            editArticleAcademicModal: !this.state.editArticleAcademicModal,
          });
        });
      }
    });
  };

  componentDidMount() {
    if (
      this.props.match !== undefined &&
      this.props.match.path === "/post/media/:referenceId"
    ) {
      let endPoint;
      let data;
      if (this.props.match.params.referenceId.slice(-4) === "true") {
        endPoint = URL.getAcademicRepoByRefId;
        data = {
          postType: postType.mediaCode,
          referenceId: this.props.match.params.referenceId.slice(0, -4),
        };
      } else {
        endPoint = URL.getPostByReferenceId;
        data = {
          postType: postType.mediaCode,
          referenceId: this.props.match.params.referenceId,
        };
      }
      axiosPost(endPoint, data, (response) => {
        if (response.status === 200) {
          //let newPosts = [...response.data.data];
          let newPosts = [];
          if (this.props.match.params.referenceId.slice(-4) === "true") {
            const obj = response.data.data[0];
            newPosts.push({ ...obj });
          } else {
            newPosts.push({ ...response.data.data });
          }
          newPosts.forEach((post) => {
            if (
              post.postType === postType.articleCode ||
              post.postType === postType.mediaCode
            ) {
              post.contents.forEach((content) => {
                if (
                  content.contentTypeCode === "LNK" &&
                  content.content !== ""
                ) {
                  // let videoId = "";
                  if (
                    content.content.includes("youtube") ||
                    content.content.includes("youtu.be")
                  ) {
                    let contentObj = youtubeLink(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  } else if (
                    content.content.includes("dailymotion") ||
                    content.content.includes("dai.ly")
                  ) {
                    let contentObj = dailyMotionLink(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  } else if (content.content.includes("vimeo")) {
                    let contentObj = vimeoLink(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  } else if (
                    content.content.includes("slideshare") &&
                    content.youtubeTitle !== undefined
                  ) {
                    let contentObj = slideshareLink(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  } else {
                    let contentObj = defaultLinkURL(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  }
                }
              });
            }
          });

          this.setState({ post: newPosts[0] }, function () {
            if (this.state.post && this.state.post.contents.length > 0) {
              this.state.post.contents.forEach((el) => {
                if (el.content.includes(reactServer)) {
                  this.getInsitePostDataURL(el);
                }
              });
            }
          });
        }
      });
    }
    if (this.props.post && this.props.post.contents.length > 0) {
      this.props.post.contents.forEach((el) => {
        if (el.content.includes(reactServer)) {
          this.getInsitePostData(el);
        }
      });
    }

    this.getComments();
  }

  getInsitePostDataURL = (el) => {
    if (el.origin === "insitePost") {
      if (el.content !== null || el.content !== "") {
        let data = {
          postType: el.content
            ? el.content.split("://")[1].split("/")[2] === "media"
              ? postType.mediaCode
              : postType.articleCode
            : null,
          referenceId: el.content
            ? el.content.split("://")[1].split("/")[3].trim()
            : null,
        };
        axiosPost(URL.getPostByReferenceId, data, (response) => {
          if (response.status === 200) {
            if (response.data.data) {
              this.setState({
                insertedInsitePostData: response.data.data,
              });
            }
          }
        });
      }
    }
  };

  getInsitePostData = (el) => {
    if (el.origin === "insitePost") {
      if (el.content !== null || el.content !== "") {
        let data = {
          postType: el.content
            ? el.content.split("://")[1].split("/")[2] === "media"
              ? postType.mediaCode
              : postType.articleCode
            : null,
          referenceId: el.content
            ? el.content.split("://")[1].split("/")[3].trim()
            : null,
        };
        axiosPost(URL.getPostByReferenceId, data, (response) => {
          if (response.status === 200) {
            if (response.data.data) {
              this.setState({
                insertedInsitePostData: response.data.data,
              });
            }
          }
        });
      }
    }
  };

  toggleModal = (e, action) => {
    if (action === "open") {
      //   this.setState({ notice: notice });
    }
    this.setState({ modal: !this.state.modal });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.type === "checked" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  toggleEditable = (idx) => {
    let comments = [...this.state.comments];
    comments.forEach((el) => {
      el.editable = false;
    });
    comments[idx].editable = true;
    this.setState({
      comments: comments,
      newCommentValue: comments[idx].commentValue,
    });
  };

  getComments = () => {
    let url = "",
      param = {};
    if (this.state.post && this.state.post.id !== undefined) {
      if (this.props.isAcademicRepo) {
        url = URL.getAcademicRepoComment;
        param = {
          academicRepoId: this.state.post.id,
        };
      } else {
        url = URL.getCommentByKey;
        param = { postId: this.state.post.id };
      }
      axiosPost(url, param, (response) => {
        if (response.status === 200) {
          let comments = response.data.data;
          comments.forEach((el) => {
            el.editable = false;
            el.ownPost =
              parseInt(localStorage.getItem("peopleId")) === el.peopleId
                ? true
                : false;
          });
          this.setState({ comments });
        }
      });
    } else {
      if (this.props.post && this.props.post.id !== undefined) {
        if (this.props.isAcademicRepo) {
          url = URL.getAcademicRepoComment;
          param = {
            academicRepoId: this.props.post.id,
          };
        } else {
          url = URL.getCommentByKey;
          param = { postId: this.props.post.id };
        }
        axiosPost(url, param, (response) => {
          if (response.status === 200) {
            let comments = response.data.data;
            comments.forEach((el) => {
              el.editable = false;
              el.ownPost =
                parseInt(localStorage.getItem("peopleId")) === el.peopleId
                  ? true
                  : false;
            });
            this.setState({ comments });
          }
        });
      }
    }
  };

  deleteComment = (id) => {
    let url = "";
    if (this.props.isAcademicRepo) {
      url = URL.deleteAcademicRepoComment;
    } else {
      url = URL.deleteComment;
    }
    axiosPost(
      url,
      {
        id: id,
        deleteStatus: true,
      },
      (response) => {
        if (response.status === 200) {
          this.getComments();
        }
      }
    );
  };
  updateComment = (commentValue, comment) => {
    let url = "",
      param = {};

    if (this.props.isAcademicRepo) {
      url = URL.updateAcademicRepo;
      param = {
        academicRepoId: this.state.post
          ? this.state.post.id
          : this.props.post.id,
        commentValue: this.state.newCommentValue,
        contentTypeCode: comment.contentTypeCode,
        id: comment.id,
        peopleId: comment.peopleId,
        moderatedStatus: comment.moderatedStatus,
        moderatorPeopleId: comment.moderatorPeopleId,
      };
    } else {
      url = URL.updateComment;
      param = {
        id: comment.id,
        contentTypeCode: comment.contentTypeCode,
        postId: comment.postId,
        peopleId: comment.peopleId,
        commentValue: this.state.newCommentValue,
      };
    }
    axiosPost(url, param, (response) => {
      if (response.status === 200) {
        this.getComments();
      }
    });
  };

  //For Notice Reload
  callNoticeFunctions = () => {
    if (this.props.groupPage) {
      this.groupNoticeConfig();
    } else if (this.props.familyPage) {
      this.familyNoticeConfig();
    } else {
      this.personalNoticeConfig();
    }
  };
  personalNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      if (!this.props.username) {
        param.peopleId = localStorage.getItem("peopleId");
        this.props.getNotices(param);
      } else {
        let data = {
          username: this.props.username,
        };

        axiosPost(URL.getLoggedInUser, data, (response) => {
          if (response.status === 200) {
            param.peopleId = response.data.data.peopleId;
            this.props.getNotices(param);
          }
        });
      }
    } else {
      if (userRole === userRoleCode.roleStudentContact) {
        axiosPost(
          URL.selectFamilyMembers,
          { familyId: localStorage.getItem("familyId") },
          (response) => {
            if (response.status === 200) {
              let profilePeopleId = this.props.profilePeopleId;
              let count = 0;
              if (response.data.data.length > 0) {
                response.data.data.forEach((el) => {
                  if (el.peopleId === parseInt(profilePeopleId)) {
                    count++;
                  }
                });
              }
              if (count > 0) {
                param.peopleId = profilePeopleId;
                this.props.getNotices(param);
              }
            }
          }
        );
      } else {
        param.peopleId = localStorage.getItem("peopleId");
        this.props.getNotices(param);
      }
    }
  };
  groupNoticeConfig = () => {
    let initialData = this.props.groupName;
    let groupName = initialData.replace(/_/g, " ");
    let param = {
      groupName: groupName,
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    this.props.getGroupNotices(param);
  };

  familyNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: new Date(),
    };
    let familyId = this.props.familyId;
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      param.familyId = familyId;
      this.props.getFamilyNotices(param);
    } else {
      this.getFamilyDetails(param, familyId);
    }
  };

  getFamilyDetails = (param, familyId) => {
    let data = {
      familyId: familyId,
    };
    let count = 0;
    axiosPost(URL.selectFamilyMembers, data, (response) => {
      if (response.status === 200) {
        let peopleId = localStorage.getItem("peopleId");
        let datas = response.data.data;
        if (datas.length > 0) {
          datas.forEach((el) => {
            if (el.peopleId === parseInt(peopleId)) {
              count++;
            }
          });
        }
        if (count === 0) {
          param.peopleId = localStorage.getItem("peopleId");
          this.props.getNotices(param);
        } else {
          param.familyId = familyId;
          this.props.getFamilyNotices(param);
        }
      }
    });
  };

  postComment = (id, isAcademicRepo) => {
    let url = "";
    let param = {};
    if (isAcademicRepo) {
      url = URL.insertAcademicRepoComment;
      param = {
        academicRepoId: id,
        commentValue: this.state.commentValue,
        contentTypeCode: "TXT",
        moduleCode: "post-cmnt-section",
      };
    } else {
      url = URL.postComment;
      param = {
        postId: id,
        commentValue: this.state.commentValue,
        contentTypeCode: "TXT",
        moduleCode: "post-cmnt-section",
      };
    }
    axiosPost(url, param, (response) => {
      if (response.status === 200) {
        if (response.data.data.moderatedStatus === "PENDING") {
          swal(MESSAGEHEADER.success, "Post comment sent for moderation", "");
        } else {
          swal({
            title: MESSAGEHEADER.success,
            text: "Successfully Commented",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
        }
        // this.callNoticeFunctions();
        this.getComments(id);
        this.setState({ commentValue: "" });
      }
    });
  };

  noticeModalFooter = () => {
    return (
      <div className="row">
        <div className="col-md-2 text-right mt-1">
          <strong>Add a comment</strong>
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            name="commentValue"
            value={this.state.commentValue}
            onChange={this.handleChange}
          ></input>
        </div>
        <div className="col-md-2" style={{ marginTop: "3px" }}>
          <button
            className="tt-button tt-button-primary"
            onClick={(e) =>
              this.postComment(
                this.state.post ? this.state.post.id : this.props.post.id,
                this.props.isAcademicRepo
              )
            }
          >
            Comment
          </button>
        </div>
      </div>
    );
  };

  getEmbedLink = (link) => {
    let embeddedURL = "";
    if (link.includes("youtube") || link.includes("youtu.be")) {
      embeddedURL = getYoutubeEmbedLink(link);
    } else if (link.includes("dailymotion") || link.includes("dai.ly")) {
      embeddedURL = getDailyMotionEmbedLink(link);
    } else if (link.includes("vimeo")) {
      embeddedURL = getVimeoEmbedLink(link);
    } else if (link.includes("slideshare")) {
      if (this.state.slideShareURl === "") {
        let src = getSlideShareEmbedLink(link);
        this.setState({ slideShareURl: src });
      }
      embeddedURL = this.state.slideShareURl;
    } else if (link.includes("soundcloud.com")) {
      embeddedURL = getSoundcloudEmbedLink(link);
    }
    return embeddedURL;
  };

  displayKeywordModal = () => {
    this.setState({ keywordModal: !this.state.keywordModal });
  };

  keywordModalBody = () => {
    return (
      <ol>
        {this.props.insitePost
          ? this.props.insertedInsitePostData.postKeywords
            ? this.props.insertedInsitePostData.postKeywords.map(
                (keyword, idx) => {
                  return <li key={idx}>{keyword.keyword}</li>;
                }
              )
            : null
          : this.props.post
          ? this.props.post.postKeywords
            ? this.props.post.postKeywords.map((keyword, idx) => {
                return <li key={idx}>{keyword.keyword}</li>;
              })
            : null
          : null}
      </ol>
    );
  };

  renderLinkCopyText = () => {
    this.setState({ linkCopied: true });
    setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 1500);
  };

  renderForReferenceId = () => (
    <div className="container-fluid mt-3">
      <div className="row">
        <div
          className={
            "col-md-auto col-sm-auto mobilePPimage text-center " +
            (this.props.fullPage ? "fullpagePP" : "")
          }
        >
          {this.state.post ? (
            this.state.post.peoples ? (
              this.state.post.peoples.photo ? (
                <img
                  src={URL.imageSelectURL + this.state.post.peoples.photo}
                  onError={(e) => handleError(e)}
                  alt="media"
                  className={
                    "img-postDisplay " +
                    (this.props.fullPage
                      ? "imageNewDimension"
                      : "image-dimension")
                  }
                ></img>
              ) : (
                <img
                  src={avatar}
                  className={
                    "img-fluid " +
                    (this.props.fullPage
                      ? "imageNewDimension"
                      : "image-dimension")
                  }
                  alt="pp"
                ></img>
              )
            ) : (
              <img
                src={avatar}
                alt="ppNp"
                className={
                  "img-fluid " +
                  (this.props.fullPage
                    ? "imageNewDimension"
                    : "image-dimension")
                }
              ></img>
            )
          ) : (
            <img
              src={avatar}
              alt="avatar"
              className={
                "img-fluid " +
                (this.props.fullPage ? "imageNewDimension" : "image-dimension")
              }
            ></img>
          )}
        </div>
        <div className="col">
          <div className="row">
            <p className="mb-0" style={{ lineHeight: "20px" }}>
              <strong>
                {this.state.post ? (
                  this.state.post.peoples ? (
                    this.state.post.peoples.userRole ===
                    userRoleCode.roleStudentContact ? (
                      <Link
                        to={{
                          pathname: `/familyPage/${this.state.post.peoples.familyId}`,
                          familyPageOpen: true,
                          userId: this.state.post.peoples.userId,
                        }}
                      >
                        {this.state.post.peoples.name}
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: `/profile/${this.state.post.peoples.username}`,
                          username: this.state.post.peoples.username,
                        }}
                      >
                        {this.state.post.peoples.name}
                      </Link>
                    )
                  ) : (
                    "Anonymous"
                  )
                ) : (
                  "Anonymous"
                )}
                {this.state.post ? (
                  this.state.post.groupName ? (
                    <>
                      &nbsp;&#62;{" "}
                      <Link
                        to={{
                          pathname: `/groupPage/${this.state.post.groupName.replace(
                            / /g,
                            "_"
                          )}`,
                        }}
                      >
                        {this.state.post.groupName}
                      </Link>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}{" "}
              </strong>
            </p>
          </div>
          <div className="row">
            {this.state.post ? (
              <>
                <span className="text-muted date-displayPosts">
                  <i>
                    posted {moment(this.state.post.createdAt).calendar()}
                    {displayNepaliDate(this.state.post.createdAt)}(
                    {moment(this.state.post.createdAt).fromNow()})
                  </i>
                </span>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="tt-mobilePostKeywords">
            {this.state.post ? (
              this.state.post.postKeywords ? (
                <div className="row mb-3 mt-2">
                  <div className="tt-postTargetList">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={this.displayKeywordModal}
                    >
                      Keywords Assigned
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>

          <div className="tt-desktopPostKeywords">
            {this.state.post ? (
              this.state.post.postKeywords ? (
                <div className="row mb-3 mt-2">
                  <div className="tt-postTargetList">
                    {this.state.post.postKeywords.map((keyWord, idx) => {
                      return idx <= 2 ? (
                        keyWord.keyword !== "" ? (
                          <div key={idx}>{keyWord.keyword}</div>
                        ) : null
                      ) : idx === this.state.post.postKeywords.length - 1 ? (
                        <div
                          key={idx}
                          style={{ cursor: "pointer" }}
                          onClick={this.displayKeywordModal}
                        >
                          + {this.state.post.postKeywords.length - 3} more
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : null
            ) : null}
          </div>

          <div className="row mb-2">
            {this.state.post
              ? this.state.post.contents.map((content, idx) => {
                  return content.content !== "" ? (
                    content.contentTypeCode === "IMG" ? (
                      <>
                        {this.state.post.contents.length === 1 || idx === 0 ? (
                          <img
                            src={URL.imageSelectURL + content.content}
                            alt="mediaOP"
                            className="mediaImg"
                            key={idx}
                          />
                        ) : idx === this.state.post.contents.length - 1 ? (
                          <div
                            className="tt-moreImageHolder"
                            key={idx}
                            onClick={(e) => this.toggleModal(e, "open")}
                          >
                            + {this.state.post.contents.length - 1} more
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <div className="mobileMedia">
                        <iframe
                          key={idx}
                          title="previewURL"
                          src={this.getEmbedLink(content.content)}
                          frameBorder="1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    )
                  ) : (
                    ""
                  );
                })
              : ""}
          </div>
          {this.state.post ? (
            this.state.post.textContent &&
            this.state.post.textContent !== "" ? (
              <div className="row">
                <div className="text pl-2">
                  {ReactHtmlParser(
                    this.state.post
                      ? this.renderContent(this.state.post.textContent)
                      : "No text content"
                  )}
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-md-5"></div>
        <div className="col-md-7 text-right" style={{ paddingLeft: "11px" }}>
          <button
            className="tt-button tt-button-primary my-2"
            onClick={(e) => this.toggleModal(e, "open")}
          >
            Read More
          </button>
        </div>
      </div>
      <ModalWindow
        fullWidth={true}
        modal={this.state.modal}
        toggleModal={this.toggleModal}
        modalClass="tt-noticeModal"
        modalHeader={
          <>
            <FontAwesomeIcon icon={faPhotoVideo}></FontAwesomeIcon>{" "}
            {this.state.post
              ? this.state.post.postType === postType.mediaCode
                ? this.state.post.heading + " [Media]"
                : this.state.post.heading
              : "No heading"}
          </>
        }
        modalBody={
          <ReadMoreTemplate
            post={this.state.post}
            insertedInsitePostData={this.props.insertedInsitePostData}
            comments={this.state.comments}
            deleteComment={this.deleteComment}
            toggleEditable={this.toggleEditable}
            handleChange={this.handleChange}
            newCommentValue={this.state.newCommentValue}
            updateComment={this.updateComment}
          />
        }
        modalFooter={this.noticeModalFooter()}
      ></ModalWindow>
      <ModalWindow
        modal={this.state.keywordModal}
        toggleModal={this.displayKeywordModal}
        modalHeader="Keywords Assigned"
        modalBody={this.keywordModalBody()}
      ></ModalWindow>
    </div>
  );

  renderContent = (content) => {
    let newData = content;
    var imgRegex = new RegExp("<img[^>]*?>", "g");
    let matchedData = newData.match(imgRegex);
    if (matchedData !== null) {
      for (let i = 0; i < matchedData.length; i++) {
        newData = newData.replace(matchedData[i], "");
      }
    }
    return newData;
  };

  copyToClipboard = (data) => {
    let dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("value", data);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  //Updating post
  updatePost = (e, data, selectedImages) => {
    let newImages = [];
    let oldImages = [];
    if (selectedImages.length > 0) {
      selectedImages.forEach((el) => {
        if (el.updatedData) {
          oldImages.push(el);
        } else {
          newImages.push(el);
        }
      });
      if (oldImages.length > 0) {
        oldImages.forEach((ol) => {
          data.post.contents.push(ol);
        });
      }
    }
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.updatePost,
        data,
        (response) => {
          if (response.status === 200) {
            if (newImages.length > 0) {
              let formData = new FormData();

              for (const key of Object.keys(newImages)) {
                formData.append("content", newImages[key]);
              }
              formData.append(
                "jsonData",
                JSON.stringify({
                  origin: "myPC",
                  postId: response.data.data.postId,
                })
              );

              axiosPost(
                URL.insertPostContent,
                formData,
                (response) => {
                  if (response.status === 200) {
                    swal({
                      title: MESSAGEHEADER.success,
                      text: "Updated successfully",
                      allowOutsideClick: false,
                      closeOnClickOutside: false,
                    });
                    draggable();
                    this.setState({ showSpinner: false });
                    this.props.handleCurrentPost();
                    this.toggleMediaEditModal();
                  }
                },
                (error) => {
                  this.setState({ showSpinner: false });
                  displayErrorAlert(error);
                }
              );
            } else {
              this.setState({ showSpinner: false });
              swal({
                title: MESSAGEHEADER.success,
                text: "Updated successfully",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.props.handleCurrentPost();
              this.toggleMediaEditModal();
            }
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayErrorAlert(error);
        }
      );
    });
  };

  //Deleting Post
  confirmDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deletePost);
  };

  deletePost = (param) => {
    let data = {
      id: param.id,
    };
    axiosPost(URL.deletePost, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.props.handleCurrentPost();
      }
    });
  };

  render() {
    return (
      <>
        {this.state.post ? (
          this.renderForReferenceId()
        ) : (
          <div
            className="container-fluid mt-3"
            key={this.props.idx ? this.props.idx : ""}
          >
            <div className="row">
              {this.props.post?.isAcademicRepo ? (
                this.props.post?.isValidated ? (
                  <div className="col text-right academiRepoMarker text-muted">
                    - accepted for Academic Repository
                  </div>
                ) : (
                  <div className="col text-right academiRepoMarker text-muted">
                    - submitted for Academic Repository
                  </div>
                )
              ) : null}
            </div>
            <div className="row">
              <div
                className={
                  "col-md-auto col-sm-auto mobilePPimage text-center " +
                  (this.props.fullPage ? "fullpagePP" : "")
                }
              >
                {this.props.post ? (
                  this.props.post.peoples ? (
                    this.props.post.peoples.photo ? (
                      <img
                        src={URL.imageSelectURL + this.props.post.peoples.photo}
                        alt="as"
                        className={
                          "img-postDisplay " +
                          (this.props.fullPage
                            ? "imageNewDimension"
                            : "image-dimension")
                        }
                        onError={(e) => handleError(e)}
                      ></img>
                    ) : (
                      <img
                        src={avatar}
                        alt="asd"
                        className={
                          "img-fluid " +
                          (this.props.fullPage
                            ? "imageNewDimension"
                            : "image-dimension")
                        }
                      ></img>
                    )
                  ) : (
                    <img
                      src={avatar}
                      alt="asdqw"
                      className={
                        "img-fluid " +
                        (this.props.fullPage
                          ? "imageNewDimension"
                          : "image-dimension")
                      }
                    ></img>
                  )
                ) : (
                  <img
                    src={avatar}
                    alt="imqe"
                    className={
                      "img-fluid " +
                      (this.props.fullPage
                        ? "imageNewDimension"
                        : "image-dimension")
                    }
                  ></img>
                )}
              </div>
              <div className="col">
                <div className="row" style={{ position: "relative" }}>
                  <div className="col pl-0">
                    <p className="mb-0" style={{ lineHeight: "20px" }}>
                      <strong>
                        {this.props.post ? (
                          this.props.post.peoples ? (
                            this.props.post.peoples.userRole ===
                            userRoleCode.roleStudentContact ? (
                              <Link
                                to={{
                                  pathname: `/familyPage/${this.props.post.peoples.familyId}`,
                                  familyPageOpen: true,
                                  userId: this.props.post.peoples.userId,
                                }}
                              >
                                {this.props.post.peoples.name}
                              </Link>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/profile/${this.props.post.peoples.username}`,
                                  username: this.props.post.peoples.username,
                                }}
                              >
                                {this.props.post.peoples.name}
                              </Link>
                            )
                          ) : (
                            "Anonymous"
                          )
                        ) : (
                          "Anonymous"
                        )}
                        {this.props.post ? (
                          this.props.post.groupName ? (
                            <>
                              &nbsp;&#62;{" "}
                              <Link
                                to={{
                                  pathname: `/groupPage/${this.props.post.groupName.replace(
                                    / /g,
                                    "_"
                                  )}`,
                                }}
                              >
                                {this.props.post.groupName}
                              </Link>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}{" "}
                      </strong>
                    </p>
                  </div>

                  {this.props.isAcademicRepo ||
                  this.props.insitePost ||
                  this.props.fromCentral ? null : (
                    <div
                      className="col-md-auto tt-postUpdateDeleteIcon"
                      style={{ position: "absolute", right: 0 }}
                    >
                      <Dropdown
                        className="dropdown-trigger"
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleMenu}
                      >
                        <DropdownToggle>
                          <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
                        </DropdownToggle>
                        <DropdownMenu className="tt-post-dropdown">
                          {this.props.post?.peoples?.id ===
                            parseInt(localStorage.getItem("peopleid")) ||
                          userRole === userRoleCode.roleAdmin ? (
                            <>
                              <DropdownItem
                                className="tt-post-dropbtn"
                                onClick={(e) =>
                                  this.toggleMediaEditModal(
                                    e,
                                    this.props.post,
                                    "open"
                                  )
                                }
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                className="tt-post-dropbtn"
                                onClick={() =>
                                  this.confirmDelete(this.props.post.id)
                                }
                              >
                                Delete
                              </DropdownItem>
                            </>
                          ) : null}

                          <DropdownItem
                            className="tt-post-dropbtn"
                            onClick={(e) =>
                              this.toggleHistoryEditModal(
                                e,
                                this.props.post,
                                "open"
                              )
                            }
                          >
                            History
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                </div>

                {/* <div className="row">
                  {this.props.post ? (
                    <>
                      <span className="text-muted date-displayPosts">
                        <i>
                          posted {moment(this.props.post.createdAt).calendar()}{" "}
                          {displayNepaliDate(this.props.post.createdAt)}(
                          {moment(this.props.post.createdAt).fromNow()})
                        </i>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div> */}

                <div className="row">
                  {this.props.insertedInsitePostData ? (
                    <>
                      <span className="text-muted date-displayPosts">
                        <i>
                          posted{" "}
                          {moment(
                            this.props.insertedInsitePostData.createdAt
                          ).calendar()}{" "}
                          {displayNepaliDate(
                            this.props.insertedInsitePostData.createdAt
                          )}
                          (
                          {moment(
                            this.props.insertedInsitePostData.createdAt
                          ).fromNow()}
                          )
                        </i>
                      </span>
                    </>
                  ) : this.props.post ? (
                    <>
                      <span className="text-muted date-displayPosts">
                        <i>
                          posted {moment(this.props.post.createdAt).calendar()}{" "}
                          {displayNepaliDate(this.props.post.createdAt)}(
                          {moment(this.props.post.createdAt).fromNow()})
                        </i>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {/* {this.props.post ? (
                  this.props.post.heading !== "" ? (
                    <div className="row">
                      <p className="tt-postArticleTitle">
                        {this.props.post
                          ? this.props.post.heading
                          : "Here is the heading "}
                      </p>
                    </div>
                  ) : null
                ) : null} */}

                {this.props.insitePost ? (
                  this.props.insertedInsitePostData.heading !== "" ? (
                    <div className="row">
                      <p className="tt-postArticleTitle">
                        {this.props.post
                          ? this.props.insertedInsitePostData.heading
                          : "Here is the heading "}
                      </p>
                    </div>
                  ) : null
                ) : this.props.post ? (
                  this.props.post.heading !== "" ? (
                    <div className="row">
                      <p className="tt-postArticleTitle">
                        {this.props.post
                          ? this.props.post.heading
                          : "Here is the heading "}
                      </p>
                    </div>
                  ) : null
                ) : null}

                {/* INsite post KEywords  */}

                <div className="tt-mobilePostKeywords">
                  {this.props.post ? (
                    this.props.post.postKeywords ? (
                      <div className="row mb-3 mt-2">
                        <div className="tt-postTargetList">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={this.displayKeywordModal}
                          >
                            Keywords Assigned
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>

                <div className="tt-mobilePostKeywords">
                  {this.props.insitePost ? (
                    this.props.insertedInsitePostData.postKeywords !== "" ? (
                      <div className="row mb-3 mt-2">
                        <div className="tt-postTargetList">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={this.displayKeywordModal}
                          >
                            Keywords Assigned
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : this.props.post ? (
                    this.props.post.postKeywords !== "" ? (
                      <div className="row mb-3 mt-2">
                        <div className="tt-postTargetList">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={this.displayKeywordModal}
                          >
                            Keywords Assigned
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>

                {/* 
                <div className="tt-desktopPostKeywords">
                  {this.props.post ? (
                    this.props.post.postKeywords ? (
                      <div className="row mb-3 mt-2">
                        <div className="tt-postTargetList">
                          {this.props.post.postKeywords.map((keyWord, idx) => {
                            return idx <= 2 ? (
                              keyWord.keyword !== "" ? (
                                <div key={idx}>{keyWord.keyword}</div>
                              ) : null
                            ) : idx ===
                              this.props.post.postKeywords.length - 1 ? (
                              <div
                                key={idx}
                                style={{ cursor: "pointer" }}
                                onClick={this.displayKeywordModal}
                              >
                                + {this.props.post.postKeywords.length - 3} more
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div> */}

                <div className="tt-desktopPostKeywords">
                  {this.props.insitePost ? (
                    this.props.insertedInsitePostData &&
                    this.props.insertedInsitePostData.postKeywords ? (
                      <div className="row mb-3 mt-2">
                        <div className="tt-postTargetList">
                          {this.props.insertedInsitePostData.postKeywords.map(
                            (keyWord, idx) => {
                              return idx <= 2 ? (
                                keyWord.keyword !== "" ? (
                                  <div key={idx}>{keyWord.keyword}</div>
                                ) : null
                              ) : idx ===
                                this.props.insertedInsitePostData.postKeywords
                                  .length -
                                  1 ? (
                                <div
                                  key={idx}
                                  style={{ cursor: "pointer" }}
                                  onClick={this.displayKeywordModal}
                                >
                                  +{" "}
                                  {this.props.insertedInsitePostData
                                    .postKeywords.length - 3}{" "}
                                  more
                                </div>
                              ) : null;
                            }
                          )}
                        </div>
                      </div>
                    ) : null
                  ) : this.props.post && this.props.post.postKeywords ? (
                    <div className="row mb-3 mt-2">
                      <div className="tt-postTargetList">
                        {this.props.post.postKeywords.map((keyWord, idx) => {
                          return idx <= 2 ? (
                            keyWord.keyword !== "" ? (
                              <div key={idx}>{keyWord.keyword}</div>
                            ) : null
                          ) : idx ===
                            this.props.post.postKeywords.length - 1 ? (
                            <div
                              key={idx}
                              style={{ cursor: "pointer" }}
                              onClick={this.displayKeywordModal}
                            >
                              + {this.props.post.postKeywords.length - 3} more
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* <div className="row mb-2">
                  {this.props.post
                    ? this.props.post.contents.map((content, idx) => {
                        return content.content !== "" ? (
                          content.contentTypeCode === "IMG" ? (
                            <>
                              {this.props.post.contents.length === 1 ||
                              idx === 0 ? (
                                <img
                                  src={URL.imageSelectURL + content.content}
                                  // src={imageDisplayUrl + content.content}
                                  alt="adqdd"
                                  className="mediaImg"
                                  key={idx}
                                />
                              ) : idx ===
                                this.props.post.contents.length - 1 ? (
                                <div
                                  className="tt-moreImageHolder"
                                  onClick={(e) => this.toggleModal(e, "open")}
                                  key={idx}
                                >
                                  + {this.props.post.contents.length - 1} more
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <div className="mobileMedia">
                              <iframe
                                key={idx}
                                title="previewURL"
                                src={this.getEmbedLink(content.content)}
                                frameBorder="1"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          )
                        ) : (
                          ""
                        );
                      })
                    : ""}
                </div> */}
                {/* {this.props.post ? (
                  this.props.post.textContent &&
                  this.props.post.textContent !== "" ? (
                    <div className="row">
                      <div className="text pl-2">
                        {ReactHtmlParser(
                          this.props.post
                            ? this.renderContent(this.props.post.textContent)
                            : "No text content"
                        )}
                      </div>
                    </div>
                  ) : null
                ) : null} */}

                <div className="row mb-2">
                  {this.props.insitePost
                    ? this.props.insertedInsitePostData.contents.map(
                        (content, idx) => {
                          return content.content !== "" ? (
                            content.contentTypeCode === "IMG" ? (
                              <>
                                {this.props.insertedInsitePostData.contents
                                  .length === 1 || idx === 0 ? (
                                  <img
                                    src={URL.imageSelectURL + content.content}
                                    alt="adqdd"
                                    className="mediaImg"
                                    key={idx}
                                  />
                                ) : idx ===
                                  this.props.insertedInsitePostData.contents
                                    .length -
                                    1 ? (
                                  <div
                                    className="tt-moreImageHolder"
                                    onClick={(e) => this.toggleModal(e, "open")}
                                    key={idx}
                                  >
                                    +{" "}
                                    {this.props.insertedInsitePostData.contents
                                      .length - 1}{" "}
                                    more
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <div className="mobileMedia">
                                <iframe
                                  key={idx}
                                  title="previewURL"
                                  src={this.getEmbedLink(content.content)}
                                  frameBorder="1"
                                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            )
                          ) : (
                            ""
                          );
                        }
                      )
                    : this.props.post
                    ? this.props.post.contents.map((content, idx) => {
                        return content.content !== "" ? (
                          content.contentTypeCode === "IMG" ? (
                            <React.Fragment key={idx}>
                              {this.props.post.contents.length === 1 ||
                              idx === 0 ? (
                                <img
                                  src={URL.imageSelectURL + content.content}
                                  // src={imageDisplayUrl + content.content}
                                  alt="adqdd"
                                  className="mediaImg"
                                  key={idx}
                                />
                              ) : idx ===
                                this.props.post.contents.length - 1 ? (
                                <div
                                  className="tt-moreImageHolder"
                                  onClick={(e) => this.toggleModal(e, "open")}
                                  key={idx}
                                >
                                  + {this.props.post.contents.length - 1} more
                                </div>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ) : (
                            <div className="mobileMedia" key={idx}>
                              <iframe
                                key={idx}
                                title="previewURL"
                                src={this.getEmbedLink(content.content)}
                                frameBorder="1"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          )
                        ) : (
                          ""
                        );
                      })
                    : null}
                </div>

                {this.props.insitePost ? (
                  this.props.insertedInsitePostData.textContent &&
                  this.props.insertedInsitePostData.textContent !== "" ? (
                    <div className="row">
                      <div className="text pl-2">
                        {ReactHtmlParser(
                          this.props.post
                            ? this.renderContent(
                                this.props.insertedInsitePostData.textContent
                              )
                            : "No text content"
                        )}
                      </div>
                    </div>
                  ) : null
                ) : this.props.post ? (
                  this.props.post.textContent &&
                  this.props.post.textContent !== "" ? (
                    <div className="row">
                      <div className="text pl-2">
                        {ReactHtmlParser(
                          this.props.post
                            ? this.renderContent(this.props.post.textContent)
                            : "No text content"
                        )}
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-md-2"></div>
              <div
                className="col-md-10 text-right"
                style={{ paddingLeft: "11px" }}
              >
                {this.props.isAcademicRepo ? (
                  this.props.post.isVerified ? null : (
                    <>
                      {!checkRolePermissions(
                        "accept-academic-repository"
                      ) ? null : (
                        <>
                          <button
                            className="tt-button tt-button-primary my-2 mr-2"
                            onClick={(e) =>
                              this.props.acceptAcademicRepo(e, this.props.post)
                            }
                          >
                            Accept Post
                          </button>
                          <button
                            className="tt-button tt-button-primary my-2 mr-2"
                            onClick={(e) =>
                              this.props.rejectAcademicRepo(e, this.props.post)
                            }
                          >
                            Reject
                          </button>
                        </>
                      )}
                      {!checkRolePermissions(
                        "edit-academic-repository"
                      ) ? null : (
                        <button
                          className="tt-button tt-button-primary my-2 mr-2"
                          onClick={(e) =>
                            this.props.toggleMediaEditModal(
                              e,
                              this.props.post,
                              "open"
                            )
                          }
                        >
                          Edit
                        </button>
                      )}
                    </>
                  )
                ) : null}

                <button
                  className="tt-button tt-button-primary my-2 mr-2"
                  onClick={() => {
                    // navigator.clipboard
                    //   .writeText(`http://localhost:3000/post/media/${this.props.post.referenceId}
                    // `);
                    this.copyToClipboard(`${reactServer}/post/media/${
                      this.props.isAcademicRepo
                        ? this.props.post.referenceId + "true"
                        : this.props.post.referenceId
                    }
                  `);
                    this.renderLinkCopyText();
                  }}
                  // style={
                  //   this.state.linkCopied ? { backgroundColor: "green" } : {}
                  // }
                >
                  {this.state.linkCopied ? "Copied" : "  Copy Link"}
                </button>

                <button
                  className="tt-button tt-button-primary my-2"
                  onClick={(e) => this.toggleModal(e, "open")}
                >
                  Read More
                </button>
              </div>
            </div>
            <ModalWindow
              fullWidth={true}
              modal={this.state.modal}
              toggleModal={this.toggleModal}
              modalClass="tt-noticeModal"
              modalHeader={
                <>
                  <FontAwesomeIcon icon={faPhotoVideo}></FontAwesomeIcon>{" "}
                  {this.props.post
                    ? this.props.post.postType === postType.mediaCode
                      ? this.props.post.heading + " [Media]"
                      : this.props.post.heading
                    : "No heading"}
                </>
              }
              modalBody={
                <ReadMoreTemplate
                  insertedInsitePostData={this.props.insertedInsitePostData}
                  post={this.props.post}
                  comments={this.state.comments}
                  deleteComment={this.deleteComment}
                  toggleEditable={this.toggleEditable}
                  handleChange={this.handleChange}
                  newCommentValue={this.state.newCommentValue}
                  updateComment={this.updateComment}
                />
              }
              modalFooter={
                this.props.insitePost ? null : this.noticeModalFooter()
              }
            ></ModalWindow>
            <ModalWindow
              modal={this.state.keywordModal}
              toggleModal={this.displayKeywordModal}
              modalHeader="Keywords Assigned"
              modalBody={this.keywordModalBody()}
            ></ModalWindow>
            <ModalWindow
              modal={this.props.editMediaAcademicModal}
              toggleModal={this.props.toggleMediaEditModal}
              modalHeader="Edit Academic Repository Post"
              fullWidth={true}
              modalBody={
                <MediaAcademicRepoEditModal
                  post={this.props.selectedPost}
                  fullPage={this.props.fullPage}
                  subjectList={this.props.subjectList}
                  toggleMediaEditModal={this.props.toggleMediaEditModal}
                  updateMediaAcademicRepo={this.props.updateMediaAcademicRepo}
                ></MediaAcademicRepoEditModal>
              }
            ></ModalWindow>
            <ModalWindow
              modal={this.state.editArticleAcademicModal}
              toggleModal={this.toggleMediaEditModal}
              modalHeader="Edit Post"
              fullWidth={true}
              modalBody={
                <MediaAcademicRepoEditModal
                  post={this.state.selectedPost}
                  fullPage={true}
                  subjectList={this.state.subjectList}
                  toggleMediaEditModal={this.toggleMediaEditModal}
                  updateMediaAcademicRepo={this.updatePost}
                  postSection={true}
                ></MediaAcademicRepoEditModal>
              }
            ></ModalWindow>
            <ModalWindow
              modal={this.state.mediaHistoryModal}
              toggleModal={this.toggleHistoryEditModal}
              fullWidth={this.state.postHistory.length > 0 ? true : null}
              modalHeader={this.state.selectedPost.heading + " - History"}
              modalBody={
                <HistoryTemplates
                  postHistory={this.state.postHistory}
                ></HistoryTemplates>
              }
            ></ModalWindow>
          </div>
        )}
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              {this.state.history
                ? "Please Wait. Fetching History..."
                : "Please Wait. Updating Media ..."}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = () => ({});
const mapActionToProps = {
  getNotices,
  getPosts,
  getGroupPosts,
  getPersonProfilePosts,
  getFamilyPosts,
  getPersonPostSectionAcademicRepoPosts,
  getGroupNotices,
  getFamilyNotices,
};
export default connect(mapStateToProps, mapActionToProps)(MediaTemplate);
