/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This stateless functional component, CollapsibleBdoy, is the view for the {@link UpdateCollapse}
 *              Body Section
 *
 */

import React from "react";

const CollapisbleBody = (props) => {
  return (
    <form action="">
      <div className="form-group row">
        <div className="col-md-2"></div>
        <div className="col-md-3 text-right">
          <label htmlFor="name">
            <strong>{localStorage.getItem("classroom")} Name : </strong>
          </label>
        </div>
        <div className="col-md-4">
          <input
            type="text"
            name="name"
            className="form-control"
            value={props.name}
            onChange={props.handleChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-2"></div>
        <div className="col-md-3 text-right">
          <label htmlFor="recruitmentId">
            <strong>{localStorage.getItem("classroom")} Teacher : </strong>
          </label>
        </div>
        <div className="col-md-4">
          <select
            name="recruitmentId"
            className="form-control"
            value={props.recruitmentId}
            onChange={props.handleChange}
          >
            <option value="" disabled>
              Choose {localStorage.getItem("classroom")} Teacher
            </option>
            {props.classroomTeachers.map((classTeacher, idx) => {
              return (
                <option value={classTeacher.id} key={idx}>
                  {classTeacher.people.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-2"></div>
        <div className="col-md-3 text-right">
          <label htmlFor="roomId">
            <strong>{localStorage.getItem("classroom")} Location : </strong>
          </label>
        </div>
        <div className="col-md-4">
          <select
            name="roomId"
            className="form-control"
            value={props.roomId}
            onChange={props.handleChange}
          >
            <option value="" disabled>
              Choose a {localStorage.getItem("classroom")} location
            </option>
            {props.classroomLocations.map((room, idx) => {
              let roomName = ` ${room.name}(${room.number}) - ${room.buildingName}, ${room.buildingSectionAliasName} ${room.buildingSectionName}, ${room.floorName}`;
              return (
                <option key={idx} value={room.id}>
                  {roomName}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-2"></div>
        <div className="col-md-3"></div>
        <div className="col-md-4 text-right">
          <button
            className="tt-button tt-button-primary mr-4"
            onClick={(e) => {
              props.updateTuple(e, props.classroom.id);
            }}
          >
            Update
          </button>
          <button
            className="tt-button tt-button-primary"
            onClick={props.handleSectionLevelCancelBtnClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default CollapisbleBody;
