import React, { Component } from "react";
import swal from "sweetalert";
import DragDropFile from "../../UI/DragDropFile";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomModal from "../common/CustomModal";
import CustomPageHeader from "../common/CustomPageHeader";
import CustomSpinner from "../common/CustomSpinner";
import OtherActivitiesDataList from "./OtherActivitiesDataList";

class OtherActivitiesData extends Component {
  state = {
    name: "",
    files: null,
    description: "",
    showSpinner: false,
    categoryType: null,
    thumbnail: null,
    categoriesList: [],
    classes: [],
    classId: null,
    isTechnicalSchool: false,
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  handleChange = (e, idx) => {
    let { name, value, files } = e.target;
    this.setState({
      [name]: name === "files" ? files : name === "thumbnail" ? files : value,
    });
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    if (files.length > 1) {
      swal("Error", "Please select only one file");
    } else {
      this.setState({
        [name]: files,
      });
    }
  };

  getOtherActivitiesCategory = () => {
    axiosPost(
      URL.getOtherActivities,
      // { isTechnicalSchool: false },
      {},
      (response) => {
        this.setState({
          categoriesList: response.data.data,
        });
      }
    );
  };

  componentDidMount() {
    this.getOtherActivitiesCategory();
    this.getAllClasses();
    // this.getAllActivityData();
  }

  getAllClasses = () => {
    axiosPost(URL.getAllClass, {}, (response) => {
      if (response.status == 200) {
        this.setState({
          classes: response.data.data,
        });
      }
    });
  };

  handleCheckboxSelect = (e) => {
    let name = e.target.name;
    let value = e.target.checked;
    this.setState({ [name]: value });
  };

  handleSave = () => {
    const { name, description, classId, thumbnail, files } = this.state;

    if (
      name === "" ||
      description === "" ||
      classId === null ||
      thumbnail === null ||
      files === null
    ) {
      swal("Error", "All fields are required!!");
      return;
    }

    const data = {
      title: name,
      description,
      otherActivitiesCategoriesId: this.props.activeCat.id,
      classId: classId === "all" ? null : classId,
      isTechnicalSchool: this.state.isTechnicalSchool,
    };

    const formData = new FormData();
    formData.append("jsonData", JSON.stringify(data));
    formData.append("thumbnail", thumbnail);
    formData.append("file", files[0]);

    this.setState(
      {
        showSpinner: true,
      },
      () => {
        axiosPost(
          URL.insertOtherActivityData,
          formData,
          (response) => {
            if (response.status === 200) {
              this.toggleModal();
              swal("Success", "Activity data inserted successfully!!");
              // this.getAllActivityData();
              this.setState({
                showSpinner: false,
              });

              this.props.getOtherActivities();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              showSpinner: false,
            });
          }
        );
      }
    );
  };

  // getAllActivityData = () => {
  //   axiosPost(
  //     URL.getAllActivityData,
  //     { isTechnicalSchool: false },
  //     (response) => {
  //       this.setState({
  //         allactivityData: response.data.data,
  //       });
  //     }
  //   );
  // };

  handleDeleteActivityResource = (data) => {
    axiosPost(URL.deleteActivityResource, { id: data.id }, (response) => {
      if (response.status === 200) {
        this.props.getOtherActivities();
        swal("Success", "Successfully deleted the resources");
      }
      // this.getAllActivityData();
      // this.props.toggleClickCategory();
    });
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}
        <div className="container-fluid">
          <div className="row">
            <div className="text-right py-2">
              <button
                className="btn btn-primary cusBtn cusBtnFontSize"
                onClick={this.toggleModal}
              >
                Add data
              </button>
            </div>
            <div className="col-md-12">
              <div className="whiteContainer">
                {/* <CustomPageHeader
                  title="Other Materials Data"
                  onClick={this.toggleModal}
                  icon="add_box"
                  noPermission={true}
                /> */}

                <OtherActivitiesDataList
                  activeCat={this.props.activeCat.activitiesCategoriesResources}
                  handleDeleteActivityResource={
                    this.handleDeleteActivityResource
                  }
                />
              </div>
            </div>
          </div>
          <CustomModal
            modal={this.state.modal}
            // size="lg"
            toggleModal={this.toggleModal}
            modalHeader="Add Other activities data"
            modalBody={
              <>
                <div className="row">
                  {/* <div className="col-md-4 mt-3">
                    <select
                      className="form-control cusInputSelect"
                      name="categoryType"
                      value={this.state.categoryType}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option selected disabled value="">
                        Choose category
                      </option>
                      <option value="all">All</option>
                      {this.state.categoriesList.map((cl) => (
                        <option value={cl.id}>{cl.name}</option>
                      ))}
                    </select>
                  </div> */}
                  <div className="col-md-12 mt-3">
                    <select
                      className="form-control cusInputSelect"
                      name="classId"
                      value={this.state.classId}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option selected disabled value="">
                        Choose Class
                      </option>
                      <option value="all">All</option>
                      {this.state.classes.map((c) => (
                        <option value={c.id}>Class {c.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12 mt-3">
                    <input
                      className="form-control cusInput"
                      name="name"
                      type="text"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.name}
                      placeholder="Enter name here"
                    />
                  </div>

                  <div className="col-lg-12 mt-3">
                    <textarea
                      name="description"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.description}
                      className="form-control"
                      placeholder="Enter description here"
                    />
                  </div>

                  <div className="col-lg-12 mt-3">
                    <input
                      name="isTechnicalSchool"
                      type="checkbox"
                      onChange={(e) => this.handleCheckboxSelect(e)}
                      // value={this.state.isTechnicalSchool}
                      checked={this.state.isTechnicalSchool}
                      id="catTechnicalSchool"
                    />
                    <label
                      htmlFor="catTechnicalSchool"
                      style={{ position: "relative", top: "-3px", left: "5px" }}
                    >
                      Technical Schools
                    </label>
                  </div>

                  <div className="col-md-12 mt-3">
                    <strong>Select Thumbnail</strong>

                    <DragDropFile
                      multiple={false}
                      handleChange={this.handleChange}
                      name="thumbnail"
                      handleFileDrop={this.handleFileDrop}
                      files={this.state.thumbnail}
                      accept="image/png, image/jpeg, image/jpg"
                    />
                    {/* <br />
                    <input
                      //   multiple
                      accept="image/png, image/jpeg, image/jpg"
                      // className="form-control cusInput"
                      name="thumbnail"
                      type="file"
                      onChange={(e) => this.handleChange(e)}
                    /> */}
                  </div>

                  <div className="col-md-12 mt-3">
                    <strong>Select File</strong>
                    <DragDropFile
                      multiple={false}
                      handleChange={this.handleChange}
                      name="files"
                      handleFileDrop={this.handleFileDrop}
                      files={this.state.files}
                      accept="image/png, image/jpeg, image/jpg, video/*"
                    />

                    {/* <input
                      //   multiple
                      // accept="image/png, image/jpeg, image/jpg"
                      // className="form-control cusInput"
                      name="files"
                      type="file"
                      onChange={(e) => this.handleChange(e)}
                    /> */}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col text-right">
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize"
                      onClick={this.handleSave}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </>
    );
  }
}

export default OtherActivitiesData;
