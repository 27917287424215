import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import "../../assets/scss/permissionConfiguration.scss";
import { Spinner } from "reactstrap";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import swal from "sweetalert";

const animatedComponents = makeAnimated();
export default class AssignPermission extends Component {
  state = {
    activities: [],
    userRoles: [],
    userSubRoles: [],
    widgets: [],
    modules: null,
    subRoleId: null,
    allSelected: false,
    fullWidgets: [],
    render: false,
    dropdownOpen: false,
    subRoleName: "",
    role: "",
    submitSpinner: false,
    assignAll: false,
  };

  componentDidMount() {
    this.getWidgets();
  }
  getUserRoles = () => {
    axiosPost(URL.getUserRoles, {}, (response) => {
      if (response.status === 200) {
        this.setState({ userRoles: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleRoleChange = (e) => {
    this.setState({
      role: e,
    });
  };

  getWidgets = () => {
    axiosPost(URL.getWidgets, {}, (response) => {
      if (response.status === 200) {
        this.setState({ widgets: response.data.data }, () => {
          this.getWidgetModules();
        });
      }
    });
  };

  getWidgetModules = () => {
    axiosPost(URL.getWidgetModules, {}, (response) => {
      if (response.status === 200) {
        this.setState({ widgetModules: response.data.data }, () => {
          this.getAllModules();
        });
      }
    });
  };

  getAllModules = () => {
    axiosPost(URL.getAllModules, {}, (response) => {
      if (response.status === 200) {
        this.setState({ modules: response.data.data }, () => {
          this.getAllActivity();
        });
      }
    });
  };

  getAllActivity = () => {
    axiosPost(URL.getAllActivity, {}, (response) => {
      if (response.status === 200) {
        let activitylist = response.data.data;
        this.setState({ activities: activitylist }, () => {});
        this.getPermissions();
      }
    });
  };

  getModulesForPermission = (param) => {
    axiosPost(URL.getModulesForPermission, param, (response) => {
      if (response.status === 200) {
        this.setState({ modules: response.data.data });
      }
    });
  };

  getPermissions = () => {
    const data = { userId: this.props.UserId };
    axiosPost(URL.selectUserPermissionByKey, data, (response) => {
      if (response.status === 200) {
        this.setState({ permissions: response.data.data }, () => {
          this.insertModulesAndActivities();
        });
      } else {
        this.setState(
          { render: true, fullWidgets: [], permissions: [] },
          () => {
            this.insertModulesAndActivities();
          }
        );
      }
    });
  };

  insertModulesAndActivities = () => {
    let newWidgets = JSON.parse(JSON.stringify(this.state.widgets));
    let newModules = JSON.parse(JSON.stringify(this.state.modules));
    let newActivities = JSON.parse(JSON.stringify(this.state.activities));

    newModules.forEach((module) => {
      let activities = [];
      newActivities.forEach((activity) => {
        if (activity.moduleId === module.id) {
          activities.push(activity);
        }
      });
      module.activities = activities;
    });
    newWidgets.forEach((widget) => {
      let modules = [];
      this.state.widgetModules.forEach((widgetModule) => {
        if (widget.id === widgetModule.widgetId) {
          let moduleIndex = newModules.findIndex(
            (module) => module.id === widgetModule.moduleId
          );
          if (moduleIndex !== -1) {
            modules.push(newModules[moduleIndex]);
          }
        }
      });
      widget.modules = modules;
    });
    this.setState({ fullWidgets: newWidgets }, () => {
      this.insertPermissionIntoFullWidgets();
    });
  };

  insertPermissionIntoFullWidgets = () => {
    //let newFullWidgets = [...this.state.fullWidgets];
    let newFullWidgets = JSON.parse(JSON.stringify(this.state.fullWidgets));
    if (this.state.permissions && this.state.permissions.length > 0) {
      this.state.permissions.forEach((permission) => {
        let widgetIndex = newFullWidgets.findIndex(
          (widget) => widget.id === permission.widgetId
        );

        if (widgetIndex !== -1) {
          let moduleIndex = newFullWidgets[widgetIndex].modules.findIndex(
            (module) => module.id === permission.moduleId
          );

          if (moduleIndex !== -1) {
            let activityIndex = newFullWidgets[widgetIndex].modules[
              moduleIndex
            ].activities.findIndex(
              (activity) => activity.id === permission.activityId
            );

            if (activityIndex !== -1) {
              newFullWidgets[widgetIndex].modules[moduleIndex].activities[
                activityIndex
              ].isTrue = true;
            } else {
              newFullWidgets[widgetIndex].modules[moduleIndex].activities[
                activityIndex
              ].isTrue = false;
            }
          }
        }
      });
    }

    //set wifdget checked and module checked if all permissions under it are available
    newFullWidgets.forEach((w) => {
      w.modules.forEach((m) => {
        m.moduleChecked = m.activities?.every((a) =>
          this.state.permissions.find((p) => p.activityId === a.id) !==
          undefined
            ? true
            : false
        );
      });
      w.widgetChecked = w.modules.every((wm) => wm.moduleChecked);
    });

    const assignAll = newFullWidgets.every(
      (w) => w.widgetChecked && w.modules.every((m) => m.moduleChecked)
    );

    this.setState({ render: true, fullWidgets: newFullWidgets, assignAll });
  };

  handleActivityCheckboxChange = (
    e,
    widgetIndex,
    moduleIndex,
    activityIndex
  ) => {
    let newFullWidgets = [...this.state.fullWidgets];
    newFullWidgets[widgetIndex].modules[moduleIndex].activities[
      activityIndex
    ].isTrue = e.target.checked;
    this.setState({ fullWidgets: newFullWidgets });
  };

  handleWidgetCheckboxChange = (e, widgetIndex) => {
    let newFullWidgets = [...this.state.fullWidgets];
    newFullWidgets[widgetIndex].modules.forEach((module) => {
      module.moduleChecked = e.target.checked;
      module.activities.forEach((activity) => {
        activity.isTrue = e.target.checked;
      });
    });
    newFullWidgets[widgetIndex].widgetChecked = e.target.checked;
    this.setState({ fullWidgets: newFullWidgets });
  };

  handleModuleCheckboxChange = (e, widgetIndex, moduleIndex) => {
    let newFullWidgets = [...this.state.fullWidgets];
    newFullWidgets[widgetIndex].modules[moduleIndex].activities.forEach(
      (activity) => {
        activity.isTrue = e.target.checked;
      }
    );
    newFullWidgets[widgetIndex].modules[moduleIndex].moduleChecked =
      e.target.checked;
    this.setState({ fullWidgets: newFullWidgets });
  };

  handleSelectAllCheckboxChange = (e) => {
    let newFullWidgets = [...this.state.fullWidgets];
    newFullWidgets.forEach((widget) => {
      widget.widgetChecked = e.target.checked;
      widget.modules.forEach((module) => {
        module.moduleChecked = e.target.checked;
        module.activities.forEach((activity) => {
          activity.isTrue = e.target.checked;
        });
      });
    });
    this.setState({ fullWidgets: newFullWidgets, assignAll: e.target.checked });
  };

  handlePermissionsSubmit = (e) => {
    e.preventDefault();
    let permissions = [];
    this.state.fullWidgets.forEach((widget) => {
      widget.modules.forEach((module) => {
        module.activities.forEach((activity) => {
          if (activity.isTrue && activity.isTrue === true) {
            permissions.push({
              widgetId: widget.id,
              moduleId: module.id,
              activityId: activity.id,
            });
          }
        });
      });
    });
    if (this.props.createSubRole == false) {
      this.setState({
        submitSpinner: true,
      });
      let data = {
        id: this.props.UserId,
        permission: permissions,
      };
      axiosPost(URL.assignUserBasedPermission, data, (response) => {
        if (response.status === 200) {
          this.setState({
            submitSpinner: false,
          });
          swal({
            title: "Success",
            text: `${response.data.message}`,
            allowOutsideClick: true,
            closeOnClickOutside: true,
          });
          this.props.togglePermissionsModal(this.props.user);
        }
      });
    } else {
      let data = {
        userRoleCode: this.state.role.value,
        permission: permissions,
        subRoleName: this.state.subRoleName,
      };
      if (
        data.userRoleCode == "" ||
        data.userRoleCode == null ||
        data.userRoleCode == undefined
      ) {
        swal({
          title: "Error",
          text: "Please Select the role",
          allowOutsideClick: true,
          closeOnClickOutside: true,
        });
        return;
      }
      if (data.subRoleName == "" || data.subRoleName == null) {
        swal({
          title: "Error",
          text: "Sub Role name is required",
          allowOutsideClick: true,
          closeOnClickOutside: true,
        });
        return;
      }
      this.setState({
        submitSpinner: true,
      });
      axiosPost(URL.createSubRole, data, (response) => {
        if (response.status === 200) {
          this.setState({
            submitSpinner: false,
          });
          swal({
            title: "Success",
            text: `${response.data.message}`,
            allowOutsideClick: true,
            closeOnClickOutside: true,
          });
          this.props.toggleSubRoleModal();
        }
      });
    }
  };

  render() {
    return (
      <>
        {this.state.submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Submitting
            </div>
          </div>
        ) : (
          ""
        )}
        <form>
          <div className="container">
            {this.props.createSubRole ? (
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="calendarTitle">
                    <strong>Role: </strong>
                  </label>
                  <Select
                    menuPlacement="auto"
                    menuPosition="fixed"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={this.props.roleOptions}
                    placeholder="Choose Role"
                    name="role"
                    onChange={this.handleRoleChange}
                    closeMenuOnScroll={false}
                    value={this.state.role}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="calendarTitle">
                    <strong>Sub Role Name: </strong>
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="subRoleName"
                    className="form-control mb-2"
                    value={this.state.subRoleName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            ) : null}
            <div className="tt-permissionColumn">
              {this.state.fullWidgets.length > 0 ? (
                <table className="table">
                  <thead className="tt-group-header permissionTable">
                    <tr>
                      <th className="text-center" width="10%">
                        <input
                          type="checkbox"
                          name="assignAll"
                          className="activity-checkbox"
                          checked={this.state.assignAll}
                          onChange={this.handleSelectAllCheckboxChange}
                        />
                      </th>
                      <th width="90%">
                        {this.state.assignAll ? "Unassign all" : "Assign all"}
                      </th>
                    </tr>
                  </thead>
                </table>
              ) : (
                <div className="text-center mb-2 mt-2">
                  Select User Role and Sub Role First
                </div>
              )}
              {this.state.fullWidgets &&
                this.state.render &&
                this.state.fullWidgets.map((widget, widgetIndex) => (
                  <table className="table">
                    <thead className="tt-group-header permissionTable">
                      <tr>
                        <th
                          className="text-center"
                          width="10%"
                          style={{ backgroundColor: "#3f3f9e" }}
                        >
                          <input
                            type="checkbox"
                            className="activity-checkbox"
                            checked={widget.widgetChecked}
                            onChange={(e) =>
                              this.handleWidgetCheckboxChange(e, widgetIndex)
                            }
                          />
                        </th>
                        <th width="90%" style={{ backgroundColor: "#3f3f9e" }}>
                          {widget.name}
                        </th>
                      </tr>
                    </thead>
                    {widget.modules &&
                      widget.modules.map((module, moduleIndex) => (
                        <>
                          <thead className="tt-group-header permissionTable">
                            <tr>
                              <th width="10%" className="text-center">
                                <input
                                  type="checkbox"
                                  className="activity-checkbox"
                                  checked={module.moduleChecked}
                                  onChange={(e) =>
                                    this.handleModuleCheckboxChange(
                                      e,
                                      widgetIndex,
                                      moduleIndex
                                    )
                                  }
                                />
                              </th>
                              <th width="90%">{module.name}</th>
                            </tr>
                          </thead>
                          <tbody className="permissionTable">
                            {module.activities &&
                              module.activities.map(
                                (activity, activityIndex) => (
                                  <tr>
                                    <th width="10%" className="text-center">
                                      <input
                                        type="checkbox"
                                        className="activity-checkbox"
                                        checked={activity.isTrue}
                                        onChange={(e) =>
                                          this.handleActivityCheckboxChange(
                                            e,
                                            widgetIndex,
                                            moduleIndex,
                                            activityIndex
                                          )
                                        }
                                      />
                                    </th>
                                    <th width="90%">{activity.name}</th>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </>
                      ))}
                  </table>
                ))}
            </div>
            <div className="row">
              <div className="col text-right">
                <button
                  onClick={this.handlePermissionsSubmit}
                  className="tt-button tt-button-primary"
                >
                  {"Submit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
