import React, { Component } from "react";
import "../../assets/scss/postArea.scss";
import ModalWindow from "../UI/ModalWindow";
import Posts from "./PostSection/Posts";
import PostSearch from "./PostSearch";
import PostSection from "./PostSection";
import {
  getGroupPosts,
  getGroupDiscussions,
  getPersonProfilePosts,
  getFamilyPosts,
  getDiscussionPosts,
  getPersonProfileRecentPosts,
  getPersonPostSectionAcademicRepoPosts,
  resetSearchBoolean,
  setIsSearching,
} from "./../../ducks/PostsDucks";
import { postType, draggable } from "../../utils/Constants";
import { connect } from "react-redux";
import { getPosts } from "../../ducks/PostsDucks";
import swal from "sweetalert";
import "../../assets/scss/ResponsiveScss/responsivePostSearch.scss";
import { isEqual } from "lodash";
import {
  youtubeLink,
  dailyMotionLink,
  vimeoLink,
  slideshareLink,
  defaultLinkURL,
} from "../../utils/VideoURL";

class PostArea extends Component {
  state = {
    modal: false,
    posts: [],
    discussionPosts: [],
    // groupPosts: [],
    // familyPosts: [],
    repoModal: false,
    personProfileRecentPosts: [],
    myPosts: true,
    academicRepo: true,
    fromDate: "",
    toDate: "",
    searchKeywords: "",
    personAcademicRepoPosts: [],
    searchPost: "",
    currentPage: 1,
    totalRecords: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.familyPost && prevProps.familyId !== this.props.familyId) {
      this.props.getFamilyPosts(this.props.familyId, "initCall");
      this.props.getDiscussionPosts(this.props.familyId, "initCall");
      this.props.resetSearchBoolean();
    }
    if (this.props.groupPost) {
      if (prevProps.groupData.id !== this.props.groupData.id) {
        this.props.getGroupPosts(this.props.groupData.id, "initCall");
        this.props.getGroupDiscussions(this.props.groupData.id, "initCall");
        this.props.resetSearchBoolean();
      }
    }
  }

  componentDidMount() {
    // if (this.props.familyPost) {
    //   this.props.getFamilyPosts(this.props.familyId, "initCall");
    // }
    // if (this.props.groupPost) {
    //   this.props.getGroupPosts(this.props.groupData.id, "initCall");
    // }
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "myPosts" || name === "academicRepo") {
      if (value === false) {
        let checkValue = "";
        if (name === "myPosts") {
          checkValue = this.state.academicRepo;
        } else {
          checkValue = this.state.myPosts;
        }
        if (checkValue === false) {
          swal({
            title: "Error",
            text: "Atleast one of them must be selected",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          return false;
        } else {
          this.setState({ [name]: value });
        }
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEqual(prevState.posts, nextProps.personProfilePosts) ||
      !isEqual(
        prevState.personAcademicRepoPosts,
        nextProps.personPostSectionRepoPosts
      ) ||
      !isEqual(
        prevState.personProfileRecentPosts,
        nextProps.personProfileRecentPosts
      ) ||
      !isEqual(prevState.posts, nextProps.groupPosts) ||
      !isEqual(prevState.posts, nextProps.familyPosts) ||
      !isEqual(prevState.discussionPosts, nextProps.discussionPosts) ||
      !isEqual(prevState.discussionPosts, nextProps.groupDiscussions)
    ) {
      let initialPosts = [];
      let allPosts = [];
      let discussionPosts = [];
      if (nextProps.familyPost) {
        allPosts = nextProps.familyPosts;
        discussionPosts = nextProps.discussionPosts;
      } else if (nextProps.groupPost) {
        allPosts = nextProps.groupPosts;
        discussionPosts = nextProps.groupDiscussions;
      } else {
        allPosts = nextProps.personProfilePosts;
      }
      if (allPosts !== null) {
        allPosts.forEach((el) => {
          el.posts.forEach((post) => {
            if (
              post.postType === postType.articleCode ||
              post.postType === postType.mediaCode
            ) {
              post.contents.forEach((content) => {
                if (
                  content.contentTypeCode === "LNK" &&
                  content.content !== ""
                ) {
                  let videoId = "";
                  if (
                    content.content.includes("youtube") ||
                    content.content.includes("youtu.be")
                  ) {
                    let contentObj = youtubeLink(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  } else if (
                    content.content.includes("dailymotion") ||
                    content.content.includes("dai.ly")
                  ) {
                    let contentObj = dailyMotionLink(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  } else if (content.content.includes("vimeo")) {
                    let contentObj = vimeoLink(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  } else if (
                    content.content.includes("slideshare") &&
                    content.youtubeTitle !== undefined
                  ) {
                    let contentObj = slideshareLink(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  } else {
                    let contentObj = defaultLinkURL(content.content);
                    content.youtubeTitle = contentObj.youtubeTitle;
                    content.thumbnail = contentObj.thumbnail;
                  }
                }
              });
              initialPosts.push(el);
            }
          });
        });
      }
      let recentPosts = nextProps.personProfileRecentPosts;
      let newData = [];
      if (recentPosts != null && recentPosts.length > 0) {
        recentPosts.forEach((post) => {
          if (
            post.postType === postType.articleCode ||
            post.postType === postType.mediaCode
          ) {
            post.contents.forEach((content) => {
              if (content.contentTypeCode === "LNK" && content.content !== "") {
                let videoId = "";
                if (
                  content.content.includes("youtube") ||
                  content.content.includes("youtu.be")
                ) {
                  let contentObj = youtubeLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (
                  content.content.includes("dailymotion") ||
                  content.content.includes("dai.ly")
                ) {
                  let contentObj = dailyMotionLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (content.content.includes("vimeo")) {
                  let contentObj = vimeoLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (
                  content.content.includes("slideshare") &&
                  content.youtubeTitle !== undefined
                ) {
                  let contentObj = slideshareLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else {
                  let contentObj = defaultLinkURL(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                }
              }
            });
            newData.push(post);
          }
        });
      }
      let repoPosts = nextProps.personPostSectionRepoPosts;
      let newRepo = [];
      if (repoPosts !== null && repoPosts.length > 0) {
        repoPosts.forEach((post) => {
          if (
            post.postType === postType.articleCode ||
            post.postType === postType.mediaCode
          ) {
            post.contents.forEach((content) => {
              if (content.contentTypeCode === "LNK" && content.content !== "") {
                if (
                  content.content.includes("youtube") ||
                  content.content.includes("youtu.be")
                ) {
                  let contentObj = youtubeLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (
                  content.content.includes("dailymotion") ||
                  content.content.includes("dai.ly")
                ) {
                  let contentObj = dailyMotionLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (content.content.includes("vimeo")) {
                  let contentObj = vimeoLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (
                  content.content.includes("slideshare") &&
                  content.youtubeTitle !== undefined
                ) {
                  let contentObj = slideshareLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else {
                  let contentObj = defaultLinkURL(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                }
              }
            });
            newRepo.push(post);
          }
        });
      }

      const pp = initialPosts;
      return {
        posts: pp,
        discussionPosts,
        personProfileRecentPosts: newData,
        personAcademicRepoPosts: newRepo,
      };
    } else {
      return null;
    }
  }

  handleCurrentPost = () => {
    const profilePeopleId = this.props.profilePeopleId;
    this.props.setIsSearching(false);
    if (this.props.familyPost) {
      this.props.getFamilyPosts(this.props.familyId, "initCall");
    } else if (this.props.groupPost) {
      this.props.getGroupPosts(this.props.groupData.id, "initCall");
      this.props.getGroupDiscussions(this.props.groupData.id, "initCall");
    } else {
      this.props.getPersonProfilePosts(profilePeopleId, "initCall");
      this.props.getPersonProfileRecentPosts(profilePeopleId);
      this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
    }
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleRepository = () => {
    this.setState({ repoModal: !this.state.repoModal });
  };
  clearDate = () => {
    this.setState({ fromDate: "", toDate: "" });
  };

  fetchPostsForScroll = () => {
    if (!this.props.isSearching) {
      const profilePeopleId = this.props.profilePeopleId;
      this.props.getPersonProfilePosts(profilePeopleId);
    }
  };

  fetchRepoPostsForScroll = () => {
    if (!this.props.isSearching) {
      this.props.getPersonPostSectionAcademicRepoPosts(true);
    }
  };

  fetchGroupPostsForScroll = () => {
    if (!this.props.isSearching) {
      this.props.getGroupPosts(this.props.groupData.id);
    }
  };

  fetchFamilyPostsForScroll = () => {
    if (!this.props.isSearching) {
      this.props.getFamilyPosts(this.props.familyId);
    }
  };

  fetchDiscussionPostsForScroll = () => {
    if (!this.props.isSearching) {
      this.props.getDiscussionPosts(this.props.familyId);
    }
  };

  render() {
    return (
      <>
        <PostSearch
          familyPost={this.props.familyPost}
          groupPost={this.props.groupPost}
          otherProfile={this.props.otherProfile}
          memberName={this.props.memberName}
          userId={this.props.userId}
          handleChange={this.handleChange}
          searchPosts={this.searchPosts}
          {...this.state}
          peopleId={this.props.profilePeopleId}
          groupId={this.props.groupData ? this.props.groupData.id : null}
          handleCurrentPost={this.handleCurrentPost}
          clearDate={this.clearDate}
          familyId={this.props.familyPage ? this.props.familyId : null}
        />
        <div id="tt-postBox" className="tt-post-box">
          <div className="tt-post-area">
            <div className="tt-group-header">
              <div className="row">
                <div className="col mt-1">
                  <span className="mobileResultDisplay">
                    Post an Article or Media files
                  </span>
                  <span className="mobileCreatePost">Create a Post</span>
                </div>
                <div className="col text-right">
                  <button
                    className="tt-button tt-button-primary "
                    onClick={this.toggleModal}
                  >
                    {this.props.familyPost || this.props.groupPost
                      ? "Create Post / Discussion"
                      : "Create Post"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PostSection
          groupPost={this.props.groupPost}
          groupData={this.props.groupData}
          groupId={this.props.groupPost ? this.props.groupData.id : null}
          familyPost={this.props.familyPost}
          profilePeopleId={this.props.profilePeopleId}
          familyId={this.props.familyId}
          memberName={this.props.memberName}
          userId={this.props.userId}
          toggleRepository={this.toggleRepository}
          fetchPostsForScroll={this.fetchPostsForScroll}
          fetchRepoPostsForScroll={this.fetchRepoPostsForScroll}
          fetchGroupPostsForScroll={this.fetchGroupPostsForScroll}
          fetchFamilyPostsForScroll={this.fetchFamilyPostsForScroll}
          fetchDiscussionPostsForScroll={this.fetchDiscussionPostsForScroll}
          postSpinner={this.props.postSpinner}
          recentPostSpinner={this.props.recentPostSpinner}
          academicPostSpinner={this.props.academicPostSpinner}
          personAcademicRepoPosts={this.state.personAcademicRepoPosts}
          handleCurrentPost={this.handleCurrentPost}
          {...this.state}
          totalPosts={this.props.totalPosts}
          currentRecordsCount={this.props.currentRecordsCount}
          totalAcademicRepoPosts={this.props.totalAcademicRepoPosts}
          currentAcademicRepoPosts={this.props.currentAcademicRepoPosts}
          totalGroupPosts={this.props.totalGroupPosts}
          currentGroupPosts={this.props.currentGroupPosts}
          totalFamilyPosts={this.props.totalFamilyPosts}
          currentFamilyPosts={this.props.currentFamilyPosts}
          totalDiscussionPosts={this.props.totalDiscussionPosts}
          currentDiscussionPosts={this.props.currentDiscussionPosts}
          totalGroupDiscussions={this.props.totalGroupDiscussions}
          currentGroupDiscussions={this.props.currentGroupDiscussions}
          familyPage={this.props.familyPage ? this.props.familyPage : false}
        />

        <ModalWindow
          modal={this.state.modal}
          modalHeader="Post Section"
          fullWidth={true}
          toggleModal={this.toggleModal}
          modalClass="tt-mobileCreatePost"
          modalBody={
            <Posts
              toggleModal={this.toggleModal}
              noticeType={false}
              username={this.props.username}
              groupPost={this.props.groupPost ? this.props.groupPost : false}
              familyPost={this.props.familyPost ? this.props.familyPost : false}
              groupData={this.props.groupData}
              profilePeopleId={this.props.profilePeopleId}
              familyId={this.props.familyId}
              familyPage={this.props.familyPage ? this.props.familyPage : false}
              loggedInUser={this.props.loggedInUser}
              groupPage={this.props.groupPage ? this.props.groupPage : false}
              groupName={this.props.groupName ? this.props.groupName : ""}
            ></Posts>
          }
        ></ModalWindow>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  posts: state.posts.posts,
  totalRecords: state.posts.totalRecords,
  currentRecordsCount: state.posts.currentRecordsCount,
  groupPosts: state.posts.groupPosts,
  personProfilePosts: state.posts.personProfilePosts,
  personProfileRecentPosts: state.posts.personProfileRecentPosts,
  postSpinner: state.posts.postSpinner,
  recentPostSpinner: state.posts.recentPostSpinner,
  personPostSectionRepoPosts: state.posts.personPostSectionRepoPosts,
  academicPostSpinner: state.posts.academicPostSpinner,
  totalPosts: state.posts.totalPosts,
  personalPostOffset: state.posts.personalPostOffset,
  personalPostPage: state.posts.personalPostPage,
  personalPostPageSize: state.posts.personalPostPageSize,
  totalAcademicRepoPosts: state.posts.totalAcademicRepoPosts,
  currentAcademicRepoPosts: state.posts.currentAcademicRepoPosts,
  totalGroupPosts: state.posts.totalGroupPosts,
  currentGroupPosts: state.posts.currentGroupPosts,
  familyPosts: state.posts.familyPosts,
  totalFamilyPosts: state.posts.totalFamilyPosts,
  currentFamilyPosts: state.posts.currentFamilyPosts,
  discussionPosts: state.posts.discussionPosts,
  totalDiscussionPosts: state.posts.totalDiscussionPosts,
  currentDiscussionPosts: state.posts.currentDiscussionPosts,
  isSearching: state.posts.isSearching,
  groupDiscussions: state.posts.groupDiscussions,
  totalGroupDiscussions: state.posts.totalGroupDiscussions,
  currentGroupDiscussions: state.posts.currentGroupDiscussions,
});

const mapActionToProps = {
  getPosts,
  getGroupPosts,
  getGroupDiscussions,
  getPersonProfilePosts,
  getFamilyPosts,
  getDiscussionPosts,
  getPersonPostSectionAcademicRepoPosts,
  getPersonProfileRecentPosts,
  resetSearchBoolean,
  setIsSearching,
};

export default connect(mapStateToProps, mapActionToProps)(PostArea);
