import React from "react";

function StudentTerminalMarks(props) {
  return (
    <div className="stuTerminalMarksDiv">
      <p>Student's Marks Card</p>
      <div className="row">
        <div className="col">
          <div className="tt-group-header">
            <select
              name="terminal"
              className="form-control"
              defaultValue=""
              style={{ width: "20%" }}
            >
              <option value="" disabled>
                Select Terminal Exam
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mt-2 px-2">
        <div className="col">
          <table className="table table-bordered">
            <thead className="tt-group-header">
              <tr>
                <th>Subject</th>
                <th>Full Marks</th>
                <th>Pass Marks</th>
                <th>Obtained Marks</th>
              </tr>
            </thead>
            <tbody>
              {props.terminalMarks.length > 0 ? (
                props.terminalMarks.map((sub, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{sub.name}</td>
                      <td>{sub.fullMarks}</td>
                      <td>{sub.passMarks}</td>
                      <td>{sub.obtainedMarks}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>No Subjects</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StudentTerminalMarks;
