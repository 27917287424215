import React, { Component } from "react";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class MenuForm extends Component {
  state = {
    menuForms: [
      {
        nameEn: "",
        nameNp: "",
        link: "",
        subMenus: [],
      },
    ],
    showSpinner: false,
    error: false,
  };

  addMenu = () => {
    let menuForms = [...this.state.menuForms];
    menuForms.push({
      nameEn: "",
      nameNp: "",
      link: "",
      subMenus: [],
    });
    this.setState({ menuForms, error: false });
  };
  removeMenu = (idx) => {
    let menuForms = [...this.state.menuForms];
    menuForms.splice(idx, 1);

    this.setState({ menuForms, error: false });
  };
  addSubMenu = (idx) => {
    let menuForms = [...this.state.menuForms];
    menuForms[idx]["subMenus"].push({
      nameEn: "",
      nameNp: "",
      link: "",
    });
    this.setState({ menuForms, error: false });
  };
  removeSubMenu = (menuIndex, subMenuIndex) => {
    let menuForms = [...this.state.menuForms];
    menuForms[menuIndex].subMenus.splice(subMenuIndex, 1);
    this.setState({ menuForms, error: false });
  };

  handleMenuChange = (e, idx) => {
    let menuForms = this.state.menuForms;
    let name = e.target.name;
    let value = e.target.value;
    menuForms[idx][name] = value;
    this.setState({ menuForms });
  };
  handleSubMenuChange = (e, idx, subIdx) => {
    let menuForms = this.state.menuForms;
    let name = e.target.name;
    let value = e.target.value;
    menuForms[idx]["subMenus"][subIdx][name] = value;
    this.setState({ menuForms });
  };

  handleMenuSubmit = () => {
    let data = [...this.state.menuForms];
    let error = false;
    data.forEach((datum) => {
      datum.display = 1;

      if (datum.subMenus.length > 0) {
        datum.subMenus.forEach((subDatum) => {
          subDatum.display = 1;
        });
      }
      if (datum.nameEn == "" || datum.nameNp == "" || datum.link == "") {
        error = true;
        this.setState({
          error: true,
        });
        if (datum.subMenus.length > 0) {
          datum.subMenus.map((subMenu, sIdx) => {
            if (
              subMenu.nameEn == "" ||
              subMenu.nameNp == "" ||
              subMenu.link == ""
            ) {
              error = true;
              this.setState({
                error: true,
              });
            }
          });
        }
      }
    });
    if (error == true) {
      return;
    }
    this.setState({ showSpinner: true });
    axiosPost(
      URL.insertMenu,
      data,
      (response) => {
        if (response.status == 200) {
          this.setState({
            menuForms: [
              {
                nameEn: "",
                nameNp: "",
                link: "",
                subMenus: [],
              },
            ],
            showSpinner: false,
            error: false,
          });
          this.props.getAllMenu();
          swal("Success", response.data.message);
          this.props.toggleModal();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}
        {this.state.menuForms.length > 0
          ? this.state.menuForms.map((menuForm, index) => {
              return (
                <div key={index} className="mb-5">
                  <div className="row">
                    <div className="col-lg-3">
                      <input
                        className={`form-control cusInput ${
                          this.state.error &&
                          menuForm.nameEn == "" &&
                          "is-invalid"
                        }`}
                        name="nameEn"
                        value={menuForm.nameEn}
                        onChange={(e) => this.handleMenuChange(e, index)}
                        type="text"
                        placeholder="Enter english name"
                      />

                      {this.state.error && menuForm.nameEn == "" && (
                        <div className="errorMsg-textField mt-1">
                          *English name is required!
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3">
                      <input
                        className={`form-control cusInput ${
                          this.state.error &&
                          menuForm.nameNp == "" &&
                          "is-invalid"
                        }`}
                        name="nameNp"
                        value={menuForm.nameNp}
                        onChange={(e) => this.handleMenuChange(e, index)}
                        type="text"
                        placeholder="Enter nepali name"
                      />
                      {this.state.error && menuForm.nameNp == "" && (
                        <div className="errorMsg-textField mt-1">
                          *Nepali name is required!
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3">
                      <input
                        className={`form-control cusInput ${
                          this.state.error &&
                          menuForm.link == "" &&
                          "is-invalid"
                        }`}
                        name="link"
                        value={menuForm.link}
                        onChange={(e) => this.handleMenuChange(e, index)}
                        type="text"
                        placeholder="Enter link"
                      />
                      {this.state.error && menuForm.link == "" && (
                        <div className="errorMsg-textField mt-1">
                          *Menu Link is required!
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mt-1">
                      {index > 0 ? (
                        <span
                          className="material-icons-round text-danger pointer"
                          aria-hidden="true"
                          onClick={(e) => this.removeMenu(index)}
                        >
                          remove_circle
                        </span>
                      ) : null}
                      {index === this.state.menuForms.length - 1 ? (
                        <span
                          className="material-icons-round text-success pointer"
                          aria-hidden="true"
                          onClick={this.addMenu}
                        >
                          add_circle
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="whiteContainer">
                    {menuForm.subMenus.length <= 0 ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <button
                            className="btn btn-primary cusBtn cusBtnFontSize"
                            onClick={(e) => this.addSubMenu(index)}
                          >
                            Add Sub Menu
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <p className="formTitle text-uppercase">Sub Menu</p>
                        {menuForm.subMenus.map((subMenu, subIndex) => {
                          return (
                            <div className="row mb-3">
                              <div className="col-lg-3">
                                <input
                                  className={`${
                                    this.state.error && subMenu.nameEn == ""
                                      ? "form-control cusInput is-invalid"
                                      : "form-control cusInput"
                                  }`}
                                  name="nameEn"
                                  type="text"
                                  value={subMenu.nameEn}
                                  onChange={(e) =>
                                    this.handleSubMenuChange(e, index, subIndex)
                                  }
                                  placeholder="Enter english name"
                                />
                                {this.state.error && subMenu.nameEn == "" && (
                                  <div className="errorMsg-textField mt-1">
                                    *English name is required!
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-3">
                                <input
                                  className={`${
                                    this.state.error && subMenu.nameNp == ""
                                      ? "form-control cusInput is-invalid"
                                      : "form-control cusInput"
                                  }`}
                                  name="nameNp"
                                  type="text"
                                  onChange={(e) =>
                                    this.handleSubMenuChange(e, index, subIndex)
                                  }
                                  value={subMenu.nameNp}
                                  placeholder="Enter nepali name"
                                />
                                {this.state.error && subMenu.nameNp == "" && (
                                  <div className="errorMsg-textField mt-1">
                                    *Nepali name is required!
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-3">
                                <input
                                  className={`${
                                    this.state.error && subMenu.link == ""
                                      ? "form-control cusInput is-invalid"
                                      : "form-control cusInput"
                                  }`}
                                  name="link"
                                  onChange={(e) =>
                                    this.handleSubMenuChange(e, index, subIndex)
                                  }
                                  value={subMenu.link}
                                  type="text"
                                  placeholder="Enter link"
                                />
                                {this.state.error && subMenu.link == "" && (
                                  <div className="errorMsg-textField mt-1">
                                    *Menu link is required!
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-3">
                                {subIndex >= 0 ? (
                                  <span
                                    className="material-icons-round text-danger"
                                    aria-hidden="true"
                                    onClick={(e) =>
                                      this.removeSubMenu(index, subIndex)
                                    }
                                  >
                                    remove_circle
                                  </span>
                                ) : null}
                                {subIndex === menuForm.subMenus.length - 1 ? (
                                  <span
                                    className="material-icons-round text-success"
                                    aria-hidden="true"
                                    onClick={(e) => this.addSubMenu(index)}
                                  >
                                    add_circle
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              );
            })
          : null}
        <div className="row mt-4">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleMenuSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default MenuForm;
