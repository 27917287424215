/**
 * @author Azzeta  Karmacharya
 */

import React, { Component } from "react";

export default class TermSetupForm extends Component {
  render() {
    return (
      <>
        {this.props.terms !== undefined
          ? this.props.terms.map((term, idx) => {
              let termNameId = `termName-${idx}`,
                startDateId = `startDate-${idx}`,
                endDateId = `endDate-${idx}`;

              return (
                <div className="form-group row" key={idx}>
                  <div className="col-sm-4">
                    <input
                      id={termNameId}
                      data-id={idx}
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={(e) =>
                        this.props.handleTermChange(e, this.props.parentIndex)
                      }
                      value={term.name}
                    />
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="date"
                      id={startDateId}
                      onKeyDown={(e) => e.preventDefault()}
                      data-id={idx}
                      className="form-control"
                      name="startDate"
                      onChange={(e) =>
                        this.props.handleTermChange(e, this.props.parentIndex)
                      }
                      min={
                        idx === 0
                          ? this.props.minStartDate
                          : this.props.terms[idx - 1].endDate !== ""
                          ? this.props.terms[idx - 1].endDate
                          : this.props.minStartDate
                      }
                      max={this.props.maxEndDate}
                      value={term.startDate}
                      disabled={
                        idx === 0
                          ? false
                          : this.props.terms[idx - 1].endDate !== ""
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="date"
                      id={endDateId}
                      onKeyDown={(e) => e.preventDefault()}
                      data-id={idx}
                      className="form-control"
                      name="endDate"
                      onChange={(e) =>
                        this.props.handleTermChange(e, this.props.parentIndex)
                      }
                      min={
                        term.startDate !== ""
                          ? term.startDate
                          : this.props.minStartDate
                      }
                      max={this.props.maxEndDate}
                      value={term.endDate}
                      disabled={term.startDate !== "" ? false : true}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </>
    );
  }
}
