import React, { Component } from "react";
import logo from "../../assets/images/logo/powerLogo1.png";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
class SidebarFooterText extends Component {
  state = {
    footerLogoLink: "",
    footerLogoPreview: null,
    footerIsoLink: "",
    footerText: "",
    isFooterActive: false,
  };

  componentDidMount() {
    this.getWebsiteConfig();
  }

  getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, (res) => {
      if (res.status === 200) {
        let data = res.data.data;
        if (data.length > 0) {
          this.setState({
            footerText: data[0].footerText,
            footerLogoPreview: data[0].footerLogo,
            isFooterActive: data[0].isActiveFooter,
            footerLogoLink: data[0].footerLogoLink,
            footerIsoLink: data[0].footerIsoLink,
          });
        }
      }
    });
  };

  render() {
    return (
      <div>
        {/* {this.state.isFooterActive ? ( */}
        <div className="container-fluid px-0">
          <div className="row g-0">
            <div className="col text-center">
              <div className="sidebarFooter">
                <a href="https://techarttrekkies.com/" target="_blank">
                  {/* {this.state.footerLogoPreview ? (
                      <img
                        src={URL.imageSelectURL + this.state.footerLogoPreview}
                        alt="footer logo"
                      />
                    ) : ( */}
                  <img src={logo} alt="footer logo" />
                  {/* )} */}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* ) : null} */}
        {/* {this.state.isFooterActive ? (
          <div className="footerBg">
            <div className="container" style={{ height: "inherit" }}>
              <div className="row" style={{ height: "inherit" }}>
                <div className="col footerFlexDiv">
                  <div className="footerBox">
                    <div className="footerLogoBox">
                      <a
                        href={
                          this.state.footerLogoLink &&
                          this.state.footerLogoLink !== ""
                            ? this.state.footerLogoLink
                            : "https://techarttrekkies.com/"
                        }
                        target="_blank"
                      >
                        {this.state.footerLogoPreview ? (
                          <img
                            src={
                              URL.imageSelectURL + this.state.footerLogoPreview
                            }
                            alt="footer logo"
                          />
                        ) : (
                          <img src={logo} alt="footer logo" />
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
    );
  }
}

export default SidebarFooterText;
