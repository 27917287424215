import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { URL } from "../../../utils/Constants";

class McqResultTemplate extends Component {
  state = {};
  render() {
    let mcqResult = this.props.mcqResult;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.6vh" }}>
        <div className="row tt-mcqTestHead text-center">
          <div className="col-md-3">
            <strong>Total Answered: {this.props.totalAnswered}</strong>
          </div>
          <div className="col-md-3">
            <strong>Correct : {this.props.correctAnswer}</strong>{" "}
          </div>
          <div className="col-md-3">
            <strong>Incorrect : {this.props.incorrect}</strong>
          </div>
          <div className="col-md-3">
            <strong>Unanswered : {this.props.unanswered}</strong>
          </div>
        </div>
        <hr></hr>
        <div className="row tt-mcqTestHead text-center mt-2 p-2">
          <div className="col-md-6">
            <strong>Full Marks: {this.props?.fullMarks || 0}</strong>
          </div>
          <div className="col-md-6">
            <strong>Obtained Marks : {this.props?.obtainedMarks || 0}</strong>
          </div>
        </div>

        <hr></hr>
        {mcqResult.map((ques, idx) => {
          return (
            <div key={idx} className="tt-mcqQuestionHolder pb-2">
              <div className="question1">
                {idx + 1}) {ques.question}
              </div>
              <ul className="answerOptions mb-0 p-2">
                {ques.mcqQuestionOptions.map((opt, oIdx) => {
                  return (
                    <li
                      className={
                        "answerOption tt-mcqResultOption " +
                        (opt.id === ques.selectedOptionId
                          ? opt.isCorrect
                            ? "correctAnswer"
                            : "wrongAnswer"
                          : "")
                      }
                      key={oIdx}
                    >
                      <div className="row">
                        <div className="col-md-10">
                          {/* <FontAwesomeIcon
                            icon={faCircle}
                            className={
                              opt.id === ques.selectedOptionId
                                ? "correctOption"
                                : ""
                            }
                          ></FontAwesomeIcon> */}
                          <span className="ml-2">{oIdx + 1}.</span>
                          <span className="answerChoice">
                            {opt.mediaPath !== "" &&
                            opt.mediaType !== "" &&
                            !opt.optionValue ? (
                              <>
                                {opt.mediaType === "AUD" ? (
                                  <figure style={{ padding: "10px" }}>
                                    <figcaption>Listen:</figcaption>
                                    <audio
                                      controls
                                      src={`${URL.imageSelectURL}${opt.mediaPath}`}
                                    >
                                      <a
                                        href={`${URL.imageSelectURL}${opt.mediaPath}`}
                                      >
                                        Download audio
                                      </a>
                                    </audio>
                                  </figure>
                                ) : (
                                  <img
                                    src={`${URL.imageSelectURL}${opt.mediaPath}`}
                                    alt={opt.mediaPath}
                                    height={"250"}
                                    width={"250"}
                                    className="img-fluid"
                                  />
                                )}
                              </>
                            ) : (
                              opt.optionValue
                            )}
                          </span>
                        </div>
                        {/* <div className="col-md-2 text-center">
                          {opt.id === ques.selectedOptionId ? (
                            <span>
                              <FontAwesomeIcon
                                icon={
                                  opt.id === ques.selectedOptionId &&
                                  opt.isCorrect
                                    ? faCheck
                                    : faTimes
                                }
                                className={
                                  opt.id === ques.selectedOptionId &&
                                  opt.isCorrect
                                    ? "tt-correct"
                                    : "tt-wrong"
                                }
                              ></FontAwesomeIcon>
                            </span>
                          ) : null}
                        </div> */}
                      </div>
                    </li>
                  );
                })}
              </ul>
              {ques.mcqQuestionOptions.map((opt, oIdx) => {
                return (
                  <div className="mt-2">
                    {opt.isCorrect ? (
                      <div>
                        <div>
                          <strong>Correct option: </strong>
                          <span className="ml-2">
                            {opt.mediaPath !== "" &&
                            opt.mediaType !== "" &&
                            !opt.optionValue
                              ? oIdx + 1
                              : opt.optionValue}
                          </span>
                        </div>
                        {opt.explanation !== "" ? (
                          <>
                            <div>
                              <strong>Explanation: </strong>
                            </div>
                            <div>{ReactHtmlParser(opt.explanation)}</div>
                          </>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default McqResultTemplate;
