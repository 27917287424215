import moment from "moment";
import React, { Component } from "react";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import DragDropFile from "../../UI/DragDropFile";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class UserCreationModal extends Component {
  state = {
    name: "",
    dateOfBirth: "",
    email: "",
    gender: "",
    imagePreview: "",
    nationality: "",
    photo: "",
    photoFile: "",
    selectedData: "",
    spinner: false,
    countries: [],
    userRoles: [],
    userType: ""
  };

  componentDidMount() {
    this.getCountry();
    this.getUserType();
    if (this.props.editModal) {
      let userData = this.props.editData;
      this.setState({
        name: userData.name,
        nationality: userData.nationality,
        dateOfBirth: moment(userData.dateOfBirth).format("YYYY-MM-DD"),
        email: userData.emailAddress,
        gender: userData.gender,
        userType: userData.userRoleCode
      });
      if (userData.imagepath) {
        this.setState({
          imagePreview: URL.imageSelectURL + userData.imagepath
        });
      }
    }
  }

  getCountry = () => {
    axiosPost(URL.getCountry, {}, response => {
      if (response.status === 200) {
        this.setState({ countries: response.data.data });
      }
    });
  };

  getUserType = () => {
    const param = {};
    axiosPost(
      URL.getAdminFilteredRoles,
      param,
      response => {
        if (response.status === 200) {
          this.setState({ userRoles: response?.data?.data });
        }
      },
      () => {},
      true
    );
  };

  handleChange = e => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleImageChange = e => {
    if (e.target.files.length > 0) {
      this.setState(
        {
          photo: e.target.value,
          photoFile: e.target.files
        },
        function() {
          let image = window.URL.createObjectURL(this.state.photoFile[0]);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    if (files.length > 1) {
      swal("Error", "Please select only one file");
    } else {
      this.setState(
        {
          photo: name,
          photoFile: files
        },
        function() {
          let image = window.URL.createObjectURL(this.state.photoFile[0]);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleSubmit = () => {
    const {
      name,
      gender,
      dateOfBirth,
      email,
      nationality,
      userType,
      photoFile
    } = this.state;

    const { editData, editModal } = this.props;

    let param = {};

    if (!name || !gender || !dateOfBirth || !nationality || !userType) {
      swal("Warning", "Please fill in all required fields.");
      return;
    }

    if (editModal) {
      param = {
        id: editData.peopleId,
        name: name,
        gender: gender,
        dateOfBirth: dateOfBirth,
        nationality: nationality,
        roleCode: userType
      };
    } else {
      if (!email) {
        swal("Warning", "Please fill in all required fields.");
        return;
      }

      param = {
        name: name,
        gender: gender,
        dateOfBirth: dateOfBirth,
        emailAddress: email,
        nationality: nationality,
        roleCode: userType
      };
    }
    let formData = new FormData();
    if (photoFile !== "") {
      formData.append("file", this.state.photoFile[0]);
    } else {
      formData.append("file", null);
    }
    formData.append(editModal ? "jsonData" : "people", JSON.stringify(param));
    this.setState({ spinner: true }, function() {
      axiosPost(
        editModal ? URL.editUser : URL.userRegister,
        formData,
        response => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            swal(
              "Success",
              editModal
                ? "User updated successfully."
                : "User created successfully. Email credentials is forwarded to the user's email"
            );
            this.props.getAllUsers();
            this.props.toggleUserModal();
          }
        },
        err => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  removeImage = () => {
    this.setState({ photo: "", imagePreview: "", photoFile: "" });
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomSpinner text="Please Wait. Creating User..."></CustomSpinner>
        ) : (
          ""
        )}
        {this.state.imagePreview && this.state.imagePreview !== "" ? (
          <div className="row mb-2">
            <div className="col text-center">
              <div
                className="imageHolder"
                style={{ width: "90px", height: "90px" }}
              >
                <img src={this.state.imagePreview} />
              </div>
              {this.state.imagePreview && this.state.imagePreview !== "" ? (
                <div>
                  <button
                    className="btn btn-danger cusBtnDanger cusBtnFontSize"
                    onClick={this.removeImage}
                  >
                    Remove
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-md-3">
            <p className="formTitle">Photo</p>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <DragDropFile
                  handleChange={this.handleImageChange}
                  name="file"
                  handleFileDrop={this.handleFileDrop}
                  files={this.state.file}
                  multiple={false}
                  accept="image/png, image/jpeg, image/jpg"
                />
                {/* <input
                  accept="image/png, image/jpeg, image/jpg"
                  className="form-control cusInput"
                  name="photo"
                  onChange={this.handleImageChange}
                  type="file"
                  value={this.state.photo}
                /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-3">
            <p className="formTitle">User Type</p>
          </div>

          <div className="col">
            {/* <select
              className="form-control cusInput"
              value={this.state.userType}
              name="userType"
              onChange={this.handleChange}
            >
              <option selected>Choose User Type</option>
              <option value="admin">Admin</option>
              <option value="student">Student</option>
              <option value="teacher">Teacher</option>
              <option value="parents">Parents</option>
              <option value="others">Others</option>
            </select> */}
            <select
              className="form-control cusInput"
              name="userType"
              value={this.state.userType}
              onChange={this.handleChange}
            >
              <option value="" disabled>
                Choose User Types
              </option>
              {this.state.userRoles &&
                this.state.userRoles.map(items => {
                  return <option value={items.code}>{items.name}</option>;
                })}
            </select>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-3">
            <p className="formTitle">Name</p>
          </div>

          <div className="col">
            <input
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              className="form-control cusInput"
              placeholder="Enter full name"
            />
          </div>
        </div>
        {!this.props.editModal && (
          <div className="row mt-2">
            <div className="col-md-3">
              <p className="formTitle">Email</p>
            </div>
            <div className="col">
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                className="form-control cusInput"
                placeholder="Enter email address"
              />
            </div>
          </div>
        )}
        <div className="row mt-2">
          <div className="col-md-3">
            <p className="formTitle">Gender</p>
          </div>
          <div className="col">
            <select
              name="gender"
              value={this.state.gender}
              onChange={this.handleChange}
              className="form-control cusInputSelect"
              defaultValue=""
            >
              <option value="" disabled>
                Choose Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p className="formTitle">Date of Birth</p>
          </div>
          <div className="col">
            <input
              type="date"
              name="dateOfBirth"
              value={this.state.dateOfBirth}
              onChange={this.handleChange}
              className="form-control cusInput"
              placeholder="Enter your date of birth"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p className="formTitle">Nationality</p>
          </div>
          <div className="col">
            {/* <input
              type="text"
              name="nationality"
              value={this.state.nationality}
              onChange={this.handleChange}
              className="form-control cusInput"
              placeholder="Enter nationality"
            /> */}
            <select
              name="nationality"
              className="form-control cusInput"
              onChange={this.handleChange}
              value={this.state.nationality}
            >
              <option value="" disabled>
                Choose a nationality
              </option>
              {this.state.countries?.map((country, idx) => {
                return (
                  <option key={idx} value={country.id}>
                    {country.nationality ? country.nationality : country.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {this.state.userType === "RL_OTH" ? (
          <div className="row mt-2">
            <div className="col-md-3">
              <p className="formTitle">Remarks</p>
            </div>
            <div className="col">
              <input
                type="text"
                name="remarks"
                value={this.state.remarks}
                onChange={this.handleChange}
                className="form-control cusInput"
                placeholder="Enter remarks"
              />
            </div>
          </div>
        ) : null}

        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UserCreationModal;
