import React, { Component } from "react";
import { Spinner } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { checkRolePermissions } from "../../../utils/Constants";
import Pagination from "../../UI/Pagination";

const animatedComponents = makeAnimated();

class ListPhase extends Component {
  state = {
    permissionCanCreatePhase: false,
    permissionCanAssignTermToPhase: false,
    permissionCanEditPhase: false,
    permissionCanDeletePhase: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionCanCreatePhase: checkRolePermissions(
        "can_create_phase",
        "activity"
      ),
      permissionCanAssignTermToPhase: checkRolePermissions(
        "can_assign_term_to_phase",
        "activity"
      ),
      permissionCanEditPhase: checkRolePermissions(
        "can_edit_phase",
        "activity"
      ),
      permissionCanDeletePhase: checkRolePermissions(
        "can_delete_phase",
        "activity"
      ),
    });
  };

  render() {
    return (
      <div
        className="card mb-2 p-2 tt-lms"
        style={
          this.state.permissionCanCreatePhase
            ? { height: "46.8vh", overflow: "auto" }
            : { height: "auto" }
        }
      >
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Terms</th>
              {this.state.permissionCanAssignTermToPhase ||
              this.state.permissionCanEditPhase ||
              this.state.permissionCanDeletePhase ? (
                <th width="130px">Options</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {this.props.phaseList && this.props.phaseList.length > 0 ? (
              this.props.phaseList.map((phase, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {phase.isEditable ? (
                        <input
                          type="text"
                          className="form-control"
                          name="updateName"
                          value={this.props.updateName}
                          onChange={this.props.handleChange}
                        ></input>
                      ) : (
                        phase.name
                      )}
                    </td>
                    <td width="50%">
                      {phase.isEditable ? (
                        <textarea
                          name="updateDescription"
                          className="form-control"
                          value={this.props.updateDescription}
                          onChange={this.props.handleChange}
                        ></textarea>
                      ) : (
                        phase.description
                      )}
                    </td>

                    <td width="20%">
                      {phase.editTerm ? (
                        <Select
                          key={idx}
                          isClearable={true}
                          closeMenuOnScroll={false}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          value={this.props.editTermOption}
                          options={this.props.termOptions}
                          name="terms"
                          menuPlacement="auto"
                          placeholder="Select Terms"
                          onChange={this.props.handleEditTermValueChange}
                        ></Select>
                      ) : phase.courseTerms.length > 0 ? (
                        phase.courseTerms.map((term, tIdx) => {
                          return (
                            <span key={tIdx}>
                              <p>{term.name}</p>
                              <br></br>
                            </span>
                          );
                        })
                      ) : this.state.permissionCanAssignTermToPhase ? (
                        <Select
                          key={idx}
                          isClearable={true}
                          closeMenuOnScroll={false}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          value={phase.selectedTerms}
                          options={this.props.termOptions}
                          name="terms"
                          menuPlacement="auto"
                          placeholder="Select Terms"
                          onChange={(item) =>
                            this.props.handleTermChange(item, idx)
                          }
                          menuShouldScrollIntoView={true}
                          isDisabled={phase.isEditable}
                        ></Select>
                      ) : (
                        "No permission to assign"
                      )}
                    </td>
                    {this.state.permissionCanAssignTermToPhase ||
                    this.state.permissionCanEditPhase ||
                    this.state.permissionCanDeletePhase ? (
                      <td className="text-center">
                        {phase.courseTerms.length > 0 ? (
                          phase.editTerm && !phase.isEditable ? (
                            <>
                              <button
                                className="tt-button tt-button-primary tt-button-fullWidth"
                                onClick={this.props.updateCoursePhaseTerm}
                              >
                                Save
                              </button>
                              <button
                                className="tt-button tt-button-primary tt-button-fullWidth"
                                onClick={() => this.props.cancelEditTerm(idx)}
                              >
                                Cancel
                              </button>
                            </>
                          ) : phase.isEditable ? null : this.state
                              .permissionCanAssignTermToPhase ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={() => this.props.handleEditTerm(idx)}
                            >
                              Edit Terms
                            </button>
                          ) : null
                        ) : phase.isEditable ? null : this.state
                            .permissionCanAssignTermToPhase ? (
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={() => this.props.assignTerms(idx)}
                            disabled={phase.selectedTerms.length === 0}
                          >
                            Assign Terms
                          </button>
                        ) : null}
                        {phase.isEditable && !phase.editTerm ? (
                          <>
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={this.props.updatePhase}
                            >
                              Save
                            </button>
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={() => this.props.cancelUpdate(idx)}
                            >
                              Cancel
                            </button>
                          </>
                        ) : phase.editTerm ? null : this.state
                            .permissionCanEditPhase ? (
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth"
                            onClick={() => this.props.togglePhaseEditModal(idx)}
                          >
                            Edit
                          </button>
                        ) : null}
                        {!phase.isEditable && !phase.editTerm ? (
                          this.state.permissionCanDeletePhase ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={() => this.props.handleDelete(phase.id)}
                            >
                              Delete
                            </button>
                          ) : null
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="text-center"
                  colSpan={
                    this.state.permissionCanAssignTermToPhase ||
                    this.state.permissionCanEditPhase ||
                    this.state.permissionCanDeletePhase
                      ? 4
                      : 3
                  }
                >
                  {this.props.phaseSpinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading Data ...
                    </>
                  ) : (
                    "No data"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            itemsCount={this.props.phaseTotalRecords}
            pageSize={this.props.phaseRowCountLimit}
            currentPage={this.props.phaseCurrentPage}
            onPageChange={this.props.handlePageChange}
            nextPage={this.props.handleNextPage}
            previousPage={this.props.handlePreviousPage}
          ></Pagination>
        </div>
      </div>
    );
  }
}

export default ListPhase;
