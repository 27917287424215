import React, { Component } from "react";

export default class AssessmentModuleForm extends Component {
  defaultState = {
    educationLevelId: "",
    coordinatorId: "",
    assessmentModuleCode: "",
  };

  constructor(props) {
    super(props);
    this.state = this.defaultState;
  }

  reset = () => {
    this.setState({ ...this.defaultState });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.createRecord !== prevProps.createRecord ||
      this.props.formData !== prevProps.formData
    ) {
      this.setFormData();
    }
    if (prevProps.hasBeenAssigned !== this.props.hasBeenAssigned) {
      this.setState({
        educationLevelId: "",
        coordinatorId: "",
        assessmentModuleCode: "",
      });
    }
  }

  setFormData = () => {
    if (this.props.formData) {
      let formData = this.props.formData;
      this.setState({
        educationLevelId: formData.educationLevelId,
        coordinatorId: formData.recruitmentId,
        assessmentModuleCode: formData.assessmentModuleCode,
      });
    }
  };
  /**
   * This method return the JSON structure that need to been send through the promise
   */
  getParameter = () => {
    return {
      assessmentModuleCode: this.state.assessmentModuleCode,
      educationLevelId: this.state.educationLevelId,
      recruitmentId: this.state.coordinatorId,
    };
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (value.length > 0) {
      this.props.handleErrorChange(name);
    }
    this.setState({
      [name]: value,
    });
    if (name === "educationLevelId") {
      this.props.getAssessmentModuleList(value);
    }
  };
  render() {
    const errors = this.props.errors;
    return (
      <>
        {/* <div className="tt-group-header">{this.props.title}</div> */}
        <form onSubmit={(e) => this.props.handleSubmit(e, this.getParameter())}>
          <div className="form-group row">
            <div className="form-group col-md-2"></div>
            <div className="form-group col-md-3">
              <label htmlFor="educationLevelId">
                <strong>Education level</strong>
              </label>
              <span className="tt-assessment-module-mandatory">*</span>
            </div>
            <div className="form-group col-md-4">
              <select
                className={
                  errors.educationLevelId
                    ? "form-control indicate-error"
                    : "form-control"
                }
                type="text"
                name="educationLevelId"
                id="educationLevelId"
                onChange={(e) => this.handleChange(e)}
                value={this.state.educationLevelId}
                disabled={this.props.createRecord ? false : true}
              >
                <option value="" disabled>
                  Choose education level
                </option>
                {this.props.listOfEduLevels.length !== undefined ? (
                  <>
                    {this.props.listOfEduLevels.map((el, idx) => {
                      return (
                        <option value={el.id} key={idx}>
                          {el.name}
                        </option>
                      );
                    })}
                  </>
                ) : null}
              </select>
              {/* <sup>*</sup> */}
            </div>
          </div>

          <div className="form-group row">
            <div className="form-group col-md-2"></div>
            <div className="form-group col-md-3">
              <label htmlFor="assessmentModuleCode">
                <strong>Assessment module</strong>
              </label>
              <span className="tt-assessment-module-mandatory">*</span>
            </div>
            <div className="form-group col-md-4">
              <select
                className={
                  errors.assessmentModuleCode
                    ? "form-control indicate-error"
                    : "form-control"
                }
                type="text"
                name="assessmentModuleCode"
                id="assessmentModuleCode"
                onChange={(e) => this.handleChange(e)}
                value={this.state.assessmentModuleCode}
              >
                <option value="" disabled>
                  Choose assesment module
                </option>
                {this.props.assessmentModules !== undefined ? (
                  <>
                    {this.props.assessmentModules.map(
                      (assessmentModule, idx) => {
                        return (
                          <option value={assessmentModule.code} key={idx}>
                            {assessmentModule.name}
                          </option>
                        );
                      }
                    )}
                  </>
                ) : null}
              </select>
              {/* <sup>*</sup> */}
            </div>
          </div>

          <div className="form-group row">
            <div className="form-group col-md-2"></div>
            <div className="form-group col-md-3">
              <label htmlFor="coordinatorId">
                <strong>Coordinator</strong>
              </label>
              <span className="tt-assessment-module-mandatory">*</span>
            </div>
            <div className="form-group col-md-4">
              <select
                className={
                  errors.recruitmentId
                    ? "form-control indicate-error"
                    : "form-control"
                }
                type="text"
                name="coordinatorId"
                id="coordinatorId"
                onChange={(e) => this.handleChange(e)}
                value={this.state.coordinatorId}
              >
                <option value="" disabled>
                  Choose coordinator
                </option>
                {this.props.staffList !== undefined ? (
                  <>
                    {this.props.staffList.map((staff, idx) => {
                      return (
                        <option value={staff.id} key={idx}>
                          {staff.people.name}
                        </option>
                      );
                    })}
                  </>
                ) : null}
              </select>
            </div>
          </div>
          <div className="row button-bg-color">
            <div className="col-md-2"></div>
            <div className="col-md-3"></div>
            <div className="col-md-4 text-right">
              <button
                type="submit"
                className="tt-button tt-button-primary"
                value={this.props.createRecord ? `Submit` : `Update`}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
