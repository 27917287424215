import React, { Component } from "react";
import CustomSpinner from "../../components/common/CustomSpinner";
import Select from "react-select";

class AssignRoles extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="container">
        {this.props.userSpinner ? <CustomSpinner /> : null}
        <div className="row">
          <div className="col">
            <p className="formTitle ">
              Role Name:{" "}
              <span style={{ textDecoration: "normal", fontWeight: 300 }}>
                {this.props.selectedUserRole
                  ? this.props.selectedUserRole.roleName
                  : ""}
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/* <select
              multiple
              className="form-control"
              name="selectedUser"
              defaultValue=""
              value={this.props.selectedUsers}
              onChange={this.props.handleSelect}
            >
              <option value="" disabled>
                Select Users
              </option>
              {this.props.allUsers.length > 0 ? (
                this.props.allUsers.map((user, idx) => {
                  return (
                    <option value={user.id} key={idx}>
                      {user.name}
                    </option>
                  );
                })
              ) : (
                <option value="" disabled>
                  No Users
                </option>
              )}
            </select> */}
            <Select
              isMulti
              options={this.props.allUsers}
              value={this.props.selectedUsers}
              onChange={(u) => this.props.handleSelect(u)}
              placeholder="Select users"
              classNamePrefix="cusSelect"
              menuPlacement="auto"
              menuPosition="fixed"
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn float-right"
              onClick={this.props.assignUsertoRoles}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AssignRoles;
