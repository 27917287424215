import React, { Component } from "react";

class AddNewDesignation extends Component {
  state = {};
  render() {
    return (
      <div>
        {this.props.designationComponent ? (
          <>
            <div className="tt-group-header">Add New Designation</div>
            <form
              onSubmit={(e) =>
                this.props?.handleSubmit(e, this.props.getParameter())
              }
            >
              <div className="form-group row">
                <div className="form-group col-md-2"></div>
                <div className="form-group col-md-3">
                  <label htmlFor="departmentName">
                    <strong>Designation name</strong>
                    <span className="tt-assessment-module-mandatory">*</span>
                  </label>
                </div>
                <div className="form-group col-md-4">
                  <input
                    className={
                      this.props?.nameError
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    type="text"
                    ref={(input) => {
                      this.nameInput = input;
                    }}
                    name="designationName"
                    id="designationName"
                    onChange={(e) => this.props.handleChange(e)}
                    value={this.props?.designationName}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="form-group col-md-2"></div>
                <div className="form-group col-md-3">
                  <label htmlFor="details">
                    <strong className="optional">Details</strong>
                  </label>
                </div>
                <div className="form-group col-md-4">
                  <textarea
                    className="form-control"
                    name="details"
                    id="details"
                    onChange={(e) => this.props.handleChange(e)}
                    value={this.props?.details}
                  />
                </div>
              </div>
              <div className="row tt-group-header mb-0">
                <div className="col-md-2"></div>
                <div className="col-md-3"></div>
                <div className="col-md-4 text-right">
                  <button className="tt-button tt-button-primary" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <strong>Name: </strong>
                <span className="tt-assessment-module-mandatory">*</span>
              </div>
              <div className="col-md-9">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={this.props.name}
                  onChange={this.props.handleDesignationChange}
                ></input>
              </div>
            </div>
            <div className="row mt-2 mb-3">
              <div className="col-md-3">
                <strong>Details: </strong>
              </div>
              <div className="col-md-9">
                <textarea
                  name="details"
                  className="form-control"
                  value={this.props.details}
                  onChange={this.props.handleDesignationChange}
                ></textarea>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AddNewDesignation;
