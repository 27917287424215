import { axiosPost } from "../utils/AxiosApi";
import { URL, scaleTypeCode } from "../utils/Constants";
import { displayErrorAlert, showErroMessage } from "../utils/Utils";
import swal from "sweetalert";

// Actions
const subjectAreasLOAD = "subject-setup/subject-areas/LOAD";
const subjectsLOAD = "subject-setup/subjects/LOAD";
const assessmentListLOAD = "recording-setup/list-assessment/LOAD";
const assessmentWithScaleOrCommentLOAD = "reports/subject-assessment/LOAD";
const assessmentWithScaleOrCommentUPDATE = "reports/subject-assessment/LOAD";
const assessmentSubjectListLOAD = "reports/subject-assessment/subject/LOAD";
const assessmentSubjectListUPDATE = "reports/subject-assessment/subject/UPDATE";
const studentListLOAD = "reports/subject-assessment/student/LOAD";
const studentListUPDATE = "reports/subject-assessment/student/UPDATE";
const renderArrayLOAD = "subjectSetup/listSubjects/settings/LOAD";
const assessmentScaleLOAD = "recording-setup/list-assessment-scale/LOAD";
const assessmentScaleUPDATE = "recording-setup/list-assessment-scale/UPDATE";
const assessmentsLOAD = "recording-setup/assessments/LOAD";
const reportFormatLOAD = "reporting-setup/reportFormat/LOAD";
const subjectAreaSpinnerLOAD = "subjectArea/spinner";
const subjectSpinnerLOAD = "subject/spinner";
const publishAssessmentReportLOAD =
  "reports/publishAssessmentReport/yearLevels/LOAD";
const generalAssessmentLOAD = "reports/general-assessment-list/LOAD";
const generalAssessmentSpinnerLOAD =
  "reports/general-assessment-list/spinner/LOAD";
const generalAssessmentFinalizeClickable =
  "recording/general-assessment-finalize-clickable/LOAD";
const generalAssessmentFinalize = "recording/general-assessment-finalize/LOAD";
const assessmentListSpinnerLOAD = "recording/list-assessment/spinner/LOAD";
const reportFormatListSpinnerLOAD = "reporting/report-format-list/spinner/LOAD";
const publishAssessmentReportSpinnerLOAD =
  "reports/publish-assessment-reports/spinner/LOAD";
//reducer
export default function reducer(
  state = {
    subjectAreas: [],
    subjects: [],
    assessmentList: [],
    assessmentWithScaleOrComment: [],
    assessmentSubjectList: [],
    studentList: [],
    renderArray: [],
    assessmentScaleList: [],
    assessments: [],
    reportSetups: [],
    subjectAreaSpinner: true,
    subjectSpinner: true,
    assessmentScaleSpinner: true,
    yearLevels: [],
    generalAssessmentList: [],
    finalize: false,
    finalizeClickable: true,
    generalAssessmentListSpinner: true,
    assessmentListSpinner: true,
    reportFormatListSpinner: true,
    publishAssessmentReportSpinner: true,
    assessmentTotalRecords: 0,
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case subjectAreasLOAD:
      newState.subjectAreas = action.subjectAreas;
      return newState;
    case subjectsLOAD:
      newState.subjects = action.subjects;
      return newState;
    case assessmentListLOAD:
      newState.assessmentList = action.assessmentList;
      return newState;
    case assessmentWithScaleOrCommentLOAD:
      newState.assessmentWithScaleOrComment =
        action.assessmentWithScaleOrComment;
      return newState;
    case assessmentWithScaleOrCommentUPDATE:
      newState.assessmentWithScaleOrComment =
        action.assessmentWithScaleOrComment;
      return newState;
    case assessmentSubjectListLOAD:
      newState.assessmentSubjectList = action.assessmentSubjectList;
      return newState;
    case assessmentSubjectListUPDATE:
      newState.assessmentSubjectList = action.assessmentSubjectList;
      return newState;
    case studentListLOAD:
      newState.studentList = action.studentList;
      return newState;
    case studentListUPDATE:
      newState.studentList = action.studentList;
      return newState;
    case renderArrayLOAD:
      newState.renderArray = action.renderArray;
      return newState;
    case assessmentScaleLOAD:
      newState.assessmentScaleList = action.assessmentScaleList;
      newState.assessmentScaleSpinner = action.assessmentScaleSpinner;
      return newState;
    case assessmentScaleUPDATE:
      newState.assessmentScaleList = action.assessmentScaleList;
      return newState;
    case assessmentsLOAD:
      newState.assessments = action.assessments;
      newState.assessmentTotalRecords = action.assessmentTotalRecords;
      return newState;
    case reportFormatLOAD:
      newState.reportSetups = action.reportSetups;
      return newState;
    case subjectAreaSpinnerLOAD:
      newState.subjectAreaSpinner = action.subjectAreaSpinner;
      return newState;
    case subjectSpinnerLOAD:
      newState.subjectSpinner = action.subjectSpinner;
      return newState;
    case publishAssessmentReportLOAD:
      newState.yearLevels = action.yearLevels;
      return newState;
    case generalAssessmentLOAD:
      newState.generalAssessmentList = action.generalAssessmentList;
      return newState;
    case generalAssessmentFinalizeClickable:
      newState.finalizeClickable = action.finalizeClickable;
      return newState;
    case generalAssessmentFinalize:
      newState.finalize = action.finalize;
      return newState;

    case generalAssessmentSpinnerLOAD:
      newState.generalAssessmentListSpinner =
        action.generalAssessmentListSpinner;
      return newState;
    case assessmentListSpinnerLOAD:
      newState.assessmentListSpinner = action.assessmentListSpinner;
      return newState;
    case reportFormatListSpinnerLOAD:
      newState.reportFormatListSpinner = action.reportFormatListSpinner;
      return newState;
    case publishAssessmentReportSpinnerLOAD:
      newState.publishAssessmentReportSpinner =
        action.publishAssessmentReportSpinner;
      return newState;
    default:
      return newState;
  }
}

// Action Creators
export function loadSubjectAreas(subjectAreas) {
  return { type: subjectAreasLOAD, subjectAreas };
}

export function loadSubjects(subjects) {
  return { type: subjectsLOAD, subjects };
}
export function loadAssessmentList(assessmentList) {
  return { type: assessmentListLOAD, assessmentList };
}

export function loadAssessmentWithScaleorComment(assessmentWithScaleOrComment) {
  return {
    type: assessmentWithScaleOrCommentLOAD,
    assessmentWithScaleOrComment,
  };
}
export function updateAssessmentWithScaleorCommentAction(
  assessmentWithScaleOrComment
) {
  return {
    type: assessmentWithScaleOrCommentUPDATE,
    assessmentWithScaleOrComment,
  };
}

export function loadAssessmentSubjectList(assessmentSubjectList) {
  return {
    type: assessmentSubjectListLOAD,
    assessmentSubjectList,
  };
}

export function updateAssessmentSubjectListAction(assessmentSubjectList) {
  return {
    type: assessmentSubjectListUPDATE,
    assessmentSubjectList,
  };
}

export function loadStudentList(studentList) {
  return {
    type: studentListLOAD,
    studentList,
  };
}

export function updateStudentListAction(studentList) {
  return {
    type: studentListUPDATE,
    studentList,
  };
}

export function loadRenderArray(renderArray) {
  return {
    type: renderArrayLOAD,
    renderArray,
  };
}

export function loadAssessmentScales(
  assessmentScaleList,
  assessmentScaleSpinner
) {
  return {
    type: assessmentScaleLOAD,
    assessmentScaleList,
    assessmentScaleSpinner,
  };
}

export function updateAssessmentScales(assessmentScaleList) {
  return {
    type: assessmentScaleUPDATE,
    assessmentScaleList,
  };
}

export function loadAssessments(assessments, assessmentTotalRecords) {
  return { type: assessmentsLOAD, assessments, assessmentTotalRecords };
}

export function loadReportFormat(reportSetups) {
  return { type: reportFormatLOAD, reportSetups };
}

export function loadSubjectAreaSpinner(subjectAreaSpinner) {
  return { type: subjectAreaSpinnerLOAD, subjectAreaSpinner };
}

export function loadSubjectSpinner(subjectSpinner) {
  return { type: subjectSpinnerLOAD, subjectSpinner };
}

export function loadPublishAssessmentReport(yearLevels) {
  return { type: publishAssessmentReportLOAD, yearLevels };
}

export function loadGeneralAssessmentList(generalAssessmentList) {
  return { type: generalAssessmentLOAD, generalAssessmentList };
}

export function loadGeneralAssessmentFinalizeClickable(finalizeClickable) {
  return { type: generalAssessmentFinalizeClickable, finalizeClickable };
}

export function loadGeneralAssessmentFinalize(finalize) {
  return { type: generalAssessmentFinalize, finalize };
}

export function loadGeneralAssessmentSpinner(generalAssessmentListSpinner) {
  return { type: generalAssessmentSpinnerLOAD, generalAssessmentListSpinner };
}

export function loadAssessmentListSpinner(assessmentListSpinner) {
  return { type: assessmentListSpinnerLOAD, assessmentListSpinner };
}

export function loadReportFormatListSpinner(reportFormatListSpinner) {
  return { type: reportFormatListSpinnerLOAD, reportFormatListSpinner };
}

export function loadPublishAssessmentReportSpinner(
  publishAssessmentReportSpinner
) {
  return {
    type: publishAssessmentReportSpinnerLOAD,
    publishAssessmentReportSpinner,
  };
}

//api call that dispatches action with function
export function getRenderArray(renderArray) {
  return (dispatch) => {
    dispatch(loadRenderArray(renderArray));
  };
}

export function getSubjectAreas(code) {
  return (dispatch) => {
    let data = {
      assessmentModuleCode: code,
    };
    dispatch(loadSubjectAreaSpinner(true));
    axiosPost(
      URL.subjectAreaList,
      data,
      (response) => {
        if (response.status === 200) {
          dispatch(loadSubjectAreas(response.data.data));
          dispatch(loadSubjectAreaSpinner(false));
        }
        if (response.status === 204) {
          dispatch(loadSubjectAreas([]));
          dispatch(loadSubjectAreaSpinner(false));
        }
      },
      (error) => {
        dispatch(loadSubjectAreaSpinner(false));
        displayErrorAlert(error);
      }
    );
  };
}

export function getSubjects(code) {
  return (dispatch) => {
    let data = {
      assessmentModuleCode: code,
    };
    dispatch(loadSubjectSpinner(true));
    axiosPost(
      URL.subjectList,
      data,
      (response) => {
        if (response.status === 200) {
          dispatch(loadSubjects(response.data.data));
          dispatch(loadSubjectSpinner(false));
        }
        if (response.status === 204) {
          dispatch(loadSubjects([]));
          dispatch(loadSubjectSpinner(false));
        }
      },
      (error) => {
        displayErrorAlert(error);
        dispatch(loadSubjectSpinner(false));
      }
    );
  };
}

export function getAssessmnetList() {
  return (dispatch) => {
    axiosPost(URL.getAssessment, {}, (response) => {
      if (response.status === 200) {
        const assessmentList = response.data.data;
        dispatch(loadAssessmentList(assessmentList));
      }
    });
  };
}

export function getAssessmentWithScaleOrComment(idObject) {
  return (dispatch) => {
    dispatch(loadAssessmentWithScaleorComment([]));
    axiosPost(URL.getAssessmentWithScaleOrComment, idObject, (response) => {
      if (response.status === 200) {
        let assessments = response.data.data;
        assessments.forEach((assessment) => {
          assessment.isAssessmentOpen = false;
        });
        dispatch(loadAssessmentWithScaleorComment(assessments));
      }
    });
  };
}

export function updateAssessmentWithScaleOrComment(
  assessmentWithScaleOrComment
) {
  return (dispatch) => {
    dispatch(
      updateAssessmentWithScaleorCommentAction(assessmentWithScaleOrComment)
    );
  };
}

export function getAssessmentSubjectList(updatedIdObject) {
  return (dispatch) => {
    axiosPost(URL.getSubjectWithScaleOrComment, updatedIdObject, (response) => {
      if (response.status === 200) {
        let subjects = response.data.data;
        subjects.forEach((subject) => {
          subject.isSubjectOpen = false;
        });
        dispatch(loadAssessmentSubjectList(subjects));
      }
    });
  };
}

export function updateAssessmentSubjectList(assessmentSubjectList) {
  return (dispatch) => {
    dispatch(updateAssessmentSubjectListAction(assessmentSubjectList));
  };
}

export function getStudentList(studentIds, scaleParam) {}

export function updateStudentList(studentList) {
  return (dispatch) => {
    dispatch(updateStudentListAction(studentList));
  };
}

export function getAssessmentScales(code) {
  return (dispatch) => {
    let param = {
      assessmentModuleCode: code,
    };
    dispatch(loadAssessmentScales([], true));
    axiosPost(URL.selectAssessmentScale, param, (response) => {
      if (response.status === 200) {
        let assessmentScale = response.data.data;
        dispatch(loadAssessmentScales(assessmentScale, false));
      }
    });
  };
}

export function updateAssessmentScaleList(assessmentScaleList) {
  return (dispatch) => {
    dispatch(updateAssessmentScales(assessmentScaleList));
  };
}

export function getAssessments(code, offset, rowCountLimit) {
  return (dispatch) => {
    let data = {
      assessmentModuleCode: code,
      offset: offset,
      rowCountLimit: rowCountLimit,
    };
    dispatch(loadAssessments([], 0));
    dispatch(loadAssessmentListSpinner(true));
    axiosPost(URL.selectAssessments, data, (resp) => {
      if (resp.status === 200) {
        let assessments = resp.data.data;
        let assessmentTotalRecords = resp.data.totalRecordsCount;
        assessments.forEach((assessment) => {
          assessment.isOpen = false;
        });
        dispatch(loadAssessments(assessments, assessmentTotalRecords));
        dispatch(loadAssessmentListSpinner(false));
      } else {
        dispatch(loadAssessmentListSpinner(false));
      }
    });
  };
}

export function getReportFormatList(code) {
  let data = {
    assessmentModuleCode: code,
  };
  return (dispatch) => {
    dispatch(loadReportFormatListSpinner(true));
    axiosPost(URL.getReportSetupByAssessment, data, (response) => {
      if (response.status === 200) {
        let reportSetups = response.data.data;
        reportSetups.forEach((el) => {
          el.isOpen = false;
        });
        dispatch(loadReportFormat(reportSetups));
        dispatch(loadReportFormatListSpinner(false));
      } else {
        swal("Error", response.data.message, "");
        dispatch(loadReportFormatListSpinner(false));
      }
    });
  };
}

export function getYearLevelForPublishAssessmentReport(code) {
  let data = {
    assessmentModuleCode: code,
  };
  return (dispatch) => {
    dispatch(loadPublishAssessmentReportSpinner(true));
    axiosPost(
      URL.selectFinalizedAssessmentYearLevel,
      data,
      (response) => {
        if (response.status === 200) {
          const yearLevels = response.data.data;
          yearLevels.forEach((level) => {
            level.isYearLevelOpen = false;
            level.classroomYearLevelRecruitmentRoom.forEach((classroom) => {
              classroom.isClassroomOpen = false;
            });
          });
          dispatch(loadPublishAssessmentReport(yearLevels));
          dispatch(loadPublishAssessmentReportSpinner(false));
        } else {
          swal("Error", response.data.message, "");
          dispatch(loadPublishAssessmentReportSpinner(false));
        }
      },
      (err) => {
        dispatch(loadPublishAssessmentReportSpinner(false));
      }
    );
  };
}

export function getGeneralAssessmentList(code) {
  let data = {
    assessmentModuleCode: code,
  };
  return (dispatch) => {
    dispatch(loadGeneralAssessmentSpinner(true));
    axiosPost(URL.generalAssessmentSelectAll, data, (response) => {
      if (response.status === 200) {
        const generalAssessmentList = response.data.data;
        generalAssessmentList.forEach((year) => {
          year.isOpenYearLevel = false;
          year.classrooms.forEach((classes) => {
            classes.isClassRoomOpen = false;
          });
        });
        dispatch(loadGeneralAssessmentList(generalAssessmentList));
        dispatch(loadGeneralAssessmentSpinner(false));
      } else {
        swal("Error", response.data.message, "");
        dispatch(loadGeneralAssessmentSpinner(false));
      }
    });
  };
}

export function handleCheckAssessmentFinalize(
  assessmentId,
  classroomId,
  yearLevelId
) {
  let param = {
    assessmentId: assessmentId,
    classroomId: classroomId,
    yearLevelId: yearLevelId,
  };

  return (dispatch) => {
    axiosPost(URL.selectGeneralAssessmentIsFinalized, param, (response) => {
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          if (response.data.data[0].isFinalized) {
            let finalize = true;
            dispatch(loadGeneralAssessmentFinalize(finalize));
          } else {
            let finalize = false;
            dispatch(loadGeneralAssessmentFinalize(finalize));
          }
        } else {
          let finalize = false;
          dispatch(loadGeneralAssessmentFinalize(finalize));
        }
      }
    });
  };
}

export function handleCheckFinalizeClickable(
  assessmentId,
  classroomId,
  yearLevelId
) {
  let param = {
    assessmentId: assessmentId,
    classroomId: classroomId,
    yearLevelId: yearLevelId,
  };

  return (dispatch) => {
    axiosPost(URL.selectFinalizeClickable, param, (response) => {
      if (response.status === 200) {
        const finalizeClickable = response.data.data.finalizeClickable;
        dispatch(loadGeneralAssessmentFinalizeClickable(finalizeClickable));
      } else {
        showErroMessage(response);
      }
    });
  };
}
