import React, { Component } from "react";

class CreateCourseTerm extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-1">
            <strong>
              Name:
              <span
                className="tt-assessment-module-mandatory"
                style={{ paddingLeft: "5px" }}
              >
                *
              </span>
            </strong>
          </div>
          <div className="col-md-4" style={{ display: "flex" }}>
            <input
              type="text"
              className="form-control"
              name="name"
              value={this.props.name}
              onChange={this.props.handleChange}
            ></input>
            {/* <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span> */}
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-1">
            <strong>
              Description:
              <span
                className="tt-assessment-module-mandatory"
                style={{ paddingLeft: "5px" }}
              >
                *
              </span>
            </strong>
          </div>
          <div className="col-md-4">
            <textarea
              name="description"
              className="form-control"
              value={this.props.description}
              onChange={this.props.handleChange}
            ></textarea>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row button-bg-color mt-2">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.props.createCourse}
            >
              Create
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.clearState}
            >
              Cancel
            </button>
          </div>
          <div className="col-md-3"></div>
        </div>
      </>
    );
  }
}

export default CreateCourseTerm;
