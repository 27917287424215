import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomPageHeader from "../common/CustomPageHeader";

export class CentralFeedbackList extends Component {
  state = {
    feedbackLists: []
  };

  componentDidMount() {
    this.getFeedbackList();
  }

  getFeedbackList = () => {
    axiosPost(
      URL.getAllFeedbacks,
      {},
      response => {
        if (response.status === 200) {
          this.setState({
            feedbackLists: response.data.data
          });
        }
      },
      err => {
        displayErrorAlert(err);
      }
    );
  };

  render() {
    return (
      <div>
        <div className="whiteContainer">
          <CustomPageHeader title="Feedbacks" />
          <table className="table cusTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Contact No.</th>
                <th>Feedback Type</th>
                <th>Description</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {this.state.feedbackLists.length > 0 ? (
                this.state.feedbackLists.map((feedback, index) => {
                  return (
                    <tr>
                      <td>{feedback?.name ? feedback?.name : "-"}</td>
                      <td>{feedback?.email ? feedback?.email : "-"}</td>
                      <td>
                        {feedback?.contactNumber
                          ? feedback?.contactNumber
                          : "-"}
                      </td>
                      <td>
                        {feedback?.feedbackTypeName
                          ? feedback?.feedbackTypeName
                          : "-"}
                      </td>
                      <td>
                        {feedback?.description ? feedback?.description : "-"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={5}>
                    No records to display!!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default CentralFeedbackList;
