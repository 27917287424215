import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomButton from "../../components/common/CustomButton";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import CustomEditButton from "../../components/common/CustomEditButton";
import CustomModal from "../../components/common/CustomModal";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayConfirmDeleteAlert } from "../../utils/DisplayMessage";
import { displayErrorAlert } from "../../utils/Utils";

export class ListSubject extends Component {
  state = {
    id: "",
    spinner: false,
    editModal: false,
    file: "",
    name: "",
    description: "",
    thumbnailPath: "",
    fieldErr: false,
    display: true,
    isGeneral: "",
  };
  deleteSubject = (datum) => {
    let data = {
      id: datum.id,
    };
    axiosPost(URL.deleteSubject, data, (response) => {
      if (response.status === 200) {
        this.props.getAllSubjects();
        swal("Success", response.data.message);
      }
    });
  };

  handleDelete = (id, idx) => {
    displayConfirmDeleteAlert({ id: id, idx: idx }, this.deleteSubject);
  };

  handleEditClick = (data) => {
    this.setState(
      {
        name: data.name,
        description: data.description,
        thumbnailPath: data.thumbnailPath,
        id: data.id,
        display: data.display,
        isGeneral: data.isGeneral,
      },
      () => {
        this.setState({
          editModal: true,
        });
      }
    );
  };

  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeImage = (e) => {
    this.setState({
      thumbnailPath: "",
      file: e.target.files[0],
    });
  };

  handleUpdate = () => {
    let err = false;
    if (this.state.name === "" || this.state.description === "") {
      err = true;
      this.setState({
        fieldErr: true,
      });
    }

    let formData = new FormData();

    let param = {
      id: this.state.id,
      name: this.state.name,
      description: this.state.description,
      display: this.state.display,
      isGeneral: this.state.isGeneral,
    };

    formData.append("jsonData", JSON.stringify(param));

    if (this.state.file !== "") {
      formData.append("file", this.state.file);
    }

    if (!err) {
      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(
            URL.updateSubjectCore,
            formData,
            (response) => {
              if (response.status === 200) {
                this.setState({
                  spinner: false,
                  editModal: false,
                });
                this.props.getAllSubjects();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                spinner: false,
              });
            }
          );
        }
      );
    }
  };

  render() {
    return (
      <>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Subject Name</th>
                  <th>Description</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.subjects?.length > 0
                  ? this.props.subjects?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.name}</td>
                          <td>{item?.description}</td>
                          <td>
                            <img
                              width="100"
                              src={URL.imageSelectURL + item.thumbnailPath}
                            />
                          </td>
                          <td>
                            <CustomEditButton
                              onClick={() => this.handleEditClick(item)}
                            />
                            <CustomDeleteButton
                              onClick={() => this.handleDelete(item.id, index)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>

          <CustomModal
            size="md"
            modal={this.state.editModal}
            toggleModal={() => {
              this.setState({
                editModal: !this.state.editModal,
              });
            }}
            modalHeader="Update Subject"
            modalBody={
              <div className="container">
                <div className="row mt-3">
                  <label htmlFor="name">Name</label>
                  <input
                    className={`${
                      this.state.fieldErr && this.state.name == ""
                        ? "form-control cusInput border border-danger"
                        : "form-control cusInput"
                    }`}
                    name="name"
                    type="text"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.name}
                    placeholder="Enter subject name here"
                  />
                  {this.state.fieldErr && this.state.name == "" ? (
                    <small className="errorMsg-textField">
                      Name is required
                    </small>
                  ) : null}
                </div>
                <div className="row mt-3">
                  <label htmlFor="description">Description</label>

                  <input
                    className={`${
                      this.state.fieldErr && this.state.description == ""
                        ? "form-control cusInput border border-danger"
                        : "form-control cusInput"
                    }`}
                    name="description"
                    type="text"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.description}
                    placeholder="Enter subject description here"
                  />
                  {this.state.fieldErr && this.state.description == "" ? (
                    <small className="errorMsg-textField">
                      Description is required
                    </small>
                  ) : null}
                </div>
                <div className="row mt-3">
                  {this.state.thumbnailPath !== "" ? (
                    <img
                      style={{ height: "250px" }}
                      src={URL.imageSelectURL + this.state.thumbnailPath}
                      alt="No preview available"
                      className="img-fluid p-4"
                    />
                  ) : (
                    <img
                      style={{ height: "250px" }}
                      src={
                        this.state.file !== "" &&
                        window.URL.createObjectURL(this.state.file)
                      }
                      alt="No preview available"
                      className="img-fluid p-4"
                    />
                  )}
                  <input
                    className="form-control cusInput mt-2"
                    name="image"
                    type="file"
                    onChange={(e) => this.handleChangeImage(e)}
                  />
                  <div className="py-2">
                    <CustomButton text="Update" onClick={this.handleUpdate} />
                  </div>{" "}
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  }
}

export default ListSubject;
