import React from "react";

function AttendanceList(props) {
  return (
    <table className="table text-center table-bordered table-striped">
      <thead className="tt-group-header">
        <tr>
          <th className="text-center">
            <input
              type="checkbox"
              style={{ position: "relative", top: "3px" }}
              name="checkAll"
              onChange={props.checkAll}
              checked={props.checkedAll}
              disabled={props.selectedAttendance.some((s) => s.id)}
            />
          </th>
          <th>Student</th>
          <th>Remarks</th>
        </tr>
      </thead>
      <tbody>
        {props.students && props.students.length > 0 ? (
          props.selectedAttendance.map((student, idx) => {
            return (
              <React.Fragment key={idx}>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      name="isPresent"
                      onChange={(e) => props.studentAttendanceChange(e, idx)}
                      checked={student.isPresent}
                      disabled={student.id}
                    />
                  </td>
                  <td>{student.studentName}</td>
                  <td>
                    <input
                      type="textarea"
                      name="remarks"
                      onChange={(e) => props.studentAttendanceChange(e, idx)}
                      value={student.remarks}
                      disabled={student.id}
                    />
                  </td>
                </tr>
              </React.Fragment>
            );
          })
        ) : (
          <tr>
            <td colSpan="3">No Students</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default AttendanceList;
