import React, { Component } from "react";

const Weekday = props => {
  const { weekday } = props;
  return (
    <>
      {weekday
        ? weekday.map((el, idx) => {
            return <div key={idx}> {el.name}</div>;
          })
        : null}
    </>
  );
};

export default Weekday;
