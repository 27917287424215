import React, { Component } from "react";
import Chip from "@material-ui/core/Chip";

class SubjectAndScalesList extends Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="mt-2">
              <strong>Choose your Subjects: </strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </div>
            <div className="tt-subjectAssessmentList" style={{ marginTop: 0 }}>
              <select
                multiple
                id="subjects"
                name="subjectsSel"
                className="form-control"
                value={this.props.subjectsSel}
                onChange={this.props.handleSubjectSelectChange}
              >
                {this.props.subjects.map((subject, idx) => {
                  return (
                    <option value={subject.id} key={idx}>
                      {subject.name} -{" "}
                      {subject.hasTheory && subject.hasPractical
                        ? "Theory-Practical"
                        : subject.hasTheory
                        ? "Theory"
                        : "Practical"}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mt-2">
              <strong>Choose Assessment Scales: </strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </div>
            <div className="tt-subjectAssessmentList" style={{ marginTop: 0 }}>
              <select
                multiple
                id="scalsesSel"
                name="scalsesSel"
                className="form-control"
                value={this.props.scalsesSel}
                onChange={this.props.handleAssessmentScaleSelectChange}
              >
                {this.props.assessmentScales.map((scale, idx) => {
                  return (
                    <option value={scale.id} key={idx}>
                      {scale.scaleName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <i>
              *Hold <strong>Ctrl</strong> to select multiple options.
            </i>
          </div>
          <div className="col-md-4"></div>
        </div>
      </>
    );
  }
}

export default SubjectAndScalesList;
