import React from "react";

function CustomButton(props) {
  return (
    <button
      className={"cusBtn " + props.customStyle}
      onClick={props.onClick}
      style={props.style}
      type={props.type}
    >
      {props.text}
    </button>
  );
}

export default CustomButton;
