import { axiosPost } from "../utils/AxiosApi";
import { URL } from "../utils/Constants";

// Actions
const mcqLOAD = "MCQ_LOAD";

export default function reducer(
  state = {
    practiseTest: [],
    examTest: [],
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case mcqLOAD:
      newState.practiseTest = action.practiseTest;
      newState.examTest = action.examTest;
      return newState;
    default:
      return newState;
  }
}

// Action Creators
export function loadMcqList(practiseTest, examTest) {
  return { type: mcqLOAD, practiseTest, examTest };
}

//Dispatch Function

export function getMcqList(mcqType) {
  return (dispatch) => {
    axiosPost(URL.getMcqList, {}, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        let practiseTest = [];
        let examTest = [];
        if (datum && datum.length > 0) {
          for (let i = 0; i < datum.length; i++) {
            if (mcqType === "my") {
              if (
                parseInt(datum[i].testTypeId) === 1 &&
                datum[i].creatorPeopleId ===
                  parseInt(localStorage.getItem("peopleId"))
              ) {
                examTest.push(datum[i]);
              }
              if (
                parseInt(datum[i].testTypeId) === 2 &&
                datum[i].creatorPeopleId ===
                  parseInt(localStorage.getItem("peopleId"))
              ) {
                practiseTest.push(datum[i]);
              }
            } else {
              if (
                parseInt(datum[i].testTypeId) === 1 &&
                datum[i].creatorPeopleId !==
                  parseInt(localStorage.getItem("peopleId"))
              ) {
                examTest.push(datum[i]);
              }
              if (
                parseInt(datum[i].testTypeId) === 2 &&
                datum[i].creatorPeopleId !==
                  parseInt(localStorage.getItem("peopleId"))
              ) {
                practiseTest.push(datum[i]);
              }
            }
          }
        }
        dispatch(loadMcqList(practiseTest, examTest));
      }
    });
  };
}
