import React, { Component } from "react";
import { Link } from "react-router-dom";

class GroupSearchResult extends Component {
  state = {};
  render() {
    let props = this.props;
    return (
      <div className="row">
        {props.searchData.map((data, idx) => {
          return (
            <div className="col-md-4">
              <Link
                to={{
                  pathname: data.name
                    ? `/groupPage/${data?.name.replace(/ /g, "_")}`
                    : "",
                }}
              >
                <div className="groupSearchBox">
                  {/* <p className="groupSearchTitle">{data?.name}</p>
                  <p className="groupSearchContent">{data?.description}</p> */}
                  <p className="groupSearchTitle line-clamp-1">{data?.name}</p>
                  <p className="groupSearchContent line-clamp-3">
                    {data?.description}
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}

export default GroupSearchResult;
