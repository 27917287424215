import React, { Component } from "react";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "../../assets/scss/attendance.scss";
// import Summary from "./Summary";
import DailySummary from "./DailySummary";
import { checkRolePermissions } from "../../utils/Constants";
import DailyAttendanceSummary from "./DailyAttendanceSummary";
// import DetailedSummary from "./DetailedSummary";

export default class AttendanceHistory extends Component {
  state = {
    tabId: "summary",
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79vh" }}>
        <div className="tt-group-header">Attendance Details</div>
        <div
          className="tt-postTabs tt-inner-content"
          style={{ height: "79vh" }}
        >
          <Tabs
            onChange={(tabId) => this.setState({ tabId })}
            className="tt-userSetting"
          >
            <TabList style={{ marginBottom: "0 !important" }}>
              {/* <Tab tabFor="summary">Summary</Tab> */}
              {checkRolePermissions("select-daily-summary", "activity") ? (
                <Tab tabFor="dailySummary">Daily Summary</Tab>
              ) : null}
              {checkRolePermissions("daily-summary", "activity") ? (
                <Tab tabFor="dailyAttendanceSummary">
                  Daily Attendance Summary
                </Tab>
              ) : null}
              {/* {checkRolePermissions("select-detailed-summary", "activity") ? (
                <Tab tabFor="detailedSummary">Detailed Summary</Tab>
              ) : null} */}
            </TabList>
            {/* <TabPanel
              tabId="summary"
              style={{ padding: "20px" }}
              render={() =>
                this.state.tabId === "summary" ? <Summary /> : null
              }
            /> */}
            {checkRolePermissions("select-daily-summary", "activity") ? (
              <TabPanel
                tabId="dailySummary"
                style={{ padding: "20px" }}
                render={() =>
                  this.state.tabId === "dailySummary" ? <DailySummary /> : null
                }
              />
            ) : null}
            {checkRolePermissions("daily-summary", "activity") ? (
              <TabPanel
                tabId="dailyAttendanceSummary"
                style={{ padding: "20px", height: "79vh" }}
                render={() =>
                  this.state.tabId === "dailyAttendanceSummary" ? (
                    <DailyAttendanceSummary />
                  ) : null
                }
              />
            ) : null}

            {/* {checkRolePermissions("select-detailed-summary", "activity") ? (
              <TabPanel
                tabId="detailedSummary"
                style={{ padding: "20px" }}
                render={() =>
                  this.state.tabId === "detailedSummary" ? (
                    <DetailedSummary />
                  ) : null
                }
              />
            ) : null} */}
          </Tabs>
        </div>
      </div>
    );
  }
}
