import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { validateFile } from "../../components/Validation/Validation";
import ModalWindow from "../../UI/ModalWindow";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import DragDropFile from "../../UI/DragDropFile";

class CurrentTrainingForm extends Component {
  state = {
    staffModal: false,
    name: "",
    link: "",
    description: "",
    hostedBy: "",
    hostDesc: "",
    hasExam: false,
    error: false,
    showSpinner: false,
    files: null,
    selectedStaff: [],
    staffLists: [],
  };

  handleChange = (e, idx) => {
    let { name, value, files, checked } = e.target;

    if (name === "hasExam") {
      this.setState({
        [name]: checked,
      });
    } else {
      this.setState({ [name]: files ? files : value });
    }

    let filesArr = [];
    if (files !== null && files !== undefined) {
      for (const key of Object.keys(files)) {
        filesArr.push(files[key]);
      }
      this.setState({
        files: filesArr,
      });
    }
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    this.setState({
      [name]: files,
    });

    let filesArr = [];
    if (files !== null) {
      for (const key of Object.keys(files)) {
        filesArr.push(files[key]);
      }
      this.setState({
        files: filesArr,
      });
    }
  };

  handleSave = () => {
    let error = false;
    let param = {};
    if (this.state.name === "" || this.state.description === "") {
      error = true;
    }
    param = {
      name: this.state.name,
      link: this.state.link,
      description: this.state.description,
      hostName: this.state.hostedBy,
      hostDetail: this.state.hostDesc,
      hasExams: this.state.hasExam,
    };

    let editParam = {
      id: this.props.isUpdate ? this.props.editTrainingData.id : null,
      name: this.state.name,
      link: this.state.link,
      description: this.state.description,
      hostName: this.state.hostedBy,
      hostDetail: this.state.hostDesc,
      hasExams: this.state.hasExam,
    };
    if (error === true) {
      swal("Error", "Training name and description is required");
      return;
    }
    let formData = new FormData();

    formData.append("currentTraining", JSON.stringify(param));

    let files = this.state.files;

    if (files !== null && !this.props.isUpdate) {
      files.map((file) => formData.append("files", file));

      // for (const key of Object.keys(files)) {
      //   formData.append("files", files[key]);
      // }
    }

    let url = this.props.isUpdate ? URL.updateAllTraining : URL.insertTraining;
    let paramData = this.props.isUpdate ? editParam : formData;
    this.setState(
      {
        showSpinner: true,
      },
      () => {
        axiosPost(
          url,
          paramData,
          (response) => {
            if (response.status === 200) {
              this.props.getAllTraining();
              this.setState({
                CurrentTrainingForms: [{ name: "", link: "", description: "" }],
                showSpinner: false,
              });
              swal("Success", response.data.message);
              this.props.toggleModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              showSpinner: false,
            });
          }
        );
      }
    );
  };

  componentDidMount() {
    if (this.props.editTrainingData) {
      this.setState({
        name: this.props.editTrainingData.name,
        link: this.props.editTrainingData.link,
        description: this.props.editTrainingData.description,
        hostedBy: this.props.editTrainingData.hostName,
        hostDesc: this.props.editTrainingData.hostDetail,
        hasExam: this.props.editTrainingData.hasExams,
      });
    }
  }
  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}

        <>
          <p className="formTitle text-uppercase mb-2">Training Details</p>
          <div className="mt-2 d-flex justify-content-start align-items-center">
            <input
              className="mr-3 ml-2"
              type="checkbox"
              name="hasExam"
              onChange={(e) => this.handleChange(e)}
              checked={this.state.hasExam}
            />
            <label className="text-muted text-uppercase">
              <b>Exam</b>
            </label>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <input
                className={`${
                  this.state.error == true && this.state.name == ""
                    ? "form-control cusInput is-invalid"
                    : "form-control cusInput "
                }`}
                name="name"
                type="text"
                onChange={(e) => this.handleChange(e)}
                value={this.state.name}
                placeholder="Enter name here"
              />
              {this.state.error == true && this.state.name == "" && (
                <div className="errorMsg-textField mt-1">
                  *Training name is required!
                </div>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <input
                className="form-control cusInput"
                name="link"
                type="text"
                onChange={(e) => this.handleChange(e)}
                value={this.state.link}
                placeholder="Enter link here"
              />
            </div>
            <div className="col-lg-4 mt-3">
              <textarea
                name="description"
                onChange={(e) => this.handleChange(e)}
                value={this.state.description}
                className="form-control cusDesc"
                placeholder="Enter description here"
              />
            </div>
          </div>

          <p className="formTitle text-uppercase mt-2">Host Details</p>
          <div className="row ">
            <div className="col-md-4 ">
              <input
                className="form-control cusInput"
                name="hostedBy"
                type="text"
                onChange={(e) => this.handleChange(e)}
                value={this.state.hostedBy}
                placeholder="Enter name here"
              />
            </div>

            <div className="col-md-4 ">
              <textarea
                className="form-control cusDesc"
                name="hostDesc"
                type="text"
                onChange={(e) => this.handleChange(e)}
                value={this.state.hostDesc}
                placeholder="Enter host details"
              />
            </div>
          </div>

          {!this.props.editModal ? (
            <div className="row mt-3">
              <div className="col ">
                <DragDropFile
                  multiple={true}
                  handleChange={this.handleChange}
                  name="files"
                  handleFileDrop={this.handleFileDrop}
                  files={this.state.files}
                  accept="image/png, image/jpeg, image/jpg"
                />

                {/* <input
                multiple
                accept="image/png, image/jpeg, image/jpg"
                className="form-control cusInput"
                name="files"
                type="file"
                onChange={(e) => this.handleChange(e)}
              /> */}
              </div>
            </div>
          ) : null}
        </>

        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default CurrentTrainingForm;
