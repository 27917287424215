import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import CustomCollapse from "../../UI/CustomCollapse";
import CollapsibleSemester from "./CollapsibleSemester";
import CollapsibleSemesterForClasses from "./CollapsibleSemesterForClasses";

class CollapsiblePhaseSemester extends Component {
  state = {};

  displayTable = (phase) => {
    return this.props.type === "classes" ? (
      <CollapsibleSemesterForClasses
        phase={phase}
      ></CollapsibleSemesterForClasses>
    ) : (
      <CollapsibleSemester phase={phase}></CollapsibleSemester>
    );
  };

  render() {
    let course = this.props.programPhaseSemester;
    return (
      <React.Fragment>
        {course.coursePhases && course.coursePhases.length > 0 ? (
          course.coursePhases.map((phase, idx) => {
            return (
              <div key={idx} className="pl-2 pr-2 pt-2">
                <div className="container-fluid">
                  <div
                    className="row tt-phase-header"
                    onClick={(e) => this.props.togglePhaseDetails(e, idx)}
                  >
                    <div className="col">{phase.name}</div>
                    <div className="col-md-1 text-center">
                      <FontAwesomeIcon
                        icon={phase.isOpen ? faAngleUp : faAngleDown}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                  <CustomCollapse
                    isOpen={phase.isOpen}
                    collapsibleBody={this.displayTable(phase)}
                    collapseClass="mt-2"
                  ></CustomCollapse>
                </div>
              </div>
            );
          })
        ) : this.props.type === "classes" ? (
          <CollapsibleSemesterForClasses
            phase={this.props.programPhaseSemester}
          ></CollapsibleSemesterForClasses>
        ) : (
          <CollapsibleSemester
            phase={this.props.programPhaseSemester}
          ></CollapsibleSemester>
        )}
      </React.Fragment>
    );
  }
}

export default CollapsiblePhaseSemester;
