import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import Select from "react-select";
import swal from "sweetalert";

class CentralGroupForm extends Component {
  state = {
    name: "",
    description: "",
    usersList: [],
    groupPeople: [],
    groupId: "",
  };

  componentDidMount() {
    this.getAllUsers();
    if (this.props.editData && this.props.isEdit && this.props.groupMembers) {
      let gp = this.props.groupMembers;
      gp.forEach((a) => {
        a.label = a.name;
        a.value = a.peopleId.toString();
      });
      this.setState({
        name: this.props.editData.name,
        description: this.props.editData.description,
        groupPeople: gp,
        groupId: this.props.groupMembers[0].groupId,
      });
    }
  }

  getAllUsers = () => {
    axiosPost(
      URL.getAllCombinedUsers,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({ usersList: response.data.data });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleUsersSelect = (items) => {
    this.setState({ groupPeople: items });
  };

  handleChange = (e) => {
    let { name, type, value, checked } = e.target;
    this.setState({ [name]: type === "checkbox" ? checked : value });
  };

  createGroup = () => {
    if (this.state.name !== "" && this.state.groupPeople.length > 0) {
      const data = {
        id: this.props.isEdit ? this.state.groupId : null,
        name: this.state.name,
        description: this.state.description,
        groupPeople: this.state.groupPeople,
      };

      let url = this.props.isEdit ? URL.updateCoreGroups : URL.insertGroupCore;
      axiosPost(
        url,
        data,
        (response) => {
          if (response.status === 200) {
            this.props.toggleCreateModal();
            swal({
              title: "Success",
              text: "Group has been successfully created.",
              allowOutsideClick: false,
              closeOnClickOutside: false,
            });
            this.props.getAllGroups();
            this.setState({
              name: "",
              description: "",
              groupPeople: [],
            });
          }
        },
        (error) => {
          displayErrorAlert(error);
          this.setState({ showSpinner: false });
        }
      );
    } else {
      swal("Warning", "Please fill the users in the group and group name.");
    }
  };

  // componentDidMount() {
  //   if (this.props.editData && this.props.isEdit) {
  //     this.setState({
  //       name: this.props.editData.name
  //     });
  //   }
  // }

  render() {
    const { name, description, usersList, groupPeople } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={usersList}
                  value={groupPeople}
                  onChange={(items) => this.handleUsersSelect(items)}
                  placeholder="Select users for group"
                  classNamePrefix="cusSelect"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12 text-end mb-2">
            <input
              type="text"
              name="name"
              value={name}
              className="form-control cusInput"
              onChange={this.handleChange}
              placeholder="Enter group name"
            />
          </div>
          <div className="col-md-12 text-end">
            {/* <input
              type="text"
              name="description"
              value={description}
              className="form-control cusInput"
              onChange={this.handleChange}
              placeholder="Enter description"
            /> */}
            <textarea
              rows={5}
              name="description"
              value={description}
              className="form-control cusDesc"
              onChange={this.handleChange}
              placeholder="Enter description"
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize float-right"
                  onClick={this.createGroup}
                >
                  {this.props.isEdit ? "Update Group" : "Create group"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CentralGroupForm;
