import React, { Component } from "react";
import loginImage from "../../assets/images/logoImagine.png";

class AboutUs extends Component {
  state = {};

  componentDidMount() {
    // this.effect();
    this.changeAnimationHeight();
  }

  changeAnimationHeight = () => {
    let element = document.getElementById("scrollWrapper");
    let scrollHeight = 0;
    if (element !== null) {
      scrollHeight = element.scrollHeight - 15;
    }
    element.style.setProperty("--foo", "-" + scrollHeight + "px");
  };

  render() {
    return (
      <div
        className="tt-login-image mobileAboutLogin"
        style={{ borderBottomRightRadius: "7px" }}
      >
        <div className="mobileAboutUsLogoDisplay">
          <img
            src={loginImage}
            alt="Not Loading"
            className="img-fluid tt-login-logo"
          />
        </div>
        <div className="outer-wrapper" id="outerWrapper">
          <div className="wrapper" id="scrollWrapper">
            <p>
              Based on the implemented system{" "}
              <a
                href="https://web.archive.org/web/20110314052431/http://inquirylearning.com/inner.php?sid=2&link_url=inquirysys.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                InquirySys v1
              </a>{" "}
              originally developed by Shubhojoy Mitra (Inquiry Learning Pty
              Ltd., Melbourne/Inquiry Learning Solutions, Kolkata) in 2006,
              ImagineSys is a complete school academic administration system now
              designed and developed by Tech@rt Trekkies Pvt. Ltd., Kathmandu
            </p>
            <br></br>
            <p>
              Anish Silakar, Sandeep Shakya, Suyog Manandhar, Saurav Sitaula,
              Sushant Sapkota, Pralhad Man Ranjit, Priansu Koirala, Ajay Kumar
              Mahato
            </p>
            <br></br>
            <p className="mb-0">Azzeta Karmacharya (Chief of Projects)</p>
            <p className="mb-0">
              Rachhita Dhungel (Chief of Communications and Research)
            </p>
            <p className="mb-0">Hari Sapkota (Director)</p>
            <p className="mb-0">Sanjeev Mainaly (Managing Director)</p>
            <p>Shubhojoy Mitra (CEO)</p>
            <br></br> <br></br>
            <p>© Tech@rt Trekkies Pvt. Ltd., 2020</p>
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
