import React, { Component } from "react";

class DialogBoxTemplate extends Component {
  state = {};
  render() {
    return (
      <div className="p-5">
        <div>
          {this.props.failed ? (
            <span className="dialogBoxCongrats">Time UP !!! </span>
          ) : (
            <span className="dialogBoxCongrats">Congratulations !!! </span>
          )}
          <br />
          <span className="dialogBoxBody">{this.props.name}</span>
        </div>
        <div className="dialogBoxBody">
          {this.props.failed ? (
            <>Sorry, Your time is up. Please start new game</>
          ) : (
            <>
              You completed the game in <strong>{this.props.moves}</strong>{" "}
              moves with{" "}
              <strong>
                {this.props.min} : {this.props.sec < 10 ? 0 : ""}
                {this.props.sec}
              </strong>{" "}
              minutes remaining.
            </>
          )}
        </div>
        <div className="mt-3" style={{ textAlign: "center" }}>
          <button
            className="tt-button tt-button-primary"
            onClick={this.props.closeDialog}
          >
            Start New Game
          </button>
        </div>
      </div>
    );
  }
}

export default DialogBoxTemplate;
