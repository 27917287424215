import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomModal from "../../components/common/CustomModal";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import CustomEditButton from "../../components/common/CustomEditButton";
import { displayConfirmDeleteAlert } from "../../utils/DisplayMessage";
import CustomPageHeader from "../../components/common/CustomPageHeader";

class MenuList extends Component {
  state = {
    subMenuModal: false,
    subMenus: [],
    editModal: false,
    editData: {},
    error: false,
  };

  deleteMenu = (datum) => {
    let data = {
      id: datum.id,
    };

    axiosPost(URL.deleteMenu, data, (response) => {
      if (response.status === 200) {
        let subMenus1 = [...this.state.subMenus];
        subMenus1.splice(datum.idx, 1);
        this.setState({ subMenus: subMenus1 }, () => {
          this.props.getAllMenu();
          swal("Success", response.data.message);
        });
      }
    });
  };

  handleDelete = (id, idx) => {
    displayConfirmDeleteAlert({ id: id, idx: idx }, this.deleteMenu);
  };

  toggleSubMenuModal = (subMenus) => {
    this.setState(
      {
        subMenuModal: !this.state.subMenuModal,
      },
      () => {
        if (this.state.subMenuModal) {
          this.setState({
            subMenus: subMenus,
          });
        } else {
          this.setState({
            subMenus: [],
          });
        }
      }
    );
  };

  handleChange = (e, item) => {
    const { name, checked, value, type } = e.target;

    this.setState({
      [name]: type === "checkbox" ? checked : value,
    });
  };

  handleSubmit = () => {
    if (
      this.state.display === "" ||
      this.state.nameEn == "" ||
      this.state.nameNp === "" ||
      this.state.link === ""
    ) {
      this.setState({
        error: true,
      });
    } else {
      let data = {
        id: this.state.editData.id,
        display: this.state.display,
        nameEn: this.state.nameEn,
        nameNp: this.state.nameNp,
        link: this.state.link,
      };

      axiosPost(URL.changeMenuStatus, data, (response) => {
        if (response.status === 200) {
          swal("Success", "Data updated successfully");
          this.toggleEditModal();
          this.props.getAllMenu();
        }
      });
    }
  };

  handleEdit = (item) => {
    this.setState({
      editData: item,
      display: item.display,
      nameEn: item.nameEn,
      nameNp: item.nameNp,
      link: item.link,

      editModal: true,
    });
  };

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal,
    });
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let data = {
      id: item.id,
      display: checked,
      nameEn: item.nameEn,
      nameNp: item.nameNp,
      link: item.link,
    };

    axiosPost(URL.changeMenuStatus, data, (response) => {
      if (response.status === 200) {
        this.props.getAllMenu();
      }
    });
  };

  render() {
    return (
      <>
        <div className="row">
          {this.props.subMenu == false ? null : (
            <div className="col-lg-12">
              <p className="formTitle text-uppercase mb-2">
                <th>Sub Menu List</th>
              </p>
            </div>
          )}
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  <th>English Name</th>
                  <th>Nepali Name</th>
                  <th>Link</th>
                  {/* {this.props.subMenu === false ? <th>Sub Menu</th> : null} */}
                  <th>Display</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.menus.length > 0
                  ? this.props.menus.map((menu, index) => {
                      return (
                        <tr>
                          <td>{menu.nameEn}</td>
                          <td>{menu.nameNp}</td>
                          <td>{menu.link}</td>
                          {/* {this.props.subMenu == false ? (
                            <th>
                              {" "}
                              <td>
                                <span
                                  className="material-icons-round text-success pointer"
                                  aria-hidden="true"
                                  onClick={() =>
                                    this.toggleSubMenuModal(menu.subMenus)
                                  }
                                >
                                  add_circle
                                </span>
                              </td>
                            </th>
                          ) : null} */}
                          <td>
                            <label className="switch mt-2">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  this.handleChangeToggle(e, menu)
                                }
                                checked={menu.display}
                                name="display"
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>

                          {/* <td>{menu.display ? "Active" : "Inactive"}</td> */}

                          <td>
                            {/* <CustomDeleteButton
                              onClick={() => this.handleDelete(menu.id, index)}
                            /> */}
                            <CustomEditButton
                              onClick={() => this.handleEdit(menu)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        {this.props.subMenu == false ? (
          <CustomModal
            size="lg"
            modal={this.state.subMenuModal}
            modalBody={
              <MenuList
                menus={this.state.subMenus}
                getAllMenu={this.props.getAllMenu}
                subMenu={true}
              />
            }
            fullscreen="md"
            toggleModal={this.toggleSubMenuModal}
          ></CustomModal>
        ) : null}

        <CustomModal
          size="lg"
          modal={this.state.editModal}
          modalHeader="Edit menu"
          modalBody={
            <div className="container">
              <div className="row py-3">
                <div className="col-md-3">
                  <label htmlFor="nameEn">Englist Name</label>

                  <input
                    className={`form-control cusInput ${
                      this.state.error &&
                      this.state.nameEn == "" &&
                      "is-invalid"
                    }`}
                    name="nameEn"
                    value={this.state.nameEn}
                    onChange={(e) => this.handleChange(e)}
                    type="text"
                    placeholder="Enter english name"
                  />

                  {this.state.error && this.state.nameEn === "" && (
                    <div className="errorMsg-textField mt-1">
                      *English name is required!
                    </div>
                  )}
                </div>

                <div className="col-lg-3">
                  <label htmlFor="nameNp">Nepali Name</label>

                  <input
                    className={`form-control cusInput ${
                      this.state.error &&
                      this.state.nameNp == "" &&
                      "is-invalid"
                    }`}
                    name="nameNp"
                    value={this.state.nameNp}
                    onChange={(e) => this.handleChange(e)}
                    type="text"
                    placeholder="Enter nepali name"
                  />
                  {this.state.error && this.state.nameNp == "" && (
                    <div className="errorMsg-textField mt-1">
                      *Nepali name is required!
                    </div>
                  )}
                </div>
                <div className="col-lg-3">
                  <label htmlFor="link">Link</label>

                  <input
                    className={`form-control cusInput ${
                      this.state.error && this.state.link == "" && "is-invalid"
                    }`}
                    name="link"
                    value={this.state.link}
                    onChange={(e) => this.handleChange(e)}
                    type="text"
                    placeholder="Enter link"
                  />
                  {this.state.error && this.state.link == "" && (
                    <div className="errorMsg-textField mt-1">
                      *Menu Link is required!
                    </div>
                  )}
                </div>

                {/* <div className="col-md-3">
                  <label htmlFor="display">Display</label>
                  <div>
                    <label className="switch mt-2">
                      <input
                        type="checkbox"
                        onChange={(e) => this.handleChange(e)}
                        checked={this.state.display}
                        name="display"
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div> */}
                <div className="col text-right mt-2">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={this.handleSubmit}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          }
          fullscreen="md"
          toggleModal={this.toggleEditModal}
        ></CustomModal>
      </>
    );
  }
}

export default MenuList;
