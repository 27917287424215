import React, { Component } from "react";
import { checkWidgetAdminOrNot } from "../../../utils/Constants";
import { formatDate } from "../../../utils/Utils";
import { Link } from "@material-ui/core";
import { Collapse, Spinner } from "reactstrap";
import ModalWindow from "../../UI/ModalWindow";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import ReportingConfiguration from "./ReportingConfiguration";
import { connect } from "react-redux";
import { getReportFormatList } from "../../../ducks/PrimaryARRDucks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

class ReportingConfigurationList extends Component {
  state = {
    reportSetups: [],
    assessmentId: "",
    educationLevelId: "",
    setup: [],
    modal: false,
  };
  componentDidMount() {
    this.props.getReportFormatList(this.props.code);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return { reportSetups: nextProps.reportSetups };
  }

  handleReportSetupClick = (e, id, setup, eduLevelId) => {
    e.preventDefault();
    this.setState(
      { assessmentId: id, setup: setup, educationLevelId: eduLevelId },
      function () {
        this.toggleModal();
      }
    );
  };
  toggleModal = (e) => {
    this.setState({ modal: !this.state.modal });
  };

  openCollapsible = (e, idx) => {
    let reportSetups = [...this.state.reportSetups];
    reportSetups.forEach((el, i) => {
      if (i === idx) {
        el.isOpen = !el.isOpen;
      } else {
        el.isOpen = false;
      }
    });
    this.setState({ reportSetups });
  };

  render() {
    let reportSetups = this.state.reportSetups;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          Report Format List
          {checkWidgetAdminOrNot(this.props.name) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="card" style={{ maxHeight: "69.6vh", overflow: "auto" }}>
          <div className="card-body">
            {reportSetups ? (
              reportSetups.length > 0 ? (
                reportSetups.map((reportSetup, idx) => (
                  <div className="card">
                    <div
                      className="card-header tt-group-header"
                      onClick={(e) => {
                        this.openCollapsible(e, idx);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {reportSetup.assessmentName}
                      <span
                        style={{
                          fontSize: "20px",
                          position: "absolute",
                          top: "6px",
                          right: "15px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={reportSetup.isOpen ? faAngleUp : faAngleDown}
                        />
                      </span>
                    </div>
                    <Collapse isOpen={reportSetup.isOpen}>
                      <div className="card-body">
                        <table className="table table-striped table-bordered">
                          <thead className="tt-group-header">
                            <tr>
                              <th>Report Name</th>
                              <th>Publishing Date</th>
                              <th>Publisher</th>
                              <th>Total No. of Pages</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reportSetup.reportSetups.map((setups, sIdx) => {
                              return (
                                <tr key={sIdx}>
                                  <td>
                                    <Link
                                      href="#"
                                      onClick={(e) =>
                                        this.handleReportSetupClick(
                                          e,
                                          reportSetup.id,
                                          setups,
                                          reportSetup.educationLevelId
                                        )
                                      }
                                    >
                                      {setups.name}
                                    </Link>
                                  </td>
                                  <td>{formatDate(setups.publishingDate)}</td>
                                  <td>{setups.recruitmentName}</td>
                                  <td>{setups.reportSetupPages.length}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Collapse>
                  </div>
                ))
              ) : this.props.reportFormatListSpinner ? (
                <div className="text-center">
                  <Spinner color="primary"></Spinner>
                  <br></br>Loading Data...
                </div>
              ) : (
                <div className="text-center">No records found.</div>
              )
            ) : (
              <div className="text-center">No records found.</div>
            )}
          </div>
        </div>
        <ModalWindow
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          centered
          fullWidth={true}
          modalHeader="Update Report Format"
          modalBody={
            <ReportingConfiguration
              code={this.props.code}
              update={true}
              setup={this.state.setup}
              assessmentId={this.state.assessmentId}
              educationLevelId={this.state.educationLevelId}
              closeModal={this.toggleModal}
            ></ReportingConfiguration>
          }
        />
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Reporting Setup"
              header="View Reporting Format List"
              activityName="select-report-format"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapActionToProps = {
  getReportFormatList,
};

const mapStateToProps = (state, props) => ({
  reportSetups: state.primaryArr.reportSetups,
  reportFormatListSpinner: state.primaryArr.reportFormatListSpinner,
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(ReportingConfigurationList);
