import { axiosPost } from "../utils/AxiosApi";
import { URL } from "../utils/Constants";

//Actions
const recruitmentConfigurationLOAD = "recruitment/configuration/LOAD";
const staffsLOAD = "recruitment/staffs/LOAD";
const staffListPaginationLOAD = "recruitment/staffs/Pagination/LOAD";

//reducer
export default function reducer(
  state = {
    recruitmentConfiguration: [],
    staffs: [],
    totalRecords: 0,
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case recruitmentConfigurationLOAD:
      newState.recruitmentConfiguration = action.recruitmentConfiguration;
      return newState;
    case staffsLOAD:
      newState.staffs = action.staffs;
      return newState;
    case staffListPaginationLOAD:
      newState.staffs = action.staffs;
      newState.totalRecords = action.totalRecords;
      return newState;
    default:
      return newState;
  }
}

//Action Creators
export function loadRecruitmentConfiguration(recruitmentConfiguration) {
  return { type: recruitmentConfigurationLOAD, recruitmentConfiguration };
}

export function loadStaffs(staffs) {
  return { type: staffsLOAD, staffs };
}

export function loadStaffListPagination(staffs, totalRecords) {
  return {
    type: staffListPaginationLOAD,
    staffs,
    totalRecords,
  };
}

//api call that dispatches action with function
export function getRecruitmentConfiguration(crudName, peopleId) {
  return (dispatch) => {
    dispatch(loadRecruitmentConfiguration([]));
    axiosPost(
      URL.getRecruitmentConfiguration,
      {
        crudName,
        peopleId,
      },
      (response) => {
        if (response.status === 200) {
          const recruitmentConfiguration = response.data.data;
          recruitmentConfiguration.forEach((config) => {
            config.customTableFields.forEach((field) => {
              // field.value = "";
              // field.year = null;
              // field.month = null;
              // field.day = null;
              field.gender = null;
              field.rows = [];
              field.rows.push({
                rowId: 0,
              });
              return field.customFieldTableColumns?.forEach((col) => {
                col.rows = [];
                col.rows.push({
                  rowId: 0,
                });
              });
            });
          });
          dispatch(loadRecruitmentConfiguration(recruitmentConfiguration));
        }
      }
    );
  };
}

export function getStaffList(id) {
  return (dispatch) => {
    axiosPost(URL.getStaffList, { recruitmentStatusId: id }, (response) => {
      if (response.status === 200) {
        const staffs = response.data.data;
        staffs.forEach((el) => {
          el.status = false;
        });
        dispatch(loadStaffs(staffs));
      }
    });
  };
}

export function getStaffListPagination(id, offset, rowCountLimit, staffTypeId) {
  return (dispatch) => {
    let data = {
      recruitmentStatusId: id,
      offset: offset,
      rowCountLimit: rowCountLimit,
      staffTypeId: staffTypeId,
    };
    axiosPost(URL.getStaffList, data, (response) => {
      if (response.status === 200) {
        let totalRecords = response.data.totalRecordsCount;
        const staffs = response.data.data;
        staffs.forEach((el) => {
          el.status = false;
        });
        dispatch(loadStaffListPagination(staffs, totalRecords));
      }
    });
  };
}
