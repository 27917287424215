import React, { Component } from "react";
import "../../assets/scss/performanceAnalysis.scss";
import { axiosPost } from "../../utils/AxiosApi";
import { profilePeopleId, URL as URLs } from "../../utils/Constants";
import StudentInfoDiv from "./StudentInfoDIv";
import userImage from "../../assets/images/user-image.png";
import StudentContactDetails from "./StudentContactDetails";
import StudentPerformanceChart from "./StudentPerformanceChart";
import StudentTerminalMarks from "./StudentTerminalMarks";
import StudentTerminalMarksChart from "./StudentTerminalMarksChart";
import { displayErrorAlert } from "../../utils/Utils";

class PerformanceAnalysis extends Component {
  state = {
    image: userImage,
    profilePicture: null,
    terminalMarks: [
      {
        name: "English",
        fullMarks: "100",
        passMarks: "40",
        obtainedMarks: "65",
      },
      {
        name: "English II",
        fullMarks: "100",
        passMarks: "40",
        obtainedMarks: "55",
      },
      {
        name: "Science",
        fullMarks: "100",
        passMarks: "40",
        obtainedMarks: "85",
      },
      {
        name: "Math I",
        fullMarks: "100",
        passMarks: "40",
        obtainedMarks: "99",
      },
      {
        name: "Math II",
        fullMarks: "100",
        passMarks: "40",
        obtainedMarks: "80",
      },
      {
        name: "Nepali",
        fullMarks: "100",
        passMarks: "40",
        obtainedMarks: "65",
      },
    ],
    userData: "",
  };

  componentDidMount() {
    this.getProfilePicture();
    this.getUserProfileInfo();
  }

  getUserProfileInfo = () => {
    let data = {
      peopleId: this.props.profilePeopleId
        ? this.props.profilePeopleId
        : profilePeopleId,
    };
    axiosPost(
      URLs.userProfileInfo,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({
            userData: response.data.data,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getProfilePicture = () => {
    let data = {
      id: this.props.profilePeopleId
        ? this.props.profilePeopleId
        : profilePeopleId,
    };

    axiosPost(URLs.getProfilePicture, data, (response) => {
      if (
        response.status === 200 &&
        response.data.data !== null &&
        response?.data?.data?.name !== null
      ) {
        let newPath = response.data.data?.name.replace(
          "profilePics",
          "136x136"
        );
        this.setState({
          image: URLs.imageSelectURL + newPath,
          profilePicture: response.data.data,
        });
      }
    });
  };

  handleError = (e) => {
    let self = this;
    self.setState({ image: userImage });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-9">
            <StudentContactDetails
              contactDetails={this.state.userData.parents}
            />
            <StudentPerformanceChart />
            <StudentTerminalMarks terminalMarks={this.state.terminalMarks} />
            <StudentTerminalMarksChart />
          </div>
          <div className="col-md-3">
            <StudentInfoDiv
              image={this.state.image}
              handleError={this.handleError}
              userData={this.state.userData}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PerformanceAnalysis;
