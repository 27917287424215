import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getClassroomLevels,
  getYearLevels,
  getClassroomLocations,
  getGroups,
} from "../../../../ducks/AcademicAdministrationDucks";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import {
  displayErrorAlert,
  displayErrorMessage,
  displaySuccessAlert,
} from "../../../../utils/Utils";
import swal from "sweetalert";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import NoteDisplay from "../../../../utils/noteDisplay";
import { Spinner } from "reactstrap";

class CreateClassroom extends Component {
  state = {
    regEx: /^[0-9a-zA-Z!.;':" ]*$/,
    yearLevelId: "",
    classroomTeacherId: "",
    classroomLocationId: "",
    name: localStorage.getItem("classroom"),
    yearLevels: [],
    classroomTeachers: [],
    classroomLocations: [],
    assignedClassroomLocations: [],
    isAvailable: false,
    render: false,
    errors: {
      yearLevelId: false,
      classroomTeacherId: false,
      classroomLocationId: false,
      name: false,
    },
  };

  componentDidMount() {
    this.props.getYearLevels();
    this.staffStatus();
    this.getRooms();
    this.getAssignedRooms();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { yearLevels: nextProps.yearLevels };
  }

  getRooms = () => {
    axiosPost(URL.unAssignedRoom, {}, (response) => {
      if (response.status === 200) {
        this.setState({ classroomLocations: response.data.data });
      }
    });
  };

  getAssignedRooms = () => {
    axiosPost(URL.assignedRoom, {}, (response) => {
      if (response.status === 200) {
        this.setState({ assignedClassroomLocations: response.data.data });
      }
    });
  };

  getYearLevel = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  staffStatus = () => {
    axiosPost(
      URL.getRecruitmentStatus,
      {},
      (response) => {
        if (response.status === 200) {
          let status = response.data.data;
          let id = "";
          status.forEach((el) => {
            if (el.name === "Current") {
              id = el.id;
            }
          });
          this.getStaffList(id);
        }
      },
      (err) => {
        this.setState({ render: true });
      }
    );
  };

  getStaffList = (id) => {
    axiosPost(
      URL.getStaffList,
      { recruitmentStatusId: id },
      (response) => {
        if (response.status === 200) {
          this.setState({ classroomTeachers: response.data.data }, function () {
            this.checkAvailability();
          });
        }
      },
      (err) => {
        this.setState({ render: true });
      }
    );
  };

  checkAvailability = () => {
    if (
      this.state.classroomTeachers.length > 0 &&
      this.state.yearLevels.length > 0 &&
      (this.state.classroomLocations.length > 0 ||
        this.state.assignedClassroomLocations.length > 0)
    ) {
      this.setState({ render: true, isAvailable: true });
    } else {
      this.setState({ render: true, isAvailable: false });
    }
  };

  handleDataChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let newErrors = { ...this.state.errors };
    if (value.length > 0) {
      newErrors[name] = false;
    }
    if (name === "name") {
      if (!value.match(this.state.regEx)) {
        let errorCharacter = value.slice(-1);
        swal({
          title: "Warning",
          text: `${errorCharacter} not allowed`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value, errors: newErrors });
      }
    } else {
      this.setState({ [name]: value, errors: newErrors });
    }
  };

  handleSubmit = (e, params) => {
    e.preventDefault();
    if (
      this.state.name === "" ||
      this.state.classroomLocationId === "" ||
      this.state.classroomTeacherId === "" ||
      this.state.yearLevelId === ""
    ) {
      swal({
        title: "Error",
        text: "Fill all the necessary fields: Section name, Class, Section Teacher and Section Location.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      let newErrors = { ...this.state.errors };
      Object.entries(this.state.errors).forEach((error) => {
        const [key, value] = error;
        if (this.state[key] === "") {
          newErrors[key] = true;
        }
      });
      this.setState({ errors: newErrors });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertClassroom,
          params,
          (response) => {
            if (response.status === 200) {
              displaySuccessAlert(response, this.props.getClassroomLevels);
              this.props.getGroups(this.props.profilePeopleId);
              const newClassroomLocations =
                this.state.classroomLocations.filter(
                  (room) => room.id !== parseInt(params.roomId)
                );
              this.setState(
                {
                  yearLevelId: "",
                  classroomTeacherId: "",
                  classroomLocationId: "",
                  name: localStorage.getItem("classroom"),
                  yearLevels: "",
                  showSpinner: false,
                  classroomLocations: newClassroomLocations,
                },
                () => {
                  this.checkAvailability();
                }
              );
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            displayErrorMessage(error);
          }
        );
      });
    }
  };

  getParameters = () => {
    return {
      name: this.state.name,
      yearLevelId: this.state.yearLevelId,
      recruitmentId: this.state.classroomTeacherId,
      roomId: this.state.classroomLocationId,
    };
  };

  render() {
    const { errors } = this.state;
    return this.state.render ? (
      <>
        {this.state.isAvailable ? (
          <>
            <div
              className="tt-widgetContent-tab-holder"
              style={{ height: "79.5vh" }}
            >
              <div className="tt-group-header">
                Create {localStorage.getItem("classroom")}
                {checkWidgetAdminOrNot("Academic Administration") === true ? (
                  <button
                    className="tt-button tt-button-primary float-right permissionBtnCSS"
                    onClick={() => {
                      this.setState({
                        permissionModal: !this.state.permissionModal,
                      });
                    }}
                  >
                    Permissions
                  </button>
                ) : null}
              </div>
              <div className="container">
                <form
                  onSubmit={(e) => this.handleSubmit(e, this.getParameters())}
                >
                  <div className="form-group row">
                    <div className="col-md-2"></div>
                    <div className="col-md-3">
                      <label htmlFor="classroomName">
                        <strong>
                          {localStorage.getItem("classroom") || "Class"} Name
                        </strong>
                      </label>
                      <span className="tt-assessment-module-mandatory">*</span>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className={
                          errors.name
                            ? "form-control indicate-error"
                            : "form-control"
                        }
                        name="name"
                        placeholder={`Enter ${localStorage.getItem(
                          "classroom"
                        )} Name`}
                        value={this.state.name}
                        onChange={this.handleDataChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2"></div>
                    <div className="col-md-3">
                      <label htmlFor="yearLevelId">
                        <strong>
                          {localStorage.getItem("yearLevel") || "Class"}
                        </strong>
                      </label>
                      <span className="tt-assessment-module-mandatory">*</span>
                    </div>
                    <div className="col-md-4">
                      <select
                        name="yearLevelId"
                        className={
                          errors.yearLevelId
                            ? "form-control indicate-error"
                            : "form-control"
                        }
                        value={this.state.yearLevelId}
                        onChange={this.handleDataChange}
                      >
                        <option value="" disabled>
                          Choose a {localStorage.getItem("yearLevel")}
                        </option>
                        {this.state.yearLevels
                          ? this.state.yearLevels.map((yearLevel, idx) => {
                              return (
                                <option key={idx} value={yearLevel.id}>
                                  {localStorage.getItem("yearLevel")}{" "}
                                  {yearLevel.name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2"></div>
                    <div className="col-md-3">
                      <label htmlFor="classroomTeacherId">
                        <strong>
                          {localStorage.getItem("classroom")} Teacher
                        </strong>
                      </label>
                      <span className="tt-assessment-module-mandatory">*</span>
                    </div>
                    <div className="col-md-4">
                      <select
                        className={
                          errors.classroomTeacherId
                            ? "form-control indicate-error"
                            : "form-control"
                        }
                        name="classroomTeacherId"
                        value={this.state.classroomTeacherId}
                        onChange={this.handleDataChange}
                      >
                        <option value="" disabled>
                          Choose {localStorage.getItem("classroom")} Teacher
                        </option>
                        {this.state.classroomTeachers
                          ? this.state.classroomTeachers.map(
                              (classTeacher, idx) => {
                                return (
                                  <option key={idx} value={classTeacher.id}>
                                    {classTeacher.people.name}
                                  </option>
                                );
                              }
                            )
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-2"></div>
                    <div className="col-md-3">
                      <label htmlFor="classroomLocationId">
                        <strong>
                          {localStorage.getItem("classroom") || "Class"}{" "}
                          Location
                        </strong>
                        <span className="tt-assessment-module-mandatory">
                          *
                        </span>
                      </label>
                    </div>
                    <div className="col-md-4">
                      <select
                        name="classroomLocationId"
                        className={
                          errors.classroomLocationId
                            ? "form-control indicate-error"
                            : "form-control"
                        }
                        value={this.state.classroomLocationId}
                        onChange={this.handleDataChange}
                      >
                        <option value="" disabled>
                          Choose a {localStorage.getItem("classroom")} Location
                        </option>
                        <optgroup label="Unassigned Rooms">
                          {this.state.classroomLocations.length > 0 ? (
                            this.state.classroomLocations.map((room, idx) => {
                              let roomName = ` ${room.name}(${room.number}) - ${
                                room.buildingName
                              }${
                                room.buildingSectionAliasName !== ""
                                  ? `, ${room.buildingSectionAliasName}`
                                  : ""
                              }${
                                room.buildingSectionName !== null
                                  ? ` ${room.buildingSectionName}`
                                  : ""
                              }, ${room.floorName}`;
                              return (
                                <option key={idx} value={room.id}>
                                  {roomName}
                                </option>
                              );
                            })
                          ) : (
                            <option value="noopt" disabled>
                              No Options
                            </option>
                          )}
                        </optgroup>
                        <optgroup label="Assigned Rooms">
                          {this.state.assignedClassroomLocations
                            ? this.state.assignedClassroomLocations.map(
                                (room, idx) => {
                                  let roomName = ` ${room.name}(${
                                    room.number
                                  }) - ${room.buildingName}${
                                    room.buildingSectionAliasName !== ""
                                      ? `, ${room.buildingSectionAliasName}`
                                      : ""
                                  }${
                                    room.buildingSectionName !== null
                                      ? ` ${room.buildingSectionName}`
                                      : ""
                                  }, ${room.floorName}`;
                                  return (
                                    <option key={idx} value={room.id} disabled>
                                      {roomName}
                                    </option>
                                  );
                                }
                              )
                            : "No options"}
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div className="row button-bg-color">
                    <div className="col-md-2"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-4 text-right">
                      <button
                        type="submit"
                        className="tt-button tt-button-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <ModalWindow
                modal={this.state.permissionModal}
                size="lg"
                id="tt-permissionModal"
                toggleModal={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
                modalHeader={"Assign permission to user"}
                modalBody={
                  <GranularPermissionModal
                    widgetName="Academic Administration"
                    moduleName="School Groupings"
                    header={`Create ${localStorage.getItem("classroom")}`}
                    activityName="insert-section"
                  />
                }
              ></ModalWindow>
            </div>
            {this.state.showSpinner ? (
              <div className="fullWindow-Spinner">
                <div>
                  <Spinner color="white"></Spinner>
                </div>
                <div style={{ fontSize: "16px", marginTop: "15px" }}>
                  Please Wait.Creating{" "}
                  {localStorage.getItem("classroom")
                    ? localStorage.getItem("classroom")
                    : "Section"}
                  ...
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            <NoteDisplay
              textContent={`
          To add a new ${
            localStorage.getItem("classroom")
              ? localStorage.getItem("classroom")
              : "Section"
          }, you must first add ${
                localStorage.getItem("yearLevel")
                  ? localStorage.getItem("yearLevel")
                  : "Class"
              }, Classroom location and Section Teachers first. Please add them and then add a new ${
                localStorage.getItem("classroom")
                  ? localStorage.getItem("classroom")
                  : "Section"
              }.`}
            ></NoteDisplay>
          </div>
        )}
      </>
    ) : (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      ></div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  //classroomLevels: state.academicAdministration.classroomLevels
  yearLevels: state.academicAdministration.yearLevels,
  classroomLocations: state.academicAdministration.classroomLocations,
});

const mapActionsToProps = {
  getClassroomLevels,
  getYearLevels,
  getClassroomLocations,
  getGroups,
};

export default connect(mapStateToProps, mapActionsToProps)(CreateClassroom);
