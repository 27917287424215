import React, { Component } from "react";
import ModalWindow from "../../UI/ModalWindow";
import SubjectTopicSetting from "./SubjectTopicSetting";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import swal from "sweetalert";

export class CreateCourseSyallabus extends Component {
  state = {
    yearLevels: [],
    selectedYearLevel: "",

    classes: [],
    selectedClass: "",

    subjects: [],
    selectedSubject: "",

    addCourseModal: "",
  };

  componentDidMount = () => {
    this.getEducationLevels();
    this.getAllClasses();
    this.getAllSubjects();
  };

  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getEducationLevels = () => {
    axiosPost(URL.getAllEducationLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          yearLevels: response.data.data,
        });
      }
    });
  };

  getAllClasses = () => {
    axiosPost(URL.getAllClass, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          classes: response.data.data,
        });
      }
    });
  };

  getAllSubjects = () => {
    axiosPost(URL.getAllSubject, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          subjects: response.data.data,
        });
      }
    });
  };

  handleEducationLevelChange = (e) => {
    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          selectedYearLevel: datum.yearLevels[0].id,

          classrooms: [],
          selectedClassrooms: "",

          subjects: [],
          selectedSubjects: "",
          selectedEducationLevelId: e.target.value,
        });
      }
    });
  };

  handleSubjects = (e) => {
    this.setState({
      selectedSubjects: e.target.value,
    });
  };

  handleAddCourse = (subject) => {
    this.setState({
      // selectedSubject: subject,
      addCourseModal: !this.state.addCourseModal,
    });
  };

  handleAddCourseSubject = () => {
    let data = {
      classId: this.state.selectedClass,
      subjectId: this.state.selectedSubject,
      coursePlanSyllabus: [],
    };

    if (this.state.selectedClass !== "" && this.state.selectedSubject !== "") {
      axiosPost(URL.insertCoursePlan, data, (response) => {
        if (response.status === 200) {
          swal("Success", "Course added successfully.");

          this.props.getCourseSyllabus();
          this.props.toggleModal();
        }
      });
    } else {
      swal("Error", "First select the class and subject");
    }
  };

  toggleAddCourseModal = () => {
    this.setState({ addCourseModal: !this.state.addCourseModal });
  };

  render() {
    return (
      <div>
        <div>
          <div className="container-fluid">
            <div className="row py-3">
              <div className="col-4">
                <select
                  className="form-control cusInputSelect"
                  name="selectedClass"
                  // value={this.state.gradeId}
                  onChange={(e) => this.handleChange(e)}
                  disabled={false}
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {this.state.classes?.map((sl, idx) => {
                    return <option value={sl.id}>{sl.name}</option>;
                  })}
                </select>
              </div>

              <div className="col-4">
                <select
                  className="form-control cusInputSelect"
                  name="selectedSubject"
                  // value={this.state.selectedSubjects}
                  onChange={(e) => this.handleChange(e)}
                  disabled={false}
                >
                  <option value="" selected disabled>
                    Select subjects
                  </option>
                  {this.state.subjects.map((sl, idx) => {
                    return <option value={sl.id}>{sl.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-4">
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize mr-2"
                  onClick={() => this.handleAddCourseSubject("subject")}
                >
                  Add Course
                </button>
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize"
                  onClick={() => this.handleAddCourse("subject")}
                >
                  Add Course Syllabus
                </button>
              </div>
            </div>
            {this.state.addCourseModal ? (
              <SubjectTopicSetting
                selectedYearLevel={this.state.selectedYearLevel}
                selectedClass={this.state.selectedClass}
                selectedSubject={this.state.selectedSubject}
                academicRelated={true}
                toggleAddCourseModal={this.toggleAddCourseModal}
                displayType={this.props.displayType}
                getCourseSyllabus={this.props.getCourseSyllabus}
                toggleModal={this.props.toggleModal}
              ></SubjectTopicSetting>
            ) : null}
          </div>
        </div>

        {/* <ModalWindow
          modal={this.state.addCourseModal}
          toggleModal={this.toggleAddCourseModal}
          size="xl"
          modalHeader="Add New Course Plan for Subject"
          modalBody={
            <SubjectTopicSetting
              selectedYearLevel={this.state.selectedYearLevel}
              selectedClass={this.state.selectedClass}
              selectedSubject={this.state.selectedSubject}
              academicRelated={true}
              toggleAddCourseModal={this.toggleAddCourseModal}
              displayType={this.props.displayType}
              getCourseSyllabus={this.props.getCourseSyllabus}
            ></SubjectTopicSetting>
          }
        ></ModalWindow> */}
      </div>
    );
  }
}

export default CreateCourseSyallabus;
