import React, { Component } from "react";
import { formatDate, handleError } from "../../../../utils/Utils";
import { URL } from "../../../../utils/Constants";

class StudentInformation extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="container-fluiud p-2">
          <div className="row">
            <div className="col-md-auto pr-0">
              <img
                src={URL.imageSelectURL + this.props.studentInfo.people.photo}
                alt=""
                width="70px"
                height="70px"
                onError={(e) => handleError(e)}
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="col text-left">
              <div className=""> {this.props.studentInfo.people.name}</div>
              <div className=""> {this.props.studentInfo.people.gender} </div>
              <div className="">
                {" "}
                {formatDate(this.props.studentInfo.people.dateOfBirth)}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StudentInformation;
