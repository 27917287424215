import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";

class CrudContainer extends Component {
  //pass these innertabs and innertabs-content as props to make this component resuable throughout
  state = {
    tabId: "vertical-0",
  };

  componentDidMount() {
    //to show list education level as selected tab
    const index = this.props.content.tabs.findIndex(
      (tab) => tab.name === "List Education Level"
    );
    if (index !== -1) {
      this.setState({ tabId: "vertical-1" });
    } else {
      this.setState({ tabId: "vertical-0" });
    }
  }

  render() {
    return (
      <div>
        <Tabs
          defaultTab={this.state.tabId}
          //   defaultTab={this.findDefault()}
          vertical
          onChange={(tabId) => {
            this.setState({ tabId });
          }}
        >
          <TabList>
            {this.props.content.tabs
              ? this.props.content.tabs.map((tab, id) => (
                  <Tab
                    key={id}
                    tabFor={`vertical-${id}`}

                    // onClick={(e) => this.breadcrumbs(e, tab.name)}
                  >
                    {tab.name === "Reading Materials" ? "E-Library" : tab.name}
                  </Tab>
                ))
              : "No Options"}
          </TabList>
          {this.props.content.tabs
            ? this.props.content.tabs.map((tab, id) => (
                <TabPanel
                  key={id}
                  tabId={`vertical-${id}`}
                  render={() =>
                    this.state.tabId === `vertical-${id}` ? tab.content : null
                  }
                />
              ))
            : "No Content ..."}
        </Tabs>
      </div>
    );
  }
}

export default withRouter(CrudContainer);
