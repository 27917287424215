import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import "../../assets/scss/calendarDisplay.scss";
import "../../assets/scss/calendarWidget.scss";
import { axiosPost } from "../../utils/AxiosApi";
import ReactTooltip from "react-tooltip";
import { URL } from "../../utils/Constants";
import { connect } from "react-redux";
import { getCalendarEvents } from "../../ducks/AcademicAdministrationDucks";
import { displayMessage } from "../../utils/Utils";
import $ from "jquery";

class CalendarDisplay extends Component {
  calendarComponentRef = React.createRef();
  state = {
    events: [],
    minimize: false,
    currentAcYrId: "",
    currentAcYr: "",
    currentAcYrName: "",
    systemDefaultAcYr: "",
    calendarDate: "",
  };

  componentDidMount() {
    this.getUserCurrentAcademicYear();
    ReactTooltip.rebuild();
    this.addMinimizeClass();
  }

  addMinimizeClass = () => {
    let self = this;
    $(window).on("load", function () {
      if ($(window).width() <= 768) {
        self.setState({ minimize: true });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.assessmentModuleandEducationLevel !==
      this.props.assessmentModuleandEducationLevel
    ) {
      this.props.getCalendarEvents(
        this.props.assessmentModuleandEducationLevel
      );
      ReactTooltip.rebuild();
    }
  }

  filterSameCalendarEvents = () => {
    let events = [...this.props.calendarEvents];
    const unique = [];
    events.map((x) =>
      unique.filter(
        (a) =>
          a.color == x.color &&
          a.title == x.title &&
          a.start == x.start &&
          a.end == x.end
      ).length > 0
        ? null
        : unique.push(x)
    );
    // this.setState({ uniqueCalendarEvents: unique });
    return unique;
  };

  getUserCurrentAcademicYear = () => {
    axiosPost(URL.selectUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState({
            currentAcYr: response.data.data,
            currentAcYrId: response.data.data.academicYearId,
            currentAcYrName: response.data.data.academicYearName,
          });
        } else {
          this.getCurrentAcademicYear();
        }
      }
    });
  };

  getCurrentAcademicYear = () => {
    let data = {
      isCurrent: "true",
    };

    axiosPost(URL.getAcademicYears, data, (response) => {
      if (response.status === 200) {
        if (response.data.data && response.data.data.length > 0) {
          this.setState({
            currentAcYr: response.data.data[0],
            currentAcYrId: response.data.data[0].id,
            currentAcYrName: response.data.data[0].name,
          });
        }
      }
    });
  };

  //   getEducationLevels = () => {
  //     axiosPost(URL.getEducationLevel, {}, response => {
  //       this.setState({ educationLevels: response.data.data }, () => {
  //         let modifiedEvents = [...this.state.events];
  //         let newEvents = [];
  //         for (let i = 0; i < modifiedEvents.length - 1; i++) {
  //           let educationLevelNames = "";
  //           this.state.educationLevels.forEach(element => {
  //             if (element.id === modifiedEvents[i].educationLevelId) {
  //               educationLevelNames = educationLevelNames + element.name + " ";
  //             }
  //           });
  //           if (modifiedEvents[i].title !== modifiedEvents[i + 1].title) {
  //             modifiedEvents[
  //               i
  //             ].description = `${modifiedEvents[i].description} For education levels ${educationLevelNames}`;
  //             newEvents.push(modifiedEvents[i]);
  //           }
  //         }
  //         this.setState({ events: newEvents });
  //       });
  //     });
  //   };
  //   componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.calendarEvents !== this.props.calendarEvents) {
  //       this.setState({ events: this.props.calendarEvents }, () => {
  //         this.getEducationLevels();
  //       });
  //     }
  //   }

  handleEventPositioned = (arg) => {
    arg.el.setAttribute("data-tip", arg.event.extendedProps.description);
    ReactTooltip.rebuild();
  };

  renderEvents = (arg) => {
    if (arg.event.start.getMonth() !== arg.view.currentStart.getMonth()) {
      return false;
    }
  };

  //   getEvents = () => {
  //     axiosPost(URL.getEvents, {}, response => {
  //       if (response.status === 200) {
  //         let modifiedEvents = [...response.data.data];
  //         modifiedEvents.forEach((event, id) => {
  //           event.start = event.start.substring(0, event.start.length - 18);
  //           event.end = event.end.substring(0, event.end.length - 18);
  //         });
  //         this.setState({ events: modifiedEvents });
  //       }
  //     });
  //   };

  minimizeCalendarContainer = () => {
    this.setState({ minimize: !this.state.minimize });
  };

  // handleChange = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   if (value !== "") {
  //     this.setState({ [name]: value });
  //   } else {
  //     if (name === "calendarDate" && value === "") {
  //       let calendarApi = this.calendarComponentRef.current.getApi();
  //       calendarApi.gotoDate(new Date());
  //       $('.fc-day[data-date="' + this.state.calendarDate + '"]').css(
  //         "background-color",
  //         "white"
  //       );
  //     }
  //     this.setState({ [name]: value });
  //   }
  // };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState((prevState) => {
      // if (value !== "") {
      //   return { [name]: value };
      // }
      if (name === "calendarDate" && value !== "") {
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.gotoDate(new Date());
        if (prevState.calendarDate !== "") {
          $('.fc-day[data-date="' + prevState.calendarDate + '"]').css(
            "background-color",
            "white"
          );
        }
      }
      if (value !== "") {
        return { [name]: value };
      }
      if (value === "") {
        if (prevState.calendarDate !== "") {
          $('.fc-day[data-date="' + prevState.calendarDate + '"]').css(
            "background-color",
            "white"
          );
          return { [name]: value };
        }
      }
      // return { [name]: value };
    });
  };

  goToDate = (e) => {
    e.preventDefault();
    if (this.state.calendarDate !== "") {
      let calendarApi = this.calendarComponentRef.current.getApi();
      const todayDate = calendarApi.gotoDate(new Date());

      calendarApi.gotoDate(this.state.calendarDate);

      $('.fc-day[data-date="' + this.state.calendarDate + '"]').css(
        "background-color",
        "rgba(66, 118, 234, 0.3)"
      );
    }
    // else if (){

    // }
    else {
      displayMessage("Error", "Please select date first");
    }
  };

  today = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      if (prevState.calendarDate !== "") {
        $('.fc-day[data-date="' + prevState.calendarDate + '"]').css(
          "background-color",
          "white"
        );
      }

      return { calendarDate: new Date().toISOString().substring(0, 10) };
    });
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate(new Date());
  };

  render() {
    return (
      <div
        id="tt-calendarContainer"
        className={
          this.state.minimize
            ? "tt-group-container minimize"
            : "tt-group-container tt-calendar-container"
        }
      >
        <div
          className="tt-group-header"
          onDoubleClick={this.minimizeCalendarContainer}
          data-tip="Double click to expand and collapse this widget"
        >
          Calendar
        </div>
        <div className="tt-calendarBox">
          <FullCalendar
            header={{
              left: "prev",
              center: "title",
              right: "next",
            }}
            validRange={{
              start: this.state.currentAcYr.startDate,
              end: this.state.currentAcYr.endDate,
            }}
            eventLimit={1}
            // showNonCurrentDates={false}
            ref={this.calendarComponentRef}
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            weekends={true}
            fullDay={true}
            events={this.filterSameCalendarEvents() || []}
            eventPositioned={this.handleEventPositioned}
            displayEventTime={false}
            eventClick={this.eventClick}
            eventRender={this.renderEvents}
          />
        </div>
        <div className="tt-group-header mt-2">
          <div className="row">
            <div className="col calendarGOTODATE">
              <input
                type="date"
                name="calendarDate"
                className="form-control"
                value={this.state.calendarDate}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-auto col-sm-auto mt-1 calendarGOTODATEBUTTON">
              <button
                className="tt-button tt-button-primary mr-2"
                onClick={this.goToDate}
              >
                Go to Date
              </button>
              <button
                className="tt-button tt-button-primary todayCalendar"
                onClick={this.today}
              >
                Today
              </button>
            </div>
          </div>
        </div>
        <ReactTooltip globalEventOff="click"></ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  calendarEvents: state.academicAdministration.calendarEvents,
});

const mapActionToProps = {
  getCalendarEvents,
};

export default connect(mapStateToProps, mapActionToProps)(CalendarDisplay);
