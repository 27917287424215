import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import CustomEditButton from "../../components/common/CustomEditButton";
import { displayConfirmDeleteAlert } from "../../utils/DisplayMessage";
import CustomModal from "../../components/common/CustomModal";
import { displayErrorAlert } from "../../utils/Utils";

class ListEducationLevel extends Component {
  state = {
    editModal: false,
    editData: null,
    name: "",
    code: "",
  };

  handleDelete = (id, idx) => {
    displayConfirmDeleteAlert({ id: id, idx: idx }, this.deleteEducationlevel);
  };

  handleEditModal = (editData) => {
    this.setState({
      editData: editData,
      name: editData.name,
      code: editData.code,
      editModal: true,
    });
  };

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal,
    });
  };

  handleChange = (e, idx) => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  handleEdit = () => {
    const { name, code, editData } = this.state;

    if (!name || !code) {
      swal("Warning", "Please fill all fields!");
      return;
    }

    const data = {
      id: editData.id,
      name,
      code,
      display: editData.display,
    };

    axiosPost(
      URL.updateEducationLevel,
      data,
      (response) => {
        if (response.status === 200) {
          swal("Success", "Education level updated successfully");
          this.props.getAllEducationLevels();
          this.toggleEditModal();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let data = {
      id: item.id,
      display: checked,
      name: item.name,
      code: item.code,
    };

    axiosPost(URL.updateEducationLevel, data, (response) => {
      if (response.status === 200) {
        this.props.getAllEducationLevels();
      }
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <table className="table cusTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Code</th>
                <td>Display</td>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.educationLevels.length > 0
                ? this.props.educationLevels.map((educationLevel, index) => {
                    return (
                      <tr>
                        <td>{educationLevel.name}</td>
                        <td>{educationLevel.code}</td>
                        <td>
                          <label className="switch mt-2">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                this.handleChangeToggle(e, educationLevel)
                              }
                              checked={educationLevel.display}
                              name="display"
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          {/* <CustomDeleteButton
                            onClick={() =>
                              this.handleDelete(educationLevel.id, index)
                            }
                          /> */}
                          <CustomEditButton
                            onClick={() => this.handleEditModal(educationLevel)}
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        <CustomModal
          modal={this.state.editModal}
          size="lg"
          toggleModal={this.toggleEditModal}
          modalHeader="Update Education Level"
          modalBody={
            <>
              <div className="row py-2">
                <div className="col-md-5">
                  <input
                    className="form-control cusInput shadow-none"
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
                <div className="col-md-5">
                  <input
                    className="form-control cusInput shadow-none"
                    name="code"
                    value={this.state.code}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={this.handleEdit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          }
        />
      </div>
    );
  }
}

export default ListEducationLevel;
