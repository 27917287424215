import React, { Component } from "react";

class BillAddInventoryModalBody extends Component {
  render() {
    return (
      <div className="inventoryListHolder">
        <table className="table table-bordered table-striped mb-0">
          <thead className="tt-group-header">
            <tr>
              <th>
                <input
                  type="checkbox"
                  className="form-control"
                  checked={this.props.checkAllItem}
                  name="checkAllItem"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  onChange={this.props.inventoryAllCheckbox}
                ></input>
              </th>
              <th>Fee Category Name</th>
              <th>Inventory Item Name</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Amount</th>
            </tr>
          </thead>
          <tbody>
            {this.props.selectedInventoryItems &&
            this.props.selectedInventoryItems.length > 0 ? (
              this.props.selectedInventoryItems.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <input
                        type="checkbox"
                        className="form-control"
                        checked={item.isChecked}
                        name="isChecked"
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                        onChange={(e) => this.props.inventoryCheckbox(e, idx)}
                      ></input>
                    </td>
                    <td>{item.feeCategoryName}</td>
                    <td>{item.itemName}</td>
                    <td className="text-center">{item.quantity}</td>
                    <td className="text-center">{item.amount}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No inventory items added for this student
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default BillAddInventoryModalBody;
