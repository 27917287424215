// Actions
const permissionsLOAD = "PERMISSIONS_LOAD";
const OTPEnable = "asjhdgjhasdg";

export default function reducer(
  state = {
    permissions: [],
    otpenabled: false,
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case permissionsLOAD:
      newState.permissions = action.permissions;
      return newState;
    case OTPEnable:
      newState.otpenabled = action.enable;
      return newState;
    default:
      return newState;
  }
}

// Action Creators
export function loadPermissions(permissions) {
  return { type: permissionsLOAD, permissions };
}

export function setOTPEnable(enable) {
  return { type: OTPEnable, enable };
}

export function setPermissions(permissions) {
  return (dispatch) => {
    dispatch(loadPermissions(permissions));
  };
}

export function otpVerified(enable) {
  return (dispatch) => {
    dispatch(setOTPEnable(enable));
  };
}
