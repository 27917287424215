import React, { Component } from "react";
import { checkRolePermissions } from "../../../utils/Constants";
import TopicDetailReRender from "./TopicDetailReRender";

class TopicDetailModal extends Component {
  state = {
    permissionCanEditProgramCourseDetail: false,
    permissionCanDeleteProgramCourseDetail: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionCanEditProgramCourseDetail: checkRolePermissions(
        "can_edit_program_course_detail",
        "activity"
      ),
      permissionCanDeleteProgramCourseDetail: checkRolePermissions(
        "can_delete_program_course_detail",
        "activity"
      ),
    });
  };

  render() {
    return (
      <div
        className="tt-widgetContent-tab-holder"
        style={
          this.props.courseDetail
            ? null
            : this.props.courseWidget
            ? null
            : { height: "87.5vh" }
        }
      >
        <div className="row tt-group-header">
          <div className="col">Course Name</div>
          <div className="col-md-3">
            <div className="row">
              <div className="col text-center">Lecture Hour</div>
              <div className="col text-center">Practical Hour</div>
            </div>
          </div>
          <div
            className={
              this.props.courseDetail
                ? "col-md-2 text-center"
                : this.state.permissionCanEditProgramCourseDetail ||
                  this.state.permissionCanDeleteProgramCourseDetail
                ? "col-md-3"
                : "col-md-2"
            }
          ></div>
        </div>
        <div className="tt-topicHolder">
          {this.props.topicList && this.props.topicList.length > 0 ? (
            this.props.topicList.map((topic, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div
                    className="row"
                    key={idx}
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "2",
                    }}
                  >
                    <div className="col" style={{ display: "flex" }}>
                      <span className="backGround">
                        {idx + 1}. {topic.name}
                      </span>
                      <span
                        style={{ height: "inherit", width: "5px" }}
                        className="backGround"
                      ></span>
                      <div className="dotsRender"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="row">
                        <div className="col text-center">
                          {topic.lectureHour}
                        </div>
                        <div className="col text-center">
                          {topic.practicalHour}
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.props.courseDetail
                          ? "col-md-2 text-center"
                          : this.state.permissionCanEditProgramCourseDetail ||
                            this.state.permissionCanDeleteProgramCourseDetail
                          ? "col-md-3"
                          : "col-md-2"
                      }
                    >
                      {this.props.courseDetail ? null : (
                        <>
                          {this.state.permissionCanEditProgramCourseDetail ? (
                            <button
                              className="tt-button tt-button-primary mr-2"
                              onClick={() =>
                                this.props.handleUpdateCourseTopic(topic)
                              }
                            >
                              Edit
                            </button>
                          ) : null}
                          {this.state.permissionCanDeleteProgramCourseDetail ? (
                            <button
                              className="tt-button tt-button-primary mr-2"
                              onClick={() =>
                                this.props.handleCourseTopicDelete(topic.id)
                              }
                            >
                              Delete
                            </button>
                          ) : null}
                        </>
                      )}
                      <button
                        className="tt-button tt-button-primary"
                        onClick={() =>
                          this.props.handleTopicClassModal(topic.id)
                        }
                      >
                        Classes
                      </button>
                    </div>
                  </div>
                  {topic.subTopic && topic.subTopic.length > 0 ? (
                    <TopicDetailReRender
                      handleTopicClassModal={this.props.handleTopicClassModal}
                      topicList={topic.subTopic}
                      handleUpdateCourseTopic={
                        this.props.handleUpdateCourseTopic
                      }
                      index={1}
                      courseDetail={
                        this.props.courseDetail
                          ? this.props.courseDetail
                          : false
                      }
                      handleCourseTopicDelete={
                        this.props.handleCourseTopicDelete
                      }
                      permissionCanEditProgramCourseDetail={
                        this.state.permissionCanEditProgramCourseDetail
                      }
                      permissionCanDeleteProgramCourseDetail={
                        this.state.permissionCanDeleteProgramCourseDetail
                      }
                    ></TopicDetailReRender>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : (
            <div className="col text-center">No Courses available</div>
          )}
        </div>
      </div>
    );
  }
}

export default TopicDetailModal;
