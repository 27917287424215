import moment from "moment";
import React, { Component } from "react";

import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomPageHeader from "../../components/common/CustomPageHeader";

class UserLoginStatistics extends Component {
  state = {
    userRole: "",
    schoolId: "",
    userName: "",
    fromDate: "",
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    data: [],
    schools: [],
    roles: [],
  };

  componentDidMount() {
    this.getData();
    this.getAllSchools();
    this.getAllRoles();
  }

  getAllSchools = () => {
    this.setState({ getSpinner: true }, function () {
      axiosPost(
        URL.getAllSchools,
        {},
        (response) => {
          this.setState({ getSpinner: false });
          if (response.status === 200) {
            this.setState({ schools: response.data.data });
          }
        },
        (err) => {
          this.setState({ getSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };
  getAllRoles = () => {
    this.setState({ getSpinner: true }, function () {
      axiosPost(
        URL.getAllRoles,
        {},
        (response) => {
          this.setState({ getSpinner: false });
          if (response.status === 200) {
            this.setState({ roles: response.data.data });
          }
        },
        (err) => {
          this.setState({ getSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };
  getData = () => {
    let param = {
      userRole: this.state.userRole,
      schoolId: this.state.schoolId,
      userName: this.state.userName,
      searchParam: {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      },
    };
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.getLoginStatisticsDetail,
        param,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            let result = response.data.data;
            this.setState({ data: result });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };
  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };
  clearSearchParam = (e) => {
    this.setState({
      fromDate: "",
      toDate: "",
      userRole: "",
      schoolId: "",
      userName: "",
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader title="User Login Statistics" />
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <p className="labelTitle mb-1">From Date</p>
                    <input
                      type="date"
                      name="fromDate"
                      value={this.state.fromDate}
                      onChange={this.handleChange}
                      className="form-control cusInput"
                      placeholder="Enter From Date"
                    />
                  </div>
                  <div className="col-md-4">
                    <p className="labelTitle mb-1 ">To Date</p>
                    <input
                      type="date"
                      name="toDate"
                      value={this.state.toDate}
                      onChange={this.handleChange}
                      className="form-control cusInput"
                      placeholder="Enter To Date"
                    />
                  </div>

                  <div className="col">
                    <p className="labelTitle mb-1">Username</p>
                    <input
                      type="text"
                      name="userName"
                      value={this.state.userName}
                      onChange={this.handleChange}
                      className="form-control cusInput"
                      placeholder="Enter Username"
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    {/* <p className="courseTitle mb-1">School</p> */}
                    <select
                      name="schoolId"
                      value={this.state.schoolId}
                      onChange={this.handleChange}
                      className="form-control cusInputSelect"
                    >
                      <option value="" selected disabled>
                        Choose School
                      </option>
                      {this.state.schools
                        ? this.state.schools.map((school, idx) => {
                            return (
                              <option key={idx} value={school.id}>
                                {school.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="col-md-4">
                    {/* <p className="courseTitle mb-1">Role</p> */}
                    <select
                      name="userRole"
                      value={this.state.userRole}
                      onChange={this.handleChange}
                      className="form-control cusInputSelect"
                    >
                      <option value="" selected disabled>
                        Choose a Role
                      </option>
                      {this.state.roles
                        ? this.state.roles.map((role, idx) => {
                            return (
                              <option key={idx} value={role.roleCode}>
                                {role.roleName}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col text-end">
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize"
                      onClick={this.clearSearchParam}
                    >
                      Clear
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize"
                      onClick={this.getData}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="searchBar">
                <span>{this.state.data ? this.state.data.length : 0}</span>{" "}
                records found in total.
              </div>
              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Logged in Date</th>
                    <th>Logged in Time</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.length > 0
                    ? this.state.data.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.userName}</td>
                            <td>{moment(item.createdAt).format("ll")}</td>
                            <td>{moment(item.createdAt).format("h:mm a")}</td>
                          </tr>
                        );
                      })
                    : "No data found"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserLoginStatistics;
