import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomModal from "../../components/common/CustomModal";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { axiosPost } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import AssignRoles from "./AssignRoles";
import RoleAndPermissionCreationModal from "./RoleAndPermissionCreationModal";
import ViewPermissions from "./ViewPermissions";

class RolesAndPermissions extends Component {
  state = {
    getSpinner: false,
    modal: false,
    permissionModal: false,
    selectedUserRole: null,
    selectedUsers: [],
    spinner: false,
    userRoles: [],
    userModal: false,
    userSpinner: false,
    allUsersForRole: [],
    allUsers: [],
  };

  assignUsertoRoles = () => {
    let param = {
      roleCode: this.state.selectedUserRole,
      userIds: this.state.selectedUsers?.map((s) => s.id),
    };
    this.setState({ userSpinner: true }, function () {
      axiosPost(
        URL.assignRoles,
        param,
        (response) => {
          this.setState({ userSpinner: false });
          if (response.status === 200) {
            swal("Success", "User successfully assigned");
            this.getAllUserRoles();
            this.toggleUserAssignModal();
          }
        },
        (err) => {
          this.setState({ userSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  componentDidMount() {
    this.getAllUserRoles();
    this.getAllUsers();
  }

  getAllUserRoles = () => {
    this.setState({ getSpinner: true }, function () {
      axiosPost(
        URL.getAllRoles,
        {},
        (response) => {
          this.setState({ getSpinner: false });
          if (response.status === 200) {
            this.setState({ userRoles: response.data.data });
          }
        },
        (err) => {
          this.setState({ getSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  getAllUsers = () => {
    axiosPost(
      URL.getAllUsers,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({ allUsers: response.data.data });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleActivityChange = (e, moduleIdx, activityIdx) => {
    let value = e.target.checked;
    let selectedUserRole = this.state.selectedUserRole;
    selectedUserRole.modules[moduleIdx].activities[activityIdx].checked = value;
    this.setState({ selectedUserRole });
  };

  handleModuleChange = (e, moduleIdx) => {
    let value = e.target.checked;
    let selectedUserRole = this.state.selectedUserRole;
    selectedUserRole.modules[moduleIdx].checked = value;
    selectedUserRole.modules[moduleIdx].activities.forEach((el) => {
      el.checked = value;
    });
    this.setState({ selectedUserRole });
  };

  handleSelect = (users) => {
    this.setState({ selectedUsers: users });
  };

  handleSubmit = () => {
    let selectedUserRole = this.state.selectedUserRole;
    let modules = selectedUserRole.modules;
    if (modules.length > 0) {
      modules.forEach((el) => {
        let selectedActivities = [];
        el.activities.forEach((ac) => {
          if (ac.checked === true) {
            selectedActivities.push(ac);
          }
        });
        el.activities = selectedActivities;
      });
    }
    let param = {
      roleCode: selectedUserRole.roleCode,
      modules: modules,
    };
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.updatePermissionCore,
        param,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            swal("Success", "Role successfully updated");
            this.togglePermissionModal();
            this.getAllUserRoles();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  togglePermissionModal = (data) => {
    if (data) {
      let param = {
        code: data.roleCode,
      };
      axiosPost(
        URL.getPermissionByUserRoles,
        param,
        (response) => {
          if (response.status === 200) {
            let datum = response.data.data;
            datum.modules.forEach((el) => {
              el.checked = false;
              el.activities.forEach((ac) => {
                ac.checked = ac.isSelected;
              });
            });
            this.setState({ selectedUserRole: datum }, function () {
              this.setState({ permissionModal: !this.state.permissionModal });
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    } else {
      this.setState({
        permissionModal: !this.state.permissionModal,
        selectedUserRole: data,
      });
    }
  };

  toggleUserAssignModal = (data) => {
    if (data && !this.state.userModal) {
      let param = {
        code: data.roleCode,
      };
      axiosPost(
        URL.getAllAssignedUsersByUserRole,
        param,
        (response) => {
          if (response.status === 200) {
            let users = response.data.data;
            this.setState({
              selectedUsers: users,
              selectedUserRole: data.roleCode,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    }
    this.setState({ userModal: !this.state.userModal });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Roles and Permissions"
                icon="add_box"
                onClick={this.toggleModal}
                action="Insert"
                moduleName={moduleName.ROLES}
              />
              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>User Role</th>
                    <th>User Role Code</th>
                    <th className="text-center">Pemissions</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.userRoles.length > 0 ? (
                    this.state.userRoles.map((userRole, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{userRole.roleName}</td>
                          <td>{userRole.roleCode}</td>
                          <td className="text-center">
                            <span
                              className="material-icons-round primaryColor"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.togglePermissionModal(userRole)
                              }
                            >
                              visibility
                            </span>
                          </td>
                          <td>
                            <span
                              className="assignBox mt-2"
                              onClick={() =>
                                this.toggleUserAssignModal(userRole)
                              }
                            >
                              Assign Users
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {this.state.getSpinner ? (
                          <Spinner></Spinner>
                        ) : (
                          "No user roles created"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          size="xl"
          modalHeader="Add New Role and Permission"
          modalBody={
            <RoleAndPermissionCreationModal
              getAllUserRoles={this.getAllUserRoles}
              toggleModal={this.toggleModal}
            />
          }
          toggleModal={this.toggleModal}
        />
        <CustomModal
          modal={this.state.permissionModal}
          size="xl"
          modalHeader="Permissions"
          modalBody={
            <ViewPermissions
              modules={this.state.selectedUserRole?.modules}
              handleActivityChange={this.handleActivityChange}
              handleModuleChange={this.handleModuleChange}
              handleSubmit={this.handleSubmit}
              spinner={this.state.spinner}
            />
          }
          toggleModal={() => this.togglePermissionModal(null)}
        />
        <CustomModal
          modal={this.state.userModal}
          modalHeader="Assign Roles to user"
          size="xl"
          modalBody={
            <AssignRoles
              allUsers={this.state.allUsers}
              assignUsertoRoles={this.assignUsertoRoles}
              handleSelect={this.handleSelect}
              selectedUsers={this.state.selectedUsers}
              selectedUserRole={this.state.selectedUserRole}
              userSpinner={this.state.userSpinner}
            />
          }
          toggleModal={() => this.toggleUserAssignModal(null)}
        />
      </div>
    );
  }
}

export default RolesAndPermissions;
