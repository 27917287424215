import React, { Component } from "react";
import IndicatorDescriptorTable from "./IndicatorDescriptorTable";
import IndicatorDescriptorDynamicForm from "./IndicatorDescriptorDynamicForm";
class ScaleTypeIndicatorDescriptorForm extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-3 tt-paddingLeft">
            <label className="indicator-label">
              <strong>Level name</strong>
            </label>
          </div>
          <div className="col-md-4 scale-content tt-paddingRight">
            <input
              type="text"
              name="levelName"
              value={this.props.levelName}
              className="form-control  scale-name-field"
              onChange={this.props.universalHandleChange}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-3 tt-paddingLeft">
            <input
              type="checkbox"
              className="tt-record-deadline-checkbox mr-2"
              id="useLevel"
              checked={this.props.useLevel}
              name="useLevel"
              onChange={this.props.universalHandleChange}
            />
            <label htmlFor="useLevel">
              <strong>Use levels</strong>
            </label>
          </div>
          <div className="col-md-1 tt-paddingRight mt-2">
            <label className="indicator-label">
              <strong>Level type</strong>
            </label>
          </div>
          <div className="col-md-3 tt-paddingRight">
            <select
              className="form-control assess-record-dropdown"
              value={this.props.levelType}
              name="levelType"
              onChange={this.props.handleChangeLevelType}
            >
              <option value="" disabled>
                {"Choose Type"}
              </option>
              <option value="alphabets">Alphabets</option>
              <option value="number">Number</option>
              <option value="text">Text</option>
              <option value="image">Image</option>
              <option value="colourStickers">Colour stickers</option>
            </select>
          </div>
          <div className="col-md-2"></div>
          {/* <div className="col-md-1"></div> */}
        </div>
        {(this.props.gradeValues && this.props.gradeValues.length > 0) ||
        (this.props.multipleGpa && this.props.multipleGpa.length > 0) ? (
          <div className="ttgpa-table">
            <IndicatorDescriptorTable
              multipleGrades={this.props.multipleGrades}
              gradeValues={this.props.gradeValues}
              handleRemoveGrades={this.props.handleRemoveGrades}
              type="grade"
              levelName={this.props.levelName}
              levelType={this.props.levelType}
              selectedFile={this.props.selectedFile}
              useLevel={this.props.useLevel}
            />
          </div>
        ) : null}

        <p className="ttadd-title">
          {"Fill the form below to add new Grade format"}
        </p>
        <div className="tt-gpa-form container-fluid" style={{ width: "95%" }}>
          <IndicatorDescriptorDynamicForm
            universalHandleChange={this.props.universalHandleChange}
            handleGradeValuesSubmit={this.props.handleGradeValuesSubmit}
            grade={this.props.grade}
            gradeTypes={this.props.gradeTypes}
            descriptor={this.props.descriptor}
            multipleGrades={this.props.multipleGrades}
            addMultipleGrade={this.props.addMultipleGrade}
            multipleGradeChange={this.props.multipleGradeChange}
            useLevel={this.props.useLevel}
            errors={this.props.errors}
            levelType={this.props.levelType}
            displayColorPicker={this.props.displayColorPicker}
            handleChangeForColorPicker={this.props.handleChangeForColorPicker}
            handleClickColorPicker={this.props.handleClickColorPicker}
            handleCloseForColorPicker={this.props.handleCloseForColorPicker}
            color={this.props.color}
            changeColor={this.props.changeColor}
            handleRemoveGrades={this.props.handleRemoveGrades}
            handleRemoveGradeFormField={this.props.handleRemoveGradeFormField}
            handleLevelTypeNumberChange={this.props.handleLevelTypeNumberChange}
            handleLevelTypeImageChange={this.props.handleLevelTypeImageChange}
            handleGradeChange={this.props.handleGradeChange}
            selectedOptionValues={this.props.selectedOptionValues}
          />
        </div>
      </>
    );
  }
}

export default ScaleTypeIndicatorDescriptorForm;
