import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class CommentSectionTableRow extends Component {
  state = {};
  render() {
    let val = this.props.comment;
    return (
      <tr key={this.props.idx}>
        <td className="pt-2 px-2">
          <input
            type="text"
            value={val.commentName}
            name="commentName"
            className={
              val.edit
                ? "tt-input-comment-label-name-highlight form-control"
                : "tt-input-comment-label-name form-control"
            }
            onChange={(e) =>
              this.props.handleNestedChange(e, this.props.idx, "comments")
            }
            id={"commentLabel" + this.props.idx}
          />
          <textarea
            placeholder="Comments"
            name="comment"
            value={val.comment}
            className="tt-subjectAssessmentTextArea mt-1"
            onChange={(e) =>
              this.props.handleCommentValueChange(e, this.props.idx, "comments")
            }
          ></textarea>
          <span className="text-muted text-left">
            {/* <i>
              {val.minWord} to {val.maxLength} {val.WordOrCharacter}
            </i> */}
            <input
              type="number"
              className={
                val.edit ? "tt-min-max-input-highlight" : "tt-min-max-input"
              }
              name="minLength"
              value={val.minLength}
              onChange={(e) =>
                this.props.handleNestedChange(e, this.props.idx, "comments")
              }
              id={"minLength" + this.props.idx}
            />
            <i className="tt-min-max-to">to</i>
            <input
              type="number"
              className={
                val.edit ? "tt-min-max-input-highlight" : "tt-min-max-input"
              }
              name="maxLength"
              value={val.maxLength}
              onChange={(e) =>
                this.props.handleNestedChange(e, this.props.idx, "comments")
              }
              id={"maxLength" + this.props.idx}
            />
            <i className="tt-min-max-select">{val.commentTextType}</i>
          </span>

          <span className="text-muted" style={{ float: "right" }}>
            {/* <i>{val.commentor.split(",")[1]}</i> */}
          </span>
        </td>
        <td className="tt-subjectDisplayTable">
          <select
            className="form-control"
            name="commentor"
            value={
              val.hasRole ? `${val.subRoleId},role` : `${val.commentor},staff`
            }
            onChange={(e) =>
              this.props.handlecommentorChange(e, this.props.idx, "comments")
            }
          >
            <option value="" disabled>
              Select Commenter
            </option>

            <optgroup label="Roles">
              {this.props.roleList
                ? this.props.roleList.map((role, idx) => {
                    let val = `${role.subRoleId},role`;
                    return (
                      <option key={idx} value={val}>
                        {role.name}
                      </option>
                    );
                  })
                : null}
            </optgroup>
            <optgroup label="Staffs">
              {this.props.recruitmentList
                ? this.props.recruitmentList.map((teacher, i) => {
                    let val = `${teacher.id},staff`;
                    return (
                      <option value={val} key={i}>
                        {teacher.people.name}
                      </option>
                    );
                  })
                : null}
            </optgroup>
          </select>
        </td>
        <td className="tt-subjectDisplayTable">
          <FontAwesomeIcon
            icon={faEdit}
            size="2x"
            onClick={(e) =>
              this.props.handleUpdateCommentClick(e, this.props.idx)
            }
          />
          &nbsp; &nbsp; &nbsp;
          <FontAwesomeIcon
            icon={faTrashAlt}
            size="2x"
            onClick={(e) =>
              this.props.handleRemoveCommentClick(e, this.props.idx)
            }
          />
        </td>
      </tr>
    );
  }
}

export default CommentSectionTableRow;
