import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "./../UI/ModalWindow";
import swal from "sweetalert";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBook,
  faCalendar,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import { displayErrorAlert, displayErrorMessage } from "../../utils/Utils";

class StaffLeaveList extends Component {
  state = {
    remarkLeaveModal: false,
    leaveDetailModal: false,
    remarks: "",
    leaveFrom: "",
    leaveTo: "",
    name: "",
    spinner: false,
    listSpinner: false,
    leaveLists: [],
    leaveDetailData: "",
    status: "",
    listSpiner: false,
  };

  componentDidMount() {
    this.getAllLeaveList();
  }

  getDateDiff = (date1, date2) => {
    let d1 = moment(date1);
    let d2 = moment(date2);
    let dateDiff = d2.diff(d1, "days");
    return dateDiff;
  };

  getAllLeaveList = () => {
    let data = {
      fromDate: this.state.leaveFrom,
      toDate: this.state.leaveTo,
      status: this.state.status,
      title: this.state.name,
    };

    this.setState(
      {
        listSpiner: true,
      },
      () => {
        axiosPost(
          URL.getStaffLeaveList,
          data,
          (response) => {
            if (response.data.status === 200) {
              this.setState({
                leaveLists: response.data.data,
                listSpiner: false,
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              listSpiner: false,
            });
          }
        );
      }
    );
  };

  handleLeaveRemarkModal = (type, leaveData) => {
    this.setState({
      remarkLeaveModal: !this.state.remarkLeaveModal,
      leaveAction: type,
      remarks: "",
      activeLeaveId: leaveData.id,
    });
  };
  handleLeaveDetailModal = (data) => {
    this.setState({
      leaveDetailModal: !this.state.leaveDetailModal,
      leaveDetailData: data,
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleLeaveStatusChange = () => {
    let data = {
      id: this.state.activeLeaveId,
      status: this.state.leaveAction === "accept" ? "accepted" : "rejected",
      remarks: this.state.remarks,
    };

    this.setState(
      {
        spinner: true,
        remarkLeaveModal: false,
      },
      () => {
        axiosPost(
          URL.changeLeaveStatus,
          data,
          (response) => {
            if (response.data.status === 200) {
              swal("Success", response.data.message);
              this.setState({
                spinner: false,
                remarks: "",
              });

              this.getAllLeaveList();
            }
          },
          (err) => {
            displayErrorMessage(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          {this.state.spinner ? (
            <div className="fullWindow-Spinner">
              <div>
                <Spinner color="white"></Spinner>
              </div>
              <div style={{ fontSize: "16px", marginTop: "15px" }}>
                Please wait...
              </div>
            </div>
          ) : null}
          <div className="row mb-4">
            <div className="col-md-2">
              <label>From Date</label>
              <input
                type="date"
                className="form-control"
                value={this.state.leaveFrom}
                name="leaveFrom"
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className="col-md-2">
              <label>To Date</label>
              <input
                type="date"
                className="form-control"
                value={this.state.leaveTo}
                name="leaveTo"
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className="col-md-2">
              <label>Subject</label>
              <input
                type="text"
                className="form-control"
                value={this.state.name}
                name="name"
                placeholder="Subject"
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className="col-md-3">
              <label>Status</label>

              <select
                name="status"
                className="form-control"
                value={this.state.status}
                onChange={(e) => this.handleChange(e)}
              >
                <option selected>Choose status</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
                <option value="pending">Pending</option>
              </select>
            </div>
            <div className="col-md-2 mt-4">
              <button
                className="tt-button tt-button-primary"
                onClick={this.getAllLeaveList}
              >
                Search
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead className="tt-group-header">
                <tr>
                  <th>SN.</th>
                  <th>Applied By</th>
                  <th style={{ width: "12%" }}>Leave From</th>
                  <th style={{ width: "12%" }}>Leave To</th>
                  <th>Duration (Days)</th>
                  <th>Leave Type</th>
                  <th>Subject</th>
                  {/* <th>Description</th> */}
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody style={{ fontWeight: "normal", textShadow: "none" }}>
                {this.state.leaveLists?.length > 0 ? (
                  this.state.leaveLists.map((data, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{data?.people?.name}</td>
                      <td>{moment(data?.fromDate).format("YYYY-MM-DD")}</td>
                      <td>{moment(data?.toDate).format("YYYY-MM-DD")}</td>
                      <td>
                        {this.getDateDiff(data?.fromDate, data?.toDate) + 1}{" "}
                        {this.getDateDiff(data?.fromDate, data?.toDate) === 0
                          ? "Day"
                          : "Days"}
                      </td>
                      <td>{data?.leaveTypeName}</td>
                      <td>{data?.subject}</td>
                      {/* <td>{data.description}</td> */}
                      <td className="text-capitalize">{data?.status}</td>
                      <td>
                        {data?.status === "pending" ? (
                          <>
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() =>
                                this.handleLeaveRemarkModal("accept", data)
                              }
                            >
                              Accept
                            </button>
                            <button
                              className="tt-button tt-button-danger"
                              onClick={() =>
                                this.handleLeaveRemarkModal("reject", data)
                              }
                            >
                              Reject
                            </button>
                          </>
                        ) : null}
                        <button
                          className="tt-button tt-button-primary"
                          onClick={() => this.handleLeaveDetailModal(data)}
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={9}>
                      {this.state.listSpiner ? (
                        <Spinner color="white"></Spinner>
                      ) : (
                        "No records to display !!"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* leave request reject modal start */}
          <ModalWindow
            modal={this.state.remarkLeaveModal}
            // size="lg"
            id="tt-permissionModal"
            toggleModal={() => {
              this.setState({
                remarkLeaveModal: !this.state.remarkLeaveModal,
                remarks: "",
              });
            }}
            modalHeader={"Remarks"}
            modalBody={
              <>
                <textarea
                  className="form-control"
                  name="remarks"
                  placeholder="Remarks"
                  onChange={this.handleChange}
                  value={this.state.remarks}
                ></textarea>
                <button
                  className="tt-button tt-button-primary mt-2"
                  onClick={() => this.handleLeaveStatusChange()}
                >
                  Submit
                </button>
              </>
            }
          ></ModalWindow>
          {/* leave request reject modal end */}
          {/* leave request details start */}
          <ModalWindow
            modal={this.state.leaveDetailModal}
            size="lg"
            id="tt-permissionModal"
            toggleModal={() => {
              this.setState({
                leaveDetailModal: !this.state.leaveDetailModal,
              });
            }}
            modalHeader={"Leave Detail"}
            modalBody={
              this.state.leaveDetailData !== "" && (
                <div className="p-3">
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-3 d-flex justify-content-between">
                      <p>
                        <small className="text-secondary">
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            style={{ color: "#243C57" }}
                          />{" "}
                          Applied by
                        </small>{" "}
                        <br />
                        <b className="text-primary">
                          {this.state.leaveDetailData.people.name}
                        </b>
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p>
                        <small className="text-secondary">
                          {" "}
                          <FontAwesomeIcon icon={faBook} /> Subject
                        </small>{" "}
                        <br />
                        {this.state.leaveDetailData.subject}
                      </p>
                    </div>
                    <div className="col-md-3 d-flex justify-content-between">
                      <p>
                        <small className="text-secondary">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{ color: "gray" }}
                          />{" "}
                          From
                        </small>{" "}
                        <br />
                        {moment(this.state.leaveDetailData.fromDate).format(
                          "YYYY-MM-DD"
                        )}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p>
                        <small className="text-secondary">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{ color: "gray" }}
                          />{" "}
                          To
                        </small>{" "}
                        <br />
                        {moment(this.state.leaveDetailData.toDate).format(
                          "YYYY-MM-DD"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>
                        <small className="text-secondary">
                          <FontAwesomeIcon
                            icon={faBook}
                            style={{ color: "gray" }}
                          />{" "}
                          Description
                        </small>{" "}
                        <br />
                        {this.state.leaveDetailData.description}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <small className="text-secondary">
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            style={{ color: "gray" }}
                          />{" "}
                          Additional Details
                        </small>{" "}
                        <br />
                        {this.state.leaveDetailData.additionalDescription}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>
                        <small className="text-secondary">Status</small> <br />
                        {this.state.leaveDetailData.status === "accepted" ? (
                          <b className="text-capitalize text-success">
                            {this.state.leaveDetailData.status}
                          </b>
                        ) : this.state.leaveDetailData.status === "rejected" ? (
                          <b className="text-capitalize text-danger">
                            {this.state.leaveDetailData.status}
                          </b>
                        ) : (
                          <b className="text-capitalize">
                            {this.state.leaveDetailData.status}
                          </b>
                        )}
                      </p>
                    </div>
                    {this.state.leaveDetailData.remarks !== "" && (
                      <div className="col-md-6">
                        <p>
                          <small className="text-secondary">Remarks</small>{" "}
                          <br />
                          {this.state.leaveDetailData.remarks}
                        </p>
                      </div>
                    )}
                  </div>
                  {this.state.leaveDetailData.status !== "pending" ? (
                    <p>
                      <small className="text-secondary">
                        {this.state.leaveDetailData.status === "accepted"
                          ? "Approved"
                          : "Rejected"}{" "}
                        By
                      </small>{" "}
                      <br />
                      {this.state.leaveDetailData.approvedByName}
                    </p>
                  ) : null}
                </div>
              )
            }
          ></ModalWindow>
          {/* leave request details end */}
        </div>
      </div>
    );
  }
}
export default StaffLeaveList;
