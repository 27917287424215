import React, { Component } from "react";
import CustomEditButton from "../../components/common/CustomEditButton";
import CustomModal from "../../components/common/CustomModal";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import SystemExamConfiguration from "./SystemExamConfiguration";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import swal from "sweetalert";
import ModalWindow from "../../components/UI/ModalWindow";
import { Spinner } from "reactstrap";

export class ExamConfigList extends Component {
  state = {
    examList: [],
    editMode: false,
    editData: "",
    trainings: [],
    deleteModal: false,
    deletedRowData: null,
    spinner: false,
  };

  componentDidMount() {
    this.getExamList();
    this.getTrainings();
  }

  toggleDeleteModal = () => {
    this.setState({ deleteModal: !this.state.deleteModal });
  };

  getTrainings = () => {
    axiosPost(URL.getAllTraining, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          trainings: response.data.data,
        });
      }
    });
  };

  getExamList = () => {
    axiosGet(
      URL.getAllExamList,

      (response) => {
        const examList = response.data.data;
        // const theoryQuestions =
        //   questions && questions.filter((q) => q.isTheory);
        // const practicalQuestions =
        //   questions && questions.filter((q) => !q.isTheory);
        this.setState({ examList });
      },
      (err) => {}
    );
  };

  handleEditClick = (exam) => {
    this.setState({
      editMode: true,
      editData: exam,
    });
  };

  toggleEditModal = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  };

  trainingName = (exam) => {
    let name = this.state.trainings.find(
      (t) => t.id === exam.currentTrainingId
    );
    return name?.name;
  };

  handleDeleteExamList = (items) => {
    let params = {
      id: this.state.deletedRowData.id,
    };

    // this.state.deletedRowData?.examQuestions?.answers?.length > 0 &&
    // this.state.deletedRowData?.examQuestions?.answers?.some(a => a.isSubmitted)
    //   ? swal("Error", "cannot Deleted Successfully.")
    //   : axiosPost(URL.deleteExamList, params, response => {
    //       if (response.status === 200) {
    //         swal("Success", "Record Deleted Successfully.");
    //       }
    //     });
    this.state.deletedRowData.examQuestions.length > 0 &&
      this.state.deletedRowData.examQuestions.map((item) => {
        item.answers.length > 0
          ? item.answers.map((values) => values.isSubmitted)
            ? swal("Error", "cannot Deleted Successfully.")
            : axiosPost(URL.deleteExamList, params, (response) => {
                if (response.status === 200) {
                  swal("Success", "Record Deleted Successfully.");
                  this.toggleDeleteModal();
                  this.getExamList();
                }
              })
          : axiosPost(URL.deleteExamList, params, (response) => {
              if (response.status === 200) {
                swal("Success", "Record Deleted Successfully.");
                this.toggleDeleteModal();
                this.getExamList();
              }
            });
      });
  };

  render() {
    return (
      <>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="whiteContainer">
          <CustomPageHeader title="Exam List" />
          <div className="row">
            <div className="col-lg-12">
              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>Training</th>
                    <th>Exam Title</th>
                    <th>Full Marks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.examList.length > 0
                    ? this.state.examList.map((exam, index) => {
                        return (
                          <tr>
                            <td>{this.trainingName(exam)}</td>
                            <td>{exam.title}</td>
                            <td>{exam.fullMarks}</td>

                            <td>
                              <CustomDeleteButton
                                onClick={() => {
                                  this.toggleDeleteModal();
                                  this.setState({
                                    deletedRowData: exam,
                                  });
                                }}
                              />
                              <CustomEditButton
                                onClick={() => this.handleEditClick(exam)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>

          {this.state.editMode ? (
            <CustomModal
              size="xl"
              modal={this.state.editMode}
              modalHeader="Exam Configuration"
              modalBody={
                <SystemExamConfiguration
                  editMode={this.state.editMode}
                  editData={this.state.editData}
                  toggleEditModal={this.toggleEditModal}
                  getExamList={this.getExamList}
                />
              }
              toggleModal={this.toggleEditModal}
            ></CustomModal>
          ) : null}
        </div>
        <ModalWindow
          size="md"
          modal={this.state.deleteModal}
          modalHeader={"Delete Exam List"}
          modalBody={
            <>
              <h4>Are you sure want to delete ?</h4>
              <button
                className="btn btn-primary cusBtn cusBtnFontSize"
                onClick={this.handleDeleteExamList}
              >
                Submit
              </button>
              <button
                className="btn btn-primary cusBtn cusBtnFontSize"
                onClick={this.toggleDeleteModal}
              >
                Cancel
              </button>
            </>
          }
        />
      </>
    );
  }
}

export default ExamConfigList;
