import React, { Component } from "react";
import { questionType } from "../../utils/Constants";

class ExamFormQuestions extends Component {
  state = {};
  render() {
    let { practicalQuestions, theoryQuestions } = this.props;
    return (
      <div className="examBox">
        {theoryQuestions && theoryQuestions.length > 0 && (
          <div className="">
            <div className="row">
              <div className="col">
                <div className="searchBar mt-0">
                  <strong>Exam Questions</strong>
                  {/* <strong>Theory</strong> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col p-4 pt-1">
                {theoryQuestions &&
                  theoryQuestions.map((q, idx) => (
                    <div key={idx} className={idx === 0 ? "" : "mt-3"}>
                      <div className="row">
                        <div className="col">
                          <span className="courseTitle examQuestionTitle">
                            <strong>{`${idx + 1})`}&nbsp;</strong> {q.question}
                          </span>
                          {q?.explanation && (
                            <div
                              className="text-muted"
                              dangerouslySetInnerHTML={{
                                __html: q.explanation,
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {q.questionType === questionType.SUBJECTIVE ? (
                            <div className="row" key={idx}>
                              <div className="col">
                                <textarea
                                  className="form-control"
                                  rows={4}
                                  name="subjectiveAnswer"
                                  onCut={(e) => e.preventDefault()}
                                  onCopy={(e) => e.preventDefault()}
                                  onPaste={(e) => e.preventDefault()}
                                  onChange={(e) =>
                                    this.props.handleAnswerChange(
                                      e,
                                      "theoryQuestions",
                                      questionType.SUBJECTIVE,
                                      idx
                                    )
                                  }
                                  placeholder="Enter your answer here"
                                />
                              </div>
                            </div>
                          ) : q.questionType === questionType.OBJECTIVE ? (
                            <>
                              {q.examQuestionOptions &&
                                q.examQuestionOptions.length > 0 &&
                                q.examQuestionOptions.map((opt, oIdx) => (
                                  <div className="row mb-1" key={oIdx}>
                                    <div className="col-md-1 text-end">
                                      <input
                                        type="checkbox"
                                        name="ticked"
                                        checked={opt.ticked}
                                        onClick={(e) =>
                                          this.props.handleAnswerChange(
                                            e,
                                            "theoryQuestions",
                                            questionType.OBJECTIVE,
                                            idx,
                                            oIdx
                                          )
                                        }
                                        id={`ques${idx}ans${oIdx}`}
                                      ></input>
                                    </div>
                                    <div className="col-md-11">
                                      <label
                                        className="answerText"
                                        htmlFor={`ques${idx}ans${oIdx}`}
                                      >
                                        {opt.optionValue}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {practicalQuestions && practicalQuestions.length > 0 && (
          <div className="">
            <div className="row">
              <div className="col">
                <div className="searchBar mt-0">
                  <strong>Practical</strong>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col p-4">
                {practicalQuestions &&
                  practicalQuestions.map((q, idx) => (
                    <>
                      <div className="row">
                        <div className="col-md-1">
                          <span>
                            <strong>{`${idx + 1})`}&nbsp;</strong>
                          </span>
                        </div>
                        <div className="col-md-11">{q.question}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-11">
                          {q.questionType === questionType.SUBJECTIVE ? (
                            <>
                              <div className="row mt-2 mb-2" key={idx}>
                                <div className="col-md-3"></div>
                                <div className="col-md-7">
                                  <textarea
                                    className="form-control"
                                    rows={4}
                                    name="subjectiveAnswer"
                                    onCut={(e) => e.preventDefault()}
                                    onCopy={(e) => e.preventDefault()}
                                    onPaste={(e) => e.preventDefault()}
                                    onChange={(e) =>
                                      this.props.handleAnswerChange(
                                        e,
                                        "practicalQuestions",
                                        questionType.SUBJECTIVE,
                                        idx
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ) : q.questionType === questionType.OBJECTIVE ? (
                            <>
                              {q.examQuestionOptions &&
                                q.examQuestionOptions.length > 0 &&
                                q.examQuestionOptions.map((opt, oIdx) => (
                                  <>
                                    <div className="row mt-2 mb-2" key={oIdx}>
                                      <div className="col-md-3"></div>
                                      <div className="col-md-7">
                                        <div className="row">
                                          <div className="col-md-2 text-center">
                                            <input
                                              type="checkbox"
                                              name="ticked"
                                              checked={opt.ticked}
                                              onClick={(e) =>
                                                this.props.handleAnswerChange(
                                                  e,
                                                  "practicalQuestions",
                                                  questionType.OBJECTIVE,
                                                  idx,
                                                  oIdx
                                                )
                                              }
                                              style={{
                                                position: "relative",
                                                top: "8px",
                                              }}
                                            ></input>
                                          </div>
                                          <div className="col-md-10 text-center">
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="optionValue"
                                              value={opt.optionValue}
                                            ></input>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ExamFormQuestions;
