import React, { Component } from "react";
import FileViewer from "react-file-viewer";

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        {this.props.fileType === "mp4" && this.props.filePath !== null ? (
          <FileViewer
            fileType={this.props.fileType}
            filePath={this.props.filePath}
          />
        ) : (
          <div>
            <p>This file is not supported</p>
          </div>
        )}
      </div>
    );
  }
}

export default VideoPlayer;
