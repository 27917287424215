import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL } from "../../../../utils/Constants";
import SubjectAssessmentDetailTableRow from "./SubjectAssessmentDetailTableRow";
import GeneralAssessmentDetailTableRow from "./GeneralAssessmentDetailTableRow";
import { showErroMessage } from "../../../../utils/Utils";

class SubjectAssessmentDetails extends Component {
  state = {
    subjectAssessmentDetail: [],
    generalAssessmentDetail: {},
  };
  componentDidMount() {
    this.getSubjects();
    this.getGeneralAssessmentDetails();
  }

  getGeneralAssessmentDetails = () => {
    let param = {
      assessmentId: this.props.subjectParams.assessmentId,
      yearLevelId: this.props.subjectParams.yearLevelId,
      classroomId: this.props.subjectParams.classroomId,
    };

    axiosPost(URL.generalAssessmentSelect, param, (response) => {
      if (response.status === 200) {
        this.setState({ generalAssessmentDetail: response.data.data });
      } else {
        showErroMessage(response);
      }
    });
  };

  getSubjects = () => {
    axiosPost(
      URL.getSubjectListForSubjectAssessmentSetting,
      this.props.subjectParams,
      (response) => {
        if (response.status === 200) {
          let subjectAssessmentDetail = response.data.data;
          this.setState({
            subjectAssessmentDetail,
          });
        }
      }
    );
  };

  tableRow() {
    return this.state.subjectAssessmentDetail
      ? this.state.subjectAssessmentDetail.map((subject, subjectIdx) => {
          return (
            <SubjectAssessmentDetailTableRow
              subject={subject}
              subjectIdx={subjectIdx}
            />
          );
        })
      : null;
  }

  tableRowForGeneral() {
    return this.state.generalAssessmentDetail ? (
      <GeneralAssessmentDetailTableRow
        generalDetail={this.state.generalAssessmentDetail}
      />
    ) : null;
  }
  render() {
    return (
      <>
        {this.props.subjectConfig ? (
          <>
            <div className="tt-group-header"> Subject Assessment Details</div>
            <table className="table table-striped">
              <thead className="text-center">
                <tr>
                  <th>Subjects</th>
                  <th>Assessment Date(from/to)</th>
                  <th>Recording Date</th>
                  <th>Recording Deadline Date</th>
                </tr>
              </thead>
              <tbody className="text-center">{this.tableRow()}</tbody>
            </table>
          </>
        ) : null}{" "}
        {this.props.generalConfig ? (
          <>
            <div className="tt-group-header"> General Assessment Details</div>
            <table className="table table-striped">
              <thead className="text-center">
                <tr>
                  <th>Assessment From</th>
                  <th>Assessment To</th>
                  <th>Recording Date</th>
                  <th>Deadline Date</th>
                </tr>
              </thead>
              <tbody className="text-center">{this.tableRowForGeneral()}</tbody>
            </table>
          </>
        ) : null}
      </>
    );
  }
}

export default SubjectAssessmentDetails;
