import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import { displayConfirmDeleteAlert } from "../../utils/DisplayMessage";
import ModalWindow from "../../UI/ModalWindow";
import TrainingResources from "./TrainingResources";
import DragDropFile from "../../UI/DragDropFile";
import CustomEditButton from "../../components/common/CustomEditButton";

class ListCurrentTraining extends Component {
  state = {};
  deleteTraining = (datum) => {
    let data = {
      id: datum.id,
    };
    axiosPost(URL.deleteTraining, data, (response) => {
      if (response.status == 200) {
        this.props.getAllTraining();
        swal("Success", response.data.message);
      }
    });
  };

  handleDelete = (id, idx) => {
    displayConfirmDeleteAlert({ id: id, idx: idx }, this.deleteTraining);
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let data = {
      id: item.id,
      display: checked,
      nameEn: item.nameEn,
      nameNp: item.nameNp,
      link: item.link,
    };

    axiosPost(URL.changeTrainingStatus, data, (response) => {
      if (response.status === 200) {
        this.props.getAllTraining();
      }
    });
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  {/* <th>Image</th> */}
                  <th>Name</th>
                  <th>Link</th>
                  <th>Description</th>
                  <th>Host</th>
                  {/* <th>Host Details</th> */}
                  <th>Resources</th>
                  <td>Display</td>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.trainings.length > 0
                  ? this.props.trainings.map((training, index) => {
                      return (
                        <tr>
                          {/* <td>
                            <img
                              width="100"
                              src={URL.imageSelectURL + training.imagePath}
                            />
                          </td> */}
                          <td>{training.name}</td>
                          <td>{training.link}</td>
                          <td>{training.description}</td>
                          <td>{training.hostName}</td>
                          {/* <td>{training.hostDetail}</td> */}

                          <td>
                            <button
                              className="btn btn-primary cusBtn cusBtnFontSize mr-2"
                              onClick={() =>
                                this.props.handleViewResources(training)
                              }
                            >
                              View
                            </button>
                            <button
                              className="btn btn-primary cusBtn cusBtnFontSize"
                              onClick={() =>
                                this.props.addResourceModal(training)
                              }
                            >
                              Add
                            </button>
                          </td>
                          <td>
                            <label className="switch mt-2">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  this.handleChangeToggle(e, training)
                                }
                                checked={training.display}
                                name="display"
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <CustomDeleteButton
                              onClick={() =>
                                this.handleDelete(training.id, index)
                              }
                            />
                            <CustomEditButton
                              onClick={() =>
                                this.props.handleEditTraining(training)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>

        <ModalWindow
          modal={this.props.resourceModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={this.props.toggleResourceModal}
          modalHeader={"Training Resources"}
          modalBody={
            <TrainingResources
              resourceData={this.props.resourceData}
              deleteResourceImg={this.props.deleteResourceImg}
            />
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.props.resourceAddModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={this.props.toggleAddResource}
          modalHeader={"Add Training Resources"}
          modalBody={
            <>
              {/* <input
                type="file"
                name="addedFiles"
                multiple
                onChange={(e) => this.props.handleChange(e)}
              /> */}

              <DragDropFile
                multiple={true}
                handleChange={this.props.handleChange}
                name="addedFiles"
                handleFileDrop={this.props.handleFileDrop}
                files={this.props.addedFiles}
                accept="image/png, image/jpeg, image/jpg"
              />

              <button
                className="btn btn-primary cusBtn cusBtnFontSize mt-3"
                onClick={this.props.handleAddResource}
              >
                Add Resources
              </button>
            </>
          }
        ></ModalWindow>
      </>
    );
  }
}

export default ListCurrentTraining;
