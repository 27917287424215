import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import ModalWindow from "../../UI/ModalWindow";
import { axiosPost } from "../../utils/AxiosApi";
import { postType, URL } from "../../utils/Constants";
import CustomPageHeader from "../common/CustomPageHeader";
import DiscussionTemplate from "../Profile/Templates/DiscussionTemplate";
import CentralDiscussionPostForm from "./CentralDiscussionPostForm";
import DiscussionDashboardForm from "./DiscussionDashboardForm";

class DiscussionDashboard extends Component {
  state = {
    dicussionform: false,
    groups: [],
    groupId: "",
    currentRecordsLength: 0,
    totalGroupDiscussions: 0,
    groupDiscussions: [],
    page: 0
  };

  componentDidMount() {
    this.getAllGroups();
  }

  toggleDiscussionform = () => {
    this.setState({ dicussionform: !this.state.dicussionform });
  };

  getAllGroups = () => {
    axiosPost(URL.getAllGroupsCentral, {}, response => {
      if (response.status === 200) {
        let groups = response.data.data;
        this.setState({
          groups
        });
      }
    });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "groupId") {
      this.getDiscussionsByGroup(value);
    }
  };

  getDiscussionsByGroup = groupId => {
    this.setState({ postSpinner: true }, () => {
      const param = {
        groupId: groupId || this.state.groupId,
        postType: postType.discussion,
        limitOne: false
      };

      axiosPost(
        URL.selectGroupPost,
        param,
        response => {
          if (response.status === 200) {
            let groupDiscussions = response.data.data;
            let totalGroupDiscussions = response.data.totalRecordsCount;
            let currentRecordsLength = groupDiscussions.length;
            this.setState({
              groupDiscussions,
              totalGroupDiscussions,
              currentRecordsLength,
              postSpinner: false
            });
          } else {
            this.setState({
              postSpinner: false
            });
          }
        },
        err => {
          this.setState({
            postSpinner: false
          });
        }
      );
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="whiteContainer">
                <CustomPageHeader noIcon={true} title="Discussions" />
                <div></div>
                <div className="mt-4"></div>
                <div className="row">
                  <div className="col">
                    <div className="col-md-3">
                      <h3 className="heading-03">Groups</h3>
                    </div>
                    <div className="col-md-9">
                      <select
                        name="groupId"
                        value={this.state.groupId}
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Choose a group
                        </option>
                        {this.state.groups
                          ? this.state.groups.map((g, idx) => {
                              let val = `${g.id}`;
                              return (
                                <option key={idx} value={val}>
                                  {g.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize float-right"
                    onClick={() => {
                      if (this.state.groupId !== "") {
                        this.setState({ dicussionform: true });
                      } else {
                        swal(
                          "Error",
                          "Please first select the group to create discussion."
                        );
                      }
                    }}
                  >
                    Start Discussion
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.state.groupDiscussions ? (
                this.state.groupDiscussions.length > 0 ? (
                  this.state.groupDiscussions.map((post, pidx) => {
                    return (
                      <div className="tt-post-template" key={pidx}>
                        {post?.posts[0]?.postType === postType.discussion ? (
                          <>
                            <DiscussionTemplate
                              post={post.posts[0]}
                              key={pidx}
                              central={true}
                              // handleCurrentPost={this.props.handleCurrentPost}
                              getDiscussionsByGroup={this.getDiscussionsByGroup}
                            />
                            <hr></hr>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center mt-3 mb-3">
                    {this.state.postSpinner ? (
                      <>
                        <Spinner color="primary"></Spinner>
                        <br></br>
                        Loading Data ...
                      </>
                    ) : (
                      <p>No posts to display</p>
                    )}
                  </div>
                )
              ) : (
                <p>No post to display</p>
              )}
            </div>
          </div>
          {this.state.groupId && this.state.groupId !== "" && (
            <ModalWindow
              modal={this.state.dicussionform}
              size="lg"
              id="tt-permissionModal"
              toggleModal={this.toggleDiscussionform}
              modalHeader={"Create a Discussion Post"}
              modalBody={
                <DiscussionDashboardForm
                  groupId={this.state.groupId}
                  toggleModal={this.toggleDiscussionform}
                  getDiscussionsByGroup={this.getDiscussionsByGroup}
                />
              }
            />
          )}
        </div>
      </>
    );
  }
}

export default DiscussionDashboard;
