import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
class ListAssignedTeacher extends Component {
  render() {
    let yearLevel = this.props.yearLevel;
    let index = this.props.index;
    return (
      <>
        {yearLevel ? (
          <table className="table table-bordered table-striped">
            {yearLevel.educationLevelDivisions
              ? yearLevel.educationLevelDivisions.map(
                  (educationLevelDivision, eduLevelIndex) => (
                    <>
                      <thead className="tt-group-header">
                        <th colSpan={5} className="text-center">
                          {yearLevel ? ` ${yearLevel.name} - ` : null}
                          {educationLevelDivision.educationLevelDivisionName}
                        </th>
                      </thead>

                      <thead className="tt-group-header text-center">
                        <tr>
                          <th>{localStorage.getItem("classroom")}</th>
                          <th width="30%">Assigned Teachers</th>
                          <th width="20%">Options</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {educationLevelDivision.classrooms ? (
                          educationLevelDivision.classrooms.length > 0 ? (
                            educationLevelDivision.classrooms.map(
                              (classroom, classIndex) => {
                                return (
                                  <tr key={classIndex}>
                                    <td>
                                      {" "}
                                      {yearLevel.name} {classroom.name}
                                    </td>
                                    <td>
                                      {classroom.subjectClassroomTeachers &&
                                      classroom.subjectClassroomTeachers
                                        .length > 0 ? (
                                        !classroom.unAssigningTeacher ? (
                                          classroom.subjectClassroomTeachers.map(
                                            (subTeacher, idx) => {
                                              return (
                                                <p
                                                  key={idx}
                                                  className="mb-0 d-block"
                                                >
                                                  {subTeacher.peopleName}
                                                </p>
                                              );
                                            }
                                          )
                                        ) : (
                                          <Select
                                            closeMenuOnSelect={false}
                                            isClearable={false}
                                            components={animatedComponents}
                                            isMulti
                                            value={
                                              classroom.selectedsubjectClassroomTeachers
                                            }
                                            options={this.props.classTeachers}
                                            placeholder="Assign Subject Teachers"
                                            name="assignSubjectTeachers"
                                            onChange={(newValue) =>
                                              this.props.handleChangeAssignSelect(
                                                newValue,
                                                index,
                                                eduLevelIndex,
                                                classIndex
                                              )
                                            }
                                            onChangeArgument={classIndex}
                                            hideSelectedOptions={true}
                                            menuPlacement="auto"
                                          />
                                        )
                                      ) : (
                                        <Select
                                          closeMenuOnSelect={false}
                                          isClearable={true}
                                          value={
                                            classroom.selectedsubjectClassroomTeachers
                                          }
                                          components={animatedComponents}
                                          isMulti
                                          options={this.props.classTeachers}
                                          placeholder="Assign Subject Teachers"
                                          name="assignSubjectTeachers"
                                          onChange={(newValue) =>
                                            this.props.handleChangeAssignSelect(
                                              newValue,
                                              index,
                                              eduLevelIndex,
                                              classIndex
                                            )
                                          }
                                          onChangeArgument={classIndex}
                                          hideSelectedOptions={true}
                                          menuPlacement="auto"
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {classroom.subjectClassroomTeachers &&
                                      classroom.subjectClassroomTeachers
                                        .length > 0 ? (
                                        classroom.unAssigningTeacher ? (
                                          <>
                                            <button
                                              className="tt-button tt-button-primary tt-button-fullWidth"
                                              onClick={() =>
                                                this.props.submitAssignTeachers(
                                                  classroom,
                                                  classIndex,
                                                  educationLevelDivision.id,
                                                  educationLevelDivision.educationLevelId,
                                                  educationLevelDivision.educationLevelDivisionId,
                                                  index,
                                                  eduLevelIndex,
                                                  classIndex
                                                )
                                              }
                                            >
                                              {"Assign Teacher"}
                                            </button>
                                            <button
                                              className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                                              onClick={() =>
                                                this.props.cancelUnAssignTeachers(
                                                  index,
                                                  eduLevelIndex,
                                                  classIndex
                                                )
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </>
                                        ) : (
                                          <button
                                            className="tt-button tt-button-primary tt-button-fullWidth"
                                            onClick={() =>
                                              this.props.unAssignTeachers(
                                                classroom,
                                                index,
                                                eduLevelIndex,
                                                classIndex
                                              )
                                            }
                                          >
                                            {"Assign Teacher"}
                                          </button>
                                        )
                                      ) : (
                                        <button
                                          className="tt-button tt-button-primary tt-button-fullWidth"
                                          onClick={() =>
                                            this.props.submitAssignTeachers(
                                              classroom,
                                              classIndex,
                                              educationLevelDivision.id,
                                              educationLevelDivision.educationLevelId,
                                              educationLevelDivision.educationLevelDivisionId,
                                              index,
                                              eduLevelIndex,
                                              classIndex
                                            )
                                          }
                                        >
                                          {"Assign Teacher"}
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan="3" align="center">
                                Please assign{" "}
                                {localStorage.getItem("classroom")} for this{" "}
                                {localStorage.getItem("yearLevel")} to proceed
                                further.
                              </td>
                            </tr>
                          )
                        ) : null}
                      </tbody>
                    </>
                  )
                )
              : null}
          </table>
        ) : null}
      </>
    );
  }
}

export default ListAssignedTeacher;
