import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class PostQuestion extends Component {
  state = {};
  render() {
    return (
      <div className="row">
        <div className="col">
          <div className="card" style={{ padding: "10px" }}>
            <div className="card-body postQuestions">
              <strong>Question:</strong>
              {this.props.questions
                ? this.props.questions.map((question, i) => (
                    <div className="tt-add-question-form">
                      <strong style={{ marginTop: "5px", marginRight: "8px" }}>
                        {i + 1}.
                      </strong>
                      <input
                        type="text"
                        className="form-control mb-2"
                        value={question.question}
                        onChange={(e) => this.props.handleQuestionChange(e, i)}
                        name="question"
                      />
                      {i !== 0 ? (
                        <span
                          className="tt-span-grade-remove ml-3 mr-1 mt-2"
                          onClick={(e) => this.props.handleRemoveQuestion(e, i)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                        </span>
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
            <div className="tt-form" style={{ border: "none" }}>
              <div
                className="text-right form-bg-color"
                style={{ padding: "10px" }}
              >
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={this.props.handleAddMoreQuestion}
                >
                  Add
                </button>
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.props.handleQuestionSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PostQuestion;
