import moment from "moment";
import React from "react";

function StudentContactDetails(props) {
  return (
    <div className="stuContactDetailDiv">
      <p>Student Contact Details</p>
      <table className="table table-bordered table-striped">
        <thead className="tt-group-header">
          <tr>
            <th>Full Name</th>
            <th>Gender</th>
            <th>Relationship</th>
            <th>Age</th>
            <th>Email Address</th>
          </tr>
        </thead>
        <tbody>
          {props.contactDetails && props.contactDetails.length > 0 ? (
            props.contactDetails.map((parent, idx) => {
              return (
                <tr key={idx}>
                  <td>{parent.name}</td>
                  <td>{parent.gender}</td>
                  <td>{parent.relationship}</td>
                  <td>
                    {parent.dateOfBirth
                      ? moment().diff(
                          parent.dateOfBirth.substring(0, 10),
                          "years"
                        ) === 0
                        ? "1 years old"
                        : `${moment().diff(
                            parent.dateOfBirth.substring(0, 10),
                            "years"
                          )} years old`
                      : ""}
                  </td>
                  <td>{parent.emailAddress}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5}>No Contact Details Added</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default StudentContactDetails;
