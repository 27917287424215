import swal from "sweetalert";

//default message
const insertMessage = "Video conference has been successfully created",
  updateMessage = "Record has been successfully updated.";
// deleteMessage="Record has been successfully deleted.";

export const DISPLAYMESSAGE = {
    insertSystemSetup: "",

    //subject
    insertSubject: "Subject has been successfully added.",
    updateSubject: "Subject has been successfully updated.",
    deleteSubject: "Subject has been successfully deleted.",
    insertSubjectSetting: "Settings have been successfully saved.",

    //subjectArea
    deleteSubjectArea: "Subject area has been successfully deleted.",
    updateSubjectArea: "Subject area has been successfully updated.",
    //floor
    updateFloor: updateMessage,
    //videoConference
    insertVideoConference: insertMessage,
  },
  MESSAGEHEADER = {
    success: "Success",
    error: "Error",
  };

export const displayErrorAlert = (error) => {
  swal(
    "Error",
    `${error ? (error.response ? error.response.data.message : error) : error}`
  );
};

export const displayConfirmDeleteAlert = (params, handleDelete) => {
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this record!",
    closeOnClickOutside: false,
    allowOutsideClick: false,
    buttons: true,
    dangerMode: true,
  }).then((deleteConfirm) => {
    if (deleteConfirm) {
      handleDelete(params);
    } else {
    }
  });
};
