import React, { Component } from "react";

class TopicDetailReRender extends Component {
  state = {};
  render() {
    return (
      <>
        {this.props.topicList && this.props.topicList.length > 0
          ? this.props.topicList.map((topic, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div className="row" key={idx} style={{ fontSize: "14px" }}>
                    <div className="col">
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <span
                          className="backGround"
                          style={{ paddingLeft: this.props.index * 3 + "0px" }}
                        >
                          {idx + 1}. {topic.name}
                        </span>
                        <span
                          style={{ height: "inherit", width: "5px" }}
                          className="backGround"
                        ></span>
                        <div className="dotsRender"></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row">
                        <div className="col text-center">
                          {topic.lectureHour}
                        </div>
                        <div className="col text-center">
                          {topic.practicalHour}
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.props.courseDetail
                          ? "col-md-2 text-center"
                          : this.props.permissionCanEditProgramCourseDetail ||
                            this.props.permissionCanDeleteProgramCourseDetail
                          ? "col-md-3"
                          : "col-md-2"
                      }
                    >
                      {this.props.courseDetail ? null : (
                        <>
                          {this.props.permissionCanEditProgramCourseDetail ? (
                            <button
                              className="tt-button tt-button-primary mr-2"
                              onClick={() =>
                                this.props.handleUpdateCourseTopic(topic)
                              }
                            >
                              Edit
                            </button>
                          ) : null}
                          {this.props.permissionCanDeleteProgramCourseDetail ? (
                            <button
                              className="tt-button tt-button-primary mr-2"
                              onClick={() =>
                                this.props.handleCourseTopicDelete(topic.id)
                              }
                            >
                              Delete
                            </button>
                          ) : null}
                        </>
                      )}
                      <button
                        className="tt-button tt-button-primary"
                        onClick={() =>
                          this.props.handleTopicClassModal(topic.id)
                        }
                      >
                        Classes
                      </button>
                    </div>
                  </div>
                  {topic.subTopic && topic.subTopic.length > 0 ? (
                    <TopicDetailReRender
                      topicList={topic.subTopic}
                      index={this.props.index + 1}
                      courseDetail={this.props.courseDetail}
                      handleUpdateCourseTopic={
                        this.props.handleUpdateCourseTopic
                      }
                      handleCourseTopicDelete={
                        this.props.handleCourseTopicDelete
                      }
                      permissionCanEditProgramCourseDetail={
                        this.props.permissionCanEditProgramCourseDetail
                      }
                      permissionCanDeleteProgramCourseDetail={
                        this.props.permissionCanDeleteProgramCourseDetail
                      }
                    ></TopicDetailReRender>
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
      </>
    );
  }
}

export default TopicDetailReRender;
