import React from "react";
import { ErrorMessage, useField } from "formik";

export const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-2">
      {label && <label htmlFor={field.name}>{label}</label>}
      <input
        className={`form-control cusInput shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
      <div className="mb-3 mt-1">
        <ErrorMessage
          component="div"
          name={field?.name}
          className="errorMsg-textField"
        />
      </div>
    </div>
  );
};
