import React, { Component } from "react";
import { questionType } from "../../utils/Constants";

class ExamFormQuestions extends Component {
  state = {};
  render() {
    let { practicalQuestions, theoryQuestions } = this.props;
    return (
      <div className="container-fluid">
        <>
          {theoryQuestions && theoryQuestions.length > 0 && (
            <div className="">
              <div className="row">
                <div className="col">
                  <div className="row p-2">
                    <div className="col">
                      <div className="tt-group-header">Theory</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col p-4">
                  {theoryQuestions &&
                    theoryQuestions.map((q, idx) => (
                      <>
                        <div className="row">
                          <div className="col-md-1">
                            <span>
                              <strong>{`${idx + 1})`}&nbsp;</strong>
                            </span>
                          </div>
                          <div className="col-md-11">{q.question}</div>
                        </div>
                        <div className="row">
                          <div className="col-md-11">
                            {q.questionType === questionType.SUBJECTIVE ? (
                              <>
                                <div className="row mt-2 mb-2" key={idx}>
                                  <div className="col-md-3"></div>
                                  <div className="col-md-7">
                                    <textarea
                                      className="form-control"
                                      rows={4}
                                      name="subjectiveAnswer"
                                      onCut={(e) => e.preventDefault()}
                                      onCopy={(e) => e.preventDefault()}
                                      onPaste={(e) => e.preventDefault()}
                                      onChange={(e) =>
                                        this.props.handleAnswerChange(
                                          e,
                                          "theoryQuestions",
                                          questionType.SUBJECTIVE,
                                          idx
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : q.questionType === questionType.OBJECTIVE ? (
                              <>
                                {q.subjectQuestionOptions &&
                                  q.subjectQuestionOptions.length > 0 &&
                                  q.subjectQuestionOptions.map((opt, oIdx) => (
                                    <>
                                      <div className="row mt-2 mb-2" key={oIdx}>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-7">
                                          <div className="row">
                                            <div className="col-md-2 text-center">
                                              <input
                                                type="checkbox"
                                                name="ticked"
                                                checked={opt.ticked}
                                                style={{
                                                  position: "relative",
                                                  top: "8px",
                                                }}
                                                onClick={(e) =>
                                                  this.props.handleAnswerChange(
                                                    e,
                                                    "theoryQuestions",
                                                    questionType.OBJECTIVE,
                                                    idx,
                                                    oIdx
                                                  )
                                                }
                                              ></input>
                                            </div>
                                            <div className="col-md-10 text-center">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="optionValue"
                                                value={opt.optionValue}
                                              ></input>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          )}
        </>
        <>
          {practicalQuestions && practicalQuestions.length > 0 && (
            <div className="">
              <div className="row">
                <div className="col">
                  <div className="row p-2">
                    <div className="col">
                      <div className="tt-group-header">Practical</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col p-4">
                  {practicalQuestions &&
                    practicalQuestions.map((q, idx) => (
                      <>
                        <div className="row">
                          <div className="col-md-1">
                            <span>
                              <strong>{`${idx + 1})`}&nbsp;</strong>
                            </span>
                          </div>
                          <div className="col-md-11">{q.question}</div>
                        </div>
                        <div className="row">
                          <div className="col-md-11">
                            {q.questionType === questionType.SUBJECTIVE ? (
                              <>
                                <div className="row mt-2 mb-2" key={idx}>
                                  <div className="col-md-3"></div>
                                  <div className="col-md-7">
                                    <textarea
                                      className="form-control"
                                      rows={4}
                                      name="subjectiveAnswer"
                                      onCut={(e) => e.preventDefault()}
                                      onCopy={(e) => e.preventDefault()}
                                      onPaste={(e) => e.preventDefault()}
                                      onChange={(e) =>
                                        this.props.handleAnswerChange(
                                          e,
                                          "practicalQuestions",
                                          questionType.SUBJECTIVE,
                                          idx
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : q.questionType === questionType.OBJECTIVE ? (
                              <>
                                {q.subjectQuestionOptions &&
                                  q.subjectQuestionOptions.length > 0 &&
                                  q.subjectQuestionOptions.map((opt, oIdx) => (
                                    <>
                                      <div className="row mt-2 mb-2" key={oIdx}>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-7">
                                          <div className="row">
                                            <div className="col-md-2 text-center">
                                              <input
                                                type="checkbox"
                                                name="ticked"
                                                checked={opt.ticked}
                                                onClick={(e) =>
                                                  this.props.handleAnswerChange(
                                                    e,
                                                    "practicalQuestions",
                                                    questionType.OBJECTIVE,
                                                    idx,
                                                    oIdx
                                                  )
                                                }
                                                style={{
                                                  position: "relative",
                                                  top: "8px",
                                                }}
                                              ></input>
                                            </div>
                                            <div className="col-md-10 text-center">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="optionValue"
                                                value={opt.optionValue}
                                              ></input>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
}

export default ExamFormQuestions;
