import React, { Component } from "react";

export class ListGeneralSetting extends Component {
  render() {
    return (
      <div>
        <div className="container py-3">
          <button
            className="tt-button tt-button-primary mb-3"
            onClick={this.props.handleAddSetting}
          >
            Add Setting
          </button>

          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.generalSettings.length > 0 ? (
                this.props.generalSettings.map((item) => (
                  <tr>
                    <td>{item.aliasName}</td>
                    <td>{item.description}</td>
                    <td>{item.value}</td>
                    <td>
                      <button
                        className="tt-button tt-button-primary mb-3"
                        onClick={() => this.props.handleEditSettingClick(item)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center py-2">
                    No records available !!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ListGeneralSetting;
