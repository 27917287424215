import React from "react";
import { ErrorMessage, Field, useField } from "formik";

export const SelectField = (props) => {
  const { optionFields } = props;
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <Field
        name={field.name}
        // className="form-control cusInput"
        className={`form-control cusInput ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        component="select"
      >
        <option value="">Choose Education Level...</option>
        {optionFields?.length > 0
          ? optionFields.map((name) => {
              return (
                <option value={name.id}>
                  {name.name} || {name.code}
                </option>
              );
            })
          : null}
      </Field>

      <div className="mb-3 mt-1">
        <ErrorMessage
          component="div"
          name={field?.name}
          className="errorMsg-textField"
        />
      </div>
    </div>
  );
};
