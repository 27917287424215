import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosPost } from "../../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL } from "../../../utils/Constants";
import SearchStudentInventory from "./SearchStudentInventory";
import { orderBy } from "lodash";
import ModalWindow from "../../UI/ModalWindow";
import GranularPermissionModal from "../../Permissions/GranularPermissionModal";
import swal from "sweetalert";

class StudentInventory extends Component {
  state = {
    educationLevels: [],
    educationLevel: "",
    yearLevels: [],
    yearLevel: "",
    classrooms: [],
    classroom: "",
    studentInventoryList: [],
    studentList: [],
    admissionId: "",
    loader: false,
    permissionModal: false,
    activeItem: {},
    itemModal: false,
    unassignQuantity: "",
  };

  componentDidMount() {
    this.getEducationLevels();
    this.getStudentInventoryList({});
  }

  filterResult = () => {
    let param = {
      admissionId: this.state.admissionId,
      classroomId: this.state.classroom,
      educationLevelId: this.state.educationLevel,
      yearLevelId: this.state.yearLevel,
    };
    this.getStudentInventoryList(param);
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
          });
      }
    });
  };

  getStudentInventoryList = (param) => {
    this.setState({ loader: true, studentInventoryList: [] }, function () {
      axiosPost(
        URL.getStudentInventory,
        param,
        (response) => {
          if (response.status === 200) {
            this.setState({
              loader: false,
              studentInventoryList: response.data.data,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
        }
      );
    });
  };

  getStudentList = () => {
    let param = {
      classroomId: this.state.classroom,
      yearLevelId: this.state.yearLevel,
    };
    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status == 200) {
        this.setState({ studentList: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    this.setState({ [name]: value }, function () {
      if (name === "educationLevel") {
        this.state.educationLevels.forEach((el) => {
          if (el.id === parseInt(value)) {
            this.setState({
              admissionId: "",
              classroom: "",
              classrooms: [],
              studentList: [],
              yearLevels: el.yearLevels,
              yearLevel: "",
            });
          }
        });
      }
      if (name === "yearLevel") {
        let data = {
          yearLevelId: value,
        };
        axiosPost(URL.selectClassroom, data, (response) => {
          if (response.status === 200) {
            this.setState({
              admissionId: "",
              classrooms: response.data.data,
              classroom: "",
              studentList: [],
            });
          }
        });
      }
      if (name === "classroom") {
        this.setState({
          admissionId: "",
          studentList: [],
        });
        this.getStudentList();
      }
    });
  };

  unassignItemsModal = (item) => {
    this.setState({
      activeItem: item,
      itemModal: true,
    });
  };

  unassignItems = () => {
    if (
      this.state.unassignQuantity < 1 ||
      this.state.unassignQuantity > this.state.activeItem.quantity
    ) {
      swal("Error", "Invalid quantity to unassign");
      return false;
    } else {
      let data = this.state.activeItem;
      let params = {
        id: data.id,
        quantity: -Math.abs(this.state.unassignQuantity),
      };
      axiosPost(URL.updateStudentInventory, params, (response) => {
        if (response.status === 200) {
          this.setState({
            activeItem: {},
            itemModal: false,
            unassignQuantity: "",
          });
          this.getStudentInventoryList({});
          swal("Success", response.data.message);
        }
      });
    }
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="tt-group-header">
          {" "}
          Student Inventory List
          {checkWidgetAdminOrNot("Account") ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="container-fluid">
          <SearchStudentInventory
            educationLevel={this.state.educationLevel}
            educationLevels={this.state.educationLevels}
            yearLevel={this.state.yearLevel}
            yearLevels={this.state.yearLevels}
            handleChange={this.handleChange}
            classroom={this.state.classroom}
            studentList={this.state.studentList}
            classrooms={this.state.classrooms}
            filterResult={this.filterResult}
            admissionId={this.state.admissionId}
          ></SearchStudentInventory>
          <table className="table table-bordered table-striped mt-2">
            <thead className="tt-group-header">
              <tr>
                <th width="70px" className="text-center">
                  S.N
                </th>
                <th>Class Details</th>
                <th>Student Name</th>
                <th>Inventory</th>
                <th width="90px" className="text-center">
                  Quantity
                </th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.studentInventoryList.length > 0 ? (
                this.state.studentInventoryList.map((el, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{idx + 1}</td>
                      <td>
                        <strong>{el.educationLevelName}</strong>
                        <br />
                        {`${localStorage.getItem("yearLevel")} ${
                          el.yearLevelName
                        } - ${localStorage.getItem("classroom")} ${
                          el.classroomName
                        } `}
                      </td>
                      <td>{el.studentName}</td>
                      <td>
                        <strong>{el.feeCategoryName}</strong> - {el.itemName}
                      </td>
                      <td className="text-center">{el.quantity}</td>
                      <td className="text-center">
                        <button
                          className="tt-button tt-button-primary"
                          onClick={() => this.unassignItemsModal(el)}
                        >
                          Unassign
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    {this.state.loader ? (
                      <>
                        <Spinner color="primary"></Spinner>
                        <br />
                        Loading...
                      </>
                    ) : (
                      "None of the Inventory items are not assigned to student"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <ModalWindow
          modal={this.state.itemModal}
          size="sm"
          toggleModal={() => {
            this.setState({
              itemModal: !this.state.itemModal,
            });
          }}
          modalHeader={"Unassign items to student"}
          modalBody={
            <>
              <div className="container">
                <div className="mb-2">
                  {" "}
                  <span>Assigned Quantity:</span>{" "}
                  <b>{this.state.activeItem.quantity}</b>
                </div>

                <label htmlFor="unassignQuantity">Quantity</label>
                <input
                  className="form-control"
                  type="number"
                  name="unassignQuantity"
                  onChange={this.handleChange}
                  value={this.state.unassignQuantity}
                />
                <div className="text-right mt-2">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.unassignItems}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Account"
              moduleName="Inventory"
              header="Student Inventory List"
              activityName="student-inventory"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default StudentInventory;
