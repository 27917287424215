import React, { useState } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomButton from "../../components/common/CustomButton";

const AddActivityModal = (props) => {
  const { moduleId, toggleActivityModal } = props;
  const [addField, setAddField] = useState([
    {
      name: "",
      activityCode: "",
    },
  ]);

  // console.log(moduleId, "module");

  const handleFormChange = (index, event) => {
    let data = [...addField];
    data[index][event.target.name] = event.target.value;
    setAddField(data);
  };

  const handleAddFields = (e, index) => {
    e.preventDefault();
    let newField = [...addField];
    newField.push({
      name: "",
    });
    setAddField(newField);
    // let newfield = { activity: "" };
    // setAddField([...addField, newfield]);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    let data = [...addField];
    data.splice(index, 1);
    if (data.length < 1) {
      swal({ title: "Error", text: "Atleast one activity" });
    } else {
      setAddField(data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (addField.some((a) => a.name === "" || a.activityCode === "")) {
      swal("Warning", "Please fill all the fields.");
      return;
    }
    const param = addField.map((f) => {
      return { ...f, moduleId };
    });
    axiosPost(URL.insertActivity, param, (response) => {
      if (response.status === 200) {
        swal("Success", "Activity added successfully.");
        toggleActivityModal();
        props.getAllModules();
      } else {
        swal("Error", "Something went wrong.");
      }
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        {addField.map((value, index) => {
          return (
            <>
              <div className="row mt-1" key={index}>
                <div className="col-md-5">
                  <input
                    type="text"
                    name="name"
                    placeholder="Activity Name"
                    value={value.activity}
                    onChange={(event) => handleFormChange(index, event)}
                    className="form-control cusInput"
                  />
                </div>
                <div className="col-md-5">
                  <input
                    type="text"
                    name="activityCode"
                    placeholder="Activity Code"
                    value={value.activityCode}
                    onChange={(event) => handleFormChange(index, event)}
                    className="form-control cusInput"
                  />
                </div>

                <div className="col-md-2">
                  {index !== 0 && (
                    <span
                      className="deleteColor ml-2"
                      onClick={(e) => handleRemoveFields(e, index)}
                    >
                      <RemoveIcon />
                    </span>
                  )}
                  <span
                    className="AddColor ml-2"
                    onClick={(e) => handleAddFields(e, index)}
                  >
                    <AddIcon />
                  </span>
                </div>

                {/* <div className="col-md-2">
                  <span
                    className="AddColor ml-2"
                    onClick={(e) => handleAddFields(e, index)}
                  >
                    <AddIcon />
                  </span>
                </div> */}
              </div>
            </>
          );
        })}
        <div className="row">
          <div className="col">
            <CustomButton
              text="Submit"
              customStyle="cusBtnFontSize float-right mt-2"
            />
          </div>
        </div>
        {/* <button className="btn btn-primary mt-2">Submit</button> */}
      </form>
    </>
  );
};
export default AddActivityModal;
