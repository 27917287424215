import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  checkRolePermissions,
  draggable,
  regEx,
  URL,
} from "../../../utils/Constants";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
import CreateCourseTerm from "./CreateCourseTerm";
import ListCourseTerm from "./ListCourseTerm";

class TermSetting extends Component {
  state = {
    name: "",
    description: "",
    termList: [],
    termSpinner: false,
    termOffset: 0,
    termRowCountLimit: 5,
    termCurrentPage: 1,
    termTotalRecords: 0,
    courseSubjectList: [],
    id: "",
    updateName: "",
    updateDescription: "",
    updateSubjectOption: [],
  };

  componentDidMount() {
    this.getTermList();
    this.getCourseSubjectList();
  }

  getCourseSubjectList = () => {
    let data = { courseId: this.props.selectedList.id };
    axiosPost(URL.getCourseSubject, data, (response) => {
      if (response.status === 200) {
        let courseSubjectList = response.data.data;
        let newData = [];
        if (courseSubjectList.length > 0) {
          courseSubjectList.forEach((el) => {
            newData.push({
              label: el.name,
              value: el.id,
            });
          });
        }
        this.setState({ courseSubjectList: newData });
      }
    });
  };

  getTermList = () => {
    let data = {
      courseId: this.props.selectedList.id,
      offset: this.state.termOffset,
      rowCountLimit: this.state.termRowCountLimit,
    };
    this.setState({ termSpinner: true }, function () {
      axiosPost(
        URL.getCourseTerm,
        data,
        (response) => {
          this.setState({ termSpinner: false });
          if (response.status === 200) {
            let termList = response.data.data;
            if (termList.length > 0) {
              termList.forEach((el) => {
                el.isEditable = false;
                el.selectedSubjects = [];
                el.editSubject = false;
              });
            }
            this.setState({
              termList: termList,
              termTotalRecords: response.data.totalRecordsCount,
            });
          }
        },
        (er) => {
          this.setState({ termSpinner: false });
        }
      );
    });
  };

  handleSubjectChange = (selectedItem, idx) => {
    let termList = [...this.state.termList];
    termList[idx].selectedSubjects = selectedItem;
    this.setState({
      termList,
    });
  };

  //Assign subjects
  assignCourseSubjects = (index) => {
    let termList = this.state.termList;
    let selectedTermList = termList[index];
    let data = [];
    if (selectedTermList.selectedSubjects.length > 0) {
      selectedTermList.selectedSubjects.forEach((el) => {
        data.push({
          courseTermId: selectedTermList.id,
          courseSubjectId: el.value,
        });
      });
    }
    axiosPost(URL.assignCourseSubjectToCourseTerm, data, (response) => {
      if (response.status === 200) {
        swal({ title: "Success", text: "Successfully assigned subjects" });
        this.getTermList();
      }
    });
  };

  clearState = () => {
    this.setState({
      name: "",
      description: "",
      updateName: "",
      updateDescription: "",
      id: "",
      updateSubjectOption: [],
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "name" || name === "updateName") {
      if (!value.match(regEx)) {
        swal({
          title: "Warning",
          text: "Character not allowed",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  createCourse = () => {
    if (this.state.name === "") {
      swal({
        title: "Error",
        text: "Fill all the required fields.",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    }
    let data = {
      name: this.state.name,
      description: this.state.description,
      courseId: this.props.selectedList.id,
    };
    axiosPost(URL.insertCourseTerm, data, (response) => {
      if (response.status === 200) {
        swal({ title: "Success", text: "Term successfully created" });
        this.clearState();
        this.setState(
          {
            termOffset: 0,
            termCurrentPage: 1,
          },
          function () {
            this.getTermList();
          }
        );
      }
    });
  };

  //Pagination
  handlePreviousPage = () => {
    if (this.state.termCurrentPage !== 1) {
      this.reRenderTermList(this.state.termCurrentPage - 1);
      this.setState((prevState) => ({
        termCurrentPage: prevState.termCurrentPage - 1,
      }));
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.termCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.termTotalRecords / this.state.termRowCountLimit)
    )
      return;
    else {
      this.reRenderTermList(nextPage);
      this.setState({ termCurrentPage: nextPage });
    }
  };

  handlePageChange = (page) => {
    this.reRenderTermList(page);
    this.setState({ termCurrentPage: page });
  };

  reRenderTermList = (page) => {
    let offset =
      page * parseInt(this.state.termRowCountLimit) -
      parseInt(this.state.termRowCountLimit);
    this.setState({ termOffset: offset, termSpinner: true }, function () {
      let data = {
        courseId: this.props.selectedList.id,
        offset: offset,
        rowCountLimit: this.state.termRowCountLimit,
      };
      axiosPost(
        URL.getCourseTerm,
        data,
        (response) => {
          this.setState({ termSpinner: false });
          if (response.status === 200) {
            let termList = response.data.data;
            if (termList.length > 0) {
              termList.forEach((el) => {
                el.selectedSubjects = [];
                el.isEditable = false;
                el.editSubject = false;
              });
            }
            this.setState({
              termList: termList,
              termTotalRecords: response.data.totalRecordsCount,
            });
          }
        },
        (er) => {
          this.setState({ termSpinner: false });
        }
      );
    });
  };

  //Update Course Term
  toggleCourseTermEditModal = (idx) => {
    let termList = [...this.state.termList];
    termList.forEach((el) => {
      el.isEditable = false;
      el.editSubject = false;
    });
    termList[idx].isEditable = true;
    this.setState({
      termList,
      updateName: termList[idx].name,
      updateDescription: termList[idx].description,
      id: termList[idx].id,
    });
  };

  cancelUpdate = (idx) => {
    let termList = [...this.state.termList];
    termList[idx].isEditable = false;
    this.setState({
      termList,
      id: "",
      updateName: "",
      updateDescription: "",
    });
  };

  updateTerm = () => {
    let data = {
      id: this.state.id,
      name: this.state.updateName,
      description: this.state.updateDescription,
    };
    axiosPost(URL.updateCourseTerm, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Term Successfully updated",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getTermList();
        this.clearState();
      }
    });
  };

  //updateCourseTerm Subject assignment
  handleEditSubject = (idx) => {
    let termList = [...this.state.termList];
    termList.forEach((el) => {
      el.editSubject = false;
      el.isEditable = false;
    });
    termList[idx].editSubject = true;
    let updateSubjectOption = [];
    if (termList[idx].courseSubjects.length > 0) {
      termList[idx].courseSubjects.forEach((el) => {
        updateSubjectOption.push({
          label: el.name,
          value: el.id,
        });
      });
    }
    this.setState({
      termList,
      updateSubjectOption,
      id: termList[idx].id,
    });
  };

  handleEditSubjectValueChange = (item) => {
    this.setState({ updateSubjectOption: item });
  };

  cancelEditSubject = (idx) => {
    let termList = [...this.state.termList];
    termList.forEach((el) => {
      el.editSubject = false;
    });
    this.setState({
      termList,
    });
  };

  updateCourseSubjectToCourseTerm = () => {
    let data = [];
    if (
      this.state.updateSubjectOption &&
      this.state.updateSubjectOption.length > 0
    ) {
      this.state.updateSubjectOption.forEach((el) => {
        data.push({
          courseTermId: this.state.id,
          courseSubjectId: el.value,
        });
      });
    } else {
      data.push({
        courseTermId: this.state.id,
        courseSubjectId: 0,
      });
    }
    axiosPost(URL.updateCourseSubjectToCourseTerm, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Successfully updated subject assignment",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getTermList();
        this.clearState();
      }
    });
  };

  //Delete Term
  handleDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deleteTerm);
  };

  deleteTerm = (datum) => {
    let data = { id: datum.id };
    axiosPost(URL.deleteCourseTerm, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Successfully deleted Course Term",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getTermList();
      }
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "87.5vh" }}>
        <div className="tt-group-header">List of Terms</div>
        <ListCourseTerm
          termList={this.state.termList}
          termSpinner={this.state.termSpinner}
          termTotalRecords={this.state.termTotalRecords}
          termRowCountLimit={this.state.termRowCountLimit}
          termCurrentPage={this.state.termCurrentPage}
          courseSubjectList={this.state.courseSubjectList}
          handlePageChange={this.handlePageChange}
          handleNextPage={this.handleNextPage}
          handlePreviousPage={this.handlePreviousPage}
          handleSubjectChange={this.handleSubjectChange}
          assignCourseSubjects={this.assignCourseSubjects}
          handleChange={this.handleChange}
          updateName={this.state.updateName}
          updateDescription={this.state.updateDescription}
          toggleCourseTermEditModal={this.toggleCourseTermEditModal}
          cancelUpdate={this.cancelUpdate}
          updateTerm={this.updateTerm}
          updateCourseSubjectToCourseTerm={this.updateCourseSubjectToCourseTerm}
          cancelEditSubject={this.cancelEditSubject}
          handleEditSubjectValueChange={this.handleEditSubjectValueChange}
          handleEditSubject={this.handleEditSubject}
          updateSubjectOption={this.state.updateSubjectOption}
          handleDelete={this.handleDelete}
        ></ListCourseTerm>
        {checkRolePermissions("can_create_term", "activity") ? (
          <>
            <div className="tt-group-header">Create New Term</div>
            <CreateCourseTerm
              name={this.state.name}
              description={this.state.description}
              handleChange={this.handleChange}
              createCourse={this.createCourse}
              clearState={this.clearState}
            ></CreateCourseTerm>
          </>
        ) : null}
      </div>
    );
  }
}

export default TermSetting;
