import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, postType, userRole, userRoleCode } from "../../utils/Constants";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import JoditEditor from "jodit-react";
import { draggable, modules, reactServer } from "../../utils/Constants";
import { displayErrorAlert, MESSAGEHEADER } from "../../utils/DisplayMessage";
import swal from "sweetalert";
import CreateMasterNotice from "./CreateMasterNotice";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";
import ListNotice from "./ListNotice";
import moment from "moment";
// import isURL from "validator/lib/isurl";

class Notice extends Component {
  state = {
    modal: false,
    noticeList: [],
  };
  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  componentDidMount() {
    this.getAllNotices();
  }

  getAllNotices = () => {
    axiosPost(URL.getNoticeCore, {}, (response) => {
      if (response.status === 200) {
        const notices = response.data.data;
        let allPromises = [];
        notices.forEach(async (notice, idx) => {
          if (notice.postType === postType.videoConference) {
            let param = {
              postId: notice.id,
              profilePeopleId: localStorage.getItem("peopleId"),
            };
            let selectVideoConferencePostPromise = new Promise(
              (resolve, reject) => {
                axiosPost(URL.videoConferenceSelectByPostId, param, (res) => {
                  if (res.status === 200) {
                    resolve(res.data.data);
                  }
                });
              }
            );
            allPromises.push(selectVideoConferencePostPromise);
            let replaceData = await selectVideoConferencePostPromise;
            let startDateTime = replaceData?.startDateTime
              ? moment(replaceData?.startDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;
            let endDateTime = replaceData?.endDateTime
              ? moment(replaceData?.endDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;

            notice.heading = notice.heading
              ? notice.heading.replace(
                  "[sessionName]",
                  replaceData?.sessionName
                )
              : null;
            notice.textContent = notice.textContent.replace(
              "[peopleName]",
              replaceData?.peopleName
            );
            notice.textContent = notice.textContent.replace(
              "[message]",
              replaceData?.message
            );
            notice.textContent = notice.textContent.replace(
              "[sessionName]",
              replaceData?.sessionName
            );
            notice.textContent = notice.textContent.replace(
              "[startDateTime]",
              startDateTime
            );
            notice.textContent = notice.textContent.replace(
              "[endDateTime]",
              endDateTime
            );
            notice.textContent = notice.textContent.replace(
              "[hostName]",
              replaceData?.hostName
            );
          }
        });
        Promise.all(allPromises).then(() => {
          this.setState({
            noticeList: response.data.data,
          });
        });
      }
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="whiteContainer">
                <CustomPageHeader
                  title="Notices"
                  onClick={this.toggleModal}
                  icon="add_box"
                  noPermission={true}
                />
                <ListNotice noticeList={this.state.noticeList} />
              </div>
            </div>
          </div>
          <CustomModal
            modal={this.state.modal}
            size="xl"
            toggleModal={this.toggleModal}
            modalHeader="Create Notice"
            modalBody={
              <CreateMasterNotice
                toggleModal={this.toggleModal}
                getAllNotices={this.getAllNotices}
              />
            }
          />
        </div>
      </>
    );
  }
}

export default Notice;

// import React, { Component } from "react";
// import Posts from "../../components/Profile/PostSection/Posts";

// export class Notice extends Component {
//   render() {
//     return (
//       <div>
//         <Posts
//           // toggleNoticeModal={this.toggleNoticeModal}
//           noticeType={true}
//           noticeCore={true}
//           // groupPage={this.props.groupPage ? this.props.groupPage : false}
//           // peopleId={this.props.peopleId}
//         ></Posts>
//       </div>
//     );
//   }
// }

// export default Notice;
