import React, { Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { isEqual } from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

class CustomPieChart extends Component {
  state = {
    data: null,
    flag: true,
  };

  componentDidMount() {
    this.config();
  }

  componentDidUpdate(prevProps, prevState) {
    const { chartData } = this.props;
    const { studentCount, parentCount, staffCount } = chartData || {};
    const { flag } = this.state;
    if (
      chartData &&
      studentCount === 0 &&
      parentCount === 0 &&
      staffCount === 0 &&
      flag
    ) {
      this.setState({ data: null, flag: false });
    }
  }

  config = () => {
    if (this.props.chartData) {
      let self = this;
      let chartData = this.props.chartData;
      let studentCount = chartData.studentCount;
      let parentCount = chartData.parentCount;
      let staffCount = chartData.staffCount;
      let datum = [studentCount, parentCount, staffCount];
      let data = {
        labels: ["Students", "Parents", "Staffs"],
        datasets: [
          {
            data: datum,
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return null;
              }
              if (context.dataIndex === 0) {
                return self.getGradient(chart, "primary");
              } else if (context.dataIndex === 1) {
                return self.getGradient(chart, "secondary");
              } else {
                return self.getGradient(chart, "third");
              }
            },
          },
        ],
      };
      this.setState({ data });
    }
  };

  getGradient = (chart, mode) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right },
    } = chart;
    const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
    if (mode === "primary") {
      gradientSegment.addColorStop(0, "#b4d4f1");
      gradientSegment.addColorStop(1, "#4878ae");
    } else if (mode === "secondary") {
      gradientSegment.addColorStop(0, "#b3f3b6");
      gradientSegment.addColorStop(1, "#43a047");
    } else {
      gradientSegment.addColorStop(0, "#f7b1c8");
      gradientSegment.addColorStop(1, "#d81b60");
    }

    return gradientSegment;
  };

  render() {
    return (
      <div className="dashboardPieChartHolder text-center">
        {this.state.data ? (
          <Doughnut data={this.state.data} />
        ) : (
          <p>No chart data available</p>
        )}
      </div>
    );
  }
}

export default CustomPieChart;
