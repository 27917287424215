import React, { Component } from "react";

class Exammodule extends Component {
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        <div className="tt-group-header">Exam module</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col">insert exam</div>
          </div>
        </div>
      </div>
    );
  }
}
export default Exammodule;
