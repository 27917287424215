import React, { Component } from "react";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import CurrentTrainingForm from "./CurrentTrainingForm";
import ListCurrentTraining from "./ListCurrentTraining";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert
} from "../../utils/DisplayMessage";
import swal from "sweetalert";
class CurrentTraining extends Component {
  state = {
    modal: false,
    trainings: [],
    resourceModal: false,
    resourceData: "",
    resourceAddModal: false,
    activeTrainingId: "",
    addedFiles: null,
    editModal: false,
    editTrainingData: "",
    isUpdate: false
  };

  componentDidMount() {
    this.getAllTraining();
  }

  deleteResourceImg = (id, idx) => {
    displayConfirmDeleteAlert({ id: id, idx }, this.deleteResource);
  };

  toggleEditModal = () => {
    this.setState({ editModal: !this.state.editModal });
  };

  handleEditTraining = items => {
    this.toggleEditModal();
    if (items) {
      this.setState({ editTrainingData: items, isUpdate: true });
    }
  };

  deleteResource = datum => {
    let data = {
      imageId: datum.id
    };

    axiosPost(URL.deleteTrainingImg, data, response => {
      if (response.status === 200) {
        this.getAllTraining();
        swal("Success", response.data.message);
        let resourceData = this.state.resourceData;

        resourceData.images.splice(datum.idx, 1);
        this.setState({
          resourceData: resourceData
        });
      }
    });
  };

  getAllTraining = () => {
    axiosPost(URL.getAllTraining, {}, response => {
      if (response.status === 200) {
        this.setState({
          trainings: response.data.data
        });
      }
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  handleViewResources = data => {
    this.setState({
      resourceData: data,
      resourceModal: true
    });
  };

  toggleResourceModal = () => {
    this.setState({
      resourceModal: !this.state.resourceModal
    });
  };

  addResourceModal = data => {
    this.setState({
      resourceAddModal: true,
      activeTrainingId: data.id
    });
  };

  handleAddResource = () => {
    let files = this.state.addedFiles;

    let formData = new FormData();

    if (files !== null) {
      for (const key of Object.keys(files)) {
        formData.append("files", files[key]);
      }
      let trainingId = {
        id: this.state.activeTrainingId
      };
      formData.append("currentTraining", JSON.stringify(trainingId));

      this.setState(
        {
          showSpinner: true
        },
        () => {
          axiosPost(
            URL.insertTraining,
            formData,
            response => {
              if (response.status === 200) {
                this.getAllTraining();
                this.toggleAddResource();
                this.setState({
                  showSpinner: false,
                  addedFiles: null
                });

                swal("Success", response.data.message);
              }
            },
            err => {
              displayErrorAlert(err);
              this.setState({
                showSpinner: false
              });
            }
          );
        }
      );
    } else {
      swal("Error", "First choose files to add");
    }
  };

  toggleAddResource = () => {
    this.setState({
      resourceAddModal: !this.state.resourceAddModal
    });
  };

  handleChange = (e, idx) => {
    let { name, value, files } = e.target;

    this.setState({ [name]: files ? files : value });
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    this.setState({
      [name]: files
    });

    let filesArr = [];
    if (files !== null) {
      for (const key of Object.keys(files)) {
        filesArr.push(files[key]);
      }
      this.setState({
        files: filesArr
      });
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Trainings"
                onClick={this.toggleModal}
                icon="add_box"
                noPermission={true}
              />
              {/* 
title="Schools"
                  onClick={this.toggleSchoolModal}
                  icon="add_box"
                  action="Insert"
                  moduleName={moduleName.schoolManagement}
                  noPermission={true}
                  centralUser={checkCentralRole()} */}

              <ListCurrentTraining
                trainings={this.state.trainings}
                getAllTraining={this.getAllTraining}
                resourceData={this.state.resourceData}
                resourceModal={this.state.resourceModal}
                toggleResourceModal={this.toggleResourceModal}
                handleViewResources={this.handleViewResources}
                deleteResourceImg={this.deleteResourceImg}
                addResourceModal={this.addResourceModal}
                resourceAddModal={this.state.resourceAddModal}
                handleAddResource={this.handleAddResource}
                toggleAddResource={this.toggleAddResource}
                handleChange={this.handleChange}
                handleFileDrop={this.handleFileDrop}
                addedFiles={this.state.addedFiles}
                toggleEditModal={this.toggleEditModal}
                handleEditTraining={this.handleEditTraining}
              />
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          size="lg"
          toggleModal={this.toggleModal}
          modalHeader="Add Training"
          modalBody={
            <CurrentTrainingForm
              getAllTraining={this.getAllTraining}
              toggleModal={this.toggleModal}
            />
          }
        />

        <CustomModal
          modal={this.state.editModal}
          size="lg"
          toggleModal={this.toggleEditModal}
          modalHeader="Edit Training"
          modalBody={
            <CurrentTrainingForm
              getAllTraining={this.getAllTraining}
              toggleModal={this.toggleEditModal}
              editTrainingData={this.state.editTrainingData}
              editModal={this.state.editModal}
              isUpdate={this.state.isUpdate}
            />
          }
        />
      </div>
    );
  }
}

export default CurrentTraining;
