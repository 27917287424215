import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import logo3 from "../../assets/images/logo/logo3.png";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { checkUserRole, URL } from "../../utils/Constants";
import history from "../../utils/History";
import CryptoJS from "crypto-js";
import { displayErrorAlert } from "../../utils/Utils";
import OTPInput from "react-otp-input";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function OTPpage(props) {
  const { state } = useLocation();

  const [timer, setTimer] = useState(180);
  const [otp, setOtp] = useState("");
  const [spinner, setspinner] = useState(false);

  const [errCount, setErrCount] = useState(0);

  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType("navigation");
    if (
      navigationEntries.length > 0 &&
      navigationEntries[0].type === "reload"
    ) {
      gotToLogin();
    }
  }, []);

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, [timer]);

  useEffect(() => {
    localStorage.setItem("otpVerified", localStorage.getItem("otpVerified"));
  }, [localStorage.getItem("otpVerified")]);

  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  const resendOTP = () => {
    setspinner(true);
    axiosGet(
      URL.resendOTP,
      (response) => {
        if (response.data.status === 200) {
          setTimer(180);
          swal("Success", "New OTP has been generated and sent to your email.");
          setspinner(false);
        }
      },
      (err) => {
        displayErrorAlert(err);
        setspinner(false);
      }
    );
  };

  const handleSubmit = () => {
    let data = {
      twoFaCode: otp,
    };

    if (otp === "") {
      swal("Error", "Enter verification code");
    } else {
      setspinner(true);
      axiosPost(
        URL.verifyOTP,
        data,
        (response) => {
          if (response.status === 200) {
            // localStorage.setItem("token", state.access_token);
            // localStorage.setItem("schoolSetup", state.schoolSetup);
            localStorage.setItem("schoolSetup", true);
            localStorage.setItem("otpVerified", true);
            localStorage.setItem(
              "educationCommunityGroupName",
              state.educationCommunityGroupName
            );
            localStorage.setItem("academicYears", true);
            localStorage.setItem("refreshToken", state.refresh_token);

            localStorage.setItem("isFirstLogin", state.isFirstLogin);

            localStorage.setItem(
              "widgets",
              CryptoJS.AES.encrypt(
                JSON.stringify(state.academicPermissionDto.widgets),
                "saurav_imagine_sys"
              )
            );
            state.substituteTeacherRolePermissions =
              state.substituteTeacherRolePermissions || null;
            localStorage.setItem(
              "substituteTeacherRolePermissions",
              CryptoJS.AES.encrypt(
                JSON.stringify(state.substituteTeacherRolePermissions),
                "saurav_imagine_sys"
              )
            );

            localStorage.setItem(
              "academicYearId",
              state.academicPermissionDto.academicYearId
            );
            localStorage.setItem(
              "subRoles",
              CryptoJS.AES.encrypt(
                JSON.stringify(state.subRoles),
                "saurav_imagine_sys"
              )
            );
            if (state.associatedUsers) {
              localStorage.setItem(
                "associatedUsers",
                CryptoJS.AES.encrypt(
                  JSON.stringify(state.associatedUsers),
                  "saurav_imagine_sys"
                )
              );
            }

            localStorage.setItem(
              "role",
              CryptoJS.AES.encrypt(
                state.userRoleCode,
                "saurav_imagine_sys"
              ).toString()
            );
            localStorage.setItem("peopleId", state.peopleId);
            localStorage.setItem("profilePeopleId", state.peopleId);
            localStorage.setItem("familyId", state.familyId);
            localStorage.setItem("mainUserId", state.userId);
            //   localStorage.setItem(
            //     "isCoordinator",
            //     state.isCoordinator
            //   );
            // asdas
            //   localStorage.setItem(
            //     "isHomeRoomTeacher",
            //     state.isHomeRoomTeacher
            //   );
            if (props.rememberMe) {
              localStorage.setItem(
                "username",
                CryptoJS.AES.encrypt(
                  props.username,
                  "saurav_imagine_sys"
                ).toString()
              );
              localStorage.setItem(
                "password",
                CryptoJS.AES.encrypt(
                  props.password,
                  "saurav_imagine_sys"
                ).toString()
              );
            }

            if (
              state.userId == 1 ||
              checkUserRole() ||
              response.data.userRoleCode === "RL_NATION" ||
              response.data.userRoleCode === "RL_LOCAL" ||
              response.data.userRoleCode === "RL_DISTRICT"
            ) {
              history.push(`${process.env.PUBLIC_URL}/dashboard/main`);
            } else {
              if (state.userRoleCode === "RL_STC") {
                history.push(
                  `${process.env.PUBLIC_URL}/familyPage/${state.familyId}`
                );
              } else {
                state.schoolSetup
                  ? state.academicYears
                    ? history.push(`${process.env.PUBLIC_URL}/profile/`)
                    : history.push(`${process.env.PUBLIC_URL}/academicYears`)
                  : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
              }
            }
            //this.getRecruitmentIdPromise(state.peopleId);

            setspinner(false);
          }
        },
        (err) => {
          localStorage.setItem("otpVerified", false);

          displayErrorAlert(err);
          setspinner(false);
          setErrCount(errCount + 1);
          if (errCount > 4) {
            localStorage.clear();
            history.push(`${process.env.PUBLIC_URL}/`);
          }
        }
      );
    }
  };

  const gotToLogin = () => {
    localStorage.clear();
    history.push(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <div>
      {spinner ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please wait... Processing
          </div>
        </div>
      ) : null}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="otp-page">
          <div className="text-center">
            <div className="otp-img text-center">
              <img src={logo3} className="img-fluid" />
            </div>

            <h2>Please enter the OTP to Verify your Account</h2>
            <p>A One-Time Password has been sent to your account</p>
            <div className="otp-code">
              <OTPInput
                value={otp}
                inputStyle="otp-input"
                onChange={setOtp}
                inputStyle={{
                  border: "1px solid black",
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                  marginRight: "2px",
                }}
                numInputs={6}
                renderInput={(props) => (
                  <input disabled={timer === 0 ? true : false} {...props} />
                )}
              />
              {/* <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={e => setchar1(e.target.value)}
                name="char1"
                value={char1}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={e => setchar2(e.target.value)}
                name="char2"
                value={char2}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={e => setchar3(e.target.value)}
                name="char3"
                value={char3}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={e => setchar4(e.target.value)}
                name="char4"
                value={char4}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={e => setchar5(e.target.value)}
                name="char5"
                value={char5}
              />
              <input
                type="text"
                className="otp-input"
                maxLength={1}
                onChange={e => setchar6(e.target.value)}
                name="char6"
                value={char6}
              /> */}
            </div>

            {timer > 0 ? (
              <p>Your OTP will expire in {timer} seconds</p>
            ) : (
              <p>Your OTP has been expired. Please issue new OTP</p>
            )}

            <button
              className="tt-button tt-button-primary p-2"
              onClick={() => handleSubmit()}
              disabled={timer === 0 ? true : false}
            >
              Validate
            </button>
            <div className="text-right mt-2">
              <button
                disabled={timer === 0 ? false : true}
                style={{ background: "transparent", border: "none" }}
                onClick={() => resendOTP()}
              >
                Resend OTP
              </button>
            </div>
            <div className="text-right mt-2">
              <a style={{ cursor: "pointer" }} onClick={() => gotToLogin()}>
                Go back to Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
