import React, { Component } from "react";
import NavBar from "../FrontPage/NavBar";
import "../../assets/scss/newGradeCss.scss";
import { axiosPost } from "../../utils/AxiosApi";
import { questionType, URL } from "../../utils/Constants";
import NewTrainingDetails from "./NewTrainingDetails";
import { displayErrorAlert } from "../../utils/Utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import GovHeader from "../GovHeader/GovHeader";
import FooterText from "../FrontPage/FooterText";
import { connect } from "react-redux";

class NewTeacherPage extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeTraining: null,
    selectedTraining: null,
    trainingList: [],
    trainingName: "",
    activeGallery: false,
    galleryData: [],
    courseList: [],
    syllabusSelected: false,
    topicList: [],
    activeSyllabus: false,
    examActive: false,
    examList: [],
    theoryQuestions: [],
    practicalQuestions: [],
    takeExamClick: false,
    viewResultClick: false,
    isCheckedExam: false,
    examPassMarks: "",
  };

  componentDidMount() {
    this.getAllTrainingData();
  }

  getAllTrainingData = () => {
    axiosPost(
      URL.getAllTraining,
      {},
      (response) => {
        if (response.status === 200 && response.data.data.length > 0) {
          this.setState({
            trainingList: response.data.data,
          });
        }
      },
      (err) => {},
      true
    );
  };

  goBack = () => {
    if (this.state.selectedTraining && this.state.activeSyllabus) {
      this.setState({ activeSyllabus: false });
    } else if (this.state.selectedTraining && this.state.activeGallery) {
      this.setState({ activeGallery: false });
    } else if (
      this.state.selectedTraining &&
      this.state.examActive &&
      !this.state.takeExamClick &&
      !this.state.viewResultClick
    ) {
      this.setState({ examActive: false });
    } else if (
      this.state.selectedTraining &&
      this.state.examActive &&
      this.state.viewResultClick
    ) {
      this.setState({
        viewResultClick: false,
        examActive: true,
      });
    } else if (
      this.state.selectedTraining &&
      this.state.examActive &&
      this.state.takeExamClick
    ) {
      swal("Error", "Cannot skip the exam!!");
    } else {
      this.setState({
        selectedTraining: null,
        galleryData: [],
        activeGallery: false,
        activeSyllabus: false,
        takeExamClick: false,
        examActive: false,
        viewResultClick: false,
      });
    }
  };
  selectTraining = (data) => {
    if (
      this.state.selectedTraining &&
      this.state.examActive &&
      this.state.takeExamClick
    ) {
      swal("Error", "Cannot skip the exam!!");
    } else {
      this.setState(
        {
          selectedTraining: data,
          activeTraining: data.name,
          trainingName: data.id,
          galleryData: data.images,
          activeSyllabus: false,
          activeGallery: false,
          examActive: false,
        },
        function () {
          // this.getTeacherTrainingSyllabus();
        }
      );
    }
  };

  galleryClick = () => {
    this.setState({
      activeGallery: true,
    });
  };

  handleSyllabusClick = () => {
    this.getTeacherTrainingSyllabus();
  };

  getTeacherTrainingSyllabus = () => {
    this.setState(
      {
        courseSyllabus: [],
        topicList: [],
        courseSpinner: true,
      },
      () => {
        axiosPost(
          URL.getAllTeacherTrainingSyllabus,
          {},
          (response) => {
            if (response.status === 200) {
              const cs = response.data.data;

              const tl = cs
                ? cs.filter(
                    (cs) =>
                      cs.teacherTrainingId === this.state.selectedTraining.id
                  )
                : null;

              this.setState({
                courseSyllabus: response.data.data,
                courseSpinner: false,

                topicList: tl,
                activeSyllabus: true,
              });
            }
          },
          (err) => {
            this.setState({ courseSpinner: false });
            displayErrorAlert(err);
          }
        );
      }
    );
  };

  getExamData = () => {
    let data = {
      currentTrainingId: this.state.selectedTraining.id,
    };
    axiosPost(
      URL.getExamQuestions,
      data,
      (response) => {
        const examList = response.data.data;
        // const theoryQuestions =
        //   questions && questions.filter((q) => q.isTheory);
        // const practicalQuestions =
        //   questions && questions.filter((q) => !q.isTheory);
        this.setState({ examList, examActive: true });
      },
      (err) => {}
    );
  };

  toggleExamQuestions = (data) => {
    this.setState({
      takeExamClick: true,
      theoryQuestions: data.examQuestions,
    });
  };

  toggleViewResult = (item) => {
    let data = {
      id: item.id,
      peopleId: localStorage.getItem("profilePeopleId"),
    };

    this.setState(
      {
        theoryQuestions: [],
        isCheckedExam: false,
        examPassMarks: item.passMarks,
      },
      () => {
        axiosPost(
          URL.getExamAnswer,
          data,
          (response) => {
            if (response.status === 200) {
              const questions = response.data.data;

              if (questions.some((tq) => tq.answer)) {
                questions.forEach((q) => {
                  if (q.questionType === questionType.OBJECTIVE && q.answer) {
                    const trueOption =
                      q.examQuestionOptions &&
                      q.examQuestionOptions.find((opt) => opt.isCorrect);
                    if (
                      trueOption &&
                      q.answer.examQuestionOptionId === trueOption.id
                    ) {
                      q.obtainedMarks = q.markWeight;
                    } else {
                      q.obtainedMarks = "0";
                    }
                  } else {
                    q.obtainedMarks = "";
                  }
                  q.remarks = "";
                });

                const theoryQuestions = questions;
                if (questions.length > 0) {
                  this.setState({
                    answerData: true,
                  });
                }
                const isCheckedExam = theoryQuestions.some(
                  (tq) => tq.answer?.isChecked
                );
                // const practicalQuestions =
                //   questions && questions.filter((q) => !q.isTheory);
                this.setState({
                  theoryQuestions,
                  isCheckedExam,
                  viewResultClick: true,
                });
              } else {
                this.setState({ theoryQuestions: [], isCheckedExam: false });
              }
            }
          },
          (err) => {
            displayErrorAlert(err);
            // displayErrorAlert(err);
          }
        );
      }
    );
  };

  handleAnswerChange = (e, stateName, qType, index, optionIndex) => {
    const { name, value, type, checked } = e.target;
    const ans = type === "checkbox" ? checked : value;
    let questions = [...this.state[stateName]];
    if (qType === questionType.SUBJECTIVE) {
      questions[index][name] = ans;
    } else if (qType === questionType.OBJECTIVE) {
      let options = questions[index].examQuestionOptions;
      options = options.map((o, oid) => {
        o["ticked"] = optionIndex === oid ? true : false;
        return o;
      });
      questions[index].examQuestionOptions = options;
    } else {
      questions[index][name] = "";
    }
    this.setState({ [stateName]: questions });
  };

  handleSubmit = () => {
    const answers = [
      ...this.state.theoryQuestions,
      ...this.state.practicalQuestions,
    ];

    //empty subjective answers check
    const sExist = answers.some(
      (a) => a.questionType === questionType.SUBJECTIVE
    );
    const oExist = answers.some(
      (a) => a.questionType === questionType.OBJECTIVE
    );

    const ansS = answers
      .filter((a) => a.questionType === questionType.SUBJECTIVE)
      .every((a) => a.subjectiveAnswer != null && a.subjectiveAnswer !== "");

    //at least one checked subjective answer
    const ansO = answers
      .filter((a) => a.questionType === questionType.OBJECTIVE)
      .every(
        (a) =>
          a.examQuestionOptions != null &&
          a.examQuestionOptions.some((opt) => opt.ticked)
      );
    let e = null;
    if (sExist && oExist) {
      e = !ansS && ansO;
    } else if (sExist && !oExist) {
      e = !ansS;
    } else if (!sExist && oExist) {
      e = !ansO;
    }

    if (e) {
      swal("Warning", "Some answers are missing. Please answer them first.");
    } else {
      swal({
        title: "Success",
        text: "Are you sure to submit your answers?. Please check and verify your answers first before submitting.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      }).then(() => {
        const data = answers.map((a) => {
          if (a.questionType === questionType.SUBJECTIVE) {
            return {
              examQuestionId: a.id,
              subjectiveAnswer: a.subjectiveAnswer,
              examQuestionOptionId: null,
              isSubmitted: true,
            };
          } else if (a.questionType === questionType.OBJECTIVE) {
            const tickedAnswer = a.examQuestionOptions.find(
              (opt) => opt.ticked
            );
            return {
              examQuestionId: a.id,
              subjectiveAnswer: null,
              examQuestionOptionId: tickedAnswer ? tickedAnswer.id : null,
              isSubmitted: true,
            };
          } else {
            return {
              examQuestionId: a.id,
              subjectiveAnswer: null,
              examQuestionOptionId: null,
              isSubmitted: true,
            };
          }
        });

        axiosPost(
          URL.insertExamAnswer,
          data,
          (response) => {
            if (response.status === 200) {
              swal(
                "Success",
                "Congratulations! You have attended system exam successfully."
              );
              this.setState({
                yearLevelId: "",
                classRoomId: "",
                assessmentId: "",
                subjectId: "",
                theoryQuestions: [],
                practicalQuestions: [],
                takeExamClick: false,
              });
            } else {
              swal("Error", "Error submitting exam. Please try again.");
            }
          },
          (err) => {
            swal("Error", "Error submitting exam. Please try again.");
          }
        );
      });
    }
  };

  getLoggedInStatus = () => {
    let token = localStorage.getItem("token");
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  calculateTotal = () => {
    let total = 0;

    this.state.theoryQuestions.map(
      (t) => (total = t.answer.obtainedMarks + total)
    );
    return total;
  };

  handleMarksChange = () => {};

  render() {
    return (
      <div>
        <NavBar
          teacherPage={
            this.props.location?.state?.homePage || this.props.homePage
              ? false
              : true
          }
          classPage={false}
          // homePage={this.props.location?.state?.homePage || this.props.homePage}
          pathName={this.props.location?.pathname}
          homePage={this.props.homePage}
          noHeader={localStorage.getItem("token") ? true : false}
        />
        {localStorage.getItem("token") ? (
          <GovHeader teacherPage={true} />
        ) : null}
        <section>
          <div className="main-image1">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="main-heading">
                    <div className="d-flex justify-content-center">
                      <h2 className="heading-06" style={{ fontWeight: "bold" }}>
                        {this.props.lang === "nameEn"
                          ? "TEACHER MATERIALS"
                          : "शिक्षक सामाग्री"}
                      </h2>
                    </div>
                    {this.getLoggedInStatus() && (
                      <div className="searchWidget float-right">
                        <Link
                          to={{
                            pathname: "/search-imagine",
                            state: {
                              noNav: true,
                              teacherPage: true,
                              classPage: false,
                              homePage:
                                this.props.location?.state?.homePage ||
                                this.props.homePage,
                            },
                          }}
                          className="newWidgetLink"
                        >
                          <div className="tt-widget-holder-new">
                            <div className="tt-widget-icon">
                              <div>
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  className="tt-widget-fontAwesome-icon"
                                  style={{ fontSize: "40px" }}
                                />
                                <div className="tt-widget-title">Search</div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                    {/* <div
                      className="horizontal-line"
                      style={{ width: "150px" }}
                    ></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-4">
          {this.state.selectedTraining ? (
            <NewTrainingDetails
              courseList={this.state.courseList}
              activeGallery={this.state.activeGallery}
              galleryData={this.state.galleryData}
              galleryClick={this.galleryClick}
              goBack={this.goBack}
              selectedTraining={this.state.selectedTraining}
              selectTraining={this.selectTraining}
              trainingList={this.state.trainingList}
              handleSyllabusClick={this.handleSyllabusClick}
              getTeacherTrainingSyllabus={this.getTeacherTrainingSyllabus}
              activeSyllabus={this.state.activeSyllabus}
              topicList={this.state.topicList}
              getExamData={this.getExamData}
              examActive={this.state.examActive}
              examList={this.state.examList}
              toggleExamQuestions={this.toggleExamQuestions}
              takeExamClick={this.state.takeExamClick}
              theoryQuestions={this.state.theoryQuestions}
              handleSubmit={this.handleSubmit}
              handleAnswerChange={this.handleAnswerChange}
              getLoggedInStatus={this.getLoggedInStatus}
              toggleViewResult={this.toggleViewResult}
              viewResultClick={this.state.viewResultClick}
              calculateTotal={this.calculateTotal}
              isCheckedExam={this.state.isCheckedExam}
              handleMarksChange={this.handleMarksChange}
              examPassMarks={this.state.examPassMarks}
            />
          ) : (
            <div className="subjectClassList">
              {this.state.trainingList.length > 0 ? (
                this.state.trainingList.map((trn, idx) => {
                  return (
                    <div
                      key={idx}
                      className={"subjectBoxDiv"}
                      onClick={() => this.selectTraining(trn)}
                    >
                      <span>{trn.name}</span>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">No Trainings added</div>
              )}
            </div>
          )}
        </section>
        <FooterText />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(NewTeacherPage);
