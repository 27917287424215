import { faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Spinner from "reactstrap/lib/Spinner";
import swal from "sweetalert";
import { axiosPost } from "../../../../../utils/AxiosApi";
import { draggable, URL } from "../../../../../utils/Constants";
import { displayErrorAlert } from "../../../../../utils/Utils";
import isURL from "validator/lib/isURL";

class CourseClassResource extends Component {
  state = {
    link: "",
    contents: [],
    selectedLinks: [], //links
    showSpinner: false,
    error: false,
    updateFiles: [],
  };

  componentDidMount() {
    if (this.props.assignment) {
      this.assignmentDataConfig();
    }
  }

  assignmentDataConfig = () => {
    this.setState({
      contents: this.props.assignmentContents,
      selectedLinks: this.props.assignmentLinks,
      updateFiles: this.props.updateAssignment ? this.props.updateFiles : [],
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value, error: false });
  };

  addLinks = () => {
    let selectedLinks = [...this.state.selectedLinks];
    if (isURL(this.state.link)) {
      selectedLinks.push(this.state.link);
      this.setState({ selectedLinks, link: "" });
    } else {
      this.setState({ error: true });
      swal({
        title: "Error",
        text: "Invalid Link",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter" && this.state.link !== "") {
      this.addLinks();
    }
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let data = e.target.files;
    let contents = [...this.state.contents];
    Object.values(data).forEach((datum) => {
      var extension = datum.name.split(".").pop();
      if (datum.type.includes("image")) {
        if (
          extension === "jpeg" ||
          extension === "png" ||
          extension === "jpg"
        ) {
          contents.push(datum);
        }
      } else {
        if (extension !== "pptx") contents.push(datum);
      }
    });
    this.setState({ contents });
  };

  deleteFileContent = (e, idx) => {
    let contents = [...this.state.contents];
    contents.splice(idx, 1);
    this.setState({ contents });
  };

  deleteAssignmentResources = (e, idx) => {
    let updateFiles = [...this.state.updateFiles];
    updateFiles.splice(idx, 1);
    this.setState({ updateFiles });
  };

  deleteLink = (idx) => {
    let selectedLinks = [...this.state.selectedLinks];
    selectedLinks.splice(idx, 1);
    this.setState({ selectedLinks });
  };

  handleUpdate = () => {
    if (this.props.assignment) {
      this.props.updateResources(
        this.state.contents,
        this.state.selectedLinks,
        this.state.updateFiles
      );
    }
  };
  handleSubmit = () => {
    if (
      this.state.selectedLinks.length === 0 &&
      this.state.contents.length === 0 &&
      (!this.props.assignment ||
        (this.props.assignment && this.props.isSubmission))
    ) {
      swal({
        title: "Error",
        text: "There are no contents to be added",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    }
    if (this.props.assignment) {
      this.props.saveResources(this.state.contents, this.state.selectedLinks);
    } else if (this.props.submission) {
      this.props.submitAssignment(
        this.state.contents,
        this.state.selectedLinks
      );
    } else {
      let formData = new FormData();
      for (const key of Object.keys(this.state.contents)) {
        formData.append("contents", this.state.contents[key]);
      }
      formData.append(
        "jsonData",
        JSON.stringify({
          courseClassId: this.props.courceClassId,
          links: this.state.selectedLinks,
        })
      );
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertCourseClassResources,
          formData,
          (response) => {
            if (response.status === 200) {
              swal({
                title: "Success",
                text: "Resources successfully added",
                closeOnClickOutside: false,
                allowOutsideClick: false,
              });
              draggable();
              this.props.toggleAddResourceModal();
              this.props.getCourseClasses();
              this.setState({ showSpinner: false });
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            displayErrorAlert(error);
          }
        );
      });
    }
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="tt-widgetContent-tab-holder">
          <div className="row">
            <div className="col">
              <input
                type="text"
                className={
                  this.state.error
                    ? " form-control form-control-warn"
                    : "form-control"
                }
                name="link"
                value={this.state.link}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
              ></input>
            </div>
            <div
              className="col-md-auto text-right mt-1"
              style={{ borderRight: "1px solid #ccc" }}
            >
              <button
                className="tt-button tt-button-primary"
                onClick={this.addLinks}
                disabled={this.state.link === ""}
              >
                Add Links
              </button>
            </div>
            <div className="col-md-auto text-right mt-1">
              <input
                id="myInput"
                type="file"
                ref={(ref) => (this.upload = ref)}
                onChange={this.handleImageChange}
                style={{ display: "none" }}
                multiple
              />
              <button
                className="tt-button tt-button-primary"
                onClick={() => {
                  this.upload.click();
                }}
              >
                Choose Files
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="tt-courseFileSelectHolder">
                <strong>Selected Files:</strong>
                <div className="tt-courseFilesBox">
                  <div className="tt-courseFiles">
                    {this.props.updateAssignment ? (
                      <>
                        {this.state.updateFiles.length > 0
                          ? this.state.updateFiles.map((el, idx) => {
                              return (
                                <div key={idx}>
                                  {el.type === "IMG" ? (
                                    <div
                                      className="tt-courseFileHolder"
                                      style={{ padding: 0 }}
                                    >
                                      <img
                                        src={el.src}
                                        alt="assignemtResourceImage"
                                      ></img>
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="tt-courseFileDelete"
                                        onClick={() => {
                                          this.deleteAssignmentResources(
                                            el.id,
                                            idx
                                          );
                                        }}
                                      ></FontAwesomeIcon>
                                    </div>
                                  ) : (
                                    <div className="tt-courseFileHolder">
                                      <FontAwesomeIcon
                                        icon={faFile}
                                        className="tt-courseFileIcon"
                                      ></FontAwesomeIcon>
                                      <div>{el.fileName}</div>
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="tt-courseFileDelete"
                                        onClick={() => {
                                          this.deleteAssignmentResources(
                                            el.id,
                                            idx
                                          );
                                        }}
                                      ></FontAwesomeIcon>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          : ""}
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.contents.length > 0 ? (
                      this.state.contents.map((file, idx) => {
                        return (
                          <div key={idx}>
                            {file.type.includes("image") ? (
                              <div
                                className="tt-courseFileHolder"
                                style={{ padding: 0 }}
                              >
                                <img
                                  src={window.URL.createObjectURL(file)}
                                  alt="courseFile"
                                ></img>
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className="tt-courseFileDelete"
                                  onClick={(e) => {
                                    this.deleteFileContent(e, idx);
                                  }}
                                ></FontAwesomeIcon>
                              </div>
                            ) : (
                              <div className="tt-courseFileHolder">
                                <FontAwesomeIcon
                                  icon={faFile}
                                  className="tt-courseFileIcon"
                                ></FontAwesomeIcon>
                                <div>{file.name}</div>
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className="tt-courseFileDelete"
                                  onClick={(e) => {
                                    this.deleteFileContent(e, idx);
                                  }}
                                ></FontAwesomeIcon>
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">
                        {this.props.updateAssignment ? (
                          <>
                            {this.state.updateFiles.length > 0
                              ? ""
                              : "No Files uploaded"}
                          </>
                        ) : (
                          "No Files uploaded"
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <strong>Selected Links:</strong>
                  <div className="tt-courseLinks">
                    <div className="row">
                      <div className="col">
                        <table>
                          <tbody>
                            {this.state.selectedLinks.length > 0 ? (
                              this.state.selectedLinks.map((lnk, idx) => {
                                return (
                                  <tr
                                    key={idx}
                                    style={{ verticalAlign: "top" }}
                                  >
                                    <td width="100%">{lnk}</td>
                                    <td>
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        onClick={() => this.deleteLink(idx)}
                                        style={{ cursor: "pointer" }}
                                      ></FontAwesomeIcon>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={2} className="text-center">
                                  No links added
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row button-bg-color mt-2">
            <div className="col text-right">
              {this.props.updateAssignment ? (
                <>
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={this.handleUpdate}
                  >
                    Update
                  </button>
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={this.props.toggleUpdateResourceModal}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={this.props.toggleAddResourceModal}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
          {/* {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Uploading Resources ...
            </div>
          </div>
        ) : null} */}
        </div>
      </>
    );
  }
}

export default CourseClassResource;
