import React, { Component } from "react";
import { Collapse } from "reactstrap";
import ToggleButton from "react-toggle-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
  faEdit,
  faTimesCircle,
  faTrashAlt,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/scss/admission.scss";
import { addressCode, peopleCode } from "../../utils/Constants";
import AddressCluster from "./AddressCluster";
import IdentityCluster from "./IdentityCluster";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import TableForm from "./Admission/TableForm";

export default class AdmissionGroupConfig extends Component {
  render() {
    let groupConfigs = this.props.groupConfigs,
      groupConfig = this.props.groupConfig,
      index = this.props.index,
      lastIndex = this.props.lastIndex;
    return (
      <div className="card mb-3" key={this.props.key}>
        <div
          className="card-header group-header"
          style={{ marginBottom: "0.5rem" }}
        >
          <div className="row">
            <div
              className="col tt-cursor-pointer"
              onDoubleClick={
                this.props.studentContactGroup === false
                  ? () => this.props.handleGroupConfigNameDoubleClick(index)
                  : () =>
                      this.props.handleGroupConfigNameDoubleClick(
                        index,
                        "studentGroup"
                      )
              }
            >
              {groupConfig.editMode ? (
                <input
                  id={
                    this.props.studentContactGroup === false
                      ? `admission-groupConfig__update-group-textfield-${index}`
                      : `admission-studentContactGroups__update-group-textfield-${index}`
                  }
                  type="text"
                  className="form-control group-name"
                  value={groupConfig.name}
                  onChange={
                    this.props.studentContactGroup === false
                      ? (e) => this.props.handleGroupConfigNameChange(e, index)
                      : (e) =>
                          this.props.handleGroupConfigNameChange(
                            e,
                            index,
                            "studentGroup"
                          )
                  }
                  onBlur={
                    this.props.studentContactGroup === false
                      ? () => this.props.handleGroupConfigNameBlur(index)
                      : () =>
                          this.props.handleGroupConfigNameBlur(
                            index,
                            "studentGroup"
                          )
                  }
                />
              ) : (
                <strong>{groupConfig.name}</strong>
              )}
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="group-header-checkbox"
                checked={groupConfig.headerHidden}
                onClick={
                  this.props.studentContactGroup === false
                    ? (e) =>
                        this.props.handleHeaderHiddenCheckboxClick(e, index)
                    : (e) =>
                        this.props.handleHeaderHiddenCheckboxClick(
                          e,
                          index,
                          "studentGroup"
                        )
                }
              />
              <span className="group-header-text">Don't show group header</span>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="group-header-checkbox"
                checked={groupConfig.isProfileInfo}
                onClick={
                  this.props.studentContactGroup === false
                    ? (e) =>
                        this.props.handleisProfileInfoCheckboxClick(e, index)
                    : (e) =>
                        this.props.handleisProfileInfoCheckboxClick(
                          e,
                          index,
                          "studentGroup"
                        )
                }
              />
              <span className="group-header-text">
                Add in Profile Information
              </span>
            </div>
            <div className="col-md-3">
              <div className="row">
                <div className="col">
                  <FontAwesomeIcon
                    icon={faEdit}
                    size="lg"
                    className="tt-cursor-pointer"
                    onClick={
                      this.props.studentContactGroup === false
                        ? () =>
                            this.props.handleGroupConfigNameDoubleClick(index)
                        : () => {
                            this.props.handleGroupConfigNameDoubleClick(
                              index,
                              "studentGroup"
                            );
                          }
                    }
                  />
                </div>
                <div className="col">
                  {this.props.studentContactGroup === false ? (
                    <>
                      {groupConfig.unalterable === true ? null : (
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          style={{ fontSize: "1.4em" }}
                          className="float-left tt-cursor-pointer"
                          onClick={() =>
                            displayConfirmDeleteAlert(
                              index,
                              this.props.handleGroupConfigDeleteBtnClick
                            )
                          }
                        />
                      )}
                    </>
                  ) : null}
                </div>
                <div className="col">
                  {this.props.studentContactGroup ? null : (
                    <span>
                      {index !== 0 ? (
                        <FontAwesomeIcon
                          icon={faCaretUp}
                          size="2x"
                          className="float-right tt-cursor-pointer m-1"
                          onClick={() =>
                            this.props.handleGroupConfigReorderBtnClick(
                              index,
                              --index
                            )
                          }
                        />
                      ) : null}
                      {index !== lastIndex ? (
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          size="2x"
                          className="float-right tt-cursor-pointer m-1"
                          onClick={() =>
                            this.props.handleGroupConfigReorderBtnClick(
                              index,
                              ++index
                            )
                          }
                        />
                      ) : null}
                    </span>
                  )}
                </div>
                <div className="col">
                  <div
                    className="col-1"
                    onClick={
                      this.props.studentContactGroup === false
                        ? (e) => this.props.handleGroupConfigToggle(e, index)
                        : (e) =>
                            this.props.handleGroupConfigToggle(
                              e,
                              index,
                              "studentGroup"
                            )
                    }
                  >
                    <span
                      className="float-right "
                      style={{ cursor: "pointer", fontSize: "19px" }}
                    >
                      {!groupConfig.isOpen ? (
                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                      ) : (
                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* {this.props.studentContactGroup === false ? (
              <>
                {groupConfig.unalterable === 1 ? null : (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ fontSize: "1.4em" }}
                    className="float-right tt-cursor-pointer"
                    onClick={() =>
                      displayConfirmDeleteAlert(
                        index,
                        this.props.handleGroupConfigDeleteBtnClick
                      )
                    }
                  />
                )}
              </>
            ) : null} */}
          </div>
        </div>

        {/* GroupConfig body */}
        <Collapse isOpen={groupConfig.isOpen}>
          <div className="card-body">
            <table className="table table-bordered table-striped">
              <thead className="tt-group-header">
                <tr>
                  {this.props.studentContactGroup === false ? (
                    <th width="2px">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.props.handleSwitchAllGroupCheckboxClick(e, index)
                        }
                      />
                    </th>
                  ) : (
                    <th width="5%"></th>
                  )}
                  <th>Fields</th>
                  <th>Inputs</th>
                  <th colSpan={6} width="10%">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody>
                {groupConfig.customTableFields
                  ? groupConfig.customTableFields.map((field, fieldIndex) => (
                      <tr key={fieldIndex}>
                        {this.props.studentContactGroup === false ? (
                          <td>
                            <input
                              type="checkbox"
                              checked={field.selectedForGroupSwitch}
                              onClick={(e) =>
                                this.props.handleSwitchGroupCheckboxClick(
                                  e,
                                  index,
                                  fieldIndex
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td />
                        )}

                        <td>{field.fieldName}</td>
                        <td>
                          {field.fieldTypeCode === addressCode ? (
                            <AddressCluster config={true} />
                          ) : field.fieldTypeCode === peopleCode ? (
                            <IdentityCluster config={true} />
                          ) : (
                            <>
                              {field.isMulti &&
                              field.fieldTypeCode === "select" ? (
                                <select className="form-control">
                                  <option disabled selected>
                                    choose
                                  </option>
                                  {field.customTableFieldDatas?.map((data) => (
                                    <option value={data.data}>
                                      {data.data}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <>
                                  {field.isMulti &&
                                  field.fieldTypeCode !== "select" &&
                                  field.fieldTypeCode !== "table" ? (
                                    field.customTableFieldDatas?.map((data) => (
                                      <>
                                        <label>{data.data}</label>
                                        <input
                                          type={
                                            field.fieldTypeCode ===
                                            "multi-checkbox"
                                              ? "checkbox"
                                              : field.fieldTypeCode
                                          }
                                          value={data.data}
                                        />
                                      </>
                                    ))
                                  ) : (
                                    <>
                                      {field.fieldTypeCode === "textarea" ? (
                                        <textarea
                                          type={field.fieldTypeCode}
                                          className="form-control"
                                        />
                                      ) : (
                                        <>
                                          {field.fieldTypeCode === "table" ? (
                                            <TableForm
                                              field={field}
                                              conf={true}
                                            />
                                          ) : (
                                            <input
                                              type={field.fieldTypeCode}
                                              className={
                                                field.fieldTypeName === "file"
                                                  ? ""
                                                  : "form-control"
                                              }
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </td>

                        {field.fieldTypeCode === "pre-peop" ||
                        field.fieldTypeCode === "pre-addr" ? null : (
                          <td>
                            <ToggleButton
                              inactiveLabel={""}
                              activeLabel={""}
                              value={field.isMandatory}
                              onToggle={
                                this.props.studentContactGroup === false
                                  ? () =>
                                      this.props.handleMandatoryToggleBtnToggle(
                                        index,
                                        fieldIndex
                                      )
                                  : () =>
                                      this.props.handleMandatoryToggleBtnToggle(
                                        index,
                                        fieldIndex,
                                        "studentGroup"
                                      )
                              }
                            />
                          </td>
                        )}

                        {field.fieldTypeCode === "pre-peop" ||
                        field.fieldTypeCode === "pre-addr" ? null : (
                          <td>
                            {field.isMandatory ? "Mandatory" : "Optional"}
                          </td>
                        )}

                        {field.fieldTypeCode === "pre-peop" ||
                        field.fieldTypeCode === "pre-addr" ? null : (
                          <td>
                            <FontAwesomeIcon
                              icon={faEdit}
                              size="lg"
                              className="tt-cursor-pointer"
                              onClick={
                                this.props.studentContactGroup === false
                                  ? () =>
                                      this.props.handleEditGroupConfigFieldBtnClick(
                                        index,
                                        fieldIndex
                                      )
                                  : () =>
                                      this.props.handleEditGroupConfigFieldBtnClick(
                                        index,
                                        fieldIndex,
                                        "studentGroup"
                                      )
                              }
                            />
                          </td>
                        )}

                        {field.fieldTypeCode === "pre-peop" ||
                        field.fieldTypeCode === "pre-addr" ? null : (
                          <td>
                            {field.unalterable === true ? null : (
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                size="lg"
                                className="tt-cursor-pointer"
                                onClick={
                                  this.props.studentContactGroup === false
                                    ? () =>
                                        displayConfirmDeleteAlert(
                                          {
                                            groupConfigIndex: index,
                                            fieldIndex,
                                          },
                                          this.props
                                            .handleRemoveFieldFromGroupConfigBtnClick
                                        )
                                    : () =>
                                        displayConfirmDeleteAlert(
                                          {
                                            groupConfigIndex: index,
                                            fieldIndex,
                                            studentGroup: "studentGroup",
                                          },
                                          this.props
                                            .handleRemoveFieldFromGroupConfigBtnClick
                                        )
                                }
                              />
                            )}
                          </td>
                        )}

                        <td>
                          {fieldIndex !== 0 ? (
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              size="2x"
                              className="float-right tt-cursor-pointer"
                              onClick={
                                this.props.studentContactGroup === false
                                  ? () =>
                                      this.props.handleGroupConfigFieldsReorderBtnClick(
                                        index,
                                        fieldIndex,
                                        --fieldIndex
                                      )
                                  : () =>
                                      this.props.handleGroupConfigFieldsReorderBtnClick(
                                        index,
                                        fieldIndex,
                                        --fieldIndex,
                                        "studentGroup"
                                      )
                              }
                            />
                          ) : null}
                        </td>
                        <td>
                          {fieldIndex !==
                          groupConfig.customTableFields.length - 1 ? (
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              size="2x"
                              className="float-right tt-cursor-pointer"
                              onClick={
                                this.props.studentContactGroup === false
                                  ? () =>
                                      this.props.handleGroupConfigFieldsReorderBtnClick(
                                        index,
                                        fieldIndex,
                                        ++fieldIndex
                                      )
                                  : () =>
                                      this.props.handleGroupConfigFieldsReorderBtnClick(
                                        index,
                                        fieldIndex,
                                        ++fieldIndex,
                                        "studentGroup"
                                      )
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            <table className="table" style={{ marginTop: "-15px" }}>
              <thead className="tt-group-header">
                <tr>
                  <th>
                    {this.props.studentContactGroup === false ? (
                      <>
                        <select
                          name="group"
                          className="form-control"
                          value={groupConfig.selectedGroupForFieldMoveIndex}
                          onChange={(e) =>
                            this.props.handleGroupSelectChange(e, index)
                          }
                        >
                          <option value="" disabled selected>
                            Groups
                          </option>
                          {groupConfigs
                            ? groupConfigs.map((groupConfig, idx) => {
                                if (idx !== index)
                                  return (
                                    <option value={idx} key={idx}>
                                      {groupConfig.name}
                                    </option>
                                  );
                                else return null;
                              })
                            : null}
                        </select>
                      </>
                    ) : null}
                  </th>
                  <th>
                    {this.props.studentContactGroup === false ? (
                      <>
                        <button
                          className="tt-button mt-0 float-right tt-button-primary"
                          onClick={(e) =>
                            this.props.handleChangeGroupBtnClick(e, index)
                          }
                        >
                          Change group
                        </button>
                      </>
                    ) : null}
                  </th>
                  <th></th>
                  <th>
                    <button
                      className="tt-button mt-0 float-right tt-button-primary"
                      onClick={
                        this.props.studentContactGroup === false
                          ? (e) =>
                              this.props.handleAddCustomFieldBtnClick(e, index)
                          : (e) =>
                              this.props.handleAddCustomFieldBtnClick(
                                e,
                                index,
                                "studentGroup"
                              )
                      }
                    >
                      Add custom field
                    </button>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </Collapse>
      </div>
    );
  }
}
