import React, { Component } from "react";
import moment from "moment";

class SchoolEnableDisable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="whiteContainer">
              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>Updated By</th>
                    <th>Enable /Disable Status</th>
                    <th>Updated Date</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.schoolHistoryData &&
                  this.props.schoolHistoryData.length > 0 ? (
                    this.props.schoolHistoryData.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{item.peopleName}</td>
                          <td>{item.status ? "Enabled" : "Disabled"}</td>
                          <td>{moment(item.createdAt).format("LL")}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No History
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolEnableDisable;
