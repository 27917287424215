import React, { Component } from "react";
import { Collapse, Card, CardBody, Spinner } from "reactstrap";
import { checkWidgetAdminOrNot } from "../../../../utils/Constants";

class ReportGeneratorUI extends Component {
  state = {};
  render() {
    return (
      <Collapse isOpen={this.props.isClassroomOpen}>
        <Card>
          <CardBody>
            <>
              {this.props.showSpinner ? (
                <div className="fullWindow-Spinner">
                  <div>
                    <Spinner color="white"></Spinner>
                  </div>
                  <div style={{ fontSize: "16px", marginTop: "15px" }}>
                    Please Wait. Processing...
                  </div>
                </div>
              ) : null}
              <div className="tt-group-header text-center">
                <div className="row">
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="assessmentName"
                      onChange={(e) =>
                        this.props.handleAssessmentChange(
                          e,
                          this.props.yearLevelId
                        )
                      }
                      value={this.props.assessmentName}
                    >
                      <option value="" disabled>
                        Select Assessment
                      </option>
                      {this.props.assessmentList.map((assessment, idx) => (
                        <option value={assessment.id}>
                          {assessment.assessmentName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      name="reportSetupId"
                      value={this.props.reportSetupId}
                      onChange={this.props.universalHandleChange}
                      disabled={!this.props.assessmentName}
                    >
                      <option value="" disabled>
                        Select Report Setup
                      </option>
                      {this.props.reportSetups.length > 0
                        ? this.props.reportSetups.map((report, reportIdx) => (
                            <option value={report.id} key={reportIdx}>
                              {report.name}
                            </option>
                          ))
                        : "No setups"}
                    </select>
                  </div>
                  <div className="col-md-3 mt-1">
                    {this.props.reportSetups.length > 0 ? (
                      ""
                    ) : this.props.reportSetups.length === 0 &&
                      this.props.assessmentName ? (
                      <p style={{ marginLeft: "2%", display: "inline" }}>
                        (No Setups)
                      </p>
                    ) : (
                      <p style={{ marginLeft: "2%", display: "inline" }}>
                        (Select assessment first)
                      </p>
                    )}
                  </div>
                  <div className="col-md-3" style={{ display: "flex" }}>
                    <button
                      className="tt-button tt-button-primary"
                      onClick={(e) =>
                        this.props.handleGenerateReport(
                          this.props.yearLevelId,
                          this.props.classroomId
                        )
                      }
                      disabled={
                        !this.props.assessmentName || !this.props.reportSetupId
                      }
                    >
                      Generate
                    </button>

                    {checkWidgetAdminOrNot(this.props.name) === true ||
                    (this.props.loggedIdUserRecruitmentId
                      ? parseInt(this.props.loggedIdUserRecruitmentId.id) ===
                        parseInt(this.props.recruitmentId)
                      : null) ? (
                      <button
                        className="tt-button tt-button-primary"
                        onClick={(e) =>
                          this.props.handlePublishReport(
                            this.props.yearLevelId,
                            this.props.classroomId
                          )
                        }
                        disabled={
                          !this.props.assessmentName ||
                          !this.props.reportSetupId
                        }
                      >
                        Publish
                      </button>
                    ) : null}
                    {/* </a> */}
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead className="tt-group-header">
                  <tr>
                    <th
                      width="50px"
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <input
                        type="checkbox"
                        checked={this.props.allStudentChecked}
                        onClick={this.props.handleSelectAllCheckBoxClick}
                      />
                    </th>
                    <th style={{ verticalAlign: "middle" }}>Student Name</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.studentList.length > 0 ? (
                    this.props.studentList.map((student, studentIdx) => (
                      <tr key={studentIdx}>
                        <td
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            id={student.id}
                            checked={student.checked}
                            onClick={(e) =>
                              this.props.handlSelectRowCheckBoxClick(
                                e,
                                student.people.id
                              )
                            }
                          />
                        </td>
                        <td>{student.people.name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2}>There are no assigned students</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          </CardBody>
        </Card>
      </Collapse>
    );
  }
}

export default ReportGeneratorUI;
