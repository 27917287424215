import React, { Component } from "react";
import ViewLMSFile from "../SchoolLMS/ViewLMSFile";
import ModalWindow from "../UI/ModalWindow";

class ResourceSearchResult extends Component {
  state = {
    activeLMSFile: null,
    fileViewerModal: false,
  };

  handleFileViewer = (item) => {
    this.setState({ activeLMSFile: item }, function () {
      this.toggleFileViewer();
    });
  };

  toggleFileViewer = () => {
    this.setState({
      fileViewerModal: !this.state.fileViewerModal,
    });
  };

  render() {
    let props = this.props;
    return (
      <div className="row">
        {props.searchData.map((data, idx) => {
          return (
            <div className="col-md-4 px-0">
              <div onClick={() => this.handleFileViewer(data)}>
                <div className="groupSearchBox">
                  <p className="groupSearchTitle line-clamp-1">
                    {data?.title}{" "}
                  </p>
                  <p style={{ fontWeight: "bold", color: "#032467" }}>
                    {data.subjectName
                      ? `[ ${data?.yearLevelName} - ${data?.subjectName} ]`
                      : null}
                  </p>
                  <p className="groupSearchContent line-clamp-3">
                    {data?.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
        <ModalWindow
          modal={this.state.fileViewerModal}
          modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          size="lg"
          modalHeader="Resource Files"
          modalBody={<ViewLMSFile activeLMSFile={this.state.activeLMSFile} />}
        />
      </div>
    );
  }
}

export default ResourceSearchResult;
