import React, { Component } from "react";
import TableFormInputs from "./TableFormInputs";

class TableForm extends Component {
  render() {
    const {
      field,
      conf: config,
      handleCustomFieldTablesChange,
      studentFieldIndex,
      customTableFieldIndex,
      stateName,
    } = this.props;

    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            {field?.customFieldTableColumns.map((ctfc) => (
              <th
                rowSpan={1}
                colSpan={ctfc?.customFieldTableSubColumns?.length}
              >
                {ctfc.name}
              </th>
            ))}
          </tr>
          <tr>
            {field?.customFieldTableColumns?.map((ctfc) => (
              <>
                {ctfc?.customFieldTableSubColumns?.length > 0 ? (
                  ctfc?.customFieldTableSubColumns.map((cftsc) => (
                    <th rowSpan={1} colSpan={1}>
                      {cftsc.name}
                    </th>
                  ))
                ) : (
                  <th></th>
                )}
              </>
            ))}
          </tr>
        </thead>

        <tbody>
          {field?.rows?.map((row, rowIndex) => (
            <tr>
              {field?.customFieldTableColumns?.map((cftc, cftcIndex) => (
                <>
                  <TableFormInputs
                    field={cftc}
                    key={cftcIndex}
                    handleCustomFieldTablesChange={
                      handleCustomFieldTablesChange
                    }
                    config={config}
                    studentFieldIndex={studentFieldIndex}
                    customTableFieldIndex={customTableFieldIndex}
                    stateName={stateName}
                    columnIndex={cftcIndex}
                    rowIndex={rowIndex}
                    subColumn={false}
                    row={row}
                  />
                  {rowIndex > 0 &&
                  cftcIndex === field?.customFieldTableColumns?.length - 1 ? (
                    <div className="p-2">
                      <button
                        className="tt-button tt-button-danger"
                        onClick={
                          !config
                            ? () =>
                                this.props.handleRemoveDuplicate(
                                  this.props.studentFieldIndex,
                                  this.props.customTableFieldIndex,
                                  this.props.stateName,
                                  rowIndex
                                )
                            : null
                        }
                      >
                        -
                      </button>
                    </div>
                  ) : null}
                  {rowIndex === field?.rows?.length - 1 &&
                  cftcIndex === field?.customFieldTableColumns?.length - 1 &&
                  cftc?.customFieldTableSubColumns?.length === 0 ? (
                    <>
                      <div className="p-2">
                        <button
                          className="tt-button tt-button-primary"
                          onClick={
                            !config
                              ? () =>
                                  this.props.handleDuplicate(
                                    this.props.studentFieldIndex,
                                    this.props.customTableFieldIndex,
                                    this.props.stateName
                                  )
                              : null
                          }
                        >
                          +
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
              ))}
            </tr>
          ))}

          {field?.customFieldTableColumns?.map((ctfc, ctfcIndex) => (
            <>
              {ctfc.rows?.map((row, rowIndex) => (
                <tr>
                  {ctfc?.customFieldTableSubColumns?.map(
                    (cftsc, cftscIndex) => (
                      <>
                        <TableFormInputs
                          field={cftsc}
                          key={cftscIndex}
                          config={config}
                          handleCustomFieldTablesChange={
                            handleCustomFieldTablesChange
                          }
                          studentFieldIndex={studentFieldIndex}
                          customTableFieldIndex={customTableFieldIndex}
                          stateName={stateName}
                          columnIndex={ctfcIndex}
                          subColumnIndex={cftscIndex}
                          rowIndex={rowIndex}
                          subColumn={true}
                          row={row}
                        />
                        {rowIndex > 0 &&
                        cftscIndex ===
                          ctfc?.customFieldTableSubColumns?.length - 1 ? (
                          <div className="p-2">
                            <button
                              className="tt-button tt-button-danger"
                              onClick={
                                !config
                                  ? () =>
                                      this.props.handleRemoveDuplicate(
                                        this.props.studentFieldIndex,
                                        this.props.customTableFieldIndex,
                                        this.props.stateName,
                                        rowIndex,
                                        "subCol",
                                        ctfcIndex
                                      )
                                  : null
                              }
                            >
                              -
                            </button>
                          </div>
                        ) : null}
                        {rowIndex === ctfc?.rows?.length - 1 &&
                        cftscIndex ===
                          ctfc?.customFieldTableSubColumns?.length - 1 ? (
                          <>
                            <div className="p-2">
                              <button
                                className="tt-button tt-button-primary"
                                onClick={
                                  !config
                                    ? () =>
                                        this.props.handleDuplicate(
                                          this.props.studentFieldIndex,
                                          this.props.customTableFieldIndex,
                                          this.props.stateName,
                                          "subCol",
                                          ctfcIndex
                                        )
                                    : null
                                }
                              >
                                +
                              </button>
                            </div>
                          </>
                        ) : null}
                      </>
                    )
                  )}
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
    );
  }
}

export default TableForm;
