import React, { Component } from "react";

class CourseTopicUpdateModal extends Component {
  state = {};
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <strong>Course Name: </strong>
          </div>
          <div className="col-md-7">
            <input
              type="text"
              className="form-control"
              value={this.props.updateTopicName}
              name="updateTopicName"
              onChange={this.props.handleChange}
            ></input>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <strong>Time: </strong>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col">
                <strong>Lecture Hour: </strong>
              </div>
              <div className="col">
                <strong>Practical Hour: </strong>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-md-1"></div>
          <div className="col-md-3"></div>
          <div className="col-md-7">
            <div className="row">
              <div className="col">
                <input
                  type="number"
                  className="form-control"
                  name="updateTopicLectureHour"
                  value={this.props.updateTopicLectureHour}
                  onChange={this.props.handleChange}
                ></input>
              </div>
              <div className="col">
                <input
                  type="number"
                  className="form-control"
                  name="updateTopicPracticalHour"
                  value={this.props.updateTopicPracticalHour}
                  onChange={this.props.handleChange}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    );
  }
}

export default CourseTopicUpdateModal;
