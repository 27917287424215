import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../utils/Constants";

import JoditEditor from "jodit-react";
import { draggable } from "../../utils/Constants";
import { displayErrorAlert, MESSAGEHEADER } from "../../utils/DisplayMessage";
import swal from "sweetalert";
import DragDropFile from "../../UI/DragDropFile";
// import isURL from "validator/lib/isurl";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  limitChars: 60000,
  removeButtons: [
    "hr",
    "about",
    "fullsize",
    "eraser",
    "undo",
    "redo",
    "table",
    "file",
    "format",
    "brush",
    "source",
    "fontsize",
    "paragraph",
    "left",
    "center",
    "right",
    "justify",
    "video",
    "superscript",
    "subscript",
    "print",
    "copyformat",
  ],
  uploader: {
    insertImageAsBase64URI: true,
  },
};

export class CreateMasterNotice extends Component {
  state = {
    groupsArray: [],
    peopleArray: [],
    peopleSpinner: false,

    heading: "Notice Title",
    files: [],
    textContent: "",
    media: "",
    selectedMedias: [],
    links: "",
    selectedLinks: [],
    selectedOrigin: "",
    url: "",
    selectedURL: "",
    fileSize: 0,
    selectedGroups: [],
    selectedArticleGroups: [],
    selectedMediaGroups: [],
    articleKeywords: "",
    mediaKeywords: "",
    themes: null,
    addThemeModal: false,
    addQuestionModal: false,
    theme: "",
    description: "",
    themeList: [],
    questions: [
      {
        question: "",
      },
    ],
    postTypeCode: "",
    questionsForMedia: [
      {
        question: "",
      },
    ],
    isAcademicRepo: false,
    tabName: "",
    selectedIndividual: [],
    isSubmitting: false,
    showSpinner: false,
    error: false,
    insertedInsitePost: [],
  };
  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "heading") {
      if (value.length > 100) {
        swal({
          title: "Warning",
          text: "Heading cannot be more than 100 words",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleChangeSelect = (selectedItem) => {
    this.setState({
      selectedGroups: selectedItem,
    });
  };

  handleFileSelect = (e) => {
    e.preventDefault();
    let fileSize = this.state.fileSize;
    let data = e.target.files;
    let files = [...this.state.files];
    for (let i = 0; i < data.length; i++) {
      var extension = data[i].name.split(".").pop();
      if (!data[i].type.includes("image")) {
        fileSize = fileSize + data[i].size;

        if (Math.round(fileSize / 1024) > 5120) {
          swal(MESSAGEHEADER.error, "File Size exceeded its limit", "");
        } else if (extension !== "pdf") {
          swal(MESSAGEHEADER.error, "Unsupported File Type", "");
        } else {
          files.push(data[i]);
          this.setState({ files, fileSize: fileSize });
        }
      } else {
        if (
          extension === "jpeg" ||
          extension === "png" ||
          extension === "jpg"
        ) {
          files.push(data[i]);
          this.setState({ files });
        } else {
          swal(MESSAGEHEADER.error, "Unsupported File Type", "");
        }
      }
    }
  };

  handleFileDrop = (e, name) => {
    let data = e.dataTransfer.files;

    let fileSize = this.state.fileSize;
    let files = [...this.state.files];
    for (let i = 0; i < data.length; i++) {
      var extension = data[i].name.split(".").pop();
      if (!data[i].type.includes("image")) {
        fileSize = fileSize + data[i].size;

        if (Math.round(fileSize / 1024) > 5120) {
          swal(MESSAGEHEADER.error, "File Size exceeded its limit", "");
        } else if (extension !== "pdf") {
          swal(MESSAGEHEADER.error, "Unsupported File Type", "");
        } else {
          files.push(data[i]);
          this.setState({ files, fileSize: fileSize });
        }
      } else {
        if (
          extension === "jpeg" ||
          extension === "png" ||
          extension === "jpg"
        ) {
          files.push(data[i]);
          this.setState({ files });
        } else {
          swal(MESSAGEHEADER.error, "Unsupported File Type", "");
        }
      }
    }
  };

  handleTextEditor = (value) => {
    this.setState({ textContent: value });
  };

  deleteFileContent = (e, idx, file) => {
    let files = [...this.state.files];
    let fileSize = this.state.fileSize;
    if (!this.state.files[idx].type.includes("image")) {
      fileSize = fileSize - this.state.files[idx].size;
    }

    // let newfiles = this.state.files.filter((nfile) => !isEqual(nfile, file));
    files.splice(idx, 1);
    this.setState({
      files: files,
      fileSize: fileSize,
      selectedOrigin: "",
      url: "",
    });
  };

  submitPost = () => {
    if (
      this.state.heading === "" ||
      this.state.textContent === "" ||
      this.state.files.length === 0
    ) {
      swal("Error", "Heading, content and file is required");
    } else {
      let data = {
        heading: this.state.heading,
        textContent: this.state.textContent,
      };

      let formData = new FormData();

      formData.append("jsonData", JSON.stringify(data));

      if (this.state.files.length > 0) {
        for (const key of Object.keys(this.state.files)) {
          formData.append("content", this.state.files[key]);
        }
      }

      axiosPost(
        URL.insertNoticeCore,
        formData,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Notice saved successully.");
            this.props.getAllNotices();
            this.props.toggleModal();
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    }
  };

  render() {
    return (
      <div>
        <div>
          <div className="row mb-1 mt-2">
            <div className="col-md-1 mt-1 text-center">
              <strong>Title</strong>
            </div>
            <div className="col-md-11">
              <input
                type="text"
                className="form-control"
                name="heading"
                value={this.state.heading}
                onChange={(e) => this.handleChange(e)}
              ></input>
            </div>
          </div>
          <JoditEditor
            onChange={(e) => this.handleTextEditor(e)}
            config={config}
          />

          <div className="row">
            <div className="col-md-6">
              <div className="tt-postNoticeBoxes">
                <div>
                  <strong>Attached files:</strong>&nbsp;
                  {Math.round(this.state.fileSize / 1024)} of 5120 (5 MB)
                </div>
                <div className="tt-postFiles">
                  {this.state.files.length > 0 ? (
                    this.state.files.map((file, idx) => {
                      return (
                        <div key={idx}>
                          {file.type.includes("image") ? (
                            <div
                              className="tt-postFileHolder"
                              style={{ padding: 0 }}
                            >
                              <img
                                src={window.URL.createObjectURL(file)}
                                alt="notice"
                              ></img>
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="tt-fileDelete"
                                onClick={(e) => {
                                  this.deleteFileContent(e, idx, file);
                                }}
                              ></FontAwesomeIcon>
                            </div>
                          ) : (
                            <div className="tt-postFileHolder">
                              <FontAwesomeIcon
                                icon={faFile}
                                className="tt-postFileIcon"
                              ></FontAwesomeIcon>
                              <div>{file.name}</div>
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="tt-fileDelete"
                                style={{ top: "-80px" }}
                                onClick={(e) => {
                                  this.deleteFileContent(e, idx, file);
                                }}
                              ></FontAwesomeIcon>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center">No Files uploaded</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <DragDropFile
                multiple={true}
                handleChange={this.handleFileSelect}
                name="files"
                handleFileDrop={this.handleFileDrop}
                files={this.state.files}
                accept="image/png, image/jpeg, image/jpg, application/pdf"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2">
              <input
                id="myInput"
                type="file"
                ref={(ref) => (this.upload = ref)}
                style={{ display: "none" }}
                onChange={(e) => this.handleFileSelect(e)}
                onClick={(e) => {
                  e.target.value = "";
                }}
                multiple
              />
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={() => {
                  this.upload.click();
                }}
              >
                Attach File
              </button>
            </div>
            <div className="col-md-6">
              <p>
                Attach Documents (pdf) / Image (jpg,png,gif)
                {/* Attach Documents (pdf, docs, ods, rtf, etc.) / Image (jpg,png,gif) */}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10"></div>
            <div className="col-md-2 text-right">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.submitPost}
                disabled={
                  this.state.heading === "" || this.state.textContent === ""
                }
              >
                Post Notice
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateMasterNotice;
