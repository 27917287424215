import React, { Component } from "react";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, postType } from "../../utils/Constants";
import ArticleTemplate from "../../components/Profile/Templates/ArticleTemplate";
import MediaTemplate from "../../components/Profile/Templates/MediaTemplate";
import Pagination from "../../components/UI/Pagination";
import { displayErrorAlert } from "../../utils/Utils";
import { Spinner } from "reactstrap";
import {
  youtubeLink,
  dailyMotionLink,
  vimeoLink,
  slideshareLink,
  defaultLinkURL,
} from "../../utils/VideoURL";

class CentralAcademicRepo extends Component {
  state = {
    currentPage: 1,
    offset: 0,
    repoData: null,
    repoSpinner: false,
    rowCountLimit: 8,
    schools: [],
    selectedSchoolId: "",
    totalAcademicRepoPosts: 0,
  };

  componentDidMount() {
    this.getAllSchools();
  }

  getAcademicRepo = () => {
    this.setState({ repoData: [], repoSpinner: true }, function () {
      let param = {
        schoolId: this.state.selectedSchoolId,
        offset: this.state.offset,
        rowCountLimit: this.state.rowCountLimit,
      };
      axiosPost(
        URL.getCentralAcademicRepo,
        param,
        (response) => {
          if (response.status === 200) {
            let newRepo = [];
            let repoPosts = response.data.data.academicRepositories;
            if (repoPosts !== null && repoPosts.length > 0) {
              repoPosts.forEach((post) => {
                if (
                  post.postType === postType.articleCode ||
                  post.postType === postType.mediaCode
                ) {
                  post.contents.forEach((content) => {
                    if (
                      content.contentTypeCode === "LNK" &&
                      content.content !== ""
                    ) {
                      if (
                        content.content.includes("youtube") ||
                        content.content.includes("youtu.be")
                      ) {
                        let contentObj = youtubeLink(content.content);
                        content.youtubeTitle = contentObj.youtubeTitle;
                        content.thumbnail = contentObj.thumbnail;
                      } else if (
                        content.content.includes("dailymotion") ||
                        content.content.includes("dai.ly")
                      ) {
                        let contentObj = dailyMotionLink(content.content);
                        content.youtubeTitle = contentObj.youtubeTitle;
                        content.thumbnail = contentObj.thumbnail;
                      } else if (content.content.includes("vimeo")) {
                        let contentObj = vimeoLink(content.content);
                        content.youtubeTitle = contentObj.youtubeTitle;
                        content.thumbnail = contentObj.thumbnail;
                      } else if (
                        content.content.includes("slideshare") &&
                        content.youtubeTitle !== undefined
                      ) {
                        let contentObj = slideshareLink(content.content);
                        content.youtubeTitle = contentObj.youtubeTitle;
                        content.thumbnail = contentObj.thumbnail;
                      } else {
                        let contentObj = defaultLinkURL(content.content);
                        content.youtubeTitle = contentObj.youtubeTitle;
                        content.thumbnail = contentObj.thumbnail;
                      }
                    }
                  });
                  newRepo.push(post);
                }
              });
            }
            this.setState({
              repoData: newRepo,
              totalAcademicRepoPosts: response.data.data.count,
              repoSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({ repoSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  getAllSchools = () => {
    axiosPost(URL.getAllSchools, {}, (response) => {
      if (response.status === 200) {
        this.setState({ schools: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      this.getAcademicRepo();
    });
  };

  handlePageChange = (page) => {
    this.reRenderRepoList(page);
    this.setState({ currentPage: page }, function () {
      //   this.setDisplayNumber();
    });
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1)
      this.reRenderRepoList(this.state.currentPage - 1);
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.totalAcademicRepoPosts / this.state.rowCountLimit)
    )
      return;
    else {
      this.reRenderRepoList(nextPage);
      this.setState({ currentPage: nextPage });
    }
  };

  reRenderRepoList = (page) => {
    let offset =
      page * parseInt(this.state.rowCountLimit) -
      parseInt(this.state.rowCountLimit);
    this.setState({ offset: offset }, function () {
      this.getAcademicRepo();
    });
  };

  render() {
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader title="Academic Repository"></CustomPageHeader>
              <div className="container">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col text-center">
                    <select
                      className="form-control cusInputSelect"
                      defaultValue=""
                      value={this.state.selectedSchoolId}
                      onChange={this.handleChange}
                      name="selectedSchoolId"
                    >
                      <option value="" disabled>
                        Select School
                      </option>
                      {/* <option value="all">All Schools</option> */}
                      {this.state.schools.length > 0 ? (
                        this.state.schools.map((school, idx) => {
                          return (
                            <option value={school.id} key={idx}>
                              {school.name}
                            </option>
                          );
                        })
                      ) : (
                        <option value="" disabled>
                          No schools added
                        </option>
                      )}
                    </select>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
              {this.state.repoData ? (
                <React.Fragment>
                  <div className="searchBar mt-3">
                    <span>{this.state.totalAcademicRepoPosts}</span> academic
                    repository's found in total.
                  </div>
                  <div className="container">
                    <div className="row">
                      {this.state.repoData.length > 0 ? (
                        this.state.repoData.map((academicPosts, aIdx) => {
                          return (
                            <div className="col-md-12 mb-2" key={aIdx}>
                              <div
                                className={
                                  "tt-post-template " +
                                  (aIdx % 2 === 0 ? "" : "postBgColor")
                                }
                              >
                                {academicPosts.postType ===
                                postType.articleCode ? (
                                  <ArticleTemplate
                                    post={academicPosts}
                                    fullPage={true}
                                    // isAcademicRepo={true}
                                    fromCentral={true}
                                  />
                                ) : academicPosts.postType ===
                                  postType.mediaCode ? (
                                  <MediaTemplate
                                    post={academicPosts}
                                    fullPage={true}
                                    // isAcademicRepo={true}
                                    fromCentral={true}
                                  />
                                ) : null}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col text-center">
                          {this.state.repoSpinner ? (
                            <>
                              <Spinner></Spinner>
                              <br />
                              Loading Data ...
                            </>
                          ) : (
                            "No Academic Repository data found"
                          )}
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col mt-3 text-center">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Pagination
                            itemsCount={this.state.totalAcademicRepoPosts}
                            pageSize={this.state.rowCountLimit}
                            currentPage={this.state.currentPage}
                            onPageChange={this.handlePageChange}
                            previousPage={this.handlePreviousPage}
                            nextPage={this.handleNextPage}
                          ></Pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CentralAcademicRepo;
