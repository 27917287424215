import React, { Component } from "react";

class Civil extends Component {
  state = {};
  render() {
    return <div>Civil</div>;
  }
}

export default Civil;
