import React, { useState, useEffect } from "react";
import { VscHome } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { axiosPost } from "../../utils/AxiosApi";
import {
  checkUserRole,
  URL,
  userRole,
  userRoleCode,
} from "../../utils/Constants";
import logo from "../../assets/images/logo/nepal_logo.png";
import nep from "../../assets/images/logo/nep.png";
import eng from "../../assets/images/logo/eng.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import nepalFlag from "../../assets/images/nepal_flag.gif";
import CustomModal from "../../components/common/CustomModal";
import HoverDivModal from "./HoverDivModal";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setGlobalLang } from "../../ducks/LanguageDucks";
import swal from "sweetalert";

const NavBar = (props) => {
  const history = useHistory();
  const [logoPreview, setLogoPreview] = useState(null);
  const [disclaimer, setDisclaimer] = useState("");
  const [govHeader, setGovHeader] = useState(null);
  const [hoverModal, setHoverModal] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [websiteConfigData, setWebsiteConfigData] = useState();
  // console.log(websiteConfigData);
  const [webUserType, setWebUserType] = useState(
    localStorage.getItem("webUserType") || null
  );

  const [menu, setMenus] = useState([]);
  // const [lang, setLang] = useState(localStorage.getItem("lang") || "nameEn");
  const [loggedIn, setLoggedIn] = useState(false);
  const { homePage } = props;

  // const setGlobalLang = (lang) => {
  //   setLang(lang);
  //   localStorage.setItem("lang", lang);
  // };

  const loginCheck = () => {
    let res;
    if (localStorage.getItem("is2FAEnabled") === "true") {
      res =
        localStorage.getItem("token") &&
        localStorage.getItem("token") !== "" &&
        localStorage.getItem("is2FAEnabled") &&
        localStorage.getItem("otpVerified")
          ? true
          : false;
    } else {
      res =
        localStorage.getItem("token") && localStorage.getItem("token") !== ""
          ? true
          : false;
    }
    return res;
  };

  useEffect(() => {
    getMenus();
    loginCheck() && setLoggedIn(true);
  }, []);

  const getMenus = () => {
    axiosPost(
      URL.getAllMenu,
      {},
      (response) => {
        if (response.status === 200) {
          setMenus(response.data.data);
        }
      },
      (err) => {},
      true
    );
  };

  const handleLogout = (e) => {
    e.preventDefault();
    axiosPost(URL.logout, {}, (response) => {
      if (response.status === 200) {
        localStorage.clear();
        history.push("/");
        document.location.reload();
      }
    });
  };

  const renderDashboardLink = () => {
    const userId = localStorage.getItem("mainUserId");
    const schoolId = localStorage.getItem("schoolId");
    if (checkUserRole() || userId == "1") {
      return "/dashboard/main";
    } else if (
      schoolId &&
      schoolId !== "" &&
      schoolId !== "undefined" &&
      schoolId !== "null"
    ) {
      return "/profile";
    } else if (
      userRole === userRoleCode.roleStudent ||
      userRole === userRoleCode.roleOther
    ) {
      return "/student";
    } else if (
      userRole === userRoleCode.roleStaff ||
      userRole === userRoleCode.roleNationalAdmin
    ) {
      return "/teacher";
    } else {
      return "/";
    }
  };

  useEffect(() => {
    getWebsiteConfig();
  }, []);

  const getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        // console.log(data);
        if (data.length > 0) {
          setWebsiteConfigData(data);
          setDisclaimer(data[0].disclaimer);
          setLogoPreview(data[0].logoPath);
          // setNoticeDisplay(data[0].displayNotice);
          setGovHeader(data[0].mainLogoPath);
        }
      }
    });
  };

  const handleHoverFunction = (link) => {
    setHoveredLink(link);
    setHoverModal(!hoverModal);
  };

  const closeHoverModal = () => {
    setHoverModal(!hoverModal);
    setHoveredLink(null);
  };

  const submitViewType = (type) => {
    let param = {
      viewerType: type,
    };
    axiosPost(URL.updateViewerStat, param, (response) => {
      if (response.status === 200) {
        localStorage.setItem("webUserType", type);
        setWebUserType(type);
        setHoverModal(!hoverModal);
        history.push(hoveredLink);
      }
    });
  };

  return (
    <>
      <div className="disclaimerBox">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 text-center">
              {disclaimer ? disclaimer : null}
            </div>
            <div className="col-md-2">
              <div className="langMenuDiv">
                <div>
                  <a
                    onClick={() => props.setGlobalLang("nameEn")}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={eng}
                      alt="english logo"
                      className="langFlag"
                    ></img>
                    {props.lang === "nameEn" ? <u>EN</u> : "EN"}
                  </a>
                </div>
                <div>|</div>
                <div>
                  <a
                    onClick={() => props.setGlobalLang("nameNp")}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={nep}
                      alt="nepal logo"
                      className="langFlag mr-0"
                      style={{ height: "12px" }}
                    ></img>
                    {props.lang === "nameNp" ? <u>NP</u> : "NP"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="whiteContainer-01"
        style={{ borderRadius: 0, padding: "5px" }}
      >
        <div className="container-fluid mobileContainer bigContainer">
          <nav className="navbar navbar-expand-lg px-0">
            <div className="container-fluid px-0">
              <div className="mobileLogoDiv">
                {/* {logoPreview ? (
                  <img
                    src={URL.imageSelectURL + logoPreview}
                    alt="logo"
                    className="navLogo"
                  />
                ) : ( */}
                <img
                  src={`${URL.imageSelectURL}${logoPreview}`}
                  alt="logo"
                  className="navLogo"
                />
                {/* )} */}
                {/* <div className="mobileGovHeader">
                  <p className="govNepalTitle">
                    {props.lang === "nameNp"
                      ? "नेपाल सरकार"
                      : "Government of Nepal"}
                  </p>
                  <p className="govNepalMinistryTitle">
                    {props.lang === "nameNp"
                      ? "शिक्षा, विज्ञान तथा प्रविधि मन्त्रालय"
                      : "Ministry of Education, Science and Technology"}
                  </p>
                  <p className="govNepalSubTitle">
                    {props.lang === "nameNp"
                      ? "शिक्षा तथा मानव स्रोत विकास केन्द्र"
                      : "Centre for Education and Human Resource Development"}
                  </p>
                  <p className="learningPortalText">
                    {props.lang === "nameNp"
                      ? "सिकाइ चौतारी"
                      : "LEARNING PORTAL"}
                  </p>
                </div> */}
              </div>
              <button
                className="navbar-toggler"
                id="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                {props.homePage ||
                props.pathName === "/" ||
                props.pathName === "/otheractivities" ? (
                  <ul
                    className="navbar-nav ml-auto mb-2 mb-lg-0"
                    id="order-list"
                  >
                    {menu
                      .filter((m) => m.display)
                      .map((m) => (
                        <>
                          {m.subMenus && m.subMenus.length > 0 ? (
                            <li className="nav-item dropdown">
                              <a
                                className="nav-link dropdown-toggle"
                                id="navbarScrollingDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => history.push(m.link)}
                                style={{ cursor: "pointer" }}
                              >
                                {props.lang === "nameNp" ? m.nameNp : m.nameEn}
                              </a>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="navbarScrollingDropdown"
                              >
                                {m.subMenus.map((sm) => (
                                  <li>
                                    <Link to={sm.link}>
                                      <a className="dropdown-item">
                                        {props.lang === "nameNp"
                                          ? sm.nameNp
                                          : sm.nameEn}
                                      </a>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : (
                            <li
                              className="nav-item"
                              onMouseOver={
                                (m.nameEn === "Reading Materials" ||
                                  m.nameEn === "Other Materials") &&
                                webUserType === null &&
                                !loginCheck()
                                  ? () => handleHoverFunction(m.link)
                                  : null
                              }
                            >
                              <Link
                                to={{
                                  pathname: m.link,
                                  state: {
                                    homePage: true,
                                  },
                                }}
                              >
                                <a
                                  className="nav-link d-flex align-items-center "
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                >
                                  {m.link === "/" && (
                                    <VscHome style={{ marginRight: "3px" }} />
                                  )}
                                  <span>
                                    {props.lang === "nameNp"
                                      ? m.nameNp
                                      : m.nameEn}
                                  </span>
                                </a>
                              </Link>
                            </li>
                          )}
                        </>
                      ))}
                    <div
                      className="d-flex mobileLangMenu"
                      style={{ marginTop: "2px" }}
                    >
                      <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                        <li className="nav-item" id="login-button">
                          <a
                            className="nav-link "
                            aria-current="page"
                            onClick={() => props.setGlobalLang("nameEn")}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={eng}
                              alt="english logo"
                              className="langFlag"
                            ></img>
                            {props.lang === "nameEn" ? <u>EN</u> : "EN"}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link">|</a>
                        </li>
                        <li className="nav-item" id="signup-button">
                          <a
                            className="nav-link"
                            aria-current="page"
                            onClick={() => setGlobalLang("nameNp")}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={nep}
                              alt="nepal logo"
                              className="langFlag"
                              style={{ height: "12px" }}
                            ></img>
                            {props.lang === "nameNp" ? <u>NP</u> : "NP"}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {
                      // homePage ? (
                      homePage && loginCheck() ? (
                        <li className="nav-item" id="login-button">
                          <Link to={renderDashboardLink()}>
                            <a
                              className="nav-link "
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp"
                                ? "ड्यासबोर्डमा जानुहोस्"
                                : "Go to dashboard"}
                            </a>
                          </Link>
                        </li>
                      ) : (
                        <React.Fragment>
                          <li className="nav-item" id="login-button">
                            <Link to="/login">
                              <a
                                className="nav-link "
                                aria-current="page"
                                style={{ cursor: "pointer" }}
                              >
                                {props.lang === "nameNp" ? "लग-इन" : "Log in"}
                              </a>
                            </Link>
                          </li>
                          {/* <li className="nav-item" id="signup-button">
                              <Link to="/signup">
                                <a
                                  className="nav-link"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                >
                                  {props.lang === "nameNp" ? "साइन अप" : "Sign Up"}
                                </a>
                              </Link>
                            </li> */}
                        </React.Fragment>
                      )
                    }
                  </ul>
                ) : null}
                <div className={loggedIn && !homePage ? "loggedInNavbar" : ""}>
                  <ul className="navbar-nav mb-2 mb-lg-0" id="order-list">
                    {loggedIn && !homePage && (
                      <>
                        <li className="nav-item" id="notices-button">
                          <Link to={"/"}>
                            <a
                              className="nav-link"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp" ? "वेबसाइट" : "Website"}
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item" id="notices-button">
                          <Link
                            to={
                              props.classPage
                                ? "/student"
                                : props.teacherPage
                                ? "/teacher"
                                : "/"
                            }
                          >
                            <a
                              className="nav-link"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp" ? "गृहपृष्ठ" : "Home"}
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item" id="notices-button">
                          <Link
                            to={{
                              pathname: "/notices",
                              state: {
                                classPage: props.classPage,
                                teacherPage: props.teacherPage,
                                noNav: true,
                              },
                            }}
                          >
                            <a
                              className="nav-link"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp" ? "सूचनाहरू" : "Notices"}
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item" id="notices-button">
                          <Link
                            to={{
                              pathname: "/discussion",
                              state: {
                                classPage: props.classPage,
                                teacherPage: props.teacherPage,
                                noNav: true,
                              },
                            }}
                          >
                            <a
                              className="nav-link"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp" ? "छलफल" : "Discussion"}
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item" id="notices-button">
                          <Link
                            to={{
                              pathname: "/dictionary",
                              state: {
                                classPage: props.classPage,
                                teacherPage: props.teacherPage,
                                noNav: true,
                              },
                            }}
                          >
                            <a
                              className="nav-link"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp"
                                ? "शब्दकोश"
                                : "Dictionary"}
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item" id="notices-button">
                          <Link
                            to={{
                              pathname: "/customCalculator",
                              state: {
                                classPage: props.classPage,
                                teacherPage: props.teacherPage,
                                noNav: true,
                              },
                            }}
                          >
                            <a
                              className="nav-link"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp"
                                ? "क्याल्कुलेटर"
                                : "Calculator"}
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item" id="notices-button">
                          <Link
                            to={{
                              pathname: "/relative-links",
                              state: {
                                classPage: props.classPage,
                                teacherPage: props.teacherPage,
                                noNav: true,
                              },
                            }}
                          >
                            <a
                              className="nav-link"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp"
                                ? "सापेक्ष लिङ्कहरू"
                                : "Relative Links"}
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item" id="profile-button">
                          <Link
                            to={{
                              pathname: "/myprofile",
                              state: {
                                classPage: props.classPage,
                                teacherPage: props.teacherPage,
                                noNav: true,
                              },
                            }}
                          >
                            <a
                              className="nav-link"
                              aria-current="page"
                              style={{ cursor: "pointer" }}
                            >
                              {props.lang === "nameNp"
                                ? "मेरो प्रोफाइल"
                                : "My Profile"}
                            </a>
                          </Link>
                        </li>
                      </>
                    )}
                    {loggedIn && (
                      <li className="nav-item" id="logout-button">
                        <a
                          className="nav-link"
                          aria-current="page"
                          style={{ cursor: "pointer", fontWeight: "600" }}
                          onClick={(e) => handleLogout(e)}
                        >
                          {props.lang === "nameEn" ? "Sign Out" : "साइन आउट"}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              {/* <div className="nepalFlagHolder">
                <img src={nepalFlag} alt="Gif image" />
              </div> */}
            </div>
          </nav>
          <div className="text-center">
            <p className="learningPortalText">
              {props.lang === "nameNp" ? "सिकाइ चौतारी" : "LEARNING PORTAL"}
            </p>
          </div>
        </div>
        <CustomModal
          modal={hoverModal}
          toggleModal={closeHoverModal}
          modalHeader="Choose Type"
          modalBody={<HoverDivModal submitViewType={submitViewType} />}
        />
      </div>
      {/* {govHeader && !props.noHeader ? (
        <div className="govHeader p-1 mobileGovPhotoHeader">
          <div className="govHeader-img">
            <img src={URL.imageSelectURL + govHeader} />
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {
  setGlobalLang: setGlobalLang,
};

export default connect(mapStateToProps, mapActionsToProps)(NavBar);
