import React, { Component } from "react";

class HoverDivModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h3 className="hoverTitle">
              Are you visiting this page as a <p className="hoverBorder"></p> ?
            </h3>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-center">
            <button
              className="btn btn-primary cusBtn cusHoverBtn"
              onClick={() => this.props.submitViewType("Teacher")}
            >
              Teacher
            </button>
          </div>
          <div className="col text-center">
            <button
              className="btn btn-primary cusBtn cusHoverBtn"
              onClick={() => this.props.submitViewType("Student")}
            >
              Student
            </button>
          </div>
          <div className="col text-center">
            <button
              className="btn btn-primary cusBtn cusHoverBtn"
              onClick={() => this.props.submitViewType("Others")}
            >
              Others
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default HoverDivModal;
