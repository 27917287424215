import JoditEditor from "jodit-react";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import { postType, URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/Utils";
const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: ["file", "about", "copyformat", "print"],
  limitChars: 60000,
  showTooltip: true,
  uploader: {
    insertImageAsBase64URI: true,
    // url: "www.google.com"
  },
};

class CentralDiscussionEdit extends Component {
  state = {
    heading: "",
    textContent: "",
    spinner: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleTextEditor = (value) => {
    this.setState({ textContent: value });
  };

  componentDidMount() {
    if (this.props.post) {
      this.setState({
        heading: this.props.post.heading,
        textContent: this.props.post.textContent,
      });
    }
  }

  submitPost = () => {
    let data = {
      id: this.props.post.id,
      heading: this.state.heading,
      textContent: this.state.textContent,
    };

    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(
          URL.updatePostCore,
          data,
          (response) => {
            if (response.status === 200) {
              swal("Success", "Discussion post updated successfully.");
              this.props.toggleRepoEditModal();
              this.props.getDiscussionsByGroup();
              this.setState({
                spinner: false,
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  render() {
    return (
      <div>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="tt-article mt-2">
          <div className="row mb-1">
            <div className="col-md-1 mt-1 text-center">
              <strong>Heading</strong>
            </div>
            <div className="col-md-11">
              <input
                type="text"
                className="form-control"
                name="heading"
                value={this.state.heading}
                onChange={(e) => this.handleChange(e)}
              ></input>
            </div>
          </div>
          <div className="discussion-jodit">
            <JoditEditor
              onChange={(e) => this.handleTextEditor(e)}
              value={this.state.textContent}
              config={config}
            />
          </div>

          <div className="row manageTop mt-3">
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.submitPost}
                disabled={
                  this.props.post.heading === "" ||
                  this.props.post.textContent === ""
                }
              >
                Update Discussion
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CentralDiscussionEdit;
