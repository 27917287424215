import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "../../assets/scss/userDetail.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalWindow from "../UI/ModalWindow";

class MobileUserDetail extends Component {
  state = {
    moreModal: false,
    sectionName: localStorage.getItem("classroom"),
  };

  checkBirthday = (dateOfBirth) => {
    if (dateOfBirth) {
      let userDate = moment(dateOfBirth);
      let todayDate = moment();
      return (
        userDate.date() === todayDate.date() &&
        userDate.month() === todayDate.month()
      );
    }
  };

  displayMoreSubjects = () => {
    this.setState({ moreModal: !this.state.moreModal });
  };

  subjectAssignedUI = () => {
    return (
      <div>
        <ol>
          {this.props.userData
            ? this.props.userData.subjects
              ? this.props.userData.subjects.map((subject, idx) => {
                  return (
                    <li key={idx}>
                      {subject.name} -{" "}
                      {`${localStorage.getItem("classroom")} ${
                        subject.yearLevelName
                      }`}
                    </li>
                  );
                })
              : null
            : null}
        </ol>
      </div>
    );
  };

  render() {
    let data = this.props.userData;
    let groupName = this.props.groupPage ? this.props.groupData.name : "";
    return (
      <div className="container">
        <div className="tt-mobileUserDetailContainer">
          {this.props.familyPage ? (
            <div className="row">
              <div className="col">
                <p className="tt-mobileUserDetailTitle text-center">
                  {this.props.family
                    ? this.props.family.name
                    : "No family Assigned"}
                </p>
              </div>
            </div>
          ) : null}

          {this.props.groupPage ? (
            <div className="row">
              <div className="col text-center">
                <p className="tt-mobileUserDetailTitle">{groupName}</p>
              </div>
            </div>
          ) : null}

          {!this.props.familyPage &&
          !this.props.groupPage &&
          data.userRole === "Admin" ? (
            <div className="row">
              <div className="col text-center">
                <p className="tt-mobileUserDetailTitle">
                  {data.fullName} ({data.userRole})
                </p>
              </div>
            </div>
          ) : !this.props.familyPage &&
            !this.props.groupPage &&
            data.isStudent ? (
            <div className="row studentDetail">
              <div className="col">
                <p
                  className="tt-mobileUserDetailTitle"
                  style={{ textAlign: "left", fontWeight: "normal" }}
                >
                  {data.fullName} ({" "}
                  {data.yearLevelName
                    ? data.yearLevelName
                    : `No ${localStorage.getItem("yearLevel")} assigned`}{" "}
                  {data.classroomName
                    ? data.classroomName
                    : "No section assigned"}
                  )
                </p>
                {data.dateOfBirth ? (
                  <p
                    className="studentOtherDetail"
                    style={{ textAlign: "left", fontWeight: "normal" }}
                  >
                    {moment().diff(
                      data.dateOfBirth.substring(0, 10),
                      "years"
                    ) === 0
                      ? "1 years old"
                      : `${moment().diff(
                          data.dateOfBirth.substring(0, 10),
                          "years"
                        )} years old`}
                    {" (Date of Birth: "}
                    {moment(data.dateOfBirth).format("dddd, MMMM Do YYYY ")}
                    {")"}
                    {this.checkBirthday(data.dateOfBirth) ? (
                      <FontAwesomeIcon icon={faBirthdayCake}></FontAwesomeIcon>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  ""
                )}

                {data.parents && data.parents.length > 0 && (
                  <p
                    className="studentOtherDetail"
                    style={{ textAlign: "left", fontWeight: "normal" }}
                  >
                    Parents Name :{" "}
                    {data.parents
                      ? data.parents.length > 0
                        ? data.parents.map((parent, idx) => {
                            return (
                              <span key={idx}>
                                <span>
                                  {parent.name}
                                  {parent.relationship
                                    ? ` (${parent.relationship})`
                                    : ""}
                                </span>
                                <span>{", "}</span>
                              </span>
                            );
                          })
                        : "----"
                      : "----"}
                  </p>
                )}

                {data.siblings && data.siblings.length > 0 && (
                  <p
                    className="studentOtherDetail"
                    style={{ textAlign: "left", fontWeight: "normal" }}
                  >
                    Siblings Name :{" "}
                    {data.siblings
                      ? data.siblings.length > 0
                        ? data.siblings.map((sibling, idx) => {
                            return (
                              <span key={idx}>
                                <span>
                                  {sibling.name}
                                  {sibling.relationship
                                    ? ` (${sibling.relationship})`
                                    : ""}
                                </span>
                                <span>{", "}</span>
                              </span>
                            );
                          })
                        : "----"
                      : "----"}
                  </p>
                )}
              </div>
              <div className="col-md-auto familyPageMobileButton">
                {!this.props.familyPage &&
                !this.props.groupPage &&
                this.props.userData.isStudent ? (
                  <Link
                    to={{
                      pathname: `/familyPage/${this.props.userData.familyId}`,
                      familyPostFromProfile: true,
                    }}
                  >
                    <button className="tt-button tt-button-primary">
                      Family Page
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
          ) : !this.props.familyPage && !this.props.groupPage ? (
            <div className="row studentDetail">
              <div className="col">
                <p
                  className="tt-mobileUserDetailTitle"
                  style={{ textAlign: "left", fontWeight: "normal" }}
                >
                  {data.fullName}
                </p>
                {data.yearLevelName ? (
                  <p
                    className="homeRoomTeacher-mobile"
                    style={{ textAlign: "left", fontWeight: "normal" }}
                  >
                    <strong>Homeroom teacher of : </strong>{" "}
                    {data.yearLevelName
                      ? localStorage.getItem("yearLevel") +
                        " " +
                        data.yearLevelName
                      : `No ${localStorage.getItem("yearLevel")} assigned`}{" "}
                    -{" "}
                    {data.classroomName
                      ? localStorage.getItem("classroom") +
                        " " +
                        data.classroomName
                      : `No ${localStorage.getItem("classroom")} assigned`}
                  </p>
                ) : null}
                {data.isCoordinator ? (
                  <p
                    className="homeRoomTeacher-mobile"
                    style={{ textAlign: "left", fontWeight: "normal" }}
                  >
                    <strong>Coordinator of : </strong>{" "}
                    {data.coordinatorEducationLevelName
                      ? data.coordinatorEducationLevelName.length > 0
                        ? data.coordinatorEducationLevelName.map(
                            (coordinator, idx) => {
                              return (
                                <span key={idx}>
                                  <span>
                                    {coordinator.educationLevelName + " School"}
                                  </span>
                                  <span>{", "}</span>
                                </span>
                              );
                            }
                          )
                        : null
                      : null}
                  </p>
                ) : null}
                {data.isAdministrator ? (
                  <p
                    className="homeRoomTeacher-mobile"
                    style={{ textAlign: "left", fontWeight: "normal" }}
                  >
                    <strong>Administrator of : </strong>{" "}
                    {data.administratorEducationLevelName
                      ? data.administratorEducationLevelName.length > 0
                        ? data.administratorEducationLevelName.map(
                            (administrator, idx) => {
                              return (
                                <span key={idx}>
                                  <span>
                                    {administrator.educationLevelName +
                                      " School"}
                                  </span>
                                  <span>{", "}</span>
                                </span>
                              );
                            }
                          )
                        : null
                      : null}
                  </p>
                ) : null}

                <div className="userDetailChipBox mobileChipBox">
                  <span>
                    <div className="userDetailHolder">
                      <div
                        className="userDetailYearHolder displayMoreChips"
                        onClick={this.displayMoreSubjects}
                      >
                        Subjects Assigned
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <ModalWindow
          modal={this.state.moreModal}
          toggleModal={this.displayMoreSubjects}
          modalHeader="Subjects Assigned"
          modalBody={this.subjectAssignedUI()}
        ></ModalWindow>
      </div>
    );
  }
}

export default MobileUserDetail;
