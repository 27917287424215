import React, { Component } from "react";
import CustomModal from "../../components/common/CustomModal";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import CreateRelativeLinks from "./CreateRelativeLinks";
import ListRelativeLinks from "./ListRelativeLinks";

export class RelativeLinks extends Component {
  state = {
    modal: false,
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  getAllLinks = () => {
    axiosPost(URL.getAllRelativeLinks, {}, (response) => {
      if (response.status == 200) {
        this.setState({
          relativeLinks: response.data.data,
        });
      }
    });
  };

  componentDidMount() {
    this.getAllLinks();
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="whiteContainer">
                <CustomPageHeader
                  title="Relative Links"
                  onClick={this.toggleModal}
                  icon="add_box"
                  noPermission={true}
                />

                <ListRelativeLinks
                  relativeLinks={this.state.relativeLinks}
                  getAllLinks={this.getAllLinks}
                />
              </div>
            </div>
          </div>
          <CustomModal
            modal={this.state.modal}
            size="lg"
            toggleModal={this.toggleModal}
            modalHeader="Add Relative Links"
            modalBody={
              <>
                <CreateRelativeLinks
                  getAllLinks={this.getAllLinks}
                  toggleModal={this.toggleModal}
                />
              </>
            }
          />
        </div>
      </div>
    );
  }
}

export default RelativeLinks;
