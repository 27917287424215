/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, Facilites, is for rendering facilites section in the Facilities Tab,
 *              Academic Administration Widget
 */

import React, { Component } from "react";
//import FacilitiesList from "./FacilitiesList";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkWidgetAdminOrNot,
  checkRolePermissions,
} from "../../../../utils/Constants";
import {
  displaySuccessAlert,
  showErroMessage,
  displayConfirmDeleteAlert,
} from "../../../../utils/Utils";

import "../../../../assets/scss/facilities.scss";
import AddNewBuildingForm from "./AddNewBuildingForm";
import { connect } from "react-redux";
import { getClassroomLocations } from "./../../../../ducks/AcademicAdministrationDucks";
import swal from "@sweetalert/with-react";
import ListFacilities from "./ListFacilities";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";
import {
  MESSAGEHEADER,
  DISPLAYMESSAGE,
} from "../../../../utils/DisplayMessage";

class Facilities extends Component {
  state = {
    facilities: [],
    building: "",
    floors: "",
    buildingSection: "",
    numberOfFloors: [],
    wings: [],
    wing: "",
    rooms: [],
    Rooms: [],
    roomNumber: "",
    roomName: "",
    floor: "",
    section: "",
    details: "",
    noOfFloors: [],
    isAvailable: false,
    buildingDataForUpdate: [],
    updateBuildingName: "",
    updateBuildingSectionName: "",
    updateNoOfFloors: "",
    updatedId: "",
    buildingList: [],
    updatedSectionName: "",
    updatedSectionId: "",
    modal: false,
    roomList: [],
    updatedFloorName: "",
    updatedRoomNumber: "",
    updatedRoomName: "",
    updatedFloor: "",
    updatedSection: "",
    updatedDetails: "",
    errors: {
      building: false,
      floors: false,
      updateBuildingName: false,
      updateNoOfFloors: false,
      updatedRoomNumber: false,
      updatedRoomName: false,
      updatedFloor: false,
      updatedSection: false,
      roomNumber: false,
      roomName: false,
      floor: false,
      section: false,
      details: false,
    },
    modalForUpdate: false,
    updateBuilding: false,
    modalForFloor: false,
    modalForSection: false,
    suggestions: ["Block", "Wing"],
    buildingId: null,
    updateSectionName: false,
    modalForRoom: false,
    roomObject: {},
    modalRoomInsertForm: false,
    modalRoomUpdateForm: false,
    modalBuildingDetailModal: false,
    buildingDetailList: {},
    obj: {
      id: null,
      buildingId: null,
    },
    sectionModalName: "",
    showSpinner: false,
    isLoading: true,
  };

  componentDidMount() {
    this.getBuildingList();
  }

  getBuildingList = () => {
    axiosPost(
      URL.buildingSelect,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({ buildingList: response.data.data, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      },
      (err) => {
        this.setState({ isLoading: false });
      }
    );
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleCollapsible = (e, index, param) => {
    e.preventDefault();
    let buildingList = Object.assign([], this.state.buildingList);
    buildingList.forEach((building, i) => {
      if (index === i) {
        if (param === "Edit") {
          building.isUpdateOpen = !building.isUpdateOpen;
          building.isFloorOpen = false;
          building.isSectionOpen = false;
          building.isRoomOpen = false;
        } else if (param === "Floor") {
          building.isFloorOpen = !building.isFloorOpen;
          building.isUpdateOpen = false;
          building.isSectionOpen = false;
          building.isRoomOpen = false;
        } else if (param === "Section") {
          building.isSectionOpen = !building.isSectionOpen;
          building.isFloorOpen = false;
          building.isUpdateOpen = false;
          building.isRoomOpen = false;
        } else if (param === "Room") {
          building.isRoomOpen = !building.isRoomOpen;
          building.isFloorOpen = false;
          building.isUpdateOpen = false;
          building.isSectionOpen = false;
        }
      } else {
        building.isFloorOpen = false;
        building.isUpdateOpen = false;
        building.isSectionOpen = false;
        building.isRoomOpen = false;
      }
    });
    this.setState({ buildingList });
  };

  deleteBuildings = (id) => {
    displayConfirmDeleteAlert(id, this.handleRemoveBuilding);
  };

  handleRemoveBuilding = (id) => {
    let param = {
      id: id,
    };
    axiosPost(URL.buildingDelete, param, (response) => {
      displaySuccessAlert(response);
      this.getBuildingList();
    });
  };

  /**
   * @author Sandeep Shakya
   * Method to combine numberOfFloors array and rooms array to a single array with respect to the
   * floor name
   */
  checkData = () => {
    let numberOfFloors = [...this.state.numberOfFloors];
    let rooms = [...this.state.rooms];

    for (let i = 0; i < numberOfFloors.length; i++) {
      let arr = [];
      for (let j = 0; j < rooms.length; j++) {
        if (numberOfFloors[i]["floorName"] === rooms[j]["floor"]) {
          arr.push(rooms[j]);
        }
      }

      numberOfFloors[i]["data"] = arr;
    }
    this.setState({ numberOfFloors });
  };
  /**
   * @author Suyog Manandhar
   * This method generates a generic rooms array with respect to the floor number
   */
  getRoomsRenderArray = () => {
    let array1 = [];
    let array2 = [];
    this.state.noOfFloors.forEach((element) => {
      this.state.rooms.forEach((el) => {
        if (element.floorName === el.floor) {
          array1.push(el);
        }
      });
      if (array1.length > 0) {
        array2.push(array1);
        array1 = [];
      }
    });

    this.setState({
      Rooms: array2,
    });
  };

  getValuesForFloorView = (e, id) => {
    let param = {
      buildingId: id,
    };
    axiosPost(URL.floorSelect, param, (response) => {
      this.setState({ noOfFloors: response.data.data }, function () {
        this.setState({ isAvailable: true });
      });
    });
  };

  /**
   * @author Suyog Manandhar
   *
   * This method maps the states in the class with respective input fields with the corrsponding name
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === "floors") {
      if (value.includes("e") || value.includes("E")) {
      } else {
        this.setState(
          {
            [name]: value,
          },
          () => {
            this.validate(name);
          }
        );
      }
    } else if (name === "roomNumber") {
      var numbers = /^[-+]?[0-9]+$/;
      if (value === "" || value.match(numbers)) {
        this.setState(
          {
            [name]: value,
          },
          () => {
            this.validate(name);
          }
        );
      } else {
        swal({
          title: "Error",
          text: "Room number should only contain numbers",
          allowOutsideClick: false,
          closeOnOutsideClick: false,
        });
        draggable();
      }
    } else {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.validate(name);
        }
      );
    }
  };

  validate = (name) => {
    let newErrors = { ...this.state.errors };
    if (this.state[name].length > 0) {
      newErrors[name] = false;
    } else {
      newErrors[name] = true;
    }
    this.setState({ errors: newErrors });
  };

  getValuesForUpdate = (e, id, index) => {
    let param = {
      id: id,
    };
    axiosPost(URL.buildingSelectByKey, param, (response) => {
      this.setState({
        building: response.data.data["name"],
        buildingSection: response.data.data["buildingSectionName"],
        floors: response.data.data["noOfFloors"],
        updatedId: id,
      });
    });
  };

  /**
   * @author Suyog Manandhar
   *
   * This method handles Change for nested JSON Object i.e. facilities
   * @param e is the event
   * @param idx is the index of the corresponding input field
   * @param stateName is the name of the state input field binded with
   */
  handleNestedChange = (e, idx, stateName) => {
    let newState = [...this.state[stateName]];
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    newState[idx][name] = value;

    this.setState({
      [stateName]: newState,
    });
  };

  toggleSectionCancel = (e, idx) => {
    e.preventDefault();
    let newSections = [...this.state.wings];
    newSections[idx].isOpen = false;
    this.setState({
      wings: newSections,
    });
  };

  handleUpdateSubmit = (e) => {
    e.preventDefault();
    let stateName = ["building", "floors"];
    let errors = { ...this.state.errors };
    let emptyFields = [];
    errors.totalError = 0;
    stateName.forEach((name) => {
      if (this.state[name].length === 0) {
        errors[name] = true;
        errors.totalError = errors.totalError + 1;
        emptyFields.push(name);
      }
    });
    this.setState({ errors }, () => {
      if (this.state.errors.totalError === 0) {
        let param = {
          id: this.state.updatedId,
          name: this.state.building,
          buildingSectionName: this.state.buildingSection,
          noOfFloors: this.state.floors,
        };
        //update api call
        axiosPost(URL.buildingUpdate, param, (response) => {
          if (response.status === 200) {
            displaySuccessAlert(response);
            this.getBuildingList();
            this.toggleBuildingUpdateModal();
          }
        });
      } else {
        swal({
          title: "Error",
          text: `${this.state.errors.totalError} Empty Fields`,
          dangerMode: true,
        });
      }
    });
  };

  /**
   * @author Suyog Manandhar
   *
   * This methods add the new Facilities information into the facilities array
   */
  addFacilities = (e) => {
    e.preventDefault();
    const stateName = ["building", "floors"];
    let errors = { ...this.state.errors };
    let emptyFields = [];
    errors.totalError = 0;
    stateName.forEach((name) => {
      if (this.state[name].length === 0) {
        errors[name] = true;
        errors.totalError = errors.totalError + 1;
        emptyFields.push(name);
      }
    });
    this.setState({ errors }, () => {
      if (this.state.errors.totalError === 0) {
        let params = {
          name: this.state.building,
          buildingSectionName: this.state.buildingSection,
          noOfFloors: this.state.floors,
        };
        this.setState({ showSpinner: true }, function () {
          axiosPost(
            URL.buildingInsert,
            params,
            (response) => {
              if (response.status === 200) {
                displaySuccessAlert(response);
                this.setState({
                  building: "",
                  floors: "",
                  buildingSection: "",
                });
                this.getBuildingList();
                this.setState({ showSpinner: false });
              } else {
                showErroMessage(response);
              }
            },
            (error) => {
              this.setState({ showSpinner: false });
              let errorResponse = error.response ? error.response.data : error;
              if (errorResponse.status === 400) {
                //if condition to check spring boot validation errors
                let errorMessage = "";
                if (errorResponse.errors) {
                  errorResponse.errors.forEach((error) => {
                    errorMessage += `${error.field
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })} ${error.defaultMessage} \n`; //ishan
                  });
                  swal(errorResponse.error, errorMessage, "");
                } else {
                  swal(errorResponse.error, errorResponse.message, "");
                }
              } else {
                swal(
                  errorResponse.error || "Network Error",
                  errorResponse.message
                    ? errorResponse.message
                    : "Could Not Connect To The Server.",
                  ""
                );
              }
            }
          );
        });
      } else {
        swal({
          title: "Error",
          text: `${this.state.errors.totalError} Empty Fields`,
          dangerMode: true,
        });
        draggable();
      }
    });

    this.toggleModal();
  };

  /**
   * @author Suyog Manandhar
   *
   * This methods add the new Room information into the rooms array
   */
  addRoom = (e, buildingId) => {
    e.preventDefault();
    let buildingList = [...this.state.buildingList];
    let stateName = [];
    buildingList.forEach((building, i) => {
      if (parseInt(building.id) === parseInt(buildingId)) {
        if (building.buildingSectionName === "") {
          stateName = ["roomNumber", "roomName", "floor"];
        } else {
          stateName = ["roomNumber", "roomName", "floor", "section"];
        }
      }
    });
    let errors = { ...this.state.errors };
    let emptyFields = [];
    errors.totalError = 0;
    stateName.forEach((name) => {
      if (this.state[name].length === 0) {
        errors[name] = true;
        errors.totalError = errors.totalError + 1;
        emptyFields.push(name);
      }
    });
    this.setState({ errors: errors }, () => {
      if (this.state.errors.totalError === 0) {
        let param = {
          name: this.state.roomName,
          number: this.state.roomNumber,
          description: this.state.details,
          floorId: this.state.floor,
          buildingSectionId: this.state.section,
          buildingId: buildingId,
        };
        axiosPost(URL.roomInsert, param, (response) => {
          if (response.status === 200) {
            this.props.getClassroomLocations();
            displaySuccessAlert(response);
            this.setState({
              roomName: "",
              roomNumber: "",
              details: "",
              floor: "",
              section: "",
            });
            this.getRoomValues(e, buildingId);
            this.handleRoomInsertFormModal(e);
          } else {
            showErroMessage(response);
          }
        });
      } else {
        swal({
          title: "Error",
          text: `${this.state.errors.totalError} Empty Fields`,
          dangerMode: true,
        });
      }
    });
  };

  getSectionValues = (id, sectionName) => {
    this.setState({ wing: "" });
    if (sectionName) {
      this.setState({ sectionModalName: sectionName });
    }
    let param = {
      buildingId: id,
    };
    axiosPost(URL.buildingSectionSelect, param, (response) => {
      this.setState({ wings: response.data.data, buildingId: id }, () => {
        let wings = [...this.state.wings];
        wings.forEach((wing) => {
          wing.editable = false;
        });
        this.setState({ wings });
      });
    });
  };

  deleteSectionName = (e, sectionId, buildingId) => {
    let params = {
      sectionId: sectionId,
      buildingId: buildingId,
    };
    displayConfirmDeleteAlert(params, this.handleRemoveSection);
  };

  handleRemoveSection = ({ sectionId, buildingId }) => {
    let param = {
      id: sectionId,
    };
    axiosPost(URL.buildingSectionDelete, param, (response) => {
      displaySuccessAlert(response);
      this.getSectionValues(buildingId);
    });
  };

  getSectionValueForUpdate = (id) => {
    let param = {
      id: id,
    };

    axiosPost(URL.buildingSectionSelectByKey, param, (response) => {
      this.setState({ updatedSectionName: response.data.data["name"] });
    });
  };
  updateSectionSubmit = (e, buildingId) => {
    e.preventDefault();
    let param = {
      id: this.state.updatedSectionId,
      name: this.state.updatedSectionName,
    };
    if (param.name === "") {
      swal("Error", "Enter section name first and click update", "");
    } else {
      axiosPost(URL.buildingSectionUpdate, param, (response) => {
        if (response.status === 200) {
          displaySuccessAlert(response);
          this.getSectionValues(buildingId);
        } else {
          showErroMessage(response);
        }
      });
    }
  };

  updateBuildingSection = (e, id, index) => {
    this.getSectionValueForUpdate(id);
    this.setState({ updatedSectionId: id });
    let sectionList = Object.assign([], this.state.wings);
    sectionList.forEach((section, i) => {
      index === i
        ? (section.isOpen = !section.isOpen)
        : (section.isOpen = false);
    });
    this.setState({ wings: sectionList });
  };
  /**
   * @author Suyog Manandhar
   *
   * This methods add in a wing into the wings array
   */
  addWing = (e, buildingId) => {
    e.preventDefault();
    let param = {
      name: this.state.wing,
      buildingId: buildingId,
    };
    if (param.name === "") {
      swal("Error", "Enter Section First and Click Submit", "");
    } else {
      axiosPost(URL.buildingSectionInsert, param, (response) => {
        if (response.status === 200) {
          displaySuccessAlert(response);
          this.getSectionValues(buildingId);
          this.setState({ wing: "" });
        } else {
          showErroMessage(response);
        }
      });
    }
  };

  getRoomValues = (e, id) => {
    let param = {
      id: id,
    };
    this.getSectionValues(id);
    this.getValuesForFloorView(e, id);
    axiosPost(URL.roomFloorSelect, param, (response) => {
      this.setState({ roomList: response.data.data.floors });
    });
  };

  handleDeleteFloorClick = (e, id, buildingId) => {
    e.preventDefault();
    displayConfirmDeleteAlert({ e, id, buildingId }, this.handleRemoveFloor);
  };

  handleRemoveFloor = ({ e, id, buildingId }) => {
    let param = {
      id: id,
    };
    axiosPost(URL.floorDelete, param, (response) => {
      displaySuccessAlert(response);
      this.getBuildingList();
      this.getValuesForFloorView(e, buildingId);
    });
  };

  handleUpdateFloorClick = (e, id) => {
    let param = {
      id: id,
    };
    axiosPost(URL.floorSelectByKey, param, (response) => {
      this.setState({ updatedFloorName: response.data.data["name"] });
    });
  };

  toggleFloorUpdate = (e, index) => {
    e.preventDefault();
    let noOfFloors = Object.assign([], this.state.noOfFloors);
    noOfFloors.forEach((floor, i) => {
      index === i
        ? (floor.isOpenFloorUpdate = !floor.isOpenFloorUpdate)
        : (floor.isOpenFloorUpdate = false);
    });
    this.setState({ noOfFloors });
  };

  handleUpdateFloorSubmitClick = (e, floorId, buildingId) => {
    e.preventDefault();
    let param = {
      name: this.state.updatedFloorName,
      id: floorId,
    };
    axiosPost(URL.floorUpdate, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        this.getValuesForFloorView(buildingId);
      }
    });
  };

  handleUpdateRoomClick = (roomId, buildingId) => {
    let param = {
      id: roomId,
    };

    let obj = Object.assign({}, this.state.obj);
    obj.id = roomId;
    obj.buildingId = buildingId;
    this.setState({ obj });
    axiosPost(URL.roomSelectByKey, param, (response) => {
      if (response.status === 200) {
        this.setState({
          Rooms: response.data.data,
          roomNumber: response.data.data["number"],
          roomName: response.data.data["name"],
          floor: response.data.data["floorId"],

          section: response.data.data["buildingSectionId"]
            ? response.data.data["buildingSectionId"]
            : null,
          details: response.data.data["description"],
        });
        this.getValuesForFloorView("e", buildingId);
        this.getSectionValues(buildingId);
      }
    });
  };

  handleRoomUpdateSubmitClick = (e, id, buildingId) => {
    e.preventDefault();
    let param = {
      id: id,
      name: this.state.roomName,
      number: this.state.roomNumber,
      floorId: this.state.floor,
      buildingSectionId: this.state.section,
      description: this.state.details,
    };

    axiosPost(URL.roomUpdate, param, (response) => {
      displaySuccessAlert(response);
      this.getRoomValues(e, buildingId);
      this.handleRoomUpdateForm(e);
      this.handleBuildingDetailClick(buildingId);
    });
  };

  handledeleteRoom = (roomId, buildingId) => {
    let params = {
      roomId: roomId,
      buildingId: buildingId,
    };
    displayConfirmDeleteAlert(params, this.handleRemoveRoom);
  };

  handleRemoveRoom = ({ roomId, buildingId }) => {
    let param = { id: roomId };
    let id = buildingId;
    axiosPost(URL.roomDelete, param, (response) => {
      displaySuccessAlert(response);
      this.getRoomValues("e", id);
      this.handleBuildingDetailClick(buildingId);
    });
  };

  toggleBuildingUpdateModal = (e) => {
    this.setState({
      modalForUpdate: !this.state.modalForUpdate,
      building: "",
      buildingSection: "",
      floors: "",
      //updateBuilding: !this.state.updateBuilding
    });
  };

  handleFloorClick = (e) => {
    this.setState({
      modalForFloor: !this.state.modalForFloor,
    });
  };

  handleFloorChange = (e, idx) => {
    let noOfFloors = [...this.state.noOfFloors];
    let val = e.target.value;
    let name = e.target.name;
    noOfFloors[idx][name] = val;
    this.setState({ noOfFloors });
  };

  handleFloorUpdateClick = (e) => {
    let noOfFloors = [...this.state.noOfFloors];
    let newFloor = [];
    noOfFloors.forEach((floor) => {
      let floorObj = {};
      floorObj.id = floor.id;
      floorObj.name = floor.name;
      newFloor.push(floorObj);
    });

    axiosPost(URL.floorUpdate, newFloor, (response) => {
      if (response.status === 200) {
        swal({
          title: MESSAGEHEADER.success,
          text: DISPLAYMESSAGE.updateFloor,
          allowOutsideClick: false,
          closeOnOutsideClick: false,
        });
        draggable();
        this.handleFloorClick();
        this.getBuildingList();
      }
    });
  };

  handleDynamicFieldSuggestion = (e, suggestion) => {
    e.preventDefault();
    let suggestions = [...this.state.suggestions];
    suggestions.push(this.state.buildingSection);
    this.setState({ buildingSection: suggestion }, () => {
      suggestions.forEach((sugg, index) => {
        if (this.state.buildingSection === sugg) {
          // list.splice( list.indexOf('foo'), 1 );
          suggestions.splice(index, 1);
        }
      });
      this.setState({ suggestions });
    });
  };

  handleDynamicFieldSuggestionDisplay = (specificState, stateName) => {};

  handleSectionClick = (e, buildingId) => {};

  toggleSection = (e) => {
    this.setState({
      modalForSection: !this.state.modalForSection,
    });
  };

  handleSectionUpdate = (index) => {
    let wings = [...this.state.wings];
    wings.forEach((wing, i) => {
      if (index === i) {
        wing.editable = true;
        this.setState({
          updatedSectionName: wing.name,
          updatedSectionId: wing.id,
        });
      } else {
        wing.editable = false;
      }
    });
    this.setState({ wings }, () => {
      document.getElementById("section" + index).focus();
    });
  };

  handleCancelUpdate = (index) => {
    let wings = [...this.state.wings];
    wings.forEach((wing, i) => {
      if (index === i) {
        wing.editable = false;
      }
    });
    this.setState({ wings });
  };

  toggleRoom = (e, obj) => {
    this.setState({
      modalForRoom: !this.state.modalForRoom,
      roomObject: obj,
    });
  };

  handleRoomInsertFormModal = (e) => {
    e.preventDefault();
    this.setState({
      modalRoomInsertForm: !this.state.modalRoomInsertForm,
      roomNumber: "",
      roomName: "",
      floor: "",
      section: "",
      details: "",
      isAvailable: false,
    });
  };

  handleRoomUpdateForm = (e) => {
    e.preventDefault();
    this.setState({ modalRoomUpdateForm: !this.state.modalRoomUpdateForm });
  };

  handleBuildingDetailModal = (e) => {
    this.setState({
      modalBuildingDetailModal: !this.state.modalBuildingDetailModal,
    });
  };

  handleBuildingDetailClick = (buildingId, sectionName) => {
    let param = {
      buildingId: buildingId,
    };
    if (sectionName) {
      this.setState({ sectionModalName: sectionName });
    }

    axiosPost(URL.floorSectionRoom, param, (response) => {
      if (response.status === 200) {
        this.setState({ buildingDetailList: response.data.data });
      }
    });
  };

  handleGetOtherValues = (e, facility) => {
    this.setState({ roomObject: facility });
    this.getValuesForFloorView(e, facility.id);
    this.getSectionValues(facility.id);
  };
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          Facilities
          {checkWidgetAdminOrNot("Academic Administration") === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="tt-facilities-list">
          <ListFacilities
            buildingList={this.state.buildingList}
            handleBuildingUpdate={this.getValuesForUpdate}
            toggleBuildingUpdateModal={this.toggleBuildingUpdateModal}
            modalForUpdate={this.state.modalForUpdate}
            errors={this.state.errors}
            building={this.state.building}
            floors={this.state.floors}
            buildingSection={this.state.buildingSection}
            handleChange={this.handleChange}
            handleUpdateSubmit={this.handleUpdateSubmit}
            deleteBuildings={this.deleteBuildings}
            handleFloorClick={this.handleFloorClick}
            modalForFloor={this.state.modalForFloor}
            getValuesForFloorView={this.getValuesForFloorView}
            noOfFloors={this.state.noOfFloors}
            handleFloorChange={this.handleFloorChange}
            handleFloorUpdateClick={this.handleFloorUpdateClick}
            getSectionValues={this.getSectionValues}
            toggleSection={this.toggleSection}
            modalForSection={this.state.modalForSection}
            wings={this.state.wings}
            handleDeleteFloorClick={this.handleDeleteFloorClick}
            deleteSectionName={this.deleteSectionName}
            addWing={this.addWing}
            buildingId={this.state.buildingId}
            wing={this.state.wing}
            handleSectionUpdate={this.handleSectionUpdate}
            updatedSectionName={this.state.updatedSectionName}
            updateSectionSubmit={this.updateSectionSubmit}
            handleCancelUpdate={this.handleCancelUpdate}
            toggleRoom={this.toggleRoom}
            modalForRoom={this.state.modalForRoom}
            getRoomValues={this.getRoomValues}
            roomList={this.state.roomList}
            roomObject={this.state.roomObject}
            roomNumber={this.state.roomNumber}
            roomName={this.state.roomName}
            floor={this.state.floor}
            section={this.state.section}
            details={this.state.details}
            addRoom={this.addRoom}
            handleRoomInsertFormModal={this.handleRoomInsertFormModal}
            modalRoomInsertForm={this.state.modalRoomInsertForm}
            handledeleteRoom={this.handledeleteRoom}
            handleUpdateRoomClick={this.handleUpdateRoomClick}
            handleRoomUpdateForm={this.handleRoomUpdateForm}
            modalRoomUpdateForm={this.state.modalRoomUpdateForm}
            handleRoomUpdateSubmitClick={this.handleRoomUpdateSubmitClick}
            handleBuildingDetailModal={this.handleBuildingDetailModal}
            modalBuildingDetailModal={this.state.modalBuildingDetailModal}
            handleBuildingDetailClick={this.handleBuildingDetailClick}
            buildingDetailList={this.state.buildingDetailList}
            obj={this.state.obj}
            handleGetOtherValues={this.handleGetOtherValues}
            isAvailable={this.state.isAvailable}
            sectionModalName={this.state.sectionModalName}
            isLoading={this.state.isLoading}
          />
        </div>
        {checkRolePermissions("insert-facilities", "activity") ? (
          <div className="tt-building-insert-form">
            <div className="tt-group-header">Add New Building</div>
            <div className="col tt-add-new-building-form">
              <AddNewBuildingForm
                handleChange={this.handleChange}
                addFacilities={this.addFacilities}
                building={this.state.modalForUpdate ? "" : this.state.building}
                floors={this.state.modalForUpdate ? "" : this.state.floors}
                buildingSection={
                  this.state.modalForUpdate ? "" : this.state.buildingSection
                }
                errors={this.state.errors}
                suggestions={this.state.suggestions}
                handleDynamicFieldSuggestion={this.handleDynamicFieldSuggestion}
              />
            </div>
          </div>
        ) : null}

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School"
              header="View Facilities"
              activityName="select-facilities"
            />
          }
        ></ModalWindow>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Adding New Building ...
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapActionsToProps = {
  getClassroomLocations,
};

const mapStateToProps = (state, props) => ({
  classroomLocations: state.academicAdministration.classroomLocations,
});

export default connect(mapStateToProps, mapActionsToProps)(Facilities);
