import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, userRole, userRoleCode } from "../../utils/Constants";
import Navbar from "../Profile/Navbar";
import history from "../../utils/History";
import { displaySuccessAlert } from "../../utils/Utils";

class ModerationManager extends Component {
  state = {
    dropdownOpen: false,
    userRoles: [],
    userSubRoles: [],
    roleCode: null,
    subRoleId: null,
    moderatedModules: [
      { code: "prof-pic" },
      { code: "cov-pic" },
      { code: "post-section" },
      { code: "post-cmnt-section" },
    ],
    currentModeratedModules: [],
    modules: [],
  };

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  componentDidMount() {
    userRole !== userRoleCode.roleAdmin && history.push("/");
    this.getUserRoles();
    this.getAllModules();
  }

  getAllModules = () => {
    axiosPost(URL.getAllModules, {}, (response) => {
      if (response.status === 200) {
        this.setState({ modules: response.data.data });
      }
    });
  };

  getUserRoles = () => {
    axiosPost(URL.getUserRoles, {}, (response) => {
      if (response.status === 200) {
        this.setState({ userRoles: response.data.data });
      }
    });
  };

  getSubRoles = (roleCode) => {
    axiosPost(URL.getSubRolesByUserRole, { roleCode }, (response) => {
      if (response.status === 200) {
        this.setState({ userSubRoles: response.data.data });
      }
    });
  };

  handleUserRoleChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        roleCode: value,
        subRoleId: null,
      },
      () => {
        this.getSubRoles(this.state.roleCode);
      }
    );
  };

  handleSelectSubRole = (e) => {
    let value = e.target.value;
    this.setState(
      {
        subRoleId: value,
        moderatedModules: [
          { code: "prof-pic" },
          { code: "cov-pic" },
          { code: "post-section" },
          { code: "post-cmnt-section" },
        ],
        currentModeratedModules: [],
      },
      () => {
        this.getModerations();
      }
    );
  };

  getModerations = () => {
    const data = {
      subRoleId: this.state.subRoleId,
    };
    axiosPost(URL.getModerations, data, (response) => {
      if (response.status === 200) {
        const moderatedData = response.data.data;
        let moderatedModules = [...this.state.moderatedModules];
        moderatedModules.forEach((currMod) => {
          const module = this.state.modules.find(
            (module) => currMod.code === module.moduleCode
          );
          if (module) {
            currMod.moduleId = module.id;
            currMod.name = module.name;
            currMod.userRoleCode = this.state.roleCode;
            currMod.subRoleId = this.state.subRoleId;
            currMod.isModerated = false;
          }
        });
        this.setState({ currentModeratedModules: moderatedModules }, () => {
          let currentModeratedModules = [...this.state.currentModeratedModules];
          moderatedData.forEach((data) => {
            const index = currentModeratedModules.findIndex(
              (currMod) => currMod.moduleId === data.moduleId
            );
            if (index !== -1)
              currentModeratedModules[index].isModerated =
                data.isModerated || false;
          });
          this.setState({ currentModeratedModules });
        });
      }
    });
  };

  handleModerationCheckboxChange = (e, index) => {
    let currentModeratedModules = [...this.state.currentModeratedModules];
    currentModeratedModules[index].isModerated = e.target.checked;
    this.setState({ currentModeratedModules });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      userRoleCode: this.state.roleCode,
      subRoleId: this.state.subRoleId,
      moderations: this.state.currentModeratedModules,
    };
    axiosPost(URL.insertModeration, data, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response, () => {
          history.push(`${process.env.PUBLIC_URL}/moderation_manager`);
        });
      }
    });
  };

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
        />
        <div
          style={{ backgroundColor: "white", height: "88vh" }}
          className="container-fluid"
        >
          <form
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-3 mt-2">
                  <h4 className="text-center">User Roles</h4>
                  <hr className="horiPermission"></hr>
                  {this.state.userRoles.length > 0 ? (
                    <div className="row justify-content-center">
                      <select
                        className="permission-role-label"
                        value={this.state.roleCode || ""}
                        onChange={(e) => this.handleUserRoleChange(e)}
                      >
                        <option value="" disabled selected>
                          {"Select User Role"}
                        </option>
                        {this.state.userRoles.map((userRole) => (
                          <option value={userRole.code}>{userRole.name}</option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-3 mt-2">
                  <h4 className="text-center">User Sub Roles</h4>
                  <hr className="horiPermission"></hr>
                  {this.state.userSubRoles.length > 0 ? (
                    <div className="row justify-content-center">
                      <select
                        className="permission-role-label"
                        onChange={(e) => this.handleSelectSubRole(e)}
                        value={this.state.subRoleId || ""}
                      >
                        <option value="" disabled selected>
                          {"Select User Sub Role"}
                        </option>
                        {this.state.userSubRoles.map((userSubRole) => (
                          <option value={userSubRole.id}>
                            {userSubRole.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-3"></div>
              </div>
              {this.state.currentModeratedModules.length > 0 &&
              this.state.subRoleId ? (
                <>
                  <table className="table mt-4">
                    <thead className="tt-group-header permissionTable">
                      <tr>
                        <th
                          className="text-center"
                          width="10%"
                          style={{ backgroundColor: "#3f3f9e" }}
                        ></th>
                        <th width="90%" style={{ backgroundColor: "#3f3f9e" }}>
                          Modules
                        </th>
                      </tr>
                    </thead>
                    <tbody className="permissionTable">
                      {this.state.currentModeratedModules.map(
                        (currMod, index) => (
                          <tr>
                            <th width="10%" className="text-center">
                              <input
                                type="checkbox"
                                className="activity-checkbox"
                                checked={currMod.isModerated}
                                onChange={(e) =>
                                  this.handleModerationCheckboxChange(e, index)
                                }
                              />
                            </th>
                            <th width="90%">{currMod.name}</th>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="tt-button tt-button-primary"
                        style={{ marginLeft: "92px" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ModerationManager;
