import React, { Component } from "react";
import imagineLogo from "../../assets/images/logo/logo3.png";
import { URL } from "../../utils/Constants";
import NewCoverPicture from "./NewCoverPicture";
import NewProfilePicture from "./NewProfilePicture";
import NewUserDetail from "./NewUserDetail";
class NewProfileHeader extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 px-0 mobileGovDiv">
            <div className="position-relative">
              <div className="profileHeaderDiv" id="newProfileDiv">
                {this.props.minimize ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ marginBottom: "auto" }}
                  >
                    <div>
                      <img
                        src={
                          this.props.schoolInfos?.imageName != null
                            ? URL.imageSelectURL +
                              this.props.schoolInfos.imageName
                            : imagineLogo
                        }
                        className="profileNepalLogoMinimized"
                      />
                    </div>
                    {/* <div className="text-left ml-2">
                      <p className="profileSubtitleMin">Government of Nepal</p>
                      <p className="profileSubtitleMin">
                        Ministry of Education Science and Technology
                      </p>
                      <p className="profileMainTitleMin">
                        Center for Education and Human Resource Development
                      </p>
                    </div> */}
                  </div>
                ) : (
                  <>
                    <img
                      src={
                        this.props.schoolInfos?.imageName != null
                          ? URL.imageSelectURL +
                            this.props.schoolInfos.imageName
                          : imagineLogo
                      }
                      className="profileNepalLogo"
                    />
                    {/* <p className="profileSubtitle">Government of Nepal</p>
                    <p className="profileSubtitle">
                      Ministry of Education Science and Technology
                    </p>
                    <p className="profileMainTitle">
                      Center for Education and Human Resource Development
                    </p> */}
                  </>
                )}
              </div>
              {this.props.groupPage || this.props.familyPage ? null : (
                <NewProfilePicture
                  toggleHover={this.props.toggleHover}
                  fullName={this.props.fullName}
                  userId={this.props.userId}
                  profilePeopleId={this.props.profilePeopleId}
                  peopleId={this.props.peopleId}
                  otherProfile={this.props.otherProfile}
                  memberPhoto={this.props.memberPhoto}
                  isLegalGuardian={this.props.isLegalGuardian}
                />
              )}
            </div>
          </div>
          <div className="col-md-8 px-0 mobileGovDiv1">
            <NewCoverPicture
              notices={this.props.notices}
              toggleHover={this.props.toggleHover}
              pictures={this.props.pictures}
              userRole={this.props.userRole}
              minimize={this.props.minimize}
              userId={this.props.userId}
              profilePeopleId={this.props.profilePeopleId}
              peopleId={this.props.peopleId}
              loggedInUser={this.props.loggedInUser}
              userData={this.props.userData}
              isLegalGuardian={this.props.isLegalGuardian}
              isLegal={this.props.isLegal}
              username={this.props.username}
              groupName={this.props.groupName}
            />
            <div className="mobileProfilePic">
              {this.props.groupPage || this.props.familyPage ? null : (
                <NewProfilePicture
                  toggleHover={this.props.toggleHover}
                  fullName={this.props.fullName}
                  userId={this.props.userId}
                  profilePeopleId={this.props.profilePeopleId}
                  peopleId={this.props.peopleId}
                  otherProfile={this.props.otherProfile}
                  memberPhoto={this.props.memberPhoto}
                  isLegalGuardian={this.props.isLegalGuardian}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col px-0">
            {this.props.familyPage ? (
              <NewUserDetail
                familyPage={this.props.familyPage}
                family={this.props.family}
                minimize={this.props.minimize}
                minimizeCoverToggle={this.props.minimizeCoverToggle}
              ></NewUserDetail>
            ) : this.props.groupPage ? (
              <NewUserDetail
                groupData={this.props.groupData}
                minimize={this.props.minimize}
                groupPage={this.props.groupPage}
                minimizeCoverToggle={this.props.minimizeCoverToggle}
              ></NewUserDetail>
            ) : (
              <NewUserDetail
                userRole={this.props.userRole}
                userData={this.props.userData}
                minimize={this.props.minimize}
                profilePeopleId={this.props.profilePeopleId}
                fullName={this.props.fullName}
                minimizeCoverToggle={this.props.minimizeCoverToggle}
              ></NewUserDetail>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NewProfileHeader;
