import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { postType, URL } from "../../utils/Constants";
import NavBar from "../FrontPage/NavBar";
import ModalWindow from "../UI/ModalWindow";
import CentralDiscussionPostForm from "./CentralDiscussionPostForm";
import DiscussionTemplate from "../Profile/Templates/DiscussionTemplate";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { connect } from "react-redux";

class CentralDiscussion extends Component {
  state = {
    groups: [],
    groupId: "",
    discussionModal: false,
    currentRecordsLength: 0,
    totalGroupDiscussions: 0,
    groupDiscussions: [],
    page: 0,
  };

  componentDidMount() {
    this.getAllGroups();
  }

  getAllGroups = () => {
    axiosPost(URL.getCentralLoggeduserGroup, {}, (response) => {
      if (response.status === 200) {
        let groups = response.data.data;
        if (
          this.props.location.state?.groupId &&
          this.props.location.state?.groupId !== "" &&
          groups &&
          groups?.length > 0
        ) {
          const groupToFind = groups.find(
            (g) => g.id === parseInt(this.props.location.state?.groupId)
          );
          if (groupToFind) {
            groupToFind && this.setState({ groupId: groupToFind.id });
            this.getDiscussionsByGroup(groupToFind.id);
          }
        }
        this.setState({
          groups,
        });
      }
    });
  };

  getDiscussionsByGroup = (groupId) => {
    this.setState({ postSpinner: true }, () => {
      const param = {
        groupId: groupId || this.state.groupId,
        postType: postType.discussion,
        limitOne: false,
        // offset,
        // rowCountLimit,
      };
      // let page = this.state.page;
      // const pageSize = newState.posts.groupPostPageSize;
      // const offset = page * pageSize - pageSize;
      // const rowCountLimit = newState.posts.groupPostPageSize;

      axiosPost(
        URL.selectGroupPost,
        param,
        (response) => {
          if (response.status === 200) {
            let groupDiscussions = response.data.data;
            let totalGroupDiscussions = response.data.totalRecordsCount;
            let currentRecordsLength = groupDiscussions.length;
            this.setState({
              groupDiscussions,
              totalGroupDiscussions,
              currentRecordsLength,
              postSpinner: false,
            });
          } else {
            this.setState({
              postSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({
            postSpinner: false,
          });
        }
      );
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "groupId") {
      this.getDiscussionsByGroup(value);
    }
    this.setState({ [name]: value });
  };

  handleCreateDiscussion = () => {
    if (this.state.groupId !== "") {
      this.setState({ discussionModal: true });
    } else {
      swal("Error", "Please first select the group to create discussion.");
    }
  };

  toggleDiscussionModal = () => {
    this.setState({
      discussionModal: !this.state.discussionModal,
    });
  };

  render() {
    return (
      <>
        <NavBar
          noNav={true}
          teacherPage={this.props.location?.state?.teacherPage}
          classPage={this.props.location?.state?.classPage}
        />
        <section
          className="white-background"
          style={{
            padding: "3rem 0rem",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <h3 className="heading-03">
                  {this.props.lang === "nameEn" ? "Discussion" : "छलफल"}
                </h3>
              </div>
              <div className="col-md-9">
                {/* <div className="horizontal-line mb-3"> */}
                <select
                  name="groupId"
                  value={this.state.groupId}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Choose a group
                  </option>
                  {this.state.groups
                    ? this.state.groups.map((g, idx) => {
                        let val = `${g.id}`;
                        return (
                          <option key={idx} value={val}>
                            {g.name}
                          </option>
                        );
                      })
                    : null}
                </select>
                {/* </div>d */}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize float-right"
                  onClick={this.handleCreateDiscussion}
                >
                  Create a Discussion
                </button>
              </div>
            </div>
            <div className="row mt-2">
              {this.state.groupDiscussions ? (
                this.state.groupDiscussions.length > 0 ? (
                  this.state.groupDiscussions.map((post, pidx) => {
                    if (post?.posts[0]?.postType === postType.discussion) {
                      return (
                        <div className="col-md-6 p-3">
                          <div
                            className={"tt-post-template alternateBg"}
                            key={pidx}
                          >
                            <DiscussionTemplate
                              post={post.posts[0]}
                              key={pidx}
                              central={true}
                              // handleCurrentPost={this.props.handleCurrentPost}
                              getDiscussionsByGroup={this.getDiscussionsByGroup}
                              groupId={this.state.groupId}
                            />
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div className="col text-center mt-3 mb-3">
                    {this.state.postSpinner ? (
                      <>
                        <Spinner color="primary"></Spinner>
                        <br></br>
                        Loading Data ...
                      </>
                    ) : (
                      <p>No discussion posts to display</p>
                    )}
                  </div>
                )
              ) : (
                <div className="col text-center">
                  <p>No discussion posts to display</p>
                </div>
              )}
            </div>
          </div>
        </section>

        <ModalWindow
          modal={this.state.discussionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={this.toggleDiscussionModal}
          modalHeader={"Create a Discussion Post"}
          modalBody={
            <CentralDiscussionPostForm
              groupId={this.state.groupId}
              groupPost={true}
              toggleModal={this.toggleDiscussionModal}
              getDiscussionsByGroup={this.getDiscussionsByGroup}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(CentralDiscussion);
