import React, { Component } from "react";
import Switch from "@material-ui/core/Switch";
import CourseClassResources from "../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseResourceTemplate";
import SubmissionDetails from "./SubmissionDetails";
import FileModal from "../ViewFile/FileModal";

export default class AssignmentDetails extends Component {
  state = {
    showAssignmentDetails: true,
    selectedResource: [],
    resourceModal: false,
  };

  handleSwitchChange = () => {
    this.setState({ showAssignmentDetails: !this.state.showAssignmentDetails });
    //call list api
  };

  render() {
    return (
      <>
        <div>
          <div className="text-right mr-4">
            Assignment Dropbox
            <Switch
              checked={this.state.showAssignmentDetails}
              onClick={this.handleSwitchChange}
            />
            Assignment Details
          </div>

          {/* <div className="tt-group-header"> {this.props.assignment.title}</div> */}

          <div className="row m-2 mt-2">
            <div className="col-md-2 text-left">
              <strong>Title: </strong>
            </div>
            <div className="col-md-10">{this.props.assignment.title}</div>
          </div>

          {this.state.showAssignmentDetails ? (
            <>
              <div className="row m-2 mt-2">
                <div className="col-md-2 text-left">
                  <strong>Description: </strong>
                </div>
                <div className="col-md-10 assignmentDescription">
                  {this.props.assignment.description
                    ? this.props.assignment.description
                    : "No description/instructions"}
                </div>
              </div>
              <div className="row m-2 mt-2">
                <div className="col-md-2 text-left">
                  <strong>Assigned To: </strong>
                </div>
                <div className="col-md-2 ">
                  {this.props.assignment.forWholeClass
                    ? "All Students"
                    : this.props.assignment.totalStudents > 1
                    ? this.props.assignment.totalStudents + " students"
                    : this.props.assignment.totalStudents + " student"}
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-2 text-left ">
                  <strong>Assigned By: </strong>
                </div>
                <div className="col-md-3 ">
                  {this.props.assignment.creatorPeopleName}
                </div>
              </div>
              <div className="mt-2">
                {/* <div className="col-md-2 text-left">
                  <strong>Reference Links: </strong>
                </div> */}

                {this.props.assignment.assignmentResources.length > 0 ? (
                  <>
                    <div className="mt-2 m-2 text-left">
                      <CourseClassResources
                        selectedResources={
                          this.props.assignment.assignmentResources
                        }
                        assignmentStudent={this.props.assignment}
                      ></CourseClassResources>
                    </div>
                  </>
                ) : (
                  <div className="mt-2 text-left">No resources</div>
                )}
                {/* {this.props.assignment.assignmentResources.length > 0 ? (
                  <FileModal
                    files={this.props.assignment.assignmentResources}
                    isNotice={false}
                  ></FileModal>
                ) : (
                  <div className="col-md-8 mt-2 text-left">No resources</div>
                )} */}
              </div>
            </>
          ) : (
            <>
              <SubmissionDetails
                assignment={this.props.assignment}
                assignmentStudent={this.props.assignmentStudent}
                propFromStudentInformation={this.props.propFromStudent}
                handleSubmissionModal={this.props.handleSubmissionModal}
                handleViewSubmittedResources={
                  this.props.handleViewSubmittedResources
                }
              />
            </>
          )}
        </div>
      </>
    );
  }
}
