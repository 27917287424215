import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import CustomSpinner from "../../components/common/CustomSpinner";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class EventTypeCreationModal extends Component {
  state = {
    eventTypeName: "",
    eventColor: "#000000",
    isHoliday: false,
    submitSpinner: false,
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    let param = {
      colorCode: this.state.eventColor,
      eventTypeName: this.state.eventTypeName,
      isHoliday: this.state.isHoliday,
    };

    let url = this.props.isEdit
      ? URL.updateCalenderEventType
      : URL.insertCalenderEventTypeCore;

    if (this.state.eventTypeName !== "") {
      this.setState({ submitSpinner: true }, function () {
        axiosPost(
          url,
          param,
          (response) => {
            this.setState({ submitSpinner: false });
            if (response.status === 200) {
              if (this.props.isEdit) {
                swal("Success", "Event type updated successfully");
              } else {
                swal("Success", "Event type created successfully");
              }
              this.props.getEventTypes();
              this.props.toggleEventTypeModal();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              submitSpinner: false,
            });
          }
        );
      });
    } else {
      swal("Error", "Enter event type name");
      this.setState({
        submitSpinner: false,
      });
    }
  };

  componentDidMount() {
    if (this.props.editedEventTypeData) {
      this.setState({
        eventTypeName: this.props.editedEventTypeData.eventTypeName,
        eventColor: this.props.editedEventTypeData.colorCode,
        isHoliday: this.props.editedEventTypeData.isHoliday,
      });
    }
  }

  render() {
    return (
      <div className="container">
        {this.state.submitSpinner ? <CustomSpinner></CustomSpinner> : null}
        <div className="row">
          <div className="col-md-3">
            <p className="formTitle">Event Type</p>
          </div>
          <div className="col">
            <input
              type="text"
              name="eventTypeName"
              value={this.state.eventTypeName}
              onChange={this.handleChange}
              className="form-control cusInput"
              placeholder="Enter Event Type Name"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p className="formTitle">Color Code</p>
          </div>
          <div className="col">
            <input
              type="color"
              name="eventColor"
              value={this.state.eventColor}
              onChange={this.handleChange}
              className="form-control form-control-color"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <p className="formTitle">Is Holiday</p>
          </div>
          <div className="col mt-1">
            <input
              type="checkbox"
              name="isHoliday"
              checked={this.state.isHoliday}
              onChange={this.handleChange}
              className="form-check-input"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default EventTypeCreationModal;
