import React, { Component } from "react";
import CustomPieChart from "../../components/common/CustomPieChart";
import { postType, URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import ModalWindow from "../UI/ModalWindow";
import CentralVideoConference from "../VideoConferencing/CentralVideoConference";
import SchoolEnableDisable from "./SchoolEnableDisable";

class DashboardSchoolSection extends Component {
  state = {
    remarkModal: false,
  };

  handleRemarksModal = (selectedSchool) => {
    this.setState({
      remarkModal: !this.state.remarkModal,
    });
  };

  closeRemarksModal = () => {
    this.setState({ remarkModal: false });
  };

  render() {
    return (
      <div className="whiteContainer mb-4">
        <div className="listBox">
          <select
            className="form-control cusInputSelect"
            defaultValue=""
            value={this.props.selectedSchoolId}
            onChange={this.props.handleChange}
          >
            <option value="" disabled>
              Select School
            </option>
            <option value="all">All Schools</option>
            {this.props.schools.length > 0 ? (
              this.props.schools.map((school, idx) => {
                return (
                  <option value={school.id} key={idx}>
                    {school.name}
                  </option>
                );
              })
            ) : (
              <option value="" disabled>
                No schools added
              </option>
            )}
          </select>
        </div>
        {/* {this.props.selectedSchool ? ( */}
        <div className="">
          <div className="row">
            <div className="col">
              {this.props.schoolChartData ? (
                <CustomPieChart chartData={this.props.schoolChartData} />
              ) : null}
              {this.props.selectedSchool ? (
                <div className="">
                  {this.props.selectedSchool.imagePath ? (
                    <div className="dashboarLogoDiv">
                      <img
                        src={
                          URL.imageSelectURL +
                          this.props.selectedSchool.imagePath
                        }
                        className="dashboardSchoolLogo"
                      />
                    </div>
                  ) : null}
                  <hr className="cardSeparator"></hr>
                  <div className="text-center contentTitle">
                    {this.props.selectedSchool.name}
                  </div>
                  <div className="container p-3">
                    {this.props.selectedSchool.iemisCode ? (
                      <div className="newText">
                        School Code : {this.props.selectedSchool.iemisCode}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.countryFirstLevelName ? (
                      <div className="newText">
                        Province :{" "}
                        {this.props.selectedSchool.countryFirstLevelName}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.countrySecondLevelName ? (
                      <div className="newText">
                        District :{" "}
                        {this.props.selectedSchool.countrySecondLevelName}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.countryThirdLevelName ? (
                      <div className="newText">
                        City : {this.props.selectedSchool.countryThirdLevelName}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.address ? (
                      <div className="newText">
                        Address : {this.props.selectedSchool.address}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.website ? (
                      <div className="newText">
                        Website : {this.props.selectedSchool.website}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.emailAddress ? (
                      <div className="newText">
                        Contact Email : {this.props.selectedSchool.emailAddress}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.contactMobile1 ||
                    this.props.selectedSchool.contactMobile2 ? (
                      <div className="newText">
                        Contact Number :{" "}
                        {this.props.selectedSchool.contactMobile1}
                        {" ,"}
                        {this.props.selectedSchool.contactMobile2}
                      </div>
                    ) : null}
                    {/* <div className="row mt-3">
                      <div className="col text-right">
                        <button
                          className="btn btn-primary cusBtn mr-2"
                          style={{ fontSize: "12px" }}
                          onClick={() =>
                            this.handleRemarksModal(this.props.selectedSchool)
                          }
                        >
                          Remarks
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="col-md-6 dashboardSchoolLogoHolder">
              {this.props.selectedSchool ? (
                <div className="">
                  {this.props.selectedSchool.imagePath ? (
                    <div className="dashboarLogoDiv">
                      <img
                        src={
                          URL.imageSelectURL +
                          this.props.selectedSchool.imagePath
                        }
                        className="dashboardSchoolLogo"
                      />
                    </div>
                  ) : null}
                  <hr className="cardSeparator"></hr>
                  <div className="text-center contentTitle">
                    {this.props.selectedSchool.name}
                  </div>
                  <div className="container p-3">
                    {this.props.selectedSchool.iemisCode ? (
                      <div className="newText">
                        School Code : {this.props.selectedSchool.iemisCode}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.countryFirstLevelName ? (
                      <div className="newText">
                        Province :{" "}
                        {this.props.selectedSchool.countryFirstLevelName}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.countrySecondLevelName ? (
                      <div className="newText">
                        District :{" "}
                        {this.props.selectedSchool.countrySecondLevelName}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.countryThirdLevelName ? (
                      <div className="newText">
                        City : {this.props.selectedSchool.countryThirdLevelName}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.address ? (
                      <div className="newText">
                        Address : {this.props.selectedSchool.address}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.website ? (
                      <div className="newText">
                        Website : {this.props.selectedSchool.website}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.emailAddress ? (
                      <div className="newText">
                        Contact Email : {this.props.selectedSchool.emailAddress}
                      </div>
                    ) : null}
                    {this.props.selectedSchool.contactMobile1 ||
                    this.props.selectedSchool.contactMobile2 ? (
                      <div className="newText">
                        Contact Number :{" "}
                        {this.props.selectedSchool.contactMobile1}
                        {" ,"}
                        {this.props.selectedSchool.contactMobile2}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div> */}
          </div>
        </div>
        <ModalWindow
          modal={this.state.remarkModal}
          toggleModal={this.closeRemarksModal}
          modalHeader="School Enable Disable History"
          size="lg"
          modalBody={<SchoolEnableDisable />}
        />
      </div>
    );
  }
}

export default DashboardSchoolSection;
