import React, { useEffect } from "react";
import { Chart } from "chart.js";
const data = [
  {
    isPresent: true,
  },
  { isPresent: true },
  {
    isPresent: false,
  },
  { isPresent: true },
];

const assignment = [
  {
    isSubmitted: true,
  },
  { isSubmitted: true },
  {
    isSubmitted: false,
  },
  { isSubmitted: true },
  {
    isSubmitted: false,
  },
  {
    isSubmitted: false,
  },
  {
    isSubmitted: true,
  },
];

const report = [
  {
    isPassed: true,
  },
  { isPassed: true },
  {
    isPassed: true,
  },
  { isPassed: true },
  {
    isPassed: false,
  },
  {
    isPassed: false,
  },
  {
    isPassed: true,
  },
];

function StudentPerformanceChart(props) {
  const displayAttendanceChart = () => {
    const aChart = document.getElementById("attendanceChart");
    const aChart1 = document.getElementById("attendanceChart1");
    const aChart2 = document.getElementById("attendanceChart2");

    renderChart(aChart, data);
    renderAssignmentChart(aChart1, assignment);
    renderReportCard(aChart2, report);
  };

  const renderAssignmentChart = (ctx, assignmentRecord) => {
    const totalDays = assignmentRecord.length;
    const presentDays = assignmentRecord.filter(
      (rec) => rec.isSubmitted
    ).length;
    const absentDays = totalDays - presentDays;
    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Submitted Assingment", "Pending Assignment"],
        datasets: [
          {
            label: "Assignment",
            data: [presentDays, absentDays],
            backgroundColor: ["#36aeea", "#f4af43"],
            borderColor: ["#36aeea", "#f4af43"],
            borderWidth: 1,
          },
        ],
      },
    });
  };

  const renderChart = (ctx, attendanceRecord) => {
    const totalDays = attendanceRecord.length;
    const presentDays = attendanceRecord.filter((rec) => rec.isPresent).length;
    const absentDays = totalDays - presentDays;
    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Present Days", "Absent Days"],
        datasets: [
          {
            label: "Attendance",
            data: [presentDays, absentDays],
            backgroundColor: ["#4878ae", "#b54220"],
            borderColor: ["#4878ae", "#b54220"],
            borderWidth: 1,
          },
        ],
      },
    });
  };

  const renderReportCard = (ctx, reportRecord) => {
    const totalDays = reportRecord.length;
    const presentDays = reportRecord.filter((rec) => rec.isPassed).length;
    const absentDays = totalDays - presentDays;
    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Passed", "Failed"],
        datasets: [
          {
            label: "Report Card",
            data: [presentDays, absentDays],
            backgroundColor: ["#6dc9ca", "#36aeea"],
            borderColor: ["#6dc9ca", "#36aeea"],
            borderWidth: 1,
          },
        ],
      },
    });
  };

  useEffect(() => {
    displayAttendanceChart();
  }, []);

  return (
    <div className="performanceChartDiv">
      <div className="performanceChartHolder">
        <div className="tt-group-header">Student Performance Report</div>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-auto"></div>
            <div className="col-md-3 position-relative">
              <canvas id="attendanceChart" width="100" height="100" />
              <div className="attLabel">Attendance</div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 position-relative">
              <canvas id="attendanceChart1" width="100" height="100" />
              <div className="attLabel">Assignment</div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 position-relative">
              <canvas id="attendanceChart2" width="100" height="100" />
              <div className="attLabel">Report Card</div>
            </div>
            <div className="col-md-auto"></div>
          </div>
          <div className="row mt-5">
            <div className="col text-center">
              <p className="overallAch">Overall Achievement</p>
              <input
                type="range"
                id="vol"
                name="vol"
                min="0"
                max="100"
                value="64"
                // disabled
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentPerformanceChart;
