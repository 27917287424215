import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import smallLogo from "../../assets/images/logo/logo3.png";
import history from "../../utils/History";
import SidebarFooterText from "../FrontPage/SidebarFooterText";

function SideNav(props) {
  const { collapseSidebar, collapsed } = useProSidebar();

  const handleLogOut = () => {
    localStorage.clear();
    history.push("/login");
  };

  const renderLanguage = (m) => {
    return localStorage.getItem("lang") === "nameNp" ? m.nameNp : m.name;
  };
  return (
    <React.Fragment>
      <div
        className="mobileSidebar"
        onClick={props.handleMobileSidebar}
        id="mobileSidebar"
      >
        Sidebar Menu
      </div>
      <Sidebar className="sidebarCss">
        <div
          className={
            "sidebarHeader " + (collapsed ? "sidebarCollapsedHeader" : "")
          }
          onClick={() => collapseSidebar()}
        >
          {collapsed ? (
            <img src={smallLogo} />
          ) : (
            <h6 className="sidebarHeaderText">CEHRD Dashboard</h6>
          )}
        </div>
        <hr className="sidebarSeparator"></hr>
        <Menu>
          {props.menus.length > 0
            ? props.menus.map((menu, idx) => {
                if (menu.subMenus && menu.subMenus.length > 0) {
                  return (
                    <SubMenu
                      key={idx}
                      label={renderLanguage(menu)}
                      icon={
                        <span
                          className="material-icons-round sidebarIcon"
                          aria-hidden="true"
                        >
                          {menu.iconName}
                        </span>
                      }
                      className="sidebarSubmenu"
                    >
                      {menu.subMenus
                        .filter((sM) => sM.display)
                        .map((subMenu, sIdx) => {
                          return (
                            <MenuItem
                              key={sIdx}
                              icon={
                                subMenu.iconName ? (
                                  <span
                                    className="material-icons-round sidebarIcon"
                                    aria-hidden="true"
                                  >
                                    {subMenu.iconName}
                                  </span>
                                ) : null
                              }
                              active={window.location.pathname === subMenu.link}
                              component={<Link to={subMenu.link} />}
                              onClick={() => props.handleClick(subMenu, idx)}
                            >
                              {renderLanguage(subMenu)}
                            </MenuItem>
                          );
                        })}
                    </SubMenu>
                  );
                } else {
                  return (
                    <MenuItem
                      key={idx}
                      icon={
                        <span
                          className="material-icons-round sidebarIcon"
                          aria-hidden="true"
                        >
                          {menu.iconName}
                        </span>
                      }
                      active={window.location.pathname === menu.link}
                      onClick={
                        menu.name === "Log Out"
                          ? handleLogOut
                          : () => props.handleClick(menu)
                      }
                      component={
                        menu.name === "Log Out" ? null : <Link to={menu.link} />
                      }
                    >
                      {renderLanguage(menu)}
                    </MenuItem>
                  );
                }
              })
            : null}
        </Menu>
        <div>
          <SidebarFooterText />
        </div>
      </Sidebar>
    </React.Fragment>
  );
}

export default SideNav;
