import React, { Component } from "react";
import staticFileImg from "../../assets/images/pdficon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faPlus } from "@fortawesome/free-solid-svg-icons";

class AddResourcesFile extends Component {
  render() {
    let plannerList = this.props.plannerList,
      activePL = this.props.activePL;
    return (
      <div>
        <div className="mt-2 mb-2">
          <b>{this.props.filePreviewModal?.image?.name}</b>
        </div>

        <div className="tt-coverPicPreview">
          {this.props.filePreviewModal !== "" ? (
            // <img src={staticFileImg} alt="upCp"></img>
            <img
              src={
                this.props.filePreviewModal.image.type === "application/pdf"
                  ? staticFileImg
                  : this.props.filePreviewModal.src
              }
              alt="upCp"
              style={{ objectFit: "contain" }}
            ></img>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <span>No File Selected</span>
            </div>
          )}
        </div>

        <div>
          <div className="tt-profileImages">
            {plannerList[activePL].resources.length > 0 ? (
              <>
                {plannerList[activePL].resources.map((image, idx) => {
                  return (
                    <div key={idx}>
                      <div
                        id={`coverPicture-` + idx}
                        className="tt-profilePictureHolder coverThumbnail"
                        style={{ padding: 0 }}
                        onClick={(e) => this.props.filePreview(e, image, idx)}
                      >
                        <img
                          src={
                            image.image.type === "application/pdf"
                              ? staticFileImg
                              : image.src
                          }
                          alt="coverForm"
                        ></img>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="tt-fileDelete"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.handleRemoveResourceFile(idx, image);
                          }}
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="tt-profilePictureHolder" style={{ padding: 0 }}>
                No File
              </div>
            )}
            <div>
              <input
                type="file"
                multiple
                className="form-control"
                name="resources"
                onChange={(e) =>
                  this.props.handleChangeResourceFile(e, this.props.activePL)
                }
                ref={(ref) => (this.upload = ref)}
                style={{ display: "none" }}
                accept="image/png, image/jpeg, application/pdf"
              />
              <div
                id="addImage"
                className="tt-profilePictureHolder"
                style={{ padding: 0, cursor: "pointer", lineHeight: "7.5" }}
                onClick={() => {
                  this.upload.click();
                }}
              >
                <FontAwesomeIcon icon={faPlus} size="2x"></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div>

        {/* {plannerList[activePL].resources.length > 0 ? (
          <div className="row text-right">
            <div className="col">
              <button
                className="tt-button tt-button-primary mt-2"
                onClick={() => {
                  this.upload.click();
                }}
              >
                Add Files
              </button>
            </div>
          </div>
        ) : null}
        <input
          type="file"
          multiple
          className="form-control"
          name="resources"
          onChange={(e) =>
            this.props.handleChangeResourceFile(e, this.props.activePL)
          }
          ref={(ref) => (this.upload = ref)}
          style={{ display: "none" }}
          accept="image/png, image/jpeg, application/pdf"
        /> */}

        {/* <div className="row mt-2">
          <div className="col">
            <div className="resourceFileDiv">
              {plannerList[activePL].resources.length > 0 ? (
                plannerList[activePL].resources.map((image, idx) => {
                  return (
                    <div
                      key={idx}
                      className="resourceFileHolder"
                      //   onMouseEnter={() => this.displayRemoveButton(idx)}
                      //   onMouseLeave={() => this.hideRemoveBtn(idx)}
                    >
                      <div className="contentHolder">
                        <div className="insideImageHolder" id={"holder" + idx}>
                          <img
                            src={
                              image.image.type === "application/pdf"
                                ? staticFileImg
                                : image.src
                            }
                          />
                        </div>
                        <div className="d-flex flex-direction-row">
                          <span className="elipsisName">
                            {image.image.name}
                          </span>
                          <span>
                            {" "}
                            {image.image.type === "application/pdf"
                              ? image.image.name.split(".").pop()
                              : ""}
                          </span>
                        </div>

                        <button
                          className="tt-button tt-button-danger"
                          id={idx}
                          onClick={() =>
                            this.props.handleRemoveResourceFile(idx)
                          }
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="p-3">
                  No Files Added
                  <br />
                  <button
                    className="tt-button tt-button-primary mt-2"
                    onClick={() => {
                      this.upload.click();
                    }}
                  >
                    Add Files
                  </button>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default AddResourcesFile;
