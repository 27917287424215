import { fontWeight } from "@mui/system";
import React, { Component } from "react";
import { questionType } from "../../utils/Constants";

class AssessmentQuestions extends Component {
  state = {};

  calcualteCorrectObjectiveAns = () => {};

  render() {
    let { practicalQuestions, theoryQuestions } = this.props;
    return (
      <div className="container-fluid">
        <>
          {theoryQuestions && theoryQuestions.length > 0 ? (
            <div className="">
              <div className="row">
                <div className="col">
                  <div className="row p-2">
                    <div className="col">
                      {/* <div className="tt-group-header">Theory</div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col p-4">
                  {theoryQuestions &&
                    theoryQuestions.map((q, idx) => (
                      <>
                        <div className="row">
                          <div className="col-md-1">
                            <span>
                              <strong>{`${idx + 1})`}&nbsp;</strong>
                            </span>
                          </div>
                          <div className="col-md-7">{q.question}</div>
                          <div className="col-md-2">
                            <label>Marks weight:</label>
                            <input
                              className="ml-1 form-control"
                              type="number"
                              name="markWight"
                              value={q?.markWeight}
                              disabled
                            />
                          </div>
                          <div className="col-md-2">
                            <label>Obtained:</label>
                            <input
                              className="ml-1 form-control"
                              type="number"
                              name="obtainedMarks"
                              value={q?.obtainedMarks || q.answer.obtainedMarks}
                              disabled={q.answer.isChecked ? true : false}
                              onChange={(e) =>
                                this.props.handleMarksChange(
                                  e,
                                  "theoryQuestions",
                                  idx
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {q.questionType === questionType.SUBJECTIVE ? (
                              <>
                                <div className="row mt-2 mb-2" key={idx}>
                                  <div className="col-md-3"></div>
                                  <div className="col-md-7">
                                    <textarea
                                      className="form-control"
                                      rows={4}
                                      name="subjectiveAnswer"
                                      value={q.answer?.subjectiveAnswer}
                                      onChange={(e) =>
                                        this.props.handleMarksChange(
                                          e,
                                          "theoryQuestions",
                                          idx
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <label>Remarks</label>
                                    <textarea
                                      className="form-control"
                                      name="remarks"
                                      value={q.remarks || q.answer.remarks}
                                      disabled={
                                        q.answer.isChecked ? true : false
                                      }
                                      onChange={(e) =>
                                        this.props.handleMarksChange(
                                          e,
                                          "theoryQuestions",
                                          idx
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            ) : q.questionType === questionType.OBJECTIVE ? (
                              <>
                                {q.examQuestionOptions &&
                                  q.examQuestionOptions.length > 0 &&
                                  q.examQuestionOptions.map((opt, oIdx) => (
                                    <>
                                      <div className="row mt-2 mb-2" key={oIdx}>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-7">
                                          <div className="row">
                                            <div className="col-md-2 text-center">
                                              <input
                                                type="checkbox"
                                                name="ticked"
                                                checked={
                                                  q?.answer
                                                    ?.examQuestionOptionId ===
                                                  opt.id
                                                }
                                                style={{
                                                  position: "relative",
                                                  top: "8px",
                                                }}
                                                onClick={(e) =>
                                                  this.props.handleMarksChange(
                                                    e,
                                                    "theoryQuestions",
                                                    idx
                                                  )
                                                }
                                              ></input>
                                            </div>
                                            <div className="col-md-10 text-center">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="optionValue"
                                                value={opt.optionValue}
                                                style={{
                                                  backgroundColor: opt.isCorrect
                                                    ? "#f0fcf0"
                                                    : "#FFCCCC",
                                                }}
                                              ></input>
                                            </div>
                                          </div>
                                        </div>
                                        {oIdx === 0 && (
                                          <div className="col-md-2">
                                            <label>Remarks</label>
                                            <textarea
                                              value={
                                                q.remarks || q.answer.remarks
                                              }
                                              disabled={
                                                q.answer.isChecked
                                                  ? true
                                                  : false
                                              }
                                              className="form-control"
                                              name="remarks"
                                              onChange={(e) =>
                                                this.props.handleMarksChange(
                                                  e,
                                                  "theoryQuestions",
                                                  idx
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ))}
                              </>
                            ) : null}
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}

                  {this.props.isCheckedExam ? (
                    <div className="text-right" style={{ fontSize: "1.2rem" }}>
                      <b> Obtained Total: </b>{" "}
                      <span>{this.props.calculateTotal()}</span>{" "}
                      <span>
                        {this.props.calculateTotal() >=
                        this.props.examPassMarks ? (
                          <div style={{ color: "green", fontWeight: "600" }}>
                            [PASS]
                          </div>
                        ) : (
                          <div style={{ color: "red", fontWeight: "600" }}>
                            [FAIL]
                          </div>
                        )}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">No records available</div>
          )}
        </>
        <></>
      </div>
    );
  }
}

export default AssessmentQuestions;
