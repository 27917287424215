import React, { Component } from "react";
import ModalWindow from "../UI/ModalWindow";
import CourseClasses from "./SubjectSetting/CourseClass/CourseClasses";
import TopicDetailModal from "./SubjectSetting/TopicDetailModal";

class SemesterSubject extends Component {
  state = {
    topicsDetailModal: false,
    selectedSubject: "",
    classModal: false,
    courseTopicId: "",
    classSemesterModal: false,
    subjectId: "",
  };

  handleTopicsDetailModal = (sub) => {
    this.setState({
      selectedSubject: sub,
      topicsDetailModal: !this.state.topicsDetailModal,
    });
  };

  toggleTopicsDetailModal = () => {
    this.setState({ topicsDetailModal: !this.state.topicsDetailModal });
  };

  //Class Modal
  handleTopicClassModal = (id) => {
    this.setState({ classModal: !this.state.classModal, courseTopicId: id });
  };

  toggleTopicClassModal = () => {
    this.setState({ classModal: !this.state.classModal, courseTopicId: "" });
  };

  //SemesterClassModal
  handleSemesterClassModal = (id) => {
    this.setState({
      classSemesterModal: !this.state.classSemesterModal,
      subjectId: id,
    });
  };

  toggleSemesterClassModal = () => {
    this.setState({
      classSemesterModal: !this.state.classSemesterModal,
      subjectId: "",
    });
  };

  render() {
    let phase = this.props.phase;
    return (
      <React.Fragment>
        <table className="table table-bordered table-striped mb-0">
          <thead className="tt-group-header">
            <tr>
              <th>Program Terms</th>
              <th>Program Subjects</th>
              <th width="140px">Courses</th>
              <th width="140px">Classes</th>
            </tr>
          </thead>
          <tbody>
            {phase.courseTerms && phase.courseTerms.length > 0 ? (
              phase.courseTerms.map((term, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <tr>
                      <td
                        rowSpan={
                          term.courseSubjects && term.courseSubjects.length > 0
                            ? term.courseSubjects.length
                            : 1
                        }
                      >
                        {term.name}
                      </td>
                      <td rowSpan={1}>
                        {term.courseSubjects && term.courseSubjects.length > 0
                          ? term.courseSubjects.map((sub, sIdx) => {
                              if (sIdx === 0) {
                                return sub.name;
                              }
                            })
                          : "No Subjects assigned"}
                      </td>
                      <td rowSpan={1} className="text-center">
                        {term.courseSubjects && term.courseSubjects.length > 0
                          ? term.courseSubjects.map((sub, sIdx) => {
                              if (sIdx === 0) {
                                return (
                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleTopicsDetailModal(sub)
                                    }
                                  >
                                    Course Details
                                  </button>
                                );
                              }
                            })
                          : null}
                      </td>
                      <td rowSpan={1} className="text-center">
                        {term.courseSubjects && term.courseSubjects.length > 0
                          ? term.courseSubjects.map((sub, sIdx) => {
                              if (sIdx === 0) {
                                return (
                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleSemesterClassModal(sub.id)
                                    }
                                  >
                                    Class Details
                                  </button>
                                );
                              }
                            })
                          : null}
                      </td>
                    </tr>
                    {term.courseSubjects && term.courseSubjects.length > 0
                      ? term.courseSubjects.map((subject, subIdx) => {
                          if (subIdx > 0) {
                            return (
                              <tr key={subIdx}>
                                <td rowSpan={1}>{subject.name}</td>
                                <td rowSpan={1} className="text-center">
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleTopicsDetailModal(subject)
                                    }
                                  >
                                    Course Details
                                  </button>
                                </td>
                                <td rowSpan={1} className="text-center">
                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleSemesterClassModal(subject.id)
                                    }
                                  >
                                    Class Details
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                        })
                      : null}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className="text-center">
                  No terms assigned
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ModalWindow
          modal={this.state.topicsDetailModal}
          toggleModal={this.toggleTopicsDetailModal}
          modalHeader={this.state.selectedSubject.name + " - Course Details"}
          size="xl"
          modalBody={
            <TopicDetailModal
              topicList={
                this.state.selectedSubject.courseTopics
                  ? this.state.selectedSubject.courseTopics
                  : []
              }
              courseDetail={true}
              handleTopicClassModal={this.handleTopicClassModal}
            ></TopicDetailModal>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.classModal}
          toggleModal={this.toggleTopicClassModal}
          modalHeader="Classes"
          fullWidth={true}
          modalBody={
            <CourseClasses
              topicId={this.state.courseTopicId}
              subjectId={
                this.state.selectedSubject
                  ? this.state.selectedSubject.id
                  : null
              }
              topicModal={true}
              detailView={true}
            ></CourseClasses>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.classSemesterModal}
          toggleModal={this.toggleSemesterClassModal}
          modalHeader="Classes"
          fullWidth={true}
          modalBody={
            <CourseClasses
              subjectId={this.state.subjectId}
              detailView={true}
            ></CourseClasses>
          }
        ></ModalWindow>
      </React.Fragment>
    );
  }
}

export default SemesterSubject;
