import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";

class DurationCounter extends Component {
  state = {};
  render() {
    return (
      <div className="row">
        <div className="form-group col-md-2">
          <div
            className="ttbutton-icon"
            id="decrease"
            value="Decrease Value"
            name="numberOfTerms"
            onClick={this.props.decreaseValue}
            style={{ paddingLeft: 0 }}
          >
            <FontAwesomeIcon
              icon={faMinusSquare}
              size="1x"
              className="icon-minus"
            />
          </div>
        </div>
        <div className="col-md-3">
          <input
            type="number"
            id="number"
            value={this.props.duration}
            min="1"
            required
            disabled={!this.props.inputEnable}
            onChange={this.props.handleChange}
            name="duration"
            style={{ background: "white", width: "92px" }}
          />
        </div>
        <div className="form-group col-md-1">
          <div
            className="ttbutton-icon"
            id="increase"
            value="Increase Value"
            onClick={this.props.increaseValue}
          >
            <FontAwesomeIcon
              icon={faPlusSquare}
              size="1x"
              className="icon-minus"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DurationCounter;
