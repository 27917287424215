import {
  faBookAtlas,
  faBookOpen,
  faCalculator,
  faChalkboardTeacher,
  faLink,
  faPeopleGroup,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Spinner } from "reactstrap";
import ModalWindow from "../UI/ModalWindow";
import DashboardModalGroupMeet from "./DashboardModalGroupMeet";

const settings = {
  // dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 11,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 824,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};
class DashboardWidget extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    meetModal: false,
    widgets: [
      {
        name: "Student Materials",
        nameNp: "विद्यार्थी सामाग्री",
        iconName: faBookOpen,
        link: "/dashboard/student-material",
        display: 1,
      },
      {
        name: "Dictionary",
        nameNp: "शब्दकोश",
        iconName: faBookAtlas,
        link: "/dashboard/dictionary",
        display: 1,
      },
      {
        name: "Calculator",
        nameNp: "क्यालकुलेटर",
        iconName: faCalculator,
        link: "/dashboard/customCalculator",
        display: 1,
      },
      {
        name: "Teacher Materials",
        nameNp: "शिक्षक सामाग्री",
        iconName: faChalkboardTeacher,
        link: "/dashboard/teacher-material",
        display: 1,
      },
      {
        name: "Group Meet",
        nameNp: "Group Meet",
        iconName: faPeopleGroup,
        link: "",
        modal: true,
      },
      {
        name: "Relative Links",
        nameNp: "सापेक्ष लिङ्कहरू",
        iconName: faLink,
        link: "/dashboard/relative-links",
        display: 1,
      },
      {
        name: "Search",
        nameNp: "यहाँ खोज्नुहोस्",
        iconName: faSearch,
        link: "/dashboard/search-imagine",
        display: 1,
      },
    ],
  };

  handleGroupMeet = () => {
    this.setState({ meetModal: !this.state.meetModal });
  };

  render() {
    return (
      <div className="centralWidgetHolder dashboardNewWidget">
        <div className="footer-01">
          {this.state.widgets ? (
            <Slider {...settings}>
              {this.state.widgets &&
                this.state.widgets.map((item, index) => {
                  return (
                    <div className="tt-widget-holder-new" key={index}>
                      {item.modal ? (
                        <div
                          className="tt-widget-icon"
                          onClick={this.handleGroupMeet}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={item.iconName}
                              className="tt-widget-fontAwesome-icon"
                            />
                            <div className="tt-widget-title">{item.name}</div>
                          </div>
                        </div>
                      ) : item.name === "Search" ? (
                        <Link
                          to={{
                            pathname: item.link,
                            state: {
                              noNav: true,
                              teacherPage: true,
                              classPage: false,
                              fromDashboard: true,
                            },
                          }}
                          className="dashboardLink"
                          onClick={() => this.props.handleClick(item)}
                        >
                          <div className="tt-widget-icon">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={item.iconName}
                                className="tt-widget-fontAwesome-icon"
                              />
                              <div className="tt-widget-title">{item.name}</div>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <Link
                          to={item.link}
                          className="dashboardLink"
                          onClick={() => this.props.handleClick(item)}
                        >
                          <div className="tt-widget-icon">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={item.iconName}
                                className="tt-widget-fontAwesome-icon"
                              />
                              <div className="tt-widget-title">{item.name}</div>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  );
                })}
            </Slider>
          ) : (
            <div className="text-center mt-2">
              <Spinner />
            </div>
          )}
        </div>
        <ModalWindow
          modal={this.state.meetModal}
          toggleModal={this.handleGroupMeet}
          modalHeader="Group Meet"
          modalBody={
            <DashboardModalGroupMeet handleGroupMeet={this.handleGroupMeet} />
          }
          size="lg"
        />
      </div>
    );
  }
}

export default DashboardWidget;
