import React, { Component } from "react";
import { ModalFooter } from "reactstrap";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";

export default class CustomFieldForm extends Component {
  state = {
    fieldTypes: [],
  };
  componentDidMount() {
    this.getFieldTypes();
  }
  getFieldTypes = () => {
    axiosPost(URL.getFieldTypes, {}, (response) => {
      if (response.status === 200) {
        this.setState({ fieldTypes: response.data.data });
      }
    });
  };
  render() {
    let currentCustomField = this.props.currentCustomField;
    //   currentClusterIndex = this.props.currentClusterIndex;
    return (
      <>
        <div className="row mb-2">
          <div className="col-md-auto mt-1">
            <strong>Field Label:</strong>
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              name="fieldName"
              value={currentCustomField.fieldName}
              onChange={this.props.handleCustomFieldNameChange}
            />
          </div>
          <div className="col">
            <select
              className="form-control"
              name="id"
              value={
                this.props.updatingCustomField
                  ? currentCustomField.fieldTypeId
                  : currentCustomField.id
              }
              onChange={(e) =>
                this.props.handleCustomFieldTypeChange(e, this.state.fieldTypes)
              }
            >
              <option selected disabled>
                Choose Field Type
              </option>
              {this.state.fieldTypes
                ? this.state.fieldTypes.map((fieldType) => (
                    <option value={fieldType.id}>{fieldType.name}</option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="row mb-2">
          {currentCustomField.isMulti &&
          currentCustomField.fieldTypeCode !== "table"
            ? currentCustomField.customTableFieldDatas.map((data, index) => (
                <>
                  <div className="col-md-2 mb-2">
                    <label>Value:</label>
                  </div>
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="value"
                      value={data.data}
                      onChange={(e) =>
                        this.props.handleCustomFieldDataChange(e, index)
                      }
                    />
                  </div>

                  <div className="col-md-4 mb-2">
                    {index ===
                    currentCustomField.customTableFieldDatas.length - 1 ? (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="addMcqOption"
                        onClick={(e) => this.props.handleAddDatas(e)}
                      ></FontAwesomeIcon>
                    ) : null}
                    {index > 1 ? (
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="removeMcqOption ml-3"
                        onClick={(e) => this.props.handleRemoveDatas(e, index)}
                      ></FontAwesomeIcon>
                    ) : null}
                  </div>
                </>
              ))
            : null}
        </div>
        <div className="row mb-2">
          {currentCustomField.isMulti &&
          currentCustomField.fieldTypeCode === "table"
            ? currentCustomField?.customFieldTableColumns?.map(
                (column, colIndex) => (
                  <>
                    <div className="container-fluid">
                      <div className="row mb-2">
                        <div className="col-md-2 mt-1">
                          <strong>Column Name:</strong>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={column.name}
                            onChange={(e) =>
                              this.props.handleCustomFieldColumnNameChange(
                                e,
                                colIndex
                              )
                            }
                          />
                        </div>
                        <div className="col-md-3">
                          <select
                            className="form-control"
                            name="id"
                            value={
                              this.props.updatingCustomField
                                ? column.fieldTypeCode === "table"
                                  ? ""
                                  : column.fieldTypeId
                                : currentCustomField.id
                            }
                            onChange={(e) =>
                              this.props.handleCustomFieldColumnTypeChange(
                                e,
                                colIndex,
                                this.state.fieldTypes
                              )
                            }
                          >
                            <option selected disabled value="">
                              Choose Field Type
                            </option>
                            {this.state.fieldTypes
                              ? this.state.fieldTypes
                                  .filter(
                                    (fType) =>
                                      fType.code !== "table" &&
                                      fType.code !== "pre-peop" &&
                                      fType.code !== "pre-addr"
                                  )
                                  .map((fieldType) => (
                                    <option value={fieldType.id}>
                                      {fieldType.name}
                                    </option>
                                  ))
                              : null}
                          </select>
                        </div>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-4">
                              <span>
                                {colIndex ===
                                currentCustomField?.customFieldTableColumns
                                  .length -
                                  1 ? (
                                  <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className="addMcqOption"
                                    onClick={(e) =>
                                      this.props.handleAddCustomTableFieldColumns(
                                        e
                                      )
                                    }
                                  ></FontAwesomeIcon>
                                ) : null}
                                {colIndex > 0 ? (
                                  <FontAwesomeIcon
                                    icon={faMinusCircle}
                                    className="removeMcqOption ml-3"
                                    onClick={(e) =>
                                      this.props.handleRemoveCustomTableFieldColumns(
                                        e,
                                        colIndex
                                      )
                                    }
                                  ></FontAwesomeIcon>
                                ) : null}
                              </span>
                            </div>
                            <div className="col-md-8 mt-2">
                              <span>
                                <label className="mr-2">Sub Column:</label>
                                <input
                                  type="checkbox"
                                  name="hasSubColumns"
                                  checked={
                                    !this.props.updatingCustomField
                                      ? column.hasSubColumns
                                      : column.customFieldTableSubColumns
                                          ?.length > 0
                                  }
                                  onChange={(e) =>
                                    this.props.handleHasSubColumns(e, colIndex)
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {(column.hasSubColumns ||
                        column?.customFieldTableSubColumns?.length > 0) &&
                        column.customFieldTableSubColumns.map(
                          (subCol, subColIndex) => (
                            <>
                              <div className="row mb-2 p-2">
                                <div className="col-md-2 mt-1">
                                  <strong>Sub column name:</strong>
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={subCol.name}
                                    onChange={(e) =>
                                      this.props.handleCustomFieldColumnNameChange(
                                        e,
                                        colIndex,
                                        subColIndex,
                                        "subCol"
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-3">
                                  <select
                                    className="form-control"
                                    name="id"
                                    value={
                                      this.props.updatingCustomField
                                        ? subCol.fieldTypeId
                                        : currentCustomField.id
                                    }
                                    onChange={(e) =>
                                      this.props.handleCustomFieldColumnTypeChange(
                                        e,
                                        colIndex,
                                        this.state.fieldTypes,
                                        subColIndex,
                                        "subCol"
                                      )
                                    }
                                  >
                                    <option selected disabled>
                                      Choose Field Type
                                    </option>
                                    {this.state.fieldTypes
                                      ? this.state.fieldTypes
                                          .filter(
                                            (fType) =>
                                              fType.code !== "table" &&
                                              fType.code !== "pre-peop" &&
                                              fType.code !== "pre-addr"
                                          )
                                          .map((fieldType) => (
                                            <option value={fieldType.id}>
                                              {fieldType.name}
                                            </option>
                                          ))
                                      : null}
                                  </select>
                                </div>
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <span>
                                        {subColIndex ===
                                        column.customFieldTableSubColumns
                                          .length -
                                          1 ? (
                                          <FontAwesomeIcon
                                            icon={faPlusCircle}
                                            className="addMcqOption"
                                            onClick={(e) =>
                                              this.props.handleAddCustomTableFieldColumns(
                                                e,
                                                colIndex,
                                                subColIndex,
                                                "subCol"
                                              )
                                            }
                                          ></FontAwesomeIcon>
                                        ) : null}
                                        {subColIndex > 0 ? (
                                          <FontAwesomeIcon
                                            icon={faMinusCircle}
                                            className="removeMcqOption ml-3"
                                            onClick={(e) =>
                                              this.props.handleRemoveCustomTableFieldColumns(
                                                e,
                                                colIndex,
                                                subColIndex,
                                                "subCol"
                                              )
                                            }
                                          ></FontAwesomeIcon>
                                        ) : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <>
                                {subCol.isMulti
                                  ? subCol.customFieldTableColumnOptionDatas.map(
                                      (data, dataIndex) => (
                                        <div className="row mb-2 p-2">
                                          <div className="col-md-2 mb-2">
                                            <label>Value:</label>
                                          </div>
                                          <div className="col-md-6 mb-2">
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="optionData"
                                              value={data.optionData}
                                              onChange={(e) =>
                                                this.props.handleCustomFieldColumnDataChange(
                                                  e,
                                                  colIndex,
                                                  subColIndex,
                                                  dataIndex,
                                                  "subCol"
                                                )
                                              }
                                            />
                                          </div>

                                          <div className="col-md-4 mb-2">
                                            {dataIndex ===
                                            subCol
                                              .customFieldTableColumnOptionDatas
                                              .length -
                                              1 ? (
                                              <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                className="addMcqOption"
                                                onClick={(e) =>
                                                  this.props.handleAddColumnDatas(
                                                    e,
                                                    colIndex,
                                                    subColIndex,
                                                    "subCol"
                                                  )
                                                }
                                              ></FontAwesomeIcon>
                                            ) : null}
                                            {dataIndex > 1 ? (
                                              <FontAwesomeIcon
                                                icon={faMinusCircle}
                                                className="removeMcqOption ml-3"
                                                onClick={(e) =>
                                                  this.props.handleRemoveColumnDatas(
                                                    e,
                                                    colIndex,
                                                    subColIndex,
                                                    dataIndex,
                                                    "subCol"
                                                  )
                                                }
                                              ></FontAwesomeIcon>
                                            ) : null}
                                          </div>
                                        </div>
                                      )
                                    )
                                  : null}
                              </>
                            </>
                          )
                        )}

                      <div className="row mb-2">
                        {column.isMulti
                          ? column?.customFieldTableColumnOptionDatas?.map(
                              (data, index) => (
                                <>
                                  <div className="col-md-2 mb-2">
                                    <label>Value:</label>
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="optionData"
                                      value={data.optionData}
                                      onChange={(e) =>
                                        this.props.handleCustomFieldColumnDataChange(
                                          e,
                                          colIndex,
                                          index
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="col-md-4 mb-2">
                                    {index ===
                                    column.customFieldTableColumnOptionDatas
                                      ?.length -
                                      1 ? (
                                      <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        className="addMcqOption"
                                        onClick={(e) =>
                                          this.props.handleAddColumnDatas(
                                            e,
                                            colIndex,
                                            index
                                          )
                                        }
                                      ></FontAwesomeIcon>
                                    ) : null}
                                    {index > 1 ? (
                                      <FontAwesomeIcon
                                        icon={faMinusCircle}
                                        className="removeMcqOption ml-3"
                                        onClick={(e) =>
                                          this.props.handleRemoveColumnDatas(
                                            e,
                                            colIndex,
                                            index
                                          )
                                        }
                                      ></FontAwesomeIcon>
                                    ) : null}
                                  </div>
                                </>
                              )
                            )
                          : null}
                      </div>
                      <hr
                        style={{
                          color: "black",
                          height: 5,
                        }}
                      />
                    </div>
                  </>
                )
              )
            : null}
        </div>
        <ModalFooter>
          <button
            className="tt-button mt-0 tt-button-primary"
            onClick={
              this.props.updatingCustomField
                ? (e) => this.props.handleUpdateCustomFieldBtnClick(e)
                : (e) => this.props.handleSubmitCustomFieldBtnClick(e)
            }
          >
            {this.props.updatingCustomField ? `Update` : `Add`}
          </button>
          <button
            className="tt-button tt-button-primary mt-0"
            onClick={this.props.handleCancelCustomFieldBtnClick}
          >
            Cancel
          </button>
        </ModalFooter>
      </>
    );
  }
}
