import React, { Component } from "react";

class AssignSearchBox extends Component {
  state = {};
  render() {
    return (
      <>
        <div>
          <div className="form-group row">
            <label htmlFor="age" className="col-md-2 col-form-label">
              <strong> Age Between</strong>
            </label>
            <div className="col-md-3">
              <input
                type="number"
                name="minAge"
                value={this.props.minAge}
                onChange={this.props.handleChange}
                className="form-control"
                min={1}
                max={30}
              ></input>
            </div>
            <label htmlFor="ageTo" className="col-md-2 col-form-label">
              <strong> years and</strong>
            </label>
            <div className="col-md-3">
              <input
                type="number"
                name="maxAge"
                value={this.props.maxAge}
                onChange={this.props.handleChange}
                className="form-control"
                min={this.props.minAge}
                max={30}
              ></input>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="options" className="col-md-2 col-form-label">
              <strong>Options</strong>
            </label>
            <div className="col-md-4">
              <select
                name="searchName"
                value={this.props.searchName}
                onChange={this.props.handleChange}
                className="form-control"
              >
                <option value="" disabled selected>
                  Choose Field Name
                </option>
                {this.props.nameFields &&
                  this.props.nameFields.map((field) => (
                    <option value={field.id}>{field.fieldName}</option>
                  ))}
              </select>
            </div>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                name="searchValue"
                value={this.props.searchValue}
                onChange={this.props.handleChange}
              />
            </div>
            <div className="col-md-2">
              {" "}
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.searchStudents}
              >
                List Students
              </button>
            </div>
          </div>
        </div>
        <div className="form-group row tt-assignSearchDropdown">
          <div className="col-sm-2"></div>
          <label htmlFor="assignUnassign" className="col-sm-2 col-form-label">
            <strong> Search for student</strong>
          </label>
          <div className="col-sm-4">
            <select
              className="form-control"
              name="toggleStudentList"
              value={this.props.toggleStudentList}
              onChange={this.props.handleChange}
            >
              <option value="assign" selected>
                Unassigned
              </option>
              <option value="assigned">Assigned</option>
            </select>
          </div>
          <label htmlFor="assignUnassign" className="col-sm-2 col-form-label">
            <strong>
              {" "}
              in {this.props.classroom.name} for {this.props.yearLevel}
            </strong>
          </label>
        </div>{" "}
      </>
    );
  }
}

export default AssignSearchBox;
