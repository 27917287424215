import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";

export class GeneralSetting extends Component {
  state = {
    fieldTypes: [],
  };

  componentDidMount() {
    axiosPost(URL.getFieldTypes, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          fieldTypes: response.data.data,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.props.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait...
            </div>
          </div>
        ) : null}
        <div className="container py-2">
          {this.props.editMode ? (
            <>
              {/* <form>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Name:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="settingKey"
                      className="form-control"
                      placeholder="Enter setting name"
                      value={this.props.inputFields.settingKey}
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Description:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="Enter Description"
                      value={this.props.inputFields.description}
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                      maxLength="1000"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Alias Name:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="aliasName"
                      className="form-control"
                      placeholder="Enter alias name"
                      value={this.props.inputFields.aliasName}
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </form>
              <div className="text-right mt-3">
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.props.handleUpdateSetting}
                >
                  Update
                </button>
              </div> */}

              <form>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="aliasName">
                      <strong>Name:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <p>{this.props.inputFields.aliasName}</p>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Description:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <p>{this.props.inputFields.description}</p>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="value">
                      <strong>Value:</strong>
                    </label>
                  </div>

                  <div className="col-md-8">
                    <select
                      name="value"
                      className="form-control"
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                      value={this.props.inputFields?.value}
                    >
                      {this.props.inputFields?.settingOptions?.map((data) => (
                        <option value={data.value}>{data.value}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-1"></div>
                </div>
              </form>
              <div className="text-right mt-3">
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.props.handleUpdateSetting}
                >
                  Update
                </button>
              </div>
            </>
          ) : (
            <>
              <form>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Name:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="settingKey"
                      className="form-control"
                      placeholder="Enter setting name"
                      value={this.props.inputFields.settingKey}
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Default Value:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="value"
                      className="form-control"
                      placeholder="Enter default value"
                      value={this.props.inputFields.value}
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Description:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="Enter Description"
                      value={this.props.inputFields.description}
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                      maxLength="1000"
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Alias Name:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="aliasName"
                      className="form-control"
                      placeholder="Enter alias name"
                      value={this.props.inputFields.aliasName}
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="form-group row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <label htmlFor="settingKey">
                      <strong>Field Type:</strong>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <select
                      name="fieldTypeId"
                      className="form-control"
                      onChange={(e) => this.props.handleChangeInputFields(e)}
                      value={this.props.inputFields.fieldTypeId}
                    >
                      {this.state.fieldTypes?.map((field) => (
                        <option value={field.id}>{field.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </form>
              <div className="text-right mt-3">
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.props.handleSaveSetting}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default GeneralSetting;
