import React, { Component } from "react";
import { displayConfirmDeleteAlert } from "../../../../utils/Utils";
import CustomCollapse from "../../../UI/CustomCollapse";

class UpdateSubjectArea extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <>
        <tr key={this.props.idx}>
          <td width="20%">{this.props.info.name}</td>
          <td width="70%" className="text-left">
            {this.props.info.description !== ""
              ? this.props.info.description
              : "No description available"}
          </td>
          {this.props.permissionEditSubjectArea ||
          this.props.permissionDeleteSubjectArea ? (
            <td>
              {this.props.permissionEditSubjectArea ? (
                <button
                  className="tt-button tt-button-primary mt-0 tt-button-fullWidth"
                  //   onClick={() => this.toggle(this.props.idx)}
                  onClick={() =>
                    this.props.handleUpdateSubjectAreaBtnClick(
                      this.props.idx,
                      this.props.info.id
                    )
                  }
                >
                  Update
                </button>
              ) : null}
              {this.props.permissionDeleteSubjectArea ? (
                <button
                  className="tt-button tt-button-primary mt-0 tt-button-fullWidth"
                  onClick={
                    //e => this.props.deleteList(e, this.props.info.id)
                    (e) =>
                      displayConfirmDeleteAlert(
                        { e, id: this.props.info.id },
                        this.props.deleteList
                      )
                  }
                >
                  Delete
                </button>
              ) : null}
            </td>
          ) : null}
        </tr>
        <div className="tt-divRow">
          <td colSpan="3">
            <CustomCollapse
              isOpen={this.props.info.isOpen}
              collapsibleBody={this.props.subjectAreaContent(
                this.props.info.id
              )}
            ></CustomCollapse>
          </td>
        </div>
      </>
    );
  }
}

export default UpdateSubjectArea;
