import React, { Component } from "react";
import CourseSyllabusDetailReRender from "./CourseSyllabusDetailReRender";

class CourseSyllabusDetailModal extends Component {
  state = {};
  render() {
    return (
      <div className="tt-topicHolder">
        {this.props.topicList && this.props.topicList.length > 0 ? (
          this.props.topicList.map((topic, idx) => {
            return (
              <React.Fragment key={idx}>
                <div
                  className="row"
                  key={idx}
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    lineHeight: "2",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    className="col position-relative"
                    style={{ display: "flex" }}
                  >
                    <span className="backGround toolTip courseSyllabusTitle">
                      {idx + 1}. {topic.name}
                      <span className="toolTipText">
                        {topic.description && "(" + topic.description + ")"}
                      </span>
                    </span>
                    <span
                      style={{ height: "inherit", width: "5px" }}
                      className="backGround"
                    ></span>
                    <div className="dotsRender"></div>
                  </div>
                  <div className="col-md-auto text-center">
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize mb-1 mr-2"
                      onClick={() =>
                        this.props.handleUpdateSyllabusDetail(topic)
                      }
                    >
                      Edit
                    </button>

                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize mb-1 mr-2"
                      onClick={() =>
                        this.props.handleSyllabusDetailDelete(topic.id)
                      }
                    >
                      Delete
                    </button>

                    {topic.hasResource ? (
                      <button
                        className="btn btn-primary cusBtn cusBtnFontSize mb-1 mr-2"
                        onClick={() =>
                          this.props.toggleSyllabusResourcesModalView(topic)
                        }
                      >
                        View Resource
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize mb-1"
                      onClick={() => this.props.syllabusResourceModal(topic)}
                    >
                      Add Resources
                    </button>
                  </div>
                </div>
                {topic.subTopic && topic.subTopic.length > 0 ? (
                  <CourseSyllabusDetailReRender
                    topicList={topic.subTopic}
                    handleUpdateSyllabusDetail={
                      this.props.handleUpdateSyllabusDetail
                    }
                    index={1}
                    handleSyllabusDetailDelete={
                      this.props.handleSyllabusDetailDelete
                    }
                    syllabusResourceModal={this.props.syllabusResourceModal}
                    toggleSyllabusResourcesModalView={
                      this.props.toggleSyllabusResourcesModalView
                    }
                  ></CourseSyllabusDetailReRender>
                ) : null}
              </React.Fragment>
            );
          })
        ) : (
          <div className="col text-center">No Courses available</div>
        )}
      </div>
    );
  }
}

export default CourseSyllabusDetailModal;
