import React from "react";
import { checkRolePermissions } from "../../utils/Permissions";

function CustomPageHeader(props) {
  return (
    <div className="listBox">
      <p className="listTitle text-capitalize">{props.title}</p>
      {checkRolePermissions(props.action, props.moduleName) &&
      !props.centralUser ? (
        <>
          <span
            className="material-icons-round listIcon"
            onClick={props.onClick}
          >
            {props.icon}
          </span>
        </>
      ) : null}
      {/* {checkRolePermissions(props.action, props.moduleName) && (
        <span className="material-icons-round listIcon" onClick={props.onClick}>
          {props.icon}
        </span>
      )} */}
      {/* {props.noPermission ||
      checkRolePermissions(props.action, props.moduleName) ? (
        <span className="material-icons-round listIcon" onClick={props.onClick}>
          {props.icon}
        </span>
      ) : null} */}

      {/* {props.noPermission ? (
        <span className="material-icons-round listIcon" onClick={props.onClick}>
          {props.icon}
        </span>
      ) : props.noIcon ||
        !checkRolePermissions(props.action, props.moduleName) ||
        !props.noPermission ? null : (
        <span className="material-icons-round listIcon" onClick={props.onClick}>
          {props.icon}
        </span>
      )} */}
    </div>
  );
}

export default CustomPageHeader;
