import React, { Component } from "react";
import CounterInput from "react-counter-input";

class MarksModalBody extends Component {
  state = {};
  render() {
    let props = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            maxHeight: "77.5vh",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {props.selectedData?.hasTheory ? (
            <div
              style={{ border: "1px solid #ccc", padding: "3px" }}
              className="mb-2"
            >
              <div
                className="tt-group-header"
                style={{ fontWeight: "normal", borderRadius: 0 }}
              >
                Theory Marks Configuration
              </div>
              <div className="pl-3 pr-3">
                <table className="table table-striped table-bordered text-center">
                  <thead className="tt-group-header">
                    <tr>
                      <th>Assessment Scale Name</th>
                      <th>Max Value</th>
                      <th>Min Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.selectedData?.marksConfigurations.map((el, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{el.assessmentScaleName}</td>
                          <td>
                            <CounterInput
                              count={el.theoryMax ? el.theoryMax : 0}
                              min={0}
                              onCountChange={(count) =>
                                props.handleCountChange(idx, "theoryMax", count)
                              }
                              // max={props.maxLength}
                              btnStyle={{
                                backgroundColor: "#000080",
                                border: "1px solid #ccc",
                                color: "white",
                                padding: "5px",
                                backgroundImage:
                                  "linear-gradient(#000080, #000080)",
                              }}
                              inputStyle={{
                                height: "33px",
                                background: "white",
                                border: "1px solid #ccc",
                              }}
                              wrapperStyle={{
                                justifyContent: "center",
                              }}
                            />
                          </td>
                          <td>
                            {" "}
                            <CounterInput
                              count={el.theoryMin ? el.theoryMin : 0}
                              min={0}
                              onCountChange={(count) =>
                                props.handleCountChange(idx, "theoryMin", count)
                              }
                              // max={props.maxLength}
                              btnStyle={{
                                backgroundColor: "#000080",
                                border: "1px solid #ccc",
                                color: "white",
                                padding: "5px",
                                backgroundImage:
                                  "linear-gradient(#000080, #000080)",
                              }}
                              inputStyle={{
                                height: "33px",
                                background: "white",
                                border: "1px solid #ccc",
                              }}
                              wrapperStyle={{
                                justifyContent: "center",
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          {props.selectedData?.hasPractical ? (
            <div
              style={{ border: "1px solid #ccc", padding: "3px" }}
              className="mb-2"
            >
              <div
                className="tt-group-header"
                style={{ fontWeight: "normal", borderRadius: 0 }}
              >
                Practical Marks Configuration
              </div>
              <div className=" pl-3 pr-3 mb-2">
                <table className="table table-striped table-bordered text-center">
                  <thead className="tt-group-header">
                    <tr>
                      <th>Assessment Scale Name</th>
                      <th>Max Value</th>
                      <th>Min Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.selectedData?.marksConfigurations.map((el, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{el.assessmentScaleName}</td>
                          <td>
                            <CounterInput
                              count={el.practicalMax ? el.practicalMax : 0}
                              min={0}
                              onCountChange={(count) =>
                                props.handleCountChange(
                                  idx,
                                  "practicalMax",
                                  count
                                )
                              }
                              // max={props.maxLength}
                              btnStyle={{
                                backgroundColor: "#000080",
                                border: "1px solid #ccc",
                                color: "white",
                                padding: "5px",
                                backgroundImage:
                                  "linear-gradient(#000080, #000080)",
                              }}
                              inputStyle={{
                                height: "33px",
                                background: "white",
                                border: "1px solid #ccc",
                              }}
                              wrapperStyle={{
                                justifyContent: "center",
                              }}
                            />
                          </td>
                          <td>
                            <CounterInput
                              count={el.practicalMin ? el.practicalMin : 0}
                              min={0}
                              onCountChange={(count) =>
                                props.handleCountChange(
                                  idx,
                                  "practicalMin",
                                  count
                                )
                              }
                              // max={props.maxLength}
                              btnStyle={{
                                backgroundColor: "#000080",
                                border: "1px solid #ccc",
                                color: "white",
                                padding: "5px",
                                backgroundImage:
                                  "linear-gradient(#000080, #000080)",
                              }}
                              inputStyle={{
                                height: "33px",
                                background: "white",
                                border: "1px solid #ccc",
                              }}
                              wrapperStyle={{
                                justifyContent: "center",
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary"
              onClick={props.saveMarksConfiguration}
            >
              Save
            </button>
            <button
              className="tt-button tt-button-danger ml-3"
              onClick={props.closeMarksConfiguration}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MarksModalBody;
