import React, { Component } from "react";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";

class AddressCluster extends Component {
  state = {
    customFields: [],
    countrys: [],
    country: "",
    firstLevelDisplayName: [],
    secondLevelDisplayName: [],
    thirdLevelDisplayName: [],
  };

  componentDidMount() {
    this.getCustomFields();
    this.getCountry();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.customTableFieldValuesByUsername !==
      this.props.customTableFieldValuesByUsername
    ) {
      this.getCustomFields();
    }
  }

  rereunSetter = () => {
    const customTableFieldValuesByUsername =
      this.props.customTableFieldValuesByUsername;
    let customFields = [...this.state.customFields];
    customFields.forEach((field) => {
      const valueField = customTableFieldValuesByUsername.find(
        (vf) => vf.customTableFieldId === field.id
      );
      field.value = valueField ? valueField.value : null;
    });
    this.setState({ customFields });
  };

  getCountry = () => {
    axiosPost(URL.getCountry, {}, (response) => {
      if (response.status === 200) {
        this.setState({ countrys: response.data.data });
      }
    });
  };

  getCustomFields = () => {
    axiosPost(
      URL.getAddressCluster,
      {
        isConfig: this.props.config,
      },
      (response) => {
        if (response.status === 200) {
          let data = response.data.data;
          data.forEach((field) => {
            field.key = Math.random();
          });
          this.setState({ customFields: data }, () => {
            if (this.props.customTableFieldValuesByUsername) {
              this.rereunSetter();
            }
          });
        }
      }
    );
  };

  // handleChange = (e) => {
  //   let target = e.target;
  //   let name = target.name;
  //   let value = target.type === "checkbox" ? target.checked : target.value;
  //   this.setState({ [name]: value });
  // };

  renderCountryLevels = () => {
    let index = -1;
    if (this.props.customTableField !== undefined) {
      if (this.state.countrys.length > 0) {
        index = this.state.countrys.findIndex(
          (i) => i.id === parseInt(this.props.customTableField.country)
        );
      }
    }
    if (
      this.state.countrys[index] !== undefined &&
      this.state.countrys[index] !== -1
    ) {
      if (this.state.countrys[index].firstLevelDisplayName !== null) {
        return (
          <>
            {this.state.countrys[index].firstLevelDisplayName !== null &&
            this.state.countrys[index].secondLevelDisplayName !== "" ? (
              <div className="row mb-2">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <label>
                    <strong>
                      {this.state.countrys[index].firstLevelDisplayName}
                      {this.props.inputForm && (
                        <span className="tt-assessment-module-mandatory">
                          *
                        </span>
                      )}
                    </strong>
                  </label>
                </div>
                <div className="col-md-6">
                  <select
                    name="countryFirstLevelId"
                    className={
                      this.props.customTableField?.error?.stdProvince
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    value={
                      this.props.customTableField &&
                      this.props.customTableField.countryFirstLevelId
                    }
                    onChange={
                      !this.props.config
                        ? (e) =>
                            this.props.handleCustomFieldChange(
                              e,
                              this.props.studentFieldIndex,
                              this.props.customTableFieldIndex,
                              this.props.stateName
                            )
                        : null
                    }
                  >
                    <option value="" selected disabled>
                      Choose a{" "}
                      {this.state.countrys[index].firstLevelDisplayName}
                    </option>
                    {this.props.customTableField.countryFirstLevel
                      ? this.props.customTableField.countryFirstLevel.map(
                          (firstLevel, idx) => {
                            return (
                              <option key={idx} value={firstLevel.id}>
                                {firstLevel.name}
                              </option>
                            );
                          }
                        )
                      : null}
                  </select>
                </div>
              </div>
            ) : null}
            {this.state.countrys[index].secondLevelDisplayName !== null &&
            this.state.countrys[index].secondLevelDisplayName !== "" ? (
              <div className="row mb-2">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <label>
                    <strong>
                      {this.state.countrys[index].secondLevelDisplayName}
                      {this.props.inputForm && (
                        <span className="tt-assessment-module-mandatory">
                          *
                        </span>
                      )}
                    </strong>
                  </label>
                </div>
                <div className="col-md-6">
                  <select
                    name="countrySecondLevelId"
                    className={
                      this.props.customTableField?.error?.stdDistrict
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    value={
                      this.props.customTableField &&
                      this.props.customTableField.countrySecondLevelId
                    }
                    onChange={
                      !this.props.config
                        ? (e) =>
                            this.props.handleCustomFieldChange(
                              e,
                              this.props.studentFieldIndex,
                              this.props.customTableFieldIndex,
                              this.props.stateName
                            )
                        : null
                    }
                  >
                    <option value="" selected disabled>
                      Choose a{" "}
                      {this.state.countrys[index].secondLevelDisplayName}
                    </option>
                    {this.props.customTableField.countrySecondLevel
                      ? this.props.customTableField.countrySecondLevel.map(
                          (secondLevel, idx) => {
                            return (
                              <option key={idx} value={secondLevel.id}>
                                {secondLevel.name}
                              </option>
                            );
                          }
                        )
                      : null}
                  </select>
                </div>
              </div>
            ) : null}
            {this.state.countrys[index].thirdLevelDisplayName !== null &&
            this.state.countrys[index].thirdLevelDisplayName !== "" ? (
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <label>
                    <strong>
                      {this.state.countrys[index].thirdLevelDisplayName}
                      {this.props.inputForm && (
                        <span className="tt-assessment-module-mandatory">
                          *
                        </span>
                      )}
                    </strong>
                  </label>
                </div>
                <div className="col-md-6">
                  <select
                    name="countryThirdLevelId"
                    className={
                      this.props.customTableField?.error?.stdCity
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    value={
                      this.props.customTableField &&
                      this.props?.customTableField?.countryThirdLevelId
                    }
                    onChange={
                      !this.props.config
                        ? (e) =>
                            this.props.handleCustomFieldChange(
                              e,
                              this.props.studentFieldIndex,
                              this.props.customTableFieldIndex,
                              this.props.stateName
                            )
                        : null
                    }
                  >
                    <option value="" selected disabled>
                      Choose a{" "}
                      {this.state.countrys[index].thirdLevelDisplayName}
                    </option>
                    {this.props.customTableField.countryThirdLevel
                      ? this.props.customTableField.countryThirdLevel.map(
                          (thirdLevel, idx) => (
                            <option key={idx} value={thirdLevel.id}>
                              {thirdLevel.name}
                            </option>
                          )
                        )
                      : null}
                  </select>
                </div>
              </div>
            ) : null}
          </>
        );
      }
    }
  };

  render() {
    let errors = this.props.errors;
    let stateName = this.props.stateName;
    let studentFieldIndex = this.props.studentFieldIndex;
    const customTableField = this.props.customTableField;
    const customTableFieldValues =
      this.props.customTableField?.customTableFieldValues;
    return (
      <div className="card">
        <div className="card-body">
          <div className="tt-group-header">Address Cluster</div>
          {this.state.customFields.map((fields, idx) => {
            return (
              <div key={fields.key} className="row mb-2">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <label>
                    <strong>{fields.fieldName} </strong>
                    {this.props.inputForm && fields.isMandatory && (
                      <span className="tt-assessment-module-mandatory">*</span>
                    )}
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    type={fields.fieldTypeName}
                    name={fields.fieldName}
                    maxLength={255}
                    id={fields.fieldName}
                    defaultValue={
                      this.props.userFound
                        ? fields.value
                        : customTableFieldValues &&
                          customTableFieldValues.find(
                            (field) => field.customTableFieldId === fields.id
                          )?.value
                    }
                    onChange={
                      !this.props.config
                        ? (e) =>
                            this.props.handleCustomFieldChange(
                              e,
                              this.props.studentFieldIndex,
                              this.props.customTableFieldIndex,
                              this.props.stateName,
                              fields
                            )
                        : null
                    }
                    className={
                      customTableField?.error?.[fields.fieldName]
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                  ></input>
                </div>
                {/* <div className="col-md-1">
                  {errors ? (
                    errors[stateName][fields.fieldName] ? (
                      <span className="customfield-error-text">mandatory</span>
                    ) : null
                  ) : null}
                </div> */}
              </div>
            );
          })}
          <div className="row mb-2">
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <label>
                <strong>Country </strong>
                {this.props.inputForm && (
                  <span className="tt-assessment-module-mandatory">*</span>
                )}
              </label>
            </div>
            <div className="col-md-6">
              <select
                name="country"
                className={
                  !customTableField?.country &&
                  customTableField?.error?.stdCountry
                    ? "form-control indicate-error"
                    : "form-control"
                }
                value={customTableField && customTableField.country}
                onChange={
                  !this.props.config
                    ? (e) =>
                        this.props.handleCustomFieldChange(
                          e,
                          this.props.studentFieldIndex,
                          this.props.customTableFieldIndex,
                          this.props.stateName
                        )
                    : null
                }
              >
                <option value="" selected disabled>
                  Choose a country
                </option>
                {this.state.countrys.map((country, idx) => {
                  return (
                    <option key={idx} value={country.id}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {this.renderCountryLevels()}
        </div>
      </div>
    );
  }
}

export default AddressCluster;
