import React, { Component } from "react";
import GpaForm from "./GpaForm";
import GpaTable from "./GpaTable";

class ScaleTypeGradeForm extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <label className="indicator-label">
              <strong>Score name</strong>
            </label>
          </div>
          <div className="col-md-4 scale-content">
            <input
              type="text"
              name="gpaName"
              value={this.props.gpaName}
              className="form-control"
              onChange={this.props.universalHandleChange}
            />
          </div>
          <div className="col-md-6"></div>
        </div>

        <div className="ttgpa-table">
          <button
            className="tt-button tt-button-primary tt-reset-to-original-button"
            onClick={this.props.handleResetGpaFormat}
          >
            {"Reset to original Format"}
          </button>
          <GpaTable
            defaultGpaValues={this.props.defaultGpaValues}
            handleRemoveGpas={this.props.handleRemoveGpas}
            handleValueEditable={this.props.handleValueEditable}
          />
        </div>
        {/* <IndicatorDescriptorTable
          multipleGpa={this.props.multipleGpa}
          handleRemoveGrades={this.props.handleRemoveGrades}
        /> */}
        <p className="ttadd-title">Fill the form below to add new Gpa format</p>
        <GpaForm
          gradeTypes={this.props.gradeTypes}
          addMultipleGpa={this.props.addMultipleGpa}
          multipleGpa={this.props.multipleGpa}
          multipleGpaChange={this.props.multipleGpaChange}
          handleSaveNewGpa={this.props.handleSaveNewGpa}
          handleRemoveItemFromForm={this.props.handleRemoveItemFromForm}
        />
      </>
    );
  }
}

export default ScaleTypeGradeForm;
