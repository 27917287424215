import React, { Component } from "react";

class FullImage extends Component {
  state = {};
  //   componentDidMount() {
  //     let modal = document.getElementById("fullProfileImage");
  //     if (modal.scrollHeight > window.innerHeight) {
  //       modal.style.zoom = "57%";
  //     } else {
  //       modal.style.zoom = "unset";
  //     }
  //   }
  render() {
    return (
      <div className="tt-profileImageHolder">
        <img
          src={this.props.image}
          alt="profile"
          className="tt-fullImage"
        ></img>
        {this.props.caption ? (
          this.props.caption !== "" ? (
            <div className="tt-fullImageCaption">{this.props.caption}</div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default FullImage;
