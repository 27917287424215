import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL } from "../../utils/Constants";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import moment from "moment";
import {
  compareByName,
  displayErrorAlert,
  displaySuccessAlert,
} from "../../utils/Utils";
import ModalWindow from "../UI/ModalWindow";
import AddResourcesFile from "./AddResourcesFile";
import { cloneDeep } from "lodash";
import { data } from "jquery";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

const animatedComponents = makeAnimated();

class Planner extends Component {
  state = {
    activePL: null,
    deletedFiles: [],
    deletedPlannerRow: [],
    endDate: "",
    filePreviewModal: "",
    noOfPeriods: "",
    plannerList: [
      {
        id: null,
        date: "",
        weekdayId: "",
        topic: "",
        objectives: "",
        methodology: "",
        classAssignment: "",
        homeAssignment: "",
        remark: "",
        resources: [],
      },
    ],
    permissionModal: false,
    resourceFileModal: false,
    selectedSubjects: [],
    spinner: false,
    startDate: "",
    subjects: [],
    weekDays: [],
    yearLevel: "",
    yearLevels: [],
  };

  addRow = () => {
    let plannerList = [...this.state.plannerList];
    plannerList.push({
      id: null,
      date: "",
      weekdayId: "",
      topic: "",
      objectives: "",
      methodology: "",
      classAssignment: "",
      homeAssignment: "",
      remark: "",
      resources: [],
    });
    this.setState({ plannerList });
  };

  componentDidMount() {
    this.getWeekDays();
    this.getYearLevels();
    if (this.props.plannerEditModal) {
      this.updateConfig();
    }
  }

  createPlanner = () => {
    if (
      this.state.yearLevel === "" ||
      this.state.selectedSubjects.length === 0 ||
      this.state.startDate === "" ||
      this.state.endDate === "" ||
      this.state.noOfPeriods === ""
    ) {
      swal(
        "Error",
        "Year level, subjects, no. of periods,  start date and end date are required fields"
      );
      return false;
    }
    let selectedSubjects = [...this.state.selectedSubjects];
    let subjects = [];
    if (selectedSubjects.length > 0) {
      selectedSubjects.forEach((el) => {
        subjects.push({ subjectId: el.value });
      });
    }

    let plannerList = [...this.state.plannerList];
    let fileIndex = [];
    let files = [];

    plannerList.forEach((item, plidx) => {
      if (item.resources.length > 0) {
        item.resources.forEach((el, idx) => {
          files.push(el.image);
          fileIndex.push(plidx);
        });
      }
    });

    let formData = new FormData();

    let newPL = cloneDeep([...this.state.plannerList]);

    newPL.forEach((object) => {
      delete object["resources"];
    });

    let param = {
      endDate: this.state.endDate,
      periods: this.state.noOfPeriods,
      plannerList: newPL,
      subjects: subjects,
      startDate: this.state.startDate,
      yearlevelId: this.state.yearLevel,
    };

    formData.append("params", JSON.stringify(param));
    files.forEach((image) => formData.append("files", image));

    if (fileIndex.length > 0) {
      formData.append("fileIndex", JSON.stringify(fileIndex));
    }

    let validationErr = 0;

    newPL.forEach((pl) => {
      if (
        // pl.classAssignment === "" ||
        pl.date === "" ||
        // pl.homeAssignment === "" ||
        pl.methodology === "" ||
        pl.objectives === "" ||
        pl.remark === "" ||
        pl.topic === "" ||
        pl.weekdayId === ""
      ) {
        validationErr++;
      }
    });

    if (validationErr > 0) {
      swal("Error", "Fill all the required fields for the planner");
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertPlanner,
          formData,
          (res) => {
            if (res.status === 200) {
              displaySuccessAlert(res);
              this.setState({ showSpinner: false });
              this.handleCancel();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ showSpinner: false });
          }
        );
      });
    }
  };

  deleteRow = (idx, item) => {
    let plannerList = [...this.state.plannerList];
    let deletedPlannerRow = [...this.state.deletedPlannerRow];
    let deletedFiles = [...this.state.deletedFiles];
    if (item.id !== null) {
      if (item.resources.length > 0) {
        item.resources.forEach((el) => {
          deletedFiles.push(el.id);
        });
      }

      deletedPlannerRow.push(item.id);
      this.setState({
        deletedPlannerRow,
        deletedFiles,
      });
    }
    plannerList.splice(idx, 1);
    this.setState({ plannerList });
  };

  filePreview = (e, image, idx) => {
    this.setState({
      filePreviewModal: image,
    });
  };

  getSubjectsByYearLevel = () => {
    let param = { yearLevelId: this.state.yearLevel };
    axiosPost(URL.getSubjectForPlanner, param, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        let newData = [];
        if (datum.length > 0) {
          datum.forEach((el) => {
            newData.push({
              label: el.name,
              value: el.id,
            });
          });
        }
        this.setState({ subjects: newData }, () => {
          if (this.props.plannerEditModal) {
            let selectedSubject = this.state.subjects.find(
              (el) =>
                el.value ===
                this.props.selectedPlannerList.subjects[0].subjectId
            );

            this.setState({
              selectedSubjects: selectedSubject,
            });
          }
        });
      }
    });
  };

  getWeekDays = () => {
    axiosPost(URL.getWeekDays, {}, (response) => {
      if (response.status === 200) {
        this.setState({ weekDays: response.data.data });
      }
    });
  };

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };
  handleAddResourcesModal = (e, idx) => {
    let plannerList = [...this.state.plannerList];

    if (this.props.plannerEditModal) {
      if (plannerList[idx].resources.length > 0) {
        plannerList[idx].resources.forEach((image) => {
          if (!image.isNew) {
            image.src = `${URL.imageSelectURL + image.filePath}`;
            image.image = {
              type: image.fileType === "IMG" ? "image/png" : "application/pdf",
              name: image.fileName,
            };
            image.isNew = false;
          }
        });
        let previewFile = plannerList[idx].resources[0];
        this.setState({
          plannerList,
          filePreviewModal: previewFile,
        });
      } else {
        this.setState({
          filePreviewModal: "",
        });
      }
    } else {
      if (plannerList[idx].resources.length > 0) {
        let previewFile = plannerList[idx].resources[0];
        this.setState({
          filePreviewModal: previewFile,
        });
      } else {
        this.setState({
          filePreviewModal: "",
        });
      }
    }
    this.setState({
      resourceFileModal: true,
      activePL: idx,
    });
  };

  handleCancel = () => {
    this.setState({
      endDate: "",
      noOfPeriods: "",
      plannerList: [
        {
          date: "",
          weekdayId: "",
          topic: "",
          objectives: "",
          methodology: "",
          classAssignment: "",
          homeAssignment: "",
          remark: "",
          resources: [],
        },
      ],
      selectedSubjects: [],
      startDate: "",
      subjects: [],
      yearLevel: "",
    });
  };

  handleChangeResourceFile = (e, idx) => {
    const { name, type } = e.target;

    if (type === "file") {
      let plannerList = [...this.state.plannerList];
      let data = e.target.files;

      for (let i = 0; i < data.length; i++) {
        plannerList[idx][name].push({
          src: window.URL.createObjectURL(data[i]),
          image: data[i],
          isNew: true,
        });
      }

      let plLength = plannerList[idx].resources.length - 1;
      let previewFile = plannerList[idx].resources[plLength];

      this.setState({
        plannerList,
        filePreviewModal: previewFile,
      });
    }
  };

  handleChange = (e, idx) => {
    let plannerList = [...this.state.plannerList],
      { name, value } = e.target;

    if (name === "date") {
      if (this.state.startDate === "" || this.state.endDate === "") {
        swal("Error", "Fill the values for start and end date first");
        return false;
      }
      plannerList[idx][name] = value;
      let date = value;
      let dayName = moment(date).format("dddd");
      let weekDays = [...this.state.weekDays];
      let index = weekDays.findIndex((el) => el.name === dayName);
      plannerList[idx].weekdayId = weekDays[index].id;
      this.setState({ plannerList });
    } else {
      plannerList[idx][name] = value;
      this.setState({ plannerList });
    }
  };

  handleChangeEvent = (item) => {
    let selectedSubject = [];

    selectedSubject.push(item);

    this.setState({ selectedSubjects: selectedSubject });
  };

  handleDataChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      if (name === "startDate") {
        this.setState({
          endDate: "",
        });
      }

      if (name === "yearLevel") {
        this.setState({ selectedSubjects: [] }, function () {
          this.getSubjectsByYearLevel();
        });
      }

      let newPL = this.state.plannerList;

      if (name === "startDate") {
        newPL.forEach((pl) => {
          var d1 = new Date(value);
          var d2 = new Date(pl.date);

          if (d1.getTime() > d2.getTime()) {
            pl.date = "";
          }
        });
      }
      if (name === "endDate") {
        newPL.forEach((pl) => {
          var d1 = new Date(value);
          var d2 = new Date(pl.date);

          if (d1.getTime() < d2.getTime()) {
            pl.date = "";
          }
        });
      }
      this.setState({
        plannerList: newPL,
      });
    });
  };

  handleRemoveResourceFile = (idx, file) => {
    let plannerList = [...this.state.plannerList];
    let deletedFiles = [...this.state.deletedFiles];

    if (!file.isNew) {
      deletedFiles.push(file.id);
      this.setState({
        deletedFiles,
      });
    }

    plannerList[this.state.activePL].resources.splice(idx, 1);

    let previewFile = this.state.filePreviewModal;

    this.setState({ plannerList });
    if (previewFile?.src === file.src) {
      this.setState({
        filePreviewModal: "",
      });
    }
  };

  handleUpdatePlanner = () => {
    let subjects = [];
    subjects.push(this.state.selectedSubjects);

    let plannerList = [...this.state.plannerList];
    let fileIndex = [];
    let files = [];

    plannerList.forEach((item, plidx) => {
      if (item.resources.length > 0) {
        item.resources.forEach((el, idx) => {
          if (el.isNew) {
            files.push(el.image);
            fileIndex.push(plidx);
          }
        });
      }
    });

    let formData = new FormData();

    let newPL = cloneDeep([...this.state.plannerList]);

    newPL.forEach((object) => {
      delete object["resources"];
      delete object["plannerListResources"];
    });

    let param = {
      endDate: this.state.endDate,
      periods: this.state.noOfPeriods,
      id: this.props.selectedPlannerList.id,
      plannerList: newPL,
      deletedFiles: this.state.deletedFiles,
      deletedPlannerRow: this.state.deletedPlannerRow,
      // subjects: subjects,
      startDate: this.state.startDate,
      // yearlevelId: this.state.yearLevel,
    };

    formData.append("params", JSON.stringify(param));
    files.forEach((image) => formData.append("files", image));
    if (fileIndex.length > 0) {
      formData.append("fileIndex", JSON.stringify(fileIndex));
    }

    let validationErr = 0;

    newPL.forEach((pl) => {
      if (
        // pl.classAssignment === "" ||
        pl.date === "" ||
        // pl.homeAssignment === "" ||
        pl.methodology === "" ||
        pl.objectives === "" ||
        pl.remark === "" ||
        pl.topic === "" ||
        pl.weekdayId === ""
      ) {
        validationErr++;
      }
    });

    if (validationErr > 0) {
      swal("Error", "Fill all the required fields for the planner");
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.updatePlanner,
          formData,
          (res) => {
            if (res.status === 200) {
              displaySuccessAlert(res);
              this.setState({ showSpinner: false });
              this.handleCancel();
              this.props.closeEditModal();
              this.props.handleSearch();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({ showSpinner: false });
          }
        );
      });
    }
  };

  updateConfig = () => {
    let selectedPLData = this.props.selectedPlannerList;
    let plData = selectedPLData.plannerList;

    let newPL = plData.map((data) => {
      data.date = moment(data.date).format("yyyy-MM-DD");
      data.resources = data.plannerListResources;
      return data;
    });

    this.setState(
      {
        endDate: moment(selectedPLData.endDate).format("yyyy-MM-DD"),
        noOfPeriods: selectedPLData.periods,
        plannerList: newPL,
        // selectedSubjects: newSubs[0],
        startDate: moment(selectedPLData.startDate).format("yyyy-MM-DD"),
        yearLevel: selectedPLData.yearlevelId,
        spinner: false,
        resourceFileModal: false,
        activePL: null,
        filePreviewModal: "",
      },
      () => {
        this.getSubjectsByYearLevel();
      }
    );
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        {!this.props.plannerEditModal && (
          <div className="tt-group-header">
            Insert Planner
            {checkWidgetAdminOrNot("Planner") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
        )}
        <div className="row">
          <div className="col">
            <label>
              <strong>Year Level</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
            <select
              className="form-control"
              name="yearLevel"
              value={this.state.yearLevel}
              onChange={this.handleDataChange}
              // placeholder="Choose Year Level"
              disabled={this.props.plannerEditModal ? true : false}
            >
              <option value="" disabled selected>
                Choose Year Level
              </option>
              {this.state.yearLevels.length > 0
                ? this.state.yearLevels.sort(compareByName).map((yLvl, idx) => {
                    return (
                      <option value={yLvl.id} key={idx}>
                        Class {yLvl.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col">
            <label>
              <strong>Subject</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
            <Select
              menuPlacement="auto"
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={this.state.subjects}
              placeholder="Choose Subjects"
              name="Subject"
              onChange={this.handleChangeEvent}
              closeMenuOnScroll={false}
              value={this.state.selectedSubjects}
              isDisabled={
                this.state.yearLevel === "" || this.props.plannerEditModal
              }
            />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <div className="row">
              <div className="col-md-4 mt-1">
                <strong>Total Number of Periods</strong>
                <span className="tt-assessment-module-mandatory">*</span>
              </div>
              <div className="col">
                <input
                  type="number"
                  name="noOfPeriods"
                  className="form-control"
                  value={this.state.noOfPeriods}
                  onChange={this.handleDataChange}
                  placeholder="Add Total Number of Periods"
                  min={0}
                  // disabled={this.props.plannerEditModal}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-md-4 mt-1">
                    <strong>Start Date</strong>
                    <span className="tt-assessment-module-mandatory">*</span>
                  </div>
                  <div className="col pl-0">
                    <input
                      type="date"
                      name="startDate"
                      className="form-control"
                      value={this.state.startDate}
                      onChange={this.handleDataChange}
                      min={new Date().toISOString().substring(0, 10)}
                      // max={
                      //   this.state.endDate === "" ? null : this.state.endDate
                      // }
                      // disabled={this.props.plannerEditModal}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-md-4 mt-1">
                    <strong>End Date</strong>
                    <span className="tt-assessment-module-mandatory">*</span>
                  </div>
                  <div className="col pl-0">
                    <input
                      type="date"
                      name="endDate"
                      className="form-control"
                      value={this.state.endDate}
                      onChange={this.handleDataChange}
                      min={this.state.startDate}
                      // disabled={
                      //   this.state.startDate === "" ||
                      //   this.props.plannerEditModal
                      // }
                      disabled={this.state.startDate === ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 pb-2">
          <table className="table table-bordered table-striped mb-0 text-center">
            <thead className="tt-group-header">
              <tr>
                <th>
                  Date / Day{" "}
                  <span className="tt-assessment-module-mandatory">*</span>
                </th>
                <th>
                  Topic{" "}
                  <span className="tt-assessment-module-mandatory">*</span>
                </th>
                <th>
                  Objectives{" "}
                  <span className="tt-assessment-module-mandatory">*</span>
                </th>
                <th>
                  Materials / Methodology{" "}
                  <span className="tt-assessment-module-mandatory">*</span>
                </th>
                <th>Class Assignment </th>
                <th>Home Assignment </th>
                <th>
                  Evalution / Marks{" "}
                  <span className="tt-assessment-module-mandatory">*</span>
                </th>
                <th>Resources</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.plannerList.length > 0 ? (
                this.state.plannerList.map((planner, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          value={planner.date}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="Select Date"
                          min={this.state.startDate}
                          max={this.state.endDate}
                          disabled={
                            this.state.startDate === "" &&
                            this.state.endDate === ""
                          }
                        />
                        <br></br>
                        <select
                          name="weekdayId"
                          value={planner.weekdayId}
                          className="form-control"
                          onChange={(e) => this.handleChange(e, idx)}
                          disabled
                        >
                          <option value="" disabled selected>
                            Weekday
                          </option>
                          {this.state.weekDays.map((day, wIdx) => {
                            return (
                              <option key={wIdx} value={day.id}>
                                {day.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="topic"
                          value={planner.topic}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="Add Topic"
                        />
                      </td>
                      <td>
                        <textarea
                          className="form-control"
                          name="objectives"
                          value={planner.objectives}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="Add Objectives"
                        />
                      </td>
                      <td>
                        <textarea
                          className="form-control"
                          name="methodology"
                          value={planner.methodology}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="Add Materials / Methodology"
                        />
                      </td>
                      <td>
                        <textarea
                          className="form-control"
                          name="classAssignment"
                          value={planner.classAssignment}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="Add Class Assignment"
                        />
                      </td>
                      <td>
                        <textarea
                          className="form-control"
                          name="homeAssignment"
                          value={planner.homeAssignment}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="Add Home Assigment"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="remark"
                          value={planner.remark}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="Add Evaluation Marks"
                        />
                      </td>
                      <td>
                        <button
                          className="tt-button tt-button-primary"
                          onClick={(e) => this.handleAddResourcesModal(e, idx)}
                        >
                          Add Files
                        </button>
                        <br />
                        {planner.resources?.length > 0 ? (
                          <p>{planner.resources?.length} files</p>
                        ) : (
                          <p>No Files Added</p>
                        )}
                      </td>
                      <td>
                        {idx !== 0 ? (
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="removeMcqOption mb-2"
                            onClick={() => this.deleteRow(idx, planner)}
                          />
                        ) : null}
                        {idx === this.state.plannerList.length - 1 ? (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="addMcqOption"
                            onClick={this.addRow}
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8">No data</td>
                  <td style={{ padding: "0px" }}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="addMcqOption"
                      onClick={this.addRow}
                      style={{ margin: "5px" }}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div
          className="row mt-3 button-bg-color"
          style={{ position: "absolute", bottom: 0, width: "100%" }}
        >
          <div
            className="col text-right"
            style={{ marginRight: this.props.plannerEditModal ? "0" : "12%" }}
          >
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={
                this.props.plannerEditModal
                  ? this.handleUpdatePlanner
                  : this.createPlanner
              }
            >
              {this.props.plannerEditModal ? "Update" : "Create"}
            </button>

            <button
              className="tt-button tt-button-primary mr-3"
              onClick={
                this.props.plannerEditModal
                  ? this.props.closeEditModal
                  : this.handleCancel
              }
            >
              Cancel
            </button>
          </div>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait....
            </div>
          </div>
        ) : null}

        <ModalWindow
          modal={this.state.resourceFileModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              resourceFileModal: !this.state.resourceFileModal,
            });
          }}
          modalHeader={"Add Resource Files"}
          modalBody={
            <div>
              <AddResourcesFile
                plannerEditModal={this.props.plannerEditModal}
                handleChangeResourceFile={this.handleChangeResourceFile}
                activePL={this.state.activePL}
                plannerList={this.state.plannerList}
                filePreviewModal={this.state.filePreviewModal}
                filePreview={this.filePreview}
                handleRemoveResourceFile={this.handleRemoveResourceFile}
              />
            </div>
          }
        />

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Planner"
              moduleName="Planner"
              header="Insert Planner"
              activityName="insert-planner"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default Planner;
