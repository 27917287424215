import React, { Component } from "react";
import ListSubject from "./ListSubject";
import SubjectForm from "./SubjectForm";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";

export class Subject extends Component {
  state = {
    subjects: [],
    modal: false,
  };

  componentDidMount() {
    this.getAllSubjects();
  }

  getAllSubjects = () => {
    axiosPost(URL.getAllSubject, {}, (response) => {
      if (response.status == 200) {
        this.setState({
          subjects: response.data.data,
        });
      }
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="whiteContainer">
                <CustomPageHeader
                  title="Subjects"
                  onClick={this.toggleModal}
                  icon="add_box"
                  noPermission={true}
                />

                <ListSubject
                  subjects={this.state.subjects}
                  getAllSubjects={this.getAllSubjects}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          size="lg"
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          modalHeader="Add Subjects"
          modalBody={
            <SubjectForm
              getAllSubjects={this.getAllSubjects}
              toggleModal={this.toggleModal}
            />
          }
        />
      </div>
    );
  }
}

export default Subject;
