import React, { Component } from "react";
import "../../../../src/assets/scss/comment.scss";
import avatar from "../../../assets/images/user-image.png";
import moment from "moment";
import { displayConfirmDeleteAlert, handleError } from "../../../utils/Utils";
import {
  userRoleCode,
  checkRolePermissions,
  URL,
} from "../../../utils/Constants";
import { Link } from "react-router-dom";

class Comment extends Component {
  state = {};
  render() {
    return (
      <div className="panel-body">
        <li className="list-group-item">
          <div className="row">
            <div className="col-md-auto col-sm-auto mobileCommentimage tt-adjustPadding text-center">
              {this.props.comment ? (
                this.props.comment.photo ? (
                  <img
                    alt="commentphoto"
                    src={URL.imageSelectURL + this.props.comment.photo}
                    onError={(e) => handleError(e)}
                    className={
                      (this.props.notice
                        ? "img-NoticecommentDisplay "
                        : "img-commentDisplay ") +
                      (this.props.fullPage
                        ? "imageNewDimension"
                        : "image-dimension")
                    }
                  ></img>
                ) : (
                  <img
                    alt="avatar"
                    src={avatar}
                    className={
                      "img-NoticecommentDisplay " +
                      (this.props.fullPage
                        ? "imageNewDimension"
                        : "image-dimension")
                    }
                  ></img>
                )
              ) : null}
            </div>
            <div className="col">
              <div>
                <div className="mic-info">
                  <p>
                    {this.props.comment ? (
                      this.props.comment.userRole ===
                      userRoleCode.roleStudentContact ? (
                        <Link
                          to={{
                            pathname: `/familyPage/${this.props.comment.familyId}`,
                            familyPageOpen: true,
                            userId: this.props.comment.userId,
                          }}
                        >
                          <strong>{this.props.comment.peopleName}</strong>
                        </Link>
                      ) : (
                        <Link
                          to={{
                            pathname: `/profile/${this.props.comment.username}`,
                            username: this.props.comment.username,
                          }}
                        >
                          <strong>{this.props.comment.peopleName}</strong>
                        </Link>
                      )
                    ) : null}{" "}
                    <span className="text-muted">
                      <i>
                        said {moment(this.props.comment.createdAt).calendar()}({" "}
                        {moment(this.props.comment.createdAt).fromNow()})
                      </i>
                    </span>
                  </p>
                </div>
              </div>
              <div className="comment-text">
                {this.props.comment.editable ? (
                  <input
                    type="text"
                    className="form-control"
                    value={this.props.newCommentValue}
                    name="newCommentValue"
                    onChange={this.props.handleChange}
                  />
                ) : (
                  this.props.comment.commentValue
                )}
              </div>
              <div className="action">
                {this.props.comment.editable ? (
                  <button
                    type="button"
                    className="tt-button tt-button-primary"
                    onClick={() =>
                      this.props.updateComment(
                        this.props.newCommentValue,
                        this.props.comment
                      )
                    }
                  >
                    Save
                  </button>
                ) : checkRolePermissions("edit-post-comment") &&
                  this.props.comment.ownPost ? (
                  <button
                    type="button"
                    className="tt-button tt-button-primary"
                    title="Edit"
                    onClick={() => this.props.toggleEditable(this.props.idx)}
                  >
                    Edit
                  </button>
                ) : null}
                {checkRolePermissions("delete-post-comment") &&
                this.props.comment.ownPost ? (
                  <button
                    type="button"
                    className="tt-button tt-button-primary"
                    title="Approved"
                    onClick={() =>
                      displayConfirmDeleteAlert(
                        this.props.comment.id,
                        this.props.deleteComment
                      )
                    }
                  >
                    Delete
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </li>
      </div>
    );
  }
}

export default Comment;
