/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, GeneralASSectionThree, render the Model for General Assessment Settings in the
 *              Record Setup in the Primary ARR
 */

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import DisciplineTableRow from "./DisciplineTableRow";
import { Spinner } from "reactstrap";

class GeneralASSectionThree extends Component {
  state = {};

  tableRow() {
    return this.props.overall
      .assessmentGeneralCustomAssessmentRubricsConfigurations !== undefined ? (
      this.props.overall.assessmentGeneralCustomAssessmentRubricsConfigurations.map(
        (discipline, i) => {
          return (
            <DisciplineTableRow
              discipline={discipline}
              idx={i}
              handleRemoveDisciplineRow={this.props.handleRemoveDisciplineRow}
              handleNestedChange={this.props.handleNestedChange}
              handleUpdateDisciplineRow={this.props.handleUpdateDisciplineRow}
              mainIndex={this.props.idx}
              overall={this.props.overall}
              handleCustomDisciplineRowChange={
                this.props.handleCustomDisciplineRowChange
              }
            />
          );
        }
      )
    ) : (
      <tr>
        <td>
          {" "}
          <Spinner type="grow" color="primary" />
        </td>
      </tr>
    );
  }
  render() {
    return (
      <div className="row tt-generalTableContainer" key={this.props.idx}>
        <table border={1} className="table-striped" width="100%">
          <thead className="tt-AssessmentTitle">
            <tr>
              <th className="tt-subjectDisplayTable">
                {this.props.overall.customAssessmentHeaderName} Rubrics
              </th>
              {this.props.overall
                ? this.props.overall.rubricsScales
                  ? this.props.overall.rubricsScales.map((scaleName, i) => (
                      <>
                        <th className="tt-subjectDisplayTable">
                          {scaleName.scaleName}&nbsp;&nbsp;
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={(e) =>
                              this.props.handleDeleteScaleTableDataForRubric(
                                e,
                                this.props.idx,
                                i
                              )
                            }
                          />
                        </th>
                      </>
                    ))
                  : null
                : null}

              <th className="tt-subjectDisplayTable">Options</th>
            </tr>
          </thead>
          <tbody>{this.tableRow()}</tbody>
          <tfoot>
            <tr>
              <th colSpan={3}>
                <div className="container-fluid">
                  <div className="row tt-generalDiscipline">
                    <div className="col">
                      <label htmlFor="rubric">Rubric</label>
                      <input
                        type="text"
                        name="customRubricsName"
                        className="form-control"
                        value={this.props.customRubricsName}
                        onChange={(e) =>
                          this.props.handleDisciplineInputChange(
                            e,
                            this.props.idx
                          )
                        }
                        style={{ height: "28px" }}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="description">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        name="customDescription"
                        value={this.props.customDescription}
                        onChange={(e) =>
                          this.props.handleDisciplineInputChange(
                            e,
                            this.props.idx
                          )
                        }
                        style={{ height: "28px" }}
                      />
                    </div>
                    <div className="col-md-auto">
                      <button
                        id="addButton"
                        className="tt-button tt-button-primary mt-4"
                        onClick={(e) =>
                          this.props.addMultipleDisciplineRow(e, this.props.idx)
                        }
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

export default GeneralASSectionThree;
