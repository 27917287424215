import React, { Component } from "react";

class IndicatorListRow extends Component {
  state = {};

  handleKeyPress = (e) => {
    if (e.target.value.includes(".")) {
      let numbers = e.target.value.split(".");
      if (numbers[1].length === 2) {
        e.preventDefault();
      }
    }
  };

  render() {
    let indicator = this.props.val;
    return (
      <tr key={this.props.id}>
        <td
          className="associateLevel"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="number"
            name="leftGradeValue"
            className="form-control"
            onChange={(e) =>
              this.props.associateIndicatorDescriptorHandler(
                e,
                this.props.id,
                "leftGradeValue"
              )
            }
            min="0"
            step="0.01"
            onKeyPress={this.handleKeyPress}
            value={this.props.range ? this.props.range.rangeMin : ""}
          />

          <span className="range-minus">{"-"}</span>

          <input
            type="number"
            name="rightGradeValue"
            className="form-control"
            onChange={(e) =>
              this.props.associateIndicatorDescriptorHandler(
                e,
                this.props.id,
                "rightGradeValue"
              )
            }
            min="0"
            step="0.01"
            onKeyPress={this.handleKeyPress}
            value={this.props.range ? this.props.range.rangeMax : ""}
          />
        </td>
        <td className="text-center">{indicator.level}</td>
        <td>{indicator.descriptor}</td>
      </tr>
    );
  }
}

export default IndicatorListRow;
