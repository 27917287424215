import React, { Component } from "react";
import "../../../assets/scss/account.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

class AssignInventoryItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let props = this.props;
    return (
      <div className="tt-inventoryItemListHolder container-fluid">
        <div className="container-fluid">
          <div className="row tt-inventoryCategoryListHolder">
            <div className="col-md-3">Category</div>
            <div className="col-md-7">Items</div>
            <div className="col-md-2"></div>
          </div>
          {props.assignedInventories.map((inventory, idx) => {
            return (
              <div className="row assignInventoryHolder" key={idx}>
                <div
                  className="col-md-3"
                  style={{ borderRight: "1px solid #ccc" }}
                >
                  <select
                    name="feeCategoryId"
                    value={inventory.feeCategoryId}
                    className="form-control myCss"
                    onChange={(e) => props.handleAssignChange(e, idx)}
                  >
                    <option value="" disabled selected>
                      Choose Category
                    </option>
                    {props.categories.map((el, sidx) => (
                      <option value={el.id} key={sidx} disabled={el.isDisabled}>
                        {el.category}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="col-md-7"
                  style={{ borderRight: "1px solid #ccc" }}
                >
                  {inventory.inventoryItems.length > 0 ? (
                    inventory.inventoryItems.map((item, index) => {
                      return (
                        <div className="row mt-2 " key={index}>
                          <div className="col mt-1">
                            <strong> {item.itemName}</strong>
                          </div>
                          <div className="col-md-1 mt-1">
                            <input
                              type="checkbox"
                              className="form-control"
                              checked={item.isChecked}
                              onChange={(e) =>
                                props.handleCheckboxChange(e, idx, index)
                              }
                              disabled={
                                item.instock && item.instock > 0 ? false : true
                              }
                            ></input>
                          </div>
                          <div className="col">
                            <strong>In Stock:</strong>{" "}
                            {item.instock ? item.instock : 0}
                          </div>
                          <div className="col-md-4">
                            {item.instock && item.instock > 0 ? null : (
                              <div style={{ color: "#cc0000" }}>
                                <strong>( Out of Stock )</strong>
                              </div>
                            )}
                            {item.isChecked ? (
                              <div>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={
                                    item.quantity === 0 ? null : item.quantity
                                  }
                                  onChange={(e) =>
                                    props.handleQuantityChange(e, idx, index)
                                  }
                                  placeholder="Enter Quantity"
                                  min={0}
                                  max={item.instock}
                                  onKeyDown={(e) => {
                                    // Prevent the user from entering negative values using keyboard input
                                    if (e.key === "-" || e.key === "-") {
                                      e.preventDefault();
                                    }
                                  }}
                                ></input>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No Inventory Item assigned for this category</div>
                  )}
                </div>
                <div
                  className="col-md-2"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    paddingBottom: "10px",
                  }}
                >
                  {idx > 0 && (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      onClick={() => props.deleteAssignedInventories(idx)}
                      className="removeMcqOption"
                    />
                  )}
                  {idx === props.assignedInventories.length - 1 && (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      onClick={props.addAssignedInventories}
                      className="addMcqOption ml-3"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AssignInventoryItemList;
