import React, { Component } from "react";

class BuildingSectionTableRow extends Component {
  state = {};
  render() {
    let currentObj = this.props.obj;
    return (
      <tr key={this.props.idx}>
        <td>
          {currentObj.editable ? (
            <input
              type="text"
              value={this.props.updatedSectionName}
              id={"section" + this.props.idx}
              name="updatedSectionName"
              onChange={(e) => this.props.handleChange(e)}
            />
          ) : (
            currentObj.name
          )}
        </td>

        {currentObj.editable ? (
          <td>
            <button
              className="tt-button tt-button-primary"
              onClick={(e) =>
                this.props.updateSectionSubmit(e, currentObj.buildingId)
              }
            >
              Save
            </button>
            <button
              className="tt-button tt-button-primary ml-2"
              onClick={(e) => this.props.handleCancelUpdate(this.props.idx)}
            >
              Cancel
            </button>
          </td>
        ) : (
          <>
            {this.props.permissionEditFacility ||
            this.props.permissionDeleteFacility ? (
              <td>
                {this.props.permissionEditFacility ? (
                  <button
                    className="tt-button tt-button-primary"
                    onClick={(e) =>
                      this.props.handleSectionUpdate(this.props.idx)
                    }
                  >
                    Edit
                  </button>
                ) : null}
                {this.props.permissionDeleteFacility ? (
                  <button
                    className="tt-button tt-button-primary ml-2"
                    onClick={(e) =>
                      this.props.deleteSectionName(
                        e,
                        currentObj.id,
                        currentObj.buildingId
                      )
                    }
                  >
                    Delete
                  </button>
                ) : null}
              </td>
            ) : null}
          </>
        )}
      </tr>
    );
  }
}

export default BuildingSectionTableRow;
