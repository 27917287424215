import React, { Component } from "react";
import { URL } from "../../utils/Constants";

export class TrainingResources extends Component {
  render() {
    return (
      <div className="row">
        {this.props.resourceData?.images?.length > 0 ? (
          this.props.resourceData.images.map((data, idx) => (
            <div className="col-md-3">
              <div className="training-resources-img">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => this.props.deleteResourceImg(data.id, idx)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <img src={URL.imageSelectURL + data.name} />
              </div>
            </div>
          ))
        ) : (
          <div className="col text-center py-2">No resources added</div>
        )}
      </div>
    );
  }
}

export default TrainingResources;
