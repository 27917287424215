import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  checkRolePermissions,
  draggable,
  regEx,
  URL,
} from "../../../utils/Constants";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
import ModalWindow from "../../UI/ModalWindow";
import CourseClasses from "./CourseClass/CourseClasses";
import CourseTopicUpdateModal from "./CourseTopicUpdateModal";

import CreateCourseSubject from "./CreateCourseSubject";
import ListCourseSubject from "./ListCourseSubject";
import SubjectTopicSetting from "./SubjectTopicSetting";
import TopicDetailModal from "./TopicDetailModal";

class CourseSubjectSetting extends Component {
  state = {
    name: "",
    description: "",
    credit: "",
    code: "",
    courseSubjectList: [],
    courseSubjectSpinner: false,
    courseSubjectOffset: 0,
    courseSubjectRowCountLimit: 5,
    courseSubjectCurrentPage: 1,
    courseSubjectTotalRecords: 0,
    topicModal: false,
    selectedSubject: "",
    topicDetailModal: false,
    id: "",
    updateName: "",
    updateDescription: "",
    updateCredit: "",
    updateCode: "",
    updateTopicName: "",
    updateTopicLectureHour: "",
    updateTopicPracticalHour: "",
    updateTopicModal: false,
    updateType: "",
    classModal: false,
    courseTopicModal: false,
    courseTopicId: "",
    isMandatory: false,
  };

  handleMandatoryToggleBtn = () => {
    this.setState({ isMandatory: !this.state.isMandatory });
  };

  componentDidMount() {
    this.getCourseSubjectList();
  }

  getCourseSubjectList = () => {
    let data = {
      courseId: this.props.selectedList.id,
      offset: this.state.courseSubjectOffset,
      rowCountLimit: this.state.courseSubjectRowCountLimit,
    };
    this.setState({ courseSubjectSpinner: true }, function () {
      axiosPost(
        URL.getCourseSubject,
        data,
        (response) => {
          this.setState({ courseSubjectSpinner: false });
          if (response.status === 200) {
            let courseSubjectList = response.data.data;
            if (courseSubjectList.length > 0) {
              courseSubjectList.forEach((el) => {
                el.isEditable = false;
              });
            }
            this.setState(
              {
                courseSubjectList,
                courseSubjectTotalRecords: response.data.totalRecordsCount,
              },
              function () {
                let selectedSubject = this.state.selectedSubject;
                let courseSubjectList = this.state.courseSubjectList;
                if (selectedSubject && courseSubjectList.length > 0) {
                  courseSubjectList.forEach((el) => {
                    if (el.id === selectedSubject.id) {
                      selectedSubject = el;
                    }
                  });
                  this.setState({ selectedSubject });
                }
              }
            );
          }
        },
        (er) => {
          this.setState({ courseSubjectSpinner: false });
        }
      );
    });
  };

  clearState = () => {
    this.setState({
      name: "",
      description: "",
      credit: "",
      code: "",
      id: "",
      updateName: "",
      updateDescription: "",
      updateCredit: "",
      updateCode: "",
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "name" || name === "updateName") {
      if (!value.match(regEx)) {
        swal({
          title: "Warning",
          text: "Character not allowed",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  createCourseSubject = () => {
    if (this.state.name === "" || this.state.code === "") {
      swal({
        title: "Error",
        text: "Fill all the required fields.",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    }
    let data = {
      name: this.state.name,
      description: this.state.description,
      credit: this.state.credit,
      courseId: this.props.selectedList.id,
      code: this.state.code,
      isMandatory: this.state.isMandatory,
    };
    axiosPost(URL.insertCourseSubject, data, (response) => {
      if (response.status === 200) {
        swal({ title: "Success", text: "Subject successfully created" });
        this.clearState();
        this.setState(
          {
            courseSubjectOffset: 0,
            courseSubjectCurrentPage: 1,
          },
          function () {
            this.getCourseSubjectList();
          }
        );
      }
    });
  };

  //Pagination
  handlePreviousPage = () => {
    if (this.state.courseSubjectCurrentPage !== 1) {
      this.reRenderCourseSubjectList(this.state.courseSubjectCurrentPage - 1);
      this.setState((prevState) => ({
        courseSubjectCurrentPage: prevState.courseSubjectCurrentPage - 1,
      }));
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.courseSubjectCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(
        this.state.courseSubjectTotalRecords /
          this.state.courseSubjectRowCountLimit
      )
    )
      return;
    else {
      this.reRenderCourseSubjectList(nextPage);
      this.setState({ courseSubjectCurrentPage: nextPage });
    }
  };

  handlePageChange = (page) => {
    this.reRenderCourseSubjectList(page);
    this.setState({ courseSubjectCurrentPage: page });
  };

  reRenderCourseSubjectList = (page) => {
    let offset =
      page * parseInt(this.state.courseSubjectRowCountLimit) -
      parseInt(this.state.courseSubjectRowCountLimit);
    this.setState(
      { courseSubjectOffset: offset, courseSubjectSpinner: true },
      function () {
        let data = {
          courseId: this.props.selectedList.id,
          offset: offset,
          rowCountLimit: this.state.courseSubjectRowCountLimit,
        };
        axiosPost(
          URL.getCourseSubject,
          data,
          (response) => {
            this.setState({ courseSubjectSpinner: false });
            if (response.status === 200) {
              let courseSubjectList = response.data.data;
              if (courseSubjectList.length > 0) {
                courseSubjectList.forEach((el) => {
                  el.isEditable = false;
                });
              }
              this.setState({
                courseSubjectList,
                courseSubjectTotalRecords: response.data.totalRecordsCount,
              });
            }
          },
          (er) => {
            this.setState({ courseSubjectSpinner: false });
          }
        );
      }
    );
  };

  //Add Subject Topics and subtopics
  handleTopicModal = (sub) => {
    this.setState({ topicModal: !this.state.topicModal, selectedSubject: sub });
  };

  toggleTopicModal = () => {
    this.setState({ topicModal: !this.state.topicModal });
  };

  //Topic Detail
  handleTopicDetailModal = (sub) => {
    this.setState({
      topicDetailModal: !this.state.topicDetailModal,
      selectedSubject: sub,
    });
  };

  toggleTopicDetailModal = () => {
    this.setState({
      topicDetailModal: !this.state.topicDetailModal,
      selectedSubject: "",
    });
  };

  //Class Functions
  handleClassModal = (sub) => {
    this.setState({ classModal: !this.state.classModal, selectedSubject: sub });
  };

  toggleClassModal = () => {
    this.setState({ classModal: !this.state.classModal, selectedSubject: "" });
  };

  handleTopicClassModal = (id) => {
    this.setState({
      classModal: !this.state.classModal,
      courseTopicModal: true,
      courseTopicId: id,
    });
  };

  toggleTopicClassModal = () => {
    this.setState({
      classModal: !this.state.classModal,
      courseTopicModal: false,
      courseTopicId: "",
    });
  };

  //Update Subjects
  toggleCourseSubjectEditModal = (idx) => {
    let courseSubjectList = [...this.state.courseSubjectList];
    courseSubjectList.forEach((el) => {
      el.isEditable = false;
    });
    courseSubjectList[idx].isEditable = true;
    this.setState({
      courseSubjectList,
      updateName: courseSubjectList[idx].name,
      updateDescription: courseSubjectList[idx].description,
      id: courseSubjectList[idx].id,
      updateCode: courseSubjectList[idx].code,
      updateCredit: courseSubjectList[idx].credit,
      updateType: courseSubjectList[idx].isMandatory,
    });
  };

  cancelUpdate = (idx) => {
    let courseSubjectList = [...this.state.courseSubjectList];
    courseSubjectList[idx].isEditable = false;
    this.setState({
      courseSubjectList,
      id: "",
      updateName: "",
      updateDescription: "",
      updateCredit: "",
      updateCode: "",
      updateType: "",
    });
  };

  updateSubject = () => {
    let data = {
      id: this.state.id,
      name: this.state.updateName,
      description: this.state.updateDescription,
      credit: this.state.updateCredit,
      code: this.state.updateCode,
      isMandatory: this.state.updateType,
    };
    axiosPost(URL.updateCourseSubject, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Subject Successfully updated",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getCourseSubjectList();
        this.clearState();
      }
    });
  };

  //Update Course Topics
  handleUpdateCourseTopic = (data) => {
    this.setState({
      updateTopicName: data.name,
      updateTopicLectureHour: data.lectureHour,
      updateTopicPracticalHour: data.practicalHour,
      id: data.id,
      updateTopicModal: !this.state.updateTopicModal,
    });
  };

  toggleUpdateCourseTopic = () => {
    this.setState({
      updateTopicName: "",
      updateTopicLectureHour: "",
      updateTopicPracticalHour: "",
      id: "",
      updateTopicModal: !this.state.updateTopicModal,
    });
  };

  updateCourseTopic = () => {
    let data = {
      id: this.state.id,
      name: this.state.updateTopicName,
      lectureHour: this.state.updateTopicLectureHour,
      practicalHour: this.state.updateTopicPracticalHour,
    };
    axiosPost(URL.updateCourseTopic, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Topic successfully updated",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();

        this.getCourseSubjectList();
        this.toggleUpdateCourseTopic();
      }
    });
  };

  //Delete Subjects
  handleDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deleteSubject);
  };

  deleteSubject = (datum) => {
    let data = {
      id: datum.id,
    };
    axiosPost(URL.deleteCourseSubject, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Subject successfully deleted",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getCourseSubjectList();
      }
    });
  };

  //Delete Course Topics
  handleCourseTopicDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deleteCourseTopic);
  };

  deleteCourseTopic = (datum) => {
    let data = { id: datum.id };
    axiosPost(URL.deleteCourseTopic, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Topics successfully deleted",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getCourseSubjectList();
      }
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "87.5vh" }}>
        <div className="tt-group-header">List of Subjects</div>
        <ListCourseSubject
          courseSubjectList={this.state.courseSubjectList}
          courseSubjectSpinner={this.state.courseSubjectSpinner}
          courseSubjectTotalRecords={this.state.courseSubjectTotalRecords}
          courseSubjectRowCountLimit={this.state.courseSubjectRowCountLimit}
          courseSubjectCurrentPage={this.state.courseSubjectCurrentPage}
          handlePageChange={this.handlePageChange}
          handleNextPage={this.handleNextPage}
          handlePreviousPage={this.handlePreviousPage}
          handleTopicModal={this.handleTopicModal}
          handleTopicDetailModal={this.handleTopicDetailModal}
          handleChange={this.handleChange}
          updateName={this.state.updateName}
          updateCode={this.state.updateCode}
          updateDescription={this.state.updateDescription}
          updateCredit={this.state.updateCredit}
          toggleCourseSubjectEditModal={this.toggleCourseSubjectEditModal}
          cancelUpdate={this.cancelUpdate}
          updateSubject={this.updateSubject}
          handleDelete={this.handleDelete}
          handleClassModal={this.handleClassModal}
          updateType={this.state.updateType}
        ></ListCourseSubject>
        {checkRolePermissions("can_create_program_subject", "activity") ? (
          <>
            <div className="tt-group-header">Create New Subject</div>
            <CreateCourseSubject
              name={this.state.name}
              description={this.state.description}
              credit={this.state.credit}
              handleChange={this.handleChange}
              createCourseSubject={this.createCourseSubject}
              clearState={this.clearState}
              isMandatory={this.state.isMandatory}
              handleMandatoryToggleBtn={this.handleMandatoryToggleBtn}
              code={this.state.code}
            ></CreateCourseSubject>
          </>
        ) : null}

        <ModalWindow
          modal={this.state.topicModal}
          toggleModal={this.toggleTopicModal}
          modalHeader={this.state.selectedSubject.name + " - Topic Setting"}
          size="xl"
          modalBody={
            <SubjectTopicSetting
              selectedSubject={this.state.selectedSubject}
              getCourseSubjectList={this.getCourseSubjectList}
            ></SubjectTopicSetting>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.topicDetailModal}
          toggleModal={this.toggleTopicDetailModal}
          modalHeader="Course Details"
          fullWidth={true}
          modalBody={
            <TopicDetailModal
              topicList={
                this.state.selectedSubject.courseTopics
                  ? this.state.selectedSubject.courseTopics
                  : []
              }
              handleUpdateCourseTopic={this.handleUpdateCourseTopic}
              handleTopicClassModal={this.handleTopicClassModal}
              handleCourseTopicDelete={this.handleCourseTopicDelete}
            ></TopicDetailModal>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.classModal}
          toggleModal={
            this.state.courseTopicModal
              ? this.toggleTopicClassModal
              : this.toggleClassModal
          }
          modalHeader="Classes"
          fullWidth={true}
          modalBody={
            this.state.courseTopicModal ? (
              <CourseClasses
                topicId={this.state.courseTopicId}
                subjectId={
                  this.state.selectedSubject
                    ? this.state.selectedSubject.id
                    : null
                }
                topicModal={true}
              ></CourseClasses>
            ) : (
              <CourseClasses
                subjectId={this.state.selectedSubject.id}
              ></CourseClasses>
            )
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.updateTopicModal}
          toggleModal={this.toggleUpdateCourseTopic}
          modalHeader="Edit Course"
          modalBody={
            <CourseTopicUpdateModal
              updateTopicName={this.state.updateTopicName}
              updateTopicLectureHour={this.state.updateTopicLectureHour}
              updateTopicPracticalHour={this.state.updateTopicPracticalHour}
              handleChange={this.handleChange}
            ></CourseTopicUpdateModal>
          }
          modalFooter={
            <div className="container-fluid">
              <div className="row">
                <div className="col text-right">
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={this.updateCourseTopic}
                  >
                    Save
                  </button>
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.toggleUpdateCourseTopic}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default CourseSubjectSetting;
