import React, { Component } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

class IndicatorTypeColorPicker extends Component {
  state = {};

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.props.color.r}, ${this.props.color.g}, ${this.props.color.b}, ${this.props.color.a})`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    return (
      <>
        <div
          style={styles.swatch}
          onClick={(e) => this.props.handleClickColorPicker(this.props.idx)}
        >
          <div style={styles.color} />
        </div>
        {this.props.isColorPickerOpen ? (
          <div style={styles.popover}>
            <div
              style={styles.cover}
              onClick={() =>
                this.props.handleCloseForColorPicker(this.props.idx)
              }
            />
            <SketchPicker
              color={this.props.color}
              onChange={this.props.handleChangeForColorPicker}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default IndicatorTypeColorPicker;
