import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/Utils";

export class Feedback extends Component {
  state = {
    feedbackTypes: [],
    feedbackTypeId: "",
    description: "",
    spinner: false,
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    this.getFeedbackTypes();
  }

  getFeedbackTypes = () => {
    axiosPost(URL.getFeedbackTypes, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          feedbackTypes: response.data.data,
        });
      }
    });
  };

  handleSubmitFeedback = () => {
    let data = {
      feedbackTypeId: this.state.feedbackTypeId,
      description: this.state.description,
    };

    if (this.state.feedbackTypeId !== "") {
      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(
            URL.insertFeedback,
            data,
            (response) => {
              if (response.status === 200) {
                swal("Success", "Feedback successfully submitted");
                this.setState({
                  spinner: false,
                  feedbackTypeId: "",
                  description: "",
                });
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                spinner: false,
              });
            }
          );
        }
      );
    } else {
      swal("Error", "Feedback type is required!!");
    }
  };

  render() {
    return (
      <>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait !! Processing.....
            </div>
          </div>
        ) : null}

        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="container">
            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  Feedback Type
                  <span
                    className="tt-assessment-module-mandatory"
                    style={{ paddingLeft: "5px" }}
                  >
                    *
                  </span>
                </strong>
              </div>
              <div className="col">
                <select
                  className="form-control"
                  name="feedbackTypeId"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.feedbackTypeId}
                >
                  <option selected disabled value="">
                    Select type
                  </option>
                  {this.state.feedbackTypes.map((fb) => (
                    <option value={fb.feedbackTypeId}>
                      {fb.feedbackTypeName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-2 mt-1">
                <strong>
                  Description
                  {/* <span
                className="tt-assessment-module-mandatory"
                style={{ paddingLeft: "5px" }}
              >
                *
              </span> */}
                </strong>
              </div>
              <div className="col">
                <textarea
                  name="description"
                  placeholder="Enter description"
                  className="form-control"
                  value={this.state.description}
                  onChange={(e) => this.handleChange(e)}
                ></textarea>
              </div>
            </div>

            <div className="mt-3 text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.handleSubmitFeedback}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Feedback;
