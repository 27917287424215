import React, { Component, useRef } from "react";
import ModalWindow from "./../../../UI/ModalWindow";
import CommentsBank from "./../CommentsBank";
import JoditEditor from "jodit-react";
import userImage from "../../../../assets/images/user-image.png";
import {
  imageSelectURL,
  URL,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";
import { Spinner } from "reactstrap";
import { handleError } from "../../../../utils/Utils";
import moment from "moment";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: [
    "hr",
    "about",
    "fullsize",
    "eraser",
    "undo",
    "redo",
    "table",
    "image",
    "format",
    "brush",
    "source",
    "fontsize",
    "paragraph",
    "left",
    "center",
    "right",
    "justify",
    "video",
    "superscript",
    "subscript",
    "file",
    "print",
  ],
};

class StudentGeneralReport extends Component {
  state = {
    modal: false,
    commentId: "",
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isOpen) {
      return true;
    }
    return false;
  }
  componentDidUpdate() {
    if (this.props.isOpen) {
      $(".selectpicker").selectpicker();
      $(".selectpicker").selectpicker("refresh");
    } else {
      $(".selectpicker").selectpicker("destroy");
    }
  }
  // componentDidUpdate(prevProps) {
  //   if (this.props.finalize !== prevProps.finalize) {
  //     $(".selectpicker").selectpicker();
  //   }
  // }

  // componentDidMount() {
  //   let param = {
  //     peopleId: localStorage.getItem("peopleId"),
  //   };
  //   axiosPost(URL.selectRecruitmentByPeopleId, param, (response) => {
  //     if (response.status === 200) {
  //       this.setState({ recruitment: response.data.data });
  //     }
  //   });
  // }

  handleCommentBankHandler = (e, commentId) => {
    this.setState({
      modal: !this.state.modal,
      commentId: commentId,
    });
  };

  render() {
    let studentDetail = this.props.studentDetails;
    return (
      <>
        {this.props.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Processing...
            </div>
          </div>
        ) : null}
        <div className="container-fluid" key={this.props.peopleId}>
          <div className="row">
            <div
              className="col-md-5 tt-generalBorders"
              style={{ borderRight: "1px solid #ccc", marginBottom: "5px" }}
            >
              <div
                style={{ height: "200px", width: "200px", marginBottom: "8px" }}
              >
                {studentDetail ? (
                  studentDetail.people ? (
                    <img
                      src={
                        studentDetail.people.photo
                          ? URL.imageSelectURL + studentDetail.people.photo
                          : userImage
                      }
                      style={{
                        height: "inherit",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      onError={(e) => handleError(e)}
                    ></img>
                  ) : (
                    "Photo"
                  )
                ) : (
                  "Photo"
                )}
              </div>

              {studentDetail ? (
                studentDetail.people ? (
                  <>
                    <div>
                      <strong>DOB: </strong>
                      {studentDetail.people.dateOfBirth &&
                        studentDetail.people.dateOfBirth.substring(0, 10)}
                    </div>
                    <div>
                      <strong>Age: </strong>
                      {studentDetail.people.dateOfBirth &&
                      moment().diff(
                        studentDetail.people.dateOfBirth.substring(0, 10),
                        "years"
                      ) === 0
                        ? 1
                        : moment().diff(
                            studentDetail.people.dateOfBirth.substring(0, 10),
                            "years"
                          )}{" "}
                      {studentDetail.people.dateOfBirth && "years old"}
                    </div>
                    {studentDetail.rollNumber &&
                    studentDetail.rollNumber !== "" ? (
                      <div>
                        <strong>Roll No: </strong>
                        {studentDetail.rollNumber}
                      </div>
                    ) : null}
                  </>
                ) : null
              ) : null}
              <div>
                {/* {"Special Needs (will appear if data is available will also appear here)"} */}
              </div>
            </div>
            <div className="col-md-7 tt-generalBorders">
              {this.props.assessmentGeneralConfig &&
              this.props.assessmentGeneralConfig !== undefined &&
              this.props.assessmentGeneralConfig
                .assessmentGeneralCustomAssessmentConfigurations
                ? this.props.assessmentGeneralConfig.assessmentGeneralCustomAssessmentConfigurations.map(
                    (config, configIdx) => {
                      return (
                        <>
                          <div>
                            <span className="tt-studentReportHeading">
                              {config
                                .assessmentGeneralCustomAssessmentScaleConfigurations
                                .length > 0
                                ? config.customAssessmentHeaderName
                                : null}
                            </span>
                          </div>

                          <table
                            border={1}
                            width="100%"
                            className="tt-generalTable table-striped"
                          >
                            <thead>
                              <tr>
                                {config.assessmentGeneralCustomAssessmentScaleConfigurations
                                  ? config.assessmentGeneralCustomAssessmentScaleConfigurations.map(
                                      (overall, overallIdx) => (
                                        <th key={overallIdx}>
                                          {overall.assessmentScale.scoreName}
                                        </th>
                                      )
                                    )
                                  : null}
                                {/* <th>{overall.assessmentScale.scoreName}</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {config.assessmentGeneralCustomAssessmentScaleConfigurations.map(
                                  (over, overIdx) => {
                                    return (
                                      <td key={overIdx}>
                                        <select
                                          className="form-control selectpicker"
                                          name="level"
                                          value={over.level ? over.level : ""}
                                          onChange={(e) =>
                                            this.props.multipleScaleNameChange(
                                              e,
                                              configIdx,
                                              overIdx
                                            )
                                          }
                                          disabled={
                                            this.props.finalize
                                              ? true
                                              : checkWidgetAdminOrNot(
                                                  this.props.widgetName
                                                ) === true
                                              ? false
                                              : this.props.recruitment
                                              ? config.hasRole === false
                                                ? parseInt(config.assessor) ===
                                                  parseInt(
                                                    this.props.recruitment.id
                                                  )
                                                  ? false
                                                  : true
                                                : config.recruitmentIds.includes(
                                                    this.props.recruitment.id
                                                  )
                                                ? false
                                                : true
                                              : true
                                          }
                                        >
                                          <option value="" disabled>
                                            {"Select Level"}
                                          </option>
                                          {over.assessmentScale.assessmentScaleLevels.map(
                                            (level, levelIdx) => (
                                              <>
                                                {level.levelType ===
                                                "colourStickers" ? (
                                                  <option
                                                    key={levelIdx}
                                                    value={`${level.id},${over.assessmentGeneralCustomAssessmentConfigurationId},${over.assessmentScaleId}`}
                                                    style={{
                                                      backgroundColor:
                                                        level.level,
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    {level.level}
                                                  </option>
                                                ) : level.levelType ===
                                                  "image" ? (
                                                  <option
                                                    value={`${level.id},${over.assessmentGeneralCustomAssessmentConfigurationId},${over.assessmentScaleId}`}
                                                    data-content={`<img src=${
                                                      URL.imageSelectURL +
                                                      level.level
                                                    } class="img-responsive" height="42" width="42"/>`}
                                                  ></option>
                                                ) : (
                                                  <option
                                                    key={levelIdx}
                                                    value={`${level.id},${over.assessmentGeneralCustomAssessmentConfigurationId},${over.assessmentScaleId}`}
                                                  >
                                                    {level.level}
                                                  </option>
                                                )}
                                              </>
                                            )
                                          )}
                                        </select>
                                      </td>
                                    );
                                  }
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </>
                      );
                    }
                  )
                : null}
              {this.props.assessmentGeneralConfig &&
              this.props.assessmentGeneralConfig !== undefined &&
              this.props.assessmentGeneralConfig
                .assessmentGeneralCustomAssessmentConfigurations
                ? this.props.assessmentGeneralConfig.assessmentGeneralCustomAssessmentConfigurations.map(
                    (config, configIdx) => {
                      return config
                        .assessmentGeneralCustomAssessmentRubricsConfigurations
                        .length > 0 ? (
                        <>
                          <div>
                            <span className="tt-studentReportHeading">
                              {config.customAssessmentHeaderName}
                            </span>
                          </div>
                          <table
                            border={1}
                            width="100%"
                            className="tt-generalTable table-striped"
                          >
                            <thead>
                              <tr>
                                <th>
                                  {config.customAssessmentHeaderName} Rubrics
                                </th>
                                {config.assessmentGeneralCustomAssessmentRubricsConfigurations &&
                                config
                                  .assessmentGeneralCustomAssessmentRubricsConfigurations[0]
                                  .assessmentGeneralCustomAssessmentRubricsScaleConfigurations
                                  ? config.assessmentGeneralCustomAssessmentRubricsConfigurations[0].assessmentGeneralCustomAssessmentRubricsScaleConfigurations.map(
                                      (assessmentScale, assessmentScaleIdx) => (
                                        <th>
                                          {
                                            assessmentScale.assessmentScale
                                              .scoreName
                                          }
                                        </th>
                                      )
                                    )
                                  : null}
                              </tr>
                            </thead>
                            <tbody>
                              {config.assessmentGeneralCustomAssessmentRubricsConfigurations
                                ? config.assessmentGeneralCustomAssessmentRubricsConfigurations.map(
                                    (rubric, rubricIdx) => {
                                      return (
                                        <tr>
                                          <td>
                                            <span>{rubric.rubricsName}</span>
                                            <br />
                                            <span className="tt-rubric-description">
                                              {rubric.description}
                                            </span>
                                          </td>

                                          {rubric.assessmentGeneralCustomAssessmentRubricsScaleConfigurations.map(
                                            (scale, scaleIdx) => {
                                              return (
                                                <td>
                                                  <select
                                                    className="form-control selectpicker"
                                                    name="level"
                                                    value={
                                                      scale.level
                                                        ? scale.level
                                                        : ""
                                                    }
                                                    onChange={(e) =>
                                                      this.props.multipleScaleNameChangeForRubric(
                                                        e,
                                                        configIdx,
                                                        rubricIdx,
                                                        scaleIdx
                                                      )
                                                    }
                                                    disabled={
                                                      this.props.finalize
                                                        ? true
                                                        : checkWidgetAdminOrNot(
                                                            this.props
                                                              .widgetName
                                                          ) === true
                                                        ? false
                                                        : this.props.recruitment
                                                        ? config.hasRole ===
                                                          false
                                                          ? parseInt(
                                                              config.assessor
                                                            ) ===
                                                            parseInt(
                                                              this.props
                                                                .recruitment.id
                                                            )
                                                            ? false
                                                            : true
                                                          : config.recruitmentIds.includes(
                                                              this.props
                                                                .recruitment.id
                                                            )
                                                          ? false
                                                          : true
                                                        : true
                                                    }
                                                  >
                                                    <option value="">
                                                      {"Select Level"}
                                                    </option>
                                                    {scale.assessmentScale.assessmentScaleLevels.map(
                                                      (level, levelIdx) => (
                                                        <>
                                                          {level.levelType ===
                                                          "colourStickers" ? (
                                                            <option
                                                              value={`${level.id},${scale.assessmentGeneralCustomAssessmentConfigurationId},${scale.assessmentGeneralCustomAssessmentRubricsConfigurationId},${scale.assessmentScaleId}`}
                                                              style={{
                                                                backgroundColor:
                                                                  level.level,
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              {level.level}
                                                            </option>
                                                          ) : level.levelType ===
                                                            "image" ? (
                                                            <option
                                                              value={`${level.id},${scale.assessmentGeneralCustomAssessmentConfigurationId},${scale.assessmentGeneralCustomAssessmentRubricsConfigurationId},${scale.assessmentScaleId}`}
                                                              data-content={`<img src=${
                                                                URL.imageSelectURL +
                                                                level.level
                                                              } class="img-responsive" height="42" width="42"/>`}
                                                            ></option>
                                                          ) : (
                                                            <option
                                                              value={`${level.id},${scale.assessmentGeneralCustomAssessmentConfigurationId},${scale.assessmentGeneralCustomAssessmentRubricsConfigurationId},${scale.assessmentScaleId}`}
                                                            >
                                                              {level.level}
                                                            </option>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                  </select>
                                                </td>
                                              );
                                            }
                                          )}
                                        </tr>
                                      );
                                    }
                                  )
                                : null}
                            </tbody>
                          </table>
                        </>
                      ) : null;
                    }
                  )
                : null}
              <div className="mt-4">
                {this.props.assessmentGeneralConfig &&
                this.props.assessmentGeneralConfig !== undefined &&
                this.props.assessmentGeneralConfig
                  .assessmentGeneralCommentConfigurations
                  ? this.props.assessmentGeneralConfig
                      .assessmentGeneralCommentConfigurations.length > 0
                    ? this.props.assessmentGeneralConfig.assessmentGeneralCommentConfigurations.map(
                        (comment, commentIdx) => {
                          return (
                            <div
                              key={commentIdx}
                              className={
                                this.props.finalize
                                  ? "tt-disable-comment-box"
                                  : checkWidgetAdminOrNot(
                                      this.props.widgetName
                                    ) === true
                                  ? null
                                  : this.props.recruitment
                                  ? comment.hasRole === false
                                    ? parseInt(comment.commentor) ===
                                      parseInt(this.props.recruitment.id)
                                      ? null
                                      : "tt-disable-comment-box"
                                    : comment.recruitmentIds.includes(
                                        this.props.recruitment.id
                                      )
                                    ? null
                                    : "tt-disable-comment-box"
                                  : "tt-disable-comment-box"
                              }
                            >
                              <span className="tt-studentReportHeading">
                                {comment.commentName}
                              </span>
                              <div
                                id={
                                  "general" +
                                  this.props.studentDetails.admissionId +
                                  comment.assessmentId +
                                  commentIdx
                                }
                                className="tt-commentBankEditor"
                                //  style={{ pointerEvents: "none" }}
                              >
                                <JoditEditor
                                  ref={this.props.editor}
                                  key={commentIdx}
                                  value={
                                    comment.commentTextValue
                                      ? comment.commentTextValue
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.props.handleCommentChange(
                                      e,
                                      commentIdx,
                                      "general" +
                                        this.props.studentDetails.admissionId +
                                        comment.assessmentId +
                                        commentIdx
                                    )
                                  }
                                  config={config}
                                  // onFocus={this.props.handleJoditEditorClick}
                                  // config={{
                                  //   disabled:
                                  //     comment.commentor || this.props.recruitment
                                  //       ? comment.commentor ===
                                  //         this.props.recruitment.id
                                  //         ? false
                                  //         : true
                                  //       : false,
                                  // }}
                                />
                              </div>
                              {comment.error && (
                                <div className="alert alert-danger">
                                  {comment.error}
                                </div>
                              )}
                              <div className="row">
                                <div className="col-md-6">
                                  <i>
                                    {comment.minLength} to {comment.maxLength}{" "}
                                    {comment.commentTextType}
                                  </i>
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {/* <div
                                className="tt-commentsBank"
                                onClick={(e) =>
                                  this.handleCommentBankHandler(e, commentIdx)
                                }
                                disabled={
                                  comment.commentor || this.props.recruitment
                                    ? comment.commentor ===
                                      this.props.recruitment.id
                                      ? false
                                      : true
                                    : false
                                }
                              > */}
                                  <button
                                    className="tt-button tt-button-primary mt-1"
                                    onClick={(e) =>
                                      this.handleCommentBankHandler(
                                        e,
                                        commentIdx
                                      )
                                    }
                                    disabled={
                                      this.props.finalize
                                        ? true
                                        : checkWidgetAdminOrNot(
                                            this.props.widgetName
                                          ) === true
                                        ? false
                                        : this.props.recruitment
                                        ? comment.hasRole === false
                                          ? parseInt(comment.commentor) ===
                                            parseInt(this.props.recruitment.id)
                                            ? false
                                            : true
                                          : comment.recruitmentIds.includes(
                                              this.props.recruitment.id
                                            )
                                          ? false
                                          : true
                                        : true
                                    }
                                  >
                                    Comments Bank
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : null
                  : null}
              </div>
              {this.props.assessmentGeneralConfig ? (
                this.props.assessmentGeneralConfig
                  .assessmentGeneralCommentConfigurations ||
                this.props.assessmentGeneralConfig
                  .assessmentGeneralCustomAssessmentConfigurations ? (
                  this.props.assessmentGeneralConfig
                    .assessmentGeneralCommentConfigurations.length > 0 ||
                  this.props.assessmentGeneralConfig
                    .assessmentGeneralCustomAssessmentConfigurations.length >
                    0 ? (
                    <div className="row mt-4 tt-general-report-submit-div">
                      {this.props.finalizeGeneralPermission ? (
                        <button
                          className="tt-button tt-button-primary mt-2 ml-2"
                          onClick={this.props.handleSubmitGeneralAssessment}
                          disabled={
                            this.props.finalize
                              ? true
                              : checkWidgetAdminOrNot(this.props.widgetName) ===
                                true
                              ? false
                              : this.props.recruitment
                              ? false
                              : true
                          }
                        >
                          Save Assessment Records
                        </button>
                      ) : (
                        "Sorry, you don't have permision to save and finalize"
                      )}
                    </div>
                  ) : (
                    <div className="text-center">
                      No assessment configuration
                    </div>
                  )
                ) : null
              ) : null}
            </div>
          </div>

          <ModalWindow
            fullWidth={true}
            modal={this.state.modal}
            toggleModal={this.handleCommentBankHandler}
            modalBody={
              <CommentsBank
                handleInsertComment={this.props.handleInsertComment}
                commentId={this.state.commentId}
                handleCommentBankHandler={this.handleCommentBankHandler}
              />
            }
          />

          {/* <div
            className="row tt-generalAssessmentFooter"
            style={{ alignItems: "center" }}
          >
            <div className="col-md-5 pt-3">
              <div className="row">
                <div className="col-md-6">
                  <strong>Optional Subjects: </strong>
                  <p>
                    {this.props.studentOptionlSubjects.length > 0
                      ? this.props.studentOptionlSubjects
                          .map((subject, idx) => subject.subjectName)
                          .join(",")
                      : "No optional subjects."}
                  </p>
                </div>
                <div className="col-md-6">
                  <strong>Compulsory Subjects:</strong>
                  <p>
                    {this.props.compulsorySubjects.length > 0
                      ? this.props.compulsorySubjects
                          .map((sub, idx) => sub.subjectName)
                          .join(",")
                      : "No subjects."}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7 py-2">
              <div className="row">
                <div className="col-md-7">
                  <select className="form-control">
                    <option value="a">
                      Mid-Term Examination (Subject Assessment)
                    </option>
                    <option value="b">
                      Final-Term Examination (Subject Assessment)
                    </option>
                  </select>
                </div>
                <div className="col-md-5 pt-1">
                  <button className="tt-button tt-button-primary">
                    View Assessment Records
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default StudentGeneralReport;
