import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import Content from "./Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import ModalWindow from "../UI/ModalWindow";
import { axiosPost } from "../../utils/AxiosApi";
import { postType, URL } from "../../utils/Constants";
import CustomButton from "../common/CustomButton";
import swal from "sweetalert";
import { displayErrorAlert } from "../../utils/Utils";
import CustomSpinner from "../common/CustomSpinner";
import FooterText from "./FooterText";
import moment from "moment";

const FrontPage = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [feedbackTypeId, setFeedbackTypeId] = useState("");
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [logoPreview, setLogoPreview] = useState(null);
  const [disclaimer, setDisclaimer] = useState("");
  const [noticeDisplay, setNoticeDisplay] = useState(false);
  const [noticeList, setNoticeList] = useState([]);
  // const [footerText, setFooterText] = useState("");
  // const [footerLogoPreview, setFooterLogoPreview] = useState("");
  // const [isFooterActive, setIsFooterActive] = useState(false);
  // const [footerLogoLink, setFooterLogoLink] = useState("");
  // const [footerIsoLink, setFooterIsoLink] = useState("");
  const [userData, setUserData] = useState(null);

  const getFeedbackTypes = () => {
    axiosPost(URL.getFeedbackTypes, {}, (response) => {
      if (response.status === 200) {
        setFeedbackTypes(response.data.data);
      }
    });
  };

  useEffect(() => {
    getFeedbackTypes();
    getWebsiteConfig();
    getAllNoticeList();
    if (localStorage.getItem("token")) {
      getMyInfo();
    }
  }, []);

  const getMyInfo = () => {
    axiosPost(
      URL.getLoggedInUserInfo,
      {},
      (response) => {
        if (response.status === 200) {
          setUserData(response.data.data);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };
  const handleSubmitFeedback = () => {
    let data = {
      feedbackTypeId: feedbackTypeId,
      name: name,
      contactNumber: contact,
      email: email,
      description: description,
    };

    if (
      feedbackTypeId === "" ||
      email === "" ||
      contact === "" ||
      name === ""
    ) {
      swal("Error", "Fill all the required fields!!");
      return;
    } else {
      setSpinner(true);
      axiosPost(
        URL.insertFeedback,
        data,
        (response) => {
          if (response.status === 200) {
            setFeedbackModal(false);
            swal("Success", "Your feedback is submitted successfully!!");
            setContact("");
            setName("");
            setEmail("");
            setDescription("");
            setFeedbackTypeId("");
            setSpinner(false);
          }
        },
        (err) => {
          setSpinner(false);
          displayErrorAlert(err);
        }
      );
    }
  };

  const getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        if (data.length > 0) {
          // setWebTitle(data[0].title);
          setDisclaimer(data[0].disclaimer);
          // setFaviconPreview(data[0].faviconPath);
          setLogoPreview(data[0].logoPath);
          setNoticeDisplay(data[0].displayNotice);
          document.title = data[0].title;
          // setGovHeader(data[0].mainLogoPath);
          // setFooterText(data[0].footerText);
          // setFooterLogoPreview(data[0].footerLogo);
          // setIsFooterActive(data[0].isActiveFooter);
          // setFooterLogoLink(data[0].footerLogoLink);
          // setFooterIsoLink(data[0].footerIsoLink);
        }
      }
    });
  };

  const getAllNoticeList = () => {
    axiosPost(URL.getNoticeCore, {}, (response) => {
      if (response.status === 200) {
        setNoticeList([]);
        const notices = response.data.data;
        // setNoticeList(notices);
        let allPromises = [];
        notices.forEach(async (notice, idx) => {
          if (notice.postType === postType.videoConference) {
            let param = {
              postId: notice.id,
              profilePeopleId: localStorage.getItem("peopleId"),
            };
            let selectVideoConferencePostPromise = new Promise(
              (resolve, reject) => {
                axiosPost(URL.videoConferenceSelectByPostId, param, (res) => {
                  if (res.status === 200) {
                    resolve(res.data.data);
                  }
                });
              }
            );
            allPromises.push(selectVideoConferencePostPromise);
            let replaceData = await selectVideoConferencePostPromise;
            let startDateTime = replaceData?.startDateTime
              ? moment(replaceData?.startDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;
            let endDateTime = replaceData?.endDateTime
              ? moment(replaceData?.endDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;

            notice.heading = notice.heading
              ? notice.heading.replace(
                  "[sessionName]",
                  replaceData?.sessionName
                )
              : null;
            notice.textContent = notice.textContent.replace(
              "[peopleName]",
              replaceData?.peopleName
            );
            notice.textContent = notice.textContent.replace(
              "[message]",
              replaceData?.message
            );
            notice.textContent = notice.textContent.replace(
              "[sessionName]",
              replaceData?.sessionName
            );
            notice.textContent = notice.textContent.replace(
              "[startDateTime]",
              startDateTime
            );
            notice.textContent = notice.textContent.replace(
              "[endDateTime]",
              endDateTime
            );
            notice.textContent = notice.textContent.replace(
              "[hostName]",
              replaceData?.hostName
            );
          }
        });
        Promise.all(allPromises).then(() => {
          let nl = notices.filter(
            (n) => n.postType !== postType.videoConference
          );
          setNoticeList(nl);
        });
      }
    });
  };

  return (
    <>
      {/* {govHeader ? (
        <div className="govHeader">
          <div className="govHeader-img">
            <img src={URL.imageSelectURL + govHeader} />
          </div>
        </div>
      ) : (
        ""
      )} */}
      <NavBar homePage pathName={props.location?.pathname} />

      <Content noticeList={noticeList} noticeDisplay={noticeDisplay} />

      {/* <Footer /> */}
      {spinner ? (
        <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
      ) : null}

      {/* {isFooterActive ? (
        <FooterText
          footerLogoPreview={footerLogoPreview}
          footerText={footerText}
          footerIsoLink={footerIsoLink}
          footerLogoLink={footerLogoLink}
        />
      ) : (
        ""
      )} */}
      <FooterText />

      {/* {isFooterActive ? (
        <Footer
          logoPreview={logoPreview}
          footerLogoPreview={footerLogoPreview}
          footerText={footerText}
        />
      ) : (
        ""
      )} */}

      <div
        className="float-feedback-icon"
        onClick={() => setFeedbackModal(true)}
      >
        <p>
          Feedbacks
          <FontAwesomeIcon
            icon={faComment}
            className="feedback-fa-icon"
          ></FontAwesomeIcon>
        </p>
      </div>

      <ModalWindow
        modal={feedbackModal}
        toggleModal={() => setFeedbackModal(!feedbackModal)}
        size="lg"
        modalHeader="Give us a feedback"
        // noModelHeader
        modalBody={
          <>
            <div className="container py-2">
              <div className="row mt-2">
                <div className="col-md-3 mt-1">
                  <strong>
                    Name
                    <span
                      className="tt-assessment-module-mandatory"
                      style={{ paddingLeft: "5px" }}
                    >
                      *
                    </span>
                  </strong>
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="name"
                    value={name || userData?.name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control cusInput"
                    placeholder="Enter your name here"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3 mt-1">
                  <strong>
                    Email
                    <span
                      className="tt-assessment-module-mandatory"
                      style={{ paddingLeft: "5px" }}
                    >
                      *
                    </span>
                  </strong>
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="email"
                    value={email || userData?.emailAddress}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control cusInput"
                    placeholder="Enter your email here"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3 mt-1">
                  <strong>
                    Contact No.
                    <span
                      className="tt-assessment-module-mandatory"
                      style={{ paddingLeft: "5px" }}
                    >
                      *
                    </span>
                  </strong>
                </div>
                <div className="col">
                  <input
                    type="text"
                    name="contact"
                    value={contact || userData?.phoneNo}
                    onChange={(e) => setContact(e.target.value)}
                    className="form-control cusInput"
                    placeholder="Enter your contact no."
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3 mt-1">
                  <strong>
                    Feedback Type
                    <span
                      className="tt-assessment-module-mandatory"
                      style={{ paddingLeft: "5px" }}
                    >
                      *
                    </span>
                  </strong>
                </div>
                <div className="col">
                  <select
                    className="form-control cusInput"
                    name="feedbackTypeId"
                    onChange={(e) => setFeedbackTypeId(e.target.value)}
                    value={feedbackTypeId}
                  >
                    <option selected disabled value="">
                      Select type
                    </option>
                    {feedbackTypes.map((fb) => (
                      <option value={fb.feedbackTypeId}>
                        {fb.feedbackTypeName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3 mt-1">
                  <strong>
                    Description
                    {/* <span
                className="tt-assessment-module-mandatory"
                style={{ paddingLeft: "5px" }}
              >
                *
              </span> */}
                  </strong>
                </div>
                <div className="col">
                  <textarea
                    name="description"
                    placeholder="Enter description"
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="text-right mt-3">
                <CustomButton
                  text="Submit"
                  customStyle="cusBtnFontSize"
                  onClick={() => handleSubmitFeedback()}
                ></CustomButton>
              </div>
            </div>
          </>
        }
      ></ModalWindow>
    </>
  );
};

export default FrontPage;
