import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import Navbar from "../Profile/Navbar";

export class DefaultSettings extends Component {
  state = {
    dropdownOpen: false,
    editMode: false,
    showSpinner: false,

    settingsList: [
      {
        id: null,
        key: "",
        value: "",
      },
    ],
  };

  componentDidMount() {
    this.getSetupConfig();
  }

  getSetupConfig = () => {
    axiosPost(URL.setupConfiguration, {}, (response) => {
      if (response.status === 200 && response.data.data.length > 0) {
        let data = response.data.data;
        let newData = [];
        data.forEach((list) => {
          let strList = "";
          let newStr = list.lists?.forEach((ll) => {
            strList = strList + "," + ll;
          });

          newData = [
            ...newData,
            {
              id: list.id,
              key: list.key,
              value: list.lists?.length > 0 ? strList.substr(1) : list.value,
            },
          ];
        });
        this.setState({
          settingsList: newData,
          editMode: true,
        });
      }
    });
  };

  deleteRow = (item) => {
    let settingsList = [...this.state.settingsList];
    if (item.item.id === null) {
      settingsList.splice(item.idx, 1);
      this.setState({ settingsList });
    } else {
      axiosPost(URL.deleteSetupConfig, { id: item.item.id }, (response) => {
        if (response.status === 200) {
          settingsList.splice(item.idx, 1);
        }
        this.setState({ settingsList });
      });
    }
  };

  handleRemoveClick = (idx, item) => {
    let settingsList = [...this.state.settingsList];

    if (item.key !== "" || item.value !== "") {
      displayConfirmDeleteAlert({ idx, item }, this.deleteRow);
    } else {
      settingsList.splice(idx, 1);
      this.setState({ settingsList });
    }
  };

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  addRow = () => {
    let settingsList = [...this.state.settingsList];

    settingsList.push({
      id: null,
      key: "",
      value: "",
    });

    this.setState({
      settingsList,
    });
  };

  handleChange = (e, idx) => {
    let settingsListCopy = [...this.state.settingsList];

    if (e.target.name === "key") {
      settingsListCopy[idx].key = e.target.value;
    }
    if (e.target.name === "value") {
      settingsListCopy[idx].value = e.target.value;
    }

    this.setState({
      settingsList: settingsListCopy,
    });
  };

  handleSaveConfig = (e) => {
    e.preventDefault();
    let postData = [...this.state.settingsList];
    let error = 0;

    postData.forEach((pd) => {
      if (pd.key === "" || pd.value === "") {
        error = error + 1;
      }
    });

    if (error > 0) {
      swal({
        title: "Error",
        text: "Key and value cannot be empty.",
      });
      return;
    } else {
      this.setState(
        {
          showSpinner: true,
        },
        () => {
          axiosPost(
            URL.insertSetupConfig,
            postData,
            (response) => {
              if (response.status === 200) {
                this.setState({
                  showSpinner: false,
                });
                swal({
                  title: "Success",
                  text: response.data.message,
                });
              }
            },
            (err) => {
              this.setState({
                showSpinner: false,
              });
              swal({
                title: "Error",
                text: err.response.data.message,
              });
            }
          );
        }
      );
    }
  };

  handleUpdateConfig = (e) => {
    e.preventDefault();
    let postData = [...this.state.settingsList];

    let error = 0;
    postData.forEach((pd) => {
      if (pd.key === "" || pd.value === "") {
        error = error + 1;
      }
    });

    if (error > 0) {
      swal({
        title: "Error",
        text: "Key and value cannot be empty.",
      });
      return;
    } else {
      this.setState(
        {
          showSpinner: true,
        },
        () => {
          axiosPost(
            URL.updateSetupConfig,
            postData,
            (response) => {
              if (response.status === 200) {
                this.setState({
                  showSpinner: false,
                });
                swal({
                  title: "Success",
                  text: response.data.message,
                });
              }
            },
            (err) => {
              this.setState({
                showSpinner: false,
              });
              swal({
                title: "Error",
                text: err.response.data.message,
              });
            }
          );
        }
      );
    }
  };

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Your settings are being stored .....
            </div>
          </div>
        ) : null}
        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
        />

        <div
          style={{ backgroundColor: "white", height: "88vh" }}
          className="container"
        >
          <form onSubmit={this.handleSave}>
            <div className="tt-group-header mb-4 mt-3">
              Default System Settings Configuration Manager
            </div>
            <div>
              {this.state.settingsList.length === 0 ? (
                <div className="text-center">
                  <button
                    className="tt-button tt-button-primary mt-3"
                    onClick={this.addRow}
                  >
                    Add Setting Configuration Field
                  </button>
                </div>
              ) : (
                <div>
                  <div className="row mb-3">
                    <div className="col-md-1"></div>
                    <div className="col-md-3 text-center">
                      <p>
                        <b>Key</b>
                      </p>
                    </div>
                    <div className="col-md-5 text-center">
                      <p>
                        <b>Values</b>
                      </p>
                    </div>
                    <div className="col-md-2"></div>
                  </div>

                  {this.state.settingsList.map((list, idx) => (
                    <div className="row mb-3">
                      <div className="col-md-1"></div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          name="key"
                          className="form-control"
                          maxLength={255}
                          value={list.key}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="value"
                          className="form-control"
                          maxLength={255}
                          value={list.value}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                      <div className="col-md-2 d-flex">
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="removeMcqOption mr-2"
                          onClick={() => this.handleRemoveClick(idx, list)}
                        />
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="addMcqOption"
                          onClick={this.addRow}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="col text-right">
                    <button
                      className="tt-button tt-button-primary mt-3"
                      onClick={
                        this.state.editMode
                          ? this.handleUpdateConfig
                          : this.handleSaveConfig
                      }
                    >
                      {this.state.editMode ? "Update" : "Save"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default DefaultSettings;
