import React, { Component } from "react";
import { URL, draggable } from "../../utils/Constants";
import { axiosPost } from "./../../utils/AxiosApi";
import swal from "sweetalert";
import { displaySuccessAlert } from "../../utils/Utils";
import history from "../../utils/History";
import Navbar from "./../Profile/Navbar";

class ARRManager extends Component {
  state = {
    academicYears: [],
    academicYear: null,
    arrName: null,
    arrCode: null,
    submitDisabled: false,
    dropdownOpen: false,
  };

  componentDidMount() {
    axiosPost(URL.getAcademicYears, {}, (response) => {
      if (response.status === 200) {
        this.setState({ academicYears: response.data.data });
      }
    });
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  };

  handleSave = (e) => {
    e.preventDefault();
    const { academicYear, arrName, arrCode } = this.state;
    if (academicYear !== null && arrName !== null && arrCode !== null) {
      if (arrCode.length > 20) {
        swal({
          title: "Warning",
          text: "Assessment module code must not be greater than 20 in length.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        const data = {
          tempDatabaseName: academicYear,
          name: arrName,
          code: arrCode,
        };
        axiosPost(URL.insertAssessmentModule, data, (response) => {
          if (response.status === 200) {
            displaySuccessAlert(response, () => {
              history.push(`${process.env.PUBLIC_URL}/arrmanager`);
            });
          }
        });
      }
    } else {
      swal({
        title: "Warning",
        text: "Fill all the field first",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    }
  };

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
        />
        <div
          style={{ backgroundColor: "white", height: "88vh" }}
          className="container-fluid"
        >
          <form onSubmit={this.handleSave}>
            <div className="tt-group-header mb-4 mt-3">ARR Manager</div>

            <div className="row mb-3">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label>
                  <strong>ARR Name:</strong>
                </label>
              </div>
              <div className="col-md-7">
                <input
                  type="text"
                  name="arrName"
                  className="form-control"
                  maxLength={255}
                  value={this.state.arrName}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mb-3">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label>
                  <strong>ARR Code:</strong>
                </label>
              </div>
              <div className="col-md-7">
                <input
                  type="text"
                  name="arrCode"
                  className="form-control"
                  maxLength={255}
                  value={this.state.arrCode}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mb-3">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label>
                  <strong>Academic Years:</strong>
                </label>
              </div>
              <div className="col-md-7">
                <select
                  name="academicYear"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.academicYear}
                >
                  <option selected disabled value={null}>
                    select academic year
                  </option>
                  <option value="default">Latest Core</option>
                  {this.state.academicYears.map((academicYear) => (
                    <option value={academicYear.dbName}>
                      {academicYear.dbName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mb-3">
              <div className="col-md-1"></div>
              <div className="col-md-3"></div>
              <div className="col-md-7"></div>
              <div className="col-md-1">
                <input
                  type="submit"
                  value="Submit"
                  className="tt-button tt-button-primary"
                  disabled={this.state.submitDisabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ARRManager;
