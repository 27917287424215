import React, { Component } from "react";

import CommentSectionTableRow from "./CommentSectionTableRow";
import { getYearLevel, getClassroomName } from "../../../../utils/Constants";
class GeneralASSectionOne extends Component {
  state = {};

  tableRow() {
    return this.props.comments.length > 0
      ? this.props.comments.map((comment, i) => {
          return (
            <CommentSectionTableRow
              comment={comment}
              idx={i}
              handleCommentValueChange={this.props.handleCommentValueChange}
              handleRemoveCommentClick={this.props.handleRemoveCommentClick}
              handleUpdateCommentClick={this.props.handleUpdateCommentClick}
              handleNestedChange={this.props.handleNestedChange}
              handlecommentorChange={this.props.handlecommentorChange}
              recruitmentList={this.props.recruitmentList}
              roleList={this.props.roleList}
            />
          );
        })
      : null;
  }
  render() {
    return (
      <>
        <div className="tt-subjectAssessmentDisplay">
          <div className="tt-AssessmentTitle">
            {getYearLevel.toUpperCase()}{" "}
            {this.props.className ? this.props.className.toUpperCase() : null}{" "}
            {/* {getClassroomName}{" "} */}
            {this.props.sectionName
              ? this.props.sectionName.toUpperCase()
              : null}{" "}
            -{" "}
            {this.props.assessmentName !== undefined
              ? this.props.assessmentName.toUpperCase()
              : null}
          </div>
          {/* <div className="tt-AssessmentTitle tt-AssessmentSubtitle">
            {this.props.assessmentName !== undefined
              ? this.props.assessmentName.toUpperCase()
              : null}
          </div> */}
          <div
            className="container-fluid tt-subjectAssessmentContent"
            style={{ height: "33.6vh" }}
          >
            {/* 28vh */}
            <div className="row">
              <div className="col">
                <table
                  border={1}
                  className="mt-2 mb-2 table-striped"
                  width="100%"
                >
                  <thead className="tt-AssessmentTitle">
                    <tr>
                      <th width="70%" className="tt-subjectDisplayTable">
                        <strong>Comments</strong>
                      </th>
                      <th width="20%" className="tt-subjectDisplayTable">
                        <strong>Commenter</strong>
                      </th>
                      <th className="tt-subjectDisplayTable">
                        <strong>Options</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.tableRow()}
                    {/* <td className="pt-2 px-2">
                      <textarea
                        placeholder="Comments"
                        name=""
                        id=""
                        className="tt-subjectAssessmentTextArea"
                      ></textarea>
                      <span className="text-muted text-left">
                        <i>1000 to 2000 characters</i>
                      </span>

                      <span className="text-muted" style={{ float: "right" }}>
                        <i>Ms. Klasse Teach (Class Teacher)</i>
                      </span>
                    </td>
                    <td className="tt-subjectDisplayTable">
                      <select className="form-control">
                        <option value="">Classroom Teacher</option>
                      </select>
                    </td>
                    <td className="tt-subjectDisplayTable">
                      {" "}
                      <FontAwesomeIcon icon={faEdit} size="2x" />
                      &nbsp; &nbsp; &nbsp;
                      <FontAwesomeIcon icon={faTrashAlt} size="2x" />
                    </td> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GeneralASSectionOne;
