import React, { Component } from "react";
import { compareByName } from "../../utils/Utils";

export default class CreateFlashCardSet extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1 text-right mt-1">
            <strong>
              Title: <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              placeholder="Enter Title"
              name="title"
              value={this.props.title}
              onChange={this.props.handleChange}
              className={
                this.props.central ? "form-control cusInput" : "form-control"
              }
            ></input>
          </div>
          <div className="col-md-1 text-right pr-0 pl-0 mt-1">
            <strong>Description: </strong>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              placeholder="Enter Description"
              name="description"
              value={this.props.description}
              onChange={this.props.handleChange}
              className={
                this.props.central ? "form-control cusInput" : "form-control"
              }
            ></input>
          </div>
          <div className="col-md-1 text-right mt-1">
            <strong>Access: </strong>
          </div>
          <div className="col-md-3">
            <select
              className={
                this.props.central
                  ? "form-control cusInputSelect"
                  : "form-control"
              }
              name="access"
              value={this.props.access}
              onChange={this.props.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1 text-right mt-1">
            <strong>
              Class: <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-3">
            <select
              className={
                this.props.central
                  ? "form-control cusInputSelect"
                  : "form-control"
              }
              name="classId"
              value={this.props.classId}
              onChange={this.props.handleChange}
            >
              <option value="" disabled>
                Select Class
              </option>
              {this.props.classes.sort(compareByName).map((el, idx) => {
                return (
                  <option value={el.id} key={idx}>
                    Class {el.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-1 text-right pr-0 pl-0 mt-1">
            <strong>
              Subject: <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-3">
            <select
              className={
                this.props.central
                  ? "form-control cusInputSelect"
                  : "form-control"
              }
              name="subjectId"
              value={this.props.subjectId}
              onChange={this.props.handleChange}
              disabled={this.props.classId === "" ? true : false}
            >
              <option value="" disabled>
                Select subjects
              </option>
              {this.props.subjectList.map((sl, idx) => {
                return <option value={sl.id}>{sl.name}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
