import React, { Component } from "react";

class AddNewRelationShip extends Component {
  state = {};
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <strong>Name: </strong>
          </div>
          <div className="col-md-10">
            <input
              type="text"
              name="relationshipName"
              className="form-control"
              value={this.props.relationshipName}
              onChange={this.props.handleRelationShipChange}
            ></input>
          </div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-md-2"></div>
          <div className="col-md-10">
            <div className="row">
              <div className="col">
                <input
                  id="isParent"
                  type="checkbox"
                  name="isParent"
                  checked={this.props.isParent}
                  onChange={this.props.handleRelationShipChange}
                ></input>
                <label
                  htmlFor="isParent"
                  className="ml-2"
                  style={{ position: "relative", top: "-2px" }}
                >
                  Parent
                </label>
              </div>
              <div className="col">
                <input
                  id="isSibling"
                  type="checkbox"
                  name="isSibling"
                  checked={this.props.isSibling}
                  onChange={this.props.handleRelationShipChange}
                ></input>
                <label
                  htmlFor="isSibling"
                  className="ml-2"
                  style={{ position: "relative", top: "-2px" }}
                >
                  Sibling
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddNewRelationShip;
