import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import logo from "../../assets/images/logo/logoYellow.png";
import { URL } from "../../utils/Constants";

class ProfileFooter extends Component {
  state = {
    footerLogoLink: "",
    footerLogoPreview: null,
    footerIsoLink: "",
    footerText: "",
    isFooterActive: false,
  };

  componentDidMount() {
    this.getWebsiteConfig();
  }

  getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, (res) => {
      if (res.status === 200) {
        let data = res.data.data;
        if (data.length > 0) {
          this.setState({
            footerText: data[0].footerText,
            footerLogoPreview: data[0].footerLogo,
            isFooterActive: data[0].isActiveFooter,
            footerLogoLink: data[0].footerLogoLink,
            footerIsoLink: data[0].footerIsoLink,
          });
        }
      }
    });
  };

  render() {
    return (
      <div>
        {this.state.isFooterActive ? (
          <div className="sliderBox">
            <div className="insideSliderBox1">
              <p className="sliderPoweredText">Powered Bys</p>
              <div className="sliderPoweredImage">
                <a
                  href={
                    this.state.footerLogoLink &&
                    this.state.footerLogoLink !== ""
                      ? this.state.footerLogoLink
                      : "https://techarttrekkies.com/"
                  }
                  target="_blank"
                >
                  {/* {this.state.footerLogoPreview ? (
                    <img
                      src={URL.imageSelectURL + this.state.footerLogoPreview}
                      alt="footer logo"
                    />
                  ) : ( */}
                  <img src={logo} alt="footer logo" />
                  {/* )} */}
                </a>
              </div>
            </div>
            <div className="insideBreaker"></div>

            <div className="insideSliderBox2">
              <a
                href={
                  this.state.footerIsoLink && this.state.footerIsoLink !== ""
                    ? this.state.footerIsoLink
                    : "https://www.iafcertsearch.org/certified-entity/995f78f7-b5c9-543d-a166-6916d4668bb3"
                }
                target="_blank"
              >
                {this.state.footerText && this.state.footerText !== "" ? (
                  <p>{this.state.footerText}</p>
                ) : (
                  <p>
                    An ISO <span>9001:2015</span> QMS Certified Company
                  </p>
                )}
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ProfileFooter;
