import React from "react";

function CustomCard(props) {
  return (
    <div className="whiteContainer">
      <div className="cusCardHeader">
        <div className="cusCardIcon">
          <div className={`cardIconBox cardIconIndex${props.index}`}>
            <span className="material-icons-round" aria-hidden="true">
              {props.label.materialIcon}
            </span>
          </div>
        </div>
        <div className="cusCardLabelBox">
          <div className="cusCardLabel">{props.label.name}</div>
          <div className="cusCardValue">{props.label.value}</div>
        </div>
      </div>
      {props.label.subtitle ? (
        <div className="cusCardContent">{props.label.subtitle}</div>
      ) : null}
      {props.label.changeText !== "" ? (
        <>
          <hr className="cardSeparator"></hr>
          <div className="cusCardContent">
            <span>{props.label.changePercent}&nbsp;</span>
            {props.label.changeText}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CustomCard;
