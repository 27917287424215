import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import "../../assets/scss/notFound.scss";

class NotFound extends Component {
  state = {};
  goBack = () => {
    window.history.back();
  };
  render() {
    return (
      <div>
        <p className="tt-error-404">404</p>
        <p className="tt-error-title">Page Not Found</p>
        <p className="tt-error-message">
          Sorry, the requested page was not found. Please check your URL and try
          again..
        </p>
        <button className="tt-error-btn" onClick={this.goBack}>
          <FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon> GO BACK
        </button>
      </div>
    );
  }
}

export default NotFound;
