import { axiosPost } from "../utils/AxiosApi";
import { URL } from "../utils/Constants";

const courseListLOAD = "/lms/courseListLOAD";
const courseSpinnerLOAD = "/lms/courseSpinner";

export default function reducer(
  state = {
    courseList: [],
    courseSpinner: false,
    courseTotalRecords: 0,
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case courseListLOAD:
      newState.courseList = action.courseList;
      newState.courseTotalRecords = action.courseTotalRecords;
      return newState;
    case courseSpinnerLOAD:
      newState.courseSpinner = action.courseSpinner;
      return newState;
    default:
      return newState;
  }
}

//action Creators
export function loadCourseList(courseList, courseTotalRecords) {
  return { type: courseListLOAD, courseList, courseTotalRecords };
}

export function loadCourseSpinner(courseSpinner) {
  return { type: courseSpinnerLOAD, courseSpinner };
}

//dispatcher
export function getCourseList(offset, rowCountLimit) {
  return (dispatch) => {
    let data = {
      offset: offset,
      rowCountLimit: rowCountLimit,
    };
    dispatch(loadCourseSpinner(true));
    axiosPost(
      URL.getCourse,
      data,
      (response) => {
        if (response.status === 200) {
          let courseTotalRecords = response.data.totalRecordsCount;
          let dataCourse = response.data.data;
          if (dataCourse.length > 0) {
            dataCourse.forEach((el) => {
              el.isEditable = false;
              if (el.coursePhases && el.coursePhases.length > 0) {
                el.coursePhases.forEach((cP, idx) => {
                  if (idx === 0) {
                    cP.isOpen = true;
                  } else {
                    cP.isOpen = false;
                  }
                });
              }
            });
          }
          dispatch(loadCourseSpinner(false));
          dispatch(loadCourseList(dataCourse, courseTotalRecords));
        }
      },
      (error) => {
        dispatch(loadCourseSpinner(false));
      }
    );
  };
}
