import { faFileAlt, faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { URL } from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";
import FileModal from "../ViewFile/FileModal";

class ViewResourceFiles extends Component {
  state = {
    fileViewerModal: false,
    filePath: "",
    fileType: "",
    activeIndex: "",
  };

  componentDidMount() {
    this.filterFiles();
  }

  filterFiles = () => {
    let file = [];
    this.props.fileViewList.plannerListResources.length > 0 &&
      this.props.fileViewList.plannerListResources.map((content) => {
        if (content.fileType === "PDF" || content.fileType === "TXT") {
          file.push(content);
        }
      });
    this.setState({ files: file }, () => {
      if (this.state.files.length > 0) {
        let initialFile = this.state.files[0];
        this.setState({
          filePath: initialFile.fileName,
          fileType: initialFile.filePath,
          activeIndex: 0,
        });
      }
    });
  };

  handleChangeFile = (filePath, fileType, idx) => {
    this.setState(
      {
        filePath: "",
        fileType: "",
      },
      () => {
        // this.checkFileExist(content);
        this.setState({
          filePath: filePath,
          fileType: fileType,
          activeIndex: idx,
        });
      }
    );
    let element = document.getElementsByClassName("fileListMenu");
    if (element[0].classList.contains("activeFileList")) {
      element[0].classList.remove("activeFileList");
    }
    // else {
    //   element[0].classList.add("activeFileList");
    // }
  };

  handleFileViewer = (e, item, idx) => {
    this.toggleFileViewer();

    this.setState({
      filePath: item.filePath,
      fileType: item.fileType,
      activeIndex: idx,
    });
  };

  toggleFileViewer = () => {
    this.setState({
      // fileUrl: URLs.imageSelectURL + param,
      fileViewerModal: !this.state.fileViewerModal,
    });
  };
  render() {
    return (
      <div className="row p-4 m-4">
        {this.props.fileViewList.plannerListResources?.map((file, idx) => {
          if (file.fileType === "IMG")
            return (
              <div className="col-md-3">
                <FontAwesomeIcon icon={faFileImage} size="3x"></FontAwesomeIcon>
                <p
                  style={{
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    paddingLeft: "8px",
                    whiteSpace: "nowrap",
                    paddingRight: "8px",
                  }}
                >
                  <a href={URL.fileDownloadURL + file.filePath} target="_blank">
                    {file.fileName}
                  </a>
                </p>
              </div>
            );
          if (file.fileType === "PDF")
            return (
              <div className="col-md-3">
                <a
                  onClick={(e) => this.handleFileViewer(e, file, idx)}
                  className="d-block"
                  target="_blank"
                  download
                >
                  <FontAwesomeIcon icon={faFileAlt} size="3x"></FontAwesomeIcon>
                  <p
                    style={{
                      width: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingLeft: "8px",
                      whiteSpace: "nowrap",
                      paddingRight: "8px",
                      cursor: "default",
                      fontWeight: 500,
                    }}
                  >
                    {/* <a
                href={URL.fileDownloadURL + content.content}
                target="_blank"
                download
              >
                {content.origin}
              </a> */}

                    {file.fileName}
                  </p>
                </a>
              </div>
            );
        })}

        <ModalWindow
          modal={this.state.fileViewerModal}
          modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          size="lg"
          modalHeader="Resource Files"
          modalBody={
            <FileModal
              files={this.state.files}
              fileType={this.state.fileType}
              filePath={this.state.filePath}
              handleChangeFile={this.handleChangeFile}
              activeIndex={this.state.activeIndex}
              fileExist={true}
              isNotice={false}
              isResourceFile={true}
              type="resourceFile"
            ></FileModal>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default ViewResourceFiles;
