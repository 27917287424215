import moment from "moment";
import React, { Component } from "react";
import Masonry from "react-masonry-css";

class ListAllCalendarEvents extends Component {
  state = {};

  compareDate = (start, end) => {
    if (moment(start).format("ll") === moment(end).format("ll")) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <>
        <div className="text-center">
          {this.props.eventsTitle?.length > 0 ? (
            <div className="tt-group-header" style={{ borderRadius: 0 }}>
              Important Dates
            </div>
          ) : null}

          <div className="row mt-3">
            <div className="col-md-8">
              {/* <div className="row make-columns"> */}
              <Masonry
                breakpointCols={2}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {this.props.eventsTitle.length > 0
                  ? this.props.eventsTitle.map((el, id) => {
                      return (
                        <div key={id}>
                          <h6
                            style={{
                              backgroundColor: el.color,
                              padding: "8px",
                              color: "white",
                              textShadow: "1px 2px 1px rgba(0,0,0,0.33)",
                            }}
                          >
                            {el.eventTypeName}
                            {el.holiday ? "(Holiday)" : " "}
                          </h6>
                          <table className="table table-bordered text-left table-striped">
                            <tbody>
                              {this.props.eventsWithSameEventType[
                                el.eventTypeName
                              ].map((eET) => {
                                return (
                                  <tr>
                                    <td
                                      width="120px"
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      {this.compareDate(eET.start, eET.end)
                                        ? moment(eET.start).format("ll")
                                        : `${moment(eET.start).format("ll")} -
                                ${moment(eET.end).format("ll")}`}
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <strong>{eET.title}</strong>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      );
                    })
                  : null}{" "}
              </Masonry>

              {/* </div> */}
            </div>

            <div className="col-md-4">
              {this.props.holidayEvents?.length > 0 ? (
                <h6
                  style={{
                    backgroundColor: "red",
                    padding: "8px",
                    color: "white",
                    textShadow: "1px 2px 1px rgba(0,0,0,0.33)",
                  }}
                >
                 All Holidays
                </h6>
              ) : null}

              <table className="table table-bordered text-left table-striped">
                <tbody>
                  {this.props.holidayEvents.map((hevnts, id) => {
                    return (
                      <tr key={id}>
                        <td width="120px" style={{ verticalAlign: "middle" }}>
                          {this.compareDate(hevnts.start, hevnts.end)
                            ? moment(hevnts.start).format("ll")
                            : `${moment(hevnts.start).format("ll")} -
                              ${moment(hevnts.end).format("ll")}`}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <strong>{hevnts.title}</strong>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ListAllCalendarEvents;
