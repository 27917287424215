import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { ClassValidationSchema } from "./classValidationSchema";
import { TextField } from "../../UI/TextField";
import { SelectField } from "../../UI/SelectField";

class ClassForm extends Component {
  state = {
    classroomForms: {
      classForms: [
        {
          name: "",
          educationLevelId: ""
        }
      ]
    },
    educationLevels: [],
    error: false,
    showSpinner: false
  };

  componentDidMount() {
    this.getEducationLevels();
  }

  getEducationLevels = () => {
    axiosPost(URL.getAllEducationLevel, {}, response => {
      if (response.status == 200) {
        this.setState({
          educationLevels: response.data.data
        });
      }
    });
  };

  handleSave = values => {
    this.setState({
      showSpinner: true
    });
    axiosPost(URL.insertClass, values.classForms, response => {
      if (response.status == 200) {
        this.setState({
          classForms: [{ name: "", educationLevelId: "" }],
          showSpinner: false,
          error: false
        });
        swal("Success", response.data.message);
        this.props.getAllClasses();
        this.props.toggleModal();
      }
    });
  };
  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}

        <Formik
          initialValues={this.state.classroomForms}
          validationSchema={ClassValidationSchema}
          onSubmit={values => this.handleSave(values)}
        >
          {({ values, errors, touched }) => {
            // console.log(errors);
            return (
              <Form>
                <FieldArray
                  name="classForms"
                  render={({ remove, push }) => {
                    return (
                      <>
                        {values?.classForms?.length > 0 &&
                          values?.classForms?.map((value, index) => (
                            <div className="row mt-2" key={index}>
                              <div className="col-lg-5">
                                <SelectField
                                  name={`classForms.${index}.educationLevelId`}
                                  index={index}
                                  optionFields={this.state.educationLevels}
                                />
                                {/* <Field
                                  name={`classForms.${index}.educationLevelId`}
                                  // className="form-control cusInput"
                                  className={`form-control cusInput ${
                                    errors &&
                                    errors?.classForms &&
                                    errors.classForms.length > 0 &&
                                    errors.classForms[index]
                                      ?.educationLevelId == ""
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  component="select"
                                >
                                  <option value="">
                                    Choose Education Level...
                                  </option>
                                  {this.state.educationLevels.length > 0
                                    ? this.state.educationLevels.map(
                                        (educationLevel, eduIndex) => {
                                          return (
                                            <option value={educationLevel.id}>
                                              {educationLevel.name} ||{" "}
                                              {educationLevel.code}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Field> */}
                                {/* <div className="mt-1">
                                  <ErrorMessage
                                    name={`classForms.${index}.educationLevelId`}
                                    className="errorMsg-textField"
                                    component="div"
                                  />
                                </div> */}
                              </div>
                              <div className="col-lg-5">
                                <TextField
                                  name={`classForms.${index}.name`}
                                  type="text"
                                  placeholder="Enter class name"
                                />
                              </div>
                              <div className="col-lg-2">
                                {index > 0 ? (
                                  <span
                                    className="material-icons-round text-danger pointer"
                                    style={{ cursor: "pointer" }}
                                    aria-hidden="true"
                                    onClick={e => remove(index)}
                                  >
                                    remove_circle
                                  </span>
                                ) : null}
                                <span
                                  className="material-icons-round text-success pointer"
                                  style={{ cursor: "pointer" }}
                                  aria-hidden="true"
                                  onClick={() =>
                                    push({ name: "", educationLevelId: "" })
                                  }
                                >
                                  add_circle
                                </span>
                              </div>
                            </div>
                          ))}
                      </>
                    );
                  }}
                />

                <div className="row mt-3">
                  <div className="col text-end">
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize float-right"
                      // onClick={this.handleSave}
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>

        {/* <div className="row">
          {this.state.classForms.length > 0
            ? this.state.classForms.map((classForm, index) => {
                return (
                  <>
                    <div className="col-lg-5 mt-3">
                      <select
                        className={`${
                          this.state.error && classForm.educationLevelId == ""
                            ? "form-select cusInput border border-danger"
                            : "form-select cusInput"
                        }`}
                        name="educationLevelId"
                        defaultValue=""
                        value={classForm.educationLevelId}
                        onChange={(e) => this.handleSelect(e, index)}
                      >
                        <option value="" disabled>
                          Choose Education Level...
                        </option>
                        {this.state.educationLevels.length > 0
                          ? this.state.educationLevels.map(
                              (educationLevel, eduIndex) => {
                                return (
                                  <option value={educationLevel.id}>
                                    {educationLevel.name} ||{" "}
                                    {educationLevel.code}
                                  </option>
                                );
                              }
                            )
                          : null}
                      </select>
                    </div>
                    <div className="col-lg-5 mt-3">
                      <input
                        className={`${
                          this.state.error && classForm.name == ""
                            ? "form-control cusInput border border-danger"
                            : "form-control cusInput"
                        }`}
                        name="name"
                        type="text"
                        onChange={(e) => this.handleChange(e, index)}
                        value={classForm.name}
                        placeholder="Enter class name here"
                      />
                    </div>
                    <div className="col-lg-2 mt-3">
                      {index > 0 ? (
                        <span
                          className="material-icons-round text-danger"
                          aria-hidden="true"
                          onClick={(e) => this.removeClassForm(index)}
                        >
                          remove_circle
                        </span>
                      ) : null}
                      {index === this.state.classForms.length - 1 ? (
                        <span
                          className="material-icons-round text-success"
                          aria-hidden="true"
                          onClick={this.addClassForm}
                        >
                          add_circle
                        </span>
                      ) : null}
                    </div>
                  </>
                );
              })
            : null}
        </div> */}
        {/* <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSave}
            >
              Save
            </button>
          </div>
        </div> */}
      </>
    );
  }
}

export default ClassForm;
