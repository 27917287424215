import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import CustomEditButton from "../../components/common/CustomEditButton";

class ListMainFeature extends Component {
  //   deleteFeature = (id, idx) => {
  //     let data = {
  //       id: id
  //     };
  //     axiosPost(URL.deleteFeature, data, response => {
  //       if (response.status == 200) {
  //         this.props.getAllFeature();
  //         swal("Success", response.data.message);
  //       }
  //     });
  //   };
  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  <th>Icon</th>
                  <th>Title</th>
                  <th>Display</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.features.length > 0
                  ? this.props.features.map((feature, index) => {
                      return (
                        <tr>
                          <img
                            width="100"
                            src={URL.imageSelectURL + feature.imagePath}
                          />
                          <td>{feature.name}</td>
                          <td>
                            {" "}
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  this.props.handleChangeToggle(e, feature)
                                }
                                checked={feature.display}
                                name="status"
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            {/* <CustomDeleteButton
                              onClick={() => this.props.handleDelete(feature)}
                            /> */}
                            <CustomEditButton
                              onClick={() =>
                                this.props.handleEditClick(feature)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default ListMainFeature;
