import React, { Component } from "react";
import GpaTableRow from "./GpaTableRow";
class GpaTable extends Component {
    state = {};

    tableRow() {
        return this.props.defaultGpaValues.length > 0
            ? this.props.defaultGpaValues.map((val, i) => {
                return (
                    <GpaTableRow
                        val={val}
                        idx={i}
                        handleRemoveGpas={this.props.handleRemoveGpas}
                        handleValueEditable={this.props.handleValueEditable}
                    />
                );
            })
            : null;
    }
    render() {
        return (
            <table className="table table-bordered text-center table-striped">
                <thead className="tt-group-header">
                    <tr>
                        <th width="10%">Grade</th>
                        <th width="15%">Percentage</th>
                        <th width="10%">Grade point</th>
                        <th width="15%">Grade point average</th>
                        <th width="15%">Grade evaluation</th>
                        <th width="10%">Remove</th>
                    </tr>
                </thead>
                <tbody>{this.tableRow()}</tbody>
            </table>
        );
    }
}

export default GpaTable;
