import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { compareByName } from "../../utils/Utils";

const animatedComponents = makeAnimated();

class GeneratePaymentBills extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <div>
              <strong>{localStorage.getItem("yearLevel")}</strong>
            </div>
            <select
              name="yearlevelId"
              value={this.props.yearlevelId}
              className="form-control"
              onChange={this.props.handleChange}
            >
              <option value="" selected disabled>
                Choose {localStorage.getItem("yearLevel")}
              </option>
              {this.props.yearLevels?.sort(compareByName).map((y, idx) => (
                <option value={y.id} key={idx}>
                  Class {y.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <div>
              <strong>Fee Type</strong>
            </div>
            <select
              name="configType"
              onChange={this.props.handleChange}
              className="form-control"
              value={this.props.configType}
            >
              <option value="" selected disabled>
                Choose config type
              </option>
              <option value={"monthly"}>Monthly</option>
              <option value={"annually"}>Annual</option>
            </select>
          </div>
          <div className="col-md-4">
            {this.props.configType === "monthly" &&
              this.props.yearlevelId !== "" && (
                <>
                  <div>
                    <strong>Months</strong>
                  </div>
                  <Select
                    isMulti={this.props.fromDraft ? false : true}
                    closeMenuOnSelect={this.props.fromDraft ? true : false}
                    components={animatedComponents}
                    value={this.props.selectedMonths}
                    options={this.props.months}
                    name="months"
                    onChange={this.props.handleMonthChange}
                    disabled={this.props.yearlevelId === ""}
                  />
                </>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default GeneratePaymentBills;
