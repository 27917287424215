import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";

const MailConfiguration = () => {
  const [sendToOptions, setSendToOptions] = useState([]);
  const [sendTo, setSendTo] = useState([]);
  const [sendCcto, setSendCcto] = useState([]);
  const [ccToOptions, setCcToOptions] = useState([]);
  const [sendToConfig, setSendToConfig] = useState([
    {
      sendTo: "",
    },
  ]);
  const [ccToConfig, setCcToConfig] = useState([
    {
      ccTo: "",
    },
  ]);

  const [sendToValue, setSendToValue] = useState([]);
  const [ccToValue, setCcToValue] = useState([]);

  const addSendTo = (e) => {
    e.preventDefault();
    let mailConfiguration = [...sendToConfig];
    mailConfiguration.push({
      sendTo: "",
    });
    setSendToConfig(mailConfiguration);
  };

  const handleDeleteSendTo = (param) => {
    axiosPost(URL.deleteEmailConfiguration, param, (response) => {
      if (response.status === 200) {
      }
    });
  };

  const removeSendTo = (e, id) => {
    e.preventDefault();
    let mailConfiguration = [...sendToConfig];
    mailConfiguration.splice(id, 1);
    if (mailConfiguration.length < 1) {
      swal({ title: "Error", text: "Atleast one question is necessary" });
    } else {
      setSendToConfig(mailConfiguration);
    }

    const param = {
      id: "",
      isCC: false,
    };
    handleDeleteSendTo(param);
  };

  const addCCto = (e) => {
    e.preventDefault();
    let mailConfiguration = [...ccToConfig];
    mailConfiguration.push({
      sendTo: "",
    });
    setCcToConfig(mailConfiguration);
  };

  const removeCCTo = (e, id) => {
    e.preventDefault();
    let mailConfiguration = [...ccToConfig];
    mailConfiguration.splice(id, 1);
    if (mailConfiguration.length < 1) {
      swal({ title: "Error", text: "Atleast one question is necessary" });
    } else {
      setCcToConfig(mailConfiguration);
    }
  };

  const getAllEmailConfiguration = () => {
    axiosPost(URL.getEmailConfiguration, "", (response) => {
      if (response.status === 200) {
        const SendToOptions = response?.data?.data?.sendToValues?.map(
          (item) => item.email
        );
        const CcToOptions = response?.data?.data?.sendCcValues?.map(
          (item) => item.email
        );

        setSendTo(SendToOptions);
        setSendCcto(CcToOptions);
      }
    });
  };

  const handleEmailSend = () => {
    const params = {
      sendTos: sendTo,
      sendCcs: sendCcto,
    };

    if (sendTo.length > 0) {
      axiosPost(URL.insertEmailConfiguration, params, (response) => {
        if (response.status === 200) {
          swal({ title: "Success", text: "Email saved Successfully" });
          getAllEmailConfiguration();
        }
      });
    } else {
      swal("Error", "One primary email is required");
    }
  };

  useEffect(() => {
    getAllEmailConfiguration();
  }, []);

  return (
    <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
      <div className="tt-group-header">
        <div className="row mt-2">
          <div className="col-md-6">
            <label for="sendTo">Send To</label>

            <ReactMultiEmail
              placeholder="Send To"
              emails={sendTo}
              onChange={(_emails) => {
                setSendTo(_emails);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
          <div className="col-md-6">
            <label for="sendCCTo">CCs To</label>
            <ReactMultiEmail
              placeholder="Send CC to"
              emails={sendCcto}
              onChange={(_emails) => {
                setSendCcto(_emails);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
            {/* <div className="row">
            {sendToConfig &&
              sendToConfig?.map((value, id) => {
                return (
                  <>
                    <div className="col-md-8 mt-3">
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        // components={animatedComponents}
                        options={sendToOptions}
                        placeholder="Send to"
                        name="sendTo"
                        onChange={(e)=>handleSendTo(e,id)}
                        closeMenuOnScroll={false}
                        // value={this.state.sendTo}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-2">
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="removeMcqOption"
                            onClick={e => removeSendTo(e, id)}
                          ></FontAwesomeIcon>
                        </div>
                        <div className="col-md-2">
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            id="addField"
                            className="addMcqOption ml-3"
                            onClick={e => addSendTo(e)}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="col-md-5">
          <div className="row">
            {ccToConfig &&
              ccToConfig?.map((value, id) => {
                return (
                  <>
                    <div className="col-md-8  mt-3">
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        // components={animatedComponents}
                        options={ccToOptions}
                        placeholder="Send CC to"
                        name="ccTo"
                        onChange={(e)=>handleCcTo(e)}
                        closeMenuOnScroll={false}
                        // value={this.state.sendTo}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-2">
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="removeMcqOption"
                            onClick={e => removeCCTo(e, id)}
                          ></FontAwesomeIcon>
                        </div>
                        <div className="col-md-2">
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            id="addField"
                            className="addMcqOption ml-3"
                            onClick={e => addCCto(e)}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div> */}
          </div>
        </div>
        {(sendTo || sendCcto) && (
          <div className="d-flex justify-content-right mt-2">
            <button
              className="tt-button tt-button-primary"
              onClick={(e) => handleEmailSend(e)}
            >
              Save Configuration
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default MailConfiguration;
