import React, { Component } from "react";
import { fileTypes } from "../../utils/Constants";
import SearchResult from "./SearchResult";
import { Spinner } from "reactstrap";

class SearchPageHeader extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="tt-group-header">Search</div>
        <div className="container">
          <div className="row pt-3 px-3">
            <div className="col-md-1"></div>
            <div className="col-md-8">
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  value={this.props.searchText}
                  onChange={this.props.handleChange}
                  placeholder={
                    this.props.searchType === "Resources"
                      ? "Enter reading material title or description"
                      : this.props.searchType === "Group Page"
                      ? "Enter group name"
                      : "Enter your text here"
                  }
                />
              </div>
              <div className="row mt-3">
                {/* <div className="col-md-2">
                  <strong className="primaryColor">Search Type: </strong>
                </div> */}
                <div className="col">
                  <div className="searchTypeHolder">
                    {this.props.teacherPage || this.props.classPage ? null : (
                      <>
                        <div className="searchTypeBox">
                          <input
                            type="radio"
                            name="searchType"
                            value="Group Page"
                            onChange={this.props.handleChange}
                            checked={this.props.searchType === "Group Page"}
                            id="gp"
                          />
                          <label htmlFor="gp" className="ml-1">
                            Group Page
                          </label>
                        </div>
                        <div className="searchTypeBox">
                          <input
                            type="radio"
                            name="searchType"
                            value="User Profile"
                            onChange={this.props.handleChange}
                            checked={this.props.searchType === "User Profile"}
                            id="up"
                          />
                          <label htmlFor="up" className="ml-1">
                            User Profile
                          </label>
                        </div>
                      </>
                    )}
                    <div className="searchTypeBox">
                      <input
                        type="radio"
                        name="searchType"
                        value="Resources"
                        onChange={this.props.handleChange}
                        checked={this.props.searchType === "Resources"}
                        id="res"
                      />
                      <label htmlFor="res" className="ml-1">
                        Reading Materials
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mt-1">
                <button
                  className="tt-button tt-button-primary "
                  onClick={this.props.handleSearch}
                  disabled={this.props.spinner}
                >
                  Search
                </button>
                {this.props.spinner && (
                  <Spinner
                    color="primary"
                    style={{
                      width: "1rem",
                      height: "1rem",
                      marginLeft: "4px",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          {this.props.searchType === "Resources" || this.props.searching ? (
            <React.Fragment>
              <div className="row">
                <div className="col-md-12">
                  <hr />
                </div>
              </div>
              <div className="row">
                {this.props.searchType === "Resources" ? (
                  <div
                    className="col-md-3"
                    style={{ borderRight: "1px solid #ccc" }}
                  >
                    <strong className="primaryColor">School LMS Type: </strong>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="schoolLMSType"
                        value=""
                        onChange={this.props.handleChange}
                        checked={this.props.schoolLMSType === ""}
                        id="all"
                      />
                      <label htmlFor="all" className="ml-1">
                        All
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="schoolLMSType"
                        value="curriculum"
                        onChange={this.props.handleChange}
                        checked={this.props.schoolLMSType === "curriculum"}
                        id="crc"
                      />
                      <label htmlFor="crc" className="ml-1">
                        Curriculum
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="schoolLMSType"
                        value="textbooks"
                        onChange={this.props.handleChange}
                        checked={this.props.schoolLMSType === "textbooks"}
                        id="txtB"
                      />
                      <label htmlFor="txtB" className="ml-1">
                        Textbooks
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="schoolLMSType"
                        value="question-bank"
                        onChange={this.props.handleChange}
                        checked={this.props.schoolLMSType === "question-bank"}
                        id="quesB"
                      />
                      <label htmlFor="quesB" className="ml-1">
                        Question Bank
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="schoolLMSType"
                        value="av-resource"
                        onChange={this.props.handleChange}
                        checked={this.props.schoolLMSType === "av-resource"}
                        id="audVid"
                      />
                      <label htmlFor="audVid" className="ml-1">
                        Audio/Visual
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="schoolLMSType"
                        value="sign-language"
                        onChange={this.props.handleChange}
                        checked={this.props.schoolLMSType === "sign-language"}
                        id="sgnLan"
                      />
                      <label htmlFor="sgnLan" className="ml-1">
                        Sign Language
                      </label>
                    </div>
                    <hr></hr>
                    <strong className="primaryColor">Content Type: </strong>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="contentType"
                        value=""
                        onChange={this.props.handleChange}
                        checked={this.props.contentType === ""}
                        id="all"
                      />
                      <label htmlFor="all" className="ml-1">
                        All
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="contentType"
                        value={fileTypes.pdf}
                        onChange={this.props.handleChange}
                        checked={this.props.contentType === fileTypes.pdf}
                        id="pdf"
                      />
                      <label htmlFor="pdf" className="ml-1">
                        PDF
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="contentType"
                        value={fileTypes.image}
                        onChange={this.props.handleChange}
                        checked={this.props.contentType === fileTypes.image}
                        id="img"
                      />
                      <label htmlFor="img" className="ml-1">
                        Image
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="contentType"
                        value={fileTypes.video}
                        onChange={this.props.handleChange}
                        checked={this.props.contentType === fileTypes.video}
                        id="vid"
                      />
                      <label htmlFor="vid" className="ml-1">
                        Video
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="contentType"
                        value={fileTypes.audio}
                        onChange={this.props.handleChange}
                        checked={this.props.contentType === fileTypes.audio}
                        id="aud"
                      />
                      <label htmlFor="aud" className="ml-1">
                        Audio
                      </label>
                    </div>
                    <div className="m-2">
                      <input
                        type="radio"
                        name="contentType"
                        value={fileTypes.link}
                        onChange={this.props.handleChange}
                        checked={this.props.contentType === fileTypes.link}
                        id="lnk"
                      />
                      <label htmlFor="lnk" className="ml-1">
                        Links
                      </label>
                    </div>
                  </div>
                ) : null}
                <div className="col">
                  <SearchResult
                    spinner={this.props.spinner}
                    searchData={this.props.searchData}
                    searchType={this.props.searchType}
                    contentType={this.props.contentType}
                    searching={this.props.searching}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </>
    );
  }
}

export default SearchPageHeader;
