import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CustomModal = (props) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        toggle={props.toggleModal}
        aria-labelledby="contained-modal-title-vcenter"
        className={props.modalClass}
        size={props.size ? props.size : ""}
        centered
        id={props.id ? props.id : "myModal"}
      >
        {props.noModelHeader ? null : (
          <ModalHeader toggle={props.toggleModal}>
            <div>{props.modalHeader}</div>
          </ModalHeader>
        )}
        <ModalBody>{props.modalBody}</ModalBody>
        {props.modalFooter ? (
          <ModalFooter>{props.modalFooter}</ModalFooter>
        ) : (
          ""
        )}
      </Modal>
    </React.Fragment>
  );
};

export default CustomModal;
