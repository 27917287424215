import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

class SystemExamHeader extends Component {
  state = {};
  render() {
    const {
      practicalMaxMark,
      theoryMaxMark,
      marksCalculation,
      assessmentSubjects,
      educationLevelDivisions,
      assessments,
      subjectList,
      classRooms,
      yearLevels,
      educationLevels,
    } = this.props;
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-md-3 text-right mt-1">
              <strong>Question title</strong>
              <input
                type="text"
                placeholder="Enter Topic"
                name="topic"
                value={this.props.topic}
                onChange={this.props.handleChange}
                className="form-control"
              ></input>
            </div> */}
            <div className="col-md-2 text-right mt-1">
              <strong>Education Level: </strong>
              <select
                className="form-control"
                name="educationLevelId"
                value={this.props.educationLevelId}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Select Education Level
                </option>
                {educationLevels.map((el, idx) => {
                  return <option value={el.id}>{el.name}</option>;
                })}
              </select>
            </div>
            <div className="col-md-2 text-right mt-1">
              <strong>Education Level Division: </strong>
              <select
                className="form-control"
                name="educationLevelDivisionId"
                value={this.props.educationLevelDivisionId}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Select education level divisions
                </option>
                {educationLevelDivisions?.map((edv, idx) => {
                  return <option value={edv.id}>{edv.name}</option>;
                })}
              </select>
            </div>

            <div className="col-md-2 text-right mt-1">
              <strong>Year Level: </strong>
              <select
                className="form-control"
                name="yearLevelId"
                value={this.props.yearLevelId}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Select year level
                </option>
                {yearLevels.map((y, idx) => {
                  return <option value={y.id}>{y.name}</option>;
                })}
              </select>
            </div>
            <div className="col-md-2 text-right mt-1">
              <strong>CLassroom: </strong>
              <select
                className="form-control"
                name="classRoomId"
                value={this.props.classRoomId}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Select classroom
                </option>
                {classRooms.map((c, idx) => {
                  return <option value={c.id}>{c.name}</option>;
                })}
              </select>
            </div>
            <div className="col-md-2 text-right mt-1">
              <strong>Assessment: </strong>
              <select
                className="form-control"
                name="assessmentId"
                value={this.props.assessmentId}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Select assessment
                </option>
                {assessments.map((a, idx) => {
                  return <option value={a.id}>{a.assessmentName}</option>;
                })}
              </select>
            </div>
            <div className="col-md-2 text-right mt-1">
              <strong>Subject: </strong>
              <select
                className="form-control"
                name="subjectId"
                value={this.props.subjectId}
                onChange={this.props.handleChange}
                disabled={this.props.educationLevelCode === "" ? true : false}
              >
                <option value="" disabled>
                  Select subjects
                </option>
                {assessmentSubjects?.map((sl, idx) => {
                  return <option value={sl.subjectId}>{sl.subjectName}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 text-right mt-1 justify-content-end">
              <strong>Marks type: </strong>
              <select
                className="form-control"
                name="marksCalculation"
                value={this.props.marksCalculation}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Select marks type
                </option>
                <option value="0">Total</option>
                <option value="1">Individual</option>
              </select>
            </div>
            <div className="col-md-6 text-right mt-1 d-inline-flex">
              <div className="row">
                <div className="col">
                  <strong>Theory marks</strong>
                  <input
                    type="text"
                    value={theoryMaxMark}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <strong>Practical marks</strong>
                  <input
                    type="text"
                    value={practicalMaxMark}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row mt-2">
            <div
              className="col-md-2 text-right mt-1"
              style={{ maxWidth: "12%" }}
            >
              <strong>Marks</strong>
            </div>
            <div className="col-md-3">
              <select
                name="marks"
                className="form-control"
                value={this.props.marks}
                onChange={this.props.handleChange}
              >
                <option value="full">Full</option>
                <option value="individual">Individual</option>
              </select>
            </div>
            <div className="col-md-2">
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default SystemExamHeader;
