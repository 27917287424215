import React, { Component } from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import ModalWindow from "../../../UI/ModalWindow";

class ArticleHistory extends Component {
  state = {
    posts: "",
    keywordModal: false,
  };

  displayKeywordModal = () => {
    this.setState({ keywordModal: !this.state.keywordModal });
  };

  keywordModalBody = () => {
    return (
      <ol>
        {this.props.history
          ? this.props.history.postKeywords
            ? this.props.history.postKeywords.map((keyword, idx) => {
                return <li key={idx}>{keyword.keyword}</li>;
              })
            : null
          : null}
      </ol>
    );
  };

  render() {
    let post = this.props.history;
    return (
      <>
        <div className="container-fluid" style={{ width: "94%" }}>
          <div className="row">
            <div className="col text-center">
              <p>
                <strong>
                  {moment(post.createdAt).format("dddd, MMMM Do YYYY")}
                </strong>
              </p>
            </div>
          </div>
          <div className="tt-historyBorder">
            <div className="row mb-1">
              <div className="col">
                <p className="mb-0" style={{ fontSize: "20px" }}>
                  <strong>{post.heading}</strong>
                </p>
              </div>
            </div>
            <div className="tt-mobilePostKeywords">
              {post.postKeywords.length > 0 ? (
                <div className="row mb-3">
                  <div className="tt-postTargetList col">
                    {post.postKeywords.map((keyWord, idx) => {
                      return idx <= 3 ? (
                        keyWord.keyword !== "" ? (
                          <div key={idx}>{keyWord.keyword}</div>
                        ) : null
                      ) : idx === post.postKeywords.length - 1 ? (
                        <div
                          key={idx}
                          style={{ cursor: "pointer" }}
                          onClick={this.displayKeywordModal}
                        >
                          + {post.postKeywords.length - 4} more
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="tt-desktopPostKeywords">
              {post.postKeywords.length > 0 ? (
                <div className="row mb-3">
                  <div className="tt-postTargetList col">
                    {post.postKeywords.map((keyWord, idx) => {
                      return idx <= 11 ? (
                        keyWord.keyword !== "" ? (
                          <div key={idx}>{keyWord.keyword}</div>
                        ) : null
                      ) : idx === post.postKeywords.length - 1 ? (
                        <div
                          key={idx}
                          style={{ cursor: "pointer" }}
                          onClick={this.displayKeywordModal}
                        >
                          + {post.postKeywords.length - 12} more
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            {post.postThemes.length > 0 || post.postQuestions.length > 0 ? (
              <div className="container-fluid tt-themeQuestionHolder">
                {post.postThemes ? (
                  post.postThemes.length > 0 ? (
                    <div className="row">
                      <div className="col tt-theme-header">
                        {post.postThemes.map((theme, idx) => {
                          return (
                            <p key={idx}>
                              <strong>Theme : </strong>
                              {theme.themeName}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  ) : null
                ) : null}
                {post.postQuestions ? (
                  post.postQuestions.length > 0 ? (
                    <div className="row mt-1">
                      <div className="col tt-question-header">
                        <div className="mb-0">
                          Questions that this article addresses :{" "}
                        </div>
                        {post.postQuestions.map((question, idx) => {
                          return (
                            <>
                              <p key={idx}>{question.question}</p>
                              <span>, </span>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
            ) : null}
            <div className="row">
              <div className="col-12">
                <div className="row mt-3 mb-2">
                  <div className="col-12 wordBreakHyphen editorPostContent">
                    {ReactHtmlParser(
                      post.textContent !== ""
                        ? post.textContent
                        : "No text content"
                    )}
                  </div>
                </div>
                {post.contents.length > 0 ? (
                  <div className="row">
                    <div className="col">
                      <div className="tt-linkHolder">
                        <div className="tt-group-header mb-0">
                          Link References
                        </div>
                        <div>
                          {post.contents.map((content, idx) => {
                            if (
                              content.contentTypeCode === "LNK" &&
                              content.content !== ""
                            ) {
                              return (
                                <div className="tt-linkReference">
                                  <a
                                    href={
                                      content.content.includes("https") ||
                                      content.content.includes("http")
                                        ? content.content
                                        : `${"https://" + content.content}`
                                    }
                                    target="_blank"
                                    key={idx}
                                  >
                                    <div className="row text-left">
                                      <div className="col">
                                        <div className="row tt-videoLNK">
                                          {content.thumbnail ? (
                                            <div className="col-md-2 tt-videoLNKImage">
                                              <img
                                                src={content.thumbnail}
                                              ></img>
                                            </div>
                                          ) : null}
                                          <div
                                            className="col"
                                            style={{
                                              display: "flex",
                                              alignItems: "Center",
                                              wordBreak: "break-all",
                                            }}
                                          >
                                            <p>{content.linkTitle}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.keywordModal}
          toggleModal={this.displayKeywordModal}
          modalHeader="Keywords Assigned"
          modalBody={this.keywordModalBody()}
        ></ModalWindow>
      </>
    );
  }
}

export default ArticleHistory;
