import React, { Component } from "react";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class ModuleCreationModal extends Component {
  state = {
    modules: [{ name: "" }],
    spinner: false,
    moduleCode: "",
  };

  addFields = () => {
    let modules = this.state.modules;
    modules.push({
      name: "",
    });
    this.setState({ modules });
  };

  handleChange = (e, idx) => {
    let modules = this.state.modules;
    let name = e.target.name;
    let value = e.target.value;
    modules[idx][name] = value;
    this.setState({ modules });
  };

  handleSubmit = () => {
    const data = this.state.modules[0];
    data.moduleCode = this.state.moduleCode;
    if (data.name && data.name !== "" && data.moduleCode !== "") {
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.insertModule,
          data,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", "Successfully inserted");
              this.props.getAllModules();
              this.props.toggleModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  removeFields = (idx) => {
    let modules = this.state.modules;
    modules.splice(idx, 1);
    this.setState({ modules });
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? <CustomSpinner></CustomSpinner> : null}
        <div className="row">
          <div className="col-md-12">
            <p className="formTitle">Module Name</p>
          </div>
        </div>
        {this.state.modules.map((mod, idx) => {
          return (
            <div className={"row " + (idx !== 0 ? "mt-2" : "")} key={idx}>
              <div className="col-md-7">
                <input
                  type="text"
                  className="form-control cusInput"
                  name="name"
                  value={mod.name}
                  onChange={(e) => this.handleChange(e, idx)}
                  placeholder="Enter module name"
                />
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control cusInput"
                  name="moduleCode"
                  value={this.state.moduleCode}
                  onChange={(e) =>
                    this.setState({ moduleCode: e.target.value })
                  }
                  placeholder="Enter module code"
                />
              </div>
              {/* <div className="col-md-3 multipleFieldBtnBox">
                {idx === 0 && idx === this.state.modules.length - 1 ? null : (
                  <span
                    className="material-icons-round deleteColor"
                    onClick={() => this.removeFields(idx)}
                  >
                    disabled_by_default
                  </span>
                )}
                {idx === this.state.modules.length - 1 ? (
                  <span
                    className="material-icons-round primaryColor"
                    onClick={this.addFields}
                  >
                    add_box
                  </span>
                ) : null}
              </div> */}
            </div>
          );
        })}
        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ModuleCreationModal;
