import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

class SubTopicReRender extends Component {
  state = {};

  render() {
    return (
      <div className="tt-subTopicDiv pb-4">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <strong>
              Topic Name{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-2">
            <strong>Lecture Hour</strong>
          </div>
          <div className="col-md-2">
            <strong>Practical Hour</strong>
          </div>
          <div className="col-md-4"></div>
        </div>
        {this.props.topic && this.props.topic.length > 0
          ? this.props.topic.map((top, idx) => {
              return (
                <span key={idx}>
                  <div className="row mt-1" key={idx}>
                    <div className="col-md-1 text-right">{idx + 1}.</div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={top.name}
                        onChange={(e) =>
                          this.props.handleReRenderChange(
                            e,
                            this.props.topic,
                            idx
                          )
                        }
                      ></input>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="number"
                        className="form-control"
                        name="lectureHour"
                        value={top.lectureHour}
                        onChange={(e) =>
                          this.props.handleReRenderChange(
                            e,
                            this.props.topic,
                            idx
                          )
                        }
                      ></input>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="number"
                        className="form-control"
                        name="practicalHour"
                        value={top.practicalHour}
                        onChange={(e) =>
                          this.props.handleReRenderChange(
                            e,
                            this.props.topic,
                            idx
                          )
                        }
                      ></input>
                    </div>
                    <div className="col-md-auto">
                      <button
                        className="tt-button tt-button-primary mr-3"
                        onClick={() =>
                          this.props.addSubtopics(this.props.topic, idx)
                        }
                        disabled={
                          top.subTopic && top.subTopic.length > 0 ? true : false
                        }
                      >
                        Add topics
                      </button>
                    </div>
                    <div className="col-md-auto text-center">
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="removeMcqOption"
                        onClick={(e) =>
                          this.props.removeSubtopicAsTopic(
                            e,
                            this.props.topic,
                            idx
                          )
                        }
                      ></FontAwesomeIcon>
                    </div>
                    <div className="col-md-auto text-center">
                      {idx === this.props.topic.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="addMcqOption"
                          onClick={() =>
                            this.props.addSubtopicAsTopic(this.props.topic)
                          }
                        ></FontAwesomeIcon>
                      ) : null}
                    </div>
                  </div>
                  {top.subTopic && top.subTopic.length > 0 ? (
                    <div className="row mt-1 mb-1">
                      <div className="col-md-1"></div>
                      <div className="col">
                        <SubTopicReRender
                          topic={top.subTopic}
                          addSubtopics={this.props.addSubtopics}
                          handleReRenderChange={this.props.handleReRenderChange}
                          addSubtopicAsTopic={this.props.addSubtopicAsTopic}
                          removeSubtopicAsTopic={
                            this.props.removeSubtopicAsTopic
                          }
                        ></SubTopicReRender>
                      </div>
                    </div>
                  ) : null}
                </span>
              );
            })
          : null}
      </div>
    );
  }
}

export default SubTopicReRender;
