import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { validateFile } from "../../components/Validation/Validation";
import DragDropFile from "../../UI/DragDropFile";

class MainFeatureForm extends Component {
  state = {
    file: null,
    name: "",
    error: false,
    showSpinner: false,
    photo: "",
    photoPreview: "",
  };

  componentDidMount() {
    if (this.props.editMode) {
      this.setState({
        name: this.props.editData.name,
        oldImg: this.props.editData.imagePath,
      });
    }
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  handleFeatureImageChange = (e) => {
    let extensions = ["png", "jpeg", "jpg"];
    let validationResponse = validateFile(e.target.value, extensions);
    if (!validationResponse[0]) {
      swal("Error", validationResponse[1]);
      return;
    }
    if (e.target.files.length > 0) {
      this.setState({
        photo: e.target.value,
        photoPreview: window.URL.createObjectURL(e.target.files[0]),
        file: e.target.files,
      });
    }
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    if (files.length > 1) {
      swal("Error", "Please select only one file");
    } else {
      this.setState({
        photo: name,
        photoPreview: window.URL.createObjectURL(e.dataTransfer.files[0]),
        file: files,
      });
    }
  };

  removeFeatureImage = () => {
    this.setState({
      photo: "",
      photoPreview: "",
      file: null,
    });
  };

  handleSave = () => {
    let error = false;

    if (this.state.file == null || this.state.name == "") {
      error = true;
      this.setState({
        error: true,
      });
    }

    if (error == true) {
      return;
    }
    let formData = new FormData();

    formData.append("coreData", JSON.stringify({ name: this.state.name }));
    formData.append("file", this.state.file[0]);

    this.setState({
      showSpinner: true,
    });

    axiosPost(URL.insertFeature, formData, (response) => {
      if (response.status == 200) {
        this.props.getAllFeature();
        this.setState({
          file: null,
          name: "",
          photo: "",
          photoPreview: "",
          showSpinner: false,
        });
        swal("Success", response.data.message);
        this.props.toggleModal();
      }
    });
  };

  handleUpdate = () => {
    let error = false;

    if (this.state.name == "") {
      error = true;
      this.setState({
        error: true,
      });
    }

    if (error == true) {
      return;
    }
    let formData = new FormData();

    formData.append(
      "coreData",
      JSON.stringify({
        name: this.state.name,
        id: this.props.editData.id,
        display: this.props.editData.display,
      })
    );

    if (this.state.file !== null) {
      formData.append("file", this.state.file[0]);
    }
    // if (this.state.file === null) {
    //   formData.append(
    //     "imagePath",
    //     JSON.stringify(this.props.editData.imagePath)
    //   );
    // }

    this.setState({
      showSpinner: true,
    });

    axiosPost(URL.updateFeature, formData, (response) => {
      if (response.status == 200) {
        this.props.getAllFeature();
        this.setState({
          file: null,
          name: "",
          photo: "",
          photoPreview: "",
          showSpinner: false,
        });
        swal("Success", response.data.message);
        this.props.toggleModal();
      }
    });
  };
  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}

        <div className="row py-3">
          <div className="col-12">
            <label htmlFor="name">Name</label>

            <input
              className={`${
                this.state.error == true && this.state.name == ""
                  ? "form-control cusInput is-invalid"
                  : "form-control cusInput "
              }`}
              name="name"
              type="text"
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="Enter name here"
            />
            {this.state.error && this.state.name == "" && (
              <div className="errorMsg-textField mt-1">
                *Feature name is required!
              </div>
            )}
          </div>
          <div className="col-12">
            <label htmlFor="image">Icon image</label>

            <DragDropFile
              multiple={false}
              handleChange={this.handleFeatureImageChange}
              name="file"
              handleFileDrop={this.handleFileDrop}
              files={this.state.file}
              accept="image/png, image/jpeg, image/jpg"
            />
            {/* <input
              accept="image/png, image/jpeg, image/jpg"
              className={`${
                this.state.error == true && this.state.file == null
                  ? "form-control cusInput is-invalid"
                  : "form-control cusInput "
              }`}
              name="image"
              type="file"
              onChange={(e) => this.handleFeatureImageChange(e)}
              value={this.state.photo}
            /> */}

            {!this.props.editMode &&
              this.state.error == true &&
              this.state.file == null && (
                <div className="errorMsg-textField mt-1">
                  *Feature image is required!
                </div>
              )}
            <div className="d-flex align-items-center">
              {this.state.photoPreview && this.state.photoPreview !== "" ? (
                <div className="col-md-auto">
                  <img
                    width="100"
                    src={this.state.photoPreview}
                    className="mt-2 mb-2"
                  />{" "}
                  <br />
                  <button
                    className="btn btn-danger cusBtnDanger cusBtnFontSize"
                    onClick={this.removeFeatureImage}
                  >
                    Remove
                  </button>
                </div>
              ) : null}

              {this.props.editMode ? (
                <div className="col-md-auto">
                  <img
                    width="100"
                    src={URL.imageSelectURL + this.state.oldImg}
                    className="mt-2 mb-2"
                  />{" "}
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="row my-2">
          <div className="col text-right">
            {this.props.editMode ? (
              <button
                className="btn btn-primary cusBtn cusBtnFontSize"
                onClick={this.handleUpdate}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-primary cusBtn cusBtnFontSize"
                onClick={this.handleSave}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MainFeatureForm;
