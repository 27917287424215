import React, { Component } from "react";

class Agriculture extends Component {
  state = {};
  render() {
    return <div>Agriculture</div>;
  }
}

export default Agriculture;
