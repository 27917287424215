import { faTimes, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

import { Modal, ModalBody } from "reactstrap";
import FullImage from "../../UI/FullImage";
import { TabList, Tabs, Tab } from "react-tabs";

import {
  URL,
  checkRolePermissions,
  userRole,
  userRoleCode,
} from "../../utils/Constants";
import FileModal from "../../components/ViewFile/FileModal";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import { axiosPost } from "../../utils/AxiosApi";

class CourseClassResources extends Component {
  state = {
    images: [],
    files: [],
    links: [],
    imageModal: false,
    selectedImage: "",
    batch: [],
    selectedIndex: 0,
    currentBatch: "",
    remarks: "",
    filePath: "",
    fileType: "",
    activeIndex: 0,
    type: "",
    mark: "",
    ckText: "",
    ckHead: "",
    contents: [],
  };

  componentDidMount() {
    this.switchMethod();
  }

  switchMethod = () => {
    this.props.isSubmitted ? this.handleTabForResources() : this.separateData();
  };

  presetFileViewModal = () => {
    if (this.state.images.length > 0) {
      this.setState({
        type: "image",
        fileType: this.state.images[0].filePath.split(".").pop(),
        filePath: this.state.images[0].filePath,
      });
    } else if (this.state.files.length > 0) {
      this.setState({
        type: "files",
        fileType: this.state.files[0].filePath.split(".").pop(),
        filePath: this.state.files[0].filePath,
      });
    } else if (this.state.contents.length > 0) {
      this.setState({
        type: "txt",
        fileType: this.state.contents[0].fileType,
        filePath: this.state.contents[0].content,
      });
    } else if (this.state.links.length > 0) {
      this.handleChangeFile(
        this.state.links[0].filePath || this.state.links[0].fileName,
        0,
        "link"
      );
      // this.setState({
      //   type: "link",
      //   fileType: this.state.links[0].fileType,
      //   filePath: this.state.links[0].fileName,
      // });
    }
  };
  handleChangeFile = (content, idx, type) => {
    let embeddedUrl = "";
    let videoId = "";
    if (type === "link") {
      if (content?.includes("youtube") || content?.includes("youtu.be")) {
        var regExp =
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = content.match(regExp);
        if (match && match[7].length == 11) {
          videoId = match[7];
          embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
          this.setState({
            filePath: embeddedUrl,
            fileType: type,
            type: type,
            activeIndex: idx,
          });
        } else {
          this.setState({
            filePath: content,
            type: type,
            fileType: type,
            activeIndex: idx,
          });
        }
      }
    } else if (type === "txt") {
      this.setState(
        {
          filePath: "",
          fileType: "",
        },
        () => {
          this.setState({
            filePath: content.fileName,
            fileType: content.fileType,
            activeIndex: idx,
            type: type,
          });
        }
      );
    } else {
      this.setState(
        {
          filePath: "",
          fileType: "",
        },
        () => {
          this.checkFileExist(content);
          this.setState({
            filePath: content,
            fileType: content.split(".").pop(),
            activeIndex: idx,
            type: type,
          });
        }
      );
    }
    let element = document.getElementsByClassName("fileListMenu");
    if (element[0].classList.contains("activeFileList")) {
      element[0].classList.remove("activeFileList");
    }
    //  else {
    //   element[0].classList.add("activeFileList");
    // }
  };

  checkFileExist = (path) => {
    let data = URL.imageSelectURL + path;
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", data, false);
    xhr.send();

    let status = parseInt(xhr.status);
    if (status == 404 || status == 401) {
      this.setState({
        fileExist: false,
      });
    } else {
      this.setState({
        fileExist: true,
      });
    }
  };

  deleteResource = (item) => {
    displayConfirmDeleteAlert(item, this.deleteResourceFile);
  };

  deleteResourceFile = (item) => {
    let images = [];
    let files = [];
    let links = [];
    let contents = [];
    let medias = [];

    axiosPost(URL.deleteCoursePlanResources, { id: item.id }, (response) => {
      if (response.status === 200) {
        if (item.fileType === "IMG") {
          images = this.state.images.filter((i) => i.id !== item.id);
          this.setState({
            images: images,
          });
        } else if (item.fileType === "LNK") {
          links = this.state.links.filter((i) => i.id !== item.id);
          this.setState({
            links: links,
          });
        } else if (item.fileType === "TXT") {
          contents = this.state.contents.filter((i) => i.id !== item.id);
          this.setState({
            contents: contents,
          });
        } else if (item.fileType === "AUD" || item.fileType === "VID") {
          medias = this.state.medias.filter((i) => i.id !== item.id);
          this.setState({
            medias: medias,
          });
        } else {
          files = this.state.files.filter((i) => i.id !== item.id);
          this.setState({
            files: files,
          });
        }
      }
      // this.props.getCourseSyllabus();
      this.setState({
        filePath: "",
        fileType: "",
        type: "",
      });
    });
  };

  handleTabForResources = () => {
    let resources = this.props.selectedResources;
    let images = [];
    let files = [];
    let links = [];
    let batch = [];
    let remarks = "";
    let mark = "";
    let text = "";

    batch = [...new Set(resources.map((item) => item.batch))];
    this.setState({ batch }, () => {
      resources.forEach((el) => {
        if (el.fileType === "IMG" && el.batch === this.state.batch[0]) {
          images.push(el);
        } else if (el.fileType === "LNK" && el.batch === this.state.batch[0]) {
          links.push(el);
        } else if (
          el.fileType !== "IMG" &&
          el.fileType !== "LNK" &&
          el.fileType !== "RMK" &&
          el.batch === this.state.batch[0]
        ) {
          files.push(el);
        } else if (el.fileType === "RMK" && el.batch === this.state.batch[0]) {
          remarks = el.fileName;
          mark = el.mark;
        }
      });
      this.setState(
        {
          images,
          files,
          links,
          currentBatch: this.state.batch[0],
          remarks,
          mark,
        },
        () => {
          this.presetFileViewModal();
        }
      );
    });
  };

  handleTabForResourcesCustom = (idx) => {
    let resources = this.props.selectedResources;
    let images = [];
    let files = [];
    let links = [];
    let remarks = "";
    let mark = "";
    this.setState({ remarks: "" });
    resources.forEach((el) => {
      if (el.fileType === "IMG" && el.batch === this.state.batch[idx]) {
        images.push(el);
      } else if (el.fileType === "LNK" && el.batch === this.state.batch[idx]) {
        links.push(el);
      } else if (
        el.fileType !== "IMG" &&
        el.fileType !== "LNK" &&
        el.fileType !== "RMK" &&
        el.batch === this.state.batch[idx]
      ) {
        files.push(el);
      } else if (el.fileType === "RMK" && el.batch === this.state.batch[idx]) {
        remarks = el.fileName;
        mark = el.mark;
      }
    });
    this.setState({
      images,
      files,
      links,
      currentBatch: this.state.batch[idx],
      remarks,
      mark,
    });
  };

  separateData = () => {
    let resources = this.props.selectedResources;
    let images = [];
    let files = [];
    let links = [];
    let contents = [];
    if (resources && resources.length > 0) {
      resources.forEach((el) => {
        if (el.fileType === "IMG") {
          images.push(el);
        } else if (el.fileType === "LNK") {
          links.push(el);
        } else if (el.fileType === "TXT") {
          contents.push(el);
        } else {
          files.push(el);
        }
      });
    }
    this.setState({ images, files, links, contents }, () => {
      this.presetFileViewModal();
    });
  };

  handleFullImage = (image) => {
    this.setState({
      imageModal: !this.state.imageModal,
      selectedImage: URL.imageSelectURL + image.filePath,
    });
  };

  toggleImageModal = () => {
    this.setState({ imageModal: !this.state.imageModal, selectedImage: "" });
  };

  handleSelectTabs = (index) => {
    this.setState({ selectedIndex: index });
    this.handleTabForResourcesCustom(index);
  };
  render() {
    return (
      <div className="tt-contentWidget-tab-holder" style={{ height: "82.2vh" }}>
        <div className="container-fluid">
          {this.state.batch.length > 1 ? (
            <div>
              <Tabs
                selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelectTabs}
              >
                <TabList className="middle123">
                  {this.state.batch.map((el, idx) => {
                    return (
                      <>
                        <Tab className="horizontaltwo">
                          <span className="dot">{el}</span>
                          <p className="topic create-academic">
                            Submission {el}
                          </p>
                        </Tab>
                        {this.state.batch.length !== idx + 1 ? (
                          <div className="horizontalline" />
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </TabList>
              </Tabs>
            </div>
          ) : (
            ""
          )}
          <FileModal
            // files={this.props.selectedResources}
            isNotice={false}
            handleChangeFile={this.handleChangeFile}
            fileExist={true}
            fileType={this.state.fileType}
            filePath={this.state.filePath}
            activeIndex={this.state.activeIndex}
            files={this.state.files}
            images={this.state.images}
            links={this.state.links}
            type={this.state.type}
            contents={this.state.contents}
            canDeleteResources={this.props.canDeleteResources}
            syllabusFile={true}
            deleteResource={this.deleteResource}
          ></FileModal>
          <div className="row">
            {/* {this.state.images.length ? (
              <div className="col-md-8">
                <div className="tt-courseImageHolderDetail text-center">
                  <strong>Images</strong>
                  <div className="tt-courseMainImageHolder">
                    {this.state.images.length > 0
                      ? this.state.images.map((img, idx) => {
                          return (
                            <div
                              className="tt-courseImageDisplayHolder"
                              key={idx}
                              onClick={() => this.handleFullImage(img)}
                            >
                              <img
                                src={URL.imageSelectURL + img.filePath}
                              ></img>
                            </div>
                          );
                        })
                      : "No images"}
                  </div>
                </div>
              </div>
            ) : null} */}
            <div className="col">
              {/* <div className="row">
                <div className="col">
                  <div
                    className="tt-attachedBox text-center"
                    style={{ height: "38.6vh", borderRadius: 0 }}
                  >
                    <strong>Attached file(s)</strong>
                    <div className="container-fluid">
                      <div className="row">
                        {this.state.files.length > 0 ? (
                          this.state.files.map(content => {
                            return (
                              <div className="col-md-4">
                                <FontAwesomeIcon
                                  icon={faFileAlt}
                                  size="3x"
                                ></FontAwesomeIcon>
                                <p
                                  style={{
                                    width: "100%",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    paddingLeft: "8px",
                                    whiteSpace: "nowrap",
                                    paddingRight: "8px"
                                  }}
                                >
                                  <a
                                    href={
                                      URL.fileDownloadURL + content.filePath
                                    }
                                    target="_blank"
                                    download
                                  >
                                    {content.fileName}
                                  </a>
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="col text-center"
                            style={{ lineHeight: 0 }}
                          >
                            No files added
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.links.length ? (
                <div className="row mt-2">
                  <div className="col text-center">
                    <div className="tt-courseLinkHolder">
                      <strong>Link References</strong>
                      <div className="row">
                        <div className="col">
                          <table width="100%">
                            <tbody>
                              {this.state.links.length > 0 ? (
                                this.state.links.map((lnk, idx) => {
                                  return (
                                    <tr
                                      key={idx}
                                      style={{
                                        verticalAlign: "top",
                                        textAlign: "left"
                                      }}
                                    >
                                      <td className="text-right">
                                        <strong>{idx + 1}.</strong>
                                      </td>
                                      <td width="100%">
                                        <a href={lnk.link} target="_blank">
                                          {lnk.fileName
                                            ? lnk.fileName
                                            : lnk.link}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={2} className="text-center">
                                    No Links added
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null} */}
              {this.props.isSchoolAssignment &&
              this.state.remarks !== "" &&
              (userRole == userRoleCode.roleStudent ||
                userRole == userRoleCode.roleStudentContact) ? (
                <div className="row mt-2">
                  <div className="col-md-8 text-right">
                    <strong>Remarks</strong>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      style={{ height: "50px" }}
                      className="form-control"
                      name="remarks"
                      value={this.state.remarks}
                      disabled
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.props.isSchoolAssignment &&
              this.state.remarks !== "" &&
              checkRolePermissions("check-subject-assignment", "activity") ? (
                <>
                  <div className="row mt-2">
                    <div className="col-md-8 text-right">
                      <strong>Remarks</strong>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        style={{ height: "50px" }}
                        className="form-control"
                        name="remarks"
                        value={this.state.remarks}
                        disabled
                      />
                    </div>
                  </div>
                  {this.state.mark === "" || this.state.mark === null ? (
                    ""
                  ) : (
                    <div className="row mt-2">
                      <div className="col-md-8 text-right">
                        <strong>Assignment Point</strong>
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          style={{ height: "50px" }}
                          className="form-control"
                          name="mark"
                          value={this.state.mark}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : this.props.isSchoolAssignment &&
                this.props.currentStudentStatus == "Submitted" &&
                checkRolePermissions("check-subject-assignment", "activity") ? (
                <>
                  <div className="row mt-2">
                    <div className="col-md-4 text-right">
                      <strong>Remarks</strong>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Feedbacks/Suggestions if any"
                        style={{ height: "50px" }}
                        className="form-control"
                        name="remarks"
                        value={this.props.remarks}
                        onChange={this.props.handleChange}
                      />
                    </div>
                  </div>
                  {this.props.status === "Approved" ? (
                    this.props?.point === null || this.props?.point === "" ? (
                      ""
                    ) : (
                      <div className="row mt-2">
                        <div className="col-md-4 text-right">
                          <strong>Check Assignment</strong>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="number"
                            placeholder="Assess Assignment in point"
                            style={{ height: "50px" }}
                            className="form-control"
                            name="mark"
                            max={3}
                            value={this.props.mark}
                            onChange={this.props.handleChange}
                            onKeyDown={(e) => {
                              // Prevent the user from entering negative values using keyboard input
                              if (e.key === "-" || e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  <div className="row mt-2">
                    <div className="col text-right">
                      <label htmlFor="approved">
                        {this.props.status == "Approved" ? (
                          <strong>Approved</strong>
                        ) : (
                          "Approved"
                        )}
                      </label>
                      <input
                        type="radio"
                        className="score-radio-button"
                        value="Approved"
                        name="status"
                        id="approved"
                        checked={this.props.status == "Approved"}
                        onChange={this.props.handleChange}
                      />
                      <label htmlFor="correction">
                        {this.props.status === "Correction" ? (
                          <strong>Send for Correction</strong>
                        ) : (
                          "Send for Correction"
                        )}
                      </label>
                      <input
                        type="radio"
                        className="score-radio-button"
                        checked={this.props.status === "Correction"}
                        value="Correction"
                        id="correction"
                        name="status"
                        onChange={this.props.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col text-right">
                      <button
                        className="tt-button tt-button-primary"
                        onClick={(e) =>
                          this.props.handleAssignmentReviewBatch(
                            this.state.currentBatch
                          )
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <Modal
          id="fullProfileImage"
          isOpen={this.state.imageModal}
          toggle={this.toggleImageModal}
          centered
          className="tt-profileFullImage"
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="tt-ImageCloseIcon"
            onClick={this.toggleImageModal}
          ></FontAwesomeIcon>
          <ModalBody>
            <FullImage
              image={this.state.selectedImage}
              caption={""}
            ></FullImage>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default CourseClassResources;
