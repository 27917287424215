import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/Utils";
import CustomButton from "../../common/CustomButton";
import FlashCardTemplate from "../../FlashCards/FlashCardTemplate";
import ModalWindow from "../../UI/ModalWindow";

class EFlashCards extends Component {
  state = {
    flashCardModal: false,
    flashCardHeader: "",
    flashCardList: [],
    selectedFlashCard: [],
    spinner: false,
    totalCards: 0,
  };

  componentDidMount() {
    this.getPublicFlashCards();
  }

  getPublicFlashCards = () => {
    let selectedSubject = this.props.selectedSubject;
    let grade = this.props.grade;
    if (selectedSubject && grade) {
      let param = {
        yearLevelId: grade.id,
        subjectId: selectedSubject.id,
      };
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.getFlashCardByClass,
          param,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              this.setState({ flashCardList: response.data.data });
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  playFlashCard = (cards) => {
    this.setState({
      selectedFlashCard: cards,
      flashCardHeader: cards.title,
      flashCardModal: true,
      totalCards: cards.flashCards ? cards.flashCards.length : 0,
    });
  };

  toggleFlashCardModal = () => {
    this.setState({
      flashCardModal: !this.state.flashCardModal,
      selectedFlashCard: [],
      flashCardHeader: "",
    });
  };

  render() {
    return (
      <div
        className="description-shadow"
        style={{ margin: "unset", marginTop: "50px" }}
      >
        <div className="listBox coursePlanCss">
          <h2 className="listTitle" style={{ fontSize: "1.5rem" }}>
            Flash Card List
          </h2>
        </div>
        {this.state.flashCardList.length > 0 ? (
          this.state.flashCardList.map((crd, idx) => {
            return (
              <div
                key={idx}
                className="coursePlanContent"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <h4 className="heading-06">
                    {idx + 1}. {crd?.title}
                  </h4>
                  {crd.description && crd.description !== "" ? (
                    <li className="heading-06">{crd.description}</li>
                  ) : null}
                </div>
                <CustomButton
                  text="Play"
                  customStyle="resourceBtn"
                  style={{ marginRight: "unset", marginTop: "unset" }}
                  onClick={() => this.playFlashCard(crd)}
                />
              </div>
            );
          })
        ) : (
          <div className="text-center">
            {this.state.spinner ? <Spinner /> : "No Flash Cards added"}
          </div>
        )}
        <ModalWindow
          modal={this.state.flashCardModal}
          fullWidth={true}
          toggleModal={this.toggleFlashCardModal}
          modalHeader={
            this.state.flashCardHeader +
            ` [ ${this.state.totalCards} flashcards]`
          }
          modalBody={
            <FlashCardTemplate
              flashCards={this.state.selectedFlashCard}
              toggleFlashCardModal={this.toggleFlashCardModal}
            ></FlashCardTemplate>
          }
        />
      </div>
    );
  }
}

export default EFlashCards;
