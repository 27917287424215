/**
 * @author Azzeta Karmacharya
 * @version 2.0
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { getAcademicYears } from "../../../ducks/AcademicAdministrationDucks";
import "../../../assets/scss/academicYear.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AcademicYearSetup from "./AcademicYearSetup";
import TermsSetup from "./TermsSetup";
import WeekDaysSetup from "./WeekDaysSetup";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../utils/Constants";
import { displaySuccessAlert } from "../../../utils/Utils";
import history from "../../../utils/History";
import NavBar from "../../Profile/Navbar";
import swal from "sweetalert";
import ModalWindow from "./../../UI/ModalWindow";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";
import moment from "moment";
import { cloneDeep } from "lodash";

class AcademicYears extends Component {
  state = {
    termAlias: localStorage.getItem("educationLevelDivision") || "term",
    selectedIndex: 0,
    educationLevelList: [],
    educationLevelName: "All",
    startDate: "",
    endDate: "",
    numberOfTerms: 3,
    terms: [],
    weekDaysEducationLevelName: "All",
    selectedTermName: "All",
    isPresent: false,
    spinner: false,
    previousImported: false,
    newAcademicYear: false,
    academicYears: [],
    defaultWeekDays: [],
  };

  componentDidMount() {
    document.title = this.props.noNavbar ? "Profile" : "Academic Year Setup 2";
    if (!this.props.noNavbar) {
      this.getEducationLevelList();
    }
    this.handleAddTerms();
    this.getAliasList();
    this.getAllAcademicYears();
    this.setState({
      termAlias: localStorage.getItem("educationLevelDivision"),
    });
  }

  importPrevious = () => {
    this.setState({ previousImported: true, newAcademicYear: false });
    this.getEducationLevelList();
  };

  addNewEducationLevel = () => {
    if (this.state.previousImported) {
      this.setState({ educationLevelList: [] });
    }
    this.setState({ previousImported: false, newAcademicYear: true });
  };

  getAliasList = () => {
    axiosPost(URL.getAllAlias, {}, (response) => {
      response.data.data.slice(0, 4).map((alias) => {
        localStorage.setItem(`${alias.systemName}`, `${alias.displayName}`);
        return null;
      });
    });
  };

  deleteNewEduLevel = (idx) => {
    let educationLevelList = [...this.state.educationLevelList];
    educationLevelList.splice(idx, 1);
    this.setState({ educationLevelList });
  };

  addNewEduLevel = (name) => {
    let educationLevelList = [...this.state.educationLevelList];
    let data = {
      name: name,
      numberOfTerms: 3,
      terms: [],
    };
    for (let index = 0; index < data.numberOfTerms; index++) {
      data.terms.push({
        name: "",
        startDate: "",
        endDate: "",
        weekDays: this.state.defaultWeekDays,
      });
    }
    educationLevelList.push(data);
    this.setState({ educationLevelList });
  };

  getEducationLevelList = () => {
    let defaultWeekDays = [];

    axiosPost(URL.setupConfiguration, {}, (response) => {
      this.setState({
        setupData: response.data.data,
      });
      let setupData = response.data.data;
      setupData.forEach((sd) => {
        if (sd.key === "Weekdays") {
          sd.lists.map(
            (sdl) =>
              (defaultWeekDays = [...defaultWeekDays, { id: JSON.parse(sdl) }])
          );
        }
      });
    });

    axiosPost(URL.getAllAcademicYears, {}, (response) => {
      axiosPost(
        response.data.data.length > 0
          ? URL.getEducationLevelSelectAll
          : URL.getTempEducationLevel,
        {},
        (response) => {
          if (response.status === 200) {
            let educationLevels = response.data.data;
            let list = educationLevels.map(function (el) {
              let educationLevel = Object.assign({}, el);
              educationLevel.numberOfTerms = 3;
              educationLevel.terms = [];
              for (
                let index = 0;
                index < educationLevel.numberOfTerms;
                index++
              ) {
                educationLevel.terms.push({
                  name: "",
                  startDate: "",
                  endDate: "",
                  weekDays: cloneDeep(defaultWeekDays) || [],
                });
              }
              return educationLevel;
            });
            this.setState({
              educationLevelList: list,
              defaultWeekDays: defaultWeekDays,
            });
          }
        }
      );
    });
  };

  handleAddTerms = () => {
    let terms = Object.assign([], this.state.terms);
    for (let index = 0; index < this.state.numberOfTerms; index++) {
      terms.splice(index, 0, this.addTerms());
    }
    this.setState({ terms: terms });
  };

  addTerms = () => {
    let term = {
      name: "",
      startDate: "",
      endDate: "",
      weekDays: this.state.defaultWeekDays,
    };
    return term;
  };

  handleSelectTabs = (index) => {
    if (index === 2) {
      let val = 0;
      if (this.state.educationLevelName !== "All") {
        let data = [...this.state.educationLevelList];
        data.forEach((el) => {
          el.terms.forEach((term) => {
            if (
              term.name === "" ||
              term.startDate === "" ||
              term.endDate === ""
            ) {
              val++;
            }
          });
        });
      } else {
        if (this.state.terms !== []) {
          this.state.terms.forEach((term) => {
            if (
              term.name === "" ||
              term.startDate === "" ||
              term.endDate === ""
            ) {
              val++;
            }
          });
        }
      }
      if (val > 0) {
        swal({
          title: "Warning",
          text: "Fill all the field first",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ selectedIndex: index });
      }
    } else {
      this.setState({ selectedIndex: index });
    }
    draggable();
  };

  handleNextClick = () => {
    if (this.state.selectedIndex === 0) {
      this.setState({ selectedIndex: 1 });
    } else if (this.state.selectedIndex === 1) {
      let val = 0;
      if (this.state.educationLevelName !== "All") {
        let data = [...this.state.educationLevelList];
        data.forEach((el) => {
          el.terms.forEach((term) => {
            if (
              term.name === "" ||
              term.startDate === "" ||
              term.endDate === ""
            ) {
              val++;
            }
          });
        });
      } else {
        if (this.state.terms !== []) {
          this.state.terms.forEach((term) => {
            if (
              term.name === "" ||
              term.startDate === "" ||
              term.endDate === ""
            ) {
              val++;
            }
          });
        }
      }
      if (val > 0) {
        swal({
          title: "Warning",
          text: "Fill all the field first",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ selectedIndex: 2 });
      }
    }
  };

  handlePreviousClick = () => {
    if (this.state.selectedIndex === 1) {
      this.setState({ selectedIndex: 0 });
    } else if (this.state.selectedIndex === 2) {
      this.setState({ selectedIndex: 1 });
    }
  };

  handleAcademicYearChange = (e) => {
    let educationLevelList = Object.assign([], this.state.educationLevelList);
    const academicYears = this.state.academicYears;
    for (let i = 0; i < academicYears.length; i++) {
      let range = moment(e.target.value).isBetween(
        academicYears[i].startDate,
        academicYears[i].endDate,
        "days",
        "[]"
      );
      if (range) {
        swal(
          "warning",
          "Sorry,the date you selected already has an academic year."
        );
        return true;
      }
    }

    if (this.state.educationLevelName === "All") {
      this.universalHandleChange(e);
      for (let index = 0; index < educationLevelList.length; index++) {
        educationLevelList[index][e.target.name] = e.target.value;
        let newDate = moment(e.target.value).add(1, "years");
        educationLevelList[index].endDate =
          moment(newDate).format("YYYY-MM-DD");
      }
    } else {
      educationLevelList[e.target.dataset.id][e.target.name] = e.target.value;
      let newDate = moment(e.target.value).add(1, "years");
      educationLevelList[e.target.dataset.id].endDate =
        moment(newDate).format("YYYY-MM-DD");
    }
    this.setState({ educationLevelList });
  };

  getAllAcademicYears = () => {
    axiosPost(URL.getAllAcademicYears, {}, (response) => {
      if (response.status === 200) {
        this.setState({ academicYears: response.data.data });
      }
    });
  };

  universalHandleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });

    if (e.target.name === "startDate") {
      let newDate = moment(e.target.value).add(1, "years");
      this.setState({
        endDate: moment(newDate).format("YYYY-MM-DD"),
      });
    }
  };

  decreaseValue = (idx) => {
    if (idx === undefined) {
      if (this.state.numberOfTerms > 1) {
        let newValue = this.state.numberOfTerms - 1;
        let terms = this.state.terms;
        terms.pop();
        this.setState({ numberOfTerms: newValue, terms: terms });
        let educationLevelList = [...this.state.educationLevelList];
        for (let index = 0; index < educationLevelList.length; index++) {
          educationLevelList[index].numberOfTerms = newValue;
          educationLevelList[index].terms.pop();
          this.setState({ educationLevelList });
        }
      }
    } else {
      const educationLevelList = this.state.educationLevelList;
      if (educationLevelList[idx].numberOfTerms > 1) {
        educationLevelList[idx].numberOfTerms =
          educationLevelList[idx].numberOfTerms - 1;
        educationLevelList[idx].terms.pop();
        this.setState({ educationLevelList: educationLevelList });
      }
    }
  };

  increaseValue = (idx) => {
    let term = this.addTerms();
    if (idx === undefined) {
      if (this.state.numberOfTerms < 6) {
        let newValue = this.state.numberOfTerms + 1;
        let terms = Object.assign([], this.state.terms);
        terms.push(term);
        this.setState({ numberOfTerms: newValue, terms: terms });
        let educationLevelList = [...this.state.educationLevelList];
        for (let index = 0; index < educationLevelList.length; index++) {
          educationLevelList[index].numberOfTerms = newValue;
          educationLevelList[index].terms.push(term);
          this.setState({ educationLevelList });
        }
      }
    } else {
      let educationLevel = this.state.educationLevelList;
      if (educationLevel[idx].numberOfTerms < 6) {
        educationLevel[idx].numberOfTerms =
          educationLevel[idx].numberOfTerms + 1;
        educationLevel[idx].terms.push(term);
        this.setState({ educationLevelList: educationLevel });
      }
    }
  };

  handleTermChange = (e, idx) => {
    let textVal = e.target.value;
    if (textVal.length <= 25) {
      if (this.state.educationLevelName === "All" && idx === undefined) {
        let terms = Object.assign([], this.state.terms);
        let index = this.checkValueExitsInArray(terms, "name", e.target.value);
        if (index === -1 || index === undefined) {
          terms[e.target.dataset.id][e.target.name] = e.target.value;
          let educationLevelList = [...this.state.educationLevelList];
          for (let index = 0; index < educationLevelList.length; index++) {
            let terms = educationLevelList[index].terms;
            terms[e.target.dataset.id][e.target.name] = e.target.value;
          }
          this.setState({ terms, educationLevelList });
        }
      } else {
        let educationLevelList = Object.assign(
          [],
          this.state.educationLevelList
        );
        let terms = educationLevelList[idx].terms;
        let index = this.checkValueExitsInArray(terms, "name", e.target.value);
        if (index === -1 || index === undefined) {
          terms[e.target.dataset.id][e.target.name] = e.target.value;
          this.setState({ educationLevelList });
        }
      }
    } else {
      swal({
        title: "Warning",
        text: "Cannot write more than 25 words",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    }
  };

  handleWeekDays = (e, idx, termIndex, checkedList) => {
    let educationLevelList = cloneDeep(this.state.educationLevelList);

    if (this.state.weekDaysEducationLevelName === "All" && idx === undefined) {
      for (let index = 0; index < educationLevelList.length; index++) {
        let terms = educationLevelList[index].terms;
        for (let i = 0; i < terms.length; i++) {
          let weekDays = terms[i].weekDays;
          this.addWeekDays(weekDays, e);
        }
      }
    } else {
      if (this.state.selectedTermName === "All" && termIndex === undefined) {
        let terms = educationLevelList[idx].terms;
        for (let i = 0; i < terms.length; i++) {
          let weekDays = terms[i].weekDays;
          this.addWeekDays(weekDays, e);
        }
      } else {
        let terms = educationLevelList[idx].terms;
        let weekDays = terms[termIndex].weekDays;
        this.addWeekDays(weekDays, e);
      }
    }
    this.setState({ educationLevelList });
  };

  addWeekDays = (weekDays, e) => {
    if (e.target.checked) {
      weekDays.push({ id: Number(e.target.value) });
    } else {
      let index = this.checkValueExitsInArray(
        weekDays,
        "id",
        Number(e.target.value)
      );
      if (index !== -1) {
        weekDays.splice(index, 1);
      }
    }
  };

  getParameters = () => {
    return {
      educationLevelList: Object.assign([], this.state.educationLevelList),
    };
  };

  handleSubmit = (e, params) => {
    e.preventDefault();
    let newArr = [...params.educationLevelList];
    for (let i = 0; i < newArr.length; i++) {
      newArr[i].educationLevelDivisions = newArr[i].terms;
      if (this.state.educationLevelName === "All") {
        newArr[i].startDate = this.state.startDate;
        newArr[i].endDate = this.state.endDate;
      }
      // delete newArr[i].terms;
    }
    //params.educationLevelList = newArr;
    let url = "";
    if (this.props.noNavbar) {
      url = URL.insertNewAcademicYears;
    } else {
      url = URL.insertAcademicYears;
    }
    this.setState({ spinner: true }, () => {
      axiosPost(
        url,
        newArr,
        (response) => {
          if (response.status === 200) {
            displaySuccessAlert(response, () => {
              this.props.getAcademicYears();
              this.setState({
                selectedIndex: 0,
                educationLevelName: "All",
                startDate: "",
                endDate: "",
                numberOfTerms: 3,
                terms: [],
                weekDaysEducationLevelName: "All",
                selectedTermName: "All",
                spinner: false,
              });
              if (this.props.noNavbar) {
                localStorage.setItem("academicYears", true);
              }
              history.push(`${process.env.PUBLIC_URL}/`);
            });
          }
        },
        (error) => {
          if (error) {
            this.setState({ spinner: false });
            let errorResponse = error.response ? error.response.data : error;
            if (errorResponse.status === 400) {
              //if condition to check spring boot validation errors
              let errorMessage = "";
              if (errorResponse.errors) {
                errorResponse.errors.forEach((error) => {
                  errorMessage += `${error.field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} ${error.defaultMessage} \n`; //ishan
                });
                swal(errorResponse.error, errorMessage, "");
              } else {
                swal(errorResponse.error, errorResponse.message, "");
              }
            } else {
              swal(
                errorResponse.error || "Network Error",
                errorResponse.message
                  ? errorResponse.message
                  : "Could Not Connect To The Server.",
                ""
              );
            }
          }
        }
      );
    });
  };

  checkValueExitsInArray = (array, uniqueAttribute, value) => {
    if (value !== "") {
      var index = array?.findIndex(function (a) {
        return a[uniqueAttribute] === value;
      });
      return index;
    }
  };
  toggleMenu = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  render() {
    return (
      <>
        {this.props.noNavbar ? (
          ""
        ) : (
          <NavBar
            toggleMenu={this.toggleMenu}
            dropdownOpen={this.state.dropdownOpen}
            nonSystemSetup={true}
            title={"Academic Year"}
            academicYears={true}
          />
        )}

        <div
          className={
            this.props.noNavbar ? "tt-widgetContent-tab-holder conHeight" : ""
          }
        >
          {this.props.noNavbar ? (
            <div className="tt-group-header">
              {/* <p className="quote">Academic Years Configuration</p> */}
              Create
              {checkWidgetAdminOrNot("Academic Administration") === true ? (
                <button
                  className="tt-button tt-button-primary float-right permissionBtnCSS"
                  onClick={() => {
                    this.setState({
                      permissionModal: !this.state.permissionModal,
                    });
                  }}
                >
                  Permissions
                </button>
              ) : null}
            </div>
          ) : (
            ""
          )}
          <Tabs
            selectedIndex={this.state.selectedIndex}
            onSelect={this.handleSelectTabs}
          >
            <TabList className="middle123">
              <Tab className="horizontaltwo customTab1">
                <span className="dot">1</span>
                <p className="topic create-academic">Create Academic Year</p>
              </Tab>
              <div className="horizontalline" />
              <Tab className="horizontaltwo">
                <span className="dot">2</span>
                <p className="topic term-setup">{this.state.termAlias} Setup</p>
              </Tab>
              <div className="horizontalline" />
              <Tab className="horizontaltwo">
                <span className="dot">3</span>
                <p className="topic weekend-setup">Weekdays Setup</p>
              </Tab>
            </TabList>

            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div
                  className={
                    this.props.noNavbar
                      ? "academic-content"
                      : "academic-content acaContent"
                  }
                >
                  <TabPanel className="main-panel">
                    <AcademicYearSetup
                      educationLevelList={this.state.educationLevelList}
                      educationLevelName={this.state.educationLevelName}
                      handleEducationLevelChange={this.universalHandleChange}
                      termAlias={this.state.termAlias}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      numberOfTerms={this.state.numberOfTerms}
                      handleAcademicYearChange={this.handleAcademicYearChange}
                      decreaseValue={this.decreaseValue}
                      increaseValue={this.increaseValue}
                      noNavbar={this.props.noNavbar}
                      importPrevious={this.importPrevious}
                      previousImported={this.state.previousImported}
                      addNewEducationLevel={this.addNewEducationLevel}
                      addNewEduLevel={this.addNewEduLevel}
                      deleteNewEduLevel={this.deleteNewEduLevel}
                    />
                  </TabPanel>
                  <TabPanel className="main-panel">
                    <TermsSetup
                      educationLevelName={this.state.educationLevelName}
                      terms={this.state.terms}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      educationLevelList={this.state.educationLevelList}
                      handleTermChange={this.handleTermChange}
                      termAlias={this.state.termAlias}
                    />
                  </TabPanel>
                  <TabPanel
                    className="main-panel"
                    style={{ marginTop: "-10px" }}
                  >
                    <WeekDaysSetup
                      universalHandleChange={this.universalHandleChange}
                      educationLevelList={cloneDeep(
                        this.state.educationLevelList
                      )}
                      weekDaysEducationLevelName={
                        this.state.weekDaysEducationLevelName
                      }
                      terms={this.state.terms}
                      selectedTermName={this.state.selectedTermName}
                      handleWeekDays={this.handleWeekDays}
                      checkValueExitsInArray={this.checkValueExitsInArray}
                    />
                  </TabPanel>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </Tabs>
          <div className="nextbutton button-bg-color">
            {this.state.selectedIndex === 1 ||
            this.state.selectedIndex === 2 ? (
              <button
                id="previous-button"
                onClick={this.handlePreviousClick}
                className="tt-button tt-button-primary"
              >
                Previous
              </button>
            ) : null}

            {this.state.selectedIndex === 0 ||
            this.state.selectedIndex === 1 ? (
              <button
                id="nextbutton"
                onClick={this.handleNextClick}
                className="tt-button tt-button-primary"
              >
                Next
              </button>
            ) : (
              <button
                id="nextbutton"
                onClick={(e) => this.handleSubmit(e, this.getParameters())}
                className="tt-button tt-button-primary"
              >
                Submit
              </button>
            )}
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="Academic Years"
              header="Add Academic Years"
              activityName="insert-academic-years"
            />
          }
        ></ModalWindow>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Creating Academic Year
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionsToProps = {
  getAcademicYears: getAcademicYears,
};

export default connect(mapStateToProps, mapActionsToProps)(AcademicYears);
