import React, { Component } from "react";
import { compareByName } from "../../../utils/Utils";

class SearchStudentInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row m-1">
        <div className="col-md-3">
          <strong>Education Level</strong>
          <select
            className="form-control"
            name="educationLevel"
            value={this.props.educationLevel}
            onChange={this.props.handleChange}
          >
            <option value="" selected disabled>
              Select Education Level
            </option>
            {this.props.educationLevels.map((sl, idx) => {
              return (
                <option value={sl.id} key={idx}>
                  {sl.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-2">
          <strong>{localStorage.getItem("yearLevel")}</strong>
          <select
            className="form-control"
            name="yearLevel"
            value={this.props.yearLevel}
            onChange={this.props.handleChange}
            disabled={this.props.educationLevel !== "" ? false : true}
          >
            <option value="" selected disabled>
              Select {localStorage.getItem("yearLevel")}
            </option>
            {this.props.yearLevels.sort(compareByName).map((sl, idx) => {
              return (
                <option value={sl.id} key={idx}>
                  Class {sl.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-3">
          <strong>{localStorage.getItem("classroom")}</strong>
          <select
            className="form-control"
            name="classroom"
            value={this.props.classroom}
            onChange={this.props.handleChange}
            disabled={this.props.yearLevel !== "" ? false : true}
          >
            <option value="" selected disabled>
              Select {localStorage.getItem("classroom")}
            </option>
            {this.props.classrooms.map((el, idx) => {
              return (
                <option value={el.id} key={idx}>
                  {el.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-3">
          <strong>Student</strong>
          <select
            className="form-control"
            name="admissionId"
            value={this.props.admissionId}
            onChange={this.props.handleChange}
            disabled={this.props.classroom !== "" ? false : true}
          >
            <option value="" selected disabled>
              Select Student
            </option>
            {this.props.studentList.map((el, idx) => {
              return (
                <option value={el.admissionId} key={idx}>
                  {el.people.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-1 pl-0">
          <br></br>
          <button
            className="tt-button tt-button-primary"
            onClick={this.props.filterResult}
          >
            Search
          </button>
        </div>
        <hr></hr>
      </div>
    );
  }
}

export default SearchStudentInventory;
