import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import CustomCollapse from "../../UI/CustomCollapse";
import SystemExamExplanation from "./SystemExamExplanation";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";

class SystemExamQuestions extends Component {
  state = {};
  render() {
    let { questions, type, marksCalculation } = this.props;
    return (
      <div className="container-fluid">
        {questions.length > 0
          ? questions.map((mcq, idx) => {
              return (
                <div className="row mb-2" key={idx}>
                  <div
                    className="col pl-0"
                    style={{
                      paddingBottom: "5px",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    <div>
                      <div className="row">
                        <div className="col-md-8">
                          <div>
                            <span className="labelTitle">
                              <strong>{`${idx + 1})`}&nbsp;</strong>
                            </span>
                            <strong className="labelTitle">
                              Type your Question:{" "}
                            </strong>
                          </div>
                          <input
                            type="text"
                            className="form-control cusInput"
                            name="Question"
                            value={mcq.Question}
                            onChange={(e) =>
                              this.props.handleMcqQuestionChange(e, idx, type)
                            }
                            placeholder="Enter your question here"
                          ></input>
                        </div>
                        <div className="col-md-2">
                          <span className="labelTitle">Type</span>
                          <span>
                            <select
                              name="type"
                              className="form-control cusInputSelect"
                              value={mcq.type}
                              onChange={(e) =>
                                this.props.handleMcqQuestionChange(e, idx, type)
                              }
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select Question Type
                              </option>
                              <option value="0">Subjective</option>
                              <option value="1">Objective</option>
                              <option value="2">Non Answerable</option>
                            </select>
                          </span>
                        </div>
                        <div className="col-md-2">
                          <span className="labelTitle">Marks</span>
                          <span>
                            <input
                              type="text"
                              name="markWeight"
                              className="form-control cusInput"
                              value={mcq.markWeight}
                              onChange={(e) =>
                                this.props.handleMcqQuestionChange(e, idx, type)
                              }
                              placeholder="Enter Marks"
                            />
                          </span>
                        </div>
                      </div>
                      {mcq.type == "1" && (
                        <div className="row mt-2">
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-2 text-center">
                                <span className="labelTitle">Correct</span>
                              </div>
                              <div className="col-md-10 text-center">
                                <span className="labelTitle">Options</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2"></div>
                        </div>
                      )}
                      {mcq.type == "1" ? (
                        <>
                          {mcq.subjectQuestionOptions.length > 0
                            ? mcq.subjectQuestionOptions.map((opt, oIdx) => {
                                return (
                                  <div className="row mt-2 mb-2" key={oIdx}>
                                    <div className="col-md-8">
                                      <div className="row">
                                        <div className="col-md-2 text-center">
                                          <input
                                            type="checkbox"
                                            name="isCorrect"
                                            checked={opt.isCorrect}
                                            onChange={(e) =>
                                              this.props.handleMcqOptionChange(
                                                e,
                                                oIdx,
                                                idx,
                                                type
                                              )
                                            }
                                            style={{
                                              position: "relative",
                                              top: "8px",
                                            }}
                                          ></input>
                                        </div>
                                        <div className="col-md-10 text-center">
                                          <input
                                            type="text"
                                            className="form-control cusInput"
                                            name="optionValue"
                                            value={opt.optionValue}
                                            onChange={(e) =>
                                              this.props.handleMcqOptionChange(
                                                e,
                                                oIdx,
                                                idx,
                                                type
                                              )
                                            }
                                            placeholder={`Option ${oIdx + 1}`}
                                          ></input>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="row">
                                        <div className="col-md-1">
                                          <FontAwesomeIcon
                                            icon={faMinusCircle}
                                            className="deleteColor"
                                            onClick={(e) =>
                                              this.props.removeOption(
                                                e,
                                                idx,
                                                oIdx,
                                                type
                                              )
                                            }
                                          ></FontAwesomeIcon>
                                        </div>
                                        <div className="col-md-1">
                                          {oIdx ===
                                          mcq.subjectQuestionOptions.length -
                                            1 ? (
                                            <FontAwesomeIcon
                                              icon={faPlusCircle}
                                              className="editColor ml-3"
                                              onClick={(e) =>
                                                this.props.addOption(
                                                  e,
                                                  idx,
                                                  type
                                                )
                                              }
                                            ></FontAwesomeIcon>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </>
                      ) : null}
                      <div className="row mt-1">
                        <div className="col">
                          <div
                            className="tt-addExplanation"
                            onClick={
                              mcq.isOpen
                                ? (e) =>
                                    this.props.closeExplanation(e, idx, type)
                                : (e) =>
                                    this.props.openExplanation(e, idx, type)
                            }
                          >
                            <FontAwesomeIcon
                              icon={mcq.isOpen ? faMinusCircle : faPlusCircle}
                              // className="editColor"
                              style={{ cursor: "pointer" }}
                            ></FontAwesomeIcon>
                            <span
                              className="courseTitle ml-2"
                              style={{ cursor: "pointer" }}
                            >
                              Add Explanation
                            </span>
                          </div>
                          <CustomCollapse
                            isOpen={mcq.isOpen}
                            collapseClass="tt-explanationCard"
                            collapsibleBody={
                              <SystemExamExplanation
                                closeExplanation={this.props.closeExplanation}
                                saveExplanation={this.props.saveExplanation}
                                idx={idx}
                                handleExplanation={this.props.handleExplanation}
                                explanation={mcq.explanation}
                                type={type}
                              ></SystemExamExplanation>
                            }
                          ></CustomCollapse>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-auto">
                    {/* <div> */}
                    <CustomDeleteButton
                      onClick={(e) => this.props.removeQuestion(e, idx, type)}
                      style={{ fontSize: "1.5rem" }}
                    />
                    {/* </div> */}
                  </div>
                  <hr />
                </div>
              );
            })
          : null}
      </div>
    );
  }
}

export default SystemExamQuestions;
