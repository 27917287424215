import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class GradeTableRow extends Component {
  state = {};
  render() {
    let gradeProps = this.props.val;
    return (
      <tr key={this.props.id}>
        {this.props.useLevel ? (
          this.props.levelType === "colourStickers" ? (
            <td>
              <div
                style={{
                  backgroundColor: gradeProps.changeColor,
                  height: "30px",
                }}
              ></div>
            </td>
          ) : this.props.levelType === "image" ? (
            <td className="tt-grade-tableRow">
              <div className="tt-grade-image-div">
                {gradeProps.grade ? (
                  <img
                    src={window.URL.createObjectURL(gradeProps.grade)}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt="gtr"
                  />
                ) : null}
              </div>
            </td>
          ) : (
            <td>{gradeProps.grade}</td>
          )
        ) : null}
        <td>{gradeProps.descriptor}</td>
        <td>{gradeProps.description}</td>
        <td
          className="tt-span-grade-remove"
          onClick={() => this.props.handleRemoveGrades(this.props.id, "grade")}
        >
          <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
        </td>
        {/* <td>{this.props.id}</td> */}
      </tr>
    );
  }
}

export default GradeTableRow;
