import React, { Component } from "react";
import { Spinner } from "reactstrap";
import DisplayStaffMemberList from "./DisplayStaffMemberList";
import DisplayStudentMemberList from "./DisplayStudentMemberList";
import SelectedMembers from "./SelectedMembers";

class GroupMembersConfig extends Component {
  state = {};
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-3 mt-1">
                <strong>Choose Members</strong>
              </div>
              <div className="col-md-4">
                <select
                  className="form-control"
                  name="membersTitle"
                  value={this.props.membersTitle}
                  onChange={this.props.handleSelectChange}
                >
                  <option value="" disabled>
                    Choose Staff/Teachers
                  </option>
                  <option value="Staff">Staff</option>
                  <option value="Students">Students</option>
                </select>
              </div>
            </div>
            <div className="tt-memberList">
              {this.props.membersTitle === "Staff" ? (
                <div className="card mt-2">
                  <div className="card-body">
                    <DisplayStaffMemberList
                      staffs={this.props.staffs}
                      handleStaffCheckbox={this.props.handleStaffCheckbox}
                      staffType={this.props.staffType}
                      staffTypeId={this.props.staffTypeId}
                      handleStaffChange={this.props.handleStaffChange}
                      allStaffChecked={this.props.allStaffChecked}
                    ></DisplayStaffMemberList>
                  </div>
                </div>
              ) : this.props.membersTitle === "Students" ? (
                <div className="card mt-2">
                  <div className="card-body">
                    <DisplayStudentMemberList
                      yearLevels={this.props.yearLevels}
                      handleYearLevelChange={this.props.handleYearLevelChange}
                      selectYearLevel={this.props.selectYearLevel}
                      selectClassroom={this.props.selectClassroom}
                      classroomList={this.props.classroomList}
                      handleStudentCheckbox={this.props.handleStudentCheckbox}
                      handleClassroomChange={this.props.handleClassroomChange}
                      assignedStudentsList={this.props.assignedStudentsList}
                      allStudentChecked={this.props.allStudentChecked}
                    ></DisplayStudentMemberList>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-5">
            <SelectedMembers
              selectedStaff={this.props.selectedStaff}
              removeSelectedStaff={this.props.removeSelectedStaff}
              selectedStudent={this.props.selectedStudent}
              removeSelectedStudent={this.props.removeSelectedStudent}
              handleSelectedMemberCheckbox={
                this.props.handleSelectedMemberCheckbox
              }
              assignRole={this.props.assignRole}
              groupRoles={this.props.groupRoles}
              selectedGroupRole={this.props.selectedGroupRole}
              handleSelectChange={this.props.handleSelectChange}
            ></SelectedMembers>
          </div>
        </div>
        {this.props.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Assigning Members to Group ...
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default GroupMembersConfig;
