import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import CustomModal from "../common/CustomModal";
import CustomPageHeader from "../common/CustomPageHeader";
import CategoryForm from "./CategoryForm";
import CategoryList from "./CategoryList";

class OtherActivitiesComponent extends Component {
  state = {
    name: "",
    thumbnail: null,
    description: "",
    modal: false,
    categoriesList: [],
    categoryDataModal: false,
    activeCat: "",
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  getOtherActivities = () => {
    let param = {
      isTechnicalSchool: false,
    };
    axiosPost(URL.getOtherActivities, param, (response) => {
      this.setState(
        {
          categoriesList: response.data.data,
        },
        () => {
          if (this.state.categoryDataModal) {
            let newActiveData = this.state.categoriesList.filter(
              (cl) => cl.id === this.state.activeCat.id
            );

            this.setState({
              activeCat: newActiveData[0],
            });
          }
        }
      );
    });
  };

  componentDidMount() {
    this.getOtherActivities();
  }

  handleClickCategory = (data) => {
    this.setState(
      {
        activeCat: data,
      },
      () => {
        this.setState({
          categoryDataModal: true,
        });
      }
    );
  };

  toggleClickCategory = () => {
    this.setState({
      categoryDataModal: !this.state.categoryDataModal,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Other Materials Categories"
                onClick={this.toggleModal}
                icon="add_box"
                noPermission={true}
              />
              <CategoryList
                categoriesList={this.state.categoriesList}
                getOtherActivities={this.getOtherActivities}
                toggleClickCategory={this.toggleClickCategory}
                categoryDataModal={this.state.categoryDataModal}
                activeCat={this.state.activeCat}
                handleClickCategory={this.handleClickCategory}
              />
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          modalHeader="Add Category"
          modalBody={
            <CategoryForm
              toggleModal={this.toggleModal}
              getOtherActivities={this.getOtherActivities}
            />
          }
        />
      </div>
    );
  }
}

export default OtherActivitiesComponent;
