import React from "react";
import Template1 from "../CardsAndCertificates/CardTemplates/Template1";
import Template2 from "../CardsAndCertificates/CardTemplates/Template2";
import Template3 from "../CardsAndCertificates/CardTemplates/Template3";
import { URL } from "../../utils/Constants";

const ShowCardDetails = (props) => {
  const {
    cardDetails,
    userType,
    classOptionValue,
    schoolDetails,
    staffList,
    studentList,
    classValue,
  } = props;

  return (
    <>
      {staffList && userType.value === 2
        ? staffList?.map((value) => {
            return (
              <>
                {cardDetails?.map((items) => {
                  return (
                    <>
                      {items?.templateId === 1 ? (
                        <Template1
                          studentName={value?.people?.name}
                          subtitle={value.designations?.map(
                            (item) => item.name
                          )}
                          schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                          schoolName={schoolDetails?.name}
                          schoolAddress={schoolDetails?.address}
                          // signature={items?.authorizedSignatureImagePath}
                          authorizedSignatureName={
                            items?.authorizedSignatureName
                          }
                          signature={
                            items?.authorizedSignatureImagePath
                              ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                              : ""
                          }
                          validityDate={items?.validTill}
                          schoolContact={schoolDetails?.phoneNumber}
                          schoolWebAddress={schoolDetails?.website}
                          userImage={
                            value?.photo
                              ? `${URL.imageSelectURL}${value?.photo}`
                              : ""
                          }
                          issuedDate={items?.issueDate}
                          emergencyContact={{
                            name: value.emergencyContactName || "",
                            contactNumber: value.emergencyContactNumber || "",
                          }}
                        />
                      ) : items.templateId === 2 ? (
                        <Template2
                          studentName={value?.people?.name}
                          subtitle={value.designations?.map(
                            (item) => item.name
                          )}
                          schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                          schoolName={schoolDetails?.name}
                          schoolAddress={schoolDetails?.address}
                          // signature={items?.authorizedSignatureImagePath}
                          authorizedSignatureName={
                            items?.authorizedSignatureName
                          }
                          signature={
                            items?.authorizedSignatureImagePath
                              ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                              : ""
                          }
                          validityDate={items?.validTill}
                          schoolContact={schoolDetails?.phoneNumber}
                          schoolWebAddress={schoolDetails?.website}
                          userImage={
                            value?.photo
                              ? `${URL.imageSelectURL}${value?.photo}`
                              : ""
                          }
                          issuedDate={items?.issueDate}
                          emergencyContact={{
                            name: value.emergencyContactName || "",
                            contactNumber: value.emergencyContactNumber || "",
                          }}
                        />
                      ) : items.templateId === 3 ? (
                        <Template3
                          studentName={value?.people?.name}
                          subtitle={value.designations?.map(
                            (item) => item.name
                          )}
                          schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                          schoolName={schoolDetails?.name}
                          schoolAddress={schoolDetails?.address}
                          // signature={items?.authorizedSignatureImagePath}
                          authorizedSignatureName={
                            items?.authorizedSignatureName
                          }
                          signature={
                            items?.authorizedSignatureImagePath
                              ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                              : ""
                          }
                          validityDate={items?.validTill}
                          schoolContact={schoolDetails?.phoneNumber}
                          schoolWebAddress={schoolDetails?.website}
                          userImage={
                            value?.photo
                              ? `${URL.imageSelectURL}${value?.photo}`
                              : ""
                          }
                          issuedDate={items?.issueDate}
                          emergencyContact={{
                            name: value.emergencyContactName || "",
                            contactNumber: value.emergencyContactNumber || "",
                          }}
                          userAddress={
                            value?.people.peopleAddresses &&
                            value?.people.peopleAddresses[0]
                              .countryThirdLevelName +
                              ", " +
                              // value?.people.peopleAddresses[0]
                              //   .countrySecondLevelName +
                              // ", " +
                              // value?.people.peopleAddresses[0]
                              //   .countryFirstLevelName +
                              // ", " +
                              value?.people.peopleAddresses[0].countryName
                          }
                        />
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </>
            );
          })
        : ""}
      {studentList && userType.value === 1
        ? studentList?.map((value) => {
            return (
              <>
                {cardDetails?.map((items) => {
                  return (
                    <>
                      {items?.templateId === 1 ? (
                        <Template1
                          studentName={value?.people?.name}
                          subtitle={classValue?.label}
                          schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                          schoolName={schoolDetails?.name}
                          schoolAddress={schoolDetails?.address}
                          // signature={items?.authorizedSignatureImagePath}
                          authorizedSignatureName={
                            items?.authorizedSignatureName
                          }
                          signature={
                            items?.authorizedSignatureImagePath
                              ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                              : ""
                          }
                          validityDate={items?.validTill}
                          schoolContact={schoolDetails?.phoneNumber}
                          schoolWebAddress={schoolDetails?.website}
                          userImage={
                            value?.people?.photo
                              ? `${URL.imageSelectURL}${value?.people?.photo}`
                              : ""
                          }
                          emergencyContact={
                            value?.studentContacts[0]?.contactPeople
                          }
                          issuedDate={items?.issueDate}
                        />
                      ) : items.templateId === 2 ? (
                        <Template2
                          studentName={value?.people?.name}
                          subtitle={classValue?.label}
                          schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                          schoolName={schoolDetails?.name}
                          schoolAddress={schoolDetails?.address}
                          // signature={items?.authorizedSignatureImagePath}
                          authorizedSignatureName={
                            items?.authorizedSignatureName
                          }
                          signature={
                            items?.authorizedSignatureImagePath
                              ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                              : ""
                          }
                          validityDate={items?.validTill}
                          schoolContact={schoolDetails?.phoneNumber}
                          schoolWebAddress={schoolDetails?.website}
                          userImage={
                            value?.people?.photo
                              ? `${URL.imageSelectURL}${value?.people?.photo}`
                              : ""
                          }
                          emergencyContact={
                            value?.studentContacts[0]?.contactPeople
                          }
                          issuedDate={items?.issueDate}
                        />
                      ) : items.templateId === 3 ? (
                        <Template3
                          studentName={value?.people?.name}
                          subtitle={classValue?.label}
                          schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                          schoolName={schoolDetails?.name}
                          schoolAddress={schoolDetails?.address}
                          // signature={items?.authorizedSignatureImagePath}
                          authorizedSignatureName={
                            items?.authorizedSignatureName
                          }
                          signature={
                            items?.authorizedSignatureImagePath
                              ? `${URL.imageSelectURL}${items?.authorizedSignatureImagePath}`
                              : ""
                          }
                          validityDate={items?.validTill}
                          schoolContact={schoolDetails?.phoneNumber}
                          schoolWebAddress={schoolDetails?.website}
                          userImage={
                            value?.people?.photo
                              ? `${URL.imageSelectURL}${value?.people?.photo}`
                              : ""
                          }
                          emergencyContact={
                            value?.studentContacts[0]?.contactPeople
                          }
                          issuedDate={items?.issueDate}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </>
            );
          })
        : ""}
    </>
  );
};
export default ShowCardDetails;
