import React, { Component } from "react";
import JoditEditor from "jodit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  draggable,
  reactServer,
  URL,
  userRole,
  userRoleCode,
} from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import swal from "sweetalert";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import { displayErrorAlert, handleError } from "../../../utils/Utils";
import { Spinner } from "reactstrap";

const animatedComponents = makeAnimated();

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  limitChars: 60000,
  removeButtons: [
    "hr",
    "about",
    "fullsize",
    "eraser",
    "undo",
    "redo",
    "table",
    "file",
    "format",
    "brush",
    "source",
    "fontsize",
    "paragraph",
    "left",
    "center",
    "right",
    "justify",
    "video",
    "superscript",
    "subscript",
    "print",
    "copyformat",
  ],
  uploader: {
    insertImageAsBase64URI: true,
  },
};
class NoticeEditTemplate extends Component {
  state = {
    id: "",
    heading: "",
    selectedImages: [],
    selectedLinks: [],
    selectedGroups: [],
    groupsArray: [],
    selectedOrigin: "",
    selectedIndividual: [],
    links: "",
    peopleArray: [],
    peopleSpinner: false,
    showSpinner: false,
  };

  componentDidMount() {
    this.setState({
      id: this.props.notice.id,
      heading: this.props.notice.heading,
      textContent: this.props.notice.textContent,
    });
    this.manageContents();
    this.manageGroups();
    this.getGroups();
    this.manageIndividualPeople();
    this.getGroupAssociatedPeople();
  }

  getGroupAssociatedPeople = () => {
    let data = {
      peopleId: localStorage.getItem("peopleId"),
    };
    this.setState({ peopleSpinner: true }, function () {
      axiosPost(
        URL.getGroupAssociatedPeople,
        data,
        (response) => {
          if (response.status === 200) {
            let people = response.data.data;
            let peopleArray = [];
            let yearLevel = localStorage.getItem("yearLevel");
            let classroom = localStorage.getItem("classroom");
            if (people.length > 0) {
              people.forEach((el) => {
                let displayName = "";
                if (el.userRoleCode === userRoleCode.roleStudent) {
                  displayName = `${el.name} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} - ${classroom} ${el.classroom})`;
                } else {
                  displayName = `${el.name} [${el.userRole}]`;
                }
                peopleArray.push({ label: displayName, value: el.id });
              });
            }

            this.setState({ peopleArray, peopleSpinner: false });
          }
        },
        (failur) => {
          this.setState({ peopleSpinner: false });
        }
      );
    });
  };

  manageIndividualPeople = () => {
    let data = this.props.notice.peoplesobj;
    let selectedIndividual = [];
    if (data && data.length > 0) {
      data.forEach((el) => {
        selectedIndividual.push({
          label: el.name,
          value: el.id,
        });
      });
    }
    this.setState({ selectedIndividual });
  };

  getGroups = () => {
    if (userRole === userRoleCode.roleAdmin) {
      axiosPost(URL.getAllGroups, {}, (response) => {
        if (response.status === 200) {
          let groups = response.data.data;
          let groupsArray = [];
          groups.forEach((element) => {
            groupsArray.push({ label: element.name, value: element.id });
          });
          this.setState({
            groupsArray,
          });
        }
      });
    } else {
      let peopleId = localStorage.getItem("peopleId");

      let data = {
        peopleId: peopleId,
      };
      axiosPost(URL.getGroups, data, (response) => {
        if (response.status === 200) {
          let groups = response.data.data;
          let groupsArray = [];
          groups.forEach((element) => {
            groupsArray.push({ label: element.name, value: element.id });
          });
          this.setState({
            groupsArray,
          });
        }
      });
    }
  };

  manageGroups = () => {
    let data = this.props.notice.groups;
    let selectedGroups = [];
    if (data.length > 0) {
      data.forEach((el) => {
        selectedGroups.push({
          label: el.name,
          value: el.id,
        });
      });
    }
    this.setState({ selectedGroups });
  };

  manageContents = () => {
    let data = this.props.notice.contents;
    let image = [];
    let links = [];
    if (data.length > 0) {
      data.forEach((el) => {
        if (el.contentTypeCode === "LNK") {
          links.push(el.content);
        } else {
          el.updatedData = true;
          image.push(el);
        }
      });
    }
    this.setState({
      selectedImages: image,
      selectedLinks: links,
    });
  };

  handleTextEditor = (value) => {
    this.setState({ textContent: value });
  };

  handleChangeSelect = (selectedItem) => {
    this.setState({
      selectedGroups: selectedItem,
    });
  };

  handleFileSelect = (e) => {
    e.preventDefault();
    let data = e.target.files;
    let files = [...this.state.selectedImages];
    for (let i = 0; i < data.length; i++) {
      if (!data[i].type.includes("image")) {
        files.push(data[i]);
        this.setState({ selectedImages: files });
      } else {
        files.push(data[i]);
        this.setState({ selectedImages: files });
      }
    }
  };

  deleteFileContent = (e, idx, file) => {
    let files = [...this.state.selectedImages];
    files.splice(idx, 1);
    this.setState({
      selectedImages: files,
      selectedOrigin: "",
      url: "",
    });
  };

  handleIndividualSelect = (selectItem) => {
    this.setState({
      selectedIndividual: selectItem,
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "heading") {
      if (value.length > 100) {
        swal({
          title: "Warning",
          text: "Heading cannot be more than 100 words",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else if (name === "links") {
      this.setState({ [name]: value }, function () {
        if (this.state.links.includes("/soundcloud.com/")) {
          this.setState({ selectedOrigin: "soundcloud" });
        } else if (this.state.links.includes("vimeo")) {
          this.setState({ selectedOrigin: "vimeo" });
        } else if (
          this.state.links.includes("dailymotion") ||
          this.state.links.includes("dai.ly")
        ) {
          this.setState({ selectedOrigin: "dailymotion" });
        } else if (this.state.links.includes("slideshare")) {
          this.setState({ selectedOrigin: "slideshare" });
        } else if (
          this.state.links.includes("youtube") ||
          this.state.links.includes("youtu.be")
        ) {
          this.setState({ selectedOrigin: "youtube" });
        } else {
          this.setState({ selectedOrigin: "externalLink" });
        }
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleMediaChanges = (e) => {
    e.preventDefault();
    let selectedMedias = [...this.state.selectedLinks];
    selectedMedias.push(this.state.links);
    this.setState({ selectedLinks: selectedMedias, links: "" });
  };

  deleteLinkContent = (e, idx) => {
    let selectedLinks = [...this.state.selectedLinks];
    selectedLinks.splice(idx, 1);
    this.setState({ selectedLinks });
  };

  submitPost = (e) => {
    let groups = [];
    let newImages = [];
    let oldImages = [];
    let selectedImages = this.state.selectedImages;
    if (selectedImages.length > 0) {
      selectedImages.forEach((el) => {
        if (el.updatedData) {
          oldImages.push(el);
        } else {
          newImages.push(el);
        }
      });
    }
    if (this.state.selectedGroups && this.state.selectedGroups.length > 0) {
      this.state.selectedGroups.forEach((el) => {
        groups.push({ id: el.value });
      });
    }
    let peopleIds = [];
    if (this.state.selectedIndividual.length > 0) {
      this.state.selectedIndividual.forEach((el) => {
        peopleIds.push(el.value);
      });
    }
    if (
      this.state.selectedIndividual.length === 0 &&
      this.state.selectedGroups.length === 0
    ) {
      swal({
        title: "Error",
        text: "Atleast group or people should be selected.",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      // this.setState({ isSubmitting: false, showSpinner: false });
      return false;
    }
    var data = {
      id: this.state.id,
      isVerified: 0,
      heading: this.state.heading,
      textContent: this.state.textContent,
      contents: this.getContentJSON(oldImages),
      groups: groups,
      peopleIds: peopleIds,
      postThemes: [],
      postKeywords: [],
      postQuestions: [],
      postType: this.props.notice.postType,
    };
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.updatePostNotice,
        data,
        (response) => {
          if (response.status === 200) {
            if (newImages.length > 0) {
              let formData = new FormData();

              for (const key of Object.keys(newImages)) {
                formData.append("content", newImages[key]);
              }
              formData.append(
                "jsonData",
                JSON.stringify({
                  origin: "myPC",
                  postId: response.data.data.id,
                })
              );
              axiosPost(
                URL.insertPostContent,
                formData,
                (res) => {
                  if (res.status === 200) {
                    swal({
                      title: MESSAGEHEADER.success,
                      text: "Updated successfully",
                      allowOutsideClick: false,
                      closeOnClickOutside: false,
                    });
                    draggable();
                    this.props.closeModal();
                    this.setState({ showSpinner: false });
                  }
                },
                (err) => {
                  this.setState({ showSpinner: false });
                  displayErrorAlert(err);
                }
              );
            } else {
              this.setState({ showSpinner: false });
              swal({
                title: MESSAGEHEADER.success,
                text: "Updated successfully",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.props.closeModal();
            }
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayErrorAlert(error);
        }
      );
    });
  };

  getContentJSON = (oldImages) => {
    let contentArray = [];
    let links = this.state.selectedLinks;
    if (links.length > 0) {
      links.forEach((link) => {
        contentArray.push({
          contentTypeCode: "LNK",
          content: link,
          origin: this.getOrigin(link),
        });
      });
    }
    if (oldImages.length > 0) {
      oldImages.forEach((el) => {
        contentArray.push(el);
      });
    }
    return contentArray;
  };

  getOrigin = (link) => {
    if (link.includes("youtube") || link.includes("youtu.be")) return "youtube";
    if (link.includes("vimeo")) return "vimeo";
    if (link.includes("dailymotion") || link.includes("dai.ly"))
      return "dailymotion";
    if (link.includes(reactServer)) return "insitePost";
    if (link.includes("slideshare")) return "slideshare";
    if (link.includes("soundcloud")) return "soundcloud";
    return "others";
  };

  render() {
    return (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "87vh", padding: "10px" }}
      >
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-1 mt-1">
                <strong>Heading: </strong>
              </div>
              <div className="col-md-11">
                <input
                  type="text"
                  name="heading"
                  className="form-control"
                  value={this.state.heading}
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <JoditEditor
                  value={this.props.notice.textContent}
                  onChange={(e) => this.handleTextEditor(e)}
                  config={config}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="tt-postNoticeBoxes">
                  <div>
                    <strong>Attached files:</strong>
                  </div>
                  <div className="tt-postFiles">
                    {this.state.selectedImages &&
                    this.state.selectedImages.length > 0 ? (
                      this.state.selectedImages.map((file, idx) => {
                        return (
                          <div key={idx}>
                            {file.updatedData ? (
                              file.contentTypeCode === "IMG" ? (
                                <div
                                  className="tt-postFileHolder"
                                  style={{ padding: 0 }}
                                >
                                  <img
                                    src={URL.imageSelectURL + file.content}
                                    alt="notice"
                                    onError={(e) => handleError(e)}
                                  ></img>
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="tt-fileDelete"
                                    onClick={(e) => {
                                      this.deleteFileContent(e, idx, file);
                                    }}
                                  ></FontAwesomeIcon>
                                </div>
                              ) : (
                                <div className="tt-postFileHolder">
                                  <FontAwesomeIcon
                                    icon={faFile}
                                    className="tt-postFileIcon"
                                  ></FontAwesomeIcon>
                                  <div>{file.content}</div>
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="tt-fileDelete"
                                    style={{ top: "-80px" }}
                                    onClick={(e) => {
                                      this.deleteFileContent(e, idx, file);
                                    }}
                                  ></FontAwesomeIcon>
                                </div>
                              )
                            ) : file.type.includes("image") ? (
                              <div
                                className="tt-postFileHolder"
                                style={{ padding: 0 }}
                              >
                                <img
                                  src={window.URL.createObjectURL(file)}
                                  alt="notice"
                                  onError={(e) => handleError(e)}
                                ></img>
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className="tt-fileDelete"
                                  onClick={(e) => {
                                    this.deleteFileContent(e, idx, file);
                                  }}
                                ></FontAwesomeIcon>
                              </div>
                            ) : (
                              <div className="tt-postFileHolder">
                                <FontAwesomeIcon
                                  icon={faFile}
                                  className="tt-postFileIcon"
                                ></FontAwesomeIcon>
                                <div>{file.name}</div>
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className="tt-fileDelete"
                                  style={{ top: "-80px" }}
                                  onClick={(e) => {
                                    this.deleteFileContent(e, idx, file);
                                  }}
                                ></FontAwesomeIcon>
                              </div>
                            )}
                            {}
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">No Files uploaded</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="tt-postNoticeBoxes">
                  <strong>Embeded Media</strong>
                  <div className="tt-postEmbeded">
                    {this.state.selectedLinks &&
                    this.state.selectedLinks.length > 0
                      ? this.state.selectedLinks.map((link, idx) => {
                          return (
                            <div className="row" key={idx}>
                              <div className="col-md-11">
                                <p style={{ wordBreak: "break-word" }}>
                                  {link}
                                </p>
                              </div>
                              <div
                                className="col-md-1"
                                onClick={(e) => this.deleteLinkContent(e, idx)}
                              >
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                ></FontAwesomeIcon>
                              </div>
                            </div>
                          );
                        })
                      : "No links Added"}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-10 text-right">
                <p>
                  Attach Documents (pdf, docs, ods, rtf, pptx, etc.) / Image
                  (jpg,png,gif)
                </p>
              </div>
              <div className="col-md-2">
                <input
                  id="myInput"
                  type="file"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: "none" }}
                  onChange={this.handleFileSelect}
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                  multiple
                />
                <button
                  className="tt-button tt-button-primary tt-button-fullWidth"
                  onClick={() => {
                    this.upload.click();
                  }}
                >
                  Attach File
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 text-right">
                <p className="tt-postNoticeHeading">Embed Media</p>
              </div>
              <div className="col-md-4">
                <select
                  className="form-control"
                  name="selectedOrigin"
                  value={this.state.selectedOrigin}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Youtube / Vimeo / Dailymotion / External Link
                  </option>
                  <option value="youtube">Youtube</option>
                  <option value="vimeo">Vimeo</option>
                  <option value="dailymotion">Dailymotion</option>
                  <option value="externalLink">External Link</option>
                  <option value="slideshare">SlideShare</option>
                  <option value="soundcloud">Sound Cloud</option>
                  {/* <option value="slideshare">SlideShare</option> */}
                </select>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  name="links"
                  value={this.state.links}
                  placeholder="URL"
                  className="form-control"
                  onChange={this.handleChange}
                ></input>
              </div>
              <div className="col-md-2">
                <button
                  className="tt-button tt-button-primary tt-button-fullWidth"
                  onClick={this.handleMediaChanges}
                  disabled={this.state.links === ""}
                >
                  Add Link
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 text-right">
                <p className="tt-postNoticeHeading">Notify*</p>
              </div>
              <div className="col-md-4">
                <Select
                  closeMenuOnSelect={false}
                  isClearable={true}
                  components={animatedComponents}
                  isMulti
                  value={this.state.selectedGroups}
                  options={this.state.groupsArray}
                  placeholder="Choose Group"
                  name="colors"
                  onChange={this.handleChangeSelect}
                  menuPlacement="auto"
                />
              </div>
              <div className="col-md-4">
                <Select
                  closeMenuOnSelect={false}
                  isClearable={true}
                  components={animatedComponents}
                  isMulti
                  value={this.state.selectedIndividual}
                  options={this.state.peopleArray}
                  placeholder="Choose Individual People"
                  name="people"
                  onChange={this.handleIndividualSelect}
                  menuPlacement="auto"
                  isLoading={
                    (this.state.peopleArray &&
                      this.state.peopleArray.length > 0) ||
                    !this.state.peopleSpinner
                      ? false
                      : true
                  }
                />
              </div>
              <div className="col-md-2">
                <button
                  className="tt-button tt-button-primary tt-button-fullWidth"
                  onClick={(e) => this.submitPost(e)}
                  disabled={
                    this.state.heading === "" || this.state.textContent === ""
                  }
                >
                  Update Notice
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Updating Notice ...
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default NoticeEditTemplate;
