import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";

import { draggable, recruitmentStatus, URL } from "../../../utils/Constants";

class AddNewEducationlevel extends Component {
  state = {
    regEx: /^[0-9a-zA-Z!.;':" ]*$/,
    name: "",
    recruitmentStatusId: "",
    errorMessage: "",
    recruitments: [],
    administrators: [],
  };

  componentDidMount() {
    this.getRecruitmentStatusByKey();
  }

  getRecruitmentStatusByKey = () => {
    let data = {
      code: recruitmentStatus.recruitmentCurrent,
    };
    axiosPost(URL.getRecruitmentStatusByKey, data, (response) => {
      if (response.status === 200) {
        this.setState(
          { recruitmentStatusId: response.data.data.id },
          function () {
            let data = {
              recruitmentStatusId: this.state.recruitmentStatusId,
            };
            this.getStaffList(data);
          }
        );
      }
    });
  };

  getStaffList = (data) => {
    axiosPost(URL.getStaffList, data, (response) => {
      if (response.status === 200) {
        let recruitments = [];
        let staffList = response.data.data;
        staffList.forEach((element) => {
          recruitments.push({
            value: element.id,
            label: element.people.name,
          });
        });

        this.setState({
          recruitments,
        });
      }
    });
  };

  handleChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (name === "name") {
      if (!value.match(this.state.regEx)) {
        let errorCharacter = value.slice(-1);
        swal({
          title: "Warning",
          text: `${errorCharacter} not allowed`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleChangeSelect = (selectedItem) => {
    let administrators = [];
    if (selectedItem != null) {
      selectedItem.forEach((element) => {
        administrators.push({
          recruitmentId: element.value,
          value: element.value,
          label: element.label,
        });
      });
    }
    this.setState({
      administrators,
    });
  };

  render() {
    return (
      <div
        className="container-fluid tt-widgetContent-tab-holder"
        style={{ maxHeight: "81vh" }}
      >
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <label htmlFor="name">
              <strong>Education level name</strong>
            </label>
          </div>
          <div className="col-md-5">
            <input
              type="text"
              className="form-control"
              name="name"
              id="educationName"
              placeholder="Enter Name"
              value={this.state.name}
              onChange={this.handleChange}
              maxLength="255"
            />
          </div>
          <div className="col-md-2">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={() => {
                this.props.addNewEduLevel(this.state.name);
                this.setState({ name: "" });
              }}
            >
              Add
            </button>
          </div>
        </div>
        <div className="card mt-2">
          <div className="tt-group-header m-2">Education Level List</div>
          <div className="card-body">
            <table className="table table-bordered table-striped">
              <thead className="tt-group-header">
                <tr>
                  <th>Education Level Name</th>
                  <th>Option</th>
                </tr>
              </thead>
              <tbody>
                {this.props.educationLevelList.length > 0 ? (
                  this.props.educationLevelList.map((eduLevel, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{eduLevel.name}</td>
                        <td className="text-center">
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() => this.props.deleteNewEduLevel(idx)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2} className="text-center">
                      No new education level added
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.handleAddEducationModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddNewEducationlevel;
