import React, { Component } from "react";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
  displaySuccessAlert,
} from "../../utils/Utils";
import { axiosPost } from "../../utils/AxiosApi";
import {
  userRole,
  userRoleCode,
  URL,
  draggable,
  checkRolePermissions,
  checkWidgetAdminOrNot,
} from "../../utils/Constants";
import { Spinner } from "reactstrap";
import ModalWindow from "../UI/ModalWindow";
import AssignmentDetails from "./AssignmentDetails";
import Chip from "@material-ui/core/Chip";
import swal from "sweetalert";
import CourseClassResources from "../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseResourceTemplate";
import CourseClassResource from "../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseClassResource";
import CreateSubjectAssignment from "./CreateSubjectAssignment";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

export default class ListSubjectAssignment extends Component {
  state = {
    showAssigned: true,
    assignments: [],
    spinner: false,
    viewModal: false,
    assignment: null,
    assignmentStudent: [],
    contents: [],
    links: [],
    submissionModal: false,
    selectedId: "",
    submittedResourceModal: false,
    submittedResources: [],
    currentStudentStatus: "",
    currentAdmissionId: "",
    currentAssignmentId: "",
    remarks: "",
    status: "",
    assignmentUpdate: false,
    updateAssignmentId: "",
    updateAssignmentActivity: "",
    mark: "",
    point: "",
    permissionUpdateAll: false,
    permissionUpdateOwn: false,
    permissionDeleteAll: false,
    permissionDeleteOwn: false,
    permissionModal: false,
    showSpinner: false,
  };
  componentDidMount() {
    this.getAssignments();
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionUpdateAll: checkRolePermissions(
        "update-all-assignment",
        "activity"
      ),
      permissionUpdateOwn: checkRolePermissions(
        "update-own-assignment",
        "activity"
      ),
      permissionDeleteAll: checkRolePermissions(
        "delete-all-assignment",
        "activity"
      ),
      permissionDeleteOwn: checkRolePermissions(
        "delete-own-assignment",
        "activity"
      ),
    });
  };

  getAssignments = () => {
    this.setState({ spinner: true });
    axiosPost(
      URL.selectAssignment,
      { isAssigned: this.state.showAssigned },
      (response) => {
        if (response.status === 200) {
          this.setState({ assignments: response.data.data, spinner: false });
        }
      }
    );
  };
  handleSwitchChange = () => {
    this.setState({ showAssigned: !this.state.showAssigned }, () => {
      this.getAssignments();
    });
  };

  toggleAssignmentUpdateModal = () => {
    this.setState(
      {
        assignmentUpdate: !this.state.assignmentUpdate,
      },
      () => {
        this.getAssignments();
      }
    );
  };

  handleViewDetails = (id, idx) => {
    //call api to update view
    let params = {
      assignmentId: id,
    };
    this.setState({ assignment: this.state.assignments[idx] });
    axiosPost(URL.viewAssignment, params, (response) => {
      this.getStudent(id);
    });

    this.toggleViewModal();
  };

  handleDelete = (id, idx, activity) => {
    displayConfirmDeleteAlert({ id, activity }, this.handleDeleteAssignment);
  };

  handleAssign = (id, idx) => {
    let param = {
      id: id,
    };
    axiosPost(URL.assignAssignment, param, (response) => {
      if (response.data.status === 200) {
        displaySuccessAlert(response, () => {
          this.getAssignments();
        });
      }
    });
  };

  handleUpdate = (id, idx, activity) => {
    this.setState({
      assignmentUpdate: !this.state.assignmentUpdate,
      updateAssignmentId: id,
      updateAssignmentActivity: activity,
    });
  };

  handleDeleteAssignment = (data) => {
    let param = {
      id: data.id,
      activity: data.activity,
      isAssigned: this.state.showAssigned,
    };
    axiosPost(URL.deleteAssignment, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response, () => {
          this.getAssignments();
        });
      }
    });
  };

  toggleViewModal = () => {
    this.setState({ viewModal: !this.state.viewModal });
  };

  getStudent = (id) => {
    this.setState({ assignmentStudent: [] });
    let params = {
      assignmentId: id,
    };

    axiosPost(URL.selectAssignmentStudents, params, (response) => {
      if (response.status == 200) {
        this.setState({ assignmentStudent: response.data.data });
      }
    });
  };

  saveResources = (contents, links) => {
    this.setState({ contents: contents, links: links });

    let formData = new FormData();
    for (const key of Object.keys(contents)) {
      formData.append("files", contents[key]);
    }
    if (contents.length <= 0) {
      formData.append("files", []);
    }

    formData.append(
      "jsonData",
      JSON.stringify({
        assignmentId: this.state.selectedId,
        links: links,
      })
    );
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.submitStudentAssignment,
        formData,
        (res) => {
          if (res.status === 200) {
            swal({
              title: "Success",
              text: "Successfully submitted",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            this.setState({ showSpinner: false, submissionModal: false });
            this.getStudent(this.state.selectedId);
            draggable();
            // this.resetState();
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayErrorAlert(error);
          // this.resetState();
        }
      );
    });
    //call api and update list
    // this.handleAddResourceModal();
  };

  handleSubmissionModal = (id) => {
    this.setState({
      submissionModal: !this.state.submissionModal,
      selectedId: id,
    });
  };
  toggleSubmissionModal = () => {
    this.setState({
      submissionModal: !this.state.submissionModal,
      selectedId: "",
    });
  };

  toggleSubmittedResourceModal = () => {
    this.setState({
      submittedResourceModal: !this.state.submittedResourceModal,
    });
  };
  handleViewSubmittedResources = (
    assignmentId,
    studentId,
    studentStatus,
    point
  ) => {
    this.setState({
      currentStudentStatus: studentStatus,
      currentAdmissionId: studentId,
      currentAssignmentId: assignmentId,
      point: point,
    });
    let params = {
      assignmentId: assignmentId,
      admissionId: studentId,
    };
    axiosPost(URL.viewStudentAssignmentResources, params, (response) => {
      if (response.status == 200) {
        this.setState({ submittedResources: response.data.data });
        this.toggleSubmittedResourceModal();
      }
    });
  };
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === "mark") {
      if (value > this.state.point || value == 0) {
        swal({
          title: "Warning",
          text:
            "Point must be less than " +
            this.state.point +
            " and greater than 0.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
      }
    }
    this.setState({
      [name]: value,
    });
  };

  handleAssignmentReview = () => {
    let params = {
      status: this.state.status,
      remarks: this.state.remarks,
      assignmentId: this.state.currentAssignmentId,
      admissionId: this.state.currentAdmissionId,
    };
    axiosPost(URL.updateAssignmentStudentStatus, params, (response) => {
      if (response.status == 200) {
        this.setState({ currentStudentStatus: params.status });
        swal({
          title: "Success",
          text: "Assignment Checked Successfully",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
      }
    });
  };

  handleAssignmentReviewBatch = (e) => {
    let params = {
      status: this.state.status,
      remarks: this.state.remarks === "" ? "No remarks" : this.state.remarks,
      assignmentId: this.state.currentAssignmentId,
      admissionId: this.state.currentAdmissionId,
      type:
        this.state.status === "Correction" || this.state.status === "Approved"
          ? "RMK"
          : "",
      batch: e,
      point: this.state.mark,
    };

    this.setState(
      {
        showSpinner: true,
      },
      () => {
        axiosPost(
          URL.updateAssignmentStudentStatus,
          params,
          (response) => {
            if (response.status == 200) {
              this.setState({
                currentStudentStatus: params.status,
                submittedResourceModal: false,
              });
              swal({
                title: "Success",
                text: "Assignment Checked Successfully",
                closeOnClickOutside: false,
                allowOutsideClick: false,
              });

              this.setState({
                showSpinner: false,
              });
              this.getStudent(this.state.currentAssignmentId);
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              showSpinner: false,
            });
          }
        );
      }
    );
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div
          className="tt-widgetContent-tab-holder"
          style={{
            height: "79.5vh",
          }}
        >
          <div className="tt-group-header">
            List Subject Assignment
            {checkWidgetAdminOrNot("Assignment") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>

          <div
            className="tt-newListBox mb-1"
            style={{
              height: "60.5vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {userRole !== userRoleCode.roleStudent &&
            userRole !== userRoleCode.roleStudentContact ? (
              <div className="container-fluid">
                <div className="text-right">
                  Drafts
                  <Switch
                    checked={this.state.showAssigned}
                    onClick={this.handleSwitchChange}
                  />
                  Assigned
                </div>
              </div>
            ) : null}

            <table className="table table-bordered table-striped">
              <thead className="tt-group-header">
                <tr>
                  <th width="210px">Assignment</th>

                  <th width="165px">Status</th>

                  <th width="165px">Option</th>
                </tr>
              </thead>
              <tbody>
                {this.state.assignments.length > 0 ? (
                  this.state.assignments.map((assignment, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          {" "}
                          <strong>{assignment.title}</strong>
                          <br />
                          Submission Date :{" "}
                          {moment(assignment.submissionDate).format("LLL")}
                          <br />
                          <Chip
                            color="primary"
                            className="override-chip"
                            label={
                              assignment.yearLevelName +
                              "-" +
                              assignment.classroomName +
                              "-" +
                              assignment.subjectName
                            }
                          />
                        </td>
                        {userRole == userRoleCode.roleStudent ||
                        userRole == userRoleCode.roleStudentContact ? (
                          <td>
                            {assignment?.assignmentStatus}
                            <br />
                            {assignment.submittedDate != null
                              ? "Submitted On :" +
                                moment(assignment.submittedDate).format("LL")
                              : null}
                          </td>
                        ) : (
                          <td>
                            Viewed By : <strong>{assignment.totalSeen}</strong>/
                            {assignment.totalStudents}
                            <br />
                            Submitted By :
                            <strong>{assignment.totalSubmitted}</strong>/
                            {assignment.totalStudents}
                            <br />
                            Total Approved :
                            <strong>{assignment.totalApproved}</strong>/
                            {assignment.totalStudents}
                            <br />
                            Sent for Correction :
                            <strong>{assignment.totalCorrection}</strong>/
                            {assignment.totalStudents}
                          </td>
                        )}
                        <td className="text-center">
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() =>
                              this.handleViewDetails(assignment.id, idx)
                            }
                          >
                            View Details
                          </button>
                          {this.state.showAssigned ? (
                            ""
                          ) : this.state.permissionUpdateAll ? (
                            <>
                              <button
                                className="tt-button tt-button-primary ml-3"
                                onClick={() =>
                                  this.handleAssign(assignment.id, idx)
                                }
                              >
                                Assign
                              </button>
                              <button
                                className="tt-button tt-button-primary ml-3"
                                onClick={() =>
                                  this.handleUpdate(
                                    assignment.id,
                                    idx,
                                    "update-all-assignment"
                                  )
                                }
                              >
                                Update
                              </button>
                            </>
                          ) : this.state.permissionUpdateOwn ? (
                            <>
                              <button
                                className="tt-button tt-button-primary ml-3"
                                onClick={() =>
                                  this.handleAssign(assignment.id, idx)
                                }
                              >
                                Assign
                              </button>
                              <button
                                className="tt-button tt-button-primary ml-3"
                                onClick={() =>
                                  this.handleUpdate(
                                    assignment.id,
                                    idx,
                                    "update-own-assignment"
                                  )
                                }
                              >
                                Update
                              </button>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.showAssigned ? (
                            ""
                          ) : this.state.permissionDeleteAll ? (
                            <button
                              className="tt-button tt-button-primary ml-3"
                              onClick={() =>
                                this.handleDelete(
                                  assignment.id,
                                  idx,
                                  "delete-all-assignment"
                                )
                              }
                            >
                              Delete
                            </button>
                          ) : this.state.permissionDeleteOwn ? (
                            <button
                              className="tt-button tt-button-primary ml-3"
                              onClick={() =>
                                this.handleDelete(
                                  assignment.id,
                                  idx,
                                  "delete-own-assignment"
                                )
                              }
                            >
                              Delete
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      {this.state.spinner ? (
                        <Spinner color="white"></Spinner>
                      ) : (
                        "No assignments"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <ModalWindow
              modal={this.state.viewModal}
              toggleModal={this.toggleViewModal}
              modalHeader="View Assignment Details"
              // fullWidth={true}
              size="xl"
              modalBody={
                <AssignmentDetails
                  propFromStudentInformation={this.props.condition}
                  assignment={this.state.assignment}
                  assignmentStudent={this.state.assignmentStudent}
                  handleSubmissionModal={this.handleSubmissionModal}
                  handleViewSubmittedResources={
                    this.handleViewSubmittedResources
                  }
                />
              }
            ></ModalWindow>
            <ModalWindow
              modal={this.state.submittedResourceModal}
              toggleModal={this.toggleSubmittedResourceModal}
              modalHeader="View Submitted Resources"
              // fullWidth={true}
              size="xl"
              modalBody={
                <>
                  <CourseClassResources
                    showSpinner={this.state.showSpinner}
                    isSubmitted={true}
                    isSchoolAssignment={true}
                    selectedResources={this.state.submittedResources}
                    handleAssignmentReviewBatch={
                      this.handleAssignmentReviewBatch
                    }
                    remarks={this.state.remarks}
                    status={this.state.status}
                    handleChange={this.handleChange}
                    currentStudentStatus={this.state.currentStudentStatus}
                    mark={this.state.mark}
                    point={this.state.point}
                  ></CourseClassResources>
                </>
              }
            ></ModalWindow>
            <ModalWindow
              modal={this.state.submissionModal}
              toggleModal={this.toggleSubmissionModal}
              fullWidth={true}
              modalHeader="Submit Assignment Resources"
              modalBody={
                <CourseClassResource
                  assignment={true}
                  isSubmission={true}
                  toggleAddResourceModal={this.handleSubmissionModal}
                  saveResources={this.saveResources}
                  assignmentContents={this.state.contents}
                  assignmentLinks={this.state.links}
                ></CourseClassResource>
              }
            ></ModalWindow>
            <ModalWindow
              modal={this.state.assignmentUpdate}
              modalHeader="Update Assignment"
              fullWidth={true}
              toggleModal={this.toggleAssignmentUpdateModal}
              modalBody={
                <CreateSubjectAssignment
                  id={this.state.updateAssignmentId}
                  activity={this.state.updateAssignmentActivity}
                  action="update"
                  closeModal={this.toggleAssignmentUpdateModal}
                ></CreateSubjectAssignment>
              }
              //modalBody={"NOTICE HERE"}
            ></ModalWindow>
          </div>

          <ModalWindow
            modal={this.state.permissionModal}
            size="lg"
            id="tt-permissionModal"
            toggleModal={() => {
              this.setState({
                permissionModal: !this.state.permissionModal,
              });
            }}
            modalHeader={"Assign permission to user"}
            modalBody={
              <GranularPermissionModal
                widgetName="Assignment"
                moduleName="Subject Assignment"
                header="List Subject Assignment configuration"
                activityName="list-subject-assignment"
              />
            }
          ></ModalWindow>
        </div>
      </>
    );
  }
}
