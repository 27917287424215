import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import ModalWindow from "../../UI/ModalWindow";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, userRoleCode } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

import SystemExamHeader from "./SystemExamHeader";
import SystemExamQuestions from "./SystemExamQuestions";

class SystemExamConfiguration extends Component {
  state = {
    selectedStaff: [],
    staffLists: [],
    questionBox: false,
    trainings: [],
    trainingId: "",
    topic: "",
    subjectId: "",
    creatorPeopleId: "",
    educationLevels: [],
    educationLevelCode: "",
    educationLevelId: "",
    assessmentSubjects: [],
    groupsArray: [],
    selectedGroups: [],
    testType: [],
    selectedTestType: "",
    timer: "",
    peopleSpinner: false,
    selectedIndividual: [],
    peopleArray: [],
    theoryQuestions: [
      {
        Question: "",
        explanation: "",
        isOpen: false,
        type: 0, //subjective
        hierarchyOrder: 1,
        // isPublished: false,
        isTheory: true,
        markWeight: 0,
        subjectQuestionOptions: [
          {
            optionValue: "",
            isCorrect: false
          }
        ]
      }
    ],
    practicalQuestions: [
      {
        Question: "",
        explanation: "",
        isOpen: false,
        type: 0, //subjective
        hierarchyOrder: 1,
        isPractical: true,
        markWeight: 0,
        // isPublished: false,
        subjectQuestionOptions: [
          {
            optionValue: "",
            isCorrect: false
          }
        ]
      }
    ],
    marks: "full",
    yearLevels: [],
    yearLevelId: "",
    classRooms: [],
    classRoomId: "",
    assessments: [],
    assessmentId: "",
    assessmentModuleCode: null,
    educationLevelDivisions: null,
    educationLevelDivisionId: "",
    marksCalculation: "0",
    hasPractical: false,
    hasTheory: false,
    theoryMaxMark: 0,
    practicalMaxMark: 0,
    passMarks: "",
    spinner: false,
    fieldError: false,
    examTitle: "",
    examDesc: "",
    totalMark: "",
    attendees: []
  };

  clearData = () => {
    this.setState({
      trainingId: "",
      selectedStaff: [],
      examDesc: "",
      passMarks: 0,
      totalMark: 0,
      examTitle: "",
      questionBox: false,
      topic: "",
      subjectId: "",
      creatorPeopleId: "",
      educationLevelCode: "",
      selectedGroups: [],
      selectedIndividual: [],
      selectedTestType: "",
      timer: "",
      // questions: [
      //   {
      //     Question: "",
      //     explanation: "",
      //     isOpen: false,
      //     type: 0, //subjective
      //     subjectQuestionOptions: [
      //       {
      //         optionValue: "",
      //         isCorrect: false,
      //       },
      //     ],
      //   },
      // ],
      theoryQuestions: [
        {
          Question: "",
          explanation: "",
          isOpen: false,
          type: 0, //subjective
          hierarchyOrder: 1,
          // isPublished: false,
          isTheory: true,
          markWeight: 0,
          subjectQuestionOptions: [
            {
              optionValue: "",
              isCorrect: false
            }
          ]
        }
      ]
    });
  };

  clearState = () => {
    swal({
      title: "Are you sure?",
      text: "Once cleared, you will not be able to recover this record!",
      // icon: "warning",
      closeOnClickOutside: false,
      allowOutsideClick: false,
      buttons: true,
      dangerMode: true
    }).then(deleteConfirm => {
      if (deleteConfirm) {
        this.clearData();
      } else {
      }
    });
  };

  componentDidMount() {
    this.getTrainings();
    this.getAllStaffsList();

    if (this.props.editMode) {
      this.updateConfig();
    }
  }

  updateConfig = () => {
    let editData = this.props.editData;

    let newQuestions = editData.examQuestions.map((eq, idx) => {
      let data = {
        Question: eq.question,
        examGiven:
          eq?.answers?.length > 0 && eq?.answers?.some(a => a.isSubmitted)
            ? true
            : false,
        explanation: eq.explanation,
        hierarchyOrder: idx + 1,
        isOpen: eq.explanation !== "" ? true : false,
        isTheory: true,
        markWeight: eq.markWeight,
        type: eq.questionType === "OBJECTIVE" ? 1 : 0,
        subjectQuestionOptions: eq.examQuestionOptions.map(op => {
          return {
            isCorrect: op.isCorrect,
            optionValue: op.optionValue
          };
        })
      };
      return data;
    });

    this.setState({
      examDesc: editData.description,
      examTitle: editData.title,
      trainingId: editData.currentTrainingId,
      totalMark: editData.fullMarks,
      passMarks: editData.passMarks,
      theoryQuestions: newQuestions,
      selectedStaff: editData.peoples.map(p => {
        return {
          ...p,
          label: p.name,
          value: p.id
        };
      })
    });
  };

  getTrainings = () => {
    axiosPost(URL.getAllTraining, {}, response => {
      if (response.status === 200) {
        this.setState({
          trainings: response.data.data
        });
      }
    });
  };

  getAllStaffsList = () => {
    let data = {
      userRoleCode: userRoleCode.roleStaff
    };
    axiosPost(URL.getMasterUsers, data, response => {
      let data = response.data.data;
      data.forEach(sl => {
        sl.label = sl.name;
        sl.value = sl.id;
      });

      this.setState({
        staffLists: data
      });
    });
  };

  changeStaffSelect = data => {
    this.setState({
      selectedStaff: data
    });
  };

  handleChange = e => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  addOption = (e, idx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    questions[idx].subjectQuestionOptions.push({
      optionValue: "",
      isCorrect: false
    });
    this.setState({ [stateName]: questions });
  };

  removeOption = (e, idx, oIdx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    if (questions[idx].subjectQuestionOptions.length < 2) {
      swal({ title: "Error", text: "Atleast one option should be given" });
      return;
    } else {
      questions[idx].subjectQuestionOptions.splice(oIdx, 1);
      this.setState({ [stateName]: questions });
    }
  };

  handleChangeSelect = selectedItem => {
    this.setState({
      selectedGroups: selectedItem
    });
  };

  handleIndividualSelect = selectItem => {
    this.setState({
      selectedIndividual: selectItem
    });
  };

  handleMcqOptionChange = (e, oIdx, idx, type) => {
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let questions = [...this.state[stateName]];
    if (name === "isCorrect") {
      if (questions[idx].subjectQuestionOptions.length > 0) {
        questions[idx].subjectQuestionOptions.forEach(el => {
          el.isCorrect = false;
        });
      }
    }
    questions[idx].subjectQuestionOptions[oIdx][name] = value;
    this.setState({ [stateName]: questions });
  };

  handleMcqQuestionChange = (e, idx, type) => {
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let questions = [...this.state[stateName]];
    // if (name === "markWeight") {
    questions[idx][name] = value;
    // }
    this.setState({ [stateName]: questions });
  };

  handleExplanation = (e, idx, type) => {
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    questions[idx]["explanation"] = e;
    this.setState({ [stateName]: questions });
  };

  addQuestion = (e, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];

    const hierarchyOrder = questions.length + 1;
    let obj = {
      Question: "",
      explanation: "",
      isOpen: false,
      type: 0, //subjective
      hierarchyOrder: hierarchyOrder,
      markWeight: 0,
      subjectQuestionOptions: [
        {
          optionValue: "",
          isCorrect: false
        }
      ]
    };
    if (type === "t") {
      obj.isTheory = true;
    }
    if (type === "p") {
      obj.isPractical = true;
    }
    questions.push(obj);
    // if (this.state.marksCalculation === "0") {
    //   const markState = type === "t" ? "theoryMaxMark" : "practicalMaxMark";
    //   const l = questions.length;
    //   questions.forEach((q) => {
    //     q.markWeight = parseFloat((this.state[markState] / l).toFixed(2));
    //   });
    // }
    this.setState({ [stateName]: questions });
  };

  removeQuestion = (e, idx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    questions.splice(idx, 1);
    if (questions.length < 1) {
      swal({ title: "Error", text: "Atleast one question is necessary" });
    } else {
      this.setState({ [stateName]: questions });
    }
  };

  openExplanation = (e, idx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];
    questions.forEach(el => {
      el.isOpen = false;
    });
    questions[idx].isOpen = true;
    this.setState({ [stateName]: questions });
  };

  closeExplanation = (e, idx, type) => {
    e.preventDefault();
    const stateName = type === "t" ? "theoryQuestions" : "practicalQuestions";
    let questions = [...this.state[stateName]];

    if (idx !== undefined) {
      questions[idx].explanation = "";
    }
    questions.forEach(el => {
      el.isOpen = false;
    });
    this.setState({ [stateName]: questions });
  };

  saveExplanation = (e, idx) => {
    e.preventDefault();
    let questions = [...this.state.questions];
    questions.forEach(el => {
      el.isOpen = false;
    });
    this.setState({ questions });
  };

  handleSubmit = e => {
    e.preventDefault();
    let theoryQuestions = [...this.state.theoryQuestions];
    let practicalQuestions = [...this.state.practicalQuestions];

    if (theoryQuestions.some(t => t.examGiven)) {
      swal(
        "Error",
        "Exam already attended. Cannot update questions and answers."
      );
      return;
    } else {
      if (this.state.marksCalculation === "1") {
        const totalTheoryMarks = theoryQuestions?.reduce(function(acc, obj) {
          return acc + parseInt(obj.markWeight);
        }, 0);
        const totalPracticalMarks = practicalQuestions?.reduce(function(
          acc,
          obj
        ) {
          return acc + parseInt(obj.markWeight);
        },
        0);

        if (
          this.state.hasTheory &&
          totalTheoryMarks < this.state.theoryMaxMark
        ) {
          swal("Warning", "Total theory marks not reached.");
          return;
        }
        if (
          this.state.hasPractical &&
          totalPracticalMarks < this.state.practicalMaxMark
        ) {
          swal("Warning", "Total practical marks not reached.");
          return;
        }
      } else if (this.state.marksCalculation === "0") {
        theoryQuestions = theoryQuestions;
        practicalQuestions = practicalQuestions;
      }

      let qArr = [...theoryQuestions];

      // let newQ = qArr.filter((data) => data.Question !== "");

      let questions = qArr;

      //Validating Data
      let err = 0;
      if (questions.length > 0) {
        questions.forEach(el => {
          if (el.Question === "") {
            err++;
          }
          let correct = 0;
          if (el.type == "1") {
            el.subjectQuestionOptions.forEach(opt => {
              if (opt.optionValue === "") {
                err++;
              }
              if (opt.isCorrect === true) {
                correct++;
              }
            });
          }
        });

        const q = questions.find(
          que =>
            que.Question === "" ||
            (que.type == "1" &&
              que.subjectQuestionOptions.some(opt => opt.optionValue === ""))
        );
        if (q) {
          swal({
            title: "Error",
            text: "Fill all the Questions and Options first",
            allowOutsideClick: false,
            closeOnClickOutside: false
          });
          return;
        }
      }

      let data = [];
      if (questions.length > 0) {
        questions.forEach(el => {
          let obj = {};
          let options = [];

          obj.question = el.Question;
          obj.questionType =
            el.type == "0"
              ? "SUBJECTIVE"
              : el.type == "1"
              ? "OBJECTIVE"
              : "NONANSWERABLE";
          // obj.educationLevelId = this.state.educationLevelId;
          // obj.educationLevelDivisionId = this.state.educationLevelDivisionId;
          // obj.yearLevelId = this.state.yearLevelId;
          // obj.classroomId = this.state.classRoomId;
          // obj.assessmentId = this.state.assessmentId;
          // obj.subjectId = this.state.subjectId;
          obj.hierarchyOrder = el.hierarchyOrder;
          obj.explanation = el.explanation;
          obj.markWeight = el.markWeight;
          // obj.isPublished = el.isPublished;

          if (el.isTheory) {
            obj.isTheory = el.isTheory;
            obj.isPractical = false;
          }
          if (el.isPractical) {
            obj.isTheory = false;
            obj.isPractical = el.isPractical;
          }

          if (el.type == "1") {
            options = el.subjectQuestionOptions.map(opt => {
              return {
                optionValue: opt.optionValue,
                isCorrect: opt.isCorrect,
                optionType: "text"
              };
            });
            obj.examQuestionOptions = options;
          }

          data.push(obj);
        });
      }

      let totalmarks = 0;

      questions.forEach(q => {
        totalmarks = totalmarks + parseInt(q.markWeight);
      });

      let selectedStaffs = this.state.selectedStaff;

      let staffArr = [];

      selectedStaffs.map(ss => {
        staffArr.push(ss.id);
      });

      if (
        this.state.trainingId !== "" &&
        this.state.examTitle !== "" &&
        this.state.totalMark > 0 &&
        this.state.passMarks > 0 &&
        staffArr.length > 0
      ) {
        if (totalmarks === parseInt(this.state.totalMark)) {
          let newData = {
            currentTrainingId: this.state.trainingId,
            title: this.state.examTitle,
            description: this.state.examDesc,
            fullMarks: this.state.totalMark,
            passMarks: this.state.passMarks,
            attendees: staffArr,
            examQuestions: data
          };

          if (this.props.editMode) {
            newData.id = this.props.editData.id;
          }

          this.setState(
            {
              spinner: true
            },
            () => {
              axiosPost(
                this.props.editMode ? URL.updateExam : URL.insertExam,
                newData,
                response => {
                  if (response.status === 200) {
                    swal({
                      title: "Success",
                      text: "System exam added successfully.",
                      allowOutsideClick: false,
                      closeOnClickOutside: false
                    });
                    this.setState({
                      spinner: false,
                      fieldError: false
                    });
                    this.clearData();
                    if (this.props.editMode) {
                      this.props.toggleEditModal();
                      this.props.getExamList();
                    }
                  }
                },
                Err => {
                  displayErrorAlert(Err);
                  this.setState({
                    spinner: false
                  });
                }
              );
            }
          );
        } else {
          swal("Error", "Questions marks and total marks is not equal!!");
        }
      } else {
        this.setState({
          fieldError: true
        });
        swal("Error", "Fill all the required fields");
      }
    }
  };

  handleShowQuestionBox = () => {
    this.setState({
      questionBox: true
    });
  };

  render() {
    return (
      <>
        {" "}
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="whiteContainer">
                {!this.props.editMode && (
                  <CustomPageHeader
                    noIcon={true}
                    title="System Exam Configuration"
                  />
                )}
                <div>
                  <SystemExamHeader
                    editMode={this.props.editMode}
                    staffLists={this.state.staffLists}
                    selectedStaff={this.state.selectedStaff}
                    changeStaffSelect={this.changeStaffSelect}
                    questionBox={this.state.questionBox}
                    handleShowQuestionBox={this.handleShowQuestionBox}
                    trainings={this.state.trainings}
                    topic={this.state.topic}
                    handleChange={this.handleChange}
                    handleChangeSelect={this.handleChangeSelect}
                    marks={this.state.marks}
                    assessments={this.state.assessments}
                    assessmentId={this.state.assessmentId}
                    educationLevelDivisions={this.state.educationLevelDivisions}
                    educationLevelDivisionId={
                      this.state.educationLevelDivisionId
                    }
                    assessmentSubjects={this.state.assessmentSubjects}
                    marksCalculation={this.state.marksCalculation}
                    theoryMaxMark={this.state.theoryMaxMark}
                    practicalMaxMark={this.state.practicalMaxMark}
                    totalMark={this.state.totalMark}
                    passMarks={this.state.passMarks}
                    examTitle={this.state.examTitle}
                    examDesc={this.state.examDesc}
                    trainingId={this.state.trainingId}
                    fieldError={this.state.fieldError}
                  ></SystemExamHeader>

                  {this.state.questionBox || this.props.editMode ? (
                    <React.Fragment>
                      <div className="row mt-3">
                        <div className="col">
                          <div className="questionBox">
                            {/* <strong className="courseTitle">Theory</strong> */}
                            <SystemExamQuestions
                              questions={this.state.theoryQuestions}
                              addOption={this.addOption}
                              addQuestion={this.addQuestion}
                              openExplanation={this.openExplanation}
                              closeExplanation={this.closeExplanation}
                              saveExplanation={this.saveExplanation}
                              handleMcqOptionChange={this.handleMcqOptionChange}
                              handleMcqQuestionChange={
                                this.handleMcqQuestionChange
                              }
                              removeOption={this.removeOption}
                              removeQuestion={this.removeQuestion}
                              handleExplanation={this.handleExplanation}
                              marksCalculation={this.state.marksCalculation}
                              type="t"
                            ></SystemExamQuestions>
                            <div className="row">
                              <div className="col text-right mt-2">
                                <div>
                                  <button
                                    className="btn btn-primary cusBtn cusBtnFontSize"
                                    onClick={e => this.addQuestion(e, "t")}
                                    id="addQuestion"
                                  >
                                    Add Question
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col text-end">
                          <button
                            className="btn btn-primary cusBtn cusBtnFontSize mr-3"
                            onClick={e => this.handleSubmit(e, false)}
                          >
                            Publish
                          </button>

                          <button
                            className="btn btn-primary cusBtn cusBtnFontSize"
                            onClick={this.clearState}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SystemExamConfiguration;
