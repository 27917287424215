import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
} from "../../utils/Utils";
import ModalWindow from "../UI/ModalWindow";
import ViewLMSFile from "./ViewLMSFile";
import Pagination from "../UI/Pagination";
// import DataTable from "react-data-table-component";

export class ListSchoolLMS extends Component {
  state = {
    lmsList: [],
    activeLMSFile: [],
    fileViewModal: false,
    editMode: false,
    title: "",
    description: "",
    spinner: false,
    addedResource: null,
    addResourceObj: "",
    addResourceModal: false,
    links: [{ name: "" }],

    pageSize: 7,
    currentPage: 1,
    totalRecords: 0,
    searchQuery: false,
    getSpinner: false,
  };

  componentDidMount() {
    this.getSchoolLMS();
  }

  getSchoolLMS = (page = 1) => {
    let schoolLmsTypeId =
      this.props.lms === "curriculum"
        ? 1
        : this.props.lms === "textbooks"
        ? 2
        : this.props.lms === "question-bank"
        ? 3
        : this.props.lms === "av-resource"
        ? 4
        : this.props.lms === "sign-language"
        ? 5
        : "";

    let offset =
      page * parseInt(this.state.pageSize) - parseInt(this.state.pageSize);
    let params = {
      schoolLmsTypeId: schoolLmsTypeId,
      offset: offset,
      rowCountLimit: this.state.pageSize,
    };

    this.setState(
      {
        getSpinner: true,
        lmsList: [],
      },
      () => {
        axiosPost(
          URL.getSchoolLMS,
          params,
          (response) => {
            if (response.status === 200) {
              this.setState({
                lmsList: response.data.data,
                currentPage: page,
                getSpinner: false,
                totalRecords: response.data.totalRecordsCount,
              });
            }
          },
          (err) => {
            this.setState({
              getSpinner: false,
            });
            displayErrorAlert(err);
          }
        );
      }
    );
  };

  handleViewResource = (file) => {
    // if (file.schoolLmsResources?.length > 0) {
    this.setState({
      activeLMSFile: file,
      fileViewModal: true,
    });
    // } else {
    //   swal("Information", "No any resources are added yet");
    // }
  };

  toggleFileViewer = () => {
    this.setState({
      fileViewModal: !this.state.fileViewModal,
    });
  };

  handleAddResource = (item) => {
    this.setState({
      addResourceObj: item,
      addResourceModal: true,
    });
  };

  toggleAddResource = () => {
    this.setState({
      addResourceModal: !this.state.addResourceModal,
    });
  };

  handleChange = (e) => {
    let { name, value, files } = e.target;

    this.setState({ [name]: files ? files : value });
  };

  handleSubmitResources = () => {
    let newLinks = [];
    let linkCount = 0;
    newLinks = this.state.links.map((lnk) => {
      if (lnk.name !== "") {
        linkCount = linkCount + 1;
        return lnk.name;
      }
    });
    let data = {
      id: this.state.addResourceObj.id,
      links: linkCount > 0 ? newLinks : [],
    };

    let formData = new FormData();

    formData.append("jsonData", JSON.stringify(data));

    if (this.state.addedResource !== null) {
      for (const key of Object.keys(this.state.addedResource)) {
        formData.append("contents", this.state.addedResource[key]);
      }
    }

    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(
          URL.addExtraLMSResources,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", "Resources added successfully.");
              this.getSchoolLMS();
              this.toggleAddResource();
              this.setState({
                spinner: false,
                addedResource: null,
                links: [{ name: "" }],
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleUpdateClick = (item) => {
    this.setState({
      title: item.title,
      description: item.description,
      updateData: item,
      editMode: true,
    });
  };
  toggleUpdateModal = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  };

  handleSubmitUpdate = () => {
    let data = {
      id: this.state.updateData.id,
      title: this.state.title,
      description: this.state.description,
    };

    if (this.state.title === "") {
      swal("Error", "Title and description cannot be empty.");
    } else {
      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(
            URL.updateSchoolLMS,
            data,
            (response) => {
              if (response.status === 200) {
                swal("Success", "Data updated successfully");
              }
              this.getSchoolLMS();
              this.toggleUpdateModal();

              this.setState({
                spinner: false,
              });
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                spinner: false,
              });
            }
          );
        }
      );
    }
  };

  handleLinkChange = (e, idx) => {
    let links = [...this.state.links];

    links[idx].name = e.target.value;

    this.setState({
      links: links,
    });
  };

  addLinkRow = () => {
    let links = [...this.state.links];
    links.push({
      name: "",
    });
    this.setState({ links });
  };

  removeLinkRow = (idx) => {
    let links = [...this.state.links];

    links.splice(idx, 1);
    this.setState({ links });
  };

  handlePageChange = (page) => {
    if (this.state.searchQuery) {
      this.handleSearchData(page);
    } else {
      this.getSchoolLMS(page);
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (nextPage > Math.ceil(this.state.totalRecords / this.state.pageSize))
      return;
    else {
      if (this.state.searchQuery) {
        this.handleSearchData(nextPage);
      } else {
        this.getSchoolLMS(nextPage);
      }
    }
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1) {
      let prevPage = this.state.currentPage - 1;

      if (this.state.searchQuery) {
        this.handleSearchData(prevPage);
      } else {
        this.getSchoolLMS(prevPage);
      }
    }
  };

  //Delete course

  handleDeleteClick = (item) => {
    displayConfirmDeleteAlert(item, this.deleteLMS);
  };

  deleteLMS = (item) => {
    let params = { id: item.id };
    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(
          URL.deleteLMSData,
          params,
          (response) => {
            if (response.status === 200) {
              swal("Success", response.data.message);
              this.getSchoolLMS();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  render() {
    const dataColumns = [
      {
        name: "Title",
        selector: "title",
        sortable: true,
      },
      {
        name: "Year Level",
        selector: "yearLevelName",
        sortable: true,
      },
      {
        name: "Subject",
        selector: "subjectName",
        sortable: true,
      },
      {
        name: "Description",
        selector: "description",
      },
      {
        name: "Resources",
        button: true,
        cell: (row) => (
          <>
            <button
              className="tt-button tt-button-primary"
              onClick={() => this.handleAddResource(row)}
            >
              Add Resources
            </button>
            <br />
            <button
              className="tt-button tt-button-primary"
              onClick={() => this.handleViewResource(row)}
            >
              View Resources
            </button>
            <br />

            {row.schoolId && (
              <>
                <button
                  className="tt-button tt-button-primary"
                  onClick={() => this.handleUpdateClick(row)}
                >
                  Update
                </button>
                <br />

                <button
                  className="tt-button tt-button-danger"
                  onClick={() => this.handleDeleteClick(row)}
                >
                  Delete
                </button>
              </>
            )}
          </>
        ),
      },
    ];

    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            School Learning Management System
          </div>
          {/* 
          <DataTable
            // title="School LMS List"
            columns={dataColumns}
            data={this.state.lmsList}
          /> */}

          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th>Title</th>
                <th>Year Level</th>
                <th>Subject</th>
                <th>Description</th>
                <th>Resources</th>
              </tr>
            </thead>
            <tbody>
              {this.state.lmsList.length > 0 ? (
                this.state.lmsList.map((list) => (
                  <tr>
                    <td>{list.title}</td>
                    <td>{list.yearLevelName}</td>
                    <td>{list.subjectName || "--"}</td>
                    <td>{list.description}</td>
                    <td>
                      <button
                        className="tt-button tt-button-primary"
                        onClick={() => this.handleAddResource(list)}
                      >
                        Add Resources
                      </button>
                      <button
                        className="tt-button tt-button-primary"
                        onClick={() => this.handleViewResource(list)}
                      >
                        View Resources
                      </button>

                      {list.schoolId && (
                        <>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() => this.handleUpdateClick(list)}
                          >
                            Update
                          </button>
                          <button
                            className="tt-button tt-button-danger"
                            onClick={() => this.handleDeleteClick(list)}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={5}>
                    {this.state.getSpinner ? (
                      <Spinner></Spinner>
                    ) : (
                      "No records available!!"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Pagination
              itemsCount={this.state.totalRecords}
              pageSize={this.state.pageSize}
              currentPage={this.state.currentPage}
              onPageChange={this.handlePageChange}
              nextPage={this.handleNextPage}
              previousPage={this.handlePreviousPage}
            ></Pagination>
          </div>
        </div>

        <ModalWindow
          modal={this.state.fileViewModal}
          modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          modalHeader="Resource Files"
          modalBody={
            <ViewLMSFile
              activeLMSFile={this.state.activeLMSFile}
              canDeleteResources={this.state.activeLMSFile.schoolId}
              getSchoolLMS={this.getSchoolLMS}
            />
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.addResourceModal}
          toggleModal={this.toggleAddResource}
          modalHeader="Resource Files"
          size="lg"
          modalBody={
            <>
              {this.state.spinner ? (
                <div className="fullWindow-Spinner">
                  <div>
                    <Spinner color="white"></Spinner>
                  </div>
                  <div style={{ fontSize: "16px", marginTop: "15px" }}>
                    Please wait...
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-3">
                  <strong>Files: </strong>
                </div>
                <div className="col-md-9">
                  <input
                    type="file"
                    name="addedResource"
                    className="form-control"
                    onChange={this.handleChange}
                    multiple
                    style={{ padding: "2px" }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <strong>Links: </strong>
                </div>
                <div className="col-md-9">
                  {this.state.links.map((lnk, idx) => (
                    <div className="row mb-2">
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="name"
                          onChange={(e) => this.handleLinkChange(e, idx)}
                          className="form-control"
                          value={lnk.name}
                        />
                      </div>
                      <div className="col-md-2">
                        {idx > 0 ? (
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="removeMcqOption mr-2"
                            onClick={() => this.removeLinkRow(idx)}
                          />
                        ) : null}
                        {idx === this.state.links.length - 1 ? (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="addMcqOption"
                            onClick={() => this.addLinkRow()}
                          />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button
                className="tt-button tt-button-primary float-right mt-2"
                onClick={this.handleSubmitResources}
              >
                <div>Save</div>
              </button>
            </>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.editMode}
          toggleModal={this.toggleUpdateModal}
          modalHeader="Update Data"
          modalBody={
            <>
              {this.state.spinner ? (
                <div className="fullWindow-Spinner">
                  <div>
                    <Spinner color="white"></Spinner>
                  </div>
                  <div style={{ fontSize: "16px", marginTop: "15px" }}>
                    Please wait...
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-3">
                  <strong>
                    Title:{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.title}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <strong>Description: </strong>
                </div>
                <div className="col-md-9">
                  <textarea
                    name="description"
                    cols="5"
                    rows="5"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.description}
                  ></textarea>
                </div>
              </div>
              <button
                className="tt-button tt-button-primary float-right mt-2"
                onClick={this.handleSubmitUpdate}
              >
                <div>Update</div>
              </button>
            </>
          }
        ></ModalWindow>
      </>
    );
  }
}

export default ListSchoolLMS;
