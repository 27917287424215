import React, { Component } from "react";
import CentralDiscussionPostForm from "./CentralDiscussionPostForm";

class DiscussionDashboardForm extends Component {
  render() {
    return (
      <>
        <CentralDiscussionPostForm
          groupId={this.props.groupId}
          groupPost={true}
          toggleModal={this.props.toggleModal}
          getDiscussionsByGroup={this.props.getDiscussionsByGroup}
        />
      </>
    );
  }
}

export default DiscussionDashboardForm;
