import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/Utils";

class CategoryForm extends React.Component {
  state = {
    categories: [
      {
        category: "",
        isInventoryCategory: this.props.isInventory,
      },
    ],
  };

  addCategory = () => {
    this.setState({
      categories: [
        ...this.state.categories,
        {
          category: "",
          isInventoryCategory: this.props.isInventory,
        },
      ],
    });
  };

  deleteCategory = (idx) => {
    let categories = [...this.state.categories];
    categories.splice(idx, 1);
    this.setState({ categories });
  };

  insertCategory = () => {
    if (this.state.categories.every((c) => c.category !== "")) {
      axiosPost(
        URL.insertFeeCategory,
        this.state.categories,
        (response) => {
          if (response.status === 200) {
            if (this.props.isInventory) {
              swal("Success", "Inventory categories added", "");
            } else {
              swal("Success", "Fee categories added", "");
            }

            this.props.toggleModal();
            this.props.getCategories();
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    } else {
      swal("Error", "Category name cannot be empty");
    }
  };

  handleChange = (e, idx) => {
    const { value } = e.target;
    let categories = [...this.state.categories];
    categories[idx].category = value;
    this.setState({ categories });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.categories.map((cat, idx) => (
          <div className="row mt-2">
            <div className="col-md-3 mt-2 text-center">
              <strong>
                Label:
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                name="category"
                value={cat.category}
                onChange={(e) => this.handleChange(e, idx)}
              />
            </div>
            <div className="col-md-3">
              {idx > 0 && (
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  onClick={() => this.deleteCategory(idx)}
                  className="removeMcqOption"
                />
              )}
              {idx === this.state.categories.length - 1 && (
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  onClick={this.addCategory}
                  className="addMcqOption ml-3"
                />
              )}
            </div>
          </div>
        ))}
        <div className="row mt-2">
          <div className="col">
            <button
              className="tt-button tt-button-primary float-right"
              onClick={this.insertCategory}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryForm;
