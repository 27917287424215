import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { questionType, URL } from "../../utils/Constants";
import AssessmenttQuestions from "./AssessmenttQuestions";
import swal from "sweetalert";

class SystemExamAssessment extends Component {
  state = {
    educationLevels: [],
    educationLevelCode: "",
    educationLevelId: "",
    yearLevels: [],
    yearLevelId: "",
    classRooms: [],
    classRoomId: "",
    educationLevelDivisions: [],
    educationLevelDivisionId: "",
    assessments: [],
    assessmentId: "",
    assessmentSubjects: [],
    subjectId: "",
    assessments: [],
    assessmentId: "",
    subjectQuestions: [],
    theoryQuestions: [],
    practicalQuestions: [],
    students: [],
    peopleId: "",
    assessmentFor: null,
    spinner: false,
  };

  claerState = () => {
    this.setState();
  };

  componentDidMount() {
    this.getEducationLevel();
  }

  getEducationLevel = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ educationLevels: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (
        this.state.yearLevelId !== "" &&
        this.state.educationLevelId !== "" &&
        this.state.classRoomId !== "" &&
        this.state.educationLevelDivisionId !== "" &&
        this.state.assessmentId !== ""
      ) {
        this.getAssessmentSubjects();
      }

      if (name === "educationLevelId") {
        this.getYearLevelByEducationLevel(value);
        const educationLevel = this.state.educationLevels?.find(
          (e) => e.id === parseInt(value)
        );
        this.setState({
          assessmentModuleCode: educationLevel
            ? educationLevel.assessmentModuleCode
            : null,
          educationLevelDivisions: educationLevel
            ? educationLevel.educationLevelDivisions
            : [],
          yearLevelId: "",
          classRoomId: "",
          assessmentId: "",
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
        educationLevel &&
          this.getAssessments(educationLevel.assessmentModuleCode);
      }
      if (name === "educationLevelDivisionId") {
        this.setState({
          yearLevelId: "",
          classRoomId: "",
          assessmentId: "",
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
      }
      if (name === "yearLevelId") {
        const yearLevel = this.state.yearLevels.find(
          (y) => y.id === parseInt(value)
        );
        this.setState({
          classRooms: yearLevel ? yearLevel.classrooms : [],
          classRoomId: "",
          assessmentId: "",
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
      }
      if (name === "classRoomId") {
        if (this.state.yearLevelId !== "" && this.state.classRoomId !== "") {
          this.getStudentList();
        }
        this.setState({
          assessmentId: "",
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
      }
      if (name === "assessmentId") {
        this.setState({
          assessmentSubjects: [],
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
        if (
          this.state.yearLevelId !== "" &&
          this.state.educationLevelId !== "" &&
          this.state.classRoomId !== "" &&
          this.state.educationLevelDivisionId !== "" &&
          this.state.assessmentId !== ""
        ) {
          this.getAssessmentSubjects();
        }
      }
      if (name === "subjectId") {
        this.getSubjectQuestions();
      }
      if (name === "peopleId") {
        const people = this.state.studentList.find(
          (s) => s.people && s.people.id === parseInt(this.state.peopleId)
        );
        this.setState({ assessmentFor: people });
        this.getQuestionAnswerByPeople();
      }
    });
  };

  getStudentList = () => {
    let param = {
      yearLevelId: this.state.yearLevelId,
      classroomId: this.state.classRoomId,
    };
    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status == 200) {
        let list = response.data.data;
        this.setState({ studentList: list });
      }
    });
  };

  getSubjectQuestions = () => {
    const data = {
      educationLevelId: this.state.educationLevelId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      yearLevelId: this.state.yearLevelId,
      classroomId: this.state.classRoomId,
      subjectId: this.state.subjectId,
      assessmentId: this.state.assessmentId,
    };
    axiosPost(
      URL.selectSubjectQuestion,
      data,
      (response) => {
        const questions = response.data.data;
        const theoryQuestions =
          questions && questions.filter((q) => q.isTheory);
        const practicalQuestions =
          questions && questions.filter((q) => !q.isTheory);
        this.setState({ theoryQuestions, practicalQuestions });
      },
      (err) => {}
    );
  };

  getYearLevelByEducationLevel = (value) => {
    axiosPost(
      URL.getYearLevelByEducationLevel,
      { educationLevelId: value },
      (response) => {
        if (response.status === 200) {
          this.setState({ yearLevels: response.data.data });
        }
      },
      (error) => {}
    );
  };

  getAssessments = (code) => {
    const data = {
      assessmentModuleCode: code,
    };
    axiosPost(URL.selectAssessments, data, (resp) => {
      if (resp.status === 200) {
        let assessments = resp.data.data;
        this.setState({ assessments });
      }
    });
  };

  getAssessmentSubjects = () => {
    const data = {
      yearLevelId: this.state.yearLevelId,
      educationLevelId: this.state.educationLevelId,
      classroomId: this.state.classRoomId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      assessmentId: this.state.assessmentId,
    };

    axiosPost(
      URL.selectSystemExamSubjects,
      data,
      (response) => {
        if (response.status === 200) {
          let assessmentSubjects = response.data.data;
          this.setState({ assessmentSubjects });
        }
      },
      (err) => {}
    );
  };

  getQuestionAnswerByPeople = () => {
    const data = {
      yearLevelId: this.state.yearLevelId,
      educationLevelId: this.state.educationLevelId,
      classroomId: this.state.classRoomId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      subjectId: this.state.subjectId,
      assessmentId: this.state.assessmentId,
      answerBy: this.state.peopleId,
    };
    this.setState(
      { theoryQuestions: [], practicalQuestions: [], spinner: true },
      () => {
        axiosPost(
          URL.selectQuestionAnswerByPeople,
          data,
          (response) => {
            if (response.status === 200) {
              const questions = response.data.data;
              questions.forEach((q) => {
                if (q.questionType === questionType.OBJECTIVE && q.answer) {
                  const trueOption = q.subjectQuestionOptions.find(
                    (opt) => opt.isCorrect
                  );
                  if (
                    trueOption &&
                    q.answer.subjectQuestionOptionId === trueOption.id
                  ) {
                    q.obtainedMarks = q.markWeight;
                  } else {
                    q.obtainedMarks = "";
                  }
                } else {
                  q.obtainedMarks = "";
                }
                q.remarks = "";
              });
              const theoryQuestions =
                questions && questions.filter((q) => q.isTheory);
              const practicalQuestions =
                questions && questions.filter((q) => !q.isTheory);
              this.setState({
                theoryQuestions,
                practicalQuestions,
                spinner: false,
              });
            } else {
              this.setState({
                theoryQuestions: [],
                practicalQuestions: [],
                spinner: false,
              });
            }
          },
          (err) => {
            this.setState({
              theoryQuestions: [],
              practicalQuestions: [],
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleMarksChange = (e, stateName, index) => {
    const { name, value, type, checked } = e.target;
    const ans = type === "checkbox" ? checked : value;
    let questions = [...this.state[stateName]];
    questions[index][name] = ans;
    this.setState({ [stateName]: questions });
  };

  handleSubmit = () => {
    const answers = [
      ...this.state.theoryQuestions,
      ...this.state.practicalQuestions,
    ];

    const ans = answers.every(
      (a) => a.obtainedMarks != null && a.obtainedMarks !== ""
    );

    if (ans) {
      const data = answers.map((a) => {
        return {
          id: a.answer.id,
          obtainedMarks: a.obtainedMarks,
          remarks: a.remarks,
        };
      });

      axiosPost(
        URL.checkAnswerForSystemExam,
        data,
        (response) => {
          if (response.status === 200) {
            swal(
              "Success",
              `Assessment of ${this.state.assessmentFor?.people?.name} completed successfully.`
            );
            this.setState({
              peopleId: "",
              assessmentFor: null,
              theoryQuestions: [],
              practicalQuestions: [],
            });
          }
        },
        (err) => {}
      );
    } else {
      swal("Warning", "You have not given marks for some answers.");
    }
  };

  render() {
    const {
      educationLevels,
      educationLevelDivisions,
      classRooms,
      yearLevels,
      assessments,
      assessmentSubjects,
      studentList,
    } = this.state;

    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "99.5vh" }}>
        <div className="tt-group-header">System Examination</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 text-right mt-1">
              <strong>Education Level: </strong>
              <select
                className="form-control"
                name="educationLevelId"
                value={this.state.educationLevelId}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  Select Education Level
                </option>
                {educationLevels.map((el, idx) => {
                  return <option value={el.id}>{el.name}</option>;
                })}
              </select>
            </div>
            <div className="col-md-2 text-right mt-1">
              <strong>Education Level Division: </strong>
              <select
                className="form-control"
                name="educationLevelDivisionId"
                value={this.state.educationLevelDivisionId}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  Select education level divisions
                </option>
                {educationLevelDivisions?.map((edv, idx) => {
                  return <option value={edv.id}>{edv.name}</option>;
                })}
              </select>
            </div>

            <div className="col-md-2 text-right mt-1">
              <strong>Year Level: </strong>
              <select
                className="form-control"
                name="yearLevelId"
                value={this.state.yearLevelId}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  Select year level
                </option>
                {yearLevels.map((y, idx) => {
                  return <option value={y.id}>{y.name}</option>;
                })}
              </select>
            </div>
            <div className="col-md-2 text-right mt-1">
              <strong>CLassroom: </strong>
              <select
                className="form-control"
                name="classRoomId"
                value={this.state.classRoomId}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  Select classroom
                </option>
                {classRooms.map((c, idx) => {
                  return <option value={c.id}>{c.name}</option>;
                })}
              </select>
            </div>

            <div className="col-md-2 text-right mt-1">
              <strong>Assessment: </strong>
              <select
                className="form-control"
                name="assessmentId"
                value={this.state.assessmentId}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  Select assessment
                </option>
                {assessments.map((a, idx) => {
                  return <option value={a.id}>{a.assessmentName}</option>;
                })}
              </select>
            </div>
            <div className="col-md-2 text-right mt-1">
              <strong>Subject: </strong>
              <select
                className="form-control"
                name="subjectId"
                value={this.state.subjectId}
                onChange={this.handleChange}
                disabled={this.state.assessmentId === "" ? true : false}
              >
                <option value="" disabled>
                  Select subjects
                </option>
                {assessmentSubjects?.map((sl, idx) => {
                  return <option value={sl.subjectId}>{sl.subjectName}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10"></div>
            <div className="col-md-2">
              <strong>Student: </strong>
              <select
                className="form-control"
                name="peopleId"
                value={this.state.peopleId}
                onChange={this.handleChange}
                disabled={this.state.subjectId === "" ? true : false}
              >
                <option value="" disabled>
                  Select student
                </option>
                {studentList?.map((sl, idx) => {
                  return (
                    <option value={sl.people?.id}>{sl?.people?.name}</option>
                  );
                })}
              </select>
            </div>
          </div>
          {this.state.assessmentFor && (
            <div className="row mt-2">
              <div className="col">
                <div className="tt-group-header">
                  Assessment for: {this.state.assessmentFor.people.name}
                </div>
              </div>
            </div>
          )}
          <div className="row mt-2">
            <div className="col">
              <AssessmenttQuestions
                theoryQuestions={this.state.theoryQuestions}
                practicalQuestions={this.state.practicalQuestions}
                handleMarksChange={this.handleMarksChange}
              />
            </div>
          </div>
          {this.state.assessmentFor && (
            <div className="row mb-2">
              <div className="col">
                <button
                  className="tt-button tt-button-primary float-right"
                  onClick={this.handleSubmit}
                  id="addQuestion"
                >
                  Submit Assessment [{this.state.assessmentFor?.people?.name}]
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SystemExamAssessment;
