import React from "react";
import moment from "moment";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";

function DashboardCalendarEventLists(props) {
  return (
    <div
      className="whiteContainer"
      style={{ height: "250px", overflowY: "auto", marginBottom: "30px" }}
    >
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <p className="contentTitle mb-3">Upcoming Events</p>
        <Link to="/dashboard/calendar_events">
          <p className="contentBtn mb-3">View All</p>
        </Link>
      </div>
      <table className="table cusTable">
        <tbody>
          {props.calendarData && props.calendarData.length > 0 ? (
            props.calendarData.map((evt, idx) => {
              if (idx < 6) {
                return (
                  <tr key={idx}>
                    <th width="30%">
                      {evt.start == evt.end
                        ? moment(evt.start).format("ll")
                        : `${moment(evt.start).format("ll")} - ${moment(
                            evt.end
                          ).format("ll")}`}
                    </th>
                    <td>{evt.title}</td>
                  </tr>
                );
              }
            })
          ) : (
            <tr>
              <td colSpan={2} className="text-center">
                {props.calendarSpinner ? (
                  <Spinner></Spinner>
                ) : (
                  "No events added"
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default DashboardCalendarEventLists;
