import React from "react";
import history from "../../utils/History";

function PrivateRouteMaster({ children }) {
  const auth = useAuth();
  return auth ? children : history.push("/login");
}

function useAuth() {
  let token = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
}

export default PrivateRouteMaster;
