import React, { Component } from "react";
import { getAdmissionFormConfig } from "../../../ducks/AdmissionDucks";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import {
  URL,
  peopleCode,
  addressCode,
  draggable,
} from "../../../utils/Constants";
import AddressCluster from "../AddressCluster";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import IdentityCluster from "../IdentityCluster";

class StudentContactForm extends Component {
  state = {
    studentContactTableFieldGroups: [],
    errors: {
      yearLevel: false,
      academicLevel: false,
      admissionTableFieldGroups: {},
      studentContactTableFieldGroups: [{}],
      customFields: [],
      addressCustomFields: [],
    },
  };
  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree) and it fetches all the Admission Configuration
   * from the database.
   *
   */
  componentDidMount() {
    this.props.getAdmissionFormConfig();
    axiosPost(
      URL.getIdentityCluster,
      {
        isConfig: false,
      },
      (response) => {
        if (response.status === 200) {
          let data = response.data.data;
          this.setState({ customFields: data });
        }
      }
    );
    axiosPost(
      URL.getAddressCluster,
      {
        isConfig: false,
      },
      (response) => {
        if (response.status === 200) {
          let data = response.data.data;
          this.setState({ addressCustomFields: data });
        }
      }
    );
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      studentContactTableFieldGroups: nextProps.studentContactTableFieldGroups,
    };
  }

  /**
   * This methods binds the input fields with its respective state, say value
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let errors = { ...this.state.errors };
    if (value.length === 0) {
      errors[name] = true;
    } else {
      errors[name] = false;
    }
    this.setState({ errors }, () => {
      this.setState({
        [name]: value,
      });
    });
  };
  /**
   * @author Suyog Manandhar
   *
   * This method handles Change for nested JSON Object i.e. admissionTableFieldGroups
   * @param e is the event
   * @param gIdx is the index of the corresponding input field
   * @param stateName is the name of the state input field binded with
   * @param fIdx is the index of the 2nd dimension JSON Object i.e. customTableFields
   */
  handleNestedChange = (e, gIdx, stateName, fIdx) => {
    let newState = [...this.state[stateName]];
    let errors = { ...this.state.errors };
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (
      name === "Is Legal Guardian" ||
      name === "Relationship" ||
      name === "emailAddress"
    ) {
      newState[gIdx][name] = value;
    } else {
      if (!value.match(this.state.regEx)) {
        swal({
          title: "Warning",
          text: "Character not allowed",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        newState[gIdx].customTableFields[fIdx][name] = value;
      }
    }
    if (e.target.value.length > 0) {
      if (stateName === "admissionTableFieldGroups")
        //errors.studentContactTableFieldGroups[name] = false;
        errors.admissionTableFieldGroups[
          newState[gIdx].customTableFields[fIdx].fieldName
        ] = false;
      else errors.studentContactTableFieldGroups[gIdx][name] = false;
    } else {
      if (stateName === "admissionTableFieldGroups")
        //errors.studentContactTableFieldGroups[name] = true;
        errors.admissionTableFieldGroups[
          newState[gIdx].customTableFields[fIdx].fieldName
        ] = true;
      else errors.studentContactTableFieldGroups[gIdx][name] = true;
    }

    this.setState({
      [stateName]: newState,
      errors,
    });
  };
  /**
   * This handles change in the Identity Cluster Fields
   * @param  e is the synthetic event
   * @param idx is the corresponding index of the mapped value
   */
  handleCustomFieldChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName,
    fields
  ) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let newState = [...this.state[stateName]];
    let errors = { ...this.state.errors };
    newState[studentFieldIndex].customTableFields[customTableFieldIndex][name] =
      value;
    if (name === "country") {
      const data = {
        countryId: e.target.value,
      };
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "firstLevelDisplayName"
      ] = "";
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "secondLevelDisplayName"
      ] = "";
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "thirdLevelDisplayName"
      ] = "";
      axiosPost(URL.getCountryFirstLevel, data, (response) => {
        this.setState({
          countryFirstLevel: response.data.data,
          countrySecondLevel: [],
          countryThirdLevel: [],
        });
      });
    }
    if (name === "firstLevelDisplayName") {
      const data = {
        countryId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
            "country"
          ],
        countryFirstLevelId: e.target.value,
      };
      axiosPost(URL.getCountrySecondLevel, data, (response) => {
        this.setState({ countrySecondLevel: response.data.data });
      });
    }
    if (name === "secondLevelDisplayName") {
      const data = {
        countryId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
            "country"
          ],
        countryFirstLevelId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
            "firstLevelDisplayName"
          ],
        countrySecondLevelId: e.target.value,
      };
      axiosPost(URL.getCountryThirdLevel, data, (response) => {
        this.setState({ countryThirdLevel: response.data.data });
      });
    }
    if ((fields && fields.mandatory) || name === "gender") {
      if (value.length > 0) {
        if (stateName === "admissionTableFieldGroups")
          errors[stateName][name] = false;
        else errors[stateName][studentFieldIndex][name] = false;
      } else {
        if (stateName === "admissionTableFieldGroups")
          errors[stateName][name] = true;
        else errors[stateName][studentFieldIndex][name] = true;
      }
    }
    this.setState({ [stateName]: newState, errors });
  };
  handleDateChange = (
    objKey,
    objValue,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    let newState = [...this.state[stateName]];
    let errors = { ...this.state.errors };
    newState[studentFieldIndex].customTableFields[customTableFieldIndex][
      objKey
    ] = objValue;
    if (objValue) {
      if (stateName === "admissionTableFieldGroups") {
        errors[stateName][objKey] = false;
      } else {
        errors[stateName][studentFieldIndex][objKey] = false;
      }
    } else {
      if (stateName === "admissionTableFieldGroups") {
        errors[stateName][objKey] = true;
      } else {
        errors[stateName][studentFieldIndex][objKey] = true;
      }
    }
    this.setState({ [stateName]: newState, errors });
  };
  handleBdayChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    let newState = [...this.state[stateName]];
    let errors = { ...this.state.errors };
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].year =
      e.target.value.substring(0, 4);
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].month =
      e.target.value.substring(5, 7).substring(0, 1) === "0"
        ? e.target.value.substring(6, 7)
        : e.target.value.substring(5, 7);
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].day =
      e.target.value.substring(8, 10).substring(0, 1) === "0"
        ? e.target.value.substring(9, 10)
        : e.target.value.substring(8, 10);
    if (e.target.value.length > 0) {
      if (stateName === "admissionTableFieldGroups") {
        errors[stateName].year = false;
        errors[stateName].month = false;
        errors[stateName].day = false;
      } else {
        errors[stateName][studentFieldIndex].year = false;
        errors[stateName][studentFieldIndex].month = false;
        errors[stateName][studentFieldIndex].day = false;
      }
    } else {
      if (stateName === "admissionTableFieldGroups") {
        errors[stateName].year = true;
        errors[stateName].month = true;
        errors[stateName].day = true;
      } else {
        errors[stateName][studentFieldIndex].year = true;
        errors[stateName][studentFieldIndex].month = true;
        errors[stateName][studentFieldIndex].day = true;
      }
    }
    this.setState({ [stateName]: newState, errors });
  };

  doSubmit = (e) => {
    e.preventDefault();
    let studentContacts = [];
    this.state.studentContactTableFieldGroups.forEach((group) => {
      let obj = {};
      let contactPeople = {};
      contactPeople.name = "";
      contactPeople.emailAddress = "";
      let studentCustomTableFieldValues = [];
      contactPeople.emailAddress = group.emailAddress;
      obj.relationship = group.Relationship;
      obj.isLegalGuardian = group["Is Legal Guardian"];
      group.customTableFields.forEach((field) => {
        if (
          field.fieldTypeCode !== peopleCode &&
          field.fieldTypeCode !== addressCode
        ) {
          studentCustomTableFieldValues.push({
            customTableFieldId: field.id,
            value: field.value,
          });
        }
        if (field.fieldTypeCode === peopleCode) {
          contactPeople.dateOfBirth = new Date(
            `${field.year}-${field.month}-${field.day}`
          );
          contactPeople.gender = field.gender;
          this.state.customFields.forEach((cField) => {
            let keys = Object.keys(field);
            keys.forEach((key) => {
              if (cField.fieldName === key) {
                contactPeople.name = contactPeople.name.concat(
                  `${field[key]} `
                );
              }
            });
          });
        }
        if (field.fieldTypeCode === addressCode) {
          this.state.addressCustomFields.forEach((adCField) => {
            let keys = Object.keys(field);
            keys.forEach((key) => {
              if (adCField.fieldName === key) {
                studentCustomTableFieldValues.push({
                  customTableFieldId: adCField.id,
                  value: field[key],
                });
              }
            });
          });
        }
        contactPeople.customTableFieldValues = studentCustomTableFieldValues;
        obj.contactPeople = contactPeople;
        obj.admissionId = this.props.admissionModalData.id;
      });
      studentContacts.push(obj);
    });

    axiosPost(URL.insertStudentContact, studentContacts[0], (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Student contact inserted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      }
    });
  };
  render() {
    return (
      <div style={{ height: "75vh", overflowX: "hidden", overflowY: "auto" }}>
        {this.state.studentContactTableFieldGroups ? (
          this.state.studentContactTableFieldGroups.map((group, gIdx) => (
            <div key={gIdx}>
              {group.headerHidden ? null : (
                <div className="tt-group-header">
                  {group.name}{" "}
                  {gIdx > 0 ? (
                    <button
                      className="tt-button tt-button-danger float-right"
                      onClick={(e) =>
                        this.handleDynamicRemoveStudentContactForm(e, gIdx)
                      }
                    >
                      <FontAwesomeIcon icon={faMinusSquare} />
                    </button>
                  ) : null}
                </div>
              )}
              {group.customTableFields
                ? group.customTableFields.map((customTableField, fIdx) => (
                    <div key={fIdx}>
                      <div className="form-group row">
                        <div className="col-md-1"></div>
                        {customTableField.fieldTypeCode === addressCode ||
                        customTableField.fieldTypeCode === peopleCode ? null : (
                          <div className="col-md-3">
                            <label htmlFor={`id${fIdx}`}>
                              <strong>
                                {customTableField.fieldName}
                                {": "}
                              </strong>
                            </label>
                          </div>
                        )}
                        <div
                          className={
                            customTableField.fieldTypeCode === addressCode ||
                            customTableField.fieldTypeCode === peopleCode
                              ? "col-md-10"
                              : "col-md-7"
                          }
                        >
                          {customTableField.fieldTypeCode === addressCode ? (
                            <AddressCluster
                              studentFieldIndex={gIdx}
                              customTableFieldIndex={fIdx}
                              customTableField={customTableField}
                              countryFirstLevel={this.state.countryFirstLevel}
                              countrySecondLevel={this.state.countrySecondLevel}
                              countryThirdLevel={this.state.countryThirdLevel}
                              stateName="studentContactTableFieldGroups"
                              handleCustomFieldChange={
                                this.handleCustomFieldChange
                              }
                              handleFetchingLevels={this.handleFetchingLevels}
                              errors={this.state.errors}
                            />
                          ) : customTableField.fieldTypeCode === peopleCode ? (
                            <IdentityCluster
                              studentFieldIndex={gIdx}
                              customTableFieldIndex={fIdx}
                              handleBdayChange={this.handleBdayChange}
                              stateName="studentContactTableFieldGroups"
                              customTableField={customTableField}
                              handleCustomFieldChange={
                                this.handleCustomFieldChange
                              }
                              handleDateChange={this.handleDateChange}
                              errors={this.state.errors}
                            />
                          ) : (
                            <>
                              {customTableField.fieldTypeName === "radio" ||
                              customTableField.fieldTypeName === "checkbox" ||
                              customTableField.fieldTypeName === "file" ? (
                                <input
                                  type={customTableField.fieldTypeName}
                                  name={customTableField.fieldName}
                                  id={`id${fIdx}`}
                                  checked={
                                    customTableField[customTableField.fieldName]
                                  }
                                  onChange={(e) =>
                                    this.handleNestedChange(
                                      e,
                                      gIdx,
                                      "studentContactTableFieldGroups",
                                      fIdx
                                    )
                                  }
                                  className={
                                    this.state.errors[
                                      customTableField.fieldName
                                    ]
                                      ? "form-control indicate-error"
                                      : customTableField.fieldTypeName ===
                                        "file"
                                      ? ""
                                      : "form-control"
                                  }
                                />
                              ) : (
                                <input
                                  type={customTableField.fieldTypeName}
                                  name={customTableField.fieldName}
                                  maxLength="255"
                                  id={`id${fIdx}`}
                                  value={customTableField.value}
                                  onChange={(e) =>
                                    this.handleNestedChange(
                                      e,
                                      gIdx,
                                      "studentContactTableFieldGroups",
                                      fIdx
                                    )
                                  }
                                  className={
                                    this.state.errors[
                                      customTableField.fieldName
                                    ]
                                      ? "form-control indicate-error"
                                      : "form-control"
                                  }
                                />
                              )}
                              {customTableField.mandatory ? <sup>*</sup> : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
              <div className="form-group row">
                <div className="col-md-1" />
                <div className="col-md-2">
                  <label>
                    <strong>Email Address</strong>
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="email"
                    name="emailAddress"
                    className={
                      this.state.errors.studentContactTableFieldGroups[gIdx]
                        ? this.state.errors.studentContactTableFieldGroups[gIdx]
                            .emailAddress
                          ? "form-control indicate-error"
                          : "form-control"
                        : "form-control"
                    }
                    onChange={(e) =>
                      this.handleNestedChange(
                        e,
                        gIdx,
                        "studentContactTableFieldGroups"
                      )
                    }
                  />
                </div>
                <div className="col-md-2" />
              </div>
              <div className="form-group row">
                <div className="col-md-1" />
                <div className="col-md-2">
                  <label>
                    <strong>Is Legal Guardian</strong>
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="checkbox"
                    name="Is Legal Guardian"
                    onChange={(e) =>
                      this.handleNestedChange(
                        e,
                        gIdx,
                        "studentContactTableFieldGroups"
                      )
                    }
                  />
                </div>
                <div className="col-md-2" />
              </div>
              <div className="form-group row">
                <div className="col-md-1" />
                <div className="col-md-2">
                  <label>
                    <strong>Relationship</strong>
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    name="Relationship"
                    onChange={(e) =>
                      this.handleNestedChange(
                        e,
                        gIdx,
                        "studentContactTableFieldGroups"
                      )
                    }
                    className={
                      this.state.errors.studentContactTableFieldGroups[gIdx]
                        ? this.state.errors.studentContactTableFieldGroups[gIdx]
                            .Relationship
                          ? "form-control indicate-error"
                          : "form-control"
                        : "form-control"
                    }
                  />
                </div>
                <div className="col-md-2" />
              </div>
            </div>
          ))
        ) : (
          <div className="loading">
            <Spinner color="primary" />
          </div>
        )}
        {this.state.studentContactTableFieldGroups ? (
          <div className="form-group row">
            <div className="col-md-1"></div>
            <div className="col-md-2"></div>
            <div className="col-md-8 text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.doSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  studentContactTableFieldGroups:
    state.admission.studentContactTableFieldGroups,
});

const mapActionToProps = {
  getAdmissionFormConfig,
};

export default connect(mapStateToProps, mapActionToProps)(StudentContactForm);
