import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";

const animatedComponents = makeAnimated();

class BillStudentBillConfig extends Component {
  render() {
    let props = this.props;
    return (
      <>
        <div className="accountConfigHolder">
          <div className="row mt-2">
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.handleInventoryModal}
              >
                Inventory
              </button>
            </div>
          </div>
          <table className="table text-center table-bordered table-striped mt-2">
            <thead className="tt-group-header">
              <tr>
                <th>Category</th>
                <th width="200px">Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.selectedFeeCategory.length > 0 ? (
                props.selectedFeeCategory.map((cat, idx) => (
                  <tr key={idx}>
                    <td>
                      {cat.category}{" "}
                      {cat.inventoryItemName
                        ? ` [ ${cat.inventoryItemName} - ( ${cat.quantity} ) ]`
                        : null}
                      {(cat.type === "general" &&
                        props.configType === "monthly" &&
                        props.fromDraft !== true) ||
                      (cat.isInventory && props.fromDraft !== true) ? (
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          value={cat?.months}
                          options={
                            this.props.configType === "monthly"
                              ? this.props.selectedMonths
                              : this.props.months
                          }
                          name="months"
                          onChange={(months) =>
                            props.handleGeneralMonthChange(idx, months)
                          }
                        />
                      ) : null}
                    </td>
                    <td>
                      <input
                        className="form-control"
                        type="number"
                        value={cat?.amount}
                        name="amount"
                        onChange={(e) => props.handleFeeAmountChange(e, idx)}
                        disabled={cat.isInventory}
                        onKeyDown={(e) => {
                          // Prevent the user from entering negative values using keyboard input
                          if (e.key === "-" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="icon-minus"
                        size="1x"
                        onClick={() => props.handleRemoveCategory(idx, cat)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>No categories available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              {/* {this.props.addNew ? (
                <input
                  type="text"
                  className="form-control"
                  name="generalNewCategoryName"
                  placeholder="Enter Category Name"
                  value={this.props.generalNewCategoryName}
                  onChange={this.props.handleChange}
                ></input>
              ) : (
                <select
                  name="generalFee"
                  value={this.props.generalFee}
                  className="form-control"
                  onChange={this.props.handleChange}
                >
                  <option value="" disabled selected>
                    Select Category
                  </option>
                  <option value="new">Add New Category</option>
                  {this.props.generalFeeCategory.map((gF, idx) => (
                    <option value={gF.id} key={idx}>
                      {gF.feeCategoryName}
                    </option>
                  ))}
                </select>
              )} */}
              <CreatableSelect
                isClearable
                options={this.props.generalFeeCategory}
                value={this.props.generalFee}
                onChange={this.props.handleGeneralFeeCatChange}
                onCreateOption={this.props.handleCreateNewCategory}
                name="generalFee"
              />
            </div>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                name="generalAmount"
                placeholder="Enter Amount"
                value={this.props.generalAmount}
                onChange={this.props.handleChange}
                onKeyDown={(e) => {
                  // Prevent the user from entering negative values using keyboard input
                  if (e.key === "-" || e.key === "-") {
                    e.preventDefault();
                  }
                }}
                // disabled={this.props.disableAmount}
              ></input>
            </div>
            <div className="col-md-4">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.addCategory}
              >
                Add
              </button>
              <button
                className="ml-3 tt-button tt-button-danger"
                onClick={this.props.cancelNewCategory}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BillStudentBillConfig;
