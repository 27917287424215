import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import { sliceEvents, createPlugin } from "@fullcalendar/core";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class MyCalendar extends Component {
  state = {
    events: [],
  };

  componentDidMount() {
    this.getEvents();
  }

  getEvents = () => {
    axiosPost(
      URL.getEvents,
      {},
      (response) => {
        if (response.status === 200) {
          let events = response.data.data;
          if (events.length > 0) {
            events.forEach((el) => {
              if (el.start !== el.end) {
                el.end = new Date(el.end).toISOString();
              }
            });
          }
          this.setState({ events });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="whiteContainer">
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                // weekends={false}
                events={this.state.events}
                displayEventTime={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyCalendar;
