//Actions
const languageLOAD = "lang/LOAD";

//reducer
export default function reducer(
  state = { lang: localStorage.getItem("lang") || "nameEn" },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case languageLOAD:
      newState.lang = action.lang;
      return newState;
    default:
      return newState;
  }
}

//Action Creators
export function loadLanguage(lang) {
  return { type: languageLOAD, lang };
}

//api call that dispatches action with function
export function setGlobalLang(lang) {
  return (dispatch) => {
    dispatch(loadLanguage(lang));
    localStorage.setItem("lang", lang);
  };
}
