import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import PhaseSemesterSubjectDisplay from "./PhaseSemesterSubjectDisplay";

class LmsModalBody extends Component {
  state = {};
  render() {
    let list = this.props.selectedList;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.6vh" }}>
        <div className="row">
          <div className="col">
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>
                    <strong>Abbreviation:</strong>
                  </td>
                  <td>{list.abbreviation}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Program Duration:</strong>
                  </td>
                  <td>
                    {list.duration}
                    {" " + (list.duration === 1 ? "month" : "months")}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Terms:</strong>
                  </td>
                  <td>{list.termName}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="tt-descriptionLMS">
          <div className="row">
            <div className="col">
              <strong>Description:</strong>
            </div>
          </div>
          <hr style={{ marginBottom: "5px", marginTop: "5px" }}></hr>
          <div className="row">
            <div className="col">
              <p>{ReactHtmlParser(list.description)}</p>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="tt-group-header">
              {this.props.selectedList.coursePhases &&
              this.props.selectedList.coursePhases.length > 0
                ? "Program Phases"
                : "Program Semesters"}
            </div>
            <div className="card" style={{ padding: "10px" }}>
              <PhaseSemesterSubjectDisplay
                course={this.props.selectedList}
                togglePhaseDetail={this.props.togglePhaseDetail}
              ></PhaseSemesterSubjectDisplay>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LmsModalBody;
