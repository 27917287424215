import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import CustomCollapse from "../UI/CustomCollapse";
import CreateMCQExplanation from "./CreateMCQExplanation";

class CreateMCQQuestion extends Component {
  state = {};
  render() {
    let mcqQuestions = this.props.mcqQuestions;
    return (
      <div className="container-fluid">
        {mcqQuestions.length > 0
          ? mcqQuestions.map((mcq, idx) => {
              return (
                <div className="row mt-2 tt-mcqQuestionBox" key={idx}>
                  <div
                    className="col-md-10 pl-0"
                    style={{ paddingBottom: "5px" }}
                  >
                    <div style={{ borderRight: "1px solid #ccc" }}>
                      <div className="row mt-2">
                        <div
                          className="col-md-3 mt-1"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span>
                            <strong>{`${idx + 1})`}&nbsp;</strong>
                          </span>
                          <strong>
                            Type your Question:{" "}
                            <span className="tt-assessment-module-mandatory">
                              *
                            </span>
                          </strong>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className={
                              this.props.central
                                ? "form-control cusInput"
                                : "form-control"
                            }
                            name="Question"
                            value={mcq.Question}
                            onChange={(e) =>
                              this.props.handleMcqQuestionChange(e, idx)
                            }
                          ></input>
                        </div>
                        <div className="col-md-2 ml-1">
                          <input
                            type="text"
                            className={
                              this.props.central
                                ? "form-control cusInput"
                                : "form-control"
                            }
                            name="marks"
                            placeholder="Enter Marks"
                            value={mcq.marks}
                            onChange={(e) =>
                              this.props.handleMcqQuestionChange(e, idx)
                            }
                          ></input>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      <div className="row mt-2">
                        <div
                          className={
                            this.props.central ? "col-md-1" : "col-md-3"
                          }
                        ></div>
                        <div
                          className={
                            this.props.central ? "col-md-9" : "col-md-7"
                          }
                        >
                          <div className="row">
                            <div className="col-md-2 text-center">
                              <strong>Correct</strong>
                            </div>
                            <div className="col-md-7 text-center">
                              <strong>Options</strong>
                            </div>
                            <div className="col-md-3 text-center">
                              <strong>Option type</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      {mcq.mcqQuestionOptions.length > 0
                        ? mcq.mcqQuestionOptions.map((opt, oIdx) => {
                            return (
                              <div className="row mt-2 mb-2" key={oIdx}>
                                <div
                                  className={
                                    this.props.central ? "col-md-1" : "col-md-3"
                                  }
                                ></div>
                                <div
                                  className={
                                    this.props.central ? "col-md-9" : "col-md-7"
                                  }
                                >
                                  <div className="row">
                                    <div className="col-md-2 text-center">
                                      <input
                                        type="checkbox"
                                        name="isCorrect"
                                        checked={opt.isCorrect}
                                        onChange={(e) =>
                                          this.props.handleMcqOptionChange(
                                            e,
                                            oIdx,
                                            idx
                                          )
                                        }
                                        style={{
                                          position: "relative",
                                          top: "8px",
                                        }}
                                      ></input>
                                    </div>
                                    <div className="col-md-7 text-center">
                                      {opt.type == "v" ? (
                                        <input
                                          type="text"
                                          className={
                                            this.props.central
                                              ? "form-control cusInput"
                                              : "form-control"
                                          }
                                          name="optionValue"
                                          value={opt.optionValue}
                                          onChange={(e) =>
                                            this.props.handleMcqOptionChange(
                                              e,
                                              oIdx,
                                              idx
                                            )
                                          }
                                        />
                                      ) : (
                                        <input
                                          type="file"
                                          name="file"
                                          onChange={(e) =>
                                            this.props.handleMcqOptionChange(
                                              e,
                                              oIdx,
                                              idx
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                    <div className="col-md-3 text-center">
                                      <select
                                        className={
                                          this.props.central
                                            ? "form-control cusInputSelect"
                                            : "form-control"
                                        }
                                        name="type"
                                        value={opt.type}
                                        onChange={(e) =>
                                          this.props.handleMcqOptionChange(
                                            e,
                                            oIdx,
                                            idx
                                          )
                                        }
                                      >
                                        <option value="" disabled>
                                          option type
                                        </option>
                                        <option value="v">value</option>
                                        <option value="r">file</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="row">
                                    <div className="col-md-1">
                                      <FontAwesomeIcon
                                        icon={faMinusCircle}
                                        className="removeMcqOption"
                                        onClick={(e) =>
                                          this.props.removeOption(e, idx, oIdx)
                                        }
                                      ></FontAwesomeIcon>
                                    </div>
                                    <div className="col-md-1">
                                      {oIdx ===
                                      mcq.mcqQuestionOptions.length - 1 ? (
                                        <FontAwesomeIcon
                                          icon={faPlusCircle}
                                          className="addMcqOption ml-3"
                                          onClick={(e) =>
                                            this.props.addOption(e, idx)
                                          }
                                        ></FontAwesomeIcon>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                      <div className="row">
                        <div className="col">
                          <div
                            className={
                              this.props.central
                                ? "searchBar"
                                : "tt-addExplanation"
                            }
                            onClick={
                              mcq.isOpen
                                ? (e) => this.props.closeExplanation(e, idx)
                                : (e) => this.props.openExplanation(e, idx)
                            }
                            style={
                              this.props.central
                                ? { marginLeft: "10px", marginRight: "10px" }
                                : null
                            }
                          >
                            <FontAwesomeIcon
                              icon={mcq.isOpen ? faMinusCircle : faPlusCircle}
                            ></FontAwesomeIcon>
                            {"  "}
                            Add Explanation
                          </div>
                          <CustomCollapse
                            isOpen={mcq.isOpen}
                            collapseClass="tt-explanationCard"
                            collapsibleBody={
                              <CreateMCQExplanation
                                closeExplanation={this.props.closeExplanation}
                                saveExplanation={this.props.saveExplanation}
                                idx={idx}
                                handleExplanation={this.props.handleExplanation}
                                explanation={mcq.explanation}
                                central={this.props.central}
                              ></CreateMCQExplanation>
                            }
                          ></CustomCollapse>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 mcqAddButtons">
                    {/* {idx === mcqQuestions.length - 1 ? (
                      <div>
                        <button
                          className="tt-button tt-button-primary tt-button-fullWidth"
                          onClick={(e) => this.props.addQuestion(e)}
                        >
                          Add Question
                        </button>
                      </div>
                    ) : null} */}
                    <div>
                      <button
                        className={
                          this.props.central
                            ? "btn btn-primary cusBtn cusBtnFontSize"
                            : "tt-button tt-button-primary tt-button-fullWidth"
                        }
                        onClick={(e) => this.props.removeQuestion(e, idx)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  }
}

export default CreateMCQQuestion;
