import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import "react-mdl/extra/material.css";
// import "react-mdl/extra/material.js";
import "bootstrap/dist/css/bootstrap.css";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/History";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/scss/app.scss";
import "./assets/scss/main.scss";
import store from "./store";

ReactDOM.render(
  <Router history={history} basename={"/"}>
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </Provider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
