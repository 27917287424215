import React, { Component } from "react";
import { URL } from "../../utils/Constants";
import flashCardDefaultPic from "../../assets/images/flashCardDefault2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import CreateNewFlashCard from "./CreateNewFlashCard";
class UpdateFlashCards extends Component {
  state = {};

  handleCardThumbError = (e) => {
    return (e.target.src = flashCardDefaultPic);
  };

  render() {
    let cardSet = this.props.selectedCard;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "86.9vh" }}>
        <div className="row">
          <div className="col">
            <div className="tt-flashCardList">
              {cardSet.flashCards.length > 0
                ? cardSet.flashCards.map((cards, idx) => {
                    return (
                      <div className="tt-flashCardListHolder" key={idx}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="tt-flashDelete"
                          onClick={() =>
                            this.props.handleDeleteFlashCard(cards.id, idx)
                          }
                        ></FontAwesomeIcon>

                        <div className="tt-flashImgHolder">
                          <img
                            src={URL.imageSelectURL + cards.imageName}
                            alt="Card Preview"
                            onError={(e) => this.handleCardThumbError(e)}
                          ></img>
                        </div>
                        <div className="tt-flashUpdateQuestion">
                          {cards.question}
                        </div>
                      </div>
                    );
                  })
                : "No cards available"}
            </div>
          </div>
        </div>

        <div className="tt-group-header">Add New Flash Cards</div>
        <div
          className="container-fluid"
          style={{ height: "49.5vh", overflow: "auto" }}
        >
          <div className="row">
            <div className="col">
              <CreateNewFlashCard
                flashCards={this.props.flashCards}
                addNew={true}
                removeFlashCard={this.props.removeFlashCard}
                removeImage={this.props.removeImage}
                handleImageChange={this.props.handleImageChange}
                handleFlashCardChange={this.props.handleFlashCardChange}
                addFlashCard={this.props.addFlashCard}
              ></CreateNewFlashCard>
            </div>
          </div>
        </div>

        <div className="row button-bg-color pr-4">
          <div className="col-12 text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.props.handleSubmit}
            >
              Submit
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.clearFlashState}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateFlashCards;
