import React, { Component } from "react";
import moment from "moment";
import CommentBankEditor from "./CommentBankEditor";
import ReactHtmlParser from "react-html-parser";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";

class CommentsBankDisplay extends Component {
  state = {};

  render() {
    return (
      <table className="table table-bordered text-center table-striped">
        <thead className="tt-group-header">
          <tr>
            {/* <th>SN</th> */}
            <th>Comments</th>
            <th>Assessment Type</th>
            <th>Sharing Options</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {this.props.commentBanks ? (
            this.props.commentBanks.map((comments, idx) => {
              return (
                <tr key={idx}>
                  {/* <td>{idx + 1}</td> */}
                  <td className="text-left" width="50%">
                    {comments.editable ? (
                      <>
                        <CommentBankEditor
                          editorRef={this.props.editEditorRef}
                          id="editCommentBank"
                          valueArr={this.props.editComment}
                          changeHandler={this.props.changeEditHandler}
                          comments={this.props.comments}
                          pronouns={this.props.pronouns}
                          clickHandler={this.props.clickEditHandler}
                        ></CommentBankEditor>
                      </>
                    ) : (
                      ReactHtmlParser(comments.commentText)
                    )}
                    <p className="tt-commentBankDate">
                      {comments.editable
                        ? ""
                        : moment(comments.createdAt).format("LL")}
                    </p>
                  </td>
                  <td>
                    {comments.editable ? (
                      <select
                        className="form-control"
                        name="editAssessmentType"
                        value={this.props.editAssessmentType}
                        onChange={this.props.handleSelectChange}
                      >
                        <option value="" disabled>
                          Choose assessment type
                        </option>
                        <option value="general">General assessment</option>
                        <optgroup label="Subject Assessment">
                          {this.props.subjectList.map((subList, idx) => {
                            return (
                              <option value={subList.id} key={idx}>
                                {subList.name}
                              </option>
                            );
                          })}
                        </optgroup>
                      </select>
                    ) : comments.isGeneralAssessment ? (
                      "General Assessment"
                    ) : (
                      comments.subjectName
                    )}
                  </td>
                  <td>
                    {comments.editable ? (
                      <select
                        className="form-control"
                        name="editSharingOption"
                        value={this.props.editSharingOption}
                        onChange={this.props.handleSelectChange}
                      >
                        <option value="" disabled>
                          Choose sharing option
                        </option>
                        <option value={true}>Public</option>
                        <option value={false}>Private</option>
                      </select>
                    ) : comments.isPublic ? (
                      "Public"
                    ) : (
                      "Private"
                    )}
                  </td>
                  <td width="20%">
                    <button
                      className="tt-button tt-button-primary"
                      onClick={(e) => {
                        this.props.handleInsertComment(
                          e,
                          comments,
                          this.props.commentId
                        );
                        this.props.handleCommentBankHandler(
                          e,
                          this.props.commentId
                        );
                      }}
                    >
                      Insert
                    </button>
                    {comments.editable ? (
                      ""
                    ) : this.props.commentTemplates === "false" ? (
                      ""
                    ) : (
                      <button
                        className="tt-button tt-button-primary"
                        onClick={(e) =>
                          this.props.commentEdit(e, idx, comments)
                        }
                      >
                        Edit
                      </button>
                    )}
                    {this.props.commentTemplates === "false" ? (
                      ""
                    ) : (
                      <button
                        className="tt-button tt-button-primary"
                        // onClick={e => this.props.commentDelete(e, comments.id)}
                        onClick={(e) =>
                          displayConfirmDeleteAlert(
                            { e, id: comments.id },
                            this.props.commentDelete
                          )
                        }
                      >
                        Delete
                      </button>
                    )}
                    {comments.editable ? (
                      <div>
                        <button
                          className="tt-button tt-button-primary"
                          onClick={(e) =>
                            this.props.updateCommentTemplate(e, comments.id)
                          }
                        >
                          Save
                        </button>
                        <button
                          className="tt-button tt-button-primary"
                          onClick={this.props.cancelEdit}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>There are no comments</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default CommentsBankDisplay;
