import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import DragDropFile from "../../UI/DragDropFile";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorMessage } from "../../utils/Utils";

class CentralReadingMaterialCreateModal extends Component {
  state = {
    title: "",
    description: "",
    resource: null,
    yearLevels: [],
    yearLevelId: "",
    subjects: [],
    subjectList: [],
    subjectId: "",
    links: [{ name: "" }],
  };

  addLinkRow = () => {
    let links = [...this.state.links];
    links.push({
      name: "",
    });
    this.setState({ links });
  };

  componentDidMount() {
    this.getYearLevels();
  }

  getSubjectList = (yearLevelId) => {
    let param = {
      yearLevelId: yearLevelId,
    };
    // axiosPost(URL.selectDistinctSubjectsByYearLevelCore, param, (response) => {
    axiosPost(URL.getAllSubject, param, (response) => {
      if (response.status === 200) {
        this.setState({ subjectList: response.data.data });
      }
    });
  };

  getYearLevels = () => {
    // axiosPost(URL.getYearLevel, {}, (response) => {
    axiosPost(URL.getAllClass, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  handleChange = (e, idx) => {
    let { name, value, files } = e.target;

    this.setState({ [name]: files ? files : value });
    if (name === "yearLevelId") {
      this.getSubjectList(value);
    }
    let filesArr = [];
    if (files !== null && files !== undefined) {
      for (const key of Object.keys(files)) {
        filesArr.push(files[key]);
      }
      this.setState({
        resource: filesArr,
      });
    }
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    this.setState({
      [name]: files,
    });

    let filesArr = [];
    if (files !== null) {
      for (const key of Object.keys(files)) {
        filesArr.push(files[key]);
      }
      this.setState({
        resource: filesArr,
      });
    }
  };

  handleLinkChange = (e, idx) => {
    let links = [...this.state.links];

    links[idx].name = e.target.value;

    this.setState({
      links: links,
    });
  };

  handleSubmit = () => {
    const {
      yearLevels,
      subjectList,
      title,
      description,
      yearLevelId,
      subjectId,
      resource,
    } = this.state;
    const { lms } = this.props;

    if (title === "") {
      swal("Error", "Title cannot be empty");
      return false;
    }

    if (yearLevelId === "") {
      swal("Error", "Please select year level");
      return false;
    }

    if (lms !== "sign-language" && lms !== "av-resource" && resource === null) {
      swal("Error", "Please add resource");
      return false;
    }

    if (subjectId === "") {
      swal("Error", "Please select subject");
      return false;
    }

    let formData = new FormData();

    let newLinks = [];
    let linkCount = 0;
    newLinks = this.state.links.map((lnk) => {
      if (lnk.name !== "") {
        linkCount = linkCount + 1;
        return lnk.name;
      }
    });

    if (
      (lms === "sign-language" || lms === "av-resource") &&
      resource === null &&
      linkCount < 1
    ) {
      swal("Error", "Please add links if not resource.");
      return false;
    }

    let jsonData = {
      title: title,
      description: description,
      subjectId: subjectId,
      yearlevelId: yearLevelId,
      links: linkCount > 0 ? newLinks : [],
      schoolLmsTypeId:
        lms === "curriculum"
          ? "1"
          : lms === "textbooks"
          ? "2"
          : lms === "question-bank"
          ? "3"
          : lms === "av-resource"
          ? "4"
          : lms === "sign-language"
          ? "5"
          : "",
    };

    if (resource !== null) {
      for (const key of Object.keys(resource)) {
        formData.append("contents", resource[key]);
      }
    }

    formData.append("jsonData", JSON.stringify(jsonData));

    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(
          URL.insertSchoolLMS,
          formData,
          (response) => {
            this.setState({
              spinner: false,
            });
            if (response.status === 200) {
              swal("Success", response.data.message);
              this.resetState();
              this.props.getReadingMaterials();
              this.props.toggleReadingModal();
            }
          },
          (err) => {
            displayErrorMessage(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  removeLinkRow = (idx) => {
    let links = [...this.state.links];

    links.splice(idx, 1);
    this.setState({ links });
  };

  resetState = () => {
    this.setState({
      title: "",
      description: "",
      resource: "",
      yearLevelId: "",
      subjectList: [],
      subjectId: "",

      links: [{ name: "" }],
    });
  };

  render() {
    const {
      yearLevels,
      subjectList,
      title,
      description,
      yearLevelId,
      subjectId,
    } = this.state;
    return (
      <div className="container">
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="row mt-3">
          <div className="col-md-3">
            <strong>
              Title: <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col">
            <input
              type="text"
              name="title"
              className="form-control cusInput"
              onChange={this.handleChange}
              value={title}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <strong>
              Year Level:{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col">
            <select
              name="yearLevelId"
              onChange={this.handleChange}
              className="form-control cusInputSelect"
              value={yearLevelId}
            >
              <option value="" disabled selected>
                Select year level
              </option>
              {yearLevels.map((y) => (
                <option value={y.id}>{y.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <strong>Subject: </strong>
            <span className="tt-assessment-module-mandatory">*</span>
          </div>
          <div className="col">
            <select
              name="subjectId"
              onChange={this.handleChange}
              className="form-control cusInputSelect"
              value={subjectId}
            >
              <option value="" disabled selected>
                Select subject
              </option>
              {subjectList.map((s) => (
                <option value={s.id}>{s.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <strong>Links: </strong>
          </div>
          <div className="col">
            {this.state.links.map((lnk, idx) => (
              <div className="row mb-2">
                <div className="col-md-10">
                  <input
                    type="text"
                    name="name"
                    onChange={(e) => this.handleLinkChange(e, idx)}
                    className="form-control cusInput"
                    value={lnk.name}
                  />
                </div>
                <div className="col-md-2">
                  {idx > 0 ? (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      className="removeMcqOption mr-2"
                      onClick={() => this.removeLinkRow(idx)}
                    />
                  ) : null}
                  {idx === this.state.links.length - 1 ? (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="addMcqOption"
                      onClick={() => this.addLinkRow()}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <strong>
              Resource:{" "}
              <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col">
            <DragDropFile
              multiple={true}
              handleChange={this.handleChange}
              name="resource"
              handleFileDrop={this.handleFileDrop}
              files={this.state.resource}
              // accept="image/png, image/jpeg, image/jpg"
            />
            {/* <input
              type="file"
              name="resource"
              onChange={this.handleChange}
              multiple
              style={{ padding: "2px" }}
            /> */}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <strong>Description: </strong>
          </div>
          <div className="col">
            <textarea
              name="description"
              cols="5"
              rows="5"
              className="form-control"
              onChange={this.handleChange}
              value={description}
            ></textarea>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-right">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CentralReadingMaterialCreateModal;
