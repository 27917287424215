import React, { Component } from "react";
import ModalWindow from "../../UI/ModalWindow";
import CourseSyllabusDetailModal from "./CourseSyllabusDetailModal";
// import CourseSyllabusUpdateModal from "./CourseSyllabusUpdateModal";
import CourseSyllabusResource from "./CourseSyllabusResource";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import CourseClassResources from "./CourseClassResource";
import swal from "sweetalert";
import FileModal from "../../UI/FileModal";
import CreateCourseSyallabus from "./CreateCourseSyallabus";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
} from "../../utils/DisplayMessage";
import { Spinner } from "reactstrap";
import { faHeartPulse } from "@fortawesome/free-solid-svg-icons";
import SubjectTopicSetting from "./SubjectTopicSetting";

export class ListCourseSyallabus extends Component {
  state = {
    activeData: "",
    courseSyllabus: [],
    courseSpinner: false,

    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: "",

    classrooms: [],
    selectedClassrooms: "",

    subjects: [],
    selectedSubjects: "",

    syllabusDetailModal: false,
    topicList: [],

    updateSyllabusDetailModal: false,
    updateTopicName: "",
    updateDescription: "",

    syllabusResourcesModal: false,
    fileModal: false,
    selectedSubjectResources: "",

    coursePlanId: "",
    coursePlanSyllabusId: "",

    classes: [],
    selectedClass: "",
    selectedEducationLevel: "",
    selectedSubject: "",
    modal: false,
    addSyllabusModal: false,
    coursePlanIdForAdd: "",
  };

  componentDidMount() {
    this.getEducationLevels();
    this.getAllClasses();
    this.getAllSubjects();
    this.getCourseSyllabus();
  }
  getEducationLevels = () => {
    axiosPost(URL.getAllEducationLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          educationLevels: response.data.data,
        });
      }
    });
  };

  getAllClasses = () => {
    axiosPost(URL.getAllClass, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          classes: response.data.data,
        });
      }
    });
  };

  getAllSubjects = () => {
    axiosPost(URL.getAllSubject, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          subjects: response.data.data,
        });
      }
    });
  };

  getCourseSyllabus = (coursePlanId) => {
    let data = {
      classId: this.state.selectedClass,
      subjectId: this.state.selectedSubject,
      type: this.props.displayType,
    };
    this.setState(
      {
        courseSyllabus: [],
        topicList: [],
        courseSpinner: true,
      },
      () => {
        axiosPost(
          URL.getAllCoursePlan,
          data,
          (response) => {
            this.setState({ courseSpinner: false });
            if (response.status === 200) {
              const cs = response.data.data;
              const tl = cs ? cs.find((cs) => cs.id === coursePlanId) : null;
              this.setState({
                courseSyllabus: response.data.data,
                // courseSpinner: faHeartPulse,

                topicList: tl ? tl.coursePlanSyllabus : this.state.topicList,
              });
            }
          },
          (err) => {
            this.setState({ courseSpinner: false });
            displayErrorAlert(err);
          }
        );
      }
    );
  };
  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.getCourseSyllabus();
      }
    );
  };

  handleChangeUpdateData = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSyllabusDetailModal = (sub) => {
    this.setState({ topicList: null }, () => {
      this.setState({
        activeData: sub,
        topicList: sub.coursePlanSyllabus,
        syllabusDetailModal: !this.state.syllabusDetailModal,
      });
    });
  };

  toggleSyllabusDetailModal = () => {
    this.setState({ syllabusDetailModal: !this.state.syllabusDetailModal });
  };

  handleUpdateSyllabusDetail = (data) => {
    this.setState({
      updateTopicName: data.name,
      updateDescription: data.description,
      id: data.id,
      updateSyllabusDetailModal: !this.state.updateSyllabusDetailModal,
    });
  };

  // delete whole course plan syllabus
  handleSyllabusPlanDelete = (id) => {
    displayConfirmDeleteAlert(id, this.deleteSyllabusPlan);
  };

  deleteSyllabusPlan = (id) => {
    const data = {
      id: id,
    };
    axiosPost(URL.deleteCoursePlan, data, (response) => {
      if (response.data.status === 200) {
        swal({
          title: "Success",
          text: "Successfully Deleted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        this.getCourseSyllabus();
      }
    });
  };

  // delete single syllabus detail
  handleSyllabusDetailDelete = (id) => {
    displayConfirmDeleteAlert(id, this.deleteSyllabusDetail);
  };

  deleteSyllabusDetail = (id) => {
    const data = {
      id: id,
    };
    axiosPost(URL.deleteCoursePlanSyllabusDetailCore, data, (response) => {
      if (response.data.status === 200) {
        swal({
          title: "Success",
          text: "Successfully Deleted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        this.getCourseSyllabus(this.state.activeData.id);
        // this.setState({
        //   syllabusDetailModal: !this.state.syllabusDetailModal,
        // });
      }
    });
  };

  // tigger subject resource modal
  subjectResourceModal = (param) => {
    this.setState({ coursePlanId: param }, this.toggleSyllabusResourcesModal());
  };

  // View subject resource modal
  viewSubjectResourceModal = (id) => {
    const data = {
      coursePlanId: id,
    };
    axiosPost(URL.getCoursePlanResourcesCore, data, (response) => {
      if (response.data.status === 200) {
        this.setState(
          {
            selectedSubjectResources: response.data.data,
          },
          () => {
            this.toggleFileModal();
          }
        );
      }
    });
  };

  //tigger syllabus wise resource modal
  syllabusResourceModal = (param) => {
    this.setState(
      {
        coursePlanSyllabusId: param.id,
        coursePlanId: param.coursePlanId,
      },
      () => {
        this.toggleSyllabusResourcesModal();
      }
    );
  };

  //toggle resources modal
  toggleSyllabusResourcesModal = () => {
    this.setState({
      syllabusResourcesModal: !this.state.syllabusResourcesModal,
    });
  };

  toggleSyllabusResourcesModalView = (param) => {
    let data = {
      coursePlanSyllabusId: param.id,
      coursePlanId: param.coursePlanId,
    };
    axiosPost(URL.getCoursePlanResourcesCore, data, (res) => {
      if (res.status == 200) {
        this.setState({ selectedSubjectResources: res.data.data }, () => {
          this.toggleFileModal();
        });
      }
    });
  };

  toggleFileModal = () => {
    this.setState({
      fileModal: !this.state.fileModal,
    });
  };

  // update single syllabus
  handleSyllabusDetailUpdate = () => {
    let data = {
      id: this.state.id,
      name: this.state.updateTopicName,
      description: this.state.updateDescription,
    };
    axiosPost(URL.updateCoursePlanSyllabusDetailCore, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Topic successfully updated",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        this.toggleUpdateSyllabusDetail();
        this.getCourseSyllabus(this.state.activeData.id);
        // this.setState({
        //   syllabusDetailModal: !this.state.syllabusDetailModal,
        // });
      }
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleUpdateSyllabusDetail = () => {
    this.setState({
      id: "",
      updateTopicName: "",
      updateDescription: "",
      updateSyllabusDetailModal: !this.state.updateSyllabusDetailModal,
    });
  };

  handleSyllabusAdd = (list) => {
    this.setState(
      {
        // selectedClass: list.classId,
        // selectedSubject: list.subjectId,
        coursePlanIdForAdd: list.id,
      },
      () => {
        this.setState({
          addSyllabusModal: true,
        });
      }
    );
  };

  toggleAddSyllabusModal = () => {
    this.setState({
      addSyllabusModal: !this.state.addSyllabusModal,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader
                title={
                  this.props.displayType === "student"
                    ? "Student Course Plan"
                    : "Teacher Course Plan"
                }
                onClick={this.toggleModal}
                icon="add_box"
                noPermission={true}
              />
              <div>
                <div className="row">
                  <div className="col-3 col-md-4">
                    <select
                      className="form-control cusInputSelect"
                      name="selectedClass"
                      onChange={(e) => this.handleChange(e)}
                      disabled={false}
                    >
                      <option
                        value=""
                        selected={
                          this.state.selectedYearLevel == "" ? true : false
                        }
                        disabled={
                          this.state.selectedYearLevel == "" ? false : true
                        }
                      >
                        Select Class
                      </option>
                      {this.state.classes.map((sl, idx) => {
                        return <option value={sl.id}>{sl.name}</option>;
                      })}
                    </select>
                  </div>

                  <div className="col-3 col-md-4">
                    <select
                      className="form-control cusInputSelect"
                      name="selectedSubject"
                      onChange={(e) => this.handleChange(e)}
                      disabled={false}
                    >
                      <option value="" selected>
                        Select subjects
                      </option>
                      {this.state.subjects.map((sl, idx) => {
                        return <option value={sl.id}>{sl.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <table className="table cusTable">
                      <thead>
                        <tr>
                          <th>Class</th>
                          <th>Subject</th>
                          <th>Resources</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.courseSyllabus.length > 0 ? (
                          this.state.courseSyllabus.map((list, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{list.className}</td>
                                <td>{list.subjectName}</td>
                                <td>
                                  <>
                                    <button
                                      key={idx}
                                      className="btn btn-primary cusBtn cusBtnFontSize"
                                      onClick={() =>
                                        this.subjectResourceModal(list.id)
                                      }
                                    >
                                      Add
                                    </button>
                                    <button
                                      className="btn btn-primary cusBtn cusBtnFontSize ml-2"
                                      onClick={() =>
                                        this.viewSubjectResourceModal(list.id)
                                      }
                                    >
                                      View Resources
                                    </button>
                                    {/* <button className="tt-button tt-button-primary">
                                    Update
                                  </button> */}
                                  </>
                                </td>
                                <td>
                                  <>
                                    <button
                                      key={idx}
                                      className="btn btn-primary cusBtn cusBtnFontSize"
                                      onClick={() =>
                                        this.handleSyllabusDetailModal(list)
                                      }
                                    >
                                      View
                                    </button>
                                    <button
                                      key={idx}
                                      className="btn btn-primary cusBtn cusBtnFontSize"
                                      onClick={() =>
                                        this.handleSyllabusAdd(list)
                                      }
                                    >
                                      Add
                                    </button>

                                    <button
                                      key={idx}
                                      className="btn btn-primary cusBtn cusBtnFontSize"
                                      onClick={() =>
                                        this.handleSyllabusPlanDelete(list.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colspan={6}>
                              {this.state.courseSpinner ? (
                                <span className="p-4">
                                  <Spinner></Spinner>
                                  <br />
                                  Loading Data...
                                </span>
                              ) : (
                                "No records found"
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalWindow
          modal={this.state.syllabusDetailModal}
          toggleModal={this.toggleSyllabusDetailModal}
          modalHeader={"Course Syllabus"}
          size="xl"
          modalBody={
            <CourseSyllabusDetailModal
              topicList={this.state.topicList}
              handleUpdateSyllabusDetail={this.handleUpdateSyllabusDetail}
              handleSyllabusDetailDelete={this.handleSyllabusDetailDelete}
              handleSyllabusPlanDelete={this.handleSyllabusPlanDelete}
              toggleSyllabusResourcesModal={this.toggleSyllabusResourcesModal}
              toggleSyllabusResourcesModalView={
                this.toggleSyllabusResourcesModalView
              }
              syllabusResourceModal={this.syllabusResourceModal}
              toggleSyllabusDetailModal={this.toggleSyllabusDetailModal}
            ></CourseSyllabusDetailModal>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.updateSyllabusDetailModal}
          toggleModal={this.toggleUpdateSyllabusDetail}
          modalHeader="Edit Course"
          modalBody={
            <>
              <label htmlFor="updateTopicName">Topic Name</label>
              <input
                className="form-control cusInput"
                type="text"
                name="updateTopicName"
                value={this.state.updateTopicName}
                onChange={(e) => this.handleChangeUpdateData(e)}
              />

              <label htmlFor="updateDescription">Description</label>

              <input
                className="form-control cusInput"
                type="text"
                name="updateDescription"
                value={this.state.updateDescription}
                onChange={(e) => this.handleChangeUpdateData(e)}
              />
            </>
            // <CourseSyllabusUpdateModal
            //   updateTopicName={this.state.updateTopicName}
            //   updateTopicLectureHour={this.state.updateTopicLectureHour}
            //   updateTopicPracticalHour={this.state.updateTopicPracticalHour}
            //   handleChange={this.handleChange}
            // ></CourseSyllabusUpdateModal>
          }
          modalFooter={
            <div className="container-fluid">
              <div className="row">
                <div className="col text-right">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={this.handleSyllabusDetailUpdate}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={this.toggleUpdateSyllabusDetail}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.syllabusResourcesModal}
          toggleModal={this.toggleSyllabusResourcesModal}
          modalHeader="Add Resource"
          size="xl"
          modalBody={
            <CourseSyllabusResource
              syllabusDetailModal={this.state.syllabusDetailModal}
              assignment={true}
              updateAssignment={false}
              toggleAddResourceModal={this.toggleSyllabusResourcesModal}
              saveResources={this.saveResources}
              assignmentContents={this.state.contents}
              assignmentLinks={this.state.links}
              coursePlanId={this.state.coursePlanId}
              coursePlanSyllabusId={this.state.coursePlanSyllabusId}
              getCourseSyllabus={this.getCourseSyllabus}
              toggleSyllabusDetailModal={this.toggleSyllabusDetailModal}
            ></CourseSyllabusResource>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.fileModal}
          toggleModal={this.toggleFileModal}
          modalHeader="Resource"
          size="xl"
          modalBody={
            <CourseClassResources
              canDeleteResources={true}
              selectedResources={this.state.selectedSubjectResources}
              getCourseSyllabus={this.getCourseSyllabus}
            ></CourseClassResources>

            // <FileModal
            //   isNotice={false}
            //   isSyllabys={true}
            //   handleChangeFile={this.handleChangeFile}
            //   toggleModal={this.toggleFileModal}
            //   fileExist={true}
            //   activeIndex={this.state.activeIndex}
            //   fileType={this.state.fileType}
            //   filePath={this.state.filePath}
            //   files={this.state.files}
            //   images={this.state.images}
            //   links={this.state.links}
            //   type={this.state.type}
            // ></FileModal>
          }
        ></ModalWindow>

        <CustomModal
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          modalHeader="Create Course Syllabus"
          size="xl"
          modalBody={
            <CreateCourseSyallabus
              displayType={this.props.displayType}
              getCourseSyllabus={this.getCourseSyllabus}
              toggleModal={this.toggleModal}
            />
          }
        />

        <CustomModal
          modal={this.state.addSyllabusModal}
          toggleModal={this.toggleAddSyllabusModal}
          modalHeader="Add Course syllabus"
          size="xl"
          modalBody={
            <SubjectTopicSetting
              // selectedYearLevel={this.state.selectedYearLevel}
              selectedClass={this.state.selectedClass}
              selectedSubject={this.state.selectedSubject}
              academicRelated={true}
              toggleAddCourseModal={this.toggleAddSyllabusModal}
              displayType="student"
              getCourseSyllabus={this.getCourseSyllabus}
              toggleModal={this.toggleModal}
              onlySyllabus={true}
              coursePlanIdForAdd={this.state.coursePlanIdForAdd}
            ></SubjectTopicSetting>
          }
        />
      </div>
    );
  }
}

export default ListCourseSyallabus;
