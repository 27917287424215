import React, { Component } from "react";
import ListTeacherTraining from "./ListTeacherTraining";

export class TeacherTraining extends Component {
  render() {
    return (
      <div>
        <ListTeacherTraining />
      </div>
    );
  }
}

export default TeacherTraining;
