import React, { Component } from "react";

class TableFormInputs extends Component {
  render() {
    const {
      field: ctfc,
      handleCustomFieldTablesChange,
      config,
      studentFieldIndex,
      customTableFieldIndex,
      columnIndex,
      subColumnIndex,
      rowIndex,
      stateName,
      subColumn,
      row,
    } = this.props;

    return (
      <>
        {ctfc.customFieldTableSubColumns !== null &&
        ctfc.customFieldTableSubColumns?.length > 0 ? null : (
          <td>
            {ctfc.isMulti && ctfc.fieldTypeCode === "select" ? (
              <select
                name={ctfc.name}
                className={"form-control"}
                value={row[ctfc.name]}
                onChange={
                  !config
                    ? (e) =>
                        handleCustomFieldTablesChange(
                          e,
                          studentFieldIndex,
                          customTableFieldIndex,
                          columnIndex,
                          subColumnIndex,
                          rowIndex,
                          stateName,
                          subColumn
                        )
                    : null
                }
              >
                <option disabled selected>
                  select
                </option>
                {ctfc.customFieldTableColumnOptionDatas?.map((cftcd) => (
                  <option value={cftcd.id}>{cftcd.optionData}</option>
                ))}
              </select>
            ) : (
              <>
                {ctfc.isMulti && ctfc.fieldTypeCode !== "select " ? (
                  ctfc.customFieldTableColumnOptionDatas?.map((data) => (
                    <>
                      <label className="mr-2">{data.optionData}</label>
                      <input
                        type={
                          ctfc.fieldTypeCode === "multi-checkbox"
                            ? "checkbox"
                            : ctfc.fieldTypeCode
                        }
                        name={
                          ctfc.fieldTypeCode === "radio"
                            ? `${ctfc.name}${rowIndex}`
                            : data.optionData
                        }
                        maxLength="255"
                        value={
                          ctfc.fieldTypeCode === "radio"
                            ? data.optionData
                            : row[ctfc.name]
                        }
                        onChange={
                          !config
                            ? (e) =>
                                handleCustomFieldTablesChange(
                                  e,
                                  studentFieldIndex,
                                  customTableFieldIndex,
                                  columnIndex,
                                  subColumnIndex,
                                  rowIndex,
                                  stateName,
                                  subColumn
                                )
                            : null
                        }
                        className={"testing mr-2"}
                      />
                    </>
                  ))
                ) : (
                  <>
                    {ctfc.fieldTypeCode === "textarea" ? (
                      <textarea
                        name={ctfc.name}
                        value={row[ctfc.name]}
                        className={"form-control"}
                        onChange={
                          !config
                            ? (e) =>
                                handleCustomFieldTablesChange(
                                  e,
                                  studentFieldIndex,
                                  customTableFieldIndex,
                                  columnIndex,
                                  subColumnIndex,
                                  rowIndex,
                                  stateName,
                                  subColumn
                                )
                            : null
                        }
                      />
                    ) : (
                      <input
                        type={ctfc.fieldTypeCode}
                        name={ctfc.name}
                        maxLength="255"
                        checked={
                          row[ctfc.name] === "true"
                            ? true
                            : row[ctfc.name] === "false"
                            ? false
                            : row[ctfc.name]
                        }
                        value={row[ctfc.name]}
                        className={"form-control"}
                        onChange={
                          !config
                            ? (e) =>
                                handleCustomFieldTablesChange(
                                  e,
                                  studentFieldIndex,
                                  customTableFieldIndex,
                                  columnIndex,
                                  subColumnIndex,
                                  rowIndex,
                                  stateName,
                                  subColumn
                                )
                            : null
                        }
                      />
                    )}
                  </>
                )}
              </>
            )}
          </td>
        )}
      </>
    );
  }
}

export default TableFormInputs;
