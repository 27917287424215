import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "./../UI/ModalWindow";
import GranularPermissionModal from "./../Permissions/GranularPermissionModal";
import swal from "sweetalert";
import moment from "moment";
import LeaveRequestForm from "./LeaveRequestForm";
import LeaveRequestList from "./LeaveRequestList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendar,
  faPlusCircle,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

class LeaveForStudent extends Component {
  state = {
    createLeaveModal: false,

    remarkLeaveModal: false,
    leaveDetailModal: false,
    remarks: "",
    leaveFrom: "",
    leaveTo: "",
    name: "",
    spinner: false,
    listSpinner: false,
    leaveLists: [],
    leaveDetailData: "",
    status: "",
  };

  componentDidMount() {
    this.getAllLeaveList();
  }

  handleLeaveFormModal = () => {
    this.setState({
      createLeaveModal: !this.state.createLeaveModal,
    });
  };

  getDateDiff = (date1, date2) => {
    let d1 = moment(date1);
    let d2 = moment(date2);

    let dateDiff = d2.diff(d1, "days");
    return dateDiff;
  };

  getAllLeaveList = () => {
    let data = {
      fromDate: this.state.leaveFrom,
      toDate: this.state.leaveTo,
      status: this.state.status,
      title: this.state.name,
    };

    this.setState(
      {
        leaveLists: [],
        listSpinner: true,
      },
      () => {
        axiosPost(URL.getAllLeaveList, data, (response) => {
          if (response.data.status === 200) {
            this.setState({
              leaveLists: response.data.data,
              listSpinner: false,
            });
          }
        });
      }
    );
  };

  handleLeaveRemarkModal = (type, leaveData) => {
    this.setState({
      remarkLeaveModal: !this.state.remarkLeaveModal,
      leaveAction: type,
      activeLeaveId: leaveData.id,
    });
  };
  handleLeaveDetailModal = (data) => {
    this.setState({
      leaveDetailModal: !this.state.leaveDetailModal,
      leaveDetailData: data,
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    if (name === "leaveFrom") {
      this.setState({
        leaveTo: "",
      });
    }
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-newListBox container-fluid">
            <div className="tt-group-header">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-between">
                  <div>Leave</div>
                  <button
                    className="tt-button tt-button-primary attendance-button"
                    onClick={this.handleLeaveFormModal}
                  >
                    Create Leave
                  </button>
                </div>
                <div className="col-md-12 mt-4">
                  <LeaveRequestList
                    getAllLeaveList={this.getAllLeaveList}
                    leaveLists={this.state.leaveLists}
                    getDateDiff={this.getDateDiff}
                    name={this.state.name}
                    status={this.state.status}
                    leaveTo={this.state.leaveTo}
                    leaveFrom={this.state.leaveFrom}
                    handleChange={this.handleChange}
                    handleLeaveDetailModal={this.handleLeaveDetailModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* student leave form modal start */}
        <ModalWindow
          modal={this.state.createLeaveModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              createLeaveModal: !this.state.createLeaveModal,
            });
          }}
          modalHeader={"Create Leave Request"}
          modalBody={
            <>
              <LeaveRequestForm
                handleLeaveFormModal={this.handleLeaveFormModal}
                staff={true}
                getAllLeaveList={this.getAllLeaveList}
              />
            </>
          }
        ></ModalWindow>
        {/* student leave form modal end */}
        <ModalWindow
          modal={this.state.leaveDetailModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              leaveDetailModal: !this.state.leaveDetailModal,
            });
          }}
          modalHeader={"Leave Detail"}
          modalBody={
            this.state.leaveDetailData !== "" && (
              <div className="p-3">
                <div className="row d-flex justify-content-between">
                  <div className="col-md-3 d-flex justify-content-between">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          style={{ color: "#243C57" }}
                        />{" "}
                        Applied by
                      </small>{" "}
                      <br />
                      <b className="text-primary">
                        {this.state.leaveDetailData.people.name}
                      </b>
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <small className="text-secondary">
                        {" "}
                        <FontAwesomeIcon icon={faBook} /> Subject
                      </small>{" "}
                      <br />
                      {this.state.leaveDetailData.subject}
                    </p>
                  </div>
                  <div className="col-md-3 d-flex justify-content-between">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          style={{ color: "gray" }}
                        />{" "}
                        From
                      </small>{" "}
                      <br />
                      {moment(this.state.leaveDetailData.fromDate).format(
                        "YYYY-MM-DD"
                      )}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          style={{ color: "gray" }}
                        />{" "}
                        To
                      </small>{" "}
                      <br />
                      {moment(this.state.leaveDetailData.toDate).format(
                        "YYYY-MM-DD"
                      )}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faBook}
                          style={{ color: "gray" }}
                        />{" "}
                        Description
                      </small>{" "}
                      <br />
                      {this.state.leaveDetailData.description}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <small className="text-secondary">
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          style={{ color: "gray" }}
                        />{" "}
                        Additional Details
                      </small>{" "}
                      <br />
                      {this.state.leaveDetailData.additionalDescription}
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <p>
                      <small className="text-secondary">Status</small> <br />
                      {this.state.leaveDetailData.status === "accepted" ? (
                        <b className="text-capitalize text-success">
                          {this.state.leaveDetailData.status}
                        </b>
                      ) : this.state.leaveDetailData.status === "rejected" ? (
                        <b className="text-capitalize text-danger">
                          {this.state.leaveDetailData.status}
                        </b>
                      ) : (
                        <b className="text-capitalize">
                          {this.state.leaveDetailData.status}
                        </b>
                      )}
                    </p>
                  </div>
                  {this.state.leaveDetailData.remarks !== "" && (
                    <div className="col-md-6">
                      <p>
                        <small className="text-secondary">Remarks</small> <br />
                        {this.state.leaveDetailData.remarks}
                      </p>
                    </div>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    {this.state.leaveDetailData.status !== "pending" ? (
                      <p>
                        <small className="text-secondary">
                          {this.state.leaveDetailData.status === "accepted"
                            ? "Approved"
                            : "Rejected"}{" "}
                          By
                        </small>{" "}
                        <br />
                        {this.state.leaveDetailData.approvedByName}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            )
          }
        ></ModalWindow>
      </>
    );
  }
}
export default LeaveForStudent;
