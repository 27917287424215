import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";
import ExamRoutineFormat from "./ExamRoutineFormat";

export class CreateExamRoutine extends Component {
  state = {
    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: null,

    classrooms: [],
    selectedClassrooms: "",

    subjects: [],
    selectedSubjects: "",

    teachers: [],
    addRoutineModal: false,
  };

  componentDidMount = () => {
    this.getEducationLevels();
    this.getTeachers();
    this.getSubjects();
  };

  getTeachers = () => {
    axiosPost(URL.getSubjectTeachers, {}, (response) => {
      if (response.status == 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            teachers: data,
          });
      }
    });
  };

  getSubjects = () => {
    let data = this.state.selectedClassrooms;
    axiosPost(URL.getSubjectByClassroom, { data }, (response) => {
      if (response.status == 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            subjects: data,
          });
      }
    });
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
            // selectedEducationLevelId: data[0].id,
          });
      }
    });
  };

  handleAddExamRoutine = () => {
    if (
      this.state.selectedEducationLevelId !== "" &&
      this.state.selectedYearLevel !== null
    ) {
      this.setState({
        addRoutineModal: true,
      });
    } else {
      swal("Warning", "Select the class and education level first!");
      return;
    }
  };

  handleEducationLevelChange = (e) => {
    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          selectedYearLevel: null,

          classrooms: [],
          selectedClassrooms: null,

          subjects: [],
          selectedSubjects: null,
          selectedEducationLevelId: e.target.value,
        });
      }
    });
  };

  handleYearLevel = (e) => {
    this.setState({ selectedYearLevel: e.target.value });
    var data = {
      yearLevelId: e.target.value,
    };
    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.data.status === 200) {
        const datas = response.data.data;
        this.setState({
          classrooms: datas,
          selectedClassrooms: "",
          selectedSubjects: "",
        });
      }
    });

    axiosPost(URL.getSubjectForPlanner, data, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            subjects: data,
            selectedSubjects: data[0].id,
          });
      }
    });
  };

  handleSectionChange = (e) => {
    this.setState({ selectedClassrooms: e.target.value });
  };

  toggleExamRoutineModal = () => {
    this.setState({ addRoutineModal: !this.state.addRoutineModal });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">Create Exam Routine</div>
          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-3 col-md">
                  <strong>Education Level</strong>
                  <select
                    className="form-control"
                    name="educationLevel"
                    // value={this.state.gradeId}
                    onChange={this.handleEducationLevelChange}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.educationLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Class</strong>
                  <select
                    className="form-control"
                    name="yearLevel"
                    value={
                      this.state.selectedYearLevel
                        ? this.state.selectedYearLevel
                        : ""
                    }
                    onChange={this.handleYearLevel}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.yearLevels.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md">
                  <strong>Section</strong>
                  <select
                    className="form-control"
                    name="sectionId"
                    value={this.state.selectedClassrooms}
                    onChange={this.handleSectionChange}
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    {this.state.classrooms.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md-2">
                  <button
                    className="mt-4 mx-auto d-block tt-button tt-button-primary"
                    onClick={() => this.handleAddExamRoutine()}
                    disabled={
                      this.state.selectedYearLevel === "" ? true : false
                    }
                  >
                    Add Exam Routine
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          <ModalWindow
            modal={this.state.addRoutineModal}
            toggleModal={this.toggleExamRoutineModal}
            fullWidth={true}
            modalHeader="Add Exam Routine"
            modalBody={
              <ExamRoutineFormat
                selectedEducationLevelId={this.state.selectedEducationLevelId}
                // condition="create"
                selectedYearLevel={this.state.selectedYearLevel}
                selectedClassrooms={this.state.selectedClassrooms}
                // selectedSubjects={this.state.selectedSubjects}
                subjects={this.state.subjects}
                teachers={this.state.teachers}
                // academicRelated={true}
                toggleExamRoutineModal={this.toggleExamRoutineModal}
              ></ExamRoutineFormat>
            }
          ></ModalWindow>
        }
      </>
    );
  }
}

export default CreateExamRoutine;
