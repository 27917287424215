import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import { displayConfirmDeleteAlert } from "../../utils/DisplayMessage";
import CustomEditButton from "../../components/common/CustomEditButton";

class ListSlider extends Component {
  state = {};

  hanldeDelete = (id, idx) => {
    displayConfirmDeleteAlert({ id: id, idx: idx }, this.deleteSlider);
  };

  deleteSlider = datum => {
    let data = {
      id: datum.id
    };
    axiosPost(URL.deleteslider, data, response => {
      if (response.status == 200) {
        this.props.getAllSliders();
        swal("Success", response.data.message);
      }
    });
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.sliders.length > 0
                  ? this.props.sliders.map((slider, index) => {
                      return (
                        <tr>
                          <td>
                            <img
                              width="100"
                              src={URL.imageSelectURL + slider.imagePath}
                            />
                          </td>
                          <td>{slider.title}</td>
                          <td>{slider.description}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={e =>
                                  this.props.handleChangeToggle(e, slider)
                                }
                                checked={slider.isActive}
                                name="status"
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            {/* <CustomDeleteButton
                              onClick={() =>
                                this.hanldeDelete(slider.id, index)
                              }
                            /> */}
                            <CustomEditButton
                              onClick={() =>
                                this.props.handleEditSlider(slider)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default ListSlider;
