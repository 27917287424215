import React, { Component } from "react";
import SubstituteTeacherPermission from "./SubstituteTeacherPermission";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import SubstituteTeacherPermissionList from "./SubstituteTeacherPermissionList";

class SubstituteTeacherPermissionTab extends Component {
  state = {
    tabId: "add",
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "80.5vh" }}>
        <div className="tt-postTabs tt-inner-content">
          <Tabs
            onChange={(tabId) => this.setState({ tabId })}
            className="tt-userSetting"
          >
            <TabList style={{ marginBottom: "0 !important" }}>
              <Tab tabFor="add">Add</Tab>
              <Tab tabFor="delete">List</Tab>
            </TabList>
            <TabPanel
              tabId="add"
              style={{ padding: "20px" }}
              render={() =>
                this.state.tabId === "add" ? (
                  <SubstituteTeacherPermission
                    classroomId={this.props.classroomId}
                    yearLevelId={this.props.yearLevelId}
                  />
                ) : null
              }
            ></TabPanel>
            <TabPanel
              tabId="delete"
              style={{ padding: "20px" }}
              render={() =>
                this.state.tabId === "delete" ? (
                  <SubstituteTeacherPermissionList
                    classroomId={this.props.classroomId}
                    yearLevelId={this.props.yearLevelId}
                  />
                ) : null
              }
            ></TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default SubstituteTeacherPermissionTab;
