import React, { Component } from "react";
import { marksBreakdownTypeCode } from "../../../utils/Constants";

export default class ReportingConfigurationCustomColumnCalculation extends Component {
  state = { sampleValueSummation: 0, sampleValueAverage: 0 };

  render() {
    let previousAssessments = this.props.previousAssessments;
    let sampleValueSummation = this.props.sampleValueSummation;
    let sampleValueAverage = this.props.sampleValueAverage;
    let reportSetupSubjectSettingBd = this.props.reportSetupSubjectSettingBd;
    return (
      <div className="text-center">
        <div className="row mb-2">
          <div className="col-3"></div>
          <div className="col-6">
            <select
              name="breakdownTypeCode"
              id=""
              className="form-control"
              value={reportSetupSubjectSettingBd.breakdownTypeCode}
              onChange={this.props.handleSubjectSettingBreakdownChange}
            >
              <option value="" selected disabled>
                Select Marks Break Down Type
              </option>
              <option
                value={marksBreakdownTypeCode.downgradeToPercentAndSummation}
              >
                Downgrade to percentage and summation
              </option>
              <option value={marksBreakdownTypeCode.average}>Average</option>
              <option value={marksBreakdownTypeCode.summation}>
                Summation
              </option>
            </select>
          </div>
          <div className="col-3"></div>
        </div>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Subjects</th>
              {previousAssessments
                ? previousAssessments.map((assessment, i) => (
                    <th>
                      <input
                        type="checkbox"
                        checked={assessment.selectedForCustomColCalc}
                        onChange={(e) =>
                          this.props.handleSelectedForCustomColCalcCheckboxChange(
                            e,
                            i
                          )
                        }
                      />
                      {assessment.isCurrentAssessment
                        ? assessment.assessmentName + "(Current)"
                        : assessment.assessmentName}
                    </th>
                  ))
                : null}
              {reportSetupSubjectSettingBd.breakdownTypeCode ===
              marksBreakdownTypeCode.downgradeToPercentAndSummation ? (
                <th>Downgraded Value</th>
              ) : reportSetupSubjectSettingBd.breakdownTypeCode ===
                marksBreakdownTypeCode.average ? (
                <th>Average</th>
              ) : reportSetupSubjectSettingBd.breakdownTypeCode ===
                marksBreakdownTypeCode.summation ? (
                <th>Summation</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sample Subject</td>
              {previousAssessments
                ? previousAssessments.map((assessment) => (
                    <td>
                      {assessment.sampleValue &&
                      assessment.selectedForCustomColCalc
                        ? assessment.sampleValue + "/100"
                        : 0}
                    </td>
                  ))
                : null}
              {reportSetupSubjectSettingBd.breakdownTypeCode ===
              marksBreakdownTypeCode.summation ? (
                <td>{sampleValueSummation.toFixed(2)}</td>
              ) : reportSetupSubjectSettingBd.breakdownTypeCode ===
                marksBreakdownTypeCode.average ? (
                <td>{sampleValueAverage.toFixed(2)}</td>
              ) : null}
            </tr>
            {reportSetupSubjectSettingBd.breakdownTypeCode ===
            marksBreakdownTypeCode.downgradeToPercentAndSummation ? (
              <tr>
                <td>Percentage Weightage</td>
                {previousAssessments
                  ? previousAssessments.map((assessment, i) => (
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          disabled={!assessment.selectedForCustomColCalc}
                          value={assessment.percentageWeight}
                          onChange={(e) =>
                            this.props.handlePercentageWeightChange(e, i)
                          }
                        />
                      </td>
                    ))
                  : null}
                {reportSetupSubjectSettingBd.breakdownTypeCode ? (
                  <td>{this.props.sampleValuePercentage.toFixed(2)}</td>
                ) : null}
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}
