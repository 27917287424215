import React, { Component } from "react";
import { Link } from "react-router-dom";
import { URL, userRoleCode } from "../../utils/Constants";
import userImage from "../../assets/images/user-image.png";

class UserSearchResult extends Component {
  state = {};

  handleError = (e, member) => {
    let self = e.target;
    let img = new Image();
    img.src = URL.imageSelectURL + member.photo;
    img.onload = function () {
      self.src = img.src;
    };
    img.onerror = function () {
      self.src = userImage;
    };
  };

  render() {
    let props = this.props;
    return (
      <div className="row">
        {props.searchData.map((data, idx) => {
          return (
            <div className="col-md-4">
              {data?.userRoleCode === userRoleCode.roleStudentContact ? (
                <Link
                  to={{
                    pathname: `/familyPage/${data?.familyId}`,
                    profilePeopleId: data?.peopleId,
                    familyPageOpen: true,
                    userId: data?.userId,
                  }}
                >
                  <div className="userSearchBox">
                    <div className="userImageBox">
                      <img
                        src={
                          data?.photo !== null
                            ? URL.imageSelectURL + "/" + data?.photo
                            : userImage
                        }
                        onError={(e) => this.handleError(e, data)}
                      />
                    </div>
                    <div>
                      <p className="userSearchTitle">{data?.fullName}</p>
                      <p className="userSearchContent">[ Student Contact]</p>
                    </div>
                  </div>
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/profile/${data?.username}`,
                    username: data?.username,
                    name: data?.fullName,
                  }}
                >
                  <div className="userSearchBox">
                    <div className="userImageBox">
                      <img
                        src={
                          data?.photo !== null
                            ? URL.imageSelectURL + "/" + data?.photo
                            : userImage
                        }
                        onError={(e) => this.handleError(e, data)}
                      />
                    </div>
                    <div>
                      <p className="userSearchTitle">{data?.fullName}</p>
                      {data?.userRoleCode === userRoleCode.roleAdmin ? (
                        <p className="userSearchContent">
                          [ {data?.userRole} ]
                        </p>
                      ) : data?.userRoleCode === userRoleCode.roleStudent ? (
                        <p className="userSearchContent">
                          {data?.yearLevelName} - {data?.classroomName}
                        </p>
                      ) : (
                        <p className="userSearchContent">[ Staff ]</p>
                      )}
                    </div>
                  </div>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default UserSearchResult;
