import React, { Component } from "react";

class SelectedStudentList extends Component {
  state = {};
  render() {
    return (
      <div className="card">
        <div className="card-body tt-selected-Students">
          <div className="tt-group-header text-center">Selected students</div>
          {this.props.selectedStudent.length > 0 ? (
            <>
              <table className="table table-bordered text-center table-striped">
                <thead className="tt-group-header">
                  <tr>
                    <td>Student name</td>
                    <td>Option</td>
                  </tr>
                </thead>
                <tbody>
                  {this.props.selectedStudent
                    ? this.props.selectedStudent.map((student, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{student.people.name}</td>
                            <td>
                              <button
                                className="tt-button tt-button-primary"
                                onClick={(e) =>
                                  this.props.removeSelectedStudent(e, idx)
                                }
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </>
          ) : (
            <div className="text-center">No students are selected yet</div>
          )}
        </div>
      </div>
    );
  }
}

export default SelectedStudentList;
