import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

class SystemExamHeader extends Component {
  state = {};
  render() {
    const {
      trainings,
      trainingId,
      examTitle,
      examDesc,
      totalMark,
      passMarks,
      staffLists,
      handleChange,
      selectedStaff,
      questionBox,
      changeStaffSelect,
      handleShowQuestionBox,
      fieldError,
    } = this.props;
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 text-end">
              <select
                disabled={this.props.editMode}
                className={`form-control cusInput  ${
                  fieldError && trainingId === "" && "is-invalid"
                }`}
                name="trainingId"
                value={trainingId}
                onChange={handleChange}
                // disabled={educationLevelCode === "" ? true : false}
              >
                <option value="" disabled selected>
                  Select Training
                </option>
                {trainings?.map((sl, idx) => {
                  return <option value={sl.id}>{sl.name}</option>;
                })}
              </select>
            </div>
            <div className="col-md-4 text-end">
              {/* <strong>Title: </strong> */}
              <input
                type="text"
                name="examTitle"
                value={examTitle}
                className={`form-control cusInput  ${
                  fieldError && examTitle === "" && "is-invalid"
                }`}
                onChange={handleChange}
                placeholder="Enter Title"
              />
              {/* <small className="p-2">*Required</small> */}
            </div>
            <div className="col-md-4 text-right">
              {/* <strong>Description: </strong> */}
              <input
                type="text"
                name="examDesc"
                value={examDesc}
                className="form-control cusInput"
                onChange={handleChange}
                placeholder="Enter your description here"
              />
            </div>
          </div>
          <div className="row mt-2">
            {/* <div className="col-md-2 text-right mt-1 justify-content-end">
              <strong>Marks type: </strong>
              <select
                className="form-control"
                name="marksCalculation"
                value={marksCalculation}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select marks type
                </option>
                <option value="0">Total</option>
                <option value="1">Individual</option>
              </select>
            </div> */}
            <div className="col">
              <div className="row">
                <div className="col-md-3">
                  {/* <strong>Total marks</strong> */}
                  <input
                    type="number"
                    value={totalMark}
                    className={`form-control cusInput  ${
                      fieldError && totalMark === "" && "is-invalid"
                    }`}
                    onChange={handleChange}
                    name="totalMark"
                    placeholder="Full Marks"
                  />
                  {/* <small className="p-2">*Required</small> */}
                </div>
                <div className="col-md-3">
                  {/* <strong>Pass marks</strong> */}
                  <input
                    type="number"
                    value={passMarks}
                    className={`form-control cusInput  ${
                      fieldError && passMarks === "" && "is-invalid"
                    }`}
                    name="passMarks"
                    onChange={handleChange}
                    placeholder="Pass Marks"
                  />
                  {/* <small className="p-2">*Required</small> */}
                </div>

                <div className="col">
                  {/* <strong>Exam Attendees</strong> */}
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={staffLists}
                    value={selectedStaff}
                    onChange={(item) => changeStaffSelect(item)}
                    placeholder="Select Exam Attendees"
                    classNamePrefix="cusSelect"
                  />
                  {fieldError && selectedStaff?.length === 0 && (
                    <small style={{ color: "red" }} className="p-2">
                      *Exam attendees is required
                    </small>
                  )}
                </div>
                <div className="col-md-auto text-end mt-1">
                  {questionBox || this.props.editMode ? null : (
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize"
                      onClick={() => handleShowQuestionBox()}
                    >
                      Add Questions
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SystemExamHeader;
