/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, GeneralASSectionTwo, render the Model for General Assessment Settings in the
 *              Record Setup in the Primary ARR
 */

import React from "react";
import CounterInput from "react-counter-input/lib/CounterInput";

const GeneralASSectionTwo = (props) => {
  return (
    <>
      <div className="col-md-auto ">
        <label htmlFor="commentName" className="mt-1">
          <strong>
            Comment Name :{" "}
            <span className="tt-assessment-module-mandatory">*</span>
          </strong>
        </label>
      </div>
      <div className="col-md-3">
        <input
          type="text"
          className="form-control"
          id="commentName"
          value={props.commentName}
          name="commentName"
          onChange={props.universalhandleChange}
        />
      </div>

      <div className="col">
        <CounterInput
          count={props.minLength}
          min={0}
          // max={props.maxLength}
          btnStyle={{
            backgroundColor: "#000080",
            border: "1px solid white",
            color: "white",
            padding: "5px",
            backgroundImage: "linear-gradient(#000080, #000080)",
          }}
          inputStyle={{
            height: "33px",
            background: "white",
          }}
          wrapperStyle={{
            justifyContent: "center",
          }}
          onCountChange={(count) => props.handleMinLengthChange(count)}
        />
      </div>
      <div className="col">
        <CounterInput
          count={props.maxLength}
          min={props.minLength}
          // max={props.maxLength}
          btnStyle={{
            backgroundColor: "#000080",
            border: "1px solid white",
            padding: "5px",
            color: "white",
            backgroundImage: "linear-gradient(#000080, #000080)",
          }}
          inputStyle={{
            height: "33px",
            background: "white",
          }}
          wrapperStyle={{
            justifyContent: "center",
          }}
          onCountChange={(count) => props.handleMaxLengthChange(count)}
        />
      </div>
      <div className="col">
        <select
          className="form-control"
          value={props.commentTextType}
          onChange={props.handleWordCharacterChange}
          name="commentTextType"
        >
          <option value="" disabled>
            Select Word or Character
          </option>
          <option value="words">Words</option>
          <option value="characters">Character</option>
        </select>
      </div>
      <div className="col-md-2">
        <select
          className="form-control"
          name="commentor"
          value={props.commentor}
          onChange={props.universalhandleChange}
        >
          <option value="" disabled>
            Select Commenter
          </option>
          <optgroup label="Roles">
            {props.roleList
              ? props.roleList.map((role, idx) => {
                  let val = `${role.subRoleId},role`;
                  return (
                    <option key={idx} value={val}>
                      {role.name}
                    </option>
                  );
                })
              : null}
          </optgroup>
          <optgroup label="Staffs">
            {props.recruitmentList
              ? props.recruitmentList.map((teacher, i) => {
                  let val = `${teacher.id},staff`;
                  return (
                    <option value={val} key={i}>
                      {teacher.people.name}
                    </option>
                  );
                })
              : null}
          </optgroup>
        </select>
      </div>
      <div className="col-md-1">
        <button
          className="tt-button tt-button-primary"
          onClick={props.addNewCommentSection}
          style={{ marginTop: "2px" }}
        >
          Add
        </button>
      </div>
      {/* <div className="row"> */}
      {/* <div className="col-md-3" style={{ display: "flex" }}>
          <label htmlFor="commentName">
            <strong>Comment Name :</strong>
          </label>

          <input
            type="text"
            className="form-control"
            id="commentName"
            value={props.commentName}
            name="commentName"
            onChange={props.universalhandleChange}
          />
        </div> */}
      {/* <div className="col-md-2"> */}
      {/* <Counter count={200} /> */}
      {/* <IncreaseDecreaseTerm
          numberOfTerms={props.minLength}
          decreaseValue={props.decreaseminLengthValue}
          increaseValue={props.increaseminLengthValue}
        /> */}
      {/* <CounterInput
            count={props.minLength}
            min={0}
            // max={props.maxLength}
            btnStyle={{
              backgroundColor: "#000080",
              border: "1px solid white",
              color: "white",
              backgroundImage: "linear-gradient(#000080, #000080)",
            }}
            inputStyle={{
              height: "43px",
              background: "white",
            }}
            wrapperStyle={{
              justifyContent: "center",
            }}
            onCountChange={(count) => props.handleMinLengthChange(count)}
          /> */}
      {/* </div> */}
      {/* <div className="col-md-2"> */}
      {/* <Counter count={200} /> */}
      {/* <IncreaseDecreaseTerm
          numberOfTerms={props.maxLength}
          decreaseValue={props.decreasemaxLengthValue}
          increaseValue={props.increasemaxLengthValue}
        /> */}

      {/* <CounterInput
            count={props.maxLength}
            min={props.minLength}
            // max={props.maxLength}
            btnStyle={{
              backgroundColor: "#000080",
              border: "1px solid white",
              color: "white",
              backgroundImage: "linear-gradient(#000080, #000080)",
            }}
            inputStyle={{
              height: "43px",
              background: "white",
            }}
            wrapperStyle={{
              justifyContent: "center",
            }}
            onCountChange={(count) => props.handleMaxLengthChange(count)}
          /> */}
      {/* </div> */}
      {/* <div className="col-md-2">
          <select
            className="form-control"
            value={props.commentTextType}
            onChange={props.handleWordCharacterChange}
            name="commentTextType"
          >
            <option value="" disabled>
              Select Word or Character
            </option>
            <option value="words">Words</option>
            <option value="characters">Character</option>
          </select>
        </div> */}
      {/* <div className="col-md-2">
          <select
            className="form-control"
            name="commentor"
            value={props.commentor}
            onChange={props.universalhandleChange}
          >
            <option value="" disabled>
              Select Commenter
            </option>
            <optgroup label="Roles">
              {props.roleList
                ? props.roleList.map((role, idx) => {
                    let val = `${role.id},role`;
                    return (
                      <option key={idx} value={val}>
                        {role.name}
                      </option>
                    );
                  })
                : null}
            </optgroup>
            <optgroup label="Staffs">
              {props.recruitmentList
                ? props.recruitmentList.map((teacher, i) => {
                    let val = `${teacher.id},staff`;
                    return (
                      <option value={val} key={i}>
                        {teacher.people.name}
                      </option>
                    );
                  })
                : null}
            </optgroup>
          </select>
        </div> */}
      {/* <div className="col-md-1">
          <button
            className="tt-button tt-button-primary mt-1"
            onClick={props.addNewCommentSection}
          >
            Add
          </button>
        </div> */}
      {/* </div> */}
    </>
  );
};

export default GeneralASSectionTwo;
