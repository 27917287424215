import React, { Component } from "react";
import CounterInput from "react-counter-input/lib/CounterInput";

class CommentWordSetting extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="tt-commentWord">
          <input
            type="checkbox"
            className="form-check-input"
            name="addComments"
            checked={this.props.addComments}
            onChange={this.props.handleChange}
          />
          {"Add Comments"}
        </div>
        {/* minimum counter */}

        <CounterInput
          count={this.props.commentMinLength}
          min={0}
          btnStyle={{
            background: "#0417a3",
            color: "white",
          }}
          inputStyle={{
            border: "1px solid black",
            height: "41px",
            width: "60px",
          }}
          wrapperStyle={{
            justifyContent: "center",
          }}
          onCountChange={(count) => this.props.handleMinLengthChange(count)}
        />
        {/* <Counter
          handleIncrementDecrement={
            this.props.handleCommentLengthIncrementDecrement
          }
          countDescriptor={"min"}
          count={this.props.commentMinLength}
        /> */}
        {/* maximum counter */}

        <CounterInput
          count={this.props.commentMaxLength}
          min={0}
          btnStyle={{
            background: "#0417a3",
            color: "white",
          }}
          inputStyle={{
            border: "1px solid black",
            height: "41px",
            width: "60px",
          }}
          wrapperStyle={{
            justifyContent: "center",
            marginTop: "10px",
          }}
          onCountChange={(count) => this.props.handleMaxLengthChange(count)}
        />
        {/* <Counter
          handleIncrementDecrement={
            this.props.handleCommentLengthIncrementDecrement
          }
          countDescriptor={"max"}
          count={this.props.commentMaxLength}
        /> */}
        <select
          className="form-control tt-subjectAssessmentSelect mt-2"
          name="commentTextType"
          value={this.props.commentTextType}
          onChange={this.props.handleChange}
        >
          <option value="word">Word</option>
          <option value="letter">Letter</option>
        </select>
      </div>
    );
  }
}

export default CommentWordSetting;
