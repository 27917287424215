import React, { Component } from "react";
import { checkRolePermissions } from "../../../utils/Constants";
import { formatDate, displayNepaliDate } from "../../../utils/Utils";
import "../../../assets/scss/academicYear.scss";
import Weekday from "./Weekday";
import moment from "moment";

class Semester extends Component {
  state = {
    termAlias:
      localStorage.getItem("educationLevelDivision") === null
        ? "Term"
        : localStorage.getItem("educationLevelDivision"), //get from localStorage
    permisionAddEducationLevelDivision: false,
    permissionEditAcademicYear: false,
    permissionDeleteAcademicYear: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permisionAddEducationLevelDivision: checkRolePermissions(
        "add-education-level-division",
        "activity"
      ),
      permissionEditAcademicYear: checkRolePermissions(
        "edit-academic-year",
        "activity"
      ),
      permissionDeleteAcademicYear: checkRolePermissions(
        "delete-academic-year",
        "activity"
      ),
    });
  };

  render() {
    const { educationLevels } = this.props;
    return (
      <>
        {educationLevels
          ? educationLevels.map((eduLvl, idx) => {
              return (
                <div key={idx} className="tt-semester-background">
                  <div className="tt-group-header">
                    {eduLvl.name}
                    <span
                      style={{
                        float: "right",
                        position: "relative",
                        top: "-4px",
                      }}
                    >
                      {this.state.permisionAddEducationLevelDivision ? (
                        <button
                          className="tt-button tt-button-primary"
                          onClick={(e) =>
                            this.props.handleAddTerms(
                              e,
                              "open",
                              eduLvl,
                              this.props.academicYear
                            )
                          }
                        >
                          Add {this.props.termAlias}
                        </button>
                      ) : null}
                    </span>
                  </div>
                  <table className="table table-striped table-bordered">
                    <thead className="tt-group-header">
                      <tr>
                        <th width="20%">{this.state.termAlias}</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th width="20%">Weekday</th>
                        {this.state.permissionEditAcademicYear ||
                        this.state.permissionDeleteAcademicYear ? (
                          <th width="10%">Options</th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {eduLvl.educationLevelDivisions
                        ? eduLvl.educationLevelDivisions.map(
                            (eduLvlDiv, index) => {
                              return (
                                <tr key={index}>
                                  <td width="20%">
                                    {eduLvlDiv.isEditable ? (
                                      <input
                                        type="text"
                                        value={this.props.updatedName}
                                        className="form-control"
                                        name="updatedName"
                                        onChange={this.props.handleChange}
                                      />
                                    ) : (
                                      eduLvlDiv.name
                                    )}
                                  </td>
                                  <td width="15%">
                                    {eduLvlDiv.isEditable ? (
                                      <input
                                        type="date"
                                        value={moment(
                                          this.props.updatedStartDate
                                        ).format("YYYY-MM-DD")}
                                        onChange={this.props.handleChange}
                                        name="updatedStartDate"
                                        className="form-control"
                                      />
                                    ) : (
                                      <>
                                        <div>
                                          {formatDate(eduLvlDiv.startDate)}
                                        </div>
                                        <div>
                                          {displayNepaliDate(
                                            eduLvlDiv.startDate
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td width="15%">
                                    {eduLvlDiv.isEditable ? (
                                      <input
                                        type="date"
                                        value={moment(
                                          this.props.updatedEndDate
                                        ).format("YYYY-MM-DD")}
                                        onChange={this.props.handleChange}
                                        name="updatedEndDate"
                                        className="form-control"
                                      />
                                    ) : (
                                      <>
                                        <div>
                                          {formatDate(eduLvlDiv.endDate)}
                                        </div>
                                        <div>
                                          <div>
                                            {displayNepaliDate(
                                              eduLvlDiv.endDate
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td width="20%">
                                    {eduLvlDiv.isEditable ? (
                                      this.props.weekDays ? (
                                        this.props.weekDays.map(
                                          (day, weekIdx) => (
                                            <div
                                              className="row tt-weekday-wrapper"
                                              key={weekIdx}
                                            >
                                              <input
                                                type="checkbox"
                                                checked={day.checked}
                                                value={day.id}
                                                onChange={(e) =>
                                                  this.props.handleChangeForCheckBox(
                                                    e,
                                                    day.id
                                                  )
                                                }
                                              />
                                              <span className="ml-2">
                                                {day.name}
                                              </span>
                                            </div>
                                          )
                                        )
                                      ) : null
                                    ) : (
                                      <Weekday weekday={eduLvlDiv.weekDays} />
                                    )}
                                  </td>
                                  {this.state.permissionEditAcademicYear ||
                                  this.state.permissionDeleteAcademicYear ? (
                                    <td width="10%" className="text-center">
                                      {eduLvlDiv.isEditable ? (
                                        <>
                                          <button
                                            className="tt-button tt-button-primary tt-button-fullWidth"
                                            onClick={(e) =>
                                              this.props.handleUpdateEducationLevelDivision(
                                                e,
                                                eduLvlDiv.id,
                                                this.props.academicYearId,
                                                this.props.academicYearIndex
                                              )
                                            }
                                          >
                                            Save
                                          </button>
                                          <button
                                            className="tt-button tt-button-primary tt-button-fullWidth mt-2"
                                            onClick={(e) =>
                                              this.props.handleCancelEdit(
                                                e,
                                                this.props.mainIndex,
                                                idx,
                                                index
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {this.state
                                            .permissionEditAcademicYear ? (
                                            <button
                                              className="tt-button tt-button-primary tt-button-fullWidth"
                                              onClick={(e) =>
                                                this.props.handleUpdate(
                                                  e,
                                                  this.props.mainIndex,
                                                  idx,
                                                  index
                                                )
                                              }
                                            >
                                              Edit
                                            </button>
                                          ) : null}
                                        </>
                                      )}
                                      {this.state
                                        .permissionDeleteAcademicYear ? (
                                        <button
                                          className="tt-button tt-button-primary tt-button-fullWidth mt-2"
                                          onClick={(e) =>
                                            this.props.handleDelete(
                                              eduLvlDiv.id,
                                              this.props.academicYearId,
                                              this.props.academicYearIndex
                                            )
                                          }
                                        >
                                          Delete
                                        </button>
                                      ) : null}
                                    </td>
                                  ) : null}
                                </tr>
                              );
                            }
                          )
                        : null}
                    </tbody>
                  </table>
                </div>
              );
            })
          : null}
      </>
    );
  }
}

export default Semester;
