import React, { Component } from "react";
import { ModalFooter } from "reactstrap";

export default class GroupForm extends Component {
  state = {
    groupName: "",
  };
  handleGroupNameChage = (e) => {
    this.setState({ groupName: e.target.value });
  };
  render() {
    return (
      <>
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              value={this.state.groupName}
              onChange={this.handleGroupNameChage}
            />
          </div>
        </div>
        <ModalFooter>
          <button
            className="tt-button tt-button-primary"
            onClick={(e) =>
              this.props.handleSubmitNewGroupBtnClick(e, this.state.groupName)
            }
          >
            Add Group
          </button>
          <button
            className="tt-button tt-button-primary mt-0"
            onClick={this.props.handleCancelGroupBtnClick}
          >
            Cancel
          </button>
        </ModalFooter>
      </>
    );
  }
}
