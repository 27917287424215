import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { checkForSpecialCharacters, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/Utils";
import CentralNavbar from "../FrontPage/NavBar";
import Navbar from "../Profile/Navbar";
import SearchPageHeader from "./SearchPageHeader";
import SearchResult from "./SearchResult";

class SearchPage extends Component {
  state = {
    contentType: "",
    dropdownOpen: false,
    schoolLMSType: "",
    searchData: [],
    searching: false,
    searchText: "",
    searchType: this.props.location?.state?.noNav ? "Resources" : "Group Page",
    setting: false,
    spinner: false,
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "searchText") {
      if (checkForSpecialCharacters(e.target.value)) {
      } else {
        return;
      }
    }
    if (name === "searchType") {
      if (this.state.searchText !== "") {
        this.handleSearch(e, value);
        this.setState({ [name]: value });
      } else {
        this.setState(
          {
            searching: false,
            searchData: [],
            contentType: "",
            schoolLMSType: "",
          },
          function () {
            this.setState({ [name]: value });
          }
        );
      }
    } else if (name === "contentType" || name === "schoolLMSType") {
      if (this.state.searchText !== "") {
        this.setState({ [name]: value }, function () {
          this.handleSearch();
        });
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleSearch = (e, value) => {
    // if (this.state.searchText == "") {
    //   swal("Information", "Please add search texts to search data");
    //   return false;
    // }
    let url = "";
    let param = {};
    let searchType = value ? value : this.state.searchType;
    if (searchType === "Group Page") {
      url = URL.searchGroupProfile;
      param = { searchParam: this.state.searchText };
    } else if (searchType === "User Profile") {
      url = URL.searchUserProfile;
      param = { searchParam: this.state.searchText };
    } else {
      url = URL.searchResources;
      let schoolLmsTypeId = "";
      if (this.state.schoolLMSType !== "") {
        schoolLmsTypeId =
          this.state.schoolLMSType === "curriculum"
            ? 1
            : this.state.schoolLMSType === "textbooks"
            ? 2
            : this.state.schoolLMSType === "question-bank"
            ? 3
            : this.state.schoolLMSType === "av-resource"
            ? 4
            : this.state.schoolLMSType === "sign-language"
            ? 5
            : "";
      }
      param = {
        searchParam: this.state.searchText,
        fileType: this.state.contentType,
        schoolLmsTypeId: schoolLmsTypeId,
      };
    }
    this.setState({ spinner: true, searching: true }, function () {
      axiosPost(
        url,
        param,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            this.setState({ searchData: response.data.data });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  render() {
    return (
      <div>
        {this.props?.location?.state?.noNav ? (
          this.props?.location?.state?.fromDashboard ? null : (
            <CentralNavbar
              noNav={true}
              teacherPage={this.props.location?.state?.teacherPage}
              classPage={this.props.location?.state?.classPage}
            />
          )
        ) : (
          <Navbar
            dropdownOpen={this.state.dropdownOpen}
            toggleMenu={this.toggleMenu}
            openSubMenu={this.openSubMenu}
            nonSystemSetup={false}
          />
        )}

        <div className="container">
          <div className="row">
            <div className="col">
              <div className="tt-group-container" style={{ height: "auto" }}>
                <SearchPageHeader
                  contentType={this.state.contentType}
                  handleChange={this.handleChange}
                  searchType={this.state.searchType}
                  handleSearch={this.handleSearch}
                  searchText={this.state.searchText}
                  schoolLMSType={this.state.schoolLMSType}
                  teacherPage={this.props.location?.state?.teacherPage}
                  classPage={this.props.location?.state?.classPage}
                  spinner={this.state.spinner}
                  searchData={this.state.searchData}
                  searching={this.state.searching}
                />
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col">
              <SearchResult
                spinner={this.state.spinner}
                searchData={this.state.searchData}
                searchType={this.state.searchType}
                contentType={this.state.contentType}
                searching={this.state.searching}
                // handleError={this.handleError}
              />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default SearchPage;
