import React, { Component } from "react";
import "../../assets/scss/navbar.scss";
import "../../assets/scss/ResponsiveScss/responsiveNavbarPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history from "../../utils/History";
import ModalWindow from "../UI/ModalWindow";
import $ from "jquery";
import axios from "axios";
import nepalLogo from "../../assets/images/logo/nepal_logo.png";

import {
  faBars,
  faUser,
  faCog,
  faSignOutAlt,
  faServer,
  faDotCircle,
  faClock,
  faUserCog,
  faUserLock,
  faGraduationCap,
  faCheckCircle,
  faPeopleArrows,
  faSchool,
  faCogs,
  faGlobe,
  faSchoolFlag,
  faSchoolLock,
} from "@fortawesome/free-solid-svg-icons";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import {
  URL,
  profilePeopleId,
  familyId,
  schoolSetup,
  academicYears,
  getLinkedAccount,
  schoolProfileGroup,
} from "../../utils/Constants";
import { Link } from "react-router-dom";
import ChangeAcademicYear from "../AcademicYearHistory/ChangeAcademicYear";
import StaffModalData from "../AcademicAdministration/EducationCommunity/Staff/StaffDataModal";
import ModalBody from "../Admission/AdmissionList/ModalBody";
import ChangePassword from "../Login/ChangePassword";
import {
  userRole,
  userRoleCode,
  rememberMe,
  checkDevAdmin,
} from "./../../utils/Constants";
import AboutUs from "./AboutUs";
import swal from "sweetalert";
import CryptoJS from "crypto-js";
import { displayErrorAlert } from "../../utils/Utils";
import ListGeneralSetting from "../GeneralSetting/ListGeneralSetting";
import GeneralSetting from "../GeneralSetting/GeneralSetting";

class Navbar extends Component {
  state = {
    schoolName: "",
    acYrModal: false,
    admin: false,
    staffModal: false,
    staffModalData: "",
    isStaff: false,
    admissionModal: false,
    admissionListBySelect: "",
    studentContactBySelect: "",
    passwordModal: false,
    aboutUsModal: false,
    acronym: "",
    linkedAccount: [],
    linkedAccountActive: false,
    twoFAmodal: false,
    toggle2FA: localStorage.getItem("is2FAEnabled") === "true" ? true : false,
    canUse2FA: localStorage.getItem("canUse2FA") === "true" ? true : false,
    generalSettingModal: false,
    inputFields: {
      settingKey: "",
      value: "",
      description: "",
      fieldTypeId: "",
      aliasName: "",
    },
    generalSettings: [],
    showSpinner: false,
    editMode: false,
    techSubMenu: false,
  };

  handleAddSetting = () => {
    this.setState({
      addGeneralSettingModal: true,
    });
  };

  handleEditSettingClick = (item) => {
    let inputFields = this.state.inputFields;
    inputFields.settingKey = item.settingKey;
    inputFields.id = item.id;
    inputFields.aliasName = item.aliasName;
    inputFields.description = item.description;
    inputFields.fieldTypeId = item.fieldTypeId;
    inputFields.value = item.value;
    inputFields.settingOptions = item.generalSettingOptionDatas;

    this.setState(
      {
        inputFields: inputFields,
        editMode: true,
      },
      () =>
        this.setState({
          addGeneralSettingModal: true,
        })
    );
  };

  handleUpdateSetting = () => {
    let data = {
      id: this.state.inputFields.id,
      settingKey: this.state.inputFields.settingKey,
      value: this.state.inputFields.value,
    };
    this.setState(
      {
        showSpinner: true,
      },
      () => {
        axiosPost(URL.updateGeneralSetting, data, (response) => {
          if (response.status === 200) {
            this.setState({
              showSpinner: false,
              addGeneralSettingModal: false,
            });
            swal("Success", "Successfully updated.");

            // this.getGeneralSetting();
            window.location.reload();
          }
        });
      }
    );
  };

  toggleAddGeneralSettings = () => {
    this.setState({
      inputFields: {
        settingKey: "",
        value: "",
        description: "",
        fieldTypeId: "",
        aliasName: "",
      },
      addGeneralSettingModal: !this.state.addGeneralSettingModal,
    });
  };

  handleSaveSetting = () => {
    let data = [this.state.inputFields];

    data.fieldTypeId = parseInt(data.fieldTypeId);

    if (
      data.settingKey === "" ||
      data.value === "" ||
      data.description === "" ||
      data.fieldTypeId === "" ||
      data.aliasName === ""
    ) {
      swal("Error", "Fill all the fields");
    } else {
      this.setState(
        {
          showSpinner: true,
        },
        () => {
          axiosPost(URL.storeGeneralSetting, data, (response) => {
            if (response.status === 200) {
              this.setState({
                showSpinner: false,
                inputFields: {
                  settingKey: "",
                  value: "",
                  description: "",
                  fieldTypeId: "",
                  aliasName: "",
                },
                addGeneralSettingModal: false,
              });
              swal("Success", "Successfully added setting.");
              window.location.reload();

              // this.getGeneralSetting();
            }
          });
        }
      );
    }
  };

  handleAddInputFields = () => {
    this.setState({
      inputFields: [
        ...this.state.inputFields,
        {
          name: "",
          status: "",
        },
      ],
    });
  };
  handleRemoveInputFields = (index) => {
    const list = [...this.state.inputFields];
    list.splice(index, 1);
    this.setState({
      inputFields: list,
    });
  };

  handleChangeInputFields = (e, index) => {
    let { name, value } = e.target;
    let newInputs = this.state.inputFields;
    newInputs[name] = value;
    this.setState({
      inputFields: newInputs,
    });
  };

  componentDidMount() {
    this.getSchoolInfo();
    this.getUserInfo();
    this.setLinkedAccount();
    this.getGeneralSetting();
  }

  getGeneralSetting = () => {
    axiosGet(URL.getAllGeneralSetting, (response) => {
      if (response.status === 200) {
        this.setState({
          generalSettings: response.data.data,
        });
      }
      // this.props.coverPictureToggler();
    });
  };

  toggleStaffModal = () => {
    if (this.state.isStaff) {
      this.setState({
        staffModal: !this.state.staffModal,
      });
    } else {
      this.setState({
        admissionModal: !this.state.admissionModal,
      });
    }
  };

  toggleAdmissionModal = () => {
    this.setState({
      admissionModal: !this.state.admissionModal,
    });
  };

  toggleGeneralSettings = () => {
    this.setState({
      generalSettingModal: !this.state.generalSettingModal,
    });
  };

  getAdmissionListBySelect = () => {
    axiosPost(
      URL.selectByKeyAdmissionList,
      {
        peopleId: this.props.peopleId
          ? this.props.peopleId
          : parseInt(profilePeopleId),
      },
      (response) => {
        if (response.status === 200) {
          const admissionListBySelect =
              response.data.data.people.customTableFieldValues,
            studentContactBySelect = response.data.data.studentContacts;

          this.setState({ admissionListBySelect, studentContactBySelect });
        }
      }
    );
  };

  getUserInfo() {
    let data = {
      peopleId: this.props.peopleId
        ? this.props.peopleId
        : parseInt(profilePeopleId),
    };
    if (userRole !== userRoleCode.roleAdmin) {
      axiosPost(URL.admissionList, data, (response) => {
        if (
          response.status === 200 &&
          response.data.data !== null &&
          response.data.data.length > 0
        ) {
          this.setState({
            admissionModalData: response.data.data[0],
            isStaff: false,
            admin: false,
          });

          this.getAdmissionListBySelect();
        } else {
          axiosPost(URL.getStaffList, data, (response) => {
            if (
              response.status === 200 &&
              response.data.data !== null &&
              response.data.data.length > 0
            ) {
              this.setState({
                staffModalData: response.data.data,
                isStaff: true,
                admin: false,
              });
            } else {
              this.setState({ admin: true, isStaff: false });
            }
          });
        }
      });
    } else {
      this.setState({ admin: true, isStaff: false });
    }
  }

  staffDataModal = () => {
    return (
      <StaffModalData
        staffModalData={this.state.staffModalData[0]}
      ></StaffModalData>
    );
  };

  getSchoolInfo = () => {
    axiosPost(URL.selectSchoolInfo, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState({
            logoSpinner: false,
            schoolName: response.data.data?.name,
            acronym: response.data.data?.acronym,
            schoolImage: response.data.data?.imageName
              ? URL.imageSelectURL + response.data.data?.imageName
              : null,
            logoLocation: response.data.data?.logoLocation,
          });
        }
      }
    });
  };

  handleLogout = () => {
    this.deleteUserAcademicYear();
  };

  clearLocalStorage = () => {
    let keysToRemove = [
      "token",
      "refreshToken",
      "role",
      "peopleId",
      "mainUserId",
      "isCoordinator",
      "isHomeRoomTeacher",
      "peopleName",
      "educationLevelDivision",
      "yearLevel",
      "classroom",
      "profilePeopleId",
      "familyId",
      "permissions",
      "widgets",
      "subRoles",
      "schoolSetup",
      "academicYears",
      "associatedUsers",
      "substituteTeacherRolePermissions",
      "isFirstLogin",
      "academicYearId",
      "is2FAEnabled",
      "canUse2FA",
      "otpVerified",
      "educationCommunityGroupName",
      "roleName",
      "schoolId",
    ];
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
    if (rememberMe === "false") {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    }
    history.push(`${process.env.PUBLIC_URL}/school-login`);
  };

  deleteUserAcademicYear = () => {
    axiosPost(URL.deleteUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        axios
          .post(URL.logout, {})
          .then((res) => {
            if (res.status === 200) {
              this.clearLocalStorage();
            }
          })
          .catch((err) => {
            displayErrorAlert(err);
          });
      }
    });
  };

  toggleChangeAcYrModal = () => {
    this.setState({ acYrModal: !this.state.acYrModal });
  };

  changeAcademicYear = () => {
    this.toggleChangeAcYrModal();
  };

  togglePasswordModal = () => {
    this.setState({ passwordModal: !this.state.passwordModal });
  };

  openAboutUsModal = () => {
    this.setState({ aboutUsModal: !this.state.aboutUsModal }, function () {
      if (this.state.aboutUsModal) {
        var self = this;
        $(document).keypress(function (event) {
          var keycode = event.keyCode ? event.keyCode : event.which;
          if (keycode == "13") {
            self.setState({ aboutUsModal: false });
          }
        });
      }
    });
  };

  setLinkedAccount = () => {
    let linkedAccount = getLinkedAccount();
    this.setState({ linkedAccount });
  };

  openLinkedAccountSubMenu = () => {
    this.setState({ linkedAccountActive: !this.state.linkedAccountActive });
  };

  openSchoolSubMenu = () => {
    this.setState({ techSubMenu: !this.state.techSubMenu }, function () {
      let subMenu = document.getElementById("tt-subMenu1");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  handleAssociateUer = (acc, id) => {
    axiosPost(
      URL.getAssociatedLink,
      {
        username: acc.username,
      },
      (response) => {
        if (response.status === 200) {
          if (
            response.data.data.message === null ||
            response.data.data.message === ""
          ) {
            localStorage.setItem("token", response.data.data.access_token);
            // localStorage.setItem("schoolSetup", response.data.data.schoolSetup);
            localStorage.setItem("schoolSetup", true);
            localStorage.setItem(
              "academicYears",
              response.data.data.academicYears
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.refresh_token
            );

            localStorage.setItem(
              "isFirstLogin",
              response.data.data.isFirstLogin
            );
            localStorage.setItem(
              "widgets",
              CryptoJS.AES.encrypt(
                JSON.stringify(
                  response.data.data.academicPermissionDto.widgets
                ),
                "saurav_imagine_sys"
              )
            );
            localStorage.setItem(
              "substituteTeacherRolePermissions",
              CryptoJS.AES.encrypt(
                JSON.stringify(
                  response.data.data.substituteTeacherRolePermissions
                ),
                "saurav_imagine_sys"
              )
            );

            localStorage.setItem(
              "academicYearId",
              response.data.data.academicPermissionDto.academicYearId
            );
            localStorage.setItem(
              "subRoles",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data.subRoles),
                "saurav_imagine_sys"
              )
            );
            if (response.data.data.associatedUsers) {
              localStorage.setItem(
                "associatedUsers",
                CryptoJS.AES.encrypt(
                  JSON.stringify(response.data.data.associatedUsers),
                  "saurav_imagine_sys"
                )
              );
            }

            localStorage.setItem(
              "role",
              CryptoJS.AES.encrypt(
                response.data.data.userRoleCode,
                "saurav_imagine_sys"
              ).toString()
            );
            localStorage.setItem("peopleId", response.data.data.peopleId);
            localStorage.setItem(
              "profilePeopleId",
              response.data.data.peopleId
            );
            localStorage.setItem("familyId", response.data.data.familyId);
            localStorage.setItem("mainUserId", response.data.data.userId);
            if (this.state.rememberMe) {
              localStorage.setItem(
                "username",
                CryptoJS.AES.encrypt(
                  this.state.username,
                  "saurav_imagine_sys"
                ).toString()
              );
              localStorage.setItem(
                "password",
                CryptoJS.AES.encrypt(
                  this.state.password,
                  "saurav_imagine_sys"
                ).toString()
              );
            }
            //this.getRecruitmentIdPromise(response.data.peopleId);
            this.setState({ showSpinner: false });
            if (response.data.data.userRoleCode === "RL_STC") {
              history.push(
                `${process.env.PUBLIC_URL}/familyPage/${response.data.data.familyId}`
              );
            } else {
              response.data.data.schoolSetup
                ? response.data.data.academicYears
                  ? history.push(`${process.env.PUBLIC_URL}/profile/`)
                  : history.push(`${process.env.PUBLIC_URL}/academicYears`)
                : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
            }
          } else {
            swal({
              title: "Warning",
              text: response.data.message,
              allowOutsideClick: false,
              closeOnClickOutside: false,
              buttons: true,
              dangerMode: true,
            }).then((deleteConfirm) => {
              if (deleteConfirm) {
                localStorage.clear();
              } else {
              }
            });
          }
        }
      },
      (err) => {
        swal({
          title: "Warning",
          text: err.response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
          buttons: true,
          dangerMode: true,
        }).then((deleteConfirm) => {
          if (deleteConfirm) {
            localStorage.clear();
          } else {
          }
        });
      }
    );
  };

  toggleTwoFA = () => {
    this.setState({
      twoFAmodal: !this.state.twoFAmodal,
    });
  };

  handleChangeToggle = (e) => {
    const { name, checked } = e.target;

    let data = {
      is2FaEnabled: e.target.checked,
    };

    axiosPost(
      URL.change2FA,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState(
            {
              [name]: checked,
            },
            () => {
              localStorage.setItem("is2FAEnabled", this.state.toggle2FA);
            }
          );
        }
      },
      (err) => {
        displayErrorAlert(err);
        this.setState({
          toggle2FA: false,
        });
      }
    );

    // this.setState({ [name]: checked }, () => {
    //   let data = {
    //     is2FaEnabled: this.state.toggle2FA,
    //   };
    //   axiosPost(
    //     URL.change2FA,
    //     data,
    //     (response) => {},
    //     (err) => {
    //       displayErrorAlert(err);
    //     }
    //   );
    // });
  };

  componentDidUpdate() {}

  render() {
    return (
      <>
        {/* <div className="trail-period">
          <span className="trail-text">
            This site is for demo purposes only.
          </span>
          <span className="trail-text">Your trail period ends in </span>
          <span className="trail-time">1 day and 10 hrs</span>
        </div> */}

        <div
          className={
            this.props.nonSystemSetup
              ? "container-fluid tt-nav navHeight"
              : "container-fluid tt-nav"
          }
        >
          <div className="row">
            <div className="col">
              <div className="d-flex tt-nav-div">
                <div
                  className={
                    this.state.logoLocation
                      ? "tt-nav-schoolname"
                      : "tt-nav-schoolname landscape"
                  }
                >
                  {/* <img
                  src={nepalLogo}
                  alt="Not Loading"
                  className="img-fluid"
                  onError={(e) => this.handleError(e)}
                /> */}
                  {/* {this.props.nonSystemSetup ? (
                  ""
                ) : this.state.logoSpinner ? (
                  <Spinner color="success" />
                ) : this.state?.schoolImage !== null ? (
                  <img
                    src={this.state.schoolImage}
                    alt="Not Loading"
                    className="img-fluid"
                    onError={(e) => this.handleError(e)}
                  />
                ) : (
                  ""
                )} */}
                  <p
                    className="tt-navbar-heading pl-4"
                    style={
                      this.state.schoolName === "" ? { height: "26px" } : null
                    }
                  >
                    {this.props.title
                      ? this.props.title
                      : `${this.state.schoolName}`}
                  </p>
                  <p className="tt-navbar-heading-responsive">
                    {this.props.title
                      ? this.props.title?.split(" ")[0]
                      : this.state.acronym && this.state.acronym !== ""
                      ? this.state.acronym
                      : this.state.schoolName?.split(" ")[0]}
                  </p>
                </div>
                {/* <div className="mr-5">
                <input
                  className="form-control cusInput"
                  style={{ position: "relative" }}
                ></input>
                <div
                  style={{ position: "absolute", top: "15px", right: "105px" }}
                >
                  <button
                    style={{
                      border: "0",
                      backgroundColor: "#27739F",
                      borderRadius: "50%"
                    }}
                  >
                    <FaSearch />
                  </button>
                </div>
              </div> */}
                <div
                  className={
                    this.props.nonSystemSetup
                      ? "tt-menu-bar menuBarHeight"
                      : "tt-menu-bar"
                  }
                >
                  <Dropdown
                    className="dropdown-trigger"
                    isOpen={this.props.dropdownOpen}
                    toggle={this.props.toggleMenu}
                  >
                    <DropdownToggle>
                      <FontAwesomeIcon icon={faBars} />
                    </DropdownToggle>
                    <DropdownMenu className="tt-dropdown-content">
                      <>
                        {/* <Link to={"/"} className="menu-link mobileNavbarMenu">
                        <DropdownItem className="tt-dropdown-button">
                          <span className="tt-dropdnItem">Go to Website</span>
                          <span className="badge">
                            <FontAwesomeIcon icon={faGlobe} />
                          </span>
                        </DropdownItem>
                      </Link> */}
                        {window.location.pathname ===
                        "/profile" ? null : schoolSetup === "true" &&
                          academicYears === "true" &&
                          !this.props.nonSystemSetup ? (
                          <Link
                            to={
                              userRole === "RL_STC"
                                ? `/familyPage/${familyId}`
                                : "/profile"
                            }
                          >
                            <DropdownItem className="tt-dropdown-button">
                              <span className="tt-dropdnItem">My Profile</span>

                              <span className="badge">
                                <FontAwesomeIcon icon={faUser} />
                              </span>
                            </DropdownItem>
                          </Link>
                        ) : null}

                        {schoolSetup === "true" &&
                        academicYears === "true" &&
                        !this.props.nonSystemSetup ? (
                          <Link
                            to={
                              schoolProfileGroup
                                ? `/groupPage/${schoolProfileGroup.replace(
                                    / /g,
                                    "_"
                                  )}`
                                : "/"
                            }
                            className="menu-link mobileNavbarMenu"
                          >
                            <DropdownItem className="tt-dropdown-button">
                              <span className="tt-dropdnItem">
                                School Profile
                              </span>
                              <span className="badge">
                                <FontAwesomeIcon icon={faSchool} />
                              </span>
                            </DropdownItem>
                          </Link>
                        ) : null}

                        {schoolSetup === "true" &&
                        academicYears === "true" &&
                        !this.props.nonSystemSetup ? (
                          <DropdownItem
                            className="tt-dropdown-button mobileNavbarMenu"
                            onClick={this.changeAcademicYear}
                          >
                            <span className="tt-dropdnItem">
                              Change
                              <br />
                              Academic Year
                            </span>

                            <span className="badge">
                              <FontAwesomeIcon icon={faClock} />
                            </span>
                          </DropdownItem>
                        ) : null}
                        {/* <DropdownItem divider /> */}
                        {/* <Link
                        to={"/technical-schools"}
                        className="menu-link mobileNavbarMenu"
                      >
                        <DropdownItem
                          className="tt-dropdown-button"
                          // onClick={this.openSchoolSubMenu}
                          // active={this.state.techSubMenu ? true : false}
                        >
                          <span className="tt-dropdnItem">
                            Technical Schools
                          </span>
                          <span className="badge">
                            <FontAwesomeIcon icon={faSchoolFlag} />
                          </span>
                        </DropdownItem>
                      </Link> */}
                        {/* <div id="tt-subMenu1">
                        <Link
                          to={"/agriculture"}
                          className="menu-link mobileNavbarMenu"
                        >
                          <DropdownItem
                            className="tt-dropdown-button"
                            style={{ justifyContent: "start" }}
                          >
                            <span className="badge">
                              <FontAwesomeIcon icon={faDotCircle} />
                            </span>
                            <span className="tt-dropdnItem">Agriculture</span>
                          </DropdownItem>
                        </Link>
                        <Link
                          to={"/civil"}
                          className="menu-link mobileNavbarMenu"
                        >
                          <DropdownItem
                            className="tt-dropdown-button"
                            style={{ justifyContent: "start" }}
                          >
                            <span className="badge">
                              <FontAwesomeIcon icon={faDotCircle} />
                            </span>
                            <span className="tt-dropdnItem">Civil</span>
                          </DropdownItem>
                        </Link>
                        <Link
                          to={"/electrical"}
                          className="menu-link mobileNavbarMenu"
                        >
                          <DropdownItem
                            className="tt-dropdown-button"
                            style={{ justifyContent: "start" }}
                          >
                            <span className="badge">
                              <FontAwesomeIcon icon={faDotCircle} />
                            </span>
                            <span className="tt-dropdnItem">Electrical</span>
                          </DropdownItem>
                        </Link>
                      </div> */}

                        {schoolSetup === "true" &&
                        academicYears === "true" &&
                        !this.props.nonSystemSetup ? (
                          <DropdownItem divider />
                        ) : null}
                        {this.props.nonSystemSetup ? null : (
                          <DropdownItem
                            className="tt-dropdown-button"
                            toggle={false}
                            onClick={this.props.openSubMenu}
                            active={this.props.setting ? true : false}
                          >
                            <span className="tt-dropdnItem">Settings</span>
                            <span className="badge">
                              <FontAwesomeIcon icon={faCog} />
                            </span>
                          </DropdownItem>
                        )}

                        <div id="tt-subMenu">
                          <DropdownItem
                            className="tt-dropdown-button"
                            style={{ justifyContent: "start" }}
                            onClick={() => {
                              this.setState({ passwordModal: true });
                            }}
                          >
                            <span className="badge">
                              <FontAwesomeIcon icon={faDotCircle} />
                            </span>
                            <span className="tt-dropdnItem">
                              Change Password
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            className="tt-dropdown-button"
                            style={{ justifyContent: "start" }}
                            onClick={() => {
                              this.setState({ twoFAmodal: true });
                            }}
                          >
                            <span className="badge">
                              <FontAwesomeIcon icon={faDotCircle} />
                            </span>
                            <span className="tt-dropdnItem">
                              Two-Factor Authentication
                            </span>
                          </DropdownItem>
                          <DropdownItem
                            className="tt-dropdown-button"
                            style={{ justifyContent: "start" }}
                            onClick={() => {
                              this.setState({ generalSettingModal: true });
                            }}
                          >
                            <span className="badge">
                              <FontAwesomeIcon icon={faDotCircle} />
                            </span>
                            <span className="tt-dropdnItem">
                              General Settings
                            </span>
                          </DropdownItem>
                        </div>

                        <DropdownItem divider />
                      </>
                      {this.props.academicYears ? (
                        <Link to={`/systemSetup`} className="menu-link">
                          <DropdownItem className="tt-dropdown-button">
                            <span className="tt-dropdnItem">System Setup</span>
                            <span className="badge">
                              <FontAwesomeIcon icon={faServer} />
                            </span>
                          </DropdownItem>
                        </Link>
                      ) : null}
                      {checkDevAdmin() ? (
                        <>
                          <Link
                            to={"/permissionConfiguration"}
                            className="menu-link mobileNavbarMenu"
                          >
                            <DropdownItem className="tt-dropdown-button">
                              <span className="tt-dropdnItem">Permission</span>

                              <span className="badge">
                                <FontAwesomeIcon icon={faUserLock} />
                              </span>
                            </DropdownItem>
                          </Link>
                          <Link
                            to={"/school-manager"}
                            className="menu-link mobileNavbarMenu"
                          >
                            <DropdownItem className="tt-dropdown-button">
                              <span className="tt-dropdnItem">
                                School Manager
                              </span>

                              <span className="badge">
                                <FontAwesomeIcon icon={faSchoolLock} />
                              </span>
                            </DropdownItem>
                          </Link>
                        </>
                      ) : null}
                      {checkDevAdmin() ? (
                        <Link
                          to={"/arrmanager"}
                          className="menu-link mobileNavbarMenu"
                        >
                          <DropdownItem className="tt-dropdown-button">
                            <span className="tt-dropdnItem">ARR Manager</span>

                            <span className="badge">
                              <FontAwesomeIcon icon={faGraduationCap} />
                            </span>
                          </DropdownItem>
                        </Link>
                      ) : null}
                      {checkDevAdmin() ? (
                        <Link
                          to={"/moderation_manager"}
                          className="menu-link mobileNavbarMenu"
                        >
                          <DropdownItem className="tt-dropdown-button">
                            <span className="tt-dropdnItem">
                              Moderation Manager
                            </span>

                            <span className="badge">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </span>
                          </DropdownItem>
                        </Link>
                      ) : null}
                      {checkDevAdmin() ? (
                        <Link
                          to={"/usermanager"}
                          className="menu-link mobileNavbarMenu"
                        >
                          <DropdownItem className="tt-dropdown-button">
                            <span className="tt-dropdnItem">User Manager</span>

                            <span className="badge">
                              <FontAwesomeIcon icon={faUserCog} />
                            </span>
                          </DropdownItem>
                        </Link>
                      ) : null}
                      {checkDevAdmin() ? (
                        <Link
                          to={"/default_settings"}
                          className="menu-link mobileNavbarMenu"
                        >
                          <DropdownItem className="tt-dropdown-button">
                            <span className="tt-dropdnItem">
                              Default Settings
                            </span>

                            <span className="badge">
                              <FontAwesomeIcon icon={faCogs} />
                            </span>
                          </DropdownItem>
                        </Link>
                      ) : null}

                      {getLinkedAccount().length > 0 ? (
                        <DropdownItem
                          className="tt-dropdown-button"
                          toggle={false}
                          onClick={this.openLinkedAccountSubMenu}
                          active={this.state.linkedAccountActive}
                        >
                          <span className="tt-dropdnItem">Linked Account</span>
                          <span className="badge">
                            <FontAwesomeIcon icon={faPeopleArrows} />
                          </span>
                        </DropdownItem>
                      ) : null}

                      {this.state.linkedAccount.length > 0
                        ? this.state.linkedAccount.map((item, id) => {
                            return (
                              <>
                                <DropdownItem
                                  className="tt-dropdown-button"
                                  id="tt-subMenu-linkedAccount"
                                  style={{
                                    display: this.state?.linkedAccountActive
                                      ? "block"
                                      : "none",
                                  }}
                                  onClick={() =>
                                    this.handleAssociateUer(item, id)
                                  }
                                >
                                  <span className="badge mr-1 p-0">
                                    <FontAwesomeIcon icon={faDotCircle} />
                                  </span>

                                  <span className="tt-dropdnItem">
                                    {item.userRoleCode == userRoleCode.roleStaff
                                      ? "Staff"
                                      : item.userRoleCode ==
                                        userRoleCode.roleStudentContact
                                      ? "Student Contact"
                                      : item.userRoleCode ==
                                        userRoleCode.roleAdmin
                                      ? "Admin"
                                      : "Student"}{" "}
                                    Account
                                    {/* <br /> */}
                                    <div style={{ fontSize: "12px" }}>
                                      ({item.username})
                                    </div>
                                  </span>
                                </DropdownItem>
                              </>
                            );
                          })
                        : null}

                      <DropdownItem
                        className="tt-dropdown-button"
                        onClick={() => this.handleLogout()}
                      >
                        <span className="tt-dropdnItem">Sign Out</span>
                        <span className="badge">
                          <FontAwesomeIcon icon={faSignOutAlt} />
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <ModalWindow
              modal={this.state.acYrModal}
              modalHeader="Academic Year Navigator"
              size="lg"
              toggleModal={this.toggleChangeAcYrModal}
              modalBody={
                <ChangeAcademicYear
                  toggleChangeAcYrModal={this.toggleChangeAcYrModal}
                />
              }
            ></ModalWindow>
            <ModalWindow
              fullWidth={true}
              modal={this.state.staffModal}
              toggleModal={this.toggleStaffModal}
              modalHeader="Profile Information"
              modalClass="tt-peopleList"
              modalBody={this.staffDataModal()}
              modalFooter={
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.toggleStaffModal}
                >
                  Close
                </button>
              }
            ></ModalWindow>
            <ModalWindow
              fullWidth={true}
              modal={this.state.admissionModal}
              toggleModal={this.toggleAdmissionModal}
              modalClass="tt-peopleList"
              modalBody={
                <ModalBody
                  admissionListBySelect={this.state.admissionListBySelect}
                  studentContactBySelect={this.state.studentContactBySelect}
                  admissionModalData={this.state.admissionModalData}
                />
              }
              modalHeader="Profile Information"
            ></ModalWindow>
            <ModalWindow
              modal={this.state.passwordModal}
              modalHeader="Change Password"
              toggleModal={this.togglePasswordModal}
              modalBody={<ChangePassword />}
            ></ModalWindow>

            <ModalWindow
              modal={this.state.twoFAmodal}
              modalHeader="Two-Factor Authentication"
              toggleModal={this.toggleTwoFA}
              modalBody={
                <div className="twoFAModal">
                  <p>
                    Enabling Two-factor authentication (2FA) will require you to
                    login using two steps. 2FA adds more security to your
                    account.
                  </p>
                  {/* <span>Disable</span>{" "} */}
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={this.state.canUse2FA ? false : true}
                      onChange={(e) => this.handleChangeToggle(e)}
                      checked={this.state.toggle2FA}
                      name="toggle2FA"
                    />
                    <span className="slider round"></span>
                  </label>
                  {/* <span>Enable</span> */}
                  <div className="mt-2 text-right">
                    {!this.state.canUse2FA && (
                      <small className="text-danger">
                        *Please add your email address to use this feature!!
                      </small>
                    )}
                  </div>
                </div>
              }
            ></ModalWindow>

            <ModalWindow
              modal={this.state.aboutUsModal}
              size="lg"
              toggleModal={this.openAboutUsModal}
              modalBody={<AboutUs></AboutUs>}
              noModelHeader={true}
              modalClass="aboutUsModal"
            ></ModalWindow>

            <ModalWindow
              modal={this.state.generalSettingModal}
              size="xl"
              modalHeader="General Setting"
              toggleModal={this.toggleGeneralSettings}
              modalBody={
                <ListGeneralSetting
                  handleAddSetting={this.handleAddSetting}
                  generalSettings={this.state.generalSettings}
                  handleEditSettingClick={this.handleEditSettingClick}
                />
              }
            ></ModalWindow>

            <ModalWindow
              modal={this.state.addGeneralSettingModal}
              size="lg"
              modalHeader={
                this.state.editMode ? "Update Setting" : "Add Setting"
              }
              toggleModal={this.toggleAddGeneralSettings}
              modalBody={
                <GeneralSetting
                  inputFields={this.state.inputFields}
                  handleChangeInputFields={(e) =>
                    this.handleChangeInputFields(e)
                  }
                  handleSaveSetting={this.handleSaveSetting}
                  showSpinner={this.state.showSpinner}
                  editMode={this.state.editMode}
                  handleUpdateSetting={this.handleUpdateSetting}
                />
              }
            ></ModalWindow>
          </div>
        </div>
      </>
    );
  }
}

export default Navbar;
