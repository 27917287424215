import moment from "moment";
import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../../../utils/AxiosApi";
import {
  checkRolePermissions,
  draggable,
  URL,
} from "../../../../../utils/Constants";
import { displayErrorAlert } from "../../../../../utils/Utils";
import ModalWindow from "../../../../UI/ModalWindow";
import CourseClassResource from "../CourseClassResources/CourseClassResource";
import CourseClassResources from "../CourseClassResources/CourseResourceTemplate";
import SubmissionListAssignment from "./SubmissionListAssignment";
import { Spinner } from "reactstrap";

class AssignmentDropbox extends Component {
  state = {
    assignments: [],
    resourceModal: false,
    resources: [],
    selectedId: "",
    submissionModal: false,
    showSpinner: false,
    submissionListModal: false,
  };

  componentDidMount() {
    this.getAssignments();
  }

  getAssignments = () => {
    let data = {
      courseClassId: this.props.selectedClassId,
    };
    axiosPost(URL.getCourseClassAssignment, data, (res) => {
      if (res.status === 200) {
        this.setState({ assignments: res.data.data });
      }
    });
  };

  handleResourceModal = (resources) => {
    this.setState({
      resources: resources,
      resourceModal: !this.state.resourceModal,
    });
  };

  toggleResourceModal = () => {
    this.setState({ resourceModal: !this.state.resourceModal, resources: [] });
  };

  handleSubmissionModal = (id) => {
    this.setState({
      submissionModal: !this.state.submissionModal,
      selectedId: id,
    });
  };

  toggleSubmissionModal = () => {
    this.setState({
      submissionModal: !this.state.submissionModal,
      selectedId: "",
    });
  };

  handleSubmissionList = (id) => {
    this.setState({
      submissionListModal: !this.state.submissionListModal,
      selectedId: id,
    });
  };

  toggleSubmissionList = () => {
    this.setState({
      submissionListModal: !this.state.submissionListModal,
      selectedId: "",
    });
  };

  submitAssignment = (contents, links) => {
    let formData = new FormData();
    for (const key of Object.keys(contents)) {
      formData.append("contents", contents[key]);
    }
    formData.append(
      "jsonData",
      JSON.stringify({
        links: links,
        peopleId: localStorage.getItem("peopleId"),
        courseClassAssignmentId: this.state.selectedId,
      })
    );
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.submitAssignment,
        formData,
        (response) => {
          if (response.status === 200) {
            swal({
              title: "Success",
              text: "Assignment successfully submitted",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            draggable();
            this.setState({ showSpinner: false });
            this.getAssignments();
            this.toggleSubmissionModal();
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayErrorAlert(error);
        }
      );
    });
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}

        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "87.5vh" }}
        >
          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th width="210px">Assignment Title</th>
                <th>Description</th>
                <th width="173px">Submission Date Line</th>
                <th width="140px">Resources</th>
                {checkRolePermissions(
                  "can_submit_program_subject_assignment",
                  "activity"
                ) ||
                checkRolePermissions(
                  "show_program_subject_assignment_submission_list",
                  "activity"
                ) ? (
                  <th width="165px">Option</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {this.state.assignments.length > 0 ? (
                this.state.assignments.map((assignment, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{assignment.title}</td>
                      <td>{assignment.description}</td>
                      <td className="text-center">
                        {moment(assignment.submissionDateLine).format("LL")}
                      </td>
                      <td className="text-center">
                        {assignment.courseClassResources &&
                        assignment.courseClassResources.length > 0 ? (
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() =>
                              this.handleResourceModal(
                                assignment.courseClassResources
                              )
                            }
                          >
                            Resources
                          </button>
                        ) : (
                          "No Resources added"
                        )}
                      </td>
                      {checkRolePermissions(
                        "can_submit_program_subject_assignment",
                        "activity"
                      ) ||
                      checkRolePermissions(
                        "show_program_subject_assignment_submission_list",
                        "activity"
                      ) ? (
                        <td className="text-center">
                          {checkRolePermissions(
                            "can_submit_program_subject_assignment",
                            "activity"
                          ) ? (
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() =>
                                this.handleSubmissionModal(assignment.id)
                              }
                            >
                              Submit Assignment
                            </button>
                          ) : null}
                          {checkRolePermissions(
                            "show_program_subject_assignment_submission_list",
                            "activity"
                          ) ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth"
                              onClick={() =>
                                this.handleSubmissionList(assignment.id)
                              }
                            >
                              Submission List
                            </button>
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={
                      checkRolePermissions(
                        "can_submit_program_subject_assignment",
                        "activity"
                      ) ||
                      checkRolePermissions(
                        "show_program_subject_assignment_submission_list",
                        "activity"
                      )
                        ? 5
                        : 4
                    }
                    className="text-center"
                  >
                    No assignments
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ModalWindow
            modal={this.state.resourceModal}
            toggleModal={this.toggleResourceModal}
            modalHeader="Resources"
            fullWidth={true}
            modalBody={
              <CourseClassResources
                selectedResources={this.state.resources}
              ></CourseClassResources>
            }
          ></ModalWindow>
          <ModalWindow
            modal={this.state.submissionModal}
            toggleModal={this.toggleSubmissionModal}
            modalHeader="Submit Assignment"
            size="lg"
            modalBody={
              <CourseClassResource
                submission={true}
                toggleAddResourceModal={this.toggleSubmissionModal}
                submitAssignment={this.submitAssignment}
                selectedId={this.state.selectedId}
              ></CourseClassResource>
            }
          ></ModalWindow>
          <ModalWindow
            modal={this.state.submissionListModal}
            toggleModal={this.toggleSubmissionList}
            modalHeader="Submission List"
            size="lg"
            modalBody={
              <SubmissionListAssignment
                selectedId={this.state.selectedId}
                toggleSubmissionList={this.toggleSubmissionList}
              ></SubmissionListAssignment>
            }
          ></ModalWindow>
        </div>
      </>
    );
  }
}

export default AssignmentDropbox;
