import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import primaryArrReducer from "./ducks/PrimaryARRDucks";
import academicAdministrationReducer from "./ducks/AcademicAdministrationDucks";
import recruitmentReducer from "./ducks/RecruitmentDucks";
import admissionReducer from "./ducks/AdmissionDucks";
import postsReducer from "./ducks/PostsDucks";
import loginReducer from "./ducks/LogInDucks";
import mcqReducer from "./ducks/MCQDucks";
import flashCardReducer from "./ducks/FlashCardDucks";
import lmsReducer from "./ducks/LearningManagementSystemDucks";
import languageReducer from "./ducks/LanguageDucks.js";
import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  primaryArr: primaryArrReducer,
  academicAdministration: academicAdministrationReducer,
  recruitment: recruitmentReducer,
  admission: admissionReducer,
  posts: postsReducer,
  login: loginReducer,
  mcq: mcqReducer,
  flashCard: flashCardReducer,
  lms: lmsReducer,
  languageVal: languageReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const allStoreEnhancers = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, allStoreEnhancers);

export default store;
