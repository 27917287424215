import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { URL, userRoleCode } from "../../../utils/Constants";
import { Link } from "react-router-dom";
import userImage from "../../../assets/images/user-image.png";
import { handleError } from "../../../utils/Utils";
import Pagination from "../../UI/Pagination";

class GroupEditMember extends Component {
  state = {};
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "78vh" }}>
        <table className="table table-bordered text-center table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>Image</th>
              <th>Member Name</th>
              <th>User Role</th>
              <th width="20%">Group Role</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {this.props.groupDetail?.groupPeople?.length > 0 ? (
              this.props.groupDetail?.groupPeople?.map((member, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {" "}
                      <div className="tt-ppHolder">
                        <img
                          src={
                            member.photo
                              ? URL.imageSelectURL + member.photo
                              : userImage
                          }
                          onError={(e) => handleError(e)}
                          alt="group"
                        ></img>
                      </div>
                    </td>
                    <td>
                      {member.userRole === userRoleCode.roleStudentContact ? (
                        <Link
                          to={{
                            pathname: `/familyPage/${member.familyId}`,
                            familyPageOpen: true,
                            userId: member.userId,
                          }}
                        >
                          <strong>{member.name}</strong>
                        </Link>
                      ) : (
                        <Link
                          to={{
                            pathname: `/profile/${member.username}`,
                            username: member.username,
                          }}
                        >
                          <strong>{member.name}</strong>
                        </Link>
                      )}
                    </td>
                    <td>{member.roleCode}</td>
                    <td>
                      {member.editable ? (
                        <select
                          className="form-control"
                          name="selectedGroupRole"
                          value={this.props.selectedGroupRole}
                          onChange={this.props.handleSelectChange}
                        >
                          <option value="" disabled>
                            Choose group role
                          </option>
                          {this.props.groupRoles.map((groupRole, idx) => {
                            let val = `${groupRole.code},${groupRole.name}`;
                            return (
                              <option value={val} key={idx}>
                                {groupRole.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        member.groupRoleName
                      )}
                    </td>
                    <td>
                      {member.editable ? (
                        <>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={(e) =>
                              this.props.updateGroupRole(e, member.peopleId)
                            }
                          >
                            Save
                          </button>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={this.props.cancelEditGroupRoleField}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={(e) =>
                              this.props.editGroupRoleField(e, idx)
                            }
                          >
                            Edit role
                          </button>
                          {this.props.groupDetail.createdByPeopleId ===
                          member.peopleId ? (
                            ""
                          ) : (
                            <button
                              className="tt-button tt-button-primary"
                              onClick={(e) =>
                                this.props.deleteGroupMember(e, member.peopleId)
                              }
                            >
                              Remove
                            </button>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  {this.props.editMemberGroupSpinner ? (
                    <>
                      <Spinner color="primary" />
                      <br />
                      Loading Members ...
                    </>
                  ) : (
                    "No members available in this group"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Pagination
            itemsCount={this.props.totalRecordsCount}
            pageSize={this.props.rowCountLimit}
            currentPage={this.props.currentPage}
            onPageChange={this.props.handlePageChange}
            previousPage={this.props.handlePreviousPage}
            nextPage={this.props.handleNextPage}
          />
        </div>
      </div>
    );
  }
}

export default GroupEditMember;
