import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { Formik, FieldArray, Field, Form, ErrorMessage } from "formik";
import { EducationLevelValidationSchema } from "./EducationFormValidationSchema";

import { TextField } from "../../UI/TextField";

class EducationLevelForm extends Component {
  state = {
    educationLevelForms: {
      educationForm: [{ name: "", code: "" }]
    },
    error: false,
    showSpinner: false
  };

  handleChange = (e, idx) => {
    let educationLevelForms = [...this.state.educationLevelForms];
    let name = e.target.name;
    let value = e.target.value;
    educationLevelForms[idx][name] = value;
    this.setState({ educationLevelForms });
  };

  handleSave = values => {
    this.setState({
      showSpinner: true
    });
    axiosPost(URL.insertEducationLevel, values?.educationForm, response => {
      if (response.status == 200) {
        this.setState({
          educationLevelForms: [{ name: "", code: "" }],
          showSpinner: false
        });
        swal("Success", response.data.message);
        this.props.getAllEducationLevels();
        this.props.toggleModal();
      }
    });
    this.setState({ showSpinner: false });
  };
  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}

        <Formik
          initialValues={this.state.educationLevelForms}
          validationSchema={EducationLevelValidationSchema}
          onSubmit={values => this.handleSave(values)}
        >
          {({ values }) => {
            return (
              <Form>
                <FieldArray
                  name="educationForm"
                  render={({ remove, push }) => {
                    return (
                      <>
                        {values.educationForm.length > 0 &&
                          values.educationForm.map((value, index) => (
                            <div className="row" key={index}>
                              <div className="col-lg-5">
                                <TextField
                                  name={`educationForm.${index}.name`}
                                  type="text"
                                  placeholder="Enter name here"
                                />
                              </div>
                              <div className="col-lg-5">
                                <TextField
                                  name={`educationForm.${index}.code`}
                                  type="text"
                                  placeholder="Enter code here"
                                />
                                {/* <Field
                                  name={`educationForm.${index}.code`}
                                  className="form-control cusInput"
                                  type="text"
                                  placeholder="Enter code here"
                                />
                                <ErrorMessage
                                  name={`educationForm.${index}.code`}
                                /> */}
                              </div>
                              <div className="col-lg-2 mt-1">
                                {index > 0 ? (
                                  <span
                                    className="material-icons-round text-danger pointer"
                                    aria-hidden="true"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => remove(index)}
                                  >
                                    remove_circle
                                  </span>
                                ) : null}
                                <span
                                  className="material-icons-round text-success pointer"
                                  style={{ cursor: "pointer" }}
                                  aria-hidden="true"
                                  onClick={() => push({ name: "", code: "" })}
                                >
                                  add_circle
                                </span>
                              </div>
                            </div>
                          ))}
                      </>
                    );
                  }}
                />

                <div className="row mt-3">
                  <div className="col text-end">
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize"
                      // onClick={this.handleSave}
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default EducationLevelForm;
