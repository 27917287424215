import React, { Component } from "react";
import Chip from "@material-ui/core/Chip";

export default class NameDisplayFormat extends Component {
  render() {
    return (
      <>
        <div>
          <div className="tt-group-header">Name Display Formats</div>
          <hr />
          In reports:
          <h6 className="in-report">
            <strong>{this.props.showNameFormat()}</strong>
          </h6>
          <hr />
          <div className="name-tags-edit">
            {this.props.editableNameTags.map((tag, id) => (
              <>
                <Chip
                  color={tag.isLiteral ? "secondary" : ""}
                  className="override-chip"
                  label={tag.fieldName}
                  onDelete={() =>
                    this.props.handleDeleteEditableNameTags(tag, id)
                  }
                />
              </>
            ))}
          </div>
          <div className="name-tags-container">
            {this.props.labelsCopy.map((label, id) => (
              <>
                <Chip
                  className="override-chip"
                  label={label.fieldName}
                  clickable
                  color="primary"
                  onClick={() => this.props.handleEditableNameTags(label, id)}
                />
                {`  `}
              </>
            ))}
            {this.props.initialCopy.map((initCopy, id) => (
              <>
                <Chip
                  className="override-chip"
                  label={initCopy.fieldName}
                  clickable
                  color="primary"
                  onClick={() =>
                    this.props.handleEditableNameInitialTags(initCopy, id)
                  }
                />{" "}
              </>
            ))}

            <input
              id="reportLiteral"
              className="literal-input"
              onKeyPress={(e) => this.props.handleLiterals(e, "report")}
              placeholder="Add custom input and press enter key"
            />
          </div>
          <div className="form-group optional-field">
            <input
              className="mandatory-checkbox"
              type="checkbox"
              name="salutation"
              value={this.props.salutation}
              onChange={this.props.handleSalutation}
            />{" "}
            <label>
              <h6 className="add-salutation">Add salutations</h6>
            </label>
            <br />
            <input
              className="mandatory-checkbox"
              type="checkbox"
              name="adultSalutation"
              value={this.props.adultSalutation}
              onChange={this.props.handleSalutation}
            />{" "}
            <label>
              <h6 className="add-salutation">Salutations for adults only</h6>
            </label>
          </div>
          <hr />
          In the system:
          <h6 className="in-report">
            <strong>{this.props.showSystemNameFormat()}</strong>
          </h6>
          <hr />
          <div className="name-tags-edit">
            {this.props.systemTags.map((sysTag, id) => (
              <span>
                <Chip
                  color={sysTag.isLiteral ? "secondary" : ""}
                  label={sysTag.fieldName}
                  className="override-chip"
                  onDelete={() =>
                    this.props.handleDeleteEditableNameTags(
                      sysTag,
                      id,
                      "system"
                    )
                  }
                />
              </span>
            ))}
          </div>
          <div className="name-tags-container">
            {this.props.systemLabels.map((sysLabel, id) => (
              <>
                <Chip
                  label={sysLabel.fieldName}
                  className="override-chip"
                  clickable
                  color="primary"
                  onClick={() =>
                    this.props.handleEditableNameTags(sysLabel, id, "system")
                  }
                />
                {`  `}
              </>
            ))}
            {this.props.systemLabelInitials.map((sysLInit, id) => (
              <>
                <Chip
                  className="override-chip"
                  label={sysLInit.fieldName}
                  clickable
                  color="primary"
                  onClick={() =>
                    this.props.handleEditableNameInitialTags(
                      sysLInit,
                      id,
                      "system"
                    )
                  }
                />
                {`  `}
              </>
            ))}
          </div>
        </div>
        <input
          id="systemLiteral"
          type="text"
          className="literal-input"
          onKeyPress={(e) => this.props.handleLiterals(e, "system")}
          placeholder="Add custom input and press enter key"
        />
      </>
    );
  }
}
