import React, { Component } from "react";

class SelectedMembers extends Component {
  state = {};
  render() {
    return (
      <div className="card">
        <div className="card-body tt-selectedMember">
          <div className="tt-group-header">Selected members</div>
          {this.props.selectedStaff.length > 0 ? (
            <>
              <strong>Staffs</strong>
              <table className="table table-bordered text-center table-striped">
                <thead className="tt-group-header">
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          this.props.handleSelectedMemberCheckbox(
                            e,
                            "all",
                            "Staff"
                          )
                        }
                      ></input>
                    </td>
                    <td>Staff name</td>
                    <td>Assigned role</td>
                    <td>Options</td>
                  </tr>
                </thead>
                <tbody>
                  {this.props.selectedStaff ? (
                    this.props.selectedStaff.map((sel, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <input
                              type="checkbox"
                              checked={sel.checked}
                              onChange={(e) =>
                                this.props.handleSelectedMemberCheckbox(
                                  e,
                                  idx,
                                  "Staff"
                                )
                              }
                            ></input>
                          </td>
                          <td>{sel.people.name}</td>
                          <td>
                            {sel.groupRoleName !== "" ? sel.groupRoleName : "-"}
                          </td>
                          <td>
                            <button
                              className="tt-button tt-button-primary"
                              onClick={(e) =>
                                this.props.removeSelectedStaff(e, idx)
                              }
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>Not selected any</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          ) : (
            ""
          )}
          {this.props.selectedStudent.length > 0 ? (
            <>
              <strong>Students</strong>
              <table className="table table-bordered text-center table-striped">
                <thead className="tt-group-header">
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          this.props.handleSelectedMemberCheckbox(
                            e,
                            "all",
                            "Students"
                          )
                        }
                      ></input>
                    </td>
                    <td>Student name</td>
                    <td>Assigned role</td>
                    <td>Options</td>
                  </tr>
                </thead>
                <tbody>
                  {this.props.selectedStudent ? (
                    this.props.selectedStudent.map((sel, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <input
                              type="checkbox"
                              checked={sel.checked}
                              onChange={(e) =>
                                this.props.handleSelectedMemberCheckbox(
                                  e,
                                  idx,
                                  "Students"
                                )
                              }
                            ></input>
                          </td>
                          <td>{sel.people.name}</td>
                          <td>
                            {sel.groupRoleName !== "" ? sel.groupRoleName : "-"}
                          </td>
                          <td>
                            <button
                              className="tt-button tt-button-primary"
                              onClick={(e) =>
                                this.props.removeSelectedStudent(e, idx)
                              }
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>Not selected any</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="tt-group-header tt-assignRole">
          <div className="row">
            <div className="col-md-4 text-center mt-1">Assign role</div>
            <div className="col-md-5">
              <select
                className="form-control"
                name="selectedGroupRole"
                value={this.props.selectedGroupRole}
                onChange={this.props.handleSelectChange}
              >
                <option value="" disabled>
                  Choose group role
                </option>
                {this.props.groupRoles.map((groupRole, idx) => {
                  let val = `${groupRole.code},${groupRole.name}`;
                  return (
                    <option value={val} key={idx}>
                      {groupRole.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-3 mt-1">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.assignRole}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectedMembers;
