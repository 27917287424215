import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  scales: {
    y: {
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [5, 5],
        color: "rgba(255, 255, 255, .1)",
      },
      ticks: {
        suggestedMin: 0,
        // suggestedMax: 500,
        // beginAtZero: true,
        padding: 5,
        font: {
          size: 8,
          weight: 300,
          lineHeight: 2,
        },
        color: "#fff",
      },
    },
    x: {
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [5, 5],
        color: "rgba(255, 255, 255, .1)",
      },
      ticks: {
        display: true,
        color: "#f8f9fa",
        padding: 5,
        font: {
          size: 8,
          weight: 300,
          lineHeight: 2,
        },
      },
    },
  },
};

class CustomChartView extends Component {
  state = {
    data: null,
    totalSchool: 0,
  };

  componentDidMount() {
    if (this.props.summary) {
      this.configSummary();
    } else {
      this.config();
    }
  }

  config = () => {
    if (this.props.chartData && this.props.chartData.length > 0) {
      let chartDatum = this.props.chartData;
      let labels = [];
      let datas = [];
      let totalSchool = 0;
      chartDatum.forEach((el) => {
        labels.push(el.name);
        datas.push(el.totalSchool);
        totalSchool += el.totalSchool;
      });
      labels = labels.slice(0).slice(-5);
      datas = datas.slice(0).slice(-5);

      let chartData = {
        labels,
        datasets: [
          {
            label: "Total Schools",
            data: datas,
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            maxBarThickness: 6,
          },
        ],
      };
      this.setState({ data: chartData, totalSchool });
    }
  };

  configSummary = () => {
    if (this.props.chartData && this.props.chartData.length > 0) {
      let chartDatum = this.props.chartData;
      let labels = [];
      let datas = [];
      chartDatum.forEach((el) => {
        labels.push(el.viewerType);
        datas.push(el.viewCount);
      });
      labels = labels.slice(0).slice(-5);
      datas = datas.slice(0).slice(-5);

      let chartData = {
        labels,
        datasets: [
          {
            label: "Total Views",
            data: datas,
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            maxBarThickness: 6,
          },
        ],
      };
      this.setState({ data: chartData });
    }
  };

  render() {
    return (
      <div className="whiteContainer">
        <div className="cusChartHeader">
          <div
            className={
              "cusChartHolder " +
              (this.props.summary ? "viewSummaryHolder" : "")
            }
          >
            {this.state.data ? (
              <Bar options={options} data={this.state.data} />
            ) : null}
          </div>
          {this.props.summary ? (
            <p className="contentTitle mt-4 cusChartPadding">
              {this.props.title}
            </p>
          ) : (
            <p className="contentTitle mt-4 cusChartPadding">
              {this.state.totalSchool} {this.props.title}
            </p>
          )}
          {/* <p className="contentSubtitle cusChartPadding">
            Last Campaign Performance
          </p> */}
        </div>
        <hr className="cardSeparator"></hr>
        <div className="cusCardContent cusChartPadding">
          <span
            className="material-icons-round mr-2 chartMaterialIcon"
            aria-hidden="true"
          >
            schedule
          </span>
          Updated on every new data added
        </div>
      </div>
    );
  }
}

export default CustomChartView;
