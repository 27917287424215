import { Chip } from "@material-ui/core";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReportMagnifier from "./ReportMagnifier";

class ReportingConfigurationDescriptionBox extends Component {
  state = {
    rowChips: [
      {
        label: "School Name",
        color: "primary",
        param: "schoolName",
      },
      {
        label: "Education Level",
        color: "primary",
        param: "educationLevel",
      },
      {
        label: "Year Level",
        color: "primary",
        param: "yearLevel",
      },
      {
        label: "Classroom",
        color: "primary",
        param: "classroom",
      },
      {
        label: "Student Name",
        color: "primary",
        param: "studentName",
      },
      {
        label: "Roll Number",
        color: "primary",
        param: "rollNumber",
      },
      {
        label: "School Address",
        color: "primary",
        param: "schoolAddress",
      },
      {
        label: "Report Name",
        color: "primary",
        param: "reportName",
      },
      {
        label: "Report Publication Date",
        color: "primary",
        param: "publishingDate",
      },
      {
        label: "Academic Year",
        color: "primary",
        param: "academicYear",
      },
      {
        label: "Student Photo",
        color: "primary",
        param: "studentPic",
      },
      {
        label: "Separator",
        color: "secondary",
        param: "separator",
      },
    ],
  };
  render() {
    const pageSize = this.props.pageSize,
      pageSizeUnit = this.props.pageSizeUnit,
      margin = this.props.margin,
      marginSizeUnit = this.props.marginSizeUnit,
      headerHeight = this.props.headerHeight,
      footerHeight = this.props.footerHeight,
      descriptionCustomText = this.props.descriptionCustomText,
      descriptionBox = this.props.descriptionBox,
      contentFontSizes = this.props.contentFontSizes,
      contentAlignments = this.props.contentAlignments,
      activeDescriptionColumn = this.props.activeDescriptionColumn;

    return (
      <div className="card">
        <div className="tt-group-header" style={{ borderRadius: 0 }}>
          {"Create Report (Step 2 of 4: Description Box)"}
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-7 tt-printbox">
                <div className="card">
                  <div className="card-body">
                    <div className="row mt-2">
                      <div className="col-md-2 mt-2">
                        <strong>Margin</strong>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="top"
                          name="marginTop"
                          value={
                            descriptionBox.marginSizeUnit === "mm"
                              ? descriptionBox.marginTop
                              : descriptionBox.marginTop?.toFixed(3)
                          }
                          step={
                            descriptionBox.marginSizeUnit === "mm" ? 1 : 0.1
                          }
                          onChange={
                            this.props.handleDescriptionMarginDataChange
                          }
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="left"
                          name="marginLeft"
                          value={
                            descriptionBox.marginSizeUnit === "mm"
                              ? descriptionBox.marginLeft
                              : descriptionBox.marginLeft?.toFixed(3)
                          }
                          step={
                            descriptionBox.marginSizeUnit === "mm" ? 1 : 0.1
                          }
                          onChange={
                            this.props.handleDescriptionMarginDataChange
                          }
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="right"
                          name="marginRight"
                          value={
                            descriptionBox.marginSizeUnit === "mm"
                              ? descriptionBox.marginRight
                              : descriptionBox.marginRight?.toFixed(3)
                          }
                          step={
                            descriptionBox.marginSizeUnit === "mm" ? 1 : 0.1
                          }
                          onChange={
                            this.props.handleDescriptionMarginDataChange
                          }
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="bottom"
                          name="marginBottom"
                          value={
                            descriptionBox.marginSizeUnit === "mm"
                              ? descriptionBox.marginBottom
                              : descriptionBox.marginBottom?.toFixed(3)
                          }
                          step={
                            descriptionBox.marginSizeUnit === "mm" ? 1 : 0.1
                          }
                          onChange={
                            this.props.handleDescriptionMarginDataChange
                          }
                        />
                      </div>
                    </div>

                    {/* Height */}
                    <div className="row mt-2">
                      <div className="col-md-2 mt-2">
                        <strong>Height</strong>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          name="height"
                          value={descriptionBox.height}
                          step={descriptionBox.heightUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleDescriptionFormDataChange}
                        />
                      </div>
                      <div className="col-md-2 mt-2 text-right">
                        <strong>Layout</strong>
                      </div>
                      <div className="col">
                        <select
                          name="layout"
                          className="form-control"
                          value={descriptionBox.layout}
                          onChange={this.props.handleDescriptionLayoutChange}
                        >
                          <option disabled selected>
                            Choose Layout
                          </option>
                          <option value="1col">1 - column</option>
                          <option value="2col">2 - column(50/50)</option>
                          {/* <option value="2cte">2 - column(20/80)</option> */}
                          <option value="2cts">2 - column(34/66)</option>
                          <option value="2cst">2 - column(66/34)</option>
                        </select>
                      </div>
                    </div>

                    <div className="card mt-2">
                      <div
                        className="card-header tt-group-header"
                        style={{ padding: "6px", marginBottom: 0 }}
                      >
                        <div className="row">
                          <div className="col-md-7 mt-1">
                            {"Add Elements in " +
                              (descriptionBox.layout === "1col"
                                ? "1 - column"
                                : descriptionBox.layout === "2col"
                                ? "2 - column(50/50)"
                                : descriptionBox.layout === "2cts"
                                ? "2 - column(34/66)"
                                : descriptionBox.layout === "2cst"
                                ? "2 - column(66/34)"
                                : null)}
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-control"
                              value={activeDescriptionColumn}
                              name="activeDescriptionColumn"
                              onChange={this.props.handleFormDataChange}
                            >
                              {descriptionBox.columns
                                ? descriptionBox.columns.map((column, i) => (
                                    <option value={i}>{`Column ${++i} width(${
                                      column.width
                                    })`}</option>
                                  ))
                                : null}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        {descriptionBox && descriptionBox.columns
                          ? descriptionBox.columns.map((column, i) => (
                              <div className="row">
                                <div className="col">
                                  <table
                                    className="table table-bordered table-striped"
                                    style={
                                      activeDescriptionColumn == i
                                        ? {
                                            boxShadow:
                                              "2px 3px 3px rgba(0,0,0,0.33)",
                                          }
                                        : null
                                    }
                                  >
                                    <thead className="tt-group-header text-center reportTable">
                                      <tr>
                                        <th
                                          style={{ width: "30%" }}
                                        >{`Column ${descriptionBox.layout} ${column.width}`}</th>
                                        <th>Font size</th>
                                        <th>Align</th>
                                        <th>
                                          Options
                                          <div className="col">
                                            <select
                                              name="verticalAlign"
                                              value={column.verticalAlign}
                                              onChange={(e) =>
                                                this.props.handleDescriptionVerticalAlignement(
                                                  e,
                                                  i
                                                )
                                              }
                                              style={{ width: "100%" }}
                                            >
                                              <option value="top">Top</option>
                                              <option value="middle">
                                                Middle
                                              </option>
                                              <option value="bottom">
                                                Bottom
                                              </option>
                                            </select>
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {column.contents &&
                                      column.contents.length > 0
                                        ? column.contents.map(
                                            (content, contentIndex) => (
                                              <tr>
                                                <td>{content.name}</td>
                                                <td>
                                                  {content.type ===
                                                  "IMG" ? null : (
                                                    <select
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                      value={
                                                        content.fontSize
                                                          ? content.fontSize
                                                          : "10"
                                                      }
                                                      onChange={(e) =>
                                                        this.props.handleDescriptionContentFontSize(
                                                          e,
                                                          i,
                                                          contentIndex
                                                        )
                                                      }
                                                    >
                                                      {contentFontSizes
                                                        ? contentFontSizes.map(
                                                            (fontSize) => (
                                                              <option
                                                                value={fontSize}
                                                              >
                                                                {fontSize}
                                                              </option>
                                                            )
                                                          )
                                                        : null}
                                                    </select>
                                                  )}
                                                </td>
                                                <td>
                                                  <select
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    value={
                                                      content.horizontalAlign
                                                        ? content.horizontalAlign
                                                        : "center"
                                                    }
                                                    onChange={(e) =>
                                                      this.props.handleDescriptionContentHorizontalAlignement(
                                                        e,
                                                        i,
                                                        contentIndex
                                                      )
                                                    }
                                                  >
                                                    {contentAlignments
                                                      ? contentAlignments.map(
                                                          (alignment) => (
                                                            <option
                                                              value={alignment}
                                                            >
                                                              {alignment}
                                                            </option>
                                                          )
                                                        )
                                                      : null}
                                                  </select>
                                                </td>
                                                <td>
                                                  <div className="row">
                                                    <div className="col">
                                                      <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                        className="tt-cursor-pointer"
                                                        onClick={(e) =>
                                                          this.props.handleDescriptionContentRemoval(
                                                            i,
                                                            contentIndex
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className="col">
                                                      {contentIndex !== 0 ? (
                                                        <FontAwesomeIcon
                                                          icon={faCaretUp}
                                                          size="2x"
                                                          className="tt-cursor-pointer"
                                                          onClick={() =>
                                                            this.props.handleDescriptionContentReorderBtnClick(
                                                              i,
                                                              contentIndex,
                                                              --contentIndex
                                                            )
                                                          }
                                                        />
                                                      ) : null}
                                                    </div>
                                                    <div className="col">
                                                      {contentIndex !==
                                                      column.contents.length -
                                                        1 ? (
                                                        <FontAwesomeIcon
                                                          icon={faCaretDown}
                                                          size="2x"
                                                          className="tt-cursor-pointer"
                                                          onClick={() =>
                                                            this.props.handleDescriptionContentReorderBtnClick(
                                                              i,
                                                              contentIndex,
                                                              ++contentIndex
                                                            )
                                                          }
                                                        />
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )
                                        : null}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    {/* Row Addition */}
                    <div className="card">
                      <div
                        className="card-header tt-group-header"
                        style={{ padding: "6px" }}
                      >
                        {"Add Row"}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            {this.state.rowChips.map((row, idx) => {
                              return (
                                <Chip
                                  label={row.label}
                                  color={row.color}
                                  onClick={(e) =>
                                    this.props.handleAddRowDescriptionChipClick(
                                      row.param
                                    )
                                  }
                                  key={idx}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div
                        className="card-footer tt-group-header"
                        style={{ padding: "6px", marginBottom: 0 }}
                      >
                        <div className="row">
                          <div className="col">
                            <input
                              type="text"
                              name="descriptionCustomText"
                              value={descriptionCustomText}
                              onChange={this.props.handleFormDataChange}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="col">
                            <button
                              className="tt-button tt-button-primary"
                              onClick={
                                this.props
                                  .handleAddCustomRowInDescriptionBtnClick
                              }
                            >
                              Add Row
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-5 tt-printbox1"
                style={{ backgroundColor: "#ccc", position: "relative" }}
              >
                <ReportMagnifier
                  handleZoomIn={this.props.handleZoomIn}
                  handleZoomOut={this.props.handleZoomOut}
                />
                <div
                  className="tt-printPreview"
                  style={{
                    zoom: this.props.zoom + "%",
                    height: `${pageSize.height}${pageSizeUnit}`,
                    maxWidth: `${pageSize.width}${pageSizeUnit}`,
                    width: `${pageSize.width}${pageSizeUnit}`,
                    paddingTop: `${margin.top}${marginSizeUnit}`,
                    paddingLeft: `${margin.left}${marginSizeUnit}`,
                    paddingRight: `${margin.right}${marginSizeUnit}`,
                    paddingBottom: `${margin.bottom}${marginSizeUnit}`,
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      maxWidth: "100%",
                      borderLeft: "1px dashed #ccc",
                      borderRight: "1px dashed #ccc",
                    }}
                  >
                    <div
                      style={{
                        height: `${headerHeight}${pageSizeUnit}`,
                        maxWidth: "100%",
                        border: "1px dashed #ccc",
                      }}
                    ></div>
                    <div
                      style={{
                        height: `${
                          pageSize.height -
                          (headerHeight +
                            footerHeight +
                            margin.top +
                            margin.bottom)
                        }${pageSizeUnit}`,
                        maxWidth: "100%",
                        border: "1px dashed #ccc",
                      }}
                    >
                      <div
                        style={{
                          height: `${descriptionBox.height}${descriptionBox.heightUnit}`,
                          marginTop: `${descriptionBox.marginTop}${descriptionBox.marginSizeUnit}`,
                          marginLeft: `${descriptionBox.marginLeft}${descriptionBox.marginSizeUnit}`,
                          marginRight: `${descriptionBox.marginRight}${descriptionBox.marginSizeUnit}`,
                          marginBottom: `${descriptionBox.marginBottom}${descriptionBox.marginSizeUnit}`,
                          display: "flex",
                          flexWrap: "wrap",
                          border: "1px dashed #ccc",
                        }}
                      >
                        {descriptionBox && descriptionBox.columns
                          ? descriptionBox.columns.map((column, idx) => (
                              <div
                                className={`tt_align_items_v_${
                                  column.verticalAlign
                                    ? column.verticalAlign
                                    : "middle"
                                }`}
                                style={{
                                  height:
                                    descriptionBox.height +
                                    descriptionBox.heightUnit,
                                  maxWidth: "100%",
                                  width: column.width,
                                  borderRight:
                                    descriptionBox.columns.length > 1 &&
                                    idx === 0
                                      ? "1px dashed #ccc"
                                      : "none",
                                  overflow: "hidden",
                                  padding: "5px",
                                }}
                              >
                                {column.contents && column.contents.length > 0
                                  ? column.contents.map((content) => {
                                      return (
                                        <div
                                          style={{
                                            textAlign: content.horizontalAlign,
                                            fontSize: content.fontSize
                                              ? content.fontSize + "pt"
                                              : "10pt",
                                          }}
                                        >
                                          {content.name}
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                    <div
                      style={{
                        height: `${footerHeight}${pageSizeUnit}`,
                        maxWidth: "100%",
                        border: "1px dashed #ccc",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-footer"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
        >
          <div className="row">
            <div className="col"></div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handlePreviousBtnClick}
              >
                Previous
              </button>
            </div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handleSaveBtnClick}
              >
                Save
              </button>
            </div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handleNextBtnClick}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportingConfigurationDescriptionBox;
