import React, { Component } from "react";
import { validateFile } from "../../components/Validation/Validation";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import CustomSpinner from "../../components/common/CustomSpinner";
import { displayErrorAlert } from "../../utils/Utils";

class SliderForm extends Component {
  state = {
    sliderForms: [
      {
        photo: "",
        photoFile: "",
        photoPreview: "",
        title: "",
        description: "",
      },
    ],
    files: [],
    error: false,
    showSpinner: false,
  };

  addSliderForm = () => {
    let sliderForms = [...this.state.sliderForms];
    sliderForms.push({
      photo: "",
      photoFile: "",
      photoPreview: "",
      title: "",
      description: "",
    });
    this.setState({
      sliderForms,
      error: false,
    });
  };
  removeSliderForm = (idx) => {
    let sliderForms = [...this.state.sliderForms];
    let files = [...this.state.files];

    files.splice(idx, 1);
    sliderForms.splice(idx, 1);
    this.setState({
      sliderForms,
      error: false,
      files,
    });
  };

  handleChange = (e, idx) => {
    let sliderForms = this.state.sliderForms;
    let name = e.target.name;
    let value = e.target.value;
    sliderForms[idx][name] = value;
    this.setState({ sliderForms });
  };

  handleSliderImageChange = (e, idx) => {
    let extensions = ["png", "jpeg", "jpg"];
    let validationResponse = validateFile(e.target.value, extensions);
    if (!validationResponse[0]) {
      swal("Error", validationResponse[1]);
      return;
    }
    let sliderForms = this.state.sliderForms;
    let files = this.state.files;
    if (e.target.files.length > 0) {
      sliderForms[idx]["photo"] = e.target.value;
      sliderForms[idx]["photoFile"] = e.target.files[0];
      sliderForms[idx]["photoPreview"] = window.URL.createObjectURL(
        e.target.files[0]
      );
      this.setState({ sliderForms });
      files[idx] = e.target.files[0];
      this.setState({ files });
    }
  };
  removeSliderImage = (idx) => {
    let sliderForms = [...this.state.sliderForms];
    let files = [...this.state.files];
    sliderForms[idx]["photo"] = "";
    sliderForms[idx]["photoFile"] = "";
    sliderForms[idx]["photoPreview"] = "";
    files[idx] = null;
    this.setState({ sliderForms, files });
  };

  handleSliderSave = () => {
    let error = false;
    let sliderForms = [...this.state.sliderForms];

    let param = [];
    sliderForms.forEach((sliderForm, index) => {
      if (
        sliderForm.photoFile == "" ||
        sliderForm.photoFile == null ||
        sliderForm.photoFile == undefined
      ) {
        error = true;
        this.setState({ error: true });
        swal("Error", "Please select image");
      }
      param[index] = {
        title: sliderForm.title,
        description: sliderForm.description,
      };
    });

    let formData = new FormData();
    let editData = {
      title: sliderForms[0].title,
      description: sliderForms[0].description,
      id: sliderForms[0].id,
      isActive: this.props.editData?.isActive ? true : false,
    };

    if (this.props.isEdit) {
      formData.append("sliders", JSON.stringify(editData));
      this.state.files.forEach((file) => {
        formData.append("files", file);
      });
    } else {
      var data = { data: param };
      formData.append("sliders", JSON.stringify(data));
      this.state.files.forEach((file) => {
        formData.append("files[]", file);
      });
    }

    if (error === false) {
      this.setState({
        showSpinner: true,
      });

      let url = this.props.isEdit ? URL.updateSlider : URL.insertslider;
      axiosPost(
        url,
        formData,
        (response) => {
          if (response.status == 200) {
            this.setState({
              sliderForms: [
                {
                  photo: "",
                  photoFile: "",
                  photoPreview: "",
                  title: "",
                  description: "",
                },
              ],
              showSpinner: false,
              error: false,
            });
            swal("Success", response.data.message);
            this.props.getAllSliders();
            this.props.toggleModal();
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({
            showSpinner: false,
          });
        }
      );
    }
  };

  componentDidMount() {
    if (this.props.editData) {
      this.setState({
        sliderForms: [this.props.editData],
      });
    }
  }
  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}

        {this.state.sliderForms.map((sliderForm, index) => {
          return (
            <div className="row mb-4" key={index}>
              <div
                className="col-md-9"
                style={{ borderRight: "1px solid #ccc" }}
              >
                <div>
                  <div className="row mb-2">
                    <div className="col-md-12 text-center mt-1">
                      <img
                        width="150"
                        src={
                          sliderForm.photoPreview
                            ? sliderForm.photoPreview
                            : `${URL.imageSelectURL}${sliderForm.imagePath}`
                        }
                        className="mt-2 mb-2"
                      />
                    </div>

                    <div className="col">
                      <input
                        accept="image/png, image/jpeg, image/jpg"
                        className={`${
                          this.state.error && sliderForm.photoFile == ""
                            ? "form-control cusInput is-invalid"
                            : "form-control cusInput"
                        }`}
                        name="image"
                        type="file"
                        onChange={(e) => this.handleSliderImageChange(e, index)}
                        value={sliderForm.photo}
                      />
                      {this.state.error && sliderForm.photoFile == "" ? (
                        <small className="errorMsg-textField">
                          Image is required
                        </small>
                      ) : null}
                    </div>
                    {sliderForm.photoPreview &&
                    sliderForm.photoPreview !== "" ? (
                      <div className="col-md-2">
                        <button
                          className="btn btn-danger cusBtnDanger cusBtnFontSize"
                          onClick={() => this.removeSliderImage(index)}
                        >
                          Remove
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    className="form-control cusInput"
                    name="title"
                    type="text"
                    onChange={(e) => this.handleChange(e, index)}
                    value={sliderForm.title}
                    placeholder="Enter your title here"
                  />
                  {/* {this.state.error && sliderForm.title == "" ? (
                    <small className="errorMsg-textField">
                      Title is required
                    </small>
                  ) : null} */}
                </div>
                <div className="mt-2">
                  <textarea
                    name="description"
                    onChange={(e) => this.handleChange(e, index)}
                    value={sliderForm.description}
                    className="form-control "
                    placeholder="Enter your description here"
                  />
                  {/* {this.state.error && sliderForm.description == "" ? (
                    <small className="errorMsg-textField">
                      Description is required
                    </small>
                  ) : null} */}
                </div>
              </div>
              <div className="col-md-3">
                {index > 0 ? (
                  <span
                    className="material-icons-round text-danger pointer"
                    aria-hidden="true"
                    onClick={(e) => this.removeSliderForm(index)}
                  >
                    remove_circle
                  </span>
                ) : null}
                {index === this.state.sliderForms.length - 1 ? (
                  <span
                    className="material-icons-round text-success pointer"
                    aria-hidden="true"
                    onClick={this.addSliderForm}
                  >
                    add_circle
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}

        <div className="row mt-4">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSliderSave}
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default SliderForm;
