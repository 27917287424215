import React, { Component } from "react";
import { Spinner } from "reactstrap";
import ModalWindow from "../UI/ModalWindow";
import Pagination from "../UI/Pagination";
import FlashCardTemplate from "./FlashCardTemplate";

class FlashCardTableList extends Component {
  state = {
    selectedFlashCard: [],
    flashCardModal: false,
    flashCardHeader: "",
    totalCards: 0,
  };

  playFlashCard = (cards) => {
    this.setState({
      selectedFlashCard: cards,
      flashCardHeader: cards.title,
      flashCardModal: true,
      totalCards: cards.flashCards ? cards.flashCards.length : 0,
    });
  };

  toggleFlashCardModal = () => {
    this.setState({
      flashCardModal: !this.state.flashCardModal,
      selectedFlashCard: [],
      flashCardHeader: "",
    });
  };

  upperCaseLetter = (string) => {
    return string.charAt(0).toUpperCase();
  };

  render() {
    let cardList = this.props.cardList;
    return (
      <>
        <table
          className={
            this.props.central
              ? "table cusTable"
              : "table table-bordered table-striped mb-0"
          }
        >
          <thead className={this.props.central ? "" : "tt-group-header"}>
            <tr>
              <th width="300px">Title</th>
              <th>Description</th>
              {this.props.cardType === "my" ? (
                <th width="90px">Access</th>
              ) : null}
              {this.props.cardType === "my" ? null : (
                <th width="160px">Creator</th>
              )}
              <th
                width={
                  this.props.cardType === "my"
                    ? "270px"
                    : this.props.central
                    ? "270px"
                    : "80px"
                }
              ></th>
            </tr>
          </thead>
          <tbody>
            {cardList.length > 0 ? (
              cardList.map((cards, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {cards.title.charAt(0).toUpperCase() +
                        cards.title.slice(1)}{" "}
                      <strong>
                        [{cards.flashCards ? cards.flashCards.length : 0}{" "}
                        flashcards]
                      </strong>
                    </td>

                    <td>
                      {cards.description
                        ? cards.description.charAt(0).toUpperCase() +
                          cards.description.slice(1)
                        : "No description"}
                    </td>
                    {this.props.cardType === "my" ? (
                      <td>
                        {cards.access.charAt(0).toUpperCase() +
                          cards.access.slice(1)}
                      </td>
                    ) : null}
                    {this.props.cardType === "my" ? null : (
                      <td>
                        {cards.peopleName
                          ? cards.peopleName.charAt(0).toUpperCase() +
                            cards.peopleName.slice(1)
                          : "No creator found"}
                      </td>
                    )}
                    <td className="text-center">
                      <button
                        className="tt-button tt-button-primary"
                        onClick={() => this.playFlashCard(cards)}
                      >
                        Play
                      </button>
                      {this.props.cardType === "my" ? (
                        <>
                          <button
                            className="ml-3 tt-button tt-button-primary"
                            onClick={() => this.props.handleUpdateModal(cards)}
                          >
                            Update
                          </button>
                          <button
                            className="tt-button tt-button-primary ml-3"
                            onClick={() =>
                              this.props.deleteFlashCardSet(cards.id)
                            }
                          >
                            Delete
                          </button>
                        </>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className="text-center">
                  {this.props.spinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>Loading Data...
                    </>
                  ) : (
                    "No flash Cards"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {this.props.cardType === "public" ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              itemsCount={this.props.publicFlashTotalRecords}
              pageSize={this.props.publicFlashRowCountLimit}
              currentPage={this.props.publicFlashCurrentPage}
              onPageChange={this.props.handlePageChange}
              nextPage={this.props.handleNextPage}
              previousPage={this.props.handlePreviousPage}
            ></Pagination>
          </div>
        ) : null}
        <ModalWindow
          modal={this.state.flashCardModal}
          fullWidth={true}
          toggleModal={this.toggleFlashCardModal}
          modalHeader={
            this.state.flashCardHeader +
            ` [ ${this.state.totalCards} flashcards]`
          }
          modalBody={
            <FlashCardTemplate
              flashCards={this.state.selectedFlashCard}
              toggleFlashCardModal={this.toggleFlashCardModal}
            ></FlashCardTemplate>
          }
        ></ModalWindow>
      </>
    );
  }
}

export default FlashCardTableList;
