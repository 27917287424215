import React, { Component } from "react";
import FileViewer from "react-file-viewer";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import CustomButton from "../common/CustomButton";
import Navbar from "../Profile/Navbar";
import ModalWindow from "../UI/ModalWindow";
import icon02 from "../../assets/images/icon-02.png";

class TechnicalSchool extends Component {
  state = {
    activeFile: null,
    categorySelected: null,
    classes: null,
    dropdownOpen: false,
    fileViewerModal: false,
    filePath: "",
    fileType: "",
    oActivities: [],
    resourceViewModal: false,
    setting: false,
  };

  componentDidMount() {
    this.getAllEducationList();
    this.getOtherActivities();
  }

  getAllEducationList = () => {
    axiosPost(URL.getAllEducationLevel, {}, (response) => {
      if (response.status === 200) {
        const educationLevels = response.data.data;
        let yearLevels = [];
        educationLevels.forEach((el) => {
          if (el.yearLevels.length > 0) {
            el.yearLevels.forEach((c) => {
              yearLevels.push(c);
            });
          }
        });
        this.setState({
          classes: yearLevels,
        });
      }
    });
  };

  getOtherActivities = () => {
    let param = {
      isTechnicalSchool: true,
    };
    axiosPost(URL.getOtherActivities, param, (response) => {
      if (response.status === 200) {
        this.setState({ oActivities: response.data.data });
      }
    });
  };

  handleCategorySelect = (oA) => {
    this.setState({ categorySelected: oA });
  };

  handleViewResources = (data) => {
    this.setState(
      {
        filePath: data.filePath,
        fileType: data.fileType,
      },
      () => {
        this.setState({
          resourceViewModal: true,
        });
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  toggleResourceViewModal = () => {
    this.setState({
      resourceViewModal: !this.state.resourceViewModal,
    });
  };

  setResource = (r) => {
    this.setState({ activeFile: r }, () => {
      this.toggleFileViewer();
    });
  };

  toggleFileViewer = () => {
    this.setState({
      fileViewerModal: !this.state.fileViewerModal,
    });
  };

  render() {
    const { oActivities, categorySelected } = this.state;
    return (
      <div>
        {/* <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
        /> */}
        {/* <section className="my-2">
          <div className="gradeClassList">
            <div key={0} onClick={() => this.selectPublicCategory()}>
              <span>Public</span>
            </div>
            {this.state.classes?.length > 0
              ? this.state.classes.map((cls, idx) => {
                  return (
                    <div
                      key={++idx}
                      onClick={() => this.selectClassCategory(cls)}
                    >
                      <span>{cls.name}</span>
                    </div>
                  );
                })
              : null}
          </div>
        </section> */}
        <section>
          <div className="container">
            {categorySelected !== null && (
              <div className="row">
                <div className="col">
                  <CustomButton
                    text="Go Back"
                    onClick={() => this.setState({ categorySelected: null })}
                    customStyle="resourceBtn"
                    style={{ marginLeft: "unset", marginBottom: "10px" }}
                  ></CustomButton>
                </div>
              </div>
            )}
            <div className="row mt-2">
              {categorySelected === null && oActivities.length > 0 ? (
                oActivities.map((oA, idx) => (
                  <div className="col-md-4">
                    <div
                      className="card mb-4 p-3"
                      role="button"
                      onClick={() => this.handleCategorySelect(oA)}
                    >
                      <div className="cdc-center">
                        <div className="container-fluid">
                          <div className="row">
                            <div
                              className="col-md-4"
                              style={{
                                borderRight: "1px solid #ccc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <div className="icon-image mb-2">
                                <img
                                  src={URL.imageSelectURL + oA.thumbnailPath}
                                  onError={(e) => (e.target.src = icon02)}
                                  alt={oA.description}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <h6
                                className="heading-06"
                                style={{ fontWeight: "bold" }}
                              >
                                {oA.name}
                              </h6>
                              <small>{oA.description}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {categorySelected?.activitiesCategoriesResources.map((r) => (
                    <div className="col-md-4">
                      <div className="card mb-4 p-3" role="button">
                        <div className="cdc-center">
                          <div className="container-fluid">
                            <div className="row">
                              <div
                                className="col-md-4"
                                style={{
                                  borderRight: "1px solid #ccc",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="icon-image">
                                  <img
                                    src={URL.imageSelectURL + r.thumbnailPath}
                                    onError={(e) => (e.target.src = icon02)}
                                    alt={r.description}
                                  />
                                </div>
                              </div>

                              <div className="col">
                                <h6
                                  className="heading-06"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {r.title}
                                </h6>
                                <small className="truncateLines">
                                  {r.description}
                                </small>
                                <CustomButton
                                  text="View resource"
                                  customStyle="resourceBtn otherActivitiesBtn"
                                  onClick={() => this.handleViewResources(r)}
                                  //pop up modal to view resources of the category
                                ></CustomButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
        <ModalWindow
          modal={this.state.resourceViewModal}
          toggleModal={this.toggleResourceViewModal}
          size="lg"
          modalHeader="Resource"
          modalBody={
            <>
              <div style={{ height: "100%" }}>
                {this.state.fileType &&
                this.state.fileType.toLowerCase() === "img" ? (
                  <img src={URL.imageSelectURL + this.state.filePath} alt="" />
                ) : (
                  <FileViewer
                    className="file-viewer-style"
                    fileType={this.state.filePath.split(".").pop()}
                    filePath={URL.imageSelectURL + this.state.filePath}
                  />
                )}
              </div>
            </>
          }
        />
      </div>
    );
  }
}

export default TechnicalSchool;
