import React, { Component } from "react";
import GradeTableRow from "./GradeTableRow";
import GpaTableRow from "./GpaTableRow";

class IndicatorDescriptorTable extends Component {
  state = {};

  tableRow() {
    return this.props.gradeValues.length > 0 ? (
      this.props.gradeValues.map((val, id) => {
        return (
          <GradeTableRow
            val={val}
            id={id}
            handleRemoveGrades={this.props.handleRemoveGrades}
            levelType={this.props.levelType}
            selectedFile={this.props.selectedFile}
            useLevel={this.props.useLevel}
          />
        );
      })
    ) : (
      // <Spinner />
      <tr>
        {/* <td>
          <Spinner type="grow" color="primary" />
          <Spinner type="grow" color="secondary" />
          <Spinner type="grow" color="success" />
        </td>
        <td>
          <Spinner type="grow" color="primary" />
          <Spinner type="grow" color="secondary" />
          <Spinner type="grow" color="success" />
        </td>
        <td>
          <Spinner type="grow" color="primary" />
          <Spinner type="grow" color="secondary" />
          <Spinner type="grow" color="success" />
        </td>
        <td>
          <Spinner type="grow" color="primary" />
          <Spinner type="grow" color="secondary" />
          <Spinner type="grow" color="success" />
        </td> */}
      </tr>
    );
  }

  tableRowForGpa() {
    return this.props.multipleGpa.length > 0
      ? this.props.multipleGpa.map((val, id) => {
          return (
            <GpaTableRow
              val={val}
              id={id}
              handleRemoveGrades={this.props.handleRemoveGrades}
            />
          );
        })
      : null;
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <table className="table table-bordered text-center mt-1 table-striped">
            <thead className="tt-group-header">
              <tr>
                {this.props.useLevel ? (
                  <th style={{ zIndex: "0" }}>{this.props.levelName}</th>
                ) : null}

                <th style={{ zIndex: "0" }}>Descriptor</th>
                <th>Description</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {this.props.type === "grade"
                ? this.tableRow()
                : this.tableRowForGpa()}

              {/* <td>A+</td>
                <td>Outstanding</td>
                <td>
                  The Student has achieved the best possible results in this
                  subject
                </td>
                <td>[x]</td>
              </tr>
              <tr>
                <td>A</td>
                <td>Good</td>
                <td>
                  The Student has achieved the best possible results in this
                  subject
                </td>
                <td>[x]</td>
              </tr>
              <tr>
                <td>B</td>
                <td>Average</td>
                <td>
                  The Student has achieved the best possible results in this
                  subject
                </td>
                <td>[x]</td> */}
            </tbody>
          </table>
        </div>
        <div className="col-md-1"></div>
      </div>
    );
  }
}

export default IndicatorDescriptorTable;
