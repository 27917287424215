import React, { Component } from "react";

class CourseSyllabusDetailReRender extends Component {
  state = {};
  render() {
    return (
      <>
        {this.props.topicList && this.props.topicList.length > 0
          ? this.props.topicList.map((topic, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div
                    className="row mb-2"
                    key={idx}
                    style={{ fontSize: "14px" }}
                  >
                    <div className="col">
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        <span
                          className="backGround toolTip courseSyllabusSubtitle"
                          style={{ paddingLeft: this.props.index * 3 + "0px" }}
                        >
                          {idx + 1}. {topic.name}
                          <span className="toolTipText">
                            {topic.description && "(" + topic.description + ")"}
                          </span>
                        </span>
                        <span
                          style={{ height: "inherit", width: "8px" }}
                          className="backGround"
                        ></span>
                        <div className="dotsRender"></div>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="row">
                        <div className="col text-center">
                          {topic.lectureHour}
                        </div>
                        <div className="col text-center">
                          {topic.practicalHour}
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-auto text-center">
                      <button
                        className="btn btn-primary cusBtn cusBtnFontSize  mr-2"
                        onClick={() =>
                          this.props.handleUpdateSyllabusDetail(topic)
                        }
                      >
                        Edit
                      </button>

                      <button
                        className="btn btn-primary cusBtn cusBtnFontSize  mr-2"
                        onClick={() =>
                          this.props.handleSyllabusDetailDelete(topic.id)
                        }
                      >
                        Delete
                      </button>

                      {topic.hasResource ? (
                        <button
                          className="btn btn-primary cusBtn cusBtnFontSize mb-1"
                          onClick={() =>
                            this.props.toggleSyllabusResourcesModalView(topic)
                          }
                        >
                          View Resource
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        className="btn btn-primary cusBtn cusBtnFontSize mb-1"
                        onClick={() => this.props.syllabusResourceModal(topic)}
                      >
                        Add Resources
                      </button>
                    </div>
                  </div>
                  {topic.subTopic && topic.subTopic.length > 0 ? (
                    <CourseSyllabusDetailReRender
                      topicList={topic.subTopic}
                      handleUpdateSyllabusDetail={
                        this.props.handleUpdateSyllabusDetail
                      }
                      index={1}
                      handleSyllabusDetailDelete={
                        this.props.handleSyllabusDetailDelete
                      }
                      syllabusResourceModal={this.props.syllabusResourceModal}
                      toggleSyllabusResourcesModalView={
                        this.props.toggleSyllabusResourcesModalView
                      }
                    ></CourseSyllabusDetailReRender>
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
      </>
    );
  }
}

export default CourseSyllabusDetailReRender;
