import React, { Component } from "react";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import MainFeatureForm from "./MainFeatureForm";
import ListMainFeature from "./ListMainFeature";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";
import swal from "sweetalert";

class MainFeature extends Component {
  state = {
    features: [],
    modal: false,
    editMode: false,
    editData: {},
    // editModal: false,
  };

  componentDidMount() {
    this.getAllFeature();
  }

  getAllFeature = () => {
    axiosPost(URL.getAllFeature, {}, (response) => {
      if (response.status == 200) {
        this.setState({
          features: response.data.data,
        });
      }
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  handleDelete = (feature) => {
    let data = {
      id: feature.id,
    };

    axiosPost(URL.deleteFeature, data, (response) => {
      if (response.status === 200) {
        swal("Success", "Feature deleted successfully");
        this.getAllFeature();
      }
    });
  };

  handleEditClick = (data) => {
    this.setState({
      // editModal: true,
      editData: data,
      editMode: true,
    });
  };
  toggleEditModal = () => {
    this.setState({
      editMode: false,
    });
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let data = {
      id: item.id,
      display: checked,
    };

    axiosPost(URL.deleteFeature, data, (response) => {
      if (response.status === 200) {
        this.getAllFeature();
      }
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Main Features"
                onClick={this.toggleModal}
                icon="add_box"
                noPermission={true}
              />

              <ListMainFeature
                features={this.state.features}
                getAllFeature={this.getAllFeature}
                handleEditClick={this.handleEditClick}
                handleDelete={this.handleDelete}
                handleChangeToggle={this.handleChangeToggle}
              />
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          size="lg"
          toggleModal={this.toggleModal}
          modalHeader="Add Main Features"
          modalBody={
            <MainFeatureForm
              getAllFeature={this.getAllFeature}
              toggleModal={this.toggleModal}
            />
          }
        />

        <CustomModal
          modal={this.state.editMode}
          size="lg"
          toggleModal={this.toggleEditModal}
          modalHeader="Update Main Features"
          modalBody={
            <MainFeatureForm
              editData={this.state.editData}
              editMode={true}
              getAllFeature={this.getAllFeature}
              toggleModal={this.toggleEditModal}
            />
          }
        />
      </div>
    );
  }
}

export default MainFeature;
