import React from "react";
import { Spinner } from "reactstrap";

function CustomSpinner(props) {
  return (
    <div className="fullWindow-Spinner">
      <div>
        <Spinner color={props.color ? props.color : "light"}></Spinner>
      </div>
      <div style={{ fontSize: "16px", marginTop: "15px" }}>
        {props.text ? props.text : "Please Wait. Processing..."}
      </div>
    </div>
  );
}

export default CustomSpinner;
