import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAssessmnetList,
  getAssessments,
} from "../../../../ducks/PrimaryARRDucks";
import CreateAssessmentForm from "./CreateAssessmentForm";
import "../../../../assets/scss/createAssessment.scss";
import "../../../../assets/scss/form.scss";
import GradeSection from "./GradeSection";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";

import {
  compareByName,
  displayErrorAlert,
  displaySuccessAlert,
} from "../../../../utils/Utils";
import swal from "sweetalert";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import moment from "moment";
import NoteDisplay from "../../../../utils/noteDisplay";

class CreateAssessments extends Component {
  state = {
    yearLevels: [],
    selectedYearLevels: [],
    educationLevelDivisions: [],
    educationLevels: [],
    educationLevelDivision: "",
    educationLevel: "",
    assessmentName: "Monthly Test",
    assessmentType: "",
    recordingDeadlineDate: "1",
    recordingDeadlineParam: "weeks",
    years: [],
    subjectList: [
      {
        name: "Math",
      },
    ],
    newSubjectList: [],
    setDeadLine: true,
    selectOptions: [],
    code: "",
    minStartDate: "",
    maxEndDate: "",
    isAvailable: false,
    render: false,
    assessmentTypes: [],
    assignEducationlevel: true,
  };

  componentDidMount() {
    this.setState({ code: this.props.code });
    // this.getEducationLevels();
    // this.getYearLevelByEducationLevel();
    this.getEducationLevelByAssessmentModule();
  }

  setUpdateConfig = () => {
    let updateData = this.props.updateData;
    this.setState(
      {
        educationLevelDivision: updateData.educationLevelDivisionId,
        assessmentName: updateData.assessmentName,
        assessmentType: updateData.assessmentTypeCode,
      },
      function () {
        this.getYearLevelByEducationLevel();
      }
    );
  };

  setGeneralandsubjectUpdateConfig = () => {
    let selectedYearLevels = this.state.selectedYearLevels;
    let updateConfig = this.props.updateConfig;
    if (updateConfig.hasGeneralSetting) {
      updateConfig.assessmentGeneralConfigurations.forEach((el) => {
        for (let i = 0; i < selectedYearLevels.length; i++) {
          selectedYearLevels[i].classrooms.forEach((c) => {
            if (c.yearLevelId === el.yearLevelId && c.id === el.classroomId) {
              c.generalAssessmentChecked = true;
              c.assessmentFromDate = moment(el.assessmentFromDate).format(
                "YYYY-MM-DD"
              );
              c.assessmentToDate = moment(el.assessmentToDate).format(
                "YYYY-MM-DD"
              );
              c.recordingDate =
                el.recordingDate && el.recordingDate !== ""
                  ? moment(el.recordingDate).format("YYYY-MM-DD")
                  : "";
              c.recordingDeadlineDate =
                el.recordingDeadlineDate && el.recordingDeadlineDate !== ""
                  ? moment(el.recordingDeadlineDate).format("YYYY-MM-DD")
                  : "";
            }
          });
        }
      });
    }
    if (updateConfig.hasSubjectSetting) {
      updateConfig.assessmentSubjectConfigurations.forEach((el) => {
        for (let i = 0; i < selectedYearLevels.length; i++) {
          selectedYearLevels[i].classrooms.forEach((c) => {
            c.subjects.forEach((cs) => {
              if (
                cs.yearLevelId === el.yearLevelId &&
                c.id === el.classroomId &&
                cs.subjectId === el.subjectId
              ) {
                cs.isChecked = true;
                cs.assessmentFromDateTheory =
                  el.assessmentTheoryFromDate &&
                  el.assessmentTheoryFromDate !== ""
                    ? moment(el.assessmentTheoryFromDate).format(
                        "YYYY-MM-DDThh:mm"
                      )
                    : "";
                cs.assessmentToDateTheory =
                  el.assessmentTheoryToDate && el.assessmentTheoryToDate !== ""
                    ? moment(el.assessmentTheoryToDate).format(
                        "YYYY-MM-DDThh:mm"
                      )
                    : "";
                cs.assessmentFromDatePractical =
                  el.assessmentPracticalFromDate &&
                  el.assessmentPracticalFromDate !== ""
                    ? moment(el.assessmentPracticalFromDate).format(
                        "YYYY-MM-DDThh:mm"
                      )
                    : "";
                cs.assessmentToDatePractical =
                  el.assessmentPracticalToDate &&
                  el.assessmentPracticalToDate !== ""
                    ? moment(el.assessmentPracticalToDate).format(
                        "YYYY-MM-DDThh:mm"
                      )
                    : "";

                cs.recordingDateTheory =
                  el.recordingTheoryDate && el.recordingTheoryDate !== ""
                    ? moment(el.recordingTheoryDate).format("YYYY-MM-DD")
                    : "";
                cs.recordingDeadlineTheory =
                  el.recordingTheoryDeadlineDate &&
                  el.recordingTheoryDeadlineDate !== ""
                    ? moment(el.recordingTheoryDeadlineDate).format(
                        "YYYY-MM-DD"
                      )
                    : "";
                cs.recordingDatePractical =
                  el.recordingPracticalDate && el.recordingPracticalDate !== ""
                    ? moment(el.recordingPracticalDate).format("YYYY-MM-DD")
                    : "";
                cs.recordingDeadlinePractical =
                  el.recordingPracticalDeadlineDate &&
                  el.recordingPracticalDeadlineDate !== ""
                    ? moment(el.recordingPracticalDeadlineDate).format(
                        "YYYY-MM-DD"
                      )
                    : "";
              }
            });
          });
        }
      });
    }
    this.setState({ selectedYearLevels });
  };

  getEducationLevelByAssessmentModule = () => {
    let data = {
      code: this.props.code,
    };
    axiosPost(
      URL.getEducationLevelByAssessmentModule,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({ educationLevels: response.data.data }, function () {
            if (this.state.educationLevels.length === 1) {
              this.setState(
                { educationLevel: this.state.educationLevels[0].id },
                function () {
                  this.getEducationLevelDivisions();
                }
              );
            } else {
              this.setState({ assignEducationlevel: false, render: true });
            }
          });
        } else {
          this.setState({ render: true });
        }
        if (response.status === 204) {
          this.setState({ assignEducationlevel: false, render: true });
        }
      },
      (err) => {
        this.setState({ render: true });
      }
    );
  };

  getYearLevelByEducationLevel = () => {
    // const param = this.state.educationLevel;
    let data = {
      educationLevelId: this.state.educationLevel,
      assessmentModuleCode: this.props.code,
      educationLevelDivisionId: this.state.educationLevelDivision,
    };
    axiosPost(URL.getYearLevelByEducationLevel, data, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data }, () => {
          this.changeToValueLabel();
        });
      }
    });
  };

  getEducationLevelDivisions = () => {
    const param = this.state.educationLevel;
    axiosPost(
      URL.getEducationLevelDivisions,
      { educationLevelId: param },
      (response) => {
        if (response.status === 200) {
          this.setState(
            { educationLevelDivisions: response.data.data },
            function () {
              this.getAssessmentTypes();
              this.checkAvailability();
              if (this.props.edit) {
                this.setUpdateConfig();
              }
            }
          );
        }
      }
    );
  };

  getAssessmentTypes = () => {
    axiosPost(URL.getAssessmentTypes, { code: this.props.code }, (response) => {
      if (response.status === 200) {
        this.setState({ assessmentTypes: response.data.data }, function () {
          // this.checkAvailability();
        });
      }
    });
  };

  checkAvailability = () => {
    if (this.state.educationLevelDivisions.length > 0) {
      this.setState({ render: true, isAvailable: true });
    } else {
      this.setState({ render: true });
    }
  };

  getSubjectsByYearLevel = (selectedItem) => {
    if (selectedItem !== null) {
      let newSelectedYearLevels = [...selectedItem];
      this.setState({ selectedYearLevels: newSelectedYearLevels });
    } else {
      this.setState({ selectedYearLevels: [] });
    }
  };

  toggleClasses = (e, index, classroomId) => {
    let selectedYearLevels = Object.assign([], this.state.selectedYearLevels);
    if (classroomId === undefined) {
      selectedYearLevels.forEach((year, i) => {
        index === i ? (year.isOpen = !year.isOpen) : (year.isOpen = false);
      });
    } else {
      selectedYearLevels.forEach((year, i) => {
        if (index === i) {
          year.classrooms.forEach((classroom) => {
            classroom.id === classroomId
              ? (classroom.isOpen = !classroom.isOpen)
              : (classroom.isOpen = false);
          });
        }
      });
    }
    this.setState({ selectedYearLevels });
  };

  changeToValueLabel = () => {
    let selectOptions = [];
    this.state.yearLevels.sort(compareByName).forEach((yearLevel, id) => {
      let data = {
        label: "Class " + yearLevel.name,
        value: yearLevel.id,
        classrooms: yearLevel.classrooms,
      };
      selectOptions.push(data);
    });
    this.setState({ selectOptions }, function () {
      if (this.props.edit) {
        let yearLevels = this.props.updateData.yearLevels;
        let selectOptions = this.state.selectOptions;
        let selectedYearLevels = [];
        if (yearLevels.length > 0) {
          yearLevels.forEach((el) => {
            for (let i = 0; i < selectOptions.length; i++) {
              if (el.id == selectOptions[i].value) {
                selectedYearLevels.push({
                  label: selectOptions[i].label,
                  value: selectOptions[i].value,
                  classrooms: selectOptions[i].classrooms,
                });
              }
            }
          });
        }
        this.setState({ selectedYearLevels }, function () {
          this.setGeneralandsubjectUpdateConfig();
        });
      }
    });
  };

  handleGradeChange = (selectedItem) => {
    this.getSubjectsByYearLevel(selectedItem);
  };

  handleChange = (
    e,
    subjectId,
    yearLevelParam,
    classroomId,
    stateParam,
    yearLevelIndex,
    classroomIndex,
    subjectIndex
  ) => {
    let newSelectedYearLevels = [...this.state.selectedYearLevels];

    if (stateParam === "recordingDateTheory") {
      let recordingDeadlineDate = this.state.recordingDeadlineDate,
        recordingDeadlineParam = this.state.recordingDeadlineParam,
        setDeadLine = this.state.setDeadLine;
      if (setDeadLine) {
        newSelectedYearLevels[yearLevelIndex].classrooms[
          classroomIndex
        ].subjects[subjectIndex][stateParam] = e.target.value;
        if (recordingDeadlineDate && recordingDeadlineParam) {
          let days = 0;
          if (recordingDeadlineParam === "days") {
            days = parseInt(recordingDeadlineDate);
          } else if (recordingDeadlineParam === "weeks") {
            days = parseInt(recordingDeadlineDate) * 7;
          } else if (recordingDeadlineParam === "months") {
            days = parseInt(recordingDeadlineDate) * 30;
          }
          if (e.target.value != "") {
            let recordingDate = new Date(e.target.value),
              deadlineDate = new Date(e.target.value);

            deadlineDate.setDate(recordingDate.getDate() + days);
            newSelectedYearLevels[yearLevelIndex].classrooms[
              classroomIndex
            ].subjects[subjectIndex]["recordingDeadlineTheory"] = deadlineDate
              .toISOString()
              .split("T")[0];
          } else {
            newSelectedYearLevels[yearLevelIndex].classrooms[
              classroomIndex
            ].subjects[subjectIndex]["recordingDeadlineTheory"] = "";
          }
        }
      } else {
        let value = moment(e.target.value).format("YYYY-MM-DD");
        let dd =
          newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex]
            .subjects[subjectIndex]["recordingDeadlineTheory"];
        if (dd && dd !== "") {
          let deadlineDate = moment(dd).format("YYYY-MM-DD");
          if (moment(value).isBefore(deadlineDate)) {
            newSelectedYearLevels[yearLevelIndex].classrooms[
              classroomIndex
            ].subjects[subjectIndex][stateParam] = value;
          } else {
            swal({
              title: "Error",
              text: "Recording date cannot be greater than Recording deadline date",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            draggable();
            return false;
          }
        } else {
          newSelectedYearLevels[yearLevelIndex].classrooms[
            classroomIndex
          ].subjects[subjectIndex][stateParam] = value;
        }
      }
    } else {
      newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex].subjects[
        subjectIndex
      ][stateParam] = e.target.value;
    }

    if (stateParam === "recordingDatePractical") {
      let recordingDeadlineDate = this.state.recordingDeadlineDate,
        recordingDeadlineParam = this.state.recordingDeadlineParam,
        setDeadLine = this.state.setDeadLine;
      if (setDeadLine) {
        newSelectedYearLevels[yearLevelIndex].classrooms[
          classroomIndex
        ].subjects[subjectIndex][stateParam] = e.target.value;
        if (recordingDeadlineDate && recordingDeadlineParam) {
          let days = 0;
          if (recordingDeadlineParam === "days") {
            days = parseInt(recordingDeadlineDate);
          } else if (recordingDeadlineParam === "weeks") {
            days = parseInt(recordingDeadlineDate) * 7;
          } else if (recordingDeadlineParam === "months") {
            days = parseInt(recordingDeadlineDate) * 30;
          }

          if (e.target.value !== "") {
            let recordingDate = new Date(e.target.value),
              deadlineDate = new Date(e.target.value);
            deadlineDate.setDate(recordingDate.getDate() + days);
            newSelectedYearLevels[yearLevelIndex].classrooms[
              classroomIndex
            ].subjects[subjectIndex]["recordingDeadlinePractical"] =
              deadlineDate.toISOString().split("T")[0];
          } else {
            newSelectedYearLevels[yearLevelIndex].classrooms[
              classroomIndex
            ].subjects[subjectIndex]["recordingDeadlinePractical"] = "";
          }
        }
      } else {
        let value = moment(e.target.value).format("YYYY-MM-DD");
        let dd =
          newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex]
            .subjects[subjectIndex]["recordingDeadlinePractical"];
        if (dd && dd !== "") {
          let deadlineDate = moment(dd).format("YYYY-MM-DD");
          if (moment(value).isBefore(deadlineDate)) {
            newSelectedYearLevels[yearLevelIndex].classrooms[
              classroomIndex
            ].subjects[subjectIndex][stateParam] = value;
          } else {
            swal({
              title: "Error",
              text: "Recording date cannot be greater than Recording deadline date",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            draggable();
            return false;
          }
        } else {
          newSelectedYearLevels[yearLevelIndex].classrooms[
            classroomIndex
          ].subjects[subjectIndex][stateParam] = value;
        }
      }
    } else {
      newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex].subjects[
        subjectIndex
      ][stateParam] = e.target.value;
    }

    if (stateParam === "assessmentFromDateTheory") {
      newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex].subjects[
        subjectIndex
      ]["assessmentFromDateTheory"] = e.target.value;

      newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex].subjects[
        subjectIndex
      ]["assessmentToDateTheory"] = "";
    }

    if (stateParam === "assessmentToDateTheory") {
      let fromDate =
        newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex]
          .subjects[subjectIndex]["assessmentFromDateTheory"];
      if (fromDate > e.target.value) {
        swal(
          "Error",
          "Assessment To Date cannot be smaller than Assessment From Date."
        );
        return false;
      } else {
        newSelectedYearLevels[yearLevelIndex].classrooms[
          classroomIndex
        ].subjects[subjectIndex]["assessmentToDateTheory"] = e.target.value;
      }
    }

    if (stateParam === "assessmentToDatePractical") {
      let fromDate =
        newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex]
          .subjects[subjectIndex]["assessmentFromDatePractical"];
      if (fromDate > e.target.value) {
        swal(
          "Error",
          "Assessment To Date cannot be smaller than Assessment From Date."
        );
        return false;
      } else {
        newSelectedYearLevels[yearLevelIndex].classrooms[
          classroomIndex
        ].subjects[subjectIndex]["assessmentToDatePractical"] = e.target.value;
      }
    }

    if (stateParam === "assessmentFromDatePractical") {
      newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex].subjects[
        subjectIndex
      ]["assessmentFromDatePractical"] = e.target.value;

      newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex].subjects[
        subjectIndex
      ]["assessmentToDatePractical"] = "";
    }

    this.setState({
      selectedYearLevels: newSelectedYearLevels,
    });
  };

  handleGeneralAssessmentDateChange = (
    e,
    yearLevelId,
    classroomId,
    stateParam
  ) => {
    let newSelectedYearLevels = [...this.state.selectedYearLevels];
    newSelectedYearLevels.forEach((yearLevel) => {
      if (yearLevel.value === yearLevelId) {
        yearLevel.classrooms.forEach((classroom) => {
          if (classroom.id === classroomId) {
            if (stateParam === "recordingDate") {
              let recordingDeadlineDate = this.state.recordingDeadlineDate,
                recordingDeadlineParam = this.state.recordingDeadlineParam,
                setDeadLine = this.state.setDeadLine;
              if (setDeadLine) {
                classroom[stateParam] = e.target.value;
                if (recordingDeadlineDate && recordingDeadlineParam) {
                  let days = 0;
                  if (recordingDeadlineParam === "days") {
                    days = parseInt(recordingDeadlineDate);
                  } else if (recordingDeadlineParam === "weeks") {
                    days = parseInt(recordingDeadlineDate) * 7;
                  } else if (recordingDeadlineParam === "months") {
                    days = parseInt(recordingDeadlineDate) * 30;
                  }
                  let recordingDate = new Date(e.target.value),
                    deadlineDate = new Date(e.target.value);
                  deadlineDate.setDate(recordingDate.getDate() + days);
                  classroom["recordingDeadlineDate"] = deadlineDate
                    .toISOString()
                    .split("T")[0];
                }
              } else {
                let value = moment(e.target.value).format("YYYY-MM-DD");
                if (
                  classroom["recordingDeadlineDate"] &&
                  classroom["recordingDeadlineDate"] !== ""
                ) {
                  let deadlineDate = moment(
                    classroom["recordingDeadlineDate"]
                  ).format("YYYY-MM-DD");
                  if (moment(value).isBefore(deadlineDate)) {
                    classroom[stateParam] = value;
                  } else {
                    swal({
                      title: "Error",
                      text: "Recording date cannot be greater than Recording deadline date",
                      closeOnClickOutside: false,
                      allowOutsideClick: false,
                    });
                    draggable();
                    return false;
                  }
                } else {
                  classroom[stateParam] = value;
                }
              }
            } else {
              classroom[stateParam] = e.target.value;
            }

            if (stateParam === "assessmentFromDate") {
              classroom["assessmentToDate"] = e.target.value;
            }
          }
        });
      }
    });
    this.setState({ selectedYearLevels: newSelectedYearLevels });
  };

  universalHandleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value }, () => {
      if (name === "educationLevel") {
        this.setState(
          {
            selectedYearLevels: [],
            yearLevels: [],
            educationLevelDivision: "",
            selectOptions: [],
          },
          function () {
            this.getEducationLevelDivisions();
          }
        );
      }
      if (name === "educationLevelDivision") {
        let educationLevelDivisions = [...this.state.educationLevelDivisions];
        if (educationLevelDivisions.length > 0) {
          educationLevelDivisions.forEach((el) => {
            if (el.id === parseInt(value)) {
              let minStartDate = moment(el.startDate).format("YYYY-MM-DD");
              let maxEndDate = moment(el.endDate).format("YYYY-MM-DD");
              this.setState({ minStartDate, maxEndDate });
            }
          });
        }
        this.getYearLevelByEducationLevel();
      }
    });
  };

  handleSelectAllCheckBoxClick = (e, classroomId) => {
    let newSelectedYearLevels = [...this.state.selectedYearLevels];
    newSelectedYearLevels.forEach((yearLevel) => {
      yearLevel.classrooms.forEach((classroom) => {
        if (classroom.id === classroomId) {
          classroom.subjects.forEach((subject) => {
            subject.isChecked = e.target.checked;
          });
        }
      });
    });
    this.setState({
      selectedYearLevels: newSelectedYearLevels,
    });
  };

  handleSelectRowCheckBoxClick = (
    e,
    subjectId,
    classroomId,
    yearLevelIndex,
    classroomIndex,
    subjectIndex
  ) => {
    let newSelectedYearLevels = [...this.state.selectedYearLevels];

    newSelectedYearLevels[yearLevelIndex].classrooms[classroomIndex].subjects[
      subjectIndex
    ].isChecked = e.target.checked;

    this.setState({ selectedYearLevels: newSelectedYearLevels });
  };

  handleGeneralAssessmentCheckbox = (e, yearLevelIndex, classroomIndex) => {
    let newSelectedYearLevels = [...this.state.selectedYearLevels];
    newSelectedYearLevels[yearLevelIndex].classrooms[
      classroomIndex
    ].generalAssessmentChecked = e.target.checked;
    this.setState({ selectedYearLevels: newSelectedYearLevels });
  };

  handleAssessmentSubmit = (e) => {
    e.preventDefault();

    let finalSelectedYearLevels = [...this.state.selectedYearLevels];

    let assessmentGeneralConfigurations = [];
    let assessmentSubjectConfigurations = [];
    let errors = true;
    let dateCount = 0;
    let subjectChkError = 0;

    finalSelectedYearLevels.forEach((yearLevel) => {
      yearLevel.classrooms.forEach((classroom) => {
        if (classroom.generalAssessmentChecked) {
          // if (
          //   classroom.assessmentFromDate === null &&
          //   classroom.assessmentToDate === null &&
          //   classroom.recordingDate === null &&
          //   classroom.recordingDeadlineDate === null
          // ) {
          //   dateCount++;
          // }
          if (classroom.assessmentFromDate === null) {
            dateCount++;
          }
          if (classroom.assessmentToDate === null) {
            dateCount++;
          }
          if (!classroom.recordingDate || classroom.recordingDate === null) {
            dateCount++;
          }
          if (
            !classroom.recordingDate ||
            classroom.recordingDeadlineDate === null
          ) {
            dateCount++;
          }
          let data = {};
          errors = false;
          data.yearLevelId = yearLevel.value;
          data.classroomId = classroom.id;
          data.assessmentFromDate = classroom.assessmentFromDate;
          data.assessmentToDate = classroom.assessmentToDate;
          data.recordingDate = classroom.recordingDate;
          data.recordingDeadlineDate = classroom.recordingDeadlineDate;
          assessmentGeneralConfigurations.push(data);
        }
        if (classroom.subjects.some((s) => s?.isChecked)) {
          classroom.subjects.forEach((subject) => {
            let data = {};
            if (subject.isChecked) {
              if (subject.hasTheory) {
                if (
                  !subject.assessmentFromDateTheory ||
                  subject.assessmentFromDateTheory === null
                ) {
                  dateCount++;
                }
                if (
                  !subject.assessmentToDateTheory ||
                  subject.assessmentToDateTheory === null
                ) {
                  dateCount++;
                }
                if (
                  !subject.recordingDateTheory ||
                  subject.recordingDateTheory === null
                ) {
                  dateCount++;
                }
                if (
                  !subject.recordingDeadlineTheory ||
                  subject.recordingDeadlineTheory === null
                ) {
                  dateCount++;
                }
              }

              if (subject.hasPractical) {
                if (
                  !subject.assessmentFromDatePractical ||
                  subject.assessmentFromDatePractical === null
                ) {
                  dateCount++;
                }
                if (
                  !subject.assessmentToDatePractical ||
                  subject.assessmentToDatePractical === null
                ) {
                  dateCount++;
                }
                if (
                  !subject.recordingDatePractical ||
                  subject.recordingDatePractical === null
                ) {
                  dateCount++;
                }
                if (
                  !subject.recordingDeadlinePractical ||
                  subject.recordingDeadlinePractical === null
                ) {
                  dateCount++;
                }
              }

              errors = false;
              data.yearLevelId = yearLevel.value;
              data.classroomId = classroom.id;
              data.subjectId = subject.subjectId;
              data.educationLevelId = this.state.educationLevel;
              data.educationLevelDivisionId = this.state.educationLevelDivision;

              data.assessmentTheoryFromDate = subject.assessmentFromDateTheory
                ? subject.assessmentFromDateTheory
                    .replace("T", " ")
                    .concat(":00")
                : " ";

              data.assessmentTheoryToDate = subject.assessmentToDateTheory
                ? subject.assessmentToDateTheory.replace("T", " ").concat(":00")
                : " ";

              data.recordingTheoryDate = subject.recordingDateTheory
                ? subject.recordingDateTheory
                    .concat("00:00:00")
                    .replace(/(.{10})/g, "$1 ")
                    .trim()
                : " ";
              data.recordingTheoryDeadlineDate = subject.recordingDeadlineTheory
                ? subject.recordingDeadlineTheory
                    .concat("00:00:00")
                    .replace(/(.{10})/g, "$1 ")
                    .trim()
                : " ";

              data.assessmentPracticalFromDate =
                subject.assessmentFromDatePractical
                  ? subject.assessmentFromDatePractical
                      .replace("T", " ")
                      .concat(":00")
                  : " ";

              data.assessmentPracticalToDate = subject.assessmentToDatePractical
                ? subject.assessmentToDatePractical
                    .replace("T", " ")
                    .concat(":00")
                : " ";

              data.recordingPracticalDate = subject.recordingDatePractical
                ? subject.recordingDatePractical
                    .concat("00:00:00")
                    .replace(/(.{10})/g, "$1 ")
                    .trim()
                : " ";
              data.recordingPracticalDeadlineDate =
                subject.recordingDeadlinePractical
                  ? subject.recordingDeadlinePractical
                      .concat("00:00:00")
                      .replace(/(.{10})/g, "$1 ")
                      .trim()
                  : " ";

              assessmentSubjectConfigurations.push(data);
            }
          });
        }
        // else {
        //   subjectChkError++;
        // }
      });
    });

    if (subjectChkError === 0) {
      let param = {
        assessmentName: this.state.assessmentName,
        assessmentTypeCode: this.state.assessmentType,
        educationLevelId: this.state.educationLevel,
        educationLevelDivisionId: this.state.educationLevelDivision,
        assessmentGeneralConfigurations: assessmentGeneralConfigurations,
        assessmentSubjectConfigurations: assessmentSubjectConfigurations,
      };
      let yearLevels = [...this.state.selectedYearLevels];
      let yearLevelIds = [];
      if (yearLevels.length > 0) {
        yearLevels.forEach((el) => {
          yearLevelIds.push(el.value);
        });
      }
      if (this.props.edit) {
        param.id = this.props.updateData.id;
        param.yearLevelIds = yearLevelIds;
      }
      if (!errors) {
        if (dateCount > 0) {
          swal({
            title: "Warning",
            text: "Assessment from, to date or recording dates are missing",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          return;
        }
        let url = this.props.edit ? URL.updateAssessment : URL.insertAssessment;
        axiosPost(
          url,
          param,
          (response) => {
            if (response.status === 200) {
              displaySuccessAlert(response);
              this.setState(
                {
                  assessmentType: "",
                  educationLevel: this.state.educationLevels[0].id,
                  educationLevelDivision: "",
                  selectedYearLevels: [],
                  selectOptions: [],
                  assessmentName: "Monthly Test",
                },
                function () {
                  this.getEducationLevelDivisions();
                }
              );

              if (this.props.edit) {
                this.props.getAssessments(
                  this.props.code,
                  this.props.assessmentOffset,
                  this.props.assessmentRowCountLimit
                );
                this.props.updateAssessment();
              } else {
                this.props.getAssessments(this.props.code, 0, 6);
              }
            }
          },
          (err) => {
            displayErrorAlert(err);
          }
        );
      } else {
        swal({
          title: "Warning",
          text: "At least a subject or general assessment must be selected",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      }
    } else {
      swal("Error", "At least one subject should be checked.");
    }
  };

  render() {
    return this.state.render ? (
      <>
        {this.state.isAvailable ? (
          <div
            className="tt-widgetContent-tab-holder"
            style={this.props.edit ? { height: "87vh" } : { height: "79.5vh" }}
          >
            {this.props.edit ? null : (
              <div className="tt-group-header">
                Create Assessment
                {checkWidgetAdminOrNot(this.props.name) === true ? (
                  <button
                    className="tt-button tt-button-primary float-right permissionBtnCSS"
                    onClick={() => {
                      this.setState({
                        permissionModal: !this.state.permissionModal,
                      });
                    }}
                  >
                    Permissions
                  </button>
                ) : null}
              </div>
            )}
            <div
              className="container"
              style={
                this.props.edit
                  ? {
                      height: "76.3vh",
                      overflow: "auto",
                      border: "1px solid #ccc",
                    }
                  : {
                      height: "61vh",
                      overflow: "auto",
                      border: "1px solid #ccc",
                    }
              }
            >
              <CreateAssessmentForm
                selectOptions={this.state.selectOptions}
                handleGradeChange={this.handleGradeChange}
                selectedItem={this.state.selectedItem}
                setDeadLine={this.state.setDeadLine}
                universalHandleChange={this.universalHandleChange}
                assessmentName={this.state.assessmentName}
                assessmentType={this.state.assessmentType}
                educationLevel={this.state.educationLevel}
                educationLevelDivisions={this.state.educationLevelDivisions}
                recordingDeadlineDate={this.state.recordingDeadlineDate}
                recordingDeadlineParam={this.state.recordingDeadlineParam}
                educationLevels={this.state.educationLevels}
                educationLevelDivision={this.state.educationLevelDivision}
                edit={this.props.edit}
                minStartDate={this.state.minStartDate}
                maxEndDate={this.state.maxEndDate}
                selectedYearLevels={this.state.selectedYearLevels}
                assessmentTypes={this.state.assessmentTypes}
              />
              {this.state.selectedYearLevels.length > 0 ? (
                <div className="inside-content">
                  <GradeSection
                    selectedYearLevels={this.state.selectedYearLevels}
                    toggleClasses={this.toggleClasses}
                    handleChange={this.handleChange}
                    handleGeneralAssessmentCheckbox={
                      this.handleGeneralAssessmentCheckbox
                    }
                    handleGeneralAssessmentDateChange={
                      this.handleGeneralAssessmentDateChange
                    }
                    setDeadLine={this.state.setDeadLine}
                    handleSelectAllCheckBoxClick={
                      this.handleSelectAllCheckBoxClick
                    }
                    minStartDate={this.state.minStartDate}
                    maxEndDate={this.state.maxEndDate}
                    handleSelectRowCheckBoxClick={
                      this.handleSelectRowCheckBoxClick
                    }
                  />
                </div>
              ) : null}
            </div>
            <div className="container-fluid tt-form">
              <div className="row form-bg-color py-3">
                <div className="col text-right">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.handleAssessmentSubmit}
                  >
                    {this.props.edit ? "Update Assessment" : "Save Assessment"}
                  </button>
                </div>
              </div>
            </div>
            <ModalWindow
              modal={this.state.permissionModal}
              size="lg"
              id="tt-permissionModal"
              toggleModal={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
              modalHeader={"Assign permission to user"}
              modalBody={
                <GranularPermissionModal
                  widgetName={this.props.name}
                  moduleName="Recording Setup"
                  header="Create Assessment"
                  activityName="insert-assessment"
                />
              }
            ></ModalWindow>
          </div>
        ) : (
          <div
            className="tt-widgetContent-tab-holder"
            style={this.props.edit ? { height: "87vh" } : { height: "79.5vh" }}
          >
            {this.state.assignEducationlevel ? (
              <NoteDisplay
                textContent={`To add a new Assessment, you must first add education level divisions. Please add education level divisions and then add a new Assessment`}
              ></NoteDisplay>
            ) : (
              <NoteDisplay
                textContent={
                  "Please assign education level to " +
                  this.props.name +
                  " first. Then create assessment"
                }
              ></NoteDisplay>
            )}
          </div>
        )}
      </>
    ) : (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      ></div>
    );
  }
}

const mapStateToProps = (state, props) => ({});
const mapActionToProps = {
  getAssessmnetList,
  getAssessments,
};
export default connect(mapStateToProps, mapActionToProps)(CreateAssessments);
