import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL, admissionStatus, draggable } from "../../../../utils/Constants";
import { connect } from "react-redux";
import { getClassroomLevels } from "../../../../ducks/AcademicAdministrationDucks";
import AssignedStudentList from "./AssignedStudentList";
import UnassignedStudentList from "./UnassignedStudentList";
import swal from "sweetalert";
import AssignSearchBox from "./AssignSearchBox";
import NoteDisplay from "../../../../utils/noteDisplay";
import { Spinner } from "reactstrap";
import { displayErrorMessage } from "./../../../../utils/Utils";

class AssignStudentModalNewBody extends Component {
  state = {
    nameFields: null,
    searchName: "",
    lastNameCode: "",
    minAge: "",
    maxAge: "",
    searchValue: "",
    admissionList: [],
    classrooms: [],
    toggleStudentList: "assign",
    assignedStudentsList: [],
    selectedClassroom: "",
    termAlias:
      localStorage.getItem("classroom") === null
        ? "Section"
        : localStorage.getItem("classroom"), //get from localStorage
    yearLevelAlias:
      localStorage.getItem("yearLevel") === null
        ? "Class"
        : localStorage.getItem("yearLevel"), //get from localStorage
    isAvailable: false,
    showSpinner: false,
  };

  componentDidMount() {
    this.props.getClassroomLevels();
    this.unAssignedConfig();
    this.getNameFields();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      classrooms: nextProps.classroomLevels,
    };
  }

  getNameFields = () => {
    axiosPost(
      URL.getIdentityCluster,
      {
        isConfig: false,
      },
      (response) => {
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            this.setState({
              nameFields: response.data.data,
              searchName: response.data.data[0].id,
            });
          } else {
            this.setState({
              nameFields: [],
              searchName: "",
            });
          }

          let nameFields = response.data.data;
          if (nameFields.length > 0) {
            nameFields.forEach((al) => {
              if (al.fieldName === "Last Name") {
                this.setState({ lastNameCode: al.id });
              }
            });
          }
        }
      }
    );
  };
  getAdmissionStatusByKey = () => {
    let data = {
      code: admissionStatus.admissionCurrent,
    };
    axiosPost(URL.getAdmissionStatusByKey, data, (response) => {
      if (response.status === 200) {
        this.setState(
          { admissionStatusId: response.data.data.id },
          function () {
            this.getAdmissionListByStatus(this.state.admissionStatusId);
          }
        );
      }
    });
  };

  unAssignedConfig = () => {
    this.getUnAssignedStudents({});
  };

  getUnAssignedStudents = (data) => {
    data.yearLevelId = this.props.classroom.yearLevelId;
    axiosPost(URL.selectUnAssignedStudents, data, (response) => {
      if (response.status === 200) {
        let admissionList = response.data.data;
        admissionList.forEach((el) => {
          el.checked = false;
        });
        this.setState({
          admissionList,
        });
        this.getAssignedAdmissionList();
      }
    });
  };

  getAdmissionListByStatus = (id) => {
    let data = {
      admissionStatusId: id,
    };
    axiosPost(URL.admissionList, data, (response) => {
      if (response.status === 200) {
        let admissionList = response.data.data;
        admissionList.forEach((el) => {
          el.checked = false;
        });
        this.setState({ admissionList });
      }
    });
  };

  getAssignedAdmissionList = () => {
    let data = {
      yearLevelId: this.props.yearLevelId,
      classroomId: this.props.classroom.id,
    };

    axiosPost(URL.getAssignedStudents, data, (response) => {
      if (response.status === 200) {
        let assignedStudentsList = response.data.data;
        assignedStudentsList.forEach((el) => {
          el.checked = false;
        });
        this.setState(
          {
            assignedStudentsList: assignedStudentsList,
            allChecked: false,
          },
          function () {
            this.checkAvailable();
          }
        );
      }
    });
  };

  checkAvailable = () => {
    if (
      this.state.assignedStudentsList.length > 0 ||
      this.state.admissionList.length > 0
    ) {
      this.setState({ isAvailable: true });
    }
  };

  handleCheckbox = (e, idx, stateName) => {
    let array = [...this.state[stateName]];
    if (idx === "all") {
      array.forEach((el) => {
        el.checked = !el.checked;
      });
    } else {
      array[idx].checked = !array[idx].checked;
    }
    this.setState({ [stateName]: array });
  };
  handleChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    if (name === "minAge" || name === "maxAge") {
      if (
        (name === "minAge" && this.state.maxAge === "") ||
        (name === "maxAge" && this.state.minAge === "")
      ) {
        if (value > 30) {
          value = 30;
        }
        this.setState({
          [name]: value,
        });
      } else if (name === "minAge" && this.state.maxAge !== "") {
        if (value > parseInt(this.state.maxAge)) {
          swal({
            title: "Error",
            text: "Intial Age value cannot be greater than Final Age value",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
        } else {
          if (value > 30) {
            value = 30;
          }
          this.setState({
            [name]: value,
          });
        }
      } else if (name === "maxAge" && this.state.minAge !== "") {
        if (value < parseInt(this.state.minAge)) {
          swal({
            title: "Error",
            text: "Final Age value cannot be smaller than Initial Age value",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
        } else {
          if (value > 30) {
            value = 30;
          }
          this.setState({
            [name]: value,
          });
        }
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  reAssignStudents = (e) => {
    e.preventDefault();
    if (this.state.selectedClassroom === "") {
      swal({
        title: "Warning",
        text: `Select students and ${this.state.termAlias} first`,
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      let selectedClassroom = this.state.selectedClassroom;
      let newClassroom = {};
      this.state.classrooms.forEach((el) => {
        if (el.id === parseInt(selectedClassroom)) {
          newClassroom.id = el.id;
          newClassroom.yearLevelId = el.yearLevelId;
        }
      });
      let assignedStudentsList = [...this.state.assignedStudentsList];
      let classroom = {
        id: this.props.classroom.id,
        yearLevelId: this.props.yearLevelId,
      };
      let admissions = [];
      assignedStudentsList.forEach((el) => {
        if (el.checked === true) {
          admissions.push({
            id: el.admissionId,
          });
        }
      });
      let datas = {
        classroom: classroom,
        admissions: admissions,
        assessmentModuleCode: this.props.assessmentModuleCode,
        newClassroom: newClassroom,
      };
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.reAssignStudenttoClassroom,
          datas,
          (response) => {
            if (response.status === 200) {
              swal({
                title: "Success",
                text: `Select successfully re-assigned to ${this.state.termAlias}`,
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.getAssignedAdmissionList();
              this.setState({ showSpinner: false });
              this.unAssignedConfig();
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            let errorResponse = error.response ? error.response.data : error;
            if (errorResponse.status === 400) {
              //if condition to check spring boot validation errors
              let errorMessage = "";
              if (errorResponse.errors) {
                errorResponse.errors.forEach((error) => {
                  errorMessage += `${error.field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} ${error.defaultMessage} \n`; //ishan
                });
                swal(errorResponse.error, errorMessage, "");
              } else {
                swal(errorResponse.error, errorResponse.message, "");
              }
            } else {
              swal(
                errorResponse.error || "Network Error",
                errorResponse.message
                  ? errorResponse.message
                  : "Could Not Connect To The Server.",
                ""
              );
            }
          }
        );
      });
    }
  };

  assignIndividualStudents = (e, id) => {
    let admissionList = [...this.state.admissionList];
    let classroom = {
      id: this.props.classroom.id,
      yearLevelId: this.props.yearLevelId,
    };
    let admissions = [];
    admissionList.forEach((el) => {
      if (el.id === id) {
        admissions.push({
          id: el.id,
        });
      }
    });
    let datas = {
      classroom: classroom,
      admissions: admissions,
      assessmentModuleCode: this.props.assessmentModuleCode,
    };
    axiosPost(URL.insertStudenttoClassroom, datas, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: `Student successfully assigned to ${this.state.termAlias}`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getAssignedAdmissionList();
        this.unAssignedConfig();
      }
    });
  };

  assignStudents = (e) => {
    e.preventDefault();
    if (1 === 2) {
      swal({
        title: "Warning",
        text: `Select students and ${this.state.termAlias} first`,
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      let admissionList = [...this.state.admissionList];
      let classroom = {
        id: this.props.classroom.id,
        yearLevelId: this.props.yearLevelId,
      };
      let admissions = [];
      admissionList.forEach((el) => {
        if (el.checked === true) {
          admissions.push({
            id: el.id,
          });
        }
      });
      let datas = {
        classroom: classroom,
        admissions: admissions,
        assessmentModuleCode: this.props.assessmentModuleCode,
      };
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertStudenttoClassroom,
          datas,
          (response) => {
            if (response.status === 200) {
              swal({
                title: "Success",
                text: `Select successfully assigned to ${this.state.termAlias}`,
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.getAssignedAdmissionList();
              this.unAssignedConfig();
              this.setState({ showSpinner: false });
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            // displayErrorAlert(error);
            displayErrorMessage(error);
          }
        );
      });
    }
  };

  unAssignIndividualStudent = (e, id) => {
    let assignedStudentsList = [...this.state.assignedStudentsList];
    let unAssignAdmissionList = [];
    assignedStudentsList.forEach((el) => {
      if (el.admissionId === id) {
        unAssignAdmissionList.push({
          admissionId: el.admissionId,
        });
      }
    });
    axiosPost(URL.deleteAssignedStudents, unAssignAdmissionList, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Successfully unassigned",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getAssignedAdmissionList();
        this.unAssignedConfig();
      }
    });
  };

  handleSelectChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  searchStudents = () => {
    let data = "";
    if (this.state.toggleStudentList === "assign") {
      data = {
        minAge: this.state.minAge,
        maxAge: this.state.maxAge,
        searchName: this.state.searchName,
        searchValue: this.state.searchValue,
      };
      this.getUnAssignedStudents(data);
    } else {
      data = {
        yearLevelId: this.props.yearLevelId,
        classroomId: this.props.classroom.id,
        minAge: this.state.minAge,
        maxAge: this.state.maxAge,
        searchName: this.state.searchName,
        searchValue: this.state.searchValue,
      };
      axiosPost(URL.getAssignedStudentsForSearch, data, (response) => {
        if (response.status === 200) {
          let assignedStudentsList = response.data.data;
          assignedStudentsList.forEach((el) => {
            el.checked = false;
          });
          this.setState({
            assignedStudentsList: assignedStudentsList,
            allChecked: false,
          });
        }
      });
    }
  };

  render() {
    return this.state.isAvailable ? (
      <>
        <div className="container-fluid text-center">
          <AssignSearchBox
            toggleStudentList={this.state.toggleStudentList}
            handleChange={this.handleChange}
            classroom={this.props.classroom}
            yearLevel={this.props.yearLevel}
            nameFields={this.state.nameFields}
            minAge={this.state.minAge}
            maxAge={this.state.maxAge}
            searchName={this.state.searchName}
            searchValue={this.state.searchValue}
            searchStudents={this.searchStudents}
          ></AssignSearchBox>
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "66.5vh" }}
          >
            <div
              style={{
                height: "55.7vh",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {this.state.toggleStudentList === "assign" ? (
                <UnassignedStudentList
                  admissionList={this.state.admissionList}
                  handleCheckbox={this.handleCheckbox}
                  assignIndividualStudents={this.assignIndividualStudents}
                ></UnassignedStudentList>
              ) : (
                <AssignedStudentList
                  admissionList={this.state.assignedStudentsList}
                  handleCheckbox={this.handleCheckbox}
                  unAssignIndividualStudent={this.unAssignIndividualStudent}
                ></AssignedStudentList>
              )}
            </div>
            <div className="row tt-list_students tt-group-header mb-0">
              <div className="col-md-7 mt-1">
                {this.state.toggleStudentList === "assign"
                  ? `Assign Selected Students to ${this.state.termAlias}`
                  : `Re-assign Selected Students to ${this.state.termAlias}`}
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  name="selectedClassroom"
                  value={
                    this.state.toggleStudentList === "assign"
                      ? this.props.classroom.id
                      : this.state.selectedClassroom
                  }
                  onChange={this.handleSelectChange}
                  disabled={
                    this.state.toggleStudentList === "assign" ? true : false
                  }
                >
                  <option value="" disabled selected>
                    Choose {this.state.termAlias}
                  </option>
                  {this.state.classrooms.map((el, idx) => {
                    return (
                      <option value={el.id} key={idx}>
                        {`${this.state.yearLevelAlias} ${el.yearLevelName} - ${this.state.termAlias} ${el.name}`}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-2 mt-1">
                <button
                  className="tt-button tt-button-primary"
                  onClick={
                    this.state.toggleStudentList === "assign"
                      ? this.assignStudents
                      : this.reAssignStudents
                  }
                >
                  {this.state.toggleStudentList === "assign"
                    ? "Assign Students"
                    : "Re-assign Students"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait.
              {this.state.toggleStudentList === "assign"
                ? "Assigning Students ..."
                : "Re-assigning Students ..."}
            </div>
          </div>
        ) : null}
      </>
    ) : (
      <div className="tt-widgetContent-tab-holder" style={{ height: "80vh" }}>
        <NoteDisplay
          textContent={`To assign students to ${
            localStorage.getItem("classroom")
              ? localStorage.getItem("classroom")
              : "Section"
          }, you must first add students. Please add students first and then assign them to the ${
            localStorage.getItem("classroom")
              ? localStorage.getItem("classroom")
              : "Section"
          }`}
        ></NoteDisplay>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  classroomLevels: state.academicAdministration.classrooms,
});
const mapActionToProps = {
  getClassroomLevels: getClassroomLevels,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(AssignStudentModalNewBody);
