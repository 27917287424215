import React, { Component } from "react";
import GeneralASSectionThree from "./GeneralASSectionThree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../../../utils/Constants";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";

class OverallAssessmentType extends Component {
  state = {};

  componentDidUpdate() {
    $(".selectpicker").selectpicker();
  }
  render() {
    return this.props.overallAssessmentData.length > 0
      ? this.props.overallAssessmentData.map((overall, idx) => (
          <div className="tt-overallAssType">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-md-10">
                  <input
                    type="text"
                    value={overall.customAssessmentHeaderName}
                    name="customAssessmentHeaderName"
                    id={"customHeader" + idx}
                    className="form-control tt-input-comment-label-name"
                    onChange={(e) =>
                      this.props.handleNestedChange(
                        e,
                        idx,
                        "overallAssessmentData"
                      )
                    }
                  />
                </div>
                <div
                  className="col-md-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={(e) => this.props.handleUpdateCustomHeader(e, idx)}
                  />
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    onClick={(e) => this.props.handleDeleteCustomHeader(e, idx)}
                  />
                </div>
              </div>
              {overall["assessmentType"] === "both" ||
              (overall.assessmentGeneralCustomAssessmentScaleConfigurations !==
                null &&
                overall.assessmentGeneralCustomAssessmentScaleConfigurations
                  .length > 0 &&
                overall.assessmentGeneralCustomAssessmentRubricsConfigurations !==
                  null &&
                overall.assessmentGeneralCustomAssessmentRubricsConfigurations
                  .length > 0) ? (
                <>
                  <div className="row tt-customTableContainer">
                    <table border={1} className="table-striped" width="100%">
                      <thead className="tt-AssessmentTitle">
                        <tr>
                          {overall.assessmentGeneralCustomAssessmentScaleConfigurations.map(
                            (scale, i) => (
                              <th className="tt-subjectDisplayTable">
                                {scale.assessmentScale
                                  ? scale.assessmentScale.scaleName
                                  : scale.scaleName}
                                &nbsp;&nbsp;
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  onClick={(e) =>
                                    this.props.handleDeleteScaleTableData(
                                      e,
                                      idx,
                                      i
                                    )
                                  }
                                />
                              </th>
                            )
                          )}
                          <th>Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {overall.assessmentGeneralCustomAssessmentScaleConfigurations.map(
                            (scales, i) => {
                              return (
                                <td>
                                  <select className="form-control selectpicker">
                                    {scales.assessmentScale
                                      ? scales.assessmentScale.assessmentScaleLevels.map(
                                          (level, j) => (
                                            <>
                                              {level.levelType ===
                                              "colourStickers" ? (
                                                <option
                                                  style={{
                                                    backgroundColor:
                                                      level.level,
                                                    color: "#fff",
                                                    fontSize: "25px",
                                                  }}
                                                ></option>
                                              ) : level.levelType ===
                                                "image" ? (
                                                <option
                                                  data-content={`<img src=${
                                                    URL.imageSelectURL +
                                                    level.level
                                                  } class="img-responsive" height="42" width="42"/>`}
                                                ></option>
                                              ) : (
                                                <option>{level.level}</option>
                                              )}
                                            </>
                                          )
                                        )
                                      : scales.assessmentScaleLevels.map(
                                          (level, j) => (
                                            <>
                                              {level.levelType ===
                                              "colourStickers" ? (
                                                <option
                                                  style={{
                                                    backgroundColor:
                                                      level.level,
                                                    color: "#fff",
                                                    fontSize: "25px",
                                                  }}
                                                ></option>
                                              ) : level.levelType ===
                                                "image" ? (
                                                <option
                                                  data-content={`<img src=${
                                                    URL.imageSelectURL +
                                                    level.level
                                                  } class="img-responsive" height="42" width="42"/>`}
                                                ></option>
                                              ) : (
                                                <option>{level.level}</option>
                                              )}
                                            </>
                                          )
                                        )}
                                  </select>
                                </td>
                              );
                            }
                          )}
                          <td className="tt-subjectDisplayTable">
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              onClick={(e) =>
                                this.props.handleDeleteCustomOverallAssessmentRow(
                                  e,
                                  idx
                                )
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <GeneralASSectionThree
                    addMultipleDisciplineRow={
                      this.props.addMultipleDisciplineRow
                    }
                    assessmentGeneralCustomAssessmentRubricsConfigurations={
                      this.props
                        .assessmentGeneralCustomAssessmentRubricsConfigurations
                    }
                    universalHandleChange={this.props.universalHandleChange}
                    rubricsName={this.props.rubricsName}
                    description={this.props.description}
                    handleRemoveDisciplineRow={
                      this.props.handleRemoveDisciplineRow
                    }
                    handleNestedChange={this.props.handleNestedChange}
                    handleUpdateDisciplineRow={
                      this.props.handleUpdateDisciplineRow
                    }
                    customAssessmentHeaderName={
                      this.props.customAssessmentHeaderName
                    }
                    assessmentType={this.props.assessmentType}
                    overall={overall}
                    idx={idx}
                    handleDisciplineInputChange={
                      this.props.handleDisciplineInputChange
                    }
                    overallAssessmentData={this.props.overallAssessmentData}
                    handleDeleteScaleTableData={
                      this.props.handleDeleteScaleTableData
                    }
                    handleDeleteScaleTableDataForRubric={
                      this.props.handleDeleteScaleTableDataForRubric
                    }
                    handleCustomDisciplineRowChange={
                      this.props.handleCustomDisciplineRowChange
                    }
                    generalAssessmentUpdate={this.props.generalAssessmentUpdate}
                    customDescription={this.props.customDescription}
                    customRubricsName={this.props.customRubricsName}
                    //assessmentTypeHandleChange={this.props.assessmentTypeHandleChange}
                  />
                </>
              ) : overall["assessmentType"] === "rubricsBasedAssessment" ||
                overall.assessmentGeneralCustomAssessmentRubricsConfigurations
                  .length > 0 ? (
                <GeneralASSectionThree
                  addMultipleDisciplineRow={this.props.addMultipleDisciplineRow}
                  assessmentGeneralCustomAssessmentRubricsConfigurations={
                    this.props
                      .assessmentGeneralCustomAssessmentRubricsConfigurations
                  }
                  universalHandleChange={this.props.universalHandleChange}
                  rubricsName={this.props.rubricsName}
                  description={this.props.description}
                  handleRemoveDisciplineRow={
                    this.props.handleRemoveDisciplineRow
                  }
                  handleNestedChange={this.props.handleNestedChange}
                  handleUpdateDisciplineRow={
                    this.props.handleUpdateDisciplineRow
                  }
                  customAssessmentHeaderName={
                    this.props.customAssessmentHeaderName
                  }
                  assessmentType={this.props.assessmentType}
                  overall={overall}
                  idx={idx}
                  handleDisciplineInputChange={
                    this.props.handleDisciplineInputChange
                  }
                  overallAssessmentData={this.props.overallAssessmentData}
                  handleDeleteScaleTableData={
                    this.props.handleDeleteScaleTableData
                  }
                  handleDeleteScaleTableDataForRubric={
                    this.props.handleDeleteScaleTableDataForRubric
                  }
                  handleCustomDisciplineRowChange={
                    this.props.handleCustomDisciplineRowChange
                  }
                  generalAssessmentUpdate={this.props.generalAssessmentUpdate}
                  customDescription={this.props.customDescription}
                  customRubricsName={this.props.customRubricsName}
                  //assessmentTypeHandleChange={this.props.assessmentTypeHandleChange}
                />
              ) : overall["assessmentType"] === "overallAssessment" ||
                overall.assessmentGeneralCustomAssessmentScaleConfigurations
                  .length > 0 ? (
                //   <OverallAssessmentTable overall={overall} tableRow={this.tableRow} />

                <div className="row tt-customTableContainer">
                  <table border={1} className="table-striped" width="100%">
                    <thead className="tt-AssessmentTitle">
                      <tr>
                        {overall.assessmentGeneralCustomAssessmentScaleConfigurations.map(
                          (scale, i) => (
                            <th className="tt-subjectDisplayTable">
                              {scale.assessmentScale
                                ? scale.assessmentScale.scaleName
                                : scale.scaleName}
                              &nbsp;&nbsp;
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                onClick={(e) =>
                                  this.props.handleDeleteScaleTableData(
                                    e,
                                    idx,
                                    i
                                  )
                                }
                              />
                            </th>
                          )
                        )}
                        <th>Options</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {overall.assessmentGeneralCustomAssessmentScaleConfigurations.map(
                          (scales, i) => {
                            return (
                              <td>
                                <select className="form-control selectpicker">
                                  {scales.assessmentScale
                                    ? scales.assessmentScale.assessmentScaleLevels.map(
                                        (level, j) => (
                                          <>
                                            {level.levelType ===
                                            "colourStickers" ? (
                                              <option
                                                style={{
                                                  backgroundColor: level.level,
                                                  color: "#fff",
                                                  fontSize: "25px",
                                                }}
                                              ></option>
                                            ) : level.levelType === "image" ? (
                                              <option
                                                data-content={`<img src=${
                                                  URL.imageSelectURL +
                                                  level.level
                                                } class="img-responsive" height="42" width="42"/>`}
                                              ></option>
                                            ) : (
                                              <option>{level.level}</option>
                                            )}
                                          </>
                                        )
                                      )
                                    : scales.assessmentScaleLevels.map(
                                        (level, j) => (
                                          <>
                                            {level.levelType ===
                                            "colourStickers" ? (
                                              <option
                                                style={{
                                                  backgroundColor: level.level,
                                                  color: "#fff",
                                                  fontSize: "25px",
                                                }}
                                              ></option>
                                            ) : level.levelType === "image" ? (
                                              <option
                                                data-content={`<img src=${
                                                  URL.imageSelectURL +
                                                  level.level
                                                } class="img-responsive" height="42" width="42"/>`}
                                              ></option>
                                            ) : (
                                              <option>{level.level}</option>
                                            )}
                                          </>
                                        )
                                      )}
                                </select>
                              </td>
                            );
                          }
                        )}
                        <td className="tt-subjectDisplayTable">
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={(e) =>
                              this.props.handleDeleteCustomOverallAssessmentRow(
                                e,
                                idx
                              )
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        ))
      : null;
  }
}

export default OverallAssessmentType;
