import React, { Component } from "react";
import CustomButton from "../../components/common/CustomButton";
import ViewLMSFile from "../../components/SchoolLMS/ViewLMSFile";
import JitsiMeet from "../../components/VideoConferencing/jitsiMeet";
import ModalWindow from "../../UI/ModalWindow";
import { axiosPost } from "../../utils/AxiosApi";
import { postType, URL } from "../../utils/Constants";
import { displayErrorMessage, displayMessage } from "../../utils/Utils";
import NoticeFileViewer from "./NoticeFileViewer";

export class ListNotice extends Component {
  state = {
    resourceModal: false,
    activeNoticeFile: [],
    roomId: "",
    userName: "",
    password: "",
    createdByPeopleId: "",
    isHost: "",
    videoConferenceId: "",
    jitsiModal: false,
  };

  handleViewResources = (resource) => {
    this.setState({
      activeNoticeFile: resource,
      resourceModal: true,
    });
  };

  toggleFileViewer = () => {
    this.setState({
      resourceModal: !this.state.resourceModal,
    });
  };

  handleJoinConference = (id) => {
    let param = {
      postId: id,
      joinRequest: true,
    };
    axiosPost(
      URL.videoConferenceSelectByPostId,
      param,
      (response) => {
        if (response.status === 200 && response.data != null) {
          const roomId = response.data.data.jitsiRoomId;
          const userName = response.data.data.peopleName;
          const password = response.data.data.password;
          const createdByPeopleId = response.data.data.createdByPeopleId;
          const hasStarted = response.data.data.hasStarted;
          const isHost =
            localStorage.getItem("peopleId") == createdByPeopleId
              ? true
              : false;
          if (isHost && !hasStarted) {
            //update video conference
            let updateParam = response.data.data;
            updateParam.hasStarted = true;
            axiosPost(URL.videoConferenceUpdate, updateParam, (response) => {});
          }
          if (isHost || hasStarted) {
            this.setState({
              roomId: roomId,
              userName: userName,
              password: password,
              isHost: isHost,
              videoConferenceId: id,
            });

            // setRoomId(roomId);
            // setUsername(userName);
            // setPassword(password);
            // setIsHost(isHost);
            // setVideoConferenceId(id);
            password !== "" && password !== null
              ? this.toggleJitsiModal()
              : alert("Password is Required to join the room");
          } else {
            displayMessage(
              "Contact Host Provider",
              "Video Conference has not been started by the host"
            );
          }
        }
      },
      (err) => {
        displayErrorMessage(err);
      }
    );
  };

  toggleJitsiModal = () => {
    this.setState({
      jitsiModal: !this.state.jitsiModal,
    });
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Resources</th>
                </tr>
              </thead>
              <tbody>
                {this.props.noticeList.length > 0
                  ? this.props.noticeList.map((notice, index) => {
                      return (
                        <tr>
                          <td>{notice.heading}</td>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: notice.textContent,
                            }}
                          ></td>

                          <td>
                            {notice.postType === postType.videoConference ? (
                              <CustomButton
                                text="Join"
                                customStyle="cusBtnFontSize"
                                onClick={() =>
                                  this.handleJoinConference(notice.id)
                                }
                              ></CustomButton>
                            ) : null}

                            <CustomButton
                              text={
                                notice.postType === postType.videoConference
                                  ? "View Details"
                                  : "View Resources"
                              }
                              customStyle="cusBtnFontSize"
                              onClick={() =>
                                this.handleViewResources(notice.contents)
                              }
                            ></CustomButton>
                            {/* {" "}
                            <button
                              className="btn btn-primary cusBtn cusBtnFontSize"
                              onClick={() =>
                                this.handleViewResources(notice.contents)
                              }
                            >
                              View Resources
                            </button> */}
                          </td>
                          {/* <td>
                            <span
                              className="material-icons-round text-danger"
                              aria-hidden="true"
                              onClick={() =>
                                this.deletenotice(notice.id, index)
                              }
                            >
                              remove_circle
                            </span>
                          </td> */}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>

        <ModalWindow
          modal={this.state.resourceModal}
          // modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          size="lg"
          modalHeader="Resource Files"
          modalBody={
            <>
              {this.state.activeNoticeFile.length > 0 && (
                <NoticeFileViewer
                  activeNoticeFile={this.state.activeNoticeFile}
                />
              )}
            </>
          }
        />

        {this.state.roomId !== "" &&
          this.state.userName !== "" &&
          this.state.password !== "" && (
            <ModalWindow
              modal={this.state.jitsiModal}
              toggleModal={this.toggleJitsiModal}
              modalHeader="Jitsi Video Conferencing for Imagine Inquiry Sys"
              size="xl"
              modalBody={
                <JitsiMeet
                  isHost={this.state.isHost}
                  getMeetingId={this.state.roomId}
                  getDispNme={this.stateuserName}
                  toggleModal={this.state.toggleJitsiModal}
                  getPassword={this.state.password}
                  videoConferenceId={this.state.videoConferenceId}
                ></JitsiMeet>
              }
            ></ModalWindow>
          )}
      </div>
    );
  }
}

export default ListNotice;
