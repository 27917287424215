import $ from "jquery";

export function youtubeLink(content) {
  let videoId = "";
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  var match = content.match(regExp);
  if (match && match[7].length == 11) {
    videoId = match[7];
  }
  let contentObj = {
    youtubeTitle: content,
    thumbnail: `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
  };
  var url = "https://www.youtube.com/watch?v=" + videoId;

  //to get youtube title
  $.getJSON(
    "https://noembed.com/embed",
    { format: "json", url: url },
    function (data) {
      if (data !== undefined || data) {
        contentObj.youtubeTitle = data.title;
      } else {
        contentObj.youtubeTitle = content;
      }
    }
  );

  return contentObj;
}

export function dailyMotionLink(content) {
  let videoId = "";
  let url = content;
  var m = url.match(
    /^.+dailymotion.com\/(video|hub)\/([^_?]+)[^#]*(#video=([^_&]+))?/
  );
  if (m === null) {
    var n = url.match(
      /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
    );
    if (n !== null) {
      if (n[4] !== undefined) {
        videoId = n[4];
      }
      videoId = n[2];
    }
  } else if (m !== null) {
    if (m[4] !== undefined) {
      videoId = m[4];
    }
    videoId = m[2];
  }
  let contentObj = {
    youtubeTitle: content,
    thumbnail: `https://www.dailymotion.com/thumbnail/video/${videoId}`,
  };
  $.ajax({
    type: "GET",
    url:
      "https://api.dailymotion.com/video/" +
      videoId +
      "?fields=title,duration,user",
    dataType: "jsonp",
    cache: true,
    success: function (data) {
      contentObj.youtubeTitle = data.title;
    },
  });
  return contentObj;
}

export function vimeoLink(content) {
  var regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  let videoId = "";
  var parseUrl = content.match(regExp);
  videoId = parseUrl[5];
  let contentObj = {
    youtubeTitle: "",
    thumbnail: "",
  };
  $.ajax({
    type: "GET",
    url: "http://vimeo.com/api/v2/video/" + videoId + ".json",
    jsonp: "callback",
    dataType: "jsonp",
    success: function (data) {
      contentObj.youtubeTitle = data[0].title;
      contentObj.thumbnail = data[0].thumbnail_medium;
    },
  });
  return contentObj;
}

export function slideshareLink(content) {
  let contentObj = {
    youtubeTitle: "",
    thumbnail: "",
  };
  $.getJSON(
    "https://noembed.com/embed",
    { format: "json", url: content },
    function (data) {
      if (data !== undefined || data) {
        contentObj.youtubeTitle = data.title;
        contentObj.thumbnail = data.thumbnail;
      }
    }
  );
  return contentObj;
}

export function defaultLinkURL(content) {
  let contentObj = {
    youtubeTitle: "",
    thumbnail: "",
  };
  $.getJSON(
    "https://noembed.com/embed",
    { format: "json", url: content },
    function (data) {
      if (data !== undefined && data && !data.error) {
        contentObj.youtubeTitle = data.title;
        if (data.thumbnail_url) {
          contentObj.thumbnail = data.thumbnail_url;
        }
      } else {
        contentObj.youtubeTitle = content.content;
      }
    }
  );
  return contentObj;
}

export function getYoutubeEmbedLink(link) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  let embeddedURL = "";
  let videoId = "";
  var match = link.match(regExp);
  if (match && match[7].length === 11) {
    videoId = match[7];
    embeddedURL = `https://www.youtube.com/embed/${videoId}`;
  }
  return embeddedURL;
}

export function getDailyMotionEmbedLink(url) {
  let embeddedURL = "";
  let videoId = "";
  var m = url.match(
    /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/
  );
  if (m === null) {
    var n = url.match(
      /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/
    );
    if (n !== null) {
      if (n[4] !== undefined) {
        videoId = n[4];
      }
      videoId = n[2];
    }
  } else if (m !== null) {
    if (m[4] !== undefined) {
      videoId = m[4];
    }
    videoId = m[2];
  } else {
    videoId = null;
  }
  embeddedURL = `https://www.dailymotion.com/embed/video/${videoId}`;
  return embeddedURL;
}

export function getVimeoEmbedLink(url) {
  let embeddedURL = "";
  let videoId = "";
  var regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  var parseUrl = url.match(regExp);
  videoId = parseUrl[5];
  embeddedURL = `https://player.vimeo.com/video/${videoId}`;
  return embeddedURL;
}

export function getSoundcloudEmbedLink(link) {
  let embeddedURL = "https://w.soundcloud.com/player/?url=" + link;
  return embeddedURL;
}

export function getSlideShareEmbedLink(link) {
  let src = "";
  $.getJSON(
    "https://noembed.com/embed",
    { format: "json", url: link },
    function (data) {
      if (data !== undefined || data) {
        let htmlCode = data.html;
        let count = 0;
        let link = "";
        for (let i = 0; i < htmlCode.length; i++) {
          if (htmlCode.charAt(i) === '"') {
            count++;
          }
          if (count !== 0 && count < 2 && htmlCode.charAt(i + 1) !== '"') {
            link += htmlCode.charAt(i + 1);
          }
        }
        src = link;
      }
    }
  );
  return src;
}
