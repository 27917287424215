import React, { Component } from "react";
import CustomButton from "../../components/common/CustomButton";

export class SchoolImport extends Component {
  render() {
    return (
      <div>
        <div className="generateImportFile mb-2">
          <CustomButton
            text="Generate Import Format"
            customStyle="cusBtnFontSize"
            onClick={this.props.handleGenerateFormat}
          ></CustomButton>

          <div>
            <p> *Instructions</p>
            First generate the import format
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <input
              type="file"
              name="importFile"
              className="form-control mb-2"
              onChange={(e) => this.props.handleChange(e)}
            />
          </div>
          <div className="col-md-3">
            <CustomButton
              text="Import"
              customStyle="cusBtnFontSize"
              onClick={this.props.handleImport}
            ></CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolImport;
