import React, { Component } from "react";
import { connect } from "react-redux";
import { getEducationLevelsAcademicAdmin as getEducationLevels } from "../../../../ducks/AcademicAdministrationDucks";
import {
  URL,
  checkRolePermissions,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import { axiosPost } from "../../../../utils/AxiosApi";
import AllEducationLevels from "./AllEducationLevels";
import { getUnAssignedYearLevels } from "../../../../ducks/AcademicAdministrationDucks";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";

class ListEducationLevel extends Component {
  state = {
    educationLevels: [],
    yearLevels: [],
    educationLevelId: "",
    defaultAdministratorName: [],
    isLoading: true,
    permissionEditEducationLevel: false,
  };

  componentDidMount() {
    this.props.getEducationLevels();
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditEducationLevel: checkRolePermissions(
        "edit-education-level",
        "activity"
      ),
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.educationLevels !== prevState.educationLevels)
      return { educationLevels: nextProps.educationLevels, isLoading: false };
    else {
      return { isLoading: false };
    }
  }

  getYearLevels = () => {
    let data = {
      educationLevelId: this.state.educationLevelId,
    };
    axiosPost(URL.getYearLevelAssignedToEducationLevel, data, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  toggleYearLevels = (e, idx, id) => {
    e.preventDefault();
    this.setState({ educationLevelId: id }, function () {
      this.getYearLevels();
      let educationLevels = [...this.state.educationLevels];
      educationLevels.forEach((el) => {
        el.isOpen = false;
      });
      educationLevels[idx].isOpen = true;
      this.setState({ educationLevels });
    });
  };

  unAssignYearLevel = (e, yearLevelId) => {
    e.preventDefault();
    let data = {
      educationLevelId: this.state.educationLevelId,
      yearLevelId: yearLevelId,
    };
    axiosPost(URL.unAssignYearLevelFromEducationLevel, data, (response) => {
      if (response.status === 200) {
        this.getYearLevels();
        this.props.getUnAssignedYearLevels();
      }
    });
  };

  yearLevelsBody = () => {
    return (
      <table className="table table-bordered text-center table-striped">
        <thead className="tt-group-header">
          <tr>
            <th>SN</th>
            <th>{localStorage.getItem("yearLevel")}</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {this.state.yearLevels ? (
            this.state.yearLevels.map((yearLevel, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{yearLevel.yearLevelName}</td>
                  <td>
                    <button
                      className="tt-button tt-button-primary"
                      onClick={(e) =>
                        this.unAssignYearLevel(e, yearLevel.yearLevelId)
                      }
                    >
                      Un assign
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>
                There are no {localStorage.getItem("yearLevel")} assigned to
                this education level
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  toggleEducationLevels = (e, idx, id) => {
    e.preventDefault();
    this.setState({ educationLevelId: id }, function () {
      let educationLevels = [...this.state.educationLevels];
      educationLevels.forEach((el) => {
        el.isOpen = false;
        if (parseInt(el.id) === parseInt(id)) {
          this.setState(
            {
              defaultAdministratorName: el.educationLevelAdministrators,
            },
            () => {
              let newAdministratorList = [];
              let defaultAdministratorName = [
                ...this.state.defaultAdministratorName,
              ];
              defaultAdministratorName.forEach((adminstrator) => {
                newAdministratorList.push({
                  recruitmentId: adminstrator.recruitmentId,
                  value: adminstrator.recruitmentId,
                  label: adminstrator.staffName,
                });
              });
              this.setState({ defaultAdministratorName: newAdministratorList });
            }
          );
        }
      });
      educationLevels[idx].isOpen = true;
      this.setState({ educationLevels });
    });
  };

  closeCollapsible = (e) => {
    e.preventDefault();
    let educationLevels = [...this.state.educationLevels];
    educationLevels.forEach((el) => {
      el.isOpen = false;
    });
    this.setState({ educationLevels });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            List Education Level
            {checkWidgetAdminOrNot("Academic Administration") === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div className="tt-newListBox">
            <div className="container">
              <table className="table table-bordered text-center table-striped">
                <thead className="tt-group-header">
                  <tr>
                    <th>Education Level</th>
                    <th>Details</th>
                    {this.state.permissionEditEducationLevel ? (
                      <th width="15%">Options</th>
                    ) : null}
                  </tr>
                </thead>
                {!this.state.isLoading ? (
                  <tbody>
                    {this.state.educationLevels.length > 0 ? (
                      this.state.educationLevels.map((eduLevel, idx) => {
                        return (
                          <AllEducationLevels
                            eduLevel={eduLevel}
                            idx={idx}
                            yearLevelsBody={this.yearLevelsBody}
                            toggleYearLevels={this.toggleYearLevels}
                            key={idx}
                            toggleEducationLevels={this.toggleEducationLevels}
                            closeCollapsible={this.closeCollapsible}
                            profilePeopleId={this.props.profilePeopleId}
                            defaultAdministratorName={
                              this.state.defaultAdministratorName
                            }
                            permissionEditEducationLevel={
                              this.state.permissionEditEducationLevel
                            }
                          ></AllEducationLevels>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3}>No education levels</td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <div className="text-center" style={{ marginTop: "200px" }}>
                      <Spinner color="primary" />
                    </div>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School Groupings"
              header="List Education Level"
              activityName="select-education-level"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  educationLevels: state.academicAdministration.educationLevels,
});

const mapActionsToProps = {
  getEducationLevels,
  getUnAssignedYearLevels,
};

export default connect(mapStateToProps, mapActionsToProps)(ListEducationLevel);
