import React, { Component } from "react";
import { draggable, URL } from "../../utils/Constants";
import { displayMessage } from "../../utils/Utils";
import Swal from "sweetalert2";
import { axiosPost } from "../../utils/AxiosApi";
import swal from "@sweetalert/with-react";
import Snackbar from "@material-ui/core/Snackbar";

class UpdatePasswordModal extends Component {
  state = {
    password: null,
    confirmPassword: null,
    linkCopied: false,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validatePassword = () => {
    let error = 0;
    // let validateRegex = /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
    const validateRegex = "^[a-zA-Z0-9]*$";
    let ps = this.state.password;
    if (ps && (ps !== "" || ps !== null)) {
      if (ps.match(validateRegex)) {
        error = 0;
      } else {
        error++;
      }
    }
    if (error > 0 || ps.length < 8) {
      let texts = `<p>Password should have<br><ol><li>At least 8 characters</li><li>Alphanumeric characters</li></ol></p>`;
      Swal.fire({
        title: "Error",
        html: texts,
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    }
  };

  copyToClipboard = (data) => {
    let dummy1 = document.createElement("input");
    document.body.appendChild(dummy1);
    dummy1.setAttribute("value", data);
    dummy1.select();
    document.execCommand("copy");
    document.body.removeChild(dummy1);
  };

  renderLinkCopyText = () => {
    this.setState({ linkCopied: true });
  };

  handleChangePassword = () => {
    if (this.state.password !== null && this.state.confirmPassword !== null) {
      if (this.state.password === this.state.confirmPassword) {
        axiosPost(
          URL.updateForgetPassword,
          {
            password: this.state.password,
            username: this.props.selectedUsername,
            // id: this.props.selectedId,
          },
          (response) => {
            if (response.status === 200) {
              const username = response.data.data.username;
              const password = response.data.data.password;
              swal({
                title: `Password Updated`,
                content: (
                  <div>
                    New user with username: {username} and password: {password}{" "}
                    has been created.
                    <br></br>
                    <button
                      className="tt-button tt-button-primary"
                      onClick={() => {
                        this.copyToClipboard(
                          `Username: ${username}, Password: ${password}`
                        );
                        this.renderLinkCopyText();
                      }}
                    >
                      {this.state.linkCopied
                        ? "Copied"
                        : "Copy Username and Password"}
                    </button>
                  </div>
                ),
              });
              this.props.toggleForgotModal();
              this.props.getForgetPasswordUsers();
              this.props.getAllUsers();
            } else {
              displayMessage(
                "Error",
                "There was an error updating your password. Please try again."
              );
            }
          }
        );
      } else {
        swal({
          title: "Error",
          text: "New Password and Confirm Password do not match. Please re-check and submit again.",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      }
    } else {
      displayMessage("Error", "Please enter passwords on both fields.");
    }
  };

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.linkCopied}
          autoHideDuration={1000}
          onClose={() => this.setState({ linkCopied: false })}
          message="Copied to Clipboard"
        />

        <div className="row mb-2">
          <div className="col-md-4">
            <label>
              <strong>New password: </strong>
            </label>
          </div>
          <div className="col-md-8">
            <input
              id="pass"
              type="password"
              name="password"
              value={this.state.password}
              className="form-control"
              onChange={this.handleChange}
              onBlur={this.validatePassword}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label>
              <strong>Confirm password: </strong>
            </label>
          </div>
          <div className="col-md-8">
            <input
              type="password"
              name="confirmPassword"
              value={this.state.confirmPassword}
              className="form-control"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mt-1 mb-2">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary change-password"
              onClick={this.handleChangePassword}
            >
              Update password
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdatePasswordModal;
