import React, { Component } from "react";
import AssessmentQuestions from "./AssessmentQuestions";

export class ExamChkModal extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-2" style={{ borderRight: "1px solid #ccc" }}>
            <div className="text-center py-2">Exam Attendess</div>
            {this.props.examChkData.exams[0].peoples.map((list, idx) => (
              <div
                className={
                  "m-1 p-1" + (list?.isActive ? " activeTeacherSelect" : "")
                }
                key={idx}
                //   style={
                //     idx === this.props.students.length - 1
                //       ? { borderBottom: "none" }
                //       : null
                //   }
                // onClick={() => this.props.handleActiveStatus(idx)}
                onClick={(e) => this.props.handleTeacherCheckbox(e, idx, list)}
              >
                <label htmlFor={list.name + list.id}>{list.name}</label>
              </div>
            ))}
          </div>
          <div className="col-md-10">
            {this.props.assessmentFor && (
              <div className="row mt-2">
                <div className="col">
                  <div className="tt-group-header">
                    Assessment for: {this.props.assessmentFor.name}
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-2">
              <div className="col">
                <AssessmentQuestions
                  theoryQuestions={this.props.theoryQuestions}
                  practicalQuestions={this.props.practicalQuestions}
                  handleMarksChange={this.props.handleMarksChange}
                  calculateTotal={this.props.calculateTotal}
                  isCheckedExam={this.props.isCheckedExam}
                  examPassMarks={this.props.examChkData.exams[0].passMarks}
                />
              </div>
            </div>
            {this.props.answerData &&
              !this.props.isCheckedExam &&
              this.props.theoryQuestions.length > 0 && (
                <div className="row mb-2">
                  <div className="col">
                    <button
                      className="tt-button tt-button-primary float-right"
                      onClick={this.props.handleSubmit}
                      id="addQuestion"
                    >
                      Submit Assessment
                      {/* [{this.state.assessmentFor?.people?.name}] */}
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default ExamChkModal;
