/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, GeneralASSectionFour, render the Model for General Assessment Settings in the
 *              Record Setup in the Primary ARR
 */

import React, { Component } from "react";
import AssessmentTitleCheckBox from "./AssessmentTitleCheckBox";
import LevelScaleDropDown from "./LevelScaleDropDown";

class GeneralASSectionFour extends Component {
  state = {};
  render() {
    return (
      <>
        <div>
          <div className="row">
            <div className="col">
              <label htmlFor="customAssessmentHeaderName">
                <strong>Custom Assessment Header</strong>
              </label>
              <input
                type="text"
                className="form-control tt-custome-assessment-header-input"
                name="customAssessmentHeaderName"
                value={this.props.customAssessmentHeaderName}
                onChange={this.props.universalhandleChange}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="col">
              <strong>
                <label>Select Assessment Type</label>
              </strong>
              <AssessmentTitleCheckBox
                customAssessmentHeaderName={
                  this.props.customAssessmentHeaderName
                }
                handleNestedChange={this.props.handleNestedChange}
                universalhandleChange={this.props.universalhandleChange}
                assessmentType={this.props.assessmentType}
              />
            </div>
          </div>
          {this.props.assessmentType === "both" ? (
            <>
              <LevelScaleDropDown
                scaleName={this.props.scaleNameOverall}
                multipleScaleNameChange={
                  this.props.multipleScaleNameChangeForOverallAssessment
                }
                levelScales={this.props.levelScales}
                label="Level Scales For Overall Assessment"
              />
              <LevelScaleDropDown
                scaleName={this.props.scaleNameRubric}
                multipleScaleNameChange={
                  this.props.multipleScaleNameChangeForRubric
                }
                levelScales={this.props.levelScales}
                label="Level Scales For Rubrics Based Assessment"
              />
            </>
          ) : (
            <LevelScaleDropDown
              scaleName={
                this.props.assessmentType === "overallAssessment"
                  ? this.props.scaleNameOverall
                  : this.props.scaleNameRubric
              }
              multipleScaleNameChange={
                this.props.assessmentType === "overallAssessment"
                  ? this.props.multipleScaleNameChangeForOverallAssessment
                  : this.props.multipleScaleNameChangeForRubric
              }
              levelScales={this.props.levelScales}
              label={
                this.props.assessmentType === "overallAssessment"
                  ? "Level Scales For OverallAssessment"
                  : "Level Scales For Rubric Based Assessment"
              }
            />
          )}
          <div>
            <i>
              *Hold <strong>Ctrl</strong> to select multiple options.
            </i>
          </div>
          <div className="row mt-1">
            <div className="col">
              <label htmlFor="assessor">
                <strong>Assessor</strong>
              </label>
              <select
                name="assessor"
                className="form-control"
                onChange={this.props.universalhandleChange}
                value={this.props.assessor}
              >
                <option value="" disabled>
                  Select Assessor
                </option>

                <optgroup label="Roles">
                  {this.props.roleList
                    ? this.props.roleList.map((role, idx) => {
                        let val = `${role.subRoleId},role`;
                        return (
                          <option key={idx} value={val}>
                            {role.name}
                          </option>
                        );
                      })
                    : null}
                </optgroup>
                <optgroup label="Staffs">
                  {this.props.recruitmentList
                    ? this.props.recruitmentList.map((teacher, i) => {
                        let val = `${teacher.id},staff`;
                        return (
                          <option value={val} key={i}>
                            {teacher.people.name}
                          </option>
                        );
                      })
                    : null}
                </optgroup>
              </select>
            </div>
          </div>
          <div className="row mt-2" style={{ marginBottom: "5px" }}>
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.addNewDisciplineRowHandler}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GeneralASSectionFour;
