import React, { Component } from "react";
import moment from "moment";
import userImage from "../../../../assets/images/user-image.png";
import { URL } from "../../../../utils/Constants";
import { handleError } from "../../../../utils/Utils";
import { axiosPost } from "../../../../utils/AxiosApi";

class StaffModalData extends Component {
  state = {
    document: null,
  };

  checkAge = (dateOfBirth) => {
    if (dateOfBirth) {
      const year = moment().diff(dateOfBirth.substring(0, 10), "years");
      let message = year < 10 ? `${year} year old` : `${year} years old`;
      return message;
    }
  };

  getDocuments = () => {
    axiosPost(
      URL.getPeopleDocument,
      {
        peopleId: this.props.staffModalData.peopleId,
      },
      (response) => {
        if (response.status === 200) {
          if (response.data.data.resourcePath) {
            const newWindow = window.open(
              `${URL.fileDownloadURL}${response.data.data.resourcePath}`,
              "_blank",
              "noopener,noreferrer"
            );
            if (newWindow) newWindow.opener = null;
          }
        }
      }
    );
  };

  renderTableData = (field) => {
    const { customFieldTableColumns, highestLength } = field;
    let height = highestLength < 15 ? `30px` : `${highestLength + 10}px`;
    return (
      <div className="row">
        {customFieldTableColumns?.map((column) => (
          <>
            {column.customFieldTableColumnValues === null &&
            column.customFieldTableSubColumns !== null ? (
              <>
                <div className="col-md-12 text-center">
                  <strong>{column.name}</strong>
                  <hr></hr>
                </div>
                <div className="row container-fluid">
                  {column?.customFieldTableSubColumns?.map(
                    (subCol, subColIdx) => (
                      <div
                        className="col-md-3 mx-auto text-center"
                        key={subCol.id}
                      >
                        <span>
                          <strong>{subCol.name}</strong>
                        </span>
                        <hr></hr>
                        {subCol?.customFieldTableColumnValues?.map(
                          (value, valueIdx) => (
                            <div
                              style={{ height: height }}
                              className="border-bottom"
                            >
                              {value.value === "true"
                                ? "Yes"
                                : value.value === "false"
                                ? "No"
                                : value.value}
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <div className="col-md-3 mx-auto text-center">
                <span>
                  <strong>{column.name}</strong>
                </span>
                <hr></hr>
                {column?.customFieldTableColumnValues?.map((value) => (
                  <div
                    style={{ height: height }}
                    className="border-bottom"
                    key={value.id}
                  >
                    {value.value === "true"
                      ? "Yes"
                      : value.value === "false"
                      ? "No"
                      : value.value}
                  </div>
                ))}
              </div>
            )}
          </>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "78.7vh", overflowX: "hidden", overflowY: "auto" }}
      >
        <div className="row">
          <div className="col-md-5">
            <div className="tt-ppDetail">
              <div>
                <img
                  src={
                    this.props.staffModalData.photo
                      ? URL.imageSelectURL +
                        this.props.staffModalData.people.photo
                      : userImage
                  }
                  onError={(e) => handleError(e)}
                ></img>
              </div>
            </div>
          </div>
          <div className="col-md-7 detailStaffStudent">
            <table className="table table-bordered table-striped">
              <thead className="tt-group-header">
                <tr>
                  <th colSpan={2}>General Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="40%">
                    <strong>Name</strong>
                  </td>
                  <td>
                    {this.props.staffModalData
                      ? this.props.staffModalData.people
                        ? this.props.staffModalData.people.name
                        : ""
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Gender</strong>
                  </td>
                  <td>
                    {this.props.staffModalData
                      ? this.props.staffModalData.people
                        ? this.props.staffModalData.people.gender
                        : ""
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Email Address</strong>
                  </td>
                  <td>
                    {this.props.staffModalData.people
                      ? this.props.staffModalData.people.emailAddress !== null
                        ? this.props.staffModalData.people.emailAddress
                        : "No email Address"
                      : "No email Address"}
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Date of Birth</strong>
                  </td>
                  <td>
                    {this.props.staffModalData
                      ? this.props.staffModalData.people &&
                        this.props.staffModalData.people.dateOfBirth
                        ? moment(
                            this.props.staffModalData.people.dateOfBirth
                          ).format("Do MMMM, YYYY")
                        : ""
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Age</strong>
                  </td>
                  <td>
                    {this.props.staffModalData
                      ? this.props.staffModalData.people &&
                        this.props.staffModalData.people.dateOfBirth
                        ? moment().diff(
                            this.props.staffModalData.people.dateOfBirth.substring(
                              0,
                              10
                            ),
                            "years"
                          ) === 0
                          ? `1 year old`
                          : this.checkAge(
                              this.props.staffModalData.people.dateOfBirth
                            )
                        : ""
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <strong>Recuritment Status</strong>
                  </td>
                  <td>{this.props.staffModalData.recruitmentStatusName}</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered table-striped">
              <thead className="tt-group-header">
                <tr>
                  <th colSpan={2} className="text-center">
                    Documents
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center" width="40%">
                    <strong>CV</strong>
                  </td>
                  <td className="text-center">
                    {this.props.staffModalData?.people?.peopleDocument ? (
                      <button
                        className="tt-button tt-button-primary"
                        onClick={this.getDocuments}
                      >
                        Download File
                      </button>
                    ) : (
                      "none"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {this.props.peopleAddress.length > 0
              ? this.props.peopleAddress.map((pA, idx) => {
                  return (
                    <table
                      key={idx}
                      className="table table-bordered table-striped"
                    >
                      <thead className="tt-group-header">
                        <tr>
                          <th colSpan={2}>
                            {pA.customTableFieldGroupName
                              ? pA.customTableFieldGroupName
                              : "Address Information"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="40%">
                            <strong>Country</strong>
                          </td>
                          <td>{pA.countryName}</td>
                        </tr>
                        {pA.countryFirstLevelId &&
                        pA.countryFirstLevelId !== "" ? (
                          <>
                            <tr>
                              <td width="40%">
                                <strong>{pA.firstLevelDisplayName}</strong>
                              </td>
                              <td>{pA.countryFirstLevelName}</td>
                            </tr>
                            {pA.countrySecondLevelId &&
                            pA.countrySecondLevelId !== "" ? (
                              <>
                                <tr>
                                  <td width="40%">
                                    <strong>{pA.secondLevelDisplayName}</strong>
                                  </td>
                                  <td>{pA.countrySecondLevelName}</td>
                                </tr>
                                {pA.countryThirdLevelId &&
                                pA.countryThirdLevelId !== "" ? (
                                  <>
                                    <tr>
                                      <td width="40%">
                                        <strong>
                                          {pA.thirdLevelDisplayName}
                                        </strong>
                                      </td>
                                      <td>{pA.countryThirdLevelName}</td>
                                    </tr>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {pA.addressCustomTableFieldValues.length > 0
                          ? pA.addressCustomTableFieldValues.map(
                              (addr, aIdx) => {
                                return (
                                  <tr key={aIdx}>
                                    <td width="40%">
                                      <strong>
                                        {addr.customTableFieldName}
                                      </strong>
                                    </td>
                                    <td>
                                      {addr.value ? addr.value : "No data"}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  );
                })
              : null}
            <div style={{ border: "1px solid #ccc" }}>
              <div className="tt-group-header" style={{ borderRadius: 0 }}>
                Other information
              </div>
              <div style={{ padding: "0 10px" }}>
                {Object.keys(this.props.recruitmentListByNonCluster).map(
                  (key) => {
                    return (
                      <table className="table table-bordered table-striped">
                        <thead className="tt-group-header">
                          <tr>
                            <th colSpan={2}>
                              <strong>{`${key}`}</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.recruitmentListByNonCluster[key].map(
                            (el, idx) => (
                              <>
                                {el.fieldTypeCode !== "table" ? (
                                  <tr key={idx}>
                                    <td width="40%">
                                      <strong>{el.customTableFieldName}</strong>
                                    </td>
                                    <td>{el.value ? el.value : "No data."}</td>
                                  </tr>
                                ) : (
                                  <>
                                    <td>
                                      <strong>{el.customTableFieldName}</strong>
                                    </td>
                                    <td>{this.renderTableData(el)}</td>
                                  </>
                                )}
                              </>
                            )
                          )}
                        </tbody>
                      </table>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffModalData;
