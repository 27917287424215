import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";
import OtherActivitiesData from "./OtherActivitiesData";

class CategoryList extends Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.categoriesList.length > 0
                  ? this.props.categoriesList.map((category, index) => {
                      return (
                        <tr>
                          <td>
                            <img
                              width="100"
                              src={URL.imageSelectURL + category.thumbnailPath}
                            />
                          </td>
                          <td>{category.name}</td>
                          <td>{category.description}</td>

                          <td>
                            <button
                              className="btn btn-primary cusBtn cusBtnFontSize"
                              onClick={() =>
                                this.props.handleClickCategory(category)
                              }
                            >
                              View Data
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>

        <ModalWindow
          modal={this.props.categoryDataModal}
          // modalClass="fullModal"
          toggleModal={this.props.toggleClickCategory}
          size="xl"
          modalHeader="Resource"
          modalBody={
            <OtherActivitiesData
              activeCat={this.props.activeCat}
              toggleClickCategory={this.props.toggleClickCategory}
              getOtherActivities={this.props.getOtherActivities}
            />
          }
        />
      </>
    );
  }
}

export default CategoryList;
