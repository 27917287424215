import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

class SubTopicReRender extends Component {
  state = {};

  render() {
    return (
      <div className="tt-subTopicDiv pb-2">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <strong className="courseTitle text-primary">Topic Name</strong>
          </div>
          <div className="col-md-4">
            <strong className="courseTitle text-primary">Description</strong>
          </div>

          <div className="col-md-4"></div>
        </div>
        {this.props.topic && this.props.topic.length > 0
          ? this.props.topic.map((top, idx) => {
              return (
                <span key={idx}>
                  <div className="row mt-1" key={idx}>
                    <div className="col-md-1 text-end courseTitle mt-1">
                      {idx + 1}.
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className={`form-control cusInput shadow-none ${
                          top.nameErr && "is-invalid"
                        }`}
                        name="name"
                        value={top.name}
                        onChange={(e) =>
                          this.props.handleReRenderChange(
                            e,
                            this.props.topic,
                            idx
                          )
                        }
                      ></input>
                      {top.nameErr && (
                        <div className="errorMsg-textField mt-1">
                          *Course name is required!
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <input
                        type="text"
                        className={`form-control cusInput shadow-none ${
                          top.descErr && "is-invalid"
                        }`}
                        name="description"
                        value={top.description}
                        onChange={(e) =>
                          this.props.handleReRenderChange(
                            e,
                            this.props.topic,
                            idx
                          )
                        }
                      ></input>
                      {top.descErr && (
                        <div className="errorMsg-textField mt-1">
                          *Course description is required!
                        </div>
                      )}
                    </div>
                    <div className="col-md-auto">
                      <button
                        className="btn btn-primary cusBtn cusBtnFontSize mr-3"
                        onClick={() =>
                          this.props.addSubtopics(this.props.topic, idx)
                        }
                        disabled={
                          top.subTopic && top.subTopic.length > 0 ? true : false
                        }
                      >
                        Add topics
                      </button>
                    </div>
                    <div className="col-md-auto text-center">
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="deleteColor"
                        onClick={(e) =>
                          this.props.removeSubtopicAsTopic(
                            e,
                            this.props.topic,
                            idx
                          )
                        }
                        style={{ fontSize: "19px" }}
                      ></FontAwesomeIcon>
                    </div>
                    <div className="col-md-auto text-center">
                      {idx === this.props.topic.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="editColor"
                          onClick={() =>
                            this.props.addSubtopicAsTopic(this.props.topic)
                          }
                          style={{ fontSize: "19px" }}
                        ></FontAwesomeIcon>
                      ) : null}
                    </div>
                  </div>
                  {top.subTopic && top.subTopic.length > 0 ? (
                    <div className="row mt-1 mb-1">
                      <div className="col-md-1"></div>
                      <div className="col">
                        <SubTopicReRender
                          topic={top.subTopic}
                          addSubtopics={this.props.addSubtopics}
                          handleReRenderChange={this.props.handleReRenderChange}
                          addSubtopicAsTopic={this.props.addSubtopicAsTopic}
                          removeSubtopicAsTopic={
                            this.props.removeSubtopicAsTopic
                          }
                        ></SubTopicReRender>
                      </div>
                    </div>
                  ) : null}
                </span>
              );
            })
          : null}
      </div>
    );
  }
}

export default SubTopicReRender;
