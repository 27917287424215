import React, { Component } from "react";
import { checkRolePermissions } from "../../../utils/Constants";
import { Spinner } from "reactstrap";

export default class AssessmentModuleEducationLevelList extends Component {
  state = {
    permissionEditAssessmentList: false,
    permissionDeleteAssessmentList: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditAssessmentList: checkRolePermissions(
        "edit-assessment-list",
        "activity"
      ),
      permissionDeleteAssessmentList: checkRolePermissions(
        "delete-assessment-list",
        "activity"
      ),
    });
  };

  render() {
    return !this.props.moduleSpinner ? (
      <>
        <table className="table table-bordered text-center table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>Education Level</th>
              <th>Assessment Module</th>
              <th>Coordinator</th>
              {this.state.permissionEditAssessmentList ||
              this.state.permissionDeleteAssessmentList ? (
                <th>Options</th>
              ) : null}
            </tr>
          </thead>

          <tbody>
            {this.props.assessmentModuleEducationLevelList !== undefined &&
            this.props.assessmentModuleEducationLevelList.length > 0 ? (
              this.props.assessmentModuleEducationLevelList.map((list, idx) => {
                return (
                  <tr key={idx}>
                    <td>{list.educationLevelName}</td>
                    <td>{list.assessmentModuleName}</td>
                    <td>
                      {list.editable ? (
                        <select
                          className="form-control"
                          value={this.props.coordinatorName}
                          onChange={this.props.handleChange}
                          name="coordinatorName"
                        >
                          <option value="" disabled>
                            Choose Coordinator
                          </option>
                          {this.props.staffList !== undefined ? (
                            <>
                              {this.props.staffList.map((staff, idx) => {
                                return (
                                  <option value={staff.id} key={idx}>
                                    {staff.people.name}
                                  </option>
                                );
                              })}
                            </>
                          ) : null}
                        </select>
                      ) : list.staffName ? (
                        list.staffName
                      ) : (
                        "No Coordinator"
                      )}
                    </td>
                    {this.state.permissionEditAssessmentList ||
                    this.state.permissionDeleteAssessmentList ? (
                      <td>
                        {this.state.permissionEditAssessmentList ? (
                          list.editable ? (
                            <>
                              <button
                                className="tt-button tt-button-primary"
                                onClick={(e) =>
                                  this.props.handleUpdateSubmit(
                                    e,
                                    list.educationLevelId,
                                    list.assessmentModuleCode
                                  )
                                }
                              >
                                Save
                              </button>
                              <button
                                className="tt-button tt-button-primary ml-2 mr-2"
                                onClick={(e) =>
                                  this.props.handleCancelUpdate(e, idx)
                                }
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <button
                              className="tt-button tt-button-primary"
                              onClick={(e) =>
                                this.props.handleUpdateCoordinator(e, idx)
                              }
                            >
                              Update
                            </button>
                          )
                        ) : null}
                        {this.state.permissionDeleteAssessmentList ? (
                          <button
                            className="tt-button tt-button-primary"
                            onClick={(e) => this.props.deleteConfirm(e, list)}
                          >
                            Delete
                          </button>
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={
                    this.state.permissionEditAssessmentList ||
                    this.state.permissionDeleteAssessmentList
                      ? 4
                      : 3
                  }
                >
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    ) : (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    );
  }
}
