import React, { Component } from "react";

class ScaleNameType extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <label>
              <strong>Scale type</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4">
            <select
              className="form-control assess-record-dropdown"
              value={this.props.scaleType}
              name="scaleType"
              onChange={this.props.scaleTypeChange}
            >
              <option value="" disabled>
                Choose Scale Type
              </option>
              {this.props.assessmentScales
                ? this.props.assessmentScales.map((scale, idx) => (
                    <option value={scale.code} key={idx}>
                      {scale.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2"></div>
          <div className="col-md-3">
            <label>
              <strong>Scale name</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className={
                this.props.nameError
                  ? "form-control scale-name-field indicate-error"
                  : "form-control scale-name-field"
              }
              name={
                this.props.scaleType === "sct-mrk"
                  ? "scaleNameForMarks"
                  : this.props.scaleType === "sct-lvl"
                  ? "scaleNameForLevels"
                  : "scaleNameForGpa"
              }
              value={
                this.props.scaleType === "sct-mrk"
                  ? this.props.scaleNameForMarks
                  : this.props.scaleType === "sct-lvl"
                  ? this.props.scaleNameForLevels
                  : this.props.scaleNameForGpa
              }
              onChange={this.props.scaleTypeChange}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ScaleNameType;
