import React, { Component } from "react";
import Navbar from "../Profile/Navbar";
import CoverPicture from "../Profile/CoverPicture";
import NoticeDisplay from "../Profile/Notice/NoticeDisplay";
import PostArea from "../Profile/PostArea";
import CalendarDisplay from "../Profile/CalendarDisplay";
import cover1 from "../../assets/images/profile-bg.png";
import { axiosPost } from "../../utils/AxiosApi";
import GroupMemberLists from "./GroupMemberLists";
import { URL } from "../../utils/Constants";
import NoAccess from "../Error Page/NoAccess";
import MobileUserDetail from "../Profile/MobileUserDetail";
import { connect } from "react-redux";
import {
  getGroupPosts,
  getPersonPostSectionAcademicRepoPosts,
} from "../../ducks/PostsDucks";
import NewProfileHeader from "../Profile/NewProfileHeader";
import History from "../../utils/History";

class GroupPage extends Component {
  state = {
    dropdownOpen: false,
    pictures: [
      { id: "tt-cover1", src: cover1 },
      { id: "tt-cover2", src: cover1 },
    ],
    fullName: "",
    userRole: "",
    userData: "",
    minimize: true,
    setting: false,
    groupData: "",
    groupMembers: [],
    classroom: false,
    hasAccess: true,
    groupName: "",
    groupSpinner: true,
    offset: 0,
    totalRecordsCount: 0,
    rowCountLimit: 20,
    items: 20,
    loadingState: false,
    isLegal: false,
    schoolInfos: [],
  };

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  toggleHover = (component, action) => {
    let systemDefault = document.getElementById("tt-systemDefault");
    let personalChange = document.getElementById("tt-personalChange");
    if (component === "cover") {
      if (action === "show") {
        if (document.getElementById("tt-editButton")) {
          document.getElementById("tt-editButton").style.opacity = 1;
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.opacity = 1;
          personalChange.style.opacity = 1;
        }
      } else {
        if (document.getElementById("tt-editButton")) {
          document.getElementById("tt-editButton").style.opacity = 0;
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.opacity = 0;
          personalChange.style.opacity = 0;
        }
      }
    } else {
      if (action === "show") {
        document.getElementById("tt-editProfileButton").style.opacity = 1;
      } else {
        document.getElementById("tt-editProfileButton").style.opacity = 0;
      }
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSchoolInfo();
    let initialData = this.props.match.params.groupName;
    let groupName = initialData.replace(/_/g, " ");
    this.setState({
      groupName: groupName,
    });
    this.checkAccess(groupName);
    this.getGroupData(groupName);
    this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
  }

  loadMoreItems = () => {
    if (this.state.groupMembers.length < this.state.totalRecordsCount) {
      this.setState({ loadingState: true });
      let offset = this.state.offset + this.state.rowCountLimit;
      this.setState({ offset: offset });
      setTimeout(() => {
        this.setState({ items: this.state.items + 20, loadingState: false });
      }, 400);
      let data = {
        id: this.state.groupData.id,
        offset: this.state.offset,
        // peopleName:this.state.people,
        rowCountLimit: this.state.rowCountLimit,
      };
      axiosPost(URL.getGroupSelectByKey, data, (response) => {
        if (response.status === 200) {
          let groupMembers = this.state.groupMembers;
          let peoples = response.data.data?.groupPeople;
          if (peoples && peoples.length > 0) {
            peoples.forEach((el) => {
              el.displayPhoto = URL.imageSelectURL + "136x136" + "/" + el.photo;
            });
          } else {
            peoples = [];
          }
          this.setState({
            groupMembers: [...groupMembers, ...peoples],
            totalRecordsCount: response.data.totalRecordsCount,
            groupSpinner: false,
          });
        }
      });
    }
  };

  checkAccess = (groupName) => {
    let data = {
      name: groupName,
    };
    axiosPost(URL.checkGroupAccess, data, (response) => {
      if (response.status === 200) {
        this.setState({ hasAccess: response.data.data }, function () {
          if (this.state.hasAccess) {
            this.minimizeCoverToggle();
          }
        });
      }
    });
  };

  getGroupData = (groupName) => {
    let data = {
      name: groupName,
    };
    axiosPost(
      URL.getGroupByName,
      data,
      (response) => {
        debugger;
        if (response.status === 200) {
          localStorage.setItem("groupId", response.data.data.id);
          this.setState(
            {
              groupData: response.data.data,
              // groupMembers: response.data.data.groupPeople,
              classroom: false,
              // groupSpinner: false,
            },
            () => {
              //get group posts here
              // this.props.getGroupPosts(this.state.groupData.id, "initCall");
              this.checkGroupAdminModerator();
              this.loadGroupMembers();
            }
          );
        }
      },
      (error) => {
        if (error.response.status == 404) {
          History.push(`${process.env.PUBLIC_URL}/profile`);
        }
      }
    );
  };

  checkGroupAdminModerator = () => {
    let data = {
      groupId: this.state.groupData.id,
    };
    axiosPost(URL.checkGroupAdminModerator, data, (response) => {
      if (response.status === 200) {
        this.setState({ isLegal: response.data.data });
      }
    });
  };

  loadGroupMembers = () => {
    let data = {
      id: this.state.groupData.id,
      offset: this.state.offset,
      // peopleName:this.state.people,
      rowCountLimit: this.state.rowCountLimit,
    };
    axiosPost(URL.getGroupSelectByKey, data, (response) => {
      if (response.status === 200) {
        let peoples = response.data.data?.groupPeople;
        if (peoples && peoples.length > 0) {
          peoples.forEach((el) => {
            el.displayPhoto = URL.imageSelectURL + "136x136" + "/" + el.photo;
          });
        } else {
          peoples = [];
        }
        this.setState({
          groupMembers: peoples,
          totalRecordsCount: response.data.totalRecordsCount,
          groupSpinner: false,
        });
      }
    });
  };

  minimizeCoverToggle = () => {
    var coverImage = document.querySelectorAll(".tt-slider-content");
    var coverImageHolder = document.getElementById("tt-coverImage");
    var profileHeaderDiv = document.getElementById("newProfileDiv");
    let systemDefault = document.getElementById("tt-systemDefault");
    let personalChange = document.getElementById("tt-personalChange");
    var editButton = document.getElementById("tt-editButton");
    let userDetail = document.getElementById("newUserDetailBox");
    let userData = document.getElementById("newUserData");

    this.setState({ minimize: !this.state.minimize }, function () {
      if (this.state.minimize) {
        coverImageHolder.classList.add("tt-cover-minimize");
        profileHeaderDiv.classList.add("minimizeProfileDiv");
        userDetail.classList.add("minimizeUserDetail");
        userData.style.display = "none";
        if (editButton) {
          editButton.style.display = "none";
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.display = "none";
          personalChange.style.display = "none";
        }
        for (let i = 0; i < coverImage.length; i++) {
          coverImage[i].classList.add("tt-slider-content-minimize");
        }
      } else {
        coverImageHolder.classList.remove("tt-cover-minimize");
        profileHeaderDiv.classList.remove("minimizeProfileDiv");
        userDetail.classList.remove("minimizeUserDetail");
        userData.style.display = "block";
        if (editButton) {
          editButton.style.display = "block";
        }
        if (systemDefault !== null && personalChange !== null) {
          systemDefault.style.display = "block";
          personalChange.style.display = "block";
        }
        for (let i = 0; i < coverImage.length; i++) {
          coverImage[i].classList.remove("tt-slider-content-minimize");
        }
      }
    });
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data,
        });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
          setting={this.state.setting}
        ></Navbar>

        {this.state.hasAccess ? (
          <NewProfileHeader
            notices={this.state.notices}
            toggleHover={this.toggleHover}
            pictures={this.state.pictures}
            minimizeCoverToggle={this.minimizeCoverToggle}
            userRole={this.state.userRole}
            minimize={this.state.minimize}
            groupData={this.state.groupData}
            groupPage={true}
            isLegal={this.state.isLegal}
            groupName={this.props.match.params.groupName}
            schoolInfos={this.state.schoolInfos}
          ></NewProfileHeader>
        ) : null}

        {/* 
        {this.state.hasAccess ? (
          <CoverPicture
            notices={this.state.notices}
            toggleHover={this.toggleHover}
            pictures={this.state.pictures}
            minimizeCoverToggle={this.minimizeCoverToggle}
            userRole={this.state.userRole}
            minimize={this.state.minimize}
            groupData={this.state.groupData}
            groupPage={true}
            isLegal={this.state.isLegal}
            groupName={this.props.match.params.groupName}
          ></CoverPicture>
        ) : null} */}
        <div className="tt-userInfoMobile">
          <MobileUserDetail
            groupData={this.state.groupData}
            groupPage={true}
          ></MobileUserDetail>
        </div>
        {this.state.hasAccess ? (
          <div className="container">
            <NoticeDisplay
              familyPage={false}
              groupPage={true}
              groupName={this.props.match.params.groupName}
            ></NoticeDisplay>
          </div>
        ) : null}
        <div className="container">
          {this.state.hasAccess ? (
            <div id="tt-contentSection" className="tt-content-section">
              <div className="row reverseContent">
                <div className="col-md-6">
                  <PostArea
                    groupPost={true}
                    groupData={this.state.groupData}
                    groupName={this.props.match.params.groupName}
                    groupPage={true}
                  ></PostArea>
                </div>
                <div className="col-md-6">
                  <GroupMemberLists
                    groupSpinner={this.state.groupSpinner}
                    groupMembers={this.state.groupMembers}
                    groupData={this.state.groupData}
                    loadGroupMembers={this.loadGroupMembers}
                    classroom={this.state.classroom}
                    totalRecordsCount={this.state.totalRecordsCount}
                    items={this.state.items}
                    loadingState={this.state.loadingState}
                    loadMoreItems={this.loadMoreItems}
                  ></GroupMemberLists>
                  <div className="mt-4"></div>
                  <CalendarDisplay></CalendarDisplay>
                  <div className="mt-4"></div>
                </div>
              </div>
            </div>
          ) : (
            <NoAccess text="Sorry, you don't have access to this group"></NoAccess>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapActionToProps = {
  getGroupPosts,
  getPersonPostSectionAcademicRepoPosts,
};

export default connect(null, mapActionToProps)(GroupPage);
