import React, { Component } from "react";
import SliderForm from "./SliderForm";
import ListSlider from "./ListSlider";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";

class Slider extends Component {
  state = {
    modal: false,
    sliders: [],
    editModal: false,
    editData: "",
    isEdit: false
  };

  toggleEditModal = () => {
    this.setState({ editModal: !this.state.editModal });
  };

  handleEditSlider = data => {
    this.toggleEditModal();
    this.setState({ editData: data, isEdit: true });
  };

  componentDidMount() {
    this.getAllSliders();
  }
  getAllSliders = () => {
    axiosPost(URL.getAllslider, {}, response => {
      if (response.status == 200) {
        this.setState({
          sliders: response.data.data
        });
      }
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let data = {
      id: item.id,
      isActive: checked
    };

    axiosPost(URL.changeSliderStatus, data, response => {
      if (response.status === 200) {
        this.getAllSliders();
      }
    });
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Slider"
                onClick={this.toggleModal}
                icon="add_box"
                noPermission={true}
              />
              <ListSlider
                sliders={this.state.sliders}
                getAllSliders={this.getAllSliders}
                handleChangeToggle={this.handleChangeToggle}
                handleEditSlider={this.handleEditSlider}
              />
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          size="lg"
          toggleModal={this.toggleModal}
          modalHeader="Add Slider"
          modalBody={
            <SliderForm
              getAllSliders={this.getAllSliders}
              toggleModal={this.toggleModal}
            />
          }
        />

        <CustomModal
          modal={this.state.editModal}
          size="lg"
          toggleModal={this.toggleEditModal}
          modalHeader="Edit Slider"
          modalBody={
            <SliderForm
              getAllSliders={this.getAllSliders}
              toggleModal={this.toggleEditModal}
              editData={this.state.editData}
              isEdit={this.state.isEdit}
            />
          }
        />
      </div>
    );
  }
}

export default Slider;
