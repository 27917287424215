/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, GeneralASSectionFive, render the Model for General Assessment Settings in the
 *              Record Setup in the Primary ARR
 */

import React, { Component } from "react";
import { getYearLevel, getClassroomName } from "../../../../utils/Constants";

class GeneralASSectionFive extends Component {
  state = {};
  render() {
    return (
      <>
        {!this.props.generalAssessmentUpdate ? (
          <>
            <div className="tt-commentWord">
              <input
                type="checkbox"
                className="form-check-input"
                id="gradeSettings"
                checked={this.props.applySettingToOther}
                name="applySettingToOther"
                onChange={this.props.handleApplySettingToOther}
              />
              <label htmlFor="gradeSettings">
                Also Apply These Settings to:
              </label>
            </div>

            <div className="row">
              <div className="col">
                <div
                  className="tt-subjectAssessmentList"
                  style={{ marginTop: "0" }}
                >
                  <select
                    multiple
                    name="applyToMultiple"
                    className="form-control tt-subject-general-assessment-config-select"
                    onChange={(e) => this.props.handleApplyToMultiple(e)}
                    disabled={!this.props.applySettingToOther}
                    value={this.props.selectedAssessmentIds}
                  >
                    {this.props.classroomList
                      ? this.props.classroomList.map((classroom, idx) => (
                          <optgroup
                            key={idx}
                            label={`${getYearLevel + " " + classroom.name}`}
                          >
                            {classroom.classrooms
                              ? classroom.classrooms.map((section, i) => (
                                  <option value={`${section.id}`} key={i}>
                                    {getClassroomName} {section.name}
                                  </option>
                                ))
                              : null}
                          </optgroup>
                        ))
                      : null}
                  </select>
                </div>
                <i>
                  *Hold <strong>Ctrl</strong> to select multiple options.
                </i>
              </div>
            </div>
          </>
        ) : null}
        <div className="row mt-4">
          {!this.props.editGeneralAssessment ? null : this.props
              .generalAssessmentUpdate ? (
            <button
              className="tt-button tt-button-primary tt-save-setting-button"
              onClick={(e) => this.props.handleGeneralAssessmentUpdateSubmit(e)}
            >
              Update Settings
            </button>
          ) : (
            <button
              className="tt-button tt-button-primary tt-save-setting-button"
              onClick={(e) => this.props.handleGeneralAssessmentSubmit(e)}
            >
              Save Settings
            </button>
          )}
        </div>
      </>
    );
  }
}

export default GeneralASSectionFive;
