import React, { Component } from "react";

class McqStudentList extends Component {
  state = {};
  render() {
    let studentList = this.props.mcqStudentList;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.3vh" }}>
        <table className="table table-striped table-bordered">
          <thead className="tt-group-header">
            <tr>
              <th>People Name</th>
              <th>Submitted</th>
              <th width="90px" className="text-center">
                Option
              </th>
            </tr>
          </thead>
          <tbody>
            {studentList.length > 0 ? (
              studentList.map((stu, idx) => {
                return (
                  <tr key={idx}>
                    <td>{stu.name}</td>
                    <td>{stu.isSubmitted ? "Completed" : "In progress"}</td>
                    <td className="text-center">
                      {stu.isSubmitted ? (
                        <button
                          className="tt-button tt-button-primary"
                          onClick={(e) => this.props.viewStudentResult(e, stu)}
                        >
                          View
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3}>No students</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default McqStudentList;
