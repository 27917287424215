import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import history from "../../utils/History";

class Header extends Component {
  state = {
    userInfo: "",
    dropdownOpen: false,
  };

  componentDidMount() {
    this.getLoggedInUserInfo();
  }

  getLoggedInUserInfo = () => {
    axiosPost(URL.getLoggedInUserInfo, {}, (response) => {
      if (response.status === 200) {
        this.setState({ userInfo: response.data.data });
      }
    });
  };

  toggleDropdown = (e) => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;
    // console.log(name);
    // console.log(checked);
    // let data = {
    //   id: item.id,
    //   enabled: checked
    // };

    // axiosPost(URL.enableDisableUser, data, response => {
    //   if (response.status === 200) {
    //     this.getAllUsers();
    //   }
    // });
  };

  handleLogout = () => {
    axiosPost(
      URL.logout,
      {},
      (response) => {
        if (response.status === 200) {
          localStorage.clear();
          history.push(`${process.env.PUBLIC_URL}/login`);
        }
      },
      (err) => {
        swal("Sorry", "Couldnot log you out of the session.");
      }
    );
  };

  render() {
    return (
      <header className="container-fluid mb-3">
        <div className="row">
          <div className="col">
            <nav className="breadCrumbHolder">
              <ol>
                <li>
                  <Link to="/">
                    <span className="material-icons-round" aria-hidden="true">
                      home
                    </span>
                  </Link>
                </li>
                <li className="breadCrumbSeparator">/</li>
                {this.props.parentMenu ? (
                  <React.Fragment>
                    <li>
                      <span className="breadCrumbText">
                        {this.props.parentMenu
                          ? this.props.parentMenu.name
                          : "Dashboard"}
                      </span>
                    </li>
                    <li className="breadCrumbSeparator">/</li>
                  </React.Fragment>
                ) : null}
                <li>
                  <Link
                    to={
                      this.props.selectedMenu
                        ? this.props.selectedMenu.link
                        : "/"
                    }
                  >
                    <span className="breadCrumbText">
                      {this.props.selectedMenu
                        ? this.props.selectedMenu.name
                        : "Dashboard"}
                    </span>
                  </Link>
                </li>
              </ol>
            </nav>
            <h6 className="headerPageTitle">
              {this.props.selectedMenu
                ? this.props.selectedMenu.name
                : "Dashboard"}
            </h6>
          </div>
          <div className="col-md-4">
            <div className="mr-3 userHeaderBox">
              <div className="userHeaderTitle mr-2">
                <span>Hello, </span>
                {this.state.userInfo.name}
              </div>
              <div className="userHeaderSeparator mr-2">|</div>
              {this.state.userInfo.imagepath ? (
                <img
                  src={URL.imageSelectURL + this.state.userInfo.imagepath}
                  className="userHeaderImage mr-3"
                />
              ) : (
                <span className="material-icons-round mr-3" aria-hidden="true">
                  account_circle
                </span>
              )}
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropdown}
              >
                <DropdownToggle
                  // tag="span"
                  // onClick={this.toggleDropdown}
                  // data-toggle="dropdown"
                  // aria-expanded={this.state.dropdownOpen}
                  className="drpBtn"
                >
                  <span
                    className="material-icons-round dropdownIcon"
                    aria-hidden="true"
                  >
                    settings
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <Link to="/dashboard/profile" onClick={this.toggleDropdown}>
                    <DropdownItem>My Profile</DropdownItem>
                  </Link>
                  <DropdownItem>
                    <span className="mr-1">Two-factor Auhentication</span>
                    <label className="switch mt-2">
                      <input
                        type="checkbox"
                        onChange={(e) => this.handleChangeToggle(e)}
                        // checked={user?.enabled}
                        name="enabled"
                      />
                      <span className="slider round"></span>
                    </label>
                  </DropdownItem>
                  {/* <Link to="/login" onClick={this.toggleDropdown}> */}
                  <DropdownItem onClick={this.handleLogout}>
                    Log Out
                  </DropdownItem>
                  {/* </Link> */}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
