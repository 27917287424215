import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { postType } from "../../utils/Constants";
import DiscussionTemplate from "../Profile/Templates/DiscussionTemplate";

class DashboardDiscussionBoard extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="whiteContainer">
        <div className="listBox" style={{ flexDirection: "column" }}>
          <p className="listTitle">Discussion Board</p>
        </div>
        <div
          id="tt-groupContainer"
          className="tt-group-container"
          style={{ boxShadow: "unset", height: "auto" }}
        >
          <div
            className="tt-group-box"
            style={{ height: "250px", border: "1px solid #ccc" }}
          >
            {this.props.groupDiscussions ? (
              this.props.groupDiscussions.length > 0 ? (
                this.props.groupDiscussions.map((post, pidx) => {
                  return (
                    <div
                      className="tt-post-template"
                      key={pidx}
                      style={{ flex: 1 }}
                    >
                      {post?.posts[0]?.postType === postType.discussion ? (
                        <>
                          <DiscussionTemplate
                            post={post.posts[0]}
                            key={pidx}
                            // handleCurrentPost={this.props.handleCurrentPost}
                          />
                          <hr></hr>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="text-center mt-3 mb-3">
                  {this.props.postSpinner ? (
                    <>
                      <Spinner color="primary"></Spinner>
                      <br></br>
                      Loading Data ...
                    </>
                  ) : (
                    <p>No posts to display</p>
                  )}
                </div>
              )
            ) : (
              <p>No post to display</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardDiscussionBoard;
