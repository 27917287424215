import React, { Component } from "react";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { validateFile } from "../../components/Validation/Validation";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

export class CreateRelativeLinks extends Component {
  state = {
    linksForm: [
      {
        name: "",
        link: "",
        image: "",
      },
    ],

    files: [],
    error: false,
    showSpinner: false,
  };

  addlinkForm = () => {
    let linkForm = [...this.state.linksForm];
    linkForm.push({
      name: "",
      link: "",
      image: "",
    });
    this.setState({
      linksForm: linkForm,
      error: false,
    });
  };

  removelinkForm = (idx) => {
    let linksForm = [...this.state.linksForm];
    let files = [...this.state.files];

    files.splice(idx, 1);
    linksForm.splice(idx, 1);
    this.setState({
      linksForm,
      error: false,
      files,
    });
  };

  handleChange = (e, idx) => {
    let linksForm = this.state.linksForm;
    let name = e.target.name;
    let value = e.target.value;
    linksForm[idx][name] = value;
    this.setState({ linksForm });
  };

  handleChangeImage = (e, idx) => {
    let extensions = ["png", "jpeg", "jpg"];
    let validationResponse = validateFile(e.target.value, extensions);
    if (!validationResponse[0]) {
      swal("Error", validationResponse[1]);
      return;
    }
    let linksForm = this.state.linksForm;
    let files = this.state.files;
    if (e.target.files.length > 0) {
      // linksForm[idx]["photo"] = e.target.value;
      linksForm[idx]["image"] = e.target.files[0];
      // linksForm[idx]["photoPreview"] = window.URL.createObjectURL(
      //   e.target.files[0]
      // );
      this.setState({ linksForm });
      files[idx] = e.target.files[0];
      this.setState({ files });
    }
  };

  handleSave = () => {
    let error = false;
    let linksForm = [...this.state.linksForm];
    let param = [];
    linksForm.forEach((linkForm, index) => {
      if (linkForm.image == "" || linkForm.name == "" || linkForm.link == "") {
        error = true;
        this.setState({ error: true });
      }
      param[index] = {
        name: linkForm.name,
        link: linkForm.link,
      };
    });

    let formData = new FormData();

    formData.append("jsonData", JSON.stringify(param));
    this.state.files.forEach((file) => {
      formData.append("images", file);
    });

    if (error === false) {
      this.setState(
        {
          showSpinner: true,
        },
        () => {
          axiosPost(
            URL.insertRelativeLink,
            formData,
            (response) => {
              if (response.status === 200) {
                this.props.getAllLinks();
                this.setState({
                  linksForm: [{ name: "", link: "", image: "" }],
                  showSpinner: false,
                  error: false,
                });
                swal("Success", response.data.message);
                this.props.toggleModal();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                showSpinner: false,
              });
            }
          );
        }
      );
    }
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait...."></CustomSpinner>
        ) : null}
        <div className="row">
          {this.state.linksForm?.length > 0
            ? this.state.linksForm?.map((linkForm, index) => {
                return (
                  <>
                    <div className="col-md-3 mt-3">
                      <input
                        className={`${
                          this.state.error && linkForm.name == ""
                            ? "form-control cusInput border border-danger"
                            : "form-control cusInput"
                        }`}
                        name="name"
                        type="text"
                        onChange={(e) => this.handleChange(e, index)}
                        value={linkForm.name}
                        placeholder="Enter name here"
                      />
                      {this.state.error && linkForm.name == "" ? (
                        <small className="errorMsg-textField">
                          Name is required
                        </small>
                      ) : null}
                    </div>
                    <div className="col-md-3 mt-3">
                      <input
                        className={`${
                          this.state.error && linkForm.name == ""
                            ? "form-control cusInput border border-danger"
                            : "form-control cusInput"
                        }`}
                        name="link"
                        type="text"
                        onChange={(e) => this.handleChange(e, index)}
                        value={linkForm.link}
                        placeholder="Enter link here"
                      />
                      {this.state.error && linkForm.link == "" ? (
                        <small className="errorMsg-textField">
                          Link is required
                        </small>
                      ) : null}
                    </div>
                    <div className="col-md-4 mt-3">
                      <input
                        className={`${
                          this.state.error && linkForm.image == ""
                            ? "form-control cusInput border border-danger"
                            : "form-control cusInput"
                        }`}
                        name="image"
                        type="file"
                        onChange={(e) => this.handleChangeImage(e, index)}
                      />
                      {this.state.error && linkForm.image == "" ? (
                        <small className="errorMsg-textField">
                          Image is required
                        </small>
                      ) : null}
                    </div>
                    <div className="col-lg-2 mt-3">
                      {index > 0 ? (
                        <span
                          className="material-icons-round text-danger"
                          aria-hidden="true"
                          onClick={(e) => this.removelinkForm(index)}
                        >
                          remove_circle
                        </span>
                      ) : null}
                      {index === this.state.linksForm.length - 1 ? (
                        <span
                          className="material-icons-round text-success"
                          aria-hidden="true"
                          onClick={this.addlinkForm}
                        >
                          add_circle
                        </span>
                      ) : null}
                    </div>
                  </>
                );
              })
            : null}

          <div className="col text-right mt-2">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSave}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default CreateRelativeLinks;
