import React, { Component } from "react";

class FloorTableRow extends Component {
  render() {
    let currentObj = this.props.obj;
    return (
      <>
        <tr key={this.props.idx}>
          <td>{currentObj.number}</td>
          <input
            type="text"
            value={currentObj.name}
            name="name"
            onChange={(e) => this.props.handleFloorChange(e, this.props.idx)}
          />
          {this.props.permissionDeleteFloor ? (
            <td>
              <button
                className="tt-button tt-button-primary"
                onClick={(e) =>
                  this.props.handleDeleteFloorClick(
                    e,
                    currentObj.id,
                    currentObj.buildingId
                  )
                }
              >
                Delete
              </button>
            </td>
          ) : null}
        </tr>
      </>
    );
  }
}

export default FloorTableRow;
