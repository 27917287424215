import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  checkRolePermissions,
  draggable,
  regEx,
  URL,
} from "../../../utils/Constants";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
import ModalWindow from "../../UI/ModalWindow";
import CourseClasses from "../SubjectSetting/CourseClass/CourseClasses";
import CourseTopicUpdateModal from "../SubjectSetting/CourseTopicUpdateModal";
import SubjectTopicSetting from "../SubjectSetting/SubjectTopicSetting";
import TopicDetailModal from "../SubjectSetting/TopicDetailModal";

class CollapsibleSemester extends Component {
  state = {
    topicsDetailModal: false,
    selectedSubject: "",
    classModal: false,
    courseTopicId: "",
    classSemesterModal: false,
    subjectId: "",
    addCourseModal: false,
    selectedSubject: "",
    id: "",
    updateName: "",
    updateDescription: "",
    updateCredit: "",
    updateCode: "",
    permissionCanAddScore: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionCanAddScore: checkRolePermissions(
        "can_add_course_to_program_subject",
        "activity"
      ),
    });
  };

  handleAddCourse = (subject) => {
    this.setState({
      selectedSubject: subject,
      addCourseModal: !this.state.addCourseModal,
    });
  };

  toggleAddCourseModal = () => {
    this.setState({ addCourseModal: !this.state.addCourseModal });
  };

  handleTopicsDetailModal = (sub) => {
    let data = { courseSubjectId: sub.id };
    axiosPost(URL.getCourseTopic, data, (res) => {
      if (res.status === 200) {
        this.setState({ courseTopics: res.data.data });
      }
    });
    this.setState({
      selectedSubject: sub,
      topicsDetailModal: !this.state.topicsDetailModal,
    });
  };

  toggleTopicsDetailModal = () => {
    this.setState({ topicsDetailModal: !this.state.topicsDetailModal });
  };

  //Class Modal
  handleTopicClassModal = (id) => {
    this.setState({ classModal: !this.state.classModal, courseTopicId: id });
  };

  toggleTopicClassModal = () => {
    this.setState({ classModal: !this.state.classModal, courseTopicId: "" });
  };

  //SemesterClassModal
  handleSemesterClassModal = (id) => {
    this.setState({
      classSemesterModal: !this.state.classSemesterModal,
      subjectId: id,
    });
  };

  toggleSemesterClassModal = () => {
    this.setState({
      classSemesterModal: !this.state.classSemesterModal,
      subjectId: "",
    });
  };

  handleUpdateCourseTopic = (data) => {
    this.setState({
      updateTopicName: data.name,
      updateTopicLectureHour: data.lectureHour,
      updateTopicPracticalHour: data.practicalHour,
      id: data.id,
      updateTopicModal: !this.state.updateTopicModal,
    });
  };
  toggleUpdateCourseTopic = () => {
    this.setState({
      updateTopicName: "",
      updateTopicLectureHour: "",
      updateTopicPracticalHour: "",
      id: "",
      updateTopicModal: !this.state.updateTopicModal,
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "name" || name === "updateName") {
      if (!value.match(regEx)) {
        swal({
          title: "Warning",
          text: "Character not allowed",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  updateCourseTopic = () => {
    let data = {
      id: this.state.id,
      name: this.state.updateTopicName,
      lectureHour: this.state.updateTopicLectureHour,
      practicalHour: this.state.updateTopicPracticalHour,
    };
    axiosPost(URL.updateCourseTopic, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Topic successfully updated",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.toggleUpdateCourseTopic();
        this.getCourseTopics();
      }
    });
  };

  getCourseTopics = () => {
    let data = { courseSubjectId: this.state.selectedSubject.id };
    axiosPost(URL.getCourseTopic, data, (res) => {
      if (res.status === 200) {
        this.setState({ courseTopics: res.data.data });
      }
    });
  };

  handleCourseTopicDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deleteCourseTopic);
  };

  deleteCourseTopic = (datum) => {
    let data = { id: datum.id };
    axiosPost(URL.deleteCourseTopic, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Topics successfully deleted",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getCourseTopics();
      }
    });
  };

  //Add Course

  render() {
    let phase = this.props.phase;
    return (
      <React.Fragment>
        <table className="table table-bordered table-striped mb-0">
          <thead className="tt-group-header">
            <tr>
              <th>Program Terms</th>
              <th>Program Subjects</th>
              <th width="140px">Courses</th>
              {this.state.permissionCanAddScore ? (
                <th width="140px">Option</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {phase.courseTerms && phase.courseTerms.length > 0 ? (
              phase.courseTerms.map((term, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <tr>
                      <td
                        rowSpan={
                          term.courseSubjects && term.courseSubjects.length > 0
                            ? term.courseSubjects.length
                            : 1
                        }
                      >
                        {term.name}
                      </td>
                      <td rowSpan={1}>
                        {term.courseSubjects && term.courseSubjects.length > 0
                          ? term.courseSubjects.map((sub, sIdx) => {
                              if (sIdx === 0) {
                                return sub.name;
                              }
                            })
                          : "No Subjects assigned"}
                      </td>
                      <td rowSpan={1} className="text-center">
                        {term.courseSubjects && term.courseSubjects.length > 0
                          ? term.courseSubjects.map((sub, sIdx) => {
                              if (sIdx === 0) {
                                return (
                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleTopicsDetailModal(sub)
                                    }
                                  >
                                    Course Details
                                  </button>
                                );
                              }
                            })
                          : null}
                      </td>
                      {this.state.permissionCanAddScore ? (
                        <td rowSpan={1} className="text-center">
                          {term.courseSubjects && term.courseSubjects.length > 0
                            ? term.courseSubjects.map((sub, sIdx) => {
                                if (sIdx === 0) {
                                  return (
                                    <button
                                      key={idx}
                                      className="tt-button tt-button-primary"
                                      onClick={() => this.handleAddCourse(sub)}
                                    >
                                      Add Course
                                    </button>
                                  );
                                }
                              })
                            : null}
                        </td>
                      ) : null}
                    </tr>
                    {term.courseSubjects && term.courseSubjects.length > 0
                      ? term.courseSubjects.map((subject, subIdx) => {
                          if (subIdx > 0) {
                            return (
                              <tr key={subIdx}>
                                <td rowSpan={1}>{subject.name}</td>
                                <td rowSpan={1} className="text-center">
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleTopicsDetailModal(subject)
                                    }
                                  >
                                    Course Details
                                  </button>
                                </td>
                                {this.state.permissionCanAddScore ? (
                                  <td rowSpan={1} className="text-center">
                                    <button
                                      className="tt-button tt-button-primary"
                                      onClick={() =>
                                        this.handleAddCourse(subject)
                                      }
                                    >
                                      Add Course
                                    </button>
                                  </td>
                                ) : null}
                              </tr>
                            );
                          }
                        })
                      : null}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={this.state.permissionCanAddScore ? 4 : 3}
                  className="text-center"
                >
                  No terms assigned
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ModalWindow
          modal={this.state.topicsDetailModal}
          toggleModal={this.toggleTopicsDetailModal}
          modalHeader={this.state.selectedSubject.name + " - Course Details"}
          size="xl"
          modalBody={
            <TopicDetailModal
              topicList={this.state.courseTopics ? this.state.courseTopics : []}
              courseWidget={true}
              handleUpdateCourseTopic={this.handleUpdateCourseTopic}
              handleCourseTopicDelete={this.handleCourseTopicDelete}
              handleTopicClassModal={this.handleTopicClassModal}
            ></TopicDetailModal>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.addCourseModal}
          toggleModal={this.toggleAddCourseModal}
          size="xl"
          modalHeader="Add New Course"
          modalBody={
            <SubjectTopicSetting
              selectedSubject={this.state.selectedSubject}
              getCourseSubjectList={this.toggleAddCourseModal}
            ></SubjectTopicSetting>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.classModal}
          toggleModal={this.toggleTopicClassModal}
          modalHeader="Classes"
          fullWidth={true}
          modalBody={
            <CourseClasses
              topicId={this.state.courseTopicId}
              subjectId={
                this.state.selectedSubject
                  ? this.state.selectedSubject.id
                  : null
              }
              topicModal={true}
              // detailView={true}
              courseWidget={true}
            ></CourseClasses>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.updateTopicModal}
          toggleModal={this.toggleUpdateCourseTopic}
          modalHeader="Edit Course"
          modalBody={
            <CourseTopicUpdateModal
              updateTopicName={this.state.updateTopicName}
              updateTopicLectureHour={this.state.updateTopicLectureHour}
              updateTopicPracticalHour={this.state.updateTopicPracticalHour}
              handleChange={this.handleChange}
            ></CourseTopicUpdateModal>
          }
          modalFooter={
            <div className="container-fluid">
              <div className="row">
                <div className="col text-right">
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={this.updateCourseTopic}
                  >
                    Save
                  </button>
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.toggleUpdateCourseTopic}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>
      </React.Fragment>
    );
  }
}

export default CollapsibleSemester;
