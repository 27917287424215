import React, { Component } from "react";
import "../../assets/scss/flashCard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import CreateFlashCardSet from "./CreateFlashCardSet";
import CreateNewFlashCard from "./CreateNewFlashCard";
import { axiosPost } from "../../utils/AxiosApi";
import {
  draggable,
  URL as URLs,
  checkWidgetAdminOrNot,
} from "../../utils/Constants";
import swal from "sweetalert";
import { MESSAGEHEADER } from "../../utils/DisplayMessage";
import { connect } from "react-redux";
import {
  getPublicFlashCards,
  getMyFlashCards,
} from "../../ducks/FlashCardDucks";
import ModalWindow from "../UI/ModalWindow";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

class CreateFlashCards extends Component {
  state = {
    classes: [],
    classId: "",
    subjectList: [],
    subjectId: "",
    title: "",
    description: "",
    access: "public",
    flashCards: [
      {
        image: null,
        question: "",
        answer: "",
        hint: "",
        imagePreview: null,
      },
    ],
    permissionModal: false,
  };

  componentDidMount() {
    this.getAllEducationList();
  }

  clearState = () => {
    this.setState({
      title: "",
      description: "",
      access: "public",
      classId: "",
      subjectId: "",
      flashCards: [
        {
          image: null,
          question: "",
          answer: "",
          hint: "",
          imagePreview: null,
        },
      ],
    });
  };

  getAllEducationList = () => {
    axiosPost(URLs.getAllEducationLevel, {}, (response) => {
      if (response.status === 200) {
        const educationLevels = response.data.data;
        let yearLevels = [];
        if (educationLevels[0] && educationLevels[0].yearLevels) {
          educationLevels.forEach((el) => {
            if (el.yearLevels.length > 0) {
              el.yearLevels.forEach((c) => {
                yearLevels.push(c);
              });
            }
          });
          this.setState({
            classes: yearLevels,
          });
        }
      }
    });
  };

  getSubjects = () => {
    let data = {
      yearLevelId: this.state.classId,
    };
    axiosPost(
      URLs.selectDistinctSubjectsByYearLevelCoreForAcademic,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({ subjectList: response.data.data });
        }
      }
    );
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "classId") {
        this.getSubjects();
      }
    });
  };

  removeFlashCard = (e, idx) => {
    const flashCards = [...this.state.flashCards];
    if (flashCards.length === 1) {
      swal({
        title: "Error",
        text: "Atleast 1 flash card should be there",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    } else {
      flashCards.splice(idx, 1);
    }
    this.setState({ flashCards });
  };
  handleFlashCardChange = (e, idx) => {
    let name = e.target.name;
    let value = e.target.value;
    let flashCards = [...this.state.flashCards];
    flashCards[idx][name] = value;
    if (value.length > 200) {
      swal({
        title: "Error",
        text: "Character limit exceeded",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    } else {
      this.setState({ flashCards });
    }
  };

  addFlashCard = (e) => {
    e.preventDefault();
    let flashCards = [...this.state.flashCards];
    flashCards.push({
      image: null,
      question: "",
      answer: "",
      hint: "",
      imagePreview: null,
    });
    if (flashCards.length > 12) {
      swal({
        title: "Error",
        text: "Sorry, you cannot add more than 12 cards",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    } else {
      this.setState({ flashCards });
    }
  };

  handleImageChange = (e, idx) => {
    let name = e.target.name;
    let flashCards = [...this.state.flashCards];
    flashCards[idx][name] = e.target.files[0];
    var extension = flashCards[idx][name].name.split(".").pop();
    if (extension === "jpeg" || extension === "png" || extension === "jpg") {
      this.setState({ flashCards }, function () {
        let flashCards = [...this.state.flashCards];
        let image = URL.createObjectURL(flashCards[idx][name]);
        flashCards[idx]["imagePreview"] = image;
        this.setState({ flashCards });
      });
    } else {
      swal(MESSAGEHEADER.error, "Unsupported File Type", "");
    }
  };

  removeImage = (e, idx) => {
    let flashCards = [...this.state.flashCards];
    flashCards[idx]["image"] = "";
    flashCards[idx]["imagePreview"] = "";
    this.setState({ flashCards });
  };

  handleSubmit = () => {
    let flashCards = [...this.state.flashCards];
    let error = 0;
    flashCards.forEach((fCard, idx) => {
      if (fCard.question === "" || fCard.answer === "") {
        error++;
      }
    });
    if (this.state.title === "") {
      swal({
        title: MESSAGEHEADER.error,
        text: "Title should not be empty",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    } else if (this.state.classId === "" || this.state.subjectId === "") {
      swal({
        title: MESSAGEHEADER.error,
        text: "Class and Subject should not be empty",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    } else if (error > 0) {
      swal({
        title: MESSAGEHEADER.error,
        text: "Fill the questions and answers for all the flash cards",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    } else {
      var formData = new FormData();
      var filesDetails = [];

      flashCards.forEach((flashCard, idx) => {
        flashCard.code = "Flash-Card" + idx;
        if (flashCard.image != null) {
          formData.append("files", flashCard.image);
          filesDetails.push({
            code: flashCard.code,
          });
        }

        delete flashCard.image;
        delete flashCard.imagePreview;
      });
      formData.append("filesDetails", JSON.stringify(filesDetails));
      var flashCardSet = {
        title: this.state.title,
        description: this.state.description,
        access: this.state.access,
        flashCards: flashCards,
        yearLevelId: this.state.classId,
        subjectId: this.state.subjectId,
      };
      formData.append("flashCardSet", JSON.stringify(flashCardSet));

      axiosPost(
        URLs.insertFlashCards,
        formData,
        (response) => {
          if (response.status === 200) {
            swal({
              title: MESSAGEHEADER.success,
              text: "Flash Card successfully created",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            draggable();
            this.clearState();
            this.props.getMyFlashCards();
            this.props.getPublicFlashCards();
          }
        },
        (error) => {
          this.clearState();
          let errorResponse = error.response ? error.response.data : error;
          if (errorResponse.status === 400) {
            //if condition to check spring boot validation errors
            let errorMessage = "";
            if (errorResponse.errors) {
              errorResponse.errors.forEach((error) => {
                errorMessage += `${error.field
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })} ${error.defaultMessage} \n`; //ishan
              });
              swal(errorResponse.error, errorMessage, "");
            } else {
              swal(errorResponse.error, errorResponse.message, "");
            }
          } else {
            swal(
              errorResponse.error || "Network Error",
              errorResponse.message
                ? errorResponse.message
                : "Could Not Connect To The Server.",
              ""
            );
          }
        }
      );
    }
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          {this.props.title}

          {checkWidgetAdminOrNot("Teaching Tools") ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div
          className="tt-newListBox mb-1"
          style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
        >
          <div className="row">
            <CreateFlashCardSet
              handleChange={this.handleChange}
              title={this.state.title}
              description={this.state.description}
              access={this.state.access}
              classes={this.state.classes}
              classId={this.state.classId}
              subjectList={this.state.subjectList}
              subjectId={this.state.subjectId}
            />
          </div>
          <div className="row">
            <CreateNewFlashCard
              removeFlashCard={this.removeFlashCard}
              flashCards={this.state.flashCards}
              handleChange={this.handleChange}
              addFlashCard={this.addFlashCard}
              handleFlashCardChange={this.handleFlashCardChange}
              handleImageChange={this.handleImageChange}
              removeImage={this.removeImage}
            />
          </div>
        </div>

        <div className="container-fluid">
          <div className="row button-bg-color pr-4">
            <div className="col-12 text-right">
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
              <button
                className="tt-button tt-button-primary"
                onClick={this.clearState}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Teaching Tools"
              moduleName="Flash Cards"
              header="Add Flash Cards configuration"
              activityName="create-flash-cards"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({});

const mapActionsToProps = {
  getPublicFlashCards,
  getMyFlashCards,
};

export default connect(mapStateToProps, mapActionsToProps)(CreateFlashCards);
