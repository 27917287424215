import React, { Component } from "react";
import "../../../../assets/scss/schoolGrouping.scss";
import { compareByName } from "../../../../utils/Utils";

class AssignedStudentHeader extends Component {
  state = {
    termAlias:
      localStorage.getItem("yearLevel") === null
        ? "Class"
        : localStorage.getItem("yearLevel"), //get from localStorage
    termAliasClassroom:
      localStorage.getItem("classroom") === null
        ? "Section"
        : localStorage.getItem("classroom"), //get from localStorage
  };
  render() {
    return (
      <>
        <div className="row tt-calendar-dateNavigation ">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 tt-calendarLabel">
                <label>{this.state.termAlias}</label>
              </div>
              <div className="col-md-8">
                <select
                  name="selectYearLevel"
                  value={this.props.selectYearLevel}
                  onChange={this.props.handleYearLevelChange}
                  className="form-control"
                >
                  <option value="" disabled>
                    Choose a {this.state.termAlias.toLowerCase()}
                  </option>
                  {this.props.yearLevels
                    .sort(compareByName)
                    .map((yearLevel, idx) => {
                      return (
                        <option value={yearLevel.id} key={idx}>
                          Class {yearLevel.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 tt-calendarLabel">
                <label>{this.state.termAliasClassroom}</label>
              </div>
              <div className="col-md-8">
                <select
                  name="selectClassroom"
                  value={this.props.selectClassroom}
                  className="form-control"
                  onChange={this.props.handleClassroomChange}
                  disabled={this.props.selectYearLevel ? false : true}
                >
                  <option value="" disabled>
                    Choose {this.state.termAliasClassroom}
                  </option>
                  {this.props.classroomList.map((classes, idx) => {
                    return (
                      <option value={classes.id} key={idx}>
                        {classes.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AssignedStudentHeader;
