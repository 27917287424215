import * as Yup from "yup";

export const ClassValidationSchema = Yup.object({
  classForms: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Class code is required"),
      educationLevelId: Yup.string().required("Education level is required"),
      //   code: Yup.string().required("Educational code is Required")
    })
  ),
});
