import React, { Component } from "react";

import CustomCollapse from "../../../UI/CustomCollapse";
import CreateNewEducationLevel from "./CreateNewEducationLevel";

class AllEducationLevels extends Component {
  state = {
    administrators: [],
    yearLevels: [],
  };
  componentDidMount() {
    this.getDetails(this.props.eduLevel);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.eduLevel !== this.props.eduLevel) {
      this.getDetails(this.props.eduLevel);
    }
  }
  /**
   * @author Azzeta
   *
   */
  getDetails = (eduLevel) => {
    let educationLevelAdministrators = eduLevel.educationLevelAdministrators;
    let yrLevels = eduLevel.yearLevels;
    var administrators = [];
    var yearLevels = [];
    administrators = this.getNewArray(
      educationLevelAdministrators,
      "staffName"
    );
    yearLevels = this.getNewArray(yrLevels, "name");

    this.setState({ administrators, yearLevels });
  };
  getNewArray = (oldArray, keyName) => {
    let newArray = [];
    oldArray.forEach((element) => {
      if (newArray.length > 0) {
        newArray.push(", ");
      }
      newArray.push(element[keyName]);
    });
    return newArray;
  };

  render() {
    return (
      <>
        <tr>
          <td>{this.props.eduLevel.name}</td>
          <td className="text-left">
            <strong>Administrators : </strong>
            {this.state.administrators.length > 0
              ? this.state.administrators
              : "Not assigned yet"}
            <br />
            <strong>{localStorage.getItem("yearLevel")} : </strong>
            {this.state.yearLevels.length > 0
              ? this.state.yearLevels
              : "Not assigned yet"}
          </td>
          {this.props.permissionEditEducationLevel ? (
            <td>
              <button
                className="tt-button tt-button-primary"
                onClick={(e) =>
                  this.props.toggleEducationLevels(
                    e,
                    this.props.idx,
                    this.props.eduLevel.id
                  )
                }
              >
                Update
              </button>
            </td>
          ) : null}
        </tr>
        {this.props.eduLevel.isOpen ? (
          <span className="tt-divRow">
            <td colSpan="3">
              <CustomCollapse
                isOpen={this.props.eduLevel.isOpen}
                collapsibleBody={
                  <CreateNewEducationLevel
                    edit={true}
                    idx={this.props.idx}
                    profilePeopleId={this.props.profilePeopleId}
                    eduLevel={this.props.eduLevel}
                    closeCollapsible={this.props.closeCollapsible}
                    defaultAdministratorName={
                      this.props.defaultAdministratorName
                    }
                  />
                }
              ></CustomCollapse>
            </td>
          </span>
        ) : null}
      </>
    );
  }
}

export default AllEducationLevels;
