import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { questionType, URL, userRoleCode } from "../../utils/Constants";
import AssessmentQuestions from "./AssessmentQuestions";
import swal from "sweetalert";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import ModalWindow from "../../UI/ModalWindow";
import ExamChkModal from "./ExamChkModal";
import { displayErrorAlert } from "../../utils/Utils";

class SystemExamAssessment extends Component {
  state = {
    educationLevels: [],
    educationLevelCode: "",
    educationLevelId: "",
    yearLevels: [],
    yearLevelId: "",
    classRooms: [],
    classRoomId: "",
    educationLevelDivisions: [],
    educationLevelDivisionId: "",
    assessments: [],
    assessmentId: "",
    assessmentSubjects: [],
    subjectId: "",
    assessments: [],
    assessmentId: "",
    subjectQuestions: [],
    theoryQuestions: [],
    practicalQuestions: [],
    students: [],
    peopleId: "",
    assessmentFor: null,
    trainings: [],
    attendeeId: "",
    currentTrainingId: "",
    selectedStaff: "",
    staffLists: [],
    answerData: false,
    isCheckedExam: false,
    examData: [],
    examChkModal: false,
    examChkData: [],
  };

  claerState = () => {
    this.setState();
  };

  componentDidMount() {
    // this.getEducationLevel();
    this.getTrainings();
    this.getAllStaffsList();
    this.getAllExamData();
  }

  changeStaffSelect = (data) => {
    this.setState({
      selectedStaff: data,
    });
  };

  getAllStaffsList = () => {
    let data = {
      userRoleCode: userRoleCode.roleStaff,
    };
    axiosPost(URL.getMasterUsers, data, (response) => {
      let data = response.data.data;
      data.forEach((sl) => {
        sl.label = sl.name;
        sl.value = sl.id;
      });

      this.setState({
        staffLists: data,
      });
    });
  };

  getTrainings = () => {
    axiosPost(URL.getAllTraining, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          trainings: response.data.data,
        });
      }
    });
  };

  // getEducationLevel = () => {
  //   axiosPost(URL.getEducationLevel, {}, (response) => {
  //     if (response.status === 200) {
  //       this.setState({ educationLevels: response.data.data });
  //     }
  //   });
  // };

  getAllExams = () => {
    let data = {};

    axiosPost(
      URL.getAllAnswers,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({
            answersList: response.data.data,
          });
        }
      },
      (err) => {
        swal("Error", "No records available");
      }
    );
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "currentTrainingId") {
        let examLists = this.state.trainings.filter((t) => t.id == value)[0]
          .exams;
        this.setState({
          examLists: examLists,
        });
      }
    });
  };

  getAllExamData = () => {
    axiosPost(URL.getAllExamQuestionAnswer, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          examData: response.data.data,
        });
      }
    });
  };

  handleGetExamData = () => {
    let data = {
      id: this.state.selectedExam,
      peopleId: this.state.assessmentFor.id,
    };

    this.setState(
      {
        theoryQuestions: [],
        isCheckedExam: false,
      },
      () => {
        axiosPost(
          URL.getExamAnswer,
          data,
          (response) => {
            if (response.status === 200) {
              const questions = response.data.data;

              if (questions.some((tq) => tq.answer)) {
                questions.forEach((q) => {
                  if (q.questionType === questionType.OBJECTIVE && q.answer) {
                    const trueOption =
                      q.examQuestionOptions &&
                      q.examQuestionOptions.find((opt) => opt.isCorrect);
                    if (
                      trueOption &&
                      q.answer.examQuestionOptionId === trueOption.id
                    ) {
                      q.obtainedMarks = q.markWeight;
                    } else {
                      q.obtainedMarks = "0";
                    }
                  } else {
                    q.obtainedMarks = "";
                  }
                  q.remarks = "";
                });

                const theoryQuestions = questions;
                if (questions.length > 0) {
                  this.setState({
                    answerData: true,
                  });
                }
                const isCheckedExam = theoryQuestions.some(
                  (tq) => tq.answer?.isChecked
                );
                // const practicalQuestions =
                //   questions && questions.filter((q) => !q.isTheory);
                this.setState({ theoryQuestions, isCheckedExam });
              } else {
                this.setState({ theoryQuestions: [], isCheckedExam: false });
              }
            }
          },
          (err) => {
            displayErrorAlert(err);
            // displayErrorAlert(err);
          }
        );
      }
    );
  };

  calculateTotal = () => {
    let total = 0;

    this.state.theoryQuestions.map(
      (t) => (total = t.answer.obtainedMarks + total)
    );
    return total;
  };

  // handleChange = (e) => {
  //   let name = e.target.name;
  //   let value =
  //     e.target.type === "checkbox" ? e.target.checked : e.target.value;
  //   this.setState({ [name]: value }, function () {
  //     if (
  //       this.state.yearLevelId !== "" &&
  //       this.state.educationLevelId !== "" &&
  //       this.state.classRoomId !== "" &&
  //       this.state.educationLevelDivisionId !== "" &&
  //       this.state.assessmentId !== ""
  //     ) {
  //       this.getAssessmentSubjects();
  //     }

  //     if (name === "educationLevelId") {
  //       this.getYearLevelByEducationLevel(value);
  //       const educationLevel = this.state.educationLevels?.find(
  //         (e) => e.id === parseInt(value)
  //       );
  //       this.setState({
  //         assessmentModuleCode: educationLevel
  //           ? educationLevel.assessmentModuleCode
  //           : null,
  //         educationLevelDivisions: educationLevel
  //           ? educationLevel.educationLevelDivisions
  //           : [],
  //         yearLevelId: "",
  //         classRoomId: "",
  //         assessmentId: "",
  //         subjectId: "",
  //         theoryQuestions: [],
  //         practicalQuestions: [],
  //       });
  //       educationLevel &&
  //         this.getAssessments(educationLevel.assessmentModuleCode);
  //     }
  //     if (name === "educationLevelDivisionId") {
  //       this.setState({
  //         yearLevelId: "",
  //         classRoomId: "",
  //         assessmentId: "",
  //         subjectId: "",
  //         theoryQuestions: [],
  //         practicalQuestions: [],
  //       });
  //     }
  //     if (name === "yearLevelId") {
  //       const yearLevel = this.state.yearLevels.find(
  //         (y) => y.id === parseInt(value)
  //       );
  //       this.setState({
  //         classRooms: yearLevel ? yearLevel.classrooms : [],
  //         classRoomId: "",
  //         assessmentId: "",
  //         subjectId: "",
  //         theoryQuestions: [],
  //         practicalQuestions: [],
  //       });
  //     }
  //     if (name === "classRoomId") {
  //       if (this.state.yearLevelId !== "" && this.state.classRoomId !== "") {
  //         this.getStudentList();
  //       }
  //       this.setState({
  //         assessmentId: "",
  //         subjectId: "",
  //         theoryQuestions: [],
  //         practicalQuestions: [],
  //       });
  //     }
  //     if (name === "assessmentId") {
  //       this.setState({
  //         assessmentSubjects: [],
  //         subjectId: "",
  //         theoryQuestions: [],
  //         practicalQuestions: [],
  //       });
  //       if (
  //         this.state.yearLevelId !== "" &&
  //         this.state.educationLevelId !== "" &&
  //         this.state.classRoomId !== "" &&
  //         this.state.educationLevelDivisionId !== "" &&
  //         this.state.assessmentId !== ""
  //       ) {
  //         this.getAssessmentSubjects();
  //       }
  //     }
  //     if (name === "subjectId") {
  //       this.getSubjectQuestions();
  //     }
  //     if (name === "peopleId") {
  //       const people = this.state.studentList.find(
  //         (s) => s.people && s.people.id === parseInt(this.state.peopleId)
  //       );
  //       this.setState({ assessmentFor: people });
  //       this.getQuestionAnswerByPeople();
  //     }
  //   });
  // };

  getStudentList = () => {
    let param = {
      yearLevelId: this.state.yearLevelId,
      classroomId: this.state.classRoomId,
    };
    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status == 200) {
        let list = response.data.data;
        this.setState({ studentList: list });
      }
    });
  };

  getSubjectQuestions = () => {
    const data = {
      educationLevelId: this.state.educationLevelId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      yearLevelId: this.state.yearLevelId,
      classroomId: this.state.classRoomId,
      subjectId: this.state.subjectId,
      assessmentId: this.state.assessmentId,
    };
    axiosPost(
      URL.selectSubjectQuestion,
      data,
      (response) => {
        const questions = response.data.data;
        const theoryQuestions =
          questions && questions.filter((q) => q.isTheory);
        const practicalQuestions =
          questions && questions.filter((q) => !q.isTheory);
        this.setState({ theoryQuestions, practicalQuestions });
      },
      (err) => {}
    );
  };

  getYearLevelByEducationLevel = (value) => {
    axiosPost(
      URL.getYearLevelByEducationLevel,
      { educationLevelId: value },
      (response) => {
        if (response.status === 200) {
          this.setState({ yearLevels: response.data.data });
        }
      },
      (error) => {}
    );
  };

  getAssessments = (code) => {
    const data = {
      assessmentModuleCode: code,
    };
    axiosPost(URL.selectAssessments, data, (resp) => {
      if (resp.status === 200) {
        let assessments = resp.data.data;
        this.setState({ assessments });
      }
    });
  };

  getAssessmentSubjects = () => {
    const data = {
      yearLevelId: this.state.yearLevelId,
      educationLevelId: this.state.educationLevelId,
      classroomId: this.state.classRoomId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      assessmentId: this.state.assessmentId,
    };

    axiosPost(
      URL.selectSystemExamSubjects,
      data,
      (response) => {
        if (response.status === 200) {
          let assessmentSubjects = response.data.data;
          this.setState({ assessmentSubjects });
        }
      },
      (err) => {}
    );
  };

  getQuestionAnswerByPeople = () => {
    const data = {
      yearLevelId: this.state.yearLevelId,
      educationLevelId: this.state.educationLevelId,
      classroomId: this.state.classRoomId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      subjectId: this.state.subjectId,
      assessmentId: this.state.assessmentId,
      answerBy: this.state.peopleId,
    };

    axiosPost(
      URL.selectQuestionAnswerByPeople,
      data,
      (response) => {
        if (response.status === 200) {
          const questions = response.data.data;
          questions.forEach((q) => {
            if (q.questionType === questionType.OBJECTIVE && q.answer) {
              const trueOption = q.examQuestionOptions.find(
                (opt) => opt.isCorrect
              );
              if (
                trueOption &&
                q.answer.subjectQuestionOptionId === trueOption.id
              ) {
                q.obtainedMarks = q.markWeight;
              } else {
                q.obtainedMarks = "";
              }
            } else {
              q.obtainedMarks = "";
            }
            q.remarks = "";
          });
          const theoryQuestions =
            questions && questions.filter((q) => q.isTheory);
          const practicalQuestions =
            questions && questions.filter((q) => !q.isTheory);
          this.setState({ theoryQuestions, practicalQuestions });
        }
      },
      (err) => {}
    );
  };

  handleMarksChange = (e, stateName, index) => {
    const { name, value, type, checked } = e.target;
    const ans = type === "checkbox" ? checked : value;
    let questions = [...this.state[stateName]];
    questions[index][name] = ans;
    this.setState({ [stateName]: questions });
  };

  handleSubmit = () => {
    const answers = [
      ...this.state.theoryQuestions,
      ...this.state.practicalQuestions,
    ];

    const ans = answers.every(
      (a) => a.obtainedMarks != null && a.obtainedMarks !== ""
    );

    if (ans) {
      const data = answers.map((a) => {
        return {
          id: a.answer.id,
          obtainedMarks: a.obtainedMarks,
          remarks: a.remarks,
        };
      });

      axiosPost(
        URL.checkExamAnswer,
        data,
        (response) => {
          if (response.status === 200) {
            swal(
              "Success",
              // `Assessment of ${this.state.assessmentFor?.people?.name} completed successfully.`
              `Assessment check completed successfully.`
            );
            this.setState({
              peopleId: "",
              assessmentFor: null,
              theoryQuestions: [],
              practicalQuestions: [],
              answerData: false,
              currentTrainingId: "",
              examLists: [],
              attendeeId: "",
              staffLists: [],
              isCheckedExam: false,
            });
          }
        },
        (err) => {}
      );
    } else {
      swal("Warning", "You have not given marks for some answers.");
    }
  };

  examChkModalClick = (item) => {
    item.exams[0].peoples.forEach((p) => {
      p.isActive = false;
    });

    this.setState({
      examChkData: item,
      selectedExam: item.exams[0].id,
      examChkModal: true,
    });
  };

  toggleExamChkModal = () => {
    this.setState({
      theoryQuestions: [],
      assessmentFor: null,

      examChkModal: !this.state.examChkModal,
    });
  };

  handleTeacherCheckbox = (e, idx, attendee) => {
    let { checked } = e.target;

    let newList = { ...this.state.examChkData };

    newList.exams[0].peoples.forEach((p, indx) => {
      if (indx === idx) {
        p.isActive = true;
      } else {
        p.isActive = false;
      }
    });

    this.setState(
      {
        examChkData: newList,
        // attendeeId: newList.exams[0].peoples[idx].id,
        assessmentFor: attendee,
      },
      () => {
        this.handleGetExamData();
      }
    );
  };

  render() {
    const {
      educationLevels,
      educationLevelDivisions,
      classRooms,
      yearLevels,
      assessments,
      assessmentSubjects,
      studentList,
      trainings,
      attendeesList,
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="whiteContainer">
              <CustomPageHeader title="System Examination" noIcon={true} />

              {/* 
              
              <div className="row">
                <div className="col-md-2 text-right mt-1">
                 
                  <select
                    className="form-control cusInputSelect"
                    name="currentTrainingId"
                    value={this.state.currentTrainingId}
                    onChange={this.handleChange}
                    defaultValue=""
                  >
                    <option value="" selected>
                      Select Training
                    </option>
                    {trainings.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>
                <div className="col-md-2 text-right mt-1">
            

                  <select
                    className="form-control cusInputSelect"
                    name="selectedExam"
                    value={this.state.selectedExam}
                    onChange={this.handleChange}
                  >
                    <option value="" selected>
                      Select Exam
                    </option>
                    {this.state.examLists?.map((el, idx) => {
                      return <option value={el.id}>{el.title}</option>;
                    })}
                  </select>
                </div>

                <div className="col-md-2 text-right mt-1">
             

                  <select
                    className="form-control cusInputSelect"
                    name="attendeeId"
                    value={this.state.attendeeId}
                    onChange={this.handleChange}
                  >
                    <option value="" selected>
                      Select Attendee
                    </option>
                    {this.state.staffLists.length > 0 &&
                      this.state.staffLists?.map((el, idx) => {
                        return <option value={el.id}>{el.name}</option>;
                      })}
                  </select>
                </div>
                <div className="col-md-2 mt-2">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={() => this.handleGetExamData()}
                  >
                    Get Exam Answer
                  </button>
                </div>
              </div>
 */}

              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>Training</th>
                    <th>Exam</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.examData.length > 0 ? (
                    this.state.examData.map((el) => (
                      <tr>
                        <td>{el.name}</td>
                        <td>{el.exams[0].title}</td>

                        <td>
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() => this.examChkModalClick(el)}
                            id="addQuestion"
                          >
                            Check Assessment
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="text-center py-2">
                      No records available!!
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ModalWindow
          backdrop="static"
          keyboard={false}
          fullWidth
          modal={this.state.examChkModal}
          toggleModal={this.toggleExamChkModal}
          size="xl"
          modalHeader="Check Exam"
          modalBody={
            <ExamChkModal
              assessmentFor={this.state.assessmentFor}
              examChkData={this.state.examChkData}
              handleTeacherCheckbox={this.handleTeacherCheckbox}
              theoryQuestions={this.state.theoryQuestions}
              practicalQuestions={this.state.practicalQuestions}
              handleMarksChange={this.handleMarksChange}
              answerData={this.state.answerData}
              isCheckedExam={this.state.isCheckedExam}
              handleSubmit={this.handleSubmit}
              calculateTotal={this.calculateTotal}
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default SystemExamAssessment;
