import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import "../../../../assets/scss/generalAssessmentSetting.scss";

export const ObtainedMarksCounter = props => {
    return (
        <>
            <div className="counterBtn">
                <button
                    className="tt-button tt-button-primary counter"
                    onClick={() =>
                        props.handleIncrementDecrement("dec", props.studentIndex, props.assessmentScaleIndex)
                    }
                >
                    <FontAwesomeIcon icon={faMinusSquare} />
                </button>
                <input
                    type="number"
                    className="form-control text-center"
                    style={{ width: "100px" }}
                    value={props.count}
                    onChange={props.handleChange}
                    disabled
                />
                <button
                    className="tt-button tt-button-primary counter"
                    onClick={() =>
                        props.handleIncrementDecrement("inc", props.studentIndex, props.assessmentScaleIndex)
                    }
                >
                    <FontAwesomeIcon icon={faPlusSquare} />
                </button>
            </div>
        </>
    );
};
