import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faCaretUp,
  faCaretDown,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Chip from "@material-ui/core/Chip";
import ReportMagnifier from "./ReportMagnifier";

export default class ReportingConfigurationPageContent extends Component {
  render() {
    const reportPages = this.props.reportPages,
      activeReportPageIndex = this.props.activeReportPageIndex,
      pageSize = this.props.pageSize,
      pageSizeUnit = this.props.pageSizeUnit,
      headerHeight = this.props.headerHeight,
      footerHeight = this.props.footerHeight,
      activeColumn = this.props.activeColumn,
      customText = this.props.customText,
      contentFontSizes = this.props.contentFontSizes,
      contentAlignments = this.props.contentAlignments,
      assessmentSubjectScaleConfigGroups =
        this.props.assessmentSubjectScaleConfigGroups,
      assessmentGeneralCustomAssessments =
        this.props.assessmentGeneralCustomAssessments,
      legends = this.props.legends,
      commentsChip = this.props.commentsChip,
      descriptionBox = this.props.descriptionBox,
      subjectAssessmentScaleSettingOptions =
        this.props.subjectAssessmentScaleSettingOptions;

    return (
      <div className="card">
        <div
          className="tt-group-header tt-stickyHeader"
          style={{ borderRadius: 0 }}
        >
          {"Create Report (Step 3 of 4: Report Page)"}
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-7 tt-printbox">
                <div className="card">
                  <div
                    className="card-header tt-group-header"
                    style={{ padding: "6px", marginBottom: 0 }}
                  >
                    <div className="row">
                      <div className="col">
                        <select
                          name="activeReportPageIndex"
                          value={activeReportPageIndex}
                          onChange={this.props.handleFormDataChange}
                        >
                          {reportPages
                            ? reportPages.map((reportPage, i) => (
                                <option value={i}>{`Page ${++i} of ${
                                  reportPages.length
                                }`}</option>
                              ))
                            : null}
                        </select>
                      </div>
                      <div className="col-md-1">
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="tt-cursor-pointer"
                          onClick={this.props.handleAddReportPageBtnClick}
                        />
                      </div>
                      <div className="col-md-1">
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="tt-cursor-pointer"
                          onClick={this.props.handleRemoveReportPageBtnClick}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Layout dropdown */}
                  <div className="card-body">
                    {reportPages
                      ? reportPages.map((reportPage, i) => {
                          if (i === parseInt(activeReportPageIndex)) {
                            return (
                              <div className="row" key={i}>
                                <div className="col">
                                  <strong>Margin</strong>
                                </div>
                                <div className="col">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="top"
                                    name="top"
                                    value={
                                      pageSizeUnit === "mm"
                                        ? reportPage.margin.top
                                        : reportPage.margin.top?.toFixed(3)
                                    }
                                    step={pageSizeUnit === "mm" ? 1 : 0.1}
                                    onChange={(e) =>
                                      this.props.handlePagesMarginChange(e, i)
                                    }
                                  />
                                </div>

                                <div className="col">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="right"
                                    name="right"
                                    value={
                                      pageSizeUnit === "mm"
                                        ? reportPage.margin.right
                                        : reportPage.margin.right?.toFixed(3)
                                    }
                                    step={pageSizeUnit === "mm" ? 1 : 0.1}
                                    onChange={(e) =>
                                      this.props.handlePagesMarginChange(e, i)
                                    }
                                  />
                                </div>
                                <div className="col">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="bottom"
                                    name="bottom"
                                    value={
                                      pageSizeUnit === "mm"
                                        ? reportPage.margin.bottom
                                        : reportPage.margin.bottom?.toFixed(3)
                                    }
                                    step={pageSizeUnit === "mm" ? 1 : 0.1}
                                    onChange={(e) =>
                                      this.props.handlePagesMarginChange(e, i)
                                    }
                                  />
                                </div>
                                <div className="col">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="left"
                                    name="left"
                                    value={
                                      pageSizeUnit === "mm"
                                        ? reportPage.margin.left
                                        : reportPage.margin.left?.toFixed(3)
                                    }
                                    step={pageSizeUnit === "mm" ? 1 : 0.1}
                                    onChange={(e) =>
                                      this.props.handlePagesMarginChange(e, i)
                                    }
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      : null}
                    <div className="row mt-2">
                      <div className="col">
                        <strong>Layout</strong>
                      </div>
                      <div className="col">
                        <select
                          name="layout"
                          className="form-control"
                          value={
                            reportPages && activeReportPageIndex
                              ? reportPages[activeReportPageIndex].layout.layout
                              : reportPages
                              ? reportPages[0].layout.layout
                              : null
                          }
                          onChange={(e) =>
                            this.props.handleLayoutChange(
                              e,
                              activeReportPageIndex
                            )
                          }
                        >
                          <option disabled selected>
                            Layout
                          </option>
                          <option value="1col">1 - column</option>
                          <option value="2cff">2 - column(50/50)</option>
                          <option value="2cts">2 - column(34/66)</option>
                          <option value="2cst">2 - column(66/34)</option>
                        </select>
                      </div>
                    </div>

                    {/* ColumnElements */}
                    {reportPages
                      ? reportPages.map((reportPage, i) => (
                          <>
                            {i === parseInt(activeReportPageIndex) ? (
                              <div className="card mt-2">
                                <div
                                  className="card-header tt-group-header"
                                  style={{ padding: "6px" }}
                                >
                                  <div className="row">
                                    <div className="col mt-1">
                                      {"Add Elements in Column"}
                                    </div>
                                    <div className="col">
                                      <select
                                        name="activeColumn"
                                        value={activeColumn}
                                        onChange={
                                          this.props.handleFormDataChange
                                        }
                                        style={{ width: "100%" }}
                                      >
                                        <option value="1">{`Col 1 (${
                                          reportPage.layout
                                            ? reportPage.layout.col1Width
                                            : null
                                        } width)`}</option>
                                        {reportPage.layout &&
                                        reportPage.layout.col2Width !== "0%" ? (
                                          <option value="2">{`Col 2 (${
                                            reportPage.layout
                                              ? reportPage.layout.col2Width
                                              : null
                                          } width)`}</option>
                                        ) : null}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="card"
                                    style={
                                      parseInt(activeColumn) === 1
                                        ? {
                                            boxShadow:
                                              "2px 3px 3px rgba(0,0,0,0.33)",
                                          }
                                        : null
                                    }
                                  >
                                    <div
                                      className="card-header tt-group-header"
                                      style={{ padding: "6px" }}
                                    >
                                      Column 1{" "}
                                      {reportPage.layout
                                        ? `width(${reportPage.layout.col1Width})`
                                        : null}{" "}
                                    </div>
                                    <div className="card-body">
                                      <table className="table table-bordered table-striped">
                                        <thead className="tt-group-header text-center reportTable">
                                          <tr>
                                            <th style={{ width: "30%" }}>
                                              Elements
                                            </th>
                                            <th>Font Size</th>
                                            <th>Align</th>
                                            <th>
                                              Options
                                              <div className="col">
                                                <select
                                                  name="col1VerticalAlign"
                                                  value={
                                                    reportPage.layout
                                                      .col1VerticalAlign
                                                  }
                                                  onChange={(e) =>
                                                    this.props.handleReportPageVerticalAlignement(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                  style={{ width: "100%" }}
                                                >
                                                  <option value="top">
                                                    Top
                                                  </option>
                                                  <option value="middle">
                                                    Middle
                                                  </option>
                                                  <option value="bottom">
                                                    Bottom
                                                  </option>
                                                </select>
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {reportPage.col1Contents
                                            ? reportPage.col1Contents.map(
                                                (content, contentIndex) => (
                                                  <tr>
                                                    <td>
                                                      {
                                                        content.customAssessmentHeaderName
                                                      }
                                                    </td>
                                                    <td>
                                                      <select
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        value={
                                                          content.fontSize
                                                            ? content.fontSize
                                                            : "10"
                                                        }
                                                        onChange={(e) =>
                                                          this.props.handleContentFontSize(
                                                            e,
                                                            i,
                                                            "1",
                                                            contentIndex
                                                          )
                                                        }
                                                      >
                                                        {contentFontSizes
                                                          ? contentFontSizes.map(
                                                              (fontSize) => (
                                                                <option
                                                                  value={
                                                                    fontSize
                                                                  }
                                                                >
                                                                  {fontSize}
                                                                </option>
                                                              )
                                                            )
                                                          : null}
                                                      </select>
                                                    </td>
                                                    <td>
                                                      <select
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        value={
                                                          content.horizontalAlign
                                                            ? content.horizontalAlign
                                                            : "center"
                                                        }
                                                        onChange={(e) =>
                                                          this.props.handleContentHorizontalAlignement(
                                                            e,
                                                            i,
                                                            "1",
                                                            contentIndex
                                                          )
                                                        }
                                                      >
                                                        {contentAlignments
                                                          ? contentAlignments.map(
                                                              (alignment) => (
                                                                <option
                                                                  value={
                                                                    alignment
                                                                  }
                                                                >
                                                                  {alignment}
                                                                </option>
                                                              )
                                                            )
                                                          : null}
                                                      </select>
                                                    </td>
                                                    <td>
                                                      <div className="row">
                                                        <div className="col">
                                                          <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            className="tt-cursor-pointer"
                                                            onClick={(e) =>
                                                              this.props.handleAssessmentRemoval(
                                                                1,
                                                                i,
                                                                contentIndex
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        <div className="col">
                                                          {contentIndex !==
                                                          0 ? (
                                                            <FontAwesomeIcon
                                                              icon={faCaretUp}
                                                              size="2x"
                                                              className="tt-cursor-pointer"
                                                              onClick={() =>
                                                                this.props.handleAssessmentReorderBtnClick(
                                                                  1,
                                                                  i,
                                                                  contentIndex,
                                                                  --contentIndex
                                                                )
                                                              }
                                                            />
                                                          ) : null}
                                                        </div>
                                                        <div className="col">
                                                          {contentIndex !==
                                                          reportPage
                                                            .col1Contents
                                                            .length -
                                                            1 ? (
                                                            <FontAwesomeIcon
                                                              icon={faCaretDown}
                                                              size="2x"
                                                              className="tt-cursor-pointer"
                                                              onClick={() =>
                                                                this.props.handleAssessmentReorderBtnClick(
                                                                  1,
                                                                  i,
                                                                  contentIndex,
                                                                  ++contentIndex
                                                                )
                                                              }
                                                            />
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            : null}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  {reportPage.layout &&
                                  reportPage.layout.col2Width !== "0%" ? (
                                    <div
                                      className="card mt-2"
                                      style={
                                        parseInt(activeColumn) === 2
                                          ? {
                                              boxShadow:
                                                "2px 3px 3px rgba(0,0,0,0.33)",
                                            }
                                          : null
                                      }
                                    >
                                      <div
                                        className="card-header tt-group-header"
                                        style={{ padding: "6px" }}
                                      >
                                        Column 2{" "}
                                        {reportPage.layout
                                          ? `width(${reportPage.layout.col2Width})`
                                          : null}
                                      </div>
                                      <div className="card-body">
                                        <table className="table table-bordered table-striped">
                                          <thead className="tt-group-header text-center reportTable">
                                            <th style={{ width: "30%" }}>
                                              {"Elements"}
                                            </th>
                                            <th>{"Font Size"}</th>
                                            <th>{"Align"}</th>
                                            <th>
                                              {"Options"}
                                              <div className="col">
                                                <select
                                                  name="col2VerticalAlign"
                                                  value={
                                                    reportPage.layout
                                                      .col2VerticalAlign
                                                  }
                                                  onChange={(e) =>
                                                    this.props.handleReportPageVerticalAlignement(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                  style={{ width: "100%" }}
                                                >
                                                  <option value="top">
                                                    Top
                                                  </option>
                                                  <option value="middle">
                                                    Middle
                                                  </option>
                                                  <option value="bottom">
                                                    Bottom
                                                  </option>
                                                </select>
                                              </div>
                                            </th>
                                          </thead>
                                          <tbody>
                                            {reportPage.col2Contents
                                              ? reportPage.col2Contents.map(
                                                  (content, contentIndex) => (
                                                    <tr>
                                                      <td>
                                                        {
                                                          content.customAssessmentHeaderName
                                                        }
                                                      </td>
                                                      <td>
                                                        <select
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                          value={
                                                            content.fontSize
                                                              ? content.fontSize
                                                              : "10"
                                                          }
                                                          onChange={(e) =>
                                                            this.props.handleContentFontSize(
                                                              e,
                                                              i,
                                                              "2",
                                                              contentIndex
                                                            )
                                                          }
                                                        >
                                                          {contentFontSizes
                                                            ? contentFontSizes.map(
                                                                (fontSize) => (
                                                                  <option
                                                                    value={
                                                                      fontSize
                                                                    }
                                                                  >
                                                                    {fontSize}
                                                                  </option>
                                                                )
                                                              )
                                                            : null}
                                                        </select>
                                                      </td>
                                                      <td>
                                                        <select
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                          value={
                                                            content.horizontalAlign
                                                              ? content.horizontalAlign
                                                              : "center"
                                                          }
                                                          onChange={(e) =>
                                                            this.props.handleContentHorizontalAlignement(
                                                              e,
                                                              i,
                                                              "2",
                                                              contentIndex
                                                            )
                                                          }
                                                        >
                                                          {contentAlignments
                                                            ? contentAlignments.map(
                                                                (alignment) => (
                                                                  <option
                                                                    value={
                                                                      alignment
                                                                    }
                                                                  >
                                                                    {alignment}
                                                                  </option>
                                                                )
                                                              )
                                                            : null}
                                                        </select>
                                                      </td>
                                                      <td>
                                                        <div className="row">
                                                          <div className="col">
                                                            <FontAwesomeIcon
                                                              icon={faTrashAlt}
                                                              className="tt-cursor-pointer"
                                                              onClick={(e) =>
                                                                this.props.handleAssessmentRemoval(
                                                                  2,
                                                                  i,
                                                                  contentIndex
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col">
                                                            {contentIndex !==
                                                            0 ? (
                                                              <FontAwesomeIcon
                                                                icon={faCaretUp}
                                                                size="2x"
                                                                className="tt-cursor-pointer"
                                                                onClick={() =>
                                                                  this.props.handleAssessmentReorderBtnClick(
                                                                    2,
                                                                    i,
                                                                    contentIndex,
                                                                    --contentIndex
                                                                  )
                                                                }
                                                              />
                                                            ) : null}
                                                          </div>
                                                          <div className="col">
                                                            {contentIndex !==
                                                            reportPage
                                                              .col2Contents
                                                              .length -
                                                              1 ? (
                                                              <FontAwesomeIcon
                                                                icon={
                                                                  faCaretDown
                                                                }
                                                                size="2x"
                                                                className="tt-cursor-pointer"
                                                                onClick={() =>
                                                                  this.props.handleAssessmentReorderBtnClick(
                                                                    2,
                                                                    i,
                                                                    contentIndex,
                                                                    ++contentIndex
                                                                  )
                                                                }
                                                              />
                                                            ) : null}
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                )
                                              : null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                          </>
                        ))
                      : null}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    {/* Assessment addition */}
                    <div className="card">
                      <div
                        className="card-header tt-group-header"
                        style={{ padding: "6px" }}
                      >
                        {"Add Assessments"}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            {assessmentSubjectScaleConfigGroups
                              ? assessmentSubjectScaleConfigGroups.map(
                                  (group, i) => (
                                    <Chip
                                      label={
                                        "G-" +
                                        group.id +
                                        " SUBJECTS: " +
                                        group.scales
                                      }
                                      color="primary"
                                      onClick={(e) =>
                                        this.props.handleAddAssessmentChipClick(
                                          "SUB",
                                          i
                                        )
                                      }
                                    />
                                  )
                                )
                              : null}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {assessmentGeneralCustomAssessments
                            ? assessmentGeneralCustomAssessments.generalCustomAssessmentOveral.map(
                                (assessmentOverall, i) => (
                                  <div>
                                    <Chip
                                      label={`GENERAL OVERALL ONLY: ${assessmentOverall.customAssessmentHeaderName}`}
                                      color="secondary"
                                      onClick={(e) =>
                                        this.props.handleAddAssessmentChipClick(
                                          "O",
                                          i
                                        )
                                      }
                                    />
                                  </div>
                                )
                              )
                            : null}

                          {assessmentGeneralCustomAssessments
                            ? assessmentGeneralCustomAssessments.generalCustomAssessmentRubric.map(
                                (assessmentRubric, i) => (
                                  <div>
                                    <Chip
                                      label={`GENERAL RUBRIC ONLY: ${assessmentRubric.customAssessmentHeaderName}`}
                                      color="secondary"
                                      onClick={(e) =>
                                        this.props.handleAddAssessmentChipClick(
                                          "R",
                                          i
                                        )
                                      }
                                    />
                                  </div>
                                )
                              )
                            : null}

                          {assessmentGeneralCustomAssessments
                            ? assessmentGeneralCustomAssessments.generalCustomAssessmentOveralAndRubric.map(
                                (assessmentOverallAndRubric, i) => (
                                  <div>
                                    <Chip
                                      label={`GENERAL OVERALL AND RUBRIC: ${assessmentOverallAndRubric.customAssessmentHeaderName}`}
                                      color="secondary"
                                      onClick={(e) =>
                                        this.props.handleAddAssessmentChipClick(
                                          "B",
                                          i
                                        )
                                      }
                                    />
                                  </div>
                                )
                              )
                            : null}

                          {legends
                            ? legends.map((legend, i) => (
                                <div>
                                  <Chip
                                    label={`LEGEND: ${legend.scaleName}`}
                                    color="primary"
                                    onClick={(e) =>
                                      this.props.handleAddAssessmentChipClick(
                                        "LEGEND",
                                        i
                                      )
                                    }
                                  />
                                </div>
                              ))
                            : null}
                          {this.props.reportSetupSubjectSettingBds != null &&
                          this.props.reportSetupSubjectSettingBds.length > 0 &&
                          this.props.showPresetGpaLegend ? (
                            <div>
                              <Chip
                                label={`LEGEND: Grade Point Standard`}
                                color="primary"
                                onClick={(e) =>
                                  this.props.handleAddAssessmentChipClick(
                                    "LEGENDGP"
                                  )
                                }
                              />
                            </div>
                          ) : null}
                          {commentsChip
                            ? commentsChip.map((comment, i) => (
                                <div>
                                  <Chip
                                    label={`GENERAL: ${comment.commentName}`}
                                    color="primary"
                                    onClick={(e) =>
                                      this.props.handleAddAssessmentChipClick(
                                        "COMMENT",
                                        i
                                      )
                                    }
                                  />
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div
                        className="card-footer tt-group-header"
                        style={{
                          padding: "6px",
                          borderRadius: 0,
                          marginBottom: 0,
                        }}
                      >
                        <div className="row">
                          <div className="col">
                            <select
                              name="currentSubjectAssessmentScaleForSetting"
                              id=""
                              className="form-control"
                              value={
                                this.props
                                  .currentSubjectAssessmentScaleForSetting
                              }
                              onChange={(e) =>
                                this.props.handleSubjectAssessmentScaleSettingOptionChange(
                                  e
                                )
                              }
                            >
                              <option value="" disabled selected>
                                {"List of Assessment Scales"}
                              </option>
                              {subjectAssessmentScaleSettingOptions
                                ? subjectAssessmentScaleSettingOptions.map(
                                    (assessmentScale) => {
                                      let value = `${assessmentScale.groupId},${assessmentScale.id},${assessmentScale.assessmentScaleTypeCode},${assessmentScale.BdIndex}`;
                                      return (
                                        <option value={value}>
                                          {"G-" + assessmentScale.groupId}{" "}
                                          {assessmentScale.scaleName}
                                        </option>
                                      );
                                    }
                                  )
                                : null}
                            </select>
                          </div>
                          <div className="col">
                            <button
                              className="tt-button tt-button-primary"
                              onClick={this.props.handleSubjectSettingBtnClick}
                            >
                              Settings
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    {/* Row Addition */}
                    <div className="card">
                      <div
                        className="card-header tt-group-header"
                        style={{ padding: "6px" }}
                      >
                        {"Add Row"}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <Chip
                              label="School Name"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("schoolName")
                              }
                            />

                            <Chip
                              label="Education Level"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick(
                                  "educationLevel"
                                )
                              }
                            />

                            <Chip
                              label="Year Level"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("yearLevel")
                              }
                            />

                            <Chip
                              label="Classroom"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("classroom")
                              }
                            />

                            <Chip
                              label="Student Name"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("studentName")
                              }
                            />

                            <Chip
                              label="Roll Number"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("rollNumber")
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <Chip
                              label="School Address"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick(
                                  "schoolAddress"
                                )
                              }
                            />

                            <Chip
                              label="Report Name"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("reportName")
                              }
                            />
                            <Chip
                              label="Report Publication Date"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick(
                                  "publishingDate"
                                )
                              }
                            />

                            <Chip
                              label="Academic Year"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("academicYear")
                              }
                            />

                            <Chip
                              label="Student Photo"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("studentPic")
                              }
                            />

                            {/* <Chip
                              label="Result Status"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("resultStatus")
                              }
                            /> */}

                            <Chip
                              label="Separator"
                              color="secondary"
                              onClick={(e) =>
                                this.props.handleAddRowChipClick("separator")
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="card-footer tt-group-header"
                        style={{ padding: "6px", marginBottom: 0 }}
                      >
                        <div className="row">
                          <div className="col">
                            <input
                              type="text"
                              name="customText"
                              value={customText}
                              onChange={this.props.handleFormDataChange}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="col">
                            <button
                              className="tt-button tt-button-primary"
                              onClick={this.props.handleAddRowBtnClick}
                            >
                              Add Row
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-5 tt-printbox1"
                style={{ backgroundColor: "#ccc", position: "relative" }}
              >
                <ReportMagnifier
                  handleZoomIn={this.props.handleZoomIn}
                  handleZoomOut={this.props.handleZoomOut}
                />
                {reportPages
                  ? reportPages.map((reportPage, i) => (
                      <>
                        {i === parseInt(activeReportPageIndex) ? (
                          <div
                            className="tt-printPreview"
                            style={{
                              zoom: this.props.zoom + "%",
                              height: `${pageSize.height}${pageSizeUnit}`,
                              maxWidth: `${pageSize.width}${pageSizeUnit}`,
                              width: `${pageSize.width}${pageSizeUnit}`,
                              paddingTop: `${reportPage.margin.top}${reportPage.marginSizeUnit}`,
                              paddingLeft: `${reportPage.margin.left}${reportPage.marginSizeUnit}`,
                              paddingRight: `${reportPage.margin.right}${reportPage.marginSizeUnit}`,
                              paddingBottom: `${reportPage.margin.bottom}${reportPage.marginSizeUnit}`,
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                maxWidth: "100%",
                                borderLeft: "1px dashed #ccc",
                                borderRight: "1px dashed #ccc",
                              }}
                            >
                              <div
                                style={{
                                  height: `${headerHeight}${pageSizeUnit}`,
                                  maxWidth: "100%",
                                  border: "1px dashed #ccc",
                                }}
                              ></div>
                              <div
                                style={{
                                  height: `${descriptionBox.height}${descriptionBox.heightUnit}`,
                                  marginTop: `${descriptionBox.marginTop}${descriptionBox.marginSizeUnit}`,
                                  marginLeft: `${descriptionBox.marginLeft}${descriptionBox.marginSizeUnit}`,
                                  marginRight: `${descriptionBox.marginRight}${descriptionBox.marginSizeUnit}`,
                                  marginBottom: `${descriptionBox.marginBottom}${descriptionBox.marginSizeUnit}`,
                                  display: "flex",
                                  flexWrap: "wrap",
                                  border: "1px dashed #ccc",
                                }}
                              >
                                <div className="col-md-12 descriptionBox">
                                  DESCRIPTION BOX
                                </div>
                              </div>

                              <div
                                style={{
                                  height: `${
                                    pageSize.height -
                                    (headerHeight +
                                      footerHeight +
                                      descriptionBox.height +
                                      descriptionBox.marginTop +
                                      descriptionBox.marginBottom +
                                      reportPage.margin.top +
                                      reportPage.margin.bottom)
                                  }${pageSizeUnit}`,
                                  maxWidth: "100%",
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px dashed #ccc",
                                    height: "100%",
                                    width: reportPage.layout.col1Width,
                                    overflow: "hidden",
                                  }}
                                  className={`tt_align_items_v_${
                                    reportPage.layout.col1VerticalAlign
                                      ? reportPage.layout.col1VerticalAlign
                                      : "top"
                                  }`}
                                >
                                  {reportPage.col1Contents
                                    ? reportPage.col1Contents.map(
                                        (content, contentIndex) => (
                                          <div
                                            style={{
                                              maxHeight:
                                                content.customAssessmentHeaderName ===
                                                "Separator"
                                                  ? "25px"
                                                  : "150px",
                                              width: "100%",
                                              overflow: "hidden",
                                              padding: "3px",
                                            }}
                                          >
                                            <div
                                              className="col jumbotron"
                                              style={{
                                                textAlign:
                                                  content.horizontalAlign
                                                    ? content.horizontalAlign
                                                    : "center",
                                                fontSize: content.fontSize
                                                  ? content.fontSize + "pt"
                                                  : "10pt",
                                                padding:
                                                  content.customAssessmentHeaderName ===
                                                  "Separator"
                                                    ? 0
                                                    : "4rem 2rem",
                                              }}
                                            >
                                              {
                                                content.customAssessmentHeaderName
                                              }
                                            </div>
                                          </div>
                                        )
                                      )
                                    : null}
                                </div>
                                <div
                                  style={{
                                    border: "1px dashed #ccc",
                                    height: "100%",
                                    width: reportPage.layout.col2Width,
                                    display:
                                      reportPage.layout.col2Width === "0%"
                                        ? "none"
                                        : "",
                                  }}
                                  className={`tt_align_items_v_${
                                    reportPage.layout.col2VerticalAlign
                                      ? reportPage.layout.col2VerticalAlign
                                      : "top"
                                  }`}
                                >
                                  {reportPage.col2Contents
                                    ? reportPage.col2Contents.map(
                                        (content, contentIndex) => (
                                          <div
                                            style={{
                                              maxHeight: "150px",
                                              width: "100%",
                                              overflow: "hidden",
                                              padding: "3px",
                                              display: "flex",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <div
                                              className="col jumbotron"
                                              style={{
                                                textAlign:
                                                  content.horizontalAlign
                                                    ? content.horizontalAlign
                                                    : "center",
                                                fontSize: content.fontSize
                                                  ? content.fontSize + "pt"
                                                  : "10pt",
                                              }}
                                            >
                                              {
                                                content.customAssessmentHeaderName
                                              }
                                            </div>
                                          </div>
                                        )
                                      )
                                    : null}
                                </div>
                              </div>
                              <div
                                style={{
                                  height: `${footerHeight}${pageSizeUnit}`,
                                  maxWidth: "100%",
                                  border: "1px dashed #ccc",
                                }}
                              ></div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className="card-footer"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
        >
          <div className="row">
            <div className="col"></div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handlePreviousBtnClick}
              >
                Previous
              </button>
            </div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handleSaveBtnClick}
              >
                Save
              </button>
            </div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handleNextBtnClick}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
