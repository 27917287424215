import React, { Component } from "react";

class RepositoryQuestionLists extends Component {
  state = {};
  componentDidMount() {}

  render() {
    return (
      <div className="questionHolderRepo">
        <div className="text-center">
          <strong>QUESTIONS</strong>
        </div>
        <hr></hr>
        <div>
          <strong>SPATIO-TEMPORAL (When / Where)</strong>
          {this.props.spationQuestions.length > 0 ? (
            this.props.spationQuestions.map((question, idx) => {
              return (
                <p
                  key={idx}
                  className="mb-0"
                  onClick={(e) =>
                    this.props.searchQuestion(e, question.question)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {question.question}
                </p>
              );
            })
          ) : (
            <p className="mb-0">No questions</p>
          )}
        </div>
        <hr></hr>
        <div className="">
          <strong>IDENTITY (Who)</strong>
          {this.props.whoQuestions.length > 0 ? (
            this.props.whoQuestions.map((question, wIdx) => {
              return (
                <p
                  key={wIdx}
                  className="mb-0"
                  onClick={(e) =>
                    this.props.searchQuestion(e, question.question)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {question.question}
                </p>
              );
            })
          ) : (
            <p className="mb-0">No questions</p>
          )}
        </div>
        <hr></hr>
        <div className="">
          <strong>MODALITY (How)</strong>
          {this.props.howQuestions.length > 0 ? (
            this.props.howQuestions.map((question, hIdx) => {
              return (
                <p
                  key={hIdx}
                  className="mb-0"
                  onClick={(e) =>
                    this.props.searchQuestion(e, question.question)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {question.question}
                </p>
              );
            })
          ) : (
            <p className="mb-0">No questions</p>
          )}
        </div>
        <hr></hr>
        <div className="">
          <strong>DEFINITION (What)</strong>
          {this.props.whatQuestions.length > 0 ? (
            this.props.whatQuestions.map((question, whIdx) => {
              return (
                <p
                  key={whIdx}
                  className="mb-0"
                  onClick={(e) =>
                    this.props.searchQuestion(e, question.question)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {question.question}
                </p>
              );
            })
          ) : (
            <p className="mb-0">No questions</p>
          )}
        </div>
      </div>
    );
  }
}

export default RepositoryQuestionLists;
