import React, { Component } from "react";

class AddTheme extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <label>
              <strong>
                Theme name
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </label>
            <input
              type="text"
              className="form-control mb-4"
              name="theme"
              onChange={this.props.handleChange}
              value={this.props.theme}
            />
          </div>
          <div className="col-md-6">
            <label>
              <strong>Theme description</strong>
            </label>
            <input
              type="text"
              className="form-control mb-4"
              name="description"
              onChange={this.props.handleChange}
              value={this.props.description}
            />
          </div>
        </div>

        <div className="text-right">
          <button
            className="tt-button tt-button-primary mr-2"
            onClick={this.props.handleAddThemeSubmit}
          >
            Submit
          </button>
        </div>
      </>
    );
  }
}

export default AddTheme;
