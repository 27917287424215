import React, { Component } from "react";
import {
  URL,
  checkRolePermissions,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import { axiosPost } from "../../../../utils/AxiosApi";
import AddNewDesignation from "../../../Recruitment/Recruitment/AddNewDesignation";
import swal from "@sweetalert/with-react";
import { Spinner } from "reactstrap";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import ModalWindow from "./../../../UI/ModalWindow";

// import React, { useState, useReducer } from "react";

// const AddDesignation = () => {
//   const [value, setValue] = useState(0);
//   const [state, dispatch] = useReducer(reducer, value);
//   function reducer(state, action) {

//     switch (action.type) {
//       case "increment":
//         return { value: state.value + 1 };
//     }
//   default:
//     return state;
//   }
//   return (
//     <div>
//       {value}
//       <button onClick={() => dispatch({ type: increment })}>+</button>
//       <button onClick={() => setValue(value - 1)}>-</button>
//     </div>
//   );
// };

// export default AddDesignation;

class AddDesignation extends Component {
  state = {
    permissionInsertDesignation: false,
    isLoading: true,
  };

  componentDidMount = () => {
    this.checkPermissions();
    this.getListOfDesignation();
  };

  getListOfDesignation = () => {
    this.setState({ isLoading: true }, function () {
      axiosPost(URL.getDesignations, {}, (response) => {
        this.setState({ isLoading: false });
        if (response.status === 200) {
          let data = response.data.data;
          if (data.length > 0) {
            data.forEach((el) => {
              el.editable = false;
              el.designationeditable = false;
              el.selectedTeachers = [];
            });
          }
          this.setState({
            designationNames: data,
          });
        }
      });
    });
  };

  checkPermissions = () => {
    this.setState({
      permissionInsertDesignation: checkRolePermissions(
        "add-designation",
        "activity"
      ),
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "designationName" && value.length > 0) {
      this.setState({ nameError: false });
    }
    this.setState({
      [name]: value,
    });
  };
  getParameter = () => {
    return {
      name: this.state?.designationName,
      details: this.state?.details,
    };
  };
  submit = (e, params) => {
    e.preventDefault();
    //Api call to post JSON
    if (params.name === "" || params.name === undefined) {
      swal({
        title: "Warning",
        text: "First enter designation name",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      this.setState({ nameError: true });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertDesignation,
          params,
          (response) => {
            if (response.status === 200) {
              var self = this;
              swal({
                title: "Success",
                text: "Designation has been successfully added",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });

              draggable();
              this.setState({
                designationName: "",
                details: "",
                showSpinner: false,
              });
              this.getListOfDesignation();
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            let errorResponse = error.response ? error.response.data : error;
            if (errorResponse.status === 400) {
              //if condition to check spring boot validation errors
              let errorMessage = "";
              if (errorResponse.errors) {
                errorResponse.errors.forEach((error) => {
                  errorMessage += `${error.field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} ${error.defaultMessage} \n`; //ishan
                });
                swal(errorResponse.error, errorMessage, "");
              } else {
                swal(errorResponse.error, errorResponse.message, "");
              }
            } else {
              swal(
                errorResponse.error || "Network Error",
                errorResponse.message
                  ? errorResponse.message
                  : "Could Not Connect To The Server.",
                ""
              );
            }
          }
        );
      });
    }
  };

  editDesignation = (idx) => {
    let designationNames = [...this.state?.designationNames];
    if (designationNames.length > 0) {
      designationNames.forEach((el) => {
        el.editable = false;
      });
      designationNames[idx].editable = true;
    }
    this.setState({
      designationNames,
      updatedDesignationName: designationNames[idx].name,
      updatedDetails: designationNames[idx].details,
    });
  };
  updateDesignation = (el) => {
    if (
      (this.state.updatedDesignationName === null ||
        this.state.updatedDesignationName === "") &&
      (this.state.updatedDetails !== null || this.state.updatedDetails === "")
    ) {
      swal({
        title: "Success",
        text: "Fields Must not be empty",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    } else {
      const datas = {
        name: this.state?.updatedDesignationName,
        details: this.state?.updatedDetails,
        id: el.id,
      };
      axiosPost(URL.updateDesignation, datas, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: "Designation has been successfully updated",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          this.setState({ designationNames: [] }, function () {
            this.getListOfDesignation();
          });
        }
      });
    }
  };

  deleteDesignation = (el) => {
    swal({
      title: "Are you sure?",
      text: "Once cleared, you will not be able to recover this record!",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      allowOutsideClick: false,
    }).then((willDelete) => {
      if (willDelete) {
        const param = {
          id: el.id,
        };
        axiosPost(URL.deleteDesignation, param, (response) => {
          if (response.status === 200) {
            swal("success", "Designation deleted successfully");
            this.setState({ designationNames: [] }, function () {
              this.getListOfDesignation();
            });
          } else {
            swal("Information", "Not Deleted.");
          }
        });
      } else {
        swal("Information", "Not Deleted.");
      }
    });
  };
  cancelUpdate = (idx) => {
    let designationNames = [...this.state?.designationNames];
    if (designationNames.length > 0) {
      designationNames.forEach((el) => {
        el.editable = false;
      });
    }
    this.setState({ designationNames });
  };

  renderListOfDesignation = () => {
    return (
      <>
        {this.state?.designationNames ? (
          <>
            <div
              className="tt-department-table"
              style={
                this.state.permissionInsertDesignation
                  ? {}
                  : { height: "67.5vh" }
              }
            >
              {!this.state.isLoading ? (
                <table className="table text-center table-bordered table-striped">
                  <thead className="tt-group-header">
                    <tr>
                      <th width="20%">Designation Name</th>
                      <th width="40%">Details</th>
                      {/* <th>Staffs</th> */}
                      {/* {this.state.permissionAssignStaffDepartment ? ( */}
                      <th width="120px">Option</th>
                      {/* ) : null} */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.designationNames.length > 0 ? (
                      this.state.designationNames.map((el, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {el.editable ? (
                                <input
                                  type="text"
                                  id={"name" + idx}
                                  className="form-control"
                                  value={this.state?.updatedDesignationName}
                                  name="updatedDesignationName"
                                  onChange={this.handleChange}
                                />
                              ) : (
                                el.name
                              )}
                            </td>
                            <td>
                              {el.editable ? (
                                <textarea
                                  id={"details" + idx}
                                  className="form-control"
                                  value={this.state?.updatedDetails}
                                  name="updatedDetails"
                                  onChange={this.handleChange}
                                />
                              ) : (
                                el.details
                              )}
                            </td>

                            <td>
                              <button
                                className="tt-button tt-button-primary tt-button"
                                onClick={
                                  el.editable
                                    ? () => this.updateDesignation(el)
                                    : () => this.editDesignation(idx)
                                }
                              >
                                {el.editable ? "Update" : "Edit"}
                              </button>

                              {el.editable ? (
                                <button
                                  className="tt-button tt-button-primary tt-button"
                                  onClick={() => this.cancelUpdate(idx)}
                                >
                                  Cancel
                                </button>
                              ) : null}
                              {el.editable ? null : (
                                <button
                                  className="tt-button tt-button-primary tt-button"
                                  onClick={() => this.deleteDesignation(el)}
                                >
                                  Delete
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                        // colSpan={
                        //   this.state.permissionAssignStaffDepartment ? 4 : 3
                        // }
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="text-center" style={{ marginTop: "50px" }}>
                  <Spinner color="primary" />
                </div>
              )}
            </div>
          </>
        ) : null}
      </>
    );
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            Designation
            {checkWidgetAdminOrNot("Academic Administration") === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          {this.renderListOfDesignation()}

          <AddNewDesignation
            designationComponent={true}
            handleChange={this.handleChange}
            handleSubmit={this.submit}
            getParameter={this.getParameter}
            designationName={this.state?.designationName}
            details={this.state?.details}
          />
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School"
              header="View Designation"
              activityName="add-designation"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}
export default AddDesignation;
