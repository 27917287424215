import React, { Component } from "react";

class HighlightedHeader extends Component {
  state = {
    headerSection: [
      {
        classroom: this.props.classroomHeader,
        assessment:
          this.props.classrooms !== undefined
            ? this.props.classIndex +
              " of " +
              this.props.classrooms.length +
              " Records"
            : this.props.assessments !== undefined
            ? this.props.assessmentIdx +
              " of " +
              this.props.assessments.length +
              " Records"
            : null,
        recorded: this.props.recorded,
        deadline: "No",
      },
    ],
  };

  render() {
    return (
      <div>
        {this.state.headerSection.map((header, idx) => {
          return (
            <div
              className="container-fluid tt-generalAssessmentHeader"
              key={idx}
            >
              <div className="row">
                <div className="col" style={{ display: "flex" }}>
                  <div className="tt-hightlightHeaderTitle">
                    {header.classroom}
                  </div>
                  <div
                    className={
                      "tt-generalAssessmentHeaderContent " +
                      (header.recorded ? "tt-generalYellow" : "tt-generalGreen")
                    }
                  >
                    {header.assessment}
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default HighlightedHeader;
