import CryptoJS from "crypto-js";
import { cryptoSecretKey } from "./Constants";

export const checkRolePermissions = (action, moduleName) => {
  return true;
  let encryptedData = localStorage.getItem("permissions");

  let checkData = `${action} ${moduleName}`;
  let checkIndex = -1;
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, cryptoSecretKey);
    var permissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (permissions.length > 0) {
      let checkPermission = 0;
      permissions.forEach((pe) => {
        if (pe.name === moduleName) {
          checkIndex = pe.activities.find(
            (permission) => permission.name === checkData
          );
          if (checkIndex && checkIndex.isSelected) {
            checkPermission++;
          }
        }
      });
      if (checkPermission === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
