import React, { useEffect, useRef, useState } from "react";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import * as htmlToImage from "html-to-image";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const QuestionAnswersDetails = ({ selectedQA, details }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [schoolDetails, setSchoolDetails] = useState({ name: "", address: "" });

  useEffect(() => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        setSchoolDetails({
          name: response.data.data.name,
          address: response.data.data.address,
        });
      }
    });
  }, []);

  const checkScrollbar = (e) => {
    if (e.target.offsetHeight < e.target.scrollHeight) {
      alert("The element has a scrollbar");
    }
  };
  // const handleExportToPDF = async () => {
  //   const name = selectedQA.yearLevelName;
  //   const subject = selectedQA.subjectName;
  //   await htmlToImage
  //     .toJpeg(document.getElementById("targetToPrint"), {
  //       quality: 1.0,
  //       backgroundColor: "white",
  //     })
  //     .then((dataUrl) => {
  //       var link = document.createElement("a");
  //       link.download = `${name}-${subject}-WorkSheet.jpeg`;
  //       link.href = dataUrl;
  //       link.click();
  //     });
  // };

  return (
    <div>
      <React.Fragment className>
        {/* <div className="col text-center">
          <button
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: 0,
            }}
            className="tt-button tt-button-primary"
            onClick={handleExportToPDF}
          >
            {" "}
            Print Worksheet
          </button>
        </div> */}

        <button className="tt-button tt-button-primary" onClick={handlePrint}>
          Print Worksheet <FontAwesomeIcon icon={faPrint} />
        </button>

        <div id="targetToPrint" className="printWorkSheet" ref={componentRef}>
          <div
            key={selectedQA.id}
            className={selectedQA.id === 0 ? "" : " mt-3"}
          >
            <div className="row">
              <div
                className="col text-center"
                style={{
                  justifyContent: "center",
                  justifyItems: "center",
                  textAlign: "center",
                }}
              >
                <strong
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {schoolDetails.name}{" "}
                </strong>
                <div>{schoolDetails.address}</div>
              </div>
            </div>

            <div className="row">
              <div
                className="col text-center"
                style={{
                  justifyContent: "center",
                  justifyItems: "center",
                }}
              >
                <strong>{localStorage.getItem("yearLevel")}: </strong>
                {selectedQA.yearLevelName}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col text-left">
                <strong>Name:</strong>
              </div>

              <div className="col text-center">
                <strong>Subject: </strong>
                {selectedQA.subjectName}
              </div>

              <div className="col text-right">
                <strong>Date: </strong>
                {new Date().toDateString()}
              </div>
            </div>
          </div>
          <hr></hr>

          <h2> {selectedQA.title}</h2>

          {selectedQA.workSheetQuestions ? (
            selectedQA.workSheetQuestions.map((qA, idx) => {
              return (
                <div key={idx} className={idx === 0 ? "" : " mt-3"}>
                  <div
                    className="row tt-group-header"
                    style={{ borderRadius: 0, textShadow: "none" }}
                  >
                    <div className="col">
                      {idx + 1}. {qA.question}
                    </div>
                  </div>

                  {qA.answers.map((ans, id) => {
                    return (
                      <React.Fragment>
                        <div className="worksheetAnsDiv" key={id}>
                          <div
                            className="answerImageDivContainer"
                            style={{
                              width: ans.imgWidth + "px",
                              height: ans.imgHeight + "px",
                            }}
                          >
                            {details && ans.name ? (
                              <img src={URL.imageSelectURL + ans.name} />
                            ) : null}
                          </div>

                          <div style={{ flex: 1 }}>
                            <strong>
                              {ans.text ? <span>{ans.text}</span> : null}
                            </strong>
                            <textarea
                              name="answer"
                              style={{ width: "100%" }}
                              className=" worksheetTextArea"
                              rows={parseInt(ans.numberOfLines)}
                              onKeyPress={(e) => checkScrollbar(e)}
                            ></textarea>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div
              style={{ justifyItems: "center", justifyContent: "center" }}
              className="text-center"
            >
              No Data to Display
            </div>
          )}
        </div>
      </React.Fragment>
    </div>
  );
};

export default QuestionAnswersDetails;

// html2canvas(document.getElementById("targetToPrint")).then((canvas) => {
//   var data = canvas.toDataURL();
//   var pdfExportSetting = {
//     content: [
//       {
//         image: data,
//         width: 500,
//       },
//     ],
//   };
//   pdfMake.createPdf(pdfExportSetting).download("test_file.pdf");
// });

// document.getElementById("targetToPrint").parentNode.style.overflow =
//   "visible";
// const element = document.getElementById("targetToPrint");
// const canvas = await html2canvas(element);
// const data = canvas.toDataURL("image/png");

// const pdf = new jsPDF();
// const imgProperties = pdf.getImageProperties(data);
// const pdfWidth = pdf.internal.pageSize.getWidth();
// const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
// pdf.setFontSize(40);
// pdf.text(30, 20, "Hello world!");
// pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
// pdf.save("Worksheet.pdf");
