import * as Yup from "yup";

export const SubjectValidationSchema = Yup.object({
  subjectForms: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Subject Name is Required"),
      description: Yup.string().required("Description is Required"),
      image: Yup.string().required("Image is Required"),
    })
  ),
});
