import React, { Component } from "react";
import CustomSpinner from "../../components/common/CustomSpinner";

class ViewPermissions extends Component {
  state = {
    disableUpdate: true,
  };

  toggleUpdate = () => {
    this.setState({ disableUpdate: !this.state.disableUpdate });
  };

  render() {
    return (
      <div className="container">
        {this.props.spinner ? <CustomSpinner></CustomSpinner> : null}
        <div className="row">
          <div className="col text-right mb-2 mt-2">
            {!this.state.disableUpdate ? null : (
              <button
                className="btn btn-primary cusBtn cusBtnFontSize"
                onClick={this.toggleUpdate}
              >
                Update
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="roleAndPermissionBox">
              {this.props.modules && this.props.modules.length > 0
                ? this.props.modules.map((module, idx) => {
                    return (
                      <table
                        className={
                          "table cusTable " + (idx === 0 ? "" : "mt-3")
                        }
                        key={idx}
                      >
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                name={`module${idx}`}
                                checked={module.checked}
                                id={`module${idx}`}
                                onChange={(e) =>
                                  this.props.handleModuleChange(e, idx)
                                }
                                disabled={this.state.disableUpdate}
                              />
                              <label htmlFor={`module${idx}`} className="ml-2">
                                {module.name}
                              </label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {module.activities.length > 0
                            ? module.activities.map((activity, aIdx) => {
                                return (
                                  <tr key={aIdx}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        name={`activity${idx}${aIdx}`}
                                        checked={activity.checked}
                                        id={`activity${idx}${aIdx}`}
                                        onChange={(e) =>
                                          this.props.handleActivityChange(
                                            e,
                                            idx,
                                            aIdx
                                          )
                                        }
                                        disabled={this.state.disableUpdate}
                                      />
                                      <label
                                        htmlFor={`activity${idx}${aIdx}`}
                                        className="ml-2"
                                      >
                                        {activity.name}
                                      </label>
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.props.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewPermissions;
