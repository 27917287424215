import React, { Component } from "react";
import CustomModal from "../../components/common/CustomModal";
import { axiosPost } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import EventCreationModal from "./EventCreationModal";
import EventTypeCreationModal from "./EventTypeCreationModal";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert
} from "../../utils/DisplayMessage";
import { Spinner } from "reactstrap";
import CustomEditButton from "../../components/common/CustomEditButton";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import swal from "sweetalert";
import moment from "moment";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { checkRolePermissions } from "../../utils/Permissions";
class InsertCalendarEvent extends Component {
  state = {
    deleteEventTypePermission: false,
    deleteEventPermission: false,
    editEventModal: false,
    editEventPermission: false,
    eventModal: false,
    events: [],
    eventSpinner: false,
    eventTypeModal: false,
    editEventTypeModal: false,
    eventTypes: [],
    eventTypeSpinner: false,
    selectedData: "",
    editedEventTypeData: "",
    isEdit: false
  };

  checkPermissions = () => {
    this.setState(
      {
        deleteEventTypePermission: checkRolePermissions(
          "Delete",
          moduleName.eventType
        ),
        deleteEventPermission: checkRolePermissions(
          "Delete",
          moduleName.events
        ),
        editEventPermission: checkRolePermissions("Update", moduleName.events)
      },
      function() {
        this.getEventTypes();
        this.getEvents();
      }
    );
  };

  componentDidMount() {
    this.checkPermissions();
  }

  toggleEditEventTypeModal = () => {
    this.setState({ editEventTypeModal: !this.state.editEventTypeModal });
  };

  confirmDelete = data => {
    let param = {
      colorCode: data.colorCode
    };
    axiosPost(URL.deleteEventType, param, response => {
      swal("Success", "Event type successfully deleted");
      this.getEventTypes();
    });
  };

  confirmEventDelete = data => {
    let param = {
      id: data.id
    };
    axiosPost(URL.deleteCalendarEventCore, param, response => {
      swal("Success", "Event successfully deleted");
      this.getEvents();
    });
  };

  getEvents = () => {
    this.setState(
      { eventSpinner: true },
      function() {
        axiosPost(URL.getEventsCore, {}, response => {
          this.setState({ eventSpinner: false });
          if (response.status === 200) {
            this.setState({ events: response.data.data });
          }
        });
      },
      err => {
        this.setState({ eventSpinner: false });
        displayErrorAlert(err);
      }
    );
  };

  getEventTypes = () => {
    this.setState(
      { eventTypeSpinner: true },
      function() {
        axiosPost(URL.getEventTypes, {}, response => {
          this.setState({ eventTypeSpinner: false });
          if (response.status === 200) {
            this.setState({ eventTypes: response.data.data });
          }
        });
      },
      err => {
        this.setState({ eventTypeSpinner: false });
        displayErrorAlert(err);
      }
    );
  };

  handleDelete = colorCode => {
    displayConfirmDeleteAlert({ colorCode }, this.confirmDelete);
  };

  handleEventDelete = id => {
    displayConfirmDeleteAlert({ id }, this.confirmEventDelete);
  };

  toggleEditEventModal = evt => {
    this.setState({
      editEventModal: !this.state.editEventModal,
      selectedData: evt
    });
  };

  toggleEventModal = () => {
    this.setState({ eventModal: !this.state.eventModal });
  };

  toggleEventTypeModal = () => {
    this.setState({ eventTypeModal: !this.state.eventTypeModal });
  };

  handleEdit = items => {
    this.toggleEditEventTypeModal();
    this.setState({ editedEventTypeData: items });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <div className="whiteContainer" style={{ marginBottom: "0" }}>
              <CustomPageHeader
                title="Event Type"
                onClick={this.toggleEventTypeModal}
                icon="add_box"
                action="Insert"
                moduleName={moduleName.eventType}
              />
              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Color Code</th>
                    <th>IsHoliday</th>
                    {this.state.deleteEventTypePermission ? <th></th> : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.eventTypes.length > 0 ? (
                    this.state.eventTypes.map((evtType, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{evtType.eventTypeName}</td>
                          <td>
                            <div
                              className="eventTypeColorBox"
                              style={{ backgroundColor: evtType.colorCode }}
                            ></div>
                          </td>
                          <td>{evtType.isHoliday ? "Yes" : "No"}</td>
                          {this.state.deleteEventTypePermission ? (
                            <td>
                              <CustomDeleteButton
                                onClick={() =>
                                  this.handleDelete(evtType.colorCode)
                                }
                              />
                              <CustomEditButton
                                onClick={() => {
                                  this.handleEdit(evtType);
                                  this.setState({ isEdit: true });
                                }}
                              />
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={this.state.deleteEventTypePermission ? 4 : 3}
                        className="text-center"
                      >
                        {this.state.eventTypeSpinner ? (
                          <Spinner></Spinner>
                        ) : (
                          "No event type added"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 mt-5 mb-3">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Events"
                onClick={this.toggleEventModal}
                icon="add_box"
                action="Insert"
                moduleName={moduleName.events}
              />
              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th width="35%">Description</th>
                    <th>Date</th>
                    <th>Type</th>
                    {this.state.deleteEventPermission ||
                    this.state.editEventPermission ? (
                      <th></th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.events.length > 0 ? (
                    this.state.events.map((evt, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{evt.title}</td>
                          <td>{evt.description}</td>
                          <td>
                            {evt.start == evt.end
                              ? moment(evt.start).format("ll")
                              : `${moment(evt.start).format("ll")} - ${moment(
                                  evt.end
                                ).format("ll")}`}
                          </td>
                          <td>
                            <div
                              className="eventTypeBox"
                              style={{ backgroundColor: evt.color }}
                            >
                              {evt.eventTypeName}
                            </div>
                          </td>
                          {this.state.deleteEventPermission ||
                          this.state.editEventPermission ? (
                            <td>
                              {this.state.editEventPermission ? (
                                <>
                                  {" "}
                                  <CustomEditButton
                                    onClick={() =>
                                      this.toggleEditEventModal(evt)
                                    }
                                  ></CustomEditButton>{" "}
                                  |{" "}
                                </>
                              ) : null}
                              {this.state.deleteEventPermission ? (
                                <CustomDeleteButton
                                  onClick={() => this.handleEventDelete(evt.id)}
                                ></CustomDeleteButton>
                              ) : null}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={
                          this.state.deleteEventPermission ||
                          this.state.editEventPermission
                            ? 5
                            : 4
                        }
                        className="text-center"
                      >
                        {this.state.eventSpinner ? (
                          <Spinner></Spinner>
                        ) : (
                          "No events added"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.eventTypeModal}
          modalBody={
            <EventTypeCreationModal
              getEventTypes={this.getEventTypes}
              toggleEventTypeModal={this.toggleEventTypeModal}
            />
          }
          modalHeader="Add New Event Type"
          toggleModal={this.toggleEventTypeModal}
        ></CustomModal>

        <CustomModal
          modal={this.state.editEventTypeModal}
          modalBody={
            <EventTypeCreationModal
              getEventTypes={this.getEventTypes}
              toggleEventTypeModal={this.toggleEditEventTypeModal}
              editedEventTypeData={this.state.editedEventTypeData}
              isEdit={this.state.isEdit}
            />
          }
          modalHeader="Edit Event Type"
          toggleModal={this.toggleEditEventTypeModal}
        />
        <CustomModal
          modal={this.state.eventModal}
          modalBody={
            <EventCreationModal
              getEvents={this.getEvents}
              eventTypes={this.state.eventTypes}
              toggleEventModal={this.toggleEventModal}
            />
          }
          modalHeader="Add New Event"
          toggleModal={this.toggleEventModal}
        />
        <CustomModal
          modal={this.state.editEventModal}
          modalBody={
            <EventCreationModal
              edit={true}
              getEvents={this.getEvents}
              eventTypes={this.state.eventTypes}
              selectedData={this.state.selectedData}
              toggleEventModal={this.toggleEditEventModal}
            />
          }
          modalHeader="Update Event"
          toggleModal={this.toggleEditEventModal}
        />
      </div>
    );
  }
}

export default InsertCalendarEvent;
