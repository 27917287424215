import React, { Component } from "react";
import CreateCourseSyallabus from "./CreateCourseSyallabus";
import ListCourseSyallabus from "./ListCourseSyallabus";

export class CoursePlan extends Component {
  state = {
    courseSyllabus: [],
  };

  render() {
    return <ListCourseSyallabus displayType={"student"} />;
  }
}

export default CoursePlan;
