import React, { Component } from "react";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

class SubjectAssessmentDetailTableRow extends Component {
  state = {};
  render() {
    let sub = this.props.subject;
    return (
      <>
        {sub.hasTheory !== null && sub.hasTheory ? (
          <tr key={this.props.subjectIdx}>
            {sub.hasTheory !== null && sub.hasTheory ? (
              <>
                <td>
                  {sub.name}
                  <Chip
                    color={"default"}
                    className="override-chip"
                    label={"Theory"}
                  />
                </td>
                <td>
                  {sub.assessmentTheoryFromDate
                    ? moment(sub.assessmentTheoryFromDate).format("LLL")
                    : null}{" "}
                  -{" "}
                  {sub.assessmentTheoryToDate
                    ? moment(sub.assessmentTheoryToDate).format("LLL")
                    : null}
                </td>
                <td>
                  {sub.recordingTheoryDate
                    ? moment(sub.recordingTheoryDate).format("LL")
                    : null}
                </td>
                <td>
                  {sub.recordingTheoryDeadlineDate
                    ? moment(sub.recordingTheoryDeadlineDate).format("LL")
                    : null}
                </td>
              </>
            ) : null}
          </tr>
        ) : null}

        {sub.hasPractical !== null && sub.hasPractical ? (
          <tr>
            {sub.hasPractical !== null && sub.hasPractical ? (
              <>
                {sub.hasPractical ? (
                  <>
                    <td>
                      {sub.name}
                      <Chip
                        color={"default"}
                        className="override-chip"
                        label={"Practical"}
                      />
                    </td>
                    <td>
                      {sub.assessmentPracticalFromDate
                        ? moment(sub.assessmentPracticalFromDate).format("LLL")
                        : null}{" "}
                      -{" "}
                      {sub.assessmentPracticalToDate
                        ? moment(sub.assessmentPracticalToDate).format("LLL")
                        : null}
                    </td>
                    <td>
                      {sub.recordingPracticalDate
                        ? moment(sub.recordingPracticalDate).format("LL")
                        : null}
                    </td>
                    <td>
                      {sub.recordingPracticalDeadlineDate
                        ? moment(sub.recordingPracticalDeadlineDate).format(
                            "LL"
                          )
                        : null}
                    </td>
                  </>
                ) : null}
              </>
            ) : null}
          </tr>
        ) : null}
      </>
    );
  }
}

export default SubjectAssessmentDetailTableRow;
