import React, { Component } from "react";
import "../../assets/scss/userDetail.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import ModalWindow from "../UI/ModalWindow";
import Information from "./Information";
import { checkRolePermissions } from "../../utils/Constants";
import PerformanceAnalysis from "../PerformanceAnalysis/PerformanceAnalysis";

class NewUserDetail extends Component {
  state = {
    moreModal: false,
    sectionName: localStorage.getItem("classroom"),
    informationModal: false,
    performanceModal: false,
  };

  checkBirthday = (dateOfBirth) => {
    let userDate = moment(dateOfBirth);
    let todayDate = moment();
    return (
      userDate.date() === todayDate.date() &&
      userDate.month() === todayDate.month()
    );
  };

  displayMoreSubjects = () => {
    this.setState({ moreModal: !this.state.moreModal });
  };

  informationModal = () => {
    this.setState({ informationModal: !this.state.informationModal });
  };

  performanceModal = () => {
    this.setState({ performanceModal: !this.state.performanceModal });
  };
  subjectAssignedUI = () => {
    if (localStorage.getItem("classroom") !== null) {
      return (
        <div>
          <ol>
            {this.props.userData
              ? this.props.userData.subjects
                ? this.props.userData.subjects.map((subject, idx) => {
                    return (
                      <li key={idx}>
                        {subject.name} -{" "}
                        {`${localStorage.getItem("classroom")} ${
                          subject.yearLevelName
                        }`}
                      </li>
                    );
                  })
                : null
              : null}
          </ol>
        </div>
      );
    }
  };

  render() {
    let data = this.props.userData;
    let groupName = this.props.groupPage ? this.props.groupData.name : "";

    return (
      <div className="tt-mobileView">
        <div className={"tt-newUserDetailContainer"} id="newUserDetailBox">
          <div id="newUserData">
            <div className="container">
              <div className="row">
                {/* <div className="col-md-2 smallDiv"></div> */}
                <div className="col">
                  {this.props.familyPage ? (
                    <div className="row">
                      <div className="col">
                        <p
                          className="tt-newUserDetailTitle text-center"
                          style={{ fontSize: "1.7rem" }}
                        >
                          {this.props.family
                            ? this.props.family.name
                            : "No family Assigned"}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {this.props.groupPage ? (
                    <div className="row">
                      <div className="col text-center">
                        <p
                          className="tt-newUserDetailTitle"
                          style={{ fontSize: "1.7rem" }}
                        >
                          {groupName}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {!this.props.familyPage &&
                  !this.props.groupPage &&
                  data.userRole === "Admin" ? (
                    <div className="row">
                      <div className="col text-center">
                        <p
                          className="tt-newUserDetailTitle"
                          style={{ fontSize: "1.7rem" }}
                        >
                          {data.fullName} ({data.userRole})
                        </p>
                      </div>
                    </div>
                  ) : !this.props.familyPage &&
                    !this.props.groupPage &&
                    data.isStudent ? (
                    <div className="row studentDetail">
                      <div className="col-md-2 smallDiv"></div>
                      <div className="col ml-2">
                        <p className="tt-newUserDetailTitle">
                          {data.fullName} ({" "}
                          {data.yearLevelName
                            ? data.yearLevelName
                            : `No ${localStorage.getItem(
                                "yearLevel"
                              )} assigned`}{" "}
                          {/* -{" "} */}
                          {data.classroomName
                            ? data.classroomName
                            : "No section assigned"}
                          )
                        </p>

                        {data.dateOfBirth ? (
                          <p
                            className="newColor"
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              marginTop: "7px",
                            }}
                          >
                            <strong>Date of Birth: </strong>
                            {moment(data.dateOfBirth).format(
                              "ddd, MMM Do YYYY "
                            )}
                            (
                            {moment().diff(
                              data.dateOfBirth.substring(0, 10),
                              "years"
                            ) === 0
                              ? "1 years old"
                              : `${moment().diff(
                                  data.dateOfBirth.substring(0, 10),
                                  "years"
                                )} years old`}
                            )
                            {this.checkBirthday(data.dateOfBirth) ? (
                              <FontAwesomeIcon
                                icon={faBirthdayCake}
                              ></FontAwesomeIcon>
                            ) : (
                              ""
                            )}
                          </p>
                        ) : (
                          ""
                        )}

                        {data.parents && data.parents.length > 0 && (
                          <p
                            className="newColor"
                            style={{ textAlign: "left", fontWeight: "normal" }}
                          >
                            <strong>Parents Name : </strong>
                            {data.parents
                              ? data.parents.length > 0
                                ? data.parents.map((parent, idx) => {
                                    return (
                                      <span key={idx}>
                                        <span>
                                          {parent.name}
                                          {parent.relationship
                                            ? ` (${parent.relationship})`
                                            : ""}
                                        </span>
                                        <span>{", "}</span>
                                      </span>
                                    );
                                  })
                                : "----"
                              : "----"}
                          </p>
                        )}

                        {data.siblings && data.siblings.length > 0 && (
                          <p
                            className="newColor"
                            style={{ textAlign: "left", fontWeight: "normal" }}
                          >
                            <strong>Siblings Name : </strong>
                            {data.siblings
                              ? data.siblings.length > 0
                                ? data.siblings.map((sibling, idx) => {
                                    return (
                                      <span key={idx}>
                                        <span>
                                          {sibling.name}
                                          {sibling.relationship
                                            ? ` (${sibling.relationship})`
                                            : ""}
                                        </span>
                                        <span>{", "}</span>
                                      </span>
                                    );
                                  })
                                : "----"
                              : "----"}
                          </p>
                        )}
                      </div>
                      <div
                        className="col-md-2 text-right"
                        style={
                          {
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                          }
                        }
                      >
                        {!this.props.familyPage &&
                        !this.props.groupPage &&
                        this.props.userData.isStudent ? (
                          <Link
                            to={{
                              pathname: `/familyPage/${this.props.userData.familyId}`,
                              familyPostFromProfile: true,
                            }}
                          >
                            <button className="tt-button tt-button-primary tt-button-fullWidth">
                              Family Page
                            </button>
                          </Link>
                        ) : null}
                        {checkRolePermissions(
                          "student-cruicial-profile-information",
                          "activity"
                        ) ? (
                          !this.props.familyPage &&
                          !this.props.groupPage &&
                          this.props.userData.admsissionId != null ? (
                            <button
                              className="tt-button tt-button-primary tt-button-fullWidth mt-2"
                              onClick={() => this.informationModal()}
                            >
                              Information
                            </button>
                          ) : null
                        ) : null}
                        {!this.props.familyPage &&
                        !this.props.groupPage &&
                        this.props.userData.admsissionId !== null ? (
                          <button
                            className="tt-button tt-button-primary tt-button-fullWidth mt-2"
                            onClick={() => this.performanceModal()}
                          >
                            Performance
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ) : !this.props.familyPage && !this.props.groupPage ? (
                    <div className="row studentDetail">
                      <div className="col-md-2 smallDiv"></div>
                      <div className="col ml-2">
                        <p className="tt-newUserDetailTitle">{data.fullName}</p>
                        <div style={{ marginTop: "7px" }}>
                          {data.yearLevelName ? (
                            <p
                              className="newColor"
                              style={{
                                textAlign: "left",
                                fontWeight: "normal",
                              }}
                            >
                              <strong>Homeroom teacher of : </strong>{" "}
                              {data.yearLevelName !== null
                                ? data.yearLevelName.includes(
                                    localStorage.getItem("yearLevel")
                                  )
                                  ? data.yearLevelName
                                  : localStorage.getItem("yearLevel") +
                                    " " +
                                    data.yearLevelName
                                : `No ${localStorage.getItem(
                                    "yearLevel"
                                  )} assigned`}{" "}
                              -{" "}
                              {data.classroomName !== null
                                ? data.classroomName.includes(
                                    localStorage.getItem("classroom")
                                  )
                                  ? data.classroomName
                                  : localStorage.getItem("classroom") +
                                    " " +
                                    data.classroomName
                                : `No ${localStorage.getItem(
                                    "classroom"
                                  )} assigned`}
                            </p>
                          ) : null}

                          {data.isCoordinator ? (
                            <p
                              className="newColor"
                              style={{
                                textAlign: "left",
                                fontWeight: "normal",
                              }}
                            >
                              <strong>Coordinator of : </strong>{" "}
                              {data.coordinatorEducationLevelName
                                ? data.coordinatorEducationLevelName.length > 0
                                  ? data.coordinatorEducationLevelName.map(
                                      (coordinator, idx) => {
                                        return (
                                          <div className="userDetailHolder">
                                            <div className="userDetailEducationLevelHolder">
                                              {coordinator.educationLevelName +
                                                " School"}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : null
                                : null}
                            </p>
                          ) : null}
                          {data.isAdministrator ? (
                            <p
                              className="newColor"
                              style={{
                                textAlign: "left",
                                fontWeight: "normal",
                              }}
                            >
                              <strong>Administrator of : </strong>{" "}
                              {data.administratorEducationLevelName
                                ? data.administratorEducationLevelName.length >
                                  0
                                  ? data.administratorEducationLevelName.map(
                                      (administrator, idx) => {
                                        return (
                                          <div className="userDetailHolder">
                                            <div className="userDetailEducationLevelHolder">
                                              {administrator.educationLevelName +
                                                " School"}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : null
                                : null}
                            </p>
                          ) : null}

                          <div className="userDetailChipBox">
                            {data.subjects
                              ? data.subjects.length > 0
                                ? data.subjects.map((subject, idx) => {
                                    return idx <= 4 ? (
                                      <span key={idx}>
                                        <div className="userDetailHolder">
                                          <div className="userDetailYearHolder">
                                            {subject.name}
                                          </div>
                                          <div className="userDetailSectionHolder">
                                            {subject.yearLevelName}
                                          </div>
                                        </div>
                                      </span>
                                    ) : idx === data.subjects.length - 1 ? (
                                      <span key={idx}>
                                        <div className="userDetailHolder">
                                          <div
                                            className="userDetailYearHolder displayMoreChips"
                                            onClick={this.displayMoreSubjects}
                                          >
                                            + {data.subjects.length - 5} more
                                          </div>
                                        </div>
                                      </span>
                                    ) : (
                                      ""
                                    );
                                  })
                                : "No subjects assigned"
                              : "No subjects assigned"}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        {checkRolePermissions(
                          "staff-cruicial-profile-information",
                          "activity"
                        ) ? (
                          !this.props.familyPage &&
                          !this.props.groupPage &&
                          this.props.userData.recruitmentId != null ? (
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() => this.informationModal()}
                            >
                              Information
                            </button>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="toggleCoverBtn"
            onClick={this.props.minimizeCoverToggle}
          >
            <FontAwesomeIcon
              icon={this.props.minimize ? faChevronDown : faChevronUp}
            />
          </div>
        </div>
        <ModalWindow
          modal={this.state.moreModal}
          toggleModal={this.displayMoreSubjects}
          modalHeader="Subjects Assigned"
          modalBody={this.subjectAssignedUI()}
        ></ModalWindow>
        <ModalWindow
          modal={this.state.informationModal}
          toggleModal={this.informationModal}
          fullWidth={true}
          modalHeader={
            data
              ? data.fullName != "" || data.fullName != null
                ? data.fullName + "'s Information"
                : "Information"
              : null
          }
          modalBody={
            <Information
              profilePeopleId={this.props.profilePeopleId}
              userData={this.props.userData}
            />
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.performanceModal}
          toggleModal={this.performanceModal}
          fullWidth={true}
          modalHeader={
            data
              ? data.fullName != "" || data.fullName != null
                ? data.fullName + "'s Performance"
                : "Performance"
              : null
          }
          modalBody={
            <PerformanceAnalysis profilePeopleId={this.props.profilePeopleId} />
          }
        />
      </div>
    );
  }
}

export default NewUserDetail;
