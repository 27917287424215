import React, { Component } from "react";
import userImg from "../../assets/images/user-image.png";
import cp from "../../assets/images/coverPic/cp.png";
import cp2 from "../../assets/images/coverPic/cp2.png";
import cp3 from "../../assets/images/coverPic/cp3.png";
import cp4 from "../../assets/images/coverPic/cp4.png";
import teachImg1 from "../../assets/images/coverPic/teachImg1.jpg";
import teachImg2 from "../../assets/images/coverPic/teachImg2.jpg";
import teachImg3 from "../../assets/images/coverPic/teachImg3.jpg";
import teachImg4 from "../../assets/images/coverPic/teachImg4.jpg";
import loginImage from "../../assets/images/loginImage.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Spinner } from "reactstrap";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/Utils";

class GovHeader extends Component {
  state = {
    coverPictures: [
      {
        src: cp,
      },
      {
        src: cp2,
      },
      {
        src: cp3,
      },
      {
        src: cp4,
      },
    ],
    teachCoverPics: [
      {
        src: teachImg1,
      },
      { src: teachImg2 },
      { src: teachImg3 },
      { src: teachImg4 },
    ],
    fullName: "",
    loggedIn: false,
    profilePic: "",
    spinner: false,
  };

  componentDidMount() {
    this.getLoggedInStatus();
  }

  getLoggedInStatus = () => {
    let token = localStorage.getItem("token");
    if (token) {
      this.setState({ loggedIn: true }, function () {
        this.getUserInfo();
      });
    }
  };

  getUserInfo = () => {
    axiosPost(
      URL.getLoggedInUserInfo,
      {},
      (response) => {
        if (response.status === 200) {
          if (
            response.data.data.imagepath &&
            response.data.data.imagepath !== ""
          ) {
            this.setState({
              profilePic: URL.imageSelectURL + response.data.data.imagepath,
            });
          } else {
            this.setState({ profilePic: userImg });
          }
          this.setState({ fullName: response.data.data.name });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleError = (e, id) => {
    e.preventDefault();
    let newCoverPics = [...this.state.coverPictures];
    if (newCoverPics.length > 0) {
      const index = newCoverPics.findIndex((coverPic) => coverPic.id === id);
      if (newCoverPics.length === 1) {
        if (index !== -1) {
          newCoverPics[index].src = loginImage;
        }
      } else {
        index !== -1 && newCoverPics.splice(index, 1);
      }
      this.setState({ coverPictures: newCoverPics });
    }
  };
  render() {
    const settings = {
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: false,
          },
        },
      ],
    };
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 px-0">
            <div className="govHeaderDiv">
              <p className="govSubtitle">Government of Nepal</p>
              <p className="govSubtitle">
                Ministry of Education Science and Technology
              </p>
              <p className="govMainTitle">
                Center for Education and Human Resource Development
              </p>
              <p className="learnPortal">LEARNING PORTAL</p>
              {this.state.loggedIn ? (
                <div className="govProfileDiv">
                  <div className="govProfile">
                    <img src={this.state.profilePic} />
                  </div>
                  <div className="govProfileName">{this.state.fullName}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-8 px-0">
            <div className="govHeaderCoverPicDiv">
              {this.props.teacherPage ? (
                <Slider {...settings}>
                  {this.state.teachCoverPics.length >= 1 &&
                  !this.state.spinner ? (
                    this.state.teachCoverPics.map((coverPic, idx) => {
                      return (
                        <div key={idx} className="govSliderImg">
                          {/* eslint-disable-next-line */}
                          <img
                            alt="cover-picture"
                            src={coverPic.src}
                            onError={(e) => this.handleError(e, coverPic.id)}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="tt-slider-content tt-slider-content-minimize">
                      {this.state.spinner ? (
                        <div style={{ fontSize: "14px", marginTop: "40px" }}>
                          <Spinner color="primary"></Spinner>
                          <br></br>Loading Cover Photo...
                        </div>
                      ) : null}
                    </div>
                  )}
                </Slider>
              ) : (
                <Slider {...settings}>
                  {this.state.coverPictures.length >= 1 &&
                  !this.state.spinner ? (
                    this.state.coverPictures.map((coverPic, idx) => {
                      return (
                        <div key={idx} className="govSliderImg">
                          {/* eslint-disable-next-line */}
                          <img
                            alt="cover-picture"
                            src={coverPic.src}
                            onError={(e) => this.handleError(e, coverPic.id)}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="tt-slider-content tt-slider-content-minimize">
                      {this.state.spinner ? (
                        <div style={{ fontSize: "14px", marginTop: "40px" }}>
                          <Spinner color="primary"></Spinner>
                          <br></br>Loading Cover Photo...
                        </div>
                      ) : null}
                    </div>
                  )}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GovHeader;
