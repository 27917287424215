import moment from "moment";
import React from "react";

function StudentInfoDiv(props) {
  return (
    <div className="studentInfoDiv">
      <div className="studentPhotoHolder">
        <img
          src={props.image}
          alt="Profile Picture"
          onError={(e) => props.handleError(e)}
        />
      </div>
      <div className="text-center">
        <p className="stuFullName">{props.userData.fullName}</p>
      </div>
      <hr></hr>
      <div>
        <table className="table performanceTable">
          <tbody>
            <tr>
              <th>Student Id</th>
              <th>:</th>
              <td>{props.userData.admsissionId}</td>
            </tr>
            <tr>
              <th>Date Of Birth</th>
              <th>:</th>
              <td>{moment(props.userData.dateOfBirth).format("LL")}</td>
            </tr>
            <tr>
              <th>Gender</th>
              <th>:</th>
              <td>{props.userData.gender}</td>
            </tr>
            <tr>
              <th>Year Level</th>
              <th>:</th>
              <td>{props.userData.yearLevelName}</td>
            </tr>
            <tr>
              <th>Classroom</th>
              <th>:</th>
              <td>{props.userData.classroomName}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr></hr>
      <div>
        <table className="table performanceTable">
          <tbody>
            <tr>
              <th>Today's Attendance</th>
              <th>:</th>
              <td>Present</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr></hr>
      <div>
        <table className="table performanceTable">
          <tbody>
            <tr>
              <th>Total Attendance</th>
              <th>:</th>
              <td>80</td>
            </tr>
            <tr>
              <th>Total Absence</th>
              <th>:</th>
              <td>2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StudentInfoDiv;
