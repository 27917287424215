import React, { Component } from "react";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "./../UI/ModalWindow";
import GranularPermissionModal from "./../Permissions/GranularPermissionModal";
import swal from "sweetalert";
import moment from "moment";
import DatePicker from "./../UI/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBook,
  faCalendar,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import { data } from "jquery";

class LeaveRequestList extends Component {
  render() {
    return (
      <>
        {this.props.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Creating Academic Year
            </div>
          </div>
        ) : null}
        <div className="row mb-4">
          <div className="col">
            <label>From Date</label>
            <input
              type="date"
              className="form-control"
              value={this.props.leaveFrom}
              name="leaveFrom"
              onChange={(e) => this.props.handleChange(e)}
              // min={new Date().toISOString().substring(0, 10)}
            />
          </div>
          <div className="col">
            <label>To Date</label>
            <input
              type="date"
              className="form-control"
              value={this.props.leaveTo}
              min={this.props.leaveFrom}
              name="leaveTo"
              onChange={(e) => this.props.handleChange(e)}
            />
          </div>
          <div className="col">
            <label>Subject</label>
            <input
              type="text"
              className="form-control"
              value={this.props.name}
              name="name"
              placeholder="Subject"
              onChange={(e) => this.props.handleChange(e)}
            />
          </div>
          <div className="col">
            <label>Status</label>

            <select
              name="status"
              className="form-control"
              value={this.props.status}
              onChange={(e) => this.props.handleChange(e)}
            >
              <option selected value="">
                Choose status
              </option>
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
              <option value="pending">Pending</option>
            </select>
          </div>
          <div className="col-md-2 mt-4">
            <button
              className="tt-button tt-button-primary"
              onClick={() => this.props.getAllLeaveList()}
            >
              Search
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th>SN.</th>
                <th>Applied By</th>
                <th style={{ width: "12%" }}>Leave From</th>
                <th style={{ width: "12%" }}>Leave To</th>
                <th>Duration (Days)</th>
                <th>Leave Type</th>
                <th>Subject</th>
                {/* <th>Description</th> */}
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody style={{ fontWeight: "normal", textShadow: "none" }}>
              {this.props.leaveLists?.length > 0 ? (
                this.props.leaveLists.map((data, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>
                      {data?.parentPeople
                        ? data.parentPeople.name
                        : data.people?.name}
                    </td>
                    <td>{moment(data?.fromDate).format("YYYY-MM-DD")}</td>
                    <td>{moment(data?.toDate).format("YYYY-MM-DD")}</td>
                    <td>
                      {this.props.getDateDiff(data?.fromDate, data?.toDate) + 1}{" "}
                      {this.props.getDateDiff(data?.fromDate, data?.toDate) ===
                      0
                        ? "Day"
                        : "Days"}
                    </td>
                    <td>{data?.leaveTypeName}</td>
                    <td>{data?.subject}</td>
                    {/* <td>{data.description}</td> */}
                    <td className="text-capitalize">{data?.status}</td>
                    <td>
                      <button
                        className="tt-button tt-button-primary"
                        onClick={() => this.props.handleLeaveDetailModal(data)}
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={9}>No records to display</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* leave request reject modal start */}
        <ModalWindow
          modal={this.props.remarkLeaveModal}
          // size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              remarkLeaveModal: !this.props.remarkLeaveModal,
            });
          }}
          modalHeader={"Remarks"}
          modalBody={
            <>
              <textarea
                className="form-control"
                name="remarks"
                placeholder="Remarks"
                onChange={this.props.handleChange}
                value={this.props.remarks}
              ></textarea>
              <button
                className="tt-button tt-button-primary mt-2"
                onClick={() => this.props.handleLeaveStatusChange()}
              >
                Submit
              </button>
            </>
          }
        ></ModalWindow>
      </>
    );
  }
}
export default LeaveRequestList;
