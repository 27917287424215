import React, { Component } from "react";

class StudentList extends Component {
  state = {};

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body tt-selected-Students">
            {this.props.studentList ? (
              <table className="table table-bordered text-center table-striped">
                <thead className="tt-group-header">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={this.props.allStudentChecked}
                        onChange={(e) =>
                          this.props.handleStudentCheckbox(e, "all")
                        }
                      />
                    </th>
                    <th>Student's name</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.studentList.length > 0 ? (
                    this.props.studentList.map((student, idx) => {
                      return (
                        <tr key={idx}>
                          {student.people !== null ? (
                            <>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={student.checked}
                                  onChange={(e) =>
                                    this.props.handleStudentCheckbox(e, idx)
                                  }
                                />
                              </td>
                              <td>{student.people.name}</td>
                            </>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={2}>No students available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

export default StudentList;
