import moment from "moment";
import React, { Component } from "react";

class GeneralAssessmentDetailTableRow extends Component {
  state = {};
  render() {
    let general = this.props.generalDetail;
    return (
      <tr key={this.props.generalIdx}>
        <td>
          {general.assessmentFromDate
            ? moment(general.assessmentFromDate).format("LL")
            : "No date"}
        </td>
        <td>
          {general.assessmentToDate
            ? moment(general.assessmentToDate).format("LL")
            : "No date"}
        </td>
        <td>
          {general.recordingDate
            ? moment(general.recordingDate).format("LL")
            : "No date"}
        </td>
        <td>
          {general.recordingDeadlineDate
            ? moment(general.recordingDeadlineDate).format("LL")
            : "No date"}
        </td>
      </tr>
    );
  }
}

export default GeneralAssessmentDetailTableRow;
