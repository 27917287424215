import React, { Component } from "react";
import ClassForm from "./ClassForm";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import ListClass from "./ListClass";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";
class Class extends Component {
  state = {
    classes: [],
    modal: false,
  };

  componentDidMount() {
    this.getAllClasses();
  }

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  getAllClasses = () => {
    axiosPost(URL.getAllClass, {}, (response) => {
      if (response.status == 200) {
        this.setState({
          classes: response.data.data,
        });
      }
    });
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Classes"
                onClick={this.toggleModal}
                icon="add_box"
                noPermission={true}
              />
              <ListClass
                classes={this.state.classes}
                getAllClasses={this.getAllClasses}
              />
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          modalHeader="Add Class"
          modalBody={
            <ClassForm
              getAllClasses={this.getAllClasses}
              toggleModal={this.toggleModal}
            />
          }
        />
      </div>
    );
  }
}

export default Class;
