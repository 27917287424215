import React, { Component } from "react";
import CommentBankEditor from "./CommentBankEditor";

class CreateCommentTemplate extends Component {
  state = {};
  render() {
    return (
      <table className="table table-bordered text-center mb-0 table-striped">
        <thead className="tt-group-header">
          <tr>
            <th colSpan={4}>Create a New Comment Template</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="55%" className="text-left">
              <CommentBankEditor
                id="createCommentBank"
                valueArr={this.props.valueArr}
                changeHandler={this.props.changeHandler}
                comments={this.props.comments}
                pronouns={this.props.pronouns}
                clickHandler={this.props.clickHandler}
                editorRef={this.props.createEditorRef}
              ></CommentBankEditor>
            </td>
            <td>
              <select
                className="form-control"
                name="assessmentType"
                value={this.props.assessmentType}
                onChange={this.props.handleSelectChange}
              >
                <option value="" disabled>
                  Choose assessment type
                </option>
                <option value="general">General assessment</option>
                <optgroup label="Subject Assessment">
                  {this.props.subjectList.map((subList, idx) => {
                    return (
                      <option value={subList.id} key={idx}>
                        {subList.name}
                      </option>
                    );
                  })}
                </optgroup>
              </select>
            </td>
            <td>
              <select
                className="form-control"
                name="sharingOption"
                value={this.props.sharingOption}
                onChange={this.props.handleSelectChange}
              >
                <option value="" disabled>
                  Choose sharing option
                </option>
                <option value={true}>Public</option>
                <option value={false}>Private</option>
              </select>
            </td>
            <td>
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.saveCommentTemplate}
              >
                Save
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default CreateCommentTemplate;
