import React, { Component } from 'react'
import CustomButton from '../components/common/CustomButton'
import DragDropFile from './DragDropFile'

export class ImportFile extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="generateImportFile mb-2">
            <div>
              {/* <h6 className="mb-2">Instructions on importing the records</h6> */}

              <b>STEP 1: Download the template excel file</b>
              <p className="mt-2">
                The excel file contains relative columns to insert the data
                accordingly. Proceed by downloading the excel template.
              </p>
              <CustomButton
                text="Download Import Format"
                customStyle="cusBtnFontSize"
                onClick={this.props.handleGenerateFormat}
              ></CustomButton>
              <p className="mt-2" style={{ color: 'red' }}>
                *Note: The heading in the template should not be changed
                otherwise the data will not be imported.
              </p>

              <b>STEP 2: Insert the data into the template file</b>

              <p>
                According to the row and column in the template, fill up your
                data and save the file.
              </p>
            </div>
          </div>

          <b>STEP 3: Import the file </b>

          <p>
            Choose the file with your data and click "Import". Your data will be
            imported shortly.
          </p>

          <DragDropFile
            multiple={false}
            handleChange={this.props.handleChange}
            name="importFile"
            handleFileDrop={this.props.handleFileDrop}
            files={this.props.importFile}
            accept=".xlsx"
          />
          <div className="mt-3 text-right">
            {/* <input
              type="file"
              name="importFile"
              className="form-control mb-2"
              onChange={(e) => this.props.handleChange(e)}
            /> */}

            <CustomButton
              text="Import"
              customStyle="cusBtnFontSize"
              onClick={this.props.handleImport}
            ></CustomButton>
          </div>
        </div>
      </div>
    )
  }
}

export default ImportFile
