import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/Utils";
import CustomPageHeader from "../common/CustomPageHeader";
import ViewLMSFile from "../SchoolLMS/ViewLMSFile";
import CentralReadingMaterialCreateModal from "./CentralReadingMaterialsCreateModal";
import ModalWindow from "../../UI/ModalWindow";
import DragDropFile from "../../UI/DragDropFile";

export class CentralReadingMaterials extends Component {
  state = {
    addModal: false,
    lmsList: [],
    activeLMSFile: [],
    fileViewModal: false,
    editMode: false,
    title: "",
    description: "",
    spinner: false,
    addedResource: null,
    addResourceObj: "",
    addResourceModal: false,
    deleteReadingMaterials: false,
    deleteId: null,
    links: [{ name: "" }],
  };

  componentDidMount() {
    this.getSchoolLMS();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lms !== prevProps.lms && this.props.lms !== "") {
      this.getSchoolLMS();
    }
  }

  getSchoolLMS = () => {
    let schoolLmsTypeId =
      this.props.lms === "curriculum"
        ? 1
        : this.props.lms === "textbooks"
        ? 2
        : this.props.lms === "question-bank"
        ? 3
        : this.props.lms === "av-resource"
        ? 4
        : this.props.lms === "sign-language"
        ? 5
        : "";

    axiosPost(
      URL.getSchoolLMS,
      { schoolLmsTypeId: schoolLmsTypeId },
      (response) => {
        if (response.status === 200) {
          this.setState({
            lmsList: response.data.data,
          });
        }
      }
    );
  };

  toggleDeleteModal = () =>
    this.setState({
      deleteReadingMaterials: !this.state.deleteReadingMaterials,
    });
  handleViewResource = (file) => {
    this.setState({
      activeLMSFile: file,
      fileViewModal: true,
    });
  };

  toggleFileViewer = () => {
    this.setState({
      fileViewModal: !this.state.fileViewModal,
    });
  };

  handleAddResource = (item) => {
    this.setState({
      addResourceObj: item,
      addResourceModal: true,
    });
  };

  toggleAddResource = () => {
    this.setState({
      addResourceModal: !this.state.addResourceModal,
    });
  };

  handleChange = (e) => {
    let { name, value, files } = e.target;

    this.setState({ [name]: files ? files : value });
    let filesArr = [];
    if (files !== null && files !== undefined) {
      for (const key of Object.keys(files)) {
        filesArr.push(files[key]);
      }
      this.setState({
        addedResource: filesArr,
      });
    }
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    this.setState({
      [name]: files,
    });

    let filesArr = [];
    if (files !== null) {
      for (const key of Object.keys(files)) {
        filesArr.push(files[key]);
      }
      this.setState({
        addedResource: filesArr,
      });
    }
  };

  handleSubmitResources = () => {
    let newLinks = [];
    let linkCount = 0;
    newLinks = this.state.links.map((lnk) => {
      if (lnk.name !== "") {
        linkCount = linkCount + 1;
        return lnk.name;
      }
    });
    let data = {
      id: this.state.addResourceObj.id,
      links: linkCount > 0 ? newLinks : [],
    };

    let formData = new FormData();

    formData.append("jsonData", JSON.stringify(data));

    if (this.state.addedResource !== null) {
      for (const key of Object.keys(this.state.addedResource)) {
        formData.append("contents", this.state.addedResource[key]);
      }
    }

    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(
          URL.addExtraLMSResources,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", "Resources added successfully.");
              this.getSchoolLMS();
              this.toggleAddResource();
              this.setState({
                spinner: false,
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleUpdateClick = (item) => {
    this.setState({
      title: item.title,
      description: item.description,
      updateData: item,
      editMode: true,
    });
  };
  toggleUpdateModal = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  };

  handleSubmitUpdate = () => {
    let data = {
      id: this.state.updateData.id,
      title: this.state.title,
      description: this.state.description,
    };

    if (this.state.title === "" || this.state.description === "") {
      swal("Error", "Title and description cannot be empty.");
    } else {
      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(
            URL.updateSchoolLMS,
            data,
            (response) => {
              if (response.status === 200) {
                swal("Success", "Data updated successfully");
              }
              this.getSchoolLMS();
              this.toggleUpdateModal();

              this.setState({
                spinner: false,
              });
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                spinner: false,
              });
            }
          );
        }
      );
    }
  };

  handleLinkChange = (e, idx) => {
    let links = [...this.state.links];

    links[idx].name = e.target.value;

    this.setState({
      links: links,
    });
  };

  addLinkRow = () => {
    let links = [...this.state.links];
    links.push({
      name: "",
    });
    this.setState({ links });
  };

  removeLinkRow = (idx) => {
    let links = [...this.state.links];

    links.splice(idx, 1);
    this.setState({ links });
  };

  toggleAddModal = () => {
    this.setState({ addModal: !this.state.addModal });
  };

  handleDeleteReadingMaterials = () => {
    let params = {
      id: this.state.deleteId,
    };
    axiosPost(URL.deleteLMSData, params, (response) => {
      if (response.status === 200) {
        swal("Success", "Record Deleted Successfully.");
        this.getSchoolLMS();
        this.setState({ deleteReadingMaterials: false });
      }
    });
  };

  render() {
    const { title } = this.props;
    return (
      <>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="container-fluid">
          <div className="whiteContainer">
            <CustomPageHeader
              title={this.props.title}
              onClick={this.toggleAddModal}
              icon="add_box"
            />

            <table className="table cusTable">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Year Level</th>
                  <th>Subject</th>
                  <th>Description</th>
                  <th>Resources</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.lmsList.length > 0 ? (
                  this.state.lmsList.map((list) => (
                    <tr>
                      <td>{list.title}</td>
                      <td>{list.yearLevelName}</td>
                      <td>{list.subjectName || "--"}</td>
                      <td>{list.description}</td>
                      <td>
                        <button
                          className="btn btn-primary cusBtn cusBtnFontSize"
                          onClick={() => this.handleAddResource(list)}
                        >
                          Add Resources
                        </button>
                        <button
                          className="btn btn-primary cusBtn cusBtnFontSize"
                          onClick={() => this.handleViewResource(list)}
                        >
                          View Resources
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary cusBtn cusBtnFontSize"
                          onClick={() => this.handleUpdateClick(list)}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-primary cusBtn cusBtnFontSize"
                          onClick={() => {
                            this.toggleDeleteModal();
                            this.setState({ deleteId: list?.id });
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={6}>No records available!!</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <ModalWindow
          modal={this.state.fileViewModal}
          modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          size="lg"
          modalHeader="Resource Files"
          modalBody={
            <ViewLMSFile
              activeLMSFile={this.state.activeLMSFile}
              canDeleteResources={true}
              getSchoolLMS={this.getSchoolLMS}
            />
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.addResourceModal}
          toggleModal={this.toggleAddResource}
          modalHeader="Resource Files"
          size="lg"
          modalBody={
            <>
              <div className="row">
                <div className="col-md-3">
                  <strong>Links: </strong>
                </div>
                <div className="col-md-9">
                  {this.state.links.map((lnk, idx) => (
                    <div className="row mb-2">
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="name"
                          onChange={(e) => this.handleLinkChange(e, idx)}
                          className="form-control"
                          value={lnk.name}
                        />
                      </div>
                      <div className="col-md-2">
                        {idx > 0 ? (
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="removeMcqOption mr-2"
                            onClick={() => this.removeLinkRow(idx)}
                          />
                        ) : null}
                        {idx === this.state.links.length - 1 ? (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="addMcqOption"
                            onClick={() => this.addLinkRow()}
                          />
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <strong>Files: </strong>
                </div>
                <div className="col-md-9">
                  <DragDropFile
                    multiple={true}
                    handleChange={this.handleChange}
                    name="addedResource"
                    handleFileDrop={this.handleFileDrop}
                    files={this.state.addedResource}
                    // accept="image/png, image/jpeg, image/jpg"
                  />
                  {/* <input
                    type="file"
                    name="addedResource"
                    className="form-control cusInput"
                    onChange={this.handleChange}
                    multiple
                    style={{ padding: "2px" }}
                  /> */}
                </div>
              </div>

              <div className="text-right mt-2">
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize"
                  onClick={this.handleSubmitResources}
                >
                  Save
                </button>
              </div>
            </>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.editMode}
          toggleModal={this.toggleUpdateModal}
          modalHeader="Update Data"
          modalBody={
            <>
              {this.state.spinner ? (
                <div className="fullWindow-Spinner">
                  <div>
                    <Spinner color="white"></Spinner>
                  </div>
                  <div style={{ fontSize: "16px", marginTop: "15px" }}>
                    Please wait...
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-3">
                  <strong>
                    Title:{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="title"
                    className="form-control cusInput"
                    onChange={this.handleChange}
                    value={this.state.title}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <strong>Description: </strong>
                </div>
                <div className="col-md-9">
                  <textarea
                    name="description"
                    cols="5"
                    rows="5"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.description}
                  ></textarea>
                </div>
              </div>
              <div className="text-right">
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize mt-2"
                  onClick={this.handleSubmitUpdate}
                >
                  Update
                </button>
              </div>
            </>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.addModal}
          modalBody={
            <CentralReadingMaterialCreateModal
              lms={this.props.lms}
              getReadingMaterials={this.getSchoolLMS}
              toggleReadingModal={this.toggleAddModal}
            />
          }
          modalHeader={`Add ${title}`}
          toggleModal={this.toggleAddModal}
          size="lg"
        />
        <ModalWindow
          modal={this.state.deleteReadingMaterials}
          modalBody={
            <>
              <h4>Are you sure want to delete ?</h4>
              <button
                className="btn btn-primary cusBtn cusBtnFontSize"
                onClick={this.handleDeleteReadingMaterials}
              >
                Submit
              </button>
              <button
                className="btn btn-primary cusBtn cusBtnFontSize"
                onClick={this.toggleDeleteModal}
              >
                Cancel
              </button>
            </>
          }
          modalHeader={`Delete Curricula`}
          toggleModal={this.toggleDeleteModal}
          size="md"
        />
      </>
    );
  }
}

export default CentralReadingMaterials;
