import React, { Component } from "react";
import ModalWindow from "./../../../UI/ModalWindow";
import { checkRolePermissions } from "./../../../../utils/Constants";
import RoomInsertForm from "./RoomInsertForm";
import { Spinner } from "reactstrap";
class BuildingDetailList extends Component {
  state = {
    permissionEditRoom: false,
    permissionDeleteRoom: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditRoom: checkRolePermissions("edit-room", "activity"),
      permissionDeleteRoom: checkRolePermissions("delete-room", "activity"),
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "86vh" }}>
        {this.props.buildingDetailList.length > 0 ? (
          this.props.buildingDetailList.map((floor, idx) => (
            <div className="card mb-3" key={idx}>
              <div className="card-body">
                <div className="tt-group-header mb-2" key={idx}>
                  <tr>
                    <th colspan="5">FLOOR - {floor.name.toUpperCase()}</th>
                  </tr>
                </div>
                <div className="card">
                  <div className="card-body">
                    {floor.buildingSection ? (
                      floor.buildingSection.length > 0 ? (
                        floor.buildingSection.map((section, index) => (
                          <table
                            className="table table-striped table-bordered mb-2"
                            key={index}
                          >
                            <thead className="tt-group-header" key={index}>
                              <tr>
                                <th colspan="5">
                                  {this.props.sectionModalName
                                    ? this.props.sectionModalName.toUpperCase()
                                    : "Section"}{" "}
                                  {section.name.toUpperCase()}
                                </th>
                              </tr>
                            </thead>

                            <thead className="tt-group-header mb-2" key={index}>
                              <tr>
                                <th>Room Number</th>
                                <th>Room Name</th>
                                {/* <th>Floor Wings</th> */}
                                <th>Room Details</th>
                                {this.state.permissionEditRoom ||
                                this.state.permissionDeleteRoom ? (
                                  <th>Options</th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody key={index}>
                              {section.rooms.length > 0 ? (
                                section.rooms.map((room, roomIdx) => (
                                  <tr key={roomIdx}>
                                    <td>{room.number}</td>
                                    <td>{room.name}</td>
                                    {/* <td></td> */}
                                    <td width="40%">{room.description}</td>
                                    {this.state.permissionEditRoom ||
                                    this.state.permissionDeleteRoom ? (
                                      <td>
                                        {this.state.permissionEditRoom ? (
                                          <button
                                            className="tt-button tt-button-primary"
                                            onClick={(e) => {
                                              this.props.handleRoomUpdateForm(
                                                e
                                              );
                                              this.props.handleUpdateRoomClick(
                                                room.id,
                                                room.buildingId
                                              );
                                            }}
                                          >
                                            Edit
                                          </button>
                                        ) : null}
                                        {this.state.permissionDeleteRoom ? (
                                          <button
                                            className="tt-button tt-button-primary ml-2"
                                            onClick={(e) =>
                                              this.props.handledeleteRoom(
                                                room.id,
                                                room.buildingId
                                              )
                                            }
                                          >
                                            Delete
                                          </button>
                                        ) : null}
                                      </td>
                                    ) : null}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={4}>No Rooms</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        ))
                      ) : (
                        <div>No Section</div>
                      )
                    ) : (
                      <>
                        {floor.rooms.length > 0 ? (
                          <table className="table table-striped table-bordered mb-2">
                            <thead className="tt-group-header" key={idx}>
                              <tr>
                                <th>Room Number</th>
                                <th>Room Name</th>
                                {/* <th>Floor Wings</th> */}
                                <th width="40%">Room Details</th>
                                {this.state.permissionEditRoom ||
                                this.state.permissionDeleteRoom ? (
                                  <th>Options</th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody key={idx}>
                              {floor.rooms.length > 0 ? (
                                floor.rooms.map((room, roomIdx) => (
                                  <tr key={roomIdx}>
                                    <td>{room.number}</td>
                                    <td>{room.name}</td>
                                    {/* <td>{"No Wings"}</td> */}
                                    <td>{room.description}</td>
                                    {this.state.permissionEditRoom ||
                                    this.state.permissionDeleteRoom ? (
                                      <td>
                                        {this.state.permissionEditRoom ? (
                                          <button
                                            className="tt-button tt-button-primary"
                                            onClick={(e) => {
                                              this.props.handleRoomUpdateForm(
                                                e
                                              );
                                              this.props.handleUpdateRoomClick(
                                                room.id,
                                                room.buildingId
                                              );
                                            }}
                                          >
                                            Edit
                                          </button>
                                        ) : null}
                                        {this.state.permissionDeleteRoom ? (
                                          <button
                                            className="tt-button tt-button-primary ml-2"
                                            onClick={(e) =>
                                              this.props.handledeleteRoom(
                                                room.id,
                                                room.buildingId
                                              )
                                            }
                                          >
                                            Delete
                                          </button>
                                        ) : null}
                                      </td>
                                    ) : null}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={4}>No Rooms</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        ) : (
                          "No Rooms"
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <Spinner />
          </div>
        )}
        <ModalWindow
          size="lg"
          modal={this.props.modalRoomUpdateForm}
          toggleModal={this.props.handleRoomUpdateForm}
          modalHeader={"Update Rooms"}
          modalBody={
            <RoomInsertForm
              roomNumber={this.props.roomNumber}
              roomName={this.props.roomName}
              floor={this.props.floor}
              section={this.props.section}
              details={this.props.details}
              handleChange={this.props.handleChange}
              errors={this.props.errors}
              noOfFloors={this.props.noOfFloors}
              wings={this.props.wings}
              modalRoomUpdateForm={this.props.modalRoomUpdateForm}
              obj={this.props.obj}
              handleRoomUpdateSubmitClick={
                this.props.handleRoomUpdateSubmitClick
              }
              handleRoomUpdateForm={this.props.handleRoomUpdateForm}
              wings={this.props.wings}
              roomObject={this.props.roomObject}
              isAvailable={true}
            />
          }
        />
      </div>
    );
  }
}

export default BuildingDetailList;
