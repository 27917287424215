/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, SetupOne, is for the view of setup one porition of the System Setup Process
 */

import React, { Component } from "react";
// /import "../../assets/scss/academicYear.scss";
import EducationLevel from "./EducationLevel";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import DatePicker from "../UI/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Switch from "@material-ui/core/Switch";

class SetupOne extends Component {
  state = {
    countries: [],
    calendarType: [],
    checked: false,
  };
  componentDidMount() {
    //document.title = "School Information";
    axiosPost(URL.getCountry, {}, (response) => {
      this.setState({ countries: response.data.data });
    });
  }

  renderCountryLevels = () => {
    let index = -1;
    if (this.props.country !== undefined) {
      if (this.state.countries.length > 0) {
        index = this.state.countries.findIndex(
          (i) => i.id === parseInt(this.props.country)
        );
      }
    }
    if (
      this.state.countries[index] !== undefined &&
      this.state.countries[index] !== -1
    ) {
      if (this.state.countries[index].firstLevelDisplayName !== null) {
        return (
          <>
            {this.state.countries[index].firstLevelDisplayName !== null &&
            this.state.countries[index].secondLevelDisplayName !== "" ? (
              <div className="row mb-2">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <label>
                    <strong>
                      {this.state.countries[index].firstLevelDisplayName}
                    </strong>
                  </label>
                </div>
                <div className="col-md-6">
                  <select
                    name="firstLevelDisplayName"
                    className="form-control"
                    value={this.props.countryFirstLevelId}
                    onChange={(e) => this.props.handleChange(e)}
                  >
                    <option value="" selected disabled>
                      Choose a{" "}
                      {this.state.countries[index].firstLevelDisplayName}
                    </option>
                    {this.props.countryFirstLevel
                      ? this.props.countryFirstLevel.map((firstLevel, idx) => {
                          return (
                            <option key={idx} value={firstLevel.id}>
                              {firstLevel.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
            ) : null}
            {this.state.countries[index].secondLevelDisplayName !== null &&
            this.state.countries[index].secondLevelDisplayName !== "" ? (
              <div className="row mb-2">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <label>
                    <strong>
                      {this.state.countries[index].secondLevelDisplayName}
                    </strong>
                  </label>
                </div>
                <div className="col-md-6">
                  <select
                    name="secondLevelDisplayName"
                    className="form-control"
                    value={this.props.countrySecondLevelId}
                    onChange={(e) => this.props.handleChange(e)}
                  >
                    <option value="" selected disabled>
                      Choose a{" "}
                      {this.state.countries[index].secondLevelDisplayName}
                    </option>
                    {this.props.countrySecondLevel
                      ? this.props.countrySecondLevel.map(
                          (secondLevel, idx) => {
                            return (
                              <option key={idx} value={secondLevel.id}>
                                {secondLevel.name}
                              </option>
                            );
                          }
                        )
                      : null}
                  </select>
                </div>
              </div>
            ) : null}
            {this.state.countries[index].thirdLevelDisplayName !== null &&
            this.state.countries[index].thirdLevelDisplayName !== "" ? (
              <div className="row mb-2">
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <label>
                    <strong>
                      {this.state.countries[index].thirdLevelDisplayName}
                    </strong>
                  </label>
                </div>
                <div className="col-md-6">
                  <select
                    name="thirdLevelDisplayName"
                    className="form-control"
                    value={this.props.countryThirdLevelId}
                    onChange={(e) => this.props.handleChange(e)}
                  >
                    <option selected disabled>
                      Choose a{" "}
                      {this.state.countries[index].thirdLevelDisplayName}
                    </option>
                    {this.props.countryThirdLevel
                      ? this.props.countryThirdLevel.map((thirdLevel, idx) => {
                          return (
                            <option key={idx} value={thirdLevel.id}>
                              {thirdLevel.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
            ) : null}
          </>
        );
      }
    }
  };

  render() {
    let errors = this.props.errors;
    let renderErrorText = this.props.renderErrorText;
    return (
      <>
        <div className="tt-group-header tt-systemSetupTwo">
          {"General Information"}
        </div>
        <div>
          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="schoolName">
                <strong>Name of the school</strong>
              </label>
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                className={
                  errors.nameOfTheSchool
                    ? "form-control indicate-error"
                    : "form-control"
                }
                name="nameOfTheSchool"
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.nameOfTheSchool}
                maxLength="255"
                id="nameOfSchool"
              />
              {/* <sup className="optionalSup">*</sup> */}
            </div>
            <div className="col-sm-2">
              <input
                type="text"
                className="form-control"
                name="acronym"
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.acronym}
                id="acronym"
                placeholder="Acronym"
              ></input>
              {/* <sup className="optionalSup">*</sup> */}
            </div>
            <div className="col-sm-1">
              {errors.nameOfTheSchool ? renderErrorText() : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="schoolName">
                <strong>School Logo</strong>
              </label>
            </div>
            <div className="col-sm-4">
              <input
                type="file"
                name="photo"
                style={{ color: "transparent" }}
                accept="image/png, image/jpeg"
                // title=" "
                onChange={(e) => this.props.handleFileChange(e)}
              />
              <label htmlFor="fileName">
                {this.props.photo.name ? this.props.photo.name : ""}
              </label>

              <sup className="optionalSup">*</sup>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="schoolName">
                <strong>Logo position</strong>
              </label>
            </div>
            <div className="col-sm-4">
              <span className="ml-3 mr-2">Bottom</span>
              <Switch
                checked={this.props.switchToggle}
                onClick={(e) => this.props.handleSwitchChange(e)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span className="ml-3 mr-2">Top</span>
              <sup className="optionalSup">*</sup>
            </div>

            <div className="col-sm-1">
              {errors.nameOfTheSchool ? renderErrorText() : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="schoolName">
                <strong>Date of establishment</strong>
              </label>
            </div>
            <div className="col-sm-6">
              <div>
                <DatePicker
                  DOE={this.props.DOE}
                  errors={errors}
                  renderErrorText={renderErrorText}
                  handleDateChange={this.props.handleDateOfEstablishmentChange}
                />
              </div>
              {/* <sup className="optionalSup">*</sup> */}
              <input
                type="date"
                name="doe"
                className="date-picker"
                min={"1965-01-01"}
                max={new Date().toISOString().substring(0, 10)}
                // value={new Date(
                //   `${this.props.DOE.year}-${this.props.DOE.month}-${this.props.DOE.day}`
                // )
                //   .toISOString()
                //   .substring(0, 10)}
                onChange={this.props.handleDateChange}
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="date-fa" />
            </div>
            {/* <div className="col-sm-2">
                            <input
                                type="text"
                                className="form-control"
                                name="year"
                                onChange={e => this.props.handleChange(e)}
                                value={this.props.DOE.year}
                                placeholder="Year"
                            />
                            <sup>*</sup>
                        </div>
                        <div className="col-sm-2">
                            <input
                                type="text"
                                className="form-control"
                                name="month"
                                onChange={e => this.props.handleChange(e)}
                                value={this.props.DOE.month}
                                placeholder="Month"
                            />
                        </div>
                        <div className="col-sm-2">
                            <input
                                type="text"
                                className="form-control"
                                name="day"
                                onChange={e => this.props.handleChange(e)}
                                value={this.props.DOE.day}
                                placeholder="Day"
                            />
                        </div> */}
            <div className="col-sm-1">
              {errors.dateOfEstablishment.day ? renderErrorText() : null}
            </div>
          </div>

          {/* website */}
          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="country">
                <strong>School website</strong>
              </label>
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                className={
                  errors.website
                    ? "form-control indicate-error"
                    : "form-control"
                }
                name="website"
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.website}
                maxLength="255"
                id="website"
                placeholder="www.example.com"
              />

              {/* <sup className="optionalSup">*</sup> */}
            </div>
            <div className="col-sm-1">
              {" "}
              {errors.website ? renderErrorText() : null}
            </div>
          </div>

          {/* // pan/vat */}
          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="country">
                <strong>PAN/VAT No.</strong>
              </label>
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                className={
                  errors.panNo ? "form-control indicate-error" : "form-control"
                }
                name="panNo"
                placeholder="PAN No./ VAT No."
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.panNo}
                maxLength="255"
                id="panNo"
              />
              {/* <sup className="optionalSup">*</sup> */}
            </div>
            <div className="col-sm-1">
              {" "}
              {errors.country ? renderErrorText() : null}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="country">
                <strong>Country</strong>
              </label>
            </div>
            <div className="col-sm-6">
              <select
                className={
                  errors.country
                    ? "form-control indicate-error"
                    : "form-control"
                }
                id="country"
                name="country"
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.country}
              >
                <option value="" disabled selected>
                  Choose a country
                </option>
                {this.state.countries.map((country) => (
                  <option value={country.id}>{country.name}</option>
                ))}
              </select>
              {/* <sup className="optionalSup">*</sup> */}
            </div>
            <div className="col-sm-1">
              {" "}
              {errors.country ? renderErrorText() : null}
            </div>
          </div>
          {this.renderCountryLevels()}
          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="calender">
                <strong>Calendar</strong>
              </label>
            </div>
            <div className="col-sm-6">
              <select
                className={
                  errors.calendar
                    ? "form-control indicate-error"
                    : "form-control"
                }
                name="calendar"
                defaultValue={this.props.calendar}
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.calendar}
              >
                <option value="" disabled selected>
                  Choose a calendar type
                </option>
                {this.props.calendarType.map((calendar) => (
                  <option value={calendar.id}>{calendar.name}</option>
                ))}
              </select>
              {/* <sup className="optionalSup">*</sup> */}
            </div>
            <div className="col-sm-1">
              {errors.calendar ? renderErrorText() : null}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4">
              <label htmlFor="academicYearDivision">
                <strong>Academic year division</strong>
              </label>
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                className={
                  errors.academicYearDivision
                    ? "form-control indicate-error"
                    : "form-control"
                }
                name="academicYearDivision"
                max={255}
                onChange={(e) =>
                  this.props.handleSuggestionsChange(
                    e,
                    this.props.baseAcademicSuggestions,
                    "academicSuggestions"
                  )
                }
                value={this.props.academicYearDivision}
                maxLength="255"
              />
              {/* <sup className="optionalSup">*</sup> */}
            </div>
            <div className="col-sm-1">
              {errors.academicYearDivision ? renderErrorText() : null}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-5"></div>
            <div className="col-sm-5 tt-inline">
              <span className="text-muted">Suggestion:&nbsp;</span>
              <div className="tt-horizontal">
                {/*Dynamically Map out the "academicSuggestions" array with respect to its length */}

                {this.props.academicSuggestions.map((sugg, idx) => {
                  let len = this.props.academicSuggestions.length;
                  return (
                    <div key={idx}>
                      <a
                        href="!#"
                        onClick={(e) =>
                          this.props.universalSuggestionHandler(
                            e,
                            idx,
                            "academicYearDivision",
                            this.props.academicSuggestions,
                            this.props.baseAcademicSuggestions,
                            "this.props.academicSuggestions"
                          )
                        }
                      >
                        {sugg}
                        {idx !== len - 1 ? "," : ""}
                      </a>
                      &nbsp;
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <EducationLevel
            handleDefaultEducationLevels={
              this.props.handleDefaultEducationLevels
            }
            renderErrorText={renderErrorText}
            levels={this.props.levels}
            errors={errors}
            suggestions={this.props.suggestions}
            handleDynamicFieldSuggestion={
              this.props.handleDynamicFieldSuggestion
            }
            removeLevel={this.props.removeLevel}
            addLevel={this.props.addLevel}
            handleLevelsChange={this.props.handleLevelsChange}
          />
          {/*Part for School Grouping Section in the System Setup Portion 1 Starts from Here */}
          <div className="card mt-4">
            <div className="card-body">
              <div className="tt-group-header" style={{ marginBottom: "15px" }}>
                School Grouping (Naming Conventions)
              </div>
              <div className="row">
                <div className="col-sm-1"></div>
                <div className="col-sm-4 tt-paddingLeft">
                  <label htmlFor="yearLevel">
                    <strong>Year Level</strong>
                  </label>
                </div>
                <div className="col-sm-6 tt-paddingRight">
                  <input
                    type="text"
                    className={
                      errors.yearLevel
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    name="yearLevel"
                    onChange={(e) =>
                      this.props.handleSuggestionsChange(
                        e,
                        this.props.baseYearSuggestions,
                        "yearSuggestions"
                      )
                    }
                    value={this.props.yearLevel}
                    maxLength="255"
                  />
                  {/* <sup className="optionalSup">*</sup> */}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-5"></div>
                <div className="col-sm-5 tt-inline">
                  <span className="text-muted">Suggestion:&nbsp;</span>
                  <div className="tt-horizontal">
                    {/*Dynamically Map out the "yearSuggestions" array with respect to its length */}
                    {this.props.yearSuggestions.map((sugg, idx) => {
                      let len = this.props.yearSuggestions.length;
                      return (
                        <div key={idx}>
                          <a
                            href="!#"
                            onClick={(e) =>
                              this.props.universalSuggestionHandler(
                                e,
                                idx,
                                "yearLevel",
                                this.props.yearSuggestions,
                                this.props.baseYearSuggestions,
                                "this.props.yearSuggestions"
                              )
                            }
                          >
                            {sugg}
                            {idx !== len - 1 ? "," : ""}
                          </a>
                          &nbsp;
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-sm-2">
                  {errors.yearLevel ? renderErrorText() : null}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-1"></div>
                <div className="col-sm-4 tt-paddingLeft">
                  <label htmlFor="classroon">
                    <strong>Classroom</strong>
                  </label>
                </div>
                <div className="col-sm-6 tt-paddingRight">
                  <input
                    type="text"
                    className={
                      errors.classroom
                        ? "form-control indicate-error"
                        : "form-control"
                    }
                    name="classroom"
                    onChange={(e) =>
                      this.props.handleSuggestionsChange(
                        e,
                        this.props.baseClassSuggestions,
                        "classSuggestions"
                      )
                    }
                    value={this.props.classroom}
                    maxLength="255"
                  />
                  {/* <sup className="optionalSup">*</sup> */}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-5"></div>
                <div className="col-sm-5 tt-inline">
                  <span className="text-muted">Suggestion:&nbsp;</span>
                  <div className="tt-horizontal">
                    {/*Dynamically Map out the "classSuggestions" array with respect to its length */}
                    {this.props.classSuggestions.map((sugg, index) => {
                      let len = this.props.classSuggestions.length;
                      return (
                        <div key={index}>
                          <a
                            href="!#"
                            onClick={(e) =>
                              this.props.universalSuggestionHandler(
                                e,
                                index,
                                "classroom",
                                this.props.classSuggestions,
                                this.props.baseClassSuggestions,
                                "this.props.classSuggestions"
                              )
                            }
                          >
                            {sugg}
                            {index !== len - 1 ? "," : ""}
                          </a>
                          &nbsp;
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-sm-2">
                  {errors.classroom ? renderErrorText() : null}
                </div>
              </div>
            </div>
          </div>
          {/*Part for School Grouping Section in the System Setup Portion 1 Ends Here */}

          <div className="row">
            <div className="col-md-10"></div>
            <div className="col-md-2 mt-4 text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.changeToNextIndex}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SetupOne;
