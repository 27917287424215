import React from "react";

function AttendanceSubjectList(props) {
  return (
    <React.Fragment>
      <div
        className="tt-group-header"
        style={{ borderRadius: 0, textAlign: "center" }}
      >
        Subjects
      </div>
      <div className="accountStudentContainer pt-0">
        <div className="accountStudentHolder">
          {props.subjects.length > 0 ? (
            props.subjects.map((sub, idx) => {
              return (
                <div
                  key={idx}
                  style={
                    idx === props.subjects.length - 1
                      ? { borderBottom: "none", cursor: "pointer" }
                      : { cursor: "default" }
                  }
                  className={
                    "row accountStudentRow " +
                    (sub.isActive ? "activeAccountStudentRow" : "")
                  }
                  onClick={() => props.handleActiveStatus(idx)}
                >
                  <div className="col-md-auto">
                    <input
                      type="checkbox"
                      className="form-control"
                      name="isClassRoutine"
                      // checked={sub.isClassRoutine}
                      checked={sub.isClassRoutine || sub.attendanceStatus}
                      onChange={(e) => props.handleSubjectSelect(e, idx)}
                    ></input>
                  </div>
                  <div className="col">
                    <label style={{ cursor: "pointer" }}>{sub.name}</label>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center">
              <strong>No Subjects Available</strong>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AttendanceSubjectList;
