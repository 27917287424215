/**
 * @author Azzeta Karmacharya
 */
import React, { Component } from "react";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";

export default class WeekDays extends Component {
  state = {
    days: [],
  };
  componentDidMount() {
    this.getWeekDays();
  }
  getWeekDays = () => {
    axiosPost(URL.getWeekDays, {}, (response) => {
      if (response.status === 200) {
        this.setState({ days: response.data.data });
      }
    });
  };

  render() {
    return (
      <div className="tt-weekdaysDisplay">
        {this.state.days.map((day, i) => {
          return (
            <div className="form-check form-check-inline" key={i}>
              <input
                className="form-check-input"
                type="checkbox"
                id={i}
                checked={
                  this.props.checkValueExitsInArray(
                    this.props.checkedList,
                    "id",
                    day.id
                  ) !== -1
                    ? true
                    : false
                }
                value={day.id}
                onChange={(e) =>
                  this.props.handleWeekDays(
                    e,
                    this.props.parentIndex,
                    this.props.termIndex,
                    this.props.checkedList
                  )
                }
              />
              <label className="form-check-label" htmlFor={i}>
                {day.name}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
}
