import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL, checkWidgetAdminOrNot } from "../../../../utils/Constants";
import StudentList from "./StudentList";
import SelectedStudentList from "./SelectedStudentList";
import DropDownList from "./DropDownList";
import { displaySuccessAlert, showErroMessage } from "../../../../utils/Utils";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import swal from "sweetalert";
import NoteDisplay from "../../../../utils/noteDisplay";

class StudentClassroomOptionalSubject extends Component {
  state = {
    admissionList: [],
    educationLevels: [],
    educationLevelDivisions: [],
    educationLevelDivision: "",
    educationLevel: "",
    yearLevels: [],
    yearLevel: null,
    classroomList: [],
    classroom: null,
    admissionId: null,
    allStudentChecked: false,
    selectedStudent: [],
    selectedSubjectList: [],
    subjectList: [],
    isAvailable: false,
    assignEducationlevel: true,
    render: false,
  };

  componentDidMount() {
    // this.getAdmissionList();
    this.getEducationLevelByAssessmentModule();
    // this.getEducationLevelDivisions();
  }

  getEducationLevelByAssessmentModule = () => {
    let data = {
      code: this.props.code,
    };
    axiosPost(
      URL.getEducationLevelByAssessmentModule,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState({ educationLevels: response.data.data }, () => {
            if (this.state.educationLevels.length === 1) {
              this.setState(
                { educationLevel: this.state.educationLevels[0].id },
                function () {
                  this.getEducationLevelDivisions();
                }
              );
            } else {
              this.setState({ assignEducationlevel: false });
            }
          });
        } else {
          this.setState({ render: true });
        }
        if (response.status === 204) {
          this.setState({ assignEducationlevel: false });
        }
      },
      (err) => {
        this.setState({ render: true });
      }
    );
  };
  // getAdmissionList = () => {
  //   axiosPost(URL.admissionList, {}, (response) => {
  //     if (response.status === 200) {
  //       this.setState({ admissionList: response.data.data });
  //     }
  //   });
  // };

  getEducationLevelDivisions = () => {
    const param = {
      educationLevelId: this.state.educationLevel,
    };
    axiosPost(
      URL.getEducationLevelDivisions,
      param,
      (response) => {
        if (response.status === 200) {
          this.setState(
            { educationLevelDivisions: response.data.data },
            function () {
              this.checkAvailable();
            }
          );
        } else {
          this.checkAvailable();
        }
      },
      (err) => {
        this.checkAvailable();
      }
    );
  };

  checkAvailable = () => {
    if (this.state.educationLevelDivisions.length > 0) {
      this.setState({ render: true, isAvailable: true });
    } else {
      this.setState({ render: true });
    }
  };

  getYearLevelByEducationLevel = () => {
    // const param = this.state.educationLevel;
    let data = {
      educationLevelId: this.state.educationLevel,
      assessmentModuleCode: this.props.code,
      educationLevelDivisionId: this.state.educationLevelDivision,
    };
    axiosPost(URL.getYearLevelByEducationLevel, data, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data }, () => {});
      }
    });
  };

  universalHandleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      if (name === "educationLevelDivision") {
        this.setState({ yearLevels: [], yearLevel: null }, () => {
          this.getYearLevelByEducationLevel();
        });
      } else if (name === "yearLevel") {
        this.setState(
          {
            classroomList: [],
            classroom: null,
            admissionId: null,
            selectedStudent: [],
            subjectList: [],
          },
          () => {
            this.getClassroomList();
            this.getSubjectList();
          }
        );
      } else if (name === "classroom") {
        this.setState({ selectedStudent: [] }, () => {
          this.getStudentList();
        });
      }
    });
  };

  getClassroomList = () => {
    let param = {
      yearLevelId: this.state.yearLevel,
    };
    axiosPost(URL.selectClassroom, param, (response) => {
      if (response.status === 200) {
        this.setState({ classroomList: response.data.data });
      }
    });
  };

  getStudentList = () => {
    let param = {
      yearLevelId: this.state.yearLevel,
      classroomId: this.state.classroom,
    };
    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status === 200) {
        this.setState({ admissionList: response.data.data }, () => {
          let studentList = [...this.state.admissionList];
          studentList.forEach((student) => {
            if (student.people !== null) {
              student.checked = false;
            }
          });
          this.setState({ admissionList: studentList });
        });
      }
    });
  };

  getSubjectList = () => {
    let param = {
      assessmentModuleCode: this.props.code,
      yearLevelId: this.state.yearLevel,
      educationLevelId: this.state.educationLevel,
      educationLevelDivisionId: this.state.educationLevelDivision,
      isCompulsory: false,
    };
    axiosPost(URL.getSubjectsByYearLevel, param, (response) => {
      if (response.status === 200) {
        this.setState({ subjectList: response.data.data });
      }
    });
  };

  handleStudentCheckbox = (e, idx) => {
    let admissionList = [...this.state.admissionList];
    let selectedStudent = [...this.state.selectedStudent];
    if (idx === "all") {
      admissionList.forEach((student) => {
        student.checked = e.target.checked;
      });
      this.setState({ allStudentChecked: false });
    } else {
      admissionList[idx].checked = e.target.checked;
    }
    admissionList.forEach((student) => {
      if (student.checked === true) {
        selectedStudent.push(student);
      }
    });
    if (idx === "all") {
      admissionList = [];
    } else {
      admissionList.splice(idx, 1);
    }
    selectedStudent.forEach((el) => {
      el.checked = false;
    });
    this.setState({ admissionList, selectedStudent });
  };

  removeSelectedStudent = (e, idx) => {
    e.preventDefault();
    let selectedStudent = [...this.state.selectedStudent];
    let admissionList = [...this.state.admissionList];
    admissionList.push(selectedStudent[idx]);
    selectedStudent.splice(idx, 1);
    this.setState({ selectedStudent, admissionList });
  };

  multipleSubjectSelectHandler = (e) => {
    let subjectList = [...this.state.subjectList],
      subjectIds = this.handleChangeSelect(e);
    let selectedSubjectList = [];
    subjectIds.forEach((id) => {
      subjectList.forEach((subject) => {
        if (subject.subjectId === parseInt(id)) {
          selectedSubjectList.push(subject);
        }
      });
    });
    this.setState({ selectedSubjectList });
  };

  handleChangeSelect = (e) => {
    var options = e.target.options;
    var selectedValues = [];
    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    return selectedValues;
  };

  handleStudentOptionalSubjectSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.selectedStudent.length > 0 &&
      this.state.classroom &&
      this.state.selectedSubjectList.length > 0
    ) {
      let param = {
        students: this.state.selectedStudent,
        classroomId: this.state.classroom,
        yearLevelId: this.state.yearLevel,
        subjects: this.state.selectedSubjectList,
        educationLevelId: this.state.educationLevel,
        educationLevelDivisionId: this.state.educationLevelDivision,
      };
      axiosPost(
        URL.insertStudentClassroomOptionalSubject,
        param,
        (response) => {
          if (response.status === 200) {
            displaySuccessAlert(response);
            this.setState({
              educationLevelDivision: "",
              classroomList: [],
              classroom: null,
              admissionList: [],
              selectedStudent: [],
              selectedSubjectList: [],
              yearLevels: [],
              subjectList: [],
            });
          } else {
            showErroMessage(response);
          }
        }
      );
    } else {
      swal("Error", "Select the inputs properly");
    }
  };
  render() {
    return this.state.render ? (
      <>
        {this.state.isAvailable ? (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            <div className="tt-group-header">
              Create Student Optional Subjects
              {checkWidgetAdminOrNot(this.props.name) === true ? (
                <button
                  className="tt-button tt-button-primary float-right permissionBtnCSS"
                  onClick={() => {
                    this.setState({
                      permissionModal: !this.state.permissionModal,
                    });
                  }}
                >
                  Permissions
                </button>
              ) : null}
            </div>
            <div className="tt-newListBox container-fluid">
              <DropDownList
                universalHandleChange={this.universalHandleChange}
                {...this.state}
                multipleSubjectSelectHandler={this.multipleSubjectSelectHandler}
              />

              <hr />
              <div className="row mt-3">
                <div className="col-md-6">
                  <StudentList
                    studentList={this.state.admissionList}
                    allStudentChecked={this.state.allStudentChecked}
                    handleStudentCheckbox={this.handleStudentCheckbox}
                  />
                </div>
                <div className="col-md-6">
                  <SelectedStudentList
                    selectedStudent={this.state.selectedStudent}
                    removeSelectedStudent={this.removeSelectedStudent}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col text-right button-bg-color">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.handleStudentOptionalSubjectSubmit}
                    disabled={
                      this.state.selectedStudent.length === 0 ||
                      this.state.selectedSubjectList.length === 0
                    }
                  >
                    Assign Students
                  </button>
                </div>
              </div>
            </div>
            <ModalWindow
              modal={this.state.permissionModal}
              toggleModal={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
              modalHeader={"Assign permission to user"}
              size="lg"
              id="tt-permissionModal"
              modalBody={
                <GranularPermissionModal
                  widgetName={this.props.name}
                  moduleName="Subjects Setup"
                  header="Create Student Optional Subjects"
                  activityName="insert-student-optional-subjects"
                />
              }
            ></ModalWindow>
          </div>
        ) : (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            {this.state.assignEducationlevel ? (
              <NoteDisplay
                item="optional subjects"
                dependentItem={
                  localStorage.getItem("educationLevelDivision")
                    ? localStorage.getItem("educationLevelDivision")
                    : "Term"
                }
              ></NoteDisplay>
            ) : (
              <NoteDisplay
                textContent={
                  "Please assign education level to " +
                  this.props.name +
                  " first. Then add optional subjects"
                }
              ></NoteDisplay>
            )}
          </div>
        )}
      </>
    ) : (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      ></div>
    );
  }
}

export default StudentClassroomOptionalSubject;
