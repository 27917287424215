import { faSearchMinus, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function ReportMagnifier(props) {
  return (
    <div className="tt-reportMagnifier">
      <div onClick={props.handleZoomIn}>
        <FontAwesomeIcon icon={faSearchPlus} />
      </div>
      <div onClick={props.handleZoomOut}>
        <FontAwesomeIcon icon={faSearchMinus} />
      </div>
    </div>
  );
}

export default ReportMagnifier;
