import React, { useState } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { postType, URL } from "../../utils/Constants";
import moment from "moment";
import { Link } from "react-router-dom";
import ModalWindow from "../../UI/ModalWindow";
import CustomButton from "../../components/common/CustomButton";
import NoticeFileViewer from "./NoticeFileViewer";
import { useEffect } from "react";
import NavBar from "../../components/FrontPage/NavBar";
import { displayErrorMessage, displayMessage } from "../../utils/Utils";
import JitsiMeet from "../../components/VideoConferencing/jitsiMeet";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";

const ProfileNotices = (props) => {
  const [activeNoticeFile, setActiveNoticeFile] = useState([]);
  const [resourceModal, setResourceModal] = useState(false);
  const [activeNotice, setActiveNotice] = useState(null);
  const [noticeList, setNoticeList] = useState([]);
  const [roomId, setRoomId] = useState(null);
  const [userName, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [jitsiModel, setJitsiModel] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [videoConferenceId, setVideoConferenceId] = useState(null);

  useEffect(() => {
    getAllNoticeList();
  }, []);

  const getAllNoticeList = () => {
    axiosPost(URL.getNoticeCore, {}, (response) => {
      if (response.status === 200) {
        // setNoticeList(response.data.data);
        const notices = response.data.data;
        let allPromises = [];
        notices.forEach(async (notice, idx) => {
          if (notice.postType === postType.videoConference) {
            let param = {
              postId: notice.id,
              profilePeopleId: localStorage.getItem("peopleId"),
            };
            let selectVideoConferencePostPromise = new Promise(
              (resolve, reject) => {
                axiosPost(URL.videoConferenceSelectByPostId, param, (res) => {
                  if (res.status === 200) {
                    resolve(res.data.data);
                  }
                });
              }
            );
            allPromises.push(selectVideoConferencePostPromise);
            let replaceData = await selectVideoConferencePostPromise;
            let startDateTime = replaceData?.startDateTime
              ? moment(replaceData?.startDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;
            let endDateTime = replaceData?.endDateTime
              ? moment(replaceData?.endDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;

            notice.heading = notice.heading
              ? notice.heading.replace(
                  "[sessionName]",
                  replaceData?.sessionName
                )
              : null;
            notice.textContent = notice.textContent.replace(
              "[peopleName]",
              replaceData?.peopleName
            );
            notice.textContent = notice.textContent.replace(
              "[message]",
              replaceData?.message
            );
            notice.textContent = notice.textContent.replace(
              "[sessionName]",
              replaceData?.sessionName
            );
            notice.textContent = notice.textContent.replace(
              "[startDateTime]",
              startDateTime
            );
            notice.textContent = notice.textContent.replace(
              "[endDateTime]",
              endDateTime
            );
            notice.textContent = notice.textContent.replace(
              "[hostName]",
              replaceData?.hostName
            );
          }
        });
        Promise.all(allPromises).then(() => {
          setNoticeList(notices);
        });
      }
    });
  };
  const handleViewResources = (notice) => {
    setActiveNotice(notice);
    setActiveNoticeFile(notice.contents);
    setResourceModal(true);
  };

  const toggleFileViewer = () => {
    setResourceModal(!resourceModal);
  };

  const toggleJitsiModal = () => {
    setJitsiModel(!jitsiModel);
  };

  const handleJoinConference = (id) => {
    let param = {
      postId: id,
      joinRequest: true,
    };
    axiosPost(
      URL.videoConferenceSelectByPostId,
      param,
      (response) => {
        if (response.status === 200 && response.data != null) {
          const roomId = response.data.data.jitsiRoomId;
          const userName = response.data.data.peopleName;
          const password = response.data.data.password;
          const createdByPeopleId = response.data.data.createdByPeopleId;
          const hasStarted = response.data.data.hasStarted;
          const isHost =
            localStorage.getItem("peopleId") == createdByPeopleId
              ? true
              : false;
          if (isHost && !hasStarted) {
            //update video conference
            let updateParam = response.data.data;
            updateParam.hasStarted = true;
            axiosPost(URL.videoConferenceUpdate, updateParam, (response) => {});
          }
          if (isHost || hasStarted) {
            setRoomId(roomId);
            setUsername(userName);
            setPassword(password);
            setIsHost(isHost);
            setVideoConferenceId(id);
            password !== "" && password !== null
              ? toggleJitsiModal()
              : alert("Password is Required to join the room");
          } else {
            displayMessage(
              "Contact Host Provider",
              "Video Conference has not been started by the host"
            );
          }
        }
      },
      (err) => {
        displayErrorMessage(err);
      }
    );
  };

  return (
    <>
      <NavBar
        noNav={true}
        teacherPage={props.location?.state?.teacherPage}
        classPage={props.location?.state?.classPage}
      />
      <section className="white-background">
        <div className="container">
          <h3 className="heading-03">
            {props.lang === "nameEn" ? "Notices" : "सूचनाहरू"}
          </h3>
          <div className="horizontal-line mb-3"></div>
          <div>
            <div className="noticeBox-container row">
              {noticeList &&
                noticeList?.slice(0, 6)?.map((items, indx) => {
                  return (
                    <div className="col-md-6">
                      <div className="noticeBox">
                        <div className="notice-date">
                          <div className="text-center">
                            <span
                              className="material-icons-round loginIcon mt-2"
                              aria-hidden="true"
                            >
                              calendar_month
                            </span>
                            <br />
                            {moment(items.createdAt).format("ll")}
                          </div>
                        </div>
                        <div className="notice-data">
                          <div>
                            <p className="truncateLines-2">{items.heading}</p>

                            <small className="truncateLines-2">
                              {ReactHtmlParser(items.textContent)}
                            </small>
                          </div>
                          <div>
                            {items.contents && items.contents.length > 0 ? (
                              <button
                                className="tt-button tt-button-primary mr-3"
                                onClick={(e) => handleViewResources(items)}
                              >
                                View Resource
                              </button>
                            ) : null}
                            {items.postType === postType.videoConference ? (
                              <button
                                className="tt-button tt-button-primary mr-3"
                                onClick={(e) => handleJoinConference(items.id)}
                              >
                                Join
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {noticeList?.length > 0 ? (
              <div className="text-right mt-2">
                <Link
                  to={{
                    pathname: "/all-notices",
                    state: {
                      noNav: true,
                      teacherPage: props.location?.state?.teacherPage,
                      classPage: props.location?.state?.classPage,
                    },
                  }}
                >
                  <CustomButton
                    text="View All Notices"
                    customStyle="cusBtnFontSize"
                  ></CustomButton>
                </Link>
              </div>
            ) : (
              <div className="text-center">No notices published.</div>
            )}
          </div>
        </div>
        <ModalWindow
          modal={resourceModal}
          modalClass="fullModal"
          toggleModal={toggleFileViewer}
          size="lg"
          modalHeader={activeNotice?.heading}
          modalBody={
            <>
              <p className="py-3">
                {ReactHtmlParser(activeNotice?.textContent)}
              </p>
              {activeNoticeFile.length > 0 && (
                <NoticeFileViewer activeNoticeFile={activeNoticeFile} />
              )}
            </>
          }
        />

        {roomId && userName && password && (
          <ModalWindow
            modal={jitsiModel}
            toggleModal={toggleJitsiModal}
            modalHeader="Jitsi Video Conferencing for Imagine Inquiry Sys"
            size="xl"
            modalBody={
              <JitsiMeet
                isHost={isHost}
                getMeetingId={roomId}
                getDispNme={userName}
                toggleModal={toggleJitsiModal}
                getPassword={password}
                videoConferenceId={videoConferenceId}
              ></JitsiMeet>
            }
          ></ModalWindow>
        )}
      </section>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(ProfileNotices);
