/**
 * @author Ashok
 */

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
class IncreaseDecreaseTerm extends Component {
  state = {};
  render() {
    return (
      <div className="row">
        <div className="form-group col-md-2">
          <div
            className="ttbutton-icon"
            id="decrease"
            value="Decrease Value"
            name="numberOfTerms"
            onClick={(e) => this.props.decreaseValue(this.props.idx)}
          >
            <FontAwesomeIcon
              icon={faMinusSquare}
              size="1x"
              className="icon-minus"
            />
          </div>
        </div>
        <div className="col-md-3">
          <input
            type="number"
            id="number"
            value={this.props.numberOfTerms}
            min="1"
            max="6"
            onChange={this.props.handleSemesterChange}
            required
            disabled={this.props.inputEnabled ? false : true}
            name="numberOfTerms"
            style={{ background: "white" }}
          />
        </div>
        <div className="form-group col-md-1">
          <div
            className="ttbutton-icon"
            id="increase"
            value="Increase Value"
            onClick={(e) => this.props.increaseValue(this.props.idx)}
          >
            <FontAwesomeIcon
              icon={faPlusSquare}
              size="1x"
              className="icon-minus"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default IncreaseDecreaseTerm;
