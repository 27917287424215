import React, { Component } from "react";
import MenuForm from "./MenuForm";
import MenuList from "./MenuList";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import CustomSpinner from "../../components/common/CustomSpinner";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";
class Menu extends Component {
  state = {
    menus: [],
    modal: false,
  };
  componentDidMount() {
    this.getAllMenu();
  }

  getAllMenu = () => {
    axiosPost(URL.getAllMenu, {}, (response) => {
      if (response.status == 200) {
        this.setState({
          menus: response.data.data,
        });
      }
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Menu Lists"
                onClick={this.toggleModal}
                icon="add_box"
                noPermission={true}
              />
              <MenuList
                subMenu={false}
                menus={this.state.menus}
                getAllMenu={this.getAllMenu}
              />
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          modalHeader="Add Menu"
          size="lg"
          modalBody={
            <MenuForm
              getAllMenu={this.getAllMenu}
              toggleModal={this.toggleModal}
            />
          }
        />
      </div>
    );
  }
}

export default Menu;
