import React, { Component } from "react";
import download from "../../../assets/images/cardTemplates/download.png";
import signature from "../../../assets/images/cardTemplates/signature.PNG";
import schoolLogo from "../../../assets/images/logo/logo2.png";

class Template2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // console.log(this.props.emergencyContact);
  }
  render() {
    return (
      <div className="template2Container">
        <div className="padding">
          <div className="template2Front">
            <small className="schoolWeb">
              {this.props.schoolWebAddress ? this.props.schoolWebAddress : ""}
            </small>
            <div className="cardInfoHolder">
              <div className="cDetailHolder">
                <p className="cDetailName">
                  <strong>
                    {this.props.studentName
                      ? this.props.studentName
                      : "People Name"}
                  </strong>
                </p>
                <p className="cClassDetail">
                  {this.props.subtitle
                    ? this.props.subtitle
                    : "Class - Section / Designation"}
                </p>
              </div>
              <div className="cImgHolder">
                <img
                  src={this.props.userImage ? this.props.userImage : download}
                />
              </div>
            </div>
            <div className="template2Bottom">
              <div className="d-flex align-items-center justify-content-center">
                <div className="schoolInfo">
                  <div className="barcode">
                    <img
                      src={
                        this.props.schoolLogo
                          ? this.props.schoolLogo
                          : schoolLogo
                      }
                    />
                  </div>
                  <p className="no schoolCardName">
                    {this.props.schoolName
                      ? this.props.schoolName
                      : "School Name"}
                  </p>
                  <div className="text-center">
                    <small className="no schoolCardAddress">
                      {this.props.schoolAddress
                        ? this.props.schoolAddress
                        : "School Address"}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="template2Back">
          <small className="schoolWeb">
            {this.props.schoolWebAddress ? this.props.schoolWebAddress : ""}
          </small>
          <div className="details-info">
            <p>
              <b>Emergency Contact</b>
            </p>
            {/* <p>
              {this.props.emergencyContact
                ? this.props.emergencyContact?.name
                : "--"}
            </p>
            <p>
              {this.props.emergencyContact
                ? this.props.emergencyContact?.contactNumber
                : "--"}
            </p> */}
            <p>
              {this.props.emergencyContact &&
              this.props.emergencyContact.name !== ""
                ? this.props.emergencyContact.name
                : "--"}
            </p>
            <p>
              {this.props.emergencyContact &&
              this.props.emergencyContact.contactNumber !== ""
                ? this.props.emergencyContact.contactNumber ||
                  this.props.emergencyContact.mobileNumber
                : "--"}
            </p>
            <div className="signatureBox">
              <div className="signatureDiv">
                <img
                  src={this.props.signature ? this.props.signature : signature}
                />
              </div>
              <p>_______________________</p>
              {/* <div className="idSignature idSignatureHolder">
                <img
                  src={
                    this.props?.authorizedSignature
                      ? this.props?.authorizedSignature
                      : "Authorized Signature"
                  }
                />
              </div> */}
              <p className="text-center">
                {this.props.authorizedSignatureName || "Sample signature Name"}
              </p>
            </div>
          </div>
          <div className="schoolData">
            <p className="validDateCss">
              <strong>Issued Date: </strong>{" "}
              {this.props.issuedDate ? this.props.issuedDate : "Date"}
            </p>
            <p className="validDateCss">
              <strong>Valid Until: </strong>{" "}
              {this.props.validityDate ? this.props.validityDate : "Date"}
            </p>

            <p className="webAddress">
              {this.props.schoolContact
                ? this.props.schoolContact
                : "Tel: Contact Number"}
            </p>
            <div className="barcode">
              <img
                src={this.props.schoolLogo ? this.props.schoolLogo : schoolLogo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Template2;
