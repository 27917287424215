import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomSpinner from "../../components/common/CustomSpinner";

class RoleAndPermissionCreationModal extends Component {
  state = {
    roleName: "",
    modules: [],
    spinner: false,
  };

  componentDidMount() {
    this.getAllModules();
  }

  getAllModules = () => {
    axiosPost(URL.getAllCoreModules, {}, (response) => {
      if (response.status === 200) {
        let modules = response.data.data;
        if (modules.length > 0) {
          modules.forEach((el) => {
            el.checked = false;
            if (el.activities.length > 0) {
              el.activities.forEach((ac) => {
                ac.checked = false;
              });
            }
          });
        }
        this.setState({ modules: response.data.data });
      }
    });
  };

  handleActivityChange = (e, moduleIdx, activityIdx) => {
    let value = e.target.checked;
    let modules = this.state.modules;
    modules[moduleIdx].activities[activityIdx].checked = value;
    this.setState({ modules });
  };

  handleModuleChange = (e, moduleIdx) => {
    let value = e.target.checked;
    let modules = this.state.modules;
    modules[moduleIdx].checked = value;
    modules[moduleIdx].activities.forEach((el) => {
      el.checked = value;
    });
    this.setState({ modules });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.type === "checked" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    if (this.state.roleName !== "") {
      let modules = this.state.modules;
      if (modules.length > 0) {
        modules.forEach((el) => {
          let selectedActivities = [];
          el.activities.forEach((ac) => {
            if (ac.checked === true) {
              selectedActivities.push(ac);
            }
          });
          el.activities = selectedActivities;
        });
      }
      let param = {
        roleName: this.state.roleName,
        modules: modules,
      };
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.insertRole,
          param,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              swal("Success", "Role successfully created");
              this.props.getAllUserRoles();
              this.props.toggleModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    } else {
      swal("Error", "Role name cannot be empty");
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? <CustomSpinner></CustomSpinner> : null}
        <div className="row">
          <div className="col-md-2 mt-2">
            <p className="formTitle">Role Name</p>
          </div>
          <div className="col-md-6">
            <p className="formTitle">
              <input
                type="text"
                className="form-control cusInput"
                name="roleName"
                value={this.state.roleName}
                onChange={this.handleChange}
                placeholder="Enter your role name"
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="formTitle">Permissions</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="roleAndPermissionBox">
              {this.state.modules.length > 0
                ? this.state.modules.map((module, idx) => {
                    return (
                      <table
                        className={
                          "table cusTable " + (idx === 0 ? "" : "mt-3")
                        }
                        key={idx}
                      >
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                name={`module${idx}`}
                                checked={module.checked}
                                id={`module${idx}`}
                                onChange={(e) =>
                                  this.handleModuleChange(e, idx)
                                }
                              />
                              <label htmlFor={`module${idx}`} className="ml-2">
                                {module.name}
                              </label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {module.activities.length > 0
                            ? module.activities.map((activity, aIdx) => {
                                return (
                                  <tr key={aIdx}>
                                    <td key={aIdx}>
                                      <input
                                        type="checkbox"
                                        name={`activity${idx}${aIdx}`}
                                        checked={activity.checked}
                                        id={`activity${idx}${aIdx}`}
                                        onChange={(e) =>
                                          this.handleActivityChange(
                                            e,
                                            idx,
                                            aIdx
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={`activity${idx}${aIdx}`}
                                        className="ml-2"
                                      >
                                        {activity.name}
                                      </label>
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default RoleAndPermissionCreationModal;
