import React, { Component } from "react";
import swal from "sweetalert";
import DragDropFile from "../../UI/DragDropFile";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomSpinner from "../common/CustomSpinner";

class CategoryForm extends Component {
  state = {
    name: "",
    file: null,
    description: "",
    showSpinner: false,
    isTechnicalSchool: false,
    fieldErr: false,
  };

  handleChange = (e, idx) => {
    let { name, value, files } = e.target;
    this.setState({
      [name]: name === "file" ? files : value,
    });
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    if (files.length > 1) {
      swal("Error", "Please select only one file");
    } else {
      this.setState({
        [name]: files,
      });
    }
  };

  handleCheckboxSelect = (e) => {
    let name = e.target.name;
    let value = e.target.checked;
    this.setState({ [name]: value });
  };

  handleSave = () => {
    const { name, file, description } = this.state;
    if (name !== "" && file != null) {
      const param = {
        name: name,
        description: description,
        isTechnicalSchool: this.state.isTechnicalSchool,
      };

      let formData = new FormData();
      formData.append("jsonData", JSON.stringify(param));
      formData.append("file", file[0]);
      axiosPost(
        URL.insertOtherActivityCategories,
        formData,
        (response) => {
          if (response.status === 200) {
            swal("Success", response.data.message);
            this.props.toggleModal();
            this.props.getOtherActivities();
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    } else {
      swal("Error", "Name and file cannot be empty");
      this.setState({
        fieldErr: true,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}
        <>
          {/* <p className="formTitle text-uppercase mb-2">
            Other Activities Category
          </p> */}
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <input
                  className={`form-control cusInput  ${
                    this.state.fieldErr &&
                    this.state.name === "" &&
                    "is-invalid"
                  }`}
                  name="name"
                  type="text"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.name}
                  placeholder="Enter name here"
                />
              </div>
              <div className="col-md-12 mt-3">
                <DragDropFile
                  handleChange={this.handleChange}
                  name="file"
                  handleFileDrop={this.handleFileDrop}
                  files={this.state.file}
                  multiple={false}
                  accept="image/png, image/jpeg, image/jpg"
                />
                {/* <input
                  // multiple
                  // accept="image/png, image/jpeg, image/jpg"
                  // className="form-control"
                  name="file"
                 
                  onChange={(e) => this.handleChange(e)}
                /> */}
              </div>

              <div className="col-lg-12 mt-3">
                <textarea
                  name="description"
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.description}
                  className="form-control"
                  placeholder="Enter description here"
                />
              </div>
              <div className="col-lg-12 mt-3">
                <input
                  name="isTechnicalSchool"
                  type="checkbox"
                  onChange={(e) => this.handleCheckboxSelect(e)}
                  // value={this.state.isTechnicalSchool}
                  checked={this.state.isTechnicalSchool}
                  id="catTechnicalSchool"
                />
                <label
                  htmlFor="catTechnicalSchool"
                  style={{ position: "relative", top: "-3px", left: "5px" }}
                >
                  Technical Schools
                </label>
              </div>

              <div className="row mt-3">
                <div className="col-md-12 text-right">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={this.handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default CategoryForm;
