import React, { Component } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../common/CustomButton";
import icon01 from "../../assets/images/icon-01.png";
import icon02 from "../../assets/images/icon-02.png";
import icons09 from "../../assets/images/icons-09.png";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import ModalWindow from "../../UI/ModalWindow";
import CourseClassResources from "../../pages/CoursePlan/CourseClassResource";
import History from "../../utils/History";
import ExamForm from "../../pages/ExamModule/ExamForm";
import AssessmentQuestions from "../../pages/ExamModule/AssessmentQuestions";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FullImage from "../../UI/FullImage";
import TeacherTrainingGallery from "./TeacherTrainingGallery";
import { displayErrorAlert } from "../../utils/Utils";

class NewTrainingDetails extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    selectedImage: "",
    imageModal: false,
  };

  componentDidMount() {
    this.getLoggedInStatus();
  }

  getLoggedInStatus = () => {
    let token = localStorage.getItem("token");
    if (token) {
      this.setState({ loggedIn: true });
    }
  };
  state = {
    selectedSubjectResources: [],
    fileModal: false,
    loggedIn: false,
  };

  renderSubTopic = (item) => {
    return (
      <>
        {item.subTopic.map((items, cIdx) => (
          <>
            <div className="coursePlanContent">
              <div className="d-flex">
                <div style={{ flex: 1 }}>
                  <h4 className="heading-06">
                    {cIdx + 1}. {items?.name}
                  </h4>
                  {items.description && items.description !== "" ? (
                    <li className="heading-06">{items.description}</li>
                  ) : null}
                </div>
                <div>
                  {items.hasResource ? (
                    <CustomButton
                      text="View Resource"
                      z
                      customStyle="resourceBtn"
                      style={{
                        marginRight: "unset",
                        marginTop: "unset",
                      }}
                      onClick={() =>
                        this.toggleSyllabusResourcesModalView(items)
                      }
                    />
                  ) : null}
                </div>
              </div>
            </div>

            {items.subTopic && this.renderSubTopic(items)}
          </>
        ))}
      </>
    );
  };

  // View subject resource modal
  viewSubjectResourceModal = (id) => {
    const data = {
      teacherTrainingId: id,
    };
    axiosPost(URL.getCoursePlanResourcesCore, data, (response) => {
      if (response.data.status === 200) {
        this.setState(
          {
            selectedSubjectResources: response.data.data,
          },
          () => {
            this.toggleFileModal();
          }
        );
      }
    });
  };

  toggleFileModal = () => {
    this.setState({
      fileModal: !this.state.fileModal,
    });
  };

  toggleSyllabusResourcesModalView = (param) => {
    if (this.state.loggedIn) {
      let data = {
        coursePlanSyllabusId: param.id,
        teacherTrainingId: param.coursePlanId,
      };
      axiosPost(URL.getCoursePlanResourcesCore, data, (res) => {
        if (res.status === 200) {
          this.setState({ selectedSubjectResources: res.data.data }, () => {
            this.toggleFileModal();
          });
        }
      });
    } else {
      History.push("/login");
    }
  };

  handleFullViewImg = (img) => {
    if (img !== undefined) {
      this.setState({
        selectedImage: URL.imageSelectURL + img.name,
      });
    }
    this.setState({ imageModal: !this.state.imageModal });
  };

  getAllResources = (id) => {
    // View subject resource modal

    const data = {
      teacherTrainingId: this.props.topicList[0].teacherTrainingId,
    };
    axiosPost(URL.getCoursePlanResourcesCore, data, (response) => {
      if (response.data.status === 200) {
        this.setState(
          {
            selectedSubjectResources: response.data.data,
          },
          () => {
            this.toggleFileModal();
          }
        );
      }
    });
  };

  render() {
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-9 mb-5">
            <div className="row">
              <div className="col">
                <CustomButton
                  text="Go Back"
                  onClick={this.props.goBack}
                  customStyle="resourceBtn"
                  style={{ marginLeft: "unset" }}
                />
              </div>
              {/* <div className="col text-right">
                <div className="col text-end">
                  <Link to={"/login"}>
                    <CustomButton
                      text="View Resources"
                      customStyle="resourceBtn"
                      style={{ marginRight: "unset" }}
                    ></CustomButton>
                  </Link>
                </div>
              </div> */}
            </div>
            <div className="row mt-2">
              <div className="col text-center">
                <h2 className="heading-06 text-center">
                  {this.props.selectedTraining?.name}
                </h2>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                {/* <div className="d-flex align-items-center justify-content-center"> */}
                {/* {localStorage.getItem("token") ? ( */}
                {this.props.takeExamClick ? (
                  <ExamForm
                    theoryQuestions={this.props.theoryQuestions}
                    handleAnswerChange={this.props.handleAnswerChange}
                    handleSubmit={this.props.handleSubmit}
                  />
                ) : this.props.viewResultClick ? (
                  <div className="whiteContainer">
                    <AssessmentQuestions
                      theoryQuestions={this.props.theoryQuestions}
                      practicalQuestions={[]}
                      handleMarksChange={this.props.handleMarksChange}
                      calculateTotal={this.props.calculateTotal}
                      isCheckedExam={this.props.isCheckedExam}
                      examPassMarks={this.props.examPassMarks}
                    />
                  </div>
                ) : this.props.examActive ? (
                  <>
                    <div className="d-block" style={{ width: "100%" }}>
                      <div className="row mt-2 mb-2">
                        {this.props.examList.length > 0 ? (
                          this.props.examList.map((exam) => (
                            <div className="col-md-12">
                              <div
                                className="description-shadow"
                                style={{
                                  margin: "unset",
                                  marginTop: "50px",
                                }}
                              >
                                <div className="listBox">
                                  <div className="">
                                    <h2
                                      className="listTitle"
                                      style={{ fontSize: "1.5rem" }}
                                    >
                                      {exam?.title}
                                    </h2>
                                    <div>
                                      {exam.description &&
                                      exam.description !== "" ? (
                                        <p>{exam.description}</p>
                                      ) : null}
                                    </div>

                                    <div className="">
                                      Full-marks: {exam.fullMarks}
                                    </div>
                                    <div className="">
                                      Pass-marks: {exam.passMarks}
                                    </div>
                                  </div>
                                  <div>
                                    {this.props.dashbaordTeacherMaterial ? (
                                      ""
                                    ) : exam.examQuestions.some(
                                        (eq) => eq.answers.length > 0
                                      ) ? (
                                      <CustomButton
                                        text="View Result"
                                        customStyle="resourceBtn"
                                        onClick={() =>
                                          this.props.toggleViewResult(exam)
                                        }
                                      />
                                    ) : (
                                      <CustomButton
                                        text="Take Exam"
                                        customStyle="resourceBtn"
                                        onClick={() =>
                                          this.props.toggleExamQuestions(exam)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className=" col text-center">
                            No exams available for this training!!
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : this.props.activeGallery ? (
                  this.props.galleryData.length > 0 ? (
                    <TeacherTrainingGallery
                      galleryData={this.props.galleryData}
                      itemsPerPage={9}
                    />
                  ) : (
                    <div className="text-center">No gallery image added</div>
                  )
                ) : this.props.activeSyllabus ? (
                  <div className="d-block" style={{ width: "100%" }}>
                    <div className="col text-right">
                      <div className="col text-end">
                        {/* <Link to={"/login"}> */}
                        <CustomButton
                          text="View Resources"
                          customStyle="resourceBtn"
                          onClick={this.getAllResources}
                          style={{ marginRight: "unset" }}
                        ></CustomButton>
                        {/* </Link> */}
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      {this.props.topicList?.length > 0 ? (
                        this.props.topicList.map((items) => {
                          return (
                            <>
                              {items?.coursePlanSyllabus?.map((item) => {
                                return (
                                  <>
                                    <div className="col-md-12">
                                      <div
                                        className="description-shadow"
                                        style={{
                                          margin: "unset",
                                          marginTop: "50px",
                                        }}
                                      >
                                        <div className="listBox">
                                          <div className="">
                                            <h2
                                              className="listTitle"
                                              style={{ fontSize: "1.5rem" }}
                                            >
                                              {item?.name}
                                            </h2>
                                            <div>
                                              {item.description &&
                                              item.description !== "" ? (
                                                <p>{item.description}</p>
                                              ) : null}
                                            </div>
                                          </div>
                                          {item.hasResource ? (
                                            <div>
                                              <CustomButton
                                                text="View Resource"
                                                customStyle="resourceBtn"
                                                onClick={() =>
                                                  this.toggleSyllabusResourcesModalView(
                                                    item
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </div>

                                        {item.subTopic &&
                                          this.renderSubTopic(item)}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          );
                        })
                      ) : (
                        <div className="col-md-12 text-center">
                          <i>No syllabus added.</i>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <div onClick={() => this.props.handleSyllabusClick()}>
                      <div
                        className="grade-shadow"
                        // onClick={this.props.toggleCoursePlan}
                      >
                        <div className="grade-icon-image">
                          <img src={icon01} alt="icon-01" />
                        </div>
                        <span className="heading-06 text-center">Syllabus</span>
                      </div>
                    </div>
                    <div onClick={this.props.galleryClick}>
                      <div className="grade-shadow">
                        <div className="grade-icon-image">
                          <img src={icons09} alt="icon-01" />
                        </div>
                        <span className="heading-06 text-center">
                          Photo Gallery
                        </span>
                      </div>
                    </div>
                    {this.state.loggedIn ? (
                      <div onClick={this.props.getExamData}>
                        <div className="grade-shadow">
                          <div className="grade-icon-image">
                            <img src={icon02} alt="icon-01" />
                          </div>
                          <span className="heading-06 text-center">Exam</span>
                        </div>
                      </div>
                    ) : (
                      <Link to="/login">
                        <div
                          className="grade-shadow"
                          // onClick={this.props.toggleCoursePlan}
                        >
                          <div className="grade-icon-image">
                            <img src={icon02} alt="icon-01" />
                          </div>
                          <span className="heading-06 text-center">Exam</span>
                        </div>
                      </Link>
                    )}
                  </div>
                )}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ borderLeft: "2px solid #4878AE" }}>
            {this.props.trainingList.length > 0
              ? this.props.trainingList
                  .filter((el) => el.id !== this.props.selectedTraining.id)
                  .map((trn, idx) => {
                    return (
                      <div
                        className={
                          "d-flex align-items-center justify-content-between heading-06 drop-down mb-3 " +
                          (idx === 0 ? "mt-5" : "")
                        }
                        onClick={() => this.props.selectTraining(trn)}
                        style={{ cursor: "pointer" }}
                      >
                        <span>{trn?.name}</span>
                      </div>
                    );
                  })
              : null}
          </div>
        </div>

        <ModalWindow
          modal={this.state.fileModal}
          toggleModal={this.toggleFileModal}
          modalHeader="Resource"
          size="xl"
          modalBody={
            <CourseClassResources
              selectedResources={this.state.selectedSubjectResources}
            ></CourseClassResources>
          }
        ></ModalWindow>
        <Modal
          id="fullProfileImage"
          isOpen={this.state.imageModal}
          toggle={this.handleFullViewImg}
          centered
          className="tt-profileFullImage"
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="tt-ImageCloseIcon"
            onClick={this.handleFullViewImg}
          />
          <ModalBody>
            <FullImage image={this.state.selectedImage} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default NewTrainingDetails;
