import React, { Component } from "react";
import { scaleTypeCode } from "../../../../utils/Constants";
import { formatDate } from "../../../../utils/Utils";
import { ObtainedMarksCounter } from "./ObtainedMarksCounter";
import ModalWindow from "../../../UI/ModalWindow";
import CommentsBank from "../CommentsBank";
import JoditEditor from "jodit-react";

/**
 * @author Ishan Shrestha
 */

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: [
    "hr",
    "about",
    "fullsize",
    "eraser",
    "undo",
    "redo",
    "table",
    "image",
    "format",
    "brush",
    "source",
    "fontsize",
    "paragraph",
    "left",
    "center",
    "right",
    "justify",
    "video",
    "superscript",
    "subscript",
    "file",
    "print",
  ],
};
class StudentSubjectAssessmentReport extends Component {
  state = {
    assessmentScaleParam: {},
    // assessmentScales: [],
    // assessmentSubjectComment: null,
    modal: false,

    //form params
    // commentText: ""
  };

  handleCommentBankClick = (e) => {
    this.setState({ modal: !this.state.modal });
  };

  //   handleInsertComment = (e, comment) => {
  //     this.setState({ commentText: comment.commentText });
  //     // this.getStudentNameValuePair();
  //   };

  //   getStudentNameValuePair = studentId => {
  //     let param = {
  //       id: studentId
  //     };

  //     axiosPost(URL.studentNameValuePair, param, response => {
  //       if (response.status === 200) {
  //         this.setState({ studentNameValuePair: response.data.data });
  //       } else {
  //         showErroMessage(response);
  //       }
  //     });
  //   };
  componentDidMount() {
    // this.getStudentNameValuePair(this.props.student.people.id);
    // let assessmentScaleParam = this.props.assessmentScaleParam,
    //   student = this.props.student;
    // this.setState({ assessmentScaleParam, student });
    // Getting scales
    // axiosPost(
    //   URL.selectAssessmentScaleForSubject,
    //   assessmentScaleParam,
    //   response => {
    //     if (response.status === 200) {
    //       let assessmentScales = response.data.data;
    //       if (assessmentScales) {
    //         assessmentScales.forEach(assessmentScale => {
    //           if (
    //             assessmentScale.assessmentScaleTypeCode === scaleTypeCode.mark
    //           )
    //             assessmentScale.obtainedMarks = assessmentScale.assessmentScaleMark
    //               ? assessmentScale.assessmentScaleMark.minAchievementLevel
    //                 ? assessmentScale.assessmentScaleMark.minAchievementLevel
    //                 : 0
    //               : 0;
    //           if (
    //             assessmentScale.assessmentScaleTypeCode === scaleTypeCode.level
    //           )
    //             assessmentScale.assessmentScaleLevelId = assessmentScale
    //               .assessmentScaleLevels[0]
    //               ? assessmentScale.assessmentScaleLevels[0].id
    //               : null;
    //           if (
    //             assessmentScale.assessmentScaleTypeCode ===
    //             scaleTypeCode.gradePoint
    //           )
    //             assessmentScale.assessmentScaleGradePointId = assessmentScale
    //               .assessmentScaleGradePoints[0]
    //               ? assessmentScale.assessmentScaleGradePoints[0].id
    //               : null;
    //         });
    //       }
    //       this.setState(
    //         {
    //           assessmentScales
    //         },
    //         () => {
    //           // Getting records if exists
    //           // this.getstudentSubjectAssessmentByKey();
    //         }
    //       );
    //     } else {
    //       swal("Error", response.message, "");
    //     }
    //   }
    // );
    // axiosPost(
    //   URL.selectAssessmentSubjectCommentConfigurationByKey,
    //   assessmentScaleParam,
    //   response => {
    //     if (response.status === 200) {
    //       let assessmentSubjectComment = response.data.data;
    //       this.setState({
    //         assessmentSubjectComment
    //       });
    //     } else {
    //       swal("Error", response.message, "");
    //     }
    //   }
    // );
  }

  // Get student's obtained marks if already exists
  //   getstudentSubjectAssessmentByKey = () => {
  //     let assessmentScales = [...this.props.assessmentScales];
  //     let student = this.state.student;
  //     let assessmentScaleParam = this.state.assessmentScaleParam;
  //     assessmentScales.forEach(scale => {
  //       let param = {
  //         educationLevelId: assessmentScaleParam.educationLevelId,
  //         educationLevelDivisionId: assessmentScaleParam.educationLevelDivisionId,
  //         yearLevelId: assessmentScaleParam.yearLevelId,
  //         classroomId: assessmentScaleParam.classroomId,
  //         subjectId: assessmentScaleParam.subjectId,
  //         assessmentId: assessmentScaleParam.assessmentId,
  //         admissionId: student.admissionId,
  //         assessmentScaleId: scale.id
  //       };
  //       axiosPost(URL.selectStudentSubjectAssessmentByKey, param, response => {
  //         if (response.status === 200) {
  //           let studentSubjectAssessment = response.data.data;
  //           if (studentSubjectAssessment) {
  //             if (scale.assessmentScaleTypeCode === scaleTypeCode.mark) {
  //               scale.obtainedValue = studentSubjectAssessment.obtainedMarks;
  //               scale.obtainedMarks = studentSubjectAssessment.obtainedMarks;
  //             } else if (scale.assessmentScaleTypeCode === scaleTypeCode.level) {
  //               scale.obtainedValue =
  //                 studentSubjectAssessment.assessmentScaleLevelId;
  //               scale.assessmentScaleLevelId =
  //                 studentSubjectAssessment.assessmentScaleLevelId;
  //             } else if (
  //               scale.assessmentScaleTypeCode === scaleTypeCode.gradePoint
  //             ) {
  //               scale.obtainedValue =
  //                 studentSubjectAssessment.assessmentScaleGradePointId;
  //               scale.assessmentScaleGradePointId =
  //                 studentSubjectAssessment.assessmentScaleGradePointId;
  //             }
  //           }
  //         }
  //       });
  //     });
  //     this.setState(assessmentScales);
  //   };

  //   handleCommentTextChange = (value, index, parentDivId) => {
  //     if (document.getElementById(parentDivId)) {
  //       var doc = document.getElementById(parentDivId);
  //       var commentTemplate = doc.getElementsByTagName("span");
  //       for (let i = 0; i < commentTemplate.length; i++) {
  //         if (commentTemplate[i].dataset.template) {
  //           let nameValuePair = Object.entries(this.state.studentNameValuePair);
  //           for (let j = 0; j < nameValuePair.length; j++) {
  //             if (nameValuePair[j][0] === commentTemplate[i].dataset.template) {
  //               if (nameValuePair[j][1] === "") {
  //                 swal(nameValuePair[j][0] + " is Empty");
  //                 // if (commentTemplate[i].nextSibling === "&nbsp; ") {
  //                 //   commentTemplate[i].nextSibling.remove();
  //                 // }
  //                 // commentTemplate[i].nextSibling.remove();
  //                 commentTemplate[i].remove();
  //               } else {
  //                 commentTemplate[i].innerText = nameValuePair[j][1];
  //               }
  //             }
  //           }
  //         }
  //       }
  //       var children = doc.getElementsByTagName("*");
  //       for (var i = 0; i < children.length; i++) {
  //         if (children[i].className === "jodit_wysiwyg") {
  //           value = children[i].innerHTML;
  //         }
  //       }
  //     }

  //     this.handleCommentAfterReplacement(value);
  //   };

  //   handleCommentAfterReplacement = val => {
  //     const maxLength = this.state.assessmentSubjectComment.maxLength,
  //       textType = this.state.assessmentSubjectComment.textType;

  //     // let currentCommentText = val;
  //     if (val && maxLength && val.length > maxLength) {
  //       swal(
  //         "Warning",
  //         "Comment text cannot exceed " + maxLength + " " + textType,
  //         ""
  //       );
  //     }
  //     this.setState({ commentText: val });
  //   };
  //   /**
  //    * @param i index of assessment scale
  //    */
  //   handleObtainedMarksIncrementDecrement = (incDec, i) => {
  //     let assessmentScales = [...this.state.assessmentScales];
  //     let assessmentScale = assessmentScales[i];
  //     if (assessmentScale) {
  //       if (incDec === "inc") {
  //         assessmentScale.obtainedMarks++;
  //       } else if (incDec === "dec") {
  //         assessmentScale.obtainedMarks--;
  //       } else {
  //         return;
  //       }
  //     }
  //     this.setState({ assessmentScales });
  //   };
  //   /**
  //    * @param e on change event
  //    * @param i index of assessment scale
  //    */
  //   handleAssessmentScaleLevelChange = (e, i) => {
  //     let assessmentScales = [...this.state.assessmentScales],
  //       element = e.target;
  //     let assessmentScale = assessmentScales[i],
  //       value = element.value;
  //     assessmentScale.assessmentScaleLevelId = value;
  //     this.setState({ assessmentScales });
  //   };

  //   /**
  //    * @param e on change event
  //    * @param i index of assessment scale
  //    */
  //   handleAssessmentScaleGradePointChange = (e, i) => {
  //     let assessmentScales = [...this.state.assessmentScales],
  //       element = e.target;
  //     let assessmentScale = assessmentScales[i],
  //       value = element.value;
  //     assessmentScale.assessmentScaleGradePointId = value;
  //     this.setState({ assessmentScales });
  //   };
  //   handleSubmit = e => {
  //     const assessmentScaleParam = { ...this.state.assessmentScaleParam };
  //     const assessmentScales = [...this.state.assessmentScales];
  //     // preparing student subject assessment parameter
  //     let studentSubjectAssessments = [];
  //     assessmentScales.forEach(assessmentScale => {
  //       let studentSubjectAssessment = {
  //         assessmentScaleId: assessmentScale.id,
  //         assessmentScaleMarkId: assessmentScale.assessmentScaleMark
  //           ? assessmentScale.assessmentScaleMark.id
  //           : null,
  //         obtainedMarks: assessmentScale.obtainedMarks,
  //         assessmentScaleLevelId: assessmentScale.assessmentScaleLevelId,
  //         assessmentScaleGradePointId: assessmentScale.assessmentScaleGradePointId
  //       };
  //       studentSubjectAssessments.push(studentSubjectAssessment);
  //     });
  //     let studentSubjectAssessmentParam = {
  //       educationLevelId: assessmentScaleParam.educationLevelId,
  //       educationLevelDivisionId: assessmentScaleParam.educationLevelDivisionId,
  //       yearLevelId: assessmentScaleParam.yearLevelId,
  //       classroomId: assessmentScaleParam.classroomId,
  //       subjectId: assessmentScaleParam.subjectId,
  //       assessmentId: assessmentScaleParam.assessmentId,
  //       admissionId: this.state.student.admissionId,
  //       commentText: this.state.commentText,
  //       studentSubjectAssessments: studentSubjectAssessments
  //     };
  //     axiosPost(
  //       URL.insertStudentSubjectAssessment,
  //       studentSubjectAssessmentParam,
  //       response => {
  //         if (response.status === 200) {
  //           swal("Success", "Reord Successfully Inserted.", "");
  //         } else {
  //           swal("Error", response.data.message, "");
  //         }
  //       }
  //     );
  //   };
  render() {
    let assessmentSubjectComment = this.props.assessmentSubjectComment;
    let student = this.props.student;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 tt-generalBorders">
            <div className="tt-studentPhoto">Photo</div>
            {student ? (
              student.people ? (
                <>
                  <div>
                    <strong>Name: </strong>
                    {student.people.name}
                  </div>
                  <div>
                    <strong>Dob: </strong>
                    {formatDate(student.people.dateOfBirth)}
                  </div>
                  <div>
                    <strong>Gender: </strong>
                    {student.people.gender}
                  </div>
                </>
              ) : null
            ) : null}
            <div>
              <strong>Roll No: </strong>NN (Student ID No.NNNN)
            </div>
            <div>
              Special Needs (will appear if data is available will also appear
              here)
            </div>
          </div>
          <div className="col-md-7 tt-generalBorders">
            {/* Assessment Reporting Area */}
            <div className="tt-studentReportHeading">Obtained Score</div>
            <table
              border={1}
              width="100%"
              className="tt-generalTable table-striped"
            >
              <thead className="tt-group-header">
                <tr>
                  <th>Scale description</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {this.props.assessmentScales &&
                this.props.assessmentScales.length > 0
                  ? this.props.assessmentScales.map((assessmentScale, i) => (
                      <tr>
                        <td>
                          <strong>{assessmentScale.scaleName}</strong>
                          <br></br>
                          {assessmentScale.scoreName}
                        </td>
                        <td className="text-center">
                          {assessmentScale.assessmentScaleTypeCode ===
                          scaleTypeCode.level ? (
                            <select
                              value={assessmentScale.assessmentScaleLevelId}
                              onChange={(e) =>
                                this.props.handleAssessmentScaleLevelChange(
                                  e,
                                  i
                                )
                              }
                              className="form-control"
                            >
                              <option value="" disabled>
                                {"Select Level"}
                              </option>
                              {assessmentScale.assessmentScaleLevels
                                ? assessmentScale.assessmentScaleLevels.map(
                                    (scaleLevel) => (
                                      <option value={scaleLevel.id}>
                                        {scaleLevel.level}
                                      </option>
                                    )
                                  )
                                : null}
                            </select>
                          ) : assessmentScale.assessmentScaleTypeCode ===
                            scaleTypeCode.mark ? (
                            <ObtainedMarksCounter
                              count={
                                assessmentScale
                                  ? assessmentScale.obtainedMarks
                                    ? assessmentScale.obtainedMarks
                                    : 0
                                  : 0
                              }
                              handleIncrementDecrement={
                                this.props.handleObtainedMarksIncrementDecrement
                              }
                              assessmentScaleIndex={i}
                            />
                          ) : assessmentScale.assessmentScaleTypeCode ===
                            scaleTypeCode.gradePoint ? (
                            <select
                              value={
                                assessmentScale.assessmentScaleGradePointId
                              }
                              onChange={(e) =>
                                this.props.handleAssessmentScaleGradePointChange(
                                  e,
                                  i
                                )
                              }
                              className="form-control"
                            >
                              <option value="" disabled>
                                Select Grade Point
                              </option>
                              {assessmentScale.assessmentScaleGradePoints
                                ? assessmentScale.assessmentScaleGradePoints.map(
                                    (scaleGradePoint) => (
                                      <option value={scaleGradePoint.id}>
                                        {scaleGradePoint.grade}
                                      </option>
                                    )
                                  )
                                : null}
                            </select>
                          ) : null}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>

            {/* Comment Area */}
            {assessmentSubjectComment ? (
              <>
                <div className="tt-studentReportHeading">
                  {`${localStorage.getItem("classroom")} Teachers Comment`}
                </div>
                {/* <textarea
                  placeholder="Comment Text"
                  className="tt-generalTextarea"
                  value={this.state.commentText}
                  onChange={this.handleCommentTextChange}
                /> */}
                <div
                  id={
                    "subject" +
                    this.props.student.admissionId +
                    this.props.assessmentScaleParam.subjectId
                  }
                  className="tt-commentBankEditor"
                >
                  <JoditEditor
                    value={this.props.commentText}
                    onChange={(e) =>
                      this.props.handleCommentTextChange(
                        e,
                        assessmentSubjectComment.subjectId,
                        "subject" +
                          this.props.student.admissionId +
                          this.props.assessmentScaleParam.subjectId
                      )
                    }
                    config={config}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <i>{`${assessmentSubjectComment.minLength} to ${assessmentSubjectComment.maxLength} ${assessmentSubjectComment.textType}`}</i>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div
                      className="tt-commentsBank"
                      onClick={(e) => this.handleCommentBankClick(e)}
                    >
                      Comments Bank
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <ModalWindow
          modal={this.state.modal}
          toggleModal={this.handleCommentBankClick}
          fullWidth={true}
          modalBody={
            <CommentsBank
              handleInsertComment={this.props.handleInsertComment}
              //commentId={this.state.commentId}
              handleCommentBankHandler={this.handleCommentBankClick}
            />
          }
        />
        <div
          className="row tt-generalAssessmentFooter"
          style={{ alignItems: "center" }}
        >
          <div className="col-md-7 pt-3">
            <strong>Subjects: </strong>
            {
              "Arts, English, Nepali, Nepal Bhasa, Maths, Science, Physical Edu., Cooking"
            }
          </div>
          <div className="col-md-5 py-2">
            <div className="row">
              <div className="col-md-7">
                <select className="form-control">
                  <option value="a">
                    {"Mid-Term Examination (Subject Assessment)"}
                  </option>
                  <option value="b">
                    {"Final-Term Examination (Subject Assessment)"}
                  </option>
                </select>
              </div>
              <div className="col-md-5 pt-1">
                <div className="tt-commentsBank">View Assessment Records</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button onClick={this.props.handleSubmit}>
            Save Assessment Records
          </button>
        </div>
      </div>
    );
  }
}

export default StudentSubjectAssessmentReport;
