import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { DISPLAYMESSAGE, MESSAGEHEADER } from "../../utils/DisplayMessage";
import { displayErrorAlert, showErroMessage } from "../../utils/Utils";
import moment from "moment";
import Select from "react-select";

class DashboardModalGroupMeet extends Component {
  state = {
    sessionName: "",
    dateAndTime: new Date(),
    startTime: "",
    endTime: "",
    description: "",
    groupId: "",
    password: "",
    peopleSpinner: false,
    peopleArray: [],
    selectedIndividual: [],
    isOnlineClass: true,
    listSubjects: [],
    selectedSubject: null,
    disableDefaultSubject: false,
    permissionModal: false,
    groups: [],
    usersList: [],
    peopleArr: [],
  };

  clearState = () => {
    this.setState({
      sessionName: "",
      dateAndTime: new Date(),
      startTime: "",
      endTime: "",
      description: "",
      password: "",
      groupId: "",
    });
  };

  componentDidMount() {
    this.getAllGroups();
    this.getAllUsers();
  }

  getAllGroups = () => {
    axiosPost(URL.getAllGroupsCentral, {}, (response) => {
      if (response.status === 200) {
        let groups = response.data.data;
        if (groups && groups.length > 0) {
          this.setState({
            groups,
            groupId: groups[0].id,
          });
          this.getGroupMembers(groups[0].id);
        }
      }
    });
  };

  getAllUsers = () => {
    axiosPost(
      URL.getAllCombinedUsers,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({ usersList: response.data.data });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleGeneratePassword = (e) => {
    e.preventDefault();
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.setState({ password: retVal });
  };

  handleGroupDiscussionChange = (e) => {
    const { name, value } = e.target;
    if (name === "groupId") {
      this.getGroupMembers(value);
    }
    this.setState({ [name]: value });
  };

  getGroupMembers = (groupId) => {
    this.setState({ fetchingGroupMembers: true, groupMembers: [] }, () => {
      let data = {
        id: groupId,
      };
      axiosPost(
        URL.getGroupSelectByKey,
        data,
        (response) => {
          if (response.status === 200) {
            let totalMembers = response.data.totalRecordsCount;
            let groupMembers = response.data.data?.groupPeople;
            this.setState({
              groupMembers,
              totalMembers,
              fetchingGroupMembers: false,
            });
          } else {
            this.setState({
              fetchingGroupMembers: false,
            });
          }
        },
        (error) => {
          this.setState({
            fetchingGroupMembers: false,
          });
        }
      );
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.sessionName !== "" &&
      this.state.startTime !== "" &&
      this.state.endTime !== "" &&
      this.state.password !== ""
    ) {
      let startTime = this.state.startTime,
        endTime = this.state.endTime,
        dateAndTime = this.state.dateAndTime,
        peoplesData = this.state.peopleArr.map((pa) => {
          return pa.id;
        });

      let param = {
        sessionName: this.state.sessionName,
        startDateTime: new Date(dateAndTime + " " + startTime),
        endDateTime: new Date(dateAndTime + " " + endTime),
        password: this.state.password,
        description: this.state.description,
        groups: [{ id: this.state.groupId }],
        peoples: peoplesData,
      };

      this.setState({ showSpinner: true }, () => {
        axiosPost(
          URL.videoConferencInsert,
          param,
          (response) => {
            if (response.status === 200) {
              swal(MESSAGEHEADER.success, DISPLAYMESSAGE.insertVideoConference);
              this.setState({ showSpinner: false });
              this.clearState();
              this.props.handleGroupMeet();
            } else {
              showErroMessage(response);
            }
          },
          (failure) => {
            swal({
              title: MESSAGEHEADER.error,
              text: "Error inserting",
            });
            this.setState({ showSpinner: false });
          }
        );
      });
    } else {
      swal({
        title: MESSAGEHEADER.error,
        text: "Please fill the fields first and then click create!",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  };

  handleUsersSelect = (items) => {
    this.setState({ peopleArr: items });
  };

  render() {
    return (
      <div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Processing...
            </div>
          </div>
        ) : null}
        <div>
          <div className="container-fluid">
            <div>
              <div className="row mb-2 mt-3">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Group :</strong>
                </div>
                <div className="col-md-7">
                  <select
                    name="groupId"
                    value={this.state.groupId}
                    className="form-control cusInputSelect"
                    onChange={this.handleGroupDiscussionChange}
                  >
                    <option value="" disabled>
                      Choose a group
                    </option>
                    {this.state.groups
                      ? this.state.groups.map((g, idx) => {
                          let val = `${g.id}`;
                          return (
                            <option key={idx} value={val}>
                              {g.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="col-md-1"></div>
              </div>

              {/* <div className="row mb-2 mt-3">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>People :</strong>
                </div>
                <div className="col-md-7">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={this.state.usersList}
                    value={this.state.peopleArr}
                    onChange={(items) => this.handleUsersSelect(items)}
                    placeholder="Select users for group"
                    classNamePrefix="cusSelect"
                  />
                </div>
                <div className="col-md-1"></div>
              </div> */}

              <div className="row mb-2 mt-3">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Session name :</strong>
                </div>
                <div className="col-md-7">
                  <input
                    type="text"
                    className="form-control cusInput"
                    name="sessionName"
                    value={this.state.sessionName}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-1"></div>
              </div>

              <div className="row mt-2">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right"></div>
                <div className="col-md-3 text-center"></div>
                <div className="col-md-2 text-center pl-0">
                  <strong>Start Time</strong>
                </div>
                <div className="col-md-2 text-center pl-0">
                  <strong>End Time</strong>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Choose Date and Time :</strong>
                </div>
                <div className="col-md-3">
                  <input
                    type="date"
                    className="form-control cusInput"
                    name="dateAndTime"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    value={this.state.dateAndTime}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2 pl-0">
                  <input
                    type="time"
                    className="form-control cusInput"
                    name="startTime"
                    value={this.state.startTime}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2 pl-0">
                  <input
                    type="time"
                    className="form-control cusInput"
                    name="endTime"
                    value={this.state.endTime}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-1"></div>
              </div>

              <div className="row mt-2">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Description :</strong>
                </div>
                <div className="col-md-7">
                  <textarea
                    className="form-control"
                    placeholder="Add description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row mt-2">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Set a Password :</strong>
                </div>
                <div className="col-md-5">
                  <input
                    type="text"
                    className="form-control cusInput"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize mt-1"
                    onClick={this.handleGeneratePassword}
                  >
                    Generate
                  </button>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col text-right">
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize mr-3"
                  onClick={this.handleSubmit}
                >
                  Create
                </button>
                {/* <button className="btn btn-primary cusBtn cusBtnFontSize mr-3">
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardModalGroupMeet;
