import React from "react";

function CustomEditButton(props) {
  return (
    <span
      className="material-icons-round actionIcon editColor"
      onClick={props.onClick}
    >
      edit
    </span>
  );
}

export default CustomEditButton;
