import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { scaleTypeCode, URL } from "../../../../utils/Constants";
import $ from "jquery";
import CounterInput from "react-counter-input/lib/CounterInput";
class SubjectCommentConfiguration extends Component {
  state = {};

  componentDidUpdate() {
    $(".selectpicker").selectpicker();
  }
  render() {
    return (
      <>
        <div className="tt-subjectAssessmentDisplay">
          <div className="tt-AssessmentTitle">
            {localStorage.getItem("yearLevel").toUpperCase()}{" "}
            {this.props.className !== undefined
              ? this.props.className?.toUpperCase()
              : null}{" "}
            {/* {localStorage.getItem("classroom").toUpperCase()}{" "} */}
            {this.props.sectionName !== undefined
              ? this.props.sectionName?.toUpperCase()
              : null}{" "}
            -{" "}
            {this.props.assessmentName !== undefined
              ? this.props.assessmentName?.toUpperCase()
              : null}
          </div>
          {/* <div className="tt-AssessmentTitle tt-AssessmentSubtitle">
            {this.props.assessmentName !== undefined
              ? this.props.assessmentName.toUpperCase()
              : null}
          </div> */}
          <div className="container-fluid tt-subjectAssessmentContent1">
            <div className="row">
              <div className="col-md">
                {this.props.assessmentSubjectsConfigurations
                  ? this.props.assessmentSubjectsConfigurations.map(
                      (assessmentSubjectsConfiguration, mainIndex) => (
                        <table
                          border={1}
                          className="mt-2 table-striped mb-2"
                          width="100%"
                        >
                          <thead className="tt-AssessmentTitle">
                            <tr>
                              {assessmentSubjectsConfiguration.selectedSubjects
                                .length === 0 ? null : (
                                <th className="tt-subjectDisplayTable text-center">
                                  {"Subjects "}
                                </th>
                              )}

                              {assessmentSubjectsConfiguration.selectedSubjects
                                ? assessmentSubjectsConfiguration.selectedSubjects.map(
                                    (subjects, subjectIdx) =>
                                      subjectIdx === 0
                                        ? subjects.selectedAssessmentScales
                                          ? subjects.selectedAssessmentScales.map(
                                              (
                                                assessmentScale,
                                                assessmentScaleIdx
                                              ) => (
                                                <th className="text-center">
                                                  {`${assessmentScale.scaleName} (${assessmentScale.scoreName})`}{" "}
                                                  <FontAwesomeIcon
                                                    icon={faTimesCircle}
                                                    onClick={(e) =>
                                                      this.props.handleRemoveScaleFromTable(
                                                        e,
                                                        mainIndex,
                                                        subjectIdx,
                                                        assessmentScaleIdx
                                                      )
                                                    }
                                                  />
                                                </th>
                                              )
                                            )
                                          : null
                                        : null
                                  )
                                : null}

                              {(assessmentSubjectsConfiguration.selectedSubjects
                                ? assessmentSubjectsConfiguration.selectedSubjects.filter(
                                    (subject) => subject.hasComment
                                  )
                                : null) &&
                              (assessmentSubjectsConfiguration.selectedSubjects
                                ? assessmentSubjectsConfiguration.selectedSubjects.filter(
                                    (subject) => subject.hasComment
                                  )
                                : null
                              ).length > 0 ? (
                                <th>Comment</th>
                              ) : null}
                              {assessmentSubjectsConfiguration.selectedSubjects
                                .length === 0 ? null : (
                                <th className="tt-subjectDisplayTable">
                                  {"Options"}
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {assessmentSubjectsConfiguration.selectedSubjects
                              ? assessmentSubjectsConfiguration.selectedSubjects.map(
                                  (subject, subjectIndex) => (
                                    <tr>
                                      <td className="text-center">
                                        {subject.name}
                                        {" - "}
                                        {subject.hasTheory &&
                                        subject.hasPractical
                                          ? "Theory-Practical"
                                          : subject.hasTheory
                                          ? "Theory"
                                          : "Practical"}
                                      </td>
                                      {/* start of description of scales */}
                                      {subject.selectedAssessmentScales
                                        ? subject.selectedAssessmentScales.map(
                                            (
                                              assessmentScale,
                                              assessmentScaleIndex
                                            ) => (
                                              <td className="text-center">
                                                {assessmentScale.assessmentScaleTypeCode ===
                                                scaleTypeCode.level ? (
                                                  <select className="selectpicker">
                                                    {assessmentScale.assessmentScaleLevels
                                                      ? assessmentScale.assessmentScaleLevels.map(
                                                          (scaleLevel) => (
                                                            <>
                                                              {scaleLevel.levelType ===
                                                              "colourStickers" ? (
                                                                <option
                                                                  style={{
                                                                    backgroundColor:
                                                                      scaleLevel.level,
                                                                    color:
                                                                      "#fff",
                                                                    fontSize:
                                                                      "25px",
                                                                  }}
                                                                ></option>
                                                              ) : scaleLevel.levelType ===
                                                                "image" ? (
                                                                <option
                                                                  data-content={`<img src=${
                                                                    URL.imageSelectURL +
                                                                    scaleLevel.level
                                                                  } class="img-responsive" height="42" width="42"/>`}
                                                                ></option>
                                                              ) : (
                                                                <option>
                                                                  {
                                                                    scaleLevel.level
                                                                  }
                                                                </option>
                                                              )}
                                                            </>
                                                          )
                                                        )
                                                      : null}
                                                  </select>
                                                ) : assessmentScale.assessmentScaleTypeCode ===
                                                  scaleTypeCode.mark ? (
                                                  <CounterInput
                                                    count={
                                                      assessmentScale
                                                        ?.assessmentScaleMark
                                                        ?.minAchievementLevel ??
                                                      0
                                                    }
                                                    min={0}
                                                    // max={props.maxLength}
                                                    btnStyle={{
                                                      backgroundColor:
                                                        "#000080",
                                                      border: "1px solid #ccc",
                                                      color: "white",
                                                      padding: "5px",
                                                      backgroundImage:
                                                        "linear-gradient(#000080, #000080)",
                                                    }}
                                                    inputStyle={{
                                                      height: "33px",
                                                      background: "white",
                                                      border: "1px solid #ccc",
                                                    }}
                                                    wrapperStyle={{
                                                      justifyContent: "center",
                                                    }}
                                                  />
                                                ) : assessmentScale.assessmentScaleTypeCode ===
                                                  scaleTypeCode.gradePoint ? (
                                                  <select>
                                                    {assessmentScale.assessmentScaleGradePoints
                                                      ? assessmentScale.assessmentScaleGradePoints.map(
                                                          (scaleLevel) => (
                                                            <option>
                                                              {scaleLevel.grade}
                                                            </option>
                                                          )
                                                        )
                                                      : null}
                                                  </select>
                                                ) : null}
                                              </td>
                                            )
                                          )
                                        : null}
                                      {}
                                      {subject.hasComment ? (
                                        <td className="pt-2 px-2">
                                          <textarea
                                            className="tt-subjectAssessmentTextArea"
                                            placeholder="Comment Text"
                                          />
                                          <p className="tt-subjectAssessmentParagraph">
                                            {`${subject.name} (Teacher name.)`}
                                          </p>
                                        </td>
                                      ) : null}

                                      <td className="tt-subjectDisplayTable">
                                        <button
                                          className="tt-button tt-button-primary"
                                          onClick={(e) =>
                                            this.props.handleRemoveSubjectsConfig(
                                              e,
                                              mainIndex,
                                              subjectIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>

                                        {/* {subject.displayMarksConfigurationBtn ? (
                                          <button
                                            className="tt-button tt-button-primary"
                                            onClick={(e) =>
                                              this.props.handleMarkConfiguration(
                                                e,
                                                mainIndex,
                                                subjectIndex
                                              )
                                            }
                                          >
                                            Marks Configuration
                                          </button>
                                        ) : null} */}

                                        {/* <FontAwesomeIcon
                                          icon={faTimesCircle}
                                          onClick={(e) =>
                                            this.props.handleRemoveSubjectsConfig(
                                              e,
                                              mainIndex,
                                              subjectIndex
                                            )
                                          }
                                        ></FontAwesomeIcon> */}
                                      </td>
                                    </tr>
                                  )
                                )
                              : null}
                          </tbody>
                        </table>
                      )
                    )
                  : null}
              </div>
              {/* <div className="col-md-5">
                                <table border={1} className="mt-2 mb-2" width="100%">
                                    <thead className="tt-AssessmentTitle">
                                        <tr>
                                            <th className="tt-subjectDisplayTable" width="90%">
                                                {"Comments "}
                                                <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </th>
                                            <th className="tt-subjectDisplayTable">Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.comments ? this.props.comments.map((comment) => (
                                            <tr>
                                                <td className="pt-2 px-2">
                                                    <textarea
                                                        className="tt-subjectAssessmentTextArea"
                                                        placeholder="Comment Text"
                                                    />
                                                    <p className="tt-subjectAssessmentParagraph">
                                                        {comment.subjectId + " Subject (Teachers )"}
                                                    </p>
                                                </td>
                                                <td className="tt-subjectDisplayTable">
                                                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                </td>
                                            </tr>
                                        )) : null}

                                    </tbody>
                                </table>
                            </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SubjectCommentConfiguration;
