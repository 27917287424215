import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { axiosPost } from "../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL } from "../../utils/Constants";
import { Spinner } from "reactstrap";
import converter from "number-to-words";
import { compareByName, displayErrorAlert } from "../../utils/Utils";
import ModalWindow from "../../components/UI/ModalWindow";
import ReactToPrint from "react-to-print";
import ReceiptTemplate from "./ReceiptTemplate";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";
import swal from "sweetalert";

const animatedComponents = makeAnimated();
// const componentRef = React.createRef();

class Payment extends React.Component {
  state = {
    admissionId: null,
    amountPayed: "",
    billGeneratedMonths: null,
    fetchingPaymentData: null,
    loader: false,
    months: null,
    payedBy: "",
    paymentData: null,
    isAdvance: false,
    receiptData: "",
    receiptModal: false,
    selectedStudents: null,
    selectedMonths: null,
    students: [],
    switchToggle: true,
    studentLoader: false,
    totalPayment: 0,
    unpaidTotal: 0,
    yearlevelId: "",
    yearLevels: [],
    permissionModal: false,
  };

  calculateGrandTotal = () => {
    let grandTotal = 0;
    const total =
      this.state.paymentData?.studentBills[0].amount *
      (this.state.switchToggle ? this.state.selectedMonths?.length : 1);
    if (this.state.paymentData?.advanceDue?.due) {
      grandTotal = total + this.state.paymentData?.advanceDue?.due;
    } else {
      grandTotal = total - this.state.paymentData?.advanceDue?.advance;
    }
    return grandTotal;
  };

  // calculateTotal = () => {
  //   let totalPayment = 0,
  //     unpaidTotal = parseInt(this.state.unpaidTotal);
  //   if (this.state.paymentData?.advanceDue?.advance) {
  //     unpaidTotal = unpaidTotal - this.state.paymentData?.advanceDue?.advance;
  //   }
  //   totalPayment = unpaidTotal - parseInt(this.state.amountPayed);
  //   this.setState({ totalPayment });
  // };

  calculateTotal = () => {
    let totalPayment = 0,
      unpaidTotal = parseInt(
        this.state.unpaidTotal -
          this.state.paymentData?.advanceDue?.advanceDueAmount
      );

    totalPayment = parseInt(this.state.amountPayed) - unpaidTotal;
    this.setState({ totalPayment });
  };

  checkAdvance = () => {
    return this.state.paymentData?.advanceDue?.advanceDueAmount;
  };

  componentDidMount() {
    this.getYearLevels();
  }

  chkRemaining = () => {
    let remainingSum = this.state.unpaidTotal - this.state.amountPayed;

    return remainingSum;
  };

  getMonthsByGeneratedBill = (id) => {
    const param = {
      admissionId: id,
    };
    axiosPost(URL.getMonthByGeneratedBill, param, (response) => {
      if (response.status === 200) {
        let billGeneratedMonths = response.data.data;
        billGeneratedMonths = billGeneratedMonths.map((m) => {
          m.label = m.english || m.nepali;
          m.value = m.id;
          return m;
        });
        this.setState({ billGeneratedMonths });
      }
    });
  };

  getStudentAsPerYearLevel = () => {
    let param = {
      yearLevelId: this.state.yearlevelId,
    };
    this.setState(
      { studentLoader: true, students: [], selectedStudents: null },
      function () {
        axiosPost(
          URL.getStudentAsPerYearLevel,
          param,
          (response) => {
            if (response.status === 200) {
              let students = response.data.data;
              students = students.map((s) => {
                s.label = s.people.name;
                s.value = s.id;
                return s;
              });
              this.setState({ students, studentLoader: false });
            }
          },
          (err) => {
            this.setState({ studentLoader: false });
          }
        );
      }
    );
  };

  getStudentBill = () => {
    this.setState({ fetchingPaymentData: true }, () => {
      const param = {
        // yearLevelId: this.state.selectedStudents.assignedYearLevelId,
        yearLevelId: this.state.selectedStudents.yearLevelId,
        admissionId: this.state.selectedStudents.id,
        // monthIds: this.state.switchToggle
        //   ? this.state.selectedMonths.map((m) => m.id)
        //   : [],
      };
      axiosPost(URL.getStudentBill, param, (response) => {
        if (response.status === 200) {
          this.setState(
            {
              paymentData: response.data.data,
              fetchingPaymentData: null,
            },
            function () {
              this.getUnpaidTotal();
            }
          );
        } else {
          this.setState({
            fetchingPaymentData: null,
          });
        }
      });
    });
  };

  // getStudents = () => {
  //   this.setState({ studentLoader: true }, () => {
  //     axiosPost(
  //       URL.getListOfCurrentStudent,
  //       {},
  //       (response) => {
  //         if (response.status === 200) {
  //           let students = response.data.data;
  //           students = students.map((s) => {
  //             s.label = s.people.name;
  //             s.value = s.id;
  //             return s;
  //           });
  //           this.setState({ students, studentLoader: false });
  //         }
  //       },
  //       (err) => {
  //         this.setState({ studentLoader: false });
  //       }
  //     );
  //   });
  // };

  getUnpaidTotal = () => {
    let paymentData = this.state.paymentData,
      unpaidTotal = 0;
    if (paymentData.studentBills.length > 0) {
      paymentData.studentBills.forEach((el) => {
        if (el.status === "unpaid") {
          if (!el.remaining) {
            unpaidTotal = unpaidTotal + el.amount;
          } else {
            unpaidTotal = unpaidTotal + el.remaining;
          }
        }
      });
    }
    if (paymentData && paymentData.advanceDue) {
      unpaidTotal = unpaidTotal - paymentData?.advanceDue?.advanceDueAmount;
    }

    this.setState({ unpaidTotal });
  };

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          yearLevels: response.data.data,
        });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    this.setState({ [name]: value, paymentData: null }, function () {
      if (name === "yearlevelId") {
        this.getStudentAsPerYearLevel();
      }
    });
  };

  handleIsAdvanceChange = (e) => {
    let { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  handlePaymentSave = () => {
    if (this.state.amountPayed !== "" && this.state.amountPayed < 1) {
      swal("Error", "Please enter valid amount to pay");
      return false;
    } else if (
      this.state.amountPayed !== "" &&
      this.state.selectedStudents &&
      this.state.payedBy !== ""
    ) {
      let billAmt =
        this.state.amountPayed > this.state.unpaidTotal
          ? this.state.isAdvance
            ? this.state.amountPayed
            : this.state.amountPayed - parseInt(Math.abs(this.chkRemaining()))
          : this.state.amountPayed;

      const param = {
        amount: billAmt,
        yearLevelId: this.state.selectedStudents.yearLevelId,
        admissionId: this.state.selectedStudents.id,
        isAdvance: this.state.isAdvance,
        billNos: [
          ...new Set(
            this.state.paymentData?.studentBills?.map((b) => b.billNo)
          ),
        ],
        monthIds: this.state.switchToggle
          ? this.state.paymentData?.studentBills?.map((b) => b.monthId)
          : [],
        paymentTransaction: {
          billPayedBy: this.state.payedBy,
        },
        inWords:
          converter.toWords(billAmt).charAt(0).toUpperCase() +
          converter.toWords(billAmt).slice(1),
      };
      this.setState({ loader: true }, function () {
        axiosPost(
          URL.insertPayment,
          param,
          (response) => {
            if (response.status === 200) {
              // swal("Success", `${this.state.amountPayed} has been payed.`, "");

              // if (response.data.message !== "") {
              //   window.open(URL.downloadBill + response.data.message, "_blank");
              // }
              this.getStudentBill();
              this.setState({
                amountPayed: "",
                payedBy: "",
                unpaidTotal: 0,
                isAdvance: false,
                totalPayment: 0,
                loader: false,
                receiptModal: true,
                receiptData: response.data.data,
              });
            }
          },
          (err) => {
            this.setState({ loader: false });
            displayErrorAlert(err);
            // swal("Error", "Sorry, some unkown error occured", "");
          }
        );
      });
    } else {
      swal("Error", "Please enter paying amount and payer name");
      return false;
    }
  };

  handleStudentsChange = (items) => {
    this.setState({ selectedStudents: items, paymentData: null });
    if (items && items.length > 0) {
      this.getMonthsByGeneratedBill(items.id);
    }
  };

  handleSwitchChange = (e) => {
    this.setState({ switchToggle: !this.state.switchToggle });
  };

  handleTotalPayment = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, function () {
      this.calculateTotal();
    });
  };

  toggleReceiptModal = () => {
    this.setState({ receiptModal: !this.state.receiptModal });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.5vh" }}>
        {this.state.loader ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Processing...
            </div>
          </div>
        ) : null}
        <div className="tt-group-header">
          Payment
          {checkWidgetAdminOrNot("Account") ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <div>
                <strong>{localStorage.getItem("yearLevel")}</strong>
              </div>
              <select
                className="form-control"
                name="yearlevelId"
                onChange={this.handleChange}
                value={this.state.yearlevelId}
              >
                <option value="" selected disabled>
                  Choose {localStorage.getItem("yearLevel")}
                </option>
                {this.state.yearLevels?.sort(compareByName).map((y, idx) => (
                  <option value={y.id} key={idx}>
                    Class {y.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4">
              <strong>Student</strong>
              <Select
                components={animatedComponents}
                isClearable={true}
                isLoading={
                  this.state.students.length > 0 || !this.state.studentLoader
                    ? false
                    : true
                }
                menuPlacement="auto"
                name="students"
                onChange={this.handleStudentsChange}
                options={this.state.students || []}
                placeholder="Choose Students"
                value={this.state.selectedStudents}
              />
            </div>

            <div className="col-md-2" style={{ position: "relative" }}>
              <strong></strong>
              <button
                className="tt-button tt-button-primary"
                onClick={this.getStudentBill}
                disabled={this.state.selectedStudents === null}
                style={{ position: "absolute", bottom: 0 }}
              >
                Get Bill
              </button>
            </div>
            {/* <div className="col-md-1"></div> */}
          </div>
          {/* <div className="row mt-2">
            <div className="col-md-1 pt-1">Type</div>
            <div className="col-md-11">
              <label className="mr-2" htmlFor="switchToggle">
                Annual
              </label>
              <Switch
                id="switchToggle"
                checked={this.state.switchToggle}
                onClick={(e) => this.handleSwitchChange(e)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <label className="mr-2" htmlFor="switchToggle">
                Month
              </label>
            </div>
          </div> */}
          {/* {this.state.switchToggle && (
            <div className="row mt-2">
              <div className="col-md-1">Months</div>
              <div className="col-md-11">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={this.state.selectedMonths}
                  options={this.state.billGeneratedMonths || []}
                  name="months"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(items) => this.setState({ selectedMonths: items })}
                  isDisabled={this.state.selectedStudents === null}
                />
              </div>
            </div>
          )} */}

          <div className="mt-4">
            {this.state.paymentData?.studentBills?.length > 0 ? (
              this.state.paymentData.studentBills.map((data, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {idx === 0 && (
                      <div className="row mt-2">
                        <div className="col tt-group-header">
                          {this.state.selectedStudents?.people?.name} [{" "}
                          {localStorage.getItem("yearLevel")}:{" "}
                          {
                            this.state.selectedStudents?.classRoom
                              ?.yearLevelName
                          }{" "}
                          - {localStorage.getItem("classroom")}:{" "}
                          {this.state.selectedStudents?.classRoom?.name} ]
                        </div>
                      </div>
                    )}
                    <div className="row mt-1">
                      <div className="col">
                        <table className="table table-bordered mt-2">
                          <tbody>
                            <tr>
                              <th
                                colSpan={4}
                                className={
                                  data.status === "unpaid"
                                    ? "table-danger"
                                    : "table-success"
                                }
                              >
                                <span>
                                  {data.monthId
                                    ? data.month.nepali || data.month.english
                                    : data.isInventory
                                    ? "Inventory"
                                    : "Annual Fee"}
                                </span>
                                <span
                                  className={
                                    data.status === "unpaid"
                                      ? "label label-warning float-right"
                                      : "label label-success float-right"
                                  }
                                >
                                  [{data.status?.toUpperCase()}]
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan={4}>Bill No: {data.billNo}</th>
                            </tr>
                            <tr>
                              <th width="80px" className="text-center">
                                SN
                              </th>
                              <th>Category</th>
                              <th width="80px" className="text-center">
                                Quantity
                              </th>
                              <th width="150px" className="text-center">
                                Amount
                              </th>
                            </tr>
                            {data.studentBillFeeCategories.map(
                              (config, cIdx) => {
                                return (
                                  <tr key={cIdx}>
                                    <td className="text-center">{++cIdx}</td>
                                    <td>
                                      {config.category}{" "}
                                      {config.itemName
                                        ? `[ ${config.itemName} ]`
                                        : null}
                                    </td>
                                    <td className="text-center">
                                      {config.quantity}
                                    </td>
                                    <td className="text-right">
                                      {config.amount}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            <tr className="table-info">
                              <th colSpan={3}>Total Amount</th>
                              <th colSpan={1} className="text-right">
                                {data.amount}
                              </th>
                            </tr>
                            {data.remaining && data.status === "unpaid" && (
                              <tr className="table-warning">
                                <th colSpan={3}>Remaining Amount</th>
                                <th colSpan={1} className="text-right">
                                  {data.remaining}
                                </th>
                              </tr>
                            )}
                            {idx ===
                              this.state.paymentData.studentBills.length - 1 &&
                              this.state.paymentData?.advanceDue !==
                                undefined &&
                              this.state.paymentData.advanceDue
                                ?.advanceDueAmount !== 0 && (
                                <tr className="table-warning">
                                  <th colSpan={3}>
                                    {this.state.paymentData?.advanceDue
                                      ?.advanceDueAmount < 0
                                      ? "Due Amount"
                                      : "Advance Amount"}
                                  </th>
                                  <th colSpan={1} className="text-right">
                                    {Math.abs(
                                      this.state.paymentData?.advanceDue
                                        ?.advanceDueAmount
                                    )}
                                  </th>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div className="text-center">
                {this.state.fetchingPaymentData ? (
                  <Spinner color="primary" />
                ) : (
                  "No records to display!!"
                )}
              </div>
            )}
            {this.state.paymentData?.studentBills?.length > 0 ? (
              <div className="tt-paymentDiv">
                <div className="container-fluid">
                  <div className="row tt-totalAdvanceDiv">
                    <div className="col">
                      <strong>Unpaid Total: {this.state.unpaidTotal}</strong>
                    </div>
                    {/* {this.state.paymentData?.advanceDue && (
                        <div
                          className="col-md-auto text-center"
                          style={
                            this.state.paymentData?.advanceDue
                              ?.advanceDueAmount < 0
                              ? {
                                  background: "#f5c6cb",
                                  color: "black",
                                  textShadow: "none",
                                  marginRight: "6px",
                                }
                              : {
                                  color: "black",
                                  background: "#86cfda",
                                  textShadow: "none",
                                  marginRight: "6px",
                                }
                          }
                        >
                          <strong>
                            {this.state.paymentData?.advanceDue
                              ?.advanceDueAmount < 0
                              ? "Due"
                              : "Advance"}
                            : {this.checkAdvance()}
                          </strong>
                        </div>
                      )} */}
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <strong>
                        Paying amount{" "}
                        <span className="tt-assessment-module-mandatory">
                          *
                        </span>{" "}
                      </strong>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="number"
                        name="amountPayed"
                        className="form-control"
                        value={this.state.amountPayed}
                        onChange={this.handleTotalPayment}
                        onKeyDown={(e) => {
                          // Prevent the user from entering negative values using keyboard input
                          if (e.key === "-" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <strong>
                        Paid by{" "}
                        <span className="tt-assessment-module-mandatory">
                          *
                        </span>
                      </strong>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        name="payedBy"
                        className="form-control"
                        value={this.state.payedBy}
                        onChange={(e) =>
                          this.setState({ payedBy: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  {this.state.amountPayed !== "" ? (
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <strong>Remaining:</strong>
                      </div>
                      <div className="col-md-3">{this.chkRemaining()}</div>
                      {parseInt(this.chkRemaining()) < 0 ? (
                        <>
                          <div className="col-md-auto">
                            <input
                              type="checkbox"
                              className="form-control"
                              name="isAdvance"
                              checked={this.state.isAdvance}
                              onChange={this.handleIsAdvanceChange}
                            />
                          </div>
                          <div className="col pl-0">
                            <label htmlFor="isAdvance">
                              <strong>Is Advance</strong>
                            </label>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="row mt-2">
                    <div className="col">
                      <button
                        className="tt-button tt-button-primary float-right"
                        onClick={this.handlePaymentSave}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <ModalWindow
          modal={this.state.receiptModal}
          toggleModal={this.toggleReceiptModal}
          modalHeader="Receipt"
          modalBody={
            <div className="container payment-print">
              <div className="row">
                <div className="col text-right">
                  <ReactToPrint
                    trigger={() => (
                      <button className="tt-button tt-button-primary">
                        Print
                      </button>
                    )}
                    // content={() => componentRef.current}
                    content={() => this.componentRef}
                  />
                  <button
                    className="tt-button tt-button-primary ml-3"
                    onClick={() =>
                      window.open(
                        URL.downloadBill + this.state.receiptData.fileName,
                        "_blank"
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.state.receiptData !== "" ? (
                    // <div ref={componentRef}>
                    <div
                      ref={(el) => (this.componentRef = el)}
                      className="printPage"
                    >
                      <ReceiptTemplate receiptData={this.state.receiptData} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          }
          size="lg"
        />

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Account"
              moduleName="Student Billing"
              header="Payment"
              activityName="payment"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default Payment;
