import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getEducationLevels,
  getDetailYearLevels,
} from "../../../../ducks/AcademicAdministrationDucks";
import SectionCollapsible from "./SectionCollapsible";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkRolePermissions,
  checkWidgetAdminOrNot,
} from "../../../../utils/Constants";
import swal from "sweetalert";
import { displayConfirmDeleteAlert } from "../../../../utils/Utils";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";
import { checkPermissionForSubstituteTeachers } from "./../../../../utils/Constants";

class ClassroomList extends Component {
  state = {
    detailYearLevels: [],
    educationLevels: [],
    classroomLocations: [],
    classroomTeachers: [],
    name: "",
    recruitmentId: "",
    roomId: "",
    termAlias:
      localStorage.getItem("classroom") === null
        ? "Section"
        : localStorage.getItem("classroom"), //get from localStorage
    assessmentModules: [],
  };

  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree) and it fetches all the list from the year
   * level table from the database.
   *
   */
  componentDidMount() {
    // this.props.getYearLevels();
    this.props.getDetailYearLevels();
    this.getStaffList();
    this.getRooms();
    this.getAssessmentModules();
  }

  getAssessmentModules = () => {
    axiosPost(URL.getAssesmentModules, {}, (response) => {
      if (response.status === 200) {
        this.setState({ assessmentModules: response.data.data });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.detailYearLevels !== this.props.detailYearLevels) {
      let detailYearLevels = this.props.detailYearLevels;
      detailYearLevels.forEach((el) => {
        el.yearLevels.forEach((el1) => {
          el1.classrooms.forEach((el2) => {
            el2.isOpen = false;
          });
        });
      });
      this.setState({
        detailYearLevels,
      });
    }
  }

  /**
   *This method fetches tuple with respect to the id and maintains the toggle state for all collapsibles
   */
  handleSectionLevelUpdateBtnClick = (index, yearLevelIdx, classroomIdx) => {
    let detailYearLevels = [...this.state.detailYearLevels];
    detailYearLevels.forEach((el) => {
      el.yearLevels.forEach((el1) => {
        el1.classrooms.forEach((el2) => {
          el2.isOpen = false;
        });
      });
    });
    detailYearLevels[index].yearLevels[yearLevelIdx].classrooms[
      classroomIdx
    ].isOpen = true;

    this.setState({
      detailYearLevels,
      name: detailYearLevels[index].yearLevels[yearLevelIdx].classrooms[
        classroomIdx
      ].name,
      recruitmentId:
        detailYearLevels[index].yearLevels[yearLevelIdx].classrooms[
          classroomIdx
        ].recruitmentId,
      roomId:
        detailYearLevels[index].yearLevels[yearLevelIdx].classrooms[
          classroomIdx
        ].roomId,
    });
  };
  /**
   * This method toggles the selected List
   */
  handleSectionLevelCancelBtnClick = (e) => {
    e.preventDefault();
    let detailYearLevels = [...this.state.detailYearLevels];
    detailYearLevels.forEach((el) => {
      el.yearLevels.forEach((el1) => {
        el1.classrooms.forEach((el2) => {
          el2.isOpen = false;
        });
      });
    });
    this.setState({
      detailYearLevels,
    });
  };
  getRooms = () => {
    axiosPost(URL.roomSelect, {}, (response) => {
      if (response.status === 200) {
        this.setState({ classroomLocations: response.data.data });
      }
    });
  };

  getStaffList = () => {
    axiosPost(URL.getStaffList, {}, (response) => {
      if (response.status === 200) {
        this.setState({ classroomTeachers: response.data.data });
      }
    });
  };
  /**
   * This method handles the input on Change
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "name") {
      if (!value.match(this.state.regEx)) {
        let errorCharacter = value.slice(-1);
        swal({
          title: "Warning",
          text: `${errorCharacter} not allowed`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };
  /**
   * This method sends put/update request with the modified data
   */
  updateTuple = (e, id) => {
    e.preventDefault();
    let data = {
      id: id,
      name: this.state.name,
      recruitmentId: parseInt(this.state.recruitmentId),
      roomId: this.state.roomId,
      //   yearLevelId: yearLevelId,
    };
    axiosPost(URL.updateClassroom, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: `${this.state.termAlias} has been updated`,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.props.getDetailYearLevels();
      }
    });
  };
  /**
   * This method sends delete request with the corresponding 'id' to the server
   * to delete the specific tuple
   */

  deleteRecods = (e, id) => {
    e.preventDefault();
    displayConfirmDeleteAlert(id, this.deleteTuple);
  };

  deleteTuple = (id) => {
    axiosPost(URL.deleteClassroom, { id: id }, (response) => {
      if (response.status === 200) {
        let deleteRecord = true;
        let message = response.data.message;
        if (message.includes("containing")) {
          deleteRecord = false;
        }
        swal({
          title: deleteRecord ? "Success" : "Error",
          text: response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.props.getDetailYearLevels();
      }
    });
  };
  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          List {this.state.termAlias}
          {checkWidgetAdminOrNot("Academic Administration") === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="tt-newListBox">
          {this.state.detailYearLevels.length > 0 ? (
            this.state.detailYearLevels.map((el, index) => {
              return (
                <>
                  {el.yearLevels.map((element, yearLevelIdx) => {
                    return (
                      <>
                        {element.classrooms ? (
                          <table
                            className="table  table-bordered table-striped"
                            key={yearLevelIdx}
                          >
                            <thead className="tt-group-header">
                              <tr>
                                <th colSpan="4">Class {element.name}</th>
                              </tr>
                            </thead>
                            <thead className="tt-group-header-dark">
                              <tr>
                                <th width="20%">{this.state.termAlias}</th>
                                <th width="40%">Teachers</th>
                                <th width="40%">Location</th>
                                <th width="20%">Options</th>
                                {
                                  // checkRolePermissions(
                                  //   "edit-section",
                                  //   "activity"
                                  // ) ||
                                  // checkRolePermissions(
                                  //   "delete-section",
                                  //   "activity"
                                  // ) ||
                                  // checkRolePermissions(
                                  //   "assign-student-to-section",
                                  //   "activity"
                                  // ) ||
                                  //   checkPermissionForSubstituteTeachers(
                                  //     null,
                                  //     null,
                                  //     this.state.assessmentModules
                                  //   ) ? (
                                  //   <th width="20%">Options</th>
                                  //   ) : null
                                }
                              </tr>
                            </thead>

                            <tbody>
                              {element.classrooms.length > 0 ? (
                                element.classrooms.map(
                                  (classroom, classroomIdx) => {
                                    return (
                                      <SectionCollapsible
                                        key={classroomIdx}
                                        yearLevel={element.name}
                                        classroom={classroom}
                                        yearLevelId={element.id}
                                        classroomIdx={classroomIdx}
                                        yearLevelIdx={yearLevelIdx}
                                        assessmentModuleCode={
                                          el.assessmentModuleCode
                                        }
                                        index={index}
                                        handleSectionLevelUpdateBtnClick={
                                          this.handleSectionLevelUpdateBtnClick
                                        }
                                        handleSectionLevelCancelBtnClick={
                                          this.handleSectionLevelCancelBtnClick
                                        }
                                        handleChange={this.handleChange}
                                        {...this.state}
                                        updateTuple={this.updateTuple}
                                        deleteRecods={this.deleteRecods}
                                        assessmentModules={
                                          this.state.assessmentModules
                                        }
                                      />
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td colSpan={4}>No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center"> No data available</div>
                        )}
                      </>
                    );
                  })}
                </>
              );
            })
          ) : (
            <div className="text-center">
              {this.props.sectionSpinner ? (
                <>
                  <Spinner
                    color="primary"
                    style={{ marginTop: "200px" }}
                  ></Spinner>
                  <br></br>
                  Loading Data...
                </>
              ) : (
                "No data"
              )}
            </div>
          )}
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School Groupings"
              header={`List ${this.state.termAlias}`}
              activityName="select-section"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  detailYearLevels: state.academicAdministration.detailYearLevels,
  educationLevels: state.academicAdministration.educationLevels,
  sectionSpinner: state.academicAdministration.sectionSpinner,
});

const mapActionsToProps = {
  getDetailYearLevels,
  getEducationLevels,
};

export default connect(mapStateToProps, mapActionsToProps)(ClassroomList);
