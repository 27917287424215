import React, { Component } from "react";
import moment from "moment";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

export class ListFeedback extends Component {
  state = {
    feedbacksData: [],
    spinner: false,
  };

  componentDidMount() {
    this.getAllFeedbacks();
  }

  getAllFeedbacks = () => {
    axiosPost(
      URL.getAllFeedbacks,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({
            feedbacksData: response.data.data,
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  render() {
    return (
      <div>
        <>
          <table className="table table-bordered table-striped">
            <thead className="tt-group-header">
              <tr>
                <th>Isssued By</th>
                <th>Feedback Type</th>
                <th>Description</th>
                <th>Isssued On</th>
              </tr>
            </thead>
            <tbody>
              {this.state.feedbacksData.length > 0 ? (
                this.state.feedbacksData.map((feedback) => (
                  <tr>
                    <td>{feedback.name}</td>
                    <td>{feedback.feedbackTypeName}</td>
                    <td>{feedback.description}</td>
                    <td>{moment(feedback.createdAt).format("yyyy-MM-DD")}</td>
                  </tr>
                ))
              ) : this.state.spinner ? (
                "spinner"
              ) : (
                <tr className="text-center">
                  <td colSpan={4}>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      </div>
    );
  }
}

export default ListFeedback;
