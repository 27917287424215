import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosGet, axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/Utils";

export class ImportRecruitment extends Component {
  state = {
    spinner: false,
    recruitmentFile: null,
  };

  generateFormat = () => {
    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosGet(
          URL.generateRecruitmentFormat,
          (response) => {
            if (response.status === 200) {
              this.setState({
                spinner: false,
              });
              swal("Success", "Recruitment format generated successfully.");
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleChange = (e) => {
    this.setState({
      recruitmentFile: e.target.files[0],
    });
  };

  importFile = () => {
    let formData = new FormData();
    if (this.state.recruitmentFile !== null) {
      formData.append("file", this.state.recruitmentFile);

      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(
            URL.importRecruitment,
            formData,
            (response) => {
              if (response.status === 200) {
                document.getElementById("recruitmentFile").value = null;
                this.setState({
                  spinner: false,
                });
                swal("Success", "Recruitment data imported successfully.");
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                spinner: false,
              });
            }
          );
        }
      );
    } else {
      swal("Error", "Empty file cannot be imported");
    }
  };
  render() {
    return (
      <div style={{ height: "79.5vh" }}>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="container">
          <div className="d-flex justify-content-between py-2 align-items-center">
            <div className="">
              To import the recruitment data, the format should be according to
              the system.
              <br /> Genrete the recruitment format here.
            </div>
            {
              <button
                className="tt-button tt-button-primary"
                onClick={this.generateFormat}
              >
                Generate Format
              </button>
            }
          </div>
          <div className="py-2">
            <input
              type="file"
              name="recruitmentFile"
              className="form-control mb-2"
              onChange={(e) => this.handleChange(e)}
              id="recruitmentFile"
            />
            <button
              className="tt-button tt-button-primary"
              onClick={this.importFile}
            >
              Import
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ImportRecruitment;
