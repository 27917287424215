import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import CustomModal from "../../components/common/CustomModal";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { axiosPost } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import { displayConfirmDeleteAlert } from "../../utils/DisplayMessage";
import { checkRolePermissions } from "../../utils/Permissions";
import ModuleCreationModal from "./ModuleCreationModal";
import AddActivityModal from "./AddActivityModal";
// import CustomAddButton from "../../components/common/CustomAddButton";

class Modules extends Component {
  state = {
    deletePermission: false,
    activityModal: false,
    moduleId: "",
    modal: false,
    modules: [],
    getSpinner: false,
  };

  checkPermissions = () => {
    this.setState(
      {
        deletePermission: checkRolePermissions("Delete", moduleName.MODULE),
      },
      function () {
        this.getAllModules();
      }
    );
  };

  componentDidMount() {
    this.checkPermissions();
  }

  confirmDelete = (data) => {
    let param = {
      id: data.id,
    };
    axiosPost(URL.deleteModules, param, (response) => {
      if (response.status === 200) {
        swal("Success", "Module successfully deleted");
        this.getAllModules();
      }
    });
  };

  getAllModules = () => {
    this.setState({ getSpinner: true }, function () {
      axiosPost(URL.getAllModules, { central: true }, (response) => {
        this.setState({ getSpinner: false });
        if (response.status === 200) {
          this.setState({ modules: response.data.data });
        }
      });
    });
  };

  handleDelete = (data) => {
    let id = data.id;
    displayConfirmDeleteAlert({ id }, this.confirmDelete);
  };

  handleAddActivity = (data) => {
    let id = data.id;
    this.setState({
      moduleId: id,
    });
    this.toggleActivityModal();
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleActivityModal = () => {
    this.setState({
      activityModal: !this.state.activityModal,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Modules"
                icon="add_box"
                onClick={this.toggleModal}
                action="Insert"
                moduleName={moduleName.modules}
              />
              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>Module Name</th>
                    <th>Module Code</th>
                    <th>Activities</th>
                    {this.state.deletePermission ? <th></th> : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.modules.length > 0 ? (
                    this.state.modules.map((module, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{module.name}</td>
                          <td>{module.moduleCode}</td>
                          <td>
                            {module?.activities?.length ? (
                              <table>
                                <thead>
                                  <th>Code</th>
                                  <th>Name</th>
                                </thead>
                                <tbody>
                                  {module?.activities?.map((a) => (
                                    <tr>
                                      <td>{a?.activityCode}</td>
                                      <td>{a?.name}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              "No activities added."
                            )}
                          </td>
                          {this.state.deletePermission ? (
                            <>
                              <td>
                                <CustomDeleteButton
                                  onClick={() => this.handleDelete(module)}
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary cusBtn cusBtnFontSize"
                                  onClick={() => this.handleAddActivity(module)}
                                >
                                  Add Activity
                                </button>
                                {/* <CustomAddButton
                                  className="material-icons-round actionIcon addColor"
                                  onClick={() => this.handleAddActivity(module)}
                                /> */}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        className="text-center"
                        colSpan={this.state.deletePermission ? 3 : 2}
                      >
                        {this.state.getSpinner ? (
                          <Spinner></Spinner>
                        ) : (
                          "No modules added"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          modalHeader="Add New Module"
          modalBody={
            <ModuleCreationModal
              getAllModules={this.getAllModules}
              toggleModal={this.toggleModal}
            />
          }
          toggleModal={this.toggleModal}
        />
        <CustomModal
          modal={this.state.activityModal}
          modalHeader="Add New Activity"
          size="lg"
          modalBody={
            <AddActivityModal
              moduleId={this.state.moduleId}
              toggleActivityModal={this.toggleActivityModal}
              getAllModules={this.getAllModules}
            />
          }
          toggleModal={this.toggleActivityModal}
        />
      </div>
    );
  }
}

export default Modules;
