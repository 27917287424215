import React, { Component } from "react";
import avatar from "../../../assets/images/user-image.png";
import moment from "moment";
import {
  URL,
  reactServer,
  checkRolePermissions,
  profilePeopleId,
  modules,
} from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import JoditEditor from "jodit-react";
import ModalWindow from "../../UI/ModalWindow";
import PostQuestion from "../PostSection/PostQuestion";
import { displayConfirmDeleteAlert, handleError } from "../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import swal from "sweetalert";
import AddTheme from "../PostSection/AddTheme";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: ["file", "about"],
  uploader: {
    insertImageAsBase64URI: true,
    // url: "www.google.com"
  },
};
class ArticleAcademicRepoEditModal extends Component {
  state = {
    id: "",
    heading: "",
    peoples: [],
    postKeywords: [],
    postThemes: [],
    textContent: "",
    themeList: [],
    selectedOrigin: "",
    links: "",
    selectedLinks: [],
    addQuestionModal: false,
    selectedSubjects: [],
    questions: [
      {
        question: "",
      },
    ],
    addThemeModal: false,
    theme: "",
    description: "",
    peopleId: null,
  };

  handleAddThemeClick = (e) => {
    this.setState((prevState) => ({
      addThemeModal: !prevState.addThemeModal,
    }));
  };

  handleAddThemeSubmit = (e) => {
    if (this.state.theme !== "") {
      e.preventDefault();
      let param = {
        theme: this.state.theme,
        description: this.state.description,
      };
      axiosPost(URL.insertTheme, param, (response) => {
        if (response.status === 200) {
          swal(MESSAGEHEADER.success, "Theme is successfully added");
          this.handleAddThemeClick();
          this.getThemeList();
          this.setState({ theme: "", description: "" });
        } else {
          swal(MESSAGEHEADER.error, response.message);
        }
      });
    } else {
      swal({ title: "Error", text: "Fill the theme name." });
    }
  };

  componentDidMount() {
    this.getThemeList();
    this.setState({
      id: this.props.post.id,
      heading: this.props.post.heading,
      peoples: this.props.post.peoples,
      postThemes:
        this.props.post.postThemes.length > 0
          ? this.props.post.postThemes[0].themeId
          : "",
      textContent: this.props.post.textContent,
      subjectList: this.props.subjectList,
    });
    if (!this.props.postSection) {
      this.manageSubjects();
    }
    this.managePostKeywords();
    this.managePostQuestions();
    this.manageLinks();
  }

  manageSubjects = () => {
    let data = this.props.post.subjects;
    let selectedSubjects = [];
    if (data && data.length > 0) {
      data.forEach((el) => {
        selectedSubjects.push(el.subjectName);
      });
    }
    this.setState({ selectedSubjects: selectedSubjects });
  };

  managePostKeywords = () => {
    let data = this.props.post.postKeywords;
    let newValue = "";
    if (data.length > 0) {
      data.forEach((el) => {
        if (newValue === "") {
          newValue = el.keyword;
        } else {
          newValue = newValue + ", " + el.keyword;
        }
      });
    }
    this.setState({ postKeywords: newValue });
  };

  managePostQuestions = () => {
    let data = this.props.post.postQuestions;
    let questions = [];
    if (data.length > 0) {
      data.forEach((el) => {
        questions.push({
          question: el.question,
        });
      });
    } else {
      questions.push({
        question: "",
      });
    }
    this.setState({ questions: questions });
  };

  manageLinks = () => {
    let data = this.props.post.contents;
    let newData = [];
    if (data.length > 0) {
      data.forEach((el) => {
        if (el.contentTypeCode === "LNK") {
          newData.push(el.content);
        }
      });
    }
    this.setState({ selectedLinks: newData });
  };

  //For Questions

  handleAddQuestionClick = (e) => {
    this.setState((prevState) => ({
      addQuestionModal: !prevState.addQuestionModal,
    }));
  };

  handleAddMoreQuestion = (e) => {
    this.setState((prevState) => ({
      questions: [
        ...prevState.questions,
        {
          question: "",
        },
      ],
    }));
  };

  handleRemoveQuestion = (e, index) => {
    e.preventDefault();
    displayConfirmDeleteAlert(index, this.handleDeleteQuestion);
  };

  handleDeleteQuestion = (id) => {
    let questions = [...this.state.questions];
    questions.splice(id, 1);
    this.setState({ questions });
  };

  handleQuestionChange = (e, index) => {
    let questions = [...this.state.questions];
    let name = e.target.name;
    let value = e.target.value;
    questions[index][name] = value;
    this.setState({ questions });
  };

  deleteQuestions = (e, idx) => {
    let questions = [...this.state.questions];
    questions.splice(idx, 1);
    this.setState({ questions });
  };

  //For Links
  handleLinkChanges = (e) => {
    e.preventDefault();
    if (this.state.links !== "") {
      let selectedLinks = [...this.state.selectedLinks];
      selectedLinks.push(this.state.links);
      this.setState({ selectedLinks, links: "" });
    }
  };

  deleteLinkContent = (e, idx) => {
    let selectedLinks = [...this.state.selectedLinks];
    selectedLinks.splice(idx, 1);
    this.setState({ selectedLinks });
  };

  //For theme
  getThemeList = () => {
    axiosPost(URL.selectTheme, {}, (response) => {
      if (response.status === 200) {
        this.setState({ themeList: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (
        this.state.links.includes("youtube") ||
        this.state.links.includes("youtu.be")
      )
        this.setState({ selectedOrigin: "youtube" });
      if (this.state.links.includes("vimeo"))
        this.setState({ selectedOrigin: "vimeo" });
      if (
        this.state.links.includes("dailymotion") ||
        this.state.links.includes("dai.ly")
      )
        this.setState({ selectedOrigin: "dailymotion" });
      if (this.state.links.includes("slideshare"))
        this.setState({ selectedOrigin: "slideshare" });
      if (this.state.links.includes(reactServer))
        this.setState({ selectedOrigin: "insitePost" });
      if (this.state.links.includes("soundcloud"))
        this.setState({ selectedOrigin: "soundcloud" });
    });
  };

  handleSubjectChange = (e) => {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ selectedSubjects: value });
  };

  handleTextEditor = (value) => {
    this.setState({ textContent: value });
  };

  insertArticleKeyword = (e, value) => {
    e.preventDefault();
    let newValue;
    let keywords = this.state.postKeywords;
    if (keywords.slice(-1) !== "," && keywords.slice(-1) !== "") {
      newValue = keywords + ", " + value;
    } else if (keywords.slice(-1) === "") {
      newValue = keywords + value;
    } else {
      newValue = keywords + " " + value;
    }
    this.setState({ postKeywords: newValue });
  };

  submitData = (e) => {
    e.preventDefault();
    if (this.state.heading !== "" && this.state.textContent !== "") {
      let postKeywords = [];
      let postThemes = [
        {
          themeId: this.state.postThemes,
        },
      ];
      let subjects = [];
      if (this.state.selectedSubjects.length > 0) {
        this.state.selectedSubjects.forEach((el) => {
          subjects.push({
            subjectName: el,
          });
        });
      }
      let keywords = this.state.postKeywords.split(", ");
      keywords.forEach((el) => {
        postKeywords.push({
          keyword: el,
        });
      });
      let data = {};
      if (this.props.postSection) {
        data = {
          post: {
            id: this.state.id,
            isVerified: 0,
            textContent: this.state.textContent,
            heading: this.state.heading,
            contents: this.getContentJSON(this.state.selectedLinks),
            postThemes:
              postThemes[0].themeId === null || postThemes[0].themeId === ""
                ? []
                : postThemes,
            postKeywords: postKeywords[0].keyword === "" ? [] : postKeywords,
            postQuestions:
              this.state.questions.length > 0
                ? this.state.questions[0].question === ""
                  ? []
                  : this.state.questions
                : [],
            groups: [],
            peopleIds: [],
            postType: this.props.post.postType,
          },
          moduleCode: modules["Post Section"],
          isPostSection: true,
        };
      } else {
        data = {
          id: this.state.id,
          isVerified: 0,
          textContent: this.state.textContent,
          heading: this.state.heading,
          contents: this.getContentJSON(this.state.selectedLinks),
          postThemes:
            postThemes[0].themeId === null || postThemes[0].themeId === ""
              ? []
              : postThemes,
          postKeywords: postKeywords[0].keyword === "" ? [] : postKeywords,
          postQuestions:
            this.state.questions[0].question === "" ? [] : this.state.questions,
          groups: [],
          peopleIds: [],
          postType: this.props.post.postType,
          subjects: subjects,
          isPostSection: false,
        };
      }
      this.props.updateAcademicRepo(e, data);
    }
  };

  getContentJSON = (links) => {
    let contentArray = [];
    links.forEach((link) => {
      contentArray.push({
        contentTypeCode: "LNK",
        content: link,
        origin: this.getOrigin(link),
      });
    });
    return contentArray;
  };

  getOrigin = (link) => {
    if (link.includes("youtube") || link.includes("youtu.be")) return "youtube";
    if (link.includes("vimeo")) return "vimeo";
    if (link.includes("dailymotion") || link.includes("dai.ly"))
      return "dailymotion";
    if (link.includes(reactServer)) return "insitePost";
    if (link.includes("slideshare")) return "slideshare";
    if (link.includes("soundcloud")) return "soundcloud";
    return "others";
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "80vh", padding: "10px" }}
        >
          <div className="row">
            <div className="col-md-2 text-center">
              {this.props.post ? (
                this.props.post.peoples ? (
                  this.props.post.peoples.photo ? (
                    <img
                      //   src={require(`../../../assets/images/${this.props.post.peoples.photo}`)}
                      src={URL.imageSelectURL + this.props.post.peoples.photo}
                      className={
                        "img-postDisplay " +
                        (this.props.fullPage
                          ? "imageNewDimension1"
                          : "image-dimension")
                      }
                      onError={(e) => handleError(e)}
                    ></img>
                  ) : (
                    <img
                      src={avatar}
                      className={
                        "img-fluid " +
                        (this.props.fullPage
                          ? "imageNewDimension1"
                          : "image-dimension")
                      }
                    ></img>
                  )
                ) : (
                  <img
                    src={avatar}
                    className={
                      "img-fluid " +
                      (this.props.fullPage
                        ? "imageNewDimension1"
                        : "image-dimension")
                    }
                  ></img>
                )
              ) : null}
            </div>
            <div className="col-md-10">
              <div className="row">
                <p className="mb-0" style={{ lineHeight: "20px" }}>
                  <strong>
                    {this.props.post
                      ? this.props.post.peoples
                        ? this.props.post.peoples.name
                        : "Anonymous"
                      : "Anonymous"}
                    {this.props.post ? (
                      this.props.post.groupName ? (
                        <>&nbsp;&#62; {this.props.post.groupName}</>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}{" "}
                  </strong>
                </p>
              </div>
              <div className="row">
                {this.props.post ? (
                  <>
                    <span className="text-muted date-displayPosts">
                      <i>
                        posted {moment(this.props.post.createdAt).calendar()} (
                        {moment(this.props.post.createdAt).fromNow()})
                      </i>
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="row mt-1">
                <div className="col-md-1 mt-1">
                  <strong>
                    Heading{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
                <div className="col-md-11">
                  <input
                    type="text"
                    className="form-control"
                    name="heading"
                    value={this.state.heading}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-1 text-center mt-1">
                  <strong>Keywords</strong>
                </div>
                <div className="col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="postKeywords"
                    value={this.state.postKeywords}
                    onChange={this.handleChange}
                  ></input>
                  <div className="tt-buttonChips">
                    <div
                      className="green"
                      onClick={(e) =>
                        this.insertArticleKeyword(e, "Approaches")
                      }
                    >
                      Approaches
                    </div>
                    <div
                      className="blue"
                      onClick={(e) => this.insertArticleKeyword(e, "Skill")}
                    >
                      Skill
                    </div>
                    <div
                      className="red"
                      onClick={(e) => this.insertArticleKeyword(e, "Knowledge")}
                    >
                      Knowledge
                    </div>
                  </div>
                </div>
                <div className="col-md-1 text-center mt-1">
                  <strong>Theme</strong>
                </div>
                <div className="col-md-5">
                  <div className="row m-1">
                    <div className="col">
                      <select
                        className="form-control"
                        name="postThemes"
                        value={this.state.postThemes}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled selected>
                          Select Theme
                        </option>
                        {this.state.themeList
                          ? this.state.themeList.map((theme, i) => (
                              <option value={theme.id}>{theme.theme}</option>
                            ))
                          : null}
                      </select>
                    </div>
                    {checkRolePermissions("add-theme") ? (
                      <div className="col-md-auto">
                        <button
                          className="tt-button tt-button-primary tt-button-fullWidth"
                          onClick={this.handleAddThemeClick}
                        >
                          Add Theme
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <JoditEditor
                    value={this.state.textContent}
                    onChange={(e) => this.handleTextEditor(e)}
                    config={config}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3"></div>
                <div className="col-md-7 text-right">
                  <p className="mb-0">
                    What questions does this article address ?{" "}
                  </p>
                </div>
                <div className="col-md-2 mb-2">
                  <button
                    className="tt-button tt-button-primary tt-button-fullWidth"
                    onClick={this.handleAddQuestionClick}
                  >
                    Add Questions
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="tt-postNoticeBoxes mt-0">
                    <strong>Link References</strong>
                    <div className="tt-postEmbeded">
                      {this.state.selectedLinks.length > 0
                        ? this.state.selectedLinks.map((link, idx) => {
                            return (
                              <div className="row" key={idx}>
                                <div className="col-md-11">
                                  <p>{link}</p>
                                </div>
                                <div className="col-md-1">
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    onClick={(e) =>
                                      this.deleteLinkContent(e, idx)
                                    }
                                  ></FontAwesomeIcon>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="tt-postNoticeBoxes mt-0">
                    <strong>Questions</strong>
                    <div className="tt-postEmbeded">
                      {this.state.questions.length > 0 &&
                      this.state.questions[0].question != ""
                        ? this.state.questions.map((question, idx) => {
                            return (
                              <div className="row" key={idx}>
                                <div className="col-md-11">
                                  <p>{question.question}</p>
                                </div>
                                <div className="col-md-1">
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    onClick={(e) =>
                                      this.deleteQuestions(e, idx)
                                    }
                                  ></FontAwesomeIcon>
                                </div>
                              </div>
                            );
                          })
                        : "No questions added"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-md-1 text-center"
                  style={{ paddingRight: 0 }}
                >
                  <p className="tt-postNoticeHeading">Add Links</p>
                </div>
                <div className="col-md-5">
                  <select
                    className="form-control"
                    name="selectedOrigin"
                    onChange={this.handleChange}
                    value={this.state.selectedOrigin}
                  >
                    <option value="" disabled>
                      Insite Post / Youtube / Vimeo / Dailymotion / SlideShare
                    </option>
                    <option value="insitePost">Insite Post</option>
                    <option value="youtube">Youtube</option>
                    <option value="vimeo">Vimeo</option>
                    <option value="dailymotion">Dailymotion</option>
                    <option value="slideshare">SlideShare</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="links"
                    value={this.state.links}
                    onChange={this.handleChange}
                    placeholder="URL"
                    className="form-control"
                  ></input>
                </div>
                <div className="col-md-2">
                  <button
                    className="tt-button tt-button-primary tt-button-fullWidth"
                    onClick={this.handleLinkChanges}
                  >
                    Add Link
                  </button>
                </div>
              </div>
              {/* ad */}
              {/* {this.props.postSection ? null : (
                <div className="row mt-2">
                  <div className="col-md-2">
                    <strong>Assign Subjects</strong>
                    <br></br>
                    <i className="text-muted" style={{ fontSize: "13px" }}>
                      Hold <strong>Ctrl</strong> to select multiple
                    </i>
                  </div>
                  <div className="col-md-5">
                    <select
                      className="form-control"
                      name="selectedSubjects"
                      value={this.state.selectedSubjects}
                      onChange={this.handleSubjectChange}
                      multiple
                    >
                      <option value="" disabled>
                        Choose Subjects
                      </option>
                      {this.state.subjectList
                        ? this.state.subjectList.map((subject, idx) => {
                            return (
                              <option value={subject.name} key={idx}>
                                {subject.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="col-md-5">
                    <div className="tt-selectedSubjects">
                      <p>Selected Subjects</p>
                      {this.state.selectedSubjects.length > 0 ? (
                        <ol>
                          {this.state.selectedSubjects.map((subject, idx) => {
                            return <li key={idx}>{subject}</li>;
                          })}
                        </ol>
                      ) : (
                        <p>No Subjects selected</p>
                      )}
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="row editModalFooter mt-3">
          <div className="col text-right">
            <buttton
              className="tt-button tt-button-primary mr-3"
              onClick={this.submitData}
            >
              Save
            </buttton>
            <buttton
              className="tt-button tt-button-primary"
              onClick={this.props.toggleRepoEditModal}
            >
              Close
            </buttton>
          </div>
        </div>
        <ModalWindow
          modal={this.state.addQuestionModal}
          toggleModal={this.handleAddQuestionClick}
          modalHeader="Quesiton"
          modalBody={
            <PostQuestion
              handleAddMoreQuestion={this.handleAddMoreQuestion}
              questions={this.state.questions}
              handleRemoveQuestion={this.handleRemoveQuestion}
              handleQuestionChange={this.handleQuestionChange}
              handleQuestionSubmit={this.handleAddQuestionClick}
            />
          }
        />
        <ModalWindow
          modal={this.state.addThemeModal}
          toggleModal={this.handleAddThemeClick}
          modalHeader="Add new theme"
          modalBody={
            <AddTheme
              handleChange={this.handleChange}
              theme={this.state.theme}
              description={this.state.description}
              handleAddThemeSubmit={this.handleAddThemeSubmit}
            />
          }
        />
      </>
    );
  }
}

export default ArticleAcademicRepoEditModal;
