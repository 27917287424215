import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  checkRolePermissions,
  draggable,
  recruitmentStatus,
  URL,
} from "../../../utils/Constants";
import ModalWindow from "../../UI/ModalWindow";
import CourseClasses from "../SubjectSetting/CourseClass/CourseClasses";
import CreateCourseClass from "../SubjectSetting/CourseClass/CreateCourseClass";
import TopicDetailModal from "../SubjectSetting/TopicDetailModal";

class CollapsibleSemesterForClasses extends Component {
  state = {
    topicsDetailModal: false,
    selectedSubject: "",
    classModal: false,
    courseTopicId: "",
    classSemesterModal: false,
    subjectId: "",
    addCourseModal: false,
    selectedSubject: "",
    classroomLocations: [],
    venue: "",
    activityType: "",
    activityName: "",
    date: "",
    startTime: "",
    endTime: "",
    recruitmentStatusId: "",
    recruitments: [],
    selectedRecruitment: [],
    permissionCanCreateProgram: false,
  };

  checkPermissions = () => {
    this.setState({
      permissionCanCreateProgram: checkRolePermissions(
        "can_create_program_class",
        "activity"
      ),
    });
  };

  handleAddCourse = (subject) => {
    this.setState({
      selectedSubject: subject,
      addCourseModal: !this.state.addCourseModal,
    });
  };

  toggleAddCourseModal = () => {
    this.setState({ addCourseModal: !this.state.addCourseModal });
  };

  componentDidMount() {
    this.getAssignedRoom();
    this.getRecruitmentStatusByKey();
    this.checkPermissions();
  }

  getRecruitmentStatusByKey = () => {
    let data = {
      code: recruitmentStatus.recruitmentCurrent,
    };
    axiosPost(URL.getRecruitmentStatusByKey, data, (response) => {
      if (response.status === 200) {
        this.setState(
          { recruitmentStatusId: response.data.data.id },
          function () {
            let data = {
              recruitmentStatusId: this.state.recruitmentStatusId,
            };
            this.getStaffList(data);
          }
        );
      }
    });
  };

  getStaffList = (data) => {
    axiosPost(URL.getStaffList, data, (response) => {
      if (response.status === 200) {
        let recruitments = [];
        let staffList = response.data.data;
        staffList.forEach((element) => {
          recruitments.push({
            value: element.id,
            label: element.people.name,
          });
        });

        this.setState({
          recruitments,
        });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  getAssignedRoom = () => {
    axiosPost(URL.roomSelect, {}, (response) => {
      if (response.status === 200) {
        this.setState({ classroomLocations: response.data.data });
      }
    });
  };

  //   handleTopicsDetailModal = (sub) => {
  //     let data = { courseSubjectId: sub.id };
  //     axiosPost(URL.getCourseTopic, data, (res) => {
  //       if (res.status === 200) {
  //         this.setState({ courseTopics: res.data.data });
  //       }
  //     });
  //     this.setState({
  //       selectedSubject: sub,
  //       topicsDetailModal: !this.state.topicsDetailModal,
  //     });
  //   };

  //   toggleTopicsDetailModal = () => {
  //     this.setState({ topicsDetailModal: !this.state.topicsDetailModal });
  //   };

  //   //Class Modal
  //   handleTopicClassModal = (id) => {
  //     this.setState({ classModal: !this.state.classModal, courseTopicId: id });
  //   };

  //   toggleTopicClassModal = () => {
  //     this.setState({ classModal: !this.state.classModal, courseTopicId: "" });
  //   };

  //SemesterClassModal
  handleSemesterClassModal = (id) => {
    this.setState({
      classSemesterModal: !this.state.classSemesterModal,
      subjectId: id,
    });
  };

  toggleSemesterClassModal = () => {
    this.setState({
      classSemesterModal: !this.state.classSemesterModal,
      subjectId: "",
    });
  };

  //Create Class
  createCourseClass = () => {
    if (
      this.state.activityType === "" ||
      this.state.activityName === "" ||
      this.state.startTime === "" ||
      this.state.endTime === "" ||
      this.state.venue === "" ||
      this.state.date === ""
    ) {
      swal({
        title: "Error",
        text: "Fill all the required fields first",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    }
    let recruitmentIds = [];
    if (this.state.selectedRecruitment.length > 0) {
      this.state.selectedRecruitment.forEach((el) => {
        recruitmentIds.push(el.value);
      });
    }
    let data = {
      activityName: this.state.activityName,
      activityType: this.state.activityType,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      date: this.state.date,
      venue: this.state.venue,
      subjectId: this.state.selectedSubject.id,
      recruitmentIds: recruitmentIds,
    };

    if (this.props.topicModal) {
      data.topicId = this.props.topicId;
    }
    axiosPost(URL.insertCourseClass, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Course Class successfully created",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.toggleAddCourseModal();
      }
    });
  };

  handleRecruitmentChange = (selectedItem) => {
    this.setState({
      selectedRecruitment: selectedItem,
    });
  };

  render() {
    let phase = this.props.phase;
    return (
      <React.Fragment>
        <table className="table table-bordered table-striped mb-0">
          <thead className="tt-group-header">
            <tr>
              <th>Program Terms</th>
              <th>Program Subjects</th>
              <th width="140px">Classes</th>
              {this.state.permissionCanCreateProgram ? (
                <th width="140px">Option</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {phase.courseTerms && phase.courseTerms.length > 0 ? (
              phase.courseTerms.map((term, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <tr>
                      <td
                        rowSpan={
                          term.courseSubjects && term.courseSubjects.length > 0
                            ? term.courseSubjects.length
                            : 1
                        }
                      >
                        {term.name}
                      </td>
                      <td rowSpan={1}>
                        {term.courseSubjects && term.courseSubjects.length > 0
                          ? term.courseSubjects.map((sub, sIdx) => {
                              if (sIdx === 0) {
                                return sub.name;
                              }
                            })
                          : "No Subjects assigned"}
                      </td>
                      <td rowSpan={1} className="text-center">
                        {term.courseSubjects && term.courseSubjects.length > 0
                          ? term.courseSubjects.map((sub, sIdx) => {
                              if (sIdx === 0) {
                                return (
                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleSemesterClassModal(sub.id)
                                    }
                                  >
                                    Class Details
                                  </button>
                                );
                              }
                            })
                          : null}
                      </td>
                      {this.state.permissionCanCreateProgram ? (
                        <td rowSpan={1} className="text-center">
                          {term.courseSubjects && term.courseSubjects.length > 0
                            ? term.courseSubjects.map((sub, sIdx) => {
                                if (sIdx === 0) {
                                  return (
                                    <button
                                      key={idx}
                                      className="tt-button tt-button-primary"
                                      onClick={() => this.handleAddCourse(sub)}
                                    >
                                      Add Class
                                    </button>
                                  );
                                }
                              })
                            : null}
                        </td>
                      ) : null}
                    </tr>
                    {term.courseSubjects && term.courseSubjects.length > 0
                      ? term.courseSubjects.map((subject, subIdx) => {
                          if (subIdx > 0) {
                            return (
                              <tr key={subIdx}>
                                <td rowSpan={1}>{subject.name}</td>
                                <td rowSpan={1} className="text-center">
                                  <button
                                    key={idx}
                                    className="tt-button tt-button-primary"
                                    onClick={() =>
                                      this.handleSemesterClassModal(subject.id)
                                    }
                                  >
                                    Class Details
                                  </button>
                                </td>
                                {this.state.permissionCanCreateProgram ? (
                                  <td rowSpan={1} className="text-center">
                                    <button
                                      className="tt-button tt-button-primary"
                                      onClick={() =>
                                        this.handleAddCourse(subject)
                                      }
                                    >
                                      Add Class
                                    </button>
                                  </td>
                                ) : null}
                              </tr>
                            );
                          }
                        })
                      : null}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={this.state.permissionCanCreateProgram ? 4 : 3}
                  className="text-center"
                >
                  No terms assigned
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ModalWindow
          modal={this.state.topicsDetailModal}
          toggleModal={this.toggleTopicsDetailModal}
          modalHeader={this.state.selectedSubject.name + " - Course Details"}
          size="xl"
          modalBody={
            <TopicDetailModal
              topicList={this.state.courseTopics ? this.state.courseTopics : []}
              courseDetail={true}
              handleTopicClassModal={this.handleTopicClassModal}
            ></TopicDetailModal>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.addCourseModal}
          toggleModal={this.toggleAddCourseModal}
          size="xl"
          modalHeader="Add New Course"
          modalBody={
            <CreateCourseClass
              classroomLocations={this.state.classroomLocations}
              venue={this.state.venue}
              activityName={this.state.activityName}
              activityType={this.state.activityType}
              startTime={this.state.startTime}
              endTime={this.state.endTime}
              date={this.state.date}
              handleChange={this.handleChange}
              createCourseClass={this.createCourseClass}
              recruitments={this.state.recruitments}
              selectedRecruitment={this.state.selectedRecruitment}
              handleRecruitmentChange={this.handleRecruitmentChange}
              newWidget={true}
            ></CreateCourseClass>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.classModal}
          toggleModal={this.toggleTopicClassModal}
          modalHeader="Classes"
          fullWidth={true}
          modalBody={
            <CourseClasses
              topicId={this.state.courseTopicId}
              subjectId={
                this.state.selectedSubject
                  ? this.state.selectedSubject.id
                  : null
              }
              topicModal={true}
              detailView={true}
            ></CourseClasses>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.classSemesterModal}
          toggleModal={this.toggleSemesterClassModal}
          modalHeader="Classes"
          fullWidth={true}
          modalBody={
            <CourseClasses
              subjectId={this.state.subjectId}
              // detailView={true}
              courseWidget={true}
            ></CourseClasses>
          }
        ></ModalWindow>
      </React.Fragment>
    );
  }
}

export default CollapsibleSemesterForClasses;
