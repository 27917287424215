import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";

class InventoryItemForm extends Component {
  state = {
    inventoryItems: [
      {
        feeCategoryId: "",
        description: "",
        itemCode: "",
        itemName: "",
        purchasePrice: "",
        reorderLevel: "",
        sellingPrice: "",
      },
    ],
  };

  addInventoryItem = () => {
    this.setState({
      inventoryItems: [
        ...this.state.inventoryItems,
        {
          feeCategoryId: "",
          description: "",
          itemCode: "",
          itemName: "",
          purchasePrice: "",
          reorderLevel: "",
          sellingPrice: "",
        },
      ],
    });
  };

  deleteInventoryItem = (idx) => {
    let inventoryItems = [...this.state.inventoryItems];
    inventoryItems.splice(idx, 1);
    this.setState({ inventoryItems });
  };

  handleChange = (e, idx) => {
    const { name, value } = e.target;
    let inventoryItems = [...this.state.inventoryItems];
    inventoryItems[idx][name] = value;
    this.setState({ inventoryItems });
  };

  insertInventory = () => {
    let error = 0,
      items = this.state.inventoryItems;
    items.forEach((el) => {
      if (
        el.feeCategoryId === "" ||
        el.itemName === "" ||
        el.itemCode === "" ||
        el.purchasePrice === "" ||
        el.sellingPrice === "" ||
        el.reorderLevel === ""
      ) {
        error++;
      }
    });
    if (error !== 0) {
      swal("Error", "Fill required fields");
    } else {
      axiosPost(URL.insertInventory, this.state.inventoryItems, (response) => {
        if (response.status === 200) {
          swal("Success", "Item successfully added");
          this.props.getInventories();
          this.props.toggleModal();
        }
      });
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div
          style={{
            maxHeight: "74.6vh",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {this.state.inventoryItems.map((item, idx) => {
            return (
              <div className="container-fluid" key={idx}>
                <div className="row tt-inventoryItemHolder">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-4">
                        <strong>
                          Category
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>
                        </strong>
                        <select
                          className="form-control"
                          name="feeCategoryId"
                          value={item.feeCategoryId}
                          onChange={(e) => this.handleChange(e, idx)}
                        >
                          <option value="" disabled selected>
                            Choose Category
                          </option>
                          {this.props.categories.map((category, idx) => {
                            return (
                              <option key={idx} value={category.id}>
                                {category.category}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <strong>
                          Item name{" "}
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>{" "}
                        </strong>
                        <input
                          type="text"
                          className="form-control"
                          name="itemName"
                          value={item.itemName}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                      <div className="col-md-4">
                        <strong>
                          Item code{" "}
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>{" "}
                        </strong>
                        <input
                          type="text"
                          className="form-control"
                          name="itemCode"
                          value={item.itemCode}
                          onChange={(e) => this.handleChange(e, idx)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <strong>
                          Purchase Price{" "}
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>{" "}
                        </strong>{" "}
                        <input
                          type="number"
                          className="form-control"
                          name="purchasePrice"
                          value={item.purchasePrice}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="in Rs"
                          onKeyDown={(e) => {
                            // Prevent the user from entering negative values using keyboard input
                            if (e.key === "-" || e.key === "-") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <strong>
                          Selling Price{" "}
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>
                        </strong>{" "}
                        <input
                          type="number"
                          className="form-control"
                          name="sellingPrice"
                          value={item.sellingPrice}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="in Rs"
                          onKeyDown={(e) => {
                            // Prevent the user from entering negative values using keyboard input
                            if (e.key === "-" || e.key === "-") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <strong>
                          Reorder Level{" "}
                          <span className="tt-assessment-module-mandatory">
                            *
                          </span>
                        </strong>{" "}
                        <input
                          type="number"
                          className="form-control"
                          name="reorderLevel"
                          value={item.reorderLevel}
                          onChange={(e) => this.handleChange(e, idx)}
                          onKeyDown={(e) => {
                            // Prevent the user from entering negative values using keyboard input
                            if (e.key === "-" || e.key === "-") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col">
                        <strong>Description (Optional)</strong>
                        <textarea
                          className="form-control"
                          name="description"
                          value={item.description}
                          onChange={(e) => this.handleChange(e, idx)}
                          placeholder="Add description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        height: "100%",
                        paddingBottom: "10px",
                      }}
                    >
                      {idx > 0 && (
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          onClick={() => this.deleteInventoryItem(idx)}
                          className="removeMcqOption"
                        />
                      )}
                      {idx === this.state.inventoryItems.length - 1 && (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          onClick={this.addInventoryItem}
                          className="addMcqOption ml-3"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row mt-3">
          <div className="col mt-2">
            <button
              className="tt-button tt-button-primary float-right"
              onClick={this.insertInventory}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default InventoryItemForm;
