import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { questionType, URL } from "../../utils/Constants";
import ExamFormQuestions from "./ExamFormQuestions";
import swal from "sweetalert";
import CustomPageHeader from "../../components/common/CustomPageHeader";

class ExamForm extends Component {
  state = {
    educationLevels: [],
    educationLevelCode: "",
    educationLevelId: "",
    yearLevels: [],
    yearLevelId: "",
    classRooms: [],
    classRoomId: "",
    educationLevelDivisions: [],
    educationLevelDivisionId: "",
    assessments: [],
    assessmentId: "",
    assessmentSubjects: [],
    subjectId: "",
    assessments: [],
    assessmentId: "",
    subjectQuestions: [],
    theoryQuestions: [],
    practicalQuestions: [],
    trainings: [],
    trainingId: "",
    examList: [],
  };

  componentDidMount() {
    // this.getEducationLevel();
    this.getTrainings();
  }
  getTrainings = () => {
    axiosPost(URL.getAllTraining, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          trainings: response.data.data,
        });
      }
    });
  };

  getExamData = () => {
    let data = {
      currentTrainingId: this.state.trainingId,
    };
    axiosPost(
      URL.getExamQuestions,
      data,
      (response) => {
        const examList = response.data.data;
        // const theoryQuestions =
        //   questions && questions.filter((q) => q.isTheory);
        // const practicalQuestions =
        //   questions && questions.filter((q) => !q.isTheory);
        this.setState({ examList });
      },
      (err) => {}
    );
  };

  handleClickTakeExam = (data) => {
    this.setState({
      theoryQuestions: data.examQuestions,
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "trainingId") {
      this.setState(
        { [name]: value, examList: [], theoryQuestions: [] },
        () => {
          this.getExamData();
        }
      );
    } else {
      this.setState({ [name]: value }, function () {
        // if (
        //   this.state.yearLevelId !== "" &&
        //   this.state.educationLevelId !== "" &&
        //   this.state.classRoomId !== "" &&
        //   this.state.educationLevelDivisionId !== "" &&
        //   this.state.assessmentId !== ""
        // ) {
        //   this.getAssessmentSubjects();
        // }
        // if (name === "educationLevelId") {
        //   this.getYearLevelByEducationLevel(value);
        //   const educationLevel = this.state.educationLevels?.find(
        //     (e) => e.id === parseInt(value)
        //   );
        //   this.setState({
        //     assessmentModuleCode: educationLevel
        //       ? educationLevel.assessmentModuleCode
        //       : null,
        //     educationLevelDivisions: educationLevel
        //       ? educationLevel.educationLevelDivisions
        //       : [],
        //     yearLevelId: "",
        //     classRoomId: "",
        //     assessmentId: "",
        //     subjectId: "",
        //     theoryQuestions: [],
        //     practicalQuestions: [],
        //   });
        //   educationLevel &&
        //     this.getAssessments(educationLevel.assessmentModuleCode);
        // }
        // if (name === "educationLevelDivisionId") {
        //   this.setState({
        //     yearLevelId: "",
        //     classRoomId: "",
        //     assessmentId: "",
        //     subjectId: "",
        //     theoryQuestions: [],
        //     practicalQuestions: [],
        //   });
        // }
        // if (name === "yearLevelId") {
        //   const yearLevel = this.state.yearLevels.find(
        //     (y) => y.id === parseInt(value)
        //   );
        //   this.setState({
        //     classRooms: yearLevel ? yearLevel.classrooms : [],
        //     classRoomId: "",
        //     assessmentId: "",
        //     subjectId: "",
        //     theoryQuestions: [],
        //     practicalQuestions: [],
        //   });
        // }
        // if (name === "classRoomId") {
        //   this.setState({
        //     assessmentId: "",
        //     subjectId: "",
        //     theoryQuestions: [],
        //     practicalQuestions: [],
        //   });
        // }
        // if (name === "assessmentId") {
        //   this.setState({
        //     assessmentSubjects: [],
        //     subjectId: "",
        //     theoryQuestions: [],
        //     practicalQuestions: [],
        //   });
        //   if (
        //     this.state.yearLevelId !== "" &&
        //     this.state.educationLevelId !== "" &&
        //     this.state.classRoomId !== "" &&
        //     this.state.educationLevelDivisionId !== "" &&
        //     this.state.assessmentId !== ""
        //   ) {
        //     this.getAssessmentSubjects();
        //   }
        // }
        // if (name === "subjectId") {
        //   const subject = this.state.assessmentSubjects.find(
        //     (s) => s.subjectId === parseInt(value)
        //   );
        //   if (subject && subject.assessmentScale) {
        //     // this.setState({
        //     //   hasTheory: subject.hasTheory,
        //     //   hasPractical: subject.hasPractical,
        //     // });
        //     // const assessmentScaleMark =
        //     //   subject.assessmentScale.assessmentScaleMark;
        //     // this.setState({
        //     //   theoryMaxMark: assessmentScaleMark.theoryMax,
        //     //   practicalMaxMark: assessmentScaleMark.practicalMax,
        //     // });
        //     this.getSubjectQuestions();
        //   }
        // }
      });
    }
  };

  getSubjectQuestions = () => {
    const data = {
      educationLevelId: this.state.educationLevelId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      yearLevelId: this.state.yearLevelId,
      classroomId: this.state.classRoomId,
      subjectId: this.state.subjectId,
      assessmentId: this.state.assessmentId,
    };
    axiosPost(
      URL.selectSubjectQuestion,
      data,
      (response) => {
        const questions = response.data.data;
        const theoryQuestions =
          questions && questions.filter((q) => q.isTheory);
        const practicalQuestions =
          questions && questions.filter((q) => !q.isTheory);
        this.setState({ theoryQuestions, practicalQuestions });
      },
      (err) => {}
    );
  };

  getYearLevelByEducationLevel = (value) => {
    axiosPost(
      URL.getYearLevelByEducationLevel,
      { educationLevelId: value },
      (response) => {
        if (response.status === 200) {
          this.setState({ yearLevels: response.data.data });
        }
      },
      (error) => {}
    );
  };

  getAssessments = (code) => {
    const data = {
      assessmentModuleCode: code,
    };
    axiosPost(URL.selectAssessments, data, (resp) => {
      if (resp.status === 200) {
        let assessments = resp.data.data;
        this.setState({ assessments });
      }
    });
  };

  getAssessmentSubjects = () => {
    const data = {
      yearLevelId: this.state.yearLevelId,
      educationLevelId: this.state.educationLevelId,
      classroomId: this.state.classRoomId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      assessmentId: this.state.assessmentId,
    };

    axiosPost(
      URL.selectSystemExamSubjects,
      data,
      (response) => {
        if (response.status === 200) {
          let assessmentSubjects = response.data.data;
          this.setState({ assessmentSubjects });
        }
      },
      (err) => {}
    );
  };

  render() {
    const {
      educationLevels,
      educationLevelDivisions,
      classRooms,
      yearLevels,
      assessments,
      assessmentSubjects,
      trainings,
    } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="whiteContainer">
              {/* <CustomPageHeader title="Exam Lists" noIcon={true} />
              <div className="row mb-2">
                <div className="col-md-3">
                  <select
                    className="form-control cusInputSelect"
                    name="trainingId"
                    value={this.state.trainingId}
                    onChange={(e) => this.handleChange(e)}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Select Training
                    </option>
                    {trainings?.map((el, idx) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              {this.state.examList.length > 0 &&
              this.state.theoryQuestions.length === 0 ? (
                <table className="table cusTable">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.examList.length > 0
                      ? this.state.examList.map((training, index) => {
                          return (
                            <tr>
                              <td>{training.title}</td>
                              <td>{training.description}</td>
                              <td>
                                <button
                                  className="btn btn-primary cusBtn cusBtnFontSize"
                                  onClick={() =>
                                    this.handleClickTakeExam(training)
                                  }
                                >
                                  Take Exam
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              ) : (
                ""
              )} */}

              {this.props.theoryQuestions.length > 0 && (
                <>
                  {" "}
                  <div className="row">
                    <div className="col">
                      <ExamFormQuestions
                        theoryQuestions={this.props.theoryQuestions}
                        practicalQuestions={this.props.practicalQuestions}
                        handleAnswerChange={this.props.handleAnswerChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col text-end">
                      <button
                        className="btn btn-primary cusBtn cusBtnFontSize"
                        onClick={this.props.handleSubmit}
                        id="addQuestion"
                      >
                        Submit Exam
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExamForm;
