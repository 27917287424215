import React, { Component } from "react";
import "../../../assets/scss/groups.scss";
import { connect } from "react-redux";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  draggable,
  profilePeopleId,
  checkRolePermissions,
} from "../../../utils/Constants";
import CreateGroup from "./CreateGroup";
import GroupLists from "./GroupLists";
import swal from "sweetalert";
import { getGroups } from "../../../ducks/AcademicAdministrationDucks";
import ModalWindow from "./../../UI/ModalWindow";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
import { Spinner } from "reactstrap";

class Groups extends Component {
  state = {
    // membersTitle: "",
    description: "",
    groupName: "",
    isPublic: true,
    groupLists: "",
    editGroupName: "",
    editIsPublic: "",
    editDescription: "",
    errors: {
      groupName: false,
      description: false,
      isPublic: false,
    },
    editErrors: {
      editGroupName: false,
      editDescription: false,
      editIsPublic: false,
    },
    groupCurrentPage: 1,
    groupTotalRecordsCount: 0,
    groupOffset: 0,
    groupRowCountLimit: 10,
  };

  componentDidMount() {
    let peopleId = localStorage.getItem("peopleId");
    this.getGroups(peopleId);
  }

  getGroups = (peopleId) => {
    let data = {
      peopleId: peopleId,
      offset: this.state.groupOffset,
      rowCountLimit: this.state.groupRowCountLimit,
    };
    axiosPost(URL.getGroups, data, (response) => {
      if (response.status === 200) {
        let groupLists = response.data.data;
        groupLists.forEach((el) => {
          el.editable = false;
        });
        this.setState({
          groupLists: response.data.data,
          groupTotalRecordsCount: response.data.totalRecordsCount,
        });
      }
    });
  };

  //Group List Pagination
  groupHandlePageChange = (page) => {
    this.reRenderGroupList(page);
    this.setState({ groupCurrentPage: page });
  };

  reRenderGroupList = (page) => {
    let offset =
      page * parseInt(this.state.groupRowCountLimit) -
      parseInt(this.state.groupRowCountLimit);
    this.setState({ groupOffset: offset }, function () {
      this.getGroups(
        localStorage.getItem("peopleId"),
        this.state.groupOffset,
        this.state.groupRowCountLimit
      );
    });
  };

  groupHandlePreviousPage = () => {
    if (this.state.groupCurrentPage !== 1)
      this.reRenderGroupList(this.state.groupCurrentPage - 1);
    this.setState((prevState) => ({
      groupCurrentPage: prevState.groupCurrentPage - 1,
    }));
  };

  groupHandleNextPage = () => {
    let nextPage = this.state.groupCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(
        this.state.groupTotalRecordsCount / this.state.groupTotalRecordsCount
      )
    )
      return;
    else {
      this.reRenderGroupList(nextPage);
      this.setState({ groupCurrentPage: nextPage });
    }
  };

  handleSelectChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let newErrors = { ...this.state.errors };
    let newEditErrors = { ...this.state.editErrors };
    if (value.length > 0) {
      newErrors[name] = false;
      newEditErrors[name] = false;
    }
    this.setState({
      [name]: e.target.name === "isPublic" ? value === "true" : value,
      errors: newErrors,
      editErrors: newEditErrors,
    });
  };

  editGroup = (e, idx, groups) => {
    let groupLists = [...this.state.groupLists];
    groupLists.forEach((el) => {
      el.editable = false;
    });
    groupLists[idx].editable = true;
    this.setState({
      groupLists: groupLists,
      editGroupName: groups.name,
      editDescription: groups.description,
      editIsPublic: groups.isPublic ? 1 : 0,
    });
  };

  deleteGroup = (e, idx, group) => {
    e.preventDefault();
    displayConfirmDeleteAlert(group, this.handleDelete);
  };

  handleDelete = (group) => {
    let data = {
      isDeleted: true,
      id: group.id,
    };
    axiosPost(URL.deleteGroup, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Successfully deleted the group.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getGroups(
          profilePeopleId,
          this.state.groupOffset,
          this.state.groupRowCountLimit
        );
      }
    });
  };

  cancelEditGroup = () => {
    let groupLists = [...this.state.groupLists];
    groupLists.forEach((el) => {
      el.editable = false;
    });
    this.setState({ groupLists });
  };

  updateGroup = (e, id) => {
    e.preventDefault();
    if (
      this.state.editGroupName === "" ||
      this.state.editDescription === "" ||
      this.state.editIsPublic === ""
    ) {
      swal({
        title: "Warning",
        text: "Fill all the fields first.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      let newErrors = { ...this.state.editErrors };
      Object.entries(this.state.editErrors).forEach((error) => {
        const [key, value] = error;
        if (this.state[key] === "") {
          newErrors[key] = true;
        }
      });
      this.setState({ editErrors: newErrors });
    } else {
      let data = {
        id: id,
        name: this.state.editGroupName,
        description: this.state.editDescription,
        isPublic: parseInt(this.state.editIsPublic),
      };
      axiosPost(URL.updateGroup, data, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: "Successfully updated the group.",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          this.getGroups(profilePeopleId);
        }
      });
    }
  };

  submitGroup = (e) => {
    e.preventDefault();
    if (
      this.state.groupName === "" ||
      this.state.description === ""
      // this.state.isPublic === ""
    ) {
      swal({
        title: "Warning",
        text: "Fill all the fields first.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      let newErrors = { ...this.state.errors };
      Object.entries(this.state.errors).forEach((error) => {
        const [key, value] = error;
        if (this.state[key] === "") {
          newErrors[key] = true;
        }
      });
      this.setState({ errors: newErrors });
    } else {
      let data = {
        name: this.state.groupName,
        description: this.state.description,
        isPublic: this.state.isPublic,
      };
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertGroup,
          data,
          (response) => {
            if (response.status === 200) {
              swal({
                title: "Success",
                text: "Group has been successfully created",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.getGroups(
                profilePeopleId,
                this.state.groupOffset,
                this.state.groupRowCountLimit
              );
              this.props.getGroups(profilePeopleId, 0, 10);
              this.setState({
                groupName: "",
                description: "",
                isPublic: true,
                showSpinner: false,
              });
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            let errorResponse = error.response ? error.response.data : error;
            if (errorResponse.status === 400) {
              //if condition to check spring boot validation errors
              let errorMessage = "";
              if (errorResponse.errors) {
                errorResponse.errors.forEach((error) => {
                  errorMessage += `${error.field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} ${error.defaultMessage} \n`; //ishan
                });
                swal(errorResponse.error, errorMessage, "");
              } else {
                swal(errorResponse.error, errorResponse.message, "");
              }
            } else {
              swal(
                errorResponse.error || "Network Error",
                errorResponse.message
                  ? errorResponse.message
                  : "Could Not Connect To The Server.",
                ""
              );
            }
          }
        );
      });
    }
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          {checkRolePermissions("list-group", "activity") ? (
            <div className="tt-groupList">
              <GroupLists
                members={false}
                groupListsDisplay={this.state.groupLists}
                editGroup={this.editGroup}
                editGroupName={this.state.editGroupName}
                editDescription={this.state.editDescription}
                editIsPublic={this.state.editIsPublic}
                handleSelectChange={this.handleSelectChange}
                updateGroup={this.updateGroup}
                cancelEditGroup={this.cancelEditGroup}
                deleteGroup={this.deleteGroup}
                editErrors={this.state.editErrors}
                groupCurrentPage={this.state.groupCurrentPage}
                groupTotalRecordsCount={this.state.groupTotalRecordsCount}
                groupOffset={this.state.groupOffset}
                groupRowCountLimit={this.state.groupRowCountLimit}
                createGroup={true}
                groupHandlePageChange={this.groupHandlePageChange}
                groupHandleNextPage={this.groupHandleNextPage}
                groupHandlePreviousPage={this.groupHandlePreviousPage}
              ></GroupLists>
            </div>
          ) : null}
          {checkRolePermissions("insert-group", "activity") ? (
            <div className="tt-groupCreate">
              <CreateGroup
                isPublic={this.state.isPublic}
                description={this.state.description}
                handleSelectChange={this.handleSelectChange}
                groupName={this.state.groupName}
                submitGroup={this.submitGroup}
                errors={this.state.errors}
              ></CreateGroup>
            </div>
          ) : null}
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="School Groupings"
              header={`Create Group`}
              activityName="insert-group"
            />
          }
        />
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Creating Group ...
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = () => ({});
const mapActionToProps = {
  getGroups,
};

export default connect(mapStateToProps, mapActionToProps)(Groups);
