import React, { Component } from "react";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL, postType, checkRolePermissions } from "../../../utils/Constants";

import JoditEditor from "jodit-react";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  removeButtons: ["file", "about", "copyformat", "print"],
  limitChars: 60000,
  showTooltip: true,
  uploader: {
    insertImageAsBase64URI: true,
    // url: "www.google.com"
  },
};

class Article extends Component {
  state = {
    groupsArray: [],
  };

  componentDidMount() {
    axiosPost(URL.getAllGroups, {}, (response) => {
      if (response.status === 200) {
        let groups = response.data.data;
        let groupsArray = [];
        groups.forEach((element) => {
          groupsArray.push({ label: element.name, value: element.id });
        });
        this.setState({
          groupsArray,
        });
      }
    });
  }

  render() {
    return (
      <div className="tt-article mt-2">
        <div className="row mb-1">
          <div className="col-md-1 mt-1 text-center">
            <strong>
              Heading <span className="tt-assessment-module-mandatory">*</span>
            </strong>
          </div>
          <div className="col-md-11">
            <input
              type="text"
              className="form-control"
              name="heading"
              value={this.props.heading}
              onChange={this.props.handleChange}
            ></input>
          </div>
        </div>
        <div className="discussion-jodit">
          <JoditEditor
            onChange={(e) => this.props.handleTextEditor(e)}
            value={this.props.textContent}
            config={config}
          />
        </div>

        <div className="row manageTop mt-3">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary"
              onClick={(e) =>
                this.props.submitPost(
                  e,
                  postType.discussion,
                  this.props.groupPost,
                  this.props.familyPost
                )
              }
              disabled={
                this.props.heading === "" || this.props.textContent === ""
              }
            >
              Post Discussion
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Article;
