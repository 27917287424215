import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import NavBar from "../FrontPage/NavBar";
import icon02 from "../../assets/images/icon-02.png";
import CustomButton from "../common/CustomButton";
import ModalWindow from "../UI/ModalWindow";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import FooterText from "../FrontPage/FooterText";
import { connect } from "react-redux";
import FileViewer from "react-file-viewer";


class OtherActivities extends Component {
  state = {
    oActivities: [
      // {
      //   name: "Children's Stories",
      //   thumbnail: "https://picsum.photos/200",
      //   description: "Children's Stories",
      //   resources: [
      //     {
      //       title: "Resources 1",
      //       thumbnail: "https://picsum.photos/200",
      //       description: "Children's Stories collection.",
      //       type: "image",
      //     },
      //   ],
      // },
      // {
      //   name: "Sign Language Stories",
      //   thumbnail: "https://picsum.photos/200",
      //   description: "Sign Language Stories collection.",
      //   resources: [
      //     {
      //       title: "Resoureces 2",
      //       thumbnail: "https://picsum.photos/200",
      //       description: "Sign Language Stories collection.",
      //       type: "image",
      //     },
      //   ],
      // },
      // {
      //   name: "TED Ed",
      //   thumbnail: "https://picsum.photos/200",
      //   description: "Ted Ed classes",
      //   resources: [
      //     {
      //       title: "Resoureces 3",
      //       thumbnail: "https://picsum.photos/200",
      //       description: "Ted Ed classes collection.",
      //       type: "image",
      //     },
      //   ],
      // },
    ],
    categorySelected: null,
    classes: null,
    activeFile: null,
    fileViewerModal: false,
    filePath: "",
    fileType: "",
  };

  componentDidMount() {
    this.getAllEducationList();
    this.getOtherActivities();
  }

  getOtherActivities = () => {
    axiosPost(
      URL.getOtherActivities,
      { isTechnicalSchool: false },
      (response) => {
        if (response.status === 200) {
          this.setState({ oActivities: response.data.data });
        }
      }
    );
  };

  handleCategorySelect = (oA) => {
    this.setState({ categorySelected: oA });
  };

  getAllEducationList = () => {
    axiosPost(URL.getAllEducationLevel, {}, (response) => {
      if (response.status === 200) {
        const educationLevels = response.data.data;
        let yearLevels = [];
        educationLevels.forEach((el) => {
          if (el.yearLevels.length > 0) {
            el.yearLevels.forEach((c) => {
              yearLevels.push(c);
            });
          }
        });
        this.setState({
          classes: yearLevels,
        });
      }
    });
  };

  handleViewResources = (data) => {
    this.setState(
      {
        filePath: data.filePath,
        fileType: data.fileType,
      },
      () => {
        this.setState({
          resourceViewModal: true,
        });
      }
    );
  };

  toggleResourceViewModal = () => {
    this.setState({
      resourceViewModal: !this.state.resourceViewModal,
    });
  };

  setResource = (r) => {
    this.setState({ activeFile: r }, () => {
      this.toggleFileViewer();
    });
  };

  toggleFileViewer = () => {
    this.setState({
      fileViewerModal: !this.state.fileViewerModal,
    });
  };

  render() {
    const { oActivities, categorySelected } = this.state;
    return (
      <div>
        <NavBar homePage pathName={this.props.location?.pathname} />
        <section>
          <div className="main-image1">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="main-heading">
                    <div className="d-flex">
                      <h2 className="heading-06">
                        {this.props.lang === "nameEn"
                          ? "Other Materials / Resources"
                          : "अन्य सामग्री / स्रोतहरू"}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="my-2">
          <div className="gradeClassList">
            <div key={0} onClick={() => this.selectPublicCategory()}>
              <span>Public</span>
            </div>
            {this.state.classes?.length > 0
              ? this.state.classes.map((cls, idx) => {
                  return (
                    <div
                      key={++idx}
                      onClick={() => this.selectClassCategory(cls)}
                    >
                      <span>{cls.name}</span>
                    </div>
                  );
                })
              : null}
          </div>
        </section> */}
        <section className="mb-4">
          <div className="container mt-3">
            {categorySelected !== null && (
              <div className="row">
                <div className="col">
                  <CustomButton
                    text="Go Back"
                    onClick={() => this.setState({ categorySelected: null })}
                    customStyle="resourceBtn"
                    style={{ marginLeft: "unset", marginBottom: "10px" }}
                  ></CustomButton>
                </div>
              </div>
            )}
            <div className="row mt-2">
              {categorySelected === null && oActivities.length > 0 ? (
                oActivities.map((oA, idx) => (
                  <div className="col-md-4">
                    <div
                      className="card mb-4 p-3"
                      role="button"
                      onClick={() => this.handleCategorySelect(oA)}
                    >
                      <div className="cdc-center">
                        <div className="container-fluid">
                          <div className="row">
                            <div
                              className="col-md-4"
                              style={{
                                borderRight: "1px solid #ccc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <div className="icon-image">
                                <img
                                  src={URL.imageSelectURL + oA.thumbnailPath}
                                  onError={(e) => (e.target.src = icon02)}
                                  alt={oA.description}
                                />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <h6
                                className="heading-06"
                                style={{ fontWeight: "bold" }}
                              >
                                {oA.name}
                              </h6>
                              <small>{oA.description}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {categorySelected?.activitiesCategoriesResources.map((r) => (
                    <div className="col-md-4">
                      <div className="card mb-4 p-3" role="button">
                        <div className="cdc-center">
                          <div className="container-fluid">
                            <div className="row">
                              <div
                                className="col-md-4"
                                style={{
                                  borderRight: "1px solid #ccc",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="icon-image">
                                  <img
                                    src={URL.imageSelectURL + r.thumbnailPath}
                                    onError={(e) => (e.target.src = icon02)}
                                    alt={r.description}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <h6
                                  className="heading-06"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {r.title}
                                </h6>
                                <small className="truncateLines">
                                  {r.description}
                                </small>
                                <CustomButton
                                  text="View resource"
                                  customStyle="resourceBtn otherActivitiesBtn"
                                  onClick={() => this.handleViewResources(r)}
                                  //pop up modal to view resources of the category
                                ></CustomButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
        <FooterText />
        {/* <ModalWindow
          modal={this.state.fileViewerModal}
          modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          size="lg"
          modalHeader="Resource Files"
          modalBody={<ViewLMSFile activeLMSFile={this.state.activeFile} />}
        /> */}

        <ModalWindow
          modal={this.state.resourceViewModal}
          toggleModal={this.toggleResourceViewModal}
          size="lg"
          modalHeader="Resource"
          modalBody={
            <>
              <div style={{ height: "100%" }}>

              {this.state.filePath!=="" && this.state.fileType === "VID" ? (
                  <FileViewer
                    fileType={this.state.filePath.split(".").pop()}
                    filePath={URL.imageSelectURL + this.state.filePath}
                  />
                ) : (
                  this.state.filePath && (
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={[
                        { uri: URL.imageSelectURL + this.state.filePath },
                      ]}
                      config={{
                        header: {
                          disableHeader: false,
                          disableFileName: true,
                          retainURLParams: false,
                        },
                        pdfZoom: {
                          defaultZoom: 0.5, // 1 as default,
                          zoomJump: 0.2, // 0.1 as default,
                        },
                      }}
                    />
                  )
                )}

              
              </div>
            </>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(OtherActivities);
