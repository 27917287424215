import React, { Component } from "react";
import FloorTableRow from "./FloorTableRow";
import { checkRolePermissions } from "../../../../utils/Constants";

class FloorTable extends Component {
  state = {
    permissionDeleteFloor: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionDeleteFloor: checkRolePermissions("delete-floor", "activity"),
    });
  };

  tableRow() {
    return this.props.noOfFloors.map((floor, i) => {
      return (
        <FloorTableRow
          obj={floor}
          key={i}
          idx={i}
          handleDeleteFloorClick={this.props.handleDeleteFloorClick}
          handleUpdateFloorClick={this.props.handleUpdateFloorClick}
          toggleFloorUpdate={this.props.toggleFloorUpdate}
          updatedFloorName={this.props.updatedFloorName}
          handleChange={this.props.handleChange}
          handleUpdateFloorSubmitClick={this.props.handleUpdateFloorSubmitClick}
          buildingId={this.props.buildingId}
          handleFloorChange={this.props.handleFloorChange}
          permissionDeleteFloor={this.state.permissionDeleteFloor}
        />
      );
    });
  }
  render() {
    return (
      <>
        <table className="table text-center table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>Number</th>
              <th>Name</th>
              {this.state.permissionDeleteFloor ? <th>Options</th> : null}
            </tr>
          </thead>
          <tbody>{this.tableRow()}</tbody>
        </table>
        <div className="row button-bg-color">
          {checkRolePermissions("edit-floor", "activity") ? (
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.handleFloorUpdateClick}
              >
                Update Floors
              </button>
              <button
                className="tt-button tt-button-primary ml-3"
                onClick={this.props.handleFloorClick}
              >
                Cancel
              </button>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default FloorTable;
