import React, { Component } from "react";
import "../../assets/scss/profilePicture.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";

class UploadCoverForm extends Component {
  state = {};

  componentDidMount() {
    this.props.scrollEnd();
    let element = document.getElementById(
      `coverPicture-` + this.props.activeSliderIndex
    );
    if (element) {
      document
        .getElementById(`coverPicture-` + this.props.activeSliderIndex)
        .classList.add("selectedThumbnail");
    }
  }

  render() {
    return (
      <>
        <div className="tt-coverPicPreview">
          {this.props.imagePreviewModal !== "" ? (
            <img src={this.props.imagePreviewModal.src} alt="upCp"></img>
          ) : (
            "No Image Selected"
          )}
        </div>
        <div className="row mt-2">
          <div className="col-md-8">
            <strong>Caption</strong>
            <textarea
              type="text"
              className="form-control"
              placeholder="Write Something"
              name="editCaption"
              value={this.props.editCaption}
              onChange={(e) =>
                this.props.handleChange(e, this.props.updateIndex)
              }
              disabled={this.props.imageClicked ? false : true}
            ></textarea>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            {this.props.updateButtonDisplay ? null : (
              <button
                className="tt-button tt-button-primary tt-button-fullWidth mt-1"
                onClick={(e) => this.props.handleCoverPictureUpdate(e)}
              >
                Update Caption
              </button>
            )}
          </div>
        </div>
        <div>
          <div className="tt-profileImages">
            {this.props.tempImages.length > 0 ? (
              <>
                {this.props.tempImages.map((image, idx) => {
                  return (
                    <div key={idx}>
                      <div
                        id={`coverPicture-` + idx}
                        className="tt-profilePictureHolder coverThumbnail"
                        style={{ padding: 0 }}
                        onClick={(e) => this.props.imagePreview(e, image, idx)}
                      >
                        <img src={image.src} alt="coverForm"></img>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="tt-fileDelete"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.deleteImageContent(e, idx);
                          }}
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="tt-profilePictureHolder" style={{ padding: 0 }}>
                No Image
              </div>
            )}
            <div>
              <input
                id="image"
                type="file"
                accept="image/png, image/jpeg"
                ref={(ref) => (this.upload = ref)}
                style={{ display: "none" }}
                onChange={(e) => this.props.handleImageChange(e)}
                multiple
                required
              />
              <div
                id="addImage"
                className="tt-profilePictureHolder"
                style={{ padding: 0, cursor: "pointer", lineHeight: "7.5" }}
                onClick={() => {
                  this.upload.click();
                }}
              >
                <FontAwesomeIcon icon={faPlus} size="2x"></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div>

        {this.props.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Uploading Image ...
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default UploadCoverForm;
