/**
 * @author Ashok
 * @author Azzeta Karmacharya
 */
import React, { Component } from "react";
import IncreaseDecreaseTerm from "./IncreaseDecreaseTerm";

class CreateAcademicYearForm extends Component {
  render() {
    return (
      <div className="container-fluid ttacademic-form">
        <div className="row">
          <div className="col-md-4">
            <label>
              <strong>Start date : </strong>
            </label>
            <input
              type="date"
              className="form-control"
              onKeyDown={(e) => e.preventDefault()}
              value={this.props.startDate}
              onChange={this.props.handleAcademicYearChange}
              id={this.props.startDateId}
              data-id={this.props.dataId}
              max={this.props.endDate}
              name="startDate"
            />
          </div>
          <div className="col-md-4">
            <label>
              <strong>End date : </strong>
            </label>
            <input
              type="date"
              className="form-control"
              onKeyDown={(e) => e.preventDefault()}
              min={this.props.startDate}
              value={this.props.endDate}
              onChange={this.props.handleAcademicYearChange}
              id={this.props.EndDateId}
              data-id={this.props.dataId}
              name="endDate"
            />
          </div>

          <div className="col-md-4">
            <label>
              <strong>Number of {this.props.termAlias}s :</strong>
            </label>
            <IncreaseDecreaseTerm
              numberOfTerms={this.props.numberOfTerms}
              decreaseValue={this.props.decreaseValue}
              numberOfSemester={this.props.numberOfSemester}
              increaseValue={this.props.increaseValue}
              levelName={this.props.levelName}
              idx={this.props.dataId}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAcademicYearForm;
