import React, { Component } from "react";
import swal from "sweetalert";
import CustomSpinner from "../../components/common/CustomSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class EventCreationModal extends Component {
  state = {
    name: "",
    description: "",
    endDate: "",
    eventType: "",
    spinner: false,
    startDate: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.config();
    }
  }

  config = () => {
    if (this.props.selectedData) {
      let selectedData = this.props.selectedData;
      this.setState({
        name: selectedData.title,
        description: selectedData.description,
        endDate: selectedData.end,
        eventType: selectedData.color,
        startDate: selectedData.start,
      });
    }
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    let eventTypes = this.props.eventTypes;
    let selectedEventType = eventTypes.find(
      (el) => el.colorCode === this.state.eventType
    );
    let param = {
      title: this.state.name,
      start: this.state.startDate,
      end: this.state.endDate,
      description: this.state.description,
      color: this.state.eventType,
      isHoliday: selectedEventType?.isHoliday,
    };

    if (
      this.state.name === "" ||
      this.state.startDate === "" ||
      this.state.endDate === "" ||
      this.state.eventType === ""
    ) {
      swal("Error", "Fill all the fields");
    } else {
      this.setState({ spinner: true }, function () {
        let url = "";
        if (this.props.edit) {
          param.id = this.props.selectedData.id;
          url = URL.updateCalendarEventCore;
        } else {
          url = URL.insertCalendarEventCore;
        }
        axiosPost(
          url,
          param,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              if (this.props.edit) {
                swal("Success", "Event successfully updated");
              } else {
                swal("Success", "Event successfully created");
              }
              this.props.getEvents();
              this.props.toggleEventModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? <CustomSpinner></CustomSpinner> : null}
        <div className="row">
          <div className="col">
            <p className="formTitle">Event Name</p>
            <input
              type="text"
              name="name"
              value={this.state.name}
              className="form-control cusInput"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <div className="row">
              <div className="col">
                <p className="formTitle">Start Date</p>
                <input
                  type="date"
                  name="startDate"
                  value={this.state.startDate}
                  className="form-control cusInput"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <p className="formTitle">End Date</p>
                <input
                  type="date"
                  name="endDate"
                  min={this.state.startDate}
                  value={this.state.endDate}
                  className="form-control cusInput"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <p className="formTitle">Event Type</p>
            <select
              name="eventType"
              value={this.state.eventType}
              className="form-control cusInputSelect"
              onChange={this.handleChange}
              defaultValue=""
            >
              <option value="" disabled>
                Select Event Type
              </option>
              {this.props.eventTypes.length > 0 ? (
                this.props.eventTypes.map((evtType, idx) => {
                  return (
                    <option value={evtType.colorCode} key={idx}>
                      {evtType.eventTypeName}
                    </option>
                  );
                })
              ) : (
                <option value="" disabled>
                  No event type added. Add one first
                </option>
              )}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <p className="formTitle">
              Description <span className="optClass">[ *Optional ]</span>
            </p>
            <textarea
              name="description"
              value={this.state.description}
              className="form-control "
              onChange={this.handleChange}
              placeholder="Enter your description here"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <button
              className="btn btn-primary cusBtn cusBtnFontSize"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default EventCreationModal;
