import React, { Component } from "react";
import { checkRolePermissions } from "../../../../utils/Constants";
import BuildingSectionTableRow from "./BuildingSectionTableRow";

class BuildingSectionTable extends Component {
  state = {
    permissionEditFacility: false,
    permissionDeleteFacility: false,
    permissionInsertFacility: false,
  };

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditFacility: checkRolePermissions(
        "edit-facility-section",
        "activity"
      ),
      permissionDeleteFacility: checkRolePermissions(
        "delete-facility-section",
        "activity"
      ),
      permissionInsertFacility: checkRolePermissions(
        "insert-facility-section",
        "activity"
      ),
    });
  };

  tableRow() {
    return this.props.wings.map((wing, i) => {
      return (
        <BuildingSectionTableRow
          key={i}
          obj={wing}
          idx={i}
          deleteSectionName={this.props.deleteSectionName}
          handleSectionUpdate={this.props.handleSectionUpdate}
          handleChange={this.props.handleChange}
          updatedSectionName={this.props.updatedSectionName}
          updateSectionSubmit={this.props.updateSectionSubmit}
          handleCancelUpdate={this.props.handleCancelUpdate}
          permissionEditFacility={this.state.permissionEditFacility}
          permissionDeleteFacility={this.state.permissionDeleteFacility}
          permissionInsertFacility={this.state.permissionInsertFacility}
        />
      );
    });
  }
  render() {
    return (
      <>
        <table className="table text-center table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>
                {this.props.sectionModalName
                  ? this.props.sectionModalName
                  : "Section"}{" "}
                Name
              </th>
              {this.state.permissionEditFacility ||
              this.state.permissionDeleteFacility ? (
                <th>Options</th>
              ) : null}
            </tr>
          </thead>
          <tbody>{this.tableRow()}</tbody>
        </table>
        <div className="tt-group-header">
          Add New{" "}
          {this.props.sectionModalName
            ? this.props.sectionModalName
            : "Section"}{" "}
        </div>
        <div className="form-group row">
          <div className="col-md-4">
            <lable>
              {this.props.sectionModalName
                ? this.props.sectionModalName
                : "Section"}{" "}
              Name
            </lable>
          </div>
          <div className="form-group col-md-4">
            <input
              type="text"
              name="wing"
              className="form-control"
              value={this.props.wing}
              onChange={this.props.handleChange}
            />
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4"></div>
          <div className="col-md-4 text-center">
            {this.state.permissionInsertFacility ? (
              <button
                className="tt-button tt-button-primary"
                onClick={(e) => this.props.addWing(e, this.props.buildingId)}
              >
                Add{" "}
                {this.props.sectionModalName
                  ? this.props.sectionModalName
                  : "Section"}{" "}
              </button>
            ) : null}

            <button
              className="tt-button tt-button-primary ml-2"
              onClick={this.props.toggleSection}
            >
              Cancel
            </button>
          </div>
          <div className="col-md-4"></div>
        </div>
      </>
    );
  }
}

export default BuildingSectionTable;
