import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import Select from "react-select";

import { URL } from "../../utils/Constants";

import makeAnimated from "react-select/animated";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

const animatedComponents = makeAnimated();

class DailyAttendanceSummary extends Component {
  state = {
    attendanceDate: moment().format("YYYY-MM-DD"),
    attendanceReport: [],
    gradeAliasName: localStorage.getItem("yearLevel"),
    sectionAliasName: localStorage.getItem("classroom"),
    gradeList: [],
    gradeId: "",
    sectionList: [],
    sectionId: "",
    selectedIndividual: [],
    selectedGrade: "",
    studentList: [],
    subjectList: [],
  };

  calculateColSpan = () => {
    let colLength = this.state.subjectList?.length + 2;
    return colLength;
  };

  calculateRowSpan = (studentList) => {
    let rowLength = studentList.peoples.length + 1;

    return rowLength;
  };

  componentDidMount() {
    this.getGradeList();
  }

  getGradeList = () => {
    axiosPost(URL.getClassForAssignment, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ gradeList: data });
      }
    });
  };

  getStudentList = () => {
    let param = {
      yearLevelId: this.state.gradeId,
      classroomId: this.state.sectionId,
    };
    axiosPost(URL.getAssignedStudents, param, (response) => {
      if (response.status == 200) {
        let list = response.data.data;
        let studentList = [];
        list.forEach((std) => {
          let data = {
            label: std?.people?.name,
            value: std?.admissionId,
          };
          studentList.push(data);
        });
        this.setState({ studentList });
      }
    });
  };

  handleChange = (e) => {
    this.setState({
      attendanceReport: [],
    });
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleGradeChange = (e) => {
    this.setState({
      attendanceReport: [],
    });
    this.handleChange(e);
    let gradeList = [...this.state.gradeList];
    gradeList.filter((grade) => {
      if (e.target.value === grade.id.toString()) {
        this.setState({
          sectionList: grade.classrooms,
          sectionId: "",
          selectedGrade: grade,
        });
      }
    });
  };
  handleIndividualSelect = (selectItem) => {
    this.setState({
      attendanceReport: [],
    });
    this.setState({
      selectedIndividual: selectItem,
    });
  };

  handleSectionChange = (e) => {
    this.setState({
      attendanceReport: [],
    });
    this.handleChange(e);
    let sectionList = [...this.state.sectionList];
    sectionList.filter((section) => {
      if (e.target.value === section.id.toString()) {
        section.subjectlists = section.subjectlists.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );

        this.setState({ subjectList: section.subjectlists, subjectId: "" });
      }
    });

    this.getStudentList();
  };

  handleGetAttendanceReport = () => {
    if (this.state.gradeId === "" || this.state.sectionId === "") {
      swal("Warning", "First select the grade and section.");
      return;
    }

    let addmissionData = [...this.state.selectedIndividual];
    let studentData = [];

    addmissionData.forEach((ad) => {
      studentData.push(ad.value);
    });

    let param = {
      yearLevelId: this.state.gradeId,
      classroomId: this.state.sectionId,
      date: this.state.attendanceDate,
      admissionIds: studentData,
    };

    axiosPost(URL.getDailyAttendanceSummary, param, (response) => {
      if (response.status === 200) {
        this.setState({
          attendanceReport: response.data.data,
        });
      }
    });
  };

  render() {
    return (
      <div>
        <div className="attendance-top-header">
          <div className="row">
            <div className="col-md-2">
              <label>{this.state.gradeAliasName}</label>
              <select
                name="gradeId"
                className="form-control"
                value={this.state.gradeId}
                onChange={this.handleGradeChange}
              >
                <option value="" disabled>
                  Select {this.state.gradeAliasName}
                </option>
                {this.state.gradeList.map((sl, idx) => {
                  return <option value={sl.id}>{sl.name}</option>;
                })}
              </select>
            </div>
            <div className="col-md-2">
              <label> {this.state.sectionAliasName}</label>
              <select
                className="form-control"
                name="sectionId"
                value={this.state.sectionId}
                onChange={this.handleSectionChange}
              >
                <option value="" disabled>
                  Select {this.state.sectionAliasName}
                </option>
                {this.state.sectionList.map((el, idx) => {
                  return <option value={el.id}>{el.name}</option>;
                })}
              </select>
            </div>

            <div className="col-md-2">
              <label>Date</label>
              <input
                type="date"
                max={moment().format("YYYY-MM-DD")}
                // min="2021-07-28"
                className="form-control"
                name="attendanceDate"
                value={this.state.attendanceDate}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-4">
              <label>Student</label>
              <Select
                closeMenuOnSelect={false}
                isClearable={true}
                value={this.state.selectedIndividual}
                components={animatedComponents}
                isMulti
                options={this.state.studentList}
                placeholder="Choose Individual Students"
                name="people"
                onChange={this.handleIndividualSelect}
                menuPlacement="auto"
                isLoading={false}
                // isDisabled={this.state.allStudents}
              />
            </div>

            <div className="col-md-2 mx-auto my-auto">
              <button
                className="tt-button tt-button-primary"
                onClick={this.handleGetAttendanceReport}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        {this.state.attendanceReport.length > 0 ? (
          <div className="tt-group-header text-center">
            Attendance report of {this.state.attendanceDate}
          </div>
        ) : (
          ""
        )}
        {this.state.attendanceReport.length > 0
          ? this.state.attendanceReport.map((ar) => (
              <table className="table text-center table-bordered table-striped">
                {/* <thead className="tt-group-header">
                  <tr>
                    <th colSpan={this.calculateColSpan()}>
                      Attendance report of {this.state.attendanceDate}
                    </th>
                  </tr>
                </thead> */}

                <thead className="tt-group-header">
                  <tr>
                    <th colSpan={this.calculateColSpan()}>
                      {/* {this.state.selectedGrade?.name} */}
                      {ar.name}
                    </th>
                  </tr>
                </thead>
                <thead className="tt-group-header">
                  <tr>
                    <th className="text-center">Section</th>
                    <th>Student</th>
                    {/* <th>Subject</th>
              <th>Subject</th>
              <th>Subject</th>
              <th>Subject</th> */}
                    {this.state.subjectList.map((sl) => (
                      <th>{sl.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {ar.classrooms.map((el) =>
                    el.peoples.length > 0 ? (
                      <>
                        <tr>
                          <td
                            rowSpan={this.calculateRowSpan(el)}
                            className="align-middle"
                          >
                            {el.name}
                          </td>
                        </tr>

                        {el.peoples.map((people) => (
                          <tr>
                            <td>{people.name}</td>
                            {this.state.subjectList.map((sl) => (
                              <td>
                                {people.studentAttendances.map((sa) =>
                                  sl.id === sa.subjectId ? (
                                    sa.isPresent ? (
                                      <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        color="#1abc9c"
                                      />
                                    ) : !sa.isPresent ? (
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        color="#a70707"
                                      />
                                    ) : null
                                  ) : null
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </>
                    ) : (
                      // el.studentAttendances.length > 0 ? (
                      //   <>
                      //     <tr>
                      //       <td
                      //         rowSpan={this.calculateRowSpan(el)}
                      //         className="align-middle"
                      //       >
                      //         {el.name}
                      //       </td>

                      //     </tr>

                      //     {el.studentAttendances.map((sa) => (
                      //       <tr>
                      //         <td>{sa.studentName}</td>

                      //         {this.state.subjectList.map((sl) => (
                      //           <td>
                      //             {sl.id === sa.subjectId && sa.isPresent ? (
                      //               <FontAwesomeIcon
                      //                 icon={faCheckCircle}
                      //                 color="#1abc9c"
                      //               />
                      //             ) : sl.id !== sa.subjectId ? (
                      //               "-"
                      //             ) : (
                      //               <FontAwesomeIcon
                      //                 icon={faTimesCircle}
                      //                 color="#a70707"
                      //               />
                      //             )}
                      //           </td>
                      //         ))}
                      //       </tr>
                      //     ))}
                      //   </>
                      // )
                      <tr
                        className="text-center"
                        colSpan={this.calculateColSpan()}
                      >
                        {" "}
                        Attendance Record not found!!
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            ))
          : ""}
      </div>
    );
  }
}

export default DailyAttendanceSummary;
