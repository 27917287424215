import React, { Component } from "react";
import NoteDisplay from "../../../utils/noteDisplay";
import ListAssignedTeacher from "./ListAssignedTeachers";

class TeacherSubjects extends Component {
  state = { yearLevels: null };
  render() {
    return (
      <>
        {this.props.isSettingAvailable ? (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "72.7vh" }}
          >
            <div className="container">
              {this.props.assignedTeachers
                ? this.props.assignedTeachers.map((yearLevel, idx) => {
                    return (
                      <div className="card mb-3">
                        <div className="card-body">
                          <ListAssignedTeacher
                            key={idx}
                            index={idx}
                            yearLevel={yearLevel}
                            classTeachers={this.props.classTeachers}
                            handleChangeAssignSelect={
                              this.props.handleChangeAssignSelect
                            }
                            submitAssignTeachers={
                              this.props.submitAssignTeachers
                            }
                            unAssignTeachers={this.props.unAssignTeachers}
                            cancelUnAssignTeachers={
                              this.props.cancelUnAssignTeachers
                            }
                          ></ListAssignedTeacher>
                        </div>
                      </div>
                    );
                  })
                : "null"}
            </div>
          </div>
        ) : (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "78vh" }}
          >
            <NoteDisplay textContent="To assign Teacher, you must first add Staff. Please add Staff and then assign Teacher to Subjects"></NoteDisplay>
          </div>
        )}
      </>
    );
  }
}

export default TeacherSubjects;
