import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import CustomCollapse from "../UI/CustomCollapse";
import SemesterSubject from "./SemesterSubjectDisplay";

class PhaseSemesterSubjectDisplay extends Component {
  state = {};

  displayTable = (phase) => {
    return <SemesterSubject phase={phase}></SemesterSubject>;
  };

  render() {
    let course = this.props.course;
    return (
      <React.Fragment>
        {course.coursePhases && course.coursePhases.length > 0 ? (
          course.coursePhases.map((phase, idx) => {
            return (
              <div key={idx} className="pl-2 pr-2 pt-2">
                <div className="container-fluid">
                  <div
                    className="row tt-phase-header"
                    onClick={(e) => this.props.togglePhaseDetail(e, idx)}
                  >
                    <div className="col-md-11">{phase.name}</div>
                    <div className="col-md-1 text-center">
                      <FontAwesomeIcon
                        icon={phase.isOpen ? faAngleUp : faAngleDown}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                  <CustomCollapse
                    isOpen={phase.isOpen}
                    collapsibleBody={this.displayTable(phase)}
                    collapseClass="mt-2"
                  ></CustomCollapse>
                </div>
              </div>
            );
          })
        ) : (
          <SemesterSubject phase={this.props.course}></SemesterSubject>
        )}
      </React.Fragment>
    );
  }
}

export default PhaseSemesterSubjectDisplay;
