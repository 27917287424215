import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class GpaTableRow extends Component {
  state = {};
  handleKeyPress = (evt) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };

  render() {
    let gpaProps = this.props.val;
    return (
      <tr key={this.props.idx}>
        <td>
          <input
            type="text"
            name="grade"
            value={gpaProps.grade}
            className={
              gpaProps.errors
                ? gpaProps.errors.grade
                  ? "form-control indicate-error"
                  : "form-control tt-gpa-input"
                : "form-control tt-gpa-input"
            }
            // className="form-control tt-gpa-input"
            onChange={(e) => this.props.handleValueEditable(e, this.props.idx)}
          />
        </td>

        <td className="tt-per-table-data">
          <input
            type="number"
            name="percentageMin"
            value={gpaProps.percentageMin}
            className={
              gpaProps.errors
                ? gpaProps.errors.percentageMin
                  ? "form-control indicate-error"
                  : "form-control tt-per-input-field tt-gpa-input"
                : "form-control tt-per-input-field tt-gpa-input"
            }
            //className="form-control tt-per-input-field tt-gpa-input"
            onChange={(e) => this.props.handleValueEditable(e, this.props.idx)}
            // onKeyPress={this.handleKeyPress}
            // onKeyDown={e => e.preventDefault()}
            // onkeypress={(event) =>
            //   event.charCode == 8 || event.charCode == 0
            //     ? null
            //     : event.charCode >= 48 && event.charCode <= 57
            // }
            step={0.01}
            min="0"
            max="999"
          />
          <span className="tt-gpa-table-to" style={{ padding: "0px 20px" }}>
            to
          </span>
          <input
            type="number"
            name="percentageMax"
            value={gpaProps.percentageMax}
            className={
              gpaProps.errors
                ? gpaProps.errors.percentageMax
                  ? "form-control indicate-error"
                  : "form-control tt-per-input-field tt-gpa-input"
                : "form-control tt-per-input-field tt-gpa-input"
            }
            //className="form-control tt-per-input-field tt-gpa-input"
            onChange={(e) => this.props.handleValueEditable(e, this.props.idx)}
            // onKeyPress={this.handleKeyPress}
            step={0.01}
            min="0"
            max="999"
          />
        </td>

        <td>
          <input
            type="number"
            name="gradePoint"
            value={gpaProps.gradePoint}
            className={
              gpaProps.errors
                ? gpaProps.errors.gradePoint
                  ? "form-control indicate-error"
                  : "form-control tt-gpa-input"
                : "form-control tt-gpa-input"
            }
            // className="form-control tt-gpa-input"
            onChange={(e) => this.props.handleValueEditable(e, this.props.idx)}
            // onKeyPress={this.handleKeyPress}
            step={0.01}
            min="0"
            max="99999"
          />
        </td>

        <td className="tt-per-table-data">
          <input
            type="number"
            name="gpaMin"
            value={gpaProps.gpaMin}
            className={
              gpaProps.errors
                ? gpaProps.errors.gpaMin
                  ? "form-control indicate-error"
                  : "form-control tt-gpa-input tt-per-input-field"
                : "form-control tt-gpa-input tt-per-input-field"
            }
            // className="form-control tt-gpa-input tt-per-input-field"
            onChange={(e) => this.props.handleValueEditable(e, this.props.idx)}
            step={0.01}
            // onKeyPress={this.handleKeyPress}
            min="0"
            max="99999"
          />
          <span className="tt-gpa-table-to" style={{ padding: "0px 20px" }}>
            to
          </span>
          <input
            type="number"
            name="gpaMax"
            value={gpaProps.gpaMax}
            className={
              gpaProps.errors
                ? gpaProps.errors.gpaMax
                  ? "form-control indicate-error"
                  : "form-control tt-gpa-input tt-per-input-field"
                : "form-control tt-gpa-input tt-per-input-field"
            }
            //className="form-control tt-gpa-input tt-per-input-field"
            onChange={(e) => this.props.handleValueEditable(e, this.props.idx)}
            step={0.01}
            // onKeyPress={this.handleKeyPress}
            min="0"
            max="99999"
          />
        </td>

        <td>
          <input
            type="text"
            name="gpaEvaluation"
            value={gpaProps.gpaEvaluation}
            className={
              gpaProps.errors
                ? gpaProps.errors.gpaEvaluation
                  ? "form-control indicate-error"
                  : "form-control tt-gpa-input"
                : "form-control tt-gpa-input"
            }
            //className="form-control tt-gpa-input"
            onChange={(e) => this.props.handleValueEditable(e, this.props.idx)}
            maxLength={40}
          />
        </td>

        <td
          className="tt-added-gpa-remove"
          onClick={() => this.props.handleRemoveGpas(this.props.idx)}
        >
          <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
        </td>

        {/* <td>{this.props.id}</td> */}
      </tr>
    );
  }
}

export default GpaTableRow;
