import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

const WebsiteConfig = () => {
  const [webTitle, setWebTitle] = useState("");
  const [websiteLogo, setWebsiteLogo] = useState(null);
  const [metaDesc, setMetaDesc] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [copyrightInfo, setCopyrightInfo] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [favicon, setFavicon] = useState(null);
  const [configured, setConfigured] = useState(false);
  const [faviconPreview, setFaviconPreview] = useState("");
  const [logoPreview, setLogoPreview] = useState("");
  const [notice, setNotice] = useState(false);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [headerLogoPreview, setHeaderLogoPreview] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [footerText, setFooterText] = useState("");
  const [footerLogo, setFooterLogo] = useState(null);
  const [footerLogoLink, setFooterLogoLink] = useState(null);
  const [footerISOLink, setFooterISOLink] = useState(null);
  const [footerLogoPreview, setFooterLogoPreview] = useState("");
  const [isFooterActive, setIsFooterActive] = useState(false);
  const [schoolResource, setSchoolResource] = useState(false);

  const handleSubmit = () => {
    setShowSpinner(true);

    let formdata = new FormData();

    if (websiteLogo !== null) {
      formdata.append("logo", websiteLogo);
    }

    if (headerLogo !== null) {
      formdata.append("govLogo", headerLogo);
    }

    if (favicon !== null) {
      formdata.append("icon", favicon);
    }
    if (footerLogo !== null) {
      formdata.append("footerLogo", footerLogo);
    }

    let sendData = {
      title: webTitle,
      metaDescription: "",
      metaKeywords: "",
      disclaimer: disclaimer,
      copyrightInformation: "",
      displayNotice: notice,
      footerText: footerText,
      isActiveFooter: isFooterActive,
      displaySchoolResources: schoolResource,
      footerLogoLink: footerLogoLink,
      footerIsoLink: footerISOLink,
    };

    formdata.append("jsonData", JSON.stringify(sendData));

    if (webTitle === "" || favicon === null || websiteLogo === null) {
      setShowSpinner(false);

      swal("Error", "Title, logo and favicon is required");
    } else if (isFooterActive && (footerLogo === null || footerText === "")) {
      swal(
        "Error",
        "Footer logo and footer text is required when it is active"
      );
      setShowSpinner(false);
    } else {
      axiosPost(
        URL.insertWebsiteConfig,
        formdata,
        (response) => {
          if (response.status === 200) {
            setShowSpinner(false);
            swal("Success", "Configuration saved successfully");
            getWebsiteConfig();
            document.getElementById("configFileLogo").value = null;
            document.getElementById("configFileFavIcon").value = null;
            document.getElementById("configFileFooterLogo").value = null;
            document.getElementById("configFileGovLogo").value = null;
          }
        },
        (err) => {
          displayErrorAlert(err);
          setShowSpinner(false);
        }
      );
    }
  };

  const getWebsiteConfig = () => {
    setShowSpinner(true);
    axiosPost(
      URL.getWebsiteConfig,
      {},
      (response) => {
        if (response.status === 200) {
          setShowSpinner(false);

          let data = response.data.data;
          if (data.length > 0) {
            setWebTitle(data[0].title);
            setDisclaimer(data[0].disclaimer);
            setFaviconPreview(data[0].faviconPath);
            setLogoPreview(data[0].logoPath);
            setNotice(data[0].displayNotice);
            setHeaderLogoPreview(data[0].mainLogoPath || "");
            setFooterText(data[0].footerText);
            setFooterLogoPreview(data[0].footerLogo || "");
            setIsFooterActive(data[0].isActiveFooter);
            setSchoolResource(data[0].displaySchoolResources);
            setFooterLogoLink(data[0].footerLogoLink || "");
            setFooterISOLink(data[0].footerIsoLink || "");
            if (data[0].id) {
              setConfigured(true);
            }
          }
        }
      },
      (err) => {
        displayErrorAlert(err);
        setShowSpinner(false);
      }
    );
  };

  const handleUpdate = () => {
    setShowSpinner(true);

    let formdata = new FormData();

    // if (websiteLogo !== null) {
    formdata.append("logo", websiteLogo);
    // } else {
    //   formdata.append("logo", null);
    // }

    // if (favicon !== null) {
    formdata.append("icon", favicon);
    // } else {
    //   formdata.append("icon", null);
    // }

    formdata.append("govLogo", headerLogo);
    formdata.append("footerLogo", footerLogo);

    let sendData = {
      title: webTitle,
      metaDescription: "",
      metaKeywords: "",
      disclaimer: disclaimer,
      copyrightInformation: "",
      displayNotice: notice,
      footerText: footerText,
      isActiveFooter: isFooterActive,
      displaySchoolResources: schoolResource,
      footerLogoLink: footerLogoLink,
      footerIsoLink: footerISOLink,
    };

    formdata.append("jsonData", JSON.stringify(sendData));

    if (
      (isFooterActive && footerLogo === null && footerLogoPreview === "") ||
      footerText === ""
    ) {
      swal(
        "Error",
        "Footer logo and footer text is required when it is active"
      );
      setShowSpinner(false);
    } else {
      axiosPost(
        URL.updateWebsiteConfig,
        formdata,
        (response) => {
          setShowSpinner(false);

          if (response.status === 200) {
            swal("Success", "Configuration saved successfully");
            document.getElementById("configFileLogo").value = null;
            document.getElementById("configFileFavIcon").value = null;
            document.getElementById("configFileFooterLogo").value = null;
            document.getElementById("configFileGovLogo").value = null;

            getWebsiteConfig();
          }
        },
        (err) => {
          displayErrorAlert(err);
          setShowSpinner(false);
        }
      );
    }
  };

  useEffect(() => {
    getWebsiteConfig();
  }, []);

  return (
    <>
      <div className="website-config-container">
        <div className="whiteContainer">
          {/* {showSpinner ? (
            <div className="fullWindow-Spinner">
              <div>
                <Spinner color="white"></Spinner>
              </div>
              <div style={{ fontSize: "16px", marginTop: "15px" }}>
                Please Wait ...
              </div>
            </div>
          ) : null} */}
          <CustomPageHeader title="Configurations" />

          <div className="container">
            <p className="formTitle text-uppercase mt-2">Website Details</p>
            <div className="row">
              <div className="col-md-6 mb-2">
                <label htmlFor="webTitle">Website Title</label>
                <input
                  name="webTitle"
                  className="form-control cusInput shadow-none"
                  type="text"
                  placeholder="Enter website title"
                  value={webTitle}
                  onChange={(e) => setWebTitle(e.target.value)}
                />
              </div>

              <div className="col-md-6 mb-2">
                <label htmlFor="websiteLogo">Website Logo</label>
                <input
                  name="websiteLogo"
                  accept="image/png, image/jpeg, image/jpg"
                  className="form-control cusInput shadow-none"
                  type="file"
                  id="configFileLogo"
                  onChange={(e) => setWebsiteLogo(e.target.files[0])}
                />
                {logoPreview !== "" && configured && websiteLogo ? (
                  <img
                    src={window.URL.createObjectURL(websiteLogo)}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : logoPreview !== "" && configured ? (
                  <img
                    src={URL.imageSelectURL + logoPreview}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : !configured && websiteLogo !== null ? (
                  <img
                    src={window.URL.createObjectURL(websiteLogo)}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 mb-2">
                <label htmlFor="websiteLogo">Government Logo Header</label>
                <input
                  name="headerLogo"
                  accept="image/png, image/jpeg, image/jpg"
                  className="form-control cusInput shadow-none"
                  id="configFileGovLogo"
                  type="file"
                  onChange={(e) => setHeaderLogo(e.target.files[0])}
                />
                {headerLogoPreview !== "" && configured && headerLogo ? (
                  <img
                    src={window.URL.createObjectURL(headerLogo)}
                    alt="no preview available"
                    style={{ width: "100%", objectFit: "contain" }}
                    className="img-fluid"
                  />
                ) : headerLogoPreview !== "" && configured ? (
                  <img
                    src={URL.imageSelectURL + headerLogoPreview}
                    alt="no preview available"
                    style={{ width: "100%", objectFit: "contain" }}
                    className="img-fluid"
                  />
                ) : !configured && headerLogo !== null ? (
                  <img
                    src={window.URL.createObjectURL(headerLogo)}
                    alt="no preview available"
                    style={{ width: "100%", objectFit: "contain" }}
                    className="img-fluid"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 mb-2">
                <label htmlFor="websiteLogo">Favicon</label>
                <input
                  name="favicon"
                  accept="image/png, image/jpeg, image/jpg"
                  className="form-control cusInput shadow-none"
                  id="configFileFavIcon"
                  type="file"
                  onChange={(e) => setFavicon(e.target.files[0])}
                />
                {faviconPreview !== "" && configured && favicon ? (
                  <img
                    src={window.URL.createObjectURL(favicon)}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : faviconPreview !== "" && configured ? (
                  <img
                    src={URL.imageSelectURL + faviconPreview}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : !configured && favicon !== null ? (
                  <img
                    src={window.URL.createObjectURL(favicon)}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* <p className="formTitle text-uppercase mt-2">Meta Details</p>

          <div className="row">
            <div className="col-md-6 mb-2">
              <label htmlFor="metaDesc">Meta Description</label>
              <input
                name="metaDesc"
                className="form-control cusInput shadow-none"
                type="text"
                placeholder="Enter meta description"
              />
            </div>
            <div className="col-md-6 mb-2">
              <label htmlFor="metaKeywords">Meta Keywords</label>
              <input
                name="metaKeywords"
                className="form-control cusInput shadow-none"
                type="text"
                placeholder="Enter meta keywords"
              />
            </div>
          </div> */}

            <p className="formTitle text-uppercase mt-2">
              Disclaimer and Copyright Details
            </p>

            <div className="row">
              <div className="col-md-6 mb-2">
                <label htmlFor="disclaimer">Disclaimer Text (*Optional)</label>
                <input
                  name="disclaimer"
                  className="form-control cusInput shadow-none"
                  type="text"
                  placeholder="Enter disclaimer text"
                  value={disclaimer}
                  onChange={(e) => setDisclaimer(e.target.value)}
                />
              </div>

              {/* <div className="col-md-6 mb-2">
              <label htmlFor="copyrightInfo">Copyright Information</label>
              <input
                name="copyrightInfo"
                className="form-control cusInput shadow-none"
                type="text"
                placeholder="Enter copyright information"
              />
            </div> */}
            </div>

            <p className="formTitle text-uppercase mt-2">Notice Section</p>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="disclaimer">Display Notices</label>
                <label className="switch ml-4">
                  <input
                    type="checkbox"
                    onChange={(e) => setNotice(e.target.checked)}
                    checked={notice}
                    name="notice"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <p className="formTitle text-uppercase mt-2">Footer Details</p>

            <div className="row">
              <div className="col-md-6 mb-2">
                <label htmlFor="footerText">Footer Text</label>
                <input
                  name="footerText"
                  className="form-control cusInput shadow-none"
                  type="text"
                  placeholder="Enter footer text"
                  value={footerText}
                  onChange={(e) => setFooterText(e.target.value)}
                />
              </div>

              <div className="col-md-6 mb-2">
                <label htmlFor="footerLogo">Footer Logo</label>
                <input
                  name="footerLogo"
                  accept="image/png, image/jpeg, image/jpg"
                  className="form-control cusInput shadow-none"
                  id="configFileFooterLogo"
                  type="file"
                  onChange={(e) => setFooterLogo(e.target.files[0])}
                />
                {footerLogoPreview !== "" && configured && footerLogo ? (
                  <img
                    src={window.URL.createObjectURL(footerLogo)}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : configured && footerLogo ? (
                  <img
                    src={window.URL.createObjectURL(footerLogo)}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : footerLogoPreview !== "" && configured ? (
                  <img
                    src={URL.imageSelectURL + footerLogoPreview}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : !configured && footerLogo !== null ? (
                  <img
                    src={window.URL.createObjectURL(footerLogo)}
                    alt="no preview available"
                    className="img-thumbnail"
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-6 mb-2">
                <label htmlFor="footerText">Footer Logo Link</label>
                <input
                  name="footerText"
                  className="form-control cusInput shadow-none"
                  type="text"
                  placeholder="Enter footer logo link"
                  value={footerLogoLink}
                  onChange={(e) => setFooterLogoLink(e.target.value)}
                />
              </div>

              {/* <div className="col-md-6">
                <label htmlFor="disclaimer">Display Footer</label>
                <label className="switch ml-4">
                  <input
                    type="checkbox"
                    onChange={(e) => setIsFooterActive(e.target.checked)}
                    checked={isFooterActive}
                    name="isFooterActive"
                  />
                  <span className="slider round"></span>
                </label>
              </div> */}

              {/* <div className="col-md-6 mb-2">
                <label htmlFor="footerText">Footer ISO Link</label>
                <input
                  name="footerText"
                  className="form-control cusInput shadow-none"
                  type="text"
                  placeholder="Enter footer iso link"
                  value={footerISOLink}
                  onChange={(e) => setFooterISOLink(e.target.value)}
                />
              </div> */}
            </div>

            {/* <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="disclaimer">Display Footer</label>
                <label className="switch ml-4">
                  <input
                    type="checkbox"
                    onChange={(e) => setIsFooterActive(e.target.checked)}
                    checked={isFooterActive}
                    name="isFooterActive"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div> */}

            <p className="formTitle text-uppercase mt-3">
              School Resources Details
            </p>

            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="disclaimer">Display School Resources</label>
                <label className="switch ml-4">
                  <input
                    type="checkbox"
                    onChange={(e) => setSchoolResource(e.target.checked)}
                    checked={schoolResource}
                    name="schoolResource"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <div className="text-right mt-3">
              {configured ? (
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteConfig;
