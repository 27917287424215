import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { displayConfirmDeleteAlert } from "../../utils/Utils";

export default class AddressClusterField extends Component {
  render() {
    // let addressClusterFields = this.props.addressClusterFields,
    let addressClusterField = this.props.addressClusterField,
      addressClusterFieldLables = this.props.addressClusterFieldLables,
      index = this.props.addressClusterFieldIndex;
    return (
      <div className="form-group row">
        <div className="col-md-1"></div>
        <div className="col-md-4">
          <input
            type="text"
            className={
              "form-control " +
              (addressClusterField.isMandatory ? "tt-bold" : "")
            }
            name="label"
            list="labels"
            value={addressClusterField.fieldName}
            maxLength="255"
            onChange={(e) =>
              this.props.handleAddressClusterFieldLabelChange(
                e,
                addressClusterField
              )
            }
            // value={index}
          />
          <datalist id="labels">
            {addressClusterFieldLables.map((label) => (
              <option value={label}>{label}</option>
            ))}
          </datalist>
        </div>
        <div className="col-md-5">
          <input
            type="text"
            className="form-control"
            name="value"
            maxLength="255"
            value={addressClusterField.exampleValue}
            onChange={(e) =>
              this.props.handleAddressClusterFieldExampleValueChange(
                e,
                addressClusterField
              )
            }
          />
        </div>
        {index > 0 ? (
          <div className="col-md-1">
            <input
              className="mandatory-checkbox"
              type="checkbox"
              checked={this.props.addressClusterFields[index].isMandatory}
              onChange={(e) =>
                this.props.handleCheckBoxChange(e, index, "addressField")
              }
            />
          </div>
        ) : null}
        {index !== 0 ? (
          <>
            <div className="col-md-1">
              <button
                className="tt-button tt-button-danger"
                onClick={() =>
                  displayConfirmDeleteAlert(
                    { index, addressClusterField },
                    this.props.handleRemoveAddressClusterField
                  )
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
