import React, { Component } from "react";

class Electrical extends Component {
  state = {};
  render() {
    return <div>Electrical</div>;
  }
}

export default Electrical;
