import React from "react";
import userImage from "../../assets/images/user-image.png";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

import { handleError } from "../../utils/Utils";

function DashboardGroupMeet(props) {
  const {
    groupId,
    groups,
    groupMembers,
    fetchingGroupMembers,
    handleGroupDiscussionChange,
    handleMeeting,
  } = props;

  const renderGroupMembers = () => {
    if (groupMembers.length > 0) {
      return groupMembers.map((member, idx) => {
        const role =
          member.userRole === "RL_STC"
            ? "Student Contact"
            : member.userRole === "RL_STD"
            ? "Student"
            : member.userRole === "RL_ADM"
            ? "Admin"
            : member.userRole === "RL_OTH"
            ? "Others"
            : "Staffs";

        return (
          <div key={idx}>
            <div
              className="tt-group-holder"
              id={idx}
              data-tip={`${member.name} [${role}]`}
            >
              <div className="tt-memeber-img">
                <img src={userImage} onError={(e) => handleError(e)} />
              </div>
              <div className="tt-member-name">{member.name}</div>
            </div>
          </div>
        );
      });
    }

    if (fetchingGroupMembers) {
      return (
        <div className="text-center mt-3 mb-3">
          <Spinner color="primary" /> <br />
          Loading Data ...
        </div>
      );
    }

    if (groupId !== "") {
      return <p>No members found</p>;
    }

    return <p>Select a group first</p>;
  };

  return (
    <div className="whiteContainer">
      <div className="listBox" style={{ flexDirection: "column" }}>
        <p className="listTitle">Groups</p>
        <div>
          <select
            name="groupId"
            value={groupId}
            className="form-control cusInputSelect"
            onChange={handleGroupDiscussionChange}
          >
            <option value="" disabled>
              Choose a group
            </option>
            {groups &&
              groups.map((g, idx) => (
                <option key={idx} value={g.id}>
                  {g.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div
        id="tt-groupContainer"
        className="tt-group-container mt-0"
        style={{ boxShadow: "unset", height: "auto" }}
      >
        <div
          className="tt-group-box"
          style={{ height: "195px", border: "1px solid #ccc" }}
        >
          {renderGroupMembers()}
        </div>
        <div className="row mt-2">
          <div className="col text-right">
            <Link
              to={{
                pathname: "/discussion",
                state: {
                  noNav: true,
                  groupId: groupId,
                },
              }}
            >
              <button
                className="btn btn-primary cusBtn"
                style={{ fontSize: "12px" }}
              >
                Discussion Page
              </button>
            </Link>
            <button
              className="btn btn-primary cusBtn ml-3"
              onClick={handleMeeting}
              style={{ fontSize: "12px" }}
            >
              Create a Meet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardGroupMeet;
