import React, { Component } from "react";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, postType, userRole, userRoleCode } from "../../utils/Constants";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import { MESSAGEHEADER } from "../../utils/DisplayMessage";
import Discussion from "../Profile/PostSection/Discussion";

class CentralDiscussionPostForm extends Component {
  state = {
    heading: "General Notice",
    files: [],
    textContent: "",
    media: "",
    selectedMedias: [],
    links: "",
    selectedLinks: [],
    selectedOrigin: "",
    url: "",
    selectedURL: "",
    fileSize: 0,
    selectedGroups: [],
    selectedArticleGroups: [],
    selectedMediaGroups: [],
    articleKeywords: "",
    mediaKeywords: "",
    themes: null,
    addThemeModal: false,
    addQuestionModal: false,
    theme: "",
    description: "",
    themeList: [],
    questions: [
      {
        question: "",
      },
    ],
    postTypeCode: "",
    questionsForMedia: [
      {
        question: "",
      },
    ],
    isAcademicRepo: false,
    tabName: "",
    selectedIndividual: [],
    isSubmitting: false,
    showSpinner: false,
    error: false,
    insertedInsitePost: [],
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "heading") {
      if (value.length > 100) {
        swal({
          title: "Warning",
          text: "Heading cannot be more than 100 words",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleTextEditor = (value) => {
    this.setState({ textContent: value });
  };

  clearStates = (tabName) => {
    this.setState({
      heading:
        tabName === "article" || tabName === "media" || tabName === "discussion"
          ? ""
          : "General Notice",
      files: [],
      textContent: "",
      media: "",
      selectedMedias: [],
      links: "",
      selectedLinks: [],
      selectedOrigin: "",
      url: "",
      selectedURL: "",
      fileSize: 0,
      selectedGroups: [],
      selectedArticleGroups: [],
      selectedMediaGroups: [],
      articleKeywords: "",
      mediaKeywords: "",
      themes: null,
      postTypeCode: "",
      tabName: tabName,
    });
  };

  submitPost = (e, code, groupPost, familyPost) => {
    e.preventDefault();
    let url;
    if (this.props.groupId && this.props.groupId !== "") {
      this.setState({ isSubmitting: true, showSpinner: true }, () => {
        if (this.state.tabName === "discussion") {
          if (this.state.heading === "" && this.state.textContent === "") {
            swal({
              title: MESSAGEHEADER.error,
              text: "Heading and Content cannot be empty",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            this.setState({ showSpinner: false, isSubmitting: false });
            return false;
          } else {
            var data = {
              post: {
                postType: code,
                heading: this.state.heading,
                textContent: this.state.textContent,
              },
            };
            if (this.props.groupPost) {
              data.groupId = this.props.groupId;
            }

            url = this.props.groupPost ? URL.insertGroupPost : "";

            axiosPost(
              url,
              data,
              (response) => {
                if (response.status === 200) {
                  this.setState({ showSpinner: false });
                  this.props.toggleModal();
                  this.props.getDiscussionsByGroup(this.props.groupId);
                  swal(
                    MESSAGEHEADER.success,
                    "Discussion succesfully posted.",
                    ""
                  );
                }
              },
              (error) => {
                this.setState({ showSpinner: false });
              }
            );
          }
        } else if (this.state.heading === "" && this.state.textContent !== "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Heading cannot be null",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
        } else if (this.state.textContent === "" && this.state.heading !== "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Content cannot be null",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
        } else if (this.state.textContent === "" && this.state.heading === "") {
          swal({
            title: MESSAGEHEADER.error,
            text: "Content and Heading cannot be null",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
        }
      });
    } else {
      swal("warning", "Please select a group first.", "");
    }
  };

  render() {
    return (
      <>
        <div className={"tt-postTabs"}>
          <Tabs onChange={(tabId) => this.clearStates(tabId)}>
            <TabList>
              <Tab tabFor="discussion">Discussion</Tab>
            </TabList>
            <>
              <TabPanel tabId="discussion">
                <Discussion
                  heading={this.state.heading}
                  textContent={this.state.textContent}
                  submitPost={this.submitPost}
                  handleTextEditor={this.handleTextEditor}
                  isAcademicRepo={false}
                  handleChange={this.handleChange}
                  groupPost={false}
                  familyPost={false}
                />
              </TabPanel>
            </>
          </Tabs>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Posting ...
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default CentralDiscussionPostForm;
