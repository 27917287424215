import React, { useState, useEffect } from "react";
import icon02 from "../../assets/images/icon-02.png";
import { axiosPost } from "../../utils/AxiosApi";
import { postType, URL } from "../../utils/Constants";
import { ReactPhotoCollage } from "react-photo-collage";
import CustomButton from "../common/CustomButton";
import { Link } from "react-router-dom";
import ModalWindow from "../UI/ModalWindow";
import moment from "moment";
import NoticeFileViewer from "../../pages/Notices/NoticeFileViewer";
import ReactHtmlParser from "react-html-parser";
import indLoginOthers from "../../assets/video/IndLogin.mp4";
import indLoginTeacher from "../../assets/video/IndLoginTeacher.mp4";
import signUpParent from "../../assets/video/ParentsSignUp.mp4";
import signUpStudent from "../../assets/video/StudentSignUp.mp4";
import signUpTeacher from "../../assets/video/TeacherSignUp.mp4";
import centralAdminLogin from "../../assets/video/centralAdminLogin.mp4";
import screen1 from "../../assets/video/screenshots/screen1.png";
import screen2 from "../../assets/video/screenshots/screen1.png";
import VideoPlayer from "./VideoPlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import manualFile from "../../assets/manual.pdf";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { connect } from "react-redux";

const Content = (props) => {
  const [sliderList, setSliderList] = useState();
  const [coreFeatures, setCoreFeatures] = useState();
  const [trainingList, setTrainingList] = useState([]);
  // const [trainingCount, setTrainingCount] = useState();
  const [collageSetting, setCollageSetting] = useState("");
  const [manualFileViewer, setManualFileViewer] = useState(false);

  const [activeNoticeFile, setActiveNoticeFile] = useState([]);
  const [resourceModal, setResourceModal] = useState(false);
  const [activeNotice, setActiveNotice] = useState(null);
  const [trainingVideos, setTrainingVideos] = useState([
    {
      title: "Individual Login - Central Admin",
      url: centralAdminLogin,
      screenshot: screen1,
    },
    {
      title: "Individual Login - Others",
      url: indLoginOthers,
      screenshot: screen1,
    },
    {
      title: "Individual Login - Teacher",
      url: indLoginTeacher,
      screenshot: screen1,
    },
    {
      title: "Sign Up - Teacher",
      url: signUpTeacher,
      screenshot: screen2,
    },
    {
      title: "Sign Up - Student",
      url: signUpStudent,
      screenshot: screen2,
    },
    {
      title: "Sign Up - Parent",
      url: signUpParent,
      screenshot: screen2,
    },
  ]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoModal, setVideoModal] = useState(false);

  const handleViewResources = (notice) => {
    setActiveNotice(notice);
    setActiveNoticeFile(notice.contents);
    setResourceModal(true);
  };

  const toggleFileViewer = () => {
    setResourceModal(!resourceModal);
  };

  const getAllSliderData = () => {
    axiosPost(
      URL.getAllslider,
      {},
      (response) => {
        if (response.status === 200) {
          setSliderList(response?.data?.data);
        }
      },
      (err) => {},
      true
    );
  };

  const toggleManualViewer = () => {
    setManualFileViewer(!manualFileViewer);
  };

  const getAllCoreFeatures = () => {
    axiosPost(
      URL.getAllFeature,
      {},
      (response) => {
        if (response.status === 200) {
          setCoreFeatures(response?.data?.data);
        }
      },
      (err) => {},
      true
    );
  };

  const getAllTrainingData = () => {
    axiosPost(
      URL.getAllTraining,
      {},
      (response) => {
        if (response.status === 200) {
          setTrainingList(response?.data?.data);
        }
      },
      (err) => {},
      true
    );
  };

  const getRandomTrainingImg = () => {
    axiosPost(URL.getRandomCollageImg, {}, (response) => {
      let imgData = [];
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          imgData = response.data.data.map((data) => {
            return { source: URL.imageSelectURL + data.name };
          });
        }
        let collageData = {
          width: "auto",
          height: ["180px", "160px"],
          layout: [1, 3],
          photos: imgData,
          showNumOfRemainingPhotos: true,
        };
        setCollageSetting(collageData);
      }
    });
  };

  const toggleVideoPlayer = (data) => {
    setSelectedVideo(data);
    setVideoModal(!videoModal);
  };

  const closeVideoPlayer = () => {
    setSelectedVideo(null);
    setVideoModal(!videoModal);
  };

  useEffect(() => {
    getAllSliderData();
    getAllCoreFeatures();
    getAllTrainingData();
    getRandomTrainingImg();
  }, []);

  return (
    <>
      <section
        className="whiteContainer-01 p-0"
        style={{ background: "#f1f9fc", borderRadius: 0, boxShadow: "unset" }}
      >
        <div className="container">
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade"
            data-ride="carousel"
          >
            <div className="carousel-inner carousel-setting">
              {sliderList &&
                sliderList
                  ?.filter((s) => s.isActive === true)
                  .map((items, idx) => {
                    return (
                      <div
                        className={
                          "carousel-item " + (idx === 0 ? "active" : "")
                        }
                        key={idx}
                        style={{ position: "relative" }}
                      >
                        <img
                          src={`${URL?.imageSelectURL}${items?.imagePath}`}
                          className="d-block w-100"
                          alt={items.title}
                        />
                        {items.description !== "" || items.title !== "" ? (
                          <div className="heading-06 carouselCaption">
                            <h1 style={{ fontSize: "2rem" }}>{items?.title}</h1>
                            <div
                              className="horizontal-line"
                              style={{ width: "50%", marginBottom: "10px" }}
                            ></div>
                            <p>{items?.description}</p>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-target="#carouselExampleFade"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              {/* <span className="visually-hidden">Previous</span> */}
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-target="#carouselExampleFade"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              {/* <span className="visually-hidden">Next</span> */}
            </button>
          </div>
        </div>
      </section>

      {/* mainfeatuire */}
      <section className="white-background">
        <div className="container">
          <h3 className="heading-03">
            <strong>
              {props.lang === "nameEn" ? "FEATURES" : "विशेषताहरु"}
            </strong>
          </h3>
          <div className="horizontal-line mb-3"></div>
          <div className="text-center">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ flexWrap: "wrap" }}
            >
              {coreFeatures &&
                coreFeatures
                  ?.filter((cf) => cf.display === true)
                  .map((items, indx) => {
                    return (
                      <div
                        className="cdc-center m-4 text-center popUpHover"
                        key={indx}
                      >
                        {/* <div className="back-shadow mb-2"> */}
                        <div className="icon-image new-height">
                          <img
                            className=""
                            src={`${URL.imageSelectURL}${items?.imagePath}`}
                            alt="icon image"
                          />
                        </div>
                        {/* </div> */}
                        <small
                          className="heading-06 text-center"
                          style={{ color: "#25272f" }}
                        >
                          <b>{items?.name}</b>
                        </small>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </section>

      {/* training section */}

      <section
        className="whiteContainer-01 p-0"
        style={{ background: "#f1f9fc", borderRadius: 0, boxShadow: "unset" }}
      >
        <div
          className="container"
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        >
          <h3 className="heading-03">
            <strong>
              {props.lang === "nameEn"
                ? "TRAININGS / TUTORIALS"
                : "तालिमहरू / ट्यूटोरियलहरू"}
            </strong>
          </h3>
          <div className="horizontal-line mb-4"></div>
          <div className="row training-container">
            <div className="col-md-4 border-black-right">
              <div className="current-training-image">
                {trainingList.length > 0 && collageSetting !== "" ? (
                  <ReactPhotoCollage {...collageSetting} />
                ) : (
                  ""
                )}

                {/* <img src={icon09} alt="training image" /> */}
              </div>
            </div>
            <div className="col-md-5 border-black-right trainingScrollable">
              <div className="row">
                {trainingVideos.map((el, idx) => {
                  return (
                    <div className="col-md-6">
                      <div
                        className="card mb-4 trainingVideoBox"
                        onClick={() => toggleVideoPlayer(el)}
                      >
                        <div className="cdc-center">
                          <div
                            className="icon-image mb-2 position-relative"
                            style={{ height: "90px" }}
                          >
                            <div className="videoOverlay"></div>
                            <img
                              src={el.screenshot}
                              // onError={(e) => (e.target.src = icon02)}
                              alt="icon05"
                              style={{ objectFit: "cover", width: "100%" }}
                            />
                            <FontAwesomeIcon
                              icon={faCirclePlay}
                              className="videoPlayIcon"
                            />
                          </div>
                          <div className="text-center">
                            <h6
                              className="heading-06 truncateLines-2 "
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              {el.title}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div className="row p-3">
                {trainingList.length > 0 &&
                  trainingList.map(
                    (tl, tIdx) =>
                      tIdx < 6 && (
                        <div className="col-md-4">
                          <div
                            className="card mb-4"
                            style={{ padding: "15px" }}
                          >
                            <div className="cdc-center">
                              <div className="icon-image mb-2">
                                <img
                                  src={icon02}
                                  // src={`${URL.imageSelectURL}${tl.imagePath}`}
                                  onError={(e) => (e.target.src = icon02)}
                                  alt="icon05"
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                              <div className="text-center">
                                <h6 className="heading-06">{tl.name}</h6>
                                <small className="ml-2 truncateLines">
                                  {tl.description}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div> */}
            </div>
            <div className="col-md-3 trainingScrollable">
              <h4 style={{ color: "#4878ae" }} className="mb-3">
                {props.lang === "nameEn"
                  ? "User Manuals"
                  : "प्रयोगकर्ता पुस्तिकाहरू"}
              </h4>
              <div className="file-box" onClick={() => toggleManualViewer()}>
                <div className="fileIconDiv">
                  <FontAwesomeIcon icon={faFileAlt} />
                </div>
                <div className="manualTitle">
                  <p className="manualFiles-item">Manual File</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* notices section */}

      {props.noticeDisplay ? (
        <section className="white-background">
          <div className="container">
            <h3 className="heading-03">
              <strong>
                {props.lang === "nameEn" ? "Notices" : "सूचनाहरू"}
              </strong>
            </h3>
            <div className="horizontal-line mb-3"></div>
            <div>
              <div className="noticeBox-container row">
                {props.noticeList &&
                  props.noticeList
                    // .filter((nl) => nl.postType !== postType.videoConference)
                    .slice(0, 6)
                    ?.map((items, indx) => {
                      return (
                        <div className="col-md-6">
                          <div
                            className="noticeBox"
                            onClick={() => handleViewResources(items)}
                          >
                            <div className="notice-date">
                              <div className="text-center">
                                <span
                                  className="material-icons-round loginIcon mt-2"
                                  aria-hidden="true"
                                >
                                  calendar_month
                                </span>
                                <br />
                                {moment(items.createdAt).format("ll")}
                              </div>
                            </div>
                            <div className="notice-data">
                              <div>
                                <p className="truncateLines-2">
                                  {items.heading}
                                </p>
                                {/* <small
                            dangerouslySetInnerHTML={{
                              __html: items.textContent,
                            }}
                          ></small> */}
                                <small className="truncateLines-2">
                                  {ReactHtmlParser(items.textContent)}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
              {props.noticeList?.length > 0 ? (
                <div className="text-right mt-2">
                  <Link to="/all-notices">
                    <CustomButton
                      text="View All Notices"
                      customStyle="cusBtnFontSize"
                    ></CustomButton>
                  </Link>
                </div>
              ) : (
                <div className="text-center">No notices published.</div>
              )}
            </div>
          </div>
          <ModalWindow
            modal={resourceModal}
            modalClass="fullModal"
            toggleModal={toggleFileViewer}
            size="lg"
            modalHeader={activeNotice?.heading}
            modalBody={
              <>
                <p className="py-3">
                  {" "}
                  {ReactHtmlParser(activeNotice?.textContent)}
                </p>
                {activeNoticeFile.length > 0 && (
                  <NoticeFileViewer activeNoticeFile={activeNoticeFile} />
                )}
              </>
            }
          />
          <ModalWindow
            modal={videoModal}
            toggleModal={closeVideoPlayer}
            size="xl"
            modalHeader={selectedVideo ? selectedVideo.title : null}
            modalBody={
              <VideoPlayer
                fileType="mp4"
                filePath={selectedVideo ? selectedVideo.url : null}
              />
            }
          />

          <ModalWindow
            modal={manualFileViewer}
            toggleModal={toggleManualViewer}
            size="xl"
            modalHeader="Manual File"
            modalBody={
              <div className="manualViewer">
                {/* <FileViewer fileType="pdf" filePath={manualFile} /> */}

                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={[{ uri: manualFile }]}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                    pdfZoom: {
                      defaultZoom: 0.5, // 1 as default,
                      zoomJump: 0.2, // 0.1 as default,
                    },
                    pdfVerticalScrollByDefault: true,
                  }}
                />
              </div>
            }
          />
        </section>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Content);
