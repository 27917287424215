import React, { Component } from "react";
import { Spinner } from "reactstrap";
import GroupSearchResult from "./GroupSearchResult";
import ResourceSearchResult from "./ResourceSearchResult";
import UserSearchResult from "./UserSearchResult";

class SearchResult extends Component {
  state = {};
  render() {
    return (
      <>
        {this.props.searching ? (
          <React.Fragment>
            <div className="tt-group-header">
              Search Result [
              {this.props.searchData && this.props.searchData.length} results
              found]
            </div>
            {this.props.searchData && this.props.searchData.length > 0 ? (
              <div>
                {this.props.searchType === "Group Page" ? (
                  <GroupSearchResult searchData={this.props.searchData} />
                ) : this.props.searchType === "User Profile" ? (
                  <UserSearchResult
                    searchData={this.props.searchData}
                    handleError={this.props.handleError}
                  />
                ) : (
                  <ResourceSearchResult searchData={this.props.searchData} />
                )}
              </div>
            ) : (
              <div className="row">
                <div className="col text-center p-4">
                  {this.props.spinner ? (
                    <>
                      <Spinner></Spinner>
                      <br />
                      Loading Data ...
                    </>
                  ) : (
                    "No Data"
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        ) : null}
      </>
    );
  }
}

export default SearchResult;
