import { cloneDeep } from "lodash";
import { months } from "moment";
import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/Utils";
import ModalWindow from "../UI/ModalWindow";
import BillAddInventoryModalBody from "./BillAddInventoryModalBody";
import BillStudentList from "./BillStudentList";
import GeneratePaymentBills from "./GeneratePaymentBills";
import StudentAccountConfig from "./StudentAccountConfig";

const animatedComponents = makeAnimated();

export class DraftBills extends Component {
  state = {
    draftBills: [],
    addNew: false,
    allChecked: false,
    allInventoriesChecked: false,
    checkAllItem: false,
    configType: "monthly",
    disableAmount: false,
    feeCategory: [],
    generalAmount: "",
    generalFee: "",
    generalFeeCategory: [],
    generalNewCategoryName: "",
    inventoryItems: [],
    inventoryModal: false,
    isDraftBill: false,
    loader: false,
    monthConfiguration: null,
    monthConfigurationId: null,
    monthModal: false,
    months: null,
    selectedFeeCategory: [],
    selectedInventoryItems: [],
    selectedMonths: null,
    selectedStudent: "",
    selectedStudentIndex: "",
    students: [],
    yearlevelId: "",
    yearLevels: null,
    removedStudentInventoryItemIds: [],
  };

  componentDidMount() {
    this.getYearLevels();
    this.getMonthsByConfiguration();
    this.getAllFeeCategory();
    this.getGeneralFeeCategory();
  }

  addCategory = () => {
    if (
      this.state.generalFee === "" ||
      this.state.generalAmount === "" ||
      (this.state.addNew && this.state.generalFee?.value === "")
    ) {
      swal("Error", "Fill the Name and Amount first");
      return false;
    }
    let students = [...this.state.students];
    let selectedFeeCategory = [...this.state.selectedFeeCategory];

    if (!this.state.addNew) {
      let generalFeeCategory = this.state.generalFeeCategory.filter(
        (el) => el.id === parseInt(this.state.generalFee.id)
      );
      students.forEach((el) => {
        if (el.id === parseInt(this.state.selectedStudent)) {
          el.feeCategory.push({
            id: generalFeeCategory[0].feeCategoryId,
            quantity: generalFeeCategory[0].quantity
              ? generalFeeCategory[0].quantity
              : null,
            amount: this.state.generalAmount,
            originalPrice: generalFeeCategory[0].originalPrice
              ? generalFeeCategory[0].originalPrice
              : null,
            inventoryItemId: generalFeeCategory[0].inventoryItemId
              ? generalFeeCategory[0].inventoryItemId
              : null,
            category: generalFeeCategory[0].feeCategoryName,
            type: generalFeeCategory[0].type,
            months: null,
          });
        }
      });
      selectedFeeCategory.push({
        type: generalFeeCategory[0].type,
        months: null,
        id: generalFeeCategory[0].feeCategoryId,
        quantity: generalFeeCategory[0].quantity
          ? generalFeeCategory[0].quantity
          : null,
        amount: this.state.generalAmount,
        originalPrice: generalFeeCategory[0].originalPrice
          ? generalFeeCategory[0].originalPrice
          : null,
        inventoryItemId: generalFeeCategory[0].inventoryItemId
          ? generalFeeCategory[0].inventoryItemId
          : null,
        category: generalFeeCategory[0].feeCategoryName,
      });
    } else {
      students.forEach((el) => {
        if (el.id === parseInt(this.state.selectedStudent)) {
          el.feeCategory.push({
            id: null,
            quantity: null,
            amount: this.state.generalAmount,
            originalPrice: null,
            inventoryItemId: null,
            category: this.state.generalFee?.value,
          });
        }
      });
      selectedFeeCategory.push({
        id: null,
        quantity: null,
        amount: this.state.generalAmount,
        originalPrice: null,
        inventoryItemId: null,
        category: this.state.generalFee?.value,

        type: "general",
      });
    }
    this.setState({
      students,
      generalFee: "",
      generalAmount: "",
      disableAmount: false,
      addNew: false,
      generalNewCategoryName: "",
      selectedFeeCategory,
    });
  };
  cancelNewCategory = () => {
    this.setState({
      generalFee: "",
      generalAmount: "",
      disableAmount: false,
      addNew: false,
      generalNewCategoryName: "",
    });
  };

  getMonthsByConfiguration = () => {
    axiosPost(URL.getMonthByConfiguration, {}, (response) => {
      if (response.status === 200) {
        let months = response.data.data;
        if (months && months.length > 0) {
          months = months.map((m) => {
            m.label = m.english || m.nepali;
            m.value = m.id;
            return m;
          });
        }
        this.setState({ months });
      }
    });
  };

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          yearLevels: response.data.data,
        });
      }
    });
  };

  getStudentAsPerYearLevel = () => {
    let param = {
      yearLevelId: this.state.yearlevelId,
    };
    axiosPost(URL.getStudentAsPerYearLevel, param, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        let selectedStudentIndex = "";

        if (datum.length > 0) {
          datum.forEach((el) => {
            el.isChecked =
              this.state.draftBills.filter((fc) => fc.admissionId === el.id)
                .length > 0
                ? true
                : false;

            el.isActive = false;
          });
          datum[0].isActive = true;
          selectedStudentIndex = 0;
        }
        this.setState({ students: datum, selectedStudentIndex }, function () {
          this.getFeeCategoryConfiguration();
        });
      }
    });
  };

  getFeeCategoryConfiguration = () => {
    let param = {
      yearLevelId: this.state.yearlevelId,
      type: this.state.configType,
    };
    axiosPost(URL.selectFeeCategoryConfiguration, param, (response) => {
      if (response.status === 200) {
        let config = response.data.data;
        let selectedFeeCategory = [];
        let selectedStudent = "";
        let selectedInventoryItems = [];
        let categoriesList = cloneDeep(this.state.feeCategory);
        let newCategory = [];
        categoriesList.forEach((cat) => {
          const findConfig = config.find((c) => c.feeCategoryId === cat.id);
          if (findConfig) {
            // cat.checked = true;
            cat.amount = findConfig.amount;
            newCategory.push(cat);
          }
        });
        let students = [...this.state.students];
        if (students.length > 0) {
          students.forEach((el) => {
            el.feeCategory =
              this.state.draftBills.filter((fc) => fc.admissionId === el.id)
                .length > 0
                ? this.state.draftBills.filter(
                    (fc) => fc.admissionId === el.id
                  )[0].studentBillFeeCategories
                : JSON.parse(JSON.stringify(newCategory));
            // ||    JSON.parse(JSON.stringify(newCategory));
            if (el.studentInventoryItems.length > 0) {
              el.studentInventoryItems.forEach((si) => {
                si.isChecked = false;
              });
            }
          });
          selectedFeeCategory = students[0].feeCategory;
          selectedStudent = students[0].id;
          selectedInventoryItems = students[0].studentInventoryItems;
        }
        this.setState({
          students,
          selectedFeeCategory,
          selectedStudent,
          selectedInventoryItems,
        });
      }
    });
  };

  handleActiveStatus = (idx) => {
    let students = [...this.state.students];
    let selectedStudent = this.state.selectedStudent;
    students.forEach((el) => {
      if (el.id === selectedStudent) {
        el.feeCategory = this.state.selectedFeeCategory;
      }
    });
    this.setState({ students }, function () {
      students.forEach((el) => {
        el.isActive = false;
      });
      if (!students[idx].isActive) {
        students[idx].isActive = true;
        this.setState({
          selectedFeeCategory: students[idx].feeCategory,
          selectedStudent: students[idx].id,
          selectedStudentIndex: idx,
          selectedInventoryItems: students[idx].studentInventoryItems,
        });
      }

      this.setState({ students, checkAllItem: false });
    });
  };

  handleAllInventoriesChecked = () => {
    let students = [...this.state.students];
    students.forEach((el) => {
      if (el.studentInventoryItems.length > 0) {
        el.studentInventoryItems.forEach((item) => {
          item.isChecked = this.state.allInventoriesChecked;
          if (this.state.allInventoriesChecked) {
            let checkDuplicate = el.feeCategory.filter(
              (fee) => fee.inventoryItemId === item.inventoryItemId
            );
            if (checkDuplicate.length === 0) {
              el.feeCategory.push({
                id: item.feeCategoryId,
                quantity: item.quantity ? item.quantity : null,
                amount: item.amount,
                originalPrice: item.originalPrice ? item.originalPrice : null,
                inventoryItemId: item.inventoryItemId
                  ? item.inventoryItemId
                  : null,
                category: item.feeCategoryName,
                inventoryItemName: item.itemName ? item.itemName : null,
              });
            }
          } else {
            el.feeCategory = el.feeCategory.filter((fl) => !fl.inventoryItemId);
          }
        });
      }
    });
    this.setState({ students }, function () {
      let activeStudent = this.state.students.filter(
        (s) => s.id === parseInt(this.state.selectedStudent)
      );
      this.setState({
        selectedFeeCategory: activeStudent[0].feeCategory,
      });
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "yearlevelId") {
        this.resetFeeData();
      }
      if (name === "allChecked") {
        this.manageAllChecked();
      }
      if (name === "configType") {
        if (value === "annually") {
          let params = {
            yearLevelId: this.state.yearlevelId,
          };

          axiosPost(URL.getDraftBills, params, (response) => {
            if (response.status === 200) {
              this.setState(
                {
                  draftBills: response.data.data,
                },
                () => {
                  this.getStudentAsPerYearLevel();
                }
              );
            }
          });
        }
      }
      // if (name === "generalFee") {
      //   if (value === "new") {
      //     this.setState({ addNew: true, generalAmount: "" });
      //   } else {
      //     let generalFeeCategory = [...this.state.generalFeeCategory];
      //     generalFeeCategory.forEach((el) => {
      //       if (el.id == this.state.generalFee) {
      //         if (el.amount) {
      //           this.setState({
      //             generalAmount: el.amount,
      //             // disableAmount: true,
      //             addNew: false,
      //           });
      //         }
      //       }
      //     });
      //   }
      // }
      if (name === "allInventoriesChecked") {
        this.handleAllInventoriesChecked();
      }
    });
  };

  handleCreateNewCategory = (inputValue) => {
    const newOption = {
      label: inputValue,
      value: inputValue,
    };

    this.setState({
      generalFeeCategory: [...this.state.generalFeeCategory],
      generalFee: newOption,
      addNew: true,
      generalAmount: "",
    });
  };

  handleGeneralFeeCatChange = (value) => {
    if (value !== null) {
      this.setState({
        generalFee: value,
      });

      let generalFeeCategory = [...this.state.generalFeeCategory];
      generalFeeCategory.forEach((el) => {
        if (el.id == value.id) {
          if (el.amount) {
            this.setState({
              generalAmount: el.amount,
            });
          }
        }
      });
    } else {
      this.setState({
        generalFee: "",
        generalAmount: "",
      });
    }
  };

  handleMonthChange = (items) => {
    this.setState({
      selectedMonths: items,
    });

    let params = {
      yearLevelId: this.state.yearlevelId,
      monthId: items.id,
    };

    axiosPost(URL.getDraftBills, params, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            draftBills: response.data.data,
          },
          () => {
            this.getStudentAsPerYearLevel();
          }
        );
      }
    });
  };

  getAllFeeCategory = () => {
    axiosPost(URL.getAllFeeCategory, {}, (response) => {
      if (response.status === 200) {
        this.setState({ feeCategory: response.data.data });
      }
    });
  };

  getGeneralFeeCategory = () => {
    let param = {
      type: "general",
    };
    axiosPost(URL.selectFeeCategoryConfiguration, param, (response) => {
      if (response.status === 200) {
        let feeCat = response.data.data;
        feeCat.forEach((sub) => {
          sub.value = sub.id;
          sub.label = sub.feeCategoryName;
        });

        this.setState({ generalFeeCategory: feeCat });
      }
    });
  };

  handleRemoveCategory = (idx, cat) => {
    let removedStudentInventoryItemIds = [
      ...this.state.removedStudentInventoryItemIds,
    ];

    if (cat.inventoryItemId) {
      removedStudentInventoryItemIds.push(cat.studentInventoryItemId);
    }

    let selectedFeeCategory = [...this.state.selectedFeeCategory];
    let students = [...this.state.students];
    students[this.state.selectedStudentIndex].feeCategory.splice(idx, 1);
    selectedFeeCategory.splice(idx, 1);
    this.setState({
      selectedFeeCategory,
      students,
      removedStudentInventoryItemIds,
    });
  };

  handleStudentCheckbox = (e, idx) => {
    let students = [...this.state.students];
    students[idx].isChecked = e.target.checked;

    this.setState({ students });
  };

  inventoryAllCheckbox = (e) => {
    let students = [...this.state.students];
    let selectedFeeCategory = [...this.state.selectedFeeCategory];
    students.forEach((el) => {
      if (el.id === parseInt(this.state.selectedStudent)) {
        if (el.studentInventoryItems.length > 0) {
          el.studentInventoryItems.forEach((item) => {
            item.isChecked = e.target.checked;
            if (e.target.checked) {
              let checkDuplicate = el.feeCategory.filter(
                (fee) => fee.inventoryItemId === item.inventoryItemId
              );
              if (checkDuplicate.length === 0) {
                el.feeCategory.push({
                  id: item.feeCategoryId,
                  quantity: item.quantity ? item.quantity : null,
                  amount: item.amount,
                  originalPrice: item.originalPrice ? item.originalPrice : null,
                  inventoryItemId: item.inventoryItemId
                    ? item.inventoryItemId
                    : null,
                  category: item.feeCategoryName,
                  inventoryItemName: item.itemName ? item.itemName : null,
                  isInventory: true,
                });
                selectedFeeCategory.push({
                  id: item.feeCategoryId,
                  quantity: item.quantity ? item.quantity : null,
                  amount: item.amount,
                  originalPrice: item.originalPrice ? item.originalPrice : null,
                  inventoryItemId: item.inventoryItemId
                    ? item.inventoryItemId
                    : null,
                  category: item.feeCategoryName,
                  inventoryItemName: item.itemName ? item.itemName : null,
                  isInventory: true,
                });
                this.setState({ selectedFeeCategory });
              }
            } else {
              el.feeCategory = el.feeCategory.filter(
                (fl) => fl.inventoryItemId !== parseInt(item.inventoryItemId)
              );
              this.setState({
                selectedFeeCategory: el.feeCategory,
              });
            }
          });
        }
      }
    });
    this.setState({ students, checkAllItem: e.target.checked });
  };

  inventoryCheckbox = (e, idx) => {
    let students = [...this.state.students];
    let selectedFeeCategory = [...this.state.selectedFeeCategory];
    students.forEach((el) => {
      if (el.id === parseInt(this.state.selectedStudent)) {
        el.studentInventoryItems[idx].isChecked = e.target.checked;
        if (e.target.checked) {
          el.feeCategory.push({
            id: el.studentInventoryItems[idx].feeCategoryId,
            quantity: el.studentInventoryItems[idx].quantity
              ? el.studentInventoryItems[idx].quantity
              : null,
            amount: el.studentInventoryItems[idx].amount,
            originalPrice: el.studentInventoryItems[idx].originalPrice
              ? el.studentInventoryItems[idx].originalPrice
              : null,
            inventoryItemId: el.studentInventoryItems[idx].inventoryItemId
              ? el.studentInventoryItems[idx].inventoryItemId
              : null,
            category: el.studentInventoryItems[idx].feeCategoryName,
            inventoryItemName: el.studentInventoryItems[idx].itemName
              ? el.studentInventoryItems[idx].itemName
              : null,
            isInventory: true,
          });
          selectedFeeCategory.push({
            id: el.studentInventoryItems[idx].feeCategoryId,
            quantity: el.studentInventoryItems[idx].quantity
              ? el.studentInventoryItems[idx].quantity
              : null,
            amount: el.studentInventoryItems[idx].amount,
            originalPrice: el.studentInventoryItems[idx].originalPrice
              ? el.studentInventoryItems[idx].originalPrice
              : null,
            inventoryItemId: el.studentInventoryItems[idx].inventoryItemId
              ? el.studentInventoryItems[idx].inventoryItemId
              : null,
            category: el.studentInventoryItems[idx].feeCategoryName,
            inventoryItemName: el.studentInventoryItems[idx].itemName
              ? el.studentInventoryItems[idx].itemName
              : null,
            isInventory: true,
          });
          this.setState({ selectedFeeCategory });
        } else {
          el.feeCategory = el.feeCategory.filter(
            (fl) =>
              fl.inventoryItemId !==
              parseInt(el.studentInventoryItems[idx].inventoryItemId)
          );
          // selectedFeeCategory = selectedFeeCategory.filter(
          //   (fl) =>
          //     fl.inventoryItemId !==
          //     parseInt(el.studentInventoryItems[idx].inventoryItemId)
          // );
          this.setState({
            selectedFeeCategory: el.feeCategory,
          });
        }
      }
    });
    this.setState({ students });
  };

  manageAllChecked = () => {
    let students = [...this.state.students];
    if (this.state.allChecked) {
      students.forEach((el) => {
        el.isChecked = true;
      });
    } else {
      students.forEach((el) => {
        el.isChecked = false;
      });
    }
    this.setState({ students });
  };

  resetFeeData = () => {
    this.setState({
      // configType: "monthly",
      // selectedMonths: null,
      // students: [],
      // yearlevelId: "",
      // allChecked: false,
      addNew: false,
      allChecked: false,
      allInventoriesChecked: false,
      checkAllItem: false,
      configType: "monthly",
      disableAmount: false,
      generalAmount: "",
      generalFee: "",
      generalNewCategoryName: "",
      inventoryItems: [],
      inventoryModal: false,
      loader: false,
      monthModal: false,
      selectedFeeCategory: [],
      selectedInventoryItems: [],
      selectedMonths: "",
      selectedStudent: "",
      students: [],
      draftBills: [],
    });
  };

  resetData = () => {
    this.setState({
      // configType: "monthly",
      // selectedMonths: null,
      // students: [],
      // yearlevelId: "",
      // allChecked: false,
      addNew: false,
      allChecked: false,
      allInventoriesChecked: false,
      checkAllItem: false,
      configType: "monthly",
      disableAmount: false,
      generalAmount: "",
      generalFee: "",
      generalNewCategoryName: "",
      inventoryItems: [],
      inventoryModal: false,
      loader: false,
      monthModal: false,
      selectedFeeCategory: [],
      selectedInventoryItems: [],
      selectedMonths: "",
      selectedStudent: "",
      students: [],
      yearlevelId: "",
      draftBills: [],
    });
  };

  handleFeeAmountChange = (e, idx) => {
    let selectedFeeCategory = [...this.state.selectedFeeCategory];
    selectedFeeCategory[idx].amount = e.target.value;
    this.setState({ selectedFeeCategory });
  };

  handleConfigureMonth = () => {
    this.setState((prevState) => ({
      ...prevState,
      monthModal: !prevState.monthModal,
    }));
  };

  handleInventoryModal = () => {
    this.setState({ inventoryModal: !this.state.inventoryModal });
  };

  generateBillFromDraft = () => {
    let catErr = 0;
    let studentBillParam = {
      yearLevelId: "",
      monthIds: [],
      studentBillFeeCategoryWrappers: [],
    };
    let students = [...this.state.students],
      error = 0;
    if (this.state.configType === "monthly") {
      if (this.state.yearlevelId !== "" && this.state.selectedMonths !== "") {
      } else {
        swal(
          "Error",
          `${localStorage.getItem("yearLevel")} or Months are missing`
        );
        return false;
      }
    } else {
      if (this.state.yearLevelId === "") {
        swal("Error", `${localStorage.getItem("yearLevel")} is missing`);
        return false;
      }
    }
    studentBillParam.yearLevelId = this.state.yearlevelId;
    if (this.state.configType === "monthly") {
      studentBillParam.monthIds = [this.state.selectedMonths.id];
    }

    if (students.length > 0) {
      students.forEach((el) => {
        if (el.isChecked) {
          if (el.id === this.state.selectedStudent) {
            el.feeCategory = this.state.selectedFeeCategory;
          }

          let tempData = {
            admissionId: "",
            studentBillFeeCategories: [],
            studentInventory: [],
          };
          tempData.admissionId = el.id;
          el.feeCategory.forEach((fc) => {
            if (fc.amount === "") {
              catErr++;
            } else {
              let feeData = {
                feeCategoryId: fc.feeCategoryId || fc.id,
                quantity: fc.quantity ? fc.quantity : null,
                amount: fc.amount ? fc.amount : null,
                originalPrice: fc.originalPrice ? fc.originalPrice : null,
                inventoryItemId: fc.inventoryItemId ? fc.inventoryItemId : null,
                category: fc.category ? fc.category : null,
                monthIds:
                  this.state.selectedMonths !== ""
                    ? [this.state.selectedMonths.id]
                    : [],
              };
              if (fc.inventoryItemId) {
                feeData.amount =
                  parseInt(feeData.quantity) * parseInt(feeData.amount);
                tempData.studentInventory.push(feeData);
              } else {
                tempData.studentBillFeeCategories.push(feeData);
              }
            }
          });
          studentBillParam.studentBillFeeCategoryWrappers.push(tempData);
        } else {
          error++;
        }
      });

      if (error === students.length) {
        swal("Error", "No students selected for bill generation");
        return false;
      }
      if (catErr > 0) {
        swal("Error", "Fee Amount cannot be empty");
        return false;
      }
    }

    studentBillParam.studentBillFeeCategoryWrappers.forEach((sbp) => {
      this.state.draftBills.forEach((db) => {
        if (db.admissionId === sbp.admissionId) {
          sbp.id = db.id;
        }
      });
    });

    studentBillParam.isDraft = this.state.isDraftBill === true ? true : false;
    if (
      this.state.removedStudentInventoryItemIds.length > 0 &&
      this.state.isDraftBill
    ) {
      studentBillParam.removedStudentInventoryItemIds = this.state.removedStudentInventoryItemIds;
    }

    this.setState({ loader: true }, function () {
      axiosPost(
        URL.generateBillFromDraft,
        studentBillParam,
        (response) => {
          this.setState({ loader: false });
          if (response.status === 200) {
            this.resetData();

            this.state.isDraftBill
              ? swal("Success", "Draft Bill updated successfully.", "")
              : swal("Success", "Bill generated successfully.", "");

            if (
              response.data.data.zipPath &&
              response.data.data.zipPath !== ""
            ) {
              window.open(
                URL.downloadBill + response.data.data.zipPath,
                "_blank"
              );
            }
            if (
              response.data.data.message &&
              response.data.data.message !== ""
            ) {
              swal(
                "Info",
                response.data.data.message.slice(1, -1).replaceAll("],", "].")
              );
            }
            this.getGeneralFeeCategory();
          } else {
            swal(
              "Error",
              response.data.message
                ? response.data.message
                : "Sorry, some error occured",
              ""
            );
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  updateDraft = () => {
    this.setState(
      {
        isDraftBill: true,
      },
      () => {
        this.generateBillFromDraft();
      }
    );
  };

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Generating Bill...
            </div>
          </div>
        ) : null}
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "81.5vh" }}
        >
          <div className="tt-group-header">
            <div className="row">
              <div className="col">Draft Bills</div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="mt-2 p-2" style={{ border: "1px solid #ccc" }}>
                  <GeneratePaymentBills
                    fromDraft={true}
                    configType={this.state.configType}
                    handleChange={this.handleChange}
                    handleMonthChange={this.handleMonthChange}
                    months={this.state.months}
                    selectedMonths={this.state.selectedMonths}
                    yearlevelId={this.state.yearlevelId}
                    yearLevels={this.state.yearLevels}
                  />
                </div>
              </div>
            </div>
          </div>

          {this.state.draftBills.length > 0 ? (
            this.state.students.length > 0 ? (
              <>
                <StudentAccountConfig
                  fromDraft={true}
                  configType={this.state.configType}
                  handleGeneralMonthChange={this.handleGeneralMonthChange}
                  months={this.state.months}
                  selectedMonths={this.state.selectedMonths}
                  addCategory={this.addCategory}
                  addNew={this.state.addNew}
                  allChecked={this.state.allChecked}
                  allInventoriesChecked={this.state.allInventoriesChecked}
                  cancelNewCategory={this.cancelNewCategory}
                  disableAmount={this.state.disableAmount}
                  generalAmount={this.state.generalAmount}
                  generalFee={this.state.generalFee}
                  generalFeeCategory={this.state.generalFeeCategory}
                  generalNewCategoryName={this.state.generalNewCategoryName}
                  handleActiveStatus={this.handleActiveStatus}
                  handleCategoryCheckboxChange={
                    this.handleCategoryCheckboxChange
                  }
                  handleCreateNewCategory={this.handleCreateNewCategory}
                  handleGeneralFeeCatChange={this.handleGeneralFeeCatChange}
                  handleChange={this.handleChange}
                  handleFeeAmountChange={this.handleFeeAmountChange}
                  handleInventoryModal={this.handleInventoryModal}
                  handleRemoveCategory={this.handleRemoveCategory}
                  handleStudentCheckbox={this.handleStudentCheckbox}
                  selectedFeeCategory={this.state.selectedFeeCategory}
                  students={this.state.students}
                />
                <div className="row mt-2">
                  <div className="col">
                    {this.state.draftBills.length > 0 && (
                      <button
                        className="tt-button tt-button-primary float-right"
                        onClick={this.updateDraft}
                      >
                        Update Draft
                      </button>
                    )}
                    <button
                      className="tt-button tt-button-primary float-right"
                      onClick={this.generateBillFromDraft}
                    >
                      Generate Bill
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="row p-4">
                <div className="col text-center">
                  {this.state.yearlevelId !== ""
                    ? "Select the month first"
                    : `Select ${localStorage.getItem("yearLevel")} first`}
                </div>
              </div>
            )
          ) : (
            <div className="text-center py-4">
              No draft bill saved for this month.
            </div>
          )}
        </div>
        <ModalWindow
          modal={this.state.monthModal}
          toggleModal={this.handleConfigureMonth}
          modalHeader={"Configure month type"}
          modalBody={
            <>
              <div className="row">
                <div className="col-md-3 mt-2" style={{ fontWeight: "bold" }}>
                  Month Type
                </div>
                <div className="col-md-9">
                  <select
                    name="monthConfigurationId"
                    value={this.state.monthConfigurationId}
                    className="form-control"
                    onChange={this.handleChange}
                  >
                    <option value="" disabled selected>
                      Choose Configuration
                    </option>
                    {this.state.monthConfiguration?.map((mc, idx) => (
                      <option value={mc.id} key={idx}>
                        {mc.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  {this.state.monthConfiguration?.find((mc) => mc.isActive) ===
                    undefined && (
                    <button
                      className="tt-button tt-button-primary float-right"
                      onClick={this.handleConfigureMonthSave}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.inventoryModal}
          toggleModal={this.handleInventoryModal}
          modalHeader="Add Inventory"
          size="xl"
          modalBody={
            <BillAddInventoryModalBody
              checkAllItem={this.state.checkAllItem}
              inventoryAllCheckbox={this.inventoryAllCheckbox}
              inventoryCheckbox={this.inventoryCheckbox}
              selectedInventoryItems={this.state.selectedInventoryItems}
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

export default DraftBills;
