/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, SetupTwo, is for the view of setup two porition of the System Setup Process
 */

import React, { Component } from "react";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import NameDisplayFormat from "./NameDisplayFormat";
import "../../assets/scss/systemSetup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class SetupTwo extends Component {
  componentDidMount() {
    //document.title = "Name Formats";
  }
  render() {
    return (
      <>
        <div className="tt-group-header tt-systemSetupTwo">
          Identity Cluster
        </div>
        <div className="card">
          <div className="card-body">
            <div className="tt-group-header" style={{ marginBottom: "20px" }}>
              Name Fields
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4 card-title text-center">
                <strong>{"Field Label"}</strong>
              </div>
              <div className="col-md-5 card-title text-center">
                <strong>{"Example"}</strong>
              </div>
              <div className="col-md-2">
                <strong>Mandatory</strong>
              </div>
            </div>
            <div className="container">
              <form>
                {this.props.labels.map((label, id) => (
                  <div className="form-group row">
                    <div className="col-md-1"></div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className={
                          "form-control " + (label.isMandatory ? "tt-bold" : "")
                        }
                        name="label"
                        value={this.props.labels[id].fieldName}
                        onChange={(e) =>
                          this.props.handleLabelChange(e, id, label.index)
                        }
                        list="labels"
                        maxLength="255"
                      />
                      <datalist id="labels">
                        <option value="First Name">First name</option>
                        <option value="Middle Name">Middle name</option>
                        <option value="Last Name">Last name</option>
                      </datalist>
                    </div>
                    <div className="col-md-5">
                      <input
                        type="text"
                        className="form-control"
                        name="value"
                        value={this.props.labels[id].value}
                        onChange={(e) =>
                          this.props.handleLabelValueChange(e, id)
                        }
                        maxLength="255"
                      />
                    </div>
                    <div className="col-md-1">
                      {id > 0 ? (
                        <input
                          className="mandatory-checkbox"
                          type="checkbox"
                          checked={this.props.labels[id].isMandatory}
                          onChange={(e) =>
                            this.props.handleCheckBoxChange(e, id, "dynamic")
                          }
                        />
                      ) : null}
                    </div>
                    <div className="col-sm-1">
                      {id > 0 ? (
                        <div
                          className="tt-button tt-button-danger"
                          onClick={(e) =>
                            displayConfirmDeleteAlert(
                              { e, id, label, param: null },
                              this.props.handleRemoveNewNameField
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}

                <button
                  type="button"
                  className="tt-button tt-button-primary setup-two mb-2"
                  onClick={this.props.handleAddNewNameField}
                >
                  Add new name field
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="card name-display-format">
          <div className="card-body">
            <NameDisplayFormat
              labels={this.props.labels}
              editableNameTags={this.props.editableNameTags}
              handleEditableNameTags={this.props.handleEditableNameTags}
              handleEditableNameInitialTags={
                this.props.handleEditableNameInitialTags
              }
              handleDeleteEditableNameTags={
                this.props.handleDeleteEditableNameTags
              }
              labelsCopy={this.props.labelsCopy}
              initialCopy={this.props.initialCopy}
              showNameFormat={this.props.showNameFormat}
              symbols={this.props.symbols}
              handleSymbols={this.props.handleSymbols}
              {...this.props}
            />

            {/* <div className="optional-field">
              <h5>Optional Fields</h5>
              <input
                className="mandatory-checkbox"
                type="checkbox"
                name="nationality"
                checked={this.props.nationality}
                onChange={this.props.handleCheckBoxChange}
              />{" "}
              <label>
                <h6 className="add-salutation">Add Nationality</h6>
              </label>
              <br></br>
              <input
                className="mandatory-checkbox"
                type="checkbox"
                name="ethnicity"
                checked={this.props.ethnicity}
                onChange={this.props.handleCheckBoxChange}
              />{" "}
              <label>
                <h6 className="add-salutation">Add Ethnicity</h6>
              </label>
            </div> */}
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-9"></div>
            <div className="col-md-2 text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.changeToPrevIndex}
              >
                Previous
              </button>
            </div>
            <div className="col-md-1 text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.changeToNextIndex}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SetupTwo;
