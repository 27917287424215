import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../../../utils/Constants";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";
class DisciplineTableRow extends Component {
  state = {};
  componentDidUpdate() {
    $(".selectpicker").selectpicker();
  }
  render() {
    let val = this.props.discipline;
    return (
      <tr>
        <td className="tt-subjectDisplayTable">
          <input
            type="text"
            value={val.rubricsName}
            name="rubricsName"
            id={"rubricsName" + this.props.mainIndex + this.props.idx}
            className={
              val.edit ? "tt-discipline-input-highlight" : "tt-discipline-input"
            }
            onChange={(e) =>
              this.props.handleCustomDisciplineRowChange(
                e,
                this.props.mainIndex,
                this.props.idx
              )
            }
          />
          <br />
          <input
            type="text"
            value={val.description}
            name="description"
            id={"description" + this.props.mainIndex + this.props.idx}
            className={
              val.edit
                ? "tt-discipline-input-highlight text-muted"
                : "tt-discipline-input text-muted"
            }
            onChange={(e) =>
              this.props.handleCustomDisciplineRowChange(
                e,
                this.props.mainIndex,
                this.props.idx
              )
            }
          />
          {/* <span className="text-muted">{val.description}</span> */}
        </td>

        {this.props.overall
          ? this.props.overall.rubricsScales
            ? this.props.overall.rubricsScales.map((scales, i) => {
                return (
                  <td>
                    <select className="form-control selectpicker">
                      {scales.assessmentScaleLevels.map((level, j) => (
                        <>
                          {level.levelType === "colourStickers" ? (
                            <option
                              style={{
                                backgroundColor: level.level,
                                color: "#fff",
                                fontSize: "25px",
                              }}
                            ></option>
                          ) : level.levelType === "image" ? (
                            <option
                              data-content={`<img src=${
                                URL.imageSelectURL + level.level
                              } class="img-responsive" height="42" width="42"/>`}
                            ></option>
                          ) : (
                            <option>{level.level}</option>
                          )}
                        </>
                      ))}
                    </select>
                  </td>
                );
              })
            : null
          : null}

        {/* <td className="tt-subjectDisplayTable">
          <select
            className="form-control"
            value={val.grade}
            name="grade"
            onChange={e =>
              this.props.handleNestedChange(e, this.props.idx, "discipline")
            }
          >
            <option value="" disabled>
              Select Grade
            </option>
            <option value="A">A</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
          </select>
        </td>
        <td className="tt-subjectDisplayTable">
          <select
            className="form-control"
            value={val.effortLevel}
            name="effortLevel"
            onChange={e =>
              this.props.handleNestedChange(e, this.props.idx, "discipline")
            }
          >
            <option value="" disabled>
              Select Level
            </option>
            <option value="outstanding">Outstanding</option>
            <option value="good">Good</option>
            <option value="bad">Bad</option>
          </select>
        </td> */}
        <td className="tt-subjectDisplayTable">
          <FontAwesomeIcon
            icon={faEdit}
            onClick={(e) =>
              this.props.handleUpdateDisciplineRow(
                e,
                this.props.idx,
                this.props.mainIndex
              )
            }
          />
          &nbsp;&nbsp;
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={(e) =>
              this.props.handleRemoveDisciplineRow(
                e,
                this.props.mainIndex,
                this.props.idx
              )
            }
          />
        </td>
      </tr>
    );
  }
}

export default DisciplineTableRow;
