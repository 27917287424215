import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import StudentInformation from "../AcademicAdministration/SchoolGrouping/Classroom/StudentInformation";

class AddStudentModal extends Component {
  state = {
    unAssignedStudents: [],
    assignedStudents: [],
    assignedCheckbox: false,
    unAssignedCheckbox: false,
  };

  componentDidMount() {
    this.unAssignedConfig();
  }

  config = () => {
    let ids = this.props.admissionIds;
    let unAssignedStudents = this.state.unAssignedStudents;
    let assignedStudents = this.state.assignedStudents;
    let newArray = [];
    if (ids.length > 0) {
      if (unAssignedStudents.length > 0) {
        unAssignedStudents.forEach((el) => {
          let check = ids.includes(el.id);
          if (check) {
            assignedStudents.push(el);
          } else {
            newArray.push(el);
          }
        });
      }
      this.setState({
        unAssignedStudents: newArray,
        assignedStudents: assignedStudents,
      });
    }
  };

  unAssignedConfig = () => {
    this.getUnAssignedStudents();
  };

  getUnAssignedStudents = () => {
    axiosPost(URL.selectUnAssignedStudents, {}, (response) => {
      if (response.status === 200) {
        let unAssignedStudents = response.data.data;
        if (unAssignedStudents.length > 0) {
          unAssignedStudents.forEach((el) => {
            el.checked = false;
          });
        }
        this.setState(
          {
            unAssignedStudents,
          },
          function () {
            this.config();
          }
        );
      }
    });
  };

  handleUnassignedCheckbox = (e, idx) => {
    let array = [...this.state.unAssignedStudents];
    if (array.length > 0) {
      if (idx === "all") {
        array.forEach((el) => {
          el.checked = e.target.checked;
        });
        this.setState({ unAssignedCheckbox: e.target.checked });
      } else {
        array[idx].checked = !array[idx].checked;
      }
      this.setState({ unAssignedStudents: array });
    }
  };

  handleAssignedCheckbox = (e, idx) => {
    let array = [...this.state.assignedStudents];
    if (array.length > 0) {
      if (idx === "all") {
        array.forEach((el) => {
          el.checked = e.target.checked;
        });
        this.setState({ assignedCheckbox: e.target.checked });
      } else {
        array[idx].checked = !array[idx].checked;
      }
      this.setState({ assignedStudents: array });
    }
  };

  assignStudents = () => {
    let array = [...this.state.unAssignedStudents];
    let newArray = [];
    let assignedStudents = [...this.state.assignedStudents];
    if (array.length > 0) {
      array.forEach((el) => {
        if (el.checked) {
          el.checked = false;
          assignedStudents.push(el);
        } else {
          newArray.push(el);
        }
      });
    }
    this.setState({
      unAssignedStudents: newArray,
      assignedStudents: assignedStudents,
      unAssignedCheckbox: false,
      assignedCheckbox: false,
    });
  };

  assignSingleStudents = (idx) => {
    let array = [...this.state.unAssignedStudents];
    let assignedStudents = [...this.state.assignedStudents];
    array[idx].checked = false;
    assignedStudents.push(array[idx]);
    array.splice(idx, 1);
    this.setState({
      unAssignedStudents: array,
      assignedStudents: assignedStudents,
    });
  };

  removeSelected = () => {
    let array = [...this.state.assignedStudents];
    let unAssignedStudents = [...this.state.unAssignedStudents];
    let newArray = [];
    if (array.length > 0) {
      array.forEach((el) => {
        if (el.checked) {
          el.checked = false;
          unAssignedStudents.push(el);
        } else {
          newArray.push(el);
        }
      });
    }
    this.setState({
      unAssignedStudents: unAssignedStudents,
      assignedStudents: newArray,
      unAssignedCheckbox: false,
      assignedCheckbox: false,
    });
  };

  removeSingleStudent = (idx) => {
    let array = [...this.state.assignedStudents];
    let unAssignedStudents = [...this.state.unAssignedStudents];
    array[idx].checked = false;
    unAssignedStudents.push(array[idx]);
    array.splice(idx, 1);
    this.setState({
      unAssignedStudents: unAssignedStudents,
      assignedStudents: array,
    });
  };

  saveConfig = () => {
    let list = this.state.assignedStudents;
    let admissionIds = [];
    if (list.length > 0) {
      list.forEach((el) => {
        admissionIds.push(el.id);
      });
    }
    this.props.assignAdmissionIds(admissionIds);
  };

  render() {
    return (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "87.5vh", overflow: "auto" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 lmsBorderRight">
              <div className="tt-group-header">Unassigned Students</div>
              <div className="tt-unAssignListHolder">
                <table className="table table-bordered table-striped">
                  <thead className="tt-group-header">
                    <tr>
                      <th
                        className="text-center"
                        width="5%"
                        style={{ verticalAlign: "middle" }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.unAssignedCheckbox}
                          onChange={(e) =>
                            this.handleUnassignedCheckbox(e, "all")
                          }
                        ></input>
                      </th>
                      <th>Student Detail</th>
                      <th className="text-center" width="98px">
                        Option
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.unAssignedStudents.length > 0 ? (
                      this.state.unAssignedStudents.map((stu, idx) => {
                        return (
                          <tr key={idx}>
                            <td
                              className="text-center"
                              width="5%"
                              style={{ verticalAlign: "middle" }}
                            >
                              <input
                                type="checkbox"
                                checked={stu.checked}
                                onChange={(e) =>
                                  this.handleUnassignedCheckbox(e, idx)
                                }
                              ></input>
                            </td>
                            <td>
                              <StudentInformation
                                studentInfo={stu}
                              ></StudentInformation>
                            </td>
                            <td className="text-center">
                              <button
                                className="tt-button tt-button-primary"
                                onClick={() => this.assignSingleStudents(idx)}
                              >
                                Assign
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No unassigned Students
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="tt-group-header text-right">
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.assignStudents}
                >
                  Assign Selected
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tt-group-header">Assigned Students</div>
              <div className="tt-unAssignListHolder">
                <table className="table table-bordered table-striped">
                  <thead className="tt-group-header">
                    <tr>
                      <th
                        className="text-center"
                        width="5%"
                        style={{ verticalAlign: "middle" }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.assignedCheckbox}
                          onChange={(e) =>
                            this.handleAssignedCheckbox(e, "all")
                          }
                        ></input>
                      </th>
                      <th>Student Detail</th>
                      <th className="text-center" width="98px">
                        Option
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.assignedStudents.length > 0 ? (
                      this.state.assignedStudents.map((stu, idx) => {
                        return (
                          <tr key={idx}>
                            <td
                              className="text-center"
                              width="5%"
                              style={{ verticalAlign: "middle" }}
                            >
                              <input
                                type="checkbox"
                                checked={stu.checked}
                                onChange={(e) =>
                                  this.handleAssignedCheckbox(e, idx)
                                }
                              ></input>
                            </td>
                            <td>
                              <StudentInformation
                                studentInfo={stu}
                              ></StudentInformation>
                            </td>
                            <td className="text-center">
                              <button
                                className="tt-button tt-button-primary"
                                onClick={() => this.removeSingleStudent(idx)}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No assigned Students
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="tt-group-header text-right">
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={this.removeSelected}
                >
                  Remove Selected
                </button>
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.saveConfig}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddStudentModal;
