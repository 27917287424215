import React, { Component } from "react";
import EducationLevelForm from "./EducationLevelForm";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import ListEducationLevel from "./ListEducationLevel";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomModal from "../../components/common/CustomModal";
class EducationLevel extends Component {
  state = {
    educationLevels: [],
    modal: false,
  };

  componentDidMount() {
    this.getAllEducationLevels();
  }

  getAllEducationLevels = () => {
    axiosPost(URL.getAllEducationLevel, {}, (response) => {
      if (response.status == 200) {
        this.setState({
          educationLevels: response.data.data,
        });
      }
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader
                title="Education Levels"
                onClick={this.toggleModal}
                icon="add_box"
                noPermission={true}
              />

              <ListEducationLevel
                educationLevels={this.state.educationLevels}
                getAllEducationLevels={this.getAllEducationLevels}
              />
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          modalHeader="Add Education Level"
          modalBody={
            <EducationLevelForm
              getAllEducationLevels={this.getAllEducationLevels}
              toggleModal={this.toggleModal}
            />
          }
        />
      </div>
    );
  }
}

export default EducationLevel;
