import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import CustomCollapse from "../UI/CustomCollapse";
import FlashCardTableList from "./FlashCardTableList";
import { connect } from "react-redux";
import {
  getMyFlashCards,
  getPublicFlashCards,
} from "../../ducks/FlashCardDucks";
import { axiosPost } from "../../utils/AxiosApi";
import { checkWidgetAdminOrNot, draggable, URL } from "../../utils/Constants";
import swal from "sweetalert";
import { MESSAGEHEADER } from "../../utils/DisplayMessage";
import ModalWindow from "../UI/ModalWindow";
import UpdateFlashCardSet from "./UpdateFlashCardSet";
import UpdateFlashCards from "./UpdateFlashCards";
import { displayConfirmDeleteAlert } from "../../utils/Utils";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

class ListFlashCards extends Component {
  state = {
    cardType: [
      { name: "My Flash Cards Set", open: false, code: "my" },
      { name: "Public Flash Cards Set", open: false, code: "public" },
    ],
    publicFlashCards: [],
    publicSpinner: false,
    myFlashCards: [],
    mySpinner: false,
    title: "",
    description: "",
    access: "",
    updateModal: false,
    id: "",
    selectedCard: "",
    flashCardsUpdateModal: false,
    flashCards: [
      {
        image: null,
        question: "",
        answer: "",
        hint: "",
        imagePreview: null,
      },
    ],
    codeIdx: "",
    updateMyFlashCards: [],
    searchText: "",
    publicFlashOffset: 0,
    publicFlashCurrentPage: 1,
    publicFlashRowCountLimit: 8,
    publicFlashTotalRecords: 0,
    permissionModal: false,
  };

  componentDidMount() {
    this.props.getPublicFlashCards(
      this.state.searchText,
      this.state.publicFlashOffset,
      this.state.publicFlashRowCountLimit
    );
    this.props.getMyFlashCards();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      myFlashCards: nextProps.myFlashCards,
      publicFlashCards: nextProps.publicFlashCards,
      publicFlashTotalRecords: nextProps.publicFlashTotalRecords,
      publicSpinner: nextProps.publicSpinner,
      mySpinner: nextProps.mySpinner,
    };
  }

  toggleCollapsible = (e, idx) => {
    let cardType = this.state.cardType;
    if (cardType[idx].open === true) {
      cardType.forEach((el) => {
        el.open = false;
      });
    } else {
      cardType.forEach((el) => {
        el.open = false;
      });
      cardType[idx].open = true;
    }
    this.setState({ cardType });
  };

  displayList = (type) => {
    let cardList = "";
    let spinner = "";
    let cardType = "";
    if (type.code === "public") {
      cardList = this.state.publicFlashCards;
      spinner = this.state.publicSpinner;
      cardType = "public";
    } else {
      cardList = this.state.myFlashCards;
      spinner = this.state.mySpinner;
      cardType = "my";
    }
    return (
      <FlashCardTableList
        cardList={cardList}
        spinner={spinner}
        cardType={cardType}
        deleteFlashCardSet={this.deleteFlashCardSet}
        handleUpdateModal={this.handleUpdateModal}
        publicFlashOffset={this.state.publicFlashOffset}
        publicFlashCurrentPage={this.state.publicFlashCurrentPage}
        publicFlashRowCountLimit={this.state.publicFlashRowCountLimit}
        publicFlashTotalRecords={this.state.publicFlashTotalRecords}
        handlePageChange={this.handlePageChange}
        handleNextPage={this.handleNextPage}
        handlePreviousPage={this.handlePreviousPage}
      ></FlashCardTableList>
    );
  };
  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  //Delete Flash Card

  deleteFlashCardSet = (id) => {
    let data = { id: id };
    axiosPost(URL.deleteFlashCardSet, data, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            publicFlashOffset: 0,
            publicFlashCurrentPage: 1,
            publicFlashRowCountLimit: 8,
            publicFlashTotalRecords: 0,
          },
          function () {
            this.props.getPublicFlashCards(
              this.state.searchText,
              this.state.publicFlashOffset,
              this.state.publicFlashRowCountLimit
            );
            this.props.getMyFlashCards();
          }
        );
      }
    });
  };

  //udpate Flash Card Set
  handleUpdateModal = (cards) => {
    let code = cards.flashCards[cards.flashCards.length - 1].code;
    code = code.replace("Flash-Card", "");
    code = parseInt(code);
    this.setState({
      updateModal: !this.state.updateModal,
      title: cards.title,
      description: cards.description,
      access: cards.access,
      id: cards.id,
      selectedCard: cards,
      codeIdx: code,
    });
  };

  toggleUpdateModal = () => {
    this.setState({
      updateModal: !this.state.updateModal,
      title: "",
      description: "",
      access: "",
      id: "",
      selectedCard: "",
    });
  };

  updateFlashCardSet = () => {
    let data = {
      id: this.state.id,
      title: this.state.title,
      description: this.state.description,
      access: this.state.access,
    };
    axiosPost(URL.updateFlashCardSet, data, (response) => {
      if (response.status === 200) {
        swal({
          title: MESSAGEHEADER.success,
          text: response.data.message,
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.props.getPublicFlashCards(
          this.state.searchText,
          this.state.publicFlashOffset,
          this.state.publicFlashRowCountLimit
        );
        this.props.getMyFlashCards();
        this.toggleUpdateModal();
      }
    });
  };

  //Update Flash Cards
  handleUpdateFlashCardModal = () => {
    this.setState({
      flashCardsUpdateModal: !this.state.flashCardsUpdateModal,
      flashCards: [
        {
          image: null,
          question: "",
          answer: "",
          hint: "",
          imagePreview: null,
        },
      ],
    });
  };

  handleDeleteFlashCard = (id, idx) => {
    displayConfirmDeleteAlert({ id, idx }, this.deleteFlashCard);
  };
  deleteFlashCard = (data) => {
    if (this.state.selectedCard.flashCards.length === 1) {
      swal({
        title: "Error",
        text: "There should atleast be one flash card",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    }
    let param = {
      id: data.id,
      flashCardSetId: this.state.selectedCard.id,
    };
    axiosPost(URL.deleteFlashCards, param, (response) => {
      if (response.status === 200) {
        let card = this.state.selectedCard;
        card.flashCards.splice(data.idx, 1);
        this.setState({ selectedCard: card });
        this.props.getMyFlashCards();
      }
    });
  };

  //Add New Flash Cards

  removeFlashCard = (e, idx) => {
    const flashCards = [...this.state.flashCards];
    if (flashCards.length === 1) {
      swal({
        title: "Error",
        text: "Atleast 1 flash card should be there",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    } else {
      flashCards.splice(idx, 1);
    }
    this.setState({ flashCards });
  };

  removeImage = (e, idx) => {
    let flashCards = [...this.state.flashCards];
    flashCards[idx]["image"] = "";
    flashCards[idx]["imagePreview"] = "";
    this.setState({ flashCards });
  };

  handleImageChange = (e, idx) => {
    let name = e.target.name;
    let flashCards = [...this.state.flashCards];
    flashCards[idx][name] = e.target.files[0];
    this.setState({ flashCards }, function () {
      let flashCards = [...this.state.flashCards];
      let image = window.URL.createObjectURL(flashCards[idx][name]);
      flashCards[idx]["imagePreview"] = image;
      this.setState({ flashCards });
    });
  };

  handleFlashCardChange = (e, idx) => {
    let name = e.target.name;
    let value = e.target.value;
    let flashCards = [...this.state.flashCards];
    flashCards[idx][name] = value;
    this.setState({ flashCards });
  };

  addFlashCard = (e) => {
    e.preventDefault();
    let flashCards = [...this.state.flashCards];
    flashCards.push({
      image: null,
      question: "",
      answer: "",
      hint: "",
      imagePreview: null,
    });
    this.setState({ flashCards });
  };

  handleSubmit = () => {
    let flashCards = [...this.state.flashCards];
    let error = 0;
    flashCards.forEach((fCard, idx) => {
      if (fCard.question === "" || fCard.answer === "") {
        error++;
      }
    });
    if (error > 0) {
      swal({
        title: MESSAGEHEADER.error,
        text: "Fill the questions and answers for all the flash cards",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    } else {
      var formData = new FormData();
      var filesDetails = [];
      let codeIdx = this.state.codeIdx + 1;

      flashCards.forEach((flashCard, idx) => {
        flashCard.code = "Flash-Card" + codeIdx;
        if (flashCard.image != null) {
          formData.append("files", flashCard.image);
          filesDetails.push({
            code: flashCard.code,
          });
          codeIdx++;
        }
        delete flashCard.image;
        delete flashCard.imagePreview;
      });
      formData.append("filesDetails", JSON.stringify(filesDetails));
      var flashCardSet = {
        id: this.state.selectedCard.id,
        flashCards: flashCards,
      };
      formData.append("flashCardSet", JSON.stringify(flashCardSet));

      axiosPost(
        URL.updateFlashCards,
        formData,
        (response) => {
          if (response.status === 200) {
            swal({
              title: MESSAGEHEADER.success,
              text: "Flash Card successfully created",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            draggable();
            this.getMyFlashCardsUpdate();
            this.clearFlashState();
            this.props.getMyFlashCards();
          }
        },
        (error) => {
          this.clearFlashState();
          let errorResponse = error.response ? error.response.data : error;
          if (errorResponse.status === 400) {
            //if condition to check spring boot validation errors
            let errorMessage = "";
            if (errorResponse.errors) {
              errorResponse.errors.forEach((error) => {
                errorMessage += `${error.field
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })} ${error.defaultMessage} \n`; //ishan
              });
              swal(errorResponse.error, errorMessage, "");
            } else {
              swal(errorResponse.error, errorResponse.message, "");
            }
          } else {
            swal(
              errorResponse.error || "Network Error",
              errorResponse.message
                ? errorResponse.message
                : "Could Not Connect To The Server.",
              ""
            );
          }
        }
      );
    }
  };

  getMyFlashCardsUpdate = () => {
    axiosPost(URL.getMyFlashCards, {}, (response) => {
      if (response.status === 200) {
        this.setState({ updateMyFlashCards: response.data.data }, function () {
          let cardId = this.state.selectedCard.id;
          if (this.state.updateMyFlashCards.length > 0) {
            this.state.updateMyFlashCards.forEach((el) => {
              if (cardId === el.id) {
                this.setState({ selectedCard: el }, function () {
                  let cards = this.state.selectedCard;
                  let code = cards.flashCards[cards.flashCards.length - 1].code;
                  code = code.replace("Flash-Card", "");
                  code = parseInt(code);
                  this.setState({ codeIdx: code });
                });
              }
            });
          }
        });
      }
    });
  };

  clearFlashState = () => {
    this.setState({
      flashCards: [
        {
          image: null,
          question: "",
          answer: "",
          hint: "",
          imagePreview: null,
        },
      ],
    });
  };

  searchPublicFlashCard = () => {
    this.setState(
      {
        publicFlashOffset: 0,
        publicFlashCurrentPage: 1,
        publicFlashRowCountLimit: 8,
        publicFlashTotalRecords: 0,
      },
      function () {
        this.props.getPublicFlashCards(
          this.state.searchText,
          this.state.publicFlashOffset,
          this.state.publicFlashRowCountLimit
        );
      }
    );
  };

  //Pagination

  handlePreviousPage = () => {
    if (this.state.publicFlashCurrentPage !== 1) {
      this.reRenderPublicFlashCard(this.state.publicFlashCurrentPage - 1);
      this.setState((prevState) => ({
        publicFlashCurrentPage: prevState.publicFlashCurrentPage - 1,
      }));
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.publicFlashCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(
        this.state.publicFlashTotalRecords / this.state.publicFlashRowCountLimit
      )
    )
      return;
    else {
      this.reRenderPublicFlashCard(nextPage);
      this.setState({ publicFlashCurrentPage: nextPage });
    }
  };

  handlePageChange = (page) => {
    this.reRenderPublicFlashCard(page);
    this.setState({ publicFlashCurrentPage: page });
  };

  reRenderPublicFlashCard = (page) => {
    let offset =
      page * parseInt(this.state.publicFlashRowCountLimit) -
      parseInt(this.state.publicFlashRowCountLimit);
    this.setState({ publicFlashOffset: offset }, function () {
      this.props.getPublicFlashCards(
        this.state.searchText,
        offset,
        this.state.publicFlashRowCountLimit
      );
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          List of Flash Cards Set
          {checkWidgetAdminOrNot("Teaching Tools") ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="card" style={{ maxHeight: "69.6vh", overflow: "auto" }}>
          {this.state.cardType.map((cType, idx) => {
            return (
              <div key={idx} className="mb-1 pl-2 pr-2 pt-2">
                <div className="container-fluid">
                  <div className="row tt-group-header">
                    <div
                      className={
                        cType.code === "my" ? "col-md-11" : "col-md-3 mt-2"
                      }
                    >
                      {cType.name}
                    </div>
                    {cType.code === "public" ? (
                      <div className="col">
                        <div className="row">
                          <div className="col-md-auto mt-2">
                            <strong>Search: </strong>
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              name="searchText"
                              value={this.state.searchText}
                              onChange={this.handleChange}
                              disabled={cType.open === false}
                            ></input>
                          </div>
                          <div className="col-md-auto mt-1">
                            <button
                              className="tt-button tt-button-primary"
                              disabled={cType.open === false}
                              onClick={this.searchPublicFlashCard}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={
                        "col-md-1 text-center" +
                        (cType.code === "my" ? "" : " mt-2")
                      }
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggleCollapsible(e, idx)}
                    >
                      <FontAwesomeIcon
                        icon={cType.open ? faAngleUp : faAngleDown}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                  <CustomCollapse
                    isOpen={cType.open}
                    collapsibleBody={this.displayList(cType)}
                  ></CustomCollapse>
                </div>
              </div>
            );
          })}
        </div>
        <ModalWindow
          modal={this.state.updateModal}
          toggleModal={this.toggleUpdateModal}
          modalHeader="Update Flash Cards Set"
          modalBody={
            <UpdateFlashCardSet
              title={this.state.title}
              description={this.state.description}
              access={this.state.access}
              updateFlashCardSet={this.updateFlashCardSet}
              handleChange={this.handleChange}
              toggleUpdateModal={this.toggleUpdateModal}
              flashCardsUpdateModal={this.state.flashCardsUpdateModal}
              handleUpdateFlashCardModal={this.handleUpdateFlashCardModal}
            ></UpdateFlashCardSet>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.flashCardsUpdateModal}
          toggleModal={this.handleUpdateFlashCardModal}
          modalHeader="Update Flash Cards"
          fullWidth={true}
          modalBody={
            <UpdateFlashCards
              selectedCard={this.state.selectedCard}
              handleDeleteFlashCard={this.handleDeleteFlashCard}
              flashCards={this.state.flashCards}
              removeFlashCard={this.removeFlashCard}
              removeImage={this.removeImage}
              handleImageChange={this.handleImageChange}
              handleFlashCardChange={this.handleFlashCardChange}
              addFlashCard={this.addFlashCard}
              handleSubmit={this.handleSubmit}
              clearFlashState={this.clearFlashState}
            ></UpdateFlashCards>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Teaching Tools"
              moduleName="Flash Cards"
              header="List Flash Cards configuration"
              activityName="list-flash-cards"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  publicFlashCards: state.flashCard.publicFlashCards,
  publicFlashTotalRecords: state.flashCard.publicFlashTotalRecords,
  myFlashCards: state.flashCard.myFlashCards,
  publicSpinner: state.flashCard.publicSpinner,
  mySpinner: state.flashCard.mySpinner,
});

const mapActionsToProps = {
  getPublicFlashCards,
  getMyFlashCards,
};

export default connect(mapStateToProps, mapActionsToProps)(ListFlashCards);
