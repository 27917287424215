import React, { Component } from "react";
import GeneralASSectionFive from "../GeneralAssessmentSettings/GeneralASSectionFive";
import SubjectAssessmentApplyToOther from "./SubjectAssessmentApplyToOther";

class SetMultipleSubjectAssessment extends Component {
  state = {};
  render() {
    return (
      <SubjectAssessmentApplyToOther
        classroomList={this.props.classroomList}
        handleApplyToMultiple={this.props.handleApplyToMultiple}
        applySettingToOther={this.props.applySettingToOther}
        handleApplySettingToOther={this.props.handleApplySettingToOther}
        selectedAssessmentIds={this.props.selectedAssessmentIds}
        updateSubjectAssessmentSetting={
          this.props.updateSubjectAssessmentSetting
        }
      />
    );
  }
}

export default SetMultipleSubjectAssessment;
