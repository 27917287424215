import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomButton from "../../components/common/CustomButton";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import CustomEditButton from "../../components/common/CustomEditButton";
import CustomModal from "../../components/common/CustomModal";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import {
  displayConfirmDeleteAlert,
  displayErrorAlert,
} from "../../utils/DisplayMessage";

export class ListRelativeLinks extends Component {
  state = {
    id: "",
    spinner: false,
    editModal: false,
    file: "",
    name: "",
    link: "",
    thumbnailPath: "",
    fieldErr: false,
  };

  deleteLink = (datum) => {
    let data = {
      id: datum.id,
    };
    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(
          URL.deleteRelativeLinks,
          data,
          (response) => {
            if (response.status === 200) {
              this.props.getAllLinks();
              swal("Success", response.data.message);
              this.setState({
                spinner: false,
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleDelete = (id, idx) => {
    displayConfirmDeleteAlert({ id: id, idx: idx }, this.deleteLink);
  };

  handleEditClick = (data) => {
    this.setState(
      {
        name: data.name,
        link: data.link,
        thumbnailPath: data.imagePath,
        id: data.id,
      },
      () => {
        this.setState({
          editModal: true,
        });
      }
    );
  };

  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeImage = (e) => {
    this.setState({
      thumbnailPath: "",
      file: e.target.files[0],
    });
  };

  handleUpdate = () => {
    let err = false;
    if (this.state.name === "" || this.state.link === "") {
      err = true;
      this.setState({
        fieldErr: true,
      });
    }

    let formData = new FormData();

    let param = {
      id: this.state.id,
      name: this.state.name,
      link: this.state.link,
    };

    formData.append("jsonData", JSON.stringify(param));

    if (this.state.file !== "") {
      formData.append("image", this.state.file);
    }

    if (!err) {
      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(
            URL.updateRelativeLinks,
            formData,
            (response) => {
              if (response.status === 200) {
                this.setState({
                  spinner: false,
                  editModal: false,
                });
                this.props.getAllLinks();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                spinner: false,
              });
            }
          );
        }
      );
    }
  };

  render() {
    return (
      <div>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Name</th>
                  <th>Link</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.relativeLinks?.length > 0 ? (
                  this.props.relativeLinks?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.link}</td>
                        <td>
                          <img
                            width="100"
                            src={URL.imageSelectURL + item.imagePath}
                          />
                        </td>
                        <td>
                          <CustomEditButton
                            onClick={() => this.handleEditClick(item)}
                          />
                          <CustomDeleteButton
                            onClick={() => this.handleDelete(item.id, index)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={5}>
                      No records to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <CustomModal
            size="md"
            modal={this.state.editModal}
            toggleModal={() => {
              this.setState({
                editModal: !this.state.editModal,
              });
            }}
            modalHeader="Update Relative Link"
            modalBody={
              <div className="container">
                <div className="row mt-3">
                  <label htmlFor="name">Name</label>
                  <input
                    className={`${
                      this.state.fieldErr && this.state.name == ""
                        ? "form-control cusInput border border-danger"
                        : "form-control cusInput"
                    }`}
                    name="name"
                    type="text"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.name}
                    placeholder="Enter name here"
                  />
                  {this.state.fieldErr && this.state.name == "" ? (
                    <small className="errorMsg-textField">
                      Name is required
                    </small>
                  ) : null}
                </div>
                <div className="row mt-3">
                  <label htmlFor="link">Link</label>

                  <input
                    className={`${
                      this.state.fieldErr && this.state.link == ""
                        ? "form-control cusInput border border-danger"
                        : "form-control cusInput"
                    }`}
                    name="link"
                    type="text"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.link}
                    placeholder="Enter link here"
                  />
                  {this.state.fieldErr && this.state.link == "" ? (
                    <small className="errorMsg-textField">
                      Link is required
                    </small>
                  ) : null}
                </div>
                <div className="row mt-3">
                  {this.state.thumbnailPath !== "" ? (
                    <img
                      style={{ height: "250px" }}
                      src={URL.imageSelectURL + this.state.thumbnailPath}
                      alt="No preview available"
                      className="img-fluid p-4"
                    />
                  ) : (
                    <img
                      style={{ height: "250px" }}
                      src={
                        this.state.file !== "" &&
                        window.URL.createObjectURL(this.state.file)
                      }
                      alt="No preview available"
                      className="img-fluid p-4"
                    />
                  )}
                  <input
                    className="form-control cusInput mt-2"
                    name="image"
                    type="file"
                    onChange={(e) => this.handleChangeImage(e)}
                  />
                  <div className="py-2">
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize"
                      onClick={this.handleUpdate}
                      type="submit"
                    >
                      Save
                    </button>
                  </div>{" "}
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default ListRelativeLinks;
