import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";
import Reminder from "./Reminder";
import moment from "moment";
import ReminderDetails from "./ReminderDetails";
import swal from "sweetalert";
import Pagination from "../UI/Pagination";

export class ListReminder extends Component {
  state = {
    editRemModal: false,
    notificationsData: [],
    detailsModal: false,
    detailsNotifications: "",
    spinner: false,

    notificationStatus: "all",
    notificationDate: "",
    searchText: "",
    dateFrom: "",
    dateTo: "",
    loadingSpinner: false,
    notificationTypeArr: [],
    notificationType: "",

    totalRecords: 0,
    pageSize: 2,
    currentPage: 1,
  };

  componentDidMount() {
    this.getAllNotifications();
    this.getAllNotificationTypes();
  }

  // handlePageChange = (page) => {
  //   this.reRenderAdmissionList(page);

  //   this.setState({ currentPage: page }, function () {
  //     this.setDisplayNumber();
  //   });
  // };

  // handlePreviousPage = () => {
  //   if (this.state.currentPage !== 1)
  //     this.reRenderAdmissionList(this.state.currentPage - 1);
  //   this.setState(
  //     (prevState) => ({
  //       currentPage: prevState.currentPage - 1,
  //     }),
  //     function () {
  //       this.setDisplayNumber();
  //     }
  //   );
  // };

  // handleNextPage = () => {
  //   let nextPage = this.state.currentPage + 1;
  //   if (nextPage > Math.ceil(this.state.totalRecords / this.state.pageSize))
  //     return;
  //   else {
  //     this.reRenderAdmissionList(nextPage);
  //     this.setState({ currentPage: nextPage }, function () {
  //       this.setDisplayNumber();
  //     });
  //   }

  // };

  // setDisplayNumber = () => {
  //   var pageNumber = this.state.currentPage,
  //     pageSize = this.state.pageSize;
  //   var startIndex = (pageNumber - 1) * pageSize + 1;
  //   var offValue = pageSize - 1;
  //   var endIndex = parseInt(startIndex) + parseInt(offValue);
  //   if (endIndex > this.state.totalRecords) {
  //     endIndex = this.state.totalRecords;
  //   }
  //   this.setState({
  //     startIndex: startIndex,
  //     endIndex: endIndex,
  //   });
  // };

  getAllNotifications = () => {
    this.setState(
      {
        loadingSpinner: true,
      },
      () => {
        axiosPost(
          URL.getAllNotification,
          {},
          (response) => {
            this.setState({
              notificationsData: response.data.data,
              loadingSpinner: false,
              totalRecords: response.data.data.length,
            });
          }
          // (err) => {
          //   swal(Error, "Some error occured");
          // }
        );
      }
    );
  };

  getAllNotificationTypes = () => {
    axiosPost(URL.getAllNotificationsType, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          notificationTypeArr: response.data.data,
        });
      }
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleClickDetails = (data) => {
    this.setState({
      detailsModal: true,
      detailsNotifications: data,
    });
  };

  handleEnableDisable = (data) => {
    let param = {
      id: data.id,
      heading: "",
      message: "",
      startDate: "",
      endDate: "",
      notificationTypeId: "",
      notifyDate: "",
      groupIds: [],
      peopleIds: [],
      isEnabled: !data.isEnabled,
      isScheduled: "",
    };

    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosPost(URL.updateNotification, param, (response) => {
          swal(
            "Success",
            param.isEnabled
              ? "Notification enabled successfully!!"
              : "Notification disabled successfully!!"
          );
          this.setState({ spinner: false });
          this.getAllNotifications();
        });
      }
    );
  };

  handleSearch = () => {
    let data = {
      searchText: this.state.searchText,
      startDate: this.state.dateFrom,
      endDate: this.state.dateTo,
      notifyDate: this.state.notificationDate,
      isScheduled:
        this.state.notificationStatus === "all"
          ? ""
          : this.state.notificationStatus,
      notificationTypeId: this.state.notificationType,
    };
    if (
      this.state.notificationType !== ""
      // && this.state.notificationStatus !== ""
    ) {
      this.setState(
        {
          loadingSpinner: true,
        },
        () => {
          axiosPost(URL.searchNotification, data, (response) => {
            this.setState({
              notificationsData: response.data.data,
              loadingSpinner: false,
            });
          });
        }
      );
    } else {
      swal(
        "Error",
        "Notification type is required to search the notifications!!"
      );
      return;
    }
  };
  handleResetSearch = () => {
    this.setState({
      notificationStatus: "all",
      notificationDate: "",
      searchText: "",
      dateFrom: "",
      dateTo: "",
      notificationType: "",
    });
    this.getAllNotifications();
  };

  toggleDetailsModal = () => {
    this.setState({
      detailsModal: !this.state.detailsModal,
    });
  };

  handleDelete = (notification) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure, you want to delete this notification?",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        let data = {
          id: notification.id,
        };
        this.setState(
          {
            spinner: true,
          },
          () => {
            axiosPost(URL.deleteNotification, data, (response) => {
              swal("Success", response.data.message);
              this.getAllNotifications();
              this.setState({
                spinner: false,
              });
            });
          }
        );
      }
    });
  };

  handleEditReminder = (rem) => {
    this.setState({
      updateRemData: rem,
      editRemModal: true,
    });
  };

  sendInstantNotification = (notification) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure, you want to send instant notification?",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        this.setState(
          {
            spinner: true,
          },
          () => {
            let param = {
              id: notification.id,
            };
            axiosPost(
              URL.sendNotification,
              param,
              (response) => {
                swal("Success", response.data.message);
                this.setState({
                  spinner: false,
                });
                this.getAllNotifications();
              },
              (err) => {
                this.setState({
                  spinner: false,
                });
                swal("Error", "Some error occured");
              }
            );
          }
        );
      }
    });
  };

  toggleUpdateModal = () => {
    this.setState({
      editRemModal: !this.state.editRemModal,
    });
  };

  render() {
    return (
      <>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait !! Processing.....
            </div>
          </div>
        ) : null}
        <div className="tt-widgetContent-tab-holder">
          <div className="tt-group-header">Search Notifications</div>
          <div className="row mt-2 p-2">
            <div className="col-md-3">
              <div>
                <strong>Notification Type</strong>
              </div>
              <select
                name="notificationType"
                value={this.state.notificationType}
                onChange={this.handleChange}
                className="form-control"
              >
                <option value="" disabled selected>
                  Choose notification type
                </option>
                {this.state.notificationTypeArr.map((notification, id) => (
                  <option value={notification.id} key={id}>
                    {notification.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <div>
                <strong>Search Text</strong>
              </div>
              <input
                type="text"
                className="form-control"
                name="searchText"
                onChange={this.handleChange}
                value={this.state.searchText}
                placeholder="Enter text to search"
              ></input>
            </div>
            <div className="col-md-3">
              <div>
                <strong>Notify Date</strong>
              </div>
              <input
                type="date"
                className="form-control"
                name="notificationDate"
                onChange={this.handleChange}
                value={this.state.notificationDate}
              />
            </div>

            <div className="col-md-3">
              <div>
                <strong>Status</strong>
              </div>
              <select
                name="notificationStatus"
                onChange={this.handleChange}
                className="form-control"
                value={this.state.notificationStatus}
              >
                <option value="" selected disabled>
                  Choose Notify Status
                </option>
                <option value={false}>Sent</option>
                <option value={true}>Scheduled</option>
                <option value="all">All</option>
              </select>
            </div>
          </div>
          <div className="row mt-2  mb-4 p-2">
            <div className="col-md-3">
              <div>
                <strong>Start Date</strong>
              </div>
              <input
                type="date"
                className="form-control"
                name="dateFrom"
                onChange={this.handleChange}
                value={this.state.dateFrom}
              />
            </div>
            <div className="col-md-3">
              <div>
                <strong>End Date</strong>
              </div>
              <input
                type="date"
                className="form-control"
                name="dateTo"
                onChange={this.handleChange}
                value={this.state.dateTo}
              />
            </div>

            {/* <div className="col-md-1"></div> */}
            <div className="col-md-6 text-right mt-2 d-flex justify-content-center align-items-end">
              <button
                className="tt-button tt-button-danger"
                onClick={this.handleResetSearch}
                // style={{ position: "absolute", bottom: 0 }}
              >
                Reset
              </button>
              <button
                className="tt-button tt-button-primary"
                onClick={this.handleSearch}
                // style={{ position: "absolute", bottom: 0 }}
              >
                Search
              </button>
            </div>
          </div>

          <div
            className="tt-newListBox mb-1"
            style={{
              height: "60.5vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {this.state.loadingSpinner ? (
              <div className="text-center">
                <Spinner color="primary"></Spinner>
              </div>
            ) : this.state.notificationsData.length > 0 ? (
              <>
                <table className="table table-bordered table-striped">
                  <thead className="tt-group-header">
                    <tr>
                      <th width="210px">Title</th>
                      <th width="210px">Notification Date</th>

                      <th width="165px">Notification Type</th>
                      <th width="165px">Status</th>

                      <th width="120px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.notificationsData.map((notification) => (
                      <tr>
                        <td>{notification.heading}</td>
                        <td>
                          {moment(notification.notifyDate).format("yyyy-MM-DD")}
                        </td>
                        <td>{notification.notificationName}</td>
                        <td>
                          {notification.isScheduled ? "Scheduled" : "Sent"}

                          <div
                            className={
                              notification.isSend
                                ? "tt-chip-green"
                                : "tt-chip-red"
                            }
                          >
                            {notification.isSend ? "Sent" : "Not Sent"}
                          </div>
                        </td>
                        <td>
                          <button
                            className="tt-button tt-button-primary ml-3"
                            onClick={() =>
                              this.handleClickDetails(notification)
                            }
                          >
                            Details
                          </button>

                          <button
                            className="tt-button tt-button-primary ml-3"
                            onClick={() => this.handleDelete(notification)}
                          >
                            Delete
                          </button>
                          {notification.isScheduled ? (
                            <>
                              <button
                                className="tt-button tt-button-primary ml-3"
                                onClick={() =>
                                  this.handleEditReminder(notification)
                                }
                              >
                                Edit
                              </button>
                              {notification.isEnabled ? (
                                <button
                                  className="tt-button tt-button-primary ml-3"
                                  onClick={() =>
                                    this.sendInstantNotification(notification)
                                  }
                                >
                                  Send
                                </button>
                              ) : (
                                ""
                              )}

                              <button
                                className={
                                  notification.isEnabled
                                    ? "tt-button-danger tt-button ml-3"
                                    : "tt-button-primary tt-button ml-3"
                                }
                                onClick={() => {
                                  this.handleEnableDisable(notification);
                                }}
                              >
                                {notification.isEnabled ? "Disable" : "Enable"}
                              </button>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* 
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    itemsCount={this.state.totalRecords}
                    pageSize={this.state.pageSize}
                    currentPage={this.state.currentPage}
                    onPageChange={this.handlePageChange}
                    previousPage={this.handlePreviousPage}
                    nextPage={this.handleNextPage}
                  />
                </div> */}
              </>
            ) : (
              <div className="text-center p-2">
                No records found for your search parameters!!
              </div>
            )}
          </div>

          <ModalWindow
            modal={this.state.editRemModal}
            modalHeader="Update Reminder"
            toggleModal={this.toggleUpdateModal}
            modalBody={
              <Reminder
                updateRemData={this.state.updateRemData}
                editMode={true}
                toggleUpdateModal={this.toggleUpdateModal}
                getAllNotifications={this.getAllNotifications}
              />
            }
            size="lg"
          ></ModalWindow>
          <ModalWindow
            modal={this.state.detailsModal}
            toggleModal={this.toggleDetailsModal}
            modalHeader="Notification Details"
            modalBody={
              <ReminderDetails
                detailsNotifications={this.state.detailsNotifications}
              />
            }
            size="xl"
          ></ModalWindow>
        </div>
      </>
    );
  }
}

export default ListReminder;
