import React, { Component } from "react";
import { URL } from "../../../../utils/Constants";
import { axiosPost } from "./../../../../utils/AxiosApi";
import ModalWindow from "./../../../UI/ModalWindow";
import {
  displayErrorAlert,
  displaySuccessAlert,
} from "./../../../../utils/Utils";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

class SubstituteTeacherPermission extends Component {
  state = {
    roles: [],
    roleId: null,
    toggleModal: false,
    assignDisabled: true,
    roleName: null,
    users: [],
    permissions: [],
    rolePermisions: [],
    selectedUsers: [],
  };

  componentDidMount() {
    this.getRoles();
    this.getClassroomTeacherPermissions();
    this.getStaff();
  }

  getStaff = () => {
    axiosPost(
      URL.getStaffListExceptUser,
      {
        recruitmentStatusId: 3,
      },
      (response) => {
        if (response.status === 200) {
          const staffs = response.data.data;
          staffs.forEach((staff) => {
            staff.label = staff.people.name;
            staff.value = staff.peopleId;
          });
          this.setState({ users: staffs });
        }
      }
    );
  };

  getStaffWithRole = () => {
    axiosPost(
      URL.getStaffListForSubTeacherRole,
      {
        recruitmentStatusId: 3,
        substituteClassroomTeacherRoleId: this.state.roleId,
        classroomId: this.props.classroomId,
        yearLevelId: this.props.yearLevelId,
      },
      (response) => {
        if (response.status === 200) {
          const staffs = response.data.data;
          staffs.forEach((staff) => {
            staff.label = staff.people.name;
            staff.value = staff.peopleId;
          });
          this.setState({ selectedUsers: staffs });
        }
      }
    );
  };

  getRoles = () => {
    axiosPost(URL.getSubstituteTeacherRole, {}, (response) => {
      if (response.status === 200) {
        this.setState({ roles: response.data.data });
      }
    });
  };

  getClassroomTeacherPermissions = () => {
    const data = {
      id: this.props.classroomId,
    };
    axiosPost(URL.getClassroomTeacherPermissions, data, (response) => {
      const permissions = response.data.data;
      permissions.forEach((permission) => (permission.checked = false));
      this.setState({ permissions: permissions });
    });
  };

  getSubstituteTeacherRolePermission = () => {
    axiosPost(
      URL.getSubstituteTeacherRolePermission,
      {
        substituteClassroomTeacherRoleId: this.state.roleId,
      },
      (response) => {
        if (response.status === 200) {
          let newPermissions = [...this.state.permissions];
          newPermissions.forEach((permission) => {
            permission.checked = false;
          });
          if (response.data.data.length > 0) {
            const rolePermissions = response.data.data;
            rolePermissions.forEach((rolePermission) => {
              const index = newPermissions.findIndex(
                (permission) =>
                  rolePermission.widgetId === permission.widgetId &&
                  rolePermission.moduleId === permission.moduleId &&
                  rolePermission.activityId === permission.activityId
              );
              if (index !== -1) {
                newPermissions[index].checked = true;
              }
            });
            this.setState({
              assignDisabled: false,
              permissions: newPermissions,
            });
          } else {
            this.setState({
              assignDisabled: true,
              permissions: newPermissions,
            });
          }
        }
      }
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({ [name]: e.target.value }, () => {
      if (name === "roleId") {
        this.getSubstituteTeacherRolePermission();
        this.getStaffWithRole();
      }
    });
  };

  getSubstituteTeachers = () => {
    axiosPost(URL.getSubstituteTeachers, {});
  };

  handleActivityChange = (e, index) => {
    let newPermissions = [...this.state.permissions];
    newPermissions[index].checked = e.target.checked;
    this.setState({ permissions: newPermissions });
  };

  renderAddNewModal = () => {
    return (
      <div className="form-group row">
        <div className="col-md-3 mt-1">Name:</div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            name="roleName"
            onChange={this.handleChange}
          />
        </div>
        <div className="col-md-3">
          <button
            className="tt-button tt-button-primary"
            onClick={this.handleNewRoleSave}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  handleNewRoleSave = () => {
    this.state.roleName !== null &&
      this.state.roleName !== "" &&
      axiosPost(
        URL.insertSubstituteTeacherRole,
        { name: this.state.roleName },
        (response) => {
          if (response.status === 200) {
            this.getRoles();
            this.setState({ toggleModal: !this.state.toggleModal });
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
  };

  handleAssignPermissionToRole = () => {
    let data = [];
    const roleId = parseInt(this.state.roleId);
    const classroomId = this.props.classroomId;
    const yearLevelId = this.props.yearLevelId;
    this.state.permissions.forEach((permission) => {
      permission.checked &&
        data.push({
          substituteClassroomTeacherRoleId: roleId,
          widgetId: permission.widgetId,
          moduleId: permission.moduleId,
          activityId: permission.activityId,
          classroomId: classroomId,
          yearLevelId,
        });
    });
    if (this.state.roleId) {
      axiosPost(
        URL.insertSubstituteTeacherRolePermission,
        data,
        (response) => {
          if (response.status === 200) {
            displaySuccessAlert(
              response,
              this.getSubstituteTeacherRolePermission()
            );
          }
        },
        (err) => displayErrorAlert(err)
      );
    }
  };

  handleAssignPermission = () => {
    let data = [];
    if (this.state.roleId && this.state.selectedUsers.length > 0) {
      this.state.selectedUsers.forEach((user) => {
        data.push({
          substituteClassroomTeacherRoleId: this.state.roleId,
          recruitmentId: user.id,
          classroomId: this.props.classroomId,
          yearLevelId: this.props.yearLevelId,
        });
      });
    }
    axiosPost(
      URL.assignSubstituteRoleToTeacher,
      data,
      (response) => {
        response.status === 200 && displaySuccessAlert(response);
      },
      (err) => displayErrorAlert(err)
    );
  };

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div className="form-group row">
          <div className="col-md-3 mt-1">Role Name:</div>
          <div className="col-md-6">
            <select
              className="form-control"
              onChange={this.handleChange}
              name="roleId"
            >
              <option selected disabled>
                select role
              </option>
              {this.state.roles.map((role) => {
                return <option value={role.id}>{role.name}</option>;
              })}
            </select>
          </div>
          <div className="col-md-3">
            <button
              className="tt-button tt-button-primary"
              onClick={() => {
                this.setState({ toggleModal: !this.state.toggleModal });
              }}
            >
              Add New
            </button>
          </div>
          <ModalWindow
            //   fullWidth={true}
            modal={this.state.toggleModal}
            toggleModal={() => {
              this.setState({ toggleModal: !this.state.toggleModal });
            }}
            modalHeader={"Add New Role"}
            modalBody={this.renderAddNewModal()}
          ></ModalWindow>
        </div>

        <div className="form-group row">
          <div className="col-md-3">Permissions:</div>
          <div className="col-md-6">
            <div className="row">
              {this.state.permissions.map((permission, idx) => (
                <div className="col-md-6">
                  <label>{permission.activityName}</label>
                  <input
                    type="checkbox"
                    className="form-control"
                    checked={permission.checked}
                    onChange={(e) => this.handleActivityChange(e, idx)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3">
            <button
              className="tt-button tt-button-primary"
              onClick={this.handleAssignPermissionToRole}
            >
              Assign To Role
            </button>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-3">User:</div>
          <div className="col-md-6">
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              value={this.state.selectedUsers}
              options={this.state.users}
              name="users"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(items) => this.setState({ selectedUsers: items })}
            />
          </div>
        </div>
        <div className="form-group row mt-3">
          <div className="col-md-3"></div>
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <button
              className="tt-button tt-button-primary ml-3"
              onClick={this.handleAssignPermission}
              disabled={this.state.assignDisabled}
            >
              Assign To User
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SubstituteTeacherPermission;
