import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import moment from "moment";
import { URL } from "../../utils/Constants";

class DailySummary extends Component {
  state = {
    dailyData: [],
    totalStudents: null,
  };

  componentDidMount() {
    this.getStudentAttendanceForTheDay();
    this.getTotalStudents();
  }

  getTotalStudents = () => {
    axiosPost(URL.getTotalStudentsAssignedToSomeClassroom, {}, (response) => {
      this.setState({ totalStudents: response.data.data.totalStudents });
    });
  };

  getStudentAttendanceForTheDay = () => {
    axiosPost(
      URL.getStudentsAttendance,
      { date: moment().format("YYYY-MM-DD") },
      (response) => {
        this.setState({ dailyData: response.data.data });
      }
    );
  };
  render() {
    const presentStudents = this.state.dailyData.filter(
      (data) => data.isPresent
    ).length;
    const absentStudents = this.state.dailyData.filter(
      (data) => !data.isPresent
    ).length;
    return (
      <>
        <div className="row pl-4 ml-3" style={{ width: "90%" }}>
          <div className="col-md-4 p-4 total-days">
            <div style={{ color: "white" }}>
              Total Students: {this.state.totalStudents || 0}
            </div>
          </div>
          <div className="col-md-4 p-4 present-days">
            <div style={{ color: "white" }}>
              Present Students: {presentStudents}
            </div>
          </div>
          <div className="col-md-4 p-4 absent-days">
            <div style={{ color: "white" }}>
              Absent Students: {absentStudents}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DailySummary;
