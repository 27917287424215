/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, SetupThree, is for the view of setup Three porition of the System Setup Process
 */

import React, { Component } from "react";
import AddressClusterField from "./AddressClusterField";
import Chip from "@material-ui/core/Chip";

class SetupThree extends Component {
  render() {
    let addressClusterFields = this.props.addressClusterFields,
      addressClusterFieldsCopy = this.props.addressClusterFieldsCopy,
      addressClusterFieldLables = this.props.addressClusterFieldLables,
      selectedAddressClusterFieldsForChips = this.props
        .selectedAddressClusterFieldsForChips;
    return (
      <>
        <div className="tt-group-header tt-systemSetupTwo">
          {"Address Cluster"}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="tt-group-header">{"Address Fields"}</div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4 card-title text-center">
                <strong>{"Field Label"}</strong>
              </div>
              <div className="col-md-5 card-title text-center">
                <strong>{"Example"}</strong>
              </div>
              <div className="col-md-2">
                <strong>Mandatory</strong>
              </div>
            </div>
            {/* address component start */}
            {addressClusterFields.map((field, i) => (
              <AddressClusterField
                addressClusterField={field}
                addressClusterFields={addressClusterFields}
                addressClusterFieldLables={addressClusterFieldLables}
                addressClusterFieldIndex={i}
                handleRemoveAddressClusterField={
                  this.props.handleRemoveAddressClusterField
                }
                handleAddressClusterFieldLabelChange={
                  this.props.handleAddressClusterFieldLabelChange
                }
                handleAddressClusterFieldExampleValueChange={
                  this.props.handleAddressClusterFieldExampleValueChange
                }
                handleCheckBoxChange={this.props.handleCheckBoxChange}
              />
            ))}
            {/* address component end */}
            <button
              type="button"
              className="tt-button tt-button-primary setup-two"
              onClick={this.props.handleAddAddressClusterField}
              style={{ margin: "20px" }}
            >
              {"Add New Address Field"}
            </button>
          </div>
          <div className="card-footer">
            <b>NOTE: </b>
            <i>
              Automatic address fields will be generated according to
              administrative divisions of a country, i.e. state/province,
              district, city/town/village, etc.
            </i>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-body">
            <div className="tt-group-header">{"Address Display Format"}</div>
            <hr />
            {/* <div className="in-report"> */}
            <div className="address-format">
              {selectedAddressClusterFieldsForChips.map((field, index) => (
                <span>
                  <strong>{field.exampleValue}</strong>
                </span>
              ))}
            </div>
            <hr />
          </div>
          <div className="card-footer">
            {selectedAddressClusterFieldsForChips.map((field, index) => (
              <span>
                <Chip
                  className="override-chip"
                  label={field.fieldName !== "\n" ? field.fieldName : "\u23CE"}
                  color={field.literal ? "secondary" : "basic"}
                  onDelete={(e) =>
                    this.props.handleAddressClusterChipDelete(e, field)
                  }
                />
              </span>
            ))}
          </div>
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10">
                  {addressClusterFieldsCopy.map((field, index) => (
                    <span>
                      <Chip
                        className="override-chip"
                        label={field.fieldName}
                        clickable
                        color="primary"
                        onClick={(e) =>
                          this.props.handleUnselectedAddressLabelChipClick(
                            e,
                            field
                          )
                        }
                      />
                    </span>
                  ))}
                  <span>
                    <Chip
                      className="override-chip"
                      label="&#9166;"
                      clickable
                      color="primary"
                      onClick={this.props.handleReturnChipsClick}
                    />
                  </span>
                </div>

                <div className="col-md-2 mb-2">
                  <input
                    onKeyPress={this.props.handleAddCustomTextToAddressFormat}
                    className="literal-input"
                    placeholder="Add custom input and press enter key"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9"></div>
            <div className="col-md-2 submit-setup text-right">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.changeToPrevIndex}
              >
                Previous
              </button>
            </div>
            <div className="col-md-1 submit-setup">
              <button
                className="tt-button tt-button-primary"
                onClick={(e) => this.props.handleSubmit(e)}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SetupThree;
