import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, draggable } from "../../utils/Constants";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { displayErrorAlert, showErroMessage } from "../../utils/Utils";
import moment from "moment";
import { MESSAGEHEADER, DISPLAYMESSAGE } from "../../utils/DisplayMessage";
import ModalWindow from "../UI/ModalWindow";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";
import Select from "react-select";

const animatedComponents = makeAnimated();

class CentralVideoConference extends Component {
  state = {
    sessionName: "",
    dateAndTime: new Date(),
    startTime: "",
    endTime: "",
    description: "",
    password: "",
    peopleSpinner: false,
    peopleArray: [],
    selectedIndividual: [],
    isOnlineClass: true,
    listSubjects: [],
    selectedSubject: null,
    disableDefaultSubject: false,
    permissionModal: false,
    usersList: [],
    peopleArr: [],
  };

  componentDidMount() {
    this.getAllUsers();
  }

  getAllUsers = () => {
    axiosPost(
      URL.getAllCombinedUsers,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({ usersList: response.data.data });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleGeneratePassword = (e) => {
    e.preventDefault();
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.setState({ password: retVal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.sessionName !== "" &&
      this.state.startTime !== "" &&
      this.state.endTime !== "" &&
      this.state.password !== ""
    ) {
      let startTime = this.state.startTime,
        endTime = this.state.endTime,
        dateAndTime = this.state.dateAndTime,
        peoplesData = this.state.peopleArr.map((pa) => {
          return pa.id;
        });

      let param = {
        sessionName: this.state.sessionName,
        startDateTime: new Date(dateAndTime + " " + startTime),
        endDateTime: new Date(dateAndTime + " " + endTime),
        password: this.state.password,
        description: this.state.description,
        groups: [{ id: this.props.groupId }],
        peoples: peoplesData,
      };
      this.setState({ showSpinner: true }, () => {
        axiosPost(
          URL.videoConferencInsert,
          param,
          (response) => {
            if (response.status === 200) {
              swal(MESSAGEHEADER.success, DISPLAYMESSAGE.insertVideoConference);
              this.setState({ showSpinner: false });
              this.clearState();
            } else {
              showErroMessage(response);
            }
          },
          (failure) => {
            swal({
              title: MESSAGEHEADER.error,
              text: "Error inserting",
            });
            this.setState({ showSpinner: false });
          }
        );
      });
    } else {
      swal({
        title: MESSAGEHEADER.error,
        text: "Please fill the fields first and then click create!",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  };

  clearState = () => {
    this.setState({
      sessionName: "",
      dateAndTime: new Date(),
      startTime: "",
      endTime: "",
      description: "",
      password: "",
      peopleArr: [],
    });
  };

  handleUsersSelect = (items) => {
    this.setState({ peopleArr: items });
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Processing...
            </div>
          </div>
        ) : null}
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">Create Video Conference</div>
          <div className="tt-newListBox container-fluid">
            <div style={{ height: "40vh" }}>
              <div className="row mb-2 mt-3">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Session name :</strong>
                </div>
                <div className="col-md-7">
                  <input
                    type="text"
                    className="form-control cusInput"
                    name="sessionName"
                    value={this.state.sessionName}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row mb-2 mt-3">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>People :</strong>
                </div>
                <div className="col-md-7">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={this.state.usersList}
                    value={this.state.peopleArr}
                    onChange={(items) => this.handleUsersSelect(items)}
                    placeholder="Select users for conference"
                    classNamePrefix="cusSelect"
                  />
                </div>
                <div className="col-md-1"></div>
              </div>

              <div className="row mt-2">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right"></div>
                <div className="col-md-3 text-center"></div>
                <div className="col-md-2 text-center pl-0">
                  <strong>Start Time</strong>
                </div>
                <div className="col-md-2 text-center pl-0">
                  <strong>End Time</strong>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Choose Date and Time :</strong>
                </div>
                <div className="col-md-3">
                  <input
                    type="date"
                    className="form-control cusInput"
                    name="dateAndTime"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    value={this.state.dateAndTime}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2 pl-0">
                  <input
                    type="time"
                    className="form-control cusInput"
                    name="startTime"
                    value={this.state.startTime}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2 pl-0">
                  <input
                    type="time"
                    className="form-control cusInput"
                    name="endTime"
                    value={this.state.endTime}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-1"></div>
              </div>

              <div className="row mt-2">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Description :</strong>
                </div>
                <div className="col-md-7">
                  <textarea
                    className="form-control cusInput"
                    placeholder="Add description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row mt-2">
                <div className="col-md-1"></div>
                <div className="col-md-3 text-right">
                  <strong>Set a Password :</strong>
                </div>
                <div className="col-md-5">
                  <input
                    type="text"
                    className="form-control cusInput"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.handleGeneratePassword}
                  >
                    Generate
                  </button>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
            <div className="row">
              <div className="col text-right">
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={this.handleSubmit}
                >
                  Create
                </button>
                {/* <button className="tt-button tt-button-primary mr-3">
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CentralVideoConference;
