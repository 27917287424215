import React, { Component } from "react";
import "../../../assets/scss/repository.scss";
import RepositorySearchSection from "./RepositorySearchSection";
import swal from "sweetalert";
import RepositoryList from "./RepositoryList";
import { axiosPost } from "../../../utils/AxiosApi";
import { connect } from "react-redux";
import {
  URL,
  draggable,
  postType,
  profilePeopleId,
} from "../../../utils/Constants";
import {
  getPersonAcademicRepoPosts,
  searchAcademicRepoPosts,
  getPersonPostSectionAcademicRepoPosts,
  getPersonProfilePosts,
  getNotices,
} from "../../../ducks/PostsDucks";
import RepositoryQuestionLists from "./RepositoryQuestionLists";
import moment from "moment";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import {
  youtubeLink,
  dailyMotionLink,
  vimeoLink,
  slideshareLink,
  defaultLinkURL,
} from "../../../utils/VideoURL";

class Repository extends Component {
  state = {
    searchText: "",
    keywords: "",
    theme: "",
    fromDate: "",
    toDate: "",
    postStatus: true,
    subjects: "",
    themeList: null,
    subjectList: null,
    academicRepoList: null,
    personAcademicRepoPosts: [],
    questionLists: "",
    isVerified: false,
    editArticleAcademicModal: false,
    editMediaAcademicModal: false,
    selectedPost: "",
    spationQuestions: [],
    whoQuestions: [],
    howQuestions: [],
    whatQuestions: [],
    totalAcademicRepoPosts: 0,
    offset: 0,
    rowCountLimit: 8,
    searchParam: "All",
    currentPage: 1,
  };

  toggleArticleEditModal = (e, data, action) => {
    this.setState({
      editArticleAcademicModal: !this.state.editArticleAcademicModal,
    });
    if (action === "open") {
      this.setState({ selectedPost: data });
    }
  };
  toggleMediaEditModal = (e, data, action) => {
    this.setState({
      editMediaAcademicModal: !this.state.editMediaAcademicModal,
    });
    if (action === "open") {
      this.setState({ selectedPost: data });
    }
  };

  componentDidMount() {
    this.getThemeList();
    this.getSubjectList();
    this.props.getPersonAcademicRepoPosts(
      true,
      this.state.offset,
      this.state.rowCountLimit
    );
  }

  //Pagination

  handlePageChange = (page) => {
    this.reRenderRepoList(page);
    this.setState({ currentPage: page }, function () {
      //   this.setDisplayNumber();
    });
  };

  reRenderRepoList = (page) => {
    let offset =
      page * parseInt(this.state.rowCountLimit) -
      parseInt(this.state.rowCountLimit);
    this.setState({ offset: offset }, function () {
      this.props.getPersonAcademicRepoPosts(
        true,
        offset,
        this.state.rowCountLimit
      );
    });
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1)
      this.reRenderRepoList(this.state.currentPage - 1);
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage - 1,
      }),
      function () {
        // this.setDisplayNumber();
      }
    );
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.totalAcademicRepoPosts / this.state.rowCountLimit)
    )
      return;
    else {
      this.reRenderRepoList(nextPage);
      this.setState({ currentPage: nextPage }, function () {
        // this.setDisplayNumber();
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let academicPosts = nextProps.personAcademicRepoPosts;
    let questionLists = [];
    academicPosts.forEach((el) => {
      if (el.postQuestions && el.postQuestions.length > 0) {
        el.postQuestions.forEach((questions) => {
          let count = 0;
          if (questionLists && questionLists.length > 0) {
            questionLists.forEach((storeQuestion) => {
              if (questions.id === storeQuestion.id) {
                count++;
              }
            });
          }
          if (count === 0) {
            questionLists.push(questions);
          }
        });
      }
    });
    let spationQuestions = [];
    let whoQuestions = [];
    let howQuestions = [];
    let whatQuestions = [];
    if (questionLists.length > 0) {
      questionLists.forEach((el) => {
        var firstWord = el.question.substr(0, el.question.indexOf(" "));
        if (
          firstWord.includes("when") ||
          firstWord.includes("where") ||
          firstWord.includes("When") ||
          firstWord.includes("Where")
        ) {
          spationQuestions.push(el);
        }
        if (firstWord.includes("who") || firstWord.includes("Who")) {
          whoQuestions.push(el);
        }
        if (firstWord.includes("how") || firstWord.includes("How")) {
          howQuestions.push(el);
        }
        if (firstWord.includes("what") || firstWord.includes("What")) {
          whatQuestions.push(el);
        }
      });
    }
    let newRepoPosts = [];
    if (academicPosts.length > 0) {
      academicPosts.forEach((post) => {
        if (
          post.postType === postType.articleCode ||
          post.postType === postType.mediaCode
        ) {
          post.contents.forEach((content) => {
            if (content.contentTypeCode === "LNK" && content.content !== "") {
              let videoId = "";
              if (
                content.content.includes("youtube") ||
                content.content.includes("youtu.be")
              ) {
                let contentObj = youtubeLink(content.content);
                content.youtubeTitle = contentObj.youtubeTitle;
                content.thumbnail = contentObj.thumbnail;
              } else if (
                content.content.includes("dailymotion") ||
                content.content.includes("dai.ly")
              ) {
                let contentObj = dailyMotionLink(content.content);
                content.youtubeTitle = contentObj.youtubeTitle;
                content.thumbnail = contentObj.thumbnail;
              } else if (content.content.includes("vimeo")) {
                let contentObj = vimeoLink(content.content);
                content.youtubeTitle = contentObj.youtubeTitle;
                content.thumbnail = contentObj.thumbnail;
              } else if (
                content.content.includes("slideshare") &&
                content.youtubeTitle !== undefined
              ) {
                let contentObj = slideshareLink(content.content);
                content.youtubeTitle = contentObj.youtubeTitle;
                content.thumbnail = contentObj.thumbnail;
              } else {
                let contentObj = defaultLinkURL(content.content);
                content.youtubeTitle = contentObj.youtubeTitle;
                content.thumbnail = contentObj.thumbnail;
              }
            }
          });
          newRepoPosts.push(post);
        }
      });
    }
    return {
      personAcademicRepoPosts: newRepoPosts,
      questionLists: questionLists,
      spationQuestions: spationQuestions,
      whoQuestions: whoQuestions,
      whatQuestions: whatQuestions,
      howQuestions: howQuestions,
      totalAcademicRepoPosts: nextProps.totalAcademicRepoPosts,
    };
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "toDate" || name === "fromDate") {
      if (name === "toDate" && this.state.fromDate !== "") {
        let toDate = moment(value).format("YYYY-MM-DD");
        let fromDate = moment(this.state.fromDate).format("YYYY-MM-DD");
        if (toDate < fromDate) {
          swal({
            title: "Error",
            text: "Date To cannot be less than Date From",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          return false;
        } else {
          this.setState({
            [name]: value,
          });
        }
      } else if (name === "fromDate" && this.state.toDate !== "") {
        let toDate = moment(this.state.toDate).format("YYYY-MM-DD");
        let fromDate = moment(value).format("YYYY-MM-DD");
        if (fromDate > toDate) {
          swal({
            title: "Error",
            text: "Date From cannot be greater than Date to",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          return false;
        } else {
          this.setState({
            [name]: value,
          });
        }
      } else {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  searchPost = (e) => {
    e.preventDefault();
    let data = {
      searchPost: this.state.searchText,
      keywords: this.state.keywords,
      themeId: this.state.theme,
      isVerified: this.state.postStatus,
      from: this.state.fromDate,
      to: this.state.toDate,
      subject: this.state.subjects,
      offset: 0,
      rowCountLimit: this.state.rowCountLimit,
    };
    this.setState({
      searchParam: this.state.searchText !== "" ? this.state.searchText : "All",
    });
    this.props.searchAcademicRepoPosts(data);
    // this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
  };

  acceptAcademicRepo = (e, post) => {
    let data = {
      id: post.id,
      isVerified: true,
      postKeywords: [],
      postQuestions: [],
      subjects: [],
      textContent: post.textContent,
      heading: post.heading,
      contents: [],
      postThemes: [],
    };
    axiosPost(URL.updateAcademicRepo, data, (response) => {
      if (response.status === 200) {
        swal({
          title: MESSAGEHEADER.success,
          text: "Successfully accepted for academic repository.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.searchPost(e);
        this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
        this.props.getPersonProfilePosts(profilePeopleId, "initCall");
        this.props.getNotices(
          { peopleId: localStorage.getItem("peopleId") },
          this.clearAndUpdateInterval
        );
      }
    });
  };

  rejectAcademicRepo = (e, post) => {
    let data = {
      id: post.id,
      isVerified: true,
      postKeywords: [],
      postQuestions: [],
      subjects: [],
      textContent: post.textContent,
      heading: post.heading,
      contents: [],
      postThemes: [],
      deletedStatus: true,
    };
    axiosPost(URL.updateAcademicRepo, data, (response) => {
      if (response.status === 200) {
        swal({
          title: MESSAGEHEADER.success,
          text: "This post is rejected for academic repository.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.searchPost(e);
        this.props.getPersonPostSectionAcademicRepoPosts(true, "initCall");
        this.props.getPersonProfilePosts(profilePeopleId, "initCall");

        this.props.getNotices(
          { peopleId: localStorage.getItem("peopleId") },
          this.clearAndUpdateInterval
        );
      }
    });
  };

  updateAcademicRepo = (e, data) => {
    axiosPost(URL.updateAcademicRepo, data, (response) => {
      if (response.status === 200) {
        swal({
          title: MESSAGEHEADER.success,
          text: "Updated successfully",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        if (this.state.editArticleAcademicModal) {
          this.toggleArticleEditModal(e);
        }
        this.searchPost(e);
      }
    });
  };

  updateMediaAcademicRepo = (e, data, selectedImages) => {
    e.preventDefault();
    let newImages = [];
    let oldImages = [];
    if (selectedImages.length > 0) {
      selectedImages.forEach((el) => {
        if (el.updatedData) {
          oldImages.push(el);
        } else {
          newImages.push(el);
        }
      });
      if (oldImages.length > 0) {
        oldImages.forEach((ol) => {
          data.contents.push(ol);
        });
      }
    }
    axiosPost(URL.updateAcademicRepo, data, (response) => {
      if (response.status === 200) {
        if (newImages.length > 0) {
          let formData = new FormData();

          for (const key of Object.keys(newImages)) {
            formData.append("content", newImages[key]);
          }
          formData.append(
            "jsonData",
            JSON.stringify({
              origin: "myPC",
              postId: response.data.data.postId,
            })
          );

          axiosPost(URL.insertPostContent, formData, (response) => {
            if (response.status === 200) {
              swal({
                title: MESSAGEHEADER.success,
                text: "Updated successfully",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.searchPost(e);
              this.toggleMediaEditModal(e);
            }
          });
        } else {
          swal({
            title: MESSAGEHEADER.success,
            text: "Updated successfully",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
          this.searchPost(e);
          this.toggleMediaEditModal(e);
        }
      }
    });
  };

  searchQuestion = (e, question) => {
    e.preventDefault();
    this.setState({ searchText: question }, function () {
      this.searchPost(e);
    });
  };

  getThemeList = () => {
    axiosPost(URL.selectTheme, {}, (response) => {
      if (response.status === 200) {
        this.setState({ themeList: response.data.data });
      }
    });
  };
  getSubjectList = () => {
    axiosPost(URL.subjectList, {}, (response) => {
      if (response.status === 200) {
        this.setState({ subjectList: response.data.data });
      }
    });
  };

  clearSearch = (e) => {
    this.setState(
      {
        searchText: "",
        keywords: "",
        theme: "",
        fromDate: "",
        toDate: "",
        postStatus: true,
        subjects: "",
      },
      function () {
        this.searchPost(e);
      }
    );
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "87vh" }}>
        <div className="row">
          <div className="col-md-9">
            <RepositorySearchSection
              searchText={this.state.searchText}
              keywords={this.state.keywords}
              handleChange={this.handleChange}
              theme={this.state.theme}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              postStatus={this.state.postStatus}
              subjects={this.state.subjects}
              searchPost={this.searchPost}
              themeList={this.state.themeList}
              subjectList={this.state.subjectList}
              totalAcademicRepoPosts={this.state.totalAcademicRepoPosts}
              clearSearch={this.clearSearch}
              searchParam={this.state.searchParam}
            ></RepositorySearchSection>
            <RepositoryList
              personAcademicRepoPosts={this.state.personAcademicRepoPosts}
              acceptAcademicRepo={this.acceptAcademicRepo}
              rejectAcademicRepo={this.rejectAcademicRepo}
              subjectList={this.state.subjectList}
              updateAcademicRepo={this.updateAcademicRepo}
              toggleArticleEditModal={this.toggleArticleEditModal}
              toggleMediaEditModal={this.toggleMediaEditModal}
              editArticleAcademicModal={this.state.editArticleAcademicModal}
              editMediaAcademicModal={this.state.editMediaAcademicModal}
              selectedPost={this.state.selectedPost}
              updateMediaAcademicRepo={this.updateMediaAcademicRepo}
              totalAcademicRepoPosts={this.state.totalAcademicRepoPosts}
              rowCountLimit={this.state.rowCountLimit}
              currentPage={this.state.currentPage}
              handlePageChange={this.handlePageChange}
              handleNextPage={this.handleNextPage}
              handlePreviousPage={this.handlePreviousPage}
            ></RepositoryList>
          </div>
          <div className="col-md-3">
            <RepositoryQuestionLists
              questionLists={this.state.questionLists}
              searchQuestion={this.searchQuestion}
              spationQuestions={this.state.spationQuestions}
              whoQuestions={this.state.whoQuestions}
              whatQuestions={this.state.whatQuestions}
              howQuestions={this.state.howQuestions}
            ></RepositoryQuestionLists>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  personAcademicRepoPosts: state.posts.personAcademicRepoPosts,
  totalAcademicRepoPosts: state.posts.totalAcademicRepoPosts,
});

const mapActionToProps = {
  getNotices,
  getPersonAcademicRepoPosts,
  searchAcademicRepoPosts,
  getPersonPostSectionAcademicRepoPosts,
  getPersonProfilePosts,
};
export default connect(mapStateToProps, mapActionToProps)(Repository);
