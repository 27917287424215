import React, { Component, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  checkWidgetAdminOrNot,
  draggable,
} from "../../../utils/Constants";
import "../../../assets/scss/academicYear.scss";
import Semester from "./Semester";
import CustomCollapse from "../../UI/CustomCollapse";
import Scroll from "react-scroll";
import ModalWindow from "./../../UI/ModalWindow";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import { connect } from "react-redux";
import { getAcademicYears } from "../../../ducks/AcademicAdministrationDucks";
import {
  displaySuccessAlert,
  displayConfirmDeleteAlert,
  showErroMessage,
} from "../../../utils/Utils";
import { checkRolePermissions } from "../../../utils/Constants";
import AddTermsModal from "./AddTermsModal";
import swal from "sweetalert";
import moment from "moment";
import { Spinner } from "reactstrap";

let scroll = Scroll.animateScroll;

class AcademicYearsList extends Component {
  state = {
    termAlias:
      localStorage.getItem("educationLevelDivision") === null
        ? "Term"
        : localStorage.getItem("educationLevelDivision"), //get from localStorage
    academicYearsList: [],
    updatedName: "",
    updatedStartDate: "",
    updatedEndDate: "",
    updatedId: "",
    updatedweekDays: [],
    weekDays: [],
    addTermModal: false,
    newTerms: [],
    selectedEducationLevel: "",
    selectedAcademicYear: "",
    minStartDate: "",
    showSpinner: false,
    maxEndDate: "",
  };

  toggleCollpasible = (e, id, idx) => {
    let academicYearsList = [...this.state.academicYearsList];
    academicYearsList.forEach((el) => {
      if (parseInt(el.id) === parseInt(id)) {
        el.isOpen = !el.isOpen;
      } else {
        el.isOpen = false;
      }
    });
    if (academicYearsList.length > 1) {
      let spliceArray = academicYearsList.splice(idx, 1);
      academicYearsList.unshift(spliceArray[0]);
    }
    this.setState({
      academicYearsList,
    });
  };
  componentDidMount() {
    this.getAcademicYears();
    this.getWeekDays();
  }

  getWeekDays = () => {
    axiosPost(URL.getWeekDays, {}, (response) => {
      if (response.status === 200) {
        let days = response.data.data;
        days.forEach((week) => {
          week.checked = false;
        });
        this.setState({ weekDays: days });
      }
    });
  };

  getAcademicYears = () => {
    axiosPost(URL.getAcademicYears, {}, (response) => {
      if (response.status === 200) {
        let academicYearsList = response.data.data;
        academicYearsList.forEach((el, idx) => {
          idx === 0 ? (el.isOpen = true) : (el.isOpen = false);
          el.realName = el.name;
          el.name = el.name.replace(/_/g, "-");
        });

        this.setState(
          {
            academicYearsList,
          },
          () => {
            let academicYearsList = [...this.state.academicYearsList];
            academicYearsList.forEach((academicYear) => {
              if (academicYear && academicYear.educationLevels) {
                academicYear.educationLevels.forEach((educationLevel) => {
                  educationLevel.educationLevelDivisions.forEach(
                    (educationLevelDivision) => {
                      educationLevelDivision.isEditable = false;
                    }
                  );
                });
              }
            });
            this.setState({ academicYearsList });
          }
        );
      }
    });
  };

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  handleCurrentChange = (e, id, idx) => {
    let param = {
      id: id,
      isCurrent: true,
    };
    axiosPost(URL.updateAcademicYearsIsCurrent, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        this.getDataAfterUpdate(id, idx);
      }
    });
  };

  getDataAfterUpdate = (id, idx) => {
    axiosPost(URL.getAcademicYears, {}, (response) => {
      if (response.status === 200) {
        let academicYearsList = response.data.data;
        academicYearsList.forEach((el) => {
          if (parseInt(el.id) === parseInt(id)) {
            el.isOpen = true;
          } else {
            el.isOpen = false;
          }
        });
        let spliceArray = academicYearsList.splice(idx, 1);
        academicYearsList.unshift(spliceArray[0]);
        this.setState({
          academicYearsList,
        });
      }
    });
  };

  handleEnabledClick = (e, id, idx, isEnabled) => {
    let param = {
      id: id,
      isEnabled: isEnabled,
    };

    axiosPost(URL.updateAcademicYearsIsEnabled, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        this.getDataAfterUpdate(id, idx);
      }
    });
  };

  handleUpdate = (
    e,
    mainIndex,
    educationLevelIndex,
    educationLevelDivisionIndex
  ) => {
    let academicYearsList = [...this.state.academicYearsList];
    academicYearsList.forEach((academicYear, i) => {
      if (mainIndex === i) {
        academicYear.educationLevels.forEach((educationLevel, j) => {
          if (educationLevelIndex === j) {
            educationLevel.educationLevelDivisions.forEach(
              (educationLevelDivision, k) => {
                if (educationLevelDivisionIndex === k) {
                  educationLevelDivision.isEditable = true;
                  let weekDays = [...this.state.weekDays];
                  weekDays.forEach((week) => {
                    week.checked = false;
                  });
                  let updatedWeekDays = educationLevelDivision.weekDays;
                  for (i = 0; i < weekDays.length; i++) {
                    for (j = 0; j < updatedWeekDays.length; j++) {
                      if (weekDays[i].id === updatedWeekDays[j].id) {
                        weekDays[i].checked = true;
                      }
                    }
                  }
                  this.setState({
                    updatedName: educationLevelDivision.name,
                    updatedStartDate: educationLevelDivision.startDate,
                    updatedEndDate: educationLevelDivision.endDate,
                    updatedId: educationLevelDivision.id,
                    weekDays,
                  });
                } else {
                  educationLevelDivision.isEditable = false;
                }
              }
            );
          } else {
            educationLevel.educationLevelDivisions.forEach((eduDiv) => {
              eduDiv.isEditable = false;
            });
          }
        });
      } else {
        academicYear.educationLevels.forEach((eduLevel) => {
          eduLevel.educationLevelDivisions.forEach((eduDiv) => {
            eduDiv.isEditable = false;
          });
        });
      }
    });
    this.setState({ academicYearsList });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleChangeForCheckBox = (e, id) => {
    let weekDays = [...this.state.weekDays];
    weekDays.forEach((week, i) => {
      if (week.id === id) {
        week.checked = e.target.checked;
      }
    });
    this.setState({ weekDays });
  };
  handleCancelEdit = (e, mainIndex, eduLevelIdx, eduLevelDivIdx) => {
    e.preventDefault();
    let academicYearsList = [...this.state.academicYearsList];
    academicYearsList[mainIndex].educationLevels[
      eduLevelIdx
    ].educationLevelDivisions[eduLevelDivIdx].isEditable = false;
    this.setState({ academicYearsList });
  };

  handleDelete = (id, academicYearId, academicYearIndex) => {
    displayConfirmDeleteAlert(
      { id, academicYearId, academicYearIndex },
      this.handleRemoveEducationLevelDivision
    );
  };
  handleRemoveEducationLevelDivision = (data) => {
    let param = {
      id: data.id,
      academicYearId: data.academicYearId,
    };
    axiosPost(URL.deleteEducationLevelDivision, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        this.getDataAfterUpdate(data.academicYearId, data.academicYearIndex);
      } else {
        showErroMessage(response);
      }
    });
  };

  handleUpdateEducationLevelDivision = (
    e,
    id,
    academicYearId,
    academicYearIndex
  ) => {
    e.preventDefault();
    let weekDays = [...this.state.weekDays];
    let updatedWeekDays = [];
    weekDays.forEach((week) => {
      if (week.checked) {
        updatedWeekDays.push(week);
      }
    });
    let param = {
      id: id,
      name: this.state.updatedName,
      startDate: this.state.updatedStartDate,
      endDate: this.state.updatedEndDate,
      weekDays: updatedWeekDays,
      academicYearId: academicYearId,
    };
    axiosPost(URL.updateEducationLevelDivision, param, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        this.getDataAfterUpdate(academicYearId, academicYearIndex);
      } else {
        showErroMessage(response);
      }
    });
  };

  //Handle Add Terms
  handleAddTerms = (e, action, eduLevel, academicYear) => {
    let newTerms = [];
    if (action === "open") {
      newTerms = [
        {
          name: "",
          startDate: "",
          endDate: "",
          selectedWeekDays: JSON.parse(
            JSON.stringify([...this.state.weekDays])
          ),
        },
      ];
      this.setState({
        selectedAcademicYear: academicYear.dbName,
        selectedEducationLevel: eduLevel,

        maxEndDate: moment(academicYear.endDate).format("YYYY-MM-DD"),
      });

      if (eduLevel.educationLevelDivisions.length > 0) {
        const lastElement =
          eduLevel.educationLevelDivisions[
            eduLevel.educationLevelDivisions.length - 1
          ];
        this.setState({
          minStartDate: moment(lastElement.endDate).format("YYYY-MM-DD"),
        });
      } else {
        this.setState({
          minStartDate: moment(academicYear.startDate).format("YYYY-MM-DD"),
        });
      }
    } else {
      this.setState({
        selectedAcademicYear: "",
        selectedEducationLevel: "",
        minStartDate: "",
        maxEndDate: "",
      });
    }
    this.setState({
      addTermModal: !this.state.addTermModal,
      newTerms: newTerms,
    });
  };

  // handleTermDataChange = (e, idx) => {
  //   e.preventDefault();
  //   let newTerms = [...this.state.newTerms];

  //   if (e.target.name === "startDate" && idx !== newTerms.length - 1) {
  //     newTerms.forEach((nt) => {
  //       newTerms[idx].endDate = "";
  //       newTerms[idx + 1].startDate = "";
  //       newTerms[idx + 1].endDate = "";
  //     });

  //     newTerms[idx].startDate = e.target.value;
  //     this.setState({ newTerms });
  //   }
  //   // else if (e.target.name === "startDate") {
  //   //   newTerms[idx][e.target.name] = e.target.value;

  //   //   newTerms[idx].endDate = "";
  //   //   this.setState({ newTerms });
  //   // }
  //   else {
  //     newTerms[idx][e.target.name] = e.target.value;
  //     this.setState({ newTerms });
  //   }
  // };

  handleTermDataChange = (e, idx) => {
    e.preventDefault();
    let newTerms = [...this.state.newTerms];

    if (
      e.target.name === "startDate" &&
      idx !== newTerms.length - 1 &&
      newTerms.length > 1
    ) {
      newTerms[idx].startDate = e.target.value;
      newTerms[idx].endDate = "";
      newTerms.forEach((nt, indx) => {
        if (indx > idx) {
          nt.startDate = "";
          nt.endDate = "";
        }
      });

      this.setState({ newTerms });
    } else {
      newTerms[idx][e.target.name] = e.target.value;
      this.setState({ newTerms });
    }
  };

  handleChangeForNewTermCheckBox = (e, idx, dayId) => {
    let newTerms = [...this.state.newTerms];
    newTerms[idx].selectedWeekDays.forEach((week, i) => {
      if (week.id === dayId) {
        week.checked = e.target.checked;
      }
    });
    this.setState({ newTerms });
  };

  addTermRow = (e) => {
    e.preventDefault();
    let newTerms = [...this.state.newTerms];
    newTerms.push({
      name: "",
      startDate: "",
      endDate: "",
      selectedWeekDays: JSON.parse(JSON.stringify([...this.state.weekDays])),
    });
    this.setState({ newTerms });
  };

  deleteTermRow = (e, idx) => {
    e.preventDefault();
    let newTerms = [...this.state.newTerms];
    newTerms.splice(idx, 1);
    if (newTerms.length < 1) {
      swal({
        title: "Error",
        text: "Atleast one term is necessary",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    } else {
      this.setState({ newTerms });
    }
  };

  addTermModalFooter = () => {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={(e) => this.submitTerms(e)}
            >
              Save
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={(e) => this.handleAddTerms(e, "close")}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  submitTerms = (e) => {
    e.preventDefault();
    let newTerms = [...this.state.newTerms];
    let submitTerms = [];
    if (newTerms.length > 0) {
      newTerms.forEach((el) => {
        let weekdays = [];
        el.selectedWeekDays.forEach((wk) => {
          if (wk.checked === true) {
            weekdays.push({ id: wk.id });
          }
        });
        submitTerms.push({
          name: el.name,
          startDate: el.startDate,
          endDate: el.endDate,
          tempDatabaseName: this.state.selectedAcademicYear,
          educationLevelId: this.state.selectedEducationLevel.id,
          weekDays: weekdays,
        });
      });
    }

    if (
      submitTerms.every(
        (item) =>
          item.name !== "" &&
          item.startDate !== "" &&
          item.endDate !== "" &&
          item.weekDays.length > 0
      )
    ) {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertEducationLevelDivision,
          submitTerms,
          (response) => {
            if (response.status === 200) {
              swal({
                title: "Success",
                text: `Education Level Divisions sucessfully inserted`,
                closeOnClickOutside: false,
                allowOutsideClick: false,
              });
              this.setState({ showSpinner: false });
              draggable();
              this.getAcademicYears();
              this.handleAddTerms(e, "close");
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            let errorResponse = error.response ? error.response.data : error;
            if (errorResponse.status === 400) {
              //if condition to check spring boot validation errors
              let errorMessage = "";
              if (errorResponse.errors) {
                errorResponse.errors.forEach((error) => {
                  errorMessage += `${error.field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} ${error.defaultMessage} \n`; //ishan
                });
                swal(errorResponse.error, errorMessage, "");
              } else {
                swal(errorResponse.error, errorResponse.message, "");
              }
            } else {
              swal(
                errorResponse.error || "Network Error",
                errorResponse.message
                  ? errorResponse.message
                  : "Could Not Connect To The Server.",
                ""
              );
            }
          }
        );
      });
    } else {
      swal("Error", "Fill all the required fields");
    }
  };

  render() {
    return (
      <>
        <div className="tt-widgetContent-tab-holder" id="academicYears">
          <div className="tt-group-header">
            List
            {checkWidgetAdminOrNot("Academic Administration") === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div className="tt-newListBox">
            {this.state.academicYearsList.length > 0 ? (
              this.state.academicYearsList.map((academicYear, idx) => {
                return (
                  <div key={idx} className="container-fluid mb-1">
                    <div className="row tt-group-header">
                      <div className="col-md-8">
                        Academic Year : {academicYear.name}
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-1">
                        <span
                          onClick={(e) => {
                            this.toggleCollpasible(e, academicYear.id, idx);
                            this.scrollToTop();
                          }}
                          style={{
                            fontSize: "25px",
                            position: "absolute",
                            top: "-7px",
                            right: "10px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={academicYear.isOpen ? faAngleUp : faAngleDown}
                          />
                        </span>
                      </div>
                    </div>

                    <CustomCollapse
                      isOpen={academicYear.isOpen}
                      collapsibleBody={
                        <div className="container-fluid" id="collAcademicYear">
                          <div className="row tt-background" key={idx}>
                            <div className="col-md-2">
                              <strong>{academicYear.name}</strong>
                              {academicYear.isCurrent ? (
                                <div className="tt-bubble-background">
                                  <div>Current</div>
                                </div>
                              ) : null}
                            </div>
                            <div className="col">
                              {academicYear?.educationLevels && (
                                <Semester
                                  academicYear={academicYear}
                                  termAlias={this.state.termAlias}
                                  handleAddTerms={this.handleAddTerms}
                                  educationLevels={academicYear.educationLevels}
                                  mainIndex={idx}
                                  handleUpdate={this.handleUpdate}
                                  handleChange={this.handleChange}
                                  updatedName={this.state.updatedName}
                                  updatedStartDate={this.state.updatedStartDate}
                                  updatedEndDate={this.state.updatedEndDate}
                                  weekDays={this.state.weekDays}
                                  handleChangeForCheckBox={
                                    this.handleChangeForCheckBox
                                  }
                                  handleCancelEdit={this.handleCancelEdit}
                                  handleDelete={this.handleDelete}
                                  academicYearId={academicYear.id}
                                  academicYearIndex={idx}
                                  handleUpdateEducationLevelDivision={
                                    this.handleUpdateEducationLevelDivision
                                  }
                                />
                              )}
                            </div>
                            <div
                              className="col-md-auto"
                              style={{ marginRight: "10px" }}
                            >
                              {academicYear.isCurrent ? null : (
                                <div className="row mt-2">
                                  <button
                                    className="tt-button tt-button-primary tt-button-fullWidth"
                                    onClick={(e) =>
                                      this.handleCurrentChange(
                                        e,
                                        academicYear.id,
                                        idx
                                      )
                                    }
                                  >
                                    Set current
                                  </button>
                                </div>
                              )}
                              <div className="row mt-2">
                                {checkRolePermissions(
                                  "disable-academic-year",
                                  "activity"
                                ) ? (
                                  <button
                                    className="tt-button tt-button-primary tt-button-fullWidth"
                                    onClick={(e) =>
                                      this.handleEnabledClick(
                                        e,
                                        academicYear.id,
                                        idx,
                                        academicYear.isEnabled ? false : true
                                      )
                                    }
                                    disabled={
                                      academicYear.isCurrent &&
                                      academicYear.isEnabled
                                        ? true
                                        : false
                                    }
                                  >
                                    {academicYear.isEnabled
                                      ? "Disable"
                                      : "Enable"}
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                );
              })
            ) : (
              <div className="text-center" style={{ marginTop: "200px" }}>
                <Spinner color="primary" />
              </div>
            )}
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="Academic Years"
              header="List Academic Years"
              activityName="select-academic-years"
            />
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.addTermModal}
          size="lg"
          toggleModal={this.handleAddTerms}
          modalHeader="Add Terms"
          modalBody={
            <AddTermsModal
              termAlias={this.state.termAlias}
              weekDays={this.state.weekDays}
              newTerms={this.state.newTerms}
              addTermRow={this.addTermRow}
              deleteTermRow={this.deleteTermRow}
              handleTermDataChange={this.handleTermDataChange}
              minStartDate={this.state.minStartDate}
              maxEndDate={this.state.maxEndDate}
              handleChangeForNewTermCheckBox={
                this.handleChangeForNewTermCheckBox
              }
              showSpinner={this.state.showSpinner}
            ></AddTermsModal>
          }
          modalFooter={this.addTermModalFooter()}
        ></ModalWindow>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  academicYears: state.academicAdministration.academicYears,
});

const mapActionsToProps = {
  getAcademicYears: getAcademicYears,
};

export default connect(mapStateToProps, mapActionsToProps)(AcademicYearsList);
