import React, { Component } from "react";
import { Spinner } from "reactstrap";
import ModalWindow from "../../UI/ModalWindow";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import CustomPageHeader from "../common/CustomPageHeader";
import CentralGroupForm from "./CentralGroupForm";
import userImage from "../../assets/images/user-image.png";
import { handleError } from "../../utils/Utils";
import swal from "sweetalert";

class CentralGroup extends Component {
  state = {
    groups: [],
    fetchingGroup: false,
    selectedGroup: null,
    membersModal: false,
    fetchingGroupMembers: false,
    groupMembers: [],
    totalMembers: 0,
    createGroupModal: false,
    updateGroupModal: false,
    editData: "",
    isEdit: false,
    isView: false
  };

  toggleUpdateModal = g => {
    this.setState({
      updateGroupModal: !this.state.updateGroupModal,
      editData: g,
      isEdit: true
    });
  };

  componentDidMount() {
    this.getAllGroups();
  }

  getAllGroups = () => {
    this.setState({ fetchingGroup: true }, () => {
      axiosPost(
        URL.getAllGroupsCentral,
        {},
        response => {
          if (response.status === 200) {
            let groups = response.data.data;
            this.setState({
              groups,
              fetchingGroup: false
            });
          } else {
            this.setState({
              fetchingGroup: false
            });
          }
        },
        err => {
          this.setState({
            fetchingGroup: false
          });
        }
      );
    });
  };

  handleDelete = data => {
    let params = {
      id: data.id
    };
    axiosPost(URL.deleteGroup, params, response => {
      if (response.status === 200) {
        swal("success", "Record delete successfully.");
      }
    });
  };

  toggleMembersModal = g => {
    this.setState(
      {
        selectedGroup: this.state.selectedGroup ? null : g,
        membersModal: !this.state.membersModal,
        isView: true
      },
      () => {
        if (this.state.isView === true) {
          this.setState({ isEdit: false });
        }
        this.state.selectedGroup &&
          this.getGroupMembers(this.state.selectedGroup.id);
      }
    );
  };

  getGroupMembers = groupId => {
    this.setState({ fetchingGroupMembers: true, groupMembers: [] }, () => {
      let data = {
        id: this.state.isEdit ? groupId.id : groupId
      };

      axiosPost(
        URL.getGroupSelectByKey,
        data,
        response => {
          if (response.status === 200) {
            let totalMembers = response.data.totalRecordsCount;
            let groupMembers = response.data.data?.groupPeople;

            this.setState(
              {
                groupMembers,
                totalMembers,
                fetchingGroupMembers: false
              },
              () => {
                if (this.state.isEdit) {
                  this.toggleUpdateModal(groupId);
                }
              }
            );
          } else {
            this.setState({
              fetchingGroupMembers: false
            });
          }
        },
        error => {
          this.setState({
            fetchingGroupMembers: false
          });
        }
      );
    });
  };

  toggleCreateModal = () => {
    this.setState({
      createGroupModal: !this.state.createGroupModal
    });
  };
  toggleAddCreateModal = () => {
    this.setState({
      createGroupModal: true
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="whiteContainer">
              <CustomPageHeader
                onClick={this.toggleAddCreateModal}
                icon="add_box"
                title="Central Group"
              />

              <div className="mt-4">
                {this.state.groups?.length > 0 ? (
                  <table className="table table-bordered">
                    <thead>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Members</th>
                      <th>Actions</th>
                    </thead>
                    <tbody>
                      {this.state.groups?.map(g => (
                        <tr>
                          <td>{g.name}</td>
                          <td>{g.description}</td>
                          <td>
                            <button
                              className="btn btn-primary cusBtn cusBtnFontSize"
                              onClick={() => this.toggleMembersModal(g)}
                            >
                              View Members
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary cusBtn cusBtnFontSize"
                              onClick={() => this.handleDelete(g)}
                            >
                              Delete
                            </button>
                            <button
                              className="btn btn-primary cusBtn cusBtnFontSize"
                              onClick={() => {
                                this.setState(
                                  {
                                    isEdit: true
                                  },
                                  () => {
                                    this.getGroupMembers(g);
                                  }
                                );
                              }}
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <>
                    {this.state.fetchingGroup ? (
                      <div className="text-center">
                        <Spinner color="primary" />
                      </div>
                    ) : (
                      "No groups created."
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <ModalWindow
          backdrop="static"
          keyboard={false}
          modal={this.state.updateGroupModal}
          toggleModal={this.toggleUpdateModal}
          modalHeader=" Update Group"
          size="xl"
          modalBody={
            <div>
              <CentralGroupForm
                getAllGroups={this.getAllGroups}
                toggleCreateModal={this.toggleUpdateModal}
                editData={this.state.editData}
                isEdit={this.state.isEdit}
                groupMembers={this.state.groupMembers}
              />
            </div>
          }
        />

        <ModalWindow
          backdrop="static"
          keyboard={false}
          modal={this.state.createGroupModal}
          toggleModal={this.toggleCreateModal}
          modalHeader="Create Group"
          size="xl"
          modalBody={
            <div>
              <CentralGroupForm
                getAllGroups={this.getAllGroups}
                toggleCreateModal={this.toggleUpdateModal}
              />
            </div>
          }
        />

        <ModalWindow
          modal={this.state.membersModal}
          modalClass="fullModal"
          toggleModal={this.toggleMembersModal}
          size="lg"
          modalHeader={this.state.selectedGroup?.name + " members"}
          modalBody={
            <div className="groupImage">
              <div
                id="tt-groupContainer"
                className="tt-group-container mt-0"
                style={{ boxShadow: "unset" }}
              >
                <div className="tt-group-box" style={{ height: "100%" }}>
                  {this.state.groupMembers ||
                  this.state.groupMembers?.length > 0 ? (
                    this.state.groupMembers.map((member, idx) => {
                      return (
                        <div key={idx}>
                          <div
                            className="tt-group-holder"
                            key={idx}
                            id={idx}
                            data-tip={
                              member.name ||
                              this.state.groupMembers.name +
                                " [" +
                                (member.userRole ||
                                this.state.groupMembers.userRole === "RL_STC"
                                  ? "Student Contact"
                                  : member.userRole ||
                                    this.state.groupMembers.userRole ===
                                      "RL_STD"
                                  ? "Student"
                                  : member.userRole ||
                                    this.state.groupMembers.userRole ===
                                      "RL_ADM"
                                  ? "Admin"
                                  : member.userRole ||
                                    this.state.groupMembers.userRole ===
                                      "RL_OTH"
                                  ? "Others"
                                  : "Staffs") +
                                "]"
                            }
                          >
                            <div className="tt-memeber-img">
                              <img
                                src={userImage}
                                onError={e => handleError(e)}
                              ></img>
                            </div>
                            <div className="tt-member-name">
                              {member.name || this.state.groupMembers.name}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center mt-3 mb-3">
                      {this.state.fetchingGroupMembers ? (
                        <>
                          <Spinner color="primary"></Spinner>
                          <br></br>
                          Loading Data ...
                        </>
                      ) : this.state.groupId !== "" ? (
                        <p>No members found</p>
                      ) : (
                        <p>Select a group first</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default CentralGroup;
