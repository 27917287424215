import React, { useState, useEffect } from "react";
import Select from "react-select";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import ShowCardDetails from "./ShowCardDetails";
import * as htmlToImage from "html-to-image";
import { compareByName } from "../../utils/Utils";

const ListCards = () => {
  const [userTypeOptions, setUserTypeOptions] = useState([
    {
      label: "Student",
      value: 1,
    },
    {
      label: "Staff",
      value: 2,
    },
  ]);
  const [userType, setUserType] = useState("");
  const [academicLevelOption, setAcademicLevelOption] = useState([]);
  const [academicLevelValue, setAcademicLevelValue] = useState("");
  const [classOption, setClassOptions] = useState([]);
  const [classOptionValue, setClassOptionValue] = useState("");
  const [sectionOption, setSectionOption] = useState([]);
  const [sectionOptionValue, setSectionOptionValue] = useState("");
  const [cardDetails, setCardDetails] = useState([]);
  const [schoolDetails, setSchoolDetails] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [allDetailsData, setAllDetailsData] = useState([]);

  const handleUserType = (e) => {
    setUserType(e);
    if (e.value == 1) {
      setClassOptionValue("");
      setAcademicLevelValue("");
    }
  };

  const handleAcademicLevelChange = (e) => {
    setAcademicLevelValue(e);
    setClassOptionValue("");

    let matchAcaLevel = allDetailsData.find((ad) => ad.id === e.value);

    let sortedData = matchAcaLevel.yearLevels.sort(compareByName);
    let classOp = sortedData.map((item) => ({
      label: "Class " + item.name,
      value: item.id,
    }));
    setClassOptions(classOp);
    setCardDetails([]);
  };

  const handleClassChange = (e) => {
    setClassOptionValue(e);
    getAllIdCardData();
  };

  const handleSectionChange = (e) => {
    setSectionOptionValue(e);
  };

  const getEducationLevel = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      const options = response.data.data;
      setAllDetailsData(options);
      const acadamicOptions = options.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setAcademicLevelOption(acadamicOptions);
    });
  };

  const getAllIdCardData = () => {
    const Params = {
      cardType:
        userType?.value === 1 ? "student" : userType?.value ? "staff" : "",
    };
    axiosPost(URL.getAllIdCard, Params, (response) => {
      if (response.status === 200) {
        setCardDetails(response.data.data);
      }
    });
  };

  // const getAllSections = (param) => {
  //   const Params = {
  //     yearLevelId: param.value,
  //   };
  //   axiosPost(URL.selectClassroom, Params, (response) => {
  //     const optionVal = options.map((item) => ({
  //       label: item.name,
  //       value: item.id,
  //     }));
  //     setSectionOption(optionVal);
  //   });
  // };

  const getSchoolDetails = () => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      setSchoolDetails(response.data.data);
    });
  };

  const getAllStaffList = () => {
    const Params = {
      status: "current",
    };
    axiosPost(URL.getStaffList, Params, (response) => {
      if (response.status === 200) {
        setStaffList(response.data.data);
        setStudentList("");
      }
    });
  };

  const getAllStudent = (data) => {
    const Params = {
      status: "current",
      yearLevelId: data?.value,
    };
    axiosPost(URL.getStudentList, Params, (response) => {
      if (response.status) {
        setStudentList(response?.data?.data);
        setStaffList("");
      }
    });
  };

  useEffect(() => {
    getEducationLevel();
    getSchoolDetails();
  }, []);

  useEffect(() => {
    if (userType?.value) {
      getAllIdCardData();
    }
    if (userType?.value === 2) {
      getAllStaffList();
    }
  }, [userType?.value]);

  const handleDownloadCard = async () => {
    await htmlToImage
      .toJpeg(document.getElementById("downloadCard"), {
        quality: 1.0,
        backgroundColor: "white",
      })
      .then((dataUrl) => {
        var link = document.createElement("a");
        link.download = "download.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  useEffect(() => {
    if (classOptionValue) {
      // getAllSections(classOptionValue);
      getAllStudent(classOptionValue);
    }
  }, [classOptionValue]);

  return (
    <>
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="row mt-2 ">
          <div className="col-md-3">
            <label>
              <strong>Identity card for</strong>
            </label>
            <Select
              options={userTypeOptions}
              onChange={(e) => handleUserType(e)}
              value={userType}
            />
          </div>
          {userType?.value === 1 ? (
            <>
              <div className="col-md-3">
                <label>
                  <strong>Education Level</strong>
                </label>
                <Select
                  options={academicLevelOption}
                  onChange={(e) => handleAcademicLevelChange(e)}
                  value={academicLevelValue}
                />
              </div>

              {academicLevelValue !== "" && (
                <div className="col-md-3">
                  <label>
                    <strong>Class</strong>
                  </label>
                  <Select
                    options={classOption}
                    onChange={(e) => handleClassChange(e)}
                    value={classOptionValue}
                  />
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>

        {cardDetails?.length > 0 &&
        (studentList?.length > 0 || staffList?.length > 0) ? (
          <>
            <div className="container payment-print">
              <div className="row">
                <div className="col text-right">
                  <button
                    className="tt-button tt-button-primary ml-3"
                    onClick={() => handleDownloadCard()}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
            <div id="downloadCard">
              <ShowCardDetails
                cardDetails={cardDetails}
                userType={userType}
                sectionOptionValue={sectionOptionValue}
                schoolDetails={schoolDetails}
                staffList={staffList}
                studentList={studentList}
                classValue={classOptionValue}
              />
            </div>
          </>
        ) : (
          <div className="text-center py-5">Configurations not found</div>
        )}
      </div>
    </>
  );
};

export default ListCards;
