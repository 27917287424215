import React, { useState } from "react";
import CustomPageHeader from "../common/CustomPageHeader";
import NavBar from "../FrontPage/NavBar";
import "./Calculator.css";
import { connect } from "react-redux";

const Calculator = ({ location, homePage, ...props }) => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [error, setError] = useState(false);

  function handleButtonClick(event) {
    const value = event.target.value;
    switch (value) {
      case "=":
        try {
          const result = eval(input);
          setOutput(result);
          setError(false);
        } catch (error) {
          setOutput("");
          setError(true);
        }
        break;
      case "C":
        setInput("");
        setOutput("");
        setError(false);
        break;
      default:
        setInput(input + value);
        break;
    }
  }

  return (
    <>
      {location.pathname === "/dashboard/customCalculator" ? null : (
        <NavBar
          noNav={true}
          teacherPage={location?.state?.teacherPage}
          classPage={location?.state?.classPage}
          homePage={location?.state?.homePage || homePage}
        />
      )}
      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="whiteContainer mt-3">
                <CustomPageHeader
                  title={
                    props.lang === "nameEn" ? "Calculator" : "क्याल्कुलेटर"
                  }
                  noIcon={true}
                />

                <div className="calculator-container">
                  <div className="calculator-grid">
                    <input
                      type="text"
                      value={error ? "Error" : output}
                      disabled
                    />
                    <input type="text" value={input} disabled />

                    <button value="(" onClick={handleButtonClick}>
                      (
                    </button>
                    <button value=")" onClick={handleButtonClick}>
                      )
                    </button>
                    <button value="C" onClick={handleButtonClick}>
                      C
                    </button>
                    <button value="/" onClick={handleButtonClick}>
                      ÷
                    </button>

                    <button value="7" onClick={handleButtonClick}>
                      7
                    </button>
                    <button value="8" onClick={handleButtonClick}>
                      8
                    </button>
                    <button value="9" onClick={handleButtonClick}>
                      9
                    </button>
                    <button value="*" onClick={handleButtonClick}>
                      ×
                    </button>

                    <button value="4" onClick={handleButtonClick}>
                      4
                    </button>
                    <button value="5" onClick={handleButtonClick}>
                      5
                    </button>
                    <button value="6" onClick={handleButtonClick}>
                      6
                    </button>
                    <button value="-" onClick={handleButtonClick}>
                      −
                    </button>

                    <button value="1" onClick={handleButtonClick}>
                      1
                    </button>
                    <button value="2" onClick={handleButtonClick}>
                      2
                    </button>
                    <button value="3" onClick={handleButtonClick}>
                      3
                    </button>
                    <button value="+" onClick={handleButtonClick}>
                      +
                    </button>

                    <button value="0" onClick={handleButtonClick}>
                      0
                    </button>
                    <button value="." onClick={handleButtonClick}>
                      .
                    </button>
                    <button value="√" onClick={handleButtonClick}>
                      √
                    </button>
                    <button value="^2" onClick={handleButtonClick}>
                      x<sup>2</sup>
                    </button>

                    <button value="sin" onClick={handleButtonClick}>
                      sin
                    </button>
                    <button value="cos" onClick={handleButtonClick}>
                      cos
                    </button>
                    <button value="tan" onClick={handleButtonClick}>
                      tan
                    </button>
                    <button value="log" onClick={handleButtonClick}>
                      log
                    </button>
                    <button value="ln" onClick={handleButtonClick}>
                      ln
                    </button>

                    <button
                      className="equals"
                      value="="
                      onClick={handleButtonClick}
                    >
                      =
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Calculator);
