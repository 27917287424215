import React from "react";
import { compareByYearLevelName } from "../../utils/Utils";

function AttendanceHeader(props) {
  return (
    <React.Fragment>
      {/* {props.yearLevel && props.classroom ? (
            <div className="tt-group-header">
              <div className="row">
                <div className="col text-center">
                  Attendance for {props.yearLevel.name}{" "}
                  {props.classroom[0].name}
                </div>
              </div>
            </div>
          ) : null} */}
      <div className="tt-group-header">
        {/* <div className="row mb-2">
              <div className="col-md-12 text-center">
                Attendance for:
                <i className="ml-1">{props.calendarDate}</i>
              </div>
            </div> */}
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-2 alignContent"> Attendance For:</div>
          <div className="col-md-auto">
            <select
              name="classRoomName"
              id="classRoomName"
              value={props.classRoomName}
              className="form-control"
              onChange={props.handleClassroomSelect}
            >
              {/* value={classroom.id} */}
              <option value="" disabled>
                Select Classroom
              </option>
              {props.classrooms ? (
                props.classrooms
                  .sort(compareByYearLevelName)
                  .map((classroom, idx) => (
                    <option
                      key={idx}
                      value={`${classroom.id},${classroom.yearLevelId}`}
                    >
                      {/* What if yearlevel name is inserted as number(like:1,2,..) */}
                      Class {classroom.yearLevelName}
                      {" - "}
                      {classroom.name}
                    </option>
                  ))
              ) : (
                <option value="null">No Classroom available</option>
              )}
            </select>
          </div>
          <div className="col-md-1"></div>

          {/* <div className="col-md-1 alignContent"> Subject:</div>
              <div className="col-md-auto">
                <select
                  onChange={props.handleSubjectSelect}
                  name="subject"
                  id="subject"
                  className="form-control ttedu-level-dropdown"
                >
                  <option
                    value="All"
                    // disabled={props.selectedSubject != null ? true : false}
                  >
                    All Subject
                  </option>
                  {props.subjects
                    ? props.subjects.map((subject, idx) => (
                        <option key={idx} value={subject.id}>
                          {subject.name}
                        </option>
                      ))
                    : null}
                </select>
              </div> */}

          {/* <div className="col-md-auto alignContent">Date:</div>
          <div className="col-md-auto">
            <input
              type="date"
              id="schoolDate"
              className={
                props.isHoliday ? "holiday-date form-control" : "form-control"
              }
              name="calendarDate"
              max={props.academicYearEnd}
              min={props.academicYearStart}
              value={props.calendarDate}
              onChange={props.handleCalandarDateChange}
            />
          </div> */}
          <div className="col-md-auto alignContent">Date:</div>
          <div className="col-md-auto">
            <input
              type="date"
              id="schoolDate"
              className={
                props.isHoliday ? "holiday-date form-control" : "form-control"
              }
              name="calendarDate"
              max={new Date().toISOString().substring(0, 10)}
              min={props.academicYearStart}
              value={props.calendarDate}
              onChange={props.handleCalandarDateChange}
            />
          </div>

          <div className="col-md-2 text-center pt-1">
            {props.isHoliday && (
              <span style={{ color: "#ff8f8f" }}>Holiday Selected</span>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AttendanceHeader;
