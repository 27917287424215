import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  checkRolePermissions,
  draggable,
  regEx,
  URL,
} from "../../../utils/Constants";
import CreatePhase from "./CreatePhase";
import ListPhase from "./ListPhase";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
class PhaseSetting extends Component {
  state = {
    name: "",
    description: "",
    phaseList: [],
    phaseSpinner: false,
    phaseOffset: 0,
    phaseRowCountLimit: 5,
    phaseCurrentPage: 1,
    phaseTotalRecords: 0,
    termOptions: [],
    updateName: "",
    updateDescription: "",
    id: "",
    editTermOption: [],
  };

  componentDidMount() {
    this.getPhaseList();
    this.getTermList();
  }

  getTermList = () => {
    axiosPost(
      URL.getCourseTerm,
      { courseId: this.props.selectedList.id },
      (response) => {
        if (response.status === 200) {
          let terms = response.data.data;
          let termOptions = [];
          if (terms.length > 0) {
            terms.forEach((el) => {
              termOptions.push({ label: el.name, value: el.id });
            });
          }
          this.setState({ termOptions });
        }
      }
    );
  };

  handleTermChange = (selectedItem, idx) => {
    let phaseList = [...this.state.phaseList];
    phaseList[idx].selectedTerms = selectedItem;
    this.setState({
      phaseList,
    });
  };

  getPhaseList = () => {
    let data = {
      courseId: this.props.selectedList.id,
      offset: this.state.phaseOffset,
      rowCountLimit: this.state.phaseRowCountLimit,
    };
    this.setState({ phaseSpinner: true }, function () {
      axiosPost(
        URL.getCoursePhase,
        data,
        (response) => {
          this.setState({ phaseSpinner: false });
          if (response.status === 200) {
            let phaseList = response.data.data;
            if (phaseList.length > 0) {
              phaseList.forEach((el) => {
                el.selectedTerms = [];
                el.isEditable = false;
                el.editTerm = false;
              });
            }
            this.setState({
              phaseList: phaseList,
              phaseTotalRecords: response.data.totalRecordsCount,
            });
          }
        },
        (er) => {
          this.setState({ phaseSpinner: false });
        }
      );
    });
  };

  //Pagination
  handlePreviousPage = () => {
    if (this.state.phaseCurrentPage !== 1) {
      this.reRenderPhaseList(this.state.phaseCurrentPage - 1);
      this.setState((prevState) => ({
        phaseCurrentPage: prevState.phaseCurrentPage - 1,
      }));
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.phaseCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.phaseTotalRecords / this.state.phaseRowCountLimit)
    )
      return;
    else {
      this.reRenderPhaseList(nextPage);
      this.setState({ phaseCurrentPage: nextPage });
    }
  };

  handlePageChange = (page) => {
    this.reRenderPhaseList(page);
    this.setState({ phaseCurrentPage: page });
  };

  reRenderPhaseList = (page) => {
    let offset =
      page * parseInt(this.state.phaseRowCountLimit) -
      parseInt(this.state.phaseRowCountLimit);
    this.setState({ phaseOffset: offset, phaseSpinner: true }, function () {
      let data = {
        courseId: this.props.selectedList.id,
        offset: offset,
        rowCountLimit: this.state.phaseRowCountLimit,
      };
      axiosPost(
        URL.getCoursePhase,
        data,
        (response) => {
          this.setState({ phaseSpinner: false });
          if (response.status === 200) {
            let phaseList = response.data.data;
            if (phaseList.length > 0) {
              phaseList.forEach((el) => {
                el.selectedTerms = [];
                el.isEditable = false;
                el.editTerm = false;
              });
            }
            this.setState({
              phaseList: phaseList,
              phaseTotalRecords: response.data.totalRecordsCount,
            });
          }
        },
        (er) => {
          this.setState({ phaseSpinner: false });
        }
      );
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "name" || name === "updateName") {
      if (!value.match(regEx)) {
        swal({
          title: "Warning",
          text: "Character not allowed",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  clearState = () => {
    this.setState({
      name: "",
      description: "",
      id: "",
      updateName: "",
      updateDescription: "",
    });
  };

  createPhase = () => {
    if (this.state.name === "") {
      swal({
        title: "Error",
        text: "Fill all the required fields.",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
      return false;
    }
    let data = {
      name: this.state.name,
      description: this.state.description,
      courseId: this.props.selectedList.id,
    };
    axiosPost(URL.insertCoursePhase, data, (response) => {
      if (response.status === 200) {
        swal({ title: "Success", text: "Phase successfully created" });
        this.clearState();
        this.setState(
          {
            phaseOffset: 0,
            phaseCurrentPage: 1,
          },
          function () {
            this.getPhaseList();
          }
        );
      }
    });
  };

  //Assign Terms
  assignTerms = (index) => {
    let phaseList = this.state.phaseList;
    let selectedPhaseList = phaseList[index];
    let data = [];
    if (selectedPhaseList.selectedTerms.length > 0) {
      selectedPhaseList.selectedTerms.forEach((el) => {
        data.push({
          coursePhaseId: selectedPhaseList.id,
          courseTermId: el.value,
        });
      });
    }
    axiosPost(URL.assignCoursePhaseTerm, data, (response) => {
      if (response.status === 200) {
        swal({ title: "Success", text: "Successfully assigned terms" });
        this.getPhaseList();
      }
    });
  };

  //Update Phase
  togglePhaseEditModal = (idx) => {
    let phaseList = [...this.state.phaseList];
    phaseList.forEach((el) => {
      el.isEditable = false;
      el.editTerm = false;
    });
    phaseList[idx].isEditable = true;
    this.setState({
      phaseList,
      updateName: phaseList[idx].name,
      updateDescription: phaseList[idx].description,
      id: phaseList[idx].id,
    });
  };

  cancelUpdate = (idx) => {
    let phaseList = [...this.state.phaseList];
    phaseList[idx].isEditable = false;
    this.setState({
      phaseList,
      id: "",
      updateName: "",
      updateDescription: "",
    });
  };

  updatePhase = () => {
    let data = {
      id: this.state.id,
      name: this.state.updateName,
      description: this.state.updateDescription,
    };
    axiosPost(URL.updateCoursePhase, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Course Successfully updated",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getPhaseList();
        this.clearState();
      }
    });
  };

  //Re assign term or edit term
  handleEditTerm = (idx) => {
    let phaseList = [...this.state.phaseList];
    phaseList.forEach((el) => {
      el.editTerm = false;
      el.isEditable = false;
    });
    phaseList[idx].editTerm = true;
    let editTermOption = [];
    if (phaseList[idx].courseTerms.length > 0) {
      phaseList[idx].courseTerms.forEach((el) => {
        editTermOption.push({
          label: el.name,
          value: el.id,
        });
      });
    }
    this.setState({
      phaseList,
      editTermOption,
      id: phaseList[idx].id,
    });
  };

  handleEditTermValueChange = (item) => {
    this.setState({ editTermOption: item });
  };

  cancelEditTerm = (idx) => {
    let phaseList = [...this.state.phaseList];
    phaseList.forEach((el) => {
      el.editTerm = false;
    });
    this.setState({
      phaseList,
    });
  };

  updateCoursePhaseTerm = () => {
    let data = [];
    if (this.state.editTermOption && this.state.editTermOption.length > 0) {
      this.state.editTermOption.forEach((el) => {
        data.push({
          coursePhaseId: this.state.id,
          courseTermId: el.value,
        });
      });
    } else {
      data.push({
        coursePhaseId: this.state.id,
        courseTermId: 0,
      });
    }
    axiosPost(URL.updateCoursePhaseTerm, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Successfully updated term assignment",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getPhaseList();
        this.clearState();
      }
    });
  };

  //Delete Course Phase
  handleDelete = (id) => {
    displayConfirmDeleteAlert({ id }, this.deletePhase);
  };

  deletePhase = (datum) => {
    let data = { id: datum.id };
    axiosPost(URL.deleteCoursePhase, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Successfully deleted course phase.",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
        this.getPhaseList();
      }
      this.getPhaseList();
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "87.5vh" }}>
        <div className="tt-group-header">List of Phases</div>
        <ListPhase
          phaseList={this.state.phaseList}
          phaseSpinner={this.state.phaseSpinner}
          phaseTotalRecords={this.state.phaseTotalRecords}
          phaseRowCountLimit={this.state.phaseRowCountLimit}
          phaseCurrentPage={this.state.phaseCurrentPage}
          termOptions={this.state.termOptions}
          selectedTerms={this.state.selectedTerms}
          assignTerms={this.assignTerms}
          handleTermChange={this.handleTermChange}
          handlePageChange={this.handlePageChange}
          handleNextPage={this.handleNextPage}
          handlePreviousPage={this.handlePreviousPage}
          updateName={this.state.updateName}
          updateDescription={this.state.updateDescription}
          handleChange={this.handleChange}
          cancelUpdate={this.cancelUpdate}
          togglePhaseEditModal={this.togglePhaseEditModal}
          updatePhase={this.updatePhase}
          handleEditTerm={this.handleEditTerm}
          editTermOption={this.state.editTermOption}
          handleEditTermValueChange={this.handleEditTermValueChange}
          updateCoursePhaseTerm={this.updateCoursePhaseTerm}
          cancelEditTerm={this.cancelEditTerm}
          handleDelete={this.handleDelete}
        ></ListPhase>
        {checkRolePermissions("can_create_phase", "activity") ? (
          <>
            <div className="tt-group-header">Create New Phase</div>
            <CreatePhase
              name={this.state.name}
              description={this.state.description}
              handleChange={this.handleChange}
              createPhase={this.createPhase}
              clearState={this.clearState}
            ></CreatePhase>
          </>
        ) : null}
      </div>
    );
  }
}

export default PhaseSetting;
