import moment from "moment";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomButton from "../../components/common/CustomButton";
import CustomEditButton from "../../components/common/CustomEditButton";
import CustomModal from "../../components/common/CustomModal";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomSpinner from "../../components/common/CustomSpinner";
import ImportFile from "../../UI/ImportFile";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import UserCreationModal from "./UserCreationModal";
import Pagination from "../../components/UI/Pagination";

class UserList extends Component {
  state = {
    pageSize: 7,
    currentPage: 1,
    totalRecords: 0,
    getSpinner: false,
    userModal: false,
    users: [],
    editModal: false,
    editData: null,
    importFile: null,
    searchName: "",
    userStatus: "",
    searchQuery: false,
  };

  componentDidMount() {
    this.getAllUsers();
  }

  getAllUsers = (page = 1) => {
    let offset =
      page * parseInt(this.state.pageSize) - parseInt(this.state.pageSize);

    let params = {
      offset: offset,
      rowCountLimit: this.state.pageSize,
    };

    this.setState({ getSpinner: true, users: [] }, function () {
      axiosPost(
        URL.getAllUsersCore,
        params,
        (response) => {
          if (response.status === 200) {
            let list = response.data.data;
            list.forEach((l) => {
              l.label = l.name;
              l.value = l.id;
            });
            this.setState({
              users: list,
              getSpinner: false,
              currentPage: page,
              totalRecords: response.data.totalRecordsCount,
            });
          }
        },
        (err) => {
          this.setState({ getSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  toggleUserModal = () => {
    this.setState({ userModal: !this.state.userModal });
  };

  toggleUserEditModal = () => {
    this.setState({ editModal: !this.state.editModal });
  };

  userEditClick = (item) => {
    this.setState({
      editModal: true,
      editData: item,
    });
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let data = {
      id: item.id,
      enabled: checked,
    };

    axiosPost(URL.enableDisableUser, data, (response) => {
      if (response.status === 200) {
        this.getAllUsers();
      }
    });
  };

  handleImportClick = () => {
    this.setState({
      importModal: true,
    });
  };

  toggleImportModal = () => {
    this.setState({
      importModal: !this.state.importModal,
    });
  };

  handleGenerateFormat = () => {
    this.setState(
      {
        spinner: true,
      },
      () => {
        axiosGet(
          URL.generateUserImportFormat,
          (response) => {
            if (response.status === 200) {
              swal("Success", "User import format generated successfully.");
              this.setState({
                spinner: false,
              });
              window.open(
                URL.fileDownloadURL + response.data.data.path,
                "_blank"
              );
              // this.getAllUsers();
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              spinner: false,
            });
          }
        );
      }
    );
  };

  handleImport = () => {
    let formData = new FormData();
    if (this.state.importFile !== null) {
      formData.append("file", this.state.importFile[0]);

      this.setState(
        {
          spinner: true,
        },
        () => {
          axiosPost(
            URL.importUserData,
            formData,
            (response) => {
              if (response.status === 200) {
                swal("Success", response.data.message);
                this.toggleImportModal();
                this.setState({
                  spinner: false,
                });
                this.getAllUsers();
              }
            },
            (err) => {
              displayErrorAlert(err);
              this.setState({
                spinner: false,
              });
            }
          );
        }
      );
    } else {
      swal("Error", "Cannot import empty file");
    }
  };

  handleChange = (e) => {
    this.setState({
      importFile: e.target.files[0],
    });
  };

  handleImageChange = (e, idx) => {
    let { name, value, files } = e.target;
    this.setState({
      importFile: name === "importFile" ? files : value,
    });
  };
  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    if (files.length > 1) {
      swal("Error", "Please select only one file");
    } else {
      this.setState({
        [name]: files,
      });
    }
  };

  handlePageChange = (page) => {
    if (this.state.searchQuery) {
      this.handleSearchData(page);
    } else {
      this.getAllUsers(page);
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (nextPage > Math.ceil(this.state.totalRecords / this.state.pageSize))
      return;
    else {
      if (this.state.searchQuery) {
        this.handleSearchData(nextPage);
      } else {
        this.getAllUsers(nextPage);
      }
    }
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1) {
      let prevPage = this.state.currentPage - 1;

      if (this.state.searchQuery) {
        this.handleSearchData(prevPage);
      } else {
        this.getAllUsers(prevPage);
      }
    }
  };

  handleChangeSearch = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleSearchData = () => {};

  render() {
    const startingSerialNumber =
      (this.state.currentPage - 1) * this.state.pageSize + 1;
    return (
      <>
        {this.state.spinner ? (
          <CustomSpinner text="Please Wait. Creating User..."></CustomSpinner>
        ) : (
          ""
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="whiteContainer">
                <CustomPageHeader
                  title="Users"
                  onClick={this.toggleUserModal}
                  icon="add_box"
                  action="Insert"
                  moduleName={moduleName.userManagement}
                />
                <div className="pr-3 text-right">
                  <CustomButton
                    text="Import user data"
                    customStyle="cusBtnFontSize"
                    onClick={this.handleImportClick}
                  ></CustomButton>
                </div>

                {/* <div className="px-3 py-2 mb-2 search-box-school">
                  <div className="row">
                    <div className="col-md-4">
                      <p className="formTitle">User Name</p>
                      <input
                        type="text"
                        name="searchName"
                        value={this.state.searchName}
                        className="form-control cusInput"
                        onChange={this.handleChangeSearch}
                        placeholder="Enter user name"
                      />
                    </div>

                    <div className="col-md-3">
                      <p className="formTitle">Status</p>
                      <div className="d-flex align-items-center h-50">
                        <input
                          type="radio"
                          name="userStatus"
                          checked={this.state.userStatus === "enabled"}
                          value="enabled"
                          onChange={this.handleChangeSearch}
                        />
                        <label htmlFor="enabled" className="mr-2">
                          Enabled
                        </label>

                        <input
                          type="radio"
                          name="userStatus"
                          checked={this.state.userStatus === "disabled"}
                          value="disabled"
                          onChange={this.handleChangeSearch}
                        />
                        <label htmlFor="disabled" className="mr-2">
                          Disabled
                        </label>

                        <input
                          type="radio"
                          name="userStatus"
                          checked={this.state.userStatus === ""}
                          value=""
                          onChange={this.handleChangeSearch}
                        />
                        <label htmlFor="both">Any</label>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <CustomButton
                      text="Search"
                      customStyle="cusBtnFontSize"
                      onClick={() => this.handleSearchData()}
                    ></CustomButton>
                    <CustomButton
                      text="Reset"
                      customStyle="cusBtnFontSize"
                      onClick={() => this.handleResetClick(1)}
                    ></CustomButton>
                  </div>
                </div> */}
                <table className="table cusTable">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th className="text-center">Image</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Gender</th>
                      <th>Date of Birth</th>
                      <th>Nationality</th>
                      <th>Status</th>
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.length > 0 ? (
                      this.state.users.map((user, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + startingSerialNumber}.</td>
                            <td className="text-center">
                              {user.imagepath ? (
                                <img
                                  className="userImage"
                                  src={URL.imageSelectURL + user.imagepath}
                                  alt={user.name}
                                />
                              ) : null}
                            </td>
                            <td>{user.name}</td>
                            <td>{user.emailAddress}</td>
                            <td>{user.gender}</td>
                            <td>{moment(user.dateOfBirth).format("LL")}</td>
                            <td>{user.nationalityName}</td>
                            <td>
                              <label className="switch mt-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    this.handleChangeToggle(e, user)
                                  }
                                  checked={user?.enabled}
                                  name="enabled"
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <>
                                <CustomEditButton
                                  onClick={() => this.userEditClick(user)}
                                />
                              </>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          {this.state.getSpinner ? (
                            <Spinner></Spinner>
                          ) : (
                            "No users added"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Pagination
                    itemsCount={this.state.totalRecords}
                    pageSize={this.state.pageSize}
                    currentPage={this.state.currentPage}
                    onPageChange={this.handlePageChange}
                    nextPage={this.handleNextPage}
                    previousPage={this.handlePreviousPage}
                  ></Pagination>
                </div>
              </div>
            </div>
          </div>
          <CustomModal
            modal={this.state.userModal}
            modalHeader="Add New User"
            size="lg"
            modalBody={
              <UserCreationModal
                getAllUsers={this.getAllUsers}
                toggleUserModal={this.toggleUserModal}
              />
            }
            toggleModal={this.toggleUserModal}
          />

          <CustomModal
            modal={this.state.editModal}
            modalHeader="Update user data"
            size="lg"
            modalBody={
              <UserCreationModal
                editModal={this.state.editModal}
                editData={this.state.editData}
                getAllUsers={this.getAllUsers}
                toggleUserModal={this.toggleUserEditModal}
              />
            }
            toggleModal={this.toggleUserEditModal}
          />
          <CustomModal
            modal={this.state.importModal}
            size="lg"
            modalHeader="Import User Data"
            modalBody={
              <ImportFile
                handleChange={this.handleImageChange}
                handleGenerateFormat={this.handleGenerateFormat}
                handleImport={this.handleImport}
                handleFileDrop={this.handleFileDrop}
                importFile={this.state.importFile}
              />
            }
            toggleModal={this.toggleImportModal}
          />
        </div>
      </>
    );
  }
}

export default UserList;
