import React from "react";

function RoutineAliasModal(props) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <table className="table table-bordered table-striped text-center mb-0">
            <thead className="tt-group-header">
              <tr>
                <th></th>
                <th>Subject</th>
                <th>Alias Name</th>
              </tr>
            </thead>
            <tbody>
              {props.edit ? (
                props.selectedAliasNameData.selectedSubjectValue &&
                props.selectedAliasNameData.selectedSubjectValue.length > 0 ? (
                  props.selectedAliasNameData.selectedSubjectValue.map(
                    (sub, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <input
                              type="checkbox"
                              checked={sub.hasAliasName}
                              name="hasAliasName"
                              onChange={(e) =>
                                props.handleUpdatedAliasName(e, idx)
                              }
                            />
                          </td>
                          <td>{sub.label}</td>
                          <td>
                            <input
                              type="text"
                              value={sub.aliasName}
                              className="form-control"
                              name="aliasName"
                              onChange={(e) =>
                                props.handleUpdatedAliasName(e, idx)
                              }
                            />
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td>No Subjects Selected</td>
                  </tr>
                )
              ) : props.selectedAliasNameData.selectedOptionSubject &&
                props.selectedAliasNameData.selectedOptionSubject.length > 0 ? (
                props.selectedAliasNameData.selectedOptionSubject.map(
                  (sub, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <input
                            type="checkbox"
                            checked={sub.hasAliasName}
                            name="hasAliasName"
                            onChange={(e) =>
                              props.handleAliasDataChange(e, idx)
                            }
                          />
                        </td>
                        <td>{sub.label}</td>
                        <td>
                          <input
                            type="text"
                            value={sub.aliasName}
                            className="form-control"
                            name="aliasName"
                            onChange={(e) =>
                              props.handleAliasDataChange(e, idx)
                            }
                          />
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td colSpan={3}>No Subjects Selected</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col text-right">
          <button
            className="tt-button tt-button-primary float-right"
            onClick={
              props.edit ? props.handleUpdateAliasSave : props.handleAliasSave
            }
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default RoutineAliasModal;
