/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, InformationForm, render the Form Part in the School Information Section
 */

import React from "react";
import { checkRolePermissions, URL as URLS } from "../../../../utils/Constants";
import moment from "moment";
import { displayNepaliDate } from "../../../../utils/Utils";
import loginImage from "../../../../assets/images/logo/logo1.png";
// import loginImage from "../../../../assets/images/loginImage.PNG";
import Switch from "@material-ui/core/Switch";

class InformationForm extends React.Component {
  renderCountryLevels = () => {
    let index = -1;
    if (this.props.countryId !== undefined) {
      if (this.props.countrys.length > 0) {
        index = this.props.countrys.findIndex(
          (i) => i.id === parseInt(this.props.countryId)
        );
      }
    }
    if (
      this.props.countrys[index] !== undefined &&
      this.props.countrys[index] !== -1
    ) {
      if (this.props.countrys[index].firstLevelDisplayName !== null) {
        return (
          <>
            {this.props.countrys[index].firstLevelDisplayName !== null &&
            this.props.countrys[index].secondLevelDisplayName !== "" ? (
              <tr>
                <td>
                  <label>
                    <strong>
                      {this.props.countrys[index].firstLevelDisplayName}
                    </strong>
                  </label>
                </td>
                <td>
                  {this.props.schoolInfos && !this.props.editable ? (
                    this.props.schoolInfos.countryFirstLevelName
                  ) : (
                    <select
                      name="firstLevelDisplayName"
                      className="form-control"
                      value={this.props.countryFirstLevelId}
                      onChange={(e) => this.props.handleChange(e)}
                    >
                      <option value="" selected disabled>
                        Choose a{" "}
                        {this.props.countrys[index].firstLevelDisplayName}
                      </option>
                      {this.props.countryFirstLevel
                        ? this.props.countryFirstLevel.map(
                            (firstLevel, idx) => {
                              return (
                                <option key={idx} value={firstLevel.id}>
                                  {firstLevel.name}
                                </option>
                              );
                            }
                          )
                        : null}
                    </select>
                  )}
                </td>
              </tr>
            ) : null}
            {this.props.countrys[index].secondLevelDisplayName !== null &&
            this.props.countrys[index].secondLevelDisplayName !== "" ? (
              <tr>
                <td>
                  <label>
                    <strong>
                      {this.props.countrys[index].secondLevelDisplayName}
                    </strong>
                  </label>
                </td>
                <td>
                  {this.props.schoolInfos && !this.props.editable ? (
                    this.props.schoolInfos.countrySecondLevelName
                  ) : (
                    <select
                      name="secondLevelDisplayName"
                      className="form-control"
                      value={this.props.countrySecondLevelId}
                      onChange={(e) => this.props.handleChange(e)}
                    >
                      <option value="" selected disabled>
                        Choose a{" "}
                        {this.props.countrys[index].secondLevelDisplayName}
                      </option>
                      {this.props.countrySecondLevel
                        ? this.props.countrySecondLevel.map(
                            (secondLevel, idx) => {
                              return (
                                <option key={idx} value={secondLevel.id}>
                                  {secondLevel.name}
                                </option>
                              );
                            }
                          )
                        : null}
                    </select>
                  )}
                </td>
              </tr>
            ) : null}
            {this.props.countrys[index].thirdLevelDisplayName !== null &&
            this.props.countrys[index].thirdLevelDisplayName !== "" ? (
              <tr>
                <td>
                  {" "}
                  <label>
                    <strong>
                      {this.props.countrys[index].thirdLevelDisplayName}
                    </strong>
                  </label>
                </td>
                <td>
                  {this.props.schoolInfos && !this.props.editable ? (
                    this.props.schoolInfos.countryThirdLevelName
                  ) : (
                    <select
                      name="thirdLevelDisplayName"
                      className="form-control"
                      value={this.props.countryThirdLevelId}
                      onChange={(e) => this.props.handleChange(e)}
                    >
                      <option selected disabled>
                        Choose a{" "}
                        {this.props.countrys[index].thirdLevelDisplayName}
                      </option>
                      {this.props.countryThirdLevel
                        ? this.props.countryThirdLevel.map(
                            (thirdLevel, idx) => {
                              return (
                                <option key={idx} value={thirdLevel.id}>
                                  {thirdLevel.name}
                                </option>
                              );
                            }
                          )
                        : null}
                    </select>
                  )}
                </td>
              </tr>
            ) : null}
          </>
        );
      }
    }
  };

  render() {
    return (
      <>
        <div className="schoolInformation">
          <div className="row">
            <div className="col-md-2 text-center">
              {this.props.editable ? (
                <>
                  <img
                    alt="profile-photo"
                    src={
                      this.props.updatedImage != null
                        ? URL.createObjectURL(this.props.updatedImage)
                        : this.props.schoolInfos.imageName != null
                        ? URLS.imageSelectURL + this.props.schoolInfos.imageName
                        : loginImage
                    }
                    onClick={this.toggleImageModal}
                    // onError={(e) => this.handleError(e)}
                    width="100px"
                    height="100px"
                  />
                  <div>
                    <input
                      id="image"
                      type="file"
                      accept="image/png, image/jpeg"
                      ref={(ref) => (this.upload = ref)}
                      style={{ display: "none" }}
                      onChange={this.props.handleImageChange}
                    />
                    <button
                      className="tt-button tt-button-primary"
                      onClick={() => {
                        this.upload.click();
                      }}
                    >
                      Update Logo
                    </button>
                  </div>
                </>
              ) : (
                <img
                  alt="profile-photo"
                  src={
                    this.props.schoolInfos.imageName != null &&
                    this.props.schoolInfos.imageName != ""
                      ? URLS.imageSelectURL + this.props.schoolInfos.imageName
                      : loginImage
                  }
                  onClick={this.toggleImageModal}
                  // onError={(e) => this.handleError(e)}
                  width="100px"
                  height="100px"
                />
              )}
            </div>
            <div className="col-md-8">
              <div className="tt-infoTable">
                <table className="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Name </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.name
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            className="form-control"
                            name="name"
                            value={this.props.name}
                            maxlength="255"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Acronym</strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.acronym
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            className="form-control"
                            name="acronym"
                            value={this.props.acronym}
                            maxlength="255"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Address </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.address
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            name="address"
                            id="address"
                            className="form-control"
                            value={this.props.address}
                            maxlength="255"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Country </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.countryName
                        ) : (
                          <select
                            value={this.props.countryId}
                            onChange={this.props.handleChange}
                            className="form-control"
                            name="countryId"
                          >
                            {this.props.countrys.map((country, idx) => {
                              return (
                                <option value={country.id} key={idx}>
                                  {country.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </td>
                    </tr>
                    {this.renderCountryLevels()}
                    <tr>
                      <td>
                        <strong>Postal code </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.postalCode
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            className="form-control"
                            name="postalCode"
                            id="postalCode"
                            value={this.props.postalCode}
                            maxlength="50"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Phone </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.phoneNumber
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            className="form-control"
                            value={this.props.phoneNumber}
                            maxlength="50"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Fax </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.fax
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            name="fax"
                            id="fax"
                            className="form-control"
                            value={this.props.fax}
                            maxlength="50"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Email </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.emailAddress
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            name="emailAddress"
                            id="emailAddress"
                            className="form-control"
                            value={this.props.emailAddress}
                            maxlength="50"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Website </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.website
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            name="website"
                            id="website"
                            className="form-control"
                            value={this.props.website}
                            maxlength="255"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>PAN/VAT No. </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.panVatNo
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="text"
                            name="panVatNo"
                            id="panVatNo"
                            className="form-control"
                            value={this.props.panVatNo}
                            maxlength="255"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Established on </strong>
                      </td>
                      <td>
                        {this.props.schoolInfos && !this.props.editable ? (
                          this.props.schoolInfos.dateOfEstablishment && (
                            <>
                              <span>
                                {moment(
                                  this.props.schoolInfos.dateOfEstablishment
                                ).format("Do MMMM, YYYY")}
                              </span>
                              <span>
                                {" "}
                                {displayNepaliDate(
                                  this.props.schoolInfos.dateOfEstablishment
                                )}
                              </span>
                            </>
                          )
                        ) : (
                          <input
                            onChange={this.props.handleChange}
                            type="date"
                            name="dateOfEstablishment"
                            className="form-control"
                            value={
                              this.props.schoolInfos.dateOfEstablishment &&
                              moment(this.props.dateOfEstablishment).format(
                                "YYYY-MM-DD"
                              )
                            }
                          />
                        )}
                      </td>
                    </tr>
                    {this.props.editable ? (
                      <tr>
                        <td>
                          <strong>Logo Location</strong>
                        </td>
                        <td>
                          <span className="ml-3 mr-2">Top</span>
                          <Switch
                            checked={this.props.switchToggle}
                            onClick={(e) => this.props.handleSwitchChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span className="ml-3 mr-2">Bottom</span>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        {checkRolePermissions("edit-school", "activity") ? (
          <div className="row tt-group-header mb-0">
            <div className="col-md-2"></div>
            <div className="col-md-4"></div>
            <div className="col-md-4 text-right">
              {this.props.schoolInfos && !this.props.editable ? (
                <button
                  className="tt-button tt-button-primary"
                  onClick={this.props.toggleSettingModal}
                >
                  Edit Information
                </button>
              ) : (
                <>
                  <button
                    className="tt-button tt-button-primary mr-3"
                    onClick={(e) =>
                      this.props.updateSchoolInformation(e, this.props.id)
                    }
                  >
                    Update
                  </button>
                  <button
                    className="tt-button tt-button-primary"
                    onClick={(e) => this.props.closeEditable(e)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default InformationForm;
