import React, { Component } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import FileViewer from "react-file-viewer";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomSpinner from "../common/CustomSpinner";
import ModalWindow from "../UI/ModalWindow";

class OtherActivitiesDataList extends Component {
  state = {
    resourceViewModal: false,
    filePath: "",
    fileType: "",
  };

  handleViewResources = (data) => {
    this.setState(
      {
        filePath: data.filePath,
        fileType: data.fileType,
      },
      () => {
        this.setState({
          resourceViewModal: true,
        });
      }
    );
  };

  toggleResourceViewModal = () => {
    this.setState({
      resourceViewModal: !this.state.resourceViewModal,
    });
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <table className="table cusTable">
              <thead>
                <tr>
                  <th>Thumbnail</th>
                  <th>Name</th>
                  <th>Class</th>

                  <th style={{ maxWidth: "250px" }}>Description</th>
                  <th>Resources</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.activeCat?.length > 0
                  ? this.props.activeCat.map((category, index) => {
                      return (
                        <tr>
                          <td>
                            <img
                              width="100"
                              src={URL.imageSelectURL + category.thumbnailPath}
                            />
                          </td>
                          <td>{category.title}</td>
                          <td>Class {category.classroomName}</td>
                          <td style={{ maxWidth: "250px" }}>
                            {category.description}
                          </td>

                          <td>
                            <button
                              className="btn btn-primary cusBtn cusBtnFontSize"
                              onClick={() => this.handleViewResources(category)}
                            >
                              View Resource
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-danger cusBtnDanger cusBtnFontSize"
                              onClick={() =>
                                this.props.handleDeleteActivityResource(
                                  category
                                )
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>

        <ModalWindow
          modal={this.state.resourceViewModal}
          toggleModal={this.toggleResourceViewModal}
          size="lg"
          modalHeader="Resource"
          modalBody={
            <>
              <div style={{ height: "100%" }}>
                {this.state.filePath && this.state.fileType === "VID" ? (
                  <FileViewer
                    fileType={this.state.filePath.split(".").pop()}
                    filePath={URL.imageSelectURL + this.state.filePath}
                  />
                ) : (
                  this.state.filePath && (
                    <DocViewer
                      pluginRenderers={DocViewerRenderers}
                      documents={[
                        { uri: URL.imageSelectURL + this.state.filePath },
                      ]}
                      config={{
                        header: {
                          disableHeader: false,
                          disableFileName: true,
                          retainURLParams: false,
                        },
                        pdfZoom: {
                          defaultZoom: 0.5, // 1 as default,
                          zoomJump: 0.2, // 0.1 as default,
                        },
                      }}
                    />
                  )
                )}
              </div>
            </>
          }
        />
      </>
    );
  }
}

export default OtherActivitiesDataList;
