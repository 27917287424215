import React, { Component } from "react";

class UpdateFlashCardSet extends Component {
  state = {};
  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Title: </strong>
          </div>
          <div className="col-md-7">
            <input
              type="text"
              placeholder="Enter Title"
              name="title"
              value={this.props.title}
              onChange={this.props.handleChange}
              className="form-control"
            ></input>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Description: </strong>
          </div>
          <div className="col-md-7">
            <textarea
              className="form-control"
              name="description"
              value={this.props.description}
              onChange={this.props.handleChange}
              placeholder="Enter Desciption"
            ></textarea>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Access: </strong>
          </div>
          <div className="col-md-7">
            <select
              className="form-control"
              name="access"
              value={this.props.access}
              onChange={this.props.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row button-bg-color">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.props.handleUpdateFlashCardModal}
            >
              Update Flash Cards
            </button>
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.props.updateFlashCardSet}
            >
              Update
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.toggleUpdateModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateFlashCardSet;
