import React, { Component } from "react";
import ToggleButton from "react-toggle-button";

class CreateCourseSubject extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-1">
            <strong>
              Name:
              <span
                className="tt-assessment-module-mandatory"
                style={{ paddingLeft: "5px" }}
              >
                *
              </span>
            </strong>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col pr-0" style={{ display: "flex" }}>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={this.props.name}
                  onChange={this.props.handleChange}
                ></input>
                {/* <span
                  className="tt-assessment-module-mandatory"
                  style={{ paddingLeft: "5px" }}
                >
                  *
                </span> */}
              </div>
              {/* <div className="col-md-auto">
                <div className="mt-2">
                  <ToggleButton
                    inactiveLabel={""}
                    activeLabel={""}
                    value={this.props.isMandatory}
                    colors={{
                      active: {
                        base: "#7798ed",
                      },
                    }}
                    onToggle={() => this.props.handleMandatoryToggleBtn()}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-md-3">
            {/* {this.props.isMandatory ? "Mandatory" : "Optional"} */}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-1">
            <strong>Description: </strong>
          </div>
          <div className="col-md-4">
            <textarea
              name="description"
              className="form-control"
              value={this.props.description}
              onChange={this.props.handleChange}
            ></textarea>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3"></div>
          <div className="col-md-2">
            <strong>Credit: </strong>
            <div
              className="text-muted"
              style={{ fontStyle: "italic", fontSize: "11px" }}
            >
              *Optional (For calculation of GPA only)
            </div>
          </div>
          <div className="col-md-1">
            <input
              type="number"
              className="form-control pr-0"
              name="credit"
              value={this.props.credit}
              onChange={this.props.handleChange}
              placeholder="in hours"
            ></input>
          </div>
          <div className="col-md-auto mt-1">
            <strong>
              Subject Code:
              <span
                className="tt-assessment-module-mandatory"
                style={{ paddingLeft: "5px" }}
              >
                *
              </span>
            </strong>
          </div>
          <div className="col-md-auto" style={{ display: "flex" }}>
            <input
              type="text"
              className="form-control"
              name="code"
              value={this.props.code}
              onChange={this.props.handleChange}
            ></input>
            {/* <span
              className="tt-assessment-module-mandatory"
              style={{ paddingLeft: "5px" }}
            >
              *
            </span> */}
          </div>

          <div className="col-md-3"></div>
        </div>
        <div className="row button-bg-color mt-2">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-1">
            <strong>Subject Type:</strong>{" "}
          </div>
          <div className="col-md-4 mt-1">
            <div className="row">
              <div className="col-md-2">
                <strong>Optional</strong>
              </div>
              <div className="col-md-auto">
                <ToggleButton
                  inactiveLabel={""}
                  activeLabel={""}
                  value={this.props.isMandatory}
                  colors={{
                    active: {
                      base: "#7798ed",
                    },
                  }}
                  onToggle={() => this.props.handleMandatoryToggleBtn()}
                />
              </div>
              <div className="col-md-2">
                <strong>Mandatory</strong>
              </div>
            </div>
          </div>
          <div className="col-md-3 text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.props.createCourseSubject}
            >
              Create
            </button>
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={this.props.clearState}
            >
              Cancel
            </button>
          </div>
          <div className="col-md-3"></div>
        </div>
      </>
    );
  }
}

export default CreateCourseSubject;
