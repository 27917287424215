import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { questionType, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomPageHeader from "../common/CustomPageHeader";
import NewTrainingDetails from "../NewTeacherPage/NewTrainingDetails";

class TeacherMaterial extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    activeTraining: null,
    selectedTraining: null,
    trainingList: [],
    trainingName: "",
    activeGallery: false,
    galleryData: [],
    courseList: [],
    syllabusSelected: false,
    topicList: [],
    activeSyllabus: false,
    examActive: false,
    examList: [],
    theoryQuestions: [],
    practicalQuestions: [],
    takeExamClick: false,
  };

  componentDidMount() {
    this.getAllTrainingData();
  }

  getAllTrainingData = () => {
    axiosPost(
      URL.getAllTraining,
      {},
      (response) => {
        if (response.status === 200 && response.data.data.length > 0) {
          this.setState({
            trainingList: response.data.data,
          });
        }
      },
      (err) => {},
      true
    );
  };
  goBack = () => {
    if (this.state.selectedTraining && this.state.activeSyllabus) {
      this.setState({ activeSyllabus: false });
    } else if (this.state.selectedTraining && this.state.activeGallery) {
      this.setState({ activeGallery: false });
    } else if (
      this.state.selectedTraining &&
      this.state.examActive &&
      !this.state.takeExamClick &&
      !this.state.viewResultClick
    ) {
      this.setState({ examActive: false });
    } else if (
      this.state.selectedTraining &&
      this.state.examActive &&
      this.state.viewResultClick
    ) {
      this.setState({
        viewResultClick: false,
        examActive: true,
      });
    } else if (
      this.state.selectedTraining &&
      this.state.examActive &&
      this.state.takeExamClick
    ) {
      swal("Error", "Cannot skip the exam!!");
    } else {
      this.setState({
        selectedTraining: null,
        galleryData: [],
        activeGallery: false,
        activeSyllabus: false,
        takeExamClick: false,
        examActive: false,
        viewResultClick: false,
      });
    }
  };

  selectTraining = (data) => {
    if (
      this.state.selectedTraining &&
      this.state.examActive &&
      this.state.takeExamClick
    ) {
      swal("Error", "Cannot skip the exam!!");
    } else {
      this.setState(
        {
          selectedTraining: data,
          activeTraining: data.name,
          trainingName: data.id,
          galleryData: data.images,
          activeSyllabus: false,
          activeGallery: false,
          examActive: false,
        },
        function () {
          // this.getTeacherTrainingSyllabus();
        }
      );
    }
  };

  galleryClick = () => {
    this.setState({
      activeGallery: true,
    });
  };

  handleSyllabusClick = () => {
    this.getTeacherTrainingSyllabus();
  };

  getTeacherTrainingSyllabus = () => {
    this.setState(
      {
        courseSyllabus: [],
        topicList: [],
        courseSpinner: true,
      },
      () => {
        axiosPost(
          URL.getAllTeacherTrainingSyllabus,
          {},
          (response) => {
            if (response.status === 200) {
              const cs = response.data.data;

              const tl = cs
                ? cs.filter(
                    (cs) =>
                      cs.teacherTrainingId === this.state.selectedTraining.id
                  )
                : null;

              this.setState({
                courseSyllabus: response.data.data,
                courseSpinner: false,

                topicList: tl,
                activeSyllabus: true,
              });
            }
          },
          (err) => {
            this.setState({ courseSpinner: false });
            displayErrorAlert(err);
          }
        );
      }
    );
  };

  getExamData = () => {
    let data = {
      currentTrainingId: this.state.selectedTraining.id,
    };
    axiosPost(
      URL.getExamQuestions,
      data,
      (response) => {
        const examList = response.data.data;
        // const theoryQuestions =
        //   questions && questions.filter((q) => q.isTheory);
        // const practicalQuestions =
        //   questions && questions.filter((q) => !q.isTheory);
        this.setState({ examList, examActive: true });
      },
      (err) => {}
    );
  };

  toggleExamQuestions = (data) => {
    this.setState({
      takeExamClick: true,
      theoryQuestions: data.examQuestions,
    });
  };

  handleAnswerChange = (e, stateName, qType, index, optionIndex) => {
    const { name, value, type, checked } = e.target;
    const ans = type === "checkbox" ? checked : value;
    let questions = [...this.state[stateName]];
    if (qType === questionType.SUBJECTIVE) {
      questions[index][name] = ans;
    } else if (qType === questionType.OBJECTIVE) {
      let options = questions[index].examQuestionOptions;
      options = options.map((o, oid) => {
        o["ticked"] = optionIndex === oid ? true : false;
        return o;
      });
      questions[index].examQuestionOptions = options;
    } else {
      questions[index][name] = "";
    }
    this.setState({ [stateName]: questions });
  };

  handleSubmit = () => {
    const answers = [
      ...this.state.theoryQuestions,
      ...this.state.practicalQuestions,
    ];

    //empty subjective answers check
    const sExist = answers.some(
      (a) => a.questionType === questionType.SUBJECTIVE
    );
    const oExist = answers.some(
      (a) => a.questionType === questionType.OBJECTIVE
    );

    const ansS = answers
      .filter((a) => a.questionType === questionType.SUBJECTIVE)
      .every((a) => a.subjectiveAnswer != null && a.subjectiveAnswer !== "");

    //at least one checked subjective answer
    const ansO = answers
      .filter((a) => a.questionType === questionType.OBJECTIVE)
      .every(
        (a) =>
          a.examQuestionOptions != null &&
          a.examQuestionOptions.some((opt) => opt.ticked)
      );
    let e = null;
    if (sExist && oExist) {
      e = !ansS && ansO;
    } else if (sExist && !oExist) {
      e = !ansS;
    } else if (!sExist && oExist) {
      e = !ansO;
    }

    if (e) {
      swal("Warning", "Some answers are missing. Please answer them first.");
    } else {
      swal({
        title: "Success",
        text: "Are you sure to submit your answers?. Please check and verify your answers first before submitting.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      }).then(() => {
        const data = answers.map((a) => {
          if (a.questionType === questionType.SUBJECTIVE) {
            return {
              examQuestionId: a.id,
              subjectiveAnswer: a.subjectiveAnswer,
              examQuestionOptionId: null,
              isSubmitted: true,
            };
          } else if (a.questionType === questionType.OBJECTIVE) {
            const tickedAnswer = a.examQuestionOptions.find(
              (opt) => opt.ticked
            );
            return {
              examQuestionId: a.id,
              subjectiveAnswer: null,
              examQuestionOptionId: tickedAnswer ? tickedAnswer.id : null,
              isSubmitted: true,
            };
          } else {
            return {
              examQuestionId: a.id,
              subjectiveAnswer: null,
              examQuestionOptionId: null,
              isSubmitted: true,
            };
          }
        });

        axiosPost(
          URL.insertExamAnswer,
          data,
          (response) => {
            if (response.status === 200) {
              swal(
                "Success",
                "Congratulations! You have attended system exam successfully."
              );
              this.setState({
                yearLevelId: "",
                classRoomId: "",
                assessmentId: "",
                subjectId: "",
                theoryQuestions: [],
                practicalQuestions: [],
                takeExamClick: false,
              });
            } else {
              swal("Error", "Error submitting exam. Please try again.");
            }
          },
          (err) => {
            swal("Error", "Error submitting exam. Please try again.");
          }
        );
      });
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="whiteContainer">
              <CustomPageHeader title="Teacher Materials" noIcon={true} />
              <section>
                {this.state.selectedTraining ? (
                  <NewTrainingDetails
                    courseList={this.state.courseList}
                    activeGallery={this.state.activeGallery}
                    galleryData={this.state.galleryData}
                    galleryClick={this.galleryClick}
                    goBack={this.goBack}
                    selectedTraining={this.state.selectedTraining}
                    selectTraining={this.selectTraining}
                    trainingList={this.state.trainingList}
                    handleSyllabusClick={this.handleSyllabusClick}
                    getTeacherTrainingSyllabus={this.getTeacherTrainingSyllabus}
                    activeSyllabus={this.state.activeSyllabus}
                    topicList={this.state.topicList}
                    getExamData={this.getExamData}
                    examActive={this.state.examActive}
                    examList={this.state.examList}
                    toggleExamQuestions={this.toggleExamQuestions}
                    takeExamClick={this.state.takeExamClick}
                    theoryQuestions={this.state.theoryQuestions}
                    handleSubmit={this.handleSubmit}
                    handleAnswerChange={this.handleAnswerChange}
                    getLoggedInStatus={true}
                    dashbaordTeacherMaterial={true}
                  />
                ) : (
                  <div className="subjectClassList">
                    {this.state.trainingList.length > 0 ? (
                      this.state.trainingList.map((trn, idx) => {
                        return (
                          <div
                            key={idx}
                            className={"subjectBoxDiv"}
                            onClick={() => this.selectTraining(trn)}
                          >
                            <span>{trn.name}</span>
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">No Trainings added</div>
                    )}
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeacherMaterial;
