import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import {
  checkRolePermissions,
  URL,
  userRole,
  userRoleCode,
} from "../../utils/Constants";
import { Modal, ModalBody } from "reactstrap";
import FullImage from "../../components/Profile/FullImage";
import { TabList, Tabs, Tab } from "react-tabs";
import FileModal from "../../components/ViewFile/FileModal";
// import FileModal from "../../UI/FileModal";

class NoticeFileViewer extends Component {
  state = {
    images: [],
    files: [],
    links: [],
    medias: [],
    contents: [],
    imageModal: false,
    selectedImage: "",
    batch: [],
    selectedIndex: 0,
    currentBatch: "",
    remarks: "",
    filePath: "",
    fileType: "",
    activeIndex: 0,
    type: "",
    mark: "",
    ckText: "",
    ckHead: "",
  };

  componentDidMount() {
    this.switchMethod();
  }

  switchMethod = () => {
    this.props.isSubmitted ? this.handleTabForResources() : this.separateData();
  };

  presetFileViewModal = () => {
    if (this.state.images.length > 0) {
      this.setState({
        type: "image",
        fileType: this.state.images[0].contentTypeCode,
        filePath: this.state.images[0].content,
        activeIndex: this.state.images[0].id,
      });
    } else if (this.state.files.length > 0) {
      this.setState({
        type: "files",
        fileType: this.state.files[0].contentTypeCode,
        filePath: this.state.files[0].content,
        activeIndex: this.state.files[0].id,
      });
    } else if (this.state.links.length > 0) {
      this.setState({
        type: "link",
        fileType: this.state.links[0].contentTypeCode,
        filePath: this.state.links[0].content,
        activeIndex: this.state.links[0].id,
      });
    } else if (this.state.contents.length > 0) {
      this.setState({
        type: "txt",
        fileType: this.state.contents[0].fileType,
        filePath: this.state.contents[0].content,
        activeIndex: this.state.contents[0].id,
      });
    } else if (this.state.medias.length > 0) {
      this.setState({
        type: "media",
        fileType: this.state.medias[0].fileName.split(".").pop(),
        filePath: this.state.medias[0].content,
        activeIndex: this.state.medias[0].id,
      });
    }
  };

  //   handleChangeFile = (content, idx, type) => {
  //     if (type === "link") {
  //       this.setState({ type: type });
  //     } else if (type === "txt") {
  //       this.setState(
  //         {
  //           filePath: "",
  //           fileType: "",
  //         },
  //         () => {
  //           this.setState({
  //             filePath: content.fileName,
  //             fileType: content.fileType,
  //             activeIndex: idx,
  //             type: type,
  //           });
  //         }
  //       );
  //     } else {
  //       this.setState(
  //         {
  //           filePath: "",
  //           fileType: "",
  //         },
  //         () => {
  //           this.checkFileExist(content);
  //           this.setState({
  //             filePath: content,
  //             fileType: content.split(".").pop(),
  //             activeIndex: idx,
  //             type: type,
  //           });
  //         }
  //       );
  //     }
  //   };
  handleChangeFile = (file, content, contentTypeCode, idx, type) => {
    this.setState(
      {
        filePath: "",
        fileType: "",
      },
      () => {
        this.checkFileExist(content);
        this.setState({
          filePath: content,
          fileType: contentTypeCode,
          activeIndex: file.id,
          type: type,
        });
      }
    );
    let element = document.getElementsByClassName("fileListMenu");
    if (element[0].classList.contains("activeFileList")) {
      element[0].classList.remove("activeFileList");
    }
    // else {
    //   element[0].classList.add("activeFileList");
    // }
  };

  checkFileExist = (path) => {
    let data = URL.imageSelectURL + path;
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", data, false);
    xhr.send();

    let status = parseInt(xhr.status);
    if (status == 404 || status == 401) {
      this.setState({
        fileExist: false,
      });
    } else {
      this.setState({
        fileExist: true,
      });
    }
  };

  handleTabForResources = () => {
    let resources = this.props.selectedResources;
    let images = [];
    let files = [];
    let links = [];
    let batch = [];
    let remarks = "";
    let mark = "";
    let text = "";

    batch = [...new Set(resources.map((item) => item.batch))];
    this.setState({ batch }, () => {
      resources.forEach((el) => {
        if (el.fileType === "IMG" && el.batch === this.state.batch[0]) {
          images.push(el);
        } else if (el.fileType === "LNK" && el.batch === this.state.batch[0]) {
          links.push(el);
        } else if (
          el.fileType !== "IMG" &&
          el.fileType !== "LNK" &&
          el.fileType !== "RMK" &&
          el.batch === this.state.batch[0]
        ) {
          files.push(el);
        } else if (el.fileType === "RMK" && el.batch === this.state.batch[0]) {
          remarks = el.fileName;
          mark = el.mark;
        }
      });
      this.setState(
        {
          images,
          files,
          links,
          currentBatch: this.state.batch[0],
          remarks,
          mark,
        },
        () => {
          this.presetFileViewModal();
        }
      );
    });
  };

  handleTabForResourcesCustom = (idx) => {
    let resources = this.props.selectedResources;
    let images = [];
    let files = [];
    let links = [];
    let remarks = "";
    let mark = "";
    this.setState({ remarks: "" });
    resources.forEach((el) => {
      if (el.fileType === "IMG" && el.batch === this.state.batch[idx]) {
        images.push(el);
      } else if (el.fileType === "LNK" && el.batch === this.state.batch[idx]) {
        links.push(el);
      } else if (
        el.fileType !== "IMG" &&
        el.fileType !== "LNK" &&
        el.fileType !== "RMK" &&
        el.batch === this.state.batch[idx]
      ) {
        files.push(el);
      } else if (el.fileType === "RMK" && el.batch === this.state.batch[idx]) {
        remarks = el.fileName;
        mark = el.mark;
      }
    });
    this.setState({
      images,
      files,
      links,
      currentBatch: this.state.batch[idx],
      remarks,
      mark,
    });
  };

  separateData = () => {
    let resources = this.props.activeNoticeFile;
    let images = [];
    let files = [];
    let links = [];
    let contents = [];
    let medias = [];
    if (resources && resources.length > 0) {
      resources.forEach((el) => {
        if (el.contentTypeCode === "IMG") {
          images.push(el);
        } else if (el.contentTypeCode === "LNK") {
          links.push(el);
        } else if (el.contentTypeCode === "TXT") {
          contents.push(el);
        } else if (
          el.contentTypeCode === "AUD" ||
          el.contentTypeCode === "VID"
        ) {
          medias.push(el);
        } else {
          files.push(el);
        }
      });
    }
    this.setState({ images, files, links, contents, medias }, () => {
      this.presetFileViewModal();
    });
  };

  handleFullImage = (image) => {
    this.setState({
      imageModal: !this.state.imageModal,
      selectedImage: URL.imageSelectURL + image.filePath,
    });
  };

  toggleImageModal = () => {
    this.setState({ imageModal: !this.state.imageModal, selectedImage: "" });
  };

  handleSelectTabs = (index) => {
    this.setState({ selectedIndex: index });
    this.handleTabForResourcesCustom(index);
  };
  render() {
    return (
      <div className="tt-contentWidget-tab-holder" style={{ height: "82.2vh" }}>
        <div className="container-fluid">
          <FileModal
            // files={this.props.selectedResources}
            isNotice={true}
            handleChangeFile={this.handleChangeFile}
            fileExist={true}
            fileType={this.state.fileType}
            filePath={this.state.filePath}
            activeIndex={this.state.activeIndex}
            files={this.state.files}
            images={this.state.images}
            links={this.state.links}
            medias={this.state.medias}
            type={this.state.type}
            contents={this.state.contents}
            lmsFile={true}
          ></FileModal>
        </div>
        <Modal
          id="fullProfileImage"
          isOpen={this.state.imageModal}
          toggle={this.toggleImageModal}
          centered
          className="tt-profileFullImage"
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="tt-ImageCloseIcon"
            onClick={this.toggleImageModal}
          ></FontAwesomeIcon>
          <ModalBody>
            <FullImage
              image={this.state.selectedImage}
              caption={""}
            ></FullImage>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default NoticeFileViewer;
