import React, { useState } from "react";

import ReactPaginate from "react-paginate";
import { URL } from "../../utils/Constants";

const TeacherTrainingGallery = (props) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + props.itemsPerPage;
  const currentItems = props.galleryData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(props.galleryData.length / props.itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * props.itemsPerPage) % props.galleryData.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <div>
        <div className="row">
          {currentItems.map((img) => (
            <div className="col-md-4">
              <div className="gallery-img">
                <div
                  className="img-box"
                  onClick={() => this.handleFullViewImg(img)}
                >
                  <img
                    src={URL.imageSelectURL + img.name}
                    alt="No preview available"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={props.galleryData.length / props.itemsPerPage}
          pageCount={pageCount}
          previousLabel="< prev"
          renderOnZeroPageCount={null}
          breakClassName={"break-me"}
          marginPagesDisplayed={2}
          containerClassName={"pagination-div"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default TeacherTrainingGallery;
