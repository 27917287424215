/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, Collapsible, render the full Collapsible i.e. header along the collapsible body
 *              pass in header props to dynamically display the header and collapsibleBody to interact with
 *              the corresponding body
 */

import React, { Component } from "react";
import CustomCollapse from "./CustomCollapse";
import { URL, scaleTypeCode, draggable } from "../../utils/Constants";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import HighlightedHeader from "../AssessmentRecordReport/Reports/SubjectAssessment/HighlightedHeader";
import { connect } from "react-redux";
import {
  getAssessmentWithScaleOrComment,
  updateAssessmentWithScaleOrComment,
  getAssessmentSubjectList,
  updateAssessmentSubjectList,
  getStudentList,
  updateStudentList,
  getYearLevelForPublishAssessmentReport,
} from "../../ducks/PrimaryARRDucks";
import { showErroMessage } from "../../utils/Utils";
import StudentSubjectAssessmentReport from "./../AssessmentRecordReport/Reports/SubjectAssessment/StudentSubjectAssessmentReport";
import ModalWindow from "./ModalWindow";
import { MESSAGEHEADER } from "../../utils/DisplayMessage";
import StudentSubjectReport from "../AssessmentRecordReport/Reports/StudentSubjectReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

class SubjectAssessmentCollapsible extends Component {
  state = {
    collapsibleState: false,
    classrooms: [],
    students: [],
    newstudents: [],
    student: "",
    assessmentScaleParam: {},
    assessmentScales: [],
    assessmentSubjectComment: null,
    studentNameValuePair: "",
    commentText: "",
    finalize: false,
    finalizeClickable: true,
    studentsModal: false,
    refresh: false,
    oldRecord: [],
  };

  refreshState = () => {
    this.setState({ refresh: !this.state.refresh });
  };

  toggleCollapse = () => {
    this.setState({
      collapsibleState: !this.state.collapsibleState,
    });
    if (this.props.studentClassroom)
      this.getStudents(this.props.studentClassroom);
  };

  /**
   * This methods is invoked if any valid yearLevelId is passed as a prop and it renders the body by fetching
   * the list of classroom with respect to the yearLevelId
   * @param {integer} yearLevelId id sent as props while mapping the yearLevels array in ListClasses Component
   *
   */
  getClassrooms = (yearLevelId) => {
    axiosPost(
      URL.selectClassroom,
      {
        yearLevelId: yearLevelId,
      },
      (response) => {
        if (response.status === 200) {
          let classrooms = response.data.data;
          if (classrooms.length > 0) {
            classrooms.forEach((classroom) => {
              classroom.isClassroomOpen = false;
            });
          }
          this.setState({
            classrooms,
          });
        }
      }
    );
  };

  handleClassroomToggle = (index, idObject) => {
    let classrooms = [...this.state.classrooms];

    classrooms.forEach((classroom, i) => {
      index === i
        ? (classroom.isClassroomOpen = !classroom.isClassroomOpen)
        : (classroom.isClassroomOpen = false);
    });

    this.setState({ classrooms }, () => {
      this.props.getAssessmentWithScaleOrComment(idObject);
    });
  };

  handleAssessmentCollapse = (index, updatedIdObject) => {
    let assessments = [...this.props.assessments];

    assessments.forEach((assessment, i) => {
      index === i
        ? (assessment.isAssessmentOpen = !assessment.isAssessmentOpen)
        : (assessment.isAssessmentOpen = false);
    });
    // this.setState({ assessments }
    this.props.updateAssessmentWithScaleOrComment(assessments);
    this.props.getAssessmentSubjectList(updatedIdObject);
    // this.props.getStudentList(updatedIdObject);
  };

  handleSubjectCollapse = (index, studentIds) => {
    let subjects = [...this.props.subjects];
    subjects.forEach((subject, i) => {
      index === i
        ? (subject.isSubjectOpen = !subject.isSubjectOpen)
        : (subject.isSubjectOpen = false);
    });
    let selectedSubject = subjects[index];

    this.props.updateAssessmentSubjectList(subjects);
    const studentClassroom = studentIds;
    let assessmentId = this.props.assessments[this.props.assessmentIdx].id;
    axiosPost(
      URL.selectSubjectStudentClassroom,
      {
        classroomId: studentIds.classroomId,
        yearLevelId: studentIds.yearLevelId,
        subjectId: selectedSubject.id,
        assessmentId: assessmentId,
      },
      (response) => {
        if (response.status === 200) {
          let newstudents = response.data.data;
          //get assessment scale and update student list
          this.setState({ selectedSubject, newstudents }, () => {
            axiosPost(
              URL.selectAssessmentScaleForSubject,
              studentClassroom ? studentClassroom : {},
              (response) => {
                if (response.status === 200) {
                  let assessmentScales = response.data.data;
                  if (
                    assessmentScales &&
                    assessmentScales.assessmentScalesForPractical &&
                    assessmentScales.assessmentScalesForPractical.length > 0
                  ) {
                    assessmentScales.assessmentScalesForPractical.forEach(
                      (aSPractical) => {
                        if (
                          aSPractical.assessmentScaleTypeCode ===
                          scaleTypeCode.mark
                        )
                          aSPractical.obtainedMarks =
                            aSPractical.assessmentScaleMark
                              ? aSPractical.assessmentScaleMark
                                  .minAchievementLevel
                                ? aSPractical.assessmentScaleMark
                                    .minAchievementLevel
                                : 0
                              : 0;
                        if (
                          aSPractical.assessmentScaleTypeCode ===
                          scaleTypeCode.level
                        )
                          aSPractical.assessmentScaleLevelId = "";
                        if (
                          aSPractical.assessmentScaleTypeCode ===
                          scaleTypeCode.gradePoint
                        )
                          aSPractical.assessmentScaleGradePointId = "";
                      }
                    );
                  }
                  if (
                    assessmentScales &&
                    assessmentScales.assessmentScalesForTheory &&
                    assessmentScales.assessmentScalesForTheory.length > 0
                  ) {
                    assessmentScales.assessmentScalesForTheory.forEach(
                      (aSTheory) => {
                        if (
                          aSTheory.assessmentScaleTypeCode ===
                          scaleTypeCode.mark
                        )
                          aSTheory.obtainedMarks = aSTheory.assessmentScaleMark
                            ? aSTheory.assessmentScaleMark.minAchievementLevel
                              ? aSTheory.assessmentScaleMark.minAchievementLevel
                              : 0
                            : 0;
                        if (
                          aSTheory.assessmentScaleTypeCode ===
                          scaleTypeCode.level
                        )
                          aSTheory.assessmentScaleLevelId = "";
                        if (
                          aSTheory.assessmentScaleTypeCode ===
                          scaleTypeCode.gradePoint
                        )
                          aSTheory.assessmentScaleGradePointId = "";
                      }
                    );
                  }
                  // if (assessmentScales) {
                  //   assessmentScales.forEach((assessmentScale) => {
                  //     if (
                  //       assessmentScale.assessmentScaleTypeCode ===
                  //       scaleTypeCode.mark
                  //     )
                  //       assessmentScale.obtainedMarks =
                  //         assessmentScale.assessmentScaleMark
                  //           ? assessmentScale.assessmentScaleMark
                  //               .minAchievementLevel
                  //             ? assessmentScale.assessmentScaleMark
                  //                 .minAchievementLevel
                  //             : 0
                  //           : 0;
                  //     if (
                  //       assessmentScale.assessmentScaleTypeCode ===
                  //       scaleTypeCode.level
                  //     )
                  //       assessmentScale.assessmentScaleLevelId = "";
                  //     if (
                  //       assessmentScale.assessmentScaleTypeCode ===
                  //       scaleTypeCode.gradePoint
                  //     )
                  //       assessmentScale.assessmentScaleGradePointId = "";
                  //   });
                  // }
                  let newstudents = [...this.state.newstudents];
                  newstudents.forEach((student) => {
                    student.isStudentOpen = false;
                    student.commentText = "";
                    // student.assessmentScales = JSON.parse(
                    //   JSON.stringify(assessmentScales)
                    // );
                    student.assessmentScalesForPractical = JSON.parse(
                      JSON.stringify(
                        assessmentScales.assessmentScalesForPractical
                      )
                    );
                    student.assessmentScalesForTheory = JSON.parse(
                      JSON.stringify(assessmentScales.assessmentScalesForTheory)
                    );
                  });
                  this.setState({ newstudents }, () => {
                    let newstudents = [...this.state.newstudents];
                    this.getstudentSubjectAssessmentByKey(
                      newstudents,
                      studentIds
                    );
                  });
                }
              }
            );
          });
        }
      }
    );
  };

  handleStudentCollpase = (index) => {
    let students = [...this.state.students];

    students.forEach((student, i) => {
      index === i
        ? (student.isStudentOpen = !student.isStudentOpen)
        : (student.isStudentOpen = false);
    });
    this.setState({ students });
  };

  getScaleAndConfiguration = (assessmentScaleParam) => {
    let studentClassroom = assessmentScaleParam;
    this.setState({ studentClassroomParam: studentClassroom });
    if (studentClassroom !== undefined) {
      axiosPost(
        URL.selectAssessmentSubjectCommentConfigurationByKey,
        studentClassroom,
        (response) => {
          if (response.status === 200) {
            let assessmentSubjectComment = response.data.data;
            this.setState({
              assessmentSubjectComment,
            });
          } else {
            swal("Error", response.message, "");
          }
        }
      );
    }
  };

  getStudentNameValuePair = (studentId) => {
    let param = {
      id: studentId,
    };
    axiosPost(URL.studentNameValuePair, param, (response) => {
      if (response.status === 200) {
        this.setState({ studentNameValuePair: response.data.data });
      } else {
        showErroMessage(response);
      }
    });
  };

  handleInsertComment = (e, comment, commentId) => {
    let newStudents = [...this.state.newstudents];
    newStudents.forEach((student, i) => {
      if (commentId === i) {
        student.commentText = comment.commentText;
      }
    });
    this.setState({ newStudents });
  };

  getstudentSubjectAssessmentByKey = (studentlist, assessmentScaleParam) => {
    let newstudents = [...studentlist];
    newstudents.forEach((student) => {
      student.isCommentBoxEdited = false;
      let assessmentScalesForTheory = student.assessmentScalesForTheory;
      let assessmentScalesForPractical = student.assessmentScalesForPractical;
      assessmentScalesForTheory &&
        assessmentScalesForTheory.length > 0 &&
        assessmentScalesForTheory.forEach((scale) => {
          let param = {
            educationLevelId: assessmentScaleParam.educationLevelId,
            educationLevelDivisionId:
              assessmentScaleParam.educationLevelDivisionId,
            yearLevelId: assessmentScaleParam.yearLevelId,
            classroomId: assessmentScaleParam.classroomId,
            subjectId: assessmentScaleParam.subjectId,
            assessmentId: assessmentScaleParam.assessmentId,
            admissionId: student.admissionId,
            assessmentScaleTheoryId: scale.id,
          };
          scale.isTheoryAbsent = false;
          axiosPost(
            URL.selectStudentSubjectAssessmentByKey,
            param,
            (response) => {
              if (response.status === 200) {
                let studentSubjectAssessment = response.data.data;
                if (studentSubjectAssessment) {
                  scale.isTheoryAbsent = studentSubjectAssessment.isTheoryAbsent
                    ? studentSubjectAssessment.isTheoryAbsent
                    : false;
                  if (studentSubjectAssessment.commentText !== null) {
                    student.commentText = studentSubjectAssessment.commentText;
                  }
                  if (scale.assessmentScaleTypeCode === scaleTypeCode.mark) {
                    scale.theoryObtainedValue =
                      studentSubjectAssessment.theoryObtainedMarks;
                    scale.theoryObtainedMarks =
                      studentSubjectAssessment.theoryObtainedMarks;
                  } else if (
                    scale.assessmentScaleTypeCode === scaleTypeCode.level
                  ) {
                    scale.theoryObtainedValue =
                      studentSubjectAssessment.assessmentScaleLevelTheoryId;
                    scale.assessmentScaleLevelTheoryId =
                      studentSubjectAssessment.assessmentScaleLevelTheoryId;
                  } else if (
                    scale.assessmentScaleTypeCode === scaleTypeCode.gradePoint
                  ) {
                    scale.theoryObtainedValue =
                      studentSubjectAssessment.assessmentScaleGradePointTheoryId;
                    scale.assessmentScaleGradePointTheoryId =
                      studentSubjectAssessment.assessmentScaleGradePointTheoryId;
                  }
                }
              }
            }
          );
          scale.edited = false;
        });

      assessmentScalesForPractical.length > 0 &&
        assessmentScalesForPractical.forEach((scale) => {
          let param = {
            educationLevelId: assessmentScaleParam.educationLevelId,
            educationLevelDivisionId:
              assessmentScaleParam.educationLevelDivisionId,
            yearLevelId: assessmentScaleParam.yearLevelId,
            classroomId: assessmentScaleParam.classroomId,
            subjectId: assessmentScaleParam.subjectId,
            assessmentId: assessmentScaleParam.assessmentId,
            admissionId: student.admissionId,
            assessmentScalePracticalId: scale.id,
          };
          scale.isPracticalAbsent = false;
          axiosPost(
            URL.selectStudentSubjectAssessmentByKey,
            param,
            (response) => {
              if (response.status === 200) {
                let studentSubjectAssessment = response.data.data;
                if (studentSubjectAssessment) {
                  scale.isPracticalAbsent =
                    studentSubjectAssessment.isPracticalAbsent
                      ? studentSubjectAssessment.isPracticalAbsent
                      : false;
                  if (studentSubjectAssessment.commentText !== null) {
                    student.commentText = studentSubjectAssessment.commentText;
                  }
                  if (scale.assessmentScaleTypeCode === scaleTypeCode.mark) {
                    scale.practicalObtainedValue =
                      studentSubjectAssessment.practicalObtainedMarks;
                    scale.practicalObtainedMarks =
                      studentSubjectAssessment.practicalObtainedMarks;
                  } else if (
                    scale.assessmentScaleTypeCode === scaleTypeCode.level
                  ) {
                    scale.practicalObtainedValue =
                      studentSubjectAssessment.assessmentScaleLevelPracticalId;
                    scale.assessmentScaleLevelPracticalId =
                      studentSubjectAssessment.assessmentScaleLevelPracticalId;
                  } else if (
                    scale.assessmentScaleTypeCode === scaleTypeCode.gradePoint
                  ) {
                    scale.practicalObtainedValue =
                      studentSubjectAssessment.assessmentScaleGradePointPracticalId;
                    scale.assessmentScaleGradePointPracticalId =
                      studentSubjectAssessment.assessmentScaleGradePointPracticalId;
                  }
                }
              }
            }
          );
          scale.edited = false;
        });
    });
    this.getScaleAndConfiguration(assessmentScaleParam);
    this.setState({ newstudents }, () => {
      this.getAssessmentFinalizeClickable(assessmentScaleParam);
    });
  };

  handleStudentCommentChange = (value, index, parentDivId) => {
    let students = [...this.state.newstudents];
    students.forEach((student, i) => {
      if (i === index) {
        if (document.getElementById(parentDivId)) {
          var doc = document.getElementById(parentDivId);
          var commentTemplate = doc.getElementsByTagName("span");
          for (let i = 0; i < commentTemplate.length; i++) {
            if (commentTemplate[i].dataset.template) {
              let nameValuePair = Object.entries(
                this.state.studentNameValuePair
              );
              for (let j = 0; j < nameValuePair.length; j++) {
                if (
                  nameValuePair[j][0] === commentTemplate[i].dataset.template
                ) {
                  if (nameValuePair[j][1] === "") {
                    swal(nameValuePair[j][0] + " is Empty");
                    commentTemplate[i].remove();
                  } else {
                    commentTemplate[i].innerText = nameValuePair[j][1];
                  }
                }
              }
            }
          }
          var children = doc.getElementsByTagName("*");
          for (var i = 0; i < children.length; i++) {
            if (
              children[i].className === "jodit_wysiwyg" ||
              children[i].className === "jodit-wysiwyg"
            ) {
              value = children[i].innerHTML;
            }
          }
          let nameValuePair = Object.entries(this.state.studentNameValuePair);
          for (let j = 0; j < nameValuePair.length; j++) {
            value = value.replaceAll(
              "[" + nameValuePair[j][0] + "]",
              nameValuePair[j][1]
            );
          }
        }
        student.commentText = value;
        if (student.commentText !== "") {
          student.isCommentBoxEdited = true;
        } else {
          student.isCommentBoxEdited = false;
        }
      }
    });
    const maxLength = this.state.assessmentSubjectComment.maxLength,
      textType = this.state.assessmentSubjectComment.textType;

    if (value && maxLength && value.length > maxLength) {
      swal(
        "Warning",
        "Comment text cannot exceed " + maxLength + " " + textType,
        ""
      );
    }
    this.setState({ newstudents: students });
  };

  // counterHandler = (count, idx, i) => {
  //   let students = [...this.state.newstudents];
  //   let assessmentScales = students[idx].assessmentScales;
  //   let assessmentScale = assessmentScales[i];
  //   if (assessmentScale) {
  //     assessmentScale.obtainedMarks = count;
  //     students[idx].assessmentScales[i].edited = true;
  //   } else {
  //     return;
  //   }
  //   this.setState({ newstudents: students });
  // };

  practicalCounterHandler = (count, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForPractical =
      students[idx].assessmentScalesForPractical;
    let assessmentScale = assessmentScalesForPractical[i];
    if (count.length > 4) {
      swal("Warning", "Maximum one decimal point is allowed");
    } else if (
      parseInt(count) > assessmentScale.theoryMax ||
      parseInt(count) < 0
    ) {
      swal("Warning", "Input marks is invalid");
    } else {
      if (assessmentScale) {
        assessmentScale.practicalObtainedMarks = count;
        students[idx].assessmentScalesForPractical[i].edited = true;
      } else {
        return;
      }
      this.setState({ newstudents: students });
    }
  };

  theoryCounterHandler = (count, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForTheory = students[idx].assessmentScalesForTheory;
    let assessmentScale = assessmentScalesForTheory[i];
    if (count.length > 4) {
      swal("Warning", "Maximum one decimal point is allowed");
    } else if (
      parseInt(count) > assessmentScale.theoryMax ||
      parseInt(count) < 0
    ) {
      swal("Warning", "Input marks is invalid");
    } else {
      if (assessmentScale) {
        assessmentScale.theoryObtainedMarks = count;
        students[idx].assessmentScalesForTheory[i].edited = true;
      } else {
        return;
      }
      this.setState({ newstudents: students });
    }
  };
  // theoryCounterIncreaseDecrease = (type, idx, i) =>{

  //   let students = [...this.state.newstudents];
  //   let assessmentScalesForTheory = students[idx].assessmentScalesForTheory;
  //   let assessmentScale = assessmentScalesForTheory[i];
  //   if (assessmentScale) {
  //     assessmentScale.theoryObtainedMarks = count;
  //     students[idx].assessmentScalesForTheory[i].edited = true;
  //   } else {
  //     return;
  //   }
  //   this.setState({ newstudents: students });

  // }

  handleObtainedMarksIncrementDecrement = (incDec, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScales = students[idx].assessmentScales;
    let assessmentScale = assessmentScales[i];
    if (assessmentScale) {
      if (incDec === "inc") {
        assessmentScale.obtainedMarks++;
      } else if (incDec === "dec") {
        assessmentScale.obtainedMarks--;
      } else {
        return;
      }
    }
    this.setState({ newstudents: students });
  };

  handleAssessmentScaleForPracticalLevelChange = (e, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForPractical =
        students[idx].assessmentScalesForPractical,
      element = e.target;
    let assessmentScale = assessmentScalesForPractical[i],
      value = element.value;
    assessmentScale.assessmentScaleLevelPracticalId = value;
    students[idx].assessmentScalesForPractical[i].edited = true;
    this.setState({ newstudents: students });
  };

  handleAssessmentScaleForTheoryLevelChange = (e, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForTheory = students[idx].assessmentScalesForTheory,
      element = e.target;
    let assessmentScale = assessmentScalesForTheory[i],
      value = element.value;
    assessmentScale.assessmentScaleLevelTheoryId = value;
    students[idx].assessmentScalesForTheory[i].edited = true;
    this.setState({ newstudents: students });
  };

  handleAssessmentScaleGradePointChange = (e, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScales = students[idx].assessmentScales,
      element = e.target;
    let assessmentScale = assessmentScales[i],
      value = element.value;
    assessmentScale.assessmentScaleGradePointId = value;
    this.setState({ newstudents: students });
  };

  handleAssessmentScaleTheoryGradePointChange = (e, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForTheory = students[idx].assessmentScalesForTheory,
      element = e.target;
    let assessmentScale = assessmentScalesForTheory[i],
      value = element.value;
    assessmentScale.assessmentScaleGradePointTheoryId = value;
    students[idx].assessmentScalesForTheory[i].edited = true;
    this.setState({ newstudents: students });
  };

  handleAssessmentScalePracticalGradePointChange = (e, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForPractical =
        students[idx].assessmentScalesForPractical,
      element = e.target;
    let assessmentScale = assessmentScalesForPractical[i],
      value = element.value;
    assessmentScale.assessmentScaleGradePointPracticalId = value;
    students[idx].assessmentScalesForPractical[i].edited = true;
    this.setState({ newstudents: students });
  };

  handleSubmit = (e) => {
    const studentClassroom = this.state.studentClassroomParam;
    let students = [...this.state.newstudents];
    let studentSubjectAssessmentsParam = [];

    let error = 0;
    students.forEach((student) => {
      let studentSubjectAssessmentParam = {
        educationLevelId: studentClassroom.educationLevelId,
        educationLevelDivisionId: studentClassroom.educationLevelDivisionId,
        yearLevelId: studentClassroom.yearLevelId,
        classroomId: studentClassroom.classroomId,
        subjectId: studentClassroom.subjectId,
        assessmentId: studentClassroom.assessmentId,
        admissionId: student.admissionId, // student come from for each loop.
        commentText: student.commentText,
      };

      let studentSubjectAssessmentsPractical = [],
        studentSubjectAssessmentsTheory = [];

      if (
        student.assessmentScalesForPractical &&
        student.assessmentScalesForPractical.length > 0
      ) {
        student.assessmentScalesForPractical.forEach((assessmentScale) => {
          if (
            assessmentScale.assessmentScaleLevelPracticalId === null ||
            assessmentScale.assessmentScaleLevelPracticalId === ""
          ) {
            error++;
          }
          if (
            assessmentScale.assessmentScaleGradePointPracticalId === null ||
            assessmentScale.assessmentScaleGradePointPracticalId === ""
          ) {
            error++;
          }
          let studentSubjectAssessment = {
            assessmentScalePracticalId: assessmentScale.id,
            isPracticalAbsent: assessmentScale.isPracticalAbsent
              ? assessmentScale.isPracticalAbsent
              : false,
            // assessmentScaleId: assessmentScale.id,
            assessmentScaleMarkPracticalId: assessmentScale.assessmentScaleMark
              ? assessmentScale.assessmentScaleMark.id
              : null,
            practicalObtainedMarks: assessmentScale.practicalObtainedMarks
              ? assessmentScale.practicalObtainedMarks
              : 0,
            assessmentScaleLevelPracticalId:
              assessmentScale.assessmentScaleLevelPracticalId
                ? assessmentScale.assessmentScaleLevelPracticalId
                : null,
            assessmentScaleGradePointPracticalId:
              assessmentScale.assessmentScaleGradePointPracticalId
                ? assessmentScale.assessmentScaleGradePointPracticalId
                : null,
          };
          studentSubjectAssessmentsPractical.push(studentSubjectAssessment);
        });
      } else {
        student.assessmentScalesForTheory.forEach((assessmentScale) => {
          let studentSubjectAssessment = {
            assessmentScalePracticalId: null,
            isPracticalAbsent: null,
            // assessmentScaleId: null,
            assessmentScaleMarkPracticalId: null,
            practicalObtainedMarks: null,
            assessmentScaleLevelPracticalId: null,
            assessmentScaleGradePointPracticalId: null,
          };
          studentSubjectAssessmentsPractical.push(studentSubjectAssessment);
        });
      }

      studentSubjectAssessmentParam.studentSubjectAssessmentsPractical =
        studentSubjectAssessmentsPractical;

      if (
        student &&
        student.assessmentScalesForTheory &&
        student.assessmentScalesForTheory.length > 0
      ) {
        student.assessmentScalesForTheory.forEach((assessmentScale) => {
          if (
            assessmentScale.assessmentScaleLevelTheoryId === null ||
            assessmentScale.assessmentScaleLevelTheoryId === ""
          ) {
            error++;
          }
          if (
            assessmentScale.assessmentScaleGradePointTheoryId === null ||
            assessmentScale.assessmentScaleGradePointTheoryId === ""
          ) {
            error++;
          }
          let studentSubjectAssessment = {
            assessmentScaleTheoryId: assessmentScale.id,
            isTheoryAbsent: assessmentScale.isTheoryAbsent
              ? assessmentScale.isTheoryAbsent
              : false,
            // assessmentScaleId: assessmentScale.id,
            assessmentScaleMarkTheoryId: assessmentScale.assessmentScaleMark
              ? assessmentScale.assessmentScaleMark.id
              : null,
            theoryObtainedMarks: assessmentScale.theoryObtainedMarks
              ? assessmentScale.theoryObtainedMarks
              : null,
            assessmentScaleLevelTheoryId:
              assessmentScale.assessmentScaleLevelTheoryId
                ? assessmentScale.assessmentScaleLevelTheoryId
                : null,
            assessmentScaleGradePointTheoryId:
              assessmentScale.assessmentScaleGradePointTheoryId
                ? assessmentScale.assessmentScaleGradePointTheoryId
                : null,
          };
          studentSubjectAssessmentsTheory.push(studentSubjectAssessment);
        });
      } else {
        student.assessmentScalesForPractical.forEach((assessmentScale) => {
          let studentSubjectAssessment = {
            assessmentScaleTheoryId: null,
            isTheoryAbsent: null,
            // assessmentScaleId: null,
            assessmentScaleMarkTheoryId: null,
            theoryObtainedMarks: null,
            assessmentScaleLevelTheoryId: null,
            assessmentScaleGradePointTheoryId: null,
          };
          studentSubjectAssessmentsTheory.push(studentSubjectAssessment);
        });
      }
      studentSubjectAssessmentParam.studentSubjectAssessmentsTheory =
        studentSubjectAssessmentsTheory;
      studentSubjectAssessmentsParam.push(studentSubjectAssessmentParam);
    });

    // if (error > 0) {
    //   swal({
    //     title: MESSAGEHEADER.error,
    //     text: "Assessment Scales should not be empty",
    //     allowOutsideClick: false,
    //     closeOnClickOutside: false,
    //   });
    //   draggable();
    //   return false;
    // }
    this.setState({ showSpinner: true }, () => {
      axiosPost(
        URL.insertMultipleStudentSubjectAssessments,
        studentSubjectAssessmentsParam,
        (response) => {
          if (response.status === 200) {
            swal("Success", response.data.message, "");
            axiosPost(
              URL.selectStudentSubjectAssessmentIsFinalized,
              this.state.studentClassroomParam,
              (response) => {
                if (response.status === 200) {
                  let student = [...this.state.newstudents];
                  student.forEach((stu) => {
                    stu.isCommentBoxEdited = false;
                    stu.assessmentScalesForPractical.forEach((scale) => {
                      scale.edited = false;
                    });
                    stu.assessmentScalesForTheory.forEach((scale) => {
                      scale.edited = false;
                    });
                  });

                  this.setState({ newStudents: student });
                  if (response.data?.data?.length > 0) {
                    if (response.data.data[0].isFinalized) {
                      this.setState({ finalize: true });
                    } else {
                      this.setState({ finalize: false });
                    }
                  } else {
                    this.setState({ finalize: false });
                  }
                }
              }
            );
            this.getAssessmentFinalizeClickable(
              this.state.studentClassroomParam
            );
            this.setState({ showSpinner: false });
          } else {
            swal("Error", response.data.message, "");
          }
        },
        (failure) => {
          swal({
            title: "Error",
            text: "Error submitting assessment records!",
          });
          this.setState({ showSpinner: false });
        }
      );
    });
  };

  toggleStudentsModal = (e) => {
    if (this.state.studentsModal) {
      let students = [...this.state.newstudents];
      let counter = [];
      let commentCounter = [];
      students.forEach((student) => {
        student.assessmentScalesForTheory.forEach((scale) => {
          counter.push(scale.edited);
        });
        student.assessmentScalesForPractical.forEach((scale) => {
          counter.push(scale.edited);
        });
      });

      students.forEach((stu) => {
        commentCounter.push(stu.isCommentBoxEdited);
      });
      if (counter.includes(true) || commentCounter.includes(true)) {
        swal({
          title: "Are you sure?",
          text: "Unsaved records will be lost, close anyway?",

          // icon: "warning",
          closeOnClickOutside: false,
          allowOutsideClick: false,
          buttons: true,
          dangerMode: true,
        }).then((deleteConfirm) => {
          if (deleteConfirm) {
            this.setState({ studentsModal: false });
          }
        });
        draggable();
      } else {
        this.setState({ studentsModal: !this.state.studentsModal });
      }
    } else {
      this.setState({ studentsModal: !this.state.studentsModal });
    }
  };

  handleStudentSubjectFinalizeConfirm = (e) => {
    let students = [...this.state.newstudents];
    let counter = [];
    let commentCounter = [];
    students.forEach((student) => {
      student.assessmentScalesForPractical.forEach((scale) => {
        counter.push(scale.edited);
      });
      student.assessmentScalesForTheory.forEach((scale) => {
        counter.push(scale.edited);
      });
    });

    students.forEach((stu) => {
      commentCounter.push(stu.isCommentBoxEdited);
    });

    if (counter.includes(true) || commentCounter.includes(true)) {
      swal({
        title: "Are you sure?",
        text: this.state.finalize
          ? "Are you sure, you want to unfinalize assessment ?"
          : "Unsaved records will be lost, finalize anyway?",
        closeOnClickOutside: false,
        allowOutsideClick: false,
        buttons: true,
        dangerMode: true,
      }).then((deleteConfirm) => {
        if (deleteConfirm) {
          this.handleStudentAssessmentFinalize();
        }
      });
      draggable();
    } else {
      swal({
        title: "Are you sure?",
        text: this.state.finalize
          ? "Are you sure, you want to unfinalize assessment ?"
          : "Are you sure, you want to finalize?",
        closeOnClickOutside: false,
        allowOutsideClick: false,
        buttons: true,
        dangerMode: true,
      }).then((deleteConfirm) => {
        if (deleteConfirm) {
          this.handleStudentAssessmentFinalize();
        }
      });
      draggable();
    }
  };

  handleStudentAssessmentFinalize = () => {
    let param = {
      assessmentId: this.state.studentClassroomParam.assessmentId,
      classroomId: this.state.studentClassroomParam.classroomId,
      yearLevelId: this.state.studentClassroomParam.yearLevelId,
      educationLevelId: this.state.studentClassroomParam.educationLevelId,
      educationLevelDivisionId:
        this.state.studentClassroomParam.educationLevelDivisionId,
      subjectId: this.state.studentClassroomParam.subjectId,
      isFinalized: true,
    };
    axiosPost(
      URL.updateStudentSubjectAssessmentIsFinalized,
      param,
      (response) => {
        if (response.status === 200) {
          swal(MESSAGEHEADER.success, "Assessment successfully finalized.");
          let student = [...this.state.newstudents];
          student.forEach((stu) => {
            stu.isCommentBoxEdited = false;
            stu.assessmentScalesForPractical.forEach((scale) => {
              scale.edited = false;
            });
            stu.assessmentScalesForTheory.forEach((scale) => {
              scale.edited = false;
            });
          });

          this.setState({ newStudents: student });

          this.getStudentSubjectIsFinalizedAfterFinalizUnifilze(
            this.state.studentClassroomParam
          );
          this.props.getYearLevelForPublishAssessmentReport(this.props.code);
        } else {
          showErroMessage(response);
        }
      }
    );
  };

  handleStudentSubjectUnFinalizeConfirm = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure, you want to unfinalize assessment ?",
      closeOnClickOutside: false,
      allowOutsideClick: false,
      buttons: true,
      dangerMode: true,
    }).then((deleteConfirm) => {
      if (deleteConfirm) {
        this.handleStudentAssessmentUnFinalize();
      }
    });
    draggable();
  };

  handleStudentAssessmentUnFinalize = () => {
    let param = {
      assessmentId: this.state.studentClassroomParam.assessmentId,
      classroomId: this.state.studentClassroomParam.classroomId,
      yearLevelId: this.state.studentClassroomParam.yearLevelId,
      educationLevelId: this.state.studentClassroomParam.educationLevelId,
      educationLevelDivisionId:
        this.state.studentClassroomParam.educationLevelDivisionId,
      subjectId: this.state.studentClassroomParam.subjectId,
      isFinalized: false,
    };
    axiosPost(
      URL.updateStudentSubjectAssessmentIsFinalized,
      param,
      (response) => {
        if (response.status === 200) {
          swal(MESSAGEHEADER.success, "Assessment successfully unfinalized.");

          this.getStudentSubjectIsFinalizedAfterFinalizUnifilze(
            this.state.studentClassroomParam
          );
          this.props.getYearLevelForPublishAssessmentReport(this.props.code);
        } else {
          showErroMessage(response);
        }
      }
    );
  };

  getStudentSubjectIsFinalizedAfterFinalizUnifilze = (param) => {
    axiosPost(
      URL.selectStudentSubjectAssessmentIsFinalized,
      param,
      (response) => {
        if (response.status === 200) {
          if (response.data?.data?.length > 0) {
            if (response.data.data[0].isFinalized) {
              this.setState({ finalize: true });
            } else {
              this.setState({ finalize: false });
            }
          } else {
            this.setState({ finalize: false });
          }
        }
      }
    );
  };

  getAssessmentFinalizeClickable = (param) => {
    axiosPost(
      URL.selectStudentSubjectAssessmentFinalizeClickable,
      param,
      (response) => {
        if (response.status === 200) {
          this.setState({
            finalizeClickable: response.data.data.finalizeClickable,
          });
        } else {
          showErroMessage(response);
        }
        this.getStudentSubjectIsFinalized(param);
      }
    );
  };

  getStudentSubjectIsFinalized = (param) => {
    axiosPost(
      URL.selectStudentSubjectAssessmentIsFinalized,
      param,
      (response) => {
        if (response.status === 200) {
          if (response.data?.data?.length > 0) {
            if (response.data.data[0].isFinalized) {
              this.setState({ finalize: true });
            } else {
              this.setState({ finalize: false });
            }
          } else {
            this.setState({ finalize: false });
          }
          this.toggleStudentsModal();
        }
      }
    );
  };

  //Done By Sandeep Shakya
  handleIsAbsentForTheory = (e, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForTheory = students[idx].assessmentScalesForTheory,
      element = e.target;
    let assessmentScale = assessmentScalesForTheory[i],
      value = element.checked;
    assessmentScale.isTheoryAbsent = value;
    if (assessmentScale.assessmentScaleTypeCode == scaleTypeCode.level) {
      assessmentScale.assessmentScaleLevelTheoryId = "";
    }
    if (assessmentScale.assessmentScaleTypeCode == scaleTypeCode.mark) {
      assessmentScale.theoryObtainedMarks = 0;
      assessmentScale.theoryObtainedValue = 0;
    }
    if (assessmentScale.assessmentScaleTypeCode == scaleTypeCode.gradePoint) {
      assessmentScale.assessmentScaleGradePointTheoryId = "";
    }
    students[idx].assessmentScalesForTheory[i].edited = true;
    this.setState({ newstudents: students });
  };

  handleIsAbsentForPractical = (e, idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForPractical =
        students[idx].assessmentScalesForPractical,
      element = e.target;
    let assessmentScale = assessmentScalesForPractical[i],
      value = element.checked;
    assessmentScale.isPracticalAbsent = value;
    if (assessmentScale.assessmentScaleTypeCode == scaleTypeCode.level) {
      assessmentScale.assessmentScaleLevelPracticalId = "";
    }
    if (assessmentScale.assessmentScaleTypeCode == scaleTypeCode.mark) {
      assessmentScale.practicalObtainedMarks = 0;
      assessmentScale.practicalObtainedValue = 0;
    }
    if (assessmentScale.assessmentScaleTypeCode == scaleTypeCode.gradePoint) {
      assessmentScale.assessmentScaleGradePointPracticalId = "";
    }

    students[idx].assessmentScalesForPractical[i].edited = true;
    this.setState({ newstudents: students });
  };

  increaseValue = (idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForTheory = students[idx].assessmentScalesForTheory;
    let assessmentScale = assessmentScalesForTheory[i];
    let count = assessmentScale.theoryObtainedMarks;
    if (count.length > 4) {
      swal("Warning", "Maximum one decimal point is allowed");
    } else if (parseInt(count) > assessmentScale.theoryMax - 1) {
      swal("Warning", "Input marks is invalid");
    } else {
      if (assessmentScale) {
        assessmentScale.theoryObtainedMarks = parseInt(count) + 1;
        students[idx].assessmentScalesForTheory[i].edited = true;
      } else {
        return;
      }
      this.setState({ newstudents: students });
    }
  };

  decreaseValue = (idx, i) => {
    let students = [...this.state.newstudents];
    let assessmentScalesForTheory = students[idx].assessmentScalesForTheory;
    let assessmentScale = assessmentScalesForTheory[i];
    let count = assessmentScale.theoryObtainedMarks;
    if (count.length > 4) {
      swal("Warning", "Maximum one decimal point is allowed");
    } else if (
      parseInt(count) > assessmentScale.theoryMax ||
      parseInt(count) < 1
    ) {
      swal("Warning", "Input marks is invalid");
    } else {
      if (assessmentScale) {
        assessmentScale.theoryObtainedMarks = parseInt(count) - 1;
        students[idx].assessmentScalesForTheory[i].edited = true;
      } else {
        return;
      }
      this.setState({ newstudents: students });
    }
  };

  //Render Function
  render() {
    return (
      <div style={{ margin: "0 5px" }}>
        {this.props.highlighted ? (
          <div
            onClick={
              this.props.name === "classrooms"
                ? (e) =>
                    this.props.handleClassroomToggle(
                      this.props.classroomIdx,
                      this.props.idObject
                    )
                : this.props.name === "assessments"
                ? (e) =>
                    this.props.handleAssessmentCollapse(
                      this.props.assessmentIdx,
                      this.props.updatedIdObject
                    )
                : this.toggleCollapse
            }
          >
            <HighlightedHeader
              classroomHeader={this.props.classroomHeader}
              recorded={this.props.recorded}
              classrooms={this.props.classrooms}
              assessments={this.props.assessments}
              classIndex={this.props.classroomIdx + 1}
              assessmentIdx={this.props.assessmentIdx + 1}
              isClassroomOpen={this.props.isClassroomOpen}
              isAssessmentOpen={this.props.isAssessmentOpen}
              isStudentOpen={this.props.isStudentOpen}
              isSubjectOpen={this.props.isSubjectOpen}
              classroomIdx={this.props.classroomIdx}
            />
          </div>
        ) : (
          <div
            onClick={
              this.props.name === "yearLevels"
                ? (e) => {
                    this.props.yearLevelToggle(this.props.yearLvlIdx);
                    this.getClassrooms(this.props.yearLevelId);
                  }
                : this.props.name === "subjects"
                ? (e) => {
                    this.getScaleAndConfiguration();
                    this.props.handleSubjectCollapse(
                      this.props.subjectIdx,
                      this.props.studentClassroom
                    );
                  }
                : this.props.name === "student"
                ? (e) => {
                    this.getStudentNameValuePair(this.props.student.people.id);
                  }
                : this.toggleCollapse
            }
            className="tt-group-header text-left"
          >
            <div className="row">
              <div className="col">
                {/* {this.props.name === "yearLevels"
                  ? localStorage.getItem("yearLevel") + " "
                  : null} */}
                {this.props.header}
              </div>
              {this.props.name === "yearLevels" ? (
                <div className="col-md-1 text-center">
                  <FontAwesomeIcon
                    icon={this.props.isOpen ? faAngleUp : faAngleDown}
                  ></FontAwesomeIcon>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {this.props.yearLevelId ? (
          <CustomCollapse
            isOpen={this.props.isYearLevelOpen}
            collapsibleBody={
              this.state.classrooms
                ? this.state.classrooms.map((classroom, idx) => {
                    return this.props.assignedStudents ? (
                      <SubjectAssessmentCollapsible
                        key={idx}
                        header={classroom.name}
                        collapsibleBody={"I am here"}
                      />
                    ) : (
                      <SubjectAssessmentCollapsible
                        classroomIdx={idx}
                        key={idx}
                        highlighted={true}
                        recorded={true}
                        classroomHeader={
                          // localStorage.getItem("classroom") +
                          // " " +
                          classroom.name
                        }
                        idObject={{
                          ...this.props.idObject,
                          classroomId: classroom.id,
                        }}
                        name="classrooms"
                        isClassroomOpen={classroom.isClassroomOpen}
                        handleClassroomToggle={this.handleClassroomToggle}
                        assessments={this.props.assessments}
                        subjects={this.props.subjects}
                        updateAssessmentWithScaleOrComment={
                          this.props.updateAssessmentWithScaleOrComment
                        }
                        getAssessmentSubjectList={
                          this.props.getAssessmentSubjectList
                        }
                        updateAssessmentSubjectList={
                          this.props.updateAssessmentSubjectList
                        }
                        newstudents={this.state.newstudents}
                        getStudentList={this.props.getStudentList}
                        updateStudentList={this.props.updateStudentList}
                        classrooms={this.state.classrooms}
                        classroomName={classroom.name}
                        yearLevelName={this.props.yearLevelName}
                        code={this.props.code}
                        getYearLevelForPublishAssessmentReport={
                          this.props.getYearLevelForPublishAssessmentReport
                        }
                        widgetName={this.props.widgetName}
                      />
                    );
                  })
                : null
            }
          />
        ) : this.props.idObject ? (
          <CustomCollapse
            isOpen={this.props.isClassroomOpen}
            collapsibleBody={
              this.props.assessments && this.props.assessments.length > 0
                ? this.props.assessments.map((assessment, idx) => {
                    return (
                      <SubjectAssessmentCollapsible
                        highlighted={true}
                        recorded={false}
                        classroomHeader={`${assessment.assessmentName} (${assessment.educationLevelDivisionName})`}
                        assessmentIdx={idx}
                        key={idx}
                        updatedIdObject={{
                          ...this.props.idObject,
                          educationLevelDivisionId:
                            assessment.educationLevelDivisionId,
                          assessmentId: assessment.id,
                        }}
                        name="assessments"
                        handleAssessmentCollapse={this.handleAssessmentCollapse}
                        collapsibleBody={"There will be something good"}
                        subjects={this.props.subjects}
                        isAssessmentOpen={assessment.isAssessmentOpen}
                        getAssessmentSubjectList={
                          this.props.getAssessmentSubjectList
                        }
                        updateAssessmentSubjectList={
                          this.props.updateAssessmentSubjectList
                        }
                        newstudents={this.state.newstudents}
                        getStudentList={this.props.getStudentList}
                        updateStudentList={this.props.updateStudentList}
                        assessments={this.props.assessments}
                        classroomName={this.props.classroomName}
                        assessmentName={`${assessment.assessmentName} (${assessment.educationLevelDivisionName})`}
                        yearLevelName={this.props.yearLevelName}
                        code={this.props.code}
                        getYearLevelForPublishAssessmentReport={
                          this.props.getYearLevelForPublishAssessmentReport
                        }
                        widgetName={this.props.widgetName}
                      />
                    );
                  })
                : "No Datas"
            }
          />
        ) : this.props.updatedIdObject ? (
          <CustomCollapse
            isOpen={this.props.isAssessmentOpen}
            collapsibleBody={
              this.props.subjects && this.props.subjects.length > 0
                ? this.props.subjects.map((subject, idx) => {
                    return (
                      <SubjectAssessmentCollapsible
                        header={
                          <div className="tt-student-list">
                            <span className="tt-student-list-header">
                              {subject.name}
                            </span>
                            <span className="tt-general-assessment-subject-list-header">
                              {idx + 1} of {this.props.subjects.length} Records
                            </span>
                          </div>
                        }
                        subjectIdx={idx}
                        key={idx}
                        studentClassroom={{
                          ...this.props.updatedIdObject,
                          subjectId: subject.id,
                        }}
                        subjects={this.props.subjects}
                        name={"subjects"}
                        updateAssessmentSubjectList={
                          this.props.updateAssessmentSubjectList
                        }
                        handleSubjectCollapse={this.handleSubjectCollapse}
                        isSubjectOpen={subject.isSubjectOpen}
                        newstudents={this.state.newstudents}
                        getStudentList={this.props.getStudentList}
                        updateStudentList={this.props.updateStudentList}
                        classroomName={this.props.classroomName}
                        assessmentName={this.props.assessmentName}
                        yearLevelName={this.props.yearLevelName}
                        code={this.props.code}
                        getYearLevelForPublishAssessmentReport={
                          this.props.getYearLevelForPublishAssessmentReport
                        }
                        widgetName={this.props.widgetName}
                      />
                    );
                  })
                : "No Data"
            }
          />
        ) : this.props.studentClassroom ? null : this.props.student ? (
          <CustomCollapse
            isOpen={this.props.isStudentOpen}
            collapsibleBody={
              <StudentSubjectAssessmentReport
                assessmentScaleParam={this.props.assessmentScaleParam}
                student={this.props.student}
                assessmentScales={this.state.assessmentScales}
                assessmentSubjectComment={this.state.assessmentSubjectComment}
                studentNameValuePair={this.state.studentNameValuePair}
                commentText={this.state.commentText}
                handleCommentTextChange={this.handleCommentTextChange}
                handleCommentAfterReplacement={
                  this.handleCommentAfterReplacement
                }
                handleObtainedMarksIncrementDecrement={
                  this.handleObtainedMarksIncrementDecrement
                }
                handleAssessmentScaleLevelChange={
                  this.handleAssessmentScaleLevelChange
                }
                handleAssessmentScaleGradePointChange={
                  this.handleAssessmentScaleGradePointChange
                }
                handleSubmit={this.handleSubmit}
                handleInsertComment={this.handleInsertComment}
              />
            }
          />
        ) : (
          <CustomCollapse
            isOpen={this.state.collapsibleState}
            collapsibleBody={this.props.collapsibleBody}
          />
        )}

        <ModalWindow
          modal={this.state.studentsModal}
          toggleModal={this.toggleStudentsModal}
          size="xl"
          modalHeader={
            this.props.classroomName &&
            this.props.assessmentName &&
            this.props.yearLevelName
              ? this.state.selectedSubject
                ? this.props.assessmentName +
                  " : " +
                  // localStorage.getItem("yearLevel") +
                  // " " +
                  this.props.yearLevelName +
                  // " : " +
                  // localStorage.getItem("classroom") +
                  " " +
                  this.props.classroomName +
                  " : " +
                  this.state.selectedSubject.name
                : "Subject Name"
              : null
          }
          modalBody={
            <StudentSubjectReport
              key={this.state.refresh}
              students={this.state.newstudents}
              studentNameValuePair={this.state.studentNameValuePair}
              studentClassroom={this.props.studentClassroom}
              assessmentSubjectComment={this.state.assessmentSubjectComment}
              handleObtainedMarksIncrementDecrement={
                this.handleObtainedMarksIncrementDecrement
              }
              handleAssessmentScaleForPracticalLevelChange={
                this.handleAssessmentScaleForPracticalLevelChange
              }
              handleAssessmentScaleForTheoryLevelChange={
                this.handleAssessmentScaleForTheoryLevelChange
              }
              handleAssessmentScaleGradePointChange={
                this.handleAssessmentScaleGradePointChange
              }
              handleAssessmentScaleTheoryGradePointChange={
                this.handleAssessmentScaleTheoryGradePointChange
              }
              handleAssessmentScalePracticalGradePointChange={
                this.handleAssessmentScalePracticalGradePointChange
              }
              handleSubmit={this.handleSubmit}
              refreshState={this.refreshState}
              practicalCounterHandler={this.practicalCounterHandler}
              theoryCounterHandler={this.theoryCounterHandler}
              handleInsertComment={this.handleInsertComment}
              commentText={this.state.commentText}
              handleCommentTextChange={this.handleCommentTextChange}
              handleStudentCommentChange={this.handleStudentCommentChange}
              getStudentNameValuePair={this.getStudentNameValuePair}
              studentClassroomParam={this.state.studentClassroomParam}
              handleStudentSubjectFinalizeConfirm={
                this.handleStudentSubjectFinalizeConfirm
              }
              handleStudentSubjectUnFinalizeConfirm={
                this.handleStudentSubjectUnFinalizeConfirm
              }
              finalize={this.state.finalize}
              finalizeClickable={this.state.finalizeClickable}
              showSpinner={this.state.showSpinner}
              isOpen={this.state.studentsModal}
              widgetName={this.props.widgetName}
              handleIsAbsentForTheory={this.handleIsAbsentForTheory}
              handleIsAbsentForPractical={this.handleIsAbsentForPractical}
              decreaseValue={this.decreaseValue}
              increaseValue={this.increaseValue}
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assessments: state.primaryArr.assessmentWithScaleOrComment,
  subjects: state.primaryArr.assessmentSubjectList,
});

const mapActionToProps = {
  getAssessmentWithScaleOrComment,
  updateAssessmentWithScaleOrComment,
  getAssessmentSubjectList,
  updateAssessmentSubjectList,
  getStudentList,
  updateStudentList,
  getYearLevelForPublishAssessmentReport,
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(SubjectAssessmentCollapsible);
