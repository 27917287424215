import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { postType } from "../../../../utils/Constants";
import ArticleHistory from "./ArticleHistory";
import MediaHistory from "./MediaHistory";
import NoticeHistory from "./NoticeHistory";

class HistoryTemplates extends Component {
  state = {};
  render() {
    const settings = {
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
    };
    let history = this.props.postHistory;
    return history.length > 0 ? (
      <div className="tt-widgetContent-tab-holder" style={{ height: "87.5vh" }}>
        <div className="row">
          <div className="col">
            <Slider {...settings}>
              {history.map((post, idx) => {
                if (post.postType === postType.mediaCode) {
                  return <MediaHistory history={post}></MediaHistory>;
                }
                if (post.postType === postType.articleCode) {
                  return <ArticleHistory history={post}></ArticleHistory>;
                }
                if (post.postType === postType.noticeCode) {
                  return <NoticeHistory history={post}></NoticeHistory>;
                }
              })}
            </Slider>
          </div>
        </div>
      </div>
    ) : (
      <div className="text-center">No history</div>
    );
  }
}

export default HistoryTemplates;
