import React, { Component } from "react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class GpaForm extends Component {
  state = {};
  handleKeyPress = (evt) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };
  render() {
    return (
      <div className="tt-gpa-form container-fluid" style={{ width: "95%" }}>
        {this.props.multipleGpa.map((gpa, idx) => {
          let gradeId = `grade-${idx}`,
            perMinId = `perMin-${idx}`;
          return (
            <div className="row" key={idx}>
              <div className="col-md-11">
                <div className="row" key={idx} style={{ paddingTop: "5px" }}>
                  <div className="col-md-2">
                    {idx === 0 ? (
                      <label>
                        <strong>Grade</strong>
                      </label>
                    ) : null}
                    <select
                      className={
                        gpa.errors
                          ? gpa.errors.grade
                            ? "form-control indicate-error"
                            : "form-control ttInput-field-dropdown ttInput-field"
                          : "form-control ttInput-field-dropdown ttInput-field"
                      }
                      value={gpa.grade}
                      name="grade"
                      onChange={(e) => this.props.multipleGpaChange(e, idx)}
                      data-id={idx}
                      id={gradeId}
                    >
                      <option value="" disabled>
                        Choose Grade
                      </option>
                      {this.props.gradeTypes !== undefined
                        ? this.props.gradeTypes.map((grade, i) => (
                            <option
                              key={i}
                              value={grade}
                              disabled={this.props.multipleGpa.some(
                                (item) => item.grade === grade
                              )}
                            >
                              {grade}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="col-md-2">
                    {idx === 0 ? (
                      <label>
                        <strong>Percentage Min</strong>
                      </label>
                    ) : null}

                    <input
                      type="number"
                      className={
                        gpa.errors
                          ? gpa.errors.percentageMin
                            ? "form-control indicate-error"
                            : "form-control"
                          : "form-control"
                      }
                      value={gpa.percentageMin}
                      name="percentageMin"
                      onChange={(e) => this.props.multipleGpaChange(e, idx)}
                      data-id={idx}
                      id={perMinId}
                      min="0"
                      onKeyPress={this.handleKeyPress}
                    />
                  </div>
                  <div className="col-md-2">
                    {idx === 0 ? (
                      <label>
                        <strong>Percentage Max</strong>
                      </label>
                    ) : null}

                    <input
                      type="number"
                      className={
                        gpa.errors
                          ? gpa.errors.percentageMax
                            ? "form-control indicate-error"
                            : "form-control"
                          : "form-control"
                      }
                      value={gpa.percentageMax}
                      name="percentageMax"
                      onChange={(e) => this.props.multipleGpaChange(e, idx)}
                      data-id={idx}
                      min="0"
                      onKeyPress={this.handleKeyPress}
                    />
                  </div>

                  <div className="col-md-2">
                    {idx === 0 ? (
                      <label>
                        <strong>Grade Point</strong>
                      </label>
                    ) : null}
                    <input
                      step="0.01"
                      type="number"
                      className={
                        gpa.errors
                          ? gpa.errors.gradePoint
                            ? "form-control indicate-error"
                            : "form-control"
                          : "form-control"
                      }
                      value={gpa.gradePoint}
                      name="gradePoint"
                      onChange={(e) => this.props.multipleGpaChange(e, idx)}
                      data-id={idx}
                      min="0"
                      onKeyPress={this.handleKeyPress}
                    />
                  </div>

                  <div className="col-md-2">
                    {idx === 0 ? (
                      <label>
                        <strong>Grade Point Min</strong>
                      </label>
                    ) : null}
                    <input
                      step="0.01"
                      type="number"
                      className={
                        gpa.errors
                          ? gpa.errors.gpaMin
                            ? "form-control indicate-error"
                            : "form-control"
                          : "form-control"
                      }
                      value={gpa.gpaMin}
                      name="gpaMin"
                      onChange={(e) => this.props.multipleGpaChange(e, idx)}
                      data-id={idx}
                      min="0"
                      onKeyPress={this.handleKeyPress}
                    />
                  </div>
                  <div className="col-md-2">
                    {idx === 0 ? (
                      <label>
                        <strong>Grade Point Max</strong>
                      </label>
                    ) : null}
                    <input
                      step="0.01"
                      type="number"
                      className={
                        gpa.errors
                          ? gpa.errors.gpaMax
                            ? "form-control indicate-error"
                            : "form-control"
                          : "form-control"
                      }
                      value={gpa.gpaMax}
                      name="gpaMax"
                      onChange={(e) => this.props.multipleGpaChange(e, idx)}
                      data-id={idx}
                      min="0"
                      onKeyPress={this.handleKeyPress}
                    />
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "5px" }}>
                  <div className="col-md-12">
                    {idx === 0 ? (
                      <label>
                        <strong>Grade Evaluation</strong>
                      </label>
                    ) : null}
                    <input
                      type="text"
                      className={
                        gpa.errors
                          ? gpa.errors.gpaEvaluation
                            ? "form-control indicate-error"
                            : "form-control"
                          : "form-control"
                      }
                      value={gpa.gpaEvaluation}
                      name="gpaEvaluation"
                      onChange={(e) => this.props.multipleGpaChange(e, idx)}
                      data-id={idx}
                      maxLength={40}
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-md-1 text-center"
                style={{ paddingTop: "5px" }}
              >
                {idx === 0 ? (
                  <label>
                    <strong>Remove</strong>
                  </label>
                ) : null}
                <span
                  className="tt-added-gpa-remove"
                  onClick={() => this.props.handleRemoveItemFromForm(idx)}
                >
                  <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                </span>
              </div>
            </div>
          );
        })}
        <div className="row mt-2 mb-2">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-2"
              onClick={this.props.addMultipleGpa}
            >
              Add
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.props.handleSaveNewGpa}
              // disabled={this.props.multipleGpa["perMin"] !== ""}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default GpaForm;
