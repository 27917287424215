import React, { Component } from "react";

class LevelScaleDropDown extends Component {
  state = {};
  render() {
    return (
      <div className="row">
        <div className="col">
          <label htmlFor="indicatorDescriptorScales">
            <strong>{this.props.label}</strong>
          </label>
          {/* <input type="text" className="form-control" /> */}
          <select
            multiple
            name="scaleName"
            className="form-control"
            value={this.props.scaleName}
            onChange={this.props.multipleScaleNameChange}
          >
            {this.props.levelScales.length > 0 ? null : (
              <option value="" disabled>
                No Level scales assigned. Please assign it first.
              </option>
            )}
            {this.props.multipleScaleNameChange !== undefined &&
            this.props.levelScales.length > 0
              ? this.props.levelScales.map((level, i) => (
                  <option value={level.id} key={i}>
                    {level.scaleName}
                  </option>
                ))
              : null}
          </select>
        </div>
      </div>
    );
  }
}

export default LevelScaleDropDown;
