import moment from "moment";
import React, { Component } from "react";

import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomPageHeader from "../../components/common/CustomPageHeader";

class SchoolStatistics extends Component {
  state = {
    provinceId: "",
    districtId: "",
    cityId: "",
    address: "",
    fromDate: "",
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    provinces: [],
    districts: [],
    cities: [],
    spinner: false,
    schools: [],
  };

  componentDidMount() {
    this.getProvinces();
    this.getSchools();
  }
  getProvinces() {
    const Params = {
      countryId: 147,
    };
    axiosPost(URL.getCountryFirstLevel, Params, (response) => {
      if (response.status == 200) {
        this.setState({ provinces: response.data.data });
      }
    });
  }
  handleProvinceChange = (e) => {
    let param = {
      countryId: 147,
      countryFirstLevelId: e.target.value,
    };
    axiosPost(URL.getCountrySecondLevel, param, (response) => {
      if (response.status == 200) {
        this.setState({ districts: response.data.data });
      }
    });
    this.setState({ provinceId: param.countryFirstLevelId });
  };
  handleDistrictChange = (e) => {
    let param = {
      countryId: 147,
      countryFirstLevelId: this.state.provinceId,
      countrySecondLevelId: e.target.value,
    };
    axiosPost(URL.getCountryThirdLevel, param, (response) => {
      if (response.status == 200) {
        this.setState({ cities: response.data.data });
      }
    });
    this.setState({ districtId: param.districtId });
  };
  getSchools = () => {
    let param = {
      provinceId: this.state.provinceId,
      districtId: this.state.districtId,
      cityId: this.state.cityId,
      address: this.state.address,
      searchParam: {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      },
    };
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.getSchoolByGeography,
        param,
        (response) => {
          this.setState({ spinner: false });
          if (response.status === 200) {
            let data = response.data.data;
            this.setState({ schools: data });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };
  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };
  clearSearchParam = (e) => {
    this.setState({
      fromDate: "",
      toDate: "",
      address: "",
      provinceId: "",
      districtId: "",
      cityId: "",
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader title="School Statistics" />
              <div className="cotainer">
                <div className="row">
                  <div className="col-md-4">
                    <p className="labelTitle mb-1">From Date</p>
                    <input
                      type="date"
                      name="fromDate"
                      value={this.state.fromDate}
                      onChange={this.handleChange}
                      className="form-control cusInput"
                      placeholder="Enter From Date"
                    />
                  </div>
                  <div className="col-md-4">
                    <p className="labelTitle mb-1">To Date</p>
                    <input
                      type="date"
                      name="toDate"
                      value={this.state.toDate}
                      onChange={this.handleChange}
                      className="form-control cusInput"
                      placeholder="Enter To Date"
                    />
                  </div>

                  <div className="col">
                    <p className="labelTitle mb-1">Address</p>
                    <input
                      type="text"
                      name="address"
                      value={this.state.address}
                      onChange={this.handleChange}
                      className="form-control cusInput"
                      placeholder="Enter Address"
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    {/* <p className="courseTitle mb-1">Province</p> */}
                    <select
                      name="provinceId"
                      value={this.state.provinceId}
                      onChange={this.handleProvinceChange}
                      className="form-control cusInputSelect"
                    >
                      <option value="" selected disabled>
                        Choose a Province
                      </option>
                      {this.state.provinces
                        ? this.state.provinces.map((province, idx) => {
                            return (
                              <option key={idx} value={province.id}>
                                {province.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="col-md-4">
                    {/* <p className="courseTitle mb-1">District:</p> */}
                    <select
                      name="districtId"
                      value={this.state.districtId}
                      onChange={this.handleDistrictChange}
                      className="form-control cusInputSelect"
                    >
                      <option value="" selected disabled>
                        Choose a District
                      </option>
                      {this.state.districts
                        ? this.state.districts.map((district, idx) => {
                            return (
                              <option key={idx} value={district.id}>
                                {district.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="col">
                    {/* <p className="courseTitle mb-1">City</p> */}
                    <select
                      name="cityId"
                      value={this.state.cityId}
                      onChange={this.handleChange}
                      className="form-control cusInputSelect"
                    >
                      <option value="" selected disabled>
                        Choose a City
                      </option>
                      {this.state.cities
                        ? this.state.cities.map((city, idx) => {
                            return (
                              <option key={idx} value={city.id}>
                                {city.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col text-end">
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize"
                      onClick={this.clearSearchParam}
                    >
                      Clear
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-primary cusBtn cusBtnFontSize"
                      onClick={this.getSchools}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="searchBar">
                <span>
                  {this.state.schools ? this.state.schools.length : 0}
                </span>{" "}
                records found in total.
              </div>
              <table className="table cusTable">
                <thead>
                  <tr>
                    <th>School Name</th>
                    <th>Total Students</th>
                    <th>Total Parents</th>
                    <th>Total Staffs</th>
                    <th>Established On</th>
                    <th>Contact Details</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.schools.length > 0
                    ? this.state.schools.map((school, index) => {
                        return (
                          <tr>
                            <td>{school.name}</td>
                            <td>{school.studentCount}</td>
                            <td>{school.parentCount}</td>
                            <td>{school.staffCount}</td>
                            <td>
                              {school.establishedOn
                                ? moment(school.establishedOn).format("LL")
                                : "-"}
                            </td>
                            <td>{school.contactEmail}</td>
                          </tr>
                        );
                      })
                    : "No schools found"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SchoolStatistics;
