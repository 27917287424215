import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import "../../assets/scss/groupPage.scss";
import { axiosPost } from "../../utils/AxiosApi";
import {
  URL,
  checkRolePermissions,
  draggable,
  rowCountLimit,
} from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";
import GroupMembersConfig from "../AcademicAdministration/Groups/GroupMembersConfig";
import GroupEditMember from "../AcademicAdministration/Groups/GroupEditMember";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPersonProfilePosts } from "./../../ducks/PostsDucks";
import userImage from "../../assets/images/user-image.png";
import { Spinner } from "reactstrap";
import {
  displayErrorAlert,
  displayConfirmDeleteAlert,
} from "../../utils/Utils";

class GroupMemeberLists extends Component {
  state = {
    minimize: false,
    modal: false,
    editModal: false,
    groupId: "",
    membersTitle: "",
    groupDetail: [],
    staffs: [],
    selectedStaff: [],
    staffType: [],
    groupRoles: [],
    yearLevels: "",
    staffTypeId: "",
    classroomList: [],
    selectYearLevel: "",
    selectedStudent: [],
    assignedStudentsList: "",
    selectClassroom: "",
    groupData: [],
    offset: 0,
    rowCountLimit: 20,
    currentPage: 1,
    totalRecordsCount: 0,
    editMemberGroupSpinner: false,
    showSpinner: false,
    permissionInsertGroupMembers: false,
    permissionEditGroupMembers: false,
  };

  componentDidMount() {
    // this.setState({ groupMembers: this.props.groupMembers });
    this.getStaffType();
    this.getYearLevels();
    this.getGroupRoles();
    this.refs.iScroll.addEventListener("scroll", () => {
      if (
        this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >=
        this.refs.iScroll.scrollHeight
      ) {
        this.props.loadMoreItems();
      }
    });
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionInsertGroupMembers: checkRolePermissions(
        "insert-group-members",
        "activity"
      ),
      permissionEditGroupMembers: checkRolePermissions(
        "edit-group-members",
        "activity"
      ),
    });
  };

  getStaffType = () => {
    axiosPost(URL.getStaffType, {}, (response) => {
      if (response.status === 200) {
        let staffType = response.data.data;
        this.setState({
          staffType,
        });
      }
    });
  };

  getGroupRoles = () => {
    axiosPost(URL.getGroupRoles, {}, (response) => {
      if (response.status === 200) {
        this.setState({ groupRoles: response.data.data });
      }
    });
  };

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  minimizeMemberContainer = () => {
    this.setState({ minimize: !this.state.minimize });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleEditModal = () => {
    this.props.loadGroupMembers();
    this.setState({ editModal: !this.state.editModal, selectedGroupRole: "" });
  };

  setGroupId = (e, id, action) => {
    this.setState({ groupId: id }, function () {
      if (action === "open") {
        this.getGroupMemberList("open");
      }
    });
  };

  getStaffList = () => {
    let data = {
      groupId: this.props.groupData.id,
      staffTypeId: this.state.staffTypeId,
    };
    axiosPost(URL.getUnassignedStaffInGroup, data, (response) => {
      if (response.status === 200) {
        const staffs = response.data.data;
        let groupStaffs = [];
        if (this.state.selectedStaff.length > 0) {
          let selected = [...this.state.selectedStaff];
          let trimmedStaffs = [];
          staffs.forEach((el) => {
            let count = 0;
            if (el.people !== null) {
              for (let i = 0; i < selected.length; i++) {
                if (selected[i].peopleId === el.peopleId) {
                  count++;
                }
              }
              if (count === 0) {
                trimmedStaffs.push(el);
              }
            }
          });
          trimmedStaffs.forEach((el) => {
            el.checked = false;
          });
          this.setState({ staffs: trimmedStaffs });
        } else {
          staffs.forEach((el) => {
            if (el.people !== null) {
              el.checked = false;
              groupStaffs.push(el);
            }
          });
          this.setState({ staffs: groupStaffs });
        }
      }
    });
  };

  getAssignedStudents = () => {
    let data = {
      groupId: this.state.groupId,
      yearLevelId: this.state.selectYearLevel,
      classroomId: this.state.selectClassroom,
    };
    axiosPost(URL.getUnassignedstudentInGroup, data, (response) => {
      if (response.status === 200) {
        let assignedStudentsList = response.data.data;
        let groupStudents = [];
        if (this.state.selectedStudent.length > 0) {
          let selected = [...this.state.selectedStudent];
          let trimmedStudents = [];
          assignedStudentsList.forEach((el) => {
            let count = 0;
            if (el.people !== null) {
              for (let i = 0; i < selected.length; i++) {
                if (selected[i].people.id === el.people.id) {
                  count++;
                }
              }
              if (count === 0) {
                trimmedStudents.push(el);
              }
            }
          });
          trimmedStudents.forEach((el) => {
            el.checked = false;
          });
          this.setState({ assignedStudentsList: trimmedStudents });
        } else {
          assignedStudentsList.forEach((el) => {
            if (el.people !== null) {
              el.checked = false;
              groupStudents.push(el);
            }
          });
          this.setState({ assignedStudentsList: groupStudents });
        }
      }
    });
  };

  getGroupMemberList = (action) => {
    let data = {
      id: this.state.groupId,
      offset: this.state.offset,
      rowCountLimit: this.state.rowCountLimit,
    };
    this.setState(
      { editMemberGroupSpinner: true, groupDetail: [] },
      function () {
        this.getGroupMembers(data, action);
      }
    );
  };

  getGroupMembers = (data, action) => {
    axiosPost(
      URL.getGroupSelectByKey,
      data,
      (response) => {
        if (response.status === 200) {
          let totalRecordsCount = response.data.totalRecordsCount;
          let groupDetail = response.data.data;
          groupDetail.groupPeople.forEach((el) => {
            el.editable = false;
          });
          this.setState(
            { groupDetail, totalRecordsCount, editMemberGroupSpinner: false },
            function () {
              if (action === "open") {
                this.toggleEditModal();
              }
            }
          );
        }
      },
      (error) => {
        displayErrorAlert(error);
        this.setState({ editMemberGroupSpinner: false });
      }
    );
  };

  handlePageChange = (page) => {
    this.reRenderGroupMemberList(page);
    this.setState({ currentPage: page });
  };

  reRenderGroupMemberList = (page) => {
    let offset =
      page * parseInt(this.state.rowCountLimit) -
      parseInt(this.state.rowCountLimit);
    this.setState(
      { editMemberGroupSpinner: true, groupDetail: [], offset: offset },
      function () {
        let data = {
          id: this.state.groupId,
          offset: this.state.offset,
          rowCountLimit: this.state.rowCountLimit,
        };
        this.getGroupMembers(data);
      }
    );
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1)
      this.reRenderGroupMemberList(this.state.currentPage - 1);
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.totalRecordsCount / this.state.rowCountLimit)
    )
      return;
    else {
      this.reRenderGroupMemberList(nextPage);
      this.setState({ currentPage: nextPage });
    }
  };

  getClassroomList = () => {
    axiosPost(
      URL.selectClassroom,
      {
        yearLevelId: this.state.selectYearLevel,
      },
      (response) => {
        if (response.status === 200) {
          this.setState({ classroomList: response.data.data });
        }
      }
    );
  };

  handleYearLevelChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState(
      { [name]: value, selectClassroom: "", assignedStudentsList: "" },
      function () {
        this.getClassroomList();
      }
    );
  };

  handleClassroomChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, function () {
      this.getAssignedStudents();
    });
  };

  //Assign member function for Staff

  handleStaffChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, function () {
      this.getStaffList();
    });
  };

  handleStaffCheckbox = (e, idx) => {
    let staffs = [...this.state.staffs];
    let selected = [...this.state.selectedStaff];
    if (idx === "all") {
      staffs.forEach((el) => {
        el.checked = e.target.checked;
      });
      this.setState({ allStaffChecked: false });
    } else {
      staffs[idx].checked = e.target.checked;
    }
    staffs.forEach((el) => {
      if (el.checked === true) {
        selected.push(el);
      }
    });
    if (idx === "all") {
      staffs = [];
    } else {
      staffs.splice(idx, 1);
    }
    selected.forEach((el) => {
      el.assignedRole = "";
      el.groupRoleName = "";
      el.checked = false;
    });
    this.setState({ staffs: staffs, selectedStaff: selected });
  };

  handleStudentCheckbox = (e, idx) => {
    let assignedStudentsList = [...this.state.assignedStudentsList];
    let selected = [...this.state.selectedStudent];
    if (idx === "all") {
      assignedStudentsList.forEach((el) => {
        el.checked = e.target.checked;
      });
      this.setState({ allStudentChecked: false });
    } else {
      assignedStudentsList[idx].checked = e.target.checked;
    }
    assignedStudentsList.forEach((el) => {
      if (el.checked === true) {
        selected.push(el);
      }
    });
    if (idx === "all") {
      assignedStudentsList = [];
    } else {
      assignedStudentsList.splice(idx, 1);
    }
    selected.forEach((el) => {
      el.assignedRole = "";
      el.groupRoleName = "";
      el.checked = false;
    });
    this.setState({
      assignedStudentsList: assignedStudentsList,
      selectedStudent: selected,
    });
  };

  handleSelectedMemberCheckbox = (e, idx, member) => {
    if (member === "Staff") {
      let selectedStaff = [...this.state.selectedStaff];
      if (idx === "all") {
        selectedStaff.forEach((el) => {
          el.checked = e.target.checked;
        });
      } else {
        selectedStaff[idx].checked = e.target.checked;
      }
      this.setState({
        selectedStaff: selectedStaff,
      });
    } else {
      let selectedStudent = [...this.state.selectedStudent];
      if (idx === "all") {
        selectedStudent.forEach((el) => {
          el.checked = e.target.checked;
        });
      } else {
        selectedStudent[idx].checked = e.target.checked;
      }
      this.setState({
        selectedStudent: selectedStudent,
      });
    }
  };

  //Assign Member removal function
  removeSelectedStaff = (e, idx) => {
    e.preventDefault();
    let selectedStaff = [...this.state.selectedStaff];
    selectedStaff.splice(idx, 1);
    this.setState({ selectedStaff: selectedStaff }, function () {
      this.getStaffList();
    });
  };

  removeSelectedStudent = (e, idx) => {
    e.preventDefault();
    let selectedStudent = [...this.state.selectedStudent];
    selectedStudent.splice(idx, 1);
    this.setState({ selectedStudent: selectedStudent }, function () {
      this.getAssignedStudents();
    });
  };

  //handleChange function

  handleSelectChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  //Assign Member Functions
  assignRole = (e) => {
    e.preventDefault();
    if (this.state.selectedGroupRole === "") {
      swal("Choose Role First", "", "");
    } else {
      let groupRoles = this.state.selectedGroupRole.split(",");
      let selectedStaff = [...this.state.selectedStaff];
      let selectedStudent = [...this.state.selectedStudent];
      selectedStaff.forEach((el) => {
        if (el.checked === true) {
          el.assignedRole = groupRoles[0];
          el.groupRoleName = groupRoles[1];
          el.checked = false;
        }
      });
      selectedStudent.forEach((el) => {
        if (el.checked === true) {
          el.assignedRole = groupRoles[0];
          el.groupRoleName = groupRoles[1];
          el.checked = false;
        }
      });
      this.setState({
        selectedStaff: selectedStaff,
        selectedStudent: selectedStudent,
        selectedGroupRole: "",
      });
    }
  };

  assignMembertoGroup = (e) => {
    e.preventDefault();
    let data = [];
    let selectedStaff = [...this.state.selectedStaff];
    let selectedStudent = [...this.state.selectedStudent];
    if (selectedStaff.length === 0 && selectedStudent.length === 0) {
      swal("Warning", "No members selected to assign", "");
    } else {
      selectedStaff.forEach((el) => {
        data.push({
          groupId: this.state.groupId,
          groupRoleCode: el.assignedRole,
          peopleId: el.people.id,
        });
      });
      selectedStudent.forEach((el) => {
        data.push({
          groupId: this.state.groupId,
          groupRoleCode: el.assignedRole,
          peopleId: el.people.id,
        });
      });
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.assignMembertoGroup,
          data,
          (response) => {
            if (response.status === 200) {
              swal("Success", "Members assigned to the group", "");
              this.setState({
                selectedStaff: [],
                selectedStudent: [],
                membersTitle: "",
                selectedGroupRole: "",
                selectedYearLevel: "",
                selectYearLevel: "",
                selectClassroom: "",
                staffTypeId: "",
                showSpinner: false,
              });
              this.toggleModal();
              let data = {
                id: this.state.groupId,
              };
              axiosPost(URL.getGroupSelectByKey, data, (response) => {
                if (response.status === 200) {
                  // let groupDetail = response.data.data;
                  this.props.loadGroupMembers();
                }
              });
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            let errorResponse = error.response ? error.response.data : error;
            if (errorResponse.status === 400) {
              //if condition to check spring boot validation errors
              let errorMessage = "";
              if (errorResponse.errors) {
                errorResponse.errors.forEach((error) => {
                  errorMessage += `${error.field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })} ${error.defaultMessage} \n`; //ishan
                });
                swal(errorResponse.error, errorMessage, "");
              } else {
                swal(errorResponse.error, errorResponse.message, "");
              }
            } else {
              swal(
                errorResponse.error || "Network Error",
                errorResponse.message
                  ? errorResponse.message
                  : "Could Not Connect To The Server.",
                ""
              );
            }
          }
        );
      });
    }
  };

  //Edit member function

  editGroupRoleField = (e, idx) => {
    let groupDetail = { ...this.state.groupDetail };
    groupDetail.groupPeople.forEach((el) => {
      el.editable = false;
    });
    groupDetail.groupPeople[idx].editable = true;
    this.setState({ groupDetail });
  };

  cancelEditGroupRoleField = () => {
    let groupDetail = { ...this.state.groupDetail };
    groupDetail.groupPeople.forEach((el) => {
      el.editable = false;
    });
    this.setState({ groupDetail });
  };

  updateGroupRole = (e, id) => {
    e.preventDefault();
    let groupRoles = this.state.selectedGroupRole.split(",");
    let data = {
      groupId: this.state.groupId,
      peopleId: id,
      groupRoleCode: groupRoles[0],
    };
    axiosPost(URL.updateGroupRole, data, (response) => {
      if (response.status === 200) {
        this.getGroupMemberList();
      }
    });
  };

  deleteGroupMember = (e, id) => {
    e.preventDefault();
    let data = {
      groupId: this.state.groupId,
      peopleId: id,
    };
    displayConfirmDeleteAlert(data, this.deleteMember);
  };

  deleteMember = (data) => {
    axiosPost(URL.deleteGroupMember, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: "Successfully Deleted",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.getGroupMemberList();
      }
    });
  };

  //Modal Function
  closeModal = () => {
    this.setState({
      modal: false,
      selectedStaff: [],
      selectedStudent: [],
      membersTitle: "",
      selectedGroupRole: "",
      selectedYearLevel: "",
      selectYearLevel: "",
      selectClassroom: "",
      staffTypeId: "",
    });
  };

  groupMembersConfig = () => {
    return (
      <GroupMembersConfig
        membersTitle={this.state.membersTitle}
        handleStaffCheckbox={this.handleStaffCheckbox}
        handleStudentCheckbox={this.handleStudentCheckbox}
        handleSelectChange={this.handleSelectChange}
        staffs={this.state.staffs}
        staffType={this.state.staffType}
        staffTypeId={this.state.staffTypeId}
        handleStaffChange={this.handleStaffChange}
        yearLevels={this.state.yearLevels}
        handleYearLevelChange={this.handleYearLevelChange}
        selectYearLevel={this.state.selectYearLevel}
        selectClassroom={this.state.selectClassroom}
        classroomList={this.state.classroomList}
        handleClassroomChange={this.handleClassroomChange}
        assignedStudentsList={this.state.assignedStudentsList}
        selectedStaff={this.state.selectedStaff}
        selectedStudent={this.state.selectedStudent}
        removeSelectedStaff={this.removeSelectedStaff}
        removeSelectedStudent={this.removeSelectedStudent}
        handleSelectedMemberCheckbox={this.handleSelectedMemberCheckbox}
        assignRole={this.assignRole}
        groupRoles={this.state.groupRoles}
        allStaffChecked={this.state.allStaffChecked}
        allStudentChecked={this.state.allStudentChecked}
        showSpinner={this.state.showSpinner}
        selectedGroupRole={this.state.selectedGroupRole}
      />
    );
  };

  groupMembersFooter = () => {
    return (
      <div>
        <button
          className="tt-button tt-button-primary mr-3"
          onClick={this.assignMembertoGroup}
        >
          Assign
        </button>
        <button
          className="tt-button tt-button-primary"
          onClick={this.closeModal}
        >
          Cancel
        </button>
      </div>
    );
  };

  groupEditMemberConfig = () => {
    return (
      <GroupEditMember
        groupDetail={this.state.groupDetail}
        selectedGroupRole={this.state.selectedGroupRole}
        handleSelectChange={this.handleSelectChange}
        groupRoles={this.state.groupRoles}
        editGroupRoleField={this.editGroupRoleField}
        cancelEditGroupRoleField={this.cancelEditGroupRoleField}
        updateGroupRole={this.updateGroupRole}
        deleteGroupMember={this.deleteGroupMember}
        handleNextPage={this.handleNextPage}
        handlePreviousPage={this.handlePreviousPage}
        handlePageChange={this.handlePageChange}
        rowCountLimit={this.state.rowCountLimit}
        currentPage={this.state.currentPage}
        totalRecordsCount={this.state.totalRecordsCount}
        editMemberGroupSpinner={this.state.editMemberGroupSpinner}
      ></GroupEditMember>
    );
  };

  groupEditMemberFooter = () => {
    return (
      <button
        className="tt-button tt-button-primary"
        onClick={this.toggleEditModal}
      >
        Close
      </button>
    );
  };

  handleError = (e, member) => {
    let self = e.target;
    let img = new Image();
    img.src = URL.imageSelectURL + member.photo;
    img.onload = function () {
      self.src = img.src;
    };
    img.onerror = function () {
      self.src = userImage;
    };
  };

  render() {
    return (
      <div
        id="tt-groupContainer"
        className={
          this.state.minimize
            ? "tt-groupMember-container minimize-groupMemberContainer"
            : "tt-groupMember-container " +
              (this.props.classroom ? "removeFooter" : "") +
              (this.state.permissionInsertGroupMembers ||
              this.state.permissionEditGroupMembers
                ? ""
                : "removeFooter")
        }
      >
        <div
          className="tt-group-header"
          data-tip="Double click to expand and collapse this widget"
          onDoubleClick={this.minimizeMemberContainer}
        >
          <div className="row">
            <div className="col listHeading">Member Lists</div>
            <div className="col text-right listTotal">
              [
              {this.props.totalRecordsCount > 1
                ? this.props.totalRecordsCount + " members found"
                : this.props.totalRecordsCount + " member found"}
              ]
            </div>
          </div>
        </div>
        <div ref="iScroll" className="tt-groupMember-box">
          {this.props.groupMembers ? (
            this.props.groupMembers.map((member, idx) => {
              if (idx <= this.props.items) {
                return (
                  <>
                    {member.userRole === "RL_STC" ? (
                      <div
                        className="tt-group-holder"
                        key={idx}
                        id={idx}
                        data-tip={member.name +
                          " [" +( member.userRole === "RL_STC" ?
                           "Student Contact":member.userRole === "RL_STD"?
                           "Student": member.userRole === "RL_ADM"?
                           "Admin":"Staff") +"]"}
                      >
                        <Link
                          to={{
                            pathname: `/familyPage/${member.familyId}`,
                            profilePeopleId: member.peopleId,
                            familyPageOpen: true,
                            userId: member.userId,
                          }}
                        >
                          <div className="tt-memeber-img">
                            <img
                              src={
                                member.photo ? member.displayPhoto : userImage
                              }
                              alt="asd"
                              onError={(e) => this.handleError(e, member)}
                            ></img>
                          </div>
                          <div className="tt-member-name">{member.name}</div>
                        </Link>
                      </div>
                    ) : (
                      <div
                        className="tt-group-holder"
                        key={idx}
                        id={idx}
                        data-tip={member.name +
                           " [" +( member.userRole === "RL_STC" ?
                            "Student Contact":member.userRole === "RL_STD"?
                            "Student": member.userRole === "RL_ADM"?
                            "Admin":"Staff") +"]"}
                      >
                        <Link
                          to={{
                            pathname: `/profile/${member.username}`,
                            profilePeopleId: member.id,
                            userId: member.userId,
                            otherProfile: true,
                            name: member.name,
                            memberPhoto: member.photo,
                          }}
                          onClick={(e) =>
                            this.props.getPersonProfilePosts(
                              member.id,
                              "initCall"
                            )
                          }
                        >
                          <div className="tt-memeber-img">
                            <img
                              src={
                                member.photo ? member.displayPhoto : userImage
                              }
                              alt="Asdadw"
                              onError={(e) => this.handleError(e, member)}
                            ></img>
                          </div>
                          <div className="tt-member-name">{member.name}</div>
                        </Link>
                      </div>
                    )}
                    <ReactTooltip globalEventOff="click"></ReactTooltip>
                  </>
                );
              }
            })
          ) : this.props.groupSpinner ? (
            <>
              <div className="text-center mt-3 mb-3">
                <Spinner color="primary"></Spinner>
                <br></br>
                Loading Data ...
              </div>
            </>
          ) : (
            "No members"
          )}
          {this.props.loadingState ? (
            <div className="tt-group-holder">
              <div className="tt-memeber-img">
                <Spinner color="primary"></Spinner>
              </div>
            </div>
          ) : null}
        </div>

        {this.props.classroom ? (
          ""
        ) : this.state.permissionInsertGroupMembers ||
          this.state.permissionEditGroupMembers ? (
          <div className="tt-group-header mt-2 text-right tt-mobileGroupMember">
            {this.state.permissionInsertGroupMembers ? (
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={(e) => {
                  this.toggleModal();
                  this.setGroupId(e, this.props.groupData.id);
                }}
              >
                Assign members
              </button>
            ) : null}
            {this.state.permissionEditGroupMembers ? (
              <button
                className="tt-button tt-button-primary"
                onClick={(e) =>
                  this.setGroupId(e, this.props.groupData.id, "open")
                }
              >
                Edit members
              </button>
            ) : null}
          </div>
        ) : null}

        <ReactTooltip globalEventOff="click"></ReactTooltip>
        <ModalWindow
          fullWidth={true}
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          modalHeader="Assign Members to this group"
          modalBody={this.groupMembersConfig()}
          modalFooter={this.groupMembersFooter()}
        ></ModalWindow>
        <ModalWindow
          fullWidth={true}
          modal={this.state.editModal}
          toggleModal={this.toggleEditModal}
          modalHeader="Group Members"
          modalBody={this.groupEditMemberConfig()}
          modalFooter={this.groupEditMemberFooter()}
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionToProps = {
  getPersonProfilePosts,
};

export default connect(mapStateToProps, mapActionToProps)(GroupMemeberLists);
