import * as Yup from "yup";

export const EducationLevelValidationSchema = Yup.object({
  educationForm: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Education name is required"),
      code: Yup.string().required("Education code is required"),
    })
  ),
});
