/**
 * @author Azzeta Karmacharya
 *
 */

import React, { Component } from "react";

import WeekDays from "./WeekDays";

export default class WeekDaysSetup extends Component {
  render() {
    return (
      <>
        <div className="tt-group-header tt-systemSetupTwo">
          Select week days
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="weekDaysEducationLevelName">
              <strong>Education level : </strong>
            </label>
            <select
              onChange={this.props.universalHandleChange}
              name="weekDaysEducationLevelName"
              id="weekDaysEducationLevelName"
              value={this.props.weekDaysEducationLevelName}
              className="form-control ttedu-level-dropdown"
            >
              <option value="All">All</option>
              {this.props.educationLevelList !== undefined
                ? this.props.educationLevelList.map((educationLevel, idx) => (
                    <option key={idx} value={educationLevel.name}>
                      {educationLevel.name}
                    </option>
                  ))
                : null}
            </select>
          </div>

          <div className="col-md-6">
            <label>
              <strong>Term name : </strong>
            </label>
            <select
              onChange={this.props.universalHandleChange}
              name="selectedTermName"
              value={this.props.selectedTermName}
              className="form-control ttedu-level-dropdown"
              disabled={this.props.weekDaysEducationLevelName === "All"}
            >
              <option value="All">All</option>
              {this.props.educationLevelList !== undefined
                ? this.props.educationLevelList.map((educationLevel, i) => {
                    return educationLevel.name ===
                      this.props.weekDaysEducationLevelName
                      ? educationLevel.terms &&
                          educationLevel.terms.map((term, idx) => {
                            return (
                              <option key={idx} value={term.name}>
                                {term.name}
                              </option>
                            );
                          })
                      : null;
                  })
                : null}
            </select>
          </div>
        </div>
        {this.props.weekDaysEducationLevelName === "All" ? (
          <WeekDays
            handleWeekDays={this.props.handleWeekDays}
            disabled={this.props.weekDaysEducationLevelName === "All"}
            checkedList={
              this.props?.educationLevelList[0]?.terms[0] &&
              this.props?.educationLevelList[0]?.terms[0]?.weekDays
            }
            checkValueExitsInArray={this.props.checkValueExitsInArray}
          />
        ) : this.props.educationLevelList !== undefined ? (
          this.props.educationLevelList.map((educationLevel, i) => {
            return educationLevel.name ===
              this.props.weekDaysEducationLevelName ? (
              this.props.selectedTermName === "All" ? (
                <div key={i} className="card">
                  <div className="card-body">
                    <div className="tt-group-header">
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            {this.props.weekDaysEducationLevelName} -{" "}
                            {this.props.selectedTermName}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <WeekDays
                    handleWeekDays={this.props.handleWeekDays}
                    parentIndex={i}
                    checkedList={educationLevel.terms[0]?.weekDays}
                    checkValueExitsInArray={this.props.checkValueExitsInArray}
                  />
                </div>
              ) : (
                educationLevel.terms &&
                educationLevel.terms.map((term, idx) => {
                  return (
                    <div key={idx}>
                      <div className="row ttprimary-header">
                        <div className="col-md-8">
                          <p className="ttprimary-title">
                            {this.props.weekDaysEducationLevelName} -{" "}
                            {term.name}
                          </p>
                        </div>
                      </div>
                      <WeekDays
                        handleWeekDays={this.props.handleWeekDays}
                        parentIndex={i}
                        termIndex={idx}
                        checkedList={term.weekDays}
                        checkValueExitsInArray={
                          this.props.checkValueExitsInArray
                        }
                      />
                    </div>
                  );
                })
              )
            ) : null;
          })
        ) : null}
      </>
    );
  }
}
