import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import CustomDeleteButton from "../../components/common/CustomDeleteButton";
import { displayConfirmDeleteAlert } from "../../utils/DisplayMessage";
import CustomEditButton from "../../components/common/CustomEditButton";
import CustomModal from "../../components/common/CustomModal";

class ListClass extends Component {
  state = {
    editModal: false,
    editData: null,
    name: "",
    code: "",
    educationLevels: [],
    educationLevelId: "",
  };
  deleteClass = (datum) => {
    let data = {
      id: datum.id,
    };
    axiosPost(URL.deleteClass, data, (response) => {
      if (response.status == 200) {
        this.props.getAllClasses();
        swal("Success", response.data.message);
      }
    });
  };

  handleDelete = (id, idx) => {
    displayConfirmDeleteAlert({ id: id, idx: idx }, this.deleteClass);
  };

  handleEditModal = (editData) => {
    axiosPost(
      URL.getAllEducationLevel,
      {},
      (response) => {
        if (response.status == 200) {
          this.setState({
            educationLevels: response.data.data,
          });
        }
      },
      () => {}
    );
    this.setState({
      editData: editData,
      name: editData.name,
      educationLevelId: editData.educationLevel.id,
      editModal: true,
    });
  };

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal,
    });
  };

  handleChange = (e, idx) => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  handleEdit = () => {
    if (this.state.name !== "" && this.state.educationLevelId !== "") {
      let data = {
        id: this.state.editData.id,
        name: this.state.name,
        educationLevelId: this.state.educationLevelId,
        display: this.state.editData.display,
      };

      axiosPost(URL.updateClass, data, (response) => {
        if (response.status === 200) {
          swal("Success", "Education level updated succcessfully");
          this.props.getAllClasses();
          this.toggleEditModal();
        }
      });
    } else {
      swal("Error", "Name and education level is required");
    }
  };

  handleChangeToggle = (e, item) => {
    const { name, checked } = e.target;

    let data = {
      id: item.id,
      display: checked,
      name: item.name,
      educationLevelId: item.educationLevel.id,
    };

    axiosPost(URL.updateClass, data, (response) => {
      if (response.status === 200) {
        this.props.getAllClasses();
      }
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <table className="table cusTable">
            <thead>
              <tr>
                <th>Class Name</th>
                <th>Education Level Name</th>
                <th>Education Level Code</th>
                <th>Display</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.classes.length > 0
                ? this.props.classes.map((item, index) => {
                    return (
                      <tr>
                        <td> Class {item.name}</td>
                        <td>{item.educationLevel?.name}</td>
                        <td>
                          {item.educationLevel?.code != null
                            ? item.educationLevel?.code
                            : "-"}
                        </td>
                        <td>
                          <label className="switch mt-2">
                            <input
                              type="checkbox"
                              onChange={(e) => this.handleChangeToggle(e, item)}
                              checked={item.display}
                              name="display"
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>

                        <td>
                          {/* <CustomDeleteButton
                            onClick={() => this.handleDelete(item.id, index)}
                          /> */}
                          <CustomEditButton
                            onClick={() => this.handleEditModal(item)}
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <CustomModal
          modal={this.state.editModal}
          size="lg"
          toggleModal={this.toggleEditModal}
          modalHeader="Update Education Level"
          modalBody={
            <>
              <div className="row py-2">
                <div className="col-md-5">
                  <input
                    className="form-control cusInput shadow-none"
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div className="col-md-5">
                  <select
                    className="form-control shadow-none"
                    name="educationLevelId"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.educationLevelId}
                  >
                    <option value="" selected>
                      Choose education level
                    </option>

                    {this.state.educationLevels.map((el) => (
                      <option value={el.id}>{el.name}</option>
                    ))}
                  </select>
                </div>

                <div className="col-md-2">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={this.handleEdit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          }
        />
      </div>
    );
  }
}

export default ListClass;
