import moment from "moment";
import React, { Component } from "react";
import { axiosPost } from "../../../../../utils/AxiosApi";
import { URL } from "../../../../../utils/Constants";
import { handleError } from "../../../../../utils/Utils";
import userImage from "../../../../../assets/images/user-image.png";
import ModalWindow from "../../../../UI/ModalWindow";
import CourseClassResources from "../CourseClassResources/CourseResourceTemplate";

class SubmissionListAssignment extends Component {
  state = {
    submissionList: [],
    resourceModal: false,
  };

  componentDidMount = () => {
    this.getSubmissionList();
  };

  getSubmissionList = () => {
    let data = {
      courseClassAssignmentId: this.props.selectedId,
    };
    axiosPost(URL.getCourseAssignmentSubmissionList, data, (res) => {
      if (res.status === 200) {
        this.setState({ submissionList: res.data.data });
      }
    });
  };

  handleResourceModal = (resources) => {
    this.setState({
      selectedResources: resources,
      resourceModal: !this.state.resourceModal,
    });
  };

  toggleResourceModal = () => {
    this.setState({
      resourceModal: !this.state.resourceModal,
      selectedResources: [],
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "81.6vh" }}>
        <table className="table table-bordered table-centered">
          <thead className="tt-group-header">
            <tr>
              <th>Student Name</th>
              <th width="150px">Submission Date</th>
              <th width="110px">Resource</th>
            </tr>
          </thead>
          <tbody>
            {this.state.submissionList.length > 0 ? (
              this.state.submissionList.map((list, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div className="tt-pp">
                        <div className="tt-ppHolder">
                          <img
                            src={
                              list.imageName
                                ? URL.imageSelectURL + list.imageName
                                : userImage
                            }
                            onError={(e) => handleError(e)}
                            alt=""
                          ></img>
                        </div>
                        <div className="tt-ppName" style={{ width: "auto" }}>
                          <strong className="user-details">
                            {list.peopleName}
                          </strong>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">
                      {moment(list.createdAt).format("LL")}
                    </td>
                    <td>
                      {list.courseClassResources.length > 0 ? (
                        <button
                          className="tt-button tt-button-primary"
                          onClick={() =>
                            this.handleResourceModal(list.courseClassResources)
                          }
                        >
                          Resources
                        </button>
                      ) : (
                        "No Resources"
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3} className="text-center">
                  No students
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ModalWindow
          modal={this.state.resourceModal}
          toggleModal={this.toggleResourceModal}
          fullWidth={true}
          modalHeader="Resources"
          modalBody={
            <CourseClassResources
              selectedResources={this.state.selectedResources}
            ></CourseClassResources>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default SubmissionListAssignment;
