/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, GeneralAssessmentSettingsModal, render the Model for General Assessment Settings in the
 *              Record Setup in the Primary ARR
 */

import React, { Component } from "react";
import GeneralASSectionOne from "./GeneralASSectionOne";
import GeneralASSectionTwo from "./GeneralASSectionTwo";
import GeneralASSectionFour from "./GeneralASSectionFour";
import GeneralASSectionFive from "./GeneralASSectionFive";
import OverallAssessmentType from "./OverallAssessmentType";

class GeneralAssessmentSettingsModal extends Component {
  state = {};
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <GeneralASSectionOne
            comments={this.props.comments}
            handleCommentValueChange={this.props.handleCommentValueChange}
            handleRemoveCommentClick={this.props.handleRemoveCommentClick}
            handleUpdateCommentClick={this.props.handleUpdateCommentClick}
            handleNestedChange={this.props.handleNestedChange}
            handlecommentorChange={this.props.handlecommentorChange}
            assessmentName={this.props.assessmentName}
            sectionName={this.props.sectionName}
            className={this.props.className}
            recruitmentList={this.props.recruitmentList}
            roleList={this.props.roleList}
          />
        </div>
        <div className="row tt-GeneralAssessmentControl">
          {/* <div className="col"> */}
          <GeneralASSectionTwo
            addNewCommentSection={this.props.addNewCommentSection}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            decreaseminLengthValue={this.props.decreaseminLengthValue}
            increaseminLengthValue={this.props.increaseminLengthValue}
            decreasemaxLengthValue={this.props.decreasemaxLengthValue}
            increasemaxLengthValue={this.props.increasemaxLengthValue}
            universalhandleChange={this.props.universalhandleChange}
            commentName={this.props.commentName}
            handleWordCharacterChange={this.props.handleWordCharacterChange}
            commentTextType={this.props.commentTextType}
            commentor={this.props.commentor}
            recruitmentList={this.props.recruitmentList}
            handleMinLengthChange={this.props.handleMinLengthChange}
            handleMaxLengthChange={this.props.handleMaxLengthChange}
            roleList={this.props.roleList}
            handleChangeForCommenter={this.props.handleChangeForCommenter}
          />
          {/* </div> */}
        </div>
        <div
          className="row tt-subjectAssessmentControl"
          style={{ height: "38vh" }}
        >
          <div className="col-md-5 tt-assessment-type-table">
            <GeneralASSectionFour
              levelScales={this.props.levelScales}
              customAssessmentHeaderName={this.props.customAssessmentHeaderName}
              universalhandleChange={this.props.universalhandleChange}
              addNewDisciplineRowHandler={this.props.addNewDisciplineRowHandler}
              scaleName={this.props.scaleName}
              handleAssessmentTypeRadioChange={
                this.props.handleAssessmentTypeRadioChange
              }
              assessmentType={this.props.assessmentType}
              multipleScaleNameChangeForOverallAssessment={
                this.props.multipleScaleNameChangeForOverallAssessment
              }
              multipleScaleNameChangeForRubric={
                this.props.multipleScaleNameChangeForRubric
              }
              assessor={this.props.assessor}
              recruitmentList={this.props.recruitmentList}
              roleList={this.props.roleList}
              selectedLevelsForOverall={this.props.selectedLevelsForOverall}
              scaleNameOverall={this.props.scaleNameOverall}
              scaleNameRubric={this.props.scaleNameRubric}
            />
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-8 tt-subjectScaleRightBorder">
                <OverallAssessmentType
                  overallAssessmentData={this.props.overallAssessmentData}
                  scaleName={this.props.scaleName}
                  customAssessmentHeaderName={
                    this.props.customAssessmentHeaderName
                  }
                  //assessmentType={this.props.assessmentType}
                  universalHandleChange={this.props.universalHandleChange}
                  handleNestedChange={this.props.handleNestedChange}
                  addMultipleDisciplineRow={this.props.addMultipleDisciplineRow}
                  assessmentGeneralCustomAssessmentRubricsConfigurations={
                    this.props
                      .assessmentGeneralCustomAssessmentRubricsConfigurations
                  }
                  rubricsName={this.props.rubricsName}
                  description={this.props.description}
                  handleRemoveDisciplineRow={
                    this.props.handleRemoveDisciplineRow
                  }
                  handleUpdateDisciplineRow={
                    this.props.handleUpdateDisciplineRow
                  }
                  handleDisciplineInputChange={
                    this.props.handleDisciplineInputChange
                  }
                  selectedScaleLevels={this.props.selectedScaleLevels}
                  handleDeleteCustomOverallAssessmentRow={
                    this.props.handleDeleteCustomOverallAssessmentRow
                  }
                  selectedLevelsForRubric={this.props.selectedLevelsForRubric}
                  selectedLevelsForOverall={this.props.selectedLevelsForOverall}
                  handleUpdateCustomHeader={this.props.handleUpdateCustomHeader}
                  handleDeleteCustomHeader={this.props.handleDeleteCustomHeader}
                  handleDeleteScaleTableData={
                    this.props.handleDeleteScaleTableData
                  }
                  handleCustomDisciplineRowChange={
                    this.props.handleCustomDisciplineRowChange
                  }
                  handleDeleteScaleTableDataForRubric={
                    this.props.handleDeleteScaleTableDataForRubric
                  }
                  generalAssessmentUpdate={this.props.generalAssessmentUpdate}
                  customDescription={this.props.customDescription}
                  customRubricsName={this.props.customRubricsName}
                />
              </div>
              <div className="col-md-4">
                <GeneralASSectionFive
                  handleGeneralAssessmentSubmit={
                    this.props.handleGeneralAssessmentSubmit
                  }
                  classroomList={this.props.classroomList}
                  applyToMultiple={this.props.applyToMultiple}
                  handleApplyToMultiple={this.props.handleApplyToMultiple}
                  handleApplySettingToOther={
                    this.props.handleApplySettingToOther
                  }
                  applySettingToOther={this.props.applySettingToOther}
                  generalAssessmentUpdate={this.props.generalAssessmentUpdate}
                  editGeneralAssessment={this.props.editGeneralAssessment}
                  handleGeneralAssessmentUpdateSubmit={
                    this.props.handleGeneralAssessmentUpdateSubmit
                  }
                  selectedAssessmentIds={this.props.selectedAssessmentIds}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralAssessmentSettingsModal;
