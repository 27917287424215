import React, { Component } from "react";
import CustomButton from "../../components/common/CustomButton";
import CustomSpinner from "../../components/common/CustomSpinner";
import logo from "../../assets/images/logo/logo2.png";
import nepalLogo from "../../assets/images/logo/nepal_logo.png";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import history from "../../utils/History";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { displayErrorAlert, mobilePhoneValidator } from "../../utils/Utils";

export class RegisterPage extends Component {
  state = {
    email: "",
    emailAddress: "",
    password: "",
    passwordType: "password",
    showSpinner: false,
    name: "",
    username: "",
    cpassword: "",
    userType: "",
    provinceId: "",
    districtId: "",
    cityId: "",
    provinces: [],
    districts: [],
    cities: [],
    address: "",
    remarks: "",
    gender: "",
    dateOfBirth: "",
    contactNo: "",
    userData: "",
    validationErr: false,
  };

  componentDidMount() {
    this.getProvinces();
    this.getUserType();
  }

  getUserType = () => {
    const param = {};
    axiosPost(
      URL.getAdminFilteredRoles,
      param,
      (response) => {
        if (response.status === 200) {
          this.setState({ userData: response?.data?.data });
        }
      },
      () => {},
      true
    );
  };

  getProvinces = () => {
    const Params = {
      countryId: 147,
    };
    axiosPost(
      URL.getCountryFirstLevel,
      Params,
      (response) => {
        if (response.status === 200) {
          this.setState({ provinces: response.data.data });
        }
      },
      () => {},
      true
    );
  };

  handleRegister = () => {
    const {
      emailAddress,
      countryId,
      provinceId,
      districtId,
      cityId,
      userType,
      dateOfBirth,
      contactNo,
      name,
      username,
      gender,
      address,
      remarks,
    } = this.state;

    if (
      [
        emailAddress,
        // countryId,
        // provinceId,
        // districtId,
        // cityId,
        userType,
        name,
        gender,
        dateOfBirth,
        // contactNo,
      ].some((value) => value === "")
    ) {
      this.setState({
        validationErr: true,
      });
      swal("Error", "Please enter all the required fields.");
      return;
    }
    if (
      this.state.contactNo !== "" &&
      !mobilePhoneValidator(this.state.contactNo)
    ) {
      swal("Error", "Please enter valid contact number");
      return;
    }

    const data = {
      name,
      username,
      gender,
      dateOfBirth,
      // nationality: "Nepali",
      emailAddress,
      provinceId,
      districtId,
      cityId,
      address,
      contactNo,
      roleCode: userType,
      remarks,
    };

    this.setState({ showSpinner: true }, () => {
      axiosPost(
        URL.signUp,
        data,
        (response) => {
          if (response.status === 200) {
            swal(
              "Success",
              "User registered successfully. Check your given email for credentials."
            ).then((t) => {
              t && history.push("/login");
            });
            this.setState({ showSpinner: false });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({ showSpinner: false });
        }
      );
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if (name === "provinceId") {
      this.getDistricts(value);
      this.setState({ provinceId: value });
    } else if (name === "districtId") {
      this.getCities(value);
    }
  };

  getDistricts = (provinceId) => {
    const Params = {
      countryId: 147,
      countryFirstLevelId: provinceId,
    };
    axiosPost(
      URL.getCountrySecondLevel,
      Params,
      (response) => {
        if (response.status === 200) {
          this.setState({ districts: response.data.data });
        }
      },
      () => {},
      true
    );
  };

  getCities = (districtId) => {
    const Params = {
      countryId: 147,
      countryFirstLevelId: this.state.provinceId,
      countrySecondLevelId: districtId,
    };
    axiosPost(
      URL.getCountryThirdLevel,
      Params,
      (response) => {
        if (response.status === 200) {
          this.setState({ cities: response.data.data });
        }
      },
      () => {},
      true
    );
  };

  render() {
    const { provinces, districts, cities } = this.state;
    return (
      <div>
        <div className="container-fluid pe-0 ps-0">
          {this.state.showSpinner ? (
            <CustomSpinner text="Please wait..."></CustomSpinner>
          ) : null}

          <div className="loginDiv">
            <div className="row">
              <div className="col-md-7 loginBoxDiv">
                <div className="loginBoxHolder">
                  <div style={{ position: "absolute", right: 0, top: "5%" }}>
                    <Link to="/">
                      <strong style={{ fontSize: "1.2rem" }}>Home</strong>
                    </Link>
                  </div>
                  <img
                    src={nepalLogo}
                    style={{ height: "80px", width: "auto" }}
                  ></img>
                  <h2 className="loginTitle">Sign Up</h2>
                  <div style={{ width: "100%" }}>
                    <div className="position-relative">
                      <span
                        className="material-icons-round loginIcon"
                        aria-hidden="true"
                      >
                        account_circle
                      </span>
                      <select
                        className="customTextInput is-invalid"
                        name="userType"
                        value={this.state.userType}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled>
                          Choose User Types
                        </option>
                        {this.state.userData &&
                          this.state.userData.map((items) => {
                            return (
                              <option value={items.code}>{items.name}</option>
                            );
                          })}
                      </select>

                      {this.state.userType === "" &&
                      this.state.validationErr ? (
                        <div className="errorMsg-textField registerErr">
                          User type is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {/* <div className="col">
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            badge
                          </span>
                          <input
                            type="text"
                            name="username"
                            className="customTextInput mt-2"
                            value={this.state.username}
                            onChange={this.handleChange}
                            placeholder="Username"
                          ></input>
                        </div>
                      </div> */}
                      <div className="col">
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            account_circle
                          </span>
                          <input
                            type="text"
                            name="name"
                            className="customTextInput mt-2"
                            value={this.state.name}
                            onChange={this.handleChange}
                            placeholder="Full Name"
                          ></input>
                          {this.state.name === "" &&
                          this.state.validationErr ? (
                            <div className="errorMsg-textField registerErr">
                              Name is required
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="position-relative">
                      <span
                        className="material-icons-round loginIcon mt-2"
                        aria-hidden="true"
                      >
                        calendar_month
                      </span>
                      <input
                        type="text"
                        name="dateOfBirth"
                        className="customTextInput mt-2"
                        value={this.state.dateOfBirth}
                        onChange={this.handleChange}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        placeholder="Date Of Birth"
                      ></input>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            email
                          </span>
                          <input
                            type="text"
                            name="emailAddress"
                            className="customTextInput mt-2"
                            value={this.state.emailAddress}
                            onChange={this.handleChange}
                            placeholder="Email"
                          ></input>
                          {this.state.emailAddress === "" &&
                          this.state.validationErr ? (
                            <div className="errorMsg-textField registerErr">
                              Email is required
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            call
                          </span>
                          <input
                            type="text"
                            name="contactNo"
                            className="customTextInput mt-2"
                            value={this.state.contactNo}
                            onChange={this.handleChange}
                            placeholder="Contact No."
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            change_circle
                          </span>
                          <select
                            className="customTextInput mt-2"
                            name="gender"
                            value={this.state.gender}
                            onChange={this.handleChange}
                          >
                            <option value="" disabled>
                              Choose Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                          </select>
                          {this.state.gender === "" &&
                          this.state.validationErr ? (
                            <div className="errorMsg-textField registerErr">
                              Gender is required
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            pin_drop
                          </span>
                          <select
                            className="customTextInput mt-2"
                            name="provinceId"
                            value={this.state.provinceId}
                            onChange={this.handleChange}
                            placeholder="Choose Province"
                          >
                            <option value="" disabled>
                              Choose Province
                            </option>
                            {provinces.map((p) => (
                              <option value={p.id}>{p.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            map
                          </span>
                          <select
                            className="customTextInput mt-2"
                            name="districtId"
                            value={this.state.districtId}
                            onChange={this.handleChange}
                            placeholder="District"
                          >
                            <option value="" disabled>
                              Choose District
                            </option>
                            {districts.map((d) => (
                              <option value={d.id}>{d.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative">
                          <span
                            className="material-icons-round loginIcon mt-2"
                            aria-hidden="true"
                          >
                            apartment
                          </span>
                          <select
                            className="customTextInput mt-2"
                            name="cityId"
                            value={this.state.cityId}
                            onChange={this.handleChange}
                            placeholder="City"
                          >
                            <option value="" disabled>
                              Choose City
                            </option>
                            {cities.map((c) => (
                              <option value={c.id}>{c.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="position-relative">
                      <span
                        className="material-icons-round loginIcon mt-2"
                        aria-hidden="true"
                      >
                        home
                      </span>
                      <input
                        type="text"
                        name="address"
                        className="customTextInput mt-2"
                        value={this.state.address}
                        onChange={this.handleChange}
                        placeholder="Address"
                      ></input>
                    </div>

                    {this.state.userType === "RL_OTH" ? (
                      <div className="position-relative">
                        <span className="material-icons-round loginIconPsw">
                          email
                        </span>
                        <input
                          type={this.state.remarks}
                          name="remarks"
                          className="customTextInput mt-2"
                          value={this.state.remarks}
                          onChange={this.handleChange}
                          placeholder="Remarks"
                        ></input>
                      </div>
                    ) : null}

                    <div className="position-relative mt-2">
                      <span className="forgotPsw">
                        If you already have an account,
                        <span className="forgotPsw-hover">
                          {this.props.location.state === "private-school" ? (
                            <Link to="/school-login" className="ml-1 mr-1 mt-2">
                              <u>Login</u>
                            </Link>
                          ) : (
                            <Link to="/login" className="ml-1 mr-1 mt-2">
                              <u>Login</u>
                            </Link>
                          )}
                        </span>
                      </span>
                    </div>

                    <div className="text-center mt-3 mb-3">
                      <CustomButton
                        text={
                          this.state.registerSpinner ? (
                            <div className="text-center">
                              <Spinner size="sm" />
                              Registering...
                            </div>
                          ) : (
                            "Register"
                          )
                        }
                        customStyle="loginBtn"
                        onClick={this.handleRegister}
                      ></CustomButton>
                    </div>
                  </div>
                  {/* <div className="bottomText">Augment Your Learning</div> */}
                </div>
              </div>
              <div className="col-md-5 loginBg"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterPage;
