import React, { Component } from "react";
import NoteDisplay from "../../../../utils/noteDisplay";

class RoomInsertForm extends Component {
  state = {};
  render() {
    let errors = this.props.errors;
    return this.props.isAvailable ? (
      <form action="">
        <div className="form-group row">
          <div className="form-group col-md-2"></div>
          <div className="form-group col-md-3">
            <label htmlFor="roomNumber">
              <strong>Room Number</strong>
              <span className="tt-facilities-add-room-mandatory">*</span>
            </label>
          </div>
          <div className="form-group col-md-4">
            <input
              type="text"
              className={
                errors.roomNumber
                  ? "form-control indicate-error"
                  : "form-control"
              }
              name="roomNumber"
              value={this.props.roomNumber}
              onChange={this.props.handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="form-group col-md-2"></div>
          <div className="form-group col-md-3">
            <label htmlFor="roomName">
              <strong>Room Name</strong>
              <span className="tt-facilities-add-room-mandatory">*</span>
            </label>
          </div>
          <div className="form-group col-md-4">
            <input
              type="text"
              className={
                errors.roomName ? "form-control indicate-error" : "form-control"
              }
              name="roomName"
              value={this.props.roomName}
              onChange={this.props.handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="form-group col-md-2"></div>
          <div className="form-group col-md-3">
            <label htmlFor="floor">
              <strong> Floor</strong>
              <span className="tt-facilities-add-room-mandatory">*</span>
            </label>
          </div>
          <div className="form-group col-md-4">
            <select
              className={
                errors.floor ? "form-control indicate-error" : "form-control"
              }
              name="floor"
              value={this.props.floor}
              onChange={this.props.handleChange}
            >
              <option value="" disabled>
                Choose a floor
              </option>
              {this.props.noOfFloors.map((el, idx) => {
                return (
                  <option key={idx} value={el.id}>
                    {el.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {this.props.roomObject.buildingSectionName &&
        this.props.roomObject.buildingSectionName !== undefined ? (
          <div className="form-group row">
            <div className="form-group col-md-2"></div>
            <div className="form-group col-md-3">
              <label htmlFor="section">
                <strong>Section</strong>
                <span className="tt-facilities-add-room-mandatory">*</span>
              </label>
            </div>
            <div className="form-group col-md-4">
              <select
                className={
                  errors.section
                    ? "form-control indicate-error"
                    : "form-control"
                }
                name="section"
                value={this.props.section}
                onChange={this.props.handleChange}
              >
                <option value="" disabled>
                  Choose a section
                </option>
                {this.props.wings.length > 0 ? (
                  this.props.wings.map((el, idx) => {
                    return (
                      <option value={el.id} key={idx}>
                        {el.name}
                      </option>
                    );
                  })
                ) : (
                  <option value="">No Option</option>
                )}
              </select>
            </div>
          </div>
        ) : null}
        <div className="form-group row">
          <div className="form-group col-md-2"></div>
          <div className="form-group col-md-3">
            <label htmlFor="details">
              <strong>Details</strong>
            </label>
          </div>
          <div className="form-group col-md-4">
            <textarea
              type="text"
              className="form-control"
              name="details"
              value={this.props.details}
              onChange={this.props.handleChange}
            />
          </div>
        </div>
        <div className="row button-bg-color">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary"
              onClick={
                this.props.modalRoomUpdateForm
                  ? (e) =>
                      this.props.handleRoomUpdateSubmitClick(
                        e,
                        this.props.obj.id,
                        this.props.obj.buildingId
                      )
                  : (e) => this.props.addRoom(e, this.props.roomObject.id)
              }
            >
              {this.props.modalRoomUpdateForm ? "Update" : "Submit"}
            </button>

            <button
              className="tt-button tt-button-primary ml-3"
              onClick={
                this.props.modalRoomUpdateForm
                  ? (e) => this.props.handleRoomUpdateForm(e)
                  : (e) => this.props.handleRoomInsertFormModal(e)
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    ) : (
      <div className="tt-widgetContent-tab-holder" style={{ height: "50vh" }}>
        <NoteDisplay item="Room" dependentItem="Floor"></NoteDisplay>
      </div>
    );
  }
}

export default RoomInsertForm;
