import React, { useState, useEffect } from "react";
import ExamRoutineTableRow from "./ExamRoutineTableRow";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ViewExamRoutine = () => {
  const [allExamRoutine, setAllExamRoutine] = useState();
  const [educationLevel, setEducationLevel] = useState();
  const [educationLevelValue, setEducationLevelValue] = useState();
  const [examTerm, setExamTerm] = useState();
  const [examTermValue, setExamTermValue] = useState();

  const getEducationLevel = () => {
    axiosPost(URL.getAcademicYears, {}, (response) => {
      if (response.status === 200) {
        setEducationLevel(response.data.data);
        // console.log(response.data.data);
      }
    });
  };

  const getExamTerm = () => {
    const param = {
      educationLevelId: +educationLevelValue,
    };
    axiosPost(URL.getExamTerm, param, (response) => {
      if (response.status === 200) {
        setExamTerm(response.data.data);
      }
    });
  };

  const getAllExamRoutine = () => {
    const params = {
      id: +examTermValue,
    };
    axiosPost(URL.getExamAllRoutine, params, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        if (datum.length > 0) {
          datum.forEach((el) => {
            if (el.classrooms.length > 0) {
              el.classrooms.forEach((cc) => {
                if (cc.examDateSubjectDtos.length > 0) {
                  let uniqueIds = [];
                  let uniqueSubjects = [];
                  cc.examDateSubjectDtos.forEach((item) => {
                    if (!uniqueIds.includes(item.subjectId)) {
                      uniqueIds.push(item.subjectId);
                      uniqueSubjects.push(item);
                    }
                  });
                  cc.examDateSubjectDtos = uniqueSubjects;
                }
              });
            }
          });
        }
        setAllExamRoutine(response?.data?.data);
      }
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value) {
      setEducationLevelValue(value);
    } else {
      setEducationLevelValue(null);
    }
  };
  const handleExamTermChange = (e) => {
    const value = e.target.value;
    if (value) {
      setExamTermValue(value);
    } else {
      setExamTermValue(null);
    }
  };

  const tableRow = () => {
    return allExamRoutine ? (
      <ExamRoutineTableRow allExamRoutine={allExamRoutine} />
    ) : (
      <p>No Data Found</p>
    );
  };

  const handleDownload = () => {
    const data = document.getElementById("routine");
    html2canvas(data).then((canvas) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF("p", "mm");
      doc.addImage(
        canvas,
        "PNG",
        10,
        10,
        position,
        imgWidth,
        imgHeight,
        "",
        "FAST"
      );
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      doc.save("Downld.pdf");
    });
  };

  useEffect(() => {
    getEducationLevel();
  }, []);

  useEffect(() => {
    if (educationLevelValue) {
      getExamTerm();
    }
  }, [educationLevelValue]);

  useEffect(() => {
    if (examTermValue) {
      getAllExamRoutine();
    }
  }, [examTermValue]);

  return (
    <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
      <div className="tt-group-header">Exam Routine</div>
      <div className="row my-2">
        {educationLevel &&
          educationLevel?.map((item) => {
            return (
              <>
                <div className="col-md-2 text-right mt-1">
                  <strong>Education Level: </strong>
                </div>
                <div className="col-md-2">
                  <select
                    className="form-control"
                    name="educationLevel"
                    value={educationLevelValue}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Education Level</option>
                    {item?.educationLevels?.map((value) => {
                      return (
                        <>
                          <option value={value.id}>{value?.name}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </>
            );
          })}

        {examTerm && (
          <>
            <div className="col-md-2 text-right mt-1">
              <strong>Term Exam: </strong>
            </div>
            <div className="col-md-2">
              <select
                className="form-control"
                name="termExam"
                value={examTermValue}
                onChange={(e) => handleExamTermChange(e)}
              >
                <option value="" disabled>
                  Select
                </option>
                {examTerm?.map((item) => {
                  return (
                    <option value={item.id}>{item?.assessmentName}</option>
                  );
                })}
              </select>
            </div>
          </>
        )}
        {allExamRoutine && (
          <div className="btn btn-primary" onClick={() => handleDownload()}>
            Download
          </div>
        )}
      </div>
      <div id="routine">
        {allExamRoutine?.map((item) => {
          return (
            <table className="table">
              <thead>
                <tr>
                  <th>{item?.name}</th>
                </tr>
              </thead>
              <tbody>
                {item.classrooms.map((el) => {
                  return (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{el?.name}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {el.examDateSubjectDtos.map((item) => {
                            return (
                              <td>
                                {item?.subject?.name}
                                <br></br>
                                <strong>Theory: </strong>
                                {item?.theoryStartDate
                                  ? `${moment(item.theoryStartDate).format(
                                      "YYYY-MM-DD"
                                    )} (${moment(item.theoryStartDate).format(
                                      "hh:mm"
                                    )})`
                                  : "-"}
                                <br></br>
                                <strong>Practical:</strong>
                                {item?.practicalStartDate
                                  ? ` ${moment(item.practicalStartDate).format(
                                      "YYYY-MM-DD"
                                    )} (${moment(
                                      item.practicalStartDate
                                    ).format("hh:mm")})`
                                  : "-"}
                              </td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    </div>
  );
};
export default ViewExamRoutine;
