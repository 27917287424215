import React, { Component } from "react";

import icon02 from "../../assets/images/icon-02.png";
import NavBar from "../../components/FrontPage/NavBar";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { connect } from "react-redux";

export class RelativeLinksPage extends Component {
  state = {
    relativeLinks: [],
  };
  getAllLinks = () => {
    axiosPost(URL.getAllRelativeLinks, {}, (response) => {
      if (response.status == 200) {
        this.setState({
          relativeLinks: response.data.data,
        });
      }
    });
  };

  componentDidMount() {
    this.getAllLinks();
  }

  render() {
    const { pathname } = this.props.location;
    return (
      <>
        {pathname === "/dashboard/relative-links" ? null : (
          <NavBar
            noNav={true}
            teacherPage={this.props.location?.state?.teacherPage}
            classPage={this.props.location?.state?.classPage}
            homePage={
              this.props.location?.state?.homePage || this.props.homePage
            }
          />
        )}
        <div className="container">
          <div className="row">
            <div className="col mt-3">
              <div className="whiteContainer mt-3">
                <CustomPageHeader
                  title={
                    this.props.lang === "nameEn"
                      ? "Relative Links"
                      : "सापेक्ष लिङ्कहरू"
                  }
                  noIcon={true}
                />
                <section className="mb-4">
                  <div className="mt-3">
                    <div className="row mt-2">
                      {this.state.relativeLinks.length > 0 ? (
                        this.state.relativeLinks.map((oA, idx) => (
                          <div className="col-md-4">
                            <a href={oA.link} target="_blank" alt="">
                              <div
                                className="card mb-4 p-3"
                                role="button"

                                // onClick={() => this.handleCategorySelect(oA)}
                              >
                                <div className="cdc-center">
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div
                                        className="col-md-4"
                                        style={{
                                          borderRight: "1px solid #ccc",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <div className="icon-image">
                                          <img
                                            src={
                                              URL.imageSelectURL + oA.imagePath
                                            }
                                            onError={(e) =>
                                              (e.target.src = icon02)
                                            }
                                            alt={oA.link}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <h6
                                          className="heading-06"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {oA.name}
                                        </h6>
                                        <small>{oA.link}</small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        ))
                      ) : (
                        <div className="text-center py-2 col">
                          <span>No records to display</span>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(RelativeLinksPage);
