import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

class CreateAssessmentForm extends Component {
  state = {
    assessmentTypes: [],
    educationLevels: [],
    educationLevelDivisions: [],
  };

  render() {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-3 scale-content">
            <label>
              <strong>Education level</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4">
            <select
              className="form-control"
              onChange={this.props.universalHandleChange}
              name="educationLevel"
              value={this.props.educationLevel}
              disabled={this.props.educationLevels.length === 1 ? true : false}
            >
              <option value="" disabled>
                Select Education Level
              </option>
              {this.props.educationLevels
                ? this.props.educationLevels.map((educationLevel, id) => {
                    return (
                      <option key={id} value={educationLevel.id}>
                        {educationLevel.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-3 scale-content">
            <label>
              <strong>
                {/* {localStorage.getItem("educationLevelDivision")
                  ? localStorage.getItem("educationLevelDivision")
                  : "Term Exam"} */}
                Term
              </strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4">
            <select
              className="form-control"
              onChange={this.props.universalHandleChange}
              name="educationLevelDivision"
              disabled={this.props.educationLevel ? false : true}
              value={
                this.props.educationLevelDivision
                  ? this.props.educationLevelDivision
                  : ""
              }
            >
              <option value="" disabled>
                Select {localStorage.getItem("educationLevelDivision")}
              </option>
              {this.props.educationLevelDivisions
                ? this.props.educationLevelDivisions.map(
                    (educationLevelDivision, id) => (
                      <option key={id} value={educationLevelDivision.id}>
                        {educationLevelDivision.name}
                      </option>
                    )
                  )
                : null}
            </select>
          </div>
          <div className="col-md-3">
            {this.props.educationLevel ? (
              ""
            ) : (
              <p className="tt-notify" style={{ marginLeft: "2%" }}>
                (Select Education Level first)
              </p>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-3 scale-content">
            <label>
              <strong>Assessment name</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              name="assessmentName"
              value={this.props.assessmentName}
              onChange={this.props.universalHandleChange}
            />
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row tt-create-assessment">
          <div className="col-md-2"></div>
          <div className="col-md-3 scale-content">
            <label>
              <strong>Assessment type</strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4">
            <select
              className="form-control"
              onChange={this.props.universalHandleChange}
              name="assessmentType"
              value={this.props.assessmentType}
            >
              <option value="" disabled>
                Select Assessment Type
              </option>
              {this.props.assessmentTypes
                ? this.props.assessmentTypes.map((assessment, id) => (
                    <option key={id} value={assessment.code}>
                      {assessment.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row tt-create-assessment">
          <div className="col-md-2"></div>
          <div className="col-md-3 scale-content">
            <label>
              <strong>
                {localStorage.getItem("yearLevel")
                  ? localStorage.getItem("yearLevel")
                  : "Year Level"}
              </strong>
              <span className="tt-assessment-module-mandatory">*</span>
            </label>
          </div>
          <div className="col-md-4">
            {this.props.edit ? (
              <Select
                key={`my_unique_select_key__${this.props.selectOptions}`}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                value={this.props.selectedYearLevels}
                options={this.props.selectOptions}
                name="colors"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.props.handleGradeChange}
                isDisabled={this.props.educationLevelDivision ? false : true}
              />
            ) : (
              <Select
                key={`my_unique_select_key__${this.props.selectOptions}`}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={this.props.selectOptions}
                name="colors"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.props.handleGradeChange}
                isDisabled={this.props.educationLevelDivision ? false : true}
                //value={this.props.selectedItem}
              />
            )}
          </div>
          <div className="col-md-3">
            {this.props.educationLevelDivision ? (
              ""
            ) : (
              <p className="tt-notify" style={{ marginLeft: "2%" }}>
                (Select {localStorage.getItem("educationLevelDivision")} first)
              </p>
            )}
          </div>
        </div>
        <div className="row tt-create-assessment">
          {/* <div className="col-md-4"></div> */}
          <div className="col text-center">
            <input
              type="checkbox"
              className="tt-record-deadline-checkbox mr-1"
              id="setDeadLine"
              checked={this.props.setDeadLine}
              name="setDeadLine"
              onChange={this.props.universalHandleChange}
            />
            <label htmlFor="setDeadLine" className="mr-2">
              {"Set recording deadline dates"}
            </label>
            <select
              className="tt-create-assessment-select ml-2"
              name="recordingDeadlineDate"
              value={this.props.recordingDeadlineDate}
              onChange={this.props.universalHandleChange}
            >
              <option value="" disabled>
                {"Select Date"}
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
            <select
              className="tt-create-assessment-select ml-2"
              name="recordingDeadlineParam"
              value={this.props.recordingDeadlineParam}
              onChange={this.props.universalHandleChange}
            >
              <option value="" disabled>
                {"Weeks or days or months"}
              </option>
              <option value="days">days</option>
              <option value="weeks">weeks</option>
              <option value="months">months</option>
            </select>
            <label className="ml-2">after recording date</label>
          </div>
        </div>
      </>
    );
  }
}

export default CreateAssessmentForm;
