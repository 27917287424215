import React, { Component } from "react";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";

class ListStudentAttendanceDetails extends Component {
  state = {
    attendanceRecord: [],
    schoolInfos: null,
  };

  getStudeentName = () => {
    //fint the name of the std. whose attendance details is being downloaded
  };

  renderAttendanceStatus = (key) => {
    return this.props.subjects.map((sub, id) => {
      const isrecordthere = this.props.studentAttendanceRecord[key].find(
        (stdRecord) => stdRecord.subjectId === sub.id
      );

      if (isrecordthere) {
        return (
          <td>
            {isrecordthere.isPresent ? (
              <FontAwesomeIcon icon={faCheckCircle} color="#1abc9c" />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} color="#a70707" />
            )}
          </td>
        );
      } else {
        return <td>-</td>;
      }
    });
  };

  handleExportToPDF = async () => {
    document.getElementById("printAttendance").parentNode.style.overflow =
      "visible";
    const element = document.getElementById("printAttendance");
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.setFontSize(40);
    pdf.text(30, 20, "Hello world!");
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("AttendanceDetails.pdf");
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data,
        });
      }
    });
  };

  componentDidMount() {
    this.getSchoolInfo();
  }

  render() {
    return (
      <>
        {this.props.subjects.length > 0 ? (
          <div>
            <div id="printAttendance">
              {Object.keys(this.props.studentAttendanceRecord).length > 0 && (
                <>
                  <div className="container-fluid row calendar-print-header my-3">
                    <div className="col-md-4">
                      <img
                        src={
                          URL.imageSelectURL + this.state.schoolInfos?.imageName
                        }
                        className="img-fluid"
                      />
                    </div>

                    <div className="col-md-4 text-center">
                      <h2>{this.state.schoolInfos?.name}</h2>
                      <h5>{this.state.schoolInfos?.address}</h5>
                      <h6>{this.state.currentAcYrName}</h6>
                    </div>
                    <div className="col-md-4"></div>
                  </div>

                  <div className="py-2">
                    <b>Student name: </b>
                    {this.props.student?.people?.name}
                  </div>
                </>
              )}
              <table className="table table-bordered classroutineTable text-center">
                <thead className="tt-group-header">
                  <th>Date</th>
                  {this.props.subjects.map((sub, id) => {
                    return (
                      <>
                        <th>{sub.name}</th>
                      </>
                    );
                  })}
                </thead>
                <tbody>
                  {Object.keys(this.props.studentAttendanceRecord).length >
                  0 ? (
                    <>
                      {Object.keys(this.props.studentAttendanceRecord).map(
                        (key, id) => {
                          return (
                            <tr>
                              <td>{key}</td>
                              {this.renderAttendanceStatus(key)}
                            </tr>
                          );
                        }
                      )}
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>
            <div className="text-right">
              <button
                className="tt-button tt-button-primary my-2"
                onClick={() => this.handleExportToPDF()}
                style={{
                  display: this.props.showBtn ? " block" : "none",
                }}
              >
                Export as PDF
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
export default ListStudentAttendanceDetails;
