import React, { Component } from "react";
import "../../assets/scss/receipt.scss";
import { URL } from "../../utils/Constants";

class ReceiptTemplate extends Component {
  state = {
    imageError: false,
  };

  handleImageError = (e) => {
    this.setState({ imageError: true });
  };
  render() {
    let props = this.props.receiptData;
    return (
      <div className="invoiceBox">
        <div className="receiptDiv">
          <h1>RECEIPT</h1>
        </div>
        <div style={{ flex: 1, padding: "25px" }}>
          <div className="receiptTableHeader">
            <div style={{ display: "flex" }}>
              {this.state.imageError ? null : (
                <img
                  src={URL.imageSelectURL + props.imageName}
                  className="receiptLogo mr-2"
                  onError={this.handleImageError}
                />
              )}

              <div style={{ lineHeight: "1.5" }}>
                <strong className="mb-1">{props.schoolName}</strong>
                <div>
                  {props.address && props.address !== "" ? props.address : null}
                </div>
                <div>
                  {props.number && props.number !== "" ? props.number : null}
                </div>
                <div>
                  {props.email && props.email !== "" ? props.email : null}
                </div>
                <div>
                  {props.website && props.website !== "" ? props.website : null}
                </div>
              </div>
            </div>
            <div>
              <div>
                <strong>Date: </strong>
                {props.issueDate}
              </div>
              <div>
                <strong>Bill No.: </strong>
                {props.receiptNumber}
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div className="receiptTableBody">
              <div style={{ display: "flex" }}>
                <strong>Received with thanks from </strong>
                <div className="dottedLine1">{props.billPayedBy}</div>
                <strong style={{ marginLeft: "5px" }}>a sum of </strong>
                <div className="dottedLine2">{props.paidAmount}</div>
              </div>
            </div>
            <div className="receiptTableBody mt-2">
              <div style={{ display: "flex" }}>
                <strong>In Words Rupees </strong>
                <div className="dottedLine3">{props.amountInWords}</div>
              </div>
            </div>
            <div className="receiptTableBody mt-2">
              <div style={{ display: "flex" }}>
                <strong>On Account of </strong>
                <div className="dottedLine4">{props.studentName}</div>
                <strong style={{ marginLeft: "5px" }}>Grade </strong>
                <div className="dottedLine2">{props.grade}</div>
              </div>
            </div>
            <div className="receiptTableBody mt-2">
              <div style={{ display: "flex" }}>
                <strong>Paid Up to </strong>
                <div className="dottedLine2">{props.paidUptoMonth}</div>
              </div>
            </div>
          </div>
          <div className="receiptFooter">
            <div className="receiptFooterBox">
              <div style={{ flex: 1, paddingRight: "15px" }}>
                <table
                  className="text-center receiptTableFrame"
                  border={1}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>PRE. BALANCE</th>
                      <th>TOTAL REMAINING BALANCE</th>
                      <th>BALANCE PAID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {props.previousAdvanceDue === ""
                          ? "-"
                          : props.previousAdvanceDue}
                      </td>
                      <td>
                        {props.advanceDue !== "" ? props.advanceDue : "-"}
                      </td>
                      <td>{props.paidAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="cashierSign">Cashier</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReceiptTemplate;
