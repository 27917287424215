import React from "react";
import Slider from "react-slick";
import {
  faChalkboard,
  faGraduationCap,
  faClipboard,
  faHandshake,
  faClock,
  faVideo,
  faLaptopCode,
  faUsersCog,
  faBookReader,
  faBookOpen,
  faFileInvoiceDollar,
  faUserGraduate,
  faChalkboardTeacher,
  faClipboardList,
  faClipboardCheck,
  faCertificate,
  faIdCardAlt,
  faFile,
  faChartLine,
  faBellSlash,
  faBell
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/scss/widgetArea.scss";
import { userRole, userRoleCode } from "../../utils/Constants";

const Footer = props => {
  const { widgets } = props;

  const loadSpinner = (e, id) => {
    this.setState({ isLoading: true }, function() {
      this.widgetModal(e, id);
    });
  };
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1
  };
  return (
    <>
      <div className="footer-01  py-2">
        <Slider {...settings}>
          {widgets &&
            widgets?.map((items, index) => {
              return (
                <div>
                  {items.name === "Performance Analysis" ? (
                    userRole === userRoleCode.roleStudent ? (
                      <div
                        className="tt-widget-holder"
                        key={index}
                        id={index}
                        onClick={e => loadSpinner(e, index)}
                      >
                        <div className="tt-widget-icon">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <FontAwesomeIcon icon={items.icon} style={{ height: "100px", width: "100px" }} />
                   
               
                        <div className="tt-widget-title">
                          {(userRole === userRoleCode.roleStudent ||
                            userRole === userRoleCode.roleStudentContact) &&
                          items.name === "Academic Administration"
                            ? "The School"
                            : items.name}
                        </div>
                      </div>
                      </div>
                      </div>
                    ) : null
                  ) : (
                    <div
                      className="tt-widget-holder"
                      key={index}
                      id={index}
                      onClick={e => loadSpinner(e, index)}
                    >
                      <div className="tt-widget-icon">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <FontAwesomeIcon icon={items.icon} style={{ height: "100px", width: "100px" }} />
                     
                   
                      <div className="tt-widget-title">
                        {(userRole === userRoleCode.roleStudent ||
                          userRole === userRoleCode.roleStudentContact) &&
                        items.name === "Academic Administration"
                          ? "The School"
                          : items.name}
                      </div>
                      </div>
                    </div>
                    </div>
                  )}
                </div>
              );
            })}

          {/* <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faGraduationCap}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faClipboard}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faHandshake}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faClock}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faVideo}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faLaptopCode}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faUsersCog}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faBookReader}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faBookOpen}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div>
          <div className="tt-widget-icon">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                style={{ height: "100px", width: "100px" }}
              />
              <div className="tt-widget-title">Graduation Cap</div>
            </div>
          </div> */}
        </Slider>
      </div>
    </>
  );
};
export default Footer;
