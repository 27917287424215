import React, { Component } from "react";
import ModalWindow from "../../UI/ModalWindow";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import SubjectTopicSetting from "../CoursePlan/SubjectTopicSetting";
import swal from "sweetalert";

export class CreateTeacherTraining extends Component {
  state = {
    selectedTrainingId: "",
    addCourseModal: false,
    trainings: [],
    error: false,
  };

  componentDidMount() {
    this.getAllTrainings();
  }

  getAllTrainings = () => {
    axiosPost(URL.getAllTraining, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          trainings: response.data.data,
        });
      }
    });
  };

  handleChange = (e) => {
    const target = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleAddCourse = (subject) => {
    this.setState({
      addCourseModal: !this.state.addCourseModal,
      error: false,
    });
  };

  toggleAddCourseModal = () => {
    this.setState({ addCourseModal: !this.state.addCourseModal });
  };

  render() {
    return (
      <div>
        <div>
          <div>
            <div className="container-fluid">
              <div className="row py-3">
                <div className="col-3 col-md">
                  {/* <strong>Training</strong> */}
                  <select
                    className="form-control cusInputSelect"
                    name="selectedTrainingId"
                    value={this.state.selectedTrainingId}
                    onChange={(e) => this.handleChange(e)}
                    disabled={false}
                  >
                    <option value="" selected disabled>
                      Select Training
                    </option>
                    {this.state.trainings?.map((sl, idx) => {
                      return <option value={sl.id}>{sl.name}</option>;
                    })}
                  </select>
                </div>

                <div className="col-3 col-md-2">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={() => this.handleAddCourse()}
                    // disabled={this.state.selectedSubjects === "" ? true : false}
                  >
                    Add Course Syllabus
                  </button>
                </div>
              </div>
              {this.state.addCourseModal ? (
                <SubjectTopicSetting
                  selectedTrainingId={this.state.selectedTrainingId}
                  teacherTraining={true}
                  selectedYearLevel={this.state.selectedYearLevel}
                  selectedClass={this.state.selectedClass}
                  selectedSubject={this.state.selectedSubject}
                  academicRelated={true}
                  toggleAddCourseModal={this.toggleAddCourseModal}
                  displayType={this.props.displayType}
                  getCourseSyllabus={this.props.getCourseSyllabus}
                  toggleModal={this.props.toggleModal}
                ></SubjectTopicSetting>
              ) : null}
            </div>
          </div>
        </div>

        {/* <ModalWindow
          modal={this.state.addCourseModal}
          toggleModal={this.toggleAddCourseModal}
          size="xl"
          modalHeader="Add New Training Plan for Teacher"
          modalBody={
            <SubjectTopicSetting
              selectedTrainingId={this.state.selectedTrainingId}
              teacherTraining={true}
              selectedYearLevel={this.state.selectedYearLevel}
              selectedClass={this.state.selectedClass}
              selectedSubject={this.state.selectedSubject}
              academicRelated={true}
              toggleAddCourseModal={this.toggleAddCourseModal}
              displayType={this.props.displayType}
              getCourseSyllabus={this.props.getCourseSyllabus}
            ></SubjectTopicSetting>
          }
        ></ModalWindow> */}
      </div>
    );
  }
}

export default CreateTeacherTraining;
