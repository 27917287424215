import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

class NoAccess extends Component {
  state = {};
  render() {
    return (
      <div className="hasAccessBox">
        <div className="tt-group-header">Unauthorized Access</div>
        <div className="text-center" style={{ fontSize: "70px" }}>
          <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
        </div>
        <div
          className="text-center"
          style={{ fontSize: "25px", fontWeight: "bold", marginBottom: "5px" }}
        >
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default NoAccess;
