import React, { useEffect } from "react";
import { Chart } from "chart.js";

const data = {
  labels: ["English", "English II", "Science", "Math", "Math II", "Nepali"],
  datasets: [
    {
      label: "First Terminal Exam",
      data: [65, 59, 80, 81, 56, 55],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(153, 102, 255, 0.2)",
      ],
    },
    {
      label: "Second Terminal Exam",
      data: [25, 29, 50, 61, 76, 35],
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(255, 159, 64, 0.5)",
        "rgba(255, 205, 86, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(153, 102, 255, 0.5)",
      ],
    },
    {
      label: "Thirs Terminal Exam",
      data: [67, 39, 40, 86, 57, 95],
      backgroundColor: [
        "rgba(255, 99, 132, 0.9)",
        "rgba(255, 159, 64, 0.9)",
        "rgba(255, 205, 86, 0.9)",
        "rgba(75, 192, 192, 0.9)",
        "rgba(54, 162, 235, 0.9)",
        "rgba(153, 102, 255, 0.9)",
      ],
    },
  ],
};

function StudentTerminalMarksChart(props) {
  const displayBarChart = () => {
    const aChart = document.getElementById("barChart");
    renderChart(aChart);
  };

  const renderChart = (ctx) => {
    new Chart(ctx, {
      type: "bar",
      data: data,
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{}],
          yAxes: [
            {
              ticks: {
                // beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    displayBarChart();
  }, []);
  return (
    <div className="performanceChartDiv">
      <div className="performanceChartHolder">
        <div className="tt-group-header">Term Exams Comparison</div>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col">
              <canvas id="barChart" width="300" height="450" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentTerminalMarksChart;
