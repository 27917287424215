/**
 * @author      Suyog Manandhar
 * @author      Azzeta Karmacharya
 * @version     2.0
 * @description This class, Assessment, renders view to create a Assessment Record with field Education Level
 *              coordinate Name
 *
 */
import React, { Component } from "react";
import {
  URL,
  recruitmentStatus,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import AssessmentModuleEducationLevelList from "./AssessmentModuleEducationLevelList";
import swal from "sweetalert";
import AssessmentModuleForm from "./AssessmentModuleForm";
import { connect } from "react-redux";
import { getAssessmentModuleEducationLevelList } from "../../../ducks/AcademicAdministrationDucks";
import {
  displayConfirmDeleteAlert,
  displaySuccessAlert,
  showErroMessage,
} from "../../../utils/Utils";

import ModalWindow from "./../../UI/ModalWindow";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import NoteDisplay from "../../../utils/noteDisplay";
import { Spinner } from "reactstrap";
import { isEqual } from "lodash";
import { displayErrorAlert } from "./../../../utils/Utils";

class Assessment extends Component {
  constructor() {
    super();
    this.assessmentModuleFormElement = React.createRef();
  }

  state = {
    listOfEduLevels: [],
    assessmentModules: [],
    staffList: [],
    assessmentModuleEducationLevelList: [],
    createRecord: true,
    formData: {},
    updateId: null,
    recruitmentStatusId: null,
    coordinatorName: "",
    isAvailable: false,
    showSpinner: false,
    render: false,
    isLoading: true,
    errors: {
      assessmentModuleCode: "",
      educationLevelId: "",
      recruitmentId: "",
    },
    hasBeenAssigned: false,
    moduleSpinner: false,
  };

  componentDidMount() {
    this.props.assign === false &&
      this.props.getAssessmentModuleEducationLevelList();
    this.getEducationLevelList();
    // this.getAssessmentModuleList();
    this.getRecruitmentStatusByKey();
    // this.getStaffList();
    
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEqual(
        prevState.assessmentModuleEducationLevelList,
        nextProps.assessmentModuleEducationLevelList
      )
    ) {
      return {
        assessmentModuleEducationLevelList:
          nextProps.assessmentModuleEducationLevelList,
        isLoading: false,
        moduleSpinner: nextProps.moduleSpinner,
      };
    }
  }

  /**
   * @author Azzeta Karmacharya
   * api call to get list of assessment modules
   */
  getAssessmentModuleList = (value) => {
    axiosPost(
      URL.getUnassignedAssessmentModuleToEducationLevel,
      { educationLevelId: value },
      (response) => {
        if (response.status === 200) {
          this.setState({
            assessmentModules: response.data.data,
          });
        }
      }
    );
  };

  /**
   * @author Azzeta Karmacharya
   * api call to get list of educationLevel
   */
  getEducationLevelList = () => {
    axiosPost(
      URL.getEducationLevelUnassignedAssessmentModule,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({
            listOfEduLevels: response.data.data,
          });
        }
      }
    );
  };

  getRecruitmentStatusByKey = () => {
    let data = {
      code: recruitmentStatus.recruitmentCurrent,
    };
    axiosPost(URL.getRecruitmentStatusByKey, data, (response) => {
      if (response.status === 200) {
        this.setState(
          { recruitmentStatusId: response.data.data.id },
          function () {
            let data = {
              recruitmentStatusId: this.state.recruitmentStatusId,
            };
            this.getStaffList(data);
          }
        );
      }
    });
  };

  /**
   * @author Azzeta Karmacharya
   * api call to get list of staff
   */
  getStaffList = (data) => {
    axiosPost(
      URL.getStaffList,
      data,
      (response) => {
        if (response.status === 200) {
          this.setState(
            {
              staffList: response.data.data,
            },
            function () {
              if (this.props.assign) {
                if (this.state.staffList.length > 0) {
                  this.setState({ render: true, isAvailable: true });
                } else {
                  this.setState({ render: true });
                }
              } else {
                this.setState({ render: true });
              }
            }
          );
        }
      },
      (err) => {
        this.setState({ render: true });
      }
    );
  };

  /**
   * @author Azzeta Karmacharya
   * This method console logs out all the states
   */
  handleSubmit = (e, params) => {
    e.preventDefault();
    if (
      params.assessmentModuleCode === "" ||
      params.educationLevelId === "" ||
      params.recruitmentId === ""
    ) {
      swal({
        title: "Warning",
        text: "Fill all the fields",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      let newErrors = { ...this.state.errors };
      Object.entries(this.state.errors).forEach((error) => {
        const [key, value] = error;
        if (params[key] === "") {
          newErrors[key] = true;
        }
      });
      this.setState({ errors: newErrors });
    } else {
      this.setState({ showSpinner: true }, function () {
        axiosPost(
          URL.insertEducationLevelAssessmentModule,
          params,
          (response) => {
            if (response.status === 200) {
              this.setState({ hasBeenAssigned: true });
              swal({
                title: "Success",
                text: "Successfully Inserted Data",
                allowOutsideClick: false,
                closeOnClickOutside: false,
              }).then(() => {
                this.setState({
                  createRecord: true,
                  showSpinner: false,
                  hasBeenAssigned: false,
                });
              });
              draggable();

              this.props.getAssessmentModuleEducationLevelList();
            }
          },
          (error) => {
            this.setState({ showSpinner: false });
            displayErrorAlert(error);
          }
        );
      });
    }
  };

  handleUpdateCoordinator = (e, index) => {
    let assessmentModuleEducationLevelList = [
      ...this.state.assessmentModuleEducationLevelList,
    ];
    assessmentModuleEducationLevelList.forEach((module, i) => {
      if (index === i) {
        module.editable = true;
        this.setState({
          coordinatorName: module.recruitmentId ? module.recruitmentId : "",
        });
      } else {
        module.editable = false;
      }
    });
    this.setState({ assessmentModuleEducationLevelList });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleCancelUpdate = (e, index) => {
    e.preventDefault();
    let assessmentModuleEducationLevelList = [
      ...this.state.assessmentModuleEducationLevelList,
    ];
    assessmentModuleEducationLevelList.forEach((module, i) => {
      if (index === i) {
        module.editable = false;
      }
    });
    this.setState({ assessmentModuleEducationLevelList });
  };

  handleUpdateSubmit = (e, educationLevelId, assessmentModuleCode) => {
    let param = {
      recruitmentId: this.state.coordinatorName,
      educationLevelId: educationLevelId,
      assessmentModuleCode: assessmentModuleCode,
    };
    axiosPost(URL.updateEducationLevelAssessmentModule, param, (response) => {
      if (response.status === 200) {
        this.props.getAssessmentModuleEducationLevelList();
        displaySuccessAlert(response);
      } else {
        showErroMessage(response);
      }
    });
  };

  //   handleUpdateButtonClick = id => {
  //     axiosGet(`${URL.assessmentEducationStaff + "/" + id}`, response => {
  //       if (response.status === 200) {
  //         this.setState({
  //           formData: response.data.data,
  //           updateId: id
  //         });
  //       }
  //     });
  //     this.setState({
  //       createRecord: false
  //     });
  //   };

  //   handleUpdate = (e, params) => {
  //     e.preventDefault();
  //     let id = this.state.updateId;
  //     axiosPut(`${URL.assessmentEducationStaff + "/" + id}`, params, response => {
  //       if (response.status === 200) {
  //         swal("Updated", "success", "");
  //         this.assessmentModuleFormElement.current.reset();
  //         this.getAssessmentModuleEducationLevelList();
  //       }
  //     });
  //     this.setState({
  //       createRecord: true
  //     });
  //   };

  deleteConfirm = (e, list) => {
    e.preventDefault();
    displayConfirmDeleteAlert(list, this.handleDelete);
  };

  handleDelete = (params) => {
    axiosPost(URL.deleteEducationLevelAssessmentModule, params, (response) => {
      if (response.status === 200) {
        this.props.getAssessmentModuleEducationLevelList();
        swal({
          title: "Success",
          text: "Successfully deleted the Data",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
      } else {
        showErroMessage(response);
      }
    });
  };

  handleErrorChange = (name) => {
    let newErrors = { ...this.state.errors };
    if (name === "coordinatorId") name = "recruitmentId";
    newErrors[name] = false;
    this.setState({ errors: newErrors });
  };

  render() {
    return this.state.render ? (
      <>
        {this.props.assign ? (
          this.state.createRecord ? (
            this.state.isAvailable ? (
              <div
                className="tt-widgetContent-tab-holder"
                style={{ height: "79.5vh" }}
              >
                <div className="tt-group-header">
                  {this.props.name}
                  {checkWidgetAdminOrNot("Academic Administration") === true ? (
                    <button
                      className="tt-button tt-button-primary float-right permissionBtnCSS"
                      onClick={() => {
                        this.setState({
                          permissionModal: !this.state.permissionModal,
                        });
                      }}
                    >
                      Permissions
                    </button>
                  ) : null}
                </div>
                <AssessmentModuleForm
                  title="Assign Assessment Module to Education Level"
                  handleSubmit={this.handleSubmit}
                  listOfEduLevels={this.state.listOfEduLevels}
                  assessmentModules={this.state.assessmentModules}
                  staffList={this.state.staffList}
                  createRecord={this.state.createRecord}
                  getAssessmentModuleList={this.getAssessmentModuleList}
                  ref={this.assessmentModuleFormElement}
                  errors={this.state.errors}
                  handleErrorChange={this.handleErrorChange}
                  hasBeenAssigned={this.state.hasBeenAssigned}
                />
                {this.state.showSpinner ? (
                  <div className="fullWindow-Spinner">
                    <div>
                      <Spinner color="white"></Spinner>
                    </div>
                    <div style={{ fontSize: "16px", marginTop: "15px" }}>
                      Please Wait. Assigning to Education level ...
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div
                className="tt-widgetContent-tab-holder"
                style={{ height: "79.5vh" }}
              >
                <NoteDisplay textContent="To assign Assessment Module to Education Level, you must first add Staffs. Please add staffs and then assign assessment module to education level"></NoteDisplay>
              </div>
            )
          ) : (
            <div
              className="tt-widgetContent-tab-holder"
              style={{ height: "79.5vh" }}
            >
              <AssessmentModuleForm
                title="Update Assessment"
                listOfEduLevels={this.state.listOfEduLevels}
                assessmentModules={this.state.assessmentModules}
                staffList={this.state.staffList}
                handleSubmit={this.handleUpdate}
                formData={this.state.formData}
                editMode={true}
                createRecord={this.state.createRecord}
                getAssessmentModuleList={this.getAssessmentModuleList}
                ref={this.assessmentModuleFormElement}
                errors={this.state.errors}
                handleErrorChange={this.handleErrorChange}
              />
            </div>
          )
        ) : (
          <div
            className="tt-widgetContent-tab-holder"
            style={{ height: "79.5vh" }}
          >
            <div className="tt-group-header">
              {this.props.name}
              {checkWidgetAdminOrNot("Academic Administration") === true ? (
                <button
                  className="tt-button tt-button-primary float-right permissionBtnCSS"
                  onClick={() => {
                    this.setState({
                      permissionModal: !this.state.permissionModal,
                    });
                  }}
                >
                  Permissions
                </button>
              ) : null}
            </div>
            <AssessmentModuleEducationLevelList
              assessmentModuleEducationLevelList={
                this.state.assessmentModuleEducationLevelList
              }
              deleteConfirm={this.deleteConfirm}
              handleUpdateButtonClick={this.handleUpdateButtonClick}
              handleUpdateCoordinator={this.handleUpdateCoordinator}
              staffList={this.state.staffList}
              coordinatorName={this.state.coordinatorName}
              handleChange={this.handleChange}
              handleCancelUpdate={this.handleCancelUpdate}
              handleUpdateSubmit={this.handleUpdateSubmit}
              isLoading={this.state.isLoading}
              moduleSpinner={this.props.moduleSpinner}
            />
          </div>
        )}
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="Assessment Module"
              header={this.props.name}
              activityName={this.props.code}
            />
          }
        />
      </>
    ) : (
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      ></div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  assessmentModuleEducationLevelList:
    state.academicAdministration.assessmentModuleEducationLevelList,
  moduleSpinner: state.academicAdministration.moduleSpinner,
});

const mapActionsToProps = {
  getAssessmentModuleEducationLevelList: getAssessmentModuleEducationLevelList,
};
export default connect(mapStateToProps, mapActionsToProps)(Assessment);
