import React, { Component } from "react";
class AssessmentTitleCheckBox extends Component {
  state = {};
  render() {
    return (
      <div key={this.props.idx}>
        <div className="form-group mb-0">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              //name={"assessmentType" + this.props.idx}
              name="assessmentType"
              value="rubricsBasedAssessment"
              onChange={this.props.universalhandleChange}
              id="rubricsBasedAssessment"
            />
            <label
              className="form-check-label"
              htmlFor="rubricsBasedAssessment"
            >
              Rubrics Based Assessment
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="assessmentType"
              //name={"assessmentType" + this.props.idx}
              value="overallAssessment"
              onChange={this.props.universalhandleChange}
              id="overallAssessment"
              checked={this.props.assessmentType === "overallAssessment"}
            />
            <label className="form-check-label" htmlFor="overallAssessment">
              Overall Assessment
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="assessmentType"
              //name={"assessmentType" + this.props.idx}
              value="both"
              id="bothAssessment"
              onChange={this.props.universalhandleChange}
            />
            <label className="form-check-label" htmlFor="bothAssessment">
              Both
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default AssessmentTitleCheckBox;
