import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import moment from "moment";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomButton from "../../components/common/CustomButton";
import CustomModal from "../../components/common/CustomModal";
import ModalWindow from "../../components/UI/ModalWindow";
import UploadForm from "../../components/Upload/UploadForm";
import fallbackSrc from "../../assets/images/fallbackimg.png";
import swal from "sweetalert";
import { handleError } from "../../utils/Utils";

class MyProfile extends Component {
  state = {
    info: "",
    editModal: false,
    name: "",
    emailAddress: "",
    gender: "",
    dateOfBirth: "",
    nationality: "",
    profileImage: null,
    countrys: [],
    profileImageEdit: false,
    imagePreview: null,
    tempImage: null,
    showSpinner: false,
  };

  componentDidMount() {
    this.getMyInfo();
    this.getCountry();
  }

  getCountry = () => {
    axiosPost(URL.getCountry, {}, (response) => {
      if (response.status === 200) {
        this.setState({ countrys: response.data.data });
      }
    });
  };

  getMyInfo = () => {
    axiosPost(
      URL.getLoggedInUserInfo,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({ info: response.data.data });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleClickUpdate = () => {
    this.setState({
      name: this.state.info.name,
      emailAddress: this.state.info.emailAddress,
      gender: this.state.info.gender,
      dateOfBirth: moment(this.state.info.dateOfBirth).format("YYYY-MM-DD"),
      nationality: this.state.info.nationality,
      profileImage: null,
      editModal: true,
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };
  toggleModal = () => {
    this.setState({
      editModal: false,
    });
  };

  handleSubmit = () => {
    const { name, emailAddress, gender, dateOfBirth, nationality } = this.state;

    if (!name || !emailAddress || !gender || !dateOfBirth || !nationality) {
      swal("Warning", "Please fill all fields.");
      return;
    }

    this.setState({ spinner: true }, () => {
      const data = { name, emailAddress, gender, dateOfBirth, nationality };

      axiosPost(
        URL.updateProfileCore,
        data,
        (response) => {
          if (response.status === 200) {
            this.getMyInfo();
            this.setState({
              spinner: false,
              editModal: false,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          this.setState({
            spinner: false,
          });
        }
      );
    });
  };

  profileModal = () => {
    this.setState({
      profileImageEdit: !this.state.profileImageEdit,
    });
  };

  handleSubmitImg = () => {
    let formData = new FormData();

    formData.append("file", this.state.tempImage[0]);

    this.setState(
      {
        showSpinner: true,
      },
      () => {
        axiosPost(
          URL.updateProfileImgCore,
          formData,
          (response) => {
            if (response.status === 200) {
              this.setState({ showSpinner: false, tempImage: null });
              this.profileModal();
              this.getMyInfo();
              swal(
                "Success",
                "Your profile image has been updated successfully."
              );
            }
          },
          (err) => {
            this.setState({
              showSpinner: false,
            });
            displayErrorAlert(err);
          }
        );
      }
    );
  };

  // handleImageChange = (e) => {
  //   this.setState(
  //     {
  //       tempImage: e.target.files[0],
  //       newImage: true,
  //     },
  //     function () {
  //       if (this.state.tempImage) {
  //         let image = window.URL.createObjectURL(this.state.tempImage);
  //         this.setState({ imagePreview: image });
  //       } else {
  //         this.setState({
  //           imagePreview: null,
  //         });
  //       }
  //     }
  //   );
  // };

  handleImageChange = (e) => {
    this.setState(
      {
        tempImage: e.target.files,
        newImage: true,
      },
      function () {
        if (this.state.tempImage) {
          let image = window.URL.createObjectURL(this.state.tempImage[0]);
          this.setState({ imagePreview: image });
        } else {
          this.setState({
            imagePreview: null,
          });
        }
      }
    );
  };

  handleFileDrop = (e, name) => {
    let files = e.dataTransfer.files;

    if (files.length > 1) {
      swal("Error", "Please select only one file");
    } else {
      this.setState(
        {
          tempImage: files,
          newImage: true,
        },
        function () {
          if (this.state.tempImage) {
            let image = window.URL.createObjectURL(this.state.tempImage[0]);
            this.setState({ imagePreview: image });
          } else {
            this.setState({
              imagePreview: null,
            });
          }
        }
      );
    }
  };

  removeProfilePicture = () => {};
  modalFooter = () => {
    return (
      <div className="row">
        <div className="text-right">
          <button
            onClick={this.handleSubmitImg}
            disabled={this.state.tempImage !== null ? false : true}
            className="tt-button tt-button-primary mr-3"
          >
            Upload
          </button>
          {/* <Button
            onClick={this.handleRemoveExistingProfilePicture}
            className="tt-button tt-button-primary mr-3"
          >
            Remove
          </Button> */}
          <button
            onClick={this.profileModal}
            className="tt-button tt-button-primary"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row p-4">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader title="User Information" noIcon={true} />
              <div className="row p-3">
                <div className="col-md-7">
                  <div>
                    <p className="formTitle text-uppercase mb-2">Name</p>
                    <p className="myProfileData">{this.state.info.name}</p>
                  </div>
                  <div>
                    <p className="formTitle text-uppercase mb-2">
                      Email Address / username
                    </p>
                    <p className="myProfileData">
                      {this.state.info.emailAddress}
                    </p>
                  </div>
                  <div>
                    <p className="formTitle text-uppercase mb-2">
                      Date of Birth
                    </p>
                    <p className="myProfileData">
                      {moment(this.state.info.dateOfBirth).format("LL")}
                    </p>
                  </div>
                  <div>
                    <p className="formTitle text-uppercase mb-2">Gender</p>
                    <p className="myProfileData text-capitalize">
                      {this.state.info.gender}
                    </p>
                  </div>
                  <div>
                    <p className="formTitle text-uppercase mb-2">Nationality</p>
                    <p className="myProfileData ">
                      {this.state.info.nationalityName}
                    </p>
                  </div>
                </div>

                <div className="col-md-5 myProfileImgDiv">
                  <div
                    className="change-profile-img"
                    onClick={this.profileModal}
                  >
                    Change Profile Image
                  </div>
                  <img
                    src={URL.imageSelectURL + this.state.info.imagepath}
                    className="myProfileImg"
                    onError={(e) => handleError(e)}
                  />
                </div>

                <div className=" col mt-3">
                  <CustomButton
                    text="Update Profile"
                    customStyle="cusBtnFontSize"
                    onClick={() => this.handleClickUpdate()}
                  ></CustomButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomModal
          // size="sm"
          modal={this.state.editModal}
          modalHeader="Update Profile details"
          toggleModal={this.toggleModal}
          modalBody={
            <>
              <div className="">
                <div>
                  <p className="formTitle text-uppercase mb-2">Name</p>

                  <input
                    className={`form-control cusInput ${
                      this.state.error && this.state.name == "" && "is-invalid"
                    }`}
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.handleChange(e)}
                    type="text"
                    placeholder="Enter your name"
                  />
                </div>
                {/* <div>
                    <p className="formTitle text-uppercase mb-2">
                      Email Address / username
                    </p>

                    <input
                      className={`form-control cusInput ${
                        this.state.error &&
                        this.state.emailAddress == "" &&
                        "is-invalid"
                      }`}
                      name="emailAddress"
                      value={this.state.emailAddress}
                      onChange={(e) => this.handleChange(e)}
                      type="text"
                      placeholder="Enter your email/username"
                    />
                  </div> */}
                <div>
                  <p className="formTitle text-uppercase mb-2">Date of Birth</p>
                  <input
                    className={`form-control cusInput ${
                      this.state.error &&
                      this.state.dateOfBirth == "" &&
                      "is-invalid"
                    }`}
                    name="dateOfBirth"
                    value={this.state.dateOfBirth}
                    onChange={(e) => this.handleChange(e)}
                    type="date"
                    placeholder="Enter date of birth"
                  />
                </div>
                <div>
                  <p className="formTitle text-uppercase mb-2">Gender</p>
                  <select
                    name="gender"
                    className="form-control cusInput"
                    onChange={this.handleChange}
                    value={this.state.gender}
                  >
                    <option value="" selected disabled>
                      Choose your gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Other</option>
                  </select>
                </div>
                <div>
                  <p className="formTitle text-uppercase mb-2">Nationality</p>
                  <select
                    name="nationality"
                    className="form-control cusInput"
                    onChange={this.handleChange}
                    value={this.state.nationality}
                  >
                    <option value="" selected disabled>
                      Choose a nationality
                    </option>

                    {this.state.countrys.map((country, idx) => {
                      return (
                        <option key={idx} value={country.id}>
                          {country.nationality
                            ? country.nationality
                            : country.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="text-right py-3">
                  <button
                    className="btn btn-primary cusBtn cusBtnFontSize"
                    onClick={this.handleSubmit}
                  >
                    Update
                  </button>
                </div>
              </div>
            </>
          }
        ></CustomModal>

        <ModalWindow
          fullWidth={false}
          modal={this.state.profileImageEdit}
          toggleModal={this.profileModal}
          modalHeader="Change Profile Picture"
          modalBody={
            <UploadForm
              handleSubmit={this.handleSubmitImg}
              handleImageChange={this.handleImageChange}
              handleFileDrop={this.handleFileDrop}
              tempImage={this.state.tempImage}
              removeProfilePicture={this.removeProfilePicture}
              imagePreview={
                this.state.newImage
                  ? this.state.imagePreview
                  : URL.imageSelectURL + this.state.info.imagepath
              }
              showSpinner={this.state.showSpinner}
            />
          }
          modalFooter={this.modalFooter()}
        ></ModalWindow>
      </div>
    );
  }
}

export default MyProfile;
