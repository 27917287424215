import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getPersonProfilePosts } from "./../../ducks/PostsDucks";
import { URL } from "../../utils/Constants";
import userImage from "../../assets/images/user-image.png";

class FamilyMemberList extends Component {
  state = {
    minimize: false,
  };

  minimizeMemberContainer = () => {
    this.setState({ minimize: !this.state.minimize });
  };

  handleError = (e, member) => {
    let self = e.target;
    let img = new Image();
    img.src = URL.imageSelectURL + member.photo;
    img.onload = function () {
      self.src = img.src;
    };
    img.onerror = function () {
      self.src = userImage;
    };
  };

  render() {
    return (
      <div
        id="tt-groupContainer"
        className={
          this.state.minimize
            ? "tt-groupMember-container minimize-groupMemberContainer"
            : "tt-groupMember-container tt-familyPage-memberlist"
        }
      >
        <div
          className="tt-group-header"
          data-tip="Double click to expand and collapse this widget"
          onDoubleClick={this.minimizeMemberContainer}
        >
          Family Member Lists
        </div>
        <div className="tt-groupMember-box tt-familyMember-box">
          {this.props.familyMembersList
            ? this.props.familyMembersList.map((member, idx) => (
                <>
                  {member.userRole === "RL_STC" ? (
                    <div
                      className="tt-group-holder"
                      key={idx}
                      id={idx}
                      data-tip={
                        member.peopleName +
                        " [" +
                        (member.userRole === "RL_STC"
                          ? "Student Contact"
                          : member.userRole === "RL_STD"
                          ? "Student"
                          : member.userRole === "RL_ADM"
                          ? "Admin"
                          : "Staff") +
                        "]"
                      }
                    >
                      <Link
                        to={{
                          pathname: `/familyPage/${member.familyId}`,
                          profilePeopleId: member.peopleId,
                          familyPageOpen: true,
                          userId: member.userId,
                        }}
                      >
                        <div className="tt-memeber-img">
                          <img
                            // src={
                            //   member.photo
                            //     ? URL.imageSelectURL + member.photo
                            //     : userImage
                            // }
                            src={member.photo ? member.displayPhoto : userImage}
                            onError={(e) => this.handleError(e, member)}
                          ></img>
                        </div>
                        <div className="tt-member-name">
                          {member.peopleName}
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <div
                      className="tt-group-holder"
                      key={idx}
                      id={idx}
                      data-tip={
                        member.peopleName +
                        " [" +
                        (member.userRole === "RL_STC"
                          ? "Student Contact"
                          : member.userRole === "RL_STD"
                          ? "Student"
                          : member.userRole === "RL_ADM"
                          ? "Admin"
                          : "Staff") +
                        "]"
                      }
                    >
                      <Link
                        to={{
                          pathname: `/profile/${member.username}`,
                          profilePeopleId: member.peopleId,
                          userId: member.userId,
                          memberPhoto: member.photo,
                          otherProfile: true,
                          name: member.peopleName,
                        }}
                        onClick={(e) =>
                          this.props.getPersonProfilePosts(
                            member.peopleId,
                            "initCall"
                          )
                        }
                      >
                        <div className="tt-memeber-img">
                          <img
                            width="100%"
                            height="100%"
                            // src={
                            //   member.photo
                            //     ? URL.imageSelectURL + member.photo
                            //     : userImage
                            // }
                            src={member.photo ? member.displayPhoto : userImage}
                            onError={(e) => this.handleError(e, member)}
                          ></img>
                        </div>
                        <div className="tt-member-name">
                          {member.peopleName}
                        </div>
                      </Link>
                    </div>
                  )}

                  <ReactTooltip globalEventOff="click"></ReactTooltip>
                </>
              ))
            : "No Members"}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionToProps = {
  getPersonProfilePosts,
};

export default connect(mapStateToProps, mapActionToProps)(FamilyMemberList);
