import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { axiosPost } from "../../utils/AxiosApi";
import {
  URL,
  postType,
  userRoleCode,
  draggable,
  checkWidgetAdminOrNot,
} from "../../utils/Constants";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { showErroMessage } from "../../utils/Utils";
import { connect } from "react-redux";
import { getNotices } from "../../ducks/PostsDucks";
import moment from "moment";
import { MESSAGEHEADER, DISPLAYMESSAGE } from "../../utils/DisplayMessage";
import ModalWindow from "../UI/ModalWindow";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";

const animatedComponents = makeAnimated();

class VideoConferencing extends Component {
  state = {
    selectOptions: [],
    selectedMembers: [],
    sessionName: "",
    dateAndTime: new Date(),
    startTime: "",
    endTime: "",
    description: "",
    password: "",
    peopleSpinner: false,
    peopleArray: [],
    selectedIndividual: [],
    isOnlineClass: true,
    listSubjects: [],
    selectedSubject: null,
    disableDefaultSubject: false,
    permissionModal: false,
  };

  componentDidMount() {
    axiosPost(URL.getAllGroups, {}, (response) => {
      if (response.status === 200) {
        let groups = response.data.data;
        let selectOptions = [];
        groups.forEach((el) => {
          selectOptions.push({ label: el.name, value: el.id });
        });
        this.setState({ selectOptions });
      }
    });
    this.getGroupAssociatedPeople();
    this.getListSubjects();
  }

  getGroupAssociatedPeople = () => {
    let data = {
      peopleId: localStorage.getItem("peopleId"),
    };
    this.setState({ peopleSpinner: true }, function () {
      axiosPost(
        URL.getGroupAssociatedPeople,
        data,
        (response) => {
          if (response.status === 200) {
            let people = response.data.data;
            let peopleArray = [];
            let yearLevel = localStorage.getItem("yearLevel");
            let classroom = localStorage.getItem("classroom");
            if (people.length > 0) {
              people.forEach((el) => {
                let displayName = "";
                if (el.userRoleCode === userRoleCode.roleStudent) {
                  if (el.yearLevel === null && el.classroom != null)
                    displayName = `${el.name} ( [${el.userRole}] ${classroom} ${el.classroom})`;
                  else if (el.yearLevel != null && el.classroom === null)
                    displayName = `${el.name} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} )`;
                  else if (el.yearLevel === null && el.classroom === null)
                    displayName = `${el.name} ( [${el.userRole}])`;
                  else
                    displayName = `${el.name} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} - ${classroom} ${el.classroom})`;
                } else {
                  if (el.userRole === null) displayName = `${el.name}`;
                  else displayName = `${el.name} [${el.userRole}]`;
                }
                peopleArray.push({ label: displayName, value: el.id });
              });
            }

            this.setState({ peopleArray, peopleSpinner: false });
          }
        },
        (failur) => {
          this.setState({ peopleSpinner: false });
        }
      );
    });
  };

  getListSubjects = () => {
    axiosPost(URL.listSubject, "", (response) => {
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          this.setState({ listSubjects: response.data.data });
        }
      }
    });
  };

  handleIndividualSelect = (selectItem) => {
    this.setState({
      selectedIndividual: selectItem,
    });
  };

  handleChangeSelect = (selectedItem) => {
    this.setState({
      selectedMembers: selectedItem,
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    if (name === "endTime") {
      var st = this.state.startTime.split(":");
      var et = value.split(":");
      if (st[0] > et[0] || (st[0] == et[0] && st[1] > et[1])) {
        swal("Error", "Enter valid time for end time");
        return false;
      }
    }

    this.setState({
      [name]: value,
    });

    if (name === "startTime") {
      this.setState({
        endTime: "",
      });
    }
  };

  handleChangeCheckbox = (e) => {
    this.setState({ isOnlineClass: e.target.checked });
  };

  handleSubjectChange = (e) => {
    this.setState({
      selectedSubject: e.target.value,
      disableDefaultSubject: true,
    });
  };

  handleGeneratePassword = (e) => {
    e.preventDefault();
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.setState({ password: retVal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.sessionName !== "" &&
      this.state.startTime !== "" &&
      this.state.endTime !== "" &&
      this.state.password !== "" &&
      this.state.selectedSubject !== null
    ) {
      if (
        this.state.selectedMembers.length > 0 ||
        this.state.selectedIndividual.length > 0
      ) {
        let startTime = this.state.startTime,
          endTime = this.state.endTime,
          dateAndTime = this.state.dateAndTime,
          groups = [];

        this.state.selectedMembers.forEach((item) => {
          groups.push({ id: item.value });
        });

        let peopleIds = [];
        if (this.state.selectedIndividual.length > 0) {
          this.state.selectedIndividual.forEach((el) => {
            peopleIds.push(el.value);
          });
        }

        let param = {
          sessionName: this.state.sessionName,
          groups: groups,
          startDateTime: new Date(dateAndTime + " " + startTime),
          endDateTime: new Date(dateAndTime + " " + endTime),
          password: this.state.password,
          description: this.state.description,
          peopleIds: peopleIds,
          isOnlineClass: this.state.isOnlineClass,
          subejctId: this.state.selectedSubject,
        };
        this.setState({ showSpinner: true }, () => {
          axiosPost(
            URL.videoConferencInsert,
            param,
            (response) => {
              if (response.status === 200) {
                swal(
                  MESSAGEHEADER.success,
                  DISPLAYMESSAGE.insertVideoConference
                );
                let param = {
                  postType: postType.noticeCode,
                  createdAt: new Date(),
                  peopleId: localStorage.getItem("peopleId"),
                };
                this.props.getNotices(param);
                this.setState({ showSpinner: false });
                this.clearState();
              } else {
                showErroMessage(response);
              }
            },
            (failure) => {
              swal({
                title: MESSAGEHEADER.error,
                text: "Error inserting",
              });
              this.setState({ showSpinner: false });
            }
          );
        });
      } else {
        swal({
          title: MESSAGEHEADER.error,
          text: "Atleast group or individual member should be selected.",
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
        draggable();
      }
    } else {
      swal({
        title: MESSAGEHEADER.error,
        text: "Please fill the fields first and then click create!",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    }
  };

  clearState = () => {
    this.setState({
      selectOptions: [],
      selectedMembers: [],
      selectedIndividual: [],
      sessionName: "",
      dateAndTime: new Date(),
      startTime: "",
      endTime: "",
      description: "",
      password: "",
    });
  };

  render() {
    return (
      <>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Processing...
            </div>
          </div>
        ) : null}
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            Create Video Conference
            {checkWidgetAdminOrNot("Video Conference") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div className="tt-newListBox container-fluid">
            <div style={{ height: "60vh" }}>
              <div className="row mb-2 mt-3">
                <div className="col-md-3 text-right">
                  <strong>
                    Session name :{" "}
                    <span className="tt-assessment-module-mandatory">*</span>{" "}
                  </strong>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="sessionName"
                    value={this.state.sessionName}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 text-right">
                  <strong>
                    Choose members :{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
                <div className="col-md-4">
                  <Select
                    closeMenuOnSelect={false}
                    isClearable={true}
                    components={animatedComponents}
                    isMulti
                    options={this.state.selectOptions}
                    value={this.state.selectedMembers}
                    name="members"
                    placeholder="Select Group"
                    menuPlacement="auto"
                    onChange={this.handleChangeSelect}
                  />
                </div>
                <div className="col-md-4">
                  <Select
                    closeMenuOnSelect={false}
                    isClearable={true}
                    value={this.state.selectedIndividual}
                    components={animatedComponents}
                    isMulti
                    options={this.state.peopleArray}
                    placeholder="or Choose Individual People"
                    name="people"
                    onChange={this.handleIndividualSelect}
                    menuPlacement="auto"
                    isLoading={
                      this.state.peopleArray.length > 0 &&
                      !this.state.peopleSpinner
                        ? false
                        : true
                    }
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3 text-right"></div>
                <div className="col-md-3 text-center"></div>
                <div className="col-md-2 text-center pl-0">
                  <strong>
                    Start Time{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
                <div className="col-md-2 text-center pl-0">
                  <strong>
                    End Time{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 text-right">
                  <strong>
                    Choose Date and Time :{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
                <div className="col-md-3">
                  <input
                    type="date"
                    className="form-control"
                    name="dateAndTime"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    value={this.state.dateAndTime}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2 pl-0">
                  <input
                    type="time"
                    className="form-control"
                    name="startTime"
                    value={this.state.startTime}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2 pl-0">
                  <input
                    type="time"
                    className="form-control"
                    name="endTime"
                    // min={this.state.startTime}
                    value={this.state.endTime}
                    onChange={this.handleChange}
                    disabled={this.state.startTime === ""}
                  ></input>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3 text-right">
                  <strong>
                    Subjects :{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
                <div className="col-md-8">
                  <select
                    onChange={this.handleSubjectChange}
                    name="subject"
                    id="subject"
                    className="form-control ttedu-level-dropdown"
                  >
                    <option
                      value="All"
                      disabled={this.state.disableDefaultSubject}
                    >
                      Select Subject
                    </option>
                    {this.state.listSubjects !== undefined
                      ? this.state.listSubjects.map((subject, idx) => (
                          <option key={idx} value={subject.id}>
                            {subject.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3 text-right">
                  <strong>Description :</strong>
                </div>
                <div className="col-md-8">
                  <textarea
                    className="form-control"
                    placeholder="Add description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3 text-right">
                  <strong>
                    Set a Password :{" "}
                    <span className="tt-assessment-module-mandatory">*</span>
                  </strong>
                </div>
                <div className="col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="col-md-2">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.handleGeneratePassword}
                  >
                    Generate
                  </button>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3 text-right">
                  <strong>Online Class:</strong>
                </div>
                <div className="col-md-8">
                  <input
                    type="checkbox"
                    checked={this.state.isOnlineClass}
                    onClick={this.handleChangeCheckbox}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-right">
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={this.handleSubmit}
                >
                  Create
                </button>
                {/* <button className="tt-button tt-button-primary mr-3">
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Video Conference"
              moduleName="Conference"
              header="Add admission configuration"
              activityName="create-video-conference"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapActionsToProps = {
  getNotices: getNotices,
};

export default connect(mapStateToProps, mapActionsToProps)(VideoConferencing);
