/**
 * @author Azzeta Karmacharya
 */

import React, { Component } from "react";
import TermSetupForm from "./TermSetupForm";

export default class TermsSetup extends Component {
  render() {
    return (
      <div>
        <div className="tt-group-header row" style={{ marginTop: "-16px" }}>
          <div className="col-sm-4 text-center">
            {this.props.termAlias} Name
          </div>
          <div className="col-sm-4 text-center">Start Date</div>
          <div className="col-sm-4 text-center">End Date</div>
        </div>
        {this.props.educationLevelName === "All" ? (
          <TermSetupForm
            terms={this.props.terms}
            minStartDate={this.props.startDate}
            handleTermChange={this.props.handleTermChange}
            maxEndDate={this.props.endDate}
          />
        ) : this.props.educationLevelList !== undefined ? (
          this.props.educationLevelList.map((educationLevel, index) => {
            return (
              <div key={index} className="card mb-4">
                <div className="card-body">
                  <div className="tt-group-header">{educationLevel.name}</div>
                  <TermSetupForm
                    parentIndex={index}
                    handleTermChange={this.props.handleTermChange}
                    minStartDate={educationLevel.startDate}
                    terms={educationLevel.terms}
                    maxEndDate={educationLevel.endDate}
                  />
                </div>
              </div>
            );
          })
        ) : null}
      </div>
    );
  }
}
