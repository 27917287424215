import {
  faMinusCircle,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
// import { axiosPost } from "../../../utils/AxiosApi";
// import { URL } from "../../../utils/Constants";
import SubTopicReRender from "./SubTopicReRender";
import CustomSpinner from "../../components/common/CustomSpinner";

class SubjectTopicSetting extends Component {
  state = {
    selectedIndex: [],
    topic: [
      {
        name: "",
        description: "",
      },
    ],
    error: false,
    submitSpinner: false,
  };

  handleChange = (e, idx) => {
    let name = e.target.name;
    let value = e.target.value;
    let topic = [...this.state.topic];
    topic[idx][name] = value;
    this.setState({ topic });
  };

  addSubtopicForMainTopic = (idx) => {
    let topic = [...this.state.topic];
    let selectedIndex = [...this.state.selectedIndex];
    selectedIndex.push({ mainTopicIdx: idx });
    topic[idx].subTopic = [
      {
        name: "",
        description: "",
      },
    ];
    topic[idx].selectedIndex = [idx];
    this.setState({ topic, selectedIndex });
  };

  addTopic = () => {
    let topic = [...this.state.topic];
    topic.push({
      name: "",
      description: "",
    });
    this.setState({ topic });
  };

  removeTopic = (e, idx) => {
    e.preventDefault();
    let topic = [...this.state.topic];
    topic.splice(idx, 1);
    if (topic.length < 1) {
      swal({ title: "Error", text: "Atleast one topic is necessary" });
    } else {
      this.setState({ topic });
    }
  };

  //Add topic in Subject
  addTopicInSubject = () => {
    // let data = {
    //   subjectId: this.props.selectedSubject.id,
    //   courseTopics: this.state.topic,
    // };
    // axiosPost(URL.addTopicsToSubject, data, (response) => {
    //   if (response.status === 200) {
    //     swal({ title: "Success", text: "Topics successfully added" });
    //     this.clearState();
    //     this.props.getCourseSubjectList();
    //   }
    // });
  };

  chkForEmptyFields = (topics) => {
    topics.forEach((t) => {
      if (t.name === "") {
        t.nameErr = true;
      } else {
        t.nameErr = false;
      }
      if (t.description === "") {
        t.descErr = true;
      } else {
        t.descErr = false;
      }
      if (t.subTopic?.length > 0) {
        this.chkForEmptyFields(t.subTopic);
      }
    });

    this.setState({
      topic: topics,
    });
  };

  addCourseSyllabus = () => {
    let data = {};

    if (this.props.onlySyllabus) {
      let newTopic = [...this.state.topic];

      newTopic.forEach((item) => {
        item.coursePlanId = this.props.coursePlanIdForAdd;
      });

      data = newTopic;
    } else {
      data = {
        // educationLevelId: this.props.selectedYearLevel,
        classId: this.props.selectedClass,
        subjectId: this.props.selectedSubject,
        // peopleId: localStorage.getItem("peopleId"),
        coursePlanSyllabus: this.state.topic,
        // type: this.props.displayType
      };
    }

    if (this.props.onlySyllabus) {
      let topics = this.state.topic;

      this.chkForEmptyFields(topics);

      let err = topics.some((t) => t.nameErr || t.descErr);

      if (err) {
        return;
        // swal("Error", "Fill all the fields for topic and description");
      } else {
        axiosPost(
          URL.insertCoursePlanSyllabus,

          data,
          (response) => {
            if (response.status === 200) {
              swal({ title: "Success", text: "Topics successfully added" });
              this.clearState();

              this.props.toggleAddCourseModal();

              this.props.getCourseSyllabus();
              // this.props.toggleModal();
            }
          }
        );
      }
    } else {
      if (
        this.props.selectedClass !== "" &&
        this.props.selectedSubject !== ""
      ) {
        let topics = this.state.topic;

        this.chkForEmptyFields(topics);

        let err = topics.some((t) => t.nameErr || t.descErr);

        if (err) {
          return;
          // swal("Error", "Fill all the fields for topic and description");
        } else {
          axiosPost(URL.insertCoursePlan, data, (response) => {
            if (response.status === 200) {
              swal({ title: "Success", text: "Topics successfully added" });
              this.clearState();

              this.props.toggleAddCourseModal();

              this.props.getCourseSyllabus();
              this.props.toggleModal();
            }
          });
        }
      } else {
        swal("Error", "Select class and subject first!!");
      }
    }
  };

  clearState = () => {
    this.setState({
      topic: [
        {
          name: "",
          description: "",
          submitSpinner: false,
        },
      ],
    });
  };

  //Dealing with infinite array of topic and subtopic
  addSubtopics = (data, idx) => {
    let topic = [...this.state.topic];
    data[idx].subTopic = [
      {
        name: "",
        description: "",
      },
    ];
    this.setState({ topic });
  };

  handleReRenderChange = (e, data, idx) => {
    let name = e.target.name;
    let value = e.target.value;
    let topic = [...this.state.topic];
    data[idx][name] = value;
    this.setState({ topic });
  };

  addSubtopicAsTopic = (data) => {
    let topic = [...this.state.topic];
    data.push({
      name: "",
      description: "",
    });
    this.setState({ topic });
  };

  removeSubtopicAsTopic = (e, data, idx) => {
    e.preventDefault();
    let topic = [...this.state.topic];
    data.splice(idx, 1);
    this.setState({ topic });
  };

  addTeacherTraining = () => {
    let data = {
      teacherTrainingId: this.props.selectedTrainingId,
      coursePlanSyllabus: this.state.topic,
    };

    if (this.props.selectedTrainingId !== "") {
      let topics = this.state.topic;

      this.chkForEmptyFields(topics);

      let err = topics.some((t) => t.nameErr || t.descErr);

      if (err) {
        return;
        // swal("Error", "Fill all the fields for topic and description");
      } else {
        axiosPost(URL.insertTeacherTrainingSyllabus, data, (response) => {
          if (response.status === 200) {
            swal({ title: "Success", text: "Teacher Training Syllabus added" });
            this.clearState();
            this.props.toggleAddCourseModal();

            if (!this.props.updated) {
              this.props.toggleModal();
            }
            this.props.getCourseSyllabus();
          } else {
            this.setState({
              submitSpinner: false,
            });
          }
        });
      }
    } else {
      swal("Error", "Select training first!!");
    }
  };

  render() {
    return (
      <>
        {this.state.submitSpinner ? (
          <CustomSpinner text="Please wait. Submitting..."></CustomSpinner>
        ) : null}
        <div className="coursePlanDiv">
          <div
            style={{ height: "69vh", overflowY: "auto", overflowX: "hidden" }}
          >
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <strong className="courseTitle text-primary">
                  Course Name
                </strong>
              </div>
              <div className="col-md-4">
                <strong className="courseTitle text-primary">
                  Description
                </strong>
              </div>

              <div className="col-md-4"></div>
            </div>
            {this.state.topic.length > 0
              ? this.state.topic.map((top, idx) => {
                  return (
                    <span key={idx}>
                      <div className="row mt-1" key={idx}>
                        <div className="col-md-1 text-end courseTitle mt-1">
                          {idx + 1}.
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className={`form-control cusInput shadow-none ${
                              top.nameErr && "is-invalid"
                            }`}
                            name="name"
                            value={top.name}
                            onChange={(e) => this.handleChange(e, idx)}
                          ></input>
                          {top.nameErr && (
                            <div className="errorMsg-textField mt-1">
                              *Course name is required!
                            </div>
                          )}
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className={`form-control cusInput shadow-none ${
                              top.descErr && "is-invalid"
                            }`}
                            name="description"
                            value={top.description}
                            onChange={(e) => this.handleChange(e, idx)}
                          ></input>
                          {top.descErr && (
                            <div className="errorMsg-textField mt-1">
                              *Course description is required!
                            </div>
                          )}
                        </div>

                        <div className="col-md-auto">
                          <button
                            className="btn btn-primary cusBtn cusBtnFontSize mr-3"
                            onClick={() => this.addSubtopicForMainTopic(idx)}
                            disabled={
                              top.subTopic && top.subTopic.length > 0
                                ? true
                                : false
                            }
                          >
                            Add topics
                          </button>
                        </div>
                        <div className="col-md-1 text-center">
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="deleteColor"
                            onClick={(e) => this.removeTopic(e, idx)}
                          ></FontAwesomeIcon>
                        </div>
                        <div className="col-md-1 text-center">
                          {idx === this.state.topic.length - 1 ? (
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="editColor"
                              onClick={this.addTopic}
                            ></FontAwesomeIcon>
                          ) : null}
                        </div>
                      </div>
                      {top.subTopic && top.subTopic.length > 0 ? (
                        <div className="row mt-1 mb-1">
                          <div className="col-md-1"></div>
                          <div className="col">
                            <SubTopicReRender
                              topic={top.subTopic}
                              parentData={top}
                              parentIndex={idx}
                              parentIndexes={top.selectedIndex}
                              addSubtopics={this.addSubtopics}
                              handleReRenderChange={this.handleReRenderChange}
                              addSubtopicAsTopic={this.addSubtopicAsTopic}
                              removeSubtopicAsTopic={this.removeSubtopicAsTopic}
                            ></SubTopicReRender>
                          </div>
                        </div>
                      ) : null}
                    </span>
                  );
                })
              : null}
          </div>
          <div className="row">
            <div className="col text-end">
              {this.props.academicRelated ? (
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize mr-3"
                  onClick={
                    this.props.teacherTraining
                      ? this.addTeacherTraining
                      : this.addCourseSyllabus
                  }
                >
                  Create Course Syllabus
                </button>
              ) : (
                <button
                  className="btn btn-primary cusBtn cusBtnFontSize mr-3"
                  onClick={this.addTopicInSubject}
                >
                  Create Course
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SubjectTopicSetting;
