import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL } from "../../../../utils/Constants";
import { Spinner } from "reactstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { displaySuccessAlert } from "../../../../utils/Utils";

class SubstituteTeacherPermissionList extends Component {
  state = {
    teachers: [],
    fetching: false,
  };

  componentDidMount() {
    this.getTeachers();
  }

  getTeachers = () => {
    const data = {
      classroomId: this.props.classroomId,
      yearLevelId: this.props.yearLevelId,
    };
    this.setState({ fetching: true }, () => {
      axiosPost(URL.getStaffListForSubTeacherRoleList, data, (response) => {
        if (response.status === 200) {
          this.setState({ teachers: response.data.data, fetching: false });
        } else {
          this.setState({ fetching: false });
        }
      });
    });
  };

  deleteTeacherRole = (teacherId, substituteClassroomTeacherRoleId) => {
    const data = {
      classroomId: this.props.classroomId,
      yearLevelId: this.props.yearLevelId,
      recruitmentId: teacherId,
      substituteClassroomTeacherRoleId,
    };
    axiosPost(URL.deleteSubstituteTeacherRole, data, (response) => {
      if (response.status === 200) {
        displaySuccessAlert(response);
        let newTeachers = [...this.state.teachers];
        this.setState({
          teachers: newTeachers.filter((teacher) => {
            if (
              teacher.id === teacherId &&
              teacher.substituteClassroomTeacherRoleId ===
                substituteClassroomTeacherRoleId
            )
              return false;
            else return true;
          }),
        });
      }
    });
  };

  render() {
    return (
      <div style={{ height: "80.5vh" }}>
        <table className="table table-bordered table-striped">
          <thead className="tt-group-header">
            <tr>
              <th>Teacher</th>
              <th>Role Name</th>
              <th>Action</th>
            </tr>
          </thead>
          {this.state.fetching ? (
            <tr>
              <td colSpan={3} className="text-center">
                <div className="text-center" style={{ marginTop: "200px" }}>
                  <Spinner color="primary" />
                </div>
              </td>
            </tr>
          ) : (
            this.state.teachers.map((teacher, idx) => (
              <tbody>
                <tr key={idx}>
                  <td>{teacher.people.name}</td>
                  <td>{teacher.subsRoleName}</td>
                  <td>
                    <button
                      className="tt-button tt-button-primary"
                      onClick={() =>
                        this.deleteTeacherRole(
                          teacher.id,
                          teacher.substituteClassroomTeacherRoleId
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              </tbody>
            ))
          )}
          {!this.state.fetching && this.state.teachers.length === 0 && (
            <tr>
              <td colSpan={3} className="text-center">
                No teachers assigned.
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  }
}

export default SubstituteTeacherPermissionList;
