import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { mainUserId } from "../../utils/Constants";
import { connect } from "react-redux";
import { searchPersonalPosts } from "./../../ducks/PostsDucks";
import "../../assets/scss/ResponsiveScss/responsivePostSearch.scss";

class PostSearch extends Component {
  state = {
    minimize: false,
  };

  minimizeSearch = () => {
    this.setState({ minimize: !this.state.minimize });
  };

  render() {
    return (
      <div
        className={
          "tt-post-search-container " +
          (this.state.minimize ? "search-minimize" : "")
        }
      >
        <div
          className="tt-group-header"
          onDoubleClick={this.minimizeSearch}
          data-tip="Double click to expand and collapse this widget"
          style={{ position: "relative" }}
        >
          {this.props.familyPost
            ? "Search Family Posts"
            : this.props.groupPost
            ? "Search Group Posts"
            : this.props.memberName === "ownProfile" ||
              parseInt(this.props.userId) === parseInt(mainUserId)
            ? "Search My Posts"
            : "Search " +
              this.props.memberName.split(" ")[0] +
              "'s" +
              " Posts"}{" "}
          <span className="mobileResultDisplay">
            {this.props.searchPostBoolean
              ? `(${this.props.totalSearchResult} ${
                  this.props.totalSearchResult > 1 ? "results" : "result"
                } found)`
              : null}
          </span>
          <button
            className="tt-button tt-button-primary"
            onClick={this.props.handleCurrentPost}
            style={{ position: "absolute", right: "10px", top: "6px" }}
          >
            Current Post
          </button>
        </div>
        <div id="tt-post-search-box">
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Search Post"
              name="searchPost"
              value={this.props.searchPost}
              onChange={this.props.handleChange}
            />
          </div>
          <div className="form-group row mt-2">
            <label htmlFor="keywords" className="col-md-auto mt-1">
              <strong>Keywords: </strong>
            </label>
            <div className="col">
              <input
                type="text"
                list="labels"
                id="keywords"
                className="form-control"
                name="searchKeywords"
                value={this.props.searchKeywords}
                onChange={this.props.handleChange}
              />
              <datalist id="labels">
                <option value="Knowledge">Knowledge</option>
                <option value="Skills">Skills</option>
                <option value="Approaches">Approaches</option>
              </datalist>
            </div>
          </div>
          <div className="row tt-mobilePostCheckbox">
            <div className="col-md-6">
              <input
                className="post-checkbox"
                type="checkbox"
                checked={this.props.myPosts}
                name="myPosts"
                onChange={this.props.handleChange}
                id="feed"
              />
              <label htmlFor="feed" className="ml-2 mb-0">
                {this.props.familyPost
                  ? "Family Posts"
                  : this.props.groupPost
                  ? "Group Posts"
                  : this.props.memberName === "ownProfile" ||
                    parseInt(this.props.userId) === parseInt(mainUserId)
                  ? "My Posts"
                  : this.props.memberName.split(" ")[0] + "'s" + " Posts"}
              </label>
            </div>
            <div className="col-md-6">
              <input
                className="post-checkbox"
                type="checkbox"
                checked={this.props.academicRepo}
                name="academicRepo"
                onChange={this.props.handleChange}
                id="academicRepository"
              />
              <label htmlFor="academicRepository" className="ml-2 mb-0">
                Academic Repository
              </label>
            </div>
          </div>
          <div className="row mt-2 tt-mobilePostCheckbox">
            <div className="col">
              <strong>Date of Posts</strong>
            </div>
          </div>
          <div className="row tt-mobilePostCheckbox">
            <div className="col-md-4">
              <label htmlFor="from" className="mb-0">
                <strong>From</strong>
              </label>
            </div>
            <div className="col-md-4">
              <label htmlFor="to" className="mb-0">
                <strong>To</strong>
              </label>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="form-row tt-mobilePostCheckbox">
            <div className="form-group col-md-4">
              <input
                type="date"
                className="form-control"
                id="from"
                name="fromDate"
                value={this.props.fromDate}
                onChange={this.props.handleChange}
              />
            </div>
            <div className="form-group col-md-4">
              <input
                type="date"
                className="form-control"
                id="to"
                name="toDate"
                value={this.props.toDate}
                min={this.props.fromDate}
                onChange={this.props.handleChange}
              />
            </div>
            <div className="form-group col-md-2">
              <button
                className="tt-button tt-button-primary"
                onClick={this.props.clearDate}
                style={{ marginTop: "2px" }}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="tt-group-header text-right">
            <button
              className="tt-button tt-button-primary"
              onClick={() =>
                this.props.searchPersonalPosts(
                  this.props.fromDate,
                  this.props.toDate,
                  this.props.myPosts,
                  this.props.academicRepo,
                  this.props.peopleId,
                  this.props.searchKeywords,
                  this.props.searchPost,
                  this.props.groupId,
                  this.props.familyId,
                  "ACCEPTED"
                )
              }
            >
              Search Posts
            </button>
          </div>
        </div>
        <ReactTooltip globalEventOff="click"></ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  totalSearchResult: state.posts.totalSearchResult,
  searchPostBoolean: state.posts.searchPostBoolean,
});

const mapActionToProps = {
  searchPersonalPosts,
};
export default connect(mapStateToProps, mapActionToProps)(PostSearch);
