import React, { Component } from "react";
import SubjectCommentConfiguration from "./SubjectCommentConfiguration";
import SubjectAndScalesList from "./SubjectsAndScalesList";
import CommentWordSetting from "./CommentWordSetting";
import SetMultipleSubjectAssessment from "./SetMultipleSubjectAssessment";

import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  draggable,
  subject,
  scaleTypeCode,
} from "../../../../utils/Constants";

import "../../../../assets/scss/subjectAssessmentSetting.scss";
import swal from "sweetalert";
import ModalWindow from "../../../UI/ModalWindow";
import MarksModalBody from "./MarksModalBody";

class SubjectAssessmentSetting extends Component {
  state = {
    subjects: [],
    assessmentScales: [],
    addComments: false,
    comments: [],
    commentMinLength: 200,
    commentMaxLength: 500,
    commentTextType: "letter",
    classroomList: [],
    selectedSubjects: [],
    selectedAssessmentScales: [],
    assessmentSubjectsConfigurations: [],
    applyToMultiple: [],
    applySettingToOther: false,
    updateSubjectAssessmentSetting: false,
    subjectsSel: [],
    scalsesSel: [],
    selectedAssessmentIds: [],
    marksModal: false,
    selectedData: null,
    mainIndex: null,
    subjectIndex: null,
  };

  closeMarksConfiguration = () => {
    this.setState({
      marksModal: !this.state.marksModal,
      selectedData: null,
      mainIndex: null,
      subjectIndex: null,
    });
  };

  componentDidMount() {
    this.getAssessmentSubjectScaleConfigGroups();
    this.getAssessmentScales();
    this.getSubjects();
    this.getClassroomList();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.subjectParams !== this.props.subjectParams) {
  //     this.getClassroomList();
  //   }
  // }

  getAssessmentScales = () => {
    let param = {
      assessmentModuleCode: this.props.code,
    };
    axiosPost(URL.selectAssessmentScale, param, (response) => {
      if (response.status === 200) {
        let assessmentScales = response.data.data;
        this.setState({
          assessmentScales,
        });
      }
    });
  };

  getAssessmentSubjectScaleConfigGroups = () => {
    const propParams = this.props.subjectParams;
    const assessmentId = propParams.assessmentId,
      educationLevelId = propParams.educationLevelId,
      educationLevelDivisionId = propParams.educationLevelDivisionId,
      yearLevelId = propParams.yearLevelId,
      classroomId = propParams.classroomId;
    // isAssesmentTheoryScaleId = false;
    const yearLevelIds = [];
    yearLevelIds.push(propParams.yearLevelId);
    //add boolean for theory and Practical
    let param = {
      educationLevelId,
      educationLevelDivisionId,
      yearLevelId,
      classroomId,
      assessmentId,
      yearLevelIds,
      // isAssesmentTheoryScaleId,
    };
    axiosPost(
      URL.selectAssessmentSubjectScaleConfigGroupsForSubjectAssessmentConfig,
      param,
      (response) => {
        if (response.status === 200) {
          let assessmentSubjectsConfigurations = response.data.data;
          this.setState({ assessmentSubjectsConfigurations }, () => {
            // this.markConfigurationSetup();
            if (this.state.assessmentSubjectsConfigurations.length > 0) {
              this.setState({ updateSubjectAssessmentSetting: true });
            } else {
              this.setState({ updateSubjectAssessmentSetting: false });
            }
          });
        } else {
          swal("Error", response.data.message, "");
        }
      }
    );
  };

  getClassroomList = () => {
    let param = {
      assessmentId: this.props.subjectParams.assessmentId,
      yearLevelId: this.props.subjectParams.yearLevelId,
      classroomId: this.props.subjectParams.classroomId,
    };
    axiosPost(
      URL.selectClassroomForSubjectAssessmentSetting,
      param,
      (response) => {
        if (response.status === 200) {
          this.setState({ classroomList: response.data.data });
        }
      }
    );
  };

  getClassroomListFilterBySubject = () => {
    let subjectIdArr = [];
    let assessmentSubjectsConfigurations = [
      ...this.state.assessmentSubjectsConfigurations,
    ];
    assessmentSubjectsConfigurations.forEach((config) => {
      config.selectedSubjects.forEach((subject) => {
        subjectIdArr.push(subject.id);
      });
    });
    let params = {
      subjectIds: subjectIdArr,
      assessmentId: this.props.subjectParams.assessmentId,
      yearLevelId: this.props.subjectParams.yearLevelId,
      classroomId: this.props.subjectParams.classroomId,
    };
    axiosPost(
      URL.selectYearLevelClassroomBySubjectAssessmentBySubject,
      params,
      (response) => {
        if (response.status === 200) {
          this.setState({ classroomList: response.data.data });
        }
      }
    );
  };

  getSubjects = () => {
    axiosPost(
      URL.getSubjectListForSubjectAssessmentSetting,
      this.props.subjectParams,
      (response) => {
        if (response.status === 200) {
          let subjects = response.data.data;
          this.setState({
            subjects,
          });
        }
      }
    );
  };

  /**
   * Handles clicking of add config button
   */
  handleAddBtnClick = (e) => {
    const selectedSubjects = [...this.state.selectedSubjects],
      selectedAssessmentScales = [...this.state.selectedAssessmentScales];

    if (selectedSubjects.length <= 0) {
      swal({
        title: "Warning",
        text: "Please select one or more subjects",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return;
    }
    if (selectedAssessmentScales.length <= 0) {
      swal({
        title: "Warning",
        text: "Please select one or more AssessmentScales",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return;
    }

    let comments = [];
    const commentMinLength = this.state.commentMinLength,
      commentMaxLength = this.state.commentMaxLength,
      commentTextType = this.state.commentTextType;
    selectedSubjects.forEach((subject) => {
      subject.hasComment = this.state.addComments;
      // let commentConfiguration = { commentMinLength, commentMaxLength };
      // subject.commentConfiguration = commentConfiguration;
      subject.commentMinLength = commentMinLength;
      subject.commentMaxLength = commentMaxLength;
      subject.commentTextType = commentTextType;

      comments.push({
        subjectId: subject.id,
        assessmentId: 1,
        commentText: "",
        maxLength: 100,
        minLength: 100,
        textType: "word",
      });

      subject.selectedAssessmentScales = selectedAssessmentScales;
    });
    this.setState({ selectedSubjects, comments }, () => {
      let assessmentSubjectsConfigurations = [
        ...this.state.assessmentSubjectsConfigurations.slice(0),
      ];
      let assessmentSubjectsConfiguration = {};

      let selectedSubjects = [...this.state.selectedSubjects];
      selectedSubjects = JSON.parse(JSON.stringify(selectedSubjects));
      let selectedAssessmentScales = [...this.state.selectedAssessmentScales];
      selectedAssessmentScales = JSON.parse(
        JSON.stringify(selectedAssessmentScales)
      );

      if (assessmentSubjectsConfigurations.length > 0) {
        let totalCount = 0;
        assessmentSubjectsConfigurations.forEach((aSC) => {
          let count = 0;
          if (
            aSC.selectedAssessmentScales.length > 0 &&
            aSC.selectedAssessmentScales.length ===
              selectedAssessmentScales.length
          ) {
            aSC.selectedAssessmentScales.forEach((sAS) => {
              if (selectedAssessmentScales.length > 0) {
                for (let i = 0; i < selectedAssessmentScales.length; i++) {
                  if (selectedAssessmentScales[i].id === sAS.id) {
                    count++;
                  }
                }
              }
            });
          }
          if (count === selectedAssessmentScales.length) {
            if (selectedSubjects.length > 0) {
              selectedSubjects.forEach((el) => {
                let subCount = 0;
                if (aSC.selectedSubjects.length > 0) {
                  for (let i = 0; i < aSC.selectedSubjects.length; i++) {
                    if (aSC.selectedSubjects[i].id === el.id) {
                      subCount++;
                    }
                  }
                }
                if (subCount === 0) {
                  aSC.selectedSubjects.push(el);
                }
              });
              totalCount++;
            }
          }
        });
        if (totalCount === 0) {
          assessmentSubjectsConfiguration.selectedSubjects = selectedSubjects;
          assessmentSubjectsConfiguration.selectedAssessmentScales =
            selectedAssessmentScales;

          assessmentSubjectsConfigurations.push(
            assessmentSubjectsConfiguration
          );
        }
        this.setState(
          {
            assessmentSubjectsConfigurations,
            addComments: false,
            comments: [],
            selectedSubjects: [],
            selectedAssessmentScales: [],
            subjectsSel: [],
            scalsesSel: [],
          },
          () => {
            // this.markConfigurationSetup();
            this.getClassroomListFilterBySubject();
          }
        );
      } else {
        assessmentSubjectsConfiguration.selectedSubjects = selectedSubjects;
        assessmentSubjectsConfiguration.selectedAssessmentScales =
          selectedAssessmentScales;

        assessmentSubjectsConfigurations.push(assessmentSubjectsConfiguration);
        this.setState(
          {
            assessmentSubjectsConfigurations,
            addComments: false,
            comments: [],
            selectedSubjects: [],
            selectedAssessmentScales: [],
            subjectsSel: [],
            scalsesSel: [],
          },
          () => {
            // this.markConfigurationSetup();
            this.getClassroomListFilterBySubject();
          }
        );
      }
    });
  };

  handleApplySettingToOther = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, function () {
      if (name === "applySettingToOther") {
        if (!value) {
          this.setState({ applyToMultiple: [], selectedAssessmentIds: [] });
        }
      }
    });
  };

  handleApplyToMultiple = (e) => {
    let classroomList = [...this.state.classroomList];
    let selectedAssessmentIds = this.handleChangeMultipleSelect(e);
    let applyToMultiple = [];
    selectedAssessmentIds.forEach((id) => {
      classroomList.forEach((classroom) => {
        classroom.classrooms.forEach((section) => {
          if (section.id === parseInt(id)) {
            applyToMultiple.push(section);
          }
        });
      });
    });

    this.setState({
      applyToMultiple: applyToMultiple,
      selectedAssessmentIds: selectedAssessmentIds,
    });
  };

  /**
   * Handle scale select
   */
  handleAssessmentScaleSelectChange = (e) => {
    const assessmentScales = [...this.state.assessmentScales],
      assessmentScaleIds = this.handleChangeSelect(e);
    let selectedAssessmentScales = [];
    assessmentScaleIds.forEach((id) => {
      assessmentScales.forEach((assessmentScale) => {
        if (assessmentScale.id === parseInt(id)) {
          selectedAssessmentScales.push(assessmentScale);
        }
      });
    });
    this.setState({ selectedAssessmentScales, scalsesSel: assessmentScaleIds });
  };

  /**
   * Handles any changes in form for all fields outside arrays
   */
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleChangeMultipleSelect = (e) => {
    var options = e.target.options;
    var selectedValues = [];
    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    return selectedValues;
  };

  handleChangeSelect = (e) => {
    var options = e.target.options;
    var selectedValues = [];
    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    return selectedValues;
  };

  /**
   * Handles increasing and decreasing of minimun length of comment
   * @param incDec option to choose either increment or decrement
   * @param minMax option to choose either minimum or maximum length of comment
   */
  handleCommentLengthIncrementDecrement = (incDec, minMax) => {
    if (minMax === "min") {
      let commentMinLength = this.state.commentMinLength;
      if (incDec === "inc") {
        commentMinLength++;
      } else if (incDec === "dec") {
        commentMinLength--;
      }
      this.setState({ commentMinLength });
    } else if (minMax === "max") {
      let commentMaxLength = this.state.commentMaxLength;
      if (incDec === "inc") {
        commentMaxLength++;
      } else if (incDec === "dec") {
        commentMaxLength--;
      }
      this.setState({ commentMaxLength });
    }
  };

  handleCountChange = (idx, name, count) => {
    let selectedData = { ...this.state.selectedData };
    selectedData.marksConfigurations[idx][name] = count;
    this.setState({ selectedData });
  };

  handleMarkConfiguration = (e, mainIndex, subjectIndex) => {
    let assessmentSubjectsConfigurations = [
      ...this.state.assessmentSubjectsConfigurations,
    ];
    this.setState({
      mainIndex: mainIndex,
      subjectIndex: subjectIndex,
      selectedData: JSON.parse(
        JSON.stringify(
          assessmentSubjectsConfigurations[mainIndex].selectedSubjects[
            subjectIndex
          ]
        )
      ),
      marksModal: !this.state.marksModal,
    });
  };

  handleMaxLengthChange = (val) => {
    this.setState({ commentMaxLength: val });
  };

  handleMinLengthChange = (val) => {
    this.setState({ commentMinLength: val });
  };

  handleRemoveScaleFromTable = (e, mainIndex, subjectIdx, index) => {
    let assessmentSubjectsConfigurations = [
      ...this.state.assessmentSubjectsConfigurations,
    ];
    assessmentSubjectsConfigurations.forEach((config, i) => {
      if (i === mainIndex) {
        config.selectedSubjects.map((subject, i) => {
          subject.selectedAssessmentScales.splice(index, 1);
        });
      }
    });

    this.setState({ assessmentSubjectsConfigurations });
  };

  handleRemoveSubjectsConfig = (e, mainIndex, index) => {
    let assessmentSubjectsConfigurations = [
      ...this.state.assessmentSubjectsConfigurations,
    ];
    assessmentSubjectsConfigurations[mainIndex].selectedSubjects.splice(
      index,
      1
    );
    this.setState({ assessmentSubjectsConfigurations }, () => {
      this.getClassroomListFilterBySubject();
    });
  };

  /**
   * Handle subject select
   */
  handleSubjectSelectChange = (e) => {
    const subjects = [...this.state.subjects],
      selectedSubjectIds = this.handleChangeSelect(e);
    let selectedSubjects = [];
    selectedSubjectIds.forEach((id) => {
      subjects.forEach((subject) => {
        if (subject.id === parseInt(id)) {
          selectedSubjects.push(subject);
        }
      });
    });
    this.setState({ selectedSubjects, subjectsSel: selectedSubjectIds });
  };

  handleSubmit = () => {
    const assessmentSubjectsConfigurations = [
      ...this.state.assessmentSubjectsConfigurations,
    ];
    if (assessmentSubjectsConfigurations.length <= 0) {
      swal({
        title: "Warning",
        text: "Please create one or more configurations",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return;
    }

    let applyToMultiple = [...this.state.applyToMultiple];
    let assessmentSubjectScaleParam = [{ ...this.props.subjectParams }];

    if (applyToMultiple.length > 0) {
      applyToMultiple.forEach((classes) => {
        let newObj = {};
        newObj.assessmentId = this.props.subjectParams.assessmentId;
        newObj.yearLevelId = classes.yearLevelId;
        newObj.classroomId = classes.id;
        newObj.educationLevelDivisionId = classes.educationLevelDivisionId;
        newObj.educationLevelId = classes.educationLevelId;
        assessmentSubjectScaleParam.push(newObj);
      });
    }

    let apiParam = { ...this.props.subjectParams };
    if (apiParam) {
      let subjects = [];
      assessmentSubjectsConfigurations.forEach((config) => {
        config.selectedSubjects.forEach((selectedSubject) => {
          let subject = {};
          subject.id = selectedSubject.id;
          subject.hasComment = selectedSubject.hasComment;
          subject.commentMinLength =
            selectedSubject.assessmentSubjectCommentConfiguration
              ? selectedSubject.assessmentSubjectCommentConfiguration.minLength
              : selectedSubject.commentMinLength;
          subject.commentMaxLength =
            selectedSubject.assessmentSubjectCommentConfiguration
              ? selectedSubject.assessmentSubjectCommentConfiguration.maxLength
              : selectedSubject.commentMaxLength;
          subject.commentTextType =
            selectedSubject.assessmentSubjectCommentConfiguration
              ? selectedSubject.assessmentSubjectCommentConfiguration.textType
              : selectedSubject.commentTextType;

          //Done for update
          if (selectedSubject.assessmentSubjectCommentConfiguration) {
            selectedSubject.commentMinLength =
              selectedSubject.assessmentSubjectCommentConfiguration.minLength;
            selectedSubject.commentMaxLength =
              selectedSubject.assessmentSubjectCommentConfiguration.maxLength;
            selectedSubject.commentTextType =
              selectedSubject.assessmentSubjectCommentConfiguration.textType;
          }
          let assessmentScales = [];
          selectedSubject.selectedAssessmentScales.forEach(
            (selectedAssessmentScale) => {
              let assessmentScale = {};
              assessmentScale.id = selectedAssessmentScale.id;
              assessmentScales.push(assessmentScale);
            }
          );
          selectedSubject.assessmentScales = assessmentScales;
          subject.assessmentScales = assessmentScales;
          subjects.push(subject);
        });
      });

      let param = {
        assessmentSubjectScaleParam: assessmentSubjectScaleParam,
        subjects: subjects,
        assessmentSubjectScaleConfigGroups: assessmentSubjectsConfigurations,
      };
      let url = this.state.updateSubjectAssessmentSetting
        ? URL.updateAssessmentSubjectScaleConfiguration
        : URL.insertAssessmentSubjectScaleConfiguration;
      axiosPost(url, param, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: response.message ? response.message : "Success",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();

          this.props.toggleSubjectSettingAssessmentModal();
        } else {
          swal({
            title: "Error",
            text: response.message ? response.message : "Error",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
        }
      });
    } else {
      swal({
        title: "Critical Error",
        text: "Needed parameters not found",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return;
    }
  };

  markConfigurationSetup = () => {
    let assessmentSubjectsConfigurations = [
      ...this.state.assessmentSubjectsConfigurations,
    ];
    if (assessmentSubjectsConfigurations.length > 0) {
      assessmentSubjectsConfigurations.forEach((aSC) => {
        let marksConfigurations = [];
        if (aSC.selectedAssessmentScales.length > 0) {
          aSC.selectedAssessmentScales.forEach((sA) => {
            if (sA.assessmentScaleTypeCode === scaleTypeCode.mark) {
              let tempData = {
                assessmentScaleId: sA.id,
                theoryMax: sA.theoryMax,
                theoryMin: sA.theoryMin,
                practicalMax: sA.practicalMax,
                practicalMin: sA.practicalMin,
                assessmentScaleName: sA.scaleName,
                // subjectId: "",
              };
              marksConfigurations.push(tempData);
            }
          });
        }

        if (aSC.selectedSubjects.length > 0) {
          aSC.selectedSubjects.forEach((sS) => {
            // if (marksConfigurations.length > 0) {
            //   marksConfigurations.forEach((mC) => {
            //     mC.subjectId = sS.id;
            //   });
            // }
            if (!sS.marksConfigurations) {
              sS.marksConfigurations = marksConfigurations;
            }
            if (marksConfigurations.length > 0) {
              sS.displayMarksConfigurationBtn = true;
            } else {
              sS.displayMarksConfigurationBtn = false;
            }
          });
        }
      });
    }
    this.setState({ assessmentSubjectsConfigurations });
  };

  saveMarksConfiguration = () => {
    let assessmentSubjectsConfigurations = [
      ...this.state.assessmentSubjectsConfigurations,
    ];
    assessmentSubjectsConfigurations[this.state.mainIndex].selectedSubjects[
      this.state.subjectIndex
    ] = this.state.selectedData;
    this.setState({ assessmentSubjectsConfigurations }, function () {
      this.closeMarksConfiguration();
    });
  };

  render() {
    return (
      <div
        className="container-fluid tt-widgetContent-tab-holder"
        style={{ height: "86.8vh" }}
      >
        <div className="row">
          {/* Selected Subject and scale configurations. */}

          <SubjectCommentConfiguration
            assessmentSubjectsConfigurations={
              this.state.assessmentSubjectsConfigurations
            }
            subjects={this.state.subjects}
            addComments={this.state.addComments}
            comments={this.state.comments}
            assessmentName={this.props.assessmentName}
            className={this.props.className}
            sectionName={this.props.sectionName}
            handleMarkConfiguration={this.handleMarkConfiguration}
            handleRemoveScaleFromTable={this.handleRemoveScaleFromTable}
            handleRemoveSubjectsConfig={this.handleRemoveSubjectsConfig}
          />
        </div>
        <div className="row tt-subjectAssessmentControl">
          <div className="col-md-7 tt-subjectScaleRightBorder1 changeBorder">
            {/* Subject and Scales to be mapped. */}
            <SubjectAndScalesList
              subjects={this.state.subjects}
              assessmentScales={this.state.assessmentScales}
              subjectsSel={this.state.subjectsSel}
              scalsesSel={this.state.scalsesSel}
              handleSubjectSelectChange={this.handleSubjectSelectChange}
              handleAssessmentScaleSelectChange={
                this.handleAssessmentScaleSelectChange
              }
            ></SubjectAndScalesList>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-6  tt-subjectScaleRightBorder changeBorder">
                <CommentWordSetting
                  addComments={this.state.addComments}
                  handleChange={this.handleChange}
                  handleCommentLengthIncrementDecrement={
                    this.handleCommentLengthIncrementDecrement
                  }
                  commentMinLength={this.state.commentMinLength}
                  commentMaxLength={this.state.commentMaxLength}
                  commentTextType={this.state.commentTextType}
                  handleMaxLengthChange={this.handleMaxLengthChange}
                  handleMinLengthChange={this.handleMinLengthChange}
                />
              </div>
              <div className="col-md-6">
                <SetMultipleSubjectAssessment
                  classroomList={this.state.classroomList}
                  handleApplyToMultiple={this.handleApplyToMultiple}
                  applySettingToOther={this.state.applySettingToOther}
                  handleApplySettingToOther={this.handleApplySettingToOther}
                  selectedAssessmentIds={this.state.selectedAssessmentIds}
                  updateSubjectAssessmentSetting={
                    this.state.updateSubjectAssessmentSetting
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            {this.props.subjectConfigurationEditable ? (
              <button
                className="tt-button tt-button-primary mr-2 mt-2"
                onClick={this.handleAddBtnClick}
              >
                {"Add"}
              </button>
            ) : null}
            {this.props.subjectConfigurationEditable ? (
              this.state.updateSubjectAssessmentSetting ? (
                <button
                  className="tt-button tt-button-primary mt-2"
                  onClick={this.handleSubmit}
                >
                  {"Update"}
                </button>
              ) : (
                <button
                  className="tt-button tt-button-primary mt-2"
                  onClick={this.handleSubmit}
                >
                  {"Save setting"}
                </button>
              )
            ) : null}
          </div>
        </div>
        <ModalWindow
          modal={this.state.marksModal}
          toggleModal={this.closeMarksConfiguration}
          modalHeader="Marks Configuration"
          size="lg"
          modalBody={
            <MarksModalBody
              selectedData={this.state.selectedData}
              handleCountChange={this.handleCountChange}
              closeMarksConfiguration={this.closeMarksConfiguration}
              saveMarksConfiguration={this.saveMarksConfiguration}
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default SubjectAssessmentSetting;
