import { axiosPost } from "../utils/AxiosApi";
import { URL } from "../utils/Constants";

//Actions
const publicFlashCardLOAD = "public/FlashCardSet";
const myFlashCardLOAD = "my/FlashCardSet";
const publicSpinnerLOAD = "spinner/public";
const mySpinnerLOAD = "spinner/my";

export default function reducer(
  state = {
    publicFlashCards: [],
    myFlashCards: [],
    publicSpinner: false,
    mySpinner: false,
    publicFlashTotalRecords: 0,
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case publicFlashCardLOAD:
      newState.publicFlashCards = action.publicFlashCards;
      newState.publicFlashTotalRecords = action.publicFlashTotalRecords;
      return newState;
    case myFlashCardLOAD:
      newState.myFlashCards = action.myFlashCards;
      return newState;
    case publicSpinnerLOAD:
      newState.publicSpinner = action.publicSpinner;
      return newState;
    case mySpinnerLOAD:
      newState.mySpinner = action.mySpinner;
      return newState;
    default:
      return newState;
  }
}

//Action Creators
export function loadPublicFlashCard(publicFlashCards, publicFlashTotalRecords) {
  return {
    type: publicFlashCardLOAD,
    publicFlashCards,
    publicFlashTotalRecords,
  };
}

export function loadMyFlashCard(myFlashCards) {
  return { type: myFlashCardLOAD, myFlashCards };
}

export function loadPublicSpinner(publicSpinner) {
  return { type: publicSpinnerLOAD, publicSpinner };
}

export function loadMySpinner(mySpinner) {
  return { type: mySpinnerLOAD, mySpinner };
}

//dispatch function
export function getPublicFlashCards(searchText, offset, rowCountLimit) {
  return (dispatch) => {
    let data = {
      searchText: searchText,
      offset: offset,
      rowCountLimit: rowCountLimit,
    };
    dispatch(loadPublicSpinner(true));
    axiosPost(
      URL.getPublicFlashCards,
      data,
      (response) => {
        if (response.status === 200) {
          let publicFlashTotalRecords = response.data.totalRecordsCount;
          dispatch(loadPublicSpinner(false));
          dispatch(
            loadPublicFlashCard(response.data.data, publicFlashTotalRecords)
          );
        }
      },
      (error) => {
        dispatch(loadPublicSpinner(false));
      }
    );
  };
}

export function getMyFlashCards() {
  return (dispatch) => {
    dispatch(loadMySpinner(true));
    axiosPost(
      URL.getMyFlashCards,
      {},
      (response) => {
        if (response.status === 200) {
          dispatch(loadMySpinner(false));
          dispatch(loadMyFlashCard(response.data.data));
        }
      },
      (error) => {
        dispatch(loadMySpinner(false));
      }
    );
  };
}
