import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { displayErrorAlert } from "../../../utils/Utils";
import CustomButton from "../../common/CustomButton";
import McqResultTemplate from "../../MCQ/ListMCQ/McqResultTemplate";
import McqTestTemplate from "../../MCQ/ListMCQ/McqTestTemplate";
import ModalWindow from "../../UI/ModalWindow";

class EMCQ extends Component {
  state = {
    mcqList: [],
    mcqModal: false,
    mcqTest: [],
    minute: 15,
    seconds: 0,
    isSubmitted: false,
    spinner: false,

    mcqResult: "",
    correctAnswer: 0,
    unanswered: 0,
    incorrect: 0,
    totalAnswered: 0,
    resultHeader: "",
    mcqResultModal: false,
    fullMarks: 0,
    obtainedMarks: 0,
  };

  componentDidMount() {
    this.getPublicMCQ();
  }

  getPublicMCQ = () => {
    let selectedSubject = this.props.selectedSubject;
    let grade = this.props.grade;
    if (selectedSubject && grade) {
      let param = {
        yearLevelId: grade.id,
        subjectId: selectedSubject.id,
      };
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.getMCQBySubject,
          param,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              this.setState({ mcqList: response.data.data });
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    }
  };

  handleOptionClick = (e, quesId, oIdx) => {
    let mcqTest = this.state.mcqTest;
    if (mcqTest.mcqQuestions.length > 0) {
      mcqTest.mcqQuestions.forEach((ques) => {
        if (ques.id === quesId) {
          ques.mcqQuestionOptions.forEach((opt, idx) => {
            if (idx === oIdx) {
              opt.isSelected = true;
            } else {
              opt.isSelected = false;
            }
          });
        }
      });
    }
    this.setState({ mcqTest }, function () {
      let data = {
        mcqTestId: this.state.mcqTest.id,
        isSubmitted: this.state.isSubmitted,
      };
      let peopleMcqRecords = [];
      if (mcqTest.mcqQuestions.length > 0) {
        mcqTest.mcqQuestions.forEach((ques) => {
          ques.mcqQuestionOptions.forEach((opt, idx) => {
            if (opt.isSelected) {
              peopleMcqRecords.push({
                mcqQuestionId: ques.id,
                mcqQuestionOptionId: opt.id,
              });
            }
          });
        });
      }
      data.peopleMcqRecords = peopleMcqRecords;
      axiosPost(URL.insertMcqOption, data, (response) => {});
    });
  };

  handleSubmit = () => {
    this.setState({ isSubmitted: true }, function () {
      let data = {
        isSubmitted: this.state.isSubmitted,
        mcqTestId: this.state.mcqTest.id,
        peopleMcqRecords: [],
      };
      axiosPost(URL.insertMcqOption, data, (response) => {
        if (response.status === 200) {
          this.setState({
            resultHeader: "MCQ Result: " + this.state.mcqTest.subjectName,
          });
          swal({
            title: "Success",
            text: "MCQ successfully submitted",
            alloswOutsideClick: false,
            closeOnClickOutside: false,
          }).then(() => {
            let mcqdata = {
              mcqTestId: this.state.mcqTest.id,
              peopleId: localStorage.getItem("profilePeopleId"),
            };
            this.viewStudentResult(null, mcqdata);
          });
          this.setState({ isSubmitted: false, mcqModal: !this.state.mcqModal });
        }
      });
    });
  };

  viewStudentResult = (e, student) => {
    let data = {
      mcqTestId: student.mcqTestId,
      peopleId: student.peopleId,
    };
    axiosPost(URL.getStudentMCQResultList, data, (response) => {
      if (response.status === 200) {
        let correctAnswer = 0;
        let unanswered = 0;
        let incorrect = 0;
        let fullMarks = 0;
        let obtainedMarks = 0;
        let datum = response.data.data;
        if (datum.length > 0) {
          datum.forEach((el) => {
            fullMarks = parseInt(el.fullMarks);

            if (
              el.selectedOptionId === undefined ||
              el.selectedOptionId === null
            ) {
              unanswered++;
            } else {
              if (el.mcqQuestionOptions.length > 0) {
                el.mcqQuestionOptions.forEach((opt) => {
                  if (el.selectedOptionId === opt.id && opt.isCorrect) {
                    obtainedMarks = obtainedMarks + parseInt(el.marks);
                    correctAnswer++;
                  }
                  if (
                    el.selectedOptionId === opt.id &&
                    opt.isCorrect === false
                  ) {
                    incorrect++;
                  }
                });
              }
            }
          });
        }
        let totalAnswered = correctAnswer + incorrect;
        this.setState(
          {
            mcqResult: datum,
            correctAnswer: correctAnswer,
            incorrect: incorrect,
            unanswered: unanswered,
            totalAnswered: totalAnswered,
            fullMarks,
            obtainedMarks,
          },
          function () {
            this.setState({
              mcqResultModal: !this.state.mcqResultModal,
            });
          }
        );
      }
    });
  };

  toggleMcqResultModal = () => {
    this.setState({ mcqResultModal: !this.state.mcqResultModal });
  };

  startTest = (id) => {
    let param = {
      id: id,
    };

    axiosPost(URL.getMcqById, param, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        if (datum.mcqQuestions.length > 0) {
          datum.mcqQuestions.forEach((ques) => {
            if (ques.mcqQuestionOptions.length > 0) {
              ques.mcqQuestionOptions.forEach((opt) => {
                opt.isSelected = false;
              });
            }
          });
        }

        this.setState(
          { mcqTest: datum, minute: datum.timer, seconds: 0 },
          function () {
            let peopleMcq = datum.peopleMcq;
            if (peopleMcq && peopleMcq !== null) {
              this.setState({ isSubmitted: peopleMcq.isSubmitted });
              if (datum.mcqQuestions.length > 0) {
                datum.mcqQuestions.forEach((ques) => {
                  if (ques.mcqQuestionOptions.length > 0) {
                    ques.mcqQuestionOptions.forEach((opt) => {
                      peopleMcq.peopleMcqRecords.forEach((el) => {
                        if (el.mcqQuestionId === ques.id) {
                          if (el.mcqQuestionOptionId === opt.id) {
                            opt.isSelected = true;
                          }
                        }
                      });
                    });
                  }
                });
              }
            }
            this.setState({ mcqTest: datum }, function () {
              this.setState({ mcqModal: !this.state.mcqModal });
            });
          }
        );
      }
    });
  };

  startTimerTest = () => {
    if (this.state.mcqTest.testTypeId === 1) {
      this.startInterval = setInterval(this.startTimer, 1000);
    }
  };

  startTimer = () => {
    this.setState({ seconds: this.state.seconds - 1 }, function () {
      if (this.state.seconds === -1) {
        this.setState({ minute: this.state.minute - 1 });
        this.setState({ seconds: 59 });
      }
      if (this.state.minute === 0 && this.state.seconds === 0) {
        clearInterval(this.startInterval);
        swal({
          title: "Time over",
          text: "Your time is up. Your given answers has been submitted.",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              dangerMode: true,
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
          alloswOutsideClick: false,
        }).then((willDelete) => {
          if (willDelete) {
            this.handleSubmit();
          } else {
            this.setState({
              isSubmitted: false,
              mcqModal: !this.state.mcqModal,
            });
          }
        });
      }
    });
  };

  submitMcqTest = (e) => {
    let mcqTest = this.state.mcqTest;
    let unanswered = 0;
    if (mcqTest.mcqQuestions.length > 0) {
      mcqTest.mcqQuestions.forEach((ques) => {
        let count = 0;
        ques.mcqQuestionOptions.forEach((opt, idx) => {
          if (opt.isSelected === false) {
            count++;
          }
        });
        if (count === ques.mcqQuestionOptions.length) {
          unanswered++;
        }
      });
    }
    if (unanswered === 0) {
      this.handleSubmit();
    } else {
      swal({
        title: "Warning",
        text: `${unanswered} questions ${
          unanswered === 1 ? "hasn't" : "haven't"
        } been answered yet. Do you want to submit answers anyway?`,
        buttons: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.handleSubmit();
        }
      });
    }
  };

  toggleMcqModal = () => {
    clearInterval(this.startInterval);
    this.setState({ mcqModal: !this.state.mcqModal, isSubmitted: false });
  };

  cannotClose = () => {
    swal("Information", "Cannot close while attending the test.");
  };

  render() {
    return (
      <div
        className="description-shadow"
        style={{ margin: "unset", marginTop: "50px" }}
      >
        <div className="listBox coursePlanCss">
          <h2 className="listTitle" style={{ fontSize: "1.5rem" }}>
            MCQ List
          </h2>
        </div>
        {this.state.mcqList.length > 0 ? (
          this.state.mcqList.map((mcq, idx) => {
            return (
              <div
                key={idx}
                className="coursePlanContent"
                style={{ display: "flex", alignItems: "center" }}
              >
                <h4 className="heading-06">
                  {idx + 1}. {mcq?.topic}
                </h4>
                <CustomButton
                  text="Play"
                  customStyle="resourceBtn"
                  style={{ marginRight: "unset", marginTop: "unset" }}
                  onClick={() => this.startTest(mcq.id)}
                />
              </div>
            );
          })
        ) : (
          <div className="text-center">
            {this.state.spinner ? <Spinner /> : "No MCQs added"}
          </div>
        )}
        <ModalWindow
          modal={this.state.mcqModal}
          toggleModal={
            this.state.mcqTest.testTypeId !== 1
              ? this.toggleMcqModal
              : this.cannotClose
          }
          size="xl"
          modalHeader="Play MCQ"
          modalBody={
            <McqTestTemplate
              mcqTest={this.state.mcqTest}
              startTimerTest={this.startTimerTest}
              minute={this.state.minute}
              seconds={this.state.seconds}
              toggleMcqModal={this.toggleMcqModal}
              isSubmitted={this.state.isSubmitted}
              handleOptionClick={this.handleOptionClick}
              submitMcqTest={this.submitMcqTest}
            ></McqTestTemplate>
          }
        />

        <ModalWindow
          modal={this.state.mcqResultModal}
          toggleModal={this.toggleMcqResultModal}
          size="xl"
          modalHeader={this.state.resultHeader}
          modalBody={
            <McqResultTemplate
              mcqResult={this.state.mcqResult}
              correctAnswer={this.state.correctAnswer}
              unanswered={this.state.unanswered}
              incorrect={this.state.incorrect}
              totalAnswered={this.state.totalAnswered}
            ></McqResultTemplate>
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default EMCQ;
