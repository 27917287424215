import React, { Component } from "react";

class CreateGroup extends Component {
  state = {};
  render() {
    const errors = this.props.errors;
    return (
      <>
        <div className="tt-group-header">Create Group</div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-1">
            <strong>Group name</strong>
            <span className="tt-assessment-module-mandatory">*</span>
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className={
                errors.groupName
                  ? "form-control indicate-error"
                  : "form-control"
              }
              name="groupName"
              value={this.props.groupName}
              onChange={this.props.handleSelectChange}
            ></input>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-1">
            <strong>Description</strong>
            <span className="tt-assessment-module-mandatory">*</span>
          </div>
          <div className="col-md-4">
            <textarea
              id="description"
              placeholder="Write Something"
              name="description"
              className={
                errors.description
                  ? "form-control indicate-error"
                  : "form-control"
              }
              value={this.props.description}
              onChange={this.props.handleSelectChange}
            />
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-1">
            <strong>Sharing option</strong>
            <span className="tt-assessment-module-mandatory">*</span>
          </div>
          <div className="col-md-4 mt-1">
            <div className={errors.isPublic ? "row indicate-error" : "row"}>
              <div className="col-md-6">
                <input
                  type="radio"
                  name="isPublic"
                  value={true}
                  checked={this.props.isPublic}
                  onChange={this.props.handleSelectChange}
                />
                Public
              </div>
              <div className="col-md-6">
                <input
                  type="radio"
                  name="isPublic"
                  value={false}
                  checked={!this.props.isPublic}
                  onChange={this.props.handleSelectChange}
                />
                Private
              </div>
            </div>
          </div>
        </div>
        <div className="text-right mr-4">
          <button
            className="tt-button tt-button-primary"
            onClick={this.props.submitGroup}
          >
            Create group
          </button>
        </div>
      </>
    );
  }
}

export default CreateGroup;
