import React, { Component } from "react";

class CommentBankEditor extends Component {
  state = {};
  render() {
    return (
      <div className="tt-commentBankEditor">
        {/* <JoditEditor
          ref={this.props.editorRef}
          id={this.props.id}
          value={this.props.valueArr}
          config={config}
          tabIndex={1} // tabIndex of textarea
          onChange={(value) => {
            this.props.changeHandler(value);
          }}
          //   onBlur={this.props.changeHandler}
        /> */}
        <div className="tt-textarea row">
          <textarea
            rows="4"
            cols="100"
            id={this.props.id}
            value={this.props.valueArr}
            placeholder="Enter your comment template"
            onChange={(e) => {
              this.props.changeHandler(e);
            }}
          ></textarea>
        </div>

        {this.props.comments.map((data, idx) => (
          <button
            className="tt-chipComment-btn"
            onClick={() => this.props.clickHandler(data.fieldName)}
            key={idx}
          >
            {data.fieldName}
          </button>
        ))}
        {this.props.pronouns.map((data, idx) => (
          <button
            className="tt-chipComment-btn"
            onClick={() => this.props.clickHandler(data.name)}
            key={idx}
          >
            {data.name}
          </button>
        ))}
      </div>
    );
  }
}

export default CommentBankEditor;
