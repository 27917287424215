import React, { Component } from "react";
import StudentInformation from "./StudentInformation";

class UnassignedStudentList extends Component {
  state = {};
  render() {
    return (
      <table className="table table-bordered mb-2 text-center table-striped">
        <thead className="tt-group-header">
          <tr>
            <th className="text-center" width="5%">
              <input
                type="checkbox"
                onChange={(e) =>
                  this.props.handleCheckbox(e, "all", "admissionList")
                }
              ></input>
            </th>
            <th width="50%">Student</th>
            <th width="30%">Parent</th>
            <th width="15%">Options</th>
          </tr>
        </thead>
        <tbody>
          {this.props.admissionList.length > 0 ? (
            this.props.admissionList.map((stu, idx) => {
              return (
                <tr key={idx}>
                  <td className="text-center" width="5%">
                    <input
                      type="checkbox"
                      checked={stu.checked}
                      onChange={(e) =>
                        this.props.handleCheckbox(e, idx, "admissionList")
                      }
                    ></input>
                  </td>

                  <td width="50%">
                    <StudentInformation studentInfo={stu} />
                  </td>

                  <td width="30%">
                    {stu.studentContacts
                      ? stu.studentContacts.map((el, idx) => (
                          <div
                            className="mb-0"
                            key={idx}
                          >{`${el.contactPeople.name} (${el.relationship})`}</div>
                        ))
                      : "No Contact Peoples"}
                  </td>
                  <td width="15%">
                    <button
                      className="tt-button tt-button-primary"
                      onClick={(e) =>
                        this.props.assignIndividualStudents(e, stu.id)
                      }
                    >
                      Assign
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                No students to display
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default UnassignedStudentList;
