import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Chip from "@material-ui/core/Chip";
import ReportMagnifier from "./ReportMagnifier";

export default class ReportingConfigurationPageMiscellaneous extends Component {
  render() {
    const pageSize = this.props.pageSize,
      pageSizeUnit = this.props.pageSizeUnit,
      headerFooter = this.props.headerFooter,
      header = this.props.header,
      activeColumnHeader = this.props.activeColumnHeader,
      contentFontSizes = this.props.contentFontSizes,
      contentAlignments = this.props.contentAlignments,
      headerCustomText = this.props.headerCustomText,
      reportPages = this.props.reportPages;
    return (
      <div className="card">
        <div className="tt-group-header" style={{ borderRadius: 0 }}>
          {"Create Report (Step 4 of 4: Extras)"}
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-7 tt-printbox">
                <div className="card">
                  <div
                    className="card-header tt-group-header"
                    style={{ padding: "6px", marginBottom: 0 }}
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <select
                          name="headerFooter"
                          value={headerFooter}
                          onChange={this.props.handleFormDataChange}
                        >
                          <option value="header">Header</option>
                          <option value="footer">Footer</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {reportPages
                      ? reportPages.map((reportPage, i) => (
                          <div className="row">
                            <div className="col-md-1">
                              <input
                                type="checkbox"
                                className="form-control float-right"
                                value={i}
                                checked={header.reportPageIndexes.includes(i)}
                                onChange={
                                  this.props.handleHeaderIncludeCheckboxChange
                                }
                              />
                            </div>
                            <div className="col">
                              {` Include this ${
                                this.props.headerFooter === "header"
                                  ? "header"
                                  : "footer"
                              } in Page ${++i}`}
                            </div>
                          </div>
                        ))
                      : null}
                    {/* Height */}
                    <div className="row mt-2">
                      <div className="col">
                        <strong>Height</strong>
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          name="height"
                          value={header.height}
                          step={header.heightUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleHeaderFormDataChange}
                        />
                      </div>
                    </div>
                    {/* margins */}
                    <div className="row mt-2">
                      <div className="col">
                        <strong>Margin</strong>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="top"
                          name="marginTop"
                          value={
                            header.marginSizeUnit === "mm"
                              ? header.marginTop
                              : header.marginTop?.toFixed(3)
                          }
                          step={header.marginSizeUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleHeaderMarginFormDataChange}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="left"
                          name="marginLeft"
                          value={
                            header.marginSizeUnit === "mm"
                              ? header.marginLeft
                              : header.marginLeft?.toFixed(3)
                          }
                          step={header.marginSizeUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleHeaderMarginFormDataChange}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="right"
                          name="marginRight"
                          value={
                            header.marginSizeUnit === "mm"
                              ? header.marginRight
                              : header.marginRight?.toFixed(3)
                          }
                          step={header.marginSizeUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleHeaderMarginFormDataChange}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="bottom"
                          name="marginBottom"
                          value={
                            header.marginSizeUnit === "mm"
                              ? header.marginBottom
                              : header.marginBottom?.toFixed(3)
                          }
                          step={header.marginSizeUnit === "mm" ? 1 : 0.1}
                          onChange={this.props.handleHeaderMarginFormDataChange}
                        />
                      </div>
                    </div>

                    {/* Layout dropdown */}
                    <div className="row mt-2">
                      <div className="col">
                        <strong>Layout</strong>
                      </div>
                      <div className="col">
                        <select
                          name="layout"
                          className="form-control"
                          value={header.layout}
                          onChange={this.props.handleHeaderLayoutChange}
                        >
                          <option disabled selected>
                            Layout
                          </option>
                          <option value="1col">1 - column</option>
                          <option value="2col">2 - column(50/50)</option>
                          <option value="2cte">2 - column(20/80)</option>
                          <option value="3col">3 - column(20/60/20)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mt-2">
                  <div
                    className="card-header tt-group-header"
                    style={{ padding: "6px", marginBottom: 0 }}
                  >
                    <div className="row">
                      <div className="col-md-7 mt-1">
                        {"Add Elements in " +
                          (header.layout === "1col"
                            ? "1 - column"
                            : header.layout === "2col"
                            ? "2 - column(50/50)"
                            : header.layout === "2cte"
                            ? "2 - column(20/80)"
                            : header.layout === "3col"
                            ? "3 - column(20/60/20)"
                            : null)}
                      </div>
                      <div className="col-md-5">
                        <select
                          className="form-control"
                          value={activeColumnHeader}
                          name="activeColumnHeader"
                          onChange={this.props.handleFormDataChange}
                        >
                          {header.columns
                            ? header.columns.map((column, i) => (
                                <option value={i}>{`Column ${++i} width(${
                                  column.width
                                })`}</option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {header && header.columns
                      ? header.columns.map((column, i) => (
                          <div className="row">
                            <div className="col">
                              <table
                                className="table table-bordered table-striped"
                                style={
                                  activeColumnHeader == i
                                    ? {
                                        boxShadow:
                                          "2px 3px 3px rgba(0,0,0,0.33)",
                                      }
                                    : null
                                }
                              >
                                <thead className="tt-group-header text-center reportTable">
                                  <tr>
                                    <th
                                      style={{ width: "30%" }}
                                    >{`Column ${header.layout} ${column.width}`}</th>
                                    <th>Font size</th>
                                    <th>Align</th>
                                    <th>
                                      Options
                                      <div className="col">
                                        <select
                                          name="verticalAlign"
                                          value={column.verticalAlign}
                                          onChange={(e) =>
                                            this.props.handleHeaderVerticalAlignement(
                                              e,
                                              i
                                            )
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          <option value="top">Top</option>
                                          <option value="middle">Middle</option>
                                          <option value="bottom">Bottom</option>
                                        </select>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {column.contents && column.contents.length > 0
                                    ? column.contents.map(
                                        (content, contentIndex) => (
                                          <tr>
                                            <td>{content.name}</td>
                                            <td>
                                              {content.type === "IMG" ? null : (
                                                <select
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                  value={
                                                    content.fontSize
                                                      ? content.fontSize
                                                      : "10"
                                                  }
                                                  onChange={(e) =>
                                                    this.props.handleHeaderContentFontSize(
                                                      e,
                                                      i,
                                                      contentIndex
                                                    )
                                                  }
                                                >
                                                  {contentFontSizes
                                                    ? contentFontSizes.map(
                                                        (fontSize) => (
                                                          <option
                                                            value={fontSize}
                                                          >
                                                            {fontSize}
                                                          </option>
                                                        )
                                                      )
                                                    : null}
                                                </select>
                                              )}
                                            </td>
                                            <td>
                                              <select
                                                style={{
                                                  width: "100%",
                                                }}
                                                value={
                                                  content.horizontalAlign
                                                    ? content.horizontalAlign
                                                    : "center"
                                                }
                                                onChange={(e) =>
                                                  this.props.handleHeaderContentHorizontalAlignement(
                                                    e,
                                                    i,
                                                    contentIndex
                                                  )
                                                }
                                              >
                                                {contentAlignments
                                                  ? contentAlignments.map(
                                                      (alignment) => (
                                                        <option
                                                          value={alignment}
                                                        >
                                                          {alignment}
                                                        </option>
                                                      )
                                                    )
                                                  : null}
                                              </select>
                                            </td>
                                            <td>
                                              <div className="row">
                                                <div className="col">
                                                  <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                    className="tt-cursor-pointer"
                                                    onClick={(e) =>
                                                      this.props.handleHeaderContentRemoval(
                                                        i,
                                                        contentIndex
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col">
                                                  {contentIndex !== 0 ? (
                                                    <FontAwesomeIcon
                                                      icon={faCaretUp}
                                                      size="2x"
                                                      className="tt-cursor-pointer"
                                                      onClick={() =>
                                                        this.props.handleHeaderContentReorderBtnClick(
                                                          i,
                                                          contentIndex,
                                                          --contentIndex
                                                        )
                                                      }
                                                    />
                                                  ) : null}
                                                </div>
                                                <div className="col">
                                                  {contentIndex !==
                                                  column.contents.length - 1 ? (
                                                    <FontAwesomeIcon
                                                      icon={faCaretDown}
                                                      size="2x"
                                                      className="tt-cursor-pointer"
                                                      onClick={() =>
                                                        this.props.handleHeaderContentReorderBtnClick(
                                                          i,
                                                          contentIndex,
                                                          ++contentIndex
                                                        )
                                                      }
                                                    />
                                                  ) : null}
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    {/* Row Addition */}
                    <div className="card">
                      <div
                        className="card-header tt-group-header"
                        style={{ padding: "6px" }}
                      >
                        {"Add Row"}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <Chip
                              label="School Name"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "schoolName"
                                )
                              }
                            />

                            <Chip
                              label="Education Level"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "educationLevel"
                                )
                              }
                            />

                            <Chip
                              label="Year Level"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "yearLevel"
                                )
                              }
                            />

                            <Chip
                              label="Classroom"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "classroom"
                                )
                              }
                            />

                            <Chip
                              label="Student Name"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "studentName"
                                )
                              }
                            />

                            <Chip
                              label="Roll Number"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "rollNumber"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <Chip
                              label="School Address"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "schoolAddress"
                                )
                              }
                            />

                            <Chip
                              label="Report Name"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "reportName"
                                )
                              }
                            />

                            <Chip
                              label="Report Publication Date"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "publishingDate"
                                )
                              }
                            />

                            <Chip
                              label="Academic Year"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "academicYear"
                                )
                              }
                            />

                            <Chip
                              label="Student Photo"
                              color="primary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "studentPic"
                                )
                              }
                            />
                            {headerFooter === "footer" ? (
                              <Chip
                                label="Page Number"
                                color="primary"
                                onClick={(e) =>
                                  this.props.handleAddRowInHeaderChipClick(
                                    "pageNumber"
                                  )
                                }
                              />
                            ) : null}

                            <Chip
                              label="Separator"
                              color="secondary"
                              onClick={(e) =>
                                this.props.handleAddRowInHeaderChipClick(
                                  "separator"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer tt-group-header"
                        style={{ padding: "6px", marginBottom: 0 }}
                      >
                        <div className="row">
                          <div className="col">
                            <input
                              type="text"
                              name="headerCustomText"
                              value={headerCustomText}
                              style={{ width: "100%" }}
                              onChange={this.props.handleFormDataChange}
                            />
                          </div>
                          <div className="col">
                            <button
                              className="tt-button tt-button-primary"
                              onClick={
                                this.props.handleAddCustomRowInHeaderBtnClick
                              }
                            >
                              Add Row
                            </button>
                          </div>
                          <div className="col text-right">
                            <input
                              id="myInput"
                              type="file"
                              ref={(ref) => (this.upload = ref)}
                              onChange={this.props.handleAddImageInHeader}
                              style={{ display: "none" }}
                              accept="image/x-png,image/gif,image/jpeg"
                            />
                            <button
                              className="tt-button tt-button-primary"
                              onClick={() => {
                                this.upload.click();
                              }}
                            >
                              Add Image File
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-5 tt-printbox1"
                style={{ backgroundColor: "#ccc", position: "relative" }}
              >
                <ReportMagnifier
                  handleZoomIn={this.props.handleZoomIn}
                  handleZoomOut={this.props.handleZoomOut}
                />
                <div
                  className="tt-printPreview"
                  style={{
                    zoom: this.props.zoom + "%",
                    height: `${pageSize.height}${pageSizeUnit}`,
                    maxWidth: `${pageSize.width}${pageSizeUnit}`,
                    width: `${pageSize.width}${pageSizeUnit}`,
                    paddingTop: `${header.marginTop}${header.marginSizeUnit}`,
                    paddingLeft: `${header.marginLeft}${header.marginSizeUnit}`,
                    paddingRight: `${header.marginRight}${header.marginSizeUnit}`,
                    paddingBottom: `${header.marginBottom}${header.marginSizeUnit}`,
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {this.props.headerFooter === "footer" ? (
                    <div
                      style={{
                        height:
                          pageSize.height -
                          header.marginTop -
                          header.marginBottom -
                          header.height +
                          header.heightUnit,
                        width: "100%",
                      }}
                    ></div>
                  ) : null}
                  {header && header.columns
                    ? header.columns.map((column) => (
                        <div
                          className={`col-md-${column.width} tt_align_items_v_${
                            column.verticalAlign
                              ? column.verticalAlign
                              : "middle"
                          }`}
                          style={{
                            height: header.height + header.heightUnit,
                            maxWidth: "100%",
                            border: "1px dashed #ccc",
                            overflow: "hidden",
                            padding: "5px",
                          }}
                        >
                          {column.contents && column.contents.length > 0
                            ? column.contents.map((content) => {
                                if (
                                  (content.type && content.type === "IMG") ||
                                  (content.type && content.type === "linkImg")
                                ) {
                                  return (
                                    <div
                                      style={{
                                        textAlign: content.horizontalAlign,
                                        fontSize: content.fontSize
                                          ? content.fontSize + "pt"
                                          : "10pt",
                                      }}
                                    >
                                      <img
                                        src={
                                          content.type === "linkImg"
                                            ? content.file
                                            : window.URL.createObjectURL(
                                                content.file
                                              )
                                        }
                                        style={{
                                          width: content.width,
                                          height: content.height,
                                        }}
                                        alt=""
                                      ></img>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      style={{
                                        textAlign: content.horizontalAlign,
                                        fontSize: content.fontSize
                                          ? content.fontSize + "pt"
                                          : "10pt",
                                      }}
                                    >
                                      {content.name}
                                    </div>
                                  );
                                }
                              })
                            : null}
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col"></div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handlePreviousBtnClick}
              >
                Previous
              </button>
            </div>
            <div className="col-2">
              <button
                className="tt-button tt-button-primary tt-button-fullWidth"
                onClick={this.props.handleSaveBtnClick}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
