import React from "react";

function CustomDeleteButton(props) {
  return (
    <span
      className="material-icons-round actionIcon deleteColor"
      onClick={props.onClick}
      style={props.style}
    >
      delete_forever
    </span>
  );
}

export default CustomDeleteButton;
