import React, { Component } from "react";
import icon01 from "../../assets/images/icon-01.png";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomCard from "../../components/common/CustomCard";

import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
export class ResourcesSummary extends Component {
  state = {
    resourcesSummary: [
      {
        name: "School Curricula",
        value: 200,
        changeText: "",
        subtitle: "Today",
        materialIcon: "library_books",
      },
      {
        name: "Sign Language",
        value: 100,
        changeText: "",
        subtitle: "Today",
        materialIcon: "sign_language",
      },
      {
        name: "A/V Resources",
        value: 50,
        changeText: "",
        subtitle: "Today",
        materialIcon: "volume_upvideocam",
      },
      {
        name: "Textbooks",
        value: 800,
        changeText: "",
        subtitle: "Today",
        materialIcon: "collections_bookmark",
      },
      {
        name: "Syllabus",
        value: 100,
        changeText: "",
        subtitle: "Today",
        materialIcon: "local_library",
      },
    ],
  };

  componentDidMount() {
    this.getResourceSummary();
  }

  getResourceSummary = () => {
    axiosPost(URL.getResourceViewerStat, {}, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        let resourceData = this.state.resourcesSummary;
        resourceData.forEach((el) => {
          if (el.name === "School Curricula") {
            el.value = datum.cirriculum;
          }
          if (el.name === "Sign Langugage") {
            el.value = datum.signLanguage;
          }
          if (el.name === "A/V Resource") {
            el.value = datum.audioVisual;
          }
          if (el.name === "Textbooks") {
            el.value = datum.textbooks;
          }
          if (el.name === "Syllabus") {
            el.value = datum.syllabus;
          }
        });
        this.setState({ resourcesSummary: resourceData });
      }
    });
  };

  render() {
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <div className="whiteContainer">
              <CustomPageHeader title="Resources Summary"></CustomPageHeader>
              <div className="container mt-4">
                <div className="row">
                  {this.state.resourcesSummary.map((rs, idx) => (
                    <div className="col-md-6 col-lg-4">
                      <CustomCard label={rs} index={idx}></CustomCard>
                      {/* <div className="resourceCountBox">
                        <div className="res-icon-image">
                          <img src={icon01} alt="icon-01" />
                        </div>
                        <p>{rs.name}</p>
                        <h3>{rs.count}</h3>
                      </div> */}
                    </div>
                  ))}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResourcesSummary;
