import React, { Component } from "react";

class NoteDisplay extends Component {
  state = {};
  render() {
    return (
      <div className="tt-errorBackground">
        <div className="tt-errorMessageHolder">
          <div className="tt-group-header" style={{ fontSize: "16px" }}>
            Note
          </div>
          <div style={{ fontSize: "15px" }}>
            {!this.props.textContent
              ? `To add a new ${this.props.item}, you must first add ${this.props.dependentItem}. Please add ${this.props.dependentItem} and then add a new ${this.props.item}`
              : this.props.textContent}
          </div>
        </div>
      </div>
    );
  }
}

export default NoteDisplay;
