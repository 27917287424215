import React, { Component } from "react";
import Navbar from "./../Profile/Navbar";
import { axiosPost } from "./../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { userRole, userRoleCode } from "./../../utils/Constants";
import history from "../../utils/History";
import swal from "@sweetalert/with-react";
import DatePicker from "./../UI/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import UserSetting from "../UserSetting/UserSetting";
import Snackbar from "@material-ui/core/Snackbar";
import "../../assets/scss/userSetting.scss";
import "../../assets/scss/widgetArea.scss";
import { Spinner } from "reactstrap";

class UserManager extends Component {
  state = {
    dropdownOpen: false,
    genders: [],
    userRoles: [],
    subRoles: [],
    fullName: "",
    gender: "",
    DOE: { year: null, month: null, day: null },
    email: "",
    userRoleCode: "",
    subRoleId: "",
    countrys: [],
    linkCopied: false,
    submitDisabled: false,
    showSpinner: false,
  };

  componentDidMount() {
    userRole !== userRoleCode.roleAdmin && history.push("/");
    document.title = this.props.noNavbar ? "Profile" : "User Manager";
    this.getGenders();
    this.getUserRoles();
    this.getCountry();
  }

  toggleMenu = () => {
    this.setState(
      { dropdownOpen: !this.state.dropdownOpen, setting: false },
      function () {
        document.getElementById("tt-subMenu").style.display = "none";
      }
    );
  };

  openSubMenu = (e) => {
    e.preventDefault();
    this.setState({ setting: !this.state.setting }, function () {
      let subMenu = document.getElementById("tt-subMenu");
      if (subMenu.style.display === "block") {
        subMenu.style.display = "none";
      } else {
        subMenu.style.display = "block";
      }
    });
  };

  getCountry = () => {
    axiosPost(URL.getCountry, {}, (response) => {
      if (response.status === 200) {
        this.setState({ countrys: response.data.data });
      }
    });
  };

  getGenders = () => {
    axiosPost(URL.getGenders, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ genders: data });
      }
    });
  };

  getUserRoles = () => {
    axiosPost(URL.getUserRoles, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ userRoles: data });
      }
    });
  };

  getSubRoles = (roleCode) => {
    axiosPost(URL.getSubRolesByUserRole, { roleCode }, (response) => {
      if (response.status === 200) {
        this.setState({ subRoles: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value }, () => {
      if (name === "userRoleCode") {
        this.getSubRoles(this.state.userRoleCode);
      }
    });
  };

  copyToClipboard = (data) => {
    let dummy1 = document.createElement("input");
    document.body.appendChild(dummy1);
    dummy1.setAttribute("value", data);
    dummy1.select();
    document.execCommand("copy");
    document.body.removeChild(dummy1);
  };

  renderLinkCopyText = () => {
    this.setState({ linkCopied: true });
    // setTimeout(() => {
    //   this.setState({ linkCopied: false });
    // }, 1500);
  };

  handleSave = (e) => {
    e.preventDefault();
    let error = false;
    let data = {
      name: this.state.fullName,
      gender: this.state.gender,
      dateOfBirth: new Date(
        `${this.state.DOE.year}-${this.state.DOE.month}-${this.state.DOE.day}`
      ),
      emailAddress: this.state.email,
      roleCode: this.state.userRoleCode,
      subRoleId: this.state.subRoleId,
    };
    for (const property in data) {
      if (data[property] === "" || data[property] === {}) {
        error = true;
      }
    }

    for (const property in this.state.DOE) {
      if (this.state.DOE[property] === null) {
        error = true;
      }
    }

    if (!error) {
      this.setState({ submitDisabled: true, showSpinner: true }, () => {
        axiosPost(
          URL.createUser,
          data,
          (response) => {
            if (response.status === 200) {
              const username = response.data.data.username;
              const password = response.data.data.password;
              this.setState({ submitDisabled: false, showSpinner: false });
              swal({
                title: `User Created Successfully`,
                content: (
                  <div>
                    New user with username: {username} and password: {password}{" "}
                    has been created.
                    <br></br>
                    <button
                      className="tt-button tt-button-primary"
                      onClick={() => {
                        this.copyToClipboard(
                          `New user with username: ${username} and password: ${password} has been created.`
                        );
                        this.renderLinkCopyText();
                      }}
                    >
                      <div>{this.state.linkCopied ? "Copied" : "Copy"}</div>
                    </button>
                  </div>
                ),
              }).then((res) => {
                history.push(`${process.env.PUBLIC_URL}/usermanager`);
              });
            }
          },
          (err) => {
            this.setState({ submitDisabled: false, showSpinner: false });
          }
        );
      });
    } else {
      swal({
        title: "Warning",
        text: "Please fill all the form.",
      });
    }
  };

  handleDateChange = (
    objKey,
    objValue,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    let newState = { ...this.state.DOE };
    newState[objKey] = objValue;
    this.setState({ DOE: newState });
  };

  handleBdayChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    let newState = { ...this.state.DOE };
    newState.year = e.target.value.substring(0, 4);
    newState.month =
      e.target.value.substring(5, 7).substring(0, 1) === "0"
        ? e.target.value.substring(6, 7)
        : e.target.value.substring(5, 7);
    newState.day =
      e.target.value.substring(8, 10).substring(0, 1) === "0"
        ? e.target.value.substring(9, 10)
        : e.target.value.substring(8, 10);

    this.setState({ DOE: newState });
  };

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.linkCopied}
          autoHideDuration={1000}
          onClose={() => this.setState({ linkCopied: false })}
          message="Copied to Clipboard"
        />
        <Navbar
          dropdownOpen={this.state.dropdownOpen}
          toggleMenu={this.toggleMenu}
          openSubMenu={this.openSubMenu}
          nonSystemSetup={false}
        />
        <div
          className="container-fluid tt-postTabs tt-inner-content tt-userSetting mt-3"
          style={{ height: "88vh" }}
        >
          <div className="row">
            <div className="col">
              <Tabs>
                <TabList>
                  <Tab tabFor="createUser">Create User</Tab>
                  <Tab tabFor="listUser">User List</Tab>
                </TabList>
                <TabPanel tabId="createUser">
                  <div className="p-2">
                    <form onSubmit={this.handleSave}>
                      {/* <div className="tt-group-header">User Managers</div> */}
                      <div className="row mb-2">
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                          <label>
                            <strong>Full Name:</strong>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="text"
                            name="fullName"
                            className="form-control"
                            maxLength={255}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                          <label>
                            <strong>Gender</strong>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className={"form-control"}
                            name="gender"
                            onChange={this.handleChange}
                          >
                            <option value="" disabled selected>
                              Choose gender
                            </option>
                            {this.state.genders.map((gender) => (
                              <option value={gender.gender}>
                                {gender.gender}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                          <label>
                            <strong>Date of birth </strong>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <DatePicker
                            DOE={this.state.DOE}
                            bday={true}
                            handleDateChange={this.handleDateChange}
                            config={false}
                            userManager={true}
                          />
                        </div>
                        <div className="col-md-1">
                          <input
                            type="date"
                            name="doe"
                            className="bday-picker"
                            min={"1900-01-01"}
                            style={{
                              top: "8px !important",
                              marginLeft: "-55px",
                            }}
                            max={new Date().toISOString().substring(0, 10)}
                            onChange={
                              !this.props.config
                                ? (e) => this.handleBdayChange(e)
                                : null
                            }
                          />
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            className="bday-fa"
                            style={{ display: "flex" }}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                          <label>
                            <strong>Roles</strong>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className={"form-control"}
                            name="userRoleCode"
                            onChange={this.handleChange}
                          >
                            <option value="" disabled selected>
                              Choose role
                            </option>
                            {this.state.userRoles
                              .slice(0, 1)
                              .map((userRole) => (
                                <option value={userRole.code}>
                                  {userRole.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                          <label>
                            <strong>Sub Roles</strong>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <select
                            className={"form-control"}
                            name="subRoleId"
                            onChange={this.handleChange}
                          >
                            <option value="" disabled selected>
                              Choose sub role
                            </option>
                            {this.state.subRoles.map((subRole) => (
                              <option value={subRole.id}>{subRole.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                          <label>
                            <strong>Email:</strong>
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            maxLength={255}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-1"></div>
                        <div className="col-md-3"></div>
                        <div className="col-md-7"></div>
                        <div className="col-md-1">
                          <input
                            type="submit"
                            value="Submit"
                            className="tt-button tt-button-primary"
                            disabled={this.state.submitDisabled}
                          />
                          {/* <button onClick={this.handleSave}>Create</button> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </TabPanel>
                <TabPanel tabId="listUser">
                  <UserSetting userManager={true}></UserSetting>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Creating User ...
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default UserManager;
