import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { questionType, URL } from "../../utils/Constants";
import ExamFormQuestions from "./ExamFormQuestions";
import swal from "sweetalert";

class ExamForm extends Component {
  state = {
    educationLevels: [],
    educationLevelCode: "",
    educationLevelId: "",
    yearLevels: [],
    yearLevelId: "",
    classRooms: [],
    classRoomId: "",
    educationLevelDivisions: [],
    educationLevelDivisionId: "",
    assessments: [],
    assessmentId: "",
    assessmentSubjects: [],
    subjectId: "",
    assessments: [],
    assessmentId: "",
    subjectQuestions: [],
    theoryQuestions: [],
    practicalQuestions: [],
  };

  componentDidMount() {
    this.getEducationLevel();
  }

  getEducationLevel = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ educationLevels: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (
        this.state.yearLevelId !== "" &&
        this.state.educationLevelId !== "" &&
        this.state.classRoomId !== "" &&
        this.state.educationLevelDivisionId !== "" &&
        this.state.assessmentId !== ""
      ) {
        this.getAssessmentSubjects();
      }
      if (name === "educationLevelId") {
        this.getYearLevelByEducationLevel(value);
        const educationLevel = this.state.educationLevels?.find(
          (e) => e.id === parseInt(value)
        );
        this.setState({
          assessmentModuleCode: educationLevel
            ? educationLevel.assessmentModuleCode
            : null,
          educationLevelDivisions: educationLevel
            ? educationLevel.educationLevelDivisions
            : [],
          yearLevelId: "",
          classRoomId: "",
          assessmentId: "",
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
        educationLevel &&
          this.getAssessments(educationLevel.assessmentModuleCode);
      }
      if (name === "educationLevelDivisionId") {
        this.setState({
          yearLevelId: "",
          classRoomId: "",
          assessmentId: "",
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
      }
      if (name === "yearLevelId") {
        const yearLevel = this.state.yearLevels.find(
          (y) => y.id === parseInt(value)
        );
        this.setState({
          classRooms: yearLevel ? yearLevel.classrooms : [],
          classRoomId: "",
          assessmentId: "",
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
      }
      if (name === "classRoomId") {
        this.setState({
          assessmentId: "",
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
      }
      if (name === "assessmentId") {
        this.setState({
          assessmentSubjects: [],
          subjectId: "",
          theoryQuestions: [],
          practicalQuestions: [],
        });
        if (
          this.state.yearLevelId !== "" &&
          this.state.educationLevelId !== "" &&
          this.state.classRoomId !== "" &&
          this.state.educationLevelDivisionId !== "" &&
          this.state.assessmentId !== ""
        ) {
          this.getAssessmentSubjects();
        }
      }
      if (name === "subjectId") {
        const subject = this.state.assessmentSubjects.find(
          (s) => s.subjectId === parseInt(value)
        );
        if (subject && subject.assessmentScale) {
          // this.setState({
          //   hasTheory: subject.hasTheory,
          //   hasPractical: subject.hasPractical,
          // });
          // const assessmentScaleMark =
          //   subject.assessmentScale.assessmentScaleMark;
          // this.setState({
          //   theoryMaxMark: assessmentScaleMark.theoryMax,
          //   practicalMaxMark: assessmentScaleMark.practicalMax,
          // });

          this.getSubjectQuestions();
        }
      }
    });
  };

  getSubjectQuestions = () => {
    const data = {
      educationLevelId: this.state.educationLevelId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      yearLevelId: this.state.yearLevelId,
      classroomId: this.state.classRoomId,
      subjectId: this.state.subjectId,
      assessmentId: this.state.assessmentId,
    };
    axiosPost(
      URL.selectSubjectQuestion,
      data,
      (response) => {
        const questions = response.data.data;
        const theoryQuestions =
          questions && questions.filter((q) => q.isTheory);
        const practicalQuestions =
          questions && questions.filter((q) => !q.isTheory);
        this.setState({ theoryQuestions, practicalQuestions });
      },
      (err) => {}
    );
  };

  getYearLevelByEducationLevel = (value) => {
    axiosPost(
      URL.getYearLevelByEducationLevel,
      { educationLevelId: value },
      (response) => {
        if (response.status === 200) {
          this.setState({ yearLevels: response.data.data });
        }
      },
      (error) => {}
    );
  };

  getAssessments = (code) => {
    const data = {
      assessmentModuleCode: code,
    };
    axiosPost(URL.selectAssessments, data, (resp) => {
      if (resp.status === 200) {
        let assessments = resp.data.data;
        this.setState({ assessments });
      }
    });
  };

  getAssessmentSubjects = () => {
    const data = {
      yearLevelId: this.state.yearLevelId,
      educationLevelId: this.state.educationLevelId,
      classroomId: this.state.classRoomId,
      educationLevelDivisionId: this.state.educationLevelDivisionId,
      assessmentId: this.state.assessmentId,
    };

    axiosPost(
      URL.selectSystemExamSubjects,
      data,
      (response) => {
        if (response.status === 200) {
          let assessmentSubjects = response.data.data;
          this.setState({ assessmentSubjects });
        }
      },
      (err) => {}
    );
  };

  handleAnswerChange = (e, stateName, qType, index, optionIndex) => {
    const { name, value, type, checked } = e.target;
    const ans = type === "checkbox" ? checked : value;
    let questions = [...this.state[stateName]];
    if (qType === questionType.SUBJECTIVE) {
      questions[index][name] = ans;
    } else if (qType === questionType.OBJECTIVE) {
      let options = questions[index].subjectQuestionOptions;
      options = options.map((o, oid) => {
        o["ticked"] = optionIndex === oid ? true : false;
        return o;
      });
      questions[index].subjectQuestionOptions = options;
    } else {
      questions[index][name] = "";
    }
    this.setState({ [stateName]: questions });
  };

  handleSubmit = () => {
    const answers = [
      ...this.state.theoryQuestions,
      ...this.state.practicalQuestions,
    ];

    //empty subjective answers check
    const sExist = answers.some(
      (a) => a.questionType === questionType.SUBJECTIVE
    );
    const oExist = answers.some(
      (a) => a.questionType === questionType.OBJECTIVE
    );

    const ansS = answers
      .filter((a) => a.questionType === questionType.SUBJECTIVE)
      .every((a) => a.subjectiveAnswer != null && a.subjectiveAnswer !== "");

    //at least one checked subjective answer
    const ansO = answers
      .filter((a) => a.questionType === questionType.OBJECTIVE)
      .every(
        (a) =>
          a.subjectQuestionOptions != null &&
          a.subjectQuestionOptions.some((opt) => opt.ticked)
      );

    let e = null;
    if (sExist && oExist) {
      e = !ansS && ansO;
    } else if (sExist && !oExist) {
      e = !ansS;
    } else if (!sExist && oExist) {
      e = ansO;
    }

    if (e) {
      swal("Warning", "Some answers are missing. Please answer them first.");
    } else {
      swal({
        title: "Success",
        text: "Are you sure to submit your answers?. Please check and verify your answers first before submitting.",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      }).then(() => {
        const data = answers.map((a) => {
          if (a.questionType === questionType.SUBJECTIVE) {
            return {
              subjectQuestionId: a.id,
              subjectiveAnswer: a.subjectiveAnswer,
              subjectQuestionOptionId: null,
              isSubmitted: true,
            };
          } else if (a.questionType === questionType.OBJECTIVE) {
            const tickedAnswer = a.subjectQuestionOptions.find(
              (opt) => opt.ticked
            );
            return {
              subjectQuestionId: a.id,
              subjectiveAnswer: null,
              subjectQuestionOptionId: tickedAnswer ? tickedAnswer.id : null,
              isSubmitted: true,
            };
          } else {
            return {
              subjectQuestionId: a.id,
              subjectiveAnswer: null,
              subjectQuestionOptionId: null,
              isSubmitted: true,
            };
          }
        });
        axiosPost(
          URL.insertStudentAnswer,
          data,
          (response) => {
            if (response.status === 200) {
              swal(
                "Success",
                "Congratulations! You have attended system exam successfully."
              );
              this.setState({
                yearLevelId: "",
                classRoomId: "",
                assessmentId: "",
                subjectId: "",
                theoryQuestions: [],
                practicalQuestions: [],
              });
            } else {
              swal("Error", "Error submitting exam. Please try again.");
            }
          },
          (err) => {
            swal("Error", "Error submitting exam. Please try again.");
          }
        );
      });
    }
  };

  render() {
    const {
      educationLevels,
      educationLevelDivisions,
      classRooms,
      yearLevels,
      assessments,
      assessmentSubjects,
    } = this.state;
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "99.5vh" }}
        >
          <div className="tt-group-header">System Examination</div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2 text-right mt-1">
                <strong>Education Level: </strong>
                <select
                  className="form-control"
                  name="educationLevelId"
                  value={this.state.educationLevelId}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Select Education Level
                  </option>
                  {educationLevels.map((el, idx) => {
                    return <option value={el.id}>{el.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-2 text-right mt-1">
                <strong>Education Level Division: </strong>
                <select
                  className="form-control"
                  name="educationLevelDivisionId"
                  value={this.state.educationLevelDivisionId}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Select education level divisions
                  </option>
                  {educationLevelDivisions?.map((edv, idx) => {
                    return <option value={edv.id}>{edv.name}</option>;
                  })}
                </select>
              </div>

              <div className="col-md-2 text-right mt-1">
                <strong>Year Level: </strong>
                <select
                  className="form-control"
                  name="yearLevelId"
                  value={this.state.yearLevelId}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Select year level
                  </option>
                  {yearLevels.map((y, idx) => {
                    return <option value={y.id}>{y.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-2 text-right mt-1">
                <strong>CLassroom: </strong>
                <select
                  className="form-control"
                  name="classRoomId"
                  value={this.state.classRoomId}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Select classroom
                  </option>
                  {classRooms.map((c, idx) => {
                    return <option value={c.id}>{c.name}</option>;
                  })}
                </select>
              </div>

              <div className="col-md-2 text-right mt-1">
                <strong>Assessment: </strong>
                <select
                  className="form-control"
                  name="assessmentId"
                  value={this.state.assessmentId}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Select assessment
                  </option>
                  {assessments.map((a, idx) => {
                    return <option value={a.id}>{a.assessmentName}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-2 text-right mt-1">
                <strong>Subject: </strong>
                <select
                  className="form-control"
                  name="subjectId"
                  value={this.state.subjectId}
                  onChange={this.handleChange}
                  disabled={this.state.assessmentId === "" ? true : false}
                >
                  <option value="" disabled>
                    Select subjects
                  </option>
                  {assessmentSubjects?.map((sl, idx) => {
                    return (
                      <option value={sl.subjectId}>{sl.subjectName}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ExamFormQuestions
                  theoryQuestions={this.state.theoryQuestions}
                  practicalQuestions={this.state.practicalQuestions}
                  handleAnswerChange={this.handleAnswerChange}
                />
              </div>
            </div>
            {this.state.theoryQuestions?.length > 0 &&
              this.state.practicalQuestions?.length > 0 && (
                <div className="row">
                  <div className="col">
                    <button
                      className="tt-button tt-button-primary float-right"
                      onClick={this.handleSubmit}
                      id="addQuestion"
                    >
                      Submit Exam
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}

export default ExamForm;
